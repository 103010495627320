import style from "./ProfilePage.module.scss";
import { ProfilePageBloc, ProfilePageState } from "./ProfilePageBloc";
import { useCreation } from "ahooks";
import cls from "classnames";
import React, { ReactElement, useEffect } from "react";
import { MyAccountBlocContext } from "@uiview/pages/profile/MyAccountBlocContext";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { logEvent } from "@uikit/service/amplitude";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";

export function ProfilePage(): JSX.Element {
  const navigate = useNavigate();
  const state = new ProfilePageState();
  const fotProcessManager = FotProcessManager.use();
  const location = useLocation();
  const bloc = useCreation(() => new ProfilePageBloc(state, location.pathname), []);
  const isMobile = window.navigator.userAgent.includes("Mobile");
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;
  function getItem(x: any): ReactElement[] {
    const component: ReactElement[] = [];
    if (x.key === "signOut") {
      component.push(<div key={"line"} className={style.line}></div>);
    }
    const item = (
      <div
        key={x.key}
        onClick={() => {
          navigate(x.key);
          bloc.switchPage(x);
          if (x.key === "signOut") {
            fotProcessManager.logout();
          }
        }}
        className={cls(style.textItem, bloc.state.currentData.value.key === x.key ? style.selected : "")}
      >
        {x.title}
      </div>
    );
    component.push(item);
    return component;
  }

  useEffect(() => {
    bloc.checkSubscriptTags();
  }, []);

  if (isMobile && featureTags.disableMobilePage) {
    return <Outlet />;
  }

  return (
    <div>
      <div className={style.maskBox}></div>
      <div className={style.myAccount}>
        <div className={style.content}>
          <div className={style.sidebar}>
            <div className={style.sidebarHeader}>
              <div className={style.menuHeaderList}>
                {bloc.state.allData.value.map(x => {
                  return getItem(x);
                })}
              </div>
            </div>

            <div className={style.sidebarMenu}>
              <div className={style.topBox}>
                <div className={style.titleBox}>
                  <div className={style.title}>{bloc.state.currentData.value.title}</div>
                  <div className={style.subTitle}>{bloc.state.currentData.value.description}</div>
                </div>
                <img
                  onClick={() => {
                    logEvent("my_account_x_btn_close");
                    navigate(bloc.popProfilePath);
                  }}
                  src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`}
                  alt=""
                ></img>
              </div>

              <div className={style.assemblyContent}>
                <MyAccountBlocContext.Provider value={bloc}>
                  <Outlet></Outlet>
                </MyAccountBlocContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
