import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppstoreOutlined, LoadingOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Form, Input, Menu, Modal, Select, Spin, Tag } from "antd";
import DebounceSelect from "./components/DebounceSelect";
import StudioSwitch from "./components/StudioSwitch";
import StudioRunAllButton from "./components/StudioRunAllButton";
import func from "@uikit/func";

import { studioActions } from "../store/studio";
import { fotActions } from "../store/fot";
import { feedActions } from "../store/feed";
import { Api } from "../uikit/service/api";

const { TextArea } = Input;
const { Option } = Select;
const { Search } = Input;

const StudioMenu = props => {
  const dispatch = useDispatch();
  const api = new Api(false);

  // store states
  const fetching = useSelector(state => state.fot.fetching);
  const mode = useSelector(state => state.fot.mode);
  const templates = useSelector(state => state.studio.templates);
  const setTemplates = val => {
    dispatch(studioActions.setTemplates(val));
  };

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);
  const setSelectedTemplate = val => {
    dispatch(studioActions.setSelectedTemplate(val));
  };

  const loadingState = useSelector(state => state.studio.loadingState);
  const setLoadingState = val => {
    dispatch(studioActions.setLoadingState(val));
  };

  const prompt = useSelector(state => state.studio.prompt);
  const setPrompt = val => {
    dispatch(studioActions.setPrompt(val));
  };

  const updateSelectedTemplate = useSelector(state => state.studio.updateSelectedTemplate);

  const saveAsExistingTemplate = useSelector(state => state.studio.saveAsExistingTemplate);
  const setSaveAsExistingTemplate = val => {
    dispatch(studioActions.setSaveAsExistingTemplate(val));
  };

  const setThoughts = val => {
    dispatch(feedActions.setThoughts(val));
  };
  const setEditorIdArr = val => {
    dispatch(fotActions.setEditorIdArr(val));
  };
  const setCheckEdgeArr = val => {
    dispatch(fotActions.setCheckEdgeArr(val));
  };
  const setCheckNodeArr = val => {
    dispatch(fotActions.setCheckNodeArr(val));
  };

  const setStudioProjectName = val => {
    dispatch(fotActions.setStudioProjectName(val));
  };
  // local state
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isExistingProjectModalOpen, setIsExistingProjectModalOpen] = useState(false);
  const [newTemplateObj, setNewTemplateObj] = useState({ name: "", domain: "" });
  const [selectedExistingProject, setSelectedExistingProject] = useState({});
  const [existingProjectSearchTerm, setExistingProjectSearchTerm] = useState({
    value: "",
    label: "",
  });
  const [domains, setDomains] = useState([]);
  const [disableExistingProjectModalOK, setDisableExistingProjectModalOK] = useState(false);

  // methods

  const handleNewProjectModalOk = () => {
    createTemplate();
  };

  const handleNewProjectModalCancel = () => {
    setNewTemplateObj({ name: "", domain: "" });
    setIsNewProjectModalOpen(false);
  };

  const handleExistingProjectModalOk = () => {
    adpotSelectedExistingProject();
    setIsExistingProjectModalOpen(false);
    setStudioProjectName(existingProjectSearchTerm.label);

    // 重置股票购买数据
    setEditorIdArr([]);
    setCheckEdgeArr([]);
    setCheckNodeArr([]);
  };

  const handleExistingProjectModalCancel = () => {
    setExistingProjectSearchTerm({
      value: "",
      label: "",
    });
    setSelectedExistingProject({});
    setDisableExistingProjectModalOK(false);
    setIsExistingProjectModalOpen(false);
  };

  const handleNewTemplateNameChange = e => {
    setNewTemplateObj({ name: e.target.value, domain: newTemplateObj.domain });
  };

  const handleNewTemplateDomainChange = value => {
    setNewTemplateObj({ name: newTemplateObj.name, domain: value });
  };

  const handleSaveAsExistingTemplate = () => {
    createTemplate(selectedTemplate.name);
  };

  const handleSelectedExistingTemplateChange = async value => {
    setExistingProjectSearchTerm({
      value: value.value,
      label: value.label,
    });
    getTemplateByName(value.value);
  };

  const filterProjects = value => {
    return new Promise((resolve, reject) => {
      const options = templates
        .filter(x => (x || "").toLowerCase().includes(value.toLowerCase()))
        .map(x => {
          return {
            label: x,
            value: x,
          };
        });
      resolve(options);
    });
  };

  const getAllTemplates = () => {
    dispatch(fotActions.setFetching(true));
    setLoadingState({
      promptCard: true,
      debounceSelectLoading: true,
    });
    // TODO: revisit after complete merge with studios
    api
      .get(`/be/bas-demo-v4/brain_studios/templates${props.stickyNotes ? "?v2=true" : ""}`)
      .then(response => response.json())
      .then(data => {
        const deduplicatedData = new Set();
        data.forEach(x => {
          deduplicatedData.add(x);
        });
        const sortedData = (Array.from(deduplicatedData) || []).sort((a, b) => a);
        setTemplates(sortedData);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({
          promptCard: false,
          debounceSelectLoading: false,
        });
        setSaveAsExistingTemplate(false);
      });
  };

  const getStudioDomainList = selectedDomain => {
    dispatch(fotActions.setFetching(true));
    setLoadingState({ studioDomainSelect: true });
    api
      .get(`/be/bas-demo-v4/brain_studios/list_domains`)
      .then(response => response.json())
      .then(data => {
        setDomains(data[0]);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({ studioDomainSelect: false });
      });
  };

  const getTemplateByName = (name, updateSelected = false) => {
    dispatch(fotActions.setFetching(true));
    setLoadingState({ existingProjectModalOK: true });
    // TODO: revisit after complete merge with studios
    api
      .get(`/be/bas-demo-v4/brain_studios/templates?template_name=${name}${props.stickyNotes ? "&v2=true" : ""}`)
      .then(response => response.json())
      .then(data => {
        data.name = data.template_name;
        if (updateSelected) {
          setSelectedTemplate(data);
          const promptText = data.prompts && data.prompts[0] && data.prompts[0].text;
          setPrompt(promptText || "");
        } else {
          setSelectedExistingProject(data);
        }
        setDisableExistingProjectModalOK(false);
      })
      .catch(error => {
        console.error(error);
        func.messageError(error);
        setSelectedExistingProject({});
        setDisableExistingProjectModalOK(true);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({ existingProjectModalOK: false });
      });
  };

  const adpotSelectedExistingProject = () => {
    // TODO: revisit after complete merge with studios
    if (props.stickyNotes) {
      if (typeof props.loadFromExistingTemplateV2 === "function") {
        try {
          setSelectedTemplate(selectedExistingProject || {});
          props.loadFromExistingTemplateV2(selectedExistingProject);
        } catch (error) {
          console.error(error);
          func.messageError(error);
          return;
        }
      }
      setSelectedTemplate(selectedExistingProject || {});
      return;
    }
    setSelectedTemplate(selectedExistingProject || {});
    const promptText =
      selectedExistingProject.prompts && selectedExistingProject.prompts[0] && selectedExistingProject.prompts[0].text;
    setPrompt(promptText || "");
  };

  const createTemplate = () => {
    let templateName = newTemplateObj.name;
    if (!templateName) {
      templateName = selectedTemplate.name;
    }
    dispatch(fotActions.setFetching(true));
    setLoadingState({ promptSaveBtn: true, promptNewBtn: true, promptCard: true });
    const data = {
      template_name: templateName,
    };
    if (newTemplateObj.domain) {
      data.domain = newTemplateObj.domain;
    }
    if (saveAsExistingTemplate) {
      data.prompt = prompt;
    }
    // TODO: revisit after complete merge with studios
    if (props.stickyNotes) {
      data.v2 = true;
    }
    api
      .post(`/be/bas-demo-v4/brain_studios/create_template`, data)
      .then(response => response.json())
      .then(data => {
        const selectedTemplateObj = data[0];
        selectedTemplateObj.name = selectedTemplateObj.name || templateName;
        setSelectedTemplate(selectedTemplateObj || {});
        const promptText =
          selectedTemplateObj.prompts && selectedTemplateObj.prompts[0] && selectedTemplateObj.prompts[0].text;
        setPrompt(promptText || "");
        // const variables = selectedTemplateObj.variables;
        // setVariables(variables);
        setStudioProjectName(selectedTemplateObj.name);
        if (props.stickyNotes) {
          if (typeof props.initNewProjectV2 === "function") props.initNewProjectV2(selectedTemplateObj);
        }
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({ promptSaveBtn: false, promptNewBtn: false, promptCard: false });
        setIsNewProjectModalOpen(false);
        setNewTemplateObj({ name: "", domain: "" });
        getAllTemplates();
      });
  };

  const currentProjectLabel = () => {
    return (
      <p>
        <span style={{ marginRight: "20px" }} className="bold">
          {selectedTemplate.template_name || "No Project Selected"}
        </span>
        {selectedTemplate.domain ? (
          <span>
            <Tag color="blue">{selectedTemplate.domain}</Tag>
          </span>
        ) : (
          ""
        )}
      </p>
    );
  };

  const SaveLabel = () => {
    return (
      <>
        <span>Save</span>
        <span style={{ marginLeft: "5px" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} spinning={saveLoading} />
        </span>
      </>
    );
  };

  const items = [
    {
      label: <StudioSwitch />,
      key: "studioSwitch",
    },
    {
      label: "New Project",
      key: "projects",
      icon: <PlusOutlined />,
      onClick: () => {
        setIsNewProjectModalOpen(true);
      },
      disabled: fetching,
    },
    {
      label: "Existing Projects",
      key: "app",
      icon: <AppstoreOutlined />,
      onClick: () => {
        setExistingProjectSearchTerm({
          value: "",
          label: "",
        });
        setSelectedExistingProject({});
        setIsExistingProjectModalOpen(true);
      },
      disabled: fetching,
    },
    {
      label: SaveLabel(),
      key: "save",
      icon: <SaveOutlined />,
      onClick: async () => {
        if (typeof props.saveAsExistingTemplateV2 !== "function") return;
        try {
          setSaveLoading(true);
          // todo: should use handleSave() to replace saveAsExistingTemplateV2
          await props.saveAsExistingTemplateV2();
          func.messageUtil("Successfully saved", "success");
        } catch (error) {
          func.messageError(error);
        } finally {
          setSaveLoading(false);
        }
      },
      disabled: fetching || !selectedTemplate.name,
    },
    {
      label: currentProjectLabel(),
      key: "currentProject",
      style: {
        position: "absolute",
        width: "400px",
        left: "calc(50% - 200px)",
        textAlign: "center",
      },
      onClick: () => {
        setExistingProjectSearchTerm({
          value: selectedTemplate?.template_name || "",
          label: selectedTemplate?.template_name || "",
        });
        setSelectedExistingProject(selectedTemplate);
        setIsExistingProjectModalOpen(true);
      },
      disabled: fetching,
    },
    // {
    //   label: <StudioRunAllButton/>,
    //   key: 'studioRunAllButton',
    // },
  ].filter(x => {
    if (props.stickyNotes) {
      return x.key !== "studioSwitch";
    }
    return x.key !== "save";
  });

  useEffect(() => {
    getAllTemplates();
    getStudioDomainList();
  }, []);

  useEffect(() => {
    if (mode === "editorMode") return;
    setThoughts([]);
  }, [selectedTemplate.name]);

  useEffect(() => {
    if (saveAsExistingTemplate) {
      handleSaveAsExistingTemplate();
    }
  }, [saveAsExistingTemplate]);

  useEffect(() => {
    if (updateSelectedTemplate) {
      getTemplateByName(selectedTemplate.name, true);
      // adpotSelectedExistingProject();
    }
  }, [updateSelectedTemplate]);

  return (
    <>
      {props.stickyNotes ? null : <StudioRunAllButton templateName={selectedTemplate.name} />}
      <Menu selectable={false} mode="horizontal" items={items} />
      <Modal
        title="New Project"
        open={isNewProjectModalOpen}
        onOk={handleNewProjectModalOk}
        onCancel={handleNewProjectModalCancel}
        okButtonProps={{ loading: loadingState.promptNewBtn }}
      >
        <Form layout="vertical">
          <Form.Item
            label="Name"
            rules={[{ required: true, message: "Please input project name" }]}
            name="fotProjectName"
          >
            <Input
              placeholder="Input project name"
              value={newTemplateObj.name}
              onChange={handleNewTemplateNameChange}
            />
          </Form.Item>
          <Form.Item label="Domain (Optional)" name="domain">
            <Select
              placeholder="Select domain"
              value={newTemplateObj.domain}
              onChange={handleNewTemplateDomainChange}
              loading={loadingState.studioDomainSelect}
              allowClear
            >
              {domains.map((x, idx) => (
                <Option value={x} key={idx}>
                  {x}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Existing Projects"
        className="Studio-Existing-Projects-Modal"
        open={isExistingProjectModalOpen}
        onOk={handleExistingProjectModalOk}
        onCancel={handleExistingProjectModalCancel}
        okButtonProps={{
          loading: loadingState.existingProjectModalOK,
          disabled: disableExistingProjectModalOK || loadingState.debounceSelectLoading,
        }}
      >
        <div style={{ width: "90%", left: "5%", position: "absolute" }}>
          <Form layout="vertical">
            <Form.Item label="Select An Existing Project">
              <DebounceSelect
                placeholder="Select from existing projects"
                value={existingProjectSearchTerm}
                fetchOptions={filterProjects}
                debounceTimeout={100}
                loading={loadingState.debounceSelectLoading}
                onChange={handleSelectedExistingTemplateChange}
                initialOptions={templates.map(x => {
                  return {
                    label: x,
                    value: x,
                  };
                })}
                style={{ width: "100%" }}
              />
            </Form.Item>
            {selectedExistingProject.template_name ? (
              <>
                <Form.Item label="Domain">
                  <Input className="Studio-Prompt-Raw" value={selectedExistingProject.domain} disabled />
                </Form.Item>
                <Form.Item label="Domain Description">
                  <TextArea
                    className="Studio-Prompt-Raw"
                    value={selectedExistingProject.domain_description}
                    disabled
                  ></TextArea>
                </Form.Item>
              </>
            ) : (
              ""
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default StudioMenu;
