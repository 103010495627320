"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStateValue = exports.ImgCacheValue = void 0;
const VBGraphDataBase_1 = require("./VBGraphData/VBGraphDataBase");
class ImgCacheValue {
    constructor() {
        this.urls = [];
    }
}
exports.ImgCacheValue = ImgCacheValue;
class LocalStateValue {
    constructor(data = new VBGraphDataBase_1.VBGraphDataBase()) {
        this.loading = false;
        this.noUseMockData = false;
        this.data = data;
    }
}
exports.LocalStateValue = LocalStateValue;
