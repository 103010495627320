export var fileFormatConfig = {
  baseVideo: {
    formats: ["MP4", "WebM", "Ogg"]
  },
  unStructured: {
    formats: [],
    featureTag: "disableUploadNodeUnStructured"
  },
  table: {
    formats: ["xlsx", "csv"]
  },
  audio: {
    formats: ["WAV", "mp3", "Ogg", "m4a"]
  },
  uploadFile: {
    formats: []
  },
  pdfFile: {
    formats: ["pdf"]
  },
  uploadImage: {
    formats: ["JPEG", "PNG", "GIF", "BMP", "WebP", "SVG", "ICO", "JPG", "SVG"],
    featureTag: "disableUploadNodeImage"
  },
  html: {
    formats: ["html"],
    featureTag: "disableUploadNodeHtml"
  }
};