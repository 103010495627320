import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import style from "./AModal.module.css";

export function ModalButton(props: ButtonProps) {
  const { children, type = "default", ...rest } = props;
  return (
    <Button
      {...rest}
      block
      shape="round"
      size="large"
      type={type}
      className={props.danger ? style["a-modal-btn-dangerous"] : null}
    >
      {children}
    </Button>
  );
}
