import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import style from "../components-css/LoadingOutline.module.css";

export default function LoadingOutline(props) {
  return (
    <div className={`${style["loading-box"]} ${props.className}`}>
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: props.size || 24,
            }}
            spin
          />
        }
      />
    </div>
  );
}
