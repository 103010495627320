"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadApi = void 0;
const RestClient_1 = require("../cutil/RestClient");
const ProfileUploadResult_1 = require("../api/uploadTyped/ProfileUploadResult");
const LangUtil_1 = require("../cutil/LangUtil");
const JsonUtil_1 = require("../cutil/JsonUtil");
class UploadApi {
    constructor(httpRequest, adapter) {
        this.httpRequest = httpRequest;
        this.adapter = adapter;
    }
    async profileUpload(stream) {
        const dateTime = new Date().getTime();
        const url = `${this.adapter.baseUrl}/api/users/me/profile/upload/img_${dateTime}`;
        const res = await fetch(url, {
            method: "POST",
            body: stream,
            headers: this.adapter.headers,
        });
        const json = await (0, LangUtil_1.tryPromise)(res.json());
        if (json.error) {
            const basicError = new RestClient_1.RestInternalError(json.error);
            this.adapter?.errorHandler(basicError);
            throw basicError;
        }
        const data = json.data;
        const response = new RestClient_1.RestResponse(res.status, res.statusText, res.headers, data);
        return response.toModelFromType(ProfileUploadResult_1.ProfileUploadResult);
    }
    profileUploadXhr(body, onProgress) {
        const dateTime = new Date().getTime();
        const url = `${this.adapter.baseUrl}/api/users/me/profile/upload/img_${dateTime}`;
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", url, true);
            const headers = this.adapter.headers;
            for (let key of Object.keys(headers)) {
                const value = headers[key];
                if (value == undefined)
                    continue;
                xhr.setRequestHeader(key, value);
            }
            xhr.onload = () => {
                const data = JsonUtil_1.JsonUtil.toModelFromType(ProfileUploadResult_1.ProfileUploadResult, xhr.responseText);
                const response = new RestClient_1.RestResponse(xhr.status, xhr.statusText, new Headers(), data);
                resolve(response);
            };
            xhr.upload.onprogress = ({ loaded, total }) => {
                onProgress(loaded, total);
            };
            xhr.onerror = () => {
                reject("Network error occurred");
            };
            xhr.send(body);
        });
    }
}
exports.UploadApi = UploadApi;
