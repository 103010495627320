import { signal } from "@preact/signals-react";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { logEvent } from "@uikit/service/amplitude";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";

export class UpgradeButtonState {
  showUpgrade = signal(false);
}

export class UpgradeButtonBloc {
  store: SubscriptionStore = getIt(SubscriptionStore);

  state = new UpgradeButtonState();

  constructor() {
    this.loadData();
  }

  async loadData(): Promise<void> {
    const currentPlan = await this.store.currentPlan();
    const plans = await this.store.imagicaCreatorPlans().catch(e => {
      func.messageUtil("Get plan list failed.", "error");
      return [];
    });
    if (plans.length > 0) {
      const lastPlan = plans.last();
      if (lastPlan?.value === currentPlan.value) {
        this.state.showUpgrade.value = false;
        return;
      }
    }
    this.state.showUpgrade.value = true;
  }

  getPlanPath(currentPath: string): string {
    return `${currentPath}/upgrade_plans`;
  }

  handleClick(): void {
    logEvent("click", {
      target: "top-bar-upgrade-button",
    });
  }
}
