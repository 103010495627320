"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VBDataWeatherWeekly = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataWeatherWeekly extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.current_weather = new CurrentWeather();
        this.weekly_weather = [];
    }
}
exports.VBDataWeatherWeekly = VBDataWeatherWeekly;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => CurrentWeather)
], VBDataWeatherWeekly.prototype, "current_weather", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => WeeklyWeather, [])
], VBDataWeatherWeekly.prototype, "weekly_weather", void 0);
class CurrentWeather {
    constructor() {
        this.weather_icon = "";
        this.current_temperature = "";
        this.weather_condition = "";
        this.location = "";
    }
}
class WeeklyWeather {
    constructor() {
        this.day = "";
        this.daily_temperature = "";
        this.daily_weather_icon = "";
        this.health_tip = new HealthTip();
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => HealthTip)
], WeeklyWeather.prototype, "health_tip", void 0);
class HealthTip {
    constructor() {
        this.tip_icon = "";
        this.tip_text = "";
    }
}
