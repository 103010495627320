"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ideation = void 0;
class Ideation {
    constructor() {
        this.app_generated = false;
        this.current_response = "";
        this.one_line_description = "";
        this.suggested_response = [];
        this.app_features = "";
    }
}
exports.Ideation = Ideation;
