import { PlanCell } from "./PlanCell";
import { useEffect, useMemo } from "react";
import css from "./index.module.scss";
import { FeatureCell } from "./FeatureCell";
import classNames from "classnames";
import { ImagicaCreatorPlan } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { ImagicaFeature } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaFeature";
import { Buttonloading } from "@uikit/planList/PlansListBloc";

type PlanTableProps = {
  buttonLoading: Buttonloading;
  options?: ImagicaCreatorPlan[];
  featureOptions?: ImagicaFeature[];
  onUpgradeClick: (plan: ImagicaCreatorPlan) => void;
  onDowngradeClick: (plan: ImagicaCreatorPlan) => void;
};

export function PlanTable(props: PlanTableProps): JSX.Element {
  const { options, featureOptions } = props;

  const renderOptions = useMemo(() => {
    return [{ value: "__empty__" }, ...(options || [])];
  }, [options]);

  useEffect(() => {
    featureOptions?.forEach((x, index) => {
      const dom = document.querySelector<HTMLDivElement>(`[data-cell-id="FeatureTitleCell${index}"]`);
      if (dom) {
        const scrollHeight = dom.scrollHeight;
        const clientHeight = dom.clientHeight;
        if (scrollHeight > clientHeight) {
          dom.style.height = scrollHeight + 20 + "px";
          const domList = document.querySelectorAll<HTMLDivElement>(`[data-cell-id="FeatureContentCell${index}"]`);
          if (domList) {
            domList.forEach(j => {
              j.style.height = scrollHeight + 20 + "px";
            });
          }
        }
      }
    });
  }, []);

  return (
    <div className={css.planTable}>
      <div className={css.columns}>
        {renderOptions.map(item => {
          const plan = item as ImagicaCreatorPlan;
          return (
            <div key={item.value} className={css.column} data-highlight-border={plan.highlight_border}>
              <div className={classNames(css.cell)}>
                {item.value === "__empty__" ? null : (
                  <PlanCell
                    record={plan}
                    buttonLoading={props.buttonLoading}
                    onUpgradeClick={props.onUpgradeClick}
                    onDowngradeClick={props.onDowngradeClick}
                  />
                )}
              </div>
              {featureOptions?.map((feature: ImagicaFeature, index: number) => {
                return (
                  <div
                    className={classNames(css.cell)}
                    key={feature.name}
                    data-cell-id={(item.value === "__empty__" ? "FeatureTitleCell" : "FeatureContentCell") + index}
                  >
                    <FeatureCell
                      type={item.value === "__empty__" ? "title" : "content"}
                      plan={plan}
                      feature={feature}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
