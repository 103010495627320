"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapApi = void 0;
const lodash_1 = require("lodash");
class MapApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    async postDomainMapping(input) {
        const params = {
            domain: "brain_studios",
            identifier_type: "file",
            identifier_value: "map_location_extractor.model",
            variables: {
                input,
            },
        };
        const result = await this.httpRequest.post("/be/bas-demo-v4/nlp/completions_generation", params, {}, { "Content-Type": "application/json" });
        return result.data;
    }
    async getMapTextSearch(keyword, location) {
        const locationStr = (0, lodash_1.isEmpty)(location) ? "" : `${location[1]},${location[0]}`;
        const result = await this.httpRequest.get(`/be/svc-adapter/google/maps/textsearch?query=${keyword}&location=${locationStr}`);
        return result?.data?.data;
    }
    async getMapAddressGps(address) {
        const result = await this.httpRequest.get(`/be/svc-adapter/google/maps/address-gps`, { address });
        return result?.data?.data;
    }
    async getPlaceDetail(placeid) {
        const result = await this.httpRequest.get(`/be/svc-adapter/google/maps/place_details?placeid=${placeid}`);
        return result?.data?.data?.result;
    }
}
exports.MapApi = MapApi;
