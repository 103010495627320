import { find, get } from "lodash";
import { Node } from "reactflow";
import { PreviewDataType } from "../PreviewDataType";
import { PreviewAppValue } from "../model/PreviewAppValue";
import { PreviewContentUtil } from "../PreviewContentUtil";

export const PreviewOutputLocusMap = {
  preview: "saas",
  canvas: "canvas",
} as const;

export class PreviewOutputUtil {
  /**
   * 获取每个 output node 的唯一ID
   * @param node
   * @returns
   */
  static genNodeUUID(nodeId: string, parentId?: string): string {
    return `${parentId || ""}**${nodeId}`;
  }

  static getOutputRenderData(node?: Node, outputList: any = []): Record<string, any> {
    if (!node) {
      return { style: {} };
    }

    const uid = PreviewOutputUtil.genNodeUUID(node.id, node.parentNode);

    const output = find(outputList, output => {
      const noedId = get(output, "results.0.nodeId");
      const targetUid = PreviewOutputUtil.genNodeUUID(noedId, output.groupId);
      return targetUid === uid;
    });

    return {
      style: get(output, "results.0.nodeFontStyle"),
      isCaptionToggle: get(output, "results.0.isCaptionToggle"),
    };
  }

  /**
   * 将原来 previewOutput 数据(saasuidata.output)转成新的 previewOutput 数据
   * @param thought
   * @param targetNode
   * @returns
   */
  static thoughtToPreviewData(thought: any, targetNode: Node): PreviewDataType {
    const renderData = {
      style: get(thought, "results.0.nodeFontStyle"),
      isCaptionToggle: get(thought, "results.0.isCaptionToggle"),
    };

    const outputContent = PreviewContentUtil.getPreviewContentByNode(targetNode);

    /**
     * FIXME: 该数据用于之前 sassuidata.output 中数据可用的
     */
    const previewData: PreviewDataType = {
      /**
       * 唯一的id
       */
      id: PreviewOutputUtil.genNodeUUID(targetNode.id, targetNode.parentNode),

      nodeId: targetNode.id,

      parentId: targetNode.parentNode || "",

      data: {
        // blank: {
        //   title: node.data.inputTitle,
        // }
        blank: get(thought, ["results", "0", "blank"], {}),
        renderData,
        fontSize: get(thought, ["results", "0", "fontSize"]),
        content: outputContent.content,
        errorText: get(thought, ["results", "0", "errotText"]),
        contentType: outputContent.contentType as PreviewDataType["data"]["contentType"],
        // 没有使用了
        // layout: get(thought, ["results", "0", "layout"]),
        nodeFontStyle: get(thought, ["results", "0", "nodeFontStyle"]),
        viewBuilderData: {
          loadingHtml: get(thought, ["results", "0", "viewBuilderData", "loadingHtml"]),
          domList: get(thought, ["results", "0", "viewBuilderData", "domList"]),
          originalText: get(thought, ["results", "0", "viewBuilderData", "originalText"]),
        },
        isCaptionToggle: get(thought, ["results", "0", "isCaptionToggle"]),
      },
    };

    return previewData;
  }

  /**
   *
   * @param output
   * @param getNodes
   * @deprecated `PreviewAppValue.toPreviewOutputv2`
   * @returns
   */
  static toPreviewOutputv2(output: AISaasOutput.ThoughtType[], getNodes: () => any[]): PreviewDataType[] {
    return PreviewAppValue.toPreviewOutputv2(output, getNodes);
  }

  static genStockBuyid(nodeid: string) {
    return `${PreviewOutputLocusMap.preview}${nodeid}`;
  }
}
