"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiberClothingAdapter = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
class FiberClothingAdapter extends AdapterBase_1.AdapterBase {
    runResponseType() {
        return AdapterBase_1.RunResponseType.TEXT;
    }
}
exports.FiberClothingAdapter = FiberClothingAdapter;
