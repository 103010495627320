import { ProjectNetDataStore } from "@base/ProjectNetDataStore";
import store from "@store/index";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { ConnectAgentService } from "@uikit/service/ConnectAgentService";
import { CreatorNodesErrorTips } from "@uikit/service/CreatorNodesErrorTips";
import { FotNavigateController } from "@uikit/service/FotNavigateController";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";
import { SharedLinkToGetProject } from "@uikit/service/SharedLinkToGetProject";
import { AmodalStore } from "@uikit/store/AmodalStore";
import { CotStore } from "@uikit/store/CotStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorRefStore, creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { FotProcessStore } from "@uikit/store/FotProcessStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { WindowSizeStore } from "@uikit/store/WindowSizeStore";
import { HomeToolBarStore, homeToolBarStore } from "@uikit/store/homeToolBarStore";
import { ChatAIStore } from "@uiview/views/ChatAI/ChatAIStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { PreviewPaneStore } from "@uiview/views/PreviewPane/PreviewPaneStore";
import { NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { Container } from "inversify";

/**
 * 该 class 用于注册 fot 一些基本类，比如
 *
 * - CreatorNodeStore
 * - ProjectNodeStore
 *
 * 没有依赖或基础依赖的 class 实例
 *
 * 或者是一些公用的 class 实例需要提前加载
 */
export class RegisterBasic {
  static configure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };

    // from corekit
    container.bind(HomePluginStore).toConstantValue(new HomePluginStore());
    container.bind(HomeStore).toConstantValue(new HomeStore());
    container.bind(ProjectNetDataStore).toConstantValue(new ProjectNetDataStore(it(MeStore)));

    // from fot base store or service
    const fotReduxStore = new FotReduxStore(store);
    const creatorNodesStore = new CreatorNodesStore();
    const objectRelationGqlService = new ObjectRelationGqlService();
    const postUrlCanAbortService = new PostUrlCanAbortService();

    container.bind(FotReduxStore).toConstantValue(fotReduxStore);
    container.bind(ChatAIStore).toConstantValue(new ChatAIStore());
    container.bind(PreviewPaneStore).toConstantValue(new PreviewPaneStore());
    container.bind(CotStore).toConstantValue(new CotStore());
    container.bind(NavBarStore).toConstantValue(new NavBarStore());
    container.bind(ObjectRelationGqlService).toConstantValue(objectRelationGqlService);
    container.bind(WindowSizeStore).toConstantValue(new WindowSizeStore());
    container.bind(CreatorNodesStore).toConstantValue(creatorNodesStore);
    container.bind(CreatorEdgesStore).toConstantValue(new CreatorEdgesStore());
    container.bind(ProjectNodeStore).toConstantValue(new ProjectNodeStore());
    container.bind(FotProcessStore).toConstantValue(new FotProcessStore());
    container.bind(PostUrlCanAbortService).toConstantValue(postUrlCanAbortService);
    container.bind(AmodalStore).toConstantValue(new AmodalStore());
    container.bind(ProjectRunStore).toConstantValue(new ProjectRunStore());

    // FIXME: export signle instance
    container.bind(HomeToolBarStore).toConstantValue(homeToolBarStore);
    container.bind(CreatorRefStore).toConstantValue(creatorRefStore);

    container.bind(CreatorAISaasStore).toConstantValue(new CreatorAISaasStore(fotReduxStore));
    container.bind(CreatorSaasAppStore).toConstantValue(new CreatorSaasAppStore(creatorNodesStore));
    container.bind(CanvasDataRef).toConstantValue(new CanvasDataRef(it(CreatorRefStore), creatorNodesStore));
    container.bind(CreatorNodesErrorTips).toConstantValue(new CreatorNodesErrorTips(creatorNodesStore));
    container.bind(SharedLinkToGetProject).toConstantValue(new SharedLinkToGetProject(objectRelationGqlService));
    container
      .bind(ConnectAgentService)
      .toConstantValue(new ConnectAgentService(creatorNodesStore, postUrlCanAbortService));
    container.bind(ShowNavPageStore).toConstantValue(new ShowNavPageStore());
    container
      .bind(FotNavigateController)
      .toConstantValue(new FotNavigateController(it(ShowNavPageStore), myModificationDataStroe));
  }
}
