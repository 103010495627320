import style from "./PublishV2Item.module.scss";
import cls from "classnames";
import { logEvent } from "@uikit/service/amplitude";
import { useSignal } from "@preact/signals-react";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";
import { PreviewHeaderViewBlocV2 } from "@uiview/views/PreviewHeaderV2/PreviewHeaderViewBlocV2";
import func from "@uikit/func";
import store from "@store/index";
import { previewStore } from "@uiview/store/PreviewStore";
import { useDispatch } from "react-redux";
import { studioActions } from "@store/studio";
import { Meta } from "imagica-corekit/dist/base/cutil/Meta";
import { PreviewUtil } from "imagica-corekit/dist/cases/preview/PreviewUtil";
import { useEffect, useMemo } from "react";
import { Button } from "antd";
import { PublishCompletionModal } from "@uiview/views/HomeNavBarViews/PublishCompletion/PublishCompletion";
import { LinksAndQRCodesViewBloc, QRCodeViewState } from "@uiview/views/LinksAndQRCodes/LinksAndQRCodesViewBloc";
import { useCreation } from "ahooks";
import { useHomePublicMethods } from "@uiview/views/ProjectCanvas/useHomeUtilMethods";
import { NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { PreviewAppStore, PublishState } from "@uikit/store/PreviewAppStore";
import { useIsStaticApp } from "@custom-hooks/useNodePreview";

interface Props {
  beforePublish: () => boolean;
  showQRCode?: () => void;
  cleanStyle?: boolean;
  publishBtnDisable?: boolean;
}

export function PublishV2Item(props: Props): JSX.Element {
  const navBarStore = getIt(NavBarStore);
  const navBarState = useImagicaStore(navBarStore).value;
  const fotSaveService = getIt(FotSaveService);
  const previewComposeState = getIt(PreviewComposeState);
  const { isStaticApp } = useIsStaticApp();
  const creatorPreviewState = useImagicaStore(previewStore).value;
  const bloc = useCreation(() => new PreviewHeaderViewBlocV2(), []);
  const previewAppStore = getIt(PreviewAppStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const disabledPublishV2 = useMemo(() => {
    // bsf-4912 replace thoughts
    return previewComposeState.disabledPublishV2();
  }, [saasUIData, isStaticApp, creatorPreviewState.selectedUI]);
  const LinksAndQRCodesUrl = useSignal(
    PreviewUtil.getCustomDomain(saasUIData) || previewAppStore.getPublishMetadata()?.link || ""
  );

  const dispatch = useDispatch();
  const isShowCompletionModal = useSignal(false);
  const title = saasUIData.v2App?.appName ?? "";
  const qrCodeState = new QRCodeViewState();
  const QRCodeBloc = useCreation(() => new LinksAndQRCodesViewBloc(qrCodeState), []);
  const homePublicMethods = useHomePublicMethods();

  function setSelectedTemplate(val: any): void {
    dispatch(studioActions.setSelectedTemplate(val));
  }

  const showPublishTips = (disabledPublishV2 && navBarState.publishLoading === false) || bloc.subscriptionAndNoPrice();
  useEffect(() => {
    const nothing = "Add input/content to publish";
    const nosubscriptionPrice = "Add subscription pricing before publishing";
    if (!showPublishTips) {
      navBarStore.setShowPublishDisableTip("");
      return;
    }
    if (bloc.subscriptionAndNoPrice()) {
      navBarStore.setShowPublishDisableTip(nosubscriptionPrice);
    } else {
      navBarStore.setShowPublishDisableTip(nothing);
    }
  }, [showPublishTips]);

  useEffect(() => {
    // 说明是打开了已存在的project
    if (!func.isEmpty((store.getState().studio.selectedTemplate as any)?.relationId)) {
      // eslint-disable-next-line
      const showPreviewContent = async () => {
        await homePublicMethods.showPreviewContent();
      };

      showPreviewContent();
    }
  }, []);

  async function handlePublishClick(): Promise<void> {
    if (showPublishTips || navBarState.publishLoading || !props.beforePublish()) return;

    if (!previewStore.state.showPreviewPanel) {
      // 展示preview页面
      logEvent("click_publish_as");
      await homePublicMethods.showPreviewContent();
      previewAppStore.setShowPreviewPanel(true);
    } else {
      // 发布
      await publish();
      // 标记状态为published
      previewAppStore.setPublishState(PublishState.publish);
    }
  }

  async function publish(): Promise<void> {
    if (showPublishTips || navBarState.publishLoading) return;
    // sutd-1722: published_metadata
    // let published_metadata = selectedTemplate?.published_metadata || {}
    let published_metadata = previewAppStore.getPublishMetadata() || {};
    navBarStore.setPublishLoading(true);
    if (
      previewStore.state.selectedUI === "saas" ||
      // || (util.isEmpty(selectedTemplate?.published_metadata)
      (func.isEmpty(published_metadata) &&
        func.isEmpty(published_metadata) &&
        bloc.featureTags.showSubscriptionFeaturesToggle &&
        previewStore.state.selectedUI === "saasV2")
    ) {
      published_metadata = {
        uuid: "",
        original: true,
        stripe_product_id: "",
        stripe_price_id: "",
        stripe_account_id: "",
      };
    }
    try {
      const shareLink = "";
      const template = store.getState().studio.selectedTemplate;
      const data: any = {
        templateObj: template,
        shareObj: { share: true, shareLink },
        params: { published_metadata },
        showPublishTips,
      };

      const { updated_project } = await fotSaveService.handleSave(data);
      if (!updated_project) {
        throw new Meta("Error", "Unable to create shared project");
      }
      setSelectedTemplate(updated_project);
      await bloc.handleSubscription(updated_project);
      LinksAndQRCodesUrl.value =
        PreviewUtil.getCustomDomain(saasUIData) || previewAppStore.getPublishMetadata()?.link || "";
      await QRCodeBloc.getStudioDomainList(LinksAndQRCodesUrl.value, title);
      if (props.showQRCode) {
        props.showQRCode();
      } else {
        isShowCompletionModal.value = true;
      }
      const selectTemlate = previewAppStore.getSelectedTemplate();
      const eventProps = bloc.getPublishEventProps(selectTemlate, LinksAndQRCodesUrl.value as string, template);
      switch (previewStore.state.selectedUI) {
        case "saas":
          logEvent("publish_as_webapp", { URL: updated_project.published_metadata.link, ...eventProps });
          break;
        case "saasV2":
          logEvent("publish_as_saas", { URL: updated_project.published_metadata.link });
          break;
        case "thinkingApp":
          logEvent("publish_as_thinkingapp", { URL: updated_project.published_metadata.link });
          break;
        default:
          break;
      }
    } catch (error: any) {
      console.error("publish", error);
      func.messageUtil(error);
    } finally {
      navBarStore.setPublishLoading(false);
    }
  }

  const publishBtnStyl = useSignal(cls(style.content));

  useEffect(() => {
    publishBtnStyl.value = cls(
      style.content,
      !showPublishTips ? style.conentOthersStyle : style.disableBox,
      !props.publishBtnDisable ? style.conentCursorPointer : style.conentCursorPointNoDrop
    );
  }, [showPublishTips]);
  return (
    <div className={props.cleanStyle ? null : style["divider-box"]}>
      <div className={style.button}>
        <Button
          className={publishBtnStyl.value}
          loading={navBarState.publishLoading}
          disabled={showPublishTips || props.publishBtnDisable}
          type="primary"
          shape="round"
          onClick={async () => {
            await handlePublishClick();
          }}
          data-testid="PublishShareButton"
        >
          {navBarState.publishLoading ? (
            <></>
          ) : (
            <div className={style.center}>
              <span className={style["icon-text"]}>Publish</span>
            </div>
          )}
        </Button>
      </div>
      <PublishCompletionModal
        isOpen={isShowCompletionModal.value}
        onClose={() => {
          isShowCompletionModal.value = false;
        }}
        qrCodeImgUrl={QRCodeBloc.state.qrCodeUrl.value}
        appLink={LinksAndQRCodesUrl.value}
      />
    </div>
  );
}
