"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VBDataRestaurantRecommendation = exports.VBDataRestaurant = exports.Source = exports.Rating = void 0;
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
const VBGraphDataBase_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBGraphDataBase");
class Rating {
    constructor() {
        this.rating = "";
        this.reviews = "";
    }
}
exports.Rating = Rating;
class Source {
    constructor() {
        this.source_icon = "";
        this.source_text = "";
    }
}
exports.Source = Source;
class VBDataRestaurant {
    constructor() {
        this.restaurant_image = "";
        this.restaurant_name = "";
        this.restaurant_rating = new Rating();
        this.restaurant_type = "";
        this.restaurant_price_range = "";
    }
}
exports.VBDataRestaurant = VBDataRestaurant;
class VBDataRestaurantRecommendation extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.title = "";
        this.description = "";
        this.source = new Source();
        this.restaurant_list = [];
    }
}
exports.VBDataRestaurantRecommendation = VBDataRestaurantRecommendation;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Source)
], VBDataRestaurantRecommendation.prototype, "source", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => VBDataRestaurant, [])
], VBDataRestaurantRecommendation.prototype, "restaurant_list", void 0);
