"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BluePrintDataService = void 0;
const ComponentId_1 = require("../../../base/api/viewBuilderTyped/VBGraphData/ComponentId");
const VBGraphDataBase_1 = require("../../../base/api/viewBuilderTyped/VBGraphData/VBGraphDataBase");
const JsonUtil_1 = require("../../../base/cutil/JsonUtil");
const LangUtil_1 = require("../../../base/cutil/LangUtil");
const RestClient_1 = require("../../../base/cutil/RestClient");
class BluePrintDataService {
    constructor(brainClient, socketRestClient) {
        this.brainClient = brainClient;
        this.socketRestClient = socketRestClient;
    }
    async executeBlueprint(blueprintInfo, componentId, appDescription) {
        const result = await (0, LangUtil_1.tryPromise)(this.socketRestClient.requestRestDefault(topic => {
            return this.brainClient.openAi.onboardingVBBlueprintExecuteWithSocket({
                id: blueprintInfo.id,
                inputs: blueprintInfo.inputs,
                context: appDescription,
                stream: false,
            }, topic);
        }, "blueprint_execute"));
        if (result.error) {
            console.log(result.error);
            return Promise.reject(result.error);
        }
        const data = await result.data?.promise();
        const dataModelType = (0, ComponentId_1.getVBDataType)(componentId);
        if (dataModelType) {
            const value = data instanceof RestClient_1.RestResponse ? data.data?.data?.value : data?.data?.value;
            const finalResult = JsonUtil_1.JsonUtil.toModelFromType(dataModelType, value) ?? new VBGraphDataBase_1.VBGraphDataBase();
            // console.log(finalResult,'finalResult',value);
            return finalResult;
        }
        console.warn("dataModel component_id not match", componentId);
        return new VBGraphDataBase_1.VBGraphDataBase();
    }
}
exports.BluePrintDataService = BluePrintDataService;
