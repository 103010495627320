import { motion } from "framer-motion";
import React, { PropsWithChildren } from "react";

export const LoadingMotion: React.FC<PropsWithChildren> = ({ children = "Processing..." }) => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, repeat: Infinity }}>
      {children}
    </motion.div>
  );
};
