import useAlertBeforeUnload from "@custom-hooks/useAlertBeforeunload";
import { editorActions } from "@store/editor";
import store, { RootState } from "@store/index";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { useFitCanvas } from "@uikit/hooks/useFitCanvas";
import { logEvent } from "@uikit/service/amplitude";
import { CallerBot } from "@uiview/views/CallerBot/CallerBot";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { TemplateModal } from "@uiview/views/NewOnboardingFlow/TemplateModal/TemplateModal";
import { getDefaultPreviewAppTypeList } from "@uiview/views/PreviewApp/const";
import { UnSupportedGraph } from "@uiview/views/UnSupportedGraph";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { CotFlow } from "imagica-corekit/dist/cases/cot/CotFlow";
import { TransformResponseData } from "imagica-corekit/dist/cases/cot/typed/TransformResponseData";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { uniqBy } from "lodash";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "reactflow/dist/style.css";
import { useEffect12 } from "../effect/useEffect12";
// import { markerEnd, selectedMarkerEnd } from "../homeConst";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import useCompositionKeypressMethods from "../useCompositionKeypressMethods";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { useHomePublicMethods } from "../useHomeUtilMethods";
import { CreatorCanvas } from "./CreatorCanvas";
import { CreatorCodeModal } from "./CreatorCodeModal";
import { CreatorFunctionPopup } from "./CreatorFunctionPopup";
import { CreatorIdeaList } from "./CreatorIdeaList";
import { CreatorRightMenu } from "./CreatorRightMenu";
import { CreatorTermsPopup } from "./CreatorTermsPopup";
import { CreatorToolBar } from "./CreatorToolBar";
import { previewStore } from "@uiview/store/PreviewStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { CreatorHelp } from "@uiview/views/ProjectCanvas/CreatorRoot/CreatorHelp";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { OneProjectOneApp } from "@uiview/views/OneProjectOneApp/OneProjectOneApp";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { CreatorCotService } from "@uikit/service/CreatorCotService";
import { CotStore } from "@uikit/store/CotStore";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";

export const CreatorRoot = memo(() => {
  const homeStore = getIt(HomeStore);
  const cotFlow = getIt(CotFlow);
  const homePluginStore = getIt(HomePluginStore);
  const creatorAISaasStore = getIt(CreatorAISaasStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const canvasDataRef = getIt(CanvasDataRef);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const previewAppStore = getIt(PreviewAppStore);
  const projectNodeStore = getIt(ProjectNodeStore);
  const creatorPreviewService = getIt(CreatorPreviewService);
  const creatorCotService = getIt(CreatorCotService);
  const showNavPageStore = getIt(ShowNavPageStore);
  const webConfigState = useImagicaStore(getIt(WebConfigStore)).value;

  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const { fitCanvas } = useFitCanvas();
  const homePluginState = useImagicaStore(homePluginStore).value;
  const creatorPreviewState = useImagicaStore(previewStore).value;
  const showNavPageState = useImagicaStore(showNavPageStore).value;
  const homeHandleFieldMethods = getIt(HomeHandleFieldMethods);
  const cotStore = getIt(CotStore);
  const cotStoreState = useImagicaStore(cotStore).value;

  const featureTags = homeStore.state.featureTags;

  const disableAddPreview = useSelector((state: RootState) => state.fot.disableAddPreview);
  const homePublicMethods = useHomePublicMethods();

  // kill auto save for now unti duplicate project fixed
  // useAutoSavePer5Mins(showNavPage, undoRef, handleSave)
  const { handleCompositionKeyPress } = useCompositionKeypressMethods();

  useEffect(() => {
    document.addEventListener("keydown", handleCompositionKeyPress);

    return (): void => {
      document.removeEventListener("keydown", handleCompositionKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  //FIXME:OPT node下发没有add content按钮，应该删除
  useEffect(() => {
    projectPageStore.setPreviewHeight(disableAddPreview ? 0 : 50);
  }, [disableAddPreview]);

  // effect 15
  useEffect(() => {
    let inputId = [];
    if (creatorPreviewState.selectedUI === "saas") {
      inputId = (saasUIData.input || []).map(x => x.id);
    }
    creatorAISaasStore.setInputId(inputId);
    // eslint-disable-next-line
  }, [creatorPreviewState.selectedUI]);

  useEffect12();

  useAlertBeforeUnload({
    showNavPage: showNavPageState.showNavPage,
    isShare: false,
    saasUIData: saasUIData,
  });

  // functions
  const dispatch = useDispatch();
  const setEnableCOTStartAgain = useCallback(
    (val: any) => {
      dispatch(editorActions.setEnableCOTStartAgain(val));
    },
    [dispatch]
  );

  const closeUnsupported = useCallback(() => {
    cotStore.setShowUnsupported(false);
    if (
      featureTags.disableMultiRoundChatOnboarding === false &&
      getIt(ChatOnboardingStore).state.selectTemplateInModal === ChoosingFormat.AUTOIMAGICA
    ) {
      cotStore.setMultiRoundChatOnboarding(true);
    } else {
      cotStore.setShowCot(true);
    }
    homePublicMethods.clickClosePreview();
    setEnableCOTStartAgain(null);
    // logEvent("click_cot_ai_icon");
    // eslint-disable-next-line
  }, [homePublicMethods.clickClosePreview, setEnableCOTStartAgain]);

  const startShowCotFlow = useCallback(
    (flowData: TransformResponseData, thinkValue: any) => {
      const { inputs, previewData, extraSaasUIData, flowGroup } = flowData as any;
      const saasInputNodeIds = inputs.map((node: { id: any }) => node.id);
      creatorSaasAppStore.setSaasUIData(prev => ({
        ...prev,
        fromCotTitle: extraSaasUIData.title,
        fromCotSubtitle: extraSaasUIData.subTitle,
      }));

      setTimeout(() => {
        creatorRefStore.COTInputOutputNodes.current = previewData;
        creatorNodesStore.setNodes(inputs);
        projectPageStore.setSaasEdgesStaging(flowGroup);
        // setThinkLoading(false);
        if (typeof thinkValue === "string") {
          creatorRefStore.cotDescription.current = thinkValue;
        } else {
          const item = (webConfigState.cot.examples.find((li: any) => li.value === thinkValue?.option) || {}) as any;
          creatorRefStore.cotDescription.current = item?.label || "";
        }

        if (func.isEmpty(saasInputNodeIds)) {
          // util.messageUtil('Empty node');
          logEvent("empty_cot_result", previewData.templateValue);
          cotStore.setShowUnsupported(true);
          return;
        }
        setTimeout(() => {
          cotStore.setOldCotAnimationState({
            creating: true,
            inputIds: saasInputNodeIds,
            currentInputId: saasInputNodeIds[0],
          });
          // bsf-1919 重置状态
          cotStore.setCotAutomaticNode(true);
          creatorCotService.showAllInputs(saasInputNodeIds, inputs);
        }, 300);
      });
    },
    // eslint-disable-next-line
    []
  );

  const genUIStartFlow = useCallback(
    async (flow: any) => {
      const flowData = await cotFlow.transformTemplate(
        "",
        flow,
        canvasDataRef.nodeDataRef.current,
        canvasDataRef.newEdgeDataRef.current,
        canvasDataRef.newLineDataRef.current
      );
      const { nodes = [], edges = [], inputs = [], previewData } = flowData;
      creatorNodesStore.setNodes(nodes);
      creatorEdgesStore.setEdges(edges);
      creatorCotService.defaultOpenWebApp();
      const platforms = ["saas"];

      fitCanvas();
      // stud-2493 set input(preview)(clear)
      // Empty input and output first
      creatorSaasAppStore.setSaasUIData((prevData: any) => {
        return {
          ...prevData,
          output: [],
          input: [],
        };
      });
      projectNodeStore.composeNodes(creatorNodesStore.getNodes(), creatorSaasAppStore.state.saasUIData);

      if (previewData) {
        const { input = [], output = [] } = previewData;
        const uniqNodes = uniqBy(nodes, node => node.id);
        const inputNodes = uniqNodes.filter(node => input.map(item => `editor-${item}`).includes(node.id));
        const outPutNodes = uniqNodes.filter(node => output.map(item => `editor-${item}`).includes(node.id));
        const outPutGroupNodes = uniqNodes.filter(node => output.map(item => `group-${item}`).includes(node.id));

        const inputIds = store.getState().fot.inputId;
        const addInputs = inputs.map(x => x.id);
        const newInputId = [...inputIds, ...addInputs];
        creatorAISaasStore.setInputId(newInputId);

        platforms.forEach(platform => {
          const needAddInput = inputNodes.filter(node =>
            (creatorSaasAppStore.state.saasUIData?.input || []).every(input => input.id !== node.id)
          );
          needAddInput.forEach(input => {
            homeHandleFieldMethods.handleInputField(input, platform, creatorRefStore.cotDescription.current);
          });
          outPutNodes.forEach(node => creatorPreviewService.addIndividual(node, platform, true));
          outPutGroupNodes.forEach(node => creatorPreviewService.clickGroupToPreview(node, platform, true));
        });
        previewAppStore.openPreviewPage();
        previewAppStore.clearPreviewApp();
        previewAppStore.addPreview({ format: getDefaultPreviewAppTypeList()[0] });
      }
    },
    [startShowCotFlow]
  );

  const RenderCallerBot = useMemo(() => {
    if (
      featureTags.disableCallerBot === false &&
      showNavPageState.showNavPage === false &&
      homePluginState.showCallerBot
    ) {
      return <CallerBot showFlow={genUIStartFlow} />;
    }

    return null;
  }, [featureTags.disableCallerBot, showNavPageState.showNavPage, homePluginState.showCallerBot, genUIStartFlow]);

  /**
   * 此处渲染组件有几个规则和注意事项：
   *
   * 1. 根组件是一个空组件，为了兼容Homev1 和 homeV2 版本显示样式
   * 2. 渲染组件越靠后层级越高, 但是准确的控制层级关系是通过 data-creator dom 属性控制 `CreatorRoot.module`
   * 3. CreatorPublish, CreatorIdeaList, 疑似已弃用的功能
   */
  return (
    <>
      <CreatorCanvas />

      <CreatorToolBar />

      <CreatorRightMenu />

      <CreatorIdeaList />

      <CreatorFunctionPopup />

      <CreatorTermsPopup />

      {cotStoreState.showUnsupported && <UnSupportedGraph handleClose={closeUnsupported} />}

      {RenderCallerBot}

      <CreatorCodeModal />

      <TemplateModal />

      <CreatorHelp />

      <OneProjectOneApp />
    </>
  );
});
