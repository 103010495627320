"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Outputs = exports.BlueprintData = exports.Connection = exports.Piece = exports.Data = exports.Slot = exports.Component = exports.Output = exports.Input = exports.Description = exports.Blueprint = exports.WrapperInput = exports.BlueprintInfo = void 0;
const ClassTransformerDecorators_1 = require("../../cutil/ClassTransformerDecorators");
class BlueprintInfo {
    constructor() {
        this.blueprint = new Blueprint();
        this.blueprint_data = [];
        this.human_readable_description = "";
        this.id = "";
        this.inputs = [];
        this.name = "";
        this.outputs = new Outputs();
    }
}
exports.BlueprintInfo = BlueprintInfo;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Blueprint)
], BlueprintInfo.prototype, "blueprint", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => BlueprintData, [])
], BlueprintInfo.prototype, "blueprint_data", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => WrapperInput, [])
], BlueprintInfo.prototype, "inputs", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Outputs)
], BlueprintInfo.prototype, "outputs", void 0);
class WrapperInput {
    constructor() {
        this.description = "";
        this.name = "";
        this.type = "";
        this.value = "";
        this.user_input_id = "";
    }
}
exports.WrapperInput = WrapperInput;
class Blueprint {
    constructor() {
        this.description = new Description();
        this.edge_description = "";
        this.execution_message = "";
        this.id = "";
        this.inputs = [];
        this.intermediate_data = undefined;
        this.name = "";
        this.num_uses = 0;
        this.outputs = [];
        this.reason = "";
        this.slots = [];
        this.type = "";
        this.usage = [];
    }
}
exports.Blueprint = Blueprint;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Description)
], Blueprint.prototype, "description", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Input, [])
], Blueprint.prototype, "inputs", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Output, [])
], Blueprint.prototype, "outputs", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Slot, [])
], Blueprint.prototype, "slots", void 0);
class Description {
    constructor() {
        this.human = "";
        this.operational = "";
    }
}
exports.Description = Description;
class Input {
    constructor() {
        this.allow_custom_value = false;
        this.components = [];
        this.default_value = undefined;
        this.description = "";
        this.id = "";
        this.inputs = [];
        this.list = false;
        this.name = "";
        this.optional = false;
        this.reason = "";
        this.type = "";
        this.value = "";
    }
}
exports.Input = Input;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Component, [])
], Input.prototype, "components", void 0);
class Output {
    constructor() {
        this.allow_custom_value = false;
        this.components = [];
        this.default_value = undefined;
        this.description = "";
        this.id = "";
        this.inputs = [];
        this.list = false;
        this.name = "";
        this.optional = false;
        this.reason = "";
        this.type = "";
        this.value = undefined;
    }
}
exports.Output = Output;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Component, [])
], Output.prototype, "components", void 0);
class Component {
    constructor() {
        this.allow_custom_value = false;
        this.components = [];
        this.default_value = undefined;
        this.description = "";
        this.id = "";
        this.inputs = [];
        this.list = false;
        this.name = "";
        this.optional = false;
        this.reason = "";
        this.type = "";
        this.value = "";
    }
}
exports.Component = Component;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Component, [])
], Component.prototype, "components", void 0);
class Slot {
    constructor() {
        this.data = new Data();
        this.description = "";
        this.edge_description = "";
        this.execution_message = "";
        this.functionality_type = "";
        this.inputs = [];
        this.name = "";
        this.next_slots = [];
        this.outputs = [];
        this.piece = [];
        this.prev_slots = [];
        this.reason = "";
    }
}
exports.Slot = Slot;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Data)
], Slot.prototype, "data", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Input, [])
], Slot.prototype, "inputs", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Output, [])
], Slot.prototype, "outputs", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Piece, [])
], Slot.prototype, "piece", void 0);
class Data {
}
exports.Data = Data;
class Piece {
    constructor() {
        this.connection = new Connection();
        this.id = "";
        this.type = "";
    }
}
exports.Piece = Piece;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Connection)
], Piece.prototype, "connection", void 0);
class Connection {
    constructor() {
        this.inputs = [];
        this.outputs = [];
    }
}
exports.Connection = Connection;
class BlueprintData {
    constructor() {
        this.description = "";
        this.execution_message = "";
        this.io = "";
        this.name = "";
        this.piece_type = "";
        this.selected_toolpiece = "";
        this.type = "";
    }
}
exports.BlueprintData = BlueprintData;
class Outputs {
    constructor() {
        this.allow_custom_value = false;
        this.components = [];
        this.default_value = undefined;
        this.description = "";
        this.id = "";
        this.inputs = [];
        this.list = false;
        this.name = "";
        this.optional = false;
        this.reason = "";
        this.type = "";
        this.value = undefined;
    }
}
exports.Outputs = Outputs;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Component, [])
], Outputs.prototype, "components", void 0);
