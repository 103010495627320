import { PreviewTitle } from "./PreviewTitle";
import { useAiSaasInputContainer } from "../container";
import css from "./index.module.scss";
import { ProjectNodeUtil } from "@uikit/projectNode/ProjectNodeUtil";
import { useProjectNode } from "@uikit/projectNode/useProjectNode";

export function InputTitle(props: AISaas.AISaasInputItemTitleProps): JSX.Element {
  const { x } = props;
  const { id: itemId } = x.data;
  const projectNode = useProjectNode(x.id.toString());

  const {
    inputTitleId,
    showInputTitleId,
    onInputTitleBlur,
    onInputTitleEnter,
    onClickInputTitle,
    onSaasInputTitleChange,
    onClickInputTitleClose,
  } = useAiSaasInputContainer();

  // stud-2493 get input title(preview)
  // const inputTitle = x.data.inputTitle || x?.data?.generatedLabel || "User Input";
  const inputTitle = ProjectNodeUtil.getTitle(projectNode, "User Input");

  return (
    <div className={css["input-title-box"]}>
      <PreviewTitle
        id={itemId}
        title={inputTitle}
        isShare={true}
        inputTitleId={inputTitleId.value}
        onInputTitleBlur={onInputTitleBlur}
        onInputTitleEnter={onInputTitleEnter}
        onSaasInputTitleChange={onSaasInputTitleChange}
        onClickInputTitleClose={onClickInputTitleClose}
        onClickEdit={onClickInputTitle}
        showInputTitleId={showInputTitleId.value}
        saasUIInputData={x}
      />
    </div>
  );
}
