import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";

export class RunEdgeAddNodeToPreview {
  constructor(
    private creatorNodesStore: CreatorNodesStore,
    private creatorPreviewService: CreatorPreviewService,
    private previewAppStore: PreviewAppStore,
    private creatorRefStore: CreatorRefStore,
    private homeHandleFieldMethods: HomeHandleFieldMethods,
    private creatorAISaasStore: CreatorAISaasStore
  ) {}

  //  第一次运行边，自动添加node到input和output
  firstRunAddNodeToPreview({ sourceNode, outputNode }: any): void {
    if (!this.creatorRefStore.firstRunEdgeRef.current) return;
    this.creatorRefStore.firstRunEdgeRef.current = false;
    this.previewAppStore.setShowPreviewPanel(true);
    if (Array.isArray(sourceNode)) {
      const inputIdArr: any[] = [];
      sourceNode.forEach(async item => {
        inputIdArr.push(item.id);
        await this.homeHandleFieldMethods.handleInputField(item);
      });
      this.creatorAISaasStore.setInputId(inputIdArr);
    } else {
      this.homeHandleFieldMethods.handleInputField(sourceNode);
      this.creatorAISaasStore.setInputId(sourceNode.id);
    }
    if (CreatorNodesConsts.GROUPID_REG.test(outputNode?.id)) {
      this.creatorPreviewService.clickGroupToPreview(outputNode);
    } else {
      this.creatorPreviewService.addIndividual(outputNode);
    }
  }

  //  如果第一次运行边点的是runAll就执行这个
  firstRunAllAddNodeToPreview(edgeId: any): void {
    const nodes = this.creatorNodesStore.getNodes();
    const edgeNode = nodes.find(item => item.id === edgeId);
    const inputNodeIdArr = edgeNode?.data?.flows?.map((item: any) => item.sourceNodeId);
    const outputNodeId = edgeNode?.data?.targetNodeId;
    const inputNodeArr: any[] = [];
    const outputNode = nodes.find(item => item.id === outputNodeId);
    inputNodeIdArr?.forEach((inputId: any) => {
      inputNodeArr.push(nodes.find(item => item.id === inputId));
    });
    this.firstRunAddNodeToPreview({ sourceNode: inputNodeArr, outputNode });
  }
}
