"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnSubscriber = exports.ProductSearchPrice = exports.ProductSearch = exports.SubscriptionPrice = void 0;
class MetadataStadios {
    constructor() {
        this.type = "";
        this.userId = "";
        this.uuid = "";
    }
}
class Recurring {
    constructor() {
        this.interval = "";
        this.usage_type = "";
        this.interval_count = 0;
    }
}
class SubscriptionPrice {
    constructor() {
        this.id = "";
        this.metadata = new MetadataStadios();
        this.recurring = new Recurring();
        this.unit_amount = 0;
    }
}
exports.SubscriptionPrice = SubscriptionPrice;
class ProductSearchData {
    constructor() {
        this.id = "";
        this.name = "";
    }
}
class ProductSearch {
    constructor() {
        this.object = "";
        this.data = [];
    }
}
exports.ProductSearch = ProductSearch;
class ProductSearchPriceData {
    constructor() {
        this.id = "";
        this.object = "";
        this.active = false;
        this.billing_scheme = "";
        this.created = 0;
    }
}
class ProductSearchPrice {
    constructor() {
        this.object = "";
        this.data = [];
    }
}
exports.ProductSearchPrice = ProductSearchPrice;
class UnSubscriber {
    constructor() {
        this.id = "";
        this.customer = "";
        this.return_url = "";
        this.url = "";
    }
}
exports.UnSubscriber = UnSubscriber;
