import { StudioProjectAttributesV3Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Node";
import { NewEdgeData, OldEdgeData } from "@uikit/project/HomeUsePluginData";
import { UploadTypeArray } from "@uiview/views/Nodes/typing";
import { StudioProjectV3EdgeFlow } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { StudioProjectAttributesV3NodeData } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3NodeData";
import { DispatchType } from "@uikit/store/CreatorNodesStore";
import { Node } from "reactflow";
export class CustomNewEdgeUtil {
  /// 获取节点
  static getCurrNode(
    id: string,
    edgeData: NewEdgeData & OldEdgeData,
    getNodes: () => any[]
  ): StudioProjectAttributesV3Node | undefined {
    const nodes: StudioProjectAttributesV3Node[] = getNodes() as StudioProjectAttributesV3Node[];
    return nodes.find((x: StudioProjectAttributesV3Node) => x.id === id);
  }

  /// 子节点
  static getChildNodes(
    parentNodeId: string,
    edgeData: NewEdgeData & OldEdgeData,
    getNodes: () => any[]
  ): StudioProjectAttributesV3Node[] {
    const nodes: StudioProjectAttributesV3Node[] = getNodes() as StudioProjectAttributesV3Node[];
    return nodes.filter((x: StudioProjectAttributesV3Node) => x.parentNode === parentNodeId);
  }

  /// 获取source节点id
  static getSourceNodeId(edgeData: NewEdgeData & OldEdgeData): string {
    const flowsData: StudioProjectV3EdgeFlow = edgeData.flows.find(x => x?.creationMethod) || edgeData.flows[0] || {};
    return flowsData?.sourceNodeId || "";
  }

  static getSourceNode(edgeData: NewEdgeData & OldEdgeData, getNodes: () => any[]): any {
    const sourceNode = getNodes().find(x => x.id === CustomNewEdgeUtil.getSourceNodeId(edgeData));
    return sourceNode;
  }

  static getSourceNodeText(edgeData: NewEdgeData & OldEdgeData, getNodes: () => any[]): string {
    const sourceNode = this.getSourceNode(edgeData, getNodes);
    return (sourceNode?.data as StudioProjectAttributesV3NodeData).textAreaValue as string;
  }

  static getSourceNodeDisplayType(edgeData: NewEdgeData & OldEdgeData, getNodes: () => any[]): string {
    const sourceNode = CustomNewEdgeUtil.getSourceNode(edgeData, getNodes);
    if (sourceNode?.type === "customGroup") {
      return sourceNode?.type;
    }
    const sourceNodeData = sourceNode?.data as any;
    return sourceNodeData?.displayType || "";
  }

  /// 获取 group 节点的直接点类型
  static getSubSourceNodeDisplayType(edgeData: NewEdgeData & OldEdgeData, getNodes: () => any[]): string {
    const sourceNode = CustomNewEdgeUtil.getSourceNode(edgeData, getNodes);
    if (sourceNode?.type === "customGroup") {
      const childNodes: string[] = (sourceNode.data as any).childNode ?? [];
      const nodeId = childNodes.first() || "";
      const nodes: StudioProjectAttributesV3Node[] = getNodes() as StudioProjectAttributesV3Node[];
      const childNode = nodes.find((x: StudioProjectAttributesV3Node) => x.id === nodeId);
      return (childNode?.data as any).displayType || "";
    }
    return "";
  }

  /// source节点是不是upload file类型
  static isUploadType(
    edgeData: NewEdgeData & OldEdgeData,
    uploadTypes: UploadTypeArray,
    getNodes: () => any[]
  ): boolean {
    const sourceId = CustomNewEdgeUtil.getSourceNodeId(edgeData);
    const currentSourceNode = CustomNewEdgeUtil.getCurrNode(sourceId, edgeData, getNodes);
    const sourceNodeData = currentSourceNode?.data as any;
    return uploadTypes.includes(sourceNodeData?.displayType);
  }

  /// 更新LineParam数据
  static updateEdgeLineParam(
    edgeData: NewEdgeData & OldEdgeData,
    edgeId: string,
    setNodes: (value: DispatchType<Node<any>[]>) => void,
    lineParam: any
  ): void {
    setNodes((prevList: any) => {
      return prevList.map((x: any) => {
        if (x.id === edgeId) {
          return {
            ...x,
            data: {
              ...x.data,
              lineParam: {
                ...x.data.lineParam,
                ...lineParam,
              },
            },
          };
        }
        return x;
      });
    });
  }

  /// 根据边上的文字判断是否是普通文本
  static isNormalEdgeByEdgeText(text: string): boolean {
    return text.indexOf("/") === -1 && text.indexOf("$") === -1;
  }
}
