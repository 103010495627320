"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseHandlerRegistry = void 0;
const FunctionBase_1 = require("../../../storyV2/function/FunctionBase");
const WebsocketResponse_1 = require("../../../storyV2/model/WebsocketResponse");
const GlobalClient_1 = require("../../../cutil/GlobalClient");
class ResponseHandlerRegistry {
    constructor() {
        this.map = {};
        this.mapCreateNewHandler = {};
    }
    register(handler, name) {
        this.map[name] = handler;
    }
    registerCreateNewHandler(handler, name) {
        this.mapCreateNewHandler[name] = handler;
    }
    getHandlerByName(name) {
        let handler = this.map[name];
        if (!handler) {
            handler = this.mapCreateNewHandler[name]?.();
        }
        if (!handler) {
            // 未注册，那么取默认Handler处理
            handler = this.mapCreateNewHandler[FunctionBase_1.FuncDataSourceType.DEFAULT]?.();
        }
        return handler;
    }
    getByResponse(res) {
        if (res instanceof WebsocketResponse_1.WebsocketResponse) {
            return this.getHandlerByName(FunctionBase_1.FuncDataSourceType.CUSTOMAPI);
        }
        else if (res instanceof GlobalClient_1.GlobalResponse) {
            return this.getHandlerByName(FunctionBase_1.FuncDataSourceType.CUSTOMAPI);
        }
        else {
            return this.getHandlerByName(res.type);
        }
    }
}
exports.ResponseHandlerRegistry = ResponseHandlerRegistry;
