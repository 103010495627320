import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class PanelValue {
  pointerEvents: "all" | "none" = "all";
}

export class FotReactFlowPanelStore extends StoreBase<PanelValue> {
  constructor() {
    super(() => new PanelValue());
  }

  changePanel(background: PanelValue): void {
    const value = JsonUtil.toModelFromType(PanelValue, background);
    value && this.emit(value);
  }
}

export const fotReactFlowPanelStore = new FotReactFlowPanelStore();
