"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputNode = exports.UpdateEdgeResponse = void 0;
class UpdateEdgeResponse {
    constructor() {
        this.edge_description = "";
        this.input_nodes = [];
    }
}
exports.UpdateEdgeResponse = UpdateEdgeResponse;
class InputNode {
}
exports.InputNode = InputNode;
