"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomApiTransfromService = void 0;
const ChainRequest_1 = require("../../../storyV2/response/customApiHandler/chain/ChainRequest");
const AskBrainHandler_1 = require("../../../storyV2/response/customApiHandler/handler/AskBrainHandler");
const BlobHandler_1 = require("../../../storyV2/response/customApiHandler/handler/BlobHandler");
const UrlHandler_1 = require("../../../storyV2/response/customApiHandler/handler/UrlHandler");
const StreamHandler_1 = require("../../../storyV2/response/customApiHandler/handler/StreamHandler");
const JsonHandler_1 = require("../../../storyV2/response/customApiHandler/handler/JsonHandler");
const TransformServiceHandlerChain_1 = require("../../../storyV2/response/customApiHandler/chain/TransformServiceHandlerChain");
const CustomApiTransformUtil_1 = require("../../../storyV2/response/customApiHandler/util/CustomApiTransformUtil");
const GlobalClient_1 = require("../../../cutil/GlobalClient");
const WSHandler_1 = require("../../../storyV2/response/customApiHandler/handler/WSHandler");
/**
 * 用于处理custom api 的返回结果类型 GlobalResponse | WebsocketResponse
 */
class CustomApiTransfromService {
    constructor(brainClient) {
        this.brainClient = brainClient;
    }
    typeTransform(res) {
        if (res instanceof GlobalClient_1.GlobalResponse) {
            const displayType = CustomApiTransformUtil_1.CustomApiTransformUtil.getDisplayTypeByHeaders(res.headers);
            return displayType;
        }
        else {
            return res.displayType;
        }
    }
    async contentTransform(query, res) {
        const chainRequest = new ChainRequest_1.ChainRequest(query, res);
        const chain = new TransformServiceHandlerChain_1.TransformServiceHandlerChain();
        chain.addHandler(new WSHandler_1.WSHandler(this.brainClient));
        chain.addHandler(new AskBrainHandler_1.AskBrainHandler());
        chain.addHandler(new UrlHandler_1.UrlHandler(this.brainClient));
        chain.addHandler(new BlobHandler_1.BlobHandler(this.brainClient));
        chain.addHandler(new StreamHandler_1.StreamHandler());
        chain.addHandler(new JsonHandler_1.JsonHandler());
        await chain.process(chainRequest);
        return { result: chainRequest.getResult(), isEnded: chainRequest.isEnded };
    }
}
exports.CustomApiTransfromService = CustomApiTransfromService;
