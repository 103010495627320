import { signal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import store from "@store/index";
import func from "@uikit/func";
import { getQueryParams } from "@views/thinking-layout-editor/util";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { UniqueIdentifier } from "@dnd-kit/core";

export enum ChargeOption {
  FREE = "Free",
  SUBSCRIPTION = "Subscription",
}

class ChargeYourCustomersBlocState {
  currentSelectOption = signal("");

  isAgreementChecked = signal<boolean>(false);
  selectedChargeOption = signal<ChargeOption>(ChargeOption.FREE);

  price = signal<string>("");
  trialPeriod = signal<string>("");
  copyright = signal<string>("");

  termsLink = signal<string>("");
  privacyLink = signal<string>("");
  removeCheckBox = signal<boolean>(false);
}

export class ChargeYourCustomersBloc {
  state = new ChargeYourCustomersBlocState();

  subscriptionStore: PublishSubscriptionStore = getIt(PublishSubscriptionStore);

  brainClient = getIt(BrainClient);
  urlParams: any = getQueryParams();

  get selectedTemplate(): any {
    return store.getState().studio.selectedTemplate;
  }

  toggleAgreement(checked: boolean) {
    this.state.isAgreementChecked.value = checked;
    setTimeout(() => {
      this.state.removeCheckBox.value = true;
    }, 1000);
  }

  updatePrice(value: string) {
    this.state.price.value = value;
  }

  updateTrialPeriod(value: string) {
    this.state.trialPeriod.value = value;
  }

  // updateCopyright(value: string) {
  //   this.state.copyright.value = value;
  // }

  updateTermsLink(value: string) {
    this.state.termsLink.value = value;
  }
  updatePrivacyLink(value: string) {
    this.state.privacyLink.value = value;
  }

  clickFree() {
    this.state.selectedChargeOption.value = ChargeOption.FREE;
  }

  clickSubscription() {
    this.state.selectedChargeOption.value = ChargeOption.SUBSCRIPTION;
  }

  async loadStripLink(appId: UniqueIdentifier): Promise<string> {
    const res = await tryPromise(
      this.subscriptionStore.loadStripInfo(this.urlParams?.type, this.selectedTemplate.index, appId.toString())
    );
    if (res.error) {
      func.messageUtil(res.error || "Request failed.", "error");
    }
    return res.data || "";
  }
}
