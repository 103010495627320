import { getIt } from "@uikit/getIt";
import { BluePrintUtil } from "@uiview/views/BluePrint/BluePrintUtil";
import { BLUEPRINT } from "@uiview/views/BluePrint/CreateAGenerativeFunction";
import { BlueprintInfo } from "imagica-corekit/dist/base/api/blueprintTyped/blueprintInfo";
import { BlueprintFunctionType } from "imagica-corekit/dist/base/storyV2/function/BlueprintFunction";
import { BlueprintService } from "imagica-corekit/dist/cases/blueprint/BlueprintService";
import { Node } from "reactflow";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";

export type ExecuteProps = {
  id: string;
  aiDescription: string;
  blueprintInfo: BlueprintInfo;
  nodeIndexRef: React.MutableRefObject<number>;
  edgeIndexRef: React.MutableRefObject<number>;
  nodeDataRef: React.MutableRefObject<{
    [key: string]: any;
  }>;
  newLineDataRef: React.MutableRefObject<{
    [key: string]: any;
  }>;
  nodes: Node<any>[];
  edges: Node<any>[];
};

export class ExecuteBlueprintUtil {
  static execute(props: ExecuteProps, callback: (value: object) => void): void {
    // FIXME: 依赖的 store 需要从函数参数传入
    const blueprintService = getIt(BlueprintService);
    const creatorNodesStore = getIt(CreatorNodesStore);
    const creatorEdgesStore = getIt(CreatorEdgesStore);
    const projectNodeStore = getIt(ProjectNodeStore);

    const result = blueprintService.generateInputs({
      id: props.id,
      nodes: props.nodes,
      edges: props.edges as any[],
      inputs: props.blueprintInfo?.inputs,
      nodeIndexRef: props.nodeIndexRef,
      edgeIndexRef: props.edgeIndexRef,
      newLineDataRef: props.newLineDataRef,
      nodeDataRef: props.nodeDataRef,
    });
    if (result === null) {
      return;
    }

    creatorEdgesStore.setEdges(result.edges);
    creatorNodesStore.setNodes(result.nodes);
    const inputs = BluePrintUtil.findTargetNodes(props.id, props.nodes, projectNodeStore);

    callback({
      type: BLUEPRINT,
      label: props.aiDescription,
      blueJson: JSON.stringify({
        id: props.blueprintInfo?.id,
        inputs,
        functionType: props.blueprintInfo.functionType || BlueprintFunctionType.BLUEPRINTS_V2,
      }),
    });
  }
}
