"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnknownFunction = void 0;
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
class UnknownFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo) {
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.TEXT, input);
    }
}
exports.UnknownFunction = UnknownFunction;
