import { Story } from "imagica-corekit/dist/base/storyV2/domain/Story";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { MessageCenter } from "imagica-corekit/dist/base/kernel/MessageCenter";
import { StoryNode } from "imagica-corekit/dist/base/storyV2/domain/StoryNode";
import { StoryEdge } from "imagica-corekit/dist/base/storyV2/domain/StoryEdge";
import { getIt } from "@uikit/getIt";

export class CorebeUtil {
  static async runByStream(story: Story) {
    const brainClient: BrainClient = getIt(BrainClient);
    const edges = story.findAllEdges();
    const nodes = story.findAllNodes();
    const res = await brainClient.corebe.storyRun(story.projectId);
    res.onReceived((data: any) => {
      if (data.done) return;

      const node = nodes.find((node: StoryNode) => node.id === data.id);
      if (node) {
        node.state = data.state;
        node.content = data.content;
        story.emit(nodes);
      } else {
        const edge = edges.find((edge: StoryEdge) => edge.options.id === data.id);
        if (edge) {
          edge.state = data.state;
        }
        story.emit(edges);
      }
    });
  }

  static async runBySocket(story: Story) {
    const brainClient = getIt(BrainClient);
    const meStore = getIt(MeStore);
    const me = await meStore.get();
    let messageCenter = getIt(MessageCenter);
    const topic = `corebe/${me.id}/${story.projectId}`;
    console.log("查询参数", story.projectId, topic);
    const edges = story.findAllEdges();
    const nodes = story.findAllNodes();
    messageCenter.on(topic, (data: any) => {
      console.log("查看接到的数据", data);
      const node = nodes.find((node: StoryNode) => node.id === data.id);
      if (node) {
        node.state = data.state;
        node.content = data.content;
        story.emit(nodes);
      } else {
        const edge = edges.find((edge: StoryEdge) => edge.options.id === data.id);
        if (edge) {
          edge.state = data.state;
        }
        story.emit(edges);
      }
    });

    await brainClient.corebe.storyRunBySocket(story.projectId, topic);
  }
}
