import { useSignal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { ProjectNodeStore } from "./ProjectNodeStore";
import { useEffect } from "react";
import { ProjectNode } from "./model";
import { isEqual } from "lodash";

/**
 * 该 hook 仅用于需要绑定 projectNode 数据的组件
 * @param id
 * @returns
 */
export function useProjectNode(id: string): ProjectNode | undefined {
  const projectNodeStore = getIt(ProjectNodeStore);
  const projectNode = useSignal(projectNodeStore.getNode(id));

  useEffect(() => {
    projectNode.value = projectNodeStore.getNode(id);

    const subscription = projectNodeStore.on(() => {
      const newNode = projectNodeStore.getNode(id);
      if (!isEqual(newNode, projectNode.value)) {
        // if (newNode !== projectNode.value) {
        projectNode.value = newNode;
      }
      // !!! 同步，不能使用 setTimeout 等异步行为
    }, true);

    return () => {
      subscription.off();
    };
    // eslint-disable-next-line
  }, [id]);

  return projectNode.value;
}
