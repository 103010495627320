/* eslint-disable no-template-curly-in-string */
import func from "@uikit/func";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { CUSTOM_FUNCTION_VARS_JS_REG, CUSTOM_FUNCTION_VARS_REG } from "@views/thinking-layout-editor/constants";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { CustomApiVariable } from "imagica-corekit/dist/cases/util/CustomApiUtil";

type VarInjectResult = {
  url: any;
  method: any;
  headerList: any;
  bodyParam: any;
};

/**
 * 原来 HomeRunUtil 方法
 */
export class EdgeRunVariable {
  constructor(private fotAuthStore: FotAuthStore, private fotReduxStore: FotReduxStore) {}
  getHtmlStr(currentSingleFlow: any, currentSourceNodeValue: any): string {
    const variableList = this.fotReduxStore.getState().fot.variableList as any[];
    const brainAuthToken = this.fotAuthStore.state.brainToken;

    let cssValue = currentSingleFlow?.codeData?.cssStr || "";
    let htmlValue = currentSingleFlow?.codeData?.htmlStr || "";
    let jsValue = currentSingleFlow?.codeData?.jsStr || "";
    if (
      currentSourceNodeValue instanceof Object &&
      // currentSourceNodeValue.hasOwnProperty("data") &&
      Object.hasOwnProperty.call(currentSourceNodeValue, "data") &&
      !func.isEmpty(currentSourceNodeValue.data.value)
    ) {
      currentSourceNodeValue = JSON.stringify(currentSourceNodeValue.data.value);
    }
    htmlValue = htmlValue.replaceAll(CUSTOM_FUNCTION_VARS_REG, (match: string) => {
      if (match === "${currentSourceNodeValue}") return currentSourceNodeValue;
      if (match === "${brainAuthToken}") return brainAuthToken;
    });
    jsValue = jsValue.replaceAll(CUSTOM_FUNCTION_VARS_JS_REG, (match: string) => {
      // if (match === 'currentSourceNodeValue') return `'${currentSourceNodeValue}'`
      if (match === "currentSourceNodeValue") return JSON.stringify(currentSourceNodeValue);
      if (match === "brainAuthToken") return `'${brainAuthToken}'`;
      return match;
    });
    variableList.map(x => {
      htmlValue = htmlValue.replaceAll("${" + x.name + "}", x?.node?.data?.textAreaValue);
      cssValue = cssValue.replaceAll("${" + x.name + "}", x?.node?.data?.textAreaValue);
      jsValue = jsValue.replaceAll(x.name, JSON.stringify(x?.node?.data?.textAreaValue));
      return x;
    });
    const lineFeed = htmlValue.match(/^<html>/) ? "" : "white-space: pre-wrap;";
    return `<html>
              <style>${cssValue}</style>
              <body style="${lineFeed}">${htmlValue}</body>
              <script>{${jsValue}}</script>
            </html>`;
  }

  getVariableList(currentSourceNodeValue: any): CustomApiVariable[] {
    const variableList = this.fotReduxStore.getState().fot.variableList;
    const brainAuthToken = this.fotAuthStore.state.brainToken;

    return [
      {
        name: "currentSourceNodeValue",
        node: {
          data: {
            textAreaValue: currentSourceNodeValue,
          },
        },
      },
      {
        name: "brainAuthToken",
        node: {
          data: {
            textAreaValue: brainAuthToken,
          },
        },
      },
      ...variableList,
    ];
  }
  evaluateFinalBodyStr(currentSourceNodeValue: any, bodyParam: any): any {
    if (func.isEmpty(bodyParam)) {
      return "";
    }
    // 注入currentSourceNodeValue
    const variableList = this.getVariableList(currentSourceNodeValue);

    // 替换body变量
    // bodyParam can either be a string or object, if JSON.parse(bodyParam) works then it's an object otherwise string
    let bodyStr = bodyParam;
    if (typeof bodyParam === "object") {
      bodyStr = JSON.stringify(bodyParam);
    }
    // trim is moved here as part of post processing
    bodyStr = bodyStr.trim();
    const varFormatReg = /\${\w+\}/g;
    variableList.forEach(y => {
      // eslint-disable-next-line no-useless-escape
      const varReg = new RegExp(`\\\$\\\{${y.name}\\\}`, "g");
      bodyStr = bodyStr && bodyStr.replace(varReg, y.node.data.textAreaValue);
    });
    // 将剩下的没有的变量全部替换为空字符串
    bodyStr =
      bodyStr &&
      bodyStr
        .replace(varFormatReg, "")
        .replace(/\n/g, "")
        .replace(/(\r\n|\n|\r)/g, "")
        .replace(/^"/, "")
        .replace(/"$/, "");
    return bodyStr;
  }

  handleVarInject(currentSourceNodeValue: any, requestData: any): VarInjectResult | undefined {
    let { url, headerList, bodyParam = {} } = requestData;
    // 注入currentSourceNodeValue
    // currentSourceNode value is not always string, stringify it if it's not
    if (typeof currentSourceNodeValue !== "string") {
      currentSourceNodeValue = JSON.stringify(currentSourceNodeValue) || "";
    }
    const variableList = this.getVariableList(currentSourceNodeValue);
    // 替换url
    const varFormatReg = /\${\w+\}/g;
    variableList.forEach(y => {
      // eslint-disable-next-line no-useless-escape
      const varReg = new RegExp(`\\\$\\\{${y.name}\\\}`, "g");
      url = url.replace(varReg, y.node.data.textAreaValue);
    });
    url = url.replace(varFormatReg, "");
    // replace body变量
    // finalized body string must be derived from this function, DO NOT MAKE CHANGES before discussing with @David
    const bodyStr = this.evaluateFinalBodyStr(currentSourceNodeValue, bodyParam);
    try {
      bodyParam = func.isEmpty(bodyStr) ? null : JSON.parse(bodyStr);
    } catch (error) {
      console.error(error);
      func.messageUtil("Incorrect JSON format in request body");
      return;
    }
    // 替换header变量
    headerList = headerList.map((x: any) => {
      let { name, value, description } = x;
      variableList.forEach(y => {
        // eslint-disable-next-line no-useless-escape
        const varReg = new RegExp(`\\\$\\\{${y.name}\\\}`, "g");
        name = name?.replace(varReg, y.node.data.textAreaValue);
        value = value?.replace(varReg, y.node.data.textAreaValue);
        description = description.replace(varReg, y.node.data.textAreaValue);
      });
      // 将剩下的没有的变量全部替换为空字符串
      name = name?.replace(varFormatReg, "");
      value = value?.replace(varFormatReg, "");
      description = description.replace(varFormatReg, "");

      return {
        ...x,
        name,
        value,
        description,
      };
    });

    return {
      url,
      method: requestData.method,
      headerList,
      bodyParam,
    };
  }
}
