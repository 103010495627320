import { Button, Modal } from "antd";
import styles from "./Unpublish.module.scss";
import { UnpublishBloc } from "./UnpublishBloc";
import { ExclamationCircleOutlined } from "@ant-design/icons";

type UnpublishModalProps = {
  bloc: UnpublishBloc;
  handleOk: () => void;
};

const CLOSE_ICON = "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ACAEB5Cross.svg";
const TITLE = "Are you sure you want to Unpublish?";
// const SUBTITLE = "This will delete this publish format from your project. You can not undo this action.";
const SUBTITLE =
  "This app is being unpublished by its creator. You will still have access until the end of your monthly subscription period.";

export function UnpublishModal(props: UnpublishModalProps) {
  return (
    <div className={styles.modalRoot}>
      <Modal
        open={props.bloc.state.showModal.value}
        wrapClassName={styles.modalWrap}
        width="400px"
        centered={true}
        closeIcon={<img src={CLOSE_ICON} width="20" height="20" alt="close" />}
        footer={null}
        onOk={props.handleOk}
        onCancel={() => props.bloc.handleCancel()}
      >
        <ExclamationCircleOutlined className={styles.modalIcon} />
        <span className={styles.modalTitle}>{TITLE}</span>
        {props.bloc.state.showSubTitle.value && <p className={styles.modalSubtitle}>{SUBTITLE}</p>}
        <div className={styles.modalFooter}>
          <Button
            data-testid="Preview-Settings-Unpublish-Cancel"
            shape="round"
            size="large"
            type="default"
            className={styles.modalButtonNormal}
            onClick={() => props.bloc.handleCancel()}
          >
            Cancel
          </Button>
          <Button
            data-testid="Preview-Settings-Unpublish-Confirm"
            shape="round"
            size="large"
            className={styles.modalButtonDanger}
            loading={props.bloc.state.unpublishLoading.value}
            onClick={props.handleOk}
          >
            Unpublish
          </Button>
        </div>
      </Modal>
    </div>
  );
}
