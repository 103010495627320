"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorUtil = void 0;
const RestClient_1 = require("../cutil/RestClient");
const Extensions_1 = require("../cutil/Extensions");
const ResponseUtil_1 = require("../cutil/ResponseUtil");
class ErrorUtil {
    static parseRestResponse(response) {
        let errorData = response.data;
        if (errorData instanceof Response && !(0, Extensions_1.isBlank)(ResponseUtil_1.ResponseUtil.unwrapJson(errorData))) {
            errorData = ResponseUtil_1.ResponseUtil.unwrapJson(errorData);
        }
        const handleInfo = ErrorUtil.parseErrorData(errorData);
        if (handleInfo.handle) {
            return handleInfo.result;
        }
        return response.message;
    }
    static parseErrorData(errorData) {
        if (typeof errorData === "string") {
            try {
                const tmp = JSON.parse(errorData);
                errorData = tmp;
            }
            catch (_) { }
        }
        if (typeof errorData === "string") {
            return { handle: true, result: errorData };
        }
        else if (errorData instanceof Object) {
            const result = errorData?.detail ||
                errorData?.message?.identifier_type ||
                errorData?.error?.message ||
                errorData?.message ||
                errorData?.non_field_errors?.[0];
            return { handle: true, result: result };
        }
        return { handle: false, result: undefined };
    }
    static isAbortError(error) {
        return error instanceof RestClient_1.RestInternalError && error.error?.message?.includes("The user aborted a request.");
    }
}
exports.ErrorUtil = ErrorUtil;
ErrorUtil.defaultErrorMsg = "Something went wrong. Please try again.";
ErrorUtil.abortErrorName = "AbortError";
