import styles from "./AlertView.module.scss";
import { JSX } from "react";
export function AlertView(props: { onCancel: () => void; onConfirm: () => void }): JSX.Element {
  return (
    <div className={styles.content}>
      <div className={styles.title}>Are you sure?</div>
      <div className={styles.message}>
        There is an ongoing domain validation. Are you sure you want to start over with the updated domain?
      </div>
      <div className={styles.bottomContainer}>
        <button className={styles.cancel} onClick={() => props.onCancel()}>
          Cancel
        </button>
        <button className={styles.confirm} onClick={() => props.onConfirm()}>
          Confirm
        </button>
      </div>
    </div>
  );
}
