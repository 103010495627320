export class AISaasInputConverter {
  static toSoratbleList(saasUIInputData: any[]): SortableList.SortableData<AISaas.SaasUIInputType>[] {
    return saasUIInputData.map(item => ({
      id: item.id,
      data: item,
    }));
  }
  static toSaasUIInputData(sortableList: SortableList.SortableData<AISaas.SaasUIInputType>[]) {
    return sortableList.map(item => item.data);
  }
}
