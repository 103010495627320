"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class GTheme {
}
exports.GTheme = GTheme;
GTheme.theme = {
    chatAiTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.chatAiTheme,
        chatBackground: "linear-gradient(180deg, #D9E7F1 0%, #D9E7F1 100%)",
        chatBackgroundPad: "linear-gradient(180deg, #D9E7F1 0%, #D9E7F1 100%)",
        chatBackgroundMobile: "linear-gradient(180deg, #D9E7F1 0%, #D9E7F1 100%)",
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBarBorderColor: "transparent",
        hightLightBorderLeft: "0",
        inputPaddingLeft: "25px",
        inputPadding: "15px 25px",
        inputBorder: "1px solid rgba(18, 25, 43, 0.10)",
        inputBorderBottom: "1px solid rgba(18, 25, 43, 0.10)",
        inputBorderRadius: "30px",
        inputBackground: "transparent",
        toolTipBackground: "#F98250",
        hintTextColor: "#12192B",
        tooltipBoxColor: "#12192B",
        seeFormatDescriptionColor: "#12192B",
        valueColor: "#fff",
    },
};
