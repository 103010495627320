"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterDataService = void 0;
const CardDataSeriviceRegistry_1 = require("../cases/cardDataSearch/dataSerivce/CardDataSeriviceRegistry");
const YoutubeSearchResultsDataService_1 = require("../cases/cardDataSearch/dataSerivce/YoutubeSearchResultsDataService");
const FunctionRegistry_1 = require("../base/storyV2/function/FunctionRegistry");
const ComponentId_1 = require("../base/api/viewBuilderTyped/VBGraphData/ComponentId");
const YoutubeVideoDataService_1 = require("../cases/cardDataSearch/dataSerivce/YoutubeVideoDataService");
const ShoppingProductDataService_1 = require("../cases/cardDataSearch/dataSerivce/ShoppingProductDataService");
const RestaurantRecommendationDataService_1 = require("../cases/cardDataSearch/dataSerivce/RestaurantRecommendationDataService");
const ShoppingSearchResultDataService_1 = require("../cases/cardDataSearch/dataSerivce/ShoppingSearchResultDataService");
const DishRecommendationDataService_1 = require("../cases/cardDataSearch/dataSerivce/DishRecommendationDataService");
const FotClient_1 = require("../base/api/FotClient");
class RegisterDataService {
    static configure(container) {
        const it = (serviceIdentifier) => {
            return container.get(serviceIdentifier);
        };
        const dataServiceRegistry = it(CardDataSeriviceRegistry_1.CardDataServiceRegistry);
        dataServiceRegistry.registry(ComponentId_1.ComponentId.YoutubeSearchResults, () => {
            return new YoutubeSearchResultsDataService_1.YoutubeSearchResultsDataService(it(FunctionRegistry_1.FunctionRegistry));
        });
        dataServiceRegistry.registry(ComponentId_1.ComponentId.YoutubeVideo, () => {
            return new YoutubeVideoDataService_1.YoutubeVideoDataService(it(FunctionRegistry_1.FunctionRegistry));
        });
        dataServiceRegistry.registry(ComponentId_1.ComponentId.ShoppingProduct, () => {
            return new ShoppingProductDataService_1.ShoppingProductDataService(it(FunctionRegistry_1.FunctionRegistry));
        });
        dataServiceRegistry.registry(ComponentId_1.ComponentId.ShoppingSearchResults, () => {
            return new ShoppingSearchResultDataService_1.ShoppingSearchResultDataService(it(FunctionRegistry_1.FunctionRegistry));
        });
        dataServiceRegistry.registry(ComponentId_1.ComponentId.RestaurantRecommendation, () => {
            return new RestaurantRecommendationDataService_1.RestaurantRecommendationDataService(it(FunctionRegistry_1.FunctionRegistry));
        });
        dataServiceRegistry.registry(ComponentId_1.ComponentId.DishRecommendation, () => {
            return new DishRecommendationDataService_1.DishRecommendationDataService(it(FunctionRegistry_1.FunctionRegistry), it(FotClient_1.FotClient));
        });
    }
}
exports.RegisterDataService = RegisterDataService;
