import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Spin } from "antd";
import style from "../components-css/SharesBuy.module.css";
import func from "@uikit/func";
import { Api } from "../../uikit/service/api";
import { fotActions } from "../../store/fot";
import { AnimatePresence, motion } from "framer-motion";

function SharesBuy(props) {
  const api = new Api(false);
  const dispatch = useDispatch();

  const editorIdArr = useSelector(state => state.fot.editorIdArr);
  const setEditorIdArr = val => {
    dispatch(fotActions.setEditorIdArr(val));
  };
  const setSaasEditorId = val => {
    dispatch(fotActions.setSaasEditorId(val));
  };

  const data = editorIdArr.find(x => x.editorId === props.editorId);
  const stockData = data?.content;
  const userInputPrice = data?.userInputPrice ?? 0;
  const price = stockData?.price?.match(/\d+(\.\d+)?/g)[0];
  const buyValueRef = useRef(null);
  const [sharesPage, setSharesPage] = useState("");
  const [buyValue, setBuyValue] = useState(userInputPrice);
  const [checkInput, setCheckInput] = useState(true);

  const container = {
    init: {
      opacity: 0,
      y: "100%",
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    hide: {
      opacity: 0,
      y: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };
  const loadingAnimate = {
    init: {
      opacity: 0,
      marginTop: "300px",
    },
    show: {
      opacity: 1,
      marginTop: 0,
      transition: {
        duration: 0.3,
      },
    },
    hide: {
      opacity: 0,
    },
  };
  const confirmAnimate = {
    init: {
      marginTop: "-800px",
    },
    show: {
      opacity: 1,
      marginTop: 0,
      transition: {
        duration: 0.5,
      },
    },
    hide: {
      opacity: 0,
    },
  };

  const loadingContentAnimate = {
    init: {
      marginTop: "800px",
    },
    show: {
      opacity: 1,
      marginTop: 0,
      transition: {
        duration: 0.5,
      },
    },
    hide: {
      opacity: 0,
    },
  };

  const onChange = e => {
    const str = e.target.value;
    const lastStr = str[str.length - 1];
    const patt = /[0-9]/;

    //删除，添加.，添加数字
    if (
      str.length < (buyValue?.length || 0) ||
      (lastStr === "." && !buyValue?.toString()?.includes(".") && patt.test(buyValue)) ||
      patt.test(lastStr)
    ) {
      if (str.length === 2 && buyValue === 0 && lastStr !== ".") {
        setBuyValue(lastStr);
      } else {
        setBuyValue(str);
      }
    }
  };

  const buyConfirm = async () => {
    try {
      setSharesPage("loading");
      setCheckInput(false);
      // 其中stock_id为股票id，budget为使用多少美金购买该股票，最少1美金。小于一美金会当做1美金来处理。
      // const urlStr = 'http://35.163.73.88:8008/remote/loadContextualMappingHierarchy'
      const urlStr = `http://35.163.73.88:8010/remote/buyStockFromRobinhood?stock_id=${stockData.symbol}&budget=${buyValue}`;
      const url = `/be/svc-adapter/natural/proxy?url=${encodeURIComponent(urlStr)}`;
      console.log("Purchase stock:", url, urlStr);
      await api.get(url);
      setCheckInput(false);
      setSharesPage("confirm");
      props?.setUserInputPrice?.(Number(buyValue));
    } catch (error) {
      setCheckInput(false);
      setSharesPage("buy");
      func.messageUtil(error.statusText || "Purchase failed");
    }
  };

  const getTheFocus = () => {
    // 选中 input
    if (func.isEmpty(buyValueRef.current)) return;
    buyValueRef.current?.focus();
    setCheckInput(true);
  };

  const cancel = () => {
    setSharesPage("");
    const arr = editorIdArr.filter(x => x.editorId != props.editorId);
    setEditorIdArr(arr);
    setSaasEditorId("");
  };

  useEffect(() => {
    if (func.isEmpty(sharesPage)) return;
    cancel();
  }, [props.filteredThoughts.length]);

  useEffect(() => {
    if (!func.isEmpty(stockData)) {
      setSharesPage("buy");
    }
  }, [stockData]);

  useEffect(() => {
    if (sharesPage !== "buy" || func.isEmpty(buyValueRef.current)) return;
    // 等待动画执行完成获取焦点，否则可能导致动画异常
    setTimeout(() => {
      setCheckInput(true);
      buyValueRef.current?.focus();
    }, 500);
  }, [sharesPage]);

  return (
    <AnimatePresence>
      {!func.isEmpty(stockData) ? (
        <motion.div
          className={`${style["shares-box"]} ${sharesPage == "confirm" ? style[props.className] : ""}`}
          key="sharesBuyBox"
          variants={container}
          initial="init"
          animate="show"
          exit="hide"
          data-testid="ShareBuy"
        >
          {/* {
              sharesPage == 'initPopup' ?
                <div className={style['button-group']}>
                  <Button type="primary" shape="round" size="large">Trade Options</Button>
                  <Button type="primary" shape="round" size="large">Sell</Button>
                  <Button type="primary" shape="round" size="large" onClick={() => setSharesPage('buy')}>Buy</Button>
                  <Button type="ghost" shape="round" size="large" onClick={cancel}>X</Button>
                </div>
              : null
            } */}
          {sharesPage == "buy" ? (
            <div className={style.buyBox}>
              <span className="iconfont icon-guanbi1" onClick={cancel}></span>
              <div className={style.buyInput} onClick={getTheFocus}>
                $ <div className={style.buyValue}>{buyValue}</div>
                {checkInput ? <div className={style["check-cursor"]}></div> : null}
              </div>
              <Input value={buyValue} ref={buyValueRef} onChange={onChange} />
              <div className={style.buyButtonBox}>
                <Button
                  disabled={buyValue == 0}
                  className="CardHomeStockDoneBtn"
                  shape="round"
                  size="large"
                  onClick={buyConfirm}
                >
                  Confirm
                </Button>
              </div>
            </div>
          ) : null}
          {sharesPage == "loading" ? (
            <motion.div
              className={style["loading-box"]}
              variants={loadingContentAnimate}
              initial="init"
              animate="show"
              exit="hide"
            >
              <motion.div variants={loadingAnimate} initial="init" animate="show" exit="hide">
                <Spin size="large" />
              </motion.div>
            </motion.div>
          ) : null}

          {sharesPage == "confirm" ? (
            <motion.div
              className={style["confirm-box"]}
              variants={confirmAnimate}
              initial="init"
              animate="show"
              exit="hide"
            >
              <div>
                <p className={style.title}>{stockData.name} Order Queued</p>
                <p>
                  Your order to buy ${Number(buyValue).toFixed(2)} of {stockData.name} is queued for market open.
                </p>
              </div>

              <div className={style.parameters}>
                <span>Amount Invested</span>
                <span>$0.00 of ${Number(buyValue).toFixed(2)}</span>
              </div>
              <div className={style.parameters}>
                <span>Estimated Shares</span>
                <span>{(buyValue / Number(price)).toFixed(4)}</span>
              </div>

              <Button className="CardHomeStockDoneBtn" shape="round" size="large" onClick={cancel} block>
                Done
              </Button>
            </motion.div>
          ) : null}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export default SharesBuy;
