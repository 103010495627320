import React, { useContext } from "react";
import { CustomApiPageBlocContext } from "@uiview/pages/customApi/CustomApiPageBlocContext";
import ResponseTabs from "./ResponseTabs";
import { ApiResponse } from "./CustomApiPageBloc";
import style from "./Response.module.css";
interface ResponseProps {
  responseData: ApiResponse | null;
}

const Response = (props: ResponseProps) => {
  const bloc = useContext(CustomApiPageBlocContext);

  return (
    <div className={style["response-box"]}>
      <ResponseTabs responseData={props.responseData} bloc={bloc} />
    </div>
  );
};

export default Response;
