import React, { useEffect } from "react";
import { RestClientPageBloc } from "./RestClientPageBloc";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { useDidMount } from "imagica-uikit/dist/hooks/useDidMount";

export function RestClientPage(props: { bloc: RestClientPageBloc }) {
  const { bloc } = props;

  useDidMount(async () => {
    const me1 = await tryPromise(bloc.brainClient.user.me());
    if (me1.error) {
      console.log("error", me1.error.errorDescription, me1.error);
    }
    const meData1 = me1.data!;
    console.log("me api", "isSuccess: " + meData1.isSuccess, "errorDescription: " + meData1.errorDescription, meData1);
    console.log("RestClientPage mounted", meData1.errorDescription, meData1);

    const me2 = await tryPromise(bloc.brainClient.graphql.me());
    if (me2.error) {
      console.log("error", me2.error.errorDescription, me2.error);
    }
    const meData2 = me2.data!;
    console.log(
      "graphql me api",
      "isSuccess: " + meData2.isSuccess,
      "errorDescription: " + meData2.errorDescription,
      meData2
    );
    console.log("RestClientPage mounted", meData2.errorDescription, meData2);
  });

  return (
    <div>
      <h1>RestClientPage</h1>
    </div>
  );
}
