import store from "../../../store";
import { nodeTypeOptions } from "../../../uiview/views/Nodes/nodeConfig";
import { defaultApis as CorekitDefaultApis } from "imagica-corekit/dist/base/project/config/FunctionsConfig";

const enableExperimentalFeature = store.getState().fot.enableExperimentalFeature;
// when renaming a function, add the old functionname to it's aliases and replace the function name with the updated name

let defaultApis = CorekitDefaultApis;
let preDefinedGlobalFunctions = [
  {
    label: "Generate an Image",
    groupType: "imageGen",
    groupTitle: "imageGeneration",
    description: "",
    order: 0,
  },
  {
    label: "Generate a Photo",
    groupType: "imageGen",
    groupTitle: "imageGeneration",
    description: "",
    order: 1,
  },
  {
    label: "Animation Style",
    groupType: "imageGen",
    groupTitle: "imageGeneration",
    description: "",
    aliases: ["Animation Style Image"],
    order: 3,
  },
  {
    label: "Cybernetic Painting Style",
    groupType: "imageGen",
    groupTitle: "imageGeneration",
    description: "",
    aliases: ["Cybernetic Digital Painting"],
    order: 4,
  },
  {
    label: "Cute Emoji Style",
    groupType: "imageGen",
    groupTitle: "imageGeneration",
    description: "",
    aliases: ["Tiny Cute Emoji"],
    order: 5,
  },
  {
    label: "Amazon",
    groupType: "interfaceGeneration",
    groupTitle: "interfaceGeneration",
    description: "",
    aliases: ["Amazon Shopping"],
    order: 0,
  },
  {
    label: "YouTube",
    groupType: "interfaceGeneration",
    groupTitle: "interfaceGeneration",
    description: "",
    aliases: ["YouTube Videos"],
    order: 4,
  },
  {
    label: "Weee Groceries",
    groupType: "interfaceGeneration",
    groupTitle: "interfaceGeneration",
    description: "",
    order: 3,
  },
];

const getMidjourney = {
  label: "Midjourney(old)",
  value: "midjourney",
  groupType: "imageGen",
  groupTitle: "imageGeneration",
  hide: true,
};

const getMidjourneyV2 = {
  label: "Midjourney",
  value: "midjourneyV2",
  groupType: "imageGen",
  groupTitle: "imageGeneration",
  hide: false,
};

const groupTitleOptions = [
  {
    value: "blueprints",
    label: "Blueprints",
  },
  {
    value: "toolPieces",
    label: "Tool Pieces(Not User Facing)",
  },
  {
    value: "mostPopular",
    label: "Most Popular",
  },
  {
    value: "general",
    label: "General",
  },
  {
    value: "new",
    label: "New",
  },
  {
    value: "realTimeData",
    label: "Real-Time Data",
  },
  {
    value: "imageGeneration",
    label: "Image Generation",
  },
  {
    value: "interfaceGeneration",
    label: "Interface Generation",
  },
  {
    value: "myFunctions",
    label: "My Functions",
  },
  {
    value: "marketplace",
    label: "Marketplace",
  },
];

const handleAliases = funcArr => {
  let updatedFuncArr = [...funcArr];
  funcArr
    .filter(x => x.aliases?.length > 0)
    .forEach(each => {
      // if a function has aliases, create a duplicated function config for each alias and set hide to true
      const functionsFromAliases = each.aliases.map(x =>
        Object.assign({}, each, {
          hide: true,
          alias: true,
          label: x,
          groupTitle: null,
        })
      );
      updatedFuncArr = updatedFuncArr.concat(functionsFromAliases);
    });
  return updatedFuncArr;
};

// get the latest function name from alias, if a function has no previously used aliases, return the alias
const getFunctionNameByAlias = alias => {
  const allFunctions = preDefinedGlobalFunctions.concat(defaultApis);
  return (allFunctions.find(x => x.aliases?.includes(alias)) || {}).label || alias;
};

preDefinedGlobalFunctions = handleAliases(preDefinedGlobalFunctions);
defaultApis = handleAliases(defaultApis);

// generate a complete list of defined functions and their configs, including built-in functions and defined global functions
let allPreDefinedFunctions = defaultApis.concat(preDefinedGlobalFunctions);

// TODO handle Midjourney
allPreDefinedFunctions.push(getMidjourney);

// if order is not defined, default to Infinity
allPreDefinedFunctions = allPreDefinedFunctions.map(x =>
  Object.assign({}, x, {
    order: [null, undefined].includes(x.order) ? Infinity : x.order,
  })
);

export {
  defaultApis,
  getMidjourney,
  getMidjourneyV2,
  groupTitleOptions,
  nodeTypeOptions,
  allPreDefinedFunctions,
  getFunctionNameByAlias,
};
