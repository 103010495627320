import { useMemo } from "react";
import { OnBoardingInputBloc } from "./OnBoardingInputBloc";

export type CurrentStatus = "normal" | "creating" | "completeCreating";

export const useCurrentStatus = (bloc: OnBoardingInputBloc): { currentStatus: CurrentStatus } => {
  const creating = bloc.creating.value;
  const completeCreating = bloc.completeCreating.value;

  const currentStatus = useMemo(() => {
    if (completeCreating === true) {
      return "completeCreating";
    }
    if (creating === true) {
      return "creating";
    }
    return "normal";
  }, [creating, completeCreating]);

  return {
    currentStatus,
  };
};
