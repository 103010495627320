import React, { useState } from "react";
import { Button, Input } from "antd";
import { motion } from "framer-motion";
import func from "@uikit/func";
import style from "../components-css/LayerHomeSearch.module.css";
import { getIt } from "@uikit/getIt";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";

export default function LayerHomeSearch(props) {
  const postUrlCanAbortService = getIt(PostUrlCanAbortService);
  const [inputValue, setInputValue] = useState("");
  const [searchBoxAnimate, setSearchBoxAnimate] = useState("");
  const [buildBtnAnimate, setBuildBtnAnimate] = useState("");

  const searchVariants = {
    searchBox: {
      top: "50px",
      transition: {
        duration: 1,
        type: "tween",
        ease: "easeInOut",
      },
    },
    buildBtn: {
      opacity: 0,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "easeInOut",
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  const onChange = e => {
    setInputValue(e.target.value);
  };
  const restructureResult = str => {
    if (func.isEmpty(str)) return [];
    const outArr = str.split("\n\n");
    const results = [];

    outArr.forEach(x => {
      const arr = x.split("\n").filter(x => !func.isEmpty(x));
      const obj = {
        groupTitle: arr[0]?.replace(/\d\.\s?/g, "") || "",
        values: [],
      };
      arr.slice(1).forEach(y => {
        const childArr = y.split(/:\s/g);
        const child = {
          title: childArr[0]?.replace(/\-\s?/g, "") || "",
          description: childArr[1] || "",
        };
        obj.values.push(child);
      });
      results.push(obj);
    });
    return results;
  };
  const getSearchData = async () => {
    const prompt = `The system lists all the needed public APIs with a sublist of available public ones based on a description of the product. Make sure to add a description of the public APIs following the names.
###
Product: investment app that allows users to input a query and generates lists of portfolio suggestions with reasons that users can directly purchase.

APIs:
1. Portfolio Management API:
- Bloomberg Open API: Provides financial data such as news, price quotes, and industry research.
- Morningstar Investment Data API: Provides investment data and analytics related to stocks, funds, ETFs, and economic indicators.

2. Financial Data API:
- Alpha Vantage API: Provides real-time and historical financial data, technical indicators, and stock market analysis.
- Quandl API: Provides access to a wide range of financial and economic data.

3. Stock transaction API:
- Robinhood API: Provides an easy way to trade stocks and other financial instruments.
- TD Ameritrade API: Provides access to stock and option order placement, real-time streaming data, and account and position information.

4. Stock recommendation API:
- Stocktwits API: Provides access to the Stocktwits social network and related analytics.
- TipRanks API: Provides access to stock recommendations, analyst ratings and target prices.
###
Product: ${inputValue}

APIs:`;
    const data = {
      api: "completions",
      engine: "text-davinci-003",
      frequency_penalty: 0,
      max_tokens: 500,
      top_p: 0.7,
      presence_penalty: 0,
      stop: ["###"],
      prompt: prompt,
    };

    try {
      props.setSearchLoading(true);
      const res = await postUrlCanAbortService.postData("/be/bas-demo-v4/nlp/brain_openai", data);
      const formateResArr = restructureResult(res?.data?.choices?.[0]?.text || "");
      props.setSearchLoading(false);
      return formateResArr;
    } catch (error) {
      if (error.name === "AbortError") return;
      props.setSearchLoading(false);
      func.messageUtil(error.statusText || "Get content failed");
    }
  };
  const enterInput = async () => {
    if (func.isEmpty(inputValue)) {
      func.customMsg({
        content: "Idea cannot be empty",
        type: "warning",
      });
      return;
    }
    props.resetAllData();
    setSearchBoxAnimate("searchBox");
    setBuildBtnAnimate("buildBtn");
    props.setShowLayerView(true);
    const data = await getSearchData();
    props.setSearchData(data);
  };

  return (
    <motion.div className={style["layer-search"]} variants={searchVariants} animate={searchBoxAnimate}>
      <Input
        value={inputValue}
        onChange={onChange}
        onPressEnter={enterInput}
        placeholder="What do you want to build today?"
        size="large"
        allowClear
      />
      <motion.div className={style["build-thinking-btn"]} variants={searchVariants} animate={buildBtnAnimate}>
        <Button onClick={enterInput} size="large" className={style["layer-btn"]}>
          Build your ThinkingApp
        </Button>
      </motion.div>
    </motion.div>
  );
}
