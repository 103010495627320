import React from "react";
import Button from "components/Button";
import classNames from "classnames";
import styles from "./PriceText.module.scss";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import {
  PublishSubscriptionStore,
  PublishSubscriptionStoreState,
} from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
interface PriceTextProps {
  className?: string;
}

export function PriceText(props: PriceTextProps): React.JSX.Element | null {
  const store = getIt(PublishSubscriptionStore);
  const state = useStore(store).value;
  const authStore = getIt(FotAuthStore);

  function pay() {
    if (!authStore?.state?.brainToken) {
      return store.setOpenPayWallAndLogin();
    }
    store.setIsOpenPayWall(true);
  }

  /**
   * 1、未支付显示文案
   * 2、免费天数显示优先
   */
  return (
    <div onClick={pay} className={classNames(styles.priceText, props.className)}>
      {state.free ? <FreePrice state={state} /> : <MonthlyPrice state={state} />}
    </div>
  );
}

function FreePrice(props: { state: PublishSubscriptionStoreState }): React.JSX.Element {
  const computedFreeTxt = (free: number) => {
    return `Get ${free} ${free > 1 ? "days" : "day"} free`;
  };
  return (
    <div className={styles.freePrice}>
      <div className={styles.info}>
        <div className={styles.price}>{computedFreeTxt(props.state.free)}</div>
        <div className={styles.des}>then unlimited access for ${props.state.price} per month</div>
      </div>
      <Button className={styles.btn}>Get Started</Button>
    </div>
  );
}

function MonthlyPrice(props: { state: PublishSubscriptionStoreState }): React.JSX.Element {
  return (
    <div className={styles.monthlyPrice}>
      <div className={styles.info}>
        <div className={styles.price}>${props.state.price} per month</div>
        <div className={styles.des}>unlimited access</div>
      </div>
      <Button className={styles.btn}>Get Started</Button>
    </div>
  );
}
