import { useAddToPreview } from "@custom-hooks/HomeUsePluginRoot/useAddToPreview";
import { isOnlyChatInterface } from "@views/thinking-layout-editor/util";
import func from "@uikit/func";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "@store/editor";
import { WEB_APP_DEFAULT_TITLE } from "@views/thinking-layout-editor/constants";
import { setThemeProperty } from "@custom-hooks/useSaasTheme";
import { PreviewAppTypeMap } from "@uiview/views/PreviewApp/const";
import { PreviewAppUtil } from "../PreviewApp/PreviewAppUtil";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { sharePreviewStore } from "./SharePreviewStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { getIt } from "@uikit/getIt";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppValueLangUtil } from "../PreviewApp/PreviewAppValueLangUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { useSignal } from "@preact/signals-react";
import { Node } from "reactflow";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { EdgeRunAll } from "@uikit/service/EdgeRunAll";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
/**
 * 为 AISaas 提供通用状态和内部实现逻辑
 *
 * 目前状态为了保证唯一，挂载到了 home 容器上
 *
 * 当后老板 AISaas 弃用后，应该重新该 stroe，让内部状态独立于store 中
 * @param props
 * @returns
 */
export function useAiSaasStore(storeProps?: AISaas.AISaasStoreProps): {
  isDarkMode: boolean;
  thoughtOptions: AISaasOutput.ThoughtType[];
  onChangeThoughts: (thoughts: AISaasOutput.ThoughtType[]) => void;
  addToPreview: any;
  clickBrainStudios: () => void;
  /**
   * 老版本 aisaas 组件属性
   */
  // onPreviewItemMouseEnter,
  // onPreviewItemMouseLeave,
  previewHoverData: AISaas.previewHoverDataType;
  // setPreviewHoverData,
  props: any;
  onMouseEnterAISaas: () => void;
  onMouseLeaveAISaas: () => void;
  showBottomPrompt: boolean;
  outputListRef: React.MutableRefObject<HTMLDivElement | null>;
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
} {
  const creatorAISaasStore = getIt(CreatorAISaasStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const homePluginStore = getIt(HomePluginStore);
  const previewAppStore = getIt(PreviewAppStore);
  const isShare = homePluginStore.state.isShare;
  const edgeRunAll = getIt(EdgeRunAll);
  const projectRunStore = getIt(ProjectRunStore);

  // 必须传入
  const { silderState } = (storeProps || {}) as AISaas.AISaasStoreProps;

  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const projectRunStoreState = useImagicaStore(projectRunStore).value;
  const creatorAISassState = useImagicaStore(creatorAISaasStore).value;
  const addToPreview = useAddToPreview();
  const dispatch = useDispatch();

  /** displaytThoughts 兼容逻辑 ============ */
  const isFirstRunOnSharePage = useSignal(true);
  // 下列是处理原来 displaytThoughts 的逻辑(且满足非 staticMode 的情况下), 用于缓存上一次 output 数据
  // 1. 在分享页时，run 结束后是逐步替换 output， 也就是 displayThoughts 是 sassuidata.output 的缓存，当每一次 run 结束后会更新 sassuidata.output, 所有监听 sassuidata.output 同步更新知道 runall 结束
  // 2. homeuseplugin 原来的逻辑, 过滤掉需要隐藏的节点
  // 3. showSaasOutputList === false 时表示不显示 output
  // previewOutput 状态仅用于缓存上一次的 output 列表，
  // !!! 如果不需要缓存功能,可直接去掉,使用 sassuidata.output
  const [previewOutput, setPreviewOutput] = useState<AISaasOutput.ThoughtType[]>([]);
  useEffect(() => {
    //! 在非static模式时，让displayThought数据跟随saasUIData.output数据变化，避免会存在有的地方返回的displayThought数据没有修改的saasUIData.output里的数据这种问题
    if (!PreviewAppValueLangUtil.isStaticApp(saasUIData)) {
      if (!creatorAISassState.showSaasOutputList) {
        return;
      }
      // 如果有隐藏的应该用 previewid 设置为上一次的，而不是直接隐藏,
      // 1. 会保留上一次部分内容
      // setPreviewOutput(prev => {
      //   const newOutput = PreviewAppUtil.mergeOutputList(prev, saasUIData.output)
      //   // 是否需要去掉隐藏内容
      //   return handleHiddenNode(newOutput)
      // })
      // 2. 不保留上一次部分内容
      // setPreviewOutput(handleHiddenNode(saasUIData.output));
      // STUD-2396 解决chat不显示空的preview的bug
      setPreviewOutput(saasUIData.output);
    }
  }, [saasUIData.output, creatorAISassState.showSaasOutputList, projectRunStoreState.runAllLoading]);

  const thoughtOptions = useMemo(() => {
    // 数据中 staticMode 为 false, 但实际是 STATIC_WEB。
    const isStaticMode =
      creatorSaasAppStore.state.saasUIData.staticMode ||
      previewAppStore.getPreviewAppActiveLable() === PreviewAppTypeMap.STATIC_WEB;
    if (isOnlyChatInterface(creatorSaasAppStore.state.saasUIData.output) || isStaticMode) {
      return creatorSaasAppStore.state.saasUIData.output;
    }
    if (!creatorAISassState.showSaasOutputList || (isFirstRunOnSharePage.value && isShare)) {
      return [];
    }

    return previewOutput;
  }, [
    previewOutput,
    creatorAISassState.showSaasOutputList,
    creatorSaasAppStore.state.saasUIData.output,
    isFirstRunOnSharePage.value,
  ]);

  /** displaytThoughts 兼容逻辑 ============ */

  // 从 homeUsePlugin 移动来的方法
  const fromHomeUsePlunFunctions = {
    //查找指向input node的边，其他起点的边不用运行
    getParentEdge: (targetArr: any[], edgeArr: any[]): any => {
      const nodes = creatorNodesStore.getNodes();
      const allEdges = creatorEdgesStore.getEdges();
      const edges = [] as any[];
      targetArr.forEach(n => {
        const target: any = nodes.find(x => x.id === n);
        let eArr = [];
        if (!func.isEmpty(target?.parentNode)) {
          eArr = allEdges.filter(e => e.target === target.parentNode && edgeArr.every(x => x.id !== e.id));
        } else {
          eArr = allEdges.filter(e => e.target === n && edgeArr.every(x => x.id !== e.id));
        }
        if (!func.isEmpty(eArr)) {
          edges.push(...eArr);
        }
      });
      if (func.isEmpty(edges)) {
        return { edgeArr };
      }
      edgeArr.push(...edges);
      const sources = edges.map(e => e.source);
      return fromHomeUsePlunFunctions.getParentEdge(sources, edgeArr);
    },

    //获取saas起点node以及output相关的edges
    getSourceNodeAndEdges: (): { sourceNodes: Node<any>[]; edgesArr: any[] } | undefined => {
      const data = creatorSaasAppStore.state.saasUIData;
      if (isEmpty(data)) return;
      const inputId = (data.input as any[]).map(x => x.id) || [];
      const sourceNodes = creatorNodesStore.getNodes().filter(x => inputId.includes(x.id));

      const outputNodes = ((data.output || []) as any[])
        .map(x => ((x?.results || []) as any[]).map(r => r.nodeId))
        ?.flat(Infinity);
      let edgesArr = [] as any[];
      outputNodes.forEach((x: any) => {
        const { edgeArr } = fromHomeUsePlunFunctions.getParentEdge([x], edgesArr);
        edgesArr = edgeArr;
      });
      return { sourceNodes, edgesArr };
    },

    resetThoughts: (): void => {
      // bsf-4912 replace displayThoughts
      const displayThoughtArr = HomeMethodsUtil.handleHiddenNode(
        creatorAISassState.showSaasOutputList ? [...saasUIData.output] : []
      );
      setPreviewOutput(displayThoughtArr);
    },

    clickCreateContent: async (): Promise<void> => {
      const InputNode = creatorSaasAppStore.state.saasUIData.input;
      // 更新node数据
      creatorNodesStore.setNodes(prevList => {
        return prevList.map(x => {
          const node = InputNode.find(input => input.id === x.id);
          const textAreaValue =
            typeof node?.data?.textAreaValue === "string"
              ? node?.data?.textAreaValue?.trim()
              : node?.data?.textAreaValue;

          if (!isEmpty(node)) {
            return {
              ...x,
              data: {
                ...x.data,
                textAreaValue,
              },
            };
          }
          // 是auto imagica chat，分享页默认展示view builder
          if (chatOnboardingStore.state.isOpenChat) {
            return {
              ...x,
              data: {
                ...x.data,
                showBuilderUI: true,
              },
            };
          }
          return x;
        });
      });
      // 更新nodeRef
      // stud-2043 注意点
      // FIXME: Cannot assign to 'current' because it is a read-only property.ts(2540)
      // nodesRef.current = nodesRef.current.map(x => {
      //   const node = InputNode.find(input => input.id === x.id);
      //   const textAreaValue =
      //     typeof node?.data?.textAreaValue === "string" ? node?.data?.textAreaValue?.trim() : node?.data?.textAreaValue;

      //   if (!isEmpty(node)) {
      //     return {
      //       ...x,
      //       data: {
      //         ...x.data,
      //         textAreaValue,
      //       },
      //     };
      //   }
      //   return x;
      // });
      // opt:
      creatorNodesStore.setNodes(
        HomeMethodsUtil.getClickCreateContentNewNodes(InputNode, creatorNodesStore.getNodes())
      );
      fromHomeUsePlunFunctions.resetThoughts();
      const { sourceNodes } = fromHomeUsePlunFunctions.getSourceNodeAndEdges() as any;
      //运行从group 子node添加的边，需要将所有的input相关的边都运行了
      creatorAISaasStore.setShowSaasOutputList(true);
      await edgeRunAll.clickRunAllBtn(sourceNodes, true);

      if (isFirstRunOnSharePage.value) {
        isFirstRunOnSharePage.value = false;
      }
    },
  };

  const sharePreviewStoreState = useImagicaStore(sharePreviewStore).value;

  const props = {
    /**
     * @deprecated 目前看来 `props.ref` 并未使用应该废弃掉
     */
    ref: creatorRefStore.aiSaasRef,
    saasLoading: sharePreviewStoreState.saasLoading,
    saasUIData: saasUIData,
    saasUIInputData: saasUIData.input,
    /**
     * - preview1.5- use displayThgouts
     * - preview1.5+ use sassuidata.output
     */
    saasUIListData: saasUIData.output,
    isShare: isShare,
    showSaasOutputList: creatorAISassState.showSaasOutputList,
    setSaasUIData: creatorSaasAppStore.setSaasUIData,
    closeInputId: creatorAISaasStore.onCloseInputId,
    clickCreateContent: fromHomeUsePlunFunctions.clickCreateContent,
    getSourceNodeAndEdges: fromHomeUsePlunFunctions.getSourceNodeAndEdges,
    /**
     * 已经弃用
     * @deprecated preview1.5- 使用 displayThought 状态, preview1.5+ 代码只有 sassv2 组件使用,且使用 sassuidata.output
     */
    displayThought: saasUIData.output,
  };

  const onChangeThoughts = useCallback((thoughts: AISaasOutput.ThoughtType[]) => {
    // 兼容老数据，更新 `SaasUIData` 数据(AiSaas)
    creatorSaasAppStore.setSaasUIData(prevData => {
      return {
        ...prevData,
        output: thoughts,
      };
    });
  }, []);

  const onMouseEnterAISaas = useCallback(() => {
    if (silderState) {
      silderState.value = true;
    }
  }, []);

  const onMouseLeaveAISaas = useCallback(() => {
    if (silderState) {
      silderState.value = false;
    }
  }, []);

  // from aisaas.js inner state
  const setIsStaticMode = (val: any): void => {
    dispatch(editorActions.setIsStaticMode(val));
  };
  const [showBottomPrompt, setShowBottomPrompt] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);
  const applicationTheme = useSelector((state: any) => state.editor.applicationTheme);
  const outputListRef = useRef<HTMLDivElement>(null);
  // from aisaas useEffect
  useEffect(() => {
    const theme = props?.saasUIData?.theme;
    // setSaasTitleStyle(props.saasUIData?.titleStyle)

    if (!func.isEmpty(theme)) {
      // setApplicationTheme(theme);
    }
    setIsStaticMode(props?.saasUIData?.staticMode || false);
    setShowBottomPrompt(true);
    setTimeout(() => {
      if (!isEmpty(contentRef.current)) contentRef.current.scrollTop = 200;
    }, 200);

    if (props.isShare) return;

    props.setSaasUIData(prevData => {
      return {
        ...prevData,
        title: prevData.title || selectedTemplate.template_name || WEB_APP_DEFAULT_TITLE,
        // bsf-6211 subtitle 默认为空
        subTitle: PreviewAppUtil.getPreviewAppDefualtSubtitle(prevData),
      };
    });
    // const obj = {}
    const foucsObj = {} as any;
    props.saasUIInputData.forEach(x => {
      // obj[x.id] = false
      foucsObj[x.id] = false;
    });
    // setCursorStatus(obj)
  }, []);

  /**
   * Preview 1.5 重构后，这里代码会移除
   * 主题切换会提升到preview层控制
   */
  useEffect(() => {
    if (!func.isEmpty(applicationTheme) && contentRef?.current) {
      setThemeProperty(contentRef.current, applicationTheme);
    }
  }, [applicationTheme, contentRef]);

  useEffect(() => {
    if (!isEmpty(props.saasUIListData)) {
      setTimeout(() => {
        // setScrollBoxPositioning();
      }, 1000);
    }

    if (isEmpty(outputListRef.current) || projectRunStore.state.runAllLoading) {
      setShowBottomPrompt(true);
      return;
    }
    if (!contentRef.current) {
      return;
    }
    if (
      contentRef.current.scrollHeight > contentRef.current.offsetHeight &&
      outputListRef.current.scrollHeight > 100 // 这里的100是屏幕比较矮的时候页面初始化会出现== 100的情况，需要避免执行代码(也不满足>100的条件)，经过测试，两个条件同时满足时需要执行下述代码各种情况都符合要求
    ) {
      setShowBottomPrompt(false);
      return;
    }
    if (outputListRef.current.scrollHeight === outputListRef.current.offsetHeight) {
      setShowBottomPrompt(true);
    }
  }, [props.saasUIListData]);

  const clickBrainStudios = useCallback(() => {
    // window.open(`${window.location.origin}${window.location.pathname}#/editor`, 'target')
    window.open("https://create.imagica.ai", "target");
    if (func.isEmpty(contentRef.current)) return;
    // contentRef.current.removeEventListener('scroll', setTopSuction, false)
  }, []);

  return {
    isDarkMode: applicationTheme === "dark",
    thoughtOptions,
    onChangeThoughts,
    addToPreview,
    clickBrainStudios,
    /**
     * 老版本 aisaas 组件属性
     */
    // onPreviewItemMouseEnter,
    // onPreviewItemMouseLeave,
    previewHoverData: creatorAISassState.aiSaasPreviewHoverData,
    // setPreviewHoverData,
    props,
    onMouseEnterAISaas,
    onMouseLeaveAISaas,
    showBottomPrompt,
    outputListRef,
    contentRef,
  };
}

// export const AISaasContainer = createContainer(useAiSaasStore);
// export const { Provider: AISaasProvider, useContainer: useAISaasContainer } = AISaasContainer;
