"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BgBTheme = void 0;
const CDNPathUtil_1 = require("../../../base/util/CDNPathUtil");
const DefaultTheme_1 = require("./DefaultTheme");
const DarkTheme_1 = require("./DarkTheme");
class BgBTheme {
}
exports.BgBTheme = BgBTheme;
BgBTheme.theme = {
    saasAiAppTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.saasAiAppTheme,
        background: `url(${CDNPathUtil_1.CDNPathUtil.getFilePath("themeBgB.jpg")})`,
        shareFooterUrlInfo: "#fff",
        shareFooterUrlColor: "#fff",
    },
    nodeTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.nodeTheme,
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        nodeWrapperShadow: "0px 30px 60px 0px rgba(0, 0, 0, 0.15)",
        nodeBackdropFilter: DarkTheme_1.DarkTheme.theme.nodeTheme.nodeBackdropFilter,
        renderingTransitionColors: "#ecf2fc",
    },
};
