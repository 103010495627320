import style from "./ProjectCellStyle.module.scss";
import { useCallback, useEffect, useMemo } from "react";
import { useSignal } from "@preact/signals-react";
import {
  downloadFileAsBlobFromS3ByObjectKey,
  getAssetsIcon,
  getDataUrlByBlob,
} from "@views/thinking-layout-editor/utilities";
import Thumbnail from "./Thumbnail";
import dayjs from "dayjs";
import { animated, useSpring } from "@react-spring/web";
import Text from "../Text";
import Icon from "../Icon";
import { ProjectDropdownItems, ProjectProps } from "./type";
import Dropdown, { Triggers } from "../Dropdown";
import classNames from "classnames";

export function ProjectCellItem(props: ProjectProps): JSX.Element {
  const thumbnailUrl = useSignal("");

  const name = useSignal("");

  const [templateStyle, templateApi] = useSpring(() => ({
    config: { duration: 0 },
    to: [{ transform: "scale(1)" }, { transform: "scale(1)" }],
  }));
  const isDropdownOpen = useSignal(false);

  const updateTime = useMemo(() => {
    return dayjs(props.project.updatedAt).fromNow();
  }, [props.project]);

  const createTime = useMemo(() => {
    return dayjs(props.project.createdAt).fromNow();
  }, [props.project]);

  const setThumbnailUrlByS3ObjectKey = useCallback(async (objectKey: string) => {
    try {
      const blob = await downloadFileAsBlobFromS3ByObjectKey(objectKey);
      const dataUrl = await getDataUrlByBlob(blob);
      thumbnailUrl.value = dataUrl;
      props.project.thumbnailUrl = dataUrl;
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!props.project) {
      thumbnailUrl.value = "";
      return;
    }

    try {
      const attr = props.project.attributesV2 || JSON.parse(props.project.attributes ?? "");
      name.value = attr?.name || "Untitled";
    } catch (error) {
      name.value = "Untitled";
      console.error(error);
    }

    if (props.project.thumbnailUrl) {
      thumbnailUrl.value = props.project.thumbnailUrl;
    } else {
      try {
        const json = props.project.attributesV2 || JSON.parse(props.project.attributes ?? "");
        if (!json.thumbnail_s3ObjectKey) {
          return;
        }
        setThumbnailUrlByS3ObjectKey(json.thumbnail_s3ObjectKey);
      } catch (error) {
        console.error(error);
        return;
      }
    }
  }, [props.project, setThumbnailUrlByS3ObjectKey, thumbnailUrl]);

  const handleClick = useCallback(async () => {
    props.onClick?.(props.project);
    templateApi.start({
      config: { duration: 500 },
      to: [{ transform: "scale(0.96)" }, { transform: "scale(1)" }],
    });
  }, [props.project, templateApi, props.onClick]);

  function handleDropdownOpenChange(value: boolean): void {
    isDropdownOpen.value = value;
  }

  const handleDropdownClick = useCallback(
    (type: ProjectDropdownItems) => () => {
      isDropdownOpen.value = false;
      props.onDropdownClick?.({
        type,
        project: props.project,
      });
    },
    [props.project, props.onDropdownClick]
  );

  const trigger = useMemo<Triggers>(
    () => (isDropdownOpen.value ? Triggers.Click : Triggers.Focus),
    [isDropdownOpen.value]
  );

  const onStopEvent = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // 阻止 dropdown 里面内容点击冒泡，防止点击事件触发上层的 handleClick
    e.stopPropagation();
  }, []);

  return (
    <animated.div
      className={classNames(style.cellContent, isDropdownOpen.value ? style.cellSelect : null)}
      style={templateStyle}
      onClick={handleClick}
      onContextMenu={event => {
        event.preventDefault();
        isDropdownOpen.value = true;
      }}
    >
      <div className={style.template__image}>
        {thumbnailUrl.value === "" ? <Thumbnail height={80} /> : <img src={thumbnailUrl.value} alt="thumbnail" />}
      </div>
      <div className={style.title}>{name.value}</div>
      <div className={style.title}>{updateTime}</div>
      <div className={style.title}>{createTime}</div>
      <Dropdown
        isOpen={isDropdownOpen.value}
        trigger={trigger}
        placement="bottom"
        className={style.templateToolTipsBox}
        showArrow={true}
        content={
          <div className={style.dropDown_content} onClick={onStopEvent}>
            <div className={style.dropdown__item}>
              <div className={style.dropdownItem} onClick={handleDropdownClick(ProjectDropdownItems.Duplicate)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("copy.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Duplicate</Text>
                </div>
              </div>
              <div className={style.dropdownItem} onClick={handleDropdownClick(ProjectDropdownItems.Delete)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("bin.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Delete</Text>
                </div>
              </div>
            </div>
          </div>
        }
        onOpenChange={handleDropdownOpenChange}
      >
        <div className={style.rightIcon}></div>
      </Dropdown>
    </animated.div>
  );
}
