"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DarkTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class DarkTheme {
}
exports.DarkTheme = DarkTheme;
DarkTheme.theme = {
    saasAiAppTheme: {
        background: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/darkModeBackground2x.png')",
        saasTitleColor: "#FFFFFF",
        inputBoxBgc: "rgba(255, 255, 255, 0.1)",
        inputBoxBottom: "rgba(255, 255, 255, 0.1)",
        inputBoxEnterBgc: "rgba(255, 255, 255, 0.1)",
        footerBrainStudiosColor: "rgba(255, 255, 255, 0.65)",
        topSuctionBgc: "rgba(255, 255, 255, 0.1)",
        topSuctionBorder: "#12192B",
        submitButtonColor: "#12192B",
        submitButtonBgClr: "#fff",
        homeIconBgcolor: "#fff",
        homeInconFilter: "blur(80px)",
        rightTopNumberBg: "#12192B",
        botIndexBg: "rgb(10, 157, 255)",
        askLinkBorder: "rgba(255, 255, 255, 0.3)",
        inputTitleColor: "#fff",
        saasTitleDisableColor: "rgba(255, 255, 255, 0.3)",
        aiLogoBrightColor: "#fff",
        addContentColor: "#fff",
        priceTextColor: "#fff",
        shareFooterUrlInfo: "#fff",
        shareFooterUrlColor: "#fff",
        priceBtnBg: "#12192B",
        priceDesColor: "#fff",
        tooltipBgColor: "#fff",
        tooltipColor: "rgba(18, 25, 43, 0.65)",
    },
    nodeTheme: {
        nodeWrapperBgColor: "rgba(255, 255, 255, 0.1)",
        addContentColor: "#fff",
        textColor: "#fff",
        contentBg: "rgba(255, 255, 255, 0.3)",
        messageColor: "#fff",
        errorColor: "#FF7B7B",
        useNodeContentBg: "#0A9DFF",
        handleBtnBorder: "rgba(255, 255, 255, 0.5)",
        handleBtnTextColor: "rgba(255, 255, 255, 0.5)",
        chatInputBorder: "rgba(255, 255, 255, 0.13)",
        chatInputTextColor: "#fff",
        chatInputTextPlaceholder: "#fff",
        svgColor: "#fff",
        svgOpacity: 0.3,
        audioFileNameColor: "#fff",
        audioCurrentTimeColor: "#fff",
        audioTotalTimeColor: "rgba(255, 255, 255, 0.65)",
        audioVoiceBrightness: "brightness(25)",
        pdfPreviewBg: "rgba(255, 255, 255, 0.2)",
        pdfPreviewItem: "#fff",
        pdfPreviewScrollBarColor: "rgba(255, 255, 255, 0.15)",
        xlsxThTextColor: "#fff",
        xlsxScrollBorder: "rgba(255, 255, 255, 0.2)",
        xlsxScrollBg: "rgba(23, 23, 23, 0.80)",
        xlsxThBg: "rgba(76, 76, 77)",
        xlsxTdBorder: "rgba(255, 255, 255, 0.2)",
        xlsxScrollbarTrack: "rgba(255, 255, 255, 0.10)",
        dragDropTitle: "#fff",
        dragDropDes: "rgba(255, 255, 255, 0.65)",
        uploadDes: "#fff",
        uploadLimitColor: "rgba(255, 255, 255, 0.35)",
        uploadingWrapBg: "rgba(255, 255, 255, 0.10)",
        uploadingFilename: "#fff",
        uploadingFilesize: "rgba(255, 255, 255, 0.35)",
        uploadingError: "#CC295F",
        uploadingSuccess: "transparent",
        textboxBg: "rgba(255, 255, 255, 0.1)",
        textboxColor: "#fff",
        imageBg: "rgba(255, 255, 255, 0.1)",
        imageColor: "#fff",
        codeBg: "rgba(255, 255, 255, 0.1)",
        codeColor: "#fff",
        codeFooterBg: "rgba(255, 255, 255, 0.1)",
        codeFooterColor: "#fff",
        stockBg: "rgba(255, 255, 255, 0.1)",
        stockColor: "#fff",
        codeEditorBg: "rgba(255, 255, 255, 0.1)",
        rightBtnBg: "rgba(255, 255, 255, 0.1)",
        stockTradeBtnBg: "rgba(255, 255, 255, 0.1)",
        stockTradeBtnBoxShadow: "none",
        stockCancelBtnBg: "#fff",
        stockCancelBtnColor: "#424244",
        stockCancelBtnBorder: "0.5px solid rgba(255, 255, 255, 0.1)",
        stockNamePrice: "#fff",
        askBrainTextColor: "#fff",
        nodeWrapperBorder: "0",
        imgTextColor: "#fff",
        askImagicaDotBackground: "rgba(255, 255, 255, 0.1)",
        askImagicaDotTextColor: "#fff",
        nodeBackdropFilter: "blur(60px)",
        videoKeywordContentBg: "#fff",
        videoKeywordContentColor: "#fff",
        videoItemContentColor: "#fff",
        videoItemSpanColor: "rgba(255, 255, 255, 0.65)",
        videoPaginationBg: "rgba(255,255,255, 0.1)",
        videoShowMoreColor: "rgba(255, 255, 255, 0.35)",
        videoItemDurationBg: "rgba(18, 25, 43, 0.35)",
        videoSelectedItemBg: "rgba(255,255,255, 0.1)",
        videoPaginationIconDisabledColor: "rgba(255,255,255, 0.1)",
        shoppingSearchIconBrightness: "brightness(80)",
        shoppingSearchBarInputColor: "rgba(255, 255, 255, 0.35)",
        shoppingSearchBarBorderColor: "rgba(255, 255, 255, 0.3)",
        shoppingItemBg: "rgba(255, 255, 255, 0.1)",
        shoppingItemInfoColor: "#fff",
        shoppingItemInfoSpanColor: "#fff",
        shoppingNoResultIcon: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/no_results_found_dark.png')",
        shoppingNoResultTitleColor: "#fff",
        shoppingNoResultDesColor: "rgba(255, 255, 255, 0.65)",
        viewBuilderButtonBackground: "rgba(243, 243, 244, 0.1)",
        viewBuilderButtonTextColor: "rgba(149, 159, 177, 1)",
        ViewBuilderL1DividerColor: "rgba(255, 255, 255, 0.7)",
        viewBuilderButtonHoverBackground: "rgba(243, 243, 244, 0.15)",
        viewBuilderButtonHoverTextColor: "rgba(243, 243, 244, 1)",
        viewBuilderButtonHoverBorderColor: "rgba(243, 243, 244, 0.35)",
        viewBuilderButtonClickBackground: "rgba(243, 243, 244, 0.25)",
        viewBuilderButtonClickBorderColor: "rgba(243, 243, 244, 0.65)",
        toolbarBg: "rgb(167,169,175)",
        toolbarIcon: "rgb(90,95,106)",
        talkBuilderBg: "#fff",
        talkBuilderText: "#12192b",
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        renderingTransitionColors: "#1a142d",
    },
};
