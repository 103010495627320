import { type HTMLAttributes, type ReactNode } from "react";
import cc from "classcat";
import type { PanelPosition } from "reactflow";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { fotReactFlowPanelStore } from "./FotReactFlowPanelStore";

export type PanelProps = HTMLAttributes<HTMLDivElement> & {
  position: PanelPosition;
  children: ReactNode;
};

function FotReactFlowPanel({ position, children, className, style, ...rest }: PanelProps): React.ReactNode {
  // override
  const { pointerEvents } = useImagicaStore(fotReactFlowPanelStore).value;
  const positionClasses = `${position}`.split("-");

  return (
    <div
      className={cc(["react-flow__panel", className, ...positionClasses])}
      style={{ ...style, pointerEvents }}
      {...rest}
    >
      {children}
    </div>
  );
}

export default FotReactFlowPanel;
