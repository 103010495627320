"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class FTheme {
}
exports.FTheme = FTheme;
FTheme.theme = {
    saasAiAppTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.saasAiAppTheme,
        background: "linear-gradient(180deg, #F3F3F3 0%, #F3F3F3 100%)",
    },
    nodeTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.nodeTheme,
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBorderRadius: "30px",
        inputPadding: "15px 25px",
        topSuctionBgc: "rgba(12, 12, 12, 0.07)",
        inputBoxShadow: "none",
        submitButtonBgClr: "#000101",
        submitButtonColor: "#fff",
        nodeWrapperShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
    },
};
