"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component = exports.Outputs = exports.Input = exports.BlueprintDetails = void 0;
class BlueprintDetails {
}
exports.BlueprintDetails = BlueprintDetails;
class Input {
}
exports.Input = Input;
class Outputs {
}
exports.Outputs = Outputs;
class Component {
}
exports.Component = Component;
