const AddPlusSvg = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Add Plus">
        <rect y="0.5" width="20" height="20" rx="10" fill="#0A9DFF" />
        <path
          id="Vector"
          d="M10 15.5V5.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M15 10.5H5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
const AddCancelSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M13.5355 6.46449L6.46447 13.5356M6.46447 6.46449L13.5355 13.5356"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DownSvg = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon">
        <path
          id="Icon_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.66404 7.37401L3.18284 5.82325C3.02663 5.72562 2.77337 5.72562 2.61716 5.82325C2.46095 5.92089 2.46095 6.07918 2.61716 6.17681L5.41717 7.92683C5.58674 8.03281 5.86638 8.02236 6.01454 7.90451L10.4146 4.40447C10.551 4.2959 10.5209 4.13874 10.3472 4.05343C10.1734 3.96813 9.92198 3.98699 9.7855 4.09556L5.66404 7.37401Z"
          fill="#1BA769"
        />
      </g>
    </svg>
  );
};

const TopSvg = () => {
  return (
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1 2.2C5.28667 1.95111 5.64756 1.93452 5.85745 2.15022L5.9 2.2L8.9 6.2C9.13598 6.51464 8.93241 6.95753 8.55506 6.99714L8.5 7H7L7 9.5C7 9.73669 6.83553 9.93497 6.61465 9.98679L6.55831 9.99664L6.5 10H4.5C4.24358 10 4.03225 9.80698 4.00336 9.55831L4 9.5V7H2.5C2.12637 7 1.89815 6.6132 2.04321 6.29532L2.06925 6.24576L2.1 6.2L5.1 2.2ZM5.5 3.3335L3.5 6H4.5C4.73669 6 4.93497 6.16447 4.98679 6.38535L4.99664 6.44169L5 6.5V9H6V6.5C6 6.26331 6.16447 6.06503 6.38535 6.01321L6.44169 6.00336L6.5 6H7.5L5.5 3.3335ZM10 0C10.2761 0 10.5 0.223858 10.5 0.5C10.5 0.776142 10.2761 1 10 1L1 1C0.723858 1 0.5 0.776142 0.5 0.5C0.5 0.223858 0.723858 0 1 0L10 0Z"
        fill="#0A9DFF"
      />
    </svg>
  );
};

export { AddPlusSvg, AddCancelSvg, DownSvg, TopSvg };
