import { getIt } from "@uikit/getIt";
import { ChatBloc, PromptInfos } from "@uiview/views/CallerBot/ChatBloc";
import { useCreation } from "ahooks";
import cls from "classnames";
import { motion } from "framer-motion";
import { tryBlock } from "imagica-corekit/dist/base/cutil/LangUtil";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useDidMount } from "imagica-uikit/dist/hooks/useDidMount";
import { last } from "lodash";
import { useState } from "react";
import { ChatPanel } from "./ChatPanel";
import { OnBoardingInputBloc } from "./OnBoardingInputBloc";
import styles from "./OnboardingInput.module.scss";
import { InputBar } from "./InputBar";
import { transition } from "./motion/motionConfig";
import { useCurrentStatus } from "./useCurrentStatus";

const variants = {
  normal: { top: "30%" },
  creating: { top: "40%" },
  completeCreating: {
    top: "80%",
    right: "180px",
    transition: {
      delay: 0.2,
    },
  },
};

export const OnboardingInput = ({
  bloc,
  showOnBoardingInput,
  setIsCallerBotAI,
}: {
  setIsCallerBotAI: (bool: boolean) => void;
  showOnBoardingInput: boolean;
  bloc: OnBoardingInputBloc;
}): JSX.Element => {
  const chatBloc = useCreation(() => new ChatBloc(PromptInfos.classifier), []);

  const homePluginStore = getIt(HomePluginStore);

  const { currentStatus } = useCurrentStatus(bloc);

  const [startMotionComplete, setStartMotionComplete] = useState(false);

  const handleThinkEnter = async (): Promise<void> => {
    bloc.cotChatStatus.value = "loading";
    await chatBloc.chatOnce(bloc.thinkValue.value);
    const message = last(chatBloc.state.message.value)?.content || "";
    const result = tryBlock<{ caller_bot_ai: boolean }>(() => JSON.parse(message));
    if (result.data) {
      setIsCallerBotAI(result.data.caller_bot_ai);
    }
  };

  const handleMotionComplete = (): void => {
    if (currentStatus === "completeCreating") {
      console.log("completeCreating");
    }
  };

  const handleStartMotionComplete = (): void => {
    setStartMotionComplete(true);
  };

  useDidMount(() => {
    bloc.selectCursor();
  });

  const handleClose = (): void => {
    homePluginStore.setShowCallerBot(false);
  };

  if (showOnBoardingInput === false) {
    return <></>;
  }

  const fotBoxClassName = cls(styles.fotInputBox, startMotionComplete === false && styles.startAnimation);

  return (
    <>
      <div className={styles.retryMask} />
      <motion.div
        initial={variants.normal}
        variants={variants}
        animate={currentStatus}
        transition={transition}
        className={styles["fotInput-wrapper"]}
        onAnimationComplete={handleMotionComplete}
      >
        <motion.div
          animate={{ width: ["10px", "10px", "560px"], marginTop: ["50px", "0px", "0px"] }}
          transition={{ duration: 1, times: [0, 0.25, 1] }}
          className={fotBoxClassName}
          onAnimationComplete={handleStartMotionComplete}
        >
          <div>
            <ChatPanel bloc={bloc} onClose={handleClose} startMotionComplete={startMotionComplete} />
            {startMotionComplete === true ? (
              <>
                <InputBar onPressEnter={handleThinkEnter} bloc={bloc} />
              </>
            ) : null}
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};
