import { AModal } from "@uiview/views/AModal/AModal";
import { Button } from "antd";
import { MissingApi } from "imagica-corekit/dist/base/api/blueprintTyped/Blueprint";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { map } from "lodash";
import useStateRef from "react-usestateref";
import { UploadFile } from "../BringOwnApi/UploadFile";
import styles from "./UnsupportedBlueprintFlowModal.module.scss";
import { useState } from "react";
import { BluePrintUtil } from "../BluePrintUtil";

type UnsupportedBlueprintFlowProps = {
  missing_apis?: MissingApi[];
  onClose: () => void;
  onOk: () => void;
};

export function UnsupportedBlueprintFlowModal({ missing_apis = [], onClose, onOk }: UnsupportedBlueprintFlowProps) {
  const [statuses, setStatuses, statusesRef] = useStateRef<boolean[]>(new Array(missing_apis.length).fill(false));
  const [missings, setMissings, missingsRef] = useStateRef(missing_apis);

  const handleChange = (index: number, status: boolean) => {
    const newStatuses = [...statusesRef.current];
    const newMissings = [...missingsRef.current];
    newStatuses[index] = status;
    if (status) {
      // 调换位置
      setMissings(BluePrintUtil.moveElementToEnd(newMissings, index));
      setStatuses(BluePrintUtil.moveElementToEnd(newStatuses, index));
    } else {
      setStatuses(newStatuses);
    }
  };

  const disableTryAgainButton = statuses.some(s => s === false);

  return (
    <AModal
      title={
        <div className={styles.mainTitle}>
          <StatusIcon status={!disableTryAgainButton} className={styles.titleIcon} /> Generated Blueprint is Missing
          these APIs
        </div>
      }
      open={true}
      onCancel={onClose}
      onOk={onOk}
      buttons={["Cancel", "Try Again"]}
      disableConfirm={disableTryAgainButton}
      width={620}
      className={styles.unsupportedBlueprintFlowModel}
    >
      <div className={styles.unsupportedBlueprintFlow}>
        {map(missings, (missingApi, index) => {
          return (
            <MissingApiPanel
              onChange={status => handleChange(index, status)}
              key={missingApi.name}
              missingApi={missingApi}
              status={statuses[index]}
            />
          );
        })}
      </div>
    </AModal>
  );
}

type MissingApiPanelProps = {
  missingApi: MissingApi;
  onChange: (status: boolean) => void;
  status: boolean;
};

export function MissingApiPanel({ missingApi, status, onChange }: MissingApiPanelProps) {
  const [uploadFileModel, setUploadFileModel] = useState<React.ReactElement | null>(null);

  const handleClick = () => {
    onChange(false);
    setUploadFileModel(
      <UploadFile
        className={styles.uploadFile}
        onClose={() => {
          setUploadFileModel(null);
        }}
        onUploaded={() => onChange(true)}
        onReUpload={() => onChange(false)}
      />
    );
  };

  const statusText = status === true ? "API Submitted" : "Missing API";
  const buttonText = status === true ? "Replace Submission" : "Submit Your Own API";

  return (
    <div className={styles.missingApiPanel}>
      {uploadFileModel ? (
        uploadFileModel
      ) : (
        <div className={styles.missingApiContent}>
          <div className={styles.title}>
            <div>{missingApi.name}</div>
            <StatusIcon status={status} className={styles.subTitleIcon} />
          </div>
          <div className={styles.status}>{statusText}</div>
          <div className={styles.desc}>{missingApi.description}</div>
          <div className={styles.buttonWrapper}>
            <Button onClick={handleClick}> {buttonText}</Button>
          </div>
        </div>
      )}
    </div>
  );
}

type StatusIconProps = { status: boolean; className: string };

function StatusIcon(props: StatusIconProps) {
  return (
    <img
      className={props.className}
      src={`${settings.S3CDN}${settings.viewAssetsPath}${props.status === true ? "checked" : "warning"}.svg`}
      alt=""
    />
  );
}
