"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebConfigAPI = void 0;
const Settings_1 = require("../kernel/Settings");
class WebConfigAPI {
    /**
     * @see https://brain-ai.atlassian.net/browse/STUD-1138?focusedCommentId=47009
     */
    async fetchWebConfig() {
        const url = Settings_1.settings.templatesProjectUrl;
        return fetch(url).then(res => res.json());
    }
}
exports.WebConfigAPI = WebConfigAPI;
