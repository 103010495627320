import isBlank from "@sedan-utils/is-blank";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import { Signal, signal } from "@preact/signals-react";
import { logEvent } from "@uikit/service/amplitude";
import {
  ImagicaCreatorPlan,
  ImagicaCreatorPlanElement,
} from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { CurrentPlan } from "imagica-corekit/dist/cases/util/PayWallUtil";
import { ImagicaFeature } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaFeature";
import { PlansTableUtil } from "./PlanTableUtil";
import store from "@store/index";
import { ProjectModifiedUtil } from "@uikit/projectModified/ProjectModifiedUtil";
import { PageManager } from "@uikit/page/PageManager";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import func from "@uikit/func";
import { FotSaveService } from "@uikit/service/FotSaveService";

export type Buttonloading = {
  value: string;
  loading: boolean;
};
export class PriceSelectPopOptionType {
  currentOperate: string = "Subscribe";
  currentBtnStr: string = "Proceed to Payment";
  showTipText: boolean = true;
}

class PlanCellState {
  upgrageLoading: Signal<boolean> = signal(false);
  showAlertView: Signal<boolean> = signal(false);
  myPlan: CurrentPlan = new CurrentPlan();
  selectPlan: Signal<ImagicaCreatorPlan> = signal(new ImagicaCreatorPlan());
  priceSelectPopOption: Signal<PriceSelectPopOptionType> = signal(new PriceSelectPopOptionType());

  showDownAlertView = signal(false);

  planTablePopupSingle = signal({
    loading: false,
    options: [] as ImagicaCreatorPlan[],
    features: [] as ImagicaFeature[],
  });

  path: string = "";
}

export class PlansListBloc {
  state: PlanCellState = new PlanCellState();

  constructor(
    path: string,
    private brainClient: BrainClient,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public subscriptionStore: SubscriptionStore,
    public fotSaveService: FotSaveService,
    public showNavPageStore: ShowNavPageStore
  ) {
    this.state.path = path.split("/").slice(0, -1).join("/");
    this.getCurrentPlan();
  }

  initData(): void {
    this.state.planTablePopupSingle.value = {
      ...this.state.planTablePopupSingle.value,
      loading: true,
    };

    Promise.all([this.subscriptionStore.imagicaCreatorPlans(), this.subscriptionStore.imagicaFeatures()])
      .then(res => {
        const [options, features] = res;
        this.state.planTablePopupSingle.value = {
          loading: false,
          options: PlansTableUtil.transformPlansV2(options),
          features: features,
        };
      })
      .catch(error => {
        console.trace("get failed.", error);
        func.messageUtil("Get plan list failed.", "error");
        this.state.planTablePopupSingle.value = {
          ...this.state.planTablePopupSingle.value,
          loading: false,
        };
      });
  }

  async triggerSaveProject(): Promise<void> {
    const showNavPage = this.showNavPageStore.state.showNavPage;
    const appList = previewStore.state.previewAppList ?? [];
    const saasUIData = this.creatorSaasAppStore.state.saasUIData;
    const selectedTemplate = store.getState().studio.selectedTemplate as any;

    // 是否需要保存, 等待保存成功
    if (!showNavPage) {
      const hasModifed = ProjectModifiedUtil.hasModified({
        showNavPage: showNavPage,
        selectedTemplate: selectedTemplate,
        appList: appList,
        saasUIData: saasUIData,
      });

      if (hasModifed) {
        await this.fotSaveService.handleSave({});
      }
    }
  }

  async getCurrentPlan(): Promise<void> {
    this.state.myPlan = await this.subscriptionStore.currentPlan();
  }

  async upgrade(priceId: string): Promise<void> {
    if (this.state.upgrageLoading.value) return;
    if (isBlank(priceId)) {
      console.log("no price id");
      return;
    }

    this.state.upgrageLoading.value = true;

    // 是否需要保存, 等待保存成功
    await this.triggerSaveProject();

    const parmasData = {
      line_items: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      after_completion: {
        redirect: {
          url: `${window.location.origin}${window.location.pathname}#${this.state.path}/upgrade_success`,
        },
        type: "redirect",
      },
    };
    const res = await tryPromise(this.brainClient.payWall.paymentLink(parmasData));
    this.state.upgrageLoading.value = false;
    if (res.data?.data?.url) {
      localStorage.setItem("UPGRADE_SUCCESS_URL", "");
      PageManager.openPage(res.data?.data.url);
    }
  }

  async openStripeManagementLink(): Promise<void> {
    this.state.upgrageLoading.value = true;

    // 是否需要保存, 等待保存成功
    await this.triggerSaveProject();

    // await sleep(3000)
    await this.subscriptionStore.stripeManagementLink().then(link => {
      // logEvent("pay_wall_downgrade", { planName: this.state.myPlan.name });
      PageManager.openPage(link);
    });
    this.state.upgrageLoading.value = false;
    this.state.showDownAlertView.value = false;
  }
  /// Downgrade button 点击事件
  clickDowngradeBtn(plan: ImagicaCreatorPlan): void {
    // 已经cancel了的plan，禁用downgrade
    if (this.state.myPlan.isCancel) return;
    this.state.selectPlan.value = plan;
    if (plan.downgrade_button.popup) {
      this.state.showDownAlertView.value = true;
    } else {
      this.handleDowngradeLink();
    }
  }
  handleDowngradeLink(): void {
    if (this.state.upgrageLoading.value) return;
    //有订阅信息
    if (this.state.myPlan.customer && !isBlank(this.state.myPlan.customer)) {
      // this.useUpdateLink(plan);
      return;
    }
    //没有订阅信息
    this.openStripeManagementLink();
  }

  /// upgradeBtn 点击事件
  clickUpgradeBtn(plan: ImagicaCreatorPlan): void {
    if (this.state.upgrageLoading.value) return;
    this.state.selectPlan.value = plan;
    // 有订阅信息，显示upgrade弹窗
    if (this.state.myPlan.customer) {
      this.state.priceSelectPopOption.value = {
        currentOperate: "Upgrade",
        currentBtnStr: "Confirm",
        showTipText: false,
      };
    } else {
      this.state.priceSelectPopOption.value = {
        currentOperate: "Subscribe",
        currentBtnStr: "Proceed to Payment",
        showTipText: true,
      };
    }
    this.state.showAlertView.value = true;
  }
  confirmUpgrade(priceObj: ImagicaCreatorPlanElement): void {
    const priceId = priceObj.priceId ?? "";
    if (this.state.priceSelectPopOption.value.currentOperate === "Subscribe") {
      this.upgrade(priceId);
    } else {
      this.useUpdateLink(priceObj);
    }
    const mode = priceObj.interval ?? "";
    const currentPlan = this.state.myPlan;
    logEvent("pay_wall_upgrade", {
      ...this.state.selectPlan.value,
      payMode: mode,
      priceId,
      currentPlanName: currentPlan.name,
    });
  }
  //downgarde才传plan
  async useUpdateLink(priceObj: ImagicaCreatorPlanElement): Promise<void> {
    //如果是点击的免费plan的downgrade,去到cancel页面
    // const type = !isBlank(plan) && plan?.free ? "subscription_cancel" : "subscription_update_confirm";
    const type = "subscription_update_confirm";
    const parmasData = {
      customer: this.state.myPlan.customer || "",
      return_url: window.location.href,
      flow_data: {
        type,
        [type]: {
          subscription: this.state.myPlan.subscriptionId,
          items: [
            {
              id: this.state.myPlan.itemId,
              quantity: 1,
              price: priceObj.priceId ?? "",
            },
          ],
        },
        after_completion: {
          redirect: {
            return_url: `${window.location.origin}${window.location.pathname}#${this.state.path}/upgrade_success`,
          },
          type: "redirect",
        },
      },
    };
    this.state.upgrageLoading.value = true;

    // 是否需要保存, 等待保存成功
    await this.triggerSaveProject();

    const res = await tryPromise(this.brainClient.payWall.updateSubscripber(parmasData));
    this.state.upgrageLoading.value = false;
    if (res.data?.data?.url) {
      PageManager.openPage(res.data?.data?.url);
    } else {
      console.error("未获取到地址", res);
    }
  }
}
