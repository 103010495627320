import classNames from "classnames";

import styles from "./TalkToAIBuilderCp.module.scss";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import TalkIcon from "./assets/talk.png";
import { TalkBuilder } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboardingType";
import { matchMobile } from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

export function TalkToAIBuilderCp(props: any): JSX.Element {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const chatOnboardingState = useStore(chatOnboardingStore).value;
  const { id } = chatOnboardingState.talkBuilder;

  const handleClick = (): void => {
    if (props.id === id) {
      chatOnboardingStore.setChatOnboardingState({
        talkBuilder: {
          ...new TalkBuilder(),
        },
      });
    } else {
      props.onClickTalkBuilderEdit();
    }
    chatOnboardingStore.setIsOpenChat(true);
  };

  const isMobileFlag = isMobile && featureTags.disableMobilePage;

  return (
    <div
      onClick={handleClick}
      className={classNames(
        styles["cp-box"],
        { [styles["cp-box-mobile"]]: isMobileFlag },
        { [styles.active]: props.id === id }
      )}
    >
      <div className={styles.icon}>
        <img src={TalkIcon} alt="" />
      </div>
      <div className={styles.txt}>{isMobileFlag ? "Edit with AI" : "Talk to AI Builder"}</div>
    </div>
  );
}
