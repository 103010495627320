"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImagicaFeature = void 0;
class ImagicaFeature {
    constructor() {
        this.name = "";
        this.value = "";
        this.description = "";
        this.short_description = "";
        this.plans = [];
        this.minimum_plan = "";
    }
}
exports.ImagicaFeature = ImagicaFeature;
