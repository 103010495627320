import func from "@uikit/func";
// import { getBottomOrRightPosition } from "@views/thinking-layout-editor/utils/getBottomOrRightPosition";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";

export class CreatorNodeNewPosition {
  constructor(
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public fotReactFlow: FotReactFlow
  ) {}
  getNewNodePosition(e: any): {
    x: number;
    y: number;
  } {
    // const distance = 30;
    const viewport = this.fotReactFlow.getViewport();
    if (!viewport) {
      throw new Error("getNewNodePosition");
    }
    const { x, y, zoom } = viewport;
    const nodes = this.creatorNodesStore.getNodes();
    const edges = this.creatorEdgesStore.getEdges();

    const targetNodes = edges.map(x => x.target);
    const needCalcNode = nodes.filter(x => !CreatorNodesConsts.NEW_EDGE_REG.test(x.id));
    const inputNodes = needCalcNode.filter(x => !targetNodes.includes(x.id) && func.isEmpty(x.parentNode));
    if (func.isEmpty(nodes) || func.isEmpty(inputNodes)) {
      if (func.isEmpty(e)) {
        return {
          x: 0,
          y: 0,
        };
      }
      const newOriginX = (e.clientX - x) / zoom;
      const newOriginY = (e.clientY - y) / zoom;
      return {
        x: newOriginX,
        y: newOriginY,
      };
    }
    // const newInputNode: any = inputNodes[inputNodes.length - 1];
    // const nodeX = newInputNode.position.x;
    // const nodeY = newInputNode.position.y + newInputNode.height + distance;
    // const newNodeY = getBottomOrRightPosition(needCalcNode, "right", distance, { x: nodeX, y: nodeY });
    return {
      x: (e.clientX - x) / zoom,
      y: (e.clientY - y) / zoom, // TODO 新建node时应规避上一个node会把tooltip遮挡住
    };
  }
}
