"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapModel = void 0;
class MapModel {
    constructor(value, date) {
        this.value = value;
        this.date = date;
    }
}
exports.MapModel = MapModel;
