import styles from "./PowerMaskView.module.scss";
import { PowerChildProps } from "./PowerMaskViewBloc";

export function PowerMaskBig(props: PowerChildProps) {
  return (
    <div className={styles.mask_view}>
      <div className={styles.vip_view}>
        <span>{props.minimumPlanObj.value?.name?.text ?? props.defaultWallName}</span>
      </div>
      <div className={styles.mask_view_content}>
        <div className={styles.title}>{props.featureDescription.value}</div>
        <div className={styles.button} onClick={props.onClick}>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_5193_17601)">
              <path
                d="M12.8994 3.5L4.8835 13.1191C4.56957 13.4958 4.41261 13.6841 4.41021 13.8432C4.40812 13.9815 4.46975 14.1131 4.57732 14.2C4.70106 14.3 4.94625 14.3 5.43662 14.3H11.9994L11.0994 21.5L19.1153 11.8809C19.4292 11.5042 19.5862 11.3159 19.5886 11.1568C19.5907 11.0185 19.529 10.8869 19.4215 10.8C19.2977 10.7 19.0525 10.7 18.5622 10.7H11.9994L12.8994 3.5Z"
                fill="white"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_5193_17601"
                x="0.910156"
                y="0"
                width="22.1787"
                height="25"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="1.75" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.65 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5193_17601" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5193_17601" result="shape" />
              </filter>
            </defs>
          </svg>
          <span>Upgrade to {props.minimumPlanObj.value?.name?.text ?? props.defaultWallName}</span>
        </div>
      </div>
    </div>
  );
}
