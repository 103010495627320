import { IconButtonProps } from "./IconButtonType";
import style from "./style.module.scss";

export function ListButton(props: IconButtonProps): JSX.Element {
  return (
    <div
      className={style.box}
      style={props.isHeightLight ? { backgroundColor: "#F3F3F4" } : undefined}
      onClick={props.onClickBtn}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4 5H2"
          stroke="#12192B"
          strokeOpacity="0.65"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M18 5H7" stroke="#12192B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 10H2" stroke="#12192B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 10H7" stroke="#12192B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 15H2" stroke="#12192B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 15H7" stroke="#12192B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}
