import { display } from "html2canvas/dist/types/css/property-descriptors/display";

export function DownloadIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline-block" }}
    >
      <g clipPath="url(#clip0_10101_56992)">
        <path
          d="M18 9.56055V13.7605C18 15.4407 18 16.2808 17.673 16.9225C17.3854 17.487 16.9265 17.9459 16.362 18.2336C15.7202 18.5605 14.8802 18.5605 13.2 18.5605H6.8C5.11984 18.5605 4.27976 18.5605 3.63803 18.2336C3.07354 17.9459 2.6146 17.487 2.32698 16.9225C2 16.2808 2 15.4407 2 13.7605V9.56055"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 8.56055L10 12.5605M10 12.5605L6 8.56055M10 12.5605V1.56055"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10101_56992">
          <rect width="20" height="20" fill="white" transform="translate(0 0.560547)" />
        </clipPath>
      </defs>
    </svg>
  );
}
