import { uploadTypes } from "@uiview/views/Nodes/constants";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import func from "@uikit/func";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { getIt } from "@uikit/getIt";
import { CotStore } from "@uikit/store/CotStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

const isSplitable = nodeType => ["inputTextarea", "text"].includes(nodeType);

const firstEdgeAndSecendEdgeObj = {
  "edge-0": "edge-upload",
  "editor-2": "editor-upload",
};
const FIRST_NODE_ID = "editor-1";

const introToolTipNodeList = nodeType => {
  return {
    "editor-1": {
      title: "This is your input node",
      content: [
        "Add a starting thought. It can be an area of interest, sentence or word. Hover on the side of the node and ",
        "click ",
        "+",
        " to continue.",
      ],
    },
    "editor-2": {
      title: "This is your output node",
      content: isSplitable(nodeType)
        ? [
            "You can ",
            "split the content ",
            "\nusing the shard tool. ",
            "Click ",
            "+",
            " to add ",
            "more edges and AI functions.",
          ]
        : ["You can ", "click ", "+", " to add more edges and activate more AI functions."],
    },
    "editor-upload": {
      title: "This is your output node",
      content: [
        "",
        "Click ",
        "+",
        " to add more edges and AI functions, ",
        "You can also split the content before adding edges.",
      ],
    },
    "group-2": {
      title: "This is a group node",
      content: ["You can ", "click ", "+", " to add edges\nand AI functions to the entire group or each node."],
    },
    "edge-0": {
      content: [
        "Based on your thought in the node, describe what the AI should do or ",
        "type ",
        "/",
        " to select a function from the drop-down.",
      ],
    },
    "edge-upload": {
      title: ["Describe what you want the AI", "\nto do with the document"],
      content: ["i.e. summarize, shorten", "\nor create bullet points"],
    },
  };
};

const colTipNodeList = () => {
  return {
    "editor-0": {
      title: "",
      content: ["Enter an input to test your app"],
    },
  };
};

const showToolTip = ["editor-0", "editor-1", "editor-2", "edge-0", "group-2"];

/**
 * @returns nodeType
 * 如果editor-1是upload**
 * 把与之链接的edge的用于匹配tooltip文案的id修改为edge-upload
 * 把output node的用于匹配tooltip文案的id修改为editor-upload
 * 以应对uploadTypes类型的node链接的edge和output node展示不同的tooltip
 */
const modifyIdType = (id, nodes) => {
  let newId = id;

  if (Object.keys(firstEdgeAndSecendEdgeObj).includes(id)) {
    // const nodes = Array.from(nodesMap.values());

    const firstNode = nodes?.find(item => item.id === FIRST_NODE_ID);
    const isInUploadTypes = uploadTypes.includes(firstNode?.data.displayType);
    newId = isInUploadTypes ? firstEdgeAndSecendEdgeObj[id] : id;
  }

  return newId;
};

const useIntroTooltip = (id, nodeType, edgeState, sourcePosition, isAiChatOfNode) => {
  const newOnboardingFlowState = useImagicaStore(newOnboardingFlowStore).value;
  const creatorNodesStore = getIt(CreatorNodesStore);
  const cotStore = getIt(CotStore);

  //v3执行，第一个group id是group-3, 第一个输出node为editor-3
  let idKey = id;
  //只有新建的项目才能进入这儿的逻辑，新建不存在v2
  if (id === "group-3") {
    idKey = "group-2";
  }
  if (id === "editor-3") {
    idKey = "editor-2";
  }
  if (id === "new-edge-2") {
    idKey = "edge-0";
  }
  const enableTooltipWhatTodo = useSelector(state => get(state, "editor.enableTooltipWhatTodo", false));
  const getShowTooltip = useSelector(state => get(state, "editor.showTooltip", ""));
  const closeToolTipArr = useSelector(state => get(state, "editor.closeToolTipArr", []));
  const cotStoreState = useImagicaStore(cotStore).value;

  // const nodesMap = useStore(state => state.nodeInternals);

  const showIntroTooltip = () => {
    if (enableTooltipWhatTodo !== true) {
      return false;
    }

    if (!showToolTip.includes(idKey)) {
      return false;
    }

    if (closeToolTipArr.some(x => x === id)) {
      return false;
    }

    if (getShowTooltip.includes(id) === false) {
      return false;
    }

    //  隐藏部分node类型的tooltip
    const hideNodeType = ["showFoodInterface", "video", "map", "Agent", "chatInterface", ...uploadTypes];
    //  如果是aichat模板生成的node，这里不阻止显示
    if (hideNodeType.includes(nodeType) && !isAiChatOfNode) {
      return false;
    }
    // 目前 shopping 存在多个类型，为兼容。
    // nodetype 中包含 shopping 字符 即认定为 shopping。
    // eslint-disable-next-line
    if (nodeType && nodeType.toLowerCase().search("shopping") != -1) {
      return false;
    }

    if (cotStore.state.oldCotAnimationState.creating) {
      return false;
    }

    return true;
  };

  const getClassNameSuffix = () => {
    let suffix = "";
    if (nodeType) {
      suffix = isSplitable(nodeType) ? "-split" : "";
    } else if (edgeState) {
      suffix = `-${edgeState}`;
    }
    return `${idKey}${suffix}-${sourcePosition}`;
  };

  const getTooltipObj = () => {
    const cotCreating = cotStore.state.oldCotAnimationState.creating;
    const toolTipNodeList = cotCreating === true ? colTipNodeList : introToolTipNodeList;
    const classNameSuffix = cotCreating === true ? "-cot-editor-0" : getClassNameSuffix();

    const modifyId = modifyIdType(idKey, creatorNodesStore.state.nodes);
    let title = toolTipNodeList(nodeType)?.[modifyId]?.title || "";
    let content = toolTipNodeList(nodeType)?.[modifyId]?.content || "";

    if (!func.isEmpty(newOnboardingFlowStore.state.selectedFlowItem)) {
      if (!func.isEmpty(newOnboardingFlowStore.state.selectedFlowItem.toolTips)) {
        title = newOnboardingFlowStore.state.selectedFlowItem.toolTips?.[modifyId]?.title || "";
        content = newOnboardingFlowStore.state.selectedFlowItem.toolTips?.[modifyId]?.content || "";
      }
    }
    return {
      show: showIntroTooltip(),
      classNameSuffix,
      title: title,
      content: content,
    };
  };

  const [introTooltipObj, setIntroTooltipObj] = useState(() => getTooltipObj());
  useEffect(() => {
    setIntroTooltipObj(getTooltipObj());
  }, [
    id,
    nodeType,
    edgeState,
    getShowTooltip,
    cotStoreState.oldCotAnimationState.creating,
    enableTooltipWhatTodo,
    closeToolTipArr,
    newOnboardingFlowState.openOptTempLateModal,
  ]);

  return {
    introTooltipObj,
  };
};

export default useIntroTooltip;
