import { eventbus } from "imagica-corekit/dist/base/cutil/Eventbus";
import { useEffect, useRef } from "react";
export function useEventbus(MsgType, callback) {
  var callbackRef = useRef();
  callbackRef.current = callback;
  useEffect(function () {
    var subscription = eventbus.on(MsgType, function (msg) {
      if (callbackRef.current) {
        callbackRef.current(msg);
      }
    });
    return function () {
      subscription.off();
    };
  }, []);
}