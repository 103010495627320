import {
  useImperativeHandle,
  forwardRef,
  useState,
  ReactNode,
  useMemo,
  useCallback,
  CSSProperties,
  useEffect,
} from "react";
import { ImageLoading } from "imagica-uikit/dist/components/ImageLoading/ImageLoading";
import style from "./ImageNode.module.css";
import { get, isEmpty } from "lodash";
import { useTimeoutQuery } from "./useTimeoutQuery";
import { Button, Modal } from "antd";
import { BaseUploadNodeProps } from "imagica-uikit/dist/nodesV2/typing";
import classNames from "classnames";
import { BeautifyImage } from "./BeautifyImage";
import { useSignal } from "@preact/signals-react";

export type ImageProps = {
  isInBlueprint?: boolean;
  /**
   * node id
   */
  id: string;
  errorText: string;
  style?: CSSProperties;
  isResized: boolean;
  isLongPress: boolean;
  checkNodeArr: any[];
  handleGroupStyle: () => void;
  parentNodeId: string;
  textAreaValue: {
    loading: boolean;
    value: string;
    messageId?: string;
    meId?: string;
    progress?: string;
    polling?: boolean;
    resolve?: (url: string) => void;
  };
  isTargetNode: boolean;
  showFeedback: boolean;
  enableFeedback?: boolean;
  displayType: string;
  createBlobUrl?: BaseUploadNodeProps["createBlobUrl"];

  className?: string;
  /**
   * 是否禁用图片弹窗预览
   */
  disableModalPreview?: boolean;

  /**
   * 图片描述
   */
  caption?: ReactNode;
  showCaption?: boolean;
};

type HandleImageReportSize = {
  width: number;
  height: number;
};

type Handle = {
  initialImageReportSize: HandleImageReportSize;
};

export const Image = forwardRef<Handle, ImageProps>((props, customImageNodeRef) => {
  const messageId = get(props, "textAreaValue.messageId", "");
  const meId = get(props, "textAreaValue.meId", "");
  const { isTimeout, queryUrl, loading: queryLoading, progress } = useTimeoutQuery(messageId, props.id, meId);

  const compatibleValue = props.createBlobUrl?.objectURL || props?.textAreaValue?.value || props?.textAreaValue;

  const { loading, polling, progress: corekitProgress } = props?.textAreaValue || {};
  const imgLoading = (props as any)?.loading ?? loading;
  // 上传图片时imgLoading为字符串
  const imgLoadingBool = typeof imgLoading === "boolean" ? imgLoading : false;

  const isImgLoaded = useSignal(true);

  // https://github.com/brain/micro-apps/pull/2910
  // props?.handleGroupStyle方法中的内容已被屏蔽，props?.handleGroupStyle已无作用，故屏蔽imagicaStudioTutorial的监听
  // useEffect(() => {
  //   setTimeout(() => {
  //     props?.handleGroupStyle?.();
  //   }, 100);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [imagicaStudioTutorial]);

  useImperativeHandle(customImageNodeRef, () => ({
    initialImageReportSize: getInitialImageReportSize(),
  }));

  const getInitialImageReportSize: () => HandleImageReportSize = () => {
    return {
      width: 295,
      height: 355,
    };
  };

  const openPreviewImg = useCallback(() => {
    if (props.disableModalPreview || typeof compatibleValue !== "string") {
      return;
    }

    setShowModal(true);
  }, [compatibleValue, props.disableModalPreview]);

  const handleModalCancel = useCallback(() => {
    setShowModal(false);
  }, []);

  const openNewTab = useCallback(() => {
    window.open(`${compatibleValue}`);
  }, [compatibleValue]);

  const [showModal, setShowModal] = useState(false);

  const showProgress = Math.max(Number(corekitProgress), Number(progress));
  const isInBlueprint = props.isInBlueprint ?? false;

  const PreviewModal = useMemo(() => {
    if (props.disableModalPreview) {
      return null;
    }

    return (
      <Modal
        title=""
        open={showModal}
        onCancel={handleModalCancel}
        wrapClassName={style["img-preview-modal"]}
        footer={[]}
      >
        {typeof compatibleValue === "string" && (
          <img
            onClick={() => typeof compatibleValue === "string" && openNewTab()}
            className={style.previewImg}
            alt=""
            src={compatibleValue}
            onLoad={() => props?.handleGroupStyle?.()}
            onError={() => props?.handleGroupStyle?.()}
          />
        )}
      </Modal>
    );
  }, [compatibleValue, handleModalCancel, openNewTab, props, showModal]);

  const background = props.style?.background ? { background: props.style?.background } : {};

  useEffect(() => {
    if (imgLoadingBool) {
      isImgLoaded.value = false;
    }
    if (props.errorText) {
      isImgLoaded.value = true;
    }
  }, [imgLoadingBool, isImgLoaded, props.errorText]);

  return (
    <div
      style={background}
      className={classNames(props.className, style["white-frame"], {
        [style["target"]]: props.enableFeedback && !isInBlueprint,
        "nowheel nopan": props.isLongPress || props.checkNodeArr?.includes(props.id),
      })}
      data-testid="ImageNode"
    >
      <div className={style["image-box"]}>
        <div
          onClick={openPreviewImg}
          className={classNames(style["image-content"], {
            [style["default-resize"]]: !(
              props.isResized ||
              !isEmpty(props.parentNodeId) ||
              props.displayType === "uploadImage"
            ),
          })}
        >
          {!imgLoadingBool && (
            <BeautifyImage
              className={!isImgLoaded.value ? "hideImgContent" : ""}
              value={props.createBlobUrl?.objectURL || props?.textAreaValue?.value}
              isImgLoaded={isImgLoaded}
              handleGroupStyle={props?.handleGroupStyle}
            />
          )}

          {props.showCaption ? (
            <div style={props.style ?? {}} className={style["imageCaption"]}>
              {props.caption}
            </div>
          ) : null}

          <ImageLoading
            displayType={props.displayType}
            loading={imgLoadingBool || !isImgLoaded.value}
            style={{}}
            className={!imgLoadingBool && !isImgLoaded.value ? "improveImgLoading" : ""}
          />

          {(isTimeout || polling) && (typeof compatibleValue === "string") === false && (
            <div className={style.timeoutQuery}>
              <Button
                loading={queryLoading}
                onClick={() => {
                  queryUrl();
                }}
              >
                ({showProgress}%) Refresh
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* image preview */}
      {PreviewModal}
    </div>
  );
});
