import { getIt } from "@uikit/getIt";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import { ImagicaFeature } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaFeature";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";

export class PowerMaskUitl {
  static async hasPowerByFeature(feature: string[]): Promise<Boolean> {
    if (feature.length === 0) {
      return true;
    }
    const meStore = getIt(MeStore);
    const hasPaywallPermission = await meStore.hasPaywallPermission();
    if (!hasPaywallPermission) {
      return true;
    }
    const store: SubscriptionStore = getIt(SubscriptionStore);
    const imagicaFeatures = await store.imagicaFeatures();
    const currentPlan = await store.currentPlan();
    const featureValue = feature[0];
    const featureObject = imagicaFeatures.find((item: ImagicaFeature) => {
      return item.value === featureValue;
    });
    if (featureObject?.plans.includes(currentPlan.value)) {
      return true;
    } else {
      return false;
    }
  }

  static async disAbleByFeature(feature: string[]): Promise<Boolean> {
    const enable = await PowerMaskUitl.hasPowerByFeature(feature);
    return !enable;
  }
}
