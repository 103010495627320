import { CurrentPlan } from "imagica-corekit/dist/cases/util/PayWallUtil";
import {
  ImagicaCreatorPlan,
  ImagicaCreatorPlanElement,
} from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { get, isNumber, toNumber } from "lodash";

const UNIT_TEMPLATE_MAPS = {
  month: {
    short: "mo",
    single: "month",
    many: "months",
    prefix: "mo (month-to-month)",
  },
  year: {
    short: "annual",
    single: "year",
    many: "years",
    prefix: "mo (annual plan)",
  },
} as const;
type Keys = keyof typeof UNIT_TEMPLATE_MAPS;

class PriceNumber {
  /**
   * 将美分值转成美元
   * @param value
   * @param dit
   * @returns
   */
  static toUS(value: string, dit = 2): number {
    const _amount = isNumber(toNumber(value)) ? toNumber(value) : 0;
    return value ? PriceNumber.toDiv(_amount, 100, dit) : 0;
  }

  /**
   * 做除法运算,并保留小数
   * @param value
   * @param div
   * @param dit
   * @returns
   */
  static toDiv(value: number, div = 100, dit = 2): number {
    // Number 可以将多余的0去掉
    return Number((value / div).toFixed(dit));
  }
}
export class PlansTableUtil {
  static transformCurrentPlan(currentPlan: CurrentPlan): CurrentPlan {
    const interval_count = currentPlan.price.interval_count;
    return {
      ...currentPlan,
      price: interval_count
        ? (PlansTableUtil.transformPrice(
            currentPlan.price,
            currentPlan.price.interval as Keys,
            "single"
          ) as CurrentPlan["price"])
        : currentPlan.price,
    };
  }
  static transformPrice<T extends ImagicaCreatorPlanElement>(
    value?: T,
    type: Keys = "month",
    singleKey: keyof (typeof UNIT_TEMPLATE_MAPS)[Keys] = "single"
  ): T | undefined {
    if (!value) {
      return value;
    }

    const _amount = isNumber(toNumber(value.amount)) ? toNumber(value.amount) : 0;
    const amount = value.amount ? Number((_amount / 100).toFixed(2)) : undefined;
    const intervalCount = toNumber(get(value, "interval_count", 1));
    const interval = intervalCount <= 1 ? UNIT_TEMPLATE_MAPS[type][singleKey] : `${intervalCount} ${value.interval}s`;
    return {
      ...value,
      amount,
      interval,
    };
  }
  static transformPlans<T extends ImagicaCreatorPlan>(options: T[]): T[] {
    return options.map(opt => {
      return {
        ...opt,
        price_month: PlansTableUtil.transformPrice(opt.price_month, "month", "short"),
        price_annual: PlansTableUtil.transformPrice(opt.price_annual, "year", "short"),
      };
    });
  }

  static transformPriceV2<T extends ImagicaCreatorPlanElement>(
    value: T,
    type: Keys = "month"
  ): {
    originAmount: string | number;
    originInterval: string | undefined;
    amount: string;
    monthly_amount: string;
    interval: "mo (month-to-month)" | "mo (annual plan)";
  } {
    if (!value) {
      return value;
    }

    // 将美分转成美元
    const _amount = value.amount ? PriceNumber.toUS(value.amount, 2) : "";
    let dollar = _amount;

    // 年份取平均值
    if (type === "year" && isNumber(dollar)) {
      dollar = PriceNumber.toDiv(dollar, 12, 2);
    }

    return {
      originAmount: _amount,
      originInterval: value.interval,

      amount: _amount.toString(),
      monthly_amount: dollar.toString(),
      interval: UNIT_TEMPLATE_MAPS[type].prefix,
    };
  }

  /**
   * 转换新数据
   * @param options
   * @returns
   */
  static transformPlansV2<T extends ImagicaCreatorPlan>(options: T[]): T[] {
    return options.map(opt => {
      const year = PlansTableUtil.transformPriceV2(opt.price_annual, "year");
      const month = PlansTableUtil.transformPriceV2(opt.price_month, "month");

      return {
        ...opt,
        // swap year and month
        price_month: {
          ...opt.price_month,
          ...month,
        },
        price_annual: {
          ...opt.price_annual,
          ...year,
        },
      };
    });
  }

  /**
   * 为了类型完整
   *
   * 该方法仅为了类型完整，为 ImagicaCreatorPlanElement 增加了ui上的一些其他字段
   *
   * @param value
   * @returns
   */
  static getUiPlanElement<T extends ImagicaCreatorPlanElement>(
    value: T
  ): T & {
    originAmount: number;
    originInterval: string;
  } {
    return {
      ...value,
      // 为了类型完整
      originAmount: (value as any).originAmount ?? value.amount,
      originInterval: (value as any).originInterval ?? value.interval,
    };
  }
}
