import store from "@store/index";
import { ProjectModifiedUtil } from "@uikit/projectModified/ProjectModifiedUtil";
import { previewStore } from "@uiview/store/PreviewStore";
import { useEffect } from "react";

const useAlertBeforeUnload = props => {
  const showNavPage = props.showNavPage ?? false;
  const isShare = props.isShare ?? false;
  const needSave = props.needSave ?? true;
  const saasUIData = props.saasUIData;

  useEffect(() => {
    function listener(event) {
      if (
        ProjectModifiedUtil.hasModified({
          appList: previewStore.state.previewAppList,
          saasUIData: saasUIData,
          showNavPage,
          selectedTemplate: store.getState().studio.selectedTemplate,
        })
      ) {
        event.preventDefault();
        event.returnValue = "";
      }
    }

    if (needSave && !showNavPage && !isShare) {
      window.addEventListener("beforeunload", listener);
    } else {
      window.removeEventListener("beforeunload", listener);
    }
    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [needSave, showNavPage, saasUIData]);
};

export default useAlertBeforeUnload;
