"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NormalDistribution = void 0;
const StoryEdge_1 = require("../../../storyV2/domain/StoryEdge");
const StoryNodeDisplayType_1 = require("../../../storyV2/domain/StoryNodeDisplayType");
const FunctionBase_1 = require("../../../storyV2/function/FunctionBase");
const StoryManagerUtil_1 = require("../../../storyV2/domain/StoryManagerUtil");
const StoryNodeType_1 = require("../../../storyV2/domain/StoryNodeType");
const StoryUtil_1 = require("../../../storyV2/domain/StoryUtil");
const StoryNodeOptions_1 = require("../../../storyV2/domain/StoryNodeOptions");
const StoryNode_1 = require("../../../storyV2/domain/StoryNode");
const StoryNodeState_1 = require("../../../storyV2/domain/StoryNodeState");
const AdapterErrorUtil_1 = require("../../../storyV2/adapter/AdapterErrorUtil");
const JsonUtil_1 = require("../../../cutil/JsonUtil");
const BaseDistribution_1 = require("../../../storyV2/response/responseDistribution/BaseDistribution");
class NormalDistribution extends BaseDistribution_1.BaseDistribution {
    handleRunResponse(story, edge, res, relatedEdges, displayType) {
        if (res.data instanceof FunctionBase_1.RunStreamResponse || res.data.streamResponse instanceof FunctionBase_1.RunStreamResponse) {
            return this.handleGroupResponseData(story, edge, res, relatedEdges, displayType);
        }
        else {
            return this.handleNodeResponse(story, edge, res, relatedEdges, displayType);
        }
    }
    // 处理组类型的运行结果
    handleGroupResponseData(story, edge, res, relatedEdges, displayType) {
        let targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMGROUP, undefined);
        let contents = (StoryUtil_1.StoryUtil.findEdgeSoure(story, edge)?.children ?? []).map(child => child.content);
        if (contents.length === 0) {
            const response = res.data;
            contents = [...response.values];
        }
        const newChildIdNumbers = StoryManagerUtil_1.StoryManagerUtil.idNumberForGroup(story, contents.length, targetNode);
        const childNodes = contents.map((content, index) => {
            const nodeOptions = new StoryNodeOptions_1.StoryNodeOptions(`editor-${newChildIdNumbers.get(index)}`, content, StoryNodeType_1.StoryNodeType.CUSTOMNODE, displayType, targetNode.id);
            const node = new StoryNode_1.StoryNode(nodeOptions);
            node.state = StoryNodeState_1.StoryNodeState.RECEIVING;
            return node;
        });
        targetNode.options.children = childNodes.map(node => node.options.id);
        StoryManagerUtil_1.StoryManagerUtil.cleanAndSetNewChildren(targetNode, childNodes);
        targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
        return new Promise(executor => {
            let streamResponse = res.data;
            if (res.data.streamResponse instanceof FunctionBase_1.RunStreamResponse) {
                streamResponse = res.data.streamResponse;
            }
            streamResponse.onReceived(result => {
                result.value.forEach((value, key, map) => {
                    const childNode = targetNode.children[key];
                    if (value.error) {
                        const errorMsg = AdapterErrorUtil_1.AdapterErrorUtil.getErrorMsg(value);
                        if (childNode.content != errorMsg) {
                            childNode.received(new StoryNode_1.StoryNodeEventObject(errorMsg));
                            childNode.state = StoryNodeState_1.StoryNodeState.ERROR;
                        }
                    }
                    else {
                        let contentString = value.data;
                        if (typeof value.data !== "string") {
                            if (displayType !== StoryNodeDisplayType_1.StoryNodeDisplayType.MAP) {
                                contentString = JsonUtil_1.JsonUtil.stringify(value.data) ?? "";
                            }
                        }
                        if (childNode.content === contentString) {
                            return;
                        }
                        childNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                        childNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
                    }
                });
                if (result.completion) {
                    StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
                    executor();
                }
            });
        });
    }
    // 处理单节点的运行结果
    handleNodeResponse(story, edge, res, relatedEdges, displayType) {
        let targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMNODE, displayType);
        const sourceNode = StoryUtil_1.StoryUtil.findEdgeSoure(story, edge);
        let contentString = res.data;
        if (typeof res.data !== "string") {
            contentString = JsonUtil_1.JsonUtil.stringify(res.data) ?? "";
        }
        targetNode.options.displayType = displayType;
        targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
        targetNode.cleanChildren();
        targetNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
        return Promise.resolve();
    }
}
exports.NormalDistribution = NormalDistribution;
