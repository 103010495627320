import { CreatorRoot } from "@uiview/views/ProjectCanvas/CreatorRoot";
import { useCreation } from "ahooks";
import React, { useEffect } from "react";
import { CreatorFunctionNormalPageBloc } from "./CreatorFunctionNormalPageBloc";
import { Outlet, useParams } from "react-router-dom";
import { DashboardLayout } from "@uiview/layout/DashboardLayout";

/**
 * 该组件表示 普通函数的路由页面, 因为没有单独实现 ui, 原来的ui 基本就是 canvas,所有相当于重新渲染一次 canvas
 * @returns
 */
export function CreatorFunctionNormalPage(): React.JSX.Element | null {
  const bloc = useCreation(() => new CreatorFunctionNormalPageBloc(), []);
  const params = useParams();

  useEffect(() => {
    bloc.load(params.id);
  }, [params.id]);

  return (
    <>
      <DashboardLayout loading={!bloc.state.success.value} hideHeader={!bloc.state.success.value}>
        <CreatorRoot />
      </DashboardLayout>
      <Outlet />
    </>
  );
}
