import { signal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { Message } from "imagica-corekit/dist/base/api/chatCompletionTyped/ChatCompletionsTyped";
import { Ideation } from "imagica-corekit/dist/base/api/onboardingChatTyped/ideation";
import { PrototypingComponent } from "imagica-corekit/dist/base/api/onboardingChatTyped/prototyping";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";

import { StreamReadResult } from "imagica-corekit/dist/base/cutil/StreamClient";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { findLastIndex, last } from "lodash";

export enum Role {
  ASSISTANT = "assistant",
  USER = "user",
}

class AdditionalArgs {
  functions: string[] = [];
  show_interface: boolean = false;
  functionality_messages: Message[] = [];
  response_messages: Message[] = [];
  choices?: string[] = [];
}

// class AdditionalArgsJson {
//   additional_args: AdditionalArgs = new AdditionalArgs();
// }

export class NewIdeation {
  response: string = "";
  choices: string[] = [];
  functionality_messages: Message[] = [];
  response_messages: Message[] = [];
  functions: string[] = [];
  implementation: string = "";
  show_interface: boolean = false;
}
class ChatState {
  message = signal<Message[]>([]);
  loading = signal<boolean>(false);
  functionality_messages = signal<Message[]>([]);
  response_messages = signal<Message[]>([]);
  functions = signal<string[]>([]);
}

export class ChatBloc {
  state = new ChatState();
  brainClient = getIt(BrainClient);
  additionalArgs = new AdditionalArgs();
  newIdeation = signal<NewIdeation>(new NewIdeation());

  private updateMessage(latestMsg: string, role: Role): void {
    this.state.message.value = [
      ...this.state.message.value,
      {
        content: latestMsg,
        role,
      },
    ];
  }

  private addOrUpdateAssistantMsg(msg: string): void {
    const index = findLastIndex(this.state.message.value);
    if (this.state.message.value[index]?.role === Role.USER) {
      return this.updateMessage(msg, Role.ASSISTANT);
    }
    const newMsg = [...this.state.message.value];
    newMsg[index].content = msg;
    this.state.message.value = newMsg;
  }

  private setLastMessage(msg: Message): void {
    this.state.message.value.splice(-1, 1);
    this.state.message.value = [...this.state.message.value, msg];
  }

  public getLastMessage(): string {
    const message = last(this.state.message.value)?.content || "";
    return message;
  }

  public clearMessage(): void {
    this.state.message.value = [];
  }

  private processStreamData(fullText: string): void {
    fullText = fullText.trim();
    let additionalArgs;
    try {
      const message = JSON.parse(fullText);
      additionalArgs = message?.additional_args;
    } catch (error) {
      additionalArgs = {};
    }

    const lastMessage = this.getLastMessage();
    let lastMessageObj;
    try {
      lastMessageObj = JSON.parse(this.getLastMessage());
    } catch (_) {
      lastMessageObj = { response: lastMessage };
    }

    const chatOnboardingStore: ChatOnboardingStore = getIt(ChatOnboardingStore);
    const messageWithArgs = Object.assign({}, lastMessageObj, additionalArgs);
    chatOnboardingStore.setChatOnboardingState({ lastChatData: messageWithArgs });
    this.setLastMessage({ content: messageWithArgs, role: Role.ASSISTANT });
  }

  async getNewChatInfoByAPIStream(useInput: string, callbackReceived?: (fullText: string) => void): Promise<void> {
    const meStore = getIt(MeStore);
    const chatOnboardingStore: ChatOnboardingStore = getIt(ChatOnboardingStore);
    this.updateMessage(useInput, Role.USER);
    this.state.loading.value = true;
    await new Promise((resolve, reject) => {
      const onReceived = (msg: StreamReadResult): void => {
        if (msg.done) {
          msg.abort();
          this.processStreamData(msg.fullText);
          resolve(undefined);
          return;
        }
        const fullText = msg.fullText.trim();
        callbackReceived?.(fullText);
        this.addOrUpdateAssistantMsg(fullText);
      };
      const data = chatOnboardingStore.state.talkBuilder.id
        ? {
            selected_node_name: chatOnboardingStore.state.talkBuilder.content,
            selected_node_id: chatOnboardingStore.state.talkBuilder.id,
          }
        : {};
      this.brainClient.openAi
        .onboardingChatStream({
          ...data,
          user_name: meStore.state.first_name || meStore.state.name || "user",
          user_message: useInput,
          functionality_messages: chatOnboardingStore.state.lastChatData.functionality_messages || [],
          response_messages: chatOnboardingStore.state.lastChatData.response_messages || [],
          graphstring: chatOnboardingStore.state.lastBuildData.graphstring,
        })
        .then(re => {
          re.onReceived(re => onReceived(re));
        })
        .catch(error => {
          reject(error);
        });
    });
    this.state.loading.value = false;
  }

  async getNewChatInfoByAPI(
    useInput: string,
    callbackReceived?: (msg: string, choices?: string[]) => void
  ): Promise<void> {
    const meStore = getIt(MeStore);
    const chatOnboardingStore: ChatOnboardingStore = getIt(ChatOnboardingStore);
    this.updateMessage(useInput, Role.USER);
    this.state.loading.value = true;
    await new Promise((resolve, reject) => {
      this.brainClient.openAi
        .onboardingChat({
          user_name: meStore.state.first_name || meStore.state.name || "user",
          user_message: useInput,
          functionality_messages: chatOnboardingStore.state.lastChatData.functionality_messages || [],
          response_messages: chatOnboardingStore.state.lastChatData.response_messages || [],
        })
        .then(res => {
          const msg = res.data.response || "";
          callbackReceived?.(msg);
          chatOnboardingStore.state.lastChatData = res.data;
          this.addOrUpdateAssistantMsg(JSON.stringify(res.data));
          resolve(undefined);
          return;
        })
        .catch(error => {
          reject(error);
        });
    });
    this.state.loading.value = false;
  }

  async chatOnce(useInput: string): Promise<void> {
    this.clearMessage();
    await this.getNewChatInfoByAPIStream(useInput);
  }
}

export class IdeationChatBloc extends ChatBloc {
  ideation = signal<Ideation>(new Ideation());

  async transformAssistantMessage(
    useInput: string,
    callbackReceived?: (msg: string) => void,
    stream: boolean = true
  ): Promise<NewIdeation> {
    stream
      ? await this.getNewChatInfoByAPIStream(useInput, callbackReceived)
      : await this.getNewChatInfoByAPI(useInput, callbackReceived);
    const message = this.getLastMessage();
    const newIdeation = JsonUtil.toModelFromType(NewIdeation, message) ?? new NewIdeation();
    this.newIdeation.value = newIdeation;
    return newIdeation;
  }
}

export class PrototypingSortableComponent extends PrototypingComponent {
  id: string = "";
}
