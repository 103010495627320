import { LoadingOutlined, SendOutlined } from "@ant-design/icons";
import useGetUserAvatarUrl from "@custom-hooks/useGetUserAvatarUrl";
import isBlank from "@sedan-utils/is-blank";
import { Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import classNames from "classnames";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { map } from "lodash";
import { useEffect, useRef, ReactNode, useMemo } from "react";
import Markdown from "react-markdown";
import { BlocType } from "../../MultiRoundChatOnboarding";
import { Role } from "../../Constants";
import styles from "./Chatbox.module.scss";
import { RegenerateIcon } from "../../assets/svg";
import { getIt } from "@uikit/getIt";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { StepFinishIcon } from "@uiview/views/MultiRoundChatOnboarding/components/Chatbox/StepFinishIcon";
import cls from "classnames";
import { GenUIProcess, Message, TalkBuilder } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboardingType";
import { ChatBoxManager } from "./ChatBoxManager";
import { SwitcherModeType, NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { DisableChatUtil } from "../../DisableChatUtil";
import func, { matchMobile } from "@uikit/func";
import { previewStore } from "@uiview/store/PreviewStore";
import { MessageType } from "antd/lib/message";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { PreviewPaneStore } from "@uiview/views/PreviewPane/PreviewPaneStore";
import { FotSaveService } from "@uikit/service/FotSaveService";

type ChatboxProps = {
  bloc: BlocType;
  isInput?: boolean;
  selectOption?: (val: string) => void;
};

export const ImagicaUrl = "https://dopniceu5am9m.cloudfront.net/static/230614_ai_listen/ASSET_AI_1_loop.mp4";

const PLACEHOLDER = "Talk to your AI builder...";
const MOBILEPLACEHOLDER = "Talk to your AI builder";
const DISABLE_PLACEHOLDER =
  "Auto-Imagica is in view-only mode. Chat functionality has been disabled due to manual edits on the canvas.";

export function Chatbox(props: ChatboxProps): JSX.Element {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;
  const navBarStore = getIt(NavBarStore);
  const submitLoading = false;
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const chatBoxManager = ChatBoxManager.use();
  const fotSaveService = getIt(FotSaveService);
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const chatOnboardingState = useStore(chatOnboardingStore).value;
  const disableChat = DisableChatUtil.isDisabled();
  const { content } = chatOnboardingState.talkBuilder;
  const previewPaneStore = getIt(PreviewPaneStore);
  const previewPaneState = useStore(previewPaneStore).value;

  const previewState = useStore(previewStore).value;
  const webConfigState = useStore(getIt(WebConfigStore)).value;

  const options = useMemo(() => {
    let suggestions = webConfigState?.cot_v2?.examples;
    if (suggestions && suggestions.length > 2) {
      suggestions = suggestions.slice(0, 2);
    }
    return suggestions;
  }, [webConfigState]);

  const handleClose = (): void => {
    chatOnboardingStore.setChatOnboardingState({
      talkBuilder: {
        ...new TalkBuilder(),
      },
    });
  };

  useEffect(() => {
    chatOnboardingStore.setChatOnboardingState({ updateTime: +new Date() });
    // eslint-disable-next-line
  }, [chatOnboardingState.messages.length]);

  const moveListScrollBarToBottom = (): void => {
    if (listContainerRef.current) {
      if (listContainerRef.current.scrollHeight <= listContainerRef.current.clientHeight) return;
      listContainerRef.current.scrollTo({
        top: listContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (suggestion: string): void => {
    props.bloc.setSubmitValue(suggestion);
    props.bloc.onPressEnter();
  };

  const handleRegenerate = (): void => {
    props.bloc.handleRegenerate();
  };

  const closeChat = (e: any): MessageType | undefined => {
    if (previewStore.state.loading || previewStore.state.chatMessageLoading) {
      return func.customMsg({
        content: "Please wait a moment",
        type: "warning",
      });
    }
    chatBoxManager.onCloseChat(e);
  };

  const clearSelection = (): void => {
    window.getSelection()?.removeAllRanges();
  };

  useEffect(() => {
    if (chatOnboardingState.messages.length) {
      moveListScrollBarToBottom();
    }
  }, [listContainerRef.current?.scrollHeight]);

  const closeIconClassName = classNames(styles.Icon, styles.closeIcon);
  /* const collapseClassName = classNames(
    styles.Icon,
    props.bloc.state.collapsed.value ? styles.collapseIcon : styles.expandIcon,
    props.bloc.state.currentStep.value === GenUIProcess.Build ? styles.show : styles.hide
  ); */

  useEffect(() => {
    chatOnboardingStore.setIsOpenChat(true, DisableChatUtil.isDisabled() ? null : fotSaveService.handleSave);
    navBarStore.setSwtichMode(SwitcherModeType.imagica);
    previewStore.setPreviewCollapseState(false);
    return () => {
      chatOnboardingStore.setIsOpenChat(false);
      chatOnboardingStore.setChatOnboardingState({
        talkBuilder: {
          ...new TalkBuilder(),
        },
      });
      navBarStore.setSwtichMode(SwitcherModeType.canvas);
    };
  }, []);

  return (
    <div
      className={classNames(styles.chatBox, {
        [styles.chatBoxMobile]: isMobile && featureTags.disableMobilePage,
        [styles.chatBoxMobileInputBg]: props.isInput,
        [styles.chatBoxMobileInput]:
          isMobile &&
          featureTags.disableMobilePage &&
          previewState.showPreviewPanel &&
          content &&
          !previewState.loading &&
          !previewPaneState.mobileChatStatus && // preview打开时、选择talktoBuilder时且不在chat页面时
          !previewPaneState.settingPageStatus,
      })}
      onMouseDown={() => clearSelection()}
    >
      <div className={styles.chatHeader}>
        <span className={styles.chatHeaderTitle}>Auto-Imagica</span>
        <Process step={chatOnboardingState.currentStep} bloc={props.bloc} />
        {/* <span className={collapseClassName} onClick={() => props.bloc.handleClickCollapse()}></span> */}
        {chatOnboardingState.currentStep === GenUIProcess.Build ? (
          <span className={closeIconClassName} onClick={e => closeChat(e)}></span>
        ) : null}
      </div>
      <div className={styles.chatboxContent}>
        <div className={styles.chatboxListContainer} ref={listContainerRef}>
          <div className={styles.chatboxList}>
            {chatOnboardingState.messages.map((item, idx, array) => {
              const isLast = idx === array.length - 1;
              return (
                <div key={idx} className={styles.mgt20}>
                  {isMobile && featureTags.disableMobilePage && props.isInput ? null : (
                    <div className={styles.chatboxItem}>
                      <div className={styles.chatboxItemWrapper}>
                        <ChatMessage bloc={props.bloc} isLast={isLast} {...item} />
                      </div>
                    </div>
                  )}
                  <Suggestion
                    bloc={props.bloc}
                    handleRegenerate={handleRegenerate}
                    item={item}
                    onClick={handleClick}
                    isLast={isLast}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.chatboxFooter}>
          {isMobile && featureTags.disableMobilePage && props.isInput && (
            <div className={styles.options}>
              {options?.map((option: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      props.selectOption && props.selectOption(option.label);
                    }}
                    className={styles.optionItem}
                  >
                    {option?.display_value || option?.label}
                  </div>
                );
              })}
            </div>
          )}
          <div
            className={classNames(styles["chat-input-box"], {
              [styles.disableInputBox]: disableChat,
            })}
          >
            {content && !(isMobile && featureTags.disableMobilePage) && (
              <div className={styles.tag}>
                <span className={styles.txt}>{content}</span>
                <span onClick={handleClose} className={styles.close}>
                  X
                </span>
              </div>
            )}

            <TextArea
              disabled={disableChat}
              value={props.bloc.state.submitValue.value}
              onChange={e => props.bloc.setSubmitValue(e.target.value)}
              placeholder={
                disableChat
                  ? DISABLE_PLACEHOLDER
                  : isMobile && featureTags.disableMobilePage
                  ? MOBILEPLACEHOLDER
                  : PLACEHOLDER
              }
              onPressEnter={e => props.bloc.onPressEnter(e)}
              className={styles["chat-input"]}
              autoSize={{ minRows: 1, maxRows: 18 }}
            />
            {!disableChat && (
              <div className={styles["chat-send-box"]}>
                {!submitLoading ? (
                  <SendOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      fontSize: 28,
                    }}
                    onClick={() => props.bloc.onPressEnter()}
                  />
                ) : (
                  <LoadingOutlined
                    style={{
                      fontSize: 28,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ActionButton(props: { loading: boolean; children?: ReactNode; onClick?: () => void }): JSX.Element {
  const suggestionClassName = classNames(styles.chatboxItem, styles.suggestion);

  return (
    <div className={styles.suggestionWrapper}>
      <div className={classNames(suggestionClassName, styles.action)} onClick={props.onClick}>
        {props.loading ? (
          <span className={props.children && styles.buildButtonLoading}>
            <LoadingOutlined />
          </span>
        ) : null}
        {props.children}
      </div>
    </div>
  );
}

function Suggestion(props: {
  bloc: BlocType;
  item: Message;
  handleRegenerate: () => void;
  onClick: (suggestion: string) => void;
  isLast: boolean;
}): JSX.Element {
  const suggestionClassName = classNames(styles.chatboxItem, styles.suggestion);
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const chatOnboardingState = useStore(chatOnboardingStore).value;
  if (props.bloc.state.actionLoading.value && props.isLast) {
    return (
      <div>
        <ActionButton loading={true}></ActionButton>
      </div>
    );
  }
  if (chatOnboardingState.showMakeItReal && props.isLast) {
    return (
      <ActionButton loading={props.bloc.state.buildWaiting.value} onClick={() => props.bloc.handleClickBuildButton()}>
        {props.bloc.buildAppButtonText}
      </ActionButton>
    );
  }

  if (chatOnboardingState.showFunctionalityCheck && props.isLast) {
    return (
      <ActionButton
        loading={props.bloc.state.buildWaiting.value}
        onClick={() => props.bloc.handleClickFunctionalityCheck()}
      >
        {props.bloc.functionalityCheckButtonText}
      </ActionButton>
    );
  }

  return (
    <div className={styles.suggestionWrapper}>
      {map(props.item.suggestion, message => {
        if (isBlank(message)) {
          return null;
        }
        return (
          <div onClick={() => props.onClick(message)} className={suggestionClassName} key={message}>
            {message}
          </div>
        );
      })}

      {!props.bloc.state.submitLoading.value && props.isLast && !props.item.content && (
        <div onClick={() => props.handleRegenerate()} className={classNames(suggestionClassName, styles.regenerate)}>
          <RegenerateIcon />
          <span className={styles.txt}>Regenerate</span>
        </div>
      )}
    </div>
  );
}

function ErrorContent(): JSX.Element {
  return (
    <div className={styles.contentError}>
      <div className={styles.errorTitle}>An error occurred.</div>
      <div>Something went wrong. Please try again.</div>
    </div>
  );
}

function ChatMessage(props: Message & { bloc: BlocType; isLast?: boolean }): JSX.Element {
  const ALLOWED_MARKDOWN_ELEMENTS = ["p", "ul", "li"];

  const MessageText = function (props: { text: string }): JSX.Element {
    return (
      <Markdown allowedElements={ALLOWED_MARKDOWN_ELEMENTS}>
        {props.text.replace(/\{[^}]*\}/g, "").replaceAll("\\n", "\n")}
      </Markdown>
    );
  };

  if (props.loading) {
    return (
      <div className={styles.content}>
        <Spin />
      </div>
    );
  }

  if (isBlank(props.role)) {
    return (
      <div className={styles.content}>
        {props.title && <div className={styles.chatboxTitle}>{props.title}</div>}
        <MessageText text={props.content} />
      </div>
    );
  }
  return (
    <>
      <UserLogo role={props.role as Role} />
      <div className={styles.content}>
        <div className={styles.contentTitleBar}>
          <p className={styles.chatboxRole}>{props.role === Role.user ? "You" : "Imagica"}</p>
          {props.talkBuilder && props.talkBuilder.content && (
            <div className={styles.talkBuilderTag}>{props.talkBuilder.content}</div>
          )}
        </div>
        <div className={styles.chatboxText}>
          {previewStore.state.chatMessageLoading && props.isLast ? (
            <LoadingOutlined />
          ) : (
            <>{props.content ? <MessageText text={props.content} /> : <ErrorContent />}</>
          )}
        </div>
      </div>
    </>
  );
}

function UserLogo(props: { role: Role | string }): JSX.Element {
  const { userAvatarUrl } = useGetUserAvatarUrl();
  if (props.role === Role.user || props.role === "user") {
    return <img className={styles.userLogo} alt="" src={userAvatarUrl || settings.getViewAssetsPath("default.jpg")} />;
  }
  return (
    <video
      className={styles.userLogo}
      loop
      autoPlay
      playsInline
      x5-playsinline="true"
      webkit-playsinline="true"
      style={{ zIndex: 10 }}
      src={ImagicaUrl}
    />
  );
}

function Process(props: { step: GenUIProcess; bloc: BlocType }): JSX.Element {
  return (
    <div className={styles.processBar}>
      {props.bloc.state.process.value.map((process, index) => (
        <div key={index} className={styles.step}>
          {index != 0 && <div className={cls(styles.stepLine, index > props.step && styles.stepLine_disabled)} />}
          <div className={styles.stepContent}>
            <div className={cls(styles.stepIcon, index > props.step && styles.stepIcon_disabled)}>
              {index < props.step ? <StepFinishIcon /> : `${index + 1}`}
            </div>
            <div className={cls(index > props.step && styles.stepTitle_disabled)}>{process}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
