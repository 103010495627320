"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DestinationSource = exports.AttractionImage = exports.DestinationListItem = exports.VBDataTouristAttraction = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataTouristAttraction extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.destination_list = [];
    }
}
exports.VBDataTouristAttraction = VBDataTouristAttraction;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => DestinationListItem, [])
], VBDataTouristAttraction.prototype, "destination_list", void 0);
class DestinationListItem {
    constructor() {
        this.tourist_attraction_name = "";
        this.gallery_title = "";
        this.slidable_tourist_attraction_images = [];
        this.source = new DestinationSource();
    }
}
exports.DestinationListItem = DestinationListItem;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => AttractionImage, [])
], DestinationListItem.prototype, "slidable_tourist_attraction_images", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => DestinationSource)
], DestinationListItem.prototype, "source", void 0);
class AttractionImage {
    constructor() {
        this.image = "";
    }
}
exports.AttractionImage = AttractionImage;
class DestinationSource {
    constructor() {
        this.source_icon = "";
        this.source_text = "";
    }
}
exports.DestinationSource = DestinationSource;
