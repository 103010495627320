import classNames from "classnames";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import func from "@uikit/func";
import style from "./PreviewV2ToolBar.module.scss";

import { BoldIcon, CaptionIcon, DIcon, EditPlaceholderIcon, ItalicIcon, UnderlineIcon } from "./assets/svg";
const editImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-edit.svg`;
// const editWhiteImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-edit-white-1.svg`;
const editPlaceholderImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-edit-placeholder.svg`;
// const editPlaceholderWhiteImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-edit-placeholder-white-2.svg`;
const deleteImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-delete.svg`;
const textStyleImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-textStyle.svg`;
const boldImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-bold.svg`;
// const boldWhiteImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-bold-white.svg`;
const italicImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-italic.svg`;
// const italicWhiteImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-italic-white.svg`;
const underlineImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-underline.svg`;
// const underlineWhiteImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-underline-white.svg`;
const beautifyImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-beautify.svg`;
const beautifyWhiteImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-beautify-white.svg`;
const captionToggleImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-caption-toggle.svg`;
// const captionToggleWhiteImg = `${settings.S3CDN}${settings.viewAssetsPath}preview-toolbar-caption-toggle-white.svg`;

const IconRender = (props: {
  defaultUrl: string;
  icon: JSX.Element;
  isMobile: boolean;
  isHighlight: boolean;
}): JSX.Element => {
  if (props.isMobile) {
    return props.icon;
  }
  return <img src={props.defaultUrl} alt="" />;
};

// child component
export const EditCp = (props: any): JSX.Element => {
  return (
    <div
      className={`${style["cp-box"]} ${props.highlightBackground ? style["high-light-bar"] : ""}`}
      onClick={props.onClickEdit}
    >
      <IconRender
        defaultUrl={editImg}
        icon={<EditPlaceholderIcon />}
        isMobile={props.isMobile}
        isHighlight={props.highlightBackground}
      />
    </div>
  );
};
export const EditPlaceHolderCp = (props: any): JSX.Element => {
  return (
    <div
      className={`${style["cp-box"]} ${style["cp-box-no-fill"]} ${
        props.highlightBackground ? style["high-light-bar"] : ""
      }`}
      onClick={props.onClickEditPlaceholder}
    >
      <IconRender
        defaultUrl={editPlaceholderImg}
        icon={<EditPlaceholderIcon />}
        isMobile={props.isMobile}
        isHighlight={props.highlightBackground}
      />
    </div>
  );
};
export const BoldCp = (props: any): JSX.Element => {
  const fontWeight = props.currentOutput.results?.[0]?.nodeFontStyle?.fontWeight;
  const isBold = fontWeight === "bold" || fontWeight > 400;
  const className = classNames(
    style["cp-box"],
    { [style.disable]: props.disable },
    { [style["high-light-border"]]: isBold && !props.disable },
    props.className
  );
  return (
    <div
      className={className}
      onClick={() => {
        if (props.disable) return;
        props.clickOutputCssStyleCp("isBold");
      }}
    >
      <IconRender defaultUrl={boldImg} icon={<BoldIcon />} isMobile={props.isMobile} isHighlight={isBold} />
    </div>
  );
};
export const ItalicCp = (props: any): JSX.Element => {
  const fontStyle = props.currentOutput.results?.[0]?.nodeFontStyle?.fontStyle;
  const isItalic = fontStyle === "italic";
  const className = classNames(
    style["cp-box"],
    { [style.disable]: props.disable },
    { [style["high-light-border"]]: isItalic && !props.disable },
    props.className
  );
  return (
    <div
      className={className}
      onClick={() => {
        if (props.disable) return;
        props.clickOutputCssStyleCp("isItalic");
      }}
    >
      <IconRender defaultUrl={italicImg} icon={<ItalicIcon />} isMobile={props.isMobile} isHighlight={isItalic} />
    </div>
  );
};
export const UnderLineCp = (props: any): JSX.Element => {
  const isUnderLine = props.currentOutput.results?.[0]?.nodeFontStyle?.textDecoration === "underline";
  const className = classNames(
    style["cp-box"],
    { [style.disable]: props.disable },
    { [style["high-light-border"]]: isUnderLine && !props.disable },
    props.className
  );
  return (
    <div
      className={className}
      onClick={() => {
        if (props.disable) return;
        props.clickOutputCssStyleCp("isUnderLine");
      }}
    >
      <IconRender
        defaultUrl={underlineImg}
        icon={<UnderlineIcon />}
        isMobile={props.isMobile}
        isHighlight={isUnderLine}
      />
    </div>
  );
};
export const BeautifyCp = (props: any): JSX.Element => {
  const isBeautify = props.currentOutput.results?.[0]?.nodeFontStyle?.background === "transparent";
  const className = classNames(
    style["cp-box"],
    { [style.disable]: props.disable },
    { [style["high-light-border"]]: isBeautify && !props.disable },
    props.className
  );
  let src = beautifyImg;
  if (props.isMobile || isBeautify) {
    src = beautifyWhiteImg;
  }
  return (
    <div
      className={className}
      onClick={() => {
        if (props.disable) return;
        props.clickOutputCssStyleCp("isBeautify");
      }}
    >
      {props.isMobile ? <i className={style.rect}></i> : <img src={src} alt="" />}
    </div>
  );
};
export const CaptionToggleCp = (props: any): JSX.Element => {
  const isCaptionToggle = !func.isEmpty(props.currentOutput.results?.[0]?.isCaptionToggle);
  const hasContext = !func.isEmpty(props.currentOutput.results?.[0]?.content?.context);
  const isDisabled = !hasContext;
  const classArr = [
    `${style["cp-box"]}`,
    `${isCaptionToggle ? style["high-light-bar"] : ""}`,
    `${isDisabled ? style["disabled-bar"] : ""}`,
  ];
  const onClick = (): void => {
    if (isDisabled) return;
    props.clickOutputStateCp("isCaptionToggle");
  };

  return (
    <div className={classArr.join(" ")} onClick={onClick}>
      <IconRender
        defaultUrl={captionToggleImg}
        icon={<CaptionIcon />}
        isMobile={props.isMobile}
        isHighlight={isCaptionToggle}
      />
    </div>
  );
};
export const DeleteCp = (props: any): JSX.Element => {
  return (
    <div className={classNames(style["cp-box"], style["cp-box-no-fill"])} onClick={props.onClickRemove}>
      <IconRender defaultUrl={deleteImg} icon={<DIcon />} isMobile={props.isMobile} isHighlight={true} />
    </div>
  );
};
export const TextStyleCp = (props: any): JSX.Element => {
  return (
    <div className={style["cp-box"]} onClick={props.onClickOpenAppDesign}>
      <img src={textStyleImg} alt="" />
    </div>
  );
};
