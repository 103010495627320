"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Source = exports.NewsArticle = exports.NewsFeed = exports.VBDataNewsDigest = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataNewsDigest extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.news_feed = [];
    }
}
exports.VBDataNewsDigest = VBDataNewsDigest;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => NewsFeed, [])
], VBDataNewsDigest.prototype, "news_feed", void 0);
class NewsFeed {
    constructor() {
        this.news_article = new NewsArticle();
    }
}
exports.NewsFeed = NewsFeed;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => NewsArticle)
], NewsFeed.prototype, "news_article", void 0);
class NewsArticle {
    constructor() {
        this.author_name = "";
        this.news_category = "";
        this.news_image = "";
        this.news_preview = "";
        this.news_title = "";
        this.news_url = "";
        this.read_time = "";
        this.source = new Source();
        this.time_posted = "";
    }
}
exports.NewsArticle = NewsArticle;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Source)
], NewsArticle.prototype, "source", void 0);
class Source {
    constructor() {
        this.source_icon = "";
        this.source_text = "";
    }
}
exports.Source = Source;
