import { Modal } from "antd";
import { PlanTable } from "./components/PlanTable";
import css from "./index.module.scss";
import classNames from "classnames";
import { getIt } from "@uikit/getIt";
import { useCallback, useEffect, useMemo, useRef } from "react";
import LoadingOutline from "@views/components/LoadingOutline";
import { useNavigate, useLocation } from "react-router-dom";
import { PriceAlertView } from "./components/PriceAlert/PriceAlertView";
import { useCreation } from "ahooks";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { createContainer } from "unstated-next";
import { DowngradeAlertView } from "./components/DowngradeAlert/DowngradeAlertView";
import { throttle } from "lodash";
import { useSignal } from "@preact/signals-react";
import { ImagicaCreatorPlanElement } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { DomUtil } from "imagica-uikit/dist/base/util/DomUtil";
import { PlansListBloc } from "@uikit/planList/PlansListBloc";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";

export const { Provider: PlanListProvider, useContainer: usePlanList } = createContainer(() => {
  const location = useLocation();
  const brainClient = getIt(BrainClient);
  const paybloc = useCreation(
    () =>
      new PlansListBloc(
        location.pathname,
        brainClient,
        getIt(CreatorSaasAppStore),
        getIt(SubscriptionStore),
        getIt(FotSaveService),
        getIt(ShowNavPageStore)
      ),
    []
  );

  const isScrollBottom = useSignal<boolean>(false);
  const popupBodyRef = useRef<HTMLDivElement>(null);

  const _onScrollPopupWrap = useCallback(() => {
    const target = popupBodyRef.current;
    if (!target) {
      return;
    }
    isScrollBottom.value = DomUtil.hasScrollToBottom(target);
  }, []);

  const onScrollPopupWrap = useMemo(() => {
    return throttle(_onScrollPopupWrap, 150);
  }, [_onScrollPopupWrap]);

  useEffect(() => {
    _onScrollPopupWrap();
    window.addEventListener("resize", onScrollPopupWrap);
    return (): void => {
      window.removeEventListener("resize", onScrollPopupWrap);
    };
  }, [paybloc.state.planTablePopupSingle.value.loading]);

  return {
    paybloc,
    onScrollPopupWrap,
    isScrollBottom,
    popupBodyRef,
  };
});

function PlansListInner(): JSX.Element {
  const { paybloc, onScrollPopupWrap, isScrollBottom, popupBodyRef } = usePlanList();
  const navigate = useNavigate();
  const location = useLocation();

  const onClose = useCallback(() => {
    const path = location.pathname.split("/").slice(0, -1).join("/");
    navigate(path);
  }, []);

  useEffect(() => {
    paybloc.initData();
  }, []);

  return (
    // FIXME: 将 transitionName 动画去掉，因为 full 样式修改了 transfrom,会导致组件出现位置会有偏移
    <Modal
      transitionName="none"
      className={classNames(css.planTablePopup, css.full)}
      width="auto"
      open
      footer={false}
      closable={false}
    >
      <div className={classNames(css.planPopupWrap, { [css.bottomFade]: !isScrollBottom.value })}>
        <div className={css.popupHeader}>
          <span className={css.title}>Choose a plan</span>

          <span className={classNames(css.icon, css.closeIcon)} onClick={onClose}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#ACAEB5" />
              <path
                d="M6.46484 13.5352L13.5359 6.46409"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5352 13.5352L6.46409 6.46409"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>

        <div className={classNames(css.popupBody)} ref={popupBodyRef} onScroll={onScrollPopupWrap}>
          {paybloc.state.planTablePopupSingle.value.loading ? (
            <LoadingOutline />
          ) : (
            <div className={css.tableMain}>
              <PlanTable
                buttonLoading={
                  paybloc.state.showAlertView.value || paybloc.state.showDownAlertView.value
                    ? { value: paybloc.state.selectPlan.value.value, loading: false }
                    : { value: paybloc.state.selectPlan.value.value, loading: paybloc.state.upgrageLoading.value }
                }
                options={paybloc.state.planTablePopupSingle.value.options}
                featureOptions={paybloc.state.planTablePopupSingle.value.features}
                onUpgradeClick={plan => {
                  paybloc.clickUpgradeBtn(plan);
                }}
                onDowngradeClick={plan => {
                  paybloc.clickDowngradeBtn(plan);
                }}
              />
            </div>
          )}
        </div>
      </div>

      {paybloc.state.showAlertView.value && (
        <PriceAlertView
          payLoading={paybloc.state.upgrageLoading.value}
          plan={paybloc.state.selectPlan.value}
          onPay={(priceObj: ImagicaCreatorPlanElement) => {
            paybloc.confirmUpgrade(priceObj);
          }}
          onClose={() => (paybloc.state.showAlertView.value = false)}
        />
      )}
      {paybloc.state.showDownAlertView.value && (
        <DowngradeAlertView
          downLoading={paybloc.state.upgrageLoading.value}
          plan={paybloc.state.selectPlan.value}
          onClose={() => (paybloc.state.showDownAlertView.value = false)}
        />
      )}
    </Modal>
  );
}

export function PlansList(): JSX.Element {
  return (
    <PlanListProvider>
      <PlansListInner />
    </PlanListProvider>
  );
}
