import isBlank from "@sedan-utils/is-blank";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { ViewBuilderV2 } from "imagica-uikit/dist/nodesV2/ViewBuilderV2/ViewBuilderV2";
import React from "react";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";

const AIViewBuilder = React.memo(function ViewBuilder(): JSX.Element | null {
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;

  const viewbuilderStore = getIt(ViewbuilderStore);
  const viewbuilderStoreState = useStore(viewbuilderStore).value;

  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;

  if (featureTags.disableViewBuilderToolBoxIntegration === true) {
    return null;
  }

  /**
   * 耦合绑定 saasUIData viewBuilderGraph 需要优化
   */
  if (creatorSaasAppState.saasUIData.viewBuilderGraph === undefined) {
    return null;
  }

  if (isBlank(viewbuilderStoreState.viewBuilderGraph?.design) === false) {
    return <ViewBuilderV2 list={viewbuilderStoreState.viewBuilderGraph?.design ?? []}></ViewBuilderV2>;
  }

  return null;
});

export { AIViewBuilder };
