import { getIt } from "@uikit/getIt";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { MissingApi } from "imagica-corekit/dist/base/api/blueprintTyped/Blueprint";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import React, { useState } from "react";
import { BlueprintCommandItem } from "./components/BlueprintCommandItem";

type UnsupportedBlueprintFlowProps = {
  children: React.ReactNode;
  onClick: (data: MissingApi[] | undefined) => void;
};

export function UnsupportedBlueprintFlow(props: UnsupportedBlueprintFlowProps) {
  const brainClient = getIt(BrainClient);

  const [loading, setLoading] = useState<boolean>(false);

  const handleClickBtn = async () => {
    setLoading(true);
    const result = await tryPromise(brainClient.openAi.missingApis());
    setLoading(false);
    props.onClick(result.data?.data.missing_apis);
  };

  return (
    <BlueprintCommandItem loading={loading} onClick={handleClickBtn}>
      {props.children}
    </BlueprintCommandItem>
  );
}
