import styles from "./ChargeActiveLink.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { useSignal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";

export interface Props {
  link: string;
}

export function ChargeActiveLink(props: Props) {
  const fotSaveService = getIt(FotSaveService);
  const isLoading = useSignal<boolean>(false);

  const clickLink = async () => {
    isLoading.value = true;
    const res = await tryPromise(fotSaveService.handleSave({}));
    if (res.error) {
      console.error("save error");
    }
    isLoading.value = false;
    window.open(props.link, "_self");
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Imagica uses Stripe to provide payment services for our creators. Please activate your{" "}
        <span onClick={clickLink}>Stripe Connect</span> account using the link below.
      </div>
      <p className={`${styles.link} ${isLoading.value ? styles.loading : ""}`} onClick={() => clickLink()}>
        {isLoading.value ? <LoadingOutlined /> : props.link}
      </p>
    </div>
  );
}
