import { FunctionModel } from "../../types";

export interface FunctionDropdownItemArgs {
  type: FunctionDropdownItems;
  func: FunctionModel;
}

export enum FunctionDropdownItems {
  Duplicate,
  Delete,
}
