"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component = exports.Data = exports.VBBlueprintExecuteResponse = exports.Input = exports.VBBlueprintExecuteRequest = void 0;
const VBGraphDataBase_1 = require("../../api/viewBuilderTyped/VBGraphData/VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../cutil/ClassTransformerDecorators");
class VBBlueprintExecuteRequest {
    constructor() {
        this.id = "";
        this.stream = false;
        this.inputs = [];
        this.context = "";
    }
}
exports.VBBlueprintExecuteRequest = VBBlueprintExecuteRequest;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Input, [])
], VBBlueprintExecuteRequest.prototype, "inputs", void 0);
class Input {
    constructor() {
        this.description = "";
        this.name = "";
        // reason: string = "";
        this.type = "";
        this.value = "";
        this.user_input_id = "";
    }
}
exports.Input = Input;
class VBBlueprintExecuteResponse {
    constructor() {
        this.data = new Data();
        this.message = "";
    }
}
exports.VBBlueprintExecuteResponse = VBBlueprintExecuteResponse;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Data)
], VBBlueprintExecuteResponse.prototype, "data", void 0);
class Data {
    constructor() {
        this.allow_custom_value = false;
        this.components = [];
        this.default_value = undefined;
        this.description = "";
        this.id = "";
        this.inputs = [];
        this.list = false;
        this.name = "";
        this.optional = false;
        this.reason = "";
        this.type = "";
        /**
         * 这里只能知道基类，因为无法从这里的数据找到componentId，这里不直接转，而是再调用的地方再转
         */
        this.value = new VBGraphDataBase_1.VBGraphDataBase();
    }
}
exports.Data = Data;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Component, [])
], Data.prototype, "components", void 0);
class Component {
    constructor() {
        this.allow_custom_value = false;
        this.components = [];
        this.default_value = undefined;
        this.description = "";
        this.id = "";
        this.inputs = [];
        this.list = false;
        this.name = "";
        this.optional = false;
        this.reason = "";
        this.type = "";
        this.value = undefined;
    }
}
exports.Component = Component;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Component, [])
], Component.prototype, "components", void 0);
