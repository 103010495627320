import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { isEmpty } from "lodash";

export class ProcessLang {
  static GUEST_TOKEN_KEY = "GUEST_TOKEN";

  constructor(
    public authStore: FotAuthStore,
    public homePluginStore: HomePluginStore,
    public sessionStorage: WindowSessionStorage["sessionStorage"]
  ) {}

  isGuestUser(): boolean {
    return !!this.sessionStorage.getItem(ProcessLang.GUEST_TOKEN_KEY) && !this.authStore.state.brainToken;
  }

  isAuth(): boolean {
    const isSharedApp = this.homePluginStore.state.isShare;
    const authToken = this.authStore.state.brainToken;
    return !isEmpty(authToken) || (isSharedApp && this.isGuestUser());
  }
}
