"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = void 0;
const Me_1 = require("../api/userTyped/Me");
const PreferencesEntry_1 = require("../api/userTyped/PreferencesEntry");
const LangUtil_1 = require("../cutil/LangUtil");
class UserApi {
    constructor(restClient, customDataClient) {
        this.restClient = restClient;
        this.customDataClient = customDataClient;
    }
    async me() {
        const result = await this.restClient.get("/api/users/me.json");
        return result.toModelFromType(Me_1.Me);
    }
    // upload data source
    uploadDataSource(file) {
        const formData = new FormData();
        formData.append("file", file);
        return this.restClient.post("/api/me/upload/studios", formData).then(res => res.validate());
    }
    // search from user added data sources
    searchInDataSource(params, uuid) {
        return this.restClient.post("/be/vectordb/indexers/", params, undefined, undefined, undefined, uuid);
    }
    /**
     * 返回对象是一个GlobalResponse
     * @param params
     * @param uuid
     */
    searchInDataSourceV2(params, uuid) {
        return this.customDataClient.post("/be/vectordb/indexers/", params, undefined, undefined, undefined, uuid);
    }
    unpublish(project_id) {
        return this.restClient.post("/api/studios/project/unpublish/", { project_id }).then(res => res.validate());
    }
    preferences() {
        return this.restClient.get("/api/users/me/preferences").then(res => res.toArrayFromType(PreferencesEntry_1.PreferencesEntry));
    }
    setPreference(params) {
        return this.restClient
            .post("/api/users/me/preferences.json", params)
            .then(res => res.toArrayFromType(PreferencesEntry_1.PreferencesEntry));
    }
    /**
     * 更新个人状态
     * @param params
     */
    updateProfile(params) {
        return this.restClient.patch("/api/users/me?_mode=profile", params).then(res => res.toModelFromType(Me_1.Me));
    }
    deleteIconUrl(deleteUrl) {
        const url = "/api/users/me/profile";
        const params = {
            url: deleteUrl,
        };
        return this.restClient.delete(url).then(result => {
            return result;
        });
    }
    /**
     * 将url 通过blob url形式返回
     * @param url
     */
    async getBlobUrl(url) {
        const request = this.restClient
            .get(url)
            .then(response => {
            if (!response.data) {
                return Promise.reject();
            }
            return response.data.blob();
        })
            .then(blob => {
            const fileUrl = URL.createObjectURL(blob);
            return { fileUrl, blob };
        });
        const result = await (0, LangUtil_1.tryPromise)(request);
        return result.data?.fileUrl;
    }
    /**
     *
     * @returns otp的超时时间 second
     */
    async getDeleteAccountOTP() {
        const url = "/api/users/me/delete";
        const para = { verify_type: "email" };
        return this.restClient.post(url, para).then(result => {
            return result.validate();
        });
    }
    /**
     * 删除账号
     * @param otp 邮箱otp
     * @returns
     */
    async deleteAccount(otp) {
        const url = "/api/users/me/delete";
        const para = {
            verify_type: "email",
            otp: otp,
        };
        return this.restClient.post(url, para).then(result => {
            return result.validate();
        });
    }
}
exports.UserApi = UserApi;
