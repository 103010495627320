import { DisplayType } from "./typing";

export class NodeLayoutStyle {
  type: DisplayType;
  /// 默认大小，用于初始化，和还原
  width: number;
  height: number;
  /// 限制尺寸，用于拖动、自适应、滚动
  minHeight?: number | undefined;
  maxHeight?: number | undefined;
  minWidth?: number | undefined;
  maxWidth?: number | string | undefined;
  /// 初始化的时候高度自适应，例如：ask imagica、text
  autoHeight?: boolean = false;
  /// 是否保持原比例拖动： 例如：图片、视频
  /// 暂时不启用，这个功能 和 limitSize有一定冲突
  keepAspectRatio?: boolean = false;
  /// 是否允许拖动大小： 默认 true
  enableResize: boolean = true;

  constructor(props: {
    type: DisplayType;
    width: number;
    height: number;
    minHeight?: number | undefined;
    maxHeight?: number | undefined;
    minWidth?: number | undefined;
    maxWidth?: number | string | undefined;
    autoHeight?: boolean | false;
    keepAspectRatio?: boolean | false;
    enableResize?: boolean | true;
  }) {
    this.type = props.type;
    this.width = props.width;
    this.height = props.height;
    this.minHeight = props.minHeight;
    this.maxHeight = props.maxHeight;
    this.minWidth = props.minWidth;
    this.maxWidth = props.maxWidth;
    this.autoHeight = props.autoHeight;
    this.keepAspectRatio = props.keepAspectRatio;
    this.enableResize = props.enableResize ?? true;
  }

  toStyle() {
    const style: {
      width: number;
      height?: number;
      maxHeight?: number;
      minHeight?: number;
      minWidth?: number;
      maxWidth?: number | string;
    } = {
      width: this.width,
      height: this.height,
      maxHeight: this.maxHeight,
      minHeight: this.minHeight,
      minWidth: this.minWidth,
      maxWidth: this.maxWidth,
    };
    return style;
  }
}

export const defaultSize = {
  width: 250,
  height: 250,
};

export const defaultLimitSize = {
  minWidth: 250,
  minHeight: 250,
  maxWidth: 400,
  maxHeight: 850,
};

export const nodeLayoutStyleLists: Array<NodeLayoutStyle> = [
  new NodeLayoutStyle({ type: "amazonShopping", width: 285, height: 455 }),
  new NodeLayoutStyle({ type: "jdShopping", width: 285, height: 455 }),
  new NodeLayoutStyle({ type: "weeeShopping", width: 285, height: 455 }),
  new NodeLayoutStyle({ type: "imageGen", width: 250, height: 250, ...defaultLimitSize, autoHeight: true }),
  new NodeLayoutStyle({ type: "midjourney", width: 250, height: 250, ...defaultLimitSize }),
  new NodeLayoutStyle({ type: "midjourneyV2", width: 250, height: 250, ...defaultLimitSize }),
  new NodeLayoutStyle({ type: "imageSearch", width: 250, height: 250, ...defaultLimitSize, autoHeight: true }),
  new NodeLayoutStyle({
    type: "Agent",
    width: 320,
    height: 570,
    minWidth: 320,
    minHeight: 500,
    maxHeight: 850,
    autoHeight: true,
  }),
  new NodeLayoutStyle({ type: "uploadFile", width: 280, height: 400, autoHeight: true }),
  new NodeLayoutStyle({
    type: "text",
    width: 250,
    height: 150,
    maxHeight: 850,
    minHeight: 150,
    minWidth: 250,
    maxWidth: 400,
    autoHeight: true,
  }),
  new NodeLayoutStyle({ type: "video", width: 280, height: 550, maxWidth: 400, autoHeight: true }),
  new NodeLayoutStyle({ type: "customJsFunction", width: 320, height: 370 }),
  new NodeLayoutStyle({ type: "stockInfo", width: 300, height: 275, autoHeight: true }),
  new NodeLayoutStyle({ type: "htmlTemplate", width: 300, height: 280 }),
  new NodeLayoutStyle({ type: "customApiFunction", width: 300, height: 420 }),
  new NodeLayoutStyle({ type: "chatBox", width: 400, height: 440 }),
  new NodeLayoutStyle({ type: "inputTextarea", width: 200, height: 200 }),
  new NodeLayoutStyle({ type: "showFoodInterface", width: 295, height: 510 }),
  new NodeLayoutStyle({ type: "map", width: 300, height: 600 }),
  new NodeLayoutStyle({ type: "blueprint", width: 400, height: 400 }),
  new NodeLayoutStyle({ type: "json", width: 300, height: 600 }),
  new NodeLayoutStyle({
    type: "askBrain",
    width: 320,
    height: 680,
    minWidth: 300,
    minHeight: 350,
    maxWidth: 400,
    maxHeight: 850,
    autoHeight: true,
  }),
  new NodeLayoutStyle({ type: "baseVideo", width: 260, height: 260, keepAspectRatio: true, autoHeight: true }),
  new NodeLayoutStyle({ type: "audio", width: 285, height: 235, enableResize: false }),
  new NodeLayoutStyle({ type: "unStructured", width: 285, height: 400 }),
  new NodeLayoutStyle({
    type: "table",
    width: 460,
    height: 320,
    minHeight: 320,
    maxHeight: 850,
    maxWidth: "100%",
    autoHeight: true,
  }),
  new NodeLayoutStyle({ type: "pdfFile", width: 360, height: 438 }),
  new NodeLayoutStyle({ type: "uploadImage", width: 250, height: 250, minHeight: 150, autoHeight: true }),
  new NodeLayoutStyle({
    type: "chatInterface",
    width: 327,
    height: 535,
    minWidth: 327,
    minHeight: 535,
    maxWidth: 327,
    maxHeight: 800,
    autoHeight: true,
  }),
  new NodeLayoutStyle({ type: "html", width: 300, height: 300, minHeight: 300, maxHeight: 850, autoHeight: true }),
  new NodeLayoutStyle({
    type: "interviewBot",
    width: 320,
    height: 500,
    minHeight: 500,
    maxWidth: 400,
    minWidth: 300,
    maxHeight: 850,
  }),
  new NodeLayoutStyle({ type: "list", width: 250, height: 400 }),
  /// Add More
  // new NodeLayoutStyle({type: DisplayTpye, width: number, height: number}),
];
