import { Text } from "../../Text/TextNode";
import { DishRecommendation } from "../../ViewBuilderComponents/DishRecommendation/DishRecommendation";
import { ImageNewsGrid } from "../../ViewBuilderComponents/ImageNewsGrid/ImageNewsGrid";
import { ItineraryPlanning } from "../../ViewBuilderComponents/ItineraryPlanning/ItineraryPlanning";
import { Map } from "../Map/Map";
import { NewsDigest } from "../../ViewBuilderComponents/NewsDigest/NewsDigest";
import { NewsDigestDetails } from "../../ViewBuilderComponents/NewsDigestDetails/NewsDigestDetails";
import { NewsHeadline } from "../../ViewBuilderComponents/NewsHeadline/NewsHeadline";
import { NewsVideo } from "../../ViewBuilderComponents/NewsVideo/NewsVideo";
import { NewsVideoList } from "../../ViewBuilderComponents/NewsVideoList/NewsVideoList";
import { RestaurantRecommendation } from "../../ViewBuilderComponents/RestaurantRecommendation/RestaurantRecommendation";
import { SeasonalTravelDestinationPlan } from "../../ViewBuilderComponents/SeasonalTravelDestinationPlan/SeasonalTravelDestinationPlan";
import { ShoppingProduct } from "../../ViewBuilderComponents/ShoppingProduct/ShoppingProduct";
import { ShoppingSearchResults } from "../../ViewBuilderComponents/ShoppingSearchResults/ShoppingSearchResults";
import { TopicWithRelatedDetail } from "../../ViewBuilderComponents/TopicWithRelatedDetail/TopicWithRelatedDetail";
import { TopicWithRelatedPeople } from "../../ViewBuilderComponents/TopicWithRelatedPeople/TopicWithRelatedPeople";
import { TouristAttraction } from "../../ViewBuilderComponents/TouristAttraction/TouristAttraction";
import { WeatherCurrent } from "../../ViewBuilderComponents/WeatherCurrent/WeatherCurrent";
import { WeatherWeekly } from "../../ViewBuilderComponents/WeatherWeekly/WeatherWeekly";
import { YoutubeSearchResults } from "../../ViewBuilderComponents/YoutubeSearchResults/YoutubeSearchResults";
import { YoutubeVideo } from "../../ViewBuilderComponents/YoutubeVideo/YoutubeVideo";
import { C1 } from "../C1/C1";
import { C2 } from "../C2/C2";
import { C3 } from "../C3/C3";
import { C4 } from "../C4/C4";
export var VBComponentsMapping = {
  NewsVideo: NewsVideo,
  YoutubeVideo: YoutubeVideo,
  YoutubeSearchResults: YoutubeSearchResults,
  "Card with Image and Text Below": C1,
  "Card with Multiple Images in a Story Format": C2,
  "Card with Image and Text Side by Side (50-50)": C3,
  "Card with Image and Text Side by Side (20-80)": C4,
  "map view": Map,
  "show trip on a map": Map,
  "text body": Text,
  ShoppingSearchResults: ShoppingSearchResults,
  WeatherCurrent: WeatherCurrent,
  WeatherWeekly: WeatherWeekly,
  ItineraryPlanning: ItineraryPlanning,
  TouristAttraction: TouristAttraction,
  NewsHeadline: NewsHeadline,
  ShoppingProduct: ShoppingProduct,
  NewsVideoList: NewsVideoList,
  RestaurantRecommendation: RestaurantRecommendation,
  DishRecommendation: DishRecommendation,
  TopicWithRelatedPeople: TopicWithRelatedPeople,
  TopicWithRelatedDetail: TopicWithRelatedDetail,
  SeasonalTravelDestinationPlan: SeasonalTravelDestinationPlan,
  NewsDigest: NewsDigest,
  ImageNewsGrid: ImageNewsGrid,
  NewsDigestDetails: NewsDigestDetails
};