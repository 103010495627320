import { PreviewAppValue } from "@uiview/views/PreviewApp/model/PreviewAppValue";
import { ProjectNode } from "./model";
import { CanvasNodeType } from "@uikit/store/CreatorNodesStore";
import { CreatorNodesUtil } from "@uikit/util/CreatorNodesUtil";
import { StoryNodeType } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeType";
import { isString } from "lodash";

export class ProjectNodeUtil {
  static createProjectNode(canvasNode?: CanvasNodeType, appid?: string): ProjectNode {
    const projectNode = new ProjectNode();

    if (canvasNode) {
      projectNode.inputTitle = canvasNode.data.inputTitle;
      projectNode.generatedLabel = canvasNode.data.generatedLabel;
      projectNode.placeholder = canvasNode.data.placeholder;
      // 组节点暂时当作 text
      projectNode.displayType =
        canvasNode.type === StoryNodeType.CUSTOMGROUP ? "node-group" : canvasNode.data.displayType;
      projectNode.value = canvasNode.type === StoryNodeType.CUSTOMGROUP ? "" : canvasNode.data.textAreaValue;

      // projectNode.textAreaValue = canvasNode.data.textAreaValue;
      projectNode.id = canvasNode.id;
    }

    appid && (projectNode.appId = appid);

    return projectNode;
  }

  private static createKey(nodeid: any, appid?: any): string {
    nodeid = isString(nodeid) ? nodeid : "";
    appid = isString(appid) ? appid : "";
    return nodeid + appid;
  }

  /**
   * 整合 nodes 和 appList 中的所有input 数据(因为可能 saasUIData 中的 input 不存在 canvas中)
   *
   * 如果 node 和 appList 中重复，应该优先使用 nodes 中的字段
   *
   * FIXME:
   * **但是现在这个 PR 还没有处理 CreatorNodesStore.setNodes() 所以暂时优先 sassUIData**
   *
   * @param canvasNodes
   * @param appList
   */
  static compatibleProjectNodes(canvasNodes: CanvasNodeType[], appList: PreviewAppValue[]): ProjectNode[] {
    // key = nodeid + appid
    const nodesMap: Record<string, ProjectNode> = {};

    appList.forEach(app => {
      app.input?.forEach(node => {
        const key = ProjectNodeUtil.createKey(node.id, app.id);
        if (!nodesMap[key]) {
          nodesMap[key] = ProjectNodeUtil.createProjectNode(node, "" + app.id);
        }
      });
    });

    // 暂时不包含 CustomNewEdge
    CreatorNodesUtil.getOnlyNodes(canvasNodes).forEach(node => {
      const key = ProjectNodeUtil.createKey(node.id);
      if (!nodesMap[key]) {
        nodesMap[key] = ProjectNodeUtil.createProjectNode(node);
      }
    });

    return Object.values(nodesMap);
  }

  static filterTitleByIds(projectNodes: ProjectNode[], sourceNodeIds: any): string[] {
    return projectNodes
      .filter(node => sourceNodeIds.includes(node.id))
      .map(node => node.inputTitle || node.generatedLabel || "Input");
  }

  static getTitle(projectNode: ProjectNode | undefined, defaultTitle = ""): string {
    if (!projectNode) {
      return defaultTitle;
    }
    return projectNode.inputTitle || projectNode.generatedLabel || defaultTitle;
  }
}
