"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseDistribution = void 0;
const StoryEdge_1 = require("../../storyV2/domain/StoryEdge");
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const StoryManagerUtil_1 = require("../../storyV2/domain/StoryManagerUtil");
const BaseDistribution_1 = require("../../storyV2/response/responseDistribution/BaseDistribution");
const StreamGroupDistribution_1 = require("../../storyV2/response/responseDistribution/StreamGroupDistribution");
const NormalDistribution_1 = require("../../storyV2/response/responseDistribution/NormalDistribution");
const BluePrintDistribution_1 = require("../../storyV2/response/responseDistribution/BluePrintDistribution");
const StockDistribution_1 = require("../../storyV2/response/responseDistribution/StockDistribution");
const MidjourneyDistribution_1 = require("../../storyV2/response/responseDistribution/MidjourneyDistribution");
const StoryNodeType_1 = require("../../storyV2/domain/StoryNodeType");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const AdapterErrorUtil_1 = require("../../storyV2/adapter/AdapterErrorUtil");
const StoryNodeState_1 = require("../../storyV2/domain/StoryNodeState");
const StoryNode_1 = require("../../storyV2/domain/StoryNode");
const CustomFunctionDistirbution_1 = require("../../storyV2/response/responseDistribution/CustomFunctionDistirbution");
/**
 * run 的结果分发处理，根据不同的RunResponseType
 *
 */
class ResponseDistribution {
    constructor(registry) {
        this.registry = registry;
    }
    handleRunResponse(story, edge, res, relatedEdges, displayType) {
        console.log("handleNodeResponseV2: ", res);
        switch (res.type) {
            case AdapterBase_1.RunResponseType.FUNCTION_V2:
                return new BaseDistribution_1.BaseDistribution(this.registry).handleRunResponse(story, edge, res, relatedEdges);
            case AdapterBase_1.RunResponseType.STRING_GROUP:
                return new StreamGroupDistribution_1.StreamGroupDistribution(this.registry).handleRunResponse(story, edge, res, relatedEdges);
            case AdapterBase_1.RunResponseType.YOUTUBE_VIDEOS:
            case AdapterBase_1.RunResponseType.CHAT_INTERFACE:
            case AdapterBase_1.RunResponseType.CUSTOMCODE_FUNCTION:
                return new NormalDistribution_1.NormalDistribution(this.registry).handleRunResponse(story, edge, res, relatedEdges, displayType);
            case AdapterBase_1.RunResponseType.BLUEPRINT:
                return new BluePrintDistribution_1.BluePrintDistribution(this.registry).handleRunResponse(story, edge, res, relatedEdges);
            case AdapterBase_1.RunResponseType.STOCK:
                return new StockDistribution_1.StockDistribution(this.registry).handleRunResponse(story, edge, res, relatedEdges);
            case AdapterBase_1.RunResponseType.MIDJOURNEY:
                return new MidjourneyDistribution_1.MidjourneyDistribution(this.registry).handleRunResponse(story, edge, res, relatedEdges);
            case AdapterBase_1.RunResponseType.ERROR:
                return this.handleErrorResult(story, edge, res, relatedEdges, displayType);
            case AdapterBase_1.RunResponseType.CUSTOM_FUNCTION:
                return new CustomFunctionDistirbution_1.CustomFunctionDistirbution(this.registry).handleRunResponse(story, edge, res, relatedEdges);
            default:
                StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge], StoryEdge_1.EdgeState.COMPLETED, story);
                return Promise.resolve();
        }
    }
    handleErrorResult(story, edge, res, relatedEdges, displayType) {
        const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMNODE, displayType);
        let contentString = "";
        if (res.data === undefined) {
            contentString = undefined;
        }
        else if (typeof res.data === "string") {
            contentString = res.data;
        }
        else {
            contentString = JsonUtil_1.JsonUtil.stringify(res.data) || "";
        }
        if (contentString == undefined || contentString == "") {
            contentString = AdapterErrorUtil_1.AdapterErrorUtil.defaultErrorMessage;
        }
        targetNode.options.displayType = displayType;
        targetNode.state = StoryNodeState_1.StoryNodeState.ERROR;
        targetNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.ERROR, story);
        return Promise.resolve();
    }
}
exports.ResponseDistribution = ResponseDistribution;
