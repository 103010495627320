// 请确认安装了classnames
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Input, Result, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import style from "../components-css/JobDescription.module.css";
import { Api } from "@uikit/service/api";
import func from "@uikit/func";
import publicMethod from "@uikit/publicMethod";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

function JobDescription(props) {
  const { TextArea } = Input;
  const api = new Api(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const focusedThought = useSelector(state => state.feed.focusedThought);

  const [inputMd, setInputMd] = useState("");
  const [markdown, setMarkdown] = useState("");
  const [showEditor, setEditor] = useState(false);
  const [showPostLoading, setPostLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);

  const projectName = "jerry_job_description_demo";

  const changeTextArea = e => {
    setInputMd(e.target.value);
  };
  const clickEditorBtn = val => {
    if (!val) {
      setMarkdown(() => inputMd);
    }
    setEditor(() => val);
  };

  const handleClickRefresh = async () => {
    const res = await publicMethod.getUIFromIntelligentAppProject(projectName, focusedThought.thought, setLoading);
    const JDResult = res?.variables?.job_description?.value;
    const newArr = sanitizeMDList(false, JDResult);
    const str = newArr.join("");
    setInputMd(str);
    setMarkdown(str);
  };

  // api请求
  const postJobApi = () => {
    const mdList = markdown ? markdown.split("\n") : props.content;
    const data = sanitizeMDList(true, mdList).filter(x => x);
    return api.postWithError("/be/lambda/function/job_posting", data);
  };
  const clickPostBtn = async () => {
    if (showPostLoading) return;
    try {
      setPostLoading(true);
      await postJobApi();
      setShowResult(true);
    } catch (error) {
      console.error("clickPostBtn", error);
      func.messageError(error);
    } finally {
      setPostLoading(false);
    }
  };

  const clickToBrainAi = () => {
    window.open("https://brain.ai/");
  };
  const handleClickCard = e => {
    e.stopPropagation();
  };

  const sanitizeMDList = (noBreak = false, markdownList = props.content) => {
    const firstSharpSymbolIdx = markdownList?.findIndex?.(x => x.includes("#"));
    if (firstSharpSymbolIdx === -1 || firstSharpSymbolIdx === undefined) return [];
    const reg = /^\*\*/g;
    return markdownList.slice(firstSharpSymbolIdx).map(x => {
      if (reg.test(x)) {
        return noBreak ? x : `\n${x}\n`;
      }
      return noBreak ? x : `${x}\n`;
    });
  };

  useEffect(() => {
    const newArr = sanitizeMDList();
    const str = newArr.join("");
    setInputMd(str);
    setMarkdown(str);
  }, [props.content]);

  const SubTitleCp = () => {
    return (
      <>
        <p>view this job posting on</p>
        <Button type="link" onClick={clickToBrainAi}>
          {" "}
          brain.ai{" "}
        </Button>
      </>
    );
  };
  return (
    <Spin indicator={antIcon} spinning={loading} tip="refreshing...">
      <div className={style["Job-Description"]} onClick={handleClickCard}>
        {showResult ? (
          // 成功提示
          <Result
            status="success"
            title="Successfully Posted!"
            subTitle={<SubTitleCp />}
            extra={[
              <Button key="back" onClick={() => setShowResult(false)}>
                Back
              </Button>,
            ]}
          />
        ) : (
          <>
            {/* markdown 编辑及预览 */}
            <div className={style["Markdown-Box"]}>
              {showEditor ? (
                <TextArea autoSize={true} value={inputMd} onChange={changeTextArea} bordered={false} />
              ) : (
                <ReactMarkdown className={style["markdown-body"]} children={markdown}></ReactMarkdown>
              )}
            </div>
            {/* 底部按钮 */}
            <footer>
              <div className={style["Right-Btns"]}>
                {!showEditor ? (
                  <span className="iconfont icon-note" onClick={() => clickEditorBtn(true)}></span>
                ) : (
                  <span className="iconfont icon-duigou" onClick={() => clickEditorBtn(false)}></span>
                )}

                <div className={style["Post-Btn"]} onClick={clickPostBtn}>
                  {showPostLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <img src={`${settings.S3CDN}${settings.viewAssetsPath}in-post.png`} />
                      <span>Post</span>
                    </>
                  )}
                </div>
              </div>
            </footer>
          </>
        )}

        <div className="refresh-box" onClick={handleClickRefresh}>
          <img src={`${settings.S3CDN}${settings.viewAssetsPath}refresh.svg`} />
        </div>
      </div>
    </Spin>
  );
}

export default JobDescription;
