import { AnimatePresence, motion } from "framer-motion";
import style from "@uiview/views/HomeViews/CodeModalView/CodeModal.module.scss";
import { CodeModal } from "@uiview/views/HomeViews/CodeModalView/CodeModal";
import { bottomVariants } from "../homeConst";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import { fotActions } from "@store/fot";
import { useCallback } from "react";

export function CreatorCodeModal(): JSX.Element {
  const dispatch = useDispatch();
  const isModelOpen = useSelector((state: RootState) => state.fot.isModelOpen);
  const developerMode = useSelector((state: RootState) => state.fot.developerMode);
  const setIsModelOpen = useCallback(
    (val: any) => {
      dispatch(fotActions.setIsModelOpen(val));
    },
    [dispatch]
  );

  const setCloseModal = useCallback(() => {
    setIsModelOpen(false);
  }, [setIsModelOpen]);

  return (
    <AnimatePresence>
      {isModelOpen && developerMode ? (
        <motion.div className={style["model-box"]} variants={bottomVariants} initial="init" animate="show" exit="hide">
          <CodeModal setCloseModal={setCloseModal} />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
