import { useSignal } from "@preact/signals-react";
import { useEffect, useRef } from "react";
export function useStore(store) {
  var state = useSignal(store.state);
  var callbackRef = useRef();
  callbackRef.current = function (value) {
    state.value = value;
  };
  useEffect(function () {
    var subscription = store.on(function (msg) {
      if (callbackRef.current) {
        callbackRef.current(msg);
      }
    }, true);
    return function () {
      subscription.off();
    };
  }, []);
  return state;
}