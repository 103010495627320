"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewBuilderImgUtil = exports.DEFAULT_IMG_LIST_LEN = void 0;
const VBDataTopicListResponse_1 = require("../../base/api/viewBuilderTyped/VBGraphData/VBDataTopicListResponse");
const VBDataTopicWithRelatedPeople_1 = require("../../base/api/viewBuilderTyped/VBGraphData/VBDataTopicWithRelatedPeople");
const ViewBuilderImgService_1 = require("../service/ViewBuilderImgService");
exports.DEFAULT_IMG_LIST_LEN = 3;
class ViewBuilderImgUtil {
    static getComponentCImgs(componentData) {
        return [new ViewBuilderImgService_1.ComponentTypeImg(componentData.image)];
    }
    static getC2Imgs(componentData) {
        const imgList = [];
        componentData.image_list?.forEach?.(img => {
            imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(img.image));
        });
        return imgList;
    }
    static getItineraryPlanningImgs(componentData) {
        const imgList = [];
        componentData.itinerary_list.forEach(daily => {
            daily.daily_plan.forEach(plan => {
                imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(plan.event_image));
            });
        });
        return imgList;
    }
    static getTopicWithRelatedPeopleImgs(componentData) {
        const imgList = [];
        componentData.people_list.forEach(relatedPerson => {
            imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(relatedPerson.related_person.image));
            relatedPerson.related_person.biography.forEach(biography => {
                if (biography.biography_detail.type === VBDataTopicWithRelatedPeople_1.BiographyType.IMAGE) {
                    imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(biography.biography_detail.biography_value));
                }
            });
        });
        return imgList;
    }
    static getTopicWithRelatedDetailImgs(componentData) {
        const imgList = [];
        componentData.topic_list.forEach(topic => {
            topic.topic_details.forEach(topicDetail => {
                if (topicDetail.type === VBDataTopicListResponse_1.SignificanceType.IMAGE) {
                    imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(topicDetail.value));
                }
            });
        });
        return imgList;
    }
    static getDishRecommendationImgs(componentData) {
        const imgList = [];
        componentData.dish_list.forEach(dish => {
            imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(dish.dish_image));
        });
        return imgList;
    }
    static getRestaurantRecommendationImgs(componentData) {
        const imgList = [];
        componentData.restaurant_list.forEach(restaurant => {
            imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(restaurant.restaurant_image));
        });
        return imgList;
    }
    static getImageNewsGridImgs(componentData) {
        const imgList = [];
        componentData.news_articles.forEach(newsArticle => {
            if (newsArticle.article_image) {
                imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(newsArticle.article_image));
            }
        });
        return imgList;
    }
    static getNewsDigestImgs(componentData) {
        const imgList = [];
        componentData.news_feed.forEach(newsArticle => {
            imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(newsArticle.news_article.news_image));
        });
        return imgList;
    }
    static getNewsDigestDetailsImgs(componentData) {
        const imgList = [];
        componentData.news_details_feed.forEach(newsDetailsFeed => {
            newsDetailsFeed.news_category_detail.forEach(newsCategoryDetail => {
                imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(newsCategoryDetail.news_source_icon));
            });
        });
        return imgList;
    }
    static getNewsHeadlineImgs(componentData) {
        const imgList = [];
        componentData.news_list.forEach(newsHeadlineProps => {
            imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(newsHeadlineProps.news_image));
        });
        return imgList;
    }
    static getNewsVideoListImgs(componentData) {
        const imgList = [];
        componentData.news_video_collection.forEach(newsVideo => {
            imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(newsVideo.news_video.video_thumbnail));
        });
        return imgList;
    }
    static getShoppingImgs(componentData) {
        const imgList = [];
        componentData.product_list.forEach(product => {
            imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(product.product_image));
        });
        return imgList;
    }
    static getYoutubeSearchResultsImgs(componentData) {
        const imgList = [];
        componentData.video_list.forEach(item => {
            imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(item.video_image));
        });
        return imgList;
    }
    static getSeasonalTravelDestinationPlanImgs(componentData) {
        const imgList = [];
        componentData.seasonal_info.forEach(seasonalInfo => {
            const autoFillLen = exports.DEFAULT_IMG_LIST_LEN - (seasonalInfo.season_images.length - 1);
            seasonalInfo.season_images.forEach(img => {
                imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(img, autoFillLen > 0 ? autoFillLen : undefined));
            });
        });
        return imgList;
    }
    static getTouristAttractionImgs(componentData) {
        const imgList = [];
        componentData.destination_list.forEach(item => {
            const autoFillLen = exports.DEFAULT_IMG_LIST_LEN - (item.slidable_tourist_attraction_images.length - 1);
            item.slidable_tourist_attraction_images.forEach(img => {
                imgList.push(new ViewBuilderImgService_1.ComponentTypeImg(img.image, autoFillLen > 0 ? autoFillLen : undefined));
            });
        });
        return imgList;
    }
}
exports.ViewBuilderImgUtil = ViewBuilderImgUtil;
