import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorStoreMethods } from "./CreatorStoreMethods";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { HomeMethodsService } from "./HomeMethodsService";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
// custom hook to take a project snapshot for internal eveluation of COT results, used after COT template generation is done
export class TakeProjectSnapshot {
  constructor(
    public fotReduxStore: FotReduxStore,
    public gql: ObjectRelationGqlService,
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public canvasDataRef: CanvasDataRef,
    public creatorStoreMethods: CreatorStoreMethods,
    public homeMethods: HomeMethodsService
  ) {}
  getSnapshotData(templateObj: any, edges: any, variableList: any, params: any): any {
    const noSaveDataAttrs = ["errorText"];
    try {
      const UI = this.homeMethods.getUIListParam();
      const nodes = this.creatorNodesStore.getNodes();
      const nodeArr = nodes
        .map(node => this.canvasDataRef.getSimpleNode(node))
        .map(x => HomeMethodsUtil.getFilterDataAttrsNode(x, noSaveDataAttrs));
      const edgeArr = edges.map((node: any) => this.canvasDataRef.getSimpleEdge(node));
      const relevantFunctions = this.creatorStoreMethods.getItemRelavantFunctions();
      // 简化上传参数
      const simplifySingleFlowEdgeArr = relevantFunctions.map((x: any) => {
        const newEdgeArr = x.edgeArr.map((node: any) => this.canvasDataRef.getSimpleEdge(node));
        return {
          ...x,
          edgeArr: newEdgeArr,
        };
      });
      const tempData = {
        published_metadata: "",
        v2: "",
      };
      const defaultInit = {
        version: 1,
        nodes: nodeArr,
        edges: edgeArr,
        functions: simplifySingleFlowEdgeArr,
        UI,
        nodeIndex: this.creatorStoreMethods.getHomeNodesIndex(),
        edgeIndex: this.creatorStoreMethods.getHomeEdgesIndex(),
        variablesList: JSON.stringify(variableList),
      };

      if (params?.published_metadata) {
        tempData.published_metadata = params.published_metadata;
      }
      const v2 = Object.assign({}, templateObj.v2, defaultInit);
      tempData.v2 = v2;
      const data = Object.assign({}, { ...templateObj }, tempData);
      delete data.id;
      delete data.uuid;
      delete data.index; //project snapshot should not have attribute indexer
      return data;
    } catch (error) {
      console.error(`getSnapshotData error: ${error}`);
    }
  }
  async takeProjectSnapshot(): Promise<void> {
    const selectedTemplate = this.fotReduxStore.getState().studio.selectedTemplate;
    try {
      const edges = this.creatorEdgesStore.getEdges();
      const variableList = this.fotReduxStore.getState().fot.variableList;
      const params = {};
      const data = this.getSnapshotData(selectedTemplate, edges, variableList, params);
      // create new studio_project object
      const object = await this.gql.createObject(
        {
          name: "studio_project",
          isGlobal: false,
          attributes: data,
        },
        null as any
      );
      //save project id to editor store
      this.fotReduxStore.setCotSnapshotProjectId(object.id);
    } catch (error) {
      console.error(`takeProjectSnapshot error: ${error}`);
    }
  }
}
