import like from "../../assets/like.json";
import dislike from "../../assets/dislike.json";
import styles from "./Feedback.module.scss";
import { FeedbackAnimation } from "./FeedbackAnimation";
import { useComputed, useSignal } from "@preact/signals-react";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { TooltipMessage } from "@uiview/views/TooltipMessage/TooltipMessage";
import isBlank from "@sedan-utils/is-blank";
import { getIt } from "@uikit/getIt";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";

type FeedbackProps = {
  id: string;
  likeActive: boolean;
  dislikeActive: boolean;
  class: string;
  handleLike: () => void;
  handleDislike: () => void;
  isBluePrintParentNode: boolean;
  handleBluePrintDislike?: (msg: string) => void;
};

export function Feedback(props: FeedbackProps): JSX.Element {
  const creatorEdgesStore = getIt(CreatorEdgesStore);

  const loadingStr = useSignal<string>("");

  const isEdgeAbsent = useComputed(() => {
    const currEdge = creatorEdgesStore.getEdges().find((x: any) => x.target === props.id);
    return isBlank(currEdge);
  });
  const thumbUp = `${settings.S3CDN}${settings.viewAssetsPath}thumbUp_1.svg`;
  const thumbDown = `${settings.S3CDN}${settings.viewAssetsPath}thumbDown_1.svg`;
  const thumbUpActive = `${settings.S3CDN}${settings.viewAssetsPath}thumbUp_active.svg`;
  const thumbDownActive = `${settings.S3CDN}${settings.viewAssetsPath}thumbDown_active.svg`;

  function handleLike(): void {
    if (props.likeActive) {
      props.handleLike();
      return;
    }
    loadingStr.value = "like";
    props.handleLike();
    setTimeout(() => {
      if (isEdgeAbsent.value) return;
      loadingStr.value = "";
    }, 1000);
  }

  function handleDislike(): void {
    if (props.dislikeActive) {
      props.handleDislike();
      return;
    }
    loadingStr.value = "dislike";
    props.handleDislike();
    setTimeout(() => {
      if (isEdgeAbsent.value) return;
      loadingStr.value = "";
    }, 1000);
  }

  async function handleBluePrintDislike(msg: string): Promise<void> {
    handleDislike();
    if (props.handleBluePrintDislike) {
      return await props.handleBluePrintDislike?.(msg);
    }
  }

  const dislikeBtn = (
    <div className={styles["feedback-content"]} onClick={handleDislike}>
      <img
        alt=""
        className={`
          ${styles["dislike"]}
          ${loadingStr.value === "dislike" ? styles["img-hidden"] : ""}
        `}
        src={props.dislikeActive ? thumbDownActive : thumbDown}
      ></img>
      {loadingStr.value === "dislike" && (
        <FeedbackAnimation
          style={{ position: "absolute", top: "-32px", left: "-30px", width: "100px" }}
          loader={dislike}
        />
      )}
    </div>
  );

  return (
    <div className={props.class === "singleNode" ? styles["feedback-container"] : styles["feedback-group-container"]}>
      <div className={styles["feedback-content"]} onClick={handleLike}>
        <img
          alt=""
          className={`
            ${styles["like"]}
            ${loadingStr.value === "like" ? styles["img-hidden"] : ""}
          `}
          src={props.likeActive ? thumbUpActive : thumbUp}
        ></img>
        {loadingStr.value === "like" && (
          <FeedbackAnimation
            style={{ position: "absolute", top: "-32px", left: "-30px", width: "100px" }}
            loader={like}
          />
        )}
      </div>
      {props.isBluePrintParentNode ? (
        <TooltipMessage
          title="How could the output have been better?"
          placeholder="The output should have ..."
          onConfirm={handleBluePrintDislike}
        >
          {dislikeBtn}
        </TooltipMessage>
      ) : (
        dislikeBtn
      )}
    </div>
  );
}
