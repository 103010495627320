"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventbus = exports.Eventbus = void 0;
const Emitter_1 = require("./Emitter");
class Eventbus {
    constructor() {
        this.emitter = new Emitter_1.Emitter();
    }
    on(MsgType, callback, sync = false) {
        const msg = new MsgType();
        return this.emitter.on(msg.naming, msg => {
            callback(msg);
        }, sync);
    }
    off(MsgType, callback) {
        const msg = new MsgType();
        this.emitter.off(msg.naming, callback);
    }
    emit(msg) {
        this.emitter.emit(msg.naming, msg);
    }
}
exports.Eventbus = Eventbus;
exports.eventbus = new Eventbus();
