"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PubsubApi = void 0;
class PubsubApi {
    constructor(rest) {
        this.rest = rest;
    }
    publish(topic, data) {
        return this.rest.post("/be/pubsub/publish", {
            redis: {
                key: topic,
                data,
            },
            channel: {
                topic,
                name: "websockets",
            },
        });
    }
}
exports.PubsubApi = PubsubApi;
