import isBlank from "@sedan-utils/is-blank";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { Spin } from "antd";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { TryResult, tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { RestResponse } from "imagica-corekit/dist/base/cutil/RestClient";
import { BlueprintsStore } from "imagica-corekit/dist/base/store/BlueprintsStore";
import { ToolPieceStore } from "imagica-corekit/dist/base/store/ToolPieceStore";
import { useState } from "react";
import styles from "./Button.module.scss";

type DeleteButtonProps = {
  id: string | undefined;
  deleteType: "blueprint" | "toolPiece";
  updateFuncGroup: () => void;
};

export function DeleteButton(props: DeleteButtonProps) {
  const brainClient = getIt(BrainClient);

  const [loading, setLoading] = useState(false);

  const handleClickDelete = async () => {
    if (props.id) {
      setLoading(true);
      let result = new TryResult(undefined, "error") as unknown as TryResult<RestResponse<Object>>;
      if (props.deleteType === "blueprint") {
        result = await tryPromise(brainClient.openAi.deleteBlueprints({ id: props.id }));
      }
      if (props.deleteType === "toolPiece") {
        result = await tryPromise(brainClient.openAi.deleteToolPieces({ id: props.id }));
      }
      setLoading(false);

      if (result.error) {
        return func.customMsg({
          content: "Delete failed",
          type: "warning",
        });
      }

      func.customMsg({
        content: "Delete successfully",
        type: "success",
      });
      if (props.deleteType === "blueprint") {
        await getIt(BlueprintsStore).renew();
        props.updateFuncGroup();
      }
      if (props.deleteType === "toolPiece") {
        await getIt(ToolPieceStore).renew();
        props.updateFuncGroup();
      }
    }
  };

  const deleteText = `Delete ${props.deleteType === "blueprint" ? "Blueprint" : "Tool Piece"}`;

  if (isBlank(props.id)) {
    return null;
  }

  return (
    <div className={styles.btnWrapper}>
      <Spin spinning={loading}>
        <div className={styles.deleteButton} onClick={() => handleClickDelete()}>
          {deleteText}
        </div>
      </Spin>
    </div>
  );
}
