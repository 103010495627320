import classNames from "classnames";
import style from "./PublishCompletion.module.scss";
import { Button, Modal } from "antd";
import { DownloadIcon } from "@uiview/views/HomeNavBarViews/PublishCompletion/DownloadIcon";
import { SpinLoading } from "antd-mobile";
import { useSignal } from "@preact/signals-react";
import { matchMobile } from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";

type Props = {
  isOpen: boolean;
  qrCodeImgUrl: string;
  appLink: string;
  onClose: () => void;
};

export function PublishCompletion(props: Props): JSX.Element {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;
  const isLoading = useSignal(true);
  function handleViewSite(): void {
    const httpStr = "http://";
    const httpsStr = "https://";
    const link = props.appLink;
    const validLink = link.startsWith(httpStr) || link.startsWith(httpsStr) ? link : "//" + link;
    window.open(validLink);
  }

  function downloadQRCodeImage(): void {
    const link = document.createElement("a");
    link.href = props.qrCodeImgUrl;
    link.download = "QRCode.png";
    link.click();
  }

  function setIsLoading(value: boolean): void {
    isLoading.value = value;
  }

  const btnStyle =
    isMobile && featureTags.disableMobilePage
      ? {
          height: "40px",
          borderRadius: "20px",
          background: "#12192B",
          border: 0,
          width: "245px",
          marginTop: "20px",
          fontSize: "15px",
          fontWeight: 600,
          color: "#fff",
        }
      : {
          marginLeft: "auto",
          height: "34px",
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: "20px",
        };

  return (
    <div className={style.container}>
      {/*<div className={style.close} onClick={() => props.onClose()}>*/}
      {/*  <CloseIcon />*/}
      {/*</div>*/}
      <div className={style.title}>Congratulations!</div>
      <div className={style.discription}>Your app is published and live online</div>

      <div className={style.QRCode}>
        {isLoading.value && (
          <div className={style.loadingBox}>
            <SpinLoading color={"primary"} />
          </div>
        )}
        <img
          src={props.qrCodeImgUrl}
          alt=""
          onLoad={() => setIsLoading(false)}
          style={{ display: isLoading.value ? "none" : "block" }}
        />
      </div>

      <div className={style.mobileDeviceTitle}>Preview on mobile device</div>
      <div
        className={classNames(style.downloadBar, {
          [style.downloadBarMobile]: isMobile && featureTags.disableMobilePage,
        })}
        onClick={() => downloadQRCodeImage()}
      >
        <DownloadIcon />
        Download QR Code
      </div>
      {!(isMobile && featureTags.disableMobilePage) && (
        <div className={style.linkBar}>
          <input type="text" className={style.link} value={props.appLink} disabled />
          <div className={style.viewSite} onClick={() => handleViewSite()}>
            View Site
          </div>
        </div>
      )}
      <Button type="primary" shape="round" style={btnStyle} onClick={() => props.onClose()}>
        Done
      </Button>
    </div>
  );
}

export function PublishCompletionModal(props: Props): JSX.Element {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;
  return (
    <Modal
      maskClosable={false}
      open={props.isOpen}
      onCancel={() => {
        props.onClose();
      }}
      onOk={() => {}}
      centered
      footer={null}
      width={isMobile && featureTags.disableMobilePage ? 325 : 400}
      destroyOnClose
      wrapClassName={style.modalWrapper}
      maskStyle={{
        background: "rgba(2, 12, 38, 0.35)",
        backdropFilter: "blur(15px)",
        WebkitBackdropFilter: "blur(15px)",
      }}
    >
      <PublishCompletion {...props} />
    </Modal>
  );
}
