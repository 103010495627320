import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import { CanvasNodeType, CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

/**
 * 原来 useSetNodeErrorTips hook
 */
export class CreatorNodesErrorTips {
  constructor(public creatorNodesStore: CreatorNodesStore) {}

  /**
   * 用组ID获取所有子节点ID
   * @param {any[]} nodes 所有节点
   * @param {string} groupId 组ID
   * @returns
   */
  private getSubNodeIdsByGroupId(nodes: CanvasNodeType[], groupId: any): CanvasNodeType["id"][] | undefined {
    if (Array.isArray(nodes)) {
      return nodes.filter(node => node.parentNode === groupId).map(node => node.id);
    }
    return;
  }

  /**
   * 设置节点的错误消息
   *
   * @param {string} targetId
   * @param {{fromApiError:string, specifyErrorText:string}} error
   * @param {boolean} reset 如果为 true, 表示重置错误提示(为了解决出现 errorUI 时，rerun 后errorUI 不消失)
   */
  private _setNodeErrorTip(
    nodeId: any,
    error: { fromApiError: any; specifyErrorText: any; message: any },
    reset: boolean = false
  ): void {
    let errorText: string;

    if (reset) {
      errorText = "";
    } else {
      errorText =
        error.fromApiError || CreatorNodesConsts.DEFAULT_ERROR_TEXT || error.specifyErrorText || error?.message;
    }

    this.creatorNodesStore.setNodes(prevNodes => {
      return prevNodes.map(node => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              errorText,
            },
          };
        }
        return node;
      });
    });
  }

  /**
   * 支持 targetId 为节点ID或组ID
   *
   * 如果是组ID会设置组ID的所有节点ID
   *
   * @param {*} targetId
   * @param {*} error
   * @param {boolean} reset
   */
  setNodeErrorTip(targetId: any, error: any, reset: boolean = false): void {
    // is groupID
    if (CreatorNodesConsts.GROUPID_REG.test(targetId)) {
      const subNodeIds = this.getSubNodeIdsByGroupId(this.creatorNodesStore.getNodes(), targetId);
      subNodeIds?.forEach((id: any) => {
        this._setNodeErrorTip(id, error, reset);
      });

      return;
    }

    this._setNodeErrorTip(targetId, error, reset);
  }
}
