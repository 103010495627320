"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterSave = void 0;
const BrainClient_1 = require("../base/api/BrainClient");
const PreviewService_1 = require("../cases/preview/PreviewService");
const SaveApi_1 = require("../cases/saveService/SaveApi");
const SaveCore_1 = require("../cases/saveService/SaveCore");
const SaveCreateService_1 = require("../cases/saveService/SaveCreateService");
class RegisterSave {
    static configure(container) {
        const it = (serviceIdentifier) => {
            return container.get(serviceIdentifier);
        };
        const saveApi = it(BrainClient_1.BrainClient).saveApi;
        const saveCreateService = new SaveCreateService_1.SaveCreateService(saveApi);
        container.bind(SaveApi_1.SaveApi).toConstantValue(saveApi);
        container.bind(SaveCreateService_1.SaveCreateService).toConstantValue(saveCreateService);
        container.bind(SaveCore_1.SaveCore).toConstantValue(new SaveCore_1.SaveCore(saveApi, saveCreateService, it(PreviewService_1.PreviewService)));
    }
}
exports.RegisterSave = RegisterSave;
