import { Menu } from "antd";
import style from "./FotHomeNavBar.module.scss";
import { getIt } from "@uikit/getIt";
import { useDispatch, useSelector, useStore } from "react-redux";
import { editorActions } from "@store/editor";
import { logEvent } from "@uikit/service/amplitude";
import { ShowNavPageStore } from "../HomeRoot/store/ShowNavPageStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ProjectNameButton } from "./ProjectNameButton/ProjectNameButton";
import { NavBarHomeIcon } from "./homeIcon/NavBarHomeIcon";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import func from "@uikit/func";
import { cloneDeep, isNil } from "lodash";
import { DEFALUT_PROJECT_NAME } from "@views/thinking-layout-editor/constants";
import { RootState } from "@store/index";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { NavBarStore } from "./NavBarStore";
import { useCallback, useMemo } from "react";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { studioActions } from "@store/studio";
import { RunAllButtom } from "./runAllButton/RunAllButton";
import { NavbarCanvasSwitcher } from "./NavbarCanvasSwitcher/NavbarCanvasSwitcher";
import { PreviewUtil } from "imagica-corekit/dist/cases/preview/PreviewUtil";
import { PublishCompletionModal } from "../HomeNavBarViews/PublishCompletion/PublishCompletion";
import { useSignal } from "@preact/signals-react";
import { useCreation } from "ahooks";
import { LinksAndQRCodesViewBloc, QRCodeViewState } from "../LinksAndQRCodes/LinksAndQRCodesViewBloc";
import { UnpublishBloc, UnpublishBlocState } from "../PreviewPane/Settings/Unpublish/UnpublishBloc";
import { UnpublishModal } from "../PreviewPane/Settings/Unpublish/UnpublishModal";
import { previewStore } from "@uiview/store/PreviewStore";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { UpgradeButton } from "./upgradeButton/UpgradeButton";
import { ReportBug } from "./reportBug/ReportBug";
import { SaveButton } from "./SaveButton/SaveButton";
import { PublishButton } from "./PublishButton/PublishButton";
import { NavBarUserInfo } from "./navBarUserInfo/NavBarUserInfo";
import classNames from "classnames";
import { fotActions } from "@store/fot";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { DisableChatUtil } from "../MultiRoundChatOnboarding/DisableChatUtil";
import { PreviewHeaderViewBlocV2 } from "../PreviewHeaderV2/PreviewHeaderViewBlocV2";
import { Meta } from "imagica-corekit/dist/base/cutil/Meta";
import { PreviewAppStore, PublishState } from "@uikit/store/PreviewAppStore";
import { useAModalProvider } from "@uikit/context/AModalContext/useAModalProvider";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";
import { useIsStaticApp } from "@custom-hooks/useNodePreview";
import { CotStore } from "@uikit/store/CotStore";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { FotNavigateController } from "@uikit/service/FotNavigateController";

const gql = getIt(ObjectRelationGqlService);
const creatorSaasAppStore = getIt(CreatorSaasAppStore);

type FotHomeNavBarProps = {
  page: string;
  resetUndoData: () => any;
  emptyNodes: () => void;
};

export function FotHomeNavBar(props: FotHomeNavBarProps): JSX.Element {
  const homeStore = getIt(HomeStore);
  const homeStoreState = useImagicaStore(homeStore).value;
  const brainClient = getIt(BrainClient);
  const navBarStore = getIt(NavBarStore);
  const cotStore = getIt(CotStore);
  const fotSaveService = getIt(FotSaveService);
  const showNavPageStore = getIt(ShowNavPageStore);
  const previewAppStore = getIt(PreviewAppStore);

  const store = useStore();

  // const chatOnboardingStore = getIt(ChatOnboardingStore);
  const showNavPageState = useImagicaStore(showNavPageStore).value;
  const navBarState = useImagicaStore(navBarStore).value;

  // const backHomeModal = useSelector((state: any) => state.editor.backHomeModal);

  // const homePluginStore = getIt(HomePluginStore);

  const dispatch = useDispatch();
  // const createJSFuncData = useSelector((state: RootState) => state.editor.createJSFuncData);
  // const createAPIFuncData = useSelector((state: RootState) => state.editor.createAPIFuncData);

  const setEnableCOTStartAgain = (val: null): void => {
    dispatch(editorActions.setEnableCOTStartAgain(val));
  };
  // const setCreateJSFuncData = (val: { loading: boolean; parameter: any; function: string }) => {
  //   dispatch(editorActions.setCreateJSFuncData(val));
  // };
  // const setCreateAPIFuncData = (val: { loading: boolean; parameter: any; function: string }) => {
  //   dispatch(editorActions.setCreateAPIFuncData(val));
  // };

  const setSelectedTemplate = (val: any): void => {
    dispatch(studioActions.setSelectedTemplate(val));
  };

  const setShowTooltip = (val: any): void => {
    dispatch(editorActions.setShowTooltip(val));
  };

  const setCloseToolTipArr = (val: any): void => {
    dispatch(editorActions.setCloseToolTipArr(val));
  };

  const setDisableAddPreview = (val: any): void => {
    dispatch(fotActions.setDisableAddPreview(val));
  };

  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);
  const userMe = useSelector((state: any) => state.editor.me);
  // const isFunc = createAPIFuncData?.open || createJSFuncData?.open;

  const { showModal } = useAModalProvider();
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const previewComposeState = getIt(PreviewComposeState);
  const fotNavigateController = getIt(FotNavigateController);
  const { isStaticApp } = useIsStaticApp();
  const creatorPreviewState = useImagicaStore(previewStore).value;
  const disabledPublishV2 = useMemo(() => {
    return previewComposeState.disabledPublishV2();
  }, [saasUIData, isStaticApp, creatorPreviewState.selectedUI]);

  const dataVersion = "v3";

  const linksAndQRCodesUrl = useSignal(
    PreviewUtil.getCustomDomain(saasUIData) || previewAppStore.getPublishMetadata()?.link || ""
  );
  const qrCodeState = new QRCodeViewState();
  const qrCodeBloc = useCreation(() => new LinksAndQRCodesViewBloc(qrCodeState), []);

  const publishBloc = useCreation(() => new PreviewHeaderViewBlocV2(), []);

  const state = new UnpublishBlocState();
  const unpublishBloc = useCreation(() => new UnpublishBloc(state), []);

  const setPromptStatus = (status: any): void => {
    const selectedTemplate: any = (store.getState() as RootState).studio.selectedTemplate;
    const v = Object.assign({}, selectedTemplate[dataVersion], { showToolTipStatus: status });
    const data = Object.assign({}, selectedTemplate, { [dataVersion]: v });
    setSelectedTemplate(data);
  };

  const closePromptHide = (): void => {
    setPromptStatus("close");
    newOnboardingFlowStore.setSelectedFlowItem({
      ...newOnboardingFlowStore.state.selectedFlowItem,
      showTooltip: false,
    });
  };

  const handleReset = (): void => {
    // 重置unde数据
    closePromptHide();
    props.resetUndoData();
    props.emptyNodes();

    // 重置右侧preview数据
    previewAppStore.resetPreviewData();
    // 重置按钮默认颜色
    navBarStore.setClickItemButton(false);
    // 重置project inputvalue 内容
    creatorRefStore.currentProjectRef.current?.resetInnerState();

    // 重置Onboarding input
    cotStore.setMultiRoundChatOnboarding(false);
    // 显示首页
    fotNavigateController.openCreatorHomePage();
  };

  const checkerProjectName = (inputValue: string): string | undefined => {
    if (!inputValue.trim()) {
      const msg = props.page === "home" ? "Please enter the function name" : "Please enter the project name";
      func.messageUtil(`${msg}`);
      return undefined;
    }
    // 去掉项目名两边空格
    return inputValue.trim();
  };
  // const handleSaveFunctionEventProperties = (
  //   source: any,
  //   newFunctionData: any,
  //   createJSFuncData: any,
  //   createAPIFuncData: any
  // ) => {
  //   createJSFuncData = (store.getState() as RootState).editor.createJSFuncData;
  //   createAPIFuncData = (store.getState() as RootState).editor.createAPIFuncData;
  //   let functionType = createJSFuncData.open ? "js" : "api";
  //   let is_first_created = false;
  //   if (createJSFuncData.open) {
  //     //js
  //     is_first_created = createJSFuncData.state === "new" || false;
  //   } else {
  //     //api
  //     is_first_created = createAPIFuncData.state === "new" || false;
  //   }
  //   const eventProperties = {
  //     name: newFunctionData.name,
  //     description: newFunctionData.description,
  //     type: functionType,
  //     is_first_created: is_first_created,
  //     source: source,
  //   };
  //   return eventProperties;
  // };
  // const updateJSFunction = (newProjectName: string, source: any) => {
  //   setCreateJSFuncData({
  //     loading: false,
  //     parameter: {
  //       ...navBarStore.state.newFunctionData,
  //       name: newProjectName,
  //     },
  //     function: "handleOk", // custom js function 组件会监听这个 function 字段改变，执行相关代码
  //   });
  //   const updatedFunctionData = {
  //     ...navBarStore.state.newFunctionData,
  //     name: newProjectName,
  //   };
  //   const eventProperties = handleSaveFunctionEventProperties(source, updatedFunctionData, createJSFuncData, "");
  //   logEvent("save_function", eventProperties);
  // };
  // const createJSFunction = () => {
  //   const jsTitleData = {
  //     modify: "Modify Custom JS Function",
  //     new: "New Custom JS Function",
  //   };
  //   navBarStore.setCustomFunctionTitle(jsTitleData[createJSFuncData.state as keyof typeof jsTitleData]);
  //   if (!func.isEmpty(createJSFuncData.parameter)) {
  //     navBarStore.setNewFunctionData(createJSFuncData.parameter);
  //   }
  //   if (createJSFuncData.state === "new") {
  //     navBarStore.setNewFunctionData({ name: "", description: "" });
  //   }
  //   navBarStore.setIsNewCustomFunctionOpen(true);
  // };

  // const updateAPIFunction = (newProjectName: string, source: any) => {
  //   setCreateAPIFuncData({
  //     loading: false,
  //     parameter: {
  //       ...createAPIFuncData,
  //       name: newProjectName,
  //     },
  //     function: "handleOk", // custom api function 组件会监听这个 function 字段改变，执行相关代码
  //   });
  //   const updatedFunctionData = {
  //     ...navBarStore.state.newFunctionData,
  //     name: newProjectName,
  //   };
  //   const eventProperties = handleSaveFunctionEventProperties(source, updatedFunctionData, "", createAPIFuncData);
  //   logEvent("save_function", eventProperties);
  // };
  // const createAPIFunction = () => {
  //   const apiTitleData = {
  //     modify: "Modify Custom API Function",
  //     new: "New Custom API Function",
  //   };
  //   navBarStore.setCustomFunctionTitle(apiTitleData[createAPIFuncData.state as keyof typeof apiTitleData]);
  //   if (!func.isEmpty(createAPIFuncData.parameter)) {
  //     navBarStore.setNewFunctionData(createAPIFuncData.parameter);
  //   }
  //   if (createAPIFuncData.state === "new") {
  //     navBarStore.setNewFunctionData({ name: "", description: "" });
  //   }
  //   navBarStore.setIsNewCustomFunctionOpen(true);
  // };

  const saveProjectName = async (name: string): Promise<void> => {
    await saveProject("project title", name, true);
  };

  const saveProject = async (
    source: any,
    projectName: string = selectedTemplate?.template_name,
    isReName: boolean = false
  ): Promise<void> => {
    setEnableCOTStartAgain(null);
    // 校验保存的项目名
    const newProjectName = checkerProjectName(projectName);
    if (!newProjectName) return;
    if (homeStoreState.homeNavSaveLoading) return;

    // cjs function保存
    // if (createJSFuncData.open) {
    //   if (createJSFuncData.state !== "new") {
    //     // 跳过弹窗, 直接更新
    //     updateJSFunction(newProjectName, source);
    //   } else {
    //     createJSFunction();
    //   }
    //   return;
    // }
    // // capi function保存
    // if (createAPIFuncData.open) {
    //   if (createAPIFuncData.state !== "new") {
    //     // 跳过弹窗, 直接更新
    //     updateAPIFunction(newProjectName, source);
    //   } else {
    //     createAPIFunction();
    //   }
    //   return;
    // }

    try {
      homeStore.setHomeNavSaveLoading(true);
      // 保存项目
      if (typeof fotSaveService.handleSave !== "function") return;
      const selectedTemplate = (store.getState() as RootState).studio
        .selectedTemplate as unknown as PreviewApp.ProjectType;
      // 直接修改项目名保存
      if (!func.isEmpty(newProjectName) && newProjectName !== DEFALUT_PROJECT_NAME) {
        // 保存新的项目并替换新的项目名
        const newTemplateObj = cloneDeep(selectedTemplate);
        newTemplateObj.name = newProjectName;
        newTemplateObj.template_name = newProjectName;
        if (!newTemplateObj.isMannulModifyProjectName) {
          newTemplateObj.isMannulModifyProjectName = isReName;
        }
        await fotSaveService.handleSave({
          templateObj: newTemplateObj,
          source: source,
        });
        creatorRefStore.currentProjectRef.current?.triggerInput(false);
        func.messageUtil("Successfully Saved", "success");
        return;
      }
      // 点击save按钮保存
      await fotSaveService.handleSave({
        source: source,
      });
      func.messageUtil("Successfully Saved", "success");
      // await props.();
    } catch (error) {
      func.messageError(error);
    } finally {
      homeStore.setHomeNavSaveLoading(false);
    }
  };

  const shareProject = async (): Promise<void> => {
    if (homeStoreState.homeNavSaveLoading) return;
    try {
      // setShareLoading(true);
      const data = await fotSaveService.handleSave({ background: true });
      const url = `${window.location.origin}${window.location.pathname}#/editor`;
      const encodeProjectName = encodeURIComponent(selectedTemplate.name);
      const link = `${url}/shared_canvas/?projectId=${
        data?.updated_project?.id || selectedTemplate.id
      }&projectName=${encodeProjectName}&author=${userMe.id}`;
      navigator.clipboard.writeText(link);
      // showShareProjectTip();
      func.customMsg({
        content: "Link copied to clipboard",
        type: "info",
      });
      // setShareLoading(false);
      logEvent("share_project", { URL: link });
    } catch (error) {
      console.error(error);
    }
  };

  const duplicateProject = async (): Promise<void> => {
    if (selectedTemplate.id) {
      try {
        const res = await gql.shareStudioProject(selectedTemplate.id, userMe.id, "", true);
        const link = window.location.origin + "/fot/#/editor/" + res.uuid;
        window.open(link, "_blank");
      } catch (error: any) {
        console.log("copy failed", error);
      }
    }
  };

  const deleteProject = async (): Promise<void> => {
    if (selectedTemplate.id) {
      const final = await handleDeleteProject(
        "project",
        selectedTemplate?.template_name || "Untitled",
        selectedTemplate.id
      );
      if (final) {
        handleReset();
      }
    }
  };

  const handleDeleteProject = useCallback(
    (type: any, name: any, objectId: any) => {
      return new Promise<boolean>(resolve => {
        showModal({
          width: 520,
          title: "Confirm your action",
          subTitle: `Do you want to delete the ${type} named "${name}"? This action cannot be undone.`,
          buttons: ["Cancel", "Delete"],
          danger: true,
          shouldExecuteOkOnEnter: true,
          onOk: async ({ setLoading, close }) => {
            // For the copied functions or objects, they don't have relation id currently.
            const relationResponse = await gql.relationSet(undefined, undefined, {
              destId: objectId,
            });
            const relationId = relationResponse?.[0]?.relationId;
            if (isNil(relationId)) {
              close();
              return func.customMsg({
                type: "error",
                content: "No relation id found, delete failed",
              });
            }
            setLoading(true);
            await gql.deleteRelations(relationId, "", true);
            setLoading(false);
            close();
            resolve(true);
          },
          onCancel: () => {
            resolve(false);
          },
        });
      });
    },
    [showModal]
  );

  async function publishApp(): Promise<void> {
    const showPublishTips =
      (disabledPublishV2 && navBarStore.state.publishLoading === false) || publishBloc.subscriptionAndNoPrice();
    if (showPublishTips || navBarStore.state.publishLoading) return;
    // sutd-1722: published_metadata
    // let published_metadata = selectedTemplate?.published_metadata || {}
    let published_metadata = previewAppStore.getPublishMetadata() || {};
    navBarStore.setPublishLoading(true);
    if (
      previewStore.state.selectedUI === "saas" ||
      // || (util.isEmpty(selectedTemplate?.published_metadata)
      (func.isEmpty(published_metadata) && previewStore.state.selectedUI === "saasV2")
    ) {
      published_metadata = {
        uuid: "",
        original: true,
        stripe_product_id: "",
        stripe_price_id: "",
        stripe_account_id: "",
      };
    }
    try {
      const shareLink = "";
      const template = previewAppStore.getSelectedTemplate();
      const data: any = {
        templateObj: template,
        shareObj: { share: true, shareLink },
        params: { published_metadata },
        showPublishTips,
      };

      const { updated_project } = await fotSaveService.handleSave(data);

      if (!updated_project) {
        throw new Meta("Error", "Unable to create shared project");
      }
      setSelectedTemplate(updated_project);
      await publishBloc.handleSubscription(updated_project);
      QRCode();
      /// 发送Event，非主要业务逻辑
      const eventProps = publishBloc.getPublishEventProps(template, linksAndQRCodesUrl.value as string, template);
      switch (previewStore.state.selectedUI) {
        case "saas":
          logEvent("publish_as_webapp", { URL: updated_project.published_metadata.link, ...eventProps });
          break;
        case "saasV2":
          logEvent("publish_as_saas", { URL: updated_project.published_metadata.link });
          break;
        case "thinkingApp":
          logEvent("publish_as_thinkingapp", { URL: updated_project.published_metadata.link });
          break;
        default:
          break;
      }
    } catch (error: any) {
      console.error("publish", error);
      func.messageUtil(error);
    } finally {
      navBarStore.setPublishLoading(false);
    }
  }

  function copylink(): void {
    const published_metadata = previewAppStore.getPublishMetadata();
    const url = (PreviewUtil.getCustomDomain(saasUIData) || published_metadata?.link) ?? "";
    const uuid = published_metadata?.uuid ?? "";

    navigator.clipboard.writeText(url);
    func.customMsg({
      content: "Copied successfully",
      type: "success",
    });
    logEvent("copy_published_link", { URL: url, uuid: uuid });
  }

  function QRCode(): void {
    qrCodeBloc.clickOpen();
    const published_metadata = previewAppStore.getPublishMetadata();
    const url = (PreviewUtil.getCustomDomain(saasUIData) || published_metadata?.link) ?? "";
    const title = saasUIData.v2App?.appName ?? "";
    linksAndQRCodesUrl.value = url;
    qrCodeBloc.getStudioDomainList(url, title);
  }

  function unpublishApp(): void {
    unpublishBloc.showUnpublishModal(false, previewStore.state.previewAppAcivieId as string, selectedTemplate);
  }

  async function handleUnpublishV2(): Promise<void> {
    const published_metadata = previewAppStore.getPublishMetadata();
    try {
      unpublishBloc.state.unpublishLoading.value = true;
      const selectedTemplate = previewAppStore.getSelectedTemplate();
      if (selectedTemplate === undefined) {
        throw Error("can not find app");
      }
      if (!func.isEmpty(published_metadata?.published_at)) {
        const linkMateData = selectedTemplate.published_metadata_v2?.find(item => {
          return item.id === previewStore.state.previewAppAcivieId;
        });
        if (linkMateData?.link) {
          const linkUrl = new URL(linkMateData?.link.replace("#/", ""));
          const query = linkUrl.searchParams.get("q") ?? "";
          await brainClient.user.unpublish(query);
          const setingUIData = selectedTemplate.v3?.UI?.find((item: any) => {
            return item.id === linkMateData.id;
          });
          logEvent("unpublish", {
            URL: linkMateData?.link,
            publish_view_name: setingUIData?.title,
            name: setingUIData?.v2App?.appName,
            uuid: linkMateData.uuid,
          });
        }
      }
      let newTemplate = previewAppStore.clearPublishMetadata();
      /**
       * 重置部分已保存domain name的数据
       */
      newTemplate = previewAppStore.resetDomainName(newTemplate);
      await gql.updateObject(parseInt(selectedTemplate.id), {
        name: "studio_project",
        attributes: newTemplate,
      });
      setSelectedTemplate(newTemplate);
      unpublishBloc.state.showModal.value = false;
    } catch (error) {
      func.messageError(error);
    } finally {
      unpublishBloc.state.unpublishLoading.value = false;
      // 标记状态为unpublished
      previewAppStore.setPublishState(PublishState.unpublish);
    }
  }

  const beforePublish = (): boolean => {
    closePromptHide();
    setPromptStatus("close");
    setShowTooltip("close");
    setDisableAddPreview(true); // add content状态重置
    setCloseToolTipArr(["editor-2"]);
    return true;
  };

  const leftItems = [
    {
      key: "home",
      hide: false,
      label: (
        <NavBarHomeIcon
          disable={homeStoreState.homeNavSaveLoading || navBarState.publishLoading}
          resetUndoData={props.resetUndoData}
          emptyNodes={props.emptyNodes}
          saveProject={saveProject}
        />
      ),
      disabled: homeStoreState.homeNavSaveLoading || navBarState.publishLoading,
    },
    // {
    //   key: "line",
    //   hide: false,
    //   label: <SpaceLineItem />,
    // },
    {
      key: "project name",
      hide: false,
      label: (
        <ProjectNameButton
          disable={homeStoreState.homeNavSaveLoading || navBarState.publishLoading}
          saveProject={saveProject}
          saveProjectName={saveProjectName}
          shareProject={shareProject}
          duplicateProject={duplicateProject}
          deleteProject={deleteProject}
          copylink={copylink}
          QRCode={QRCode}
          unpublishApp={unpublishApp}
          publishApp={publishApp}
        />
      ),
      disabled: homeStoreState.homeNavSaveLoading || navBarState.publishLoading,
    },
    {
      key: "navbar-canvas-switcher",
      hide: showNavPageState.showNavPage || DisableChatUtil.disableSwitch(),
      label: <NavbarCanvasSwitcher disable={homeStoreState.homeNavSaveLoading || navBarState.publishLoading} />,
      disabled: homeStoreState.homeNavSaveLoading || navBarState.publishLoading,
    },
    {
      key: "run all",
      hide: false,
      label: <RunAllButtom disable={homeStoreState.homeNavSaveLoading || navBarState.publishLoading} />,
      disabled: homeStoreState.homeNavSaveLoading || navBarState.publishLoading,
    },
  ];

  const rightItems = [
    {
      key: "user",
      hide: false,
      label: <NavBarUserInfo disable={homeStoreState.homeNavSaveLoading || navBarState.publishLoading} />,
      disabled: homeStoreState.homeNavSaveLoading || navBarState.publishLoading,
    },
    {
      key: "Pulish",
      hide: showNavPageState.showNavPage,
      label: (
        <PublishButton
          disable={homeStoreState.homeNavSaveLoading || navBarState.publishLoading}
          copylink={copylink}
          QRCode={QRCode}
          beforePublish={beforePublish}
          unpublish={unpublishApp}
        />
      ),
      disabled: homeStoreState.homeNavSaveLoading || navBarState.publishLoading,
    },
    {
      key: "saveButton",
      // className: feedbackClassName,
      hide: showNavPageState.showNavPage,
      label: (
        <SaveButton
          disable={homeStoreState.homeNavSaveLoading || navBarState.publishLoading}
          saveProject={saveProject}
          handleSave={fotSaveService.handleSave}
        />
      ),
      disabled: homeStoreState.homeNavSaveLoading || navBarState.publishLoading,
    },
    {
      key: "Upgrade",
      // className: upgradeStyle.bar_item,
      hide: false,
      label: <UpgradeButton disable={homeStoreState.homeNavSaveLoading || navBarState.publishLoading} />,
      disabled: homeStoreState.homeNavSaveLoading || navBarState.publishLoading,
    },
    {
      key: "Report a bug",
      hide: false,
      label: <ReportBug disable={homeStoreState.homeNavSaveLoading || navBarState.publishLoading} />,
      disabled: homeStoreState.homeNavSaveLoading || navBarState.publishLoading,
    },
  ];

  return (
    <div
      id="homeNavBar"
      className={classNames(style.navber_back, {
        [style.homeNavBarBackgroud]: showNavPageState.showNavPage,
      })}
    >
      <div className={style.home_navber_box}>
        <Menu
          className={style.leftmenu}
          selectable={false}
          mode="horizontal"
          getPopupContainer={e => e}
          key="leftmenu"
          // FIXME: hide 不在 items 属性中，否则会报警告
          items={leftItems.filter(item => !item.hide).map(item => ({ ...item, hide: item.hide.toString() }))}
          triggerSubMenuAction="click"
        />
        <Menu
          selectable={false}
          className={style.rightmenu}
          mode="horizontal"
          getPopupContainer={e => e}
          key="rightmenu"
          // FIXME: hide 不在 items 属性中，否则会报警告
          items={rightItems.filter(item => !item.hide).map(item => ({ ...item, hide: item.hide.toString() }))}
          triggerSubMenuAction="click"
        />
      </div>
      <PublishCompletionModal
        isOpen={qrCodeBloc.state.isQRCodeBox.value}
        onClose={() => {
          qrCodeBloc.clickClose();
        }}
        qrCodeImgUrl={qrCodeBloc.state.qrCodeUrl.value}
        appLink={linksAndQRCodesUrl.value}
      />
      {unpublishBloc.state.showModal.value && (
        <UnpublishModal
          bloc={unpublishBloc}
          handleOk={() => {
            handleUnpublishV2();
          }}
        />
      )}
    </div>
  );
}

export function SpaceLineItem(): JSX.Element {
  return (
    <div className={style["space_line"]}>
      <div className={style["division"]}></div>
    </div>
  );
}
