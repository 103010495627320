"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CotApi = void 0;
const Cot_1 = require("../api/cotTyped/Cot");
class CotApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    async chat(history) {
        const result = await this.httpRequest.post("/be/cot/chat", { history }, {}, { "Content-Type": "application/json" });
        return result
            .parseError(() => {
            return "An error has occurred";
        })
            .toModelFromType(Cot_1.CotResponse).data;
    }
    async suggest(description) {
        const result = await this.httpRequest.post("/be/cot/suggest_input", { description }, {}, { "Content-Type": "application/json" });
        return result.data;
    }
    async queryBuilder(message) {
        const result = await this.httpRequest.post("/be/cot/query_builder", { message }, {}, { "Content-Type": "application/json" });
        return result.data;
    }
}
exports.CotApi = CotApi;
