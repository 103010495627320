import { cloneDeep, first, get, isArray, set } from "lodash";
import { PreviewAppValueLangUtil } from "./PreviewAppValueLangUtil";
import {
  PREVIEW_APP_Fields,
  PREVIEW_APP_ID_PATH,
  PREVIEW_APP_METADATA_V2_PATH,
  PreviewAppTypeMap,
  getDefaultPreviewAppTypeList,
} from "./const";
import { PreviewAppValueGenUtil } from "./PreviewAppValueGenUtil";
import { PreviewAppValueMergeUtil } from "./PreviewAppValueMergeUtil";
import { PreviewAppValue } from "./model/PreviewAppValue";
import { ProjectNodeUtil } from "@uikit/projectNode/ProjectNodeUtil";

export class PreviewAppTransfromUtil {
  /**
   * 合并一个新的 ui 列表, 并且为每一项数据增加 v2app 数据的数据,包含 id
   * @param projectUIList
   * @returns
   */
  private static mergeNewUIList(projectUIList: PreviewApp.UISaasValueType[]): PreviewAppValue[] {
    const appTypeList = getDefaultPreviewAppTypeList();
    const aiApp = appTypeList.find(item => item.appType === PreviewAppTypeMap.AI_APP);
    const staticApp = appTypeList.find(item => item.appType === PreviewAppTypeMap.STATIC_WEB);

    return projectUIList.map((uiItem, index) => {
      if (PreviewAppValueLangUtil.isV2UIValue(uiItem)) {
        return uiItem;
      }
      const format = uiItem.staticMode ? staticApp : aiApp;
      const v2App = PreviewAppValueGenUtil.genV2App({ format, lastIndex: index + 1 });
      const id = get(uiItem, PREVIEW_APP_Fields.DATA_APP_ID, v2App?.id);
      return PreviewAppValueMergeUtil.merge({ ...uiItem }, { id, v2App });
    });
  }

  static praseUIInputAndOutput(UIList: PreviewAppValue[]): void {
    UIList.forEach(app => {
      if (typeof app.input === "string") {
        app.input = JSON.parse(app.input);
      }
      if (typeof app.output === "string") {
        app.output = JSON.parse(app.output);
      }

      // https://brain-ai.atlassian.net/browse/STUD-2739
      // 为了解决 staticMode 数据可能出现的不正确
      app.staticMode = PreviewAppValueLangUtil.isStaticApp(app);
      return app;
    });
  }

  /**
   * 该方法将 项目数据进行校验并转换成 新版 项目数据
   *
   * FIXME: 只是部分数据,新版项目数据需要等 sassuidata 数据重构完成
   *
   * - 深拷贝一份原始数据
   * - 老项目数据转成新项目
   *
   * @param selectedExistingProject
   * @returns
   */
  static toV2PreviewProject(selectedExistingProject: PreviewApp.ProjectType): PreviewApp.ProjectType {
    // FIXME: 为了拦截修改 project, 深拷贝了一份 selectedExistingProject
    const result = cloneDeep(selectedExistingProject);

    PreviewAppTransfromUtil.praseUIInputAndOutput(result.v3?.UI || []);
    // FIXME: 是否需要考虑 edge？？？
    if (!isArray(result.v3?.projectNodes)) {
      set(
        result,
        ["v3", "projectNodes"],
        ProjectNodeUtil.compatibleProjectNodes(result.v3?.nodes || [], result.v3?.UI || [])
      );
    }

    // 如果已经是 previewV2 结构则直接返回
    if (PreviewAppValueLangUtil.isV2PreviewProject(result)) {
      return result;
    }

    // gen new data
    const UIData = get(result, ["v3", "UI"]);
    // uilist
    const appList = UIData ? PreviewAppTransfromUtil.mergeNewUIList(UIData) : [];
    // appid
    const appId = first(appList)?.id || "";
    // publish meta
    const publish_meta = get(result, "published_metadata") as any;
    const metadatalist = publish_meta ? [{ ...publish_meta, id: appId }] : [];

    // set new data
    set(result, PREVIEW_APP_METADATA_V2_PATH, metadatalist);
    // previewAppId
    set(result, PREVIEW_APP_ID_PATH, appId);
    // applist
    set(result, ["v3", "UI"], appList);

    return result;
  }
}
