import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { isFunction } from "lodash";
import { PreviewAppValue } from "@uiview/views/PreviewApp/model/PreviewAppValue";
import { CanvasNodeType, CreatorNodesStore } from "../store/CreatorNodesStore";
import { PreviewAppValueGenUtil } from "@uiview/views/PreviewApp/PreviewAppValueGenUtil";
import { PreviewDataType } from "@uiview/views/PreviewApp/PreviewDataType";
import { PreviewOutputUtil } from "@uiview/views/PreviewApp/PreviewOutputItem/PreviewOutputUtil";

export class CreatorSaasAppStoreState {
  /**
   * 该状态目前仅用于强制跟新 sassuidata 数据，当 sassuidata 和 node同步后则移除
   */
  forceUpdates = 0;
  saasUIData = new PreviewAppValue();

  copyWith(params: { saasUIData?: PreviewAppValue; forceUpdates?: number }): CreatorSaasAppStoreState {
    const newState = new CreatorSaasAppStoreState();
    newState.saasUIData = params.saasUIData ?? this.saasUIData;
    newState.forceUpdates = params.forceUpdates ?? this.forceUpdates;

    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);

// 原 useSaasAndThinkAppState 状态
export class CreatorSaasAppStore extends StoreBase<CreatorSaasAppStoreState> {
  lastSaasUIData: PreviewAppValue;

  constructor(public creatorNodesStore: CreatorNodesStore) {
    super(() => new CreatorSaasAppStoreState());
    this.lastSaasUIData = new PreviewAppValue();
  }

  setSaasUIData = (value: DispatchType<CreatorSaasAppStoreState["saasUIData"]>): void => {
    if (isFunction(value)) {
      const saasUIData = value(this.lastSaasUIData);
      saasUIData.outputV2 = PreviewAppValue.toPreviewOutputv2(saasUIData.output, this.creatorNodesStore.getNodes);

      this.lastSaasUIData = saasUIData;
      this.emit(this.state.copyWith({ saasUIData }));
      return;
    }

    // if (!value.length) {
    //   console.trace("empty");
    // }

    const saasUIData = value;
    saasUIData.outputV2 = PreviewAppValue.toPreviewOutputv2(value?.output, this.creatorNodesStore.getNodes);

    this.lastSaasUIData = saasUIData;

    this.emit(this.state.copyWith({ saasUIData }));
  };
  emptySaasUIData(): void {
    this.setSaasUIData(PreviewAppValueGenUtil.genPreviewAppValue());
  }

  forceUpdates(): void {
    this.emit(this.state.copyWith({ forceUpdates: Date.now() }));
  }

  /**
   * 该方法用于更新 node 中 data 的数据，但是不会触发ui变化，是直接修改 state nodes 值
   *
   * @param id
   * @param source
   */
  updateInputDataWithNoEmit(id: string, source: Partial<CanvasNodeType["data"]>): void {
    this.state.saasUIData.input.forEach(node => {
      if (id === node.id) {
        // 不修改原来 数据引用，直接对data进行扩展
        node.data = Object.assign(node.data, source);
      }
    });

    // 为了 functions 调用
    this.lastSaasUIData = this.state.saasUIData;
  }

  /**
   * 用原来 output.result 获取 outputv2 数据
   * @param outputResults
   * @returns
   */
  getOutputV2(thoughtType: AISaasOutput.PreviewValueType): PreviewDataType | undefined {
    const nodeId = thoughtType.results?.[0].nodeId;
    const parentNode = thoughtType.results?.[0].parentNode;
    const previewId = PreviewOutputUtil.genNodeUUID(nodeId, parentNode);
    // 当 outputv2 稳定打开下面注释,去掉上面代码
    // const previewId = thoughtType.results?.[0]?.previewId

    return this.state.saasUIData.outputV2?.find(outputV2 => outputV2.id === previewId);
  }
}
