"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationManagerImpl = void 0;
const Meta_1 = require("../base/cutil/Meta");
const LocationManager_1 = require("../base/port/LocationManager");
class LocationManagerImpl extends LocationManager_1.LocationManager {
    async getLocation(blocked = true) {
        // 获取用户地理位置
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    resolve(new LocationManager_1.LocationCoordinate(position.coords.latitude, position.coords.longitude));
                }, error => {
                    console.log("get loacation error::", error);
                    if (blocked) {
                        reject(new Meta_1.Meta("geo error", "can not get location"));
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                if (blocked) {
                    reject(new Meta_1.Meta("geo error", "can not get location"));
                }
                else {
                    resolve(undefined);
                }
            }
        });
        // console.log("navigator.geolocation", navigator.geolocation);
        // if (navigator.geolocation) {
        //   navigator.geolocation.getCurrentPosition(
        //     position => {
        //       return new LocationCoordinate(position.coords.latitude, position.coords.longitude);
        //     },
        //     error => {
        //       console.log("get loacation error::", error);
        //       if (notBlock) {
        //         return undefined;
        //       } else {
        //         throw new Meta("geo error", "can not get location");
        //       }
        //     }
        //   );
        // } else {
        //   if (notBlock) {
        //     return undefined;
        //   } else {
        //     throw new Meta("geo error", "can not get location");
        //   }
        // }
        // return new LocationCoordinate(0, 0);
    }
    getUserLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    console.log(latitude, longitude);
                    resolve({ latitude, longitude });
                }, error => {
                    reject(new Error(`Error getting location: ${error.message}`));
                });
            }
            else {
                reject(new Error("Geolocation is not supported by this browser."));
            }
        });
    }
}
exports.LocationManagerImpl = LocationManagerImpl;
