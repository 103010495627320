import style from "./OpenProjectLoadingStyle.module.scss";
import lottie, { AnimationItem } from "lottie-web";
import loader from "../../../Loader/assets/GeneratingLoader.json";
import { useEffect, useRef } from "react";

export function OpenProjectLoading(): JSX.Element {
  const lottieRef = useRef(null);
  useEffect(() => {
    if (lottieRef.current) {
      const animation: AnimationItem = lottie.loadAnimation({
        animationData: loader,
        container: lottieRef.current,
      });

      return () => {
        animation.destroy();
      };
    }
  }, [lottieRef]);

  return (
    <div className={style.loader}>
      <div className={style.content}>
        <div ref={lottieRef} className={style.loaderSize} />
      </div>
    </div>
  );
}
