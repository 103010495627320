import { FC, useCallback, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import style from "./style.module.scss";
import { callback, getAssetsAnimation } from "@views/thinking-layout-editor/utilities";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import cls from "classnames";
import { getIt } from "@uikit/getIt";

export interface LogoButtonProps {
  onClick?: () => void;
}

const LogoButton: FC<LogoButtonProps> = ({ onClick = callback }) => {
  const playerRef = useRef<Player>(null);
  const showNavPageStore = getIt(ShowNavPageStore);
  const handlePlayerClick = useCallback(() => {
    const player = playerRef.current;
    if (!player) {
      return;
    }
    player.setSeeker(0, true);
    player.play();
    onClick();
  }, [onClick]);

  return (
    <div className={style.logoButton} onClick={handlePlayerClick}>
      <div className={style.content}>
        <Player
          className={cls(showNavPageStore.state.showNavPage && style.isShowLogoBtn)}
          ref={playerRef}
          keepLastFrame={true}
          autoplay
          src={getAssetsAnimation("logo-button.json")}
        />
        {showNavPageStore.state.showNavPage && (
          <img
            className={style.isShowHomeTopToolBarMenu}
            src={`${settings.S3CDN}${settings.viewAssetsPath}homeMenu.svg`}
          />
        )}
      </div>
    </div>
  );
};

export default LogoButton;
