"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewbuilderImgStore = exports.ViewbuilderImgStoreState = void 0;
const StoreBase_1 = require("../../base/cutil/StoreBase");
class ViewbuilderImgStoreState {
    constructor() {
        this.viewBuilderImgCache = {};
    }
    copyWith(param) {
        const newState = new ViewbuilderImgStoreState();
        newState.viewBuilderImgCache = param.viewBuilderImgCache ?? this.viewBuilderImgCache;
        return newState;
    }
}
exports.ViewbuilderImgStoreState = ViewbuilderImgStoreState;
class ViewbuilderImgStore extends StoreBase_1.StoreBase {
    constructor() {
        super(() => new ViewbuilderImgStoreState());
    }
    setViewBuilderImgCache(viewBuilderImgCache) {
        const newViewBuilderImgCache = Object.assign({}, this.state.viewBuilderImgCache, viewBuilderImgCache);
        const newState = this.state.copyWith({ viewBuilderImgCache: newViewBuilderImgCache });
        this.emit(newState);
    }
}
exports.ViewbuilderImgStore = ViewbuilderImgStore;
