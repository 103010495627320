"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamTextGenAdapter = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const FunctionRegistry_1 = require("../function/FunctionRegistry");
class StreamTextGenAdapter extends AdapterBase_1.AdapterBase {
    constructor() {
        super(...arguments);
        this.functionName = FunctionRegistry_1.FunctionName.textGeneration;
    }
    dynamicRunResponseTypeFunctionV2() {
        return AdapterBase_1.RunResponseType.FUNCTION_V2;
    }
    runResponseType() {
        return AdapterBase_1.RunResponseType.TEXT;
    }
}
exports.StreamTextGenAdapter = StreamTextGenAdapter;
