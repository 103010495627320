import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { CreatorNodesUtil } from "@uikit/util/CreatorNodesUtil";
import { CustomEdgeType, CustomEdgeUtil } from "@uikit/util/CustomEdgeUtil";
import { HomeUsePluginUtil } from "@uikit/util/HomeUsePluginUtil";
import { ProjectLoaderUtil } from "@uikit/util/ProjectLoaderUtil";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { PreviewStore } from "@uiview/store/PreviewStore";
import { PreviewAppTransfromUtil } from "@uiview/views/PreviewApp/PreviewAppTransfromUtil";
import { PreviewAppValueGenUtil } from "@uiview/views/PreviewApp/PreviewAppValueGenUtil";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CustomFunctionStore } from "@uikit/store/CustomFunctionStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { HomeToolBarStore } from "@uikit/store/homeToolBarStore";
import { WEB_APP_DEFAULT_SUBTITLE, WEB_APP_DEFAULT_TITLE } from "@views/thinking-layout-editor/constants";
import { ProjectStore } from "imagica-corekit/dist/base/store/ProjectStore";
import { HomeUtil } from "imagica-corekit/dist/cases/home/HomeUtil";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { isEmpty } from "lodash";

export class ProjectLoader {
  constructor(
    public fotReduxStore: FotReduxStore,
    public projectStore: ProjectStore,
    public previewStore: PreviewStore,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public creatorAISaasStore: CreatorAISaasStore,
    public previewAppStore: PreviewAppStore,
    public homeStore: HomeStore,
    public creatorRefStore: CreatorRefStore,
    public canvasDataRef: CanvasDataRef,
    public homeToolBarStore: HomeToolBarStore,
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public customFunctionStore: CustomFunctionStore,
    public projectNodeStore: ProjectNodeStore
  ) {}

  /**
   * 原来 HomeUsePlugin 用来加载项目的方法`loadFromExistingTemplateV2`
   * @param selectedExistingProject
   * @param selectedUIParam
   * @param isShare
   */
  loadFromExistingTemplateV2<T extends PreviewApp.ProjectType>(
    selectedExistingProject: T,
    selectedUIParam?: string,
    isShare = false
  ): void {
    // project刷新时转换v2数据到v3
    if (isEmpty(selectedExistingProject.v3)) {
      selectedExistingProject = HomeUsePluginUtil.handleV2TransformV3Data({
        attributesV2: selectedExistingProject as any,
        nodeIndexRef: this.creatorRefStore.nodeIndexRef,
        edgeIndexRef: this.creatorRefStore.edgeIndexRef,
        newEdgeDataRef: this.canvasDataRef.newEdgeDataRef,
        newLineDataRef: this.canvasDataRef.newLineDataRef,
      }) as T;
    }
    selectedExistingProject = PreviewAppTransfromUtil.toV2PreviewProject(selectedExistingProject) as T;

    this.previewAppStore.resetPreviewData();
    this.fotReduxStore.setDisableAddPreview(true);
    const { nodesFromProject, edgesFromProject, UIData, customFunctions, variablesList } =
      ProjectLoaderUtil.handleProjectDataV3(selectedExistingProject, this.canvasDataRef);
    // 这里直接重新计算index，保存的nodeIndex, edgeIndex存在问题
    const { maxNodeIndex, maxEdgeIndex } = CreatorNodesUtil.getNodeEdgeMaxIndex(nodesFromProject, edgesFromProject);

    edgesFromProject.forEach((x: CustomEdgeType) => {
      if (x?.data?.lineParam?.loading) x.data.lineParam.loading = false;
      // bsf-1578
      CustomEdgeUtil.assignNodeToGroupNodeHandle(x);
    });
    this.homeToolBarStore.setFigmaMode(selectedExistingProject?.v3?.navBarData?.figmaMode ?? false);
    this.creatorNodesStore.setNodes(nodesFromProject);
    this.creatorEdgesStore.setEdges(edgesFromProject);
    this.fotReduxStore.setVariableList(variablesList);

    if (!isEmpty(customFunctions)) {
      this.customFunctionStore.setCustomFunction(customFunctions);
    }

    this.loadPreviewApp({
      selectedExistingProject,
      UIData,
      selectedUIParam,
      isShare,
      compatibleInput: HomeMethodsUtil.compatibleInput,
    });

    // 重置 projectNodes, toV2PreviewProject 会保证  projectNodes 一定存在
    this.projectNodeStore.resetNodes(selectedExistingProject.v3?.projectNodes || []);

    this.creatorRefStore.nodeIndexRef.current = maxNodeIndex;
    this.creatorRefStore.edgeIndexRef.current = maxEdgeIndex;
    // 加载已保存项目时，进入convas自动展开preview面板
    // 没有保存过preview app 时就不打开
    if (!isEmpty(this.creatorSaasAppStore.state.saasUIData.id)) {
      this.previewAppStore.setShowPreviewPanel(true);
    }
  }

  /**
   * 用于进入页面加载 ui 数据, 原来在 `usePreviewAppStroe.loadPreviewApp`
   * @param props
   * @returns
   */
  loadPreviewApp(props: PreviewApp.LoadPreviewAppArgs): void {
    const { selectedExistingProject, UIData, selectedUIParam, isShare, compatibleInput } = props;

    if (isEmpty(UIData)) return;
    let previewApp = {} as PreviewApp.UISaasValueType | undefined;
    if (isShare) {
      // 加载到老版本数据时,使用转换后到 appid
      const appId =
        (this.fotReduxStore.getState() as any).editor?.subscriberParamsData?.attributes?.appId ||
        selectedExistingProject?.v3?.previewAppId;
      previewApp = UIData.find((x: any) => x.id === appId);
      this.projectStore.setAttrIndexer(HomeUtil.getAttrIndexerByTemplateProject(selectedExistingProject));
    } else if (selectedExistingProject?.v3?.previewAppId) {
      previewApp = UIData.find((x: any) => x.id === selectedExistingProject?.v3?.previewAppId);
    }

    // 将project 上面的 UIData 赋值给 appList
    this.previewStore.setPreviewAppList(UIData);
    this.previewStore.setPreviewAppAcivieId(previewApp?.id || "");

    if (!isEmpty(previewApp) && previewApp) {
      const isHorizontal = previewApp.output.some((y: any) => y.angle === "Image List");
      const text = isHorizontal ? "horizontalScroll" : "verticallyScroll";
      this.fotReduxStore.setImageScrollDireaction(text);

      const input = compatibleInput(previewApp.input, isShare, previewApp?.staticMode || false);

      // FIXME: 去掉了非分享页刷新加载数据设置 saasuidata 的流程
      if (isShare) {
        this.creatorSaasAppStore.setSaasUIData(
          PreviewAppValueGenUtil.genPreviewApp(previewApp, {
            title: previewApp.title || WEB_APP_DEFAULT_TITLE,
            // bsf-6211 subtitle 默认没有了
            subTitle:
              previewApp.subTitle || PreviewAppValueLangUtil.isChatAiApp(previewApp) ? "" : WEB_APP_DEFAULT_SUBTITLE,
            placeholder: previewApp.placeholder || "Enter something ...",
            staticMode:
              this.homeStore.state.featureTags.enableStaticMode || isShare ? previewApp?.staticMode || false : false,
            input,
            // v2app 字段在 loadPreviewApp 中应该必有
            v2App: previewApp.v2App as any,
          })
        );
      }

      this.previewAppStore.setShowPreviewPanel(true);

      if (previewApp?.id) {
        this.previewAppStore.previewToSaasUiData(previewApp?.id);
        this.previewAppStore.setPreviewShowStatus(true);
        this.previewAppStore.setHistoryShowStatus(true);
      }

      // 设置一个初始选中的ui
      this.previewStore.setSelectedUI(selectedUIParam || previewApp.value);
      // 初始化preview input, 这里直接使用接口返回的input数据
      const inputIdArr = input.map((x: any) => x.id);
      this.creatorAISaasStore.setInputId(inputIdArr);
    }

    // FIXME: 重新赋值一次 selectTemplate
    this.fotReduxStore.setSelectedTemplate(selectedExistingProject);
  }
}
