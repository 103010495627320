import { DisplayType } from "../typing";

export const defaultNodeLabelMapping: Record<DisplayType, string> = {
  text: "Text",
  Agent: "Agent",
  video: "Youtube",
  jdShopping: "JD Shopping",
  customJsFunction: "Custom JS Function",
  stockInfo: "Stocks",
  stock: "Stocks",
  htmlTemplate: "HTML",
  customApiFunction: "Custom API Function",
  amazonShopping: "Amazon Shopping",
  weeeShopping: "Weee Shopping",
  chatBox: "Chat",
  inputTextarea: "Input Textarea",
  imageGen: "Image",
  midjourney: "Image",
  midjourneyV2: "Image",
  imageSearch: "Image",
  showFoodInterface: "Food",
  askBrain: "Ask Imagica",
  map: "Map",
  chatInterface: "Chat",
  blueprint: "Blueprint",
  json: "Json",
  interviewBot: "Interview Bot",
  list: "List",
  uploadFile: "Upload File",
  uploadImage: "Image",
  baseVideo: "Video",
  unStructured: "Unstructured",
  table: "Table",
  pdfFile: "PDF",
  audio: "Audio",
  html: "HTML",
};
