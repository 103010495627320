"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProteinModel = void 0;
class ProteinModel {
    constructor(type, value) {
        this.type = type;
        this.value = value;
    }
}
exports.ProteinModel = ProteinModel;
