export enum TemplateThemeType {
  CHATAI = "chat_ai",
  AIAPP = "ai_app",
}
class TemplateThemesType {
  key?: string;
  image?: string;
  features?: string[];
}
export class ImagicaAppTemplates {
  name?: string;
  value?: string;
  themes?: Array<TemplateThemesType>;
}
export class AppearanceUtil {
  static getThemes(type: TemplateThemeType, arr: Array<ImagicaAppTemplates>): TemplateThemesType[][] {
    const result = [];
    const obj = arr.find((x: ImagicaAppTemplates) => x.value === type);
    if (obj && obj.themes) {
      const themeArr = obj.themes;
      for (var i = 0; i < themeArr.length; i += 4) {
        result.push(themeArr.slice(i, i + 4));
      }
    }
    return result;
  }
}
