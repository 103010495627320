import { FontStyleCp } from "./FontStyleCp/FontStyleCp";
import {
  DeleteCp,
  EditPlaceHolderCp,
  CaptionToggleCp,
  TextStyleCp,
  BoldCp,
  ItalicCp,
  BeautifyCp,
  UnderLineCp,
} from "./ChildComponents";
import { TalkToAIBuilderCp } from "./TalkToAIBuilder/TalkToAIBuilderCp";

// type definition
export enum ToolBarConfigKey {
  "default",
  "title",
  "chatTitle",
  "description",
  "input",
  "inputNodeLabel",
  "inputField",
  "image",
  "textOutputNode",
  "video",
  "audio",
  "chat",
  "stock",
  "amazon",
  "weeeGrocery",
  "youtube",
  "askImagica",
  "fileUpload",
  "pdf",
  "word",
  "table",
  "stockInfo",
}

export type ToolBarConfigKeyType = keyof typeof ToolBarConfigKey;

export interface ToolBarConfigValue {
  fontStyleKey: "fontTitle" | "fontSub" | "fontLabel" | "fontBody" | "";
  componentsConfig: Array<{
    component: any;
    dataStateKey?: string;
    tips: string | string[];
    disable?: boolean;
  }>;
}

export const TipsType = ["Text style", "Bold", "Italic", "UnderLine", "isBeautify"];

export const typeMap: {
  [key: string]: string;
} = {
  textbox: "textOutputNode",
  chatInterface: "chat",
  video: "video",
  baseVideo: "video",
  audio: "audio",
  stockInfo: "stock",
  amazonShopping: "amazon",
  weeeShopping: "weeeGrocery",
  youtube: "youtube",
  askBrain: "askImagica",
  uploadFile: "fileUpload",
  pdfFile: "pdf",
  uploadImage: "image",
  image: "image",
  table: "table",
  stock: "stock",
};

export const TOOLBAR_CONFIG: Record<ToolBarConfigKeyType, ToolBarConfigValue> = {
  default: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  title: {
    fontStyleKey: "fontTitle",
    componentsConfig: [
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  chatTitle: {
    fontStyleKey: "fontTitle",
    componentsConfig: [
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  description: {
    fontStyleKey: "fontSub",
    componentsConfig: [
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  input: {
    fontStyleKey: "fontBody",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: EditPlaceHolderCp,
        tips: "Edit placeholder text",
      },
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  inputNodeLabel: {
    fontStyleKey: "fontLabel",
    componentsConfig: [
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  inputField: {
    fontStyleKey: "fontBody",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: EditPlaceHolderCp,
        tips: "Edit placeholder text",
      },
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  image: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: CaptionToggleCp,
        dataStateKey: "isCaptionToggle",
        tips: ["Show Caption", "Hide Caption"],
      },
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  textOutputNode: {
    fontStyleKey: "fontBody",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      /* {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: BoldCp,
        tips: "Bold",
      },
      {
        component: ItalicCp,
        tips: "Italic",
      },
      {
        component: UnderLineCp,
        tips: "UnderLine",
      },
      {
        component: BeautifyCp,
        tips: ["Hide Background", "Show Background"],
        dataStateKey: "isBeautify",
      }, */
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  video: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  audio: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  chat: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  stock: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  amazon: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  weeeGrocery: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  youtube: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  askImagica: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: TextStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  fileUpload: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  pdf: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  word: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  table: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  stockInfo: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
};

export const TextStyle_CONFIG: Record<string, ToolBarConfigValue> = {
  default: {
    fontStyleKey: "",
    componentsConfig: [],
  },
  title: {
    fontStyleKey: "fontTitle",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
    ],
  },
  chatTitle: {
    fontStyleKey: "fontTitle",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
    ],
  },
  description: {
    fontStyleKey: "fontSub",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
    ],
  },
  input: {
    fontStyleKey: "fontBody",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
    ],
  },
  inputNodeLabel: {
    fontStyleKey: "fontLabel",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
    ],
  },
  inputField: {
    fontStyleKey: "fontBody",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
    ],
  },
  askImagica: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: BoldCp,
        tips: "Bold",
      },
      {
        component: ItalicCp,
        tips: "Italic",
      },
      {
        component: UnderLineCp,
        tips: "UnderLine",
      },
      {
        component: BeautifyCp,
        tips: ["Hide Background", "Show Background"],
        dataStateKey: "isBeautify",
      },
    ],
  },
  image: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: BoldCp,
        tips: "Bold",
      },
      {
        component: ItalicCp,
        tips: "Italic",
      },
      {
        component: UnderLineCp,
        tips: "UnderLine",
      },
      {
        component: BeautifyCp,
        tips: ["Hide Background", "Show Background"],
        dataStateKey: "isBeautify",
      },
    ],
  },
  textOutputNode: {
    fontStyleKey: "fontBody",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: BoldCp,
        tips: "Bold",
      },
      {
        component: ItalicCp,
        tips: "Italic",
      },
      {
        component: UnderLineCp,
        tips: "UnderLine",
      },
      {
        component: BeautifyCp,
        tips: ["Hide Background", "Show Background"],
        dataStateKey: "isBeautify",
      },
    ],
  },
};

export const TOOLBAR_CONFIG_MOBILE: Record<ToolBarConfigKeyType, ToolBarConfigValue> = {
  default: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  title: {
    fontStyleKey: "fontTitle",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  chatTitle: {
    fontStyleKey: "fontTitle",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  description: {
    fontStyleKey: "fontSub",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  input: {
    fontStyleKey: "fontBody",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: EditPlaceHolderCp,
        tips: "Edit placeholder text",
      },
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  inputNodeLabel: {
    fontStyleKey: "fontLabel",
    componentsConfig: [
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: BoldCp,
        tips: "Bold",
      },
      {
        component: ItalicCp,
        tips: "Italic",
      },
      {
        component: UnderLineCp,
        tips: "UnderLine",
      },
      {
        component: BeautifyCp,
        tips: ["Hide Background", "Show Background"],
        dataStateKey: "isBeautify",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  inputField: {
    fontStyleKey: "fontBody",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: EditPlaceHolderCp,
        tips: "Edit placeholder text",
      },
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: BoldCp,
        tips: "Bold",
      },
      {
        component: ItalicCp,
        tips: "Italic",
      },
      {
        component: UnderLineCp,
        tips: "UnderLine",
      },
      {
        component: BeautifyCp,
        tips: ["Hide Background", "Show Background"],
        dataStateKey: "isBeautify",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  image: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: CaptionToggleCp,
        dataStateKey: "isCaptionToggle",
        tips: ["Show Caption", "Hide Caption"],
      },
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: BoldCp,
        tips: "Bold",
      },
      {
        component: ItalicCp,
        tips: "Italic",
      },
      {
        component: UnderLineCp,
        tips: "UnderLine",
      },
      {
        component: BeautifyCp,
        tips: ["Hide Background", "Show Background"],
        dataStateKey: "isBeautify",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  textOutputNode: {
    fontStyleKey: "fontBody",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: BoldCp,
        tips: "Bold",
      },
      {
        component: ItalicCp,
        tips: "Italic",
      },
      {
        component: UnderLineCp,
        tips: "UnderLine",
      },
      {
        component: BeautifyCp,
        tips: ["Hide Background", "Show Background"],
        dataStateKey: "isBeautify",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  video: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  audio: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  chat: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  stock: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  stockInfo: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  amazon: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  weeeGrocery: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  youtube: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  askImagica: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: FontStyleCp,
        tips: "Text style",
      },
      {
        component: BoldCp,
        tips: "Bold",
      },
      {
        component: ItalicCp,
        tips: "Italic",
      },
      {
        component: UnderLineCp,
        tips: "UnderLine",
      },
      {
        component: BeautifyCp,
        tips: ["Hide Background", "Show Background"],
        dataStateKey: "isBeautify",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  fileUpload: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  pdf: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  word: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
  table: {
    fontStyleKey: "",
    componentsConfig: [
      {
        component: TalkToAIBuilderCp,
        tips: "Ask about this feature",
      },
      {
        component: DeleteCp,
        tips: "Delete",
      },
    ],
  },
};
