import { DEFAULT_ALLOWZOMMORPAN } from "@custom-hooks/HomeUsePluginRoot/conts";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class HomeToolBarStoreState {
  allowZoomOrPan = DEFAULT_ALLOWZOMMORPAN;

  figmaMode = false;

  historyIndex = -1;

  currentZoomLevel = 100;

  get panOnDrag(): boolean {
    return this.allowZoomOrPan ? !this.figmaMode : this.allowZoomOrPan;
  }
  get panOnScroll(): boolean {
    return this.allowZoomOrPan ? this.figmaMode : this.allowZoomOrPan;
  }
  get selectionOnDrag(): boolean {
    return this.allowZoomOrPan ? this.figmaMode : this.allowZoomOrPan;
  }
  get nodesDraggable(): boolean {
    return this.allowZoomOrPan;
  }
  get zoomOnScroll(): boolean {
    return this.allowZoomOrPan;
  }
  get zoomOnPinch(): boolean {
    return this.allowZoomOrPan;
  }
  get elementsSelectable(): boolean {
    return this.allowZoomOrPan;
  }

  copyWith(param: Partial<Omit<HomeToolBarStoreState, "copyWith">>): HomeToolBarStoreState {
    const newState = new HomeToolBarStoreState();
    newState.allowZoomOrPan = param.allowZoomOrPan ?? this.allowZoomOrPan;
    newState.figmaMode = param.figmaMode ?? this.figmaMode;
    newState.historyIndex = param.historyIndex ?? this.historyIndex;
    newState.currentZoomLevel = param.currentZoomLevel ?? this.currentZoomLevel;

    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);

export class HomeToolBarStore extends StoreBase<HomeToolBarStoreState> {
  constructor() {
    super(() => new HomeToolBarStoreState());
  }

  setAllowZoomOrPan = (allowZoomOrPan: boolean): void => {
    this.emit(this.state.copyWith({ allowZoomOrPan }));
  };

  setFigmaMode = (figmaMode: boolean): void => {
    this.emit(this.state.copyWith({ figmaMode }));
  };

  setHistoryIndex(historyIndex: number): void {
    this.emit(this.state.copyWith({ historyIndex }));
  }

  setZoom(zoom: number): void {
    this.emit(this.state.copyWith({ currentZoomLevel: zoom }));
  }
}

export const homeToolBarStore = new HomeToolBarStore();
