import { Signal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { findLastIndex } from "lodash";
import { CallingBloc, PromptInfos } from "./ChatBloc";
import { Constants, Role } from "./Constants";
import { JsonItem, Message, OnboardingBloc, OnboardingState } from "./OnboardingBloc";

class CallerBotState extends OnboardingState {
  public messages = new Signal<Message[]>([
    {
      content: `To generate a customized call instruction for you, please take a moment to answer the following questions. Type in your answers or choose from the provided options:
    What is the primary purpose of your phone calls?`,
      role: Role.assistant,
    },
  ]);
  public currentStep = new Signal(1);
  public answerIndex = new Signal(0);
  public phoneNumber = new Signal("");
  public submitValue = new Signal("");
  public collapsed = new Signal(false);
  public buildEnd = new Signal(false);
  public showBehaviorButton = new Signal(false);
  public showMakeCallButton = new Signal(false);
  public isCallerBotBloc = new Signal(true);
  public callInstructionText = new Signal("");
  public process = new Signal(["Ideate", "Develop", "Test"]);
}

export class CallerBotBloc extends OnboardingBloc {
  homePluginStore = getIt(HomePluginStore);
  brainClient = getIt(BrainClient);

  public callingBloc = new CallingBloc(PromptInfos.callPhone);

  state = new CallerBotState();

  setSubmitValue(value: string) {
    this.state.submitValue.value = value;
  }

  async onPressEnter() {
    /// 用户输入
    const submitValue = this.state.submitValue.value;
    const message = { role: Role.user, content: this.state.submitValue.value };
    const newMessages = [...this.state.messages.value, message];
    this.state.messages.value = [...newMessages, { loading: true, content: "", role: Role.assistant }];
    this.state.submitValue.value = "";
    // 回复
    const result = await this.callingBloc.transformAssistantMessage(submitValue, msg => {
      const value = this.jsonItemRegex(JsonItem.current_response, msg);
      const suggests = this.jsonArrayRegex(JsonItem.suggested_responses, msg);
      const suggestArr = suggests?.split(`", "`).map(x => x.replaceAll(`"`, ""));
      this.modifyLastMessage("suggestion", suggestArr);

      if (value) {
        this.addOrUpdateAssistantMsg(value);
      } else {
        this.addOrUpdateAssistantMsg(msg);
      }
    });
    this.state.callInstructionText.value = result?.call_instruction || "";
    this.state.showMakeCallButton.value = !isBlank(result?.call_instruction);
    this.state.answerIndex.value += 1;
  }

  private addOrUpdateAssistantMsg(msg: string): void {
    const lastIndex = findLastIndex(this.state.messages.value);
    if (this.state.messages.value[lastIndex].role === Role.assistant) {
      const newMsg = [...this.state.messages.value];
      newMsg[lastIndex].content = msg;
      newMsg[lastIndex].loading = false;
      this.state.messages.value = newMsg;
    }
  }

  makeItCall() {
    const newMessages = [...this.state.messages.value, { role: Role.user, content: "Make it test call!" }];
    this.state.messages.value = newMessages;
    this.state.submitValue.value = "";
    setTimeout(() => {
      this.state.messages.value = [...newMessages, ...Constants.makeItCall];
      this.state.answerIndex.value += 1;
      this.state.showMakeCallButton.value = false;
    }, 1000);
  }

  async callPhone(ownInput = false) {
    if (
      (ownInput && this.state.submitValue.value.trim().length === 0) ||
      (!ownInput && this.state.phoneNumber.value.trim().length === 0)
    ) {
      func.customMsg({
        content: "Please input phone number",
        type: "warning",
      });
      return;
    }
    const preamble = this.state.callInstructionText.value;
    const newMessages = [...this.state.messages.value, { role: Role.user, content: "Call" }];
    this.state.messages.value = newMessages;
    this.state.submitValue.value = "";
    this.state.currentStep.value += 1;
    const to = this.state.phoneNumber.value; //4156713884
    const result = await tryPromise(this.brainClient.openAi.conversation({ to, preamble, intro: "callerbot" }));
    if (result.error) {
      func.customMsg({
        content: "Failed to make a call",
        type: "warning",
      });
      // return;
    }
    setTimeout(() => {
      this.state.messages.value = [...newMessages, ...Constants.callPhone];
      this.state.answerIndex.value += 1;
      this.state.showBehaviorButton.value = true;
    }, 1000);
  }

  happyBehavior() {
    const newMessages = [...this.state.messages.value, { role: Role.user, content: "I’m happy with the bot behavior" }];
    this.state.messages.value = newMessages;
    this.state.submitValue.value = "";
    setTimeout(() => {
      this.state.messages.value = [...newMessages, ...Constants.happyBehavior];
      this.state.answerIndex.value += 1;
    }, 1000);
  }

  closeCallerBot() {
    this.homePluginStore.setShowCallerBot(false);
  }

  closePreview() {
    this.state.showPreview.value = false;
  }

  setPhoneNumber(val: string) {
    this.state.phoneNumber.value = val;
  }

  handleClickCollapse() {
    this.state.collapsed.value = !this.state.collapsed.value;
  }
}
