import { findIndex, get, isArray } from "lodash";
import { useCallback, useEffect, useRef } from "react";

const scrollDirectionMapping = {
  vertical: {
    elementUnit: "top",
    scrollWrapperUnit: "scrollTop",
    scrollDirection: "top",
  },
  horizontal: {
    elementUnit: "left",
    scrollWrapperUnit: "scrollLeft",
    scrollDirection: "left",
  },
};

const useScrollToLatest = ({
  scrollWrapperRef,
  scrollList = [],
  selector,
  direction = "vertical",
  enableScrollToImage = false,
  noNeedScroll = false,
}) => {
  const scrollListLength = scrollList.length;
  const scrollListLengthRef = useRef(scrollList.length);
  const imageDataLengthRef = useRef(0);

  const scrollTo = useCallback(
    (start = scrollListLengthRef.current) => {
      const { elementUnit, scrollWrapperUnit, scrollDirection } = scrollDirectionMapping[direction];

      const scrollWrapperElement = scrollWrapperRef.current;
      const scrollWrapperRect = scrollWrapperElement.getBoundingClientRect();

      const latestGroupElements = Array.from(scrollWrapperElement.querySelectorAll(selector)).splice(start);
      if (latestGroupElements[0]) {
        const latestElementRect = latestGroupElements[0].getBoundingClientRect();

        scrollWrapperElement.scrollTo({
          [scrollDirection]:
            scrollWrapperElement[scrollWrapperUnit] - (scrollWrapperRect[elementUnit] - latestElementRect[elementUnit]),
          behavior: "smooth",
        });
      }
    },
    [direction, scrollWrapperRef, selector]
  );

  useEffect(() => {
    if (scrollWrapperRef.current && !noNeedScroll) {
      if (enableScrollToImage) {
        const imageDataIndex = findIndex(scrollList, item => item.angle === "Image List");
        const imageData = get(scrollList, [imageDataIndex, "results"]);
        if (isArray(imageData)) {
          const imageDateLength = imageData.length;
          if (imageDateLength > imageDataLengthRef.current) {
            scrollTo(imageDataIndex === 0 ? imageDataIndex : imageDataIndex - 1);
          }
          imageDataLengthRef.current = imageDateLength;
        } else {
          imageDataLengthRef.current = 0;
        }
      }

      if (scrollListLength > scrollListLengthRef.current) {
        scrollTo();
      }

      scrollListLengthRef.current = scrollListLength;
    }
  }, [enableScrollToImage, scrollList, scrollListLength, scrollTo, scrollWrapperRef]);
};

export default useScrollToLatest;
