import { Modal, Spin } from "antd";
import style from "./AuthCodeView.module.scss";
import { useEffect, useRef } from "react";
import { AlertOtpData } from "@uiview/pages/profile/account/AccountData";
import { CodeInput } from "./CodeInput";
import isBlank from "@sedan-utils/is-blank";
import { useSignal } from "@preact/signals-react";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

export function AuthCodeView(props: {
  alertData: AlertOtpData;
  otpInputComplete: (otp: string) => void;
  close: () => void;
  sendAgain: () => void;
  count?: number;
  otpLoading?: boolean;
  sendAgainLoading: boolean;
}) {
  return (
    <Modal
      title=""
      maskClosable={false}
      keyboard={false}
      open={true}
      onCancel={() => {
        console.log("oncancel");
      }}
      wrapClassName={style.myAccountVerifyModal}
      closeIcon={
        <img
          src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`}
          onClick={() => {
            console.log("tap close");
            props.close();
          }}
          width="20"
          height="20"
        />
      }
      footer={[]}
      centered={true}
    >
      <AuthCodeContentView
        alertData={props.alertData}
        otpInputComplete={props.otpInputComplete}
        close={props.close}
        sendAgain={props.sendAgain}
        count={props.count}
        otpLoading={props.otpLoading}
        sendAgainLoading={props.sendAgainLoading}
      />
    </Modal>
  );
}

export function AuthCodeContentView(props: {
  alertData: AlertOtpData;
  otpInputComplete: (otp: string) => void;
  close: () => void;
  sendAgain: () => void;
  count?: number;
  otpLoading?: boolean;
  sendAgainLoading?: boolean;
  deleteLoading?: boolean;
  clickDelete?: (code: string) => void;
}) {
  const verifyCode = useSignal("");
  const needClean = useSignal(false);
  // 验证码改变事件
  const handleChange = (verifyNum: string) => {
    // setVerifyCode(verifyCode);
    verifyCode.value = verifyNum;
  };
  // 验证码输入完成后进行校验
  const handleComplete = (verifyNum: string) => {
    if (props.alertData.isDelecount) return;
    props.otpInputComplete(verifyNum);
  };
  // 请求发送验证码
  const sendVerifyCode = () => {};

  useEffect(() => {
    // 刚进入页面时，发送验证码
    sendVerifyCode();
  }, []);

  const sendAgain = () => {
    if (props.alertData.otpLoading) {
      return;
    }
    // verifyCode("")
    verifyCode.value = "";
    props.sendAgain();
    needClean.value = true;
    setTimeout(() => {
      needClean.value = false;
    }, 500);
  };

  return (
    <div className={style.myAccountVerifyBox}>
      <div>
        <h5 className={style.verifyTitle}>{props.alertData.title}</h5>
        <span className={style.verifyDescribe}>{props.alertData.sendOtpDesciption}</span>
        {/* <div className={style.verifyDescribeNum}> +0 123-456-7890</div> */}
      </div>
      <section style={{ marginTop: props.alertData.isDelecount ? "10px" : "30px" }}>
        <h5 className={style.verifyCodeTitle}>Enter the code here</h5>
        <Spin spinning={props.otpLoading === true}>
          <CodeInput
            verifyCode={verifyCode.value}
            onChange={handleChange}
            onComplete={handleComplete}
            cleanInput={needClean.value}
          />
        </Spin>

        {!isBlank(props.alertData.errorText) && (
          <span className={style.verifyCodeFailWarn}>{props.alertData.errorText}</span>
        )}
      </section>

      {props.alertData.isDelecount && (
        <DeleteAccountFooter
          count={props.count}
          enableDelete={verifyCode.value.length === 6}
          otpLoadind={props.alertData.sendAgainLoading}
          sendAgain={sendAgain}
          sureDelete={() => {
            if (props.clickDelete) props.clickDelete(verifyCode.value);
          }}
          deleteLoading={props.deleteLoading}
        />
      )}
      {!props.alertData.isDelecount && (
        <Spin spinning={props.otpLoading === true}>
          <footer className={style.hideConfirmDelBtn}>
            <button className={props.count !== -1 ? style.verifyCountDownLft : style.sendAgain} onClick={sendAgain}>
              {props.count !== -1 ? `Send again in ${props.count} sec` : "Send again"}
            </button>
          </footer>
        </Spin>
      )}
    </div>
  );
}

/**
 * 删除账号样式的footer
 */
function DeleteAccountFooter(props: {
  count?: number;
  enableDelete: boolean;
  otpLoadind?: boolean;
  deleteLoading?: boolean;
  sendAgain: () => void;
  sureDelete: () => void;
}) {
  return (
    <div className={style.sendAgainFooter}>
      <Spin spinning={props.otpLoadind}>
        <button
          className={props.count !== -1 ? style.verifyCountDownLft : style.sendAgain}
          onClick={() => {
            if (!props.otpLoadind && props.count === -1) {
              props.sendAgain();
            }
          }}
        >
          {props.count !== -1 ? `Send again in ${props.count} sec` : "Send again"}
        </button>
      </Spin>
      <Spin spinning={props.deleteLoading === true}>
        <button
          className={props.enableDelete ? style.enableDelete : style.disableDelete}
          onClick={() => {
            if (props.enableDelete && !props.deleteLoading) {
              props.sureDelete();
            }
          }}
        >
          Confirm deletion
        </button>
      </Spin>
    </div>
  );
}
