import func from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import { EDITORABLE_INPUT_TYPE, NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import { isEmpty } from "lodash";
import { ViewBuilderData } from "imagica-uikit/dist/nodesV2/ViewBuilderComponents/ViewBuilderWorker";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { customFunctionStore } from "@uikit/store/CustomFunctionStore";
import { selectApiReg } from "@uiview/views/ProjectCanvas/homeConst";
import { EdgeAttr } from "@uikit/project/HomeUsePluginData";
import { StudioProjectAttributesV2EdgeData } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { Node } from "reactflow";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";

export class HomeMethodsUtil {
  // eslint-disable-next-line
  static generateNewData = (
    data: any[],
    nodeId: any,
    content: any,
    contentType: any,
    errorText: any,
    viewBuilderData?: ViewBuilderData
  ) => {
    const newArr = data.map((x: { results: any[] }) => {
      const resultArr = x.results.map((card: { nodeId: any }) => {
        if (card.nodeId === nodeId) {
          return {
            ...card,
            content,
            errorText,
            viewBuilderData,
            type: contentType,
          };
        }
        return card;
      });
      return {
        ...x,
        results: resultArr,
      };
    });
    return newArr;
  };

  static getNewInputData = (data: any[], nodeId: any, textAreaValue: any, isShare: boolean): any[] => {
    // opt:
    // if (homePluginState.isShare) return data;
    if (isShare) return data;
    const newArr = data.map((node: { id: any; data: { displayType: string } }) => {
      if (node.id === nodeId && EDITORABLE_INPUT_TYPE.includes(node.data.displayType)) {
        return {
          ...node,
          data: {
            ...node.data,
            textAreaValue,
          },
        };
      }
      return node;
    });
    return newArr;
  };

  // eslint-disable-next-line
  static getStartEndNodesContentArr = (prevNodes: any[], currNodes?: any[], allNodes: any[] = []) => {
    currNodes =
      currNodes ||
      allNodes.map(x => ({
        id: x.id,
        textAreaValue: x.data.textAreaValue,
        displayType: x.data.displayType,
      }));
    return prevNodes
      .map(x => {
        const currNode = currNodes?.find(y => y.id === x.id);
        if (!func.isEmpty(currNode)) {
          return {
            startNodeContent: x,
            endNodeContent: currNode,
          };
        }
        return {};
      })
      .filter(x => !func.isEmpty(x));
  };

  static compatibleInput(oldInput: any, isShare: any, isStaticMode: any): any[] {
    const oldInputArr = Array.isArray(oldInput) ? oldInput : isEmpty(oldInput) ? [] : [oldInput];
    if (!isShare) return oldInputArr;

    return oldInputArr.map(x => {
      return {
        ...x,
        data: {
          ...x.data,
          textAreaValue: isStaticMode ? x.data.textAreaValue : "",
        },
      };
    });
  }

  static handleHiddenNode = (thoughtArr: any[]): any[] => {
    const arr = [] as any[];

    thoughtArr.forEach(x => {
      const noDelArr = x.results.filter((r: any) => {
        // 如果有报错，那么也需要保留并显示出来。
        if (!func.isEmpty(r?.errorText)) return r;
        switch (r.type) {
          case "stock":
            return !func.isEmpty(r?.content?.symbol) && !func.isEmpty(r?.content?.value);
          case "code":
            return !func.isEmpty(r?.content?.code?.[0]?.codex_generation);
          case "chatBox":
            return r;
          default:
            return !func.isEmpty(r.content);
        }
      });
      if (!func.isEmpty(noDelArr)) {
        arr.push({
          ...x,
          results: noDelArr,
        });
      }
    });
    return arr;
  };

  // eslint-disable-next-line
  static getFilterDataAttrsNode(
    node: { data: { [s: string]: unknown } | ArrayLike<unknown> },
    needFilterAttrs: string | string[]
  ) {
    const needSaveDataAttrs = Object.entries(node.data).filter(([key]) => !needFilterAttrs.includes(key));
    const needSaveData = Object.fromEntries(needSaveDataAttrs);
    return {
      ...node,
      data: needSaveData,
    };
  }

  static setSimplifiedEdgeNode(obj: any, canvasDataRef: CanvasDataRef): any {
    if (obj.type === "customNode") {
      return {
        ...obj,
        data: {
          ...canvasDataRef.nodeDataRef.current,
          ...obj.data,
        },
      };
    }
    if (obj.type === "customEdge") {
      return {
        ...obj,
        data: {
          ...canvasDataRef.edgeDataRef.current,
          ...obj.data,
          lineParam: {
            ...canvasDataRef.edgeDataRef.current.lineParam,
            ...(obj.data?.lineParam || {}),
          },
        },
      };
    }
    if (obj.type === "customGroup") {
      return {
        ...obj,
        data: {
          ...canvasDataRef.groupDataRef.current,
          ...obj.data,
        },
      };
    }
    if (obj.type === "customNewEdge") {
      return {
        ...obj,
        data: {
          ...canvasDataRef.newEdgeDataRef.current,
          ...obj.data,
          lineParam: {
            ...canvasDataRef.newEdgeDataRef.current.lineParam,
            ...(obj.data?.lineParam || {}),
          },
        },
      };
    }
    if (obj.type === "customNewLine") {
      return {
        ...obj,
        data: {
          ...canvasDataRef.newLineDataRef.current,
          ...obj.data,
        },
      };
    }
    return obj;
  }

  static getNewEdgeAllLineIdsByNode(node: any, edges: any[]): any[] {
    const lines = edges.filter(x => x.source === node.id || x.target === node.id);
    return lines.map(x => x.id);
  }

  // eslint-disable-next-line
  static getStartEndPositionArr(currentDragNodes: any[], dragNodes: any[]) {
    return currentDragNodes
      .map(x => {
        const currNode = dragNodes.find(y => y.id === x.id);
        if (!func.isEmpty(currNode)) {
          return {
            id: x.id,
            startPosition: x.position,
            endPosition: currNode.position,
          };
        }
        return {};
      })
      .filter(x => !func.isEmpty(x));
  }

  static getEdgeNode(delEdges: any[], allNodes: any[]): any {
    const target = delEdges?.[0]?.target || null;
    const edgeNode = allNodes.find(x => x.id === target);
    return edgeNode?.data?.lineParam || {};
  }

  static getNewEdgeDataByLine(
    line: any,
    nodes: any[]
  ): {
    edge: any;
    lineIds: any[];
  } {
    const source = line.source;

    if (NEW_EDGE_REG.test(source)) {
      /// 选中为targetLine, 返回edge和[sourceLine,targetLine]
      const edge = nodes.find(node => node.id === source);
      return {
        edge: edge,
        lineIds: [line.id, ...edge.data.flows.map((x: any) => x.sourceLineId)],
      };
    } else {
      // 边
      const target = line.target;
      const edge = nodes.find(node => node.id === target);
      return {
        edge: edge,
        lineIds: [line.id, edge.data.targetLineId],
      };
    }
  }

  static isGroupChildeNodes(node: any, nodes: any[]): any[] {
    return nodes.filter(x => !func.isEmpty(node.parentNode) && x.parentNode === node.parentNode);
  }

  static handleClickAmplitude(event: any): void {
    const data_amplitude = event.target.getAttribute("data-amplitude");
    let target = "";
    let option = "";
    if (data_amplitude) {
      target = data_amplitude?.split(":")[0] || "";
      option = data_amplitude?.split(":")[1] || "";
      logEvent("click", { target: target, option: option });
    }
  }

  static getClickCreateContentNewNodes(inputNode: any, nodes: any[]): any[] {
    return nodes.map(x => {
      const node = inputNode.find((input: any) => input.id === x.id);
      const textAreaValue =
        typeof node?.data?.textAreaValue === "string" ? node?.data?.textAreaValue?.trim() : node?.data?.textAreaValue;

      if (!isEmpty(node)) {
        return {
          ...x,
          data: {
            ...x.data,
            textAreaValue,
          },
        };
      }
      return x;
    });
  }

  //获取删除后的inputs
  static deleteInputNode(saasDataObj: any, nodeIds: Array<string>): any {
    if (func.isEmpty(saasDataObj)) return [];
    const { oldData, type } = saasDataObj;
    if (type === "thinkingApp") return [];
    const newInput = (oldData.input || []).filter((x: any) => !nodeIds.includes(x.id));
    return newInput;
  }

  static getItemRelavantFunctionsV3(edges: EdgeAttr[], nodes: Node[]): any[] {
    // 本地所有自定义 function
    const funcArrs = creatorRefStore.singleFlowEdgeArrRef.current || [];

    // 当前接口所保存自定义 function
    if (!isEmpty(customFunctionStore.customFunctionRef.current)) {
      // 去重以本地创建 function 为主
      const noDuplicateArr = customFunctionStore.customFunctionRef.current.filter((x: any) => {
        return !funcArrs.some((y: any) => y.name === x.name);
      });
      funcArrs.push(...noDuplicateArr);
    }
    // 所有用到的自定义 func
    let edgesNameArr = edges
      .map(x => {
        return selectApiReg.test(x.data.textAreaValue) && x.data.textAreaValue.replace("/", "");
      })
      .filter(x => x);

    /// 如果是v3数据，则edge数据在getHomeNodes中
    const newEdges = nodes.filter((n: any) => n.type === "customNewEdge");
    if (newEdges.length !== 0) {
      edgesNameArr = newEdges
        .map(x => {
          const enterText = (x.data as StudioProjectAttributesV2EdgeData)?.lineParam?.enterText || "";
          return selectApiReg.test(enterText) && enterText.replace("/", "");
        })
        .filter(x => x);
    }

    const usedFunctions = funcArrs.filter((x: any) => edgesNameArr.includes(x.name));
    return usedFunctions;
  }
}
