"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeContentEnterMsg = void 0;
/// node输入内容并Command Enter时触发
class NodeContentEnterMsg {
    constructor(nodeId) {
        this.nodeId = nodeId;
        this.naming = "NodeContentEnterMsg";
    }
}
exports.NodeContentEnterMsg = NodeContentEnterMsg;
