import {
  DeleteAccountData,
  DeleteAccountAskData,
  AlertOtpData,
  DeleteAlertState,
} from "@uiview/pages/profile/account/AccountData";
import styles from "./DeleteAccountAlert.module.scss";
import { Modal } from "antd";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { AuthCodeContentView } from "./AuthCodeView";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";

export function DeleteAccountAlert(props: {
  alertData: DeleteAccountData;
  deleteLoading?: boolean;
  tapSureDelete: () => void;
  confirmClose: () => void;
  confirmDelete: (code: string) => void;
  sendAgain: () => void;
}): JSX.Element {
  const isMobile = window.navigator.userAgent.includes("Mobile");
  const dataState = props.alertData.dataState.value;
  const modalClassName =
    dataState === DeleteAlertState.askDelete ? styles.deleteAccountModal : styles.deleteConfirmModal;

  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags: FeatureTags = homeStoreState.featureTags;

  // 移动端样式调整
  const mobileCls = isMobile && featureTags.disableMobilePage ? styles.mobileClsModal : "";

  return (
    <Modal
      title=""
      maskClosable={false}
      keyboard={false}
      open={true}
      wrapClassName={`${modalClassName} ${mobileCls}`}
      footer={[]}
      centered={true}
      closeIcon={
        dataState === DeleteAlertState.askDelete ? (
          <></>
        ) : (
          <img
            src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`}
            onClick={() => {
              props.confirmClose();
            }}
            width="20"
            height="20"
          />
        )
      }
    >
      {dataState === DeleteAlertState.askDelete && (
        <AskDeleteView
          alertData={props.alertData.askDeleteData}
          tapNeverind={props.confirmClose}
          tapSureDelete={props.tapSureDelete}
        />
      )}
      {dataState === DeleteAlertState.confirmDelete && (
        <ConfirmDeleteView
          alertData={props.alertData.confirmDeleteData}
          confirmClose={props.confirmClose}
          confirmDelete={props.confirmDelete}
          sendAgain={props.sendAgain}
          deleteLoading={props.deleteLoading}
        />
      )}
    </Modal>
  );
}

function AskDeleteView(props: {
  alertData: DeleteAccountAskData;
  tapNeverind: () => void;
  tapSureDelete: () => void;
}): JSX.Element {
  const askDeleteData = props.alertData;
  return (
    <div className={styles.container}>
      <div className={styles.textContent}>
        <div className={styles.title}>{askDeleteData.title}</div>
        <div className={styles.subContent}>
          <div className={styles.subTitle1}>{askDeleteData.subTitle}</div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <button
          className={styles.neverMind}
          onClick={() => {
            props.tapNeverind();
          }}
        >
          {askDeleteData.nevermindText}
        </button>
        <button
          className={styles.sureDelete}
          onClick={() => {
            props.tapSureDelete();
          }}
        >
          {askDeleteData.sureDeleteText}
        </button>
      </div>
    </div>
  );
}

function ConfirmDeleteView(props: {
  alertData: AlertOtpData;
  confirmClose: () => void;
  confirmDelete: (code: string) => void;
  sendAgain: () => void;
  deleteLoading?: boolean;
}): JSX.Element {
  return (
    <AuthCodeContentView
      alertData={props.alertData}
      otpInputComplete={() => {}}
      close={props.confirmClose}
      sendAgain={props.sendAgain}
      count={props.alertData.otp.value}
      clickDelete={props.confirmDelete}
      deleteLoading={props.deleteLoading}
    />
  );
}
