"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctionV2Store = void 0;
const StoreBase_1 = require("../cutil/StoreBase");
const Lazyload_1 = require("../cutil/Lazyload");
const RelationSetDest_1 = require("../api/graphqlTyped/RelationSetDest");
const JsonUtil_1 = require("../cutil/JsonUtil");
const LangUtil_1 = require("../cutil/LangUtil");
class FunctionV2Store extends StoreBase_1.StoreBase {
    constructor(brainClient) {
        super(() => []);
        this.brainClient = brainClient;
        this.lazyload = new Lazyload_1.Lazyload(() => {
            return this.getFunctions(1, []).then(x => {
                const state = x;
                this.emit(state);
                return state;
            });
        });
    }
    async get() {
        return this.lazyload.get();
    }
    async findDest(params) {
        const functions = await this.get();
        const findById = functions.find(f => {
            return params.funcId && f.id === params.funcId;
        });
        if (findById) {
            return JsonUtil_1.JsonUtil.toModelFromType(RelationSetDest_1.RelationSetDestAttribute, findById.attributesV2);
        }
        const findByValue = functions.find(f => {
            return params.funcValue && f.attributesV2 && f.attributesV2?.value === params.funcValue;
        });
        if (findByValue) {
            return JsonUtil_1.JsonUtil.toModelFromType(RelationSetDest_1.RelationSetDestAttribute, findByValue.attributesV2);
        }
        const cleanName = params.edgeName.replace("/", "");
        const findByName = functions.find(f => {
            return ((f.attributesV2 && f.attributesV2?.label === cleanName) ||
                (f.attributesV2 && f.attributesV2?.aliases?.includes(cleanName)));
        });
        if (findByName) {
            return JsonUtil_1.JsonUtil.toModelFromType(RelationSetDest_1.RelationSetDestAttribute, findByName.attributesV2);
        }
        return undefined;
    }
    async getFunctions(page, completionResult) {
        const size = 50;
        const res = await this.requestFuncs(page, size);
        const newResult = [...completionResult, ...res];
        if (res.length < size) {
            return newResult;
        }
        return this.getFunctions(page + 1, newResult);
    }
    async requestFuncs(page, size) {
        const tryRes = await (0, LangUtil_1.tryPromise)(this.brainClient.graphql.getFunctionV2(page, size));
        if (tryRes.error) {
            console.error(tryRes.error);
        }
        return tryRes.data?.data || [];
    }
}
exports.FunctionV2Store = FunctionV2Store;
