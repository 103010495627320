import { useState } from "react";
import "../components-css/Jira.css";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

function Jira(props) {
  const [showTxt, setShowTxt] = useState(false);

  const getPriorityColor = () => {
    const priorityColorMap = {
      high: "#FE4619",
      medium: "#FFAB00",
      low: "#0065FF",
    };
    const defaulrPriorityColor = "#FFAB00";
    if (props?.content?.priority && typeof props?.content?.priority === "string") {
      return priorityColorMap[props.content.priority.toLowerCase()] || defaulrPriorityColor;
    }
    return defaulrPriorityColor;
  };

  const getOverTitleCss = () => {
    const title = props?.content?.title || "";
    return title.length > 35;
  };

  const getOverContentCss = () => {
    const len = props?.content?.body?.length || 0;
    if (len > 2) return true;
    if (len === 1) return props.content.body[0].length > 80;
    if (len === 0) return false;
    return props.content.body[0].length > 40 || props.content.body[1].length > 40;
  };

  const openTxt = e => {
    e.stopPropagation();
    if (getOverTitleCss() || getOverContentCss()) setShowTxt(!showTxt);
  };

  const joinStr = () => {
    return (props?.content?.body || []).join("\n").trim();
  };

  return (
    <div className="jira-box">
      <div className="head-box">
        <p className={getOverTitleCss() && !showTxt ? "over-title" : ""}>{props?.content?.title || "N/A"}</p>
        <span className={`iconfont icon-right ${showTxt ? "icon-rotate" : ""}`} onClick={e => openTxt(e)}></span>
      </div>

      <div className={`jira-content ${getOverContentCss() && !showTxt ? "over-content" : ""}`}>{joinStr()}</div>
      <div className="jira-footer">
        <div className="f-left">
          <div className="circle-item" style={{ background: getPriorityColor() }}></div>
          <img src={`${settings.S3CDN}${settings.viewAssetsPath}default-head.png`}></img>
          <span>{props?.content?.assignee || "N/A"} </span>
        </div>
        <p>To Do</p>
      </div>
    </div>
  );
}

export default Jira;
