import { PublishSubscriptionStoreState } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import styles from "./AccountStatus.module.scss";

export function PerMonth(props: { state: PublishSubscriptionStoreState }): React.JSX.Element {
  const computedFreeTxt = (free: number) => {
    return `${free > 1 ? "days" : "day"} free`;
  };

  return (
    <div className={styles.per}>
      {props.state.free ? (
        <>
          <div>
            <span className={styles.days}>{props.state.free}</span>
            <span className={styles.daysUnit}>{computedFreeTxt(props.state.free)}</span>
          </div>
          <div className={styles.unit}>then ${props.state.price} per month</div>
        </>
      ) : (
        <>
          <div className={styles.money}>${props.state.price}</div>
          <div className={styles.unit}>per month</div>
        </>
      )}
    </div>
  );
}
