import { CreatorCanvasFocus } from "@uikit/cases/canvasFocus/CreatorCanvasFocus";
import { previewStore } from "@uiview/store/PreviewStore";
import { useStartNode } from "@custom-hooks/useNodePreview";
import { useSignal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { fotActions } from "@store/fot";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { dislikePrint } from "@uiview/views/Nodes/BluePrintNode/BluePrintUtil";
import { Feedback } from "@views/components/Feedback/Feedback";
import { useCreation } from "ahooks";
import { Me } from "imagica-corekit/dist/base/api/userTyped/Me";
import { TryResult, tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { GetItContext } from "imagica-uikit/dist/base/provider/GetItContext";
import { useEnableScroll } from "imagica-uikit/dist/hooks/useEnableScroll";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { find, get } from "lodash";
import React, { MemoExoticComponent, forwardRef, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatBoxV2 } from "./ChatBox";
import { NodeConfigUtil } from "./NodeConfigUtil";
import styles from "./Nodes.module.scss";
import { NodesBloc } from "./NodesBloc";
import NodeWrapper from "./components/NodeWrapper";
import { NO_WHEEL } from "./constants";
import { useRemoveConnect } from "./hooks/useRemoveConnect";
import { initialReportSizeMapping, nodeConfig } from "./nodeConfig";
import { NodesProps } from "./typing";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorCotService } from "@uikit/service/CreatorCotService";
import { CotStore } from "@uikit/store/CotStore";
import { MoveToOptions } from "@uikit/cases/canvasFocus/FocusController";

type NodesRef = Record<string, any>;

/**
 * 此处保留 ref，因为nodes在其他地方有传入ref，react传入ref需要被 forwardRef包裹，否则会报错
 */
// eslint-disable-next-line
const NodesRoot = forwardRef<NodesRef, NodesProps>(props => {
  const dispatch = useDispatch();

  const bloc = useCreation(() => new NodesBloc(), []);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const projectNodeStore = getIt(ProjectNodeStore);
  const creatorCotService = getIt(CreatorCotService);
  const homeStore = getIt(HomeStore);
  const cotStore = getIt(CotStore);
  const homeStoreState = useStore(homeStore).value;

  const featureTags: FeatureTags = homeStoreState.featureTags;

  const nodeDisplayType = useSignal(props.displayType);
  const disableAddPreview = useSelector((state: any) => state.fot.disableAddPreview);
  const reloadMap = useSelector((state: any) => state.editor.reloadMap);
  const checkNodeArr: string[] = useSelector(state => get(state, "fot.checkNodeArr", []));

  const editorIdArr = useSelector(state => get(state, "fot.editorIdArr", []));
  const mode = useSelector(state => get(state, "fot.mode", ""));
  const userMe = useSelector(state => get(state, "editor.me", new Me()));

  const homePluginStore = getIt(HomePluginStore);
  const isShare = homePluginStore.state.isShare;

  const creatorCanvasFocus = getIt(CreatorCanvasFocus);

  const focusNodeTopBarById = useCallback(
    (id: string, options?: MoveToOptions) => {
      return creatorCanvasFocus.focusNodeTopBarById(id, {
        showPreviewPanel: previewStore.state.showPreviewPanel,
        ...options,
      });
    },
    [creatorCanvasFocus]
  );
  const {
    displayType,
    isInCanvas = true,
    resizeEventParams,
    isOpenDeleteBox,
    id,
    showFeedback,
    isTargetNode,
    inputTitle,
    reason,
    allowFitSize,
  } = props;

  useRemoveConnect({ id, displayType });

  useEffect(() => {
    if (props.displayType === "uploadFile") {
      return;
    }
    // 如果类型转换清空默认值
    if (props.displayType !== nodeDisplayType.value) {
      nodeDisplayType.value = props.displayType;
      bloc.cleanData();
    }
    if (!isBlank(props?.initCreateBlobUrl)) {
      bloc.setCreateBlobUrl(props.initCreateBlobUrl, props.textAreaValue?.unStructuredDownloadFailedText);
      return;
    }
    if (!isBlank(props.textAreaValue?.needToCreateBlobUrl)) {
      bloc.downloadMetaData(
        props.textAreaValue.needToCreateBlobUrl,
        props.textAreaValue?.unStructuredDownloadFailedText,
        props.textAreaValue?.s3Url
      );
    }
  }, [
    bloc,
    props.textAreaValue?.needToCreateBlobUrl,
    props.textAreaValue?.unStructuredDownloadFailedText,
    props.initCreateBlobUrl,
    props.displayType,
  ]);

  const { enableScroll, mouseEnterLeave } = useEnableScroll();
  const { isStartNode, isStartNodeAdded } = useStartNode(props.id);

  const { component, initialReportSize = { height: 0, width: 0 } } = useMemo(() => {
    // eslint-disable-next-line
    const config = find(nodeConfig, item => item.displayType === displayType) || {
      component: (): React.ReactNode => <div />,
      disableFeatureTag: "",
      displayType: "",
    };

    if (config.displayType === "chatBox" && featureTags.enableStudiosCustomJsFunctionChatBox) {
      config.component = ChatBoxV2;
    }

    return { ...config, initialReportSize: initialReportSizeMapping[displayType] };
  }, [displayType]);

  const noWheelClassName = checkNodeArr?.includes(props.id) && enableScroll ? NO_WHEEL : "";

  // QUICK FIX REVISIT
  // const isEdgeRunning =
  //   creatorEdgesStore.getEdges && NodeConfigUtil.getTextNodeIsRunning(creatorNodesStore.getNodes(), creatorEdgesStore.getEdges(), id);
  // const showViewBuilderButton = useMemo(
  //   () =>
  //     displayType === "text" &&
  //     featureTags.enableViewBuilder &&
  //     isTargetNode &&
  //     (props.textAreaValue[0].results[0].content || "").length > 0,
  //   [featureTags.enableViewBuilder, isTargetNode, props.textAreaValue, displayType]
  // );
  const [likeActive, setLikeActive] = useState(props.feedback?.user_input === 1);
  const [dislikeActive, setDislikeActive] = useState(props.feedback?.user_input === -1);

  const handleLike = (): void => {
    if (!likeActive) {
      //like
      if (dislikeActive) {
        props.updateFeedbackDislikeCancel();
        setDislikeActive(false);
      }
      props.updateFeedbackLike();
    } else {
      //cancel like
      props.updateFeedbackLikeCancel();
    }
    setLikeActive(!likeActive);
  };
  const handleDislike = (): void => {
    if (!dislikeActive) {
      //dislike
      if (likeActive) {
        props.updateFeedbackLikeCancel();
        setLikeActive(false);
      }
      props.updateFeedbackDislike();
    } else {
      //cancel dislike
      props.updateFeedbackDislikeCancel();
    }
    setDislikeActive(!dislikeActive);
  };

  /**
   * cot等待用户上传文件后，继续执行
   */
  const handleCot = (): void => {
    const currentInputId = cotStore.state.oldCotAnimationState.currentInputId;
    //如果已经运行结束或者当前inputId为空，不执行后续逻辑
    if (
      !cotStore.state.oldCotAnimationState.creating ||
      isBlank(cotStore.state.oldCotAnimationState.inputIds) ||
      isBlank(currentInputId) ||
      currentInputId !== props.id
    )
      return;
    const nodes = creatorNodesStore.getNodes();
    const index = cotStore.state.oldCotAnimationState.inputIds.findIndex(x => x === currentInputId);
    if (index < 0) return;
    creatorCotService.showAllInputs(cotStore.state.oldCotAnimationState.inputIds, nodes, index + 1);
  };

  const handleBuyStock = (value: any, userInputPrice: number): void => {
    const data = {
      editorId: props.id,
      content: value,
      userInputPrice: userInputPrice,
    };
    const arr = [data, ...editorIdArr];
    dispatch(fotActions.setEditorIdArr(arr));
    dispatch(fotActions.setSaasEditorId(`${props.locus || "canvas"}${props.id}`));
  };

  const handleBluePrintDislike = useCallback(
    async (feedback: string): Promise<TryResult<void>> => {
      const nodes = creatorNodesStore.getNodes();
      const edges = creatorEdgesStore.getEdges();
      const line = edges.find(x => x.target == props.id);
      const edgeNode = nodes.find(x => x.id === line?.source);
      const blueJson = JSON.parse(get(edgeNode, "data.lineParam.blueJson", JSON.stringify({})));
      const id = blueJson.id ?? blueJson.name ?? "recipe_maker";
      const output = get(props, "textAreaValue");
      const inputs = NodeConfigUtil.getInputsByTargetId(nodes, edges, props.id, projectNodeStore);
      const res = await tryPromise(dislikePrint({ id, feedback, inputs, output }));
      isBlank(res.error) ? func.messageUtil("Dislike successfully", "success") : func.messageUtil("Dislike Failed");
      return res;
    },
    [props.id, props.textAreaValue]
  );

  /**
   * 当前更新的textAreaValue值
   */
  function onChange(textAreaValue: any): void {
    const id = isInCanvas ? props.id : props.nodeId;
    creatorNodesStore.setNodes((prevList: any) => {
      return prevList.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            data: {
              ...item.data,
              textAreaValue,
            },
          };
        }
        return item;
      });
    });
  }

  /**
   *  当需要修改除了textAreaValue 以外的数据时用这个方法
   */
  function onChangeData(data: Record<string, any>): void {
    const id = isInCanvas ? props.id : props.nodeId;
    creatorNodesStore.setNodes((prevList: any) => {
      return prevList.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            data: {
              ...item.data,
              ...data,
            },
          };
        }
        return item;
      });
    });
  }

  const enableFeedback =
    featureTags.showUserFeedback && isTargetNode && showFeedback && !(bloc.state.loading.value ?? false);

  const canvasStyle = props.isInCanvas
    ? NodeConfigUtil.getNodeStyle(
        props.displayType,
        enableFeedback,
        allowFitSize,
        props.isInBlueprint,
        props.fromList,
        resizeEventParams,
        props.parentNodeId
      )
    : {};

  //  打开add to preview 按钮 修改chat interface底部padding值，用于显示按钮
  const chatInterfaceStyle = props.displayType === "chatInterface" && !disableAddPreview ? { paddingBottom: 80 } : {};
  const isBluePrintNode = props.displayType === "blueprint";

  const buyStockPermission = useMemo(() => {
    if (mode !== "editorMode") {
      return true;
    }
    const components = (userMe?.profile?.permissions || []).find(x => x.key === "components")?.value;
    if (!components) return false;
    if (components.find(x => x === "brain_studios_stock_purchase")) {
      return true;
    }
  }, [mode, userMe?.profile?.permissions]);

  return (
    <GetItContext.Provider value={getIt}>
      <NodeWrapper
        id={id}
        isInCanvas={isInCanvas}
        loading={bloc.state.loading}
        nodeLoading={props.loading}
        displayType={props.displayType}
        isOpenDeleteBox={isOpenDeleteBox}
        mouseEnterLeave={mouseEnterLeave}
        style={{ ...canvasStyle, ...chatInterfaceStyle }}
        reason={reason}
        inputTitle={inputTitle}
        isInBlueprint={props.isInBlueprint}
        enableFeedback={enableFeedback}
        typeInputRef={props.typeInputRef}
      >
        {React.createElement(component, {
          ...props,
          noWheelClassName,
          initialReportSize,
          enableFeedback,
          createBlobUrl: {
            objectURL: bloc.state.objectURL.value,
            fileBlob: bloc.state.fileBlob.value,
            loading: bloc.state.loading.value,
            streaming: bloc.state.streaming.value,
          },
          isInCanvas,
          disableAddPreview,
          isStartNode,
          isStartNodeAdded,
          isRootNode: displayType === "blueprint",
          focusNodeTopBarById,
          featureTags,
          isShare,
          reloadMap,
          onChange,
          onChangeData,
          handleCot,
          handleBuyStock,
          buyStockPermission,
          setNodes: creatorNodesStore.setNodes,
          getNodes: creatorNodesStore.getNodes,
          setEdges: creatorEdgesStore.setEdges,
          getEdges: creatorEdgesStore.getEdges,
          setCheckNodeArr: (val: any) => dispatch(fotActions.setCheckNodeArr(val)),
        })}

        {/* {showViewBuilderButton && (
          <ViewBuilder
            {...props}
            isInCanvas={true}
            initialReportSize={initialReportSize}
            changeShowBuilderUI={changeShowBuilderUI}
          />
        )} */}

        {/* user feedback */}
        {enableFeedback ? (
          <div className={styles.feedBack}>
            <Feedback
              class={"singleNode"}
              id={props.id}
              likeActive={likeActive}
              dislikeActive={dislikeActive}
              handleLike={handleLike}
              handleDislike={handleDislike}
              isBluePrintParentNode={isBluePrintNode}
              handleBluePrintDislike={handleBluePrintDislike}
            />{" "}
          </div>
        ) : null}
      </NodeWrapper>
    </GetItContext.Provider>
  );
});

export const Nodes: MemoExoticComponent<React.ForwardRefExoticComponent<NodesProps & React.RefAttributes<NodesRef>>> =
  memo(NodesRoot);
