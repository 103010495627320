import { ResizeParams } from "reactflow";
import { NodeLayoutStyle, defaultLimitSize, defaultSize, nodeLayoutStyleLists } from "./NodeLayoutSource";
import { DisplayType } from "./typing";
import { ProjectNodeUtil } from "@uikit/projectNode/ProjectNodeUtil";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";

export class NodeConfigUtil {
  /// 生成对应Node Style
  // eslint-disable-next-line
  static getNodeStyle(
    type: DisplayType,
    enableFeedback: boolean,
    enableAutoHeight: boolean,
    isInBlueprint = false,
    fromList = false,
    resizeParams?: ResizeParams,
    parentNodeId?: string
  ) {
    const layout = this.getNodeLayoutByType(type);
    const style = layout.toStyle();
    /// 手动改变大小的node, 禁止自适应大小
    let tempAllowFitSize = enableAutoHeight;
    if (tempAllowFitSize === undefined) {
      if (resizeParams && (resizeParams.width || resizeParams.height)) {
        tempAllowFitSize = false;
      } else {
        tempAllowFitSize = true;
      }
    }
    // 如果是在bluePrint节点内或者是list切分的组元素 则需要自适应
    // if里用来判定需要自适应的 node和条件, else 基于拖拽后的高宽.
    if ((isInBlueprint && type === "blueprint") || fromList) {
      style.height = undefined;
    } else if (layout.autoHeight && tempAllowFitSize) {
      style.height = undefined;
    } else if (layout.autoHeight && parentNodeId) {
      style.height = undefined;
    } else if (type === "video") {
      style.height = resizeParams?.height;
    } else {
      if (resizeParams && resizeParams.width) {
        style.width = resizeParams.width;
      }
      if (resizeParams && resizeParams.height) {
        style.height = resizeParams.height;
      }
    }
    return style;
  }
  /// 获取初始化大小
  static getInitSize(type: DisplayType): {
    width: number;
    height: number;
  } {
    const layout = this.getNodeLayoutByType(type);
    return { width: layout.width, height: layout.height };
  }
  /// 获取DisplayType对应Layout配置
  static getNodeLayoutByType(type: DisplayType = "text"): NodeLayoutStyle {
    const layout = nodeLayoutStyleLists.find(item => item.type === type);
    if (layout) {
      return layout;
    } else {
      const error = "type为空 ,NodeLayoutSource中未定义" + type + "默认尺寸";
      console.error(error);
      return new NodeLayoutStyle({ type: type, ...defaultSize, ...defaultLimitSize });
    }
  }
  /// 根据targetNodeId寻找inputs
  static getInputsByTargetId(
    nodes: any[],
    edges: any[],
    targetNodeId: string,
    projectNodeStore: ProjectNodeStore
  ): any[] {
    const edgeNodeId = edges.find(x => x.target === targetNodeId)?.source;
    const edgeNode = nodes.find(x => x.id === edgeNodeId);
    const flows = edgeNode?.data.flows.map((x: any) => x.sourceNodeId);
    return nodes
      .filter(x => flows.includes(x.id))
      .map(x => {
        const projetNode = projectNodeStore.getNode(x.id);

        return {
          // stud-2493 get input title
          // name: x.data?.inputTitle || x.data?.generatedLabel || "input",
          name: ProjectNodeUtil.getTitle(projetNode, "input"),
          type: x.data.displayType,
          description: x.data.placeholder,
          value: x.data.textAreaValue,
        };
      });
  }
  /// 根据node获取当前是否正在run
  static getTextNodeIsRunning(nodes: any[], edges: any[], nodeId: string): boolean {
    const edgeNodeId = edges.find(x => x.target === nodeId)?.source;

    if (!edgeNodeId) {
      return false;
    }

    const node = nodes.find(x => x.id === edgeNodeId);
    return node?.data?.lineParam?.loading || false;
  }
}
