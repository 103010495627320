import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";

const homePluginStore = getIt(HomePluginStore);
export let socket = null;

const BRAIN_API = settings.API;
const GUEST_TOKEN_KEY = "GUEST_TOKEN";
const USER_EMAIL = "BRAIN_USER_EMAIL";
const authStore = getIt(FotAuthStore);

export function isGuestUser() {
  return sessionStorage.getItem(GUEST_TOKEN_KEY) && !authStore.state.brainToken;
}

export function isAuth() {
  const isSharedApp = homePluginStore.state.isShare;
  const authToken = authStore.state.brainToken;
  return !func.isEmpty(authToken) || (isSharedApp && isGuestUser());
}

export function getUserEmail() {
  if (localStorage.getItem(USER_EMAIL) !== "null" && localStorage.getItem(USER_EMAIL) !== "undefined") {
    return localStorage.getItem(USER_EMAIL);
  }
  return "metric-user";
}

export function getDefaultRole() {
  if (settings.SignupRoleId) {
    return [settings.SignupRoleId];
  }
  return null;
}

export function auth(userObj) {
  let authURL = BRAIN_API + "/api/auth/token.json";
  let params = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userObj),
  };
  return fetch(authURL, params)
    .then(resp => resp.json())
    .then(data => {
      console.log("resp: " + JSON.stringify(data));
      if (data.non_field_errors) {
        return data;
      }
      localStorage.setItem(USER_EMAIL, userObj.email);
      authStore.setBrainToken(data.token);
      // if (callback) {
      //   callback(true);
      // }
      return data;
    })
    .catch(err => {
      console.log("err: " + err);
      // callback(false);
      return err;
    });
  // return response;
  // const json = await response.json();

  // localStorage.setItem(TOKEN_KEY, json.token);
}

export function verification(userObj, callback) {
  const authURL = BRAIN_API + "/api/auth/otp/token.json";
  const params = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify(userObj),
  };

  return fetch(authURL, params)
    .then(resp => resp.json())
    .then(data => {
      // 模拟正确请求
      authStore.setBrainToken(data.token);
      if (callback) {
        callback(true);
      }
      return Promise.resolve(data);
    })
    .catch(err => {
      callback(false);
      return Promise.reject(err);
    });
}

export function logout() {
  authStore.removeBrainToken();
  localStorage.removeItem(USER_EMAIL);
}

export function isEmpty(value) {
  return (
    value === null ||
    value === "" ||
    (typeof value === "string" && value.trim() === "") ||
    (value instanceof Array && value.length === 0)
  );
}
