"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterviewBotAdapter = void 0;
const AdapterBase_1 = require("./AdapterBase");
class InterviewBotAdapter extends AdapterBase_1.AdapterBase {
    runResponseType() {
        return AdapterBase_1.RunResponseType.INTERVIEW_BOT;
    }
    dynamicRunResponseTypeFunctionV2() {
        return AdapterBase_1.RunResponseType.FUNCTION_V2;
    }
}
exports.InterviewBotAdapter = InterviewBotAdapter;
