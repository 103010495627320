"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterBlueprint = void 0;
const BrainClient_1 = require("../base/api/BrainClient");
const FotClient_1 = require("../base/api/FotClient");
const ViewBuilderService_1 = require("../base/service/ViewBuilderService");
const BlueprintService_1 = require("../cases/blueprint/BlueprintService");
const GenerateService_1 = require("../cases/blueprint/GenerateService");
const ViewBuilderImgService_1 = require("../cases/service/ViewBuilderImgService");
const ViewbuilderImgStore_1 = require("../cases/store/ViewbuilderImgStore");
class RegisterBlueprint {
    static configure(container) {
        const it = (serviceIdentifier) => {
            return container.get(serviceIdentifier);
        };
        container.bind(GenerateService_1.GenerateService).toConstantValue(new GenerateService_1.GenerateService());
        container.bind(BlueprintService_1.BlueprintService).toConstantValue(new BlueprintService_1.BlueprintService(it(GenerateService_1.GenerateService)));
        container.bind(ViewBuilderService_1.ViewBuilderService).toConstantValue(new ViewBuilderService_1.ViewBuilderService(it(BrainClient_1.BrainClient), it(FotClient_1.FotClient)));
        container
            .bind(ViewBuilderImgService_1.ViewBuilderImgService)
            .toConstantValue(new ViewBuilderImgService_1.ViewBuilderImgService(it(ViewBuilderService_1.ViewBuilderService), it(ViewbuilderImgStore_1.ViewbuilderImgStore)));
    }
}
exports.RegisterBlueprint = RegisterBlueprint;
