import { Signal } from "@preact/signals-react";

export class EditData {
  constructor(
    public value: Signal<string>,
    public placeHolder: Signal<string>,
    public errorText: Signal<string>,
    public title: Signal<string>,
    public loading: Signal<boolean>
  ) {}
  public initValue = new Signal("");

  /**
   * 和初始值比较是否已经发生了变化
   * @param needTrim
   */
  textChanged(needTrim: boolean = true): boolean {
    if (needTrim) {
      return this.value.value.trim() !== this.initValue.value.trim();
    }
    return this.value.value !== this.initValue.value;
  }
}
export class HeaderData {
  constructor(
    public icon: Signal<string>,
    public typeDescription: Signal<string>,
    public state: Signal<[state: UploadState, info: string]>,
    public deleteLoading: Signal<boolean>
  ) {}
}

export class BottomViewData {
  constructor(public deleteLoading: boolean, public updateLoading: boolean, public isEnable: boolean) {}
}

export class AlertData {
  constructor(public title: string, public detail: string) {}
}

export class AlertOtpData {
  constructor(
    public title: string,
    public sendOtpDesciption: string,
    public errorText: string,
    public isEmail: boolean,
    public otpLoading?: boolean,
    public sendAgainLoading?: boolean,
    /// delete account
    public isDelecount?: boolean,
    public deleteAccountLoading?: boolean
  ) {}
  otp = new Signal(-1);
  timer: NodeJS.Timeout | null = null;
  beginOtp(otp: number) {
    let maxCount = otp;

    const countDown = () => {
      if (maxCount >= 0) {
        maxCount--;
        this.otp.value = maxCount;
      } else {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.otp.value = -1;
      }
    };
    this.timer = setInterval(countDown, 1000);
  }
  stopOtp() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.otp.value = -1;
  }
}
export enum UploadState {
  normal = "normal",
  loading = "loading",
  progress = "progress",
  finish = "finish",
  error = "error",
}
export class DeleteAccountData {
  constructor(
    public askDeleteData: DeleteAccountAskData,
    public confirmDeleteData: AlertOtpData,
    public dataState: Signal<DeleteAlertState>
  ) {}
}

export class DeleteAccountAskData {
  constructor(
    public title: string,
    public subTitle: string,
    public reduceNotifiText: string,
    public disableText: string,
    public nevermindText: string,
    public sureDeleteText: string
  ) {}

  updateSubtitle(subtitle: string): void {
    this.subTitle = subtitle;
  }
}

export enum DeleteAlertState {
  askDelete = "askDelete",
  confirmDelete = "confirmDelete",
}
