import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { AddPlusSvg } from "./svg";
import styles from "./index.module.scss";
import { useIsStaticApp } from "@custom-hooks/useNodePreview";
import { AddCancelSvg } from "./svg";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { previewStore } from "@uiview/store/PreviewStore";
import { matchMobile } from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { CotStore } from "@uikit/store/CotStore";

interface IProps {
  id: any;
  nextId?: any;
  type: "input" | "output";
  isAlwaysShow?: boolean;
  className?: string;
}

const BUTTON_TEXT_DEFAULT = "Click here to add";
const BUTTON_TEXT_FIXED = (): JSX.Element => {
  return (
    <span>
      {/* Click the <TopSvg /> icon above the Nodes */}
      Click the{" "}
      <span className={styles.sendToAppIcon}>
        <img src={`${settings.S3CDN}${settings.viewAssetsPath}send_to_app.svg`} />
      </span>{" "}
      icon above the Nodes
    </span>
  );
};
enum typeText {
  "input" = "input",
  "output" = "content",
}

const AddToContent: React.FC<IProps> = props => {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useImagicaStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;
  const cotStoreState = useImagicaStore(getIt(CotStore)).value;

  const { id, nextId, isAlwaysShow, type, className } = props;
  const [isShow, setIsShow] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const { isStaticApp } = useIsStaticApp();

  const previewState = useImagicaStore(previewStore).value;

  useEffect(() => {
    setIsDone(previewState.addContentId === id);
  }, [previewState.addContentId, id]);

  let isFixed = cotStoreState.showMultiRoundChatOnboarding === false && (isShow || isDone || isAlwaysShow);
  // 输入模式，并且当前是静态模式
  const inputAndStatic = (isStaticApp && type === typeText.input) as undefined | boolean;
  if (inputAndStatic === true) {
    isFixed = false;
  }
  const btnCls = classNames(styles.addContentBtn, {
    [styles.showBtn]: isFixed,
  });

  const handleMouse = (bol: boolean): void => {
    // if(isClickAddToContent) return
    setIsShow(bol);
  };

  //  添加input or output node类型
  const triggerAddType = (): void => {
    if (type === "input") {
      previewStore.setIsAddInputNode(true);
      previewStore.setIsAddOutputNode(false);
    } else if (type === "output") {
      previewStore.setIsAddInputNode(false);
      previewStore.setIsAddOutputNode(true);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    // if (isDone || isClickAddToContent) return
    previewStore.setAddContentId(id);
    previewStore.setNextAddContentId(nextId);
    setIsShow(true);
    previewStore.setIsClickAddToContent(true);
    triggerAddType();
  };

  const handleClickCancel = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    setIsShow(false);
    previewStore.setAddContentId("");
    previewStore.setNextAddContentId("");
    previewStore.setIsAddInputNode(false);
    previewStore.setIsAddOutputNode(false);
    previewStore.setIsClickAddToContent(false);
  };

  /* const handleClickDone = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    previewStore.setAddContentId("");
    previewStore.setNextAddContentId("");
    setIsShow(false);
    previewStore.setIsClickAddToContent(false);
  }; */

  if (isMobile && featureTags.disableMobilePage) {
    return <></>;
  }

  return (
    <div
      onClick={handleClick}
      className={classNames(styles.addToContent, className)}
      onMouseEnter={handleMouse.bind(null, true)}
      onMouseLeave={handleMouse.bind(null, false)}
    >
      <div className={btnCls} data-id={id} addcontent-id={id}>
        <AddPlusSvg />
        <div className={styles.buttonText}>
          {isDone ? BUTTON_TEXT_FIXED() : `${BUTTON_TEXT_DEFAULT} ${typeText[type]}`}
        </div>
        <div className={styles.cancelBtn} onClick={handleClickCancel}>
          {isDone ? <AddCancelSvg /> : null}
        </div>
        {/* {
                isDone && <div onClick={handleClickDone} className={styles.done}>
                    <span>Done</span>
                    <DownSvg/>
                </div>
            } */}
      </div>
    </div>
  );
};

export default AddToContent;
