"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardDataServiceUtil = void 0;
const CardDataBaseService_1 = require("../cardDataSearch/dataSerivce/CardDataBaseService");
const lodash_1 = require("lodash");
class CardDataServiceUtil {
    /**
     * 获取调用的参数
     * @param component
     */
    static getParams(componentId, textAreaValue) {
        const baseParams = new CardDataBaseService_1.CardDataBaseParams();
        baseParams.keyWords = textAreaValue;
        return baseParams;
    }
    /**
     * 是否已经结束
     * @param components
     */
    static isCompleted(components) {
        return !components.some(item => item.loading);
    }
    /**
     * 找到所有的component，生成子类，再替换到builderGraph里
     * @param builderGraph
     */
    static findAllComponents(builderGraph) {
        let newComponents = [];
        builderGraph.design.forEach(design => {
            design.section_list.forEach(section => {
                // const components =
                //   section.component_list.map(item => {
                //     const dataString = JsonUtil.stringify(item) ?? "";
                //     return JsonUtil.toModelFromType(ViewBuilderGraphDesignSectionComponent, dataString);
                //   }) ?? [];
                newComponents = [...newComponents, ...section.component_list];
                // newComponents = section.component_list
                // section.component_list = newComponents;
            });
        });
        return newComponents;
    }
    /**
     * 找到某个component 组件
     * @param bluePrintId
     * @param vbGraphData
     * @param params
     */
    static findComponent(bluePrintId, vbGraphData) {
        const components = CardDataServiceUtil.findAllComponents(vbGraphData);
        const updateComponent = components.filter(item => item.blueprint_id === bluePrintId).first();
        return updateComponent;
    }
    /**
     * 更新blueprint info信息
     * @param bluePrintId
     * @param vbGraphData
     * @param inputs
     */
    static updateBluePrintInfo(bluePrintId, vbGraphData, inputs) {
        const blueprint = (0, lodash_1.find)(vbGraphData.blueprints, blueprint => blueprint.id === bluePrintId);
        if (blueprint === undefined) {
            return blueprint;
        }
        // 更新bluePrintInfo信息
        blueprint.inputs = inputs;
        return blueprint;
    }
}
exports.CardDataServiceUtil = CardDataServiceUtil;
