import { useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { Popover, InputNumber } from "antd";
import style from "./css/HomeToolBar.module.css";
import VariablePopoverContent from "./VariablePopoverContent";
import AutoResizablePanel from "@uiview/views/AutoResizablePanel/AutoResizablePanel";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { useFitCanvas } from "@uikit/hooks/useFitCanvas";
import { getIt } from "@uikit/getIt";
import { CotStore } from "@uikit/store/CotStore";
import { FotReactFlow } from "@uikit/model/FotReactFlow";

const incrementNum = 0.25;

export default function HomeToolBar(props) {
  const { CotComponents } = props;
  const store = useStore();
  const fotReactFlow = getIt(FotReactFlow);
  const homeToolBarState = useImagicaStore(homeToolBarStore).value;
  const myModificationDataState = useImagicaStore(myModificationDataStroe).value;
  const cotStore = getIt(CotStore);
  const cotStoreState = useImagicaStore(cotStore).value;

  const { fitCanvas } = useFitCanvas();

  const [openVariable, setOpenVariable] = useState(false);
  const [openVariableToolTip, setOpenVariableToolTip] = useState(false);

  const isZooming = useRef(false);

  const developerMode = useSelector(state => state.fot.developerMode);

  const clickZoomIcon = type => {
    if (isZooming.current) return;
    const currentZoomLevel = fotReactFlow.getZoom();
    const typeObj = {
      zoomIn: currentZoomLevel + incrementNum,
      zoomOut: currentZoomLevel - incrementNum,
    };
    const restZoom = typeObj[type];

    fotReactFlow.zoomTo(restZoom, {
      duration: 500,
    });
    isZooming.current = true;
    setTimeout(() => {
      isZooming.current = false;
    }, 500);
  };

  const onPressEnter = val => {
    if (!val) return;
    const num = val / 100;
    fotReactFlow.zoomTo(isNaN(num) ? 1 : num, {
      duration: 500,
    });
  };

  const variableContent = () => {
    const numVariables = store.getState().fot.variableList.length;
    if (numVariables > 0) {
      return <VariablePopoverContent showDelet={true} />;
    } else {
      return <div className={style["tool-bar-pop-over"]}>No variable</div>;
    }
  };

  const disableAddNode = cotStoreState.showCot || cotStoreState.oldCotAnimationState.creating === true;

  const getPopupContainer = e => e;

  return (
    <div id="homeToolBar" data-creator="Toolbar" className={style["tool-bar-box"]} data-testid="hometoolbar">
      {cotStoreState.showCot ? CotComponents : null}
      <AutoResizablePanel data-testid="HomeToolBar-Tool" position="bottom-left" className={style["bottom-tool-bar"]}>
        {cotStoreState.showCot === false ? CotComponents : null}
        {/* <span
          className={`iconfont icon-choose ${props.figmaMode ? style['highlight-mouse'] : ''}`}
          onClick={() => homeToolBarStore.setFigmaMode(bool => !bool)}
        ></span> */}

        <Popover
          getPopupContainer={getPopupContainer}
          content={<p className={style["tool-bar-pop-over"]}>Select tool</p>}
        >
          <div
            className={`${style["icon-default"]} ${homeToolBarState.figmaMode ? style["selected"] : ""}`}
            onClick={() => homeToolBarStore.setFigmaMode(true)}
          >
            <div className={`${style["shift-icon"]} ${style["icon-HW"]}`}></div>
          </div>
        </Popover>
        <div className={style["spacing"]}></div>
        <Popover
          getPopupContainer={getPopupContainer}
          content={<p className={style["tool-bar-pop-over"]}>Hand tool</p>}
        >
          <div
            className={`${style["icon-default"]} ${!homeToolBarState.figmaMode ? style["selected"] : ""}`}
            onClick={() => homeToolBarStore.setFigmaMode(false)}
          >
            <div className={`${style["hand-icon"]} ${style["icon-HW"]}`}></div>
          </div>
        </Popover>
        <div className={style["spacing"]}></div>
        <Popover getPopupContainer={getPopupContainer} content={<p className={style["tool-bar-pop-over"]}>Add node</p>}>
          <div
            className={`${style["icon-default"]} ${disableAddNode ? style["not-have"] : ""}`}
            onClick={e => props.addNewNode(e)}
          >
            <div className={`${style["document-icon"]} ${style["icon-HW"]}`}></div>
          </div>
        </Popover>
        <>
          <div className={style["spacing"]}></div>
          <Popover
            getPopupContainer={getPopupContainer}
            content={<p className={style["tool-bar-pop-over"]}>Upload a File</p>}
          >
            <div
              className={`${style["icon-default"]} ${disableAddNode ? style["not-have"] : ""}`}
              onClick={props.addUploadNode}
            >
              <div className={`${style["upload-icon"]} ${style["icon-HW"]}`}></div>
            </div>
          </Popover>
        </>

        {/* 变量列表 */}
        {developerMode && !myModificationDataState.showMyModification ? (
          <>
            <div className={style["spacing"]}></div>
            <Popover
              getPopupContainer={getPopupContainer}
              onMouseEnter={() => {
                if (openVariable == false) {
                  setOpenVariableToolTip(true);
                }
              }}
              onMouseLeave={() => {
                setOpenVariableToolTip(false);
              }}
              open={openVariable}
              onOpenChange={() => {
                setOpenVariable(!openVariable);
                setOpenVariableToolTip(false);
              }}
              content={variableContent}
              trigger="click"
            >
              <Popover
                getPopupContainer={getPopupContainer}
                open={openVariableToolTip}
                content={
                  <>
                    <p className={style["tool-bar-pop-over"]}>List of variables</p>
                  </>
                }
              >
                {/* <span className='iconfont icon-bianliang'></span> */}
                <div className={style["icon-default"]}>
                  <div className={`${style["variable-icon"]} ${style["icon-HW"]}`}></div>
                </div>
              </Popover>
            </Popover>
          </>
        ) : null}

        <div className={style["spacing"]}></div>
        <Popover getPopupContainer={getPopupContainer} content={<p className={style["tool-bar-pop-over"]}>Undo</p>}>
          <button
            name="undoButton"
            className={`${style["icon-default"]} ${
              homeToolBarState.historyIndex === props.undoRef?.current?.length - 1 ? style["not-have"] : ""
            }`}
            onClick={props.handleUndo}
          >
            <div className={`${style["drawback-icon"]} ${style["icon-HW"]}`}></div>
          </button>
        </Popover>
        <div className={style["spacing"]}></div>
        <Popover getPopupContainer={getPopupContainer} content={<p className={style["tool-bar-pop-over"]}>Redo</p>}>
          <button
            name="redoButton"
            className={`${style["icon-default"]} ${homeToolBarState.historyIndex === -1 ? style["not-have"] : ""}`}
            onClick={props.handleRedo}
          >
            <div className={`${style["advance2x-icon"]} ${style["icon-HW"]}`}></div>
          </button>
        </Popover>
      </AutoResizablePanel>

      {/* zoom tool bar */}
      <AutoResizablePanel data-testid="HomeToolBar-Zoom" position="bottom-right" className={style["bottom-zoom-bar"]}>
        {!homeToolBarState.allowZoomOrPan ? (
          <div className={style["disbaled-overlay"]} onClick={e => e.stopPropagation()}></div>
        ) : null}
        <Popover
          getPopupContainer={getPopupContainer}
          content={<p className={style["tool-bar-pop-over"]}>Zoom to fit</p>}
        >
          <div className={style["icon-default"]} onClick={() => fitCanvas()}>
            <div
              data-testid="hometoolbar-zoom-top-fit"
              className={`${style["zoom-top-fit"]} ${style["icon-HW"]}`}
            ></div>
          </div>
        </Popover>

        <div className={style["zoom-in-out"]}>
          <Popover
            getPopupContainer={getPopupContainer}
            content={<p className={style["tool-bar-pop-over"]}>Zoom out</p>}
          >
            <div className={style["icon-default"]} onClick={() => clickZoomIcon("zoomOut")}>
              <div className={`${style["reduce-icon"]} ${style["icon-HW"]}`}></div>
            </div>
          </Popover>
          <Popover getPopupContainer={getPopupContainer} content={<p className={style["tool-bar-pop-over"]}>Zoom</p>}>
            <div className={style["percentage"]}>
              <InputNumber
                className={`${style["zoom-input"]} ${
                  homeToolBarState.currentZoomLevel < 100 ? style["zoom-input-two-numbers"] : ""
                }`}
                value={homeToolBarState.currentZoomLevel}
                bordered={false}
                min={1}
                max={200}
                onPressEnter={e => onPressEnter(e.target.value)}
              />
              %
            </div>
          </Popover>
          <Popover
            getPopupContainer={getPopupContainer}
            content={<p className={style["tool-bar-pop-over"]}>Zoom in</p>}
          >
            <div className={style["icon-default"]} onClick={() => clickZoomIcon("zoomIn")}>
              <div className={`${style["amplify-icon"]} ${style["icon-HW"]}`}></div>
            </div>
          </Popover>
        </div>
      </AutoResizablePanel>
    </div>
  );
}
