"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Calling = void 0;
class Calling {
    constructor() {
        this.call_instruction = "";
        this.current_response = "";
    }
}
exports.Calling = Calling;
