import { configureStore } from "@reduxjs/toolkit";
import homeScreenSlice from "./homeScreen";
import feedSlice from "./feed";
import fotSlice from "./fot";
import studioSlice from "./studio";
import editorSlice from "./editor";

const store = configureStore({
  reducer: {
    fot: fotSlice,
    homeScreen: homeScreenSlice,
    feed: feedSlice,
    studio: studioSlice,
    editor: editorSlice,
  },
  /// 序列化检查报错 暂时先关闭
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
