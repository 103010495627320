"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapAdapter = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const MapModel_1 = require("../../storyV2/model/MapModel");
class MapAdapter extends AdapterBase_1.AdapterBase {
    dynamicRunResponseTypeFunctionV2() {
        return AdapterBase_1.RunResponseType.FUNCTION_V2;
    }
    runResponseType() {
        return AdapterBase_1.RunResponseType.MAP;
    }
    async runNode(node, actionData, story, edge) {
        const textGenFunc = this.funcRegistry.createTextGenFunction("/be/bas-demo-v4/nlp/completions_generation", "file", "json_list_converter.model", undefined, "brain_studios");
        /// 生成文本
        const textGenRes = await textGenFunc.run(node.content, { projectId: story.projectId, edgeId: edge.options.id });
        const funcData = textGenRes.data;
        const streamResponse = new FunctionBase_1.RunStreamResponse();
        funcData.onReceived(result => {
            const value = result.value;
            if (value instanceof FunctionBase_1.FuncDataSource) {
                const mapModel = new MapModel_1.MapModel(value.value, new Date().getTime());
                const funcDataSource = new FunctionBase_1.FuncDataSource(FunctionBase_1.FuncDataSourceType.MAP, mapModel, value.isEnded);
                streamResponse.receive(funcDataSource, result.completion, result.error);
                return;
            }
            streamResponse.receive(result.value, result.completion, result.error);
        });
        return streamResponse;
    }
}
exports.MapAdapter = MapAdapter;
