"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Attributes = exports.DomainStudio = void 0;
const class_transformer_1 = require("class-transformer");
class DomainStudio {
    constructor() {
        this.guest_user_key = "";
        this.imagica_byod = "";
        this.attributes = {
            selectedUI: "",
            mode: "",
            project_id: "",
            appId: "",
            uuid: "",
        };
    }
}
exports.DomainStudio = DomainStudio;
__decorate([
    (0, class_transformer_1.Type)(() => Attributes)
], DomainStudio.prototype, "attributes", void 0);
class Attributes {
    constructor() {
        this.appId = "";
        this.mode = "";
        this.project_id = "";
        this.selectedUI = "";
        this.uuid = "";
    }
}
exports.Attributes = Attributes;
