import { useDispatch, useSelector, useStore } from "react-redux";
import { useRef, useState } from "react";
import style from "../components-css/ThinkingInput.module.css";
import { fotActions } from "../../store/fot";
import { feedActions } from "../../store/feed";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

function ThinkingInput(props) {
  const store = useStore();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");

  const homeCards = useSelector(state => state.homeScreen.homeCards);
  const view = useSelector(state => state.fot.view);
  const setView = val => {
    dispatch(fotActions.setView(val));
  };

  const setChainThoughtsState = val => {
    dispatch(fotActions.setChainThoughtsState(val));
  };
  const setFocusedThought = val => {
    dispatch(feedActions.setFocusedThought(val));
  };
  const setThoughtChain = val => {
    dispatch(fotActions.setThoughtChain(val));
  };

  const thinkingInputRef = useRef(null);

  const isHomeView = view === "home";

  const onChangeQuery = e => {
    setQuery(e.target.value);
  };
  const formSubmit = async e => {
    e.preventDefault();
    const mode = isHomeView ? "fromNewThoughtEnter" : "fromNewReactionEnter";

    if (store.getState().fot.showChainThoughts) {
      setChainThoughtsState(false);
    }
    try {
      thinkingInputRef.current.blur();
      setQuery("");
      await props.enterQuery(query, mode);
    } catch (error) {
      console.error("formSubmit", error);
    }
  };

  const clickHomeIcon = () => {
    setView("home");
    setThoughtChain([]);
    props.get_home_cards();
    setChainThoughtsState(false);
    setFocusedThought([]);
  };

  return (
    <form className={`${style.ThinkingInput} ${props.class}`} onSubmit={formSubmit}>
      {!isHomeView ? (
        <div className={style["home-icon"]} onClick={clickHomeIcon}>
          <img src={`${settings.S3CDN}${settings.viewAssetsPath}home-icon.png`} />
        </div>
      ) : null}
      {props.disableInput ? null : (
        <input
          className={style.FeedSearchQuery}
          value={query}
          ref={thinkingInputRef}
          onChange={onChangeQuery}
          placeholder={isHomeView ? "Start a new thinking..." : "Add a new reaction"}
          type="text"
        />
      )}
    </form>
  );
}

export default ThinkingInput;
