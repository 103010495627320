import { isEmpty } from "lodash";
import { HomeUsePluginUtil } from "./HomeUsePluginUtil";
import { GROUPID_REG, NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import func from "@uikit/func";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";

export class ProjectLoaderUtil {
  static handleProjectDataV3(
    selectedExistingProject: any,
    canvasDataRef: CanvasDataRef
  ): {
    nodeIndex: any;
    edgeIndex: any;
    nodesFromProject: any;
    edgesFromProject: any;
    UIData: any;
    variablesList: any;
    customFunctions: any;
  } {
    let nodesFromProject = [];
    if (typeof selectedExistingProject?.v3?.nodes === "string") {
      nodesFromProject = JSON.parse(selectedExistingProject?.v3?.nodes);
    }
    if (Array.isArray(selectedExistingProject?.v3?.nodes)) {
      nodesFromProject = selectedExistingProject?.v3?.nodes;
    }

    let edgesFromProject = [];
    if (typeof selectedExistingProject?.v3?.edges === "string") {
      edgesFromProject = JSON.parse(selectedExistingProject?.v3?.edges);
    }
    if (Array.isArray(selectedExistingProject?.v3?.edges)) {
      edgesFromProject = selectedExistingProject?.v3?.edges;
    }

    let variablesList = [];
    if (typeof selectedExistingProject?.v3?.variablesList === "string") {
      variablesList = JSON.parse(selectedExistingProject?.v3?.variablesList);
    }

    let UIData = [];
    if (!isEmpty(selectedExistingProject?.v3?.UI)) {
      UIData = selectedExistingProject.v3.UI.map((x: any) => {
        let inputObj = x.input;
        let outputArr = x.output;
        if (!isEmpty(x.input)) {
          if (typeof x.input === "string") {
            inputObj = JSON.parse(x.input);
          } else {
            inputObj = inputObj.map((x: any) => {
              const nodeData = HomeUsePluginUtil.getFunctionData(canvasDataRef.nodeDataRef.current);
              const data = Object.assign({}, x.data, { ...nodeData });
              return Object.assign({}, x, { data });
            });
          }
        }
        if (!isEmpty(x.output) && typeof x.output === "string") {
          outputArr = JSON.parse(x.output);
        }

        let titleStyle = {};
        if (!isEmpty(x.titleStyle) && typeof x.titleStyle === "string") {
          titleStyle = JSON.parse(x.titleStyle);
        }

        let subTitleStyle = {};
        if (!isEmpty(x.subTitleStyle) && typeof x.subTitleStyle === "string") {
          subTitleStyle = JSON.parse(x.subTitleStyle);
        }

        let showTitle = x?.showTitle;
        if (typeof x?.showTitle != "boolean") {
          showTitle = true;
        }
        let showSubTitle = x?.showSubTitle;
        if (typeof x?.showSubTitle != "boolean") {
          showSubTitle = true;
        }

        return {
          ...x,
          input: inputObj,
          output: outputArr,
          titleStyle,
          subTitleStyle,
          showTitle,
          showSubTitle,
        };
      });
    }

    const nodeIndex = selectedExistingProject.v3?.nodeIndex;
    const edgeIndex = selectedExistingProject.v3?.edgeIndex;
    if (!nodesFromProject || !edgesFromProject || isNaN(nodeIndex) || isNaN(edgeIndex)) {
      throw Error("Failed to load from project, incorrect data format");
    }
    nodesFromProject = nodesFromProject.map((x: any) => {
      let text = x.data?.textAreaValue;
      if (typeof text === "string") {
        text = x.data?.textAreaValue.trim();
      }
      let nodeData = {};
      if (GROUPID_REG.test(x.id)) {
        nodeData = canvasDataRef.groupDataRef.current;
      } else {
        nodeData = NEW_EDGE_REG.test(x.id) ? canvasDataRef.newEdgeDataRef.current : canvasDataRef.nodeDataRef.current;
      }
      const data = Object.assign({}, { ...nodeData }, x.data, { textAreaValue: text });
      return Object.assign({}, x, { data });
    });

    edgesFromProject = edgesFromProject.map((x: any) => {
      const data = Object.assign({}, { ...canvasDataRef.newLineDataRef.current }, x.data);
      return Object.assign({}, x, {
        // 由于edge连接形式更改，兼容旧数据指定默认edge起始/终点为左右连接
        sourceHandle: isEmpty(x.sourceHandle) ? "b" : x.sourceHandle,
        targetHandle: isEmpty(x.targetHandle) ? "a" : x.targetHandle,
        data,
        type: "customNewLine",
      });
    });
    const hasOldSliceData = edgesFromProject.some((x: any) => x.data.isCreatedBySlice && !GROUPID_REG.test(x.target));
    if (hasOldSliceData) {
      // FIXME: util 不应该出现 ui 东西
      func.customMsg({
        content:
          "Your project is using a older version of sharding that is no longer supported, please remove your splitted nodes and redo sharding for your nodes.",
        type: "warning",
      });
    }

    let customFunctions = [];
    if (!isEmpty(selectedExistingProject?.v3?.functions)) {
      customFunctions = selectedExistingProject.v3.functions.map((x: any) => {
        if (!isEmpty(x.edgeArr)) {
          const newEdgeArr = x.edgeArr.map((y: any) => {
            return {
              ...y,
              data: {
                ...canvasDataRef.newLineDataRef.current,
                ...y.data,
              },
            };
          });
          return {
            ...x,
            edgeArr: newEdgeArr,
          };
        }
        return x;
      });
    }

    return {
      nodeIndex,
      edgeIndex,
      nodesFromProject,
      edgesFromProject,
      UIData,
      variablesList,
      customFunctions,
    };
  }
}
