import styles from "./AppDetail.module.scss";
import { AModal } from "@uiview/views/AModal/AModal";
import { map } from "lodash";
import { getIt } from "@uikit/getIt";
import { useMemo } from "react";
import { PreviewPaneStore } from "@uiview/views/PreviewPane/PreviewPaneStore";

type AppDetailProps = {
  saasUIData: PreviewApp.UISaasValueType;
};
export function AppDetail(props: AppDetailProps) {
  const list = useMemo(() => {
    const arr = [];
    if (props.saasUIData?.appDetail) {
      for (const [key, value] of Object.entries(props.saasUIData.appDetail)) {
        arr.push({
          name: key.charAt(0).toUpperCase() + key.slice(1),
          description: Array.isArray(value) ? value.join(", ") : value,
        });
      }
      return arr;
    }
  }, [props.saasUIData?.appDetail]);

  return (
    <div className={styles.content}>
      {map(list, item => {
        return (
          <div className={styles.item} key={item.name}>
            <h3>{item.name}</h3>
            <p>{item.description}</p>
          </div>
        );
      })}
    </div>
  );
}

type AppDetailModalProps = {
  saasUIData: PreviewApp.UISaasValueType;
  onClose: () => void;
  onOk: () => void;
};

export function AppDetailModal({ saasUIData, onClose, onOk }: AppDetailModalProps) {
  const previewPaneStore = getIt(PreviewPaneStore);
  return (
    <AModal
      open={true}
      onCancel={() => {
        onClose();
        previewPaneStore.openAppDetails(false);
      }}
      onOk={() => {
        onOk();
        previewPaneStore.openAppDetails(false);
      }}
      buttons={[]}
      width={420}
    >
      <AppDetail saasUIData={saasUIData} />
    </AModal>
  );
}
