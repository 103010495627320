"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FotClient = void 0;
const FotClientAdapter_1 = require("../api/FotClientAdapter");
const FoodApi_1 = require("../api/FoodApi");
const GraphqlClient_1 = require("../cutil/GraphqlClient");
const RestClient_1 = require("../cutil/RestClient");
const StreamClient_1 = require("../cutil/StreamClient");
const MapApi_1 = require("./MapApi");
const CotApi_1 = require("./CotApi");
const JiraApi_1 = require("./JiraApi");
const GeneralApi_1 = require("./GeneralApi");
const WebConfigAPI_1 = require("./WebConfigAPI");
const Settings_1 = require("../kernel/Settings");
class FotClient {
    constructor(authStore, projectStore, locationManager) {
        this.authStore = authStore;
        this.projectStore = projectStore;
        this.locationManager = locationManager;
        const restClientAdapter = new FotClientAdapter_1.FotClientAdapter(Settings_1.settings.API, authStore, projectStore);
        this.restClient = new RestClient_1.RestClient(restClientAdapter);
        const graphqlClientAdapter = new FotClientAdapter_1.FotClientAdapter(Settings_1.settings.API + "/api/social/", authStore, projectStore);
        this.graphqlClient = new GraphqlClient_1.GraphqlClient(graphqlClientAdapter);
        const streamClientAdapter = new FotClientAdapter_1.FotClientAdapter(Settings_1.settings.API, authStore, projectStore);
        this.streamClient = new StreamClient_1.StreamClient(streamClientAdapter);
        const jiraRestClientAdapter = new FotClientAdapter_1.FotClientAdapter("", authStore, projectStore);
        this.jiraRestClient = new RestClient_1.RestClient(jiraRestClientAdapter);
    }
    get generalApi() {
        return new GeneralApi_1.GeneralApi(this.restClient);
    }
    get cot() {
        return new CotApi_1.CotApi(this.restClient);
    }
    get foodApi() {
        return new FoodApi_1.FoodApi(this.restClient, this.locationManager);
    }
    get map() {
        return new MapApi_1.MapApi(this.restClient);
    }
    get jira() {
        return new JiraApi_1.JiraAPI(this.jiraRestClient);
    }
    get webConfigApi() {
        return new WebConfigAPI_1.WebConfigAPI();
    }
}
exports.FotClient = FotClient;
