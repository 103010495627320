import { useComputed } from "@preact/signals-react";
import { useCreation } from "ahooks";
import classNames from "classnames";
import { BackgroundVariant } from "reactflow";
import { CotMsg } from "imagica-corekit/dist/base/msg/CotMsg";
import { useEventbus } from "imagica-uikit/dist/hooks/useEventbus";
import AutoResizablePanel from "../AutoResizablePanel/AutoResizablePanel";
import styles from "./MultiRoundChatOnboarding.module.scss";
import { GenUIBloc } from "./GenUIBloc";
import { Chatbox } from "./components/Chatbox/Chatbox";
import { MultiRoundChatOnboardingBloc } from "./components/OnboardingInput/MultiRoundChatOnboardingBloc";
import { OnboardingInput } from "./components/OnboardingInput/OnboardingInput";
import { PreviewPaneStore } from "../PreviewPane/PreviewPaneStore";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { CreatorBackground } from "@uiview/views/ProjectCanvas/CreatorRoot/CreatorBackground";
import { previewStore } from "@uiview/store/PreviewStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";
import { matchMobile } from "@uikit/func";
import { useEffect } from "react";
import FotReactFlowBackground from "../ProjectCanvas/CreatorRoot/FotReactFlow/FotReactFlowBackground";

export type BlocType = GenUIBloc;

type MultiRoundChatOnboardingProps = {
  className?: string;
};

export function MultiRoundChatOnboarding(props: MultiRoundChatOnboardingProps): JSX.Element {
  const isMobile = matchMobile();
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const chatOnboardingState = useImagicaStore(chatOnboardingStore).value;
  const previewState = useImagicaStore(previewStore).value;

  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags: FeatureTags = homeStoreState.featureTags;

  //  有对话内容(默认有一条数据)或者是thinking-canvas模式，则打开对话形式

  useEffect(() => {
    if (isMobile && featureTags.disableMobilePage) {
      if (chatOnboardingState.messages.length > 1) {
        chatOnboardingStore.setChatOnboardingState({ showOnBoardingInput: false });
      } else {
        chatOnboardingStore.setChatOnboardingState({ showOnBoardingInput: true });
      }
    } else {
      const isInput = !(
        chatOnboardingState.messages.length > 1 ||
        chatOnboardingState.selectTemplateInModal !== ChoosingFormat.AUTOIMAGICA
      );
      chatOnboardingStore.setChatOnboardingState({ showOnBoardingInput: isInput });
    }
  }, [chatOnboardingState.messages, chatOnboardingState.selectTemplateInModal]);

  const multiRoundChatOnboardingBloc = new MultiRoundChatOnboardingBloc([]);

  const genUIBloc = useCreation(() => new GenUIBloc(), []);

  const previewPaneStore = getIt(PreviewPaneStore);
  const { settingPageStatus } = useStore(previewPaneStore).value;

  const bloc = useComputed(() => {
    return genUIBloc;
  }).value;

  const handleSetIsCallerBotAI = (): void => {
    chatOnboardingStore.setChatOnboardingState({ showOnBoardingInput: false });
    const current = genUIBloc;
    current.state.submitValue.value = multiRoundChatOnboardingBloc.thinkValue.value;
    current.onPressEnter();
  };

  useEventbus(CotMsg, () => {
    bloc.state.showPreview.value = false;
    bloc.state.buildEnd.value = true;
  });

  const chatPanelClassName = classNames(
    styles.chatPanel,
    { [styles.showPreview]: previewState.showPreviewPanel },
    { [styles.showPreviewAndSetting]: settingPageStatus }
  );

  const coverConvasClassName = classNames(styles.coverConvas, {
    [styles.hidePreview]: chatOnboardingState.showOnBoardingInput, // input时，preview可能存在，通过提升z-index遮住preview
  });

  const selectOption = (val: string): void => {
    const current = genUIBloc;
    current.state.submitValue.value = val;
    current.onPressEnter();
  };

  if (isMobile && featureTags.disableMobilePage) {
    return (
      <div className={classNames(styles.callerBotPane, props.className)}>
        <Chatbox bloc={bloc} isInput={chatOnboardingState.showOnBoardingInput} selectOption={selectOption} />
      </div>
    );
  }

  return (
    <>
      <AutoResizablePanel
        data-creator="CallorBotPaneConverConvas"
        className={coverConvasClassName}
        data-testid="CallorBotPaneConverConvas"
      >
        <CreatorBackground />
      </AutoResizablePanel>
      {chatOnboardingState.showOnBoardingInput ? (
        <OnboardingInput setIsCallerBotAI={handleSetIsCallerBotAI} bloc={multiRoundChatOnboardingBloc} />
      ) : (
        <AutoResizablePanel
          data-creator="CallerBotPane"
          className={chatPanelClassName}
          position="top-right"
          data-testid="CallerBotPane"
        >
          <FotReactFlowBackground
            id="1"
            gap={70}
            color="rgba(2, 12, 38, 0.05)"
            lineWidth={0.5}
            variant={BackgroundVariant.Lines}
          />
          <div className={styles.callerBotPane}>
            <Chatbox bloc={bloc} />
          </div>
        </AutoResizablePanel>
      )}
    </>
  );
}
