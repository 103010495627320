import { Signal, useSignal } from "@preact/signals-react";
import { BrainClient as CorekitBrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { getIt } from "@uikit/getIt";
import { ConfigItemPage, pagesConfig } from "@uiview/pages/profile/ProfileConfig";
import { cloneDeep } from "lodash";

export class ProfilePageState {
  // 默认隐藏 subscription
  allData: Signal<ConfigItemPage[]> = useSignal(pagesConfig);

  currentData: Signal<ConfigItemPage> = useSignal(pagesConfig[0]);
}

export class ProfilePageBloc {
  brainClient = getIt(CorekitBrainClient);
  meStore = getIt(MeStore);
  constructor(public state: ProfilePageState, public enterPath: string) {
    const last = enterPath.split("/").last() ?? "";
    const find = this.state.allData.value.find(item => {
      return last === item.key;
    });
    if (find) {
      this.switchPage(find);
    }
  }
  async checkSubscriptTags(): Promise<void> {
    const metore = this.meStore;
    // TODO: move to meStore:
    async function hasStudiosPaywallPermission() {
      const me = await metore.get();
      const tags = me.feature_tags ?? [];
      return !tags.includes("disable_studios_paywall");
    }

    // 重置
    this.state.allData.value = pagesConfig.filter(item => item.key !== "subscription");
    // 重置权限
    if (await hasStudiosPaywallPermission()) {
      this.state.allData.value = cloneDeep(pagesConfig);
    }
  }
  get popProfilePath(): string {
    const parent = this.enterPath.split("/profile").first();
    return parent ?? "";
  }

  switchPage(data: ConfigItemPage): void {
    this.state.currentData.value = data;
  }
}
