import { signal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import {
  Message,
  PromptModel,
  PromptModelResponse,
} from "imagica-corekit/dist/base/api/chatCompletionTyped/ChatCompletionsTyped";
import { Building, BuildingGraph } from "imagica-corekit/dist/base/api/onboardingChatTyped/building";
import { Calling } from "imagica-corekit/dist/base/api/onboardingChatTyped/calling";
import { Confirmation } from "imagica-corekit/dist/base/api/onboardingChatTyped/confirmation";
import { Ideation } from "imagica-corekit/dist/base/api/onboardingChatTyped/ideation";
import { DescTitle } from "imagica-corekit/dist/base/api/onboardingChatTyped/descTitle";
import { Prototyping, PrototypingComponent } from "imagica-corekit/dist/base/api/onboardingChatTyped/prototyping";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";

import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { StreamReadResult } from "imagica-corekit/dist/base/cutil/StreamClient";
import { findLastIndex, last } from "lodash";

export enum Role {
  ASSISTANT = "assistant",
  USER = "user",
}

class PromptInfo extends PromptModelResponse {
  filename: string = "";
  domain: string = "";
}

export class PromptInfos {
  static classifier: PromptInfo = {
    prompt: ``,
    // engine: "gpt-4-1106-preview",
    engine: "gpt-3.5-turbo-1106",
    max_tokens: 810,
    temperature: 0,
    response_format: {
      type: "json_object",
    },
    domain: "onboarding",
    filename: "classifier",
  };
  static ideation: PromptInfo = {
    prompt: ``,
    // engine: "gpt-4-1106-preview",
    engine: "gpt-3.5-turbo-1106",
    max_tokens: 810,
    temperature: 0,
    domain: "onboarding",
    filename: "ideation_v1",
    response_format: {
      type: "json_object",
    },
  };
  static prototyping: PromptInfo = {
    prompt: ``,
    // engine: "gpt-4-1106-preview",
    engine: "gpt-3.5-turbo-1106",
    max_tokens: 1025,
    temperature: 0,
    response_format: {
      type: "json_object",
    },
    domain: "onboarding",
    filename: "prototyping_v1",
  };
  static building: PromptInfo = {
    prompt: ``,
    // engine: "gpt-4-1106-preview",
    engine: "gpt-3.5-turbo-1106",
    max_tokens: 1025,
    temperature: 0,
    response_format: {
      type: "json_object",
    },
    domain: "onboarding",
    filename: "building_v1",
  };
  static callPhone: PromptInfo = {
    prompt: ``,
    // engine: "gpt-4-1106-preview",
    engine: "gpt-3.5-turbo-1106",
    // engine: "text-davinci-003",
    max_tokens: 1025,
    temperature: 0,
    domain: "onboarding",
    filename: "callPhone_v2",
    response_format: {
      type: "json_object",
    },
  };
  static title: PromptInfo = {
    prompt: ``,
    // engine: "gpt-4-1106-preview",
    engine: "gpt-3.5-turbo-1106",
    max_tokens: 100,
    temperature: 0,
    domain: "onboarding",
    filename: "title_gen_v1",
  };
  static confirmation: PromptInfo = {
    prompt: ``,
    engine: "gpt-3.5-turbo-1106",
    max_tokens: 100,
    temperature: 0,
    domain: "onboarding",
    filename: "confirmation",
    response_format: {
      type: "json_object",
    },
  };
}

class ChatState {
  promptInfo = signal<PromptModelResponse>(new PromptModelResponse());
  message = signal<Message[]>([]);
  loading = signal<boolean>(false);
}

export class ChatBloc {
  state = new ChatState();
  brainClient = getIt(BrainClient);

  constructor(public promptInfo: PromptInfo) {
    this.state.promptInfo.value = promptInfo;
    this.getPromptInfoByAPI();
  }

  private transformPromptModel(): PromptModel {
    const domain = this.promptInfo.domain;
    const model_params = `${domain}_xxx_${this.promptInfo.filename}.model`;

    return {
      domain,
      model_params,
    };
  }

  private structureMessage(): Message[] {
    const message = [
      {
        content: this.state.promptInfo.value.prompt,
        role: "system",
      },
      ...this.state.message.value,
    ];

    return message;
  }

  private updateMessage(latestMsg: string, role: Role): void {
    this.state.message.value = [
      ...this.state.message.value,
      {
        content: latestMsg,
        role,
      },
    ];
  }

  private addOrUpdateAssistantMsg(msg: string): void {
    const index = findLastIndex(this.state.message.value);
    if (this.state.message.value[index]?.role === Role.USER) {
      return this.updateMessage(msg, Role.ASSISTANT);
    }
    const newMsg = [...this.state.message.value];
    newMsg[index].content = msg;
    this.state.message.value = newMsg;
  }

  public getLastMessage(): string {
    const message = last(this.state.message.value)?.content || "";
    return message;
  }

  public clearMessage(): void {
    this.state.message.value = [];
  }

  async getPromptInfoByAPI(): Promise<void> {
    const result = await tryPromise(this.brainClient.openAi.instantIntent(this.transformPromptModel()));
    // just only use prompt
    this.state.promptInfo.value = { ...this.state.promptInfo.value, prompt: result.data?.data.prompt || "" };
  }

  async getNewChatInfoByAPI(useInput: string, callbackReceived?: (fullText: string) => void): Promise<void> {
    this.updateMessage(useInput, Role.USER);
    this.state.loading.value = true;
    await new Promise((resolve, reject) => {
      let onReceived = (msg: StreamReadResult) => {
        if (msg.done) {
          console.log(this.promptInfo.prompt, this.state.message.value, "this.state.message");

          msg.abort();
          resolve(undefined);
        }
        const fullText = msg.fullText.trim();
        callbackReceived?.(fullText);
        this.addOrUpdateAssistantMsg(fullText);
      };

      this.brainClient.openAi
        .chatCompletions({
          messages: this.structureMessage(),
          model: this.state.promptInfo.value?.engine!,
          response_format: this.state.promptInfo.value?.response_format,
          stream: true,
        })
        .then(re => {
          re.onReceived(re => onReceived(re));
        })
        .catch(error => {
          reject(error);
        });
    });
    this.state.loading.value = false;
  }

  async chatOnce(useInput: string): Promise<void> {
    this.clearMessage();
    await this.getNewChatInfoByAPI(useInput);
  }
}

export class TitleChatBloc extends ChatBloc {
  title = signal<DescTitle>(new DescTitle());

  constructor(public promptInfo: PromptInfo) {
    super(promptInfo);
  }

  async transformAssistantMessage(useInput: string, callbackReceived?: (msg: string) => void): Promise<DescTitle> {
    this.clearMessage();
    await this.getNewChatInfoByAPI(useInput, callbackReceived);
    const message = this.getLastMessage();
    const title = JsonUtil.toModelFromType(DescTitle, message) ?? new DescTitle();
    this.title.value = title;
    return title;
  }
}

export class IdeationChatBloc extends ChatBloc {
  ideation = signal<Ideation>(new Ideation());

  constructor(public promptInfo: PromptInfo) {
    super(promptInfo);
  }

  async transformAssistantMessage(useInput: string, callbackReceived?: (msg: string) => void): Promise<Ideation> {
    await this.getNewChatInfoByAPI(useInput, callbackReceived);
    const message = this.getLastMessage();
    const ideation = JsonUtil.toModelFromType(Ideation, message) ?? new Ideation();
    this.ideation.value = ideation;
    return ideation;
  }
}

export class PrototypingSortableComponent extends PrototypingComponent {
  id: string = "";
}

export class PrototypingChatBloc extends ChatBloc {
  components = signal<PrototypingComponent[]>([]);
  sortableComponents = signal<PrototypingSortableComponent[]>([]);
  constructor(public promptInfo: PromptInfo) {
    super(promptInfo);
  }

  private isSequentiallyOrdered() {
    const sortableComponents = this.sortableComponents.value;
    if (sortableComponents.length) {
      return sortableComponents.map(component => component.id).every((num, index, arr) => num > (arr[index - 1] || -1));
    }
    return true;
  }

  private structureInput(useInput: string) {
    if (this.isSequentiallyOrdered()) {
      return useInput;
    }
    const components = this.sortableComponents.value.map(({ id, ...item }) => item);
    return JSON.stringify(components, null, 4) + "\n" + useInput;
  }

  async transformAssistantMessage(useInput: string, callbackReceived?: (msg: string) => void): Promise<Prototyping> {
    this.isSequentiallyOrdered();
    await this.getNewChatInfoByAPI(this.structureInput(useInput), callbackReceived);
    const message = this.getLastMessage();
    const prototyping = JsonUtil.toModelFromType(Prototyping, message) ?? new Prototyping();

    this.components.value = prototyping.components;
    this.sortableComponents.value = prototyping.components.map((item, index) => ({ ...item, id: index.toString() }));
    return prototyping;
  }
}

export class BuildingBloc extends ChatBloc {
  graph = signal<BuildingGraph[]>([]);
  constructor(public promptInfo: PromptInfo) {
    super(promptInfo);
  }

  async transformAssistantMessage(useInput: string, callbackReceived?: (msg: string) => void): Promise<Building> {
    this.clearMessage();
    await this.getNewChatInfoByAPI(useInput, callbackReceived);
    const message = this.getLastMessage();
    const building = JsonUtil.toModelFromType(Building, message) || new Building();

    this.graph.value = building.graph;
    return building;
  }
}

export class ConfirmBloc extends ChatBloc {
  constructor(public promptInfo: PromptInfo) {
    super(promptInfo);
  }

  async transformAssistantMessage(useInput: string, callbackReceived?: (msg: string) => void): Promise<Confirmation> {
    this.clearMessage();
    await this.getNewChatInfoByAPI(useInput, callbackReceived);
    const message = this.getLastMessage();
    const confirmation = JsonUtil.toModelFromType(Confirmation, message) || new Confirmation();
    return confirmation;
  }
}

export class CallingBloc extends ChatBloc {
  constructor(public promptInfo: PromptInfo) {
    super(promptInfo);
  }

  async transformAssistantMessage(useInput: string, callbackReceived?: (msg: string) => void): Promise<Calling> {
    await this.getNewChatInfoByAPI(useInput, callbackReceived);
    const message = this.getLastMessage();
    const calling = JsonUtil.toModelFromType(Calling, message) || new Calling();
    return calling;
  }
}
