import SharesBuy from "@views/components/SharesBuy";
import { Stock } from "imagica-uikit/dist/nodesV2/Stock/Stock";
import style from "../HtmlTemplate/HtmlTemplate.module.css";
import { useSelector } from "react-redux";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import classNames from "classnames";
import StockLoading from "@views/components/StockLoading/StockLoading";
/**
 * 临时这样处理，后续重构 `SharesBuy` 组件
 */
export function StockWithShareBuy(props: any): JSX.Element {
  const creatorNodesStore = getIt(CreatorNodesStore);

  const locusEditorId = useSelector((state: any) => state.fot.locusEditorId);

  const showShareBuy = locusEditorId === `canvas${props.id}` && props.isInCanvas === true;

  const setUserInputPrice = (value: number): void => {
    creatorNodesStore.setNodes((prevList: any[]) =>
      prevList.map((item: any) => {
        if (item.id === props.id) {
          return {
            ...item,
            data: {
              ...item.data,
              userRelatedData: {
                ...item.data.userRelatedData,
                userInputPrice: value,
              },
            },
          };
        }
        return item;
      })
    );
  };

  return (
    <div
      data-testid="StockWithShareBuy"
      className={classNames(style.StockWithShareBuy, {
        [style.shareBuy]: showShareBuy,
      })}
    >
      {showShareBuy && (
        <SharesBuy
          filteredThoughts={[]}
          setUserInputPrice={setUserInputPrice}
          editorId={props.id}
          className={style["custom-node-sharebuy"]}
        />
      )}
      {showShareBuy === false && (props.stockLoading ? <StockLoading loading /> : <Stock {...props} />)}
    </div>
  );
}
