import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";
import css from "./index.module.scss";
import { HandleDnd, HandleDndProps } from "./HandleDnd";

type ToolBarProps = HandleDndProps & {
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
  hideHandle?: boolean;
};

export function SortableToolbar(props: ToolBarProps) {
  const { hideHandle, className, children, sortableProps, onSwitch } = props;

  const { style } = sortableProps || {};

  const DndElement = <HandleDnd sortableProps={sortableProps} onSwitch={onSwitch} />;

  const Target = (
    <div className={classNames(css["Toolbar"], className)} style={style} data-testid="Toolbar">
      {/* handle */}
      {hideHandle ? null : DndElement}

      {/* overlay */}
      {/* {hideHandle ? null : <div className={css["toolbar-overlay"]}></div>} */}

      {/* {hideHandle ? null : (
  <div className={classNames(css["toolbar-actions"], css["vertical-icons"])}>
    <img src={`${settings.S3CDN}${settings.viewAssetsPath}checkMarkBlack.png`}></img>
    <img src={`${settings.S3CDN}${settings.viewAssetsPath}previewDelete2x.png`}></img>
  </div>
)} */}

      <div className={css["toolbar-main"]}>{children}</div>
    </div>
  );

  return Target;
}
