import store from "@store/index";
import func from "@uikit/func";
import { cloneDeep, isEmpty } from "lodash";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";
import { PreviewAppUtil } from "@uiview/views/PreviewApp/PreviewAppUtil";
import { WEB_APP_DEFAULT_SUBTITLE, WEB_APP_DEFAULT_TITLE } from "@views/thinking-layout-editor/constants";
import { SharePreviewStore } from "@uiview/views/AISaas/SharePreviewStore";
import { PreviewService } from "imagica-corekit/dist/cases/preview/PreviewService";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { EdgeRunTypeStock } from "@uikit/edgeRun/EdgeRunTypeStock";
import { previewStore } from "@uiview/store/PreviewStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { CreatorCanvasDeleteMethods } from "@uikit/service/CreatorCanvasDeleteService";
import { ProjectPageStore } from "@uikit/store/ProjectPageStore";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { getStartNodes } from "@views/thinking-layout-editor/util";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

export class HomeHandleFieldMethods {
  constructor(
    private creatorEdgesStore: CreatorEdgesStore,
    private creatorNodesStore: CreatorNodesStore,
    private creatorSaasAppStore: CreatorSaasAppStore,
    private projectNodeStore: ProjectNodeStore,
    private creatorAISaasStore: CreatorAISaasStore,
    private sharePreviewStore: SharePreviewStore,
    private previewService: PreviewService,
    private edgeRunTypeStock: EdgeRunTypeStock,
    private previewAppStore: PreviewAppStore,
    private creatorRefStore: CreatorRefStore,
    private fotHomeStore: HomeStore,
    private homeMethodsService: HomeMethodsService,
    private creatorCanvasDeleteMethods: CreatorCanvasDeleteMethods,
    private projectPageStore: ProjectPageStore,
    private fotReduxStore: FotReduxStore
  ) {}

  // 修复type没地方使用
  // eslint-disable-next-line
  async handleInputField(
    currentNode: any,
    type: string | undefined = undefined,
    cotDescription = "",
    title = "",
    subTitle = ""
  ): Promise<void> {
    // 提交检查需要使用type
    console.log(type);
    const edges = this.creatorEdgesStore.getEdges();
    const nodes = this.creatorNodesStore.getNodes();
    const saasUIData = this.creatorSaasAppStore.state.saasUIData;
    const oldInputNode = this.creatorSaasAppStore.state.saasUIData.input || [];
    // opt:
    const selectedTemplate = store.getState().studio.selectedTemplate as any;

    try {
      if (PreviewAppValueLangUtil.isChatAiApp(saasUIData)) {
        PreviewAppUtil.handleChatAIAppField({
          creatorSaasAppStore: this.creatorSaasAppStore,
          projectNodeStore: this.projectNodeStore,
          currentNode: currentNode,
          creatorNodesStore: this.creatorNodesStore,
          // selectedTemplate,
          setInputId: this.creatorAISaasStore.setInputId,
        });
        return;
      } else {
        this.creatorSaasAppStore.setSaasUIData(prevData => {
          return PreviewAppUtil.genHandleInputField({
            saasUIData: prevData,
            currentNode,
            selectedTemplate,
          });
        });
      }
      //只在添加第一个input feild时，获取title
      if (!func.isEmpty(oldInputNode)) return;
      let inputNodeEdges = (edges.filter(x => x.source === currentNode.id) || []) as any[];
      if (inputNodeEdges.length > 0) {
        inputNodeEdges = nodes.filter(x => x.id === inputNodeEdges[0].target);
      }
      const edge = inputNodeEdges.find(x => !func.isEmpty(x?.data?.lineParam?.enterText));
      let inputValue = edge?.data?.lineParam?.enterText;
      // /function 生成
      if (CreatorNodesConsts.selectApiReg.test(inputValue)) {
        const selectOption = (store.getState() as any).fot.imageGenSelectOptions;

        const funcData = selectOption.find((x: { label: any }) => inputValue === `/${x.label}`);
        // input使用description的部分，description没有则使用name of function
        inputValue = funcData?.description || funcData?.label;
      }
      //如果传了description，使用传入的值
      if (!func.isEmpty(cotDescription)) {
        inputValue = cotDescription;
      }
      if (func.isEmpty(inputValue)) {
        return;
      }
      this.sharePreviewStore.setSaasLoading(true);

      // const query = `You are an AI that generates a creative 4-5 word call-to-action title and a short description for a landing page based on an AI description. You must start the title after "Title:" and description after "Description:"
      //   Input: ${inputValue}
      //   Output:`

      if (!title && !subTitle) {
        const previewTitle = await this.previewService.getTitle(inputValue);
        title = previewTitle.title;
        subTitle = previewTitle.subTitle;
      }

      // STUD-1221 title, subTitle from `parseThinkingData` data
      this.creatorSaasAppStore.setSaasUIData(prevData => {
        return {
          ...prevData,
          title: prevData.fromCotTitle || title || selectedTemplate?.name,
          subTitle: prevData.fromCotSubtitle || subTitle,
        };
      });
      this.sharePreviewStore.setSaasLoading(false);
    } catch (error: any) {
      this.sharePreviewStore.setSaasLoading(false);
      console.error("error::::", error);
      func.messageUtil(error);
      this.creatorSaasAppStore.setSaasUIData(prevData => {
        return {
          ...prevData,
          title: prevData.fromCotTitle || selectedTemplate?.name || WEB_APP_DEFAULT_TITLE,
          subTitle: prevData.fromCotSubtitle || WEB_APP_DEFAULT_SUBTITLE,
        };
      });
    }
  }

  async handleSaasV2InputField(currentNode: any, selectName?: any, cotDescription = ""): Promise<void> {
    const edges = this.creatorEdgesStore.getEdges();
    const oldInputNode = this.creatorSaasAppStore.state.saasUIData.input || [];

    const newInputNode = [...oldInputNode, currentNode];
    try {
      // stud-2493 set input(preview)
      this.creatorSaasAppStore.setSaasUIData(prevData => {
        return {
          ...prevData,
          input: newInputNode,
        };
      });
      this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);

      //只在添加第一个input feild时，获取title
      if (!func.isEmpty(oldInputNode)) return;
      let inputNodeEdges = (edges.filter(x => x.source === currentNode.id) || []) as any[];
      if (inputNodeEdges.length > 0) {
        inputNodeEdges = this.creatorNodesStore.getNodes().filter(x => x.id === inputNodeEdges[0].target);
      }
      const edge = inputNodeEdges.find(x => !func.isEmpty(x?.data?.lineParam?.enterText));
      let inputValue = edge?.data?.lineParam?.enterText;
      // /function 生成
      if (CreatorNodesConsts.selectApiReg.test(inputValue)) {
        const selectOption = store.getState().fot.imageGenSelectOptions;
        const funcData = selectOption.find(x => inputValue === `/${x.label}`);
        // input使用description的部分，description没有则使用name of function
        inputValue = funcData?.description || funcData?.label;
      }
      //如果传了description，使用传入的值
      if (!func.isEmpty(cotDescription)) {
        inputValue = cotDescription;
      }
      if (func.isEmpty(inputValue)) {
        return;
      }
      this.sharePreviewStore.setSaasV2Loading(true);
      const arr = [
        this.edgeRunTypeStock.postAIDescription(
          null,
          "brain_studios_xxx_brain_studios_saas_v2_app_name_1.model",
          { input: inputValue },
          false
        ),
        this.edgeRunTypeStock.postAIDescription(
          null,
          "brain_studios_xxx_brain_studios_saas_v2_app_title_1.model",
          { input: inputValue },
          false
        ),
        this.edgeRunTypeStock.postAIDescription(
          null,
          "brain_studios_xxx_brain_studios_saas_v2_app_tagline_1.model",
          { input: inputValue },
          false
        ),
        this.edgeRunTypeStock.postAIDescription(
          null,
          "brain_studios_xxx_brain_studios_saas_v2_input_suggestion.model",
          { input: inputValue },
          false
        ),
      ];
      const resArr = await Promise.all(arr);
      const appName = resArr[0][0].title;
      const appTitle = resArr[1][0].title;
      const appUseCase1 = resArr[1][1].title;
      const appUseCase2 = resArr[1][2].title;
      const appUseCase3 = resArr[1][3].title;
      const appTagline = resArr[2][0].title;
      const inputSuggestion = resArr[3][0].title;
      this.creatorSaasAppStore.setSaasUIData(prevData => {
        return {
          ...prevData,
          appName,
          appTitle,
          appUseCase1,
          appUseCase2,
          appUseCase3,
          appTagline,
          inputSuggestion,
        };
      });
      this.sharePreviewStore.setSaasV2Loading(false);
    } catch (error) {
      this.sharePreviewStore.setSaasV2Loading(false);
      console.error("error::::", error);
      func.messageUtil(error);
    }
  }

  addOrRemoveInputFeild(currentNode: any): void {
    const uiType = previewStore.state.selectedUI;
    if (func.isEmpty(uiType)) {
      this.previewAppStore.setShowPreviewPanel(true);
      return;
    }
    const inputIds = store.getState().fot.inputId as any[];
    //Add as input field
    if (!func.isEmpty(currentNode?.id) && !inputIds.includes(currentNode?.id)) {
      this.clickAddInputField(currentNode);
      return;
    }
    //Remove input field from app
    if (func.isEmpty(uiType)) return;
    const saasActionObj = this.homeMethodsService.getSaasAction();
    const needDeleteIds = !func.isEmpty(currentNode?.id) ? [currentNode.id] : [];
    if (func.isEmpty(saasActionObj) || func.isEmpty(needDeleteIds)) return;
    // bsf-4912 replace thoughts
    const copyThoughts = cloneDeep(this.creatorSaasAppStore.state.saasUIData.output);
    this.creatorCanvasDeleteMethods.handleSaasDeleteNodes(saasActionObj, copyThoughts, needDeleteIds);
  }

  clickAddInputField(node: any): void {
    const currentNode = node ?? this.creatorRefStore.nodeContextMenuRef.current;
    // 不是文本node则提示
    if (
      this.fotHomeStore.state.featureTags.disableUploadTypesAsInputNode === true &&
      currentNode.data.displayType !== "inputTextarea" &&
      currentNode.data.displayType !== "text"
    ) {
      func.customMsg({
        content: "Only text types can be added",
        type: "warning",
      });
      return;
    }

    this.getInputField(currentNode);

    this.previewAppStore.setShowPreviewPanel(true);
  }

  getInputField(currentNode: any): void {
    const inputId = store.getState().fot.inputId;
    const newInputId = [...inputId, currentNode.id];
    this.creatorAISaasStore.setInputId(newInputId);
    previewStore.state.selectedUI === "saasV2"
      ? this.handleSaasV2InputField(currentNode)
      : this.handleInputField(currentNode);
  }

  autoLoadInputField(): void {
    const startNodes = store.getState().fot.startNodeIds as any[];
    if (!isEmpty(this.creatorSaasAppStore.state.saasUIData.input) || func.isEmpty(startNodes)) return;

    const resultArr = this.creatorNodesStore.getNodes().filter(x => startNodes.includes(x.id));
    const inputIds = store.getState().fot.inputId;
    const newInputId = [...inputIds, ...startNodes];
    this.creatorAISaasStore.setInputId(newInputId);
    resultArr.forEach(inputNode => {
      // 改变 input 预览状态
      this.handleInputField(inputNode);
    });
  }
  autoSetInputField(): void {
    if (func.isEmpty(previewStore.state.selectedUI) || !isEmpty(this.creatorSaasAppStore.state.saasUIData.input))
      return;
    this.autoLoadInputField();
  }

  autoSelectTemplate = (byUnpublish = false, autoAddInput = true): void => {
    /// 手动构造选中 UI 模板数据
    const arr = [
      {
        label: "AI App",
        value: "saas",
        isStaticApp: false,
      },
    ] as any[];

    this.projectPageStore.setIsPublish(false);
    this.previewAppStore.setShowPreviewPanel(true);
    previewStore.setSelectedUI(arr[0].value);

    if (byUnpublish) {
      this.creatorAISaasStore.setInputId([]);
      // stud-1735 default saasvalue
      // stud-2493 set input(preview)(clear)
      this.creatorSaasAppStore.emptySaasUIData();
      this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);
    }

    if (!autoAddInput) return;

    if (arr.some(x => x.value === "saasV2")) {
      this.autoLoadInputField();
    }

    if (arr.some(x => x.value === "saas")) {
      this.autoLoadInputField();
    }
  };

  removeInputFeild(nodeIds: Array<string>): void {
    const saasDataObj = this.homeMethodsService.getSaasAction();
    const newInputNode = HomeMethodsUtil.deleteInputNode(saasDataObj, nodeIds);
    const newInputNodeId = newInputNode.map((x: any) => x.id);
    this.creatorAISaasStore.setInputId(newInputNodeId);
    // stud-2493 set input(preview)(delete)
    this.creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInputNode,
      };
    });
    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);
  }

  resetStartNodeIds(isStaticMode?: any): void {
    // opt: 如果将 redux 用 useSelector 则会绑定ui
    const startNodeIds = store.getState().fot.startNodeIds as any[];

    if (isStaticMode) {
      this.fotReduxStore.setStartNodeIds([]);
      return;
    }
    const isChat = PreviewAppValueLangUtil.isChatAiApp(this.creatorSaasAppStore.state.saasUIData);
    const preStartNodeIds = [...startNodeIds];
    const currentStartNodeIds = getStartNodes(
      this.creatorNodesStore.getNodes(),
      this.creatorEdgesStore.getEdges(),
      isChat
    );
    this.fotReduxStore.setStartNodeIds(currentStartNodeIds);
    const notStartNodes = preStartNodeIds.filter(x => !currentStartNodeIds.includes(x));
    if (!func.isEmpty(notStartNodes) && !func.isEmpty(previewStore.state.selectedUI)) {
      this.removeInputFeild(notStartNodes);
    }
  }
}
