"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublishSubscriptionStore = exports.PublishSubscriptionStoreState = void 0;
const GenUtil_1 = require("../../base/cutil/GenUtil");
const LangUtil_1 = require("../../base/cutil/LangUtil");
const Meta_1 = require("../../base/cutil/Meta");
const ReadyStatus_1 = require("../../base/cutil/ReadyStatus");
const StoreBase_1 = require("../../base/cutil/StoreBase");
class PublishSubscriptionStoreState {
    constructor() {
        this.status = ReadyStatus_1.ReadyStatus.PENDING;
        this.currentSelectOption = "";
        this.activeLink = "";
        this.accountId = "";
        this.priceId = "";
        this.showSubscriptionForm = false;
        this.isOpenPayWall = false;
        this.isPay = false; //  是否支付
        this.price = 0;
        this.free = 0;
        this.isPaidApp = false; // 是否是付费APP
        this.isOpenLogin = false; // 登录提示框
        this.loading = true; //  获取订阅信息loading
    }
    copyWith(params) {
        return GenUtil_1.GenUtil.copyWith(new PublishSubscriptionStoreState(), this, params);
    }
}
exports.PublishSubscriptionStoreState = PublishSubscriptionStoreState;
class PublishSubscriptionStore extends StoreBase_1.StoreBase {
    constructor(brainClient) {
        super(() => new PublishSubscriptionStoreState());
        this.brainClient = brainClient;
    }
    setState(param) {
        let newState = this.state.copyWith(param);
        this.emit(newState);
    }
    showSubscriptionForm(showSubscriptionForm) {
        this.setState({ showSubscriptionForm });
    }
    setIsOpenPayWall(isOpenPayWall) {
        this.setState({ isOpenPayWall });
    }
    setIsPay(isPay) {
        this.setState({ isPay });
    }
    setPrice(price) {
        this.setState({ price });
    }
    setFree(free) {
        this.setState({ free });
    }
    setIsPaidApp(isPaidApp) {
        this.setState({ isPaidApp });
    }
    setIsOpenLogin(isOpenLogin) {
        this.setState({ isOpenLogin });
    }
    setOpenPayWallAndLogin(val = true) {
        this.setState({ isOpenLogin: val });
        this.setState({ isOpenPayWall: val });
    }
    setLoading(loading) {
        this.setState({ loading });
    }
    async loadStripInfo(type, index, appId) {
        this.state.status = ReadyStatus_1.ReadyStatus.LOADING;
        // 激活链接过期
        if (type === "stripeConnectExpired") {
            return this.updateStripeLink(index, appId);
        }
        // 获取当前用户支付帐号信息
        const res = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.accountExpress());
        // 有账户信息且未激活
        if (res.data?.data && !res.data?.data.payouts_enabled) {
            return this.updateStripeLink(index, appId);
        }
        // 有账户信息且已激活
        if (res.data?.data) {
            this.state.status = ReadyStatus_1.ReadyStatus.DONE;
            this.setState({ accountId: res.data.data.id, showSubscriptionForm: true });
            return res.data.data.id;
        }
        // 不存在则创建账号并生成stripe激活链接
        const express = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.typeExpress());
        if (!express.data?.data.accountId) {
            this.state.status = ReadyStatus_1.ReadyStatus.DONE;
            throw new Meta_1.Meta("PublishSubscriptionStore Error", "Failed to create account.");
        }
        return this.updateStripeLink(index, appId);
    }
    // 生成stripe激活链接
    async updateStripeLink(index, appId) {
        this.setState({
            activeLink: await this.getNewStripeLink(index, appId),
            showSubscriptionForm: false,
        });
        this.state.status = ReadyStatus_1.ReadyStatus.DONE;
        return "";
    }
    /*
      头像下拉列表点击：【Monetization Dashboard】，根据是否有账户信息返回不同信息
     */
    async getMonetizationInfo(type) {
        // 激活链接过期
        if (type === "stripeConnectExpired") {
            return this.getNewStripeLinkObj();
        }
        // 获取当前用户支付帐号信息
        const res = await (0, LangUtil_1.tryPromise)(this.getMonetizationAccountInfo());
        // 有账户信息且未激活
        if (res.data?.data && !res.data?.data.payouts_enabled) {
            return this.getNewStripeLinkObj();
        }
        // 有账户信息且已激活：返回stripe dashboard地址
        if (res.data?.data) {
            return {
                isLinkToDashboard: true,
                link: await this.getStripeDashboardLink(),
            };
        }
        // 不存在则创建账号并生成stripe激活链接
        const express = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.typeExpress());
        if (!express.data?.data.accountId) {
            throw new Meta_1.Meta("PublishSubscriptionStore Error", "Failed to create account.");
        }
        return this.getNewStripeLinkObj();
    }
    async getMonetizationAccountInfo() {
        return this.brainClient.saaSMonetization.accountExpress();
    }
    /**
     * 需要stripe已注册且激活账号
     */
    async getStripeDashboardLink() {
        const result = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.accountLogin());
        return result.data?.data.url;
    }
    /**
     * 验证一遍账号信息再获取dashboard link
     */
    async getStripeDashboardLinkSafely() {
        const account = await (0, LangUtil_1.tryPromise)(this.getMonetizationAccountInfo());
        if (account.data?.data.payouts_enabled) {
            return this.getStripeDashboardLink();
        }
        // 账号未注册或未激活，返回空链接，不跳转
        return "";
    }
    async getNewStripeLinkObj() {
        return {
            isLinkToDashboard: false,
            link: await this.getNewStripeLink("", "", true),
        };
    }
    /**
     *
     * @param index
     * @param appId
     * @param needOpenDashboardWhenReturn 如果为true，再注册成功的return_url中会带有标识字段，识别到url即会进行自动打开新的页面进入stripe dashboard
     * @private
     */
    async getNewStripeLink(index = "", appId = "", needOpenDashboardWhenReturn = false) {
        const redirectUrl = `${window.location.origin}${window.location.pathname}#/editor/${index}?type=stripeConnectExpired&appId=${appId}`;
        const returnUrlParams = needOpenDashboardWhenReturn ? "isStripeRegistrationSuccess=true" : `appId=${appId}`;
        const parmasData = {
            return_url: `${window.location.origin}${window.location.pathname}#/editor/${index}?${returnUrlParams}`,
            refresh_url: `${window.location.origin}${window.location.pathname}#/editor/error?redirect_url=${encodeURIComponent(redirectUrl)}`,
            type: "account_onboarding",
        };
        // stripe激活链接
        const res = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.accountLink(parmasData));
        if (!res.data?.data.url) {
            throw new Meta_1.Meta("PublishSubscriptionStore Error", "Get link failed.");
        }
        return res.data.data.url;
    }
    async getSubscription(search_q) {
        const res = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.getSubscription(search_q));
        // status === "active"为已订阅
        return res.data?.data;
    }
    async getShareAppSubscriptionPrice(price_id) {
        const res = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.getShareAppSubscriptionPrice(price_id));
        return res.data?.data;
    }
    async paymentLink(priceId, trialPeriodDays, redirect, accountId) {
        const parmasData = {
            line_items: [
                {
                    price: priceId,
                    quantity: 1,
                },
            ],
            after_completion: {
                redirect: {
                    url: redirect,
                },
                type: "redirect",
            },
            transfer_data: {
                destination: accountId,
            },
        };
        if (trialPeriodDays) {
            parmasData.subscription_data = {
                trial_period_days: trialPeriodDays,
            };
        }
        const res = await (0, LangUtil_1.tryPromise)(this.brainClient.payWall.paymentLink(parmasData));
        if (!res.data?.data.url) {
            throw new Meta_1.Meta("PaymentLink Error", "Get link failed.");
        }
        return res.data?.data.url;
    }
    // 订阅方获取priceId
    async getPriceId(guest_id) {
        const res = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.productSearch(guest_id));
        if (!res.data?.data.data?.[0]?.id) {
            return null;
        }
        const productId = res.data.data.data[0].id;
        const resPrice = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.getProductPrice(productId));
        if (!resPrice.data?.data?.data?.[0]?.id) {
            return null;
        }
        // 返回为空为Free处理
        return resPrice.data.data.data[0].id;
    }
    async unSubscripber(params) {
        const paramsData = {
            customer: params.customer,
            return_url: params.return_url,
            flow_data: {
                type: "subscription_cancel",
                subscription_cancel: {
                    subscription: params.subscriptionId,
                },
            },
        };
        const resPrice = await (0, LangUtil_1.tryPromise)(this.brainClient.saaSMonetization.unSubscripber(paramsData));
        if (!resPrice.data?.data.url) {
            throw new Meta_1.Meta("Error", "Get link failed.");
        }
        return resPrice.data?.data;
    }
    async resubscribe(parmas) {
        const res = await (0, LangUtil_1.tryPromise)(this.brainClient.payWall.updateSubscripber(parmas));
        return res.data?.data?.url || "";
    }
}
exports.PublishSubscriptionStore = PublishSubscriptionStore;
