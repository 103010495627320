import { FC, MouseEvent, useMemo } from "react";
import cls from "classnames";
import style from "./style.module.scss";
import { IconTypes } from "./types";
import { callback } from "../../utilities";

export interface IconProps {
  className?: string;
  url: string;
  title?: string;
  type?: IconTypes;
  onClick?: (event: MouseEvent<HTMLImageElement>) => void;
}

const Icon: FC<IconProps> = ({ className, url, title, type = IconTypes.Default, onClick = callback }) => {
  const iconClassName = useMemo(() => {
    return cls(
      style.icon,
      className,
      type === IconTypes.Preview && style.icon_preview,
      type === IconTypes.Button && style.icon_button
    );
  }, [className, type]);

  return <img className={iconClassName} src={url} alt={title} onClick={onClick} />;
};

export default Icon;
