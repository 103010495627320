export function isParentSelected(node, nodeInternals) {
  if (!node.parentNode) {
    return false;
  }

  const parentNode = nodeInternals.get(node.parentNode);

  if (!parentNode) {
    return false;
  }

  if (parentNode.selected) {
    return true;
  }

  return isParentSelected(parentNode, nodeInternals);
}

export const getNodePositionWithOrigin = (node, nodeOrigin = [0, 0]) => {
  if (!node) {
    return {
      x: 0,
      y: 0,
      positionAbsolute: {
        x: 0,
        y: 0,
      },
    };
  }

  const offsetX = (node.width ?? 0) * nodeOrigin[0];
  const offsetY = (node.height ?? 0) * nodeOrigin[1];

  const position = {
    x: node.position.x - offsetX,
    y: node.position.y - offsetY,
  };

  return {
    ...position,
    positionAbsolute: node.positionAbsolute
      ? {
          x: node.positionAbsolute.x - offsetX,
          y: node.positionAbsolute.y - offsetY,
        }
      : position,
  };
};

export const clampPosition = (position = { x: 0, y: 0 }, extent) => ({
  x: clamp(position.x, extent[0][0], extent[1][0]),
  y: clamp(position.y, extent[0][1], extent[1][1]),
});

export const isNumeric = n => !isNaN(n) && isFinite(n);

// looks for all selected nodes and created a NodeDragItem for each of them
export function getDragItems(nodeInternals, nodesDraggable, mousePos, nodeId) {
  return (
    Array.from(nodeInternals.values())
      // .filter(
      //   (n) =>
      //     (n.selected || n.id === nodeId) &&
      //     (!n.parentNode || !isParentSelected(n, nodeInternals)) &&
      //     (n.draggable || (nodesDraggable && typeof n.draggable === "undefined"))
      // )
      .filter(n => n.id === nodeId)
      .map(n => ({
        id: n.id,
        position: n.position || { x: 0, y: 0 },
        positionAbsolute: n.positionAbsolute || { x: 0, y: 0 },
        distance: {
          x: mousePos.x - (n.positionAbsolute?.x ?? 0),
          y: mousePos.y - (n.positionAbsolute?.y ?? 0),
        },
        delta: {
          x: 0,
          y: 0,
        },
        extent: n.extent,
        parentNode: n.parentNode,
        width: n.width,
        height: n.height,
      }))
  );
}

export function calcNextPosition(node, nextPosition, nodeInternals, nodeExtent, nodeOrigin = [0, 0], onError) {
  let currentExtent = node.extent || nodeExtent;

  if (node.extent === "parent") {
    if (node.parentNode && node.width && node.height) {
      const parent = nodeInternals.get(node.parentNode);
      const { x: parentX, y: parentY } = getNodePositionWithOrigin(parent, nodeOrigin).positionAbsolute;
      currentExtent =
        parent && isNumeric(parentX) && isNumeric(parentY) && isNumeric(parent.width) && isNumeric(parent.height)
          ? [
              [parentX + node.width * nodeOrigin[0], parentY + node.height * nodeOrigin[1]],
              [
                parentX + parent.width - node.width + node.width * nodeOrigin[0],
                parentY + parent.height - node.height + node.height * nodeOrigin[1],
              ],
            ]
          : currentExtent;
    } else {
      // onError?.("005", errorMessages["error005"]());

      currentExtent = nodeExtent;
    }
  } else if (node.extent && node.parentNode) {
    const parent = nodeInternals.get(node.parentNode);
    const { x: parentX, y: parentY } = getNodePositionWithOrigin(parent, nodeOrigin).positionAbsolute;
    currentExtent = [
      [node.extent[0][0] + parentX, node.extent[0][1] + parentY],
      [node.extent[1][0] + parentX, node.extent[1][1] + parentY],
    ];
  }

  let parentPosition = { x: 0, y: 0 };

  if (node.parentNode) {
    const parentNode = nodeInternals.get(node.parentNode);
    parentPosition = getNodePositionWithOrigin(parentNode, nodeOrigin).positionAbsolute;
  }

  const positionAbsolute = currentExtent ? clampPosition(nextPosition, currentExtent) : nextPosition;

  return {
    position: {
      x: positionAbsolute.x - parentPosition.x,
      y: positionAbsolute.y - parentPosition.y,
    },
    positionAbsolute,
  };
}

// returns two params:
// 1. the dragged node (or the first of the list, if we are dragging a node selection)
// 2. array of selected nodes (for multi selections)
export function getEventHandlerParams({ nodeId, dragItems, nodeInternals }) {
  const extentedDragItems = dragItems.map(n => {
    const node = nodeInternals.get(n.id);

    return {
      ...node,
      position: n.position,
      positionAbsolute: n.positionAbsolute,
    };
  });

  return [nodeId ? extentedDragItems.find(n => n.id === nodeId) : extentedDragItems[0], extentedDragItems];
}

export const isMouseEvent = event => "clientX" in event;

export const getEventPosition = (event, bounds) => {
  const isMouseTriggered = isMouseEvent(event);
  const evtX = isMouseTriggered ? event.clientX : event.touches?.[0].clientX;
  const evtY = isMouseTriggered ? event.clientY : event.touches?.[0].clientY;

  return {
    x: evtX - (bounds?.left ?? 0),
    y: evtY - (bounds?.top ?? 0),
  };
};

export const clamp = (val, min = 0, max = 1) => Math.min(Math.max(val, min), max);
