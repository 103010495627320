"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterviewBotFunction = void 0;
const FunctionBase_1 = require("./FunctionBase");
const FunctionUtil_1 = require("./FunctionUtil");
class InterviewBotFunction extends FunctionBase_1.FunctionBase {
    constructor() {
        super(...arguments);
        this.url = "/stream/bas-demo-v4/nlp/completions_generation";
        this.domain = "brain_studios";
        this.identifierType = "file";
        this.identifierValue = "interview_agent_job_description_generation.model";
        this.streaming = false;
        // private async runStream(input: string, identityInfo?: FuncRunIdentityInfo): Promise<FuncResponse> {
        //   let postParam = {
        //     domain: "brain_studios",
        //     identifier_type: "file",
        //     identifier_value: "interview_agent_job_description_generation.model",
        //     stream: true,
        //     variables: {
        //       job_title: input,
        //     },
        //   };
        //   let url = "/stream/bas-demo-v4/nlp/completions_generation";
        //   let streamResponse = new RunStreamResponse<string>();
        //   let received = (msg: StreamReadResult) => {
        //     if (msg.done) {
        //       msg.abort();
        //     }
        //     streamResponse.receive(msg.fullText.trim(), msg.done, msg.error);
        //   };
        //   return this.postWithStreaming(url, postParam, received, FunctionUtil.createAbortUuid(identityInfo)).then(() => {
        //     return Promise.resolve(new FuncResponse(FuncResponseType.INTERVIEW_BOT, streamResponse));
        //   });
        // }
        //
        // private async postWithStreaming(url: string, params: any, received: Function, uuid?: string): Promise<any> {
        //   const result = await this.brainClient.openAi.postWithStreaming(url, params, uuid);
        //   result.onReceived(result => received(result));
        //   return Promise.resolve();
        // }
    }
    async run(input, identityInfo, ...arg) {
        const streamResponse = await this.socketRestClient.requestStream(topic => {
            let postParam = {
                domain: "brain_studios",
                identifier_type: "file",
                identifier_value: "interview_agent_job_description_generation.model",
                stream: true,
                variables: {
                    job_title: input,
                },
                public_top: topic,
            };
            let url = "/stream/bas-demo-v4/nlp/completions_generation";
            return this.brainClient.openAi.postWithStreaming(url, postParam, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo));
        }, "interViewBot", FunctionBase_1.FuncDataSourceType.INTERVIEW_BOT, identityInfo);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.INTERVIEW_BOT, streamResponse);
    }
}
exports.InterviewBotFunction = InterviewBotFunction;
