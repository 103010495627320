import { Analytics } from "imagica-corekit/dist/base/port/Analytics";
import { injectable } from "inversify";
import store from "@store/index";
import { EventManager, logEvent } from "@uikit/service/amplitude";

export class AnalyticsImpl extends Analytics {
  // event 是自定义的事件名称，properties 是事件的属性
  track(event: string, properties?: Record<string, any>): Promise<void> {
    try {
      const curUser = store.getState().editor.me;
      const manager = new EventManager(curUser);
      return manager.track(event, properties).then(() => {});
    } catch (err) {
      console.error(`amplitude event failed: ${event} - ${err}`);
      return Promise.reject();
    }
  }

  // event 是 fot/src/uikit/service/events.js 定义的事件名称，properties 是事件的属性
  async logEvent(event: string, properties?: Record<string, any>): Promise<void> {
    console.log("logEvent", event, properties);
    await logEvent(event, properties);
  }
}
