import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import style from "../views-css/StudiosLogin.module.css";
import func from "@uikit/func";
import { Button, Form, Input } from "antd";
import { auth } from "../uikit/login";
import { editorActions } from "../store/editor";
import { GoogleOauthButton } from "./components/GoogleOauthButton";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import useLinkParamsMetadata from "../custom-hooks/useLinkParamsMetadata";
import { MessageCenter } from "imagica-corekit/dist/base//kernel/MessageCenter";
import { getIt } from "@uikit/getIt";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import isBlank from "@sedan-utils/is-blank";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";

const StudiosLogin = props => {
  const messsageCenter = getIt(MessageCenter);
  const homePluginStore = getIt(HomePluginStore);
  const homeUsePluginState = useStore(homePluginStore).value;
  const fotProcessManager = FotProcessManager.use();

  const formRef = useRef(null);
  const bgVideoRef = useRef(null);
  const dispatch = useDispatch();

  const setIsClickLogin = val => {
    dispatch(editorActions.setIsClickLogin(val));
  };
  const setIsClickSignup = val => {
    dispatch(editorActions.setIsClickSignup(val));
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const { metadata } = useLinkParamsMetadata();

  const [textData] = useState({
    title: "Log in to your Imagica account",
    auxiliary: "Enter your email and password to log in",
  });
  const [logInLoading, setLogInLoading] = useState(false);

  const [errorPrompt, setErrorPrompt] = useState("");
  const [resetUrl, setResetUrl] = useState("");

  const isLogin = useSelector(state => state.fot.isLogin);
  const navigate = useNavigate();

  const toLogIn = () => {
    setErrorPrompt("");
    if (func.isEmpty(formRef.current)) return;
    // 验证
    formRef.current
      .validateFields()
      .then(res => {
        loginInterface(res);
      })
      .catch(console.error);
  };

  const loginInterface = async value => {
    try {
      setLogInLoading(true);
      // 账号登录
      if (!func.isEmpty(value.email) && !func.isEmpty(value.password)) {
        // get metadata
        const userData = {
          email: value.email,
          password: value.password,
        };
        if (!func.isEmpty(metadata)) {
          userData.metadata = metadata;
        }

        const response = await auth(userData);

        // 错误提示
        if (!func.isEmpty(response.non_field_errors)) {
          setErrorPrompt(response.non_field_errors);
          return;
        }
        setIsClickLogin(true);
        setIsClickSignup(false);
        // await getUserMe();
        await fotProcessManager.init();

        messsageCenter.reconnect();

        if (searchParams.get("mode") === "subscriber") {
          searchParams.delete("mode");
          setSearchParams(searchParams);
          const url = window.location.href.replace("/signup", "/editor");
          window.history.pushState({}, "", url);
        }

        props.setIsLogin(true);
      }
    } catch (error) {
      props.setIsLogin(false);
      setErrorPrompt("Log in failed, please try again.");
      console.log("error", error);
    } finally {
      setLogInLoading(false);
    }
  };

  useEffect(() => {
    const url = document.location.origin + "/password_reset";
    setResetUrl(url);

    const paramsObj = {};
    searchParams.forEach((value, key) => {
      paramsObj[key] = value;
    });
    props.setLinkParams(paramsObj);
    const preFillEmail = searchParams.get("email");
    if (preFillEmail) {
      props.setEmail(preFillEmail);
      formRef.current.setFieldValue("email", preFillEmail);
    }
    if (func.isEmpty(bgVideoRef.current)) return;

    // https://stackoverflow.com/questions/37674223/domexception-failed-to-load-because-no-supported-source-was-found
    const playPromise = bgVideoRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then(function () {
          // Automatic playback started!
        })
        .catch(function (error) {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
        });
    }
  }, []);

  useEffect(() => {
    if (isLogin) {
      navigate("/editor");
    }
  }, [isLogin, navigate]);

  return (
    <div className={style.login_imagica}>
      <video
        className={style["bg-video"]}
        ref={bgVideoRef}
        src={`${settings.S3CDN}${settings.viewAssetsPath}site_bg_1.mp4`}
        muted
        autoPlay
        loop
        x5-playsinlinee="true"
        playsInline
        webkit-playsinline="true"
      ></video>
      {/* 顶部logo */}
      <div
        className={classNames(isBlank(homeUsePluginState.customDomain) ? style["logo-box"] : style["logo-box-default"])}
      >
        <img src={`${settings.S3CDN}${settings.viewAssetsPath}logoX1.svg`} alt="" />
      </div>

      <div className={style["login-content"]}>
        <div className={style["login-box"]}>
          <div>
            <div className={style["main-information"]}>{textData.title}</div>
            <div className={style["auxiliary-information"]}>{textData.auxiliary}</div>
            <div className={style["error-prompt"]}>{errorPrompt}</div>
          </div>
          <Form ref={formRef} onFinish={() => toLogIn()}>
            <div className={style["input-box"]}>
              <Form.Item
                name="email"
                className={style["underline"]}
                rules={[
                  { required: true, message: "Please enter your email" },
                  { type: "email", message: "Please enter a valid email address" },
                ]}
              >
                <Input className={style["enter-input"]} placeholder="Email" bordered={false} />
              </Form.Item>

              <Form.Item
                name="password"
                className={style["underline"]}
                rules={[{ required: true, message: "Please enter your password" }]}
              >
                <Input className={style["enter-input"]} type="password" placeholder="Password" bordered={false} />
              </Form.Item>
              <a href={resetUrl} className={style["forgot-pwd"]}>
                Forgot password?{" "}
              </a>
            </div>

            <Button
              type="primary"
              loading={logInLoading}
              className={style["log-in-button"]}
              // onClick={() => toLogIn()}
              htmlType="submit"
            >
              Log in
            </Button>
          </Form>
          {isBlank(homeUsePluginState.customDomain) ? (
            <>
              <p className={style["or-text"]}>Or</p>
              <GoogleOauthButton enableOneTap={true} />
            </>
          ) : null}
        </div>
        {
          <p className={style["sign-up-link"]}>
            Don't have an account?{" "}
            <span
              onClick={() => {
                props.toSignUp();
              }}
            >
              Sign up
            </span>
            {
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M9 14L13 10L9 6"
                  stroke="#12192B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          </p>
        }
      </div>
    </div>
  );
};

export default StudiosLogin;
