export type UseProjectTemplateProps = { onShareProject: (foramtTemplate: FormatTemplateType) => void | Promise<void> };

export type FormatTemplateType = {
  attrIndexer?: string;
  id: string;
  label: string;
  loading: boolean;
  relationId: string;
  uuid: string;
  value: string;
  defaultTemplateName: string; // 默认模板名，首页内置的模板，有值说明是从首页模板创建
};

export enum Contents {
  Templates,
  Projects,
  Functions,
}

export interface VideoModalState {
  isOpen: boolean;
  videoUrl: string;
  videoName: string;
}
