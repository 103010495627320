import { ThemeType } from "imagica-corekit/dist/cases/theme/appTheme/AiAppThemeData";
import { ChatThemeType } from "imagica-corekit/dist/cases/theme/chatTheme/ChatAppThemeData";
import { FontType } from "imagica-corekit/dist/cases/theme/fontTheme/FontData";
import { AiAppThemeStore } from "imagica-corekit/dist/cases/theme/appTheme/AiAppThemeStore";
import { ChatAppThemeStore } from "imagica-corekit/dist/cases/theme/chatTheme/ChatAppThemeStore";
import { ThemeUtil, TemplateType } from "imagica-corekit/dist/cases/util/ThemeUtil";
import { getIt } from "@uikit/getIt";

export class PreviewPaneBloc {
  appThemeStore = getIt(AiAppThemeStore);
  chatThemeStore = getIt(ChatAppThemeStore);
  saasUIData;
  setSaasUIData;
  themeContainer;
  constructor(params: { saasUIData: any; setSaasUIData: any; themeContainer: React.RefObject<HTMLDivElement> }) {
    this.saasUIData = params.saasUIData;
    this.setSaasUIData = params.setSaasUIData;
    this.themeContainer = params.themeContainer;
  }

  // 给需要配置主题的根元素设置style属性变量
  setThemeProperty(ref: React.RefObject<HTMLDivElement>, template: TemplateType): void {
    ThemeUtil.setThemeProperty({
      ref,
      template,
      themeData: this.appThemeStore.themeDatas,
      chatThemeData: this.chatThemeStore.chatThemeDatas,
    });
    const font = this.saasUIData?.settings?.fontFamily || FontType.AKTIVGROTESK;
    this.setFontProperty(ref, font);
  }

  setFontProperty(ref: React.RefObject<HTMLDivElement>, font: string): void {
    ref.current?.style.setProperty(`--fontFamily`, font);
  }

  //  AI App切换主题
  changeTheme(theme: ThemeType): void {
    this.appThemeStore.setTheme(theme);
    this.setThemeProperty(this.themeContainer, TemplateType.AIAPP);
    this.setSaasUIData((prev: any) => ({ ...prev, theme }));
  }

  //  Chat AI App切换主题
  changeChatTheme(theme: ChatThemeType): void {
    this.chatThemeStore.setChatTheme(theme);
    this.setThemeProperty(this.themeContainer, TemplateType.CHATAIAPP);
    this.setSaasUIData((prev: any) => ({ ...prev, theme }));
  }

  //  切换字体
  changeFont(font: string): void {
    this.setFontProperty(this.themeContainer, font);
    //  设置font到saasUIData
    this.setSaasUIData((prev: any) => ({ ...prev, settings: { ...prev.settings, fontFamily: font } }));
  }
  changeCustom(
    domainData: PreviewApp.CustomDomain,
    saasUIData: PreviewApp.UISaasValueType
  ): PreviewApp.UISaasValueType {
    const updatedSaasUIData = Object.assign({}, saasUIData, {
      ...saasUIData,
      settings: {
        ...saasUIData.settings,
        domain: {
          ...(saasUIData.settings?.domain || {}),
          data: domainData.data,
        },
      },
    });
    this.setSaasUIData(updatedSaasUIData);
    return updatedSaasUIData;
  }
}
