import { find, get, max, min } from "lodash";
import { Dimensions, Edge, Node, XYPosition } from "reactflow";
import { halfValue } from "@views/thinking-layout-editor/util";

export type PartialXYPosition = Partial<XYPosition>;
export type RectType = PartialXYPosition & Partial<Dimensions>;

/**
 * 偏移百分比
 *
 * - 如果值为 负数则表示减,相反则加
 */
export type OffsetPercentType = PartialXYPosition;

type OffsetPercentArgs = {
  position: XYPosition;
  offset?: OffsetPercentType;
  getContainer?: () => Dimensions;
};

function getDefaultOffsetRect(): Dimensions {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

/**
 * 计算坐标工具类
 */
export class PositionUtil {
  /**
   * 获取两个点的中心点
   * @param sourcePos
   * @param targetPos
   * @returns
   */
  static getCenterPostionByPositionLink(sourcePos: XYPosition, targetPos: XYPosition): XYPosition {
    const { x: sX, y: sY } = sourcePos;
    const { x: tX, y: tY } = targetPos;

    return {
      x: halfValue(sX + tX),
      y: halfValue(sY + tY),
    };
  }

  /**
   * 获取两个点的矩形区域
   * @param sourcePos
   * @param targetPos
   * @returns
   */
  static getRectByPositionLink(sourcePos: XYPosition, targetPos: XYPosition): RectType {
    const { x: sX, y: sY } = sourcePos;
    const { x: tX, y: tY } = targetPos;

    // 四个象限的坐标
    const pos2 = { x: min([sX, tX]), y: min([sY, tY]) } as XYPosition;
    const pos4 = { x: max([sX, tX]), y: max([sY, tY]) } as XYPosition;
    const pos1 = { x: pos4.x, y: pos2.y } as XYPosition;
    const pos3 = { x: pos2.y, y: pos4.x } as XYPosition;

    // console.log("979", pos1, pos2, pos3, pos4);

    return {
      ...pos2,
      width: pos1.x - pos2.x,
      height: pos3.y - pos2.y,
    };
  }

  /**
   * 获取矩形区域中心点
   * @param rect
   * @param diffRect
   * @returns
   */
  static getCenterPositionByRect(rect: RectType, diffRect?: RectType): XYPosition | undefined {
    let { width, height } = rect;
    let x = get(rect, "x", 0);
    let y = get(rect, "y", 0);

    if (width && height) {
      // diff rect
      x += get(diffRect, "x", 0);
      y += get(diffRect, "y", 0);
      width += get(diffRect, "width", 0);
      height += get(diffRect, "height", 0);

      // result
      x = x + halfValue(width);
      y = y + halfValue(height);
      return { x, y };
    }
  }

  /**
   * 将位置信息进行偏移(按百分比)
   *
   * @todo 为处理 x 轴坐标
   */
  static offsetPositionPercent(args: OffsetPercentArgs): XYPosition {
    const { position, offset, getContainer = getDefaultOffsetRect } = args;

    if (!offset) {
      return position;
    }

    const { height } = getContainer();

    /**
     *
     * - position 是正数时, offset 为负数,则 position + (-offset)
     * - position 是正数时, offset 为正数,则 position - (+offset)
     * - position 是负数时, offset 为正数,则 -position - (offset)
     * - position 是负数时, offset 为负数,则 -position - (-offset)
     *
     * @param position
     * @param offset
     * @returns
     */
    function toOffset(position: number, offset: number): number {
      const isPositionNegative = position < 0;
      // 减
      if (offset < 1) {
        return position + Math.abs(offset);
      }

      // 加 TODO:
      return isPositionNegative ? position + offset : position - offset;
    }

    const offsetY = get(offset, "y", 0);

    if (offsetY != 0 && offsetY < 1 && offsetY > -1) {
      position.y = offsetY ? toOffset(position.y, Math.round(height * offsetY)) : position.y;
    }

    return position;
  }
}

/**
 * 为聚焦节点，边提供的工具类
 *
 * @fixme 需要提供 config 获取源数据
 */
export class FocusNodeUtil {
  /**
   * 边获取连接的节点
   * @param edge
   * @returns
   */
  static getNodesByEdge(edge: Edge<any>, nodes: Node[]): (Node | undefined)[] {
    const sourceNode = find(nodes, ["id", edge.source]);
    const targetNode = find(nodes, ["id", edge.target]);
    return [sourceNode, targetNode];
  }

  /**
   * 获取节点中心坐标点
   *
   * - 如果有组节点，需要加上组节点的坐标
   *
   */
  static getNodeCenterPosition(node: Node<any>, extraRect?: RectType, nodes: Node[] = []): XYPosition | undefined {
    const { position, width, height, parentNode: parentNodeId } = node;

    const nodeRect = { width, height, ...position };

    if (parentNodeId) {
      const parentNode = find(nodes, ["id", parentNodeId]);

      // 保证数据正确性
      if (!parentNode) {
        return;
      }

      // 扩展父节点的坐标
      nodeRect.x += get(parentNode, "position.x", 0);
      nodeRect.y += get(parentNode, "position.y", 0);
    }

    return PositionUtil.getCenterPositionByRect(nodeRect as RectType, extraRect);
  }

  /**
   * 获取ToolBar顶部居中
   *
   * - 如果有组节点，需要加上组节点的坐标
   *
   */
  static getNodeTopBarPosition(node: Node<any>, extraRect?: RectType, nodes: Node[] = []): XYPosition | undefined {
    const { position, width, parentNode: parentNodeId } = node;
    const barPosition = { x: position.x, y: position.y - 70 - 90 };
    const newHeight = window.innerHeight;
    const height = node.height ?? 200;
    if (height + 60 < newHeight - 200) {
      return this.getNodeCenterPosition(node, extraRect);
    }
    const nodeRect = { width, height: newHeight, ...barPosition };
    if (parentNodeId) {
      const parentNode = find(nodes, ["id", parentNodeId]);
      // 保证数据正确性
      if (!parentNode) {
        return;
      }

      // 扩展父节点的坐标
      nodeRect.x += get(parentNode, "position.x", 0);
      nodeRect.y += get(parentNode, "position.y", 0);
    }

    return PositionUtil.getCenterPositionByRect(nodeRect as RectType, extraRect);
  }

  /**
   * Node底部顶部居中，从下往上
   *
   * - 如果有组节点，需要加上组节点的坐标
   *
   */
  static getNodeBottomPosition(node: Node<any>, extraRect?: RectType): XYPosition | undefined {
    const { height } = node;
    const centerPosition = this.getNodeCenterPosition(node, extraRect);
    if (centerPosition && height) {
      const widowH = window.innerHeight;
      const safeSpace = 200;
      if (height > widowH - safeSpace) {
        const distence = (height - widowH + safeSpace) / 2.0 + 40;
        centerPosition.y += distence;
      }
    }
    return centerPosition;
  }

  /**
   * 获取边聚焦的中心点
   *
   */
  static getEdgeCenterPosition(edge: Edge<any>, extraRect?: RectType, nodes: Node[] = []): XYPosition | undefined {
    const [sourceNode, targetNode] = this.getNodesByEdge(edge, nodes);

    if (!sourceNode || !targetNode) {
      return;
    }

    // 两个节点的中心点
    const sourceCenterPos = this.getNodeCenterPosition(sourceNode);
    const targetCenterPos = this.getNodeCenterPosition(targetNode);

    if (sourceCenterPos && targetCenterPos) {
      return PositionUtil.getCenterPostionByPositionLink(sourceCenterPos, targetCenterPos);
    }
  }
  /**
   * TODO: 该方法名应该是两个节点中心点
   * @param node1
   * @param node2
   * @returns
   */
  static focusPosition(node1: Node<any>, node2: Node<any>, nodes: Array<Node>): XYPosition | undefined {
    // 两个节点的中心点
    const sourceCenterPos = FocusNodeUtil.getNodeCenterPosition(node1, undefined, nodes);
    const targetCenterPos = FocusNodeUtil.getNodeCenterPosition(node2, undefined, nodes);

    if (sourceCenterPos && targetCenterPos) {
      const centerPos = PositionUtil.getCenterPostionByPositionLink(sourceCenterPos, targetCenterPos);
      return centerPos;
    }
  }
}
