import { get } from "lodash";

/**
 * DndThoughtType 拖拽类型
 *
 * AISaasOutput.ThoughtType 预览数据类型
 *
 *
 */
export class ConverterUtil {
  static aiThought2DndThought(thought: AISaasOutput.ThoughtType, index: number): AISaasOutput.DndThoughtType {
    const dndId = thought?.results?.[0]?.previewId;
    const sort = (index + 1) * 100;
    return {
      ...thought,
      id: dndId,
      dndData: {
        id: dndId,
        sort,
      },
    };
  }

  /**
   * 将之前 预览的 aiSaasList 转换成 dnd拖拽List
   * @param thoughts
   */
  static aiThoughtList2DndThoughtList(thoughts: AISaasOutput.ThoughtType[]): AISaasOutput.DndThoughtType[] {
    return thoughts.map(ConverterUtil.aiThought2DndThought);
  }

  /**
   * 将之前 预览的 aiSaasList 转换成 分组后的 dnd拖拽List
   *
   * - 处理分组
   * @param thoughts
   */
  static aiThoughtList2DndGroupThoughts(thoughts: AISaasOutput.ThoughtType[]): AISaasOutput.DndThoughtWithGroupType[] {
    let lastGroupId = "";
    const result = [] as AISaasOutput.DndThoughtWithGroupType[];

    // 该方法是基于在 group 和单个节点依顺序排列的数据基础之上
    // group1,group1,group1-sub1,group2,group2
    // group2 后面不会有 group1
    thoughts.forEach((sassUIDataOutput, index) => {
      if (sassUIDataOutput.groupId) {
        if (sassUIDataOutput.groupId !== lastGroupId) {
          result.push({
            id: sassUIDataOutput.groupId,
            groupDnd: [],
          });
        }

        lastGroupId = sassUIDataOutput.groupId;
        const lastResultIndex = result.length ? result.length - 1 : 0;
        result[lastResultIndex].groupDnd.push(ConverterUtil.aiThought2DndThought(sassUIDataOutput, index));
      } else {
        const previewId = get(sassUIDataOutput, "results[0].previewId");

        result.push({
          id: previewId,
          groupDnd: [ConverterUtil.aiThought2DndThought(sassUIDataOutput, index)],
        });
      }
    });

    return result;
  }

  static dndThought2AiThought(thought: AISaasOutput.DndThoughtType): AISaasOutput.ThoughtType {
    return thought;
  }

  /**
   * 转换 `aiThoughtList2DndGroupThoughts`
   *
   * @param thought
   * @returns
   */
  static dndGroupThoughts2aiThoughtList(thoughts: AISaasOutput.DndThoughtWithGroupType[]): AISaasOutput.ThoughtType[] {
    let result = [] as AISaasOutput.ThoughtType[];
    thoughts.forEach(value => {
      result = result.concat(value.groupDnd);
    });
    return result;
  }

  /**
   * 为 container 提供快捷的数据转换
   * @param thoughts
   * @returns
   */
  static toDndData(thoughts: AISaasOutput.ThoughtType[]): AISaasOutput.DndThoughtWithGroupType[] {
    // return ConverterUtil.aiThoughtList2DndThoughtList(thoughts);
    return ConverterUtil.aiThoughtList2DndGroupThoughts(thoughts);
  }
  /**
   * 为 container 提供快捷的数据转换
   * @param thoughts
   * @returns
   */
  static toAiSaasThoughts(thoughts: ReturnType<typeof ConverterUtil.toDndData>): AISaasOutput.ThoughtType[] {
    return ConverterUtil.dndGroupThoughts2aiThoughtList(thoughts);
  }
}
