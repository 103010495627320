"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaaSMonetizationApi = void 0;
const SubscriptionPrice_1 = require("./saasMonetizationTyped/SubscriptionPrice");
const StripeLogin_1 = require("../api/saasMonetizationTyped/StripeLogin");
class SaaSMonetizationApi {
    constructor(restClient) {
        this.restClient = restClient;
    }
    async accountExpress() {
        return this.restClient.get("/be/payment/stripe/account/express").then(res => {
            return res.data;
        });
    }
    async typeExpress() {
        const parmasData = {
            capabilities: {
                card_payments: {
                    requested: true,
                },
            },
        };
        const url = "/be/payment/stripe/account/type/express";
        return this.restClient.post(url, parmasData).then(res => {
            return res;
        });
    }
    async accountLink(parmasData) {
        const url = "/be/payment/stripe/account/link";
        return this.restClient.post(url, parmasData).then(res => {
            return res;
        });
    }
    async accountLogin() {
        const url = "/be/payment/stripe/account/link/login";
        return this.restClient.post(url).then(res => {
            return res.toModelFromType(StripeLogin_1.StripeLogin);
        });
    }
    async getSubscriptionPrice(id) {
        return this.restClient.get(`/be/payment/subscription/price?product=${id}`).then(res => {
            return res;
        });
    }
    async subscriptionProduct(parmasData) {
        const url = "/be/payment/subscription/product/";
        return this.restClient.post(url, parmasData).then(res => {
            return res;
        });
    }
    async putSubscriptionProduct(parmasData, id) {
        const url = `/be/payment/subscription/product/${id}`;
        return this.restClient.put(url, parmasData).then(res => {
            return res;
        });
    }
    async subscriptionPrice(parmasData) {
        const url = "/be/payment/subscription/price";
        return this.restClient.post(url, parmasData).then(res => {
            return res;
        });
    }
    async getSubscription(search_q) {
        return this.restClient.get(`/be/payment/subscription/studios/${search_q}`).then(res => {
            return res;
        });
    }
    async getShareAppSubscriptionPrice(price_id) {
        return this.restClient.get(`/be/payment/subscription/price/${price_id}`).then(res => {
            return res;
        });
    }
    async deactiveSubscriptionPrice(price_id) {
        return this.restClient.put(`/be/payment/subscription/price/${price_id}`, { active: false }).then(res => {
            return res.toModelFromType(SubscriptionPrice_1.SubscriptionPrice);
        });
    }
    async productSearch(guest_id) {
        return this.restClient
            .get(`/be/payment/subscription/product/search?query=active:'true' AND metadata['guest_id']:'${guest_id}'`)
            .then(res => {
            return res;
        });
    }
    async getProductPrice(productId) {
        return this.restClient.get(`/be/payment/subscription/price?product=${productId}&active=true`).then(res => {
            return res;
        });
    }
    async unSubscripber(parmasData) {
        const url = "/be/payment/stripe/billingPortal";
        return this.restClient.post(url, parmasData).then(res => {
            return res;
        });
    }
}
exports.SaaSMonetizationApi = SaaSMonetizationApi;
