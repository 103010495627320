"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class ATheme {
}
exports.ATheme = ATheme;
ATheme.theme = {
    saasAiAppTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.saasAiAppTheme,
        background: "linear-gradient(119deg, rgba(243, 245, 246, 0.95) 27.85%, rgba(255, 255, 255, 0.00) 100.38%), linear-gradient(90deg, #E0C2B8 37.5%, #C6C7E2 68.23%, #C6DBE2 100%)",
    },
    nodeTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.nodeTheme,
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        nodeWrapperShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 10px 20px 0px rgba(0, 0, 0, 0.05)",
    },
};
