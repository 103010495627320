import Text from "@views/thinking-layout-editor/Home/Text";
import style from "./MoreActionViewStyle.module.scss";
import { ProjectDropdownItems } from "../../type";
import { CSSProperties } from "react";

type MoreActionViewProps = {
  onHidenView: () => void;
  onClickItem: (item: ProjectDropdownItems) => void;
  style?: CSSProperties;
};

export function MoreActionView(props: MoreActionViewProps): JSX.Element {
  return (
    <>
      <div
        className={style.bg_layer}
        onClick={e => {
          e.stopPropagation();
          props.onHidenView();
        }}
        onTouchMove={e => {
          e.stopPropagation();
          e.preventDefault();
          props.onHidenView();
        }}
      ></div>
      <div className={style.content} style={props.style}>
        <div className={style.content_list}>
          <div
            className={style.dropdownItem}
            onClick={e => {
              e.stopPropagation();
              props.onClickItem(ProjectDropdownItems.Duplicate);
            }}
          >
            <div className={style.dropdownItem__icon}>
              <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.16667 5.66667V4.16667C5.16667 3.23325 5.16667 2.76654 5.34832 2.41002C5.50811 2.09641 5.76308 1.84144 6.07668 1.68166C6.4332 1.5 6.89991 1.5 7.83333 1.5H13.3333C14.2668 1.5 14.7335 1.5 15.09 1.68166C15.4036 1.84144 15.6586 2.09641 15.8183 2.41002C16 2.76654 16 3.23325 16 4.16667V9.66667C16 10.6001 16 11.0668 15.8183 11.4233C15.6586 11.7369 15.4036 11.9919 15.09 12.1517C14.7335 12.3333 14.2668 12.3333 13.3333 12.3333H11.8333M3.66667 16.5H9.16667C10.1001 16.5 10.5668 16.5 10.9233 16.3183C11.2369 16.1586 11.4919 15.9036 11.6517 15.59C11.8333 15.2335 11.8333 14.7668 11.8333 13.8333V8.33333C11.8333 7.39991 11.8333 6.9332 11.6517 6.57668C11.4919 6.26308 11.2369 6.00811 10.9233 5.84832C10.5668 5.66667 10.1001 5.66667 9.16667 5.66667H3.66667C2.73325 5.66667 2.26654 5.66667 1.91002 5.84832C1.59641 6.00811 1.34144 6.26308 1.18166 6.57668C1 6.9332 1 7.39991 1 8.33333V13.8333C1 14.7668 1 15.2335 1.18166 15.59C1.34144 15.9036 1.59641 16.1586 1.91002 16.3183C2.26654 16.5 2.73325 16.5 3.66667 16.5Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={style.dropdownItem__text}>
              <Text>Duplicate</Text>
            </div>
          </div>
          <div
            className={style.dropdownItem}
            onClick={e => {
              e.stopPropagation();
              props.onClickItem(ProjectDropdownItems.Delete);
            }}
          >
            <div className={style.dropdownItem__icon}>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.1667 4.33333V3.66667C10.1667 2.73325 10.1667 2.26654 9.98501 1.91002C9.82522 1.59641 9.57025 1.34144 9.25665 1.18166C8.90013 1 8.43342 1 7.5 1H7.83333C6.89991 1 6.4332 1 6.07668 1.18166C5.76308 1.34144 5.50811 1.59641 5.34832 1.91002C5.16667 2.26654 5.16667 2.73325 5.16667 3.66667V4.33333M1 4.33333H14.3333M12.6667 4.33333V12C12.6667 13.4001 12.6667 14.1002 12.3942 14.635C12.1545 15.1054 11.772 15.4878 11.3016 15.7275C10.7669 16 10.0668 16 8.66667 16H6.66667C5.26654 16 4.56647 16 4.03169 15.7275C3.56129 15.4878 3.17883 15.1054 2.93915 14.635C2.66667 14.1002 2.66667 13.4001 2.66667 12V4.33333"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={style.dropdownItem__text}>
              <Text>Delete</Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
