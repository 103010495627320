import { Transition } from "framer-motion";

export const transition: Transition = {
  duration: 0.8,
};

export const background1 = [
  `linear-gradient(90deg,
  rgba(255, 255, 255, 0.0) 0%,
  rgba(255, 255, 255, 0.0) 25%,
  rgba(255, 255, 255, 0.0) 50%,
  rgba(255, 255, 255, 0.0) 75%,
  rgba(255, 255, 255, 0.0) 100%
)`,
  `linear-gradient(90deg,
  rgba(255, 255, 255, 0.8) 0%,
  rgba(255, 255, 255, 0.8) 25%,
  rgba(255, 255, 255, 0.8) 50%,
  rgba(255, 255, 255, 0.8) 75%,
  rgba(255, 255, 255, 0.8) 100%
)`,
];

export const background2 = [
  `linear-gradient(90deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.8) 25%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.8) 75%,
    rgba(255, 255, 255, 0.8) 100%
  )`,
  `linear-gradient(90deg,
    rgba(255, 255, 255, 0.0) 0%,
    rgba(255, 255, 255, 0.0) 25%,
    rgba(255, 255, 255, 0.0) 50%,
    rgba(255, 255, 255, 0.0) 75%,
    rgba(255, 255, 255, 0.0) 100%
  )`,
];
