import { getIt } from "@uikit/getIt";
import { WindowSizeStore } from "@uikit/store/WindowSizeStore";

let instance: WindowSizeManager;
function useWindowSizeManager(): WindowSizeManager {
  if (!instance) {
    return new WindowSizeManager(getIt(WindowSizeStore));
  }

  return instance;
}

export class WindowSizeManager {
  DESIGN_CLIENT_WIDTH = 1920;

  static use = useWindowSizeManager;

  constructor(public windowSizeStore: WindowSizeStore) {}

  /**
   * 视口小于1920，基于 当前视口宽度 / 1920 比例值设置body的zoom
   * zoom的最小值阈值为0.75
   * 视口大于1920，不设置zoom值
   */
  getBodyZoom(): number {
    const clientWidth = window.innerWidth;
    const scale = Math.max(0.75, Math.min(1, clientWidth / this.DESIGN_CLIENT_WIDTH));
    return scale < 1 ? scale : 1;
  }

  // Handler to call on window resize
  onResize(): void {
    // Set window width/height to state
    this.windowSizeStore.updateSize({
      width: window.innerWidth,
      height: window.innerHeight,
      zoom: this.getBodyZoom(),
    });
  }

  /**
   * 确保该方法被调用一次
   * @returns
   */
  init(): () => void {
    // Add event listener
    window.addEventListener("resize", this.onResize.bind(this));
    // Call handler right away so state gets updated with initial window size
    this.onResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", this.onResize.bind(this));
  }
}
