import { useSelector } from "react-redux";
// import { LoadingOutlined } from '@ant-design/icons';
// import { Spin } from 'antd';
import style from "../views-css/HomeScreen.module.css";
import CardFrame from "./CardFrame";
import ChainThought from "./ChainThought";
import ChainThoughtIcon from "./components/ChainThoughtIcon";
import ThinkingInput from "./components/ThinkingInput";
import UserAvatar from "./components/UserAvatar";
// import useGetUserAvatarUrl from '../custom-hooks/useGetUserAvatarUrl'
// import util from '../util';
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function HomeScreen(props) {
  const page = useSelector(state => state.fot.page);
  const loadingType = useSelector(state => state.fot.loadingType);

  const currentPageLoadingState = !!loadingType[page];

  // const { userAvatarUrl, userLoading } = useGetUserAvatarUrl()

  return (
    <div className={style["home-screen"]}>
      <ChainThought enterQuery={props.enterQuery} get_home_cards={props.get_home_cards} />

      <div className={style["home-top"]}>
        <header>
          {/* 头像 */}
          <UserAvatar />
          {/* <div className={style['avatar-box']}>
            {
              !userLoading ?
                !util.isEmpty(userAvatarUrl) ?
                  <img className={style['user-avatar']} src={userAvatarUrl} />
                : <img className={style['user-avatar']} src={defaultAvatar} />
              : <Spin indicator={antIcon} />
            }
          </div> */}

          {/* 背景图片 */}
          <div className={style["home-back-img"]}>
            <img className={style["back-img"]} src={`${settings.S3CDN}${settings.viewAssetsPath}home-back.png`} />
            <div className={style["bottom-img-box"]}>
              <img className={style["img-reverse"]} src={`${settings.S3CDN}${settings.viewAssetsPath}home-back.png`} />
              <div className={style["img-gradient"]}></div>
            </div>
          </div>
          {/* chain thought按钮 */}
          <ChainThoughtIcon />
        </header>

        <p className={style["top-text"]}>
          What's
          <span>&nbsp;on your mind?</span>
        </p>
      </div>

      <div className={style["home-bottom"]}>
        {/* 输入框 */}
        <ThinkingInput class="home-input" enterQuery={props.enterQuery} get_home_cards={props.get_home_cards} />

        <p className={style["bottom-text"]}>Or start with:</p>

        <div className={style["bottom-list"]}>
          {/* 列表 */}
          <div className={style["thought-list"]}>
            {props.homeCards.map((homeCard, id) => (
              <CardFrame
                key={id}
                id={id}
                reason={false}
                card={homeCard}
                page={page}
                currentPageLoadingState={currentPageLoadingState}
                select_card={props.select_home_card}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
