import { Row, Col } from "antd";
import classNames from "classnames";
import styles from "./ThemeSelect.module.scss";

export interface ThemeProps {
  themes: { label: string; value: string; logo: string }[];
  saasTheme: any;
  setSaasTheme: (theme: any) => void;
}

export function ThemeSelect(props: ThemeProps) {
  const { saasTheme } = props;

  const changeTheme = (theme: string) => {
    props.setSaasTheme(theme);
  };

  return (
    <div className={styles.container}>
      <span className={styles.title}>Color Theme</span>
      <div className={styles.themeBody}>
        <Row gutter={[20, 20]}>
          {props.themes.map(({ value, label, logo }) => (
            <Col span={12} key={value}>
              <div
                onClick={() => changeTheme(value)}
                data-testid={`Preview-Settings-Theme-${label}`}
                className={classNames(styles.themeItem, { [styles.themeSeleted]: value === saasTheme })}
              >
                <p className={styles.themeItemTitle}>{label}</p>
                <img src={logo} alt="" draggable="false" />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}
