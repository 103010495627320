"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CotDataTransformService = void 0;
const CotDataGraphToProjectUtil_1 = require("../cot/CotDataGraphToProjectUtil");
const CotDataProjectToGraphUtil_1 = require("./CotDataProjectToGraphUtil");
class CotDataTransformService {
    transformGraphToTemplateProject(input, flow) {
        return CotDataGraphToProjectUtil_1.CotDataGraphToProjectUtil.generateTemplateProject(input, flow, {});
    }
    transformTemplateProjectToGraph(project) {
        return CotDataProjectToGraphUtil_1.CotDataProjectToGraphUtil.createGrpah(project);
    }
}
exports.CotDataTransformService = CotDataTransformService;
