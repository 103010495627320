import { LottieAnimation } from "../../../views/components/LottieAnimation";
import unsupportedGraphLoader from "../../../views/assets/unsupportedGraph.json";
import style from "./UnSupportedGraph.module.css";

type UnSupportedProps = {
  handleClose: () => void;
};

export const UnSupportedGraph = ({ handleClose }: UnSupportedProps) => {
  return (
    <div className={style["unsupported-wrapper"]}>
      <div className={style["unsupported-mask"]} />
      <div className={style["unsupported-box"]}>
        <div className={style["lottie-bg"]} />
        <div className={style["lottie-bg2"]} />
        <LottieAnimation loading={false} loader={unsupportedGraphLoader} className={style["lottie-animation"]} />
        <div className={style["unsupported-action"]}>
          <p className={style["unsupported-message"]}>
            We are currently unable to execute your request. Please try again with a new query.
          </p>
          <div className={style["unsupported-button"]} onClick={handleClose}>
            Try again
          </div>
        </div>
      </div>
    </div>
  );
};
