import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import func from "@uikit/func";
import "../views-css/ContentFrame.css";
import "./components-css/Angle.css";
import "./components-css/Info.css";
import FeedContentSkeleton from "./components/FeedContentSkeleton";
import Retry from "./components/Retry";

import { map } from "lodash";
import useScrollToLatest from "../custom-hooks/useScrollToLatest";
import CardFrame from "./CardFrame";

function ContentFrame(props) {
  const [expanded, setExpanded] = useState(false);
  const mode = useSelector(state => state.fot.mode);
  const applicationTheme = useSelector(state => state.editor.applicationTheme);

  const on_select_frame = (rid, text) => {
    if (props.content.results[0].type != "google") {
      props.select_thought({
        id: props.id,
        rid: rid,
        text: text,
      });
    }
  };

  const on_select_angle = () => {
    if (mode === "editorMode") return;
    props.add_angle(props.id, props.content.angle);
  };

  const toggle_expanded = e => {
    if (!getOverAngelTitleCss()) return;
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const getOverAngelTitleCss = () => {
    const title = props?.content?.angle || "";
    return title.length > 40;
  };

  // const click_like = () => {
  //   props.add_like(props.id);
  // };

  // const click_dislike = () => {
  //   props.add_dislike(props.id);
  // };

  const dummy_call = () => {};

  if (props.content.results?.some?.(x => x.content === "ERROR")) {
    func.messageUtil("ERROR");
  }
  if (props.content.results?.every?.(x => x.content === "ERROR")) return;

  const getUIClassNameByMode = () => {
    let uiClass = "Home-UI";
    switch (mode) {
      case "studioMode":
        uiClass = "Studio-UI";
        break;
      case "editorMode":
        uiClass = `Editor-UI ${props.className}`;
        break;
    }
    // share app 丢失了theme class，所以再这里添加
    const findTheme = uiClass.split(" ").find(x => {
      return x === applicationTheme;
    });
    if (props.isShare && func.isEmpty(findTheme)) {
      uiClass = `${uiClass} ${applicationTheme}`;
    }
    return uiClass;
  };
  return (
    <article className={`ContentFrame ${getUIClassNameByMode()}`}>
      {props.content.loading ? (
        <FeedContentSkeleton skeletonfill={[".", ".", ".", ".", ".", ".", ".", ".", ".", "."]} />
      ) : props.content.showRetryView ? (
        <Retry ContentRetrieval={props.ContentRetrieval} content={props.content} enterQuery={props.enterQuery} />
      ) : props.content.implication ? (
        <article>
          <CardFrame
            id={-1}
            card={{
              type: "implication",
              content: props.content.implication,
            }}
            isShare={props.isShare}
            reason={false}
            select_card={dummy_call}
            otherModes={props.otherModes}
            zone={props.zone}
            handleImageLoaded={props.handleImageLoaded}
          />

          <section className="ContentFrameResults ContentFrameResultsGrid">
            {props.content.results
              ? props.content.results.map((result, id) => (
                  <CardFrame
                    key={id}
                    id={id}
                    card={result}
                    isShare={props.isShare}
                    reason={false}
                    select_card={on_select_frame}
                    otherModes={props.otherModes}
                    zone={props.zone}
                    handleImageLoaded={props.handleImageLoaded}
                  />
                ))
              : null}
          </section>
        </article>
      ) : props.content.angle == "" || props.content.angle == ". . ." ? (
        props.content.grid ? (
          <section className="ContentFrameResults ContentFrameResultsGrid">
            {props.content.results
              ? props.content.results.map((result, id) => (
                  <CardFrame
                    key={id}
                    id={id}
                    card={result}
                    reason={false}
                    isShare={props.isShare}
                    select_card={on_select_frame}
                    len={props.content.results.length || 0}
                    otherModes={props.otherModes}
                    zone={props.zone}
                    handleImageLoaded={props.handleImageLoaded}
                  />
                ))
              : null}
          </section>
        ) : (
          <section className="ContentFrameResults">
            {props.content.results
              ? props.content.results.map((result, id) => (
                  <CardFrame
                    className={props.className}
                    key={id}
                    id={id}
                    card={result}
                    reason={false}
                    isShare={props.isShare}
                    select_card={on_select_frame}
                    len={props.content.results.length || 0}
                    otherModes={props.otherModes}
                    zone={props.zone}
                    editorId={props.editorId}
                    locus={props.locus}
                    handleImageLoaded={props.handleImageLoaded}
                  />
                ))
              : null}
          </section>
        )
      ) : (
        <section className="AngleContent">
          <section className="ContentFrameAngle">
            {props.content.angle ? (
              <section className="AngleTitle">
                <p onClick={on_select_angle} className={getOverAngelTitleCss() && !expanded ? "over-angle-tile" : ""}>
                  {props.content.angle}
                </p>
                <span
                  onClick={e => toggle_expanded(e)}
                  className={`iconfont icon-right ${expanded ? "icon-rotate" : ""}`}
                ></span>
              </section>
            ) : null}
            {!func.isEmpty(props.content.reason) ? (
              <section className="ContentFrameReason">
                <p onClick={on_select_angle}>{props.content.reason}</p>
              </section>
            ) : null}
            <HorizontalScrollingCardFrame on_select_angle={on_select_angle} {...props} />
          </section>
        </section>
      )}
    </article>
  );
}

const HorizontalScrollingCardFrame = props => {
  const scrollWrapperRef = useRef(null);

  const { on_select_frame, otherModes, zone, handleImageLoaded, content } = props;

  useScrollToLatest({
    scrollWrapperRef,
    scrollList: content.results,
    selector: ".CardFrame",
    direction: "horizontal",
  });

  return (
    <section className="horizontalScrolling" ref={scrollWrapperRef}>
      {map(content.results, (result, id) => (
        <CardFrame
          len={content.results.length}
          key={id}
          id={id}
          card={result}
          isShare={props.isShare}
          reason={false}
          select_card={on_select_frame}
          otherModes={otherModes}
          zone={zone}
          handleImageLoaded={handleImageLoaded}
        />
      ))}
    </section>
  );
};

export default React.memo(ContentFrame);
