import React, { useEffect, useState, ChangeEvent, useContext } from "react";
import { Input, Dropdown, Button } from "antd";
import { InfoCircleOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import func from "@uikit/func";
import style from "./BodyParam.module.css";
import { CustomApiPageBlocContext } from "@uiview/pages/customApi/CustomApiPageBlocContext";
import { DownOutlined } from "@ant-design/icons";
import { useSignal } from "@preact/signals-react";

enum menuItemsType {
  json = "Json",
  text = "Text",
}

const { TextArea } = Input;
interface BodyParamProps {
  requestData: { bodyParam: string };
  setChildState: (value: string) => void;
}

const BodyParam: React.FC<BodyParamProps> = props => {
  const [showEvaluatedBody, setShowEvaluatedBody] = useState<boolean>(false);
  const bloc = useContext(CustomApiPageBlocContext);
  function changeBodyParam(e: ChangeEvent<HTMLTextAreaElement>): void {
    let text = e.target.value;
    const evaluatedBodyStr = bloc.getEvaluateFinalBodyStr(text, bloc.getSourceNodeValue(), bloc.getVarliableList());
    props.setChildState("");
    try {
      if (!func.isEmpty(evaluatedBodyStr)) {
        JSON.parse(evaluatedBodyStr);
      }
    } catch (error: any) {
      props.setChildState(error.message);
    }
    const requestData = bloc!.state.requestData.value;
    bloc!.state.requestData.value = requestData.copyWith({ bodyParam: text });
  }

  function beautify(): void {
    try {
      let text = props.requestData.bodyParam;
      if (typeof text !== "string") {
        text = JSON.stringify(text);
      }
      const parsed = JSON.parse(text);
      const requestData = bloc!.state.requestData.value;
      bloc!.state.requestData.value = requestData.copyWith({ bodyParam: JSON.stringify(parsed, null, 4) });
    } catch (_) {}
  }

  const menuItems = [menuItemsType.json, menuItemsType.text];

  const menuOverlay = (
    <div className={style.moreActionsBox}>
      {menuItems.map(item => (
        <div key={item} className={style.boxItem} onClick={() => bloc.handleMenuClick(item)}>
          {item}
        </div>
      ))}
    </div>
  );

  return (
    <div style={{ height: `${showEvaluatedBody ? "72%" : "95%"}` }}>
      <TextArea value={bloc.state.requestData.value.bodyParam} onChange={changeBodyParam} autoSize />
      {showEvaluatedBody ? (
        <div>
          <Button
            type="link"
            icon={<EyeOutlined style={{ position: "absolute", top: 6 }} />}
            onClick={() => {
              setShowEvaluatedBody(false);
            }}
          >
            <span style={{ position: "absolute", left: 25, bottom: 5 }}>Hide Evaluated Body String</span>
          </Button>
          <TextArea
            value={bloc.evaluateFinalBodyStr.value}
            autoSize={{ minRows: 4, maxRows: 4 }}
            disabled
            className={style["post-body-param-evaluated"]}
          />
        </div>
      ) : (
        <Button
          type="link"
          icon={<EyeOutlined style={{ position: "absolute", top: 6 }} />}
          onClick={() => {
            setShowEvaluatedBody(true);
          }}
        >
          <span style={{ position: "absolute", left: 25, bottom: 5 }}>See Evaluated Body String</span>
        </Button>
      )}

      {/* <Dropdown
        trigger={['click']}
        placement="bottomRight"
        open={bloc.state.selectBodyType.value}
        className={style['select-type-btn']}
        getPopupContainer={(e) => e}
        overlay={menuOverlay}
      >
        <div className={style.previewHistory} onClick={() => bloc.setSelectBodyType(!bloc.state.selectBodyType.value)}>
          {bloc.state.selectedOption.value}
          <DownOutlined />
        </div>
      </Dropdown> */}

      <Button
        type="link"
        onClick={() => {
          beautify();
        }}
        className={style["beautify-btn"]}
      >
        Beautify
      </Button>
    </div>
  );
};

export default BodyParam;
