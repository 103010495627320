import func from "@uikit/func";
import { CanvasNodeList } from "@uikit/model/CanvasNodeList";
import { CanvasNodeType } from "@uikit/store/CreatorNodesStore";
import { isArray, last } from "lodash";

export class CreatorNodesUtil {
  static removeNodesById(id: string | string[], nodes: CanvasNodeType[]): CanvasNodeType[] {
    if (isArray(id)) {
      return new CanvasNodeList(nodes).removeByIds(id).getNodes();
    }

    return new CanvasNodeList(nodes).removeById(id).getNodes();
  }

  static getNodeEdgeMaxIndex(
    nodes: any[],
    edges: any[]
  ): {
    maxNodeIndex: number;
    maxEdgeIndex: number;
  } {
    let nodeIds = nodes.map(x => x.id);
    nodes.forEach(x => {
      if (x?.data?.childNode) {
        const arr = x.data.childNode.filter((y: any) => !nodeIds.includes(y)) || [];
        nodeIds = nodeIds.concat(arr);
      }
    });
    nodeIds = nodeIds
      .filter(id => !func.isEmpty(id))
      .map(id => last(id.split("-") as any[]).replaceAll(/[a-zA-Z]/gi, ""))
      .map(id => +id)
      .sort((a, b) => {
        return a - b;
      });
    const edgeIds = edges
      .map(edge => edge.id)
      .map(id => last(id.split("-") as any[]).replaceAll(/[a-zA-Z]/gi, ""))
      .map(id => +id)
      .sort((a, b) => {
        return a - b;
      });
    const maxNodeIndex = !func.isEmpty(nodeIds) ? Math.max(...nodeIds) + 1 : 1;
    const maxEdgeIndex = !func.isEmpty(edgeIds) ? Math.max(...edgeIds) + 1 : 0;

    return {
      maxNodeIndex,
      maxEdgeIndex,
    };
  }

  static getOnlyNodes(nodes: CanvasNodeType[]): CanvasNodeType[] {
    return nodes.filter(node => node.type !== "customNewEdge");
  }
}
