"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryDisconnectedLine = exports.StoryOutputLine = exports.StoryInputLine = exports.StoryLine = void 0;
class StoryLine {
    constructor(options, source, target) {
        this.options = options;
        this.source = source;
        this.target = target;
    }
}
exports.StoryLine = StoryLine;
class StoryInputLine extends StoryLine {
    constructor(options, source, target) {
        super(options, source, target);
        this.options = options;
        this.source = source;
        this.target = target;
    }
}
exports.StoryInputLine = StoryInputLine;
class StoryOutputLine extends StoryLine {
    constructor(options, source, target) {
        super(options, source, target);
        this.options = options;
        this.source = source;
        this.target = target;
    }
}
exports.StoryOutputLine = StoryOutputLine;
/// 因clean操作清空group的子节点，而断连的line
class StoryDisconnectedLine {
    constructor(parentId, indexInParent, lines) {
        this.parentId = parentId;
        this.indexInParent = indexInParent;
        this.lines = lines;
    }
}
exports.StoryDisconnectedLine = StoryDisconnectedLine;
