import { useEffect, useRef } from "react";
import styles from "./nodeTypeComponents.module.css";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { uploadTypes } from "../constants";
import { Spin } from "antd";
import { NodeTitle } from "./NodeTitle";
import { Button, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import func from "@uikit/func";
import cls from "classnames";
import { useNodesTitle } from "@custom-hooks/HomeUsePluginRoot/useNodesTitle";
import { useSignal } from "@preact/signals-react";

const [, , ...restUploadTypes] = uploadTypes;

const NodeWrapper = ({
  id,
  isInCanvas,
  style,
  children,
  isOpenDeleteBox,
  isInBlueprint = false,
  inputTitle,
  loading,
  nodeLoading,
  displayType,
  enableFeedback,
  reason = "",
  mouseEnterLeave,
  typeInputRef,
}) => {
  const applicationTheme = useSelector(state => state.editor.applicationTheme);
  const isShare = useSelector(state => state.fot.isShare);

  const isBluePrintNode = displayType === "blueprint";
  const isVideo = displayType === "video";
  const isAudio = displayType === "audio";
  const nodeWrapperClassName = classNames(
    styles.nodeCovering,
    displayType !== "stockInfo" ? styles.nodeWrapPadding : styles.stockInfoResetSpace,
    restUploadTypes.includes(displayType) && styles.bordered,
    {
      [styles["fitNodeContent"]]: displayType === "chatInterface" || (displayType === "askBrain" && !isInCanvas),
    }
  );
  const videoTypeClass = isVideo ? cls(styles.nodeUnsetMarginTop, styles.overFlowVisible) : null;
  const { showAllNodesLabel } = useNodesTitle(id, isInBlueprint);
  const wrapperRef = useRef(null);
  const videoHeight = useSignal(style.height);
  useEffect(() => {
    if (isInCanvas && isVideo) {
      const wrapperWidth = wrapperRef.current.offsetWidth;
      const edgeSpacing = 40; // nodeWrapper的padding = 10，video内部padding = 10, 左右内外各相加=40
      if (wrapperWidth > edgeSpacing) {
        // 除了宽高比例不变的player和videoItem，其余所有高度为280, 外加底部的feedback：60
        const staticHeightExceptVideoControls = 320;
        const contentWidth = wrapperWidth - edgeSpacing;
        const widthResizeRatio = contentWidth / 240;
        // 设计图上播放器宽高比 = 240:130
        const videoPlayerHeight = widthResizeRatio * 130;
        // 设计图上
        // itemWidth : playerWidth = 110:240
        // itemWidth : itemHeight = 110:60
        const videoItemHeight = widthResizeRatio * 60; // widthResizeRatio * 110 / 110 * 60
        const minHeight = videoItemHeight + videoPlayerHeight + staticHeightExceptVideoControls;
        if (typeof style.height == "number") {
          videoHeight.value = Math.max(minHeight, style.height);
        } else {
          videoHeight.value = style.height;
        }
      }
    }
  }, [wrapperRef.current?.offsetWidth, style.height]);

  //  text node 需要实现随内容自动撑开，所以需要在初始化时去掉固定高度。
  const computedStyle = {
    ...style,
    height:
      displayType === "text" && style.height === 150
        ? "unset"
        : isAudio && !enableFeedback
        ? style.height - 20
        : style.height,
  };

  return (
    <div
      data-theme={isInCanvas ? "light" : applicationTheme}
      data-testid="Nodes"
      data-node-type={displayType}
      className={nodeWrapperClassName}
      onMouseEnter={() => mouseEnterLeave?.(true)}
      onMouseLeave={() => mouseEnterLeave?.(false)}
      style={
        isVideo
          ? {
              ...style,
              width: isShare ? "auto" : style.width,
              height: isInCanvas ? videoHeight.value : style.height,
            }
          : computedStyle
      }
      ref={wrapperRef}
    >
      {isInCanvas && (
        <NodeTitle
          id={id}
          inputTitle={inputTitle}
          displayType={displayType}
          isInBlueprint={isInBlueprint}
          typeInputRef={typeInputRef}
        />
      )}
      {isBluePrintNode && !func.isEmpty(reason) && (
        <Popover
          content={reason}
          placement="topLeft"
          trigger="click"
          overlayClassName={styles.reasonPopover}
          overlayInnerStyle={{ width: "300px" }}
        >
          <Button className={styles.reasonIcon} type="text" icon={<InfoCircleOutlined />} />
        </Popover>
      )}
      <div
        data-testid="NodesContent"
        className={cls(
          styles.nodeContent,
          showAllNodesLabel() && isInCanvas ? styles.nodeContentSpace : styles.nodeUnsetPaddingTop,
          videoTypeClass,
          displayType === "text" && styles.nodeText
        )}
      >
        {!nodeLoading && loading.value ? <Spin spinning={true}>{children}</Spin> : children}
      </div>
    </div>
  );
};

export default NodeWrapper;
