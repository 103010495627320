import { StoryNode } from "imagica-corekit/dist/base/storyV2/domain/StoryNode";
import { isArray, isPlainObject } from "lodash";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import isBlank from "@sedan-utils/is-blank";
import { NodeTitleUtil } from "@uiview/views/Nodes/NodeTitleUtil";
import { PreviewDataType } from "@uiview/views/PreviewApp/PreviewDataType";

type TransformData<T = any> = { id: string; type: string; data: T };
export type RunAllUpdateDataType = { updateNode?: StoryNode; transformData: TransformData | TransformData[] };

export class ShareAppService {
  constructor(
    public creatorPreviewService: CreatorPreviewService,
    public creatorNodesStore: CreatorNodesStore,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public homePluginStore: HomePluginStore
  ) {}

  runAllUpdateData?: RunAllUpdateDataType;

  /**
   * 设置 runall 后的数据
   * @param runAllUpdateData
   */
  setRunAllUpdateData = (runAllUpdateData: RunAllUpdateDataType): void => {
    this.runAllUpdateData = runAllUpdateData;

    // trigger update to preview
    this.effectTextAreaValue();
  };

  private handleUpdatePreviewData(transformData: any): void {
    if (transformData.type !== "customNode") return;
    let text = transformData.data.textAreaValue;
    if (typeof text === "string") {
      text = transformData.data.textAreaValue.trim();
    }
    this.creatorPreviewService.updatePreviewNodeContent({
      nodeId: transformData.id,
      textAreaValue: text,
      displayType: transformData.data.displayType,
      chatBoxParam: transformData.data.chatBoxParam,
      errorText: transformData.data.errorText,
      data: transformData.data,
    });
  }
  handleGroupToSingleNode(updateNode: StoryNode | undefined): void {
    if (!updateNode) return;
    // 不是从group变成单个node的情况不处理
    const prevGroupId = updateNode.options.id;
    if (!prevGroupId.includes("group")) return;
    let firstGroupOutputIdx = -1;
    let firstGroupOutputV2Idx = -1;
    // 找到属于以前group的outputs
    const prevGroupOutputs = this.creatorSaasAppStore.state.saasUIData.output.filter((x: any, index: number) => {
      if (x.groupId === prevGroupId) {
        if (firstGroupOutputIdx === -1) {
          firstGroupOutputIdx = index;
        }
        return true;
      }
      return false;
    });
    const prevGroupOutputV2s = this.creatorSaasAppStore.state.saasUIData.outputV2?.filter((x: any, index: number) => {
      if (x.parentId === prevGroupId) {
        if (firstGroupOutputV2Idx === -1) {
          firstGroupOutputV2Idx = index;
        }
        return true;
      }
      return false;
    });
    // 剩余的其他outputs
    const otherOutputs = this.creatorSaasAppStore.state.saasUIData.output.filter((x: any) => x.groupId !== prevGroupId);
    const otherOutputV2s = this.creatorSaasAppStore.state.saasUIData.outputV2?.filter(
      (x: any) => x.parentId !== prevGroupId
    );

    if (isBlank(prevGroupOutputs) || !prevGroupOutputV2s || !otherOutputV2s) return;
    const currentNode = this.creatorNodesStore.getNodes().find((x: any) => x.id === updateNode.id) || {};
    // 构造新的 output 数据结构
    const output = {
      ...prevGroupOutputs[0],
      groupId: "",
      results: [
        {
          ...prevGroupOutputs[0].results[0],
          blank: {
            title: NodeTitleUtil.getNodeTitle(currentNode as any),
          },
          content: updateNode.content,
          nodeId: updateNode.id,
          parentNode: "",
          previewId: `**${updateNode.id}`,
        },
      ],
    };
    const outputV2 = {
      ...prevGroupOutputV2s[0],
      id: `**${updateNode.id}`,
      nodeId: updateNode.id,
      parentId: "",
      data: {
        ...prevGroupOutputV2s[0].data,
        blank: {
          title: NodeTitleUtil.getNodeTitle(currentNode as any),
        },
        content: updateNode.content,
      },
    } as PreviewDataType;
    // 添加到指定 index 位置
    otherOutputs.splice(firstGroupOutputIdx, 0, output as any);
    otherOutputV2s.splice(firstGroupOutputV2Idx, 0, outputV2 as any);
    // 更新数据
    this.creatorSaasAppStore.setSaasUIData(prevData => {
      return {
        ...prevData,
        output: otherOutputs,
        outputV2: otherOutputV2s,
      };
    });
  }
  effectTextAreaValue(): void {
    if (!this.runAllUpdateData) {
      console.warn("ShareAppService runAllUpdateData undefined");
      return;
    }

    const { transformData, updateNode } = this.runAllUpdateData;
    // 处理group变成单个node的情况
    this.handleGroupToSingleNode(updateNode);

    if (isPlainObject(transformData) && !isArray(transformData)) {
      this.handleUpdatePreviewData(transformData);
      return;
    }
    // split 可能返回为一个数组
    if (isArray(transformData)) {
      transformData.forEach(item => {
        this.handleUpdatePreviewData(item);
      });
    }
  }
}
