import React, { useContext, useEffect } from "react";
import { Table } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import style from "./HeaderList.module.css";
import { CustomApiPageBlocContext } from "@uiview/pages/customApi/CustomApiPageBlocContext";
import { useSignal } from "@preact/signals-react";

export interface Header {
  key: string;
  name: string;
  value: string;
  description?: string;
  selected?: boolean;
}

interface HeaderListProps {
  headerList: Header[];
  isResponse?: boolean;
}

const HeaderList: React.FC<HeaderListProps> = props => {
  const rowSelection = useSignal<any>(false);
  const bloc = useContext(CustomApiPageBlocContext);
  const selectedRows = useSignal<string[]>([]);
  const columns = useSignal<any[]>([
    {
      title: "KEY",
      dataIndex: "name",
      width: "30%",
      ellipsis: true,
      render: (value: string, record: Header) => (
        <input
          type="text"
          value={value}
          readOnly={props.isResponse}
          onChange={e => handleInputChange(e, record, "name")}
        />
      ),
    },
    {
      title: "VALUE",
      dataIndex: "value",
      width: "30%",
      ellipsis: true,
      render: (value: string, record: Header) => (
        <input
          type="text"
          value={value}
          readOnly={props.isResponse}
          onChange={e => handleInputChange(e, record, "value")}
        />
      ),
    },
  ]);

  const handleDelete = (key: string) => {
    const newData = [...props.headerList];
    bloc.state.requestData.value = bloc.state.requestData.value.copyWith({
      headerList: newData.filter(item => item.key !== key),
    });
    // props.setHeaderList(newData.filter(item => item.key !== key));
  };

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>, record: Header, keyStr: string) {
    const { key } = record;
    const requestData = bloc.state.requestData.value;
    const newHeaerList = requestData.headerList.map(x => {
      if (x.key === key) {
        let value = e.target.value;
        if (keyStr === "name" && value.toLowerCase() === "content-type") {
          value = "Content-Type";
        }
        return {
          ...x,
          [keyStr]: value,
        };
      }
      return x;
    });
    bloc.state.requestData.value = bloc.state.requestData.value.copyWith({ headerList: newHeaerList });
  }

  function handleSelectChange(keys: string[]) {
    const requestData = bloc.state.requestData.value;
    const newHeaderList = requestData.headerList.map(x => {
      if (keys.includes(x.key)) {
        return {
          ...x,
          selected: true,
        };
      }
      return {
        ...x,
        selected: false,
      };
    });
    bloc.state.requestData.value = requestData.copyWith({ headerList: newHeaderList });
  }

  function clickHeaderAddBtn() {
    const requestData = bloc.state.requestData.value;
    const newHeaderList = [
      ...requestData.headerList,
      {
        key: `${requestData.headerList.length - 1}_manualAdd`,
        name: "",
        value: "",
        description: "",
        selected: true,
      },
    ];
    bloc.state.requestData.value = requestData.copyWith({ headerList: newHeaderList });
  }

  const Footer = () => {
    return !props.isResponse ? (
      <div className={style["footer-box"]}>
        <PlusCircleOutlined onClick={clickHeaderAddBtn} />
      </div>
    ) : null;
  };

  useEffect(() => {
    selectedRows.value = props.headerList.filter(x => x.selected).map(x => x.key);

    if (!props.isResponse) {
      const otherColumns = [
        {
          title: "DESCRIPTION",
          dataIndex: "description",
          width: "30%",
          ellipsis: true,
          render: (value: string, record: Header) => (
            <input type="text" value={value} onChange={e => handleInputChange(e, record, "description")} />
          ),
        },
        {
          title: "",
          dataIndex: "operations",
          width: "50px",
          render: (_: any, record: Header) => <DeleteOutlined onClick={() => handleDelete(record.key)} />,
        },
      ];

      const filteredPrevList = columns.value.filter((x: any) => !otherColumns.some(y => y.dataIndex === x.dataIndex));
      columns.value = [...filteredPrevList, ...otherColumns];
    }
  }, [props.headerList, props.isResponse]);

  useEffect(() => {
    if (props.isResponse) {
      rowSelection.value = false;
      return;
    }
    rowSelection.value = {
      selectedRowKeys: selectedRows.value,
      onChange: handleSelectChange,
    };
  }, [selectedRows.value, props.isResponse]);

  return (
    <Table
      className={style["header-table"]}
      columns={columns.value}
      dataSource={props.headerList}
      rowSelection={rowSelection.value}
      pagination={false}
      footer={Footer}
    />
  );
};

export default HeaderList;
