"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopicListResponse = exports.Topic = exports.TopicDetail = exports.TopicDetailText = exports.SignificanceType = void 0;
var SignificanceType;
(function (SignificanceType) {
    SignificanceType["IMAGE"] = "image";
    SignificanceType["TEXT"] = "text";
})(SignificanceType || (exports.SignificanceType = SignificanceType = {}));
class TopicDetailText {
    constructor() {
        this.topic_detail_title = "";
        this.topic_detail_body = "";
    }
}
exports.TopicDetailText = TopicDetailText;
class TopicDetail {
    constructor() {
        this.type = SignificanceType.TEXT;
        this.value = "";
    }
}
exports.TopicDetail = TopicDetail;
class Topic {
    constructor() {
        this.topic_title = "";
        this.topic_summary = "";
        this.topic_details = [];
    }
}
exports.Topic = Topic;
class TopicListResponse {
    constructor() {
        this.topic_list = [];
    }
}
exports.TopicListResponse = TopicListResponse;
