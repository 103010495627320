import { editorActions } from "@store/editor";
import store from "@store/index";
import { studioActions } from "@store/studio";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { EdgeRunResultParseUtil } from "@uikit/util/EdgeRunResultParseUtil";
import { PreviewStore } from "@uiview/store/PreviewStore";
import { PreviewAppValue } from "@uiview/views/PreviewApp/model/PreviewAppValue";
import { distance } from "@uiview/views/ProjectCanvas/homeConst";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { getInputNodesForNodeLabel } from "@views/thinking-layout-editor/util";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { isEmpty } from "lodash";
import { PreviewAppValueGenUtil } from "@uiview/views/PreviewApp/PreviewAppValueGenUtil";

/**
 * 替代原来 useHomeMethods
 *
 * FIXME: 大量方法保留了箭头函数, 因为有大量方法是通过直接传递方法引用，不适用箭头函数则会丢失 this
 */
export class HomeMethodsService {
  constructor(
    public reduxStore: typeof store,
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public creatorRefStore: CreatorRefStore,
    public homePluginStore: HomePluginStore,
    public creatorAISaasStore: CreatorAISaasStore,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public previewStore: PreviewStore,
    public projectNodeStore: ProjectNodeStore
  ) {}

  setSecondInputNodeId(val: any): void {
    this.reduxStore.dispatch(editorActions.setSecondNodeId(val));
  }

  setSelectedTemplate(val: any): void {
    this.reduxStore.dispatch(studioActions.setSelectedTemplate(val));
  }

  /** 保存第二个input node id*/
  saveSecondInputNodeId(nodeId: any): void {
    const onlyOneInputNode =
      getInputNodesForNodeLabel(this.creatorNodesStore.getNodes(), this.creatorEdgesStore.getEdges()).length === 1;
    const secondInputId = store.getState().editor.secondInputNodeId;
    if (onlyOneInputNode && isEmpty(secondInputId)) {
      this.setSecondInputNodeId(nodeId);
    }
  }

  getUIListParam = (): PreviewAppValue[] => {
    const applicationTheme = store.getState().editor.applicationTheme;
    const fontSize = store.getState().editor.fontSize;

    const sassObj = PreviewAppValueGenUtil.genPreviewApp(this.creatorSaasAppStore.state.saasUIData, {
      theme: this.creatorSaasAppStore.state.saasUIData.theme || (applicationTheme as any),
      fontSize: fontSize,
      v2App: {
        id: "",
      },
    });

    return [sassObj];
  };

  handleSlice = (
    currentNode: any,
    target: string | undefined
  ): {
    sourceArr: any[];
  } => {
    const groupNode = this.creatorNodesStore.getNodes().find(x => x.id === target);
    const targetArr = this.creatorNodesStore.getNodes().filter(n => n.parentNode === target) || [];
    let childNode = groupNode?.data?.childNode || [];
    const targetIds = targetArr.map(x => x.id);

    // 等同于 Array.from([...new Set([...childNode, ...targetIds])];)
    // @ts-expect-error 修复ts报错
    childNode = [...new Set([...childNode, ...targetIds])];
    //获取切分后的文本数组
    // FIXME: 文件不纯粹
    const array = EdgeRunResultParseUtil.splitText(currentNode?.data?.textAreaValue).filter((x: any) => !isEmpty(x));
    //删除全部source，重新添加child node
    const moreChildNode = (targetArr || []).map(x => x.id);
    this.creatorNodesStore.setNodes(prevList => prevList.filter(x => !moreChildNode.includes(x.id)));
    //给child node赋值
    const newNodes: any[] = [];
    const sliceLevel = targetArr[0]?.data?.sliceLevel || 1;
    array.forEach((x: string, index: number) => {
      let newNodeId = "";

      if (!isEmpty(childNode[index])) {
        newNodeId = childNode[index];
      } else {
        newNodeId = `editor-${this.creatorRefStore.nodeIndexRef.current++}`;
        childNode.push(newNodeId);
      }
      const newNode = {
        id: newNodeId,
        type: "customNode",
        parentNode: target,
        extent: "parent",
        data: {
          ...currentNode.data,
          textAreaValue: x.trim(),
          sliceLevel,
          isSliceNode: true,
          parentNodeId: target,
        },
        position: {
          x: distance,
          y: distance * (index + 1) + index * 200,
        },
      };

      newNodes.push(newNode);
    });
    this.creatorNodesStore.setNodes(prevList => [...prevList, ...newNodes]);
    const source = [...targetArr.slice(0, array.length), ...newNodes];

    this.creatorNodesStore.setNodes(prevList => {
      return prevList.map(pre => {
        if (pre.id === target) {
          return {
            ...pre,
            // ...obj,
            data: {
              ...pre.data,
              childNode,
            },
          };
        }
        return pre;
      });
    });
    return {
      sourceArr: source,
    };
  };

  emptyPreviewApp = (byUnpublish = false): void => {
    this.creatorAISaasStore.setShowSaasOutputList(true);
    // bsf-4912 setDisplayThought
    // setDisplayThought([]);
    this.creatorAISaasStore.setInputId([]);
    this.previewStore.setSelectedUI("");
    // stud-1735 default saasvalue
    // stud-2493 set input(preview)(clear)
    this.creatorSaasAppStore.emptySaasUIData();
    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);

    if (byUnpublish) return;
    // 清空 v2 数据
    const selectedTemplate = store.getState().studio.selectedTemplate;
    const data = Object.assign({}, selectedTemplate, { v2: {} });
    this.setSelectedTemplate(data);
    // eslint-disable-next-line
  };

  emptyNodes = (): void => {
    this.creatorNodesStore.setNodes([]);
    this.creatorEdgesStore.setEdges([]);
    // eslint-disable-next-line
  };

  judgePlaceHolderDisplay(newNodes: any[], newEdges: any[]): void {
    if (newNodes.length <= 0) return;
    const sourceNodes = [] as any[];
    const targetNodes = [] as any[];
    const sourceTargetNodes = [] as any[];
    const noSourceTargetNodes = [] as any[];

    newNodes.forEach((x: any) => {
      let isSourceNode = newEdges.some((y: any) => y.source === x.id);
      let isTargetNode = newEdges.some((y: any) => y.target === x.id);
      if (x.parentNode) {
        isSourceNode = false;
        isTargetNode = true;
      }
      if (isSourceNode && !isTargetNode) {
        sourceNodes.push(x);
      } else if (!isSourceNode && isTargetNode) {
        targetNodes.push(x);
      } else if (isSourceNode && isTargetNode) {
        sourceTargetNodes.push(x);
      } else if (!isSourceNode && !isTargetNode) {
        noSourceTargetNodes.push(x);
      }
    });
    // 修改placeholder
    this.creatorNodesStore.setNodes(prevList => {
      return prevList.map(l => {
        const isSourceNodes = sourceNodes.some(x => x.id === l.id);
        const isTargetNodes = targetNodes.some(x => x.id === l.id);
        const isSourceTargetNodes = sourceTargetNodes.some(x => x.id === l.id);
        const isNonSourceTargetNodes = noSourceTargetNodes.some(x => x.id === l.id);
        // cot过来的input需要显示自己的placeholder
        if (isSourceNodes || isNonSourceTargetNodes) {
          return {
            ...l,
            data: {
              ...l.data,
              placeholder: l.data.placeholder || "Enter input",
            },
          };
        }
        if (isTargetNodes) {
          return {
            ...l,
            data: {
              ...l.data,
              placeholder: l.data.description,
            },
          };
        }
        if (isSourceTargetNodes) {
          return {
            ...l,
            data: {
              ...l.data,
              placeholder: "Output used as input to the right ",
            },
          };
        }
        return l;
      });
    });
  }
  // eslint-disable-next-line
  getSaasAction = () => {
    return {
      type: "saas",
      resetData: this.creatorSaasAppStore.setSaasUIData,
      oldData: this.creatorSaasAppStore.state.saasUIData,
    };
  };

  setNodeEdgeIndex(nodeIdx?: number, edgeIdx?: number): void {
    this.creatorRefStore.nodeIndexRef.current = nodeIdx ?? 1;
    this.creatorRefStore.edgeIndexRef.current = edgeIdx ?? 0;
  }
}
