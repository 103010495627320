import { Button, Spin } from "antd";
import { map } from "lodash";
import { Constants } from "../../Constants";
import { Sortable } from "../Sortable/Sortable";
import { ComponentKey, GenUIBloc } from "../../GenUIBloc";
import { PrototypingSortableComponent } from "../../ChatBloc";
import styles from "./Outputs.module.scss";
import classNames from "classnames";
import { ProtypingComponent } from "../../types";

type OutputsProps = {
  bloc: GenUIBloc;
};

export function Outputs(props: OutputsProps) {
  const loading = props.bloc.prototypingBloc.state.loading.value;

  const handleChange = (items: PrototypingSortableComponent[]) => {
    props.bloc.prototypingBloc.sortableComponents.value = items;
  };

  const selectedComponentID = props.bloc.state.selectedComponent.value?.id;

  return (
    <Spin spinning={loading}>
      {loading === true && (
        <div className={styles.outputWrapper}>
          {map(props.bloc.state.outputs.value, (item, index) => (
            <div key={index} className={styles.outputBox}>
              <p className={styles.outputTitle}>{item.description}</p>
              <img alt="" key={index} src={Constants.componentConfig[item.key]} />
            </div>
          ))}
        </div>
      )}
      {loading === false && (
        <div className={styles.outputWrapper}>
          <Sortable
            value={props.bloc.prototypingBloc.sortableComponents.value}
            onChange={handleChange}
            renderItem={(value, index) => {
              const name = value.name.replace(/<|>/g, "") as ComponentKey;
              return (
                <div
                  key={index}
                  className={classNames(styles.outputBox, selectedComponentID === value.id && styles.selected)}
                  onClick={() => {
                    props.bloc.handleSelected(value);
                  }}
                >
                  <p className={styles.outputTitle}>{value.description}</p>
                  <img alt="" key={`${name}${index}`} src={Constants.componentConfig[name]} />
                </div>
              );
            }}
          />
        </div>
      )}
    </Spin>
  );
}
