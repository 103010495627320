import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class CreatorIdeaListState {
  ideaList: any[] = [];
  ideaLoading = false;
  showIdeaList = false;
  shrinkIdeaList = true;

  copyWith(param: Partial<Omit<CreatorIdeaListState, "copyWith">>): CreatorIdeaListState {
    const newState = new CreatorIdeaListState();
    newState.ideaList = param.ideaList ?? this.ideaList;
    newState.ideaLoading = param.ideaLoading ?? this.ideaLoading;
    newState.showIdeaList = param.showIdeaList ?? this.showIdeaList;
    newState.shrinkIdeaList = param.shrinkIdeaList ?? this.shrinkIdeaList;
    return newState;
  }
}

class CreateIdeaListStore extends StoreBase<CreatorIdeaListState> {
  constructor() {
    super(() => new CreatorIdeaListState());
  }

  setIdeaList(ideaList: any[]): void {
    this.emit(this.state.copyWith({ ideaList }));
  }

  setIdeaLoading(ideaLoading: boolean): void {
    this.emit(this.state.copyWith({ ideaLoading }));
  }

  setShowIdeaList(showIdeaList: boolean): void {
    this.emit(this.state.copyWith({ showIdeaList }));
  }

  setShrinkIdeaList(shrinkIdeaList: boolean): void {
    this.emit(this.state.copyWith({ shrinkIdeaList }));
  }
}

export const createIdeaListStore = new CreateIdeaListStore();
