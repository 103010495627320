import { ProjectTemplateType } from "imagica-corekit/dist/cases/webconfig/domain/ProjectTemplateType";

export function mapNewTemplatesById(
  templates: ProjectTemplateType[],
  project_id: ProjectTemplateType["project_id"],
  value?: Partial<Omit<ProjectTemplateType, "project_id">>
) {
  return templates.map(tmp => {
    if (project_id === tmp.project_id) {
      return {
        ...tmp,
        ...value,
      };
    }
    return tmp;
  });
}
