"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatInterfaceFunction = void 0;
const ErrorUtil_1 = require("../../api/ErrorUtil");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
const StoryNodeDisplayType_1 = require("../domain/StoryNodeDisplayType");
const FunctionBase_1 = require("./FunctionBase");
const AddDataSourceFunctionUtil_1 = require("../../storyV2/function/AddDataSourceFunctionUtil");
const Extensions_1 = require("../../cutil/Extensions");
class ChatInterfaceFunction extends FunctionBase_1.FunctionBase {
    constructor(brainClient, authStore, meStore, socketRestClient) {
        super(brainClient, authStore, socketRestClient);
        this.brainClient = brainClient;
        this.authStore = authStore;
        this.meStore = meStore;
        this.socketRestClient = socketRestClient;
    }
    async run(input, identityInfo, variableList = [], page = 1, next = undefined, pagination = undefined, requestParams = {}, checkAbort, sourceNodeType = StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT, queries = [], blueprintJSON = "", graphData) {
        const type = sourceNodeType;
        let data = requestParams;
        let uuid = undefined;
        const pubsub_topic = await this.socketRestClient.topicService.generateTopic(identityInfo, "chatInterface");
        //  file不存在 说明文件上传失败了
        if (AddDataSourceFunctionUtil_1.AddDataSourceFunctionUtil.isFile(sourceNodeType) && (0, Extensions_1.isBlank)(requestParams.file)) {
            throw new Error(ErrorUtil_1.ErrorUtil.defaultErrorMsg);
        }
        // 三种情况的值都为空，参数存在问题
        if ((0, Extensions_1.isBlank)(requestParams.file) && (0, Extensions_1.isBlank)(requestParams.json) && (0, Extensions_1.isBlank)(requestParams.text)) {
            console.error("requestParams: ", requestParams);
            throw new Error(ErrorUtil_1.ErrorUtil.defaultErrorMsg);
        }
        await this.indexer({
            data,
            pubsub_topic,
            mode: "chat",
            uuid,
        }, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo));
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.CHAT_INTERFACE, {
            file: requestParams.file,
            filename: requestParams.filename,
            pubsub_topic,
            uuid,
            date: +new Date(),
            json: requestParams.json,
            text: requestParams.text,
        });
    }
    // async run(input: string, identityInfo?: FuncRunIdentityInfo, ...arg: any): Promise<FuncResponse> {
    //   const type = arg[arg.length - 1];
    //   let data: any = {
    //     file: input,
    //     loader: "PDFReader",
    //   };
    //   let uuid: string | undefined = undefined;
    //
    //   if (this.isText(type)) {
    //     /**
    //      * text node 把文本内容先按text文件类型上传 再处理后续
    //      * 这里filename只用于chat interface中显示
    //      */
    //     filename = "the context";
    //     const blob = new Blob([input], { type: "text/plain" });
    //     const formData = new FormData();
    //     formData.append("file", blob, `text_${+new Date()}.txt`);
    //     const res = await this.upload(formData);
    //     data.file = res?.data?.object_key || null;
    //     uuid = res?.data?.uuid;
    //   } else if (ChatInterfaceFunctionUtil.isFile(type)) {
    //     const result = ChatInterfaceFunctionUtil.getFileData(input, type);
    //     filename = result.filename;
    //     uuid = result.uuid;
    //     data.file = result.file;
    //   } else {
    //     const json = tryBlock(() => JSON.parse(input)).data;
    //     data = {
    //       json,
    //       loader: "PDFReader",
    //       query: json?.query || "",
    //     };
    //   }
    //
    //   const pubsub_topic = await this.topicService.generateTopic(identityInfo, "chatInterface");
    //
    //   //  file不存在 说明文件上传失败了
    //   if (ChatInterfaceFunctionUtil.isFile(type) && ) {
    //     throw new Error(ErrorUtil.defaultErrorMsg);
    //   }
    //
    //   await this.indexer(
    //     {
    //       data,
    //       pubsub_topic,
    //       mode: "chat",
    //       uuid,
    //     },
    //     FunctionUtil.createAbortUuid(identityInfo)
    //   );
    //
    //   return new FuncResponse(FuncResponseType.CHAT_INTERFACE, {
    //     file: data.file || data.json,
    //     filename,
    //     pubsub_topic,
    //     uuid,
    //     date: +new Date(),
    //   });
    // }
    async indexer(data, uuid) {
        const result = await this.brainClient.openAi.indexers(data, uuid);
        return result;
    }
}
exports.ChatInterfaceFunction = ChatInterfaceFunction;
