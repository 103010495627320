"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JiraAPI = void 0;
const Settings_1 = require("../kernel/Settings");
const JIRA_DOMAIN_PATH = Settings_1.settings.JiraAPIDomain;
const PROJECT_ID = Settings_1.settings.JiraAPIProjectID;
const ISSUETYPE_ID = Settings_1.settings.JiraAPIIssueTypeID;
class JiraAPI {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    genCreateIssueRequestByUsername(args) {
        return {
            title: `Imagica Unsupported Node Feedback - ${args.username}`,
            description: args.description,
            token: args.token,
            userToken: args.userToken,
        };
    }
    async fetchCreateIssue(request) {
        const url = "https://api-dev.braininc.net/be/function/proxy";
        const data = {
            url: `${JIRA_DOMAIN_PATH}/rest/api/2/issue`,
            method: "POST",
            body: {
                fields: {
                    summary: request.title,
                    project: {
                        // "id": "10017" // WEB
                        id: PROJECT_ID,
                    },
                    issuetype: {
                        // "id": "10028" // WEB
                        id: ISSUETYPE_ID,
                    },
                    description: request.description,
                },
                transition: {
                    id: "51",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: request.token,
            },
        };
        //       if(!util.isEmpty(obj.labels) && this.isSelectRDI) {
        //         data.body.fields.labels = obj.labels
        //       }
        // return data;
        const requestHeader = {
            "Content-Type": "application/json",
            Authorization: `token ${request.userToken}`,
        };
        const result = await this.httpRequest.post(url, data, undefined, requestHeader);
        return result;
        // return result.parseError((res) => {
        //   return "An error has occurred";
        // });
    }
}
exports.JiraAPI = JiraAPI;
