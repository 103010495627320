export function BIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 4H9.81894C10.704 4 11.4322 4.08403 12.0036 4.2521C12.5862 4.42017 13.0455 4.64986 13.3817 4.94118C13.7178 5.23249 13.9474 5.57423 14.0707 5.96639C14.2051 6.35854 14.2723 6.78431 14.2723 7.2437C14.2723 7.71429 14.1715 8.15686 13.9698 8.57143C13.7682 8.98599 13.3873 9.33894 12.8271 9.63025C13.1856 9.82073 13.4769 10.0224 13.701 10.2353C13.9362 10.437 14.1155 10.6555 14.2387 10.8908C14.3732 11.1261 14.4628 11.3669 14.5076 11.6134C14.5636 11.8599 14.5916 12.1064 14.5916 12.3529C14.5916 12.8571 14.5132 13.3333 14.3564 13.7815C14.2107 14.2297 13.9586 14.6162 13.6001 14.9412C13.2528 15.2661 12.7935 15.5238 12.2221 15.7143C11.6507 15.9048 10.9449 16 10.1046 16H5.5V4ZM10.1046 8.7563C10.7656 8.7563 11.2194 8.63305 11.4659 8.38655C11.7123 8.14006 11.8356 7.78712 11.8356 7.32773C11.8356 6.83473 11.6731 6.4874 11.3482 6.28571C11.0345 6.07283 10.4968 5.96639 9.73492 5.96639H7.91995V8.7563H10.1046ZM10.0206 14.0336C10.4351 14.0336 10.7768 13.9944 11.0457 13.916C11.3258 13.8375 11.5499 13.7255 11.7179 13.5798C11.886 13.423 12.0036 13.2437 12.0708 13.042C12.1381 12.8403 12.1717 12.6106 12.1717 12.3529C12.1717 11.8375 12.0316 11.4454 11.7515 11.1765C11.4715 10.9076 10.9785 10.7731 10.2727 10.7731H7.91995V14.0336H10.0206Z"
        fill="white"
      />
    </svg>
  );
}

export function UIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.08222 2H5.5741V9C5.5741 10.7596 5.95659 12.0328 6.72159 12.8197C7.49751 13.6066 8.59035 14 10.0001 14C10.6886 14 11.3061 13.9071 11.8525 13.7213C12.4098 13.5355 12.8743 13.2404 13.2459 12.8361C13.6284 12.4317 13.918 11.9235 14.1147 11.3115C14.3223 10.6885 14.4262 9.93989 14.4262 9.06557V2H12.918V9.06557C12.918 10.2678 12.6995 11.1749 12.2623 11.7869C11.8252 12.388 11.0766 12.6885 10.0165 12.6885C8.96738 12.6885 8.21332 12.388 7.75432 11.7869C7.30626 11.1858 7.08222 10.2787 7.08222 9.06557V2ZM15 16H5V17.5H15V16Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export function DIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="bin">
        <path
          id="Icon"
          d="M13 5V4.2C13 3.07989 13 2.51984 12.782 2.09202C12.5903 1.71569 12.2843 1.40973 11.908 1.21799C11.4802 1 10.9201 1 9.8 1H10.2C9.07989 1 8.51984 1 8.09202 1.21799C7.71569 1.40973 7.40973 1.71569 7.21799 2.09202C7 2.51984 7 3.07989 7 4.2V5M2 5H18M16 5V14.2C16 15.8802 16 16.7202 15.673 17.362C15.3854 17.9265 14.9265 18.3854 14.362 18.673C13.7202 19 12.8802 19 11.2 19H8.8C7.11984 19 6.27976 19 5.63803 18.673C5.07354 18.3854 4.6146 17.9265 4.32698 17.362C4 16.7202 4 15.8802 4 14.2V5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function IIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 16L10.0376 4H12.071L9.53341 16H7.5Z" fill="white" />
    </svg>
  );
}

export function EditPlaceholderIcon(): JSX.Element {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="pen edit">
        <path
          id="Rectangle 32"
          d="M4.5 13H3.1C2.53995 13 2.25992 13 2.04601 12.891C1.85785 12.7951 1.70487 12.6422 1.60899 12.454C1.5 12.2401 1.5 11.9601 1.5 11.4V8.6C1.5 8.03995 1.5 7.75992 1.60899 7.54601C1.70487 7.35785 1.85785 7.20487 2.04601 7.10899C2.25992 7 2.53995 7 3.1 7H4.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Rectangle 32_2"
          d="M10.5 13H17.9C18.4601 13 18.7401 13 18.954 12.891C19.1422 12.7951 19.2951 12.6422 19.391 12.454C19.5 12.2401 19.5 11.9601 19.5 11.4V8.6C19.5 8.03995 19.5 7.75992 19.391 7.54601C19.2951 7.35785 19.1422 7.20487 18.954 7.10899C18.7401 7 18.4601 7 17.9 7H10.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 76"
          d="M7.5 4V16"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 77"
          d="M5.5 4H9.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 77_2"
          d="M5.5 16H9.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function BoldIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 4H9.81894C10.704 4 11.4322 4.08403 12.0036 4.2521C12.5862 4.42017 13.0455 4.64986 13.3817 4.94118C13.7178 5.23249 13.9474 5.57423 14.0707 5.96639C14.2051 6.35854 14.2723 6.78431 14.2723 7.2437C14.2723 7.71429 14.1715 8.15686 13.9698 8.57143C13.7682 8.98599 13.3873 9.33894 12.8271 9.63025C13.1856 9.82073 13.4769 10.0224 13.701 10.2353C13.9362 10.437 14.1155 10.6555 14.2387 10.8908C14.3732 11.1261 14.4628 11.3669 14.5076 11.6134C14.5636 11.8599 14.5916 12.1064 14.5916 12.3529C14.5916 12.8571 14.5132 13.3333 14.3564 13.7815C14.2107 14.2297 13.9586 14.6162 13.6001 14.9412C13.2528 15.2661 12.7935 15.5238 12.2221 15.7143C11.6507 15.9048 10.9449 16 10.1046 16H5.5V4ZM10.1046 8.7563C10.7656 8.7563 11.2194 8.63305 11.4659 8.38655C11.7123 8.14006 11.8356 7.78712 11.8356 7.32773C11.8356 6.83473 11.6731 6.4874 11.3482 6.28571C11.0345 6.07283 10.4968 5.96639 9.73492 5.96639H7.91995V8.7563H10.1046ZM10.0206 14.0336C10.4351 14.0336 10.7768 13.9944 11.0457 13.916C11.3258 13.8375 11.5499 13.7255 11.7179 13.5798C11.886 13.423 12.0036 13.2437 12.0708 13.042C12.1381 12.8403 12.1717 12.6106 12.1717 12.3529C12.1717 11.8375 12.0316 11.4454 11.7515 11.1765C11.4715 10.9076 10.9785 10.7731 10.2727 10.7731H7.91995V14.0336H10.0206Z"
        fill="white"
      />
    </svg>
  );
}

export function ItalicIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 16L10.0376 4H12.071L9.53341 16H7.5Z" fill="#fff" />
    </svg>
  );
}

export function UnderlineIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08222 2H5.5741V9C5.5741 10.7596 5.95659 12.0328 6.72159 12.8197C7.49751 13.6066 8.59035 14 10.0001 14C10.6886 14 11.3061 13.9071 11.8525 13.7213C12.4098 13.5355 12.8743 13.2404 13.2459 12.8361C13.6284 12.4317 13.918 11.9235 14.1147 11.3115C14.3223 10.6885 14.4262 9.93989 14.4262 9.06557V2H12.918V9.06557C12.918 10.2678 12.6995 11.1749 12.2623 11.7869C11.8252 12.388 11.0766 12.6885 10.0165 12.6885C8.96738 12.6885 8.21332 12.388 7.75432 11.7869C7.30626 11.1858 7.08222 10.2787 7.08222 9.06557V2ZM15 16H5V17.5H15V16Z"
        fill="#fff"
      />
    </svg>
  );
}

export function CaptionIcon(): JSX.Element {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 8H19.5" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M15.5 8V17" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1.5 3H13.5" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.5 3V17" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
