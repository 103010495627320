import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./setting.module.scss";
import { ThemeSelect, ThemeProps } from "./ThemeSelect/ThemeSelect";
import { useSignal } from "@preact/signals-react";
import { Button, Modal } from "antd";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { getIt } from "@uikit/getIt";
import { useDispatch } from "react-redux";
import func from "@uikit/func";
import { studioActions } from "@store/studio";
import { previewStore } from "@uiview/store/PreviewStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

const gql = getIt(ObjectRelationGqlService);
const CLOSE_ICON = "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ACAEB5Cross.svg";
const TITLE = "Are you sure you want to Unpublish?";
const SUBTITLE = "This will delete this publish format from your project. You can not undo this action.";

type Props = {
  inOrOutSetting: (bol: boolean) => void; // 返回preview
  backPublishPage: () => void; // 返回publish页面
} & ThemeProps;

const Setting: React.FC<Props> = props => {
  const brainClient = getIt(BrainClient);
  const showModal = useSignal(false);
  const unpublishLoading = useSignal(false);
  const dispatch = useDispatch();

  const setSelectedTemplate = (val: any): void => {
    dispatch(studioActions.setSelectedTemplate(val));
  };

  const previewAppStore = getIt(PreviewAppStore);

  // sutd-1722: published_metadata
  const published_metadata = previewAppStore.getPublishMetadata();
  const disabledUnPublish = func.isEmpty(published_metadata?.link);

  // const disabledUnPublish = util.isEmpty(selectedTemplate?.published_metadata?.link);

  async function handleUnpublishV2(): Promise<void> {
    try {
      unpublishLoading.value = true;
      const selectedTemplate = previewAppStore.getSelectedTemplate();
      if (selectedTemplate === undefined) {
        throw Error("can not find app");
      }
      if (!func.isEmpty(published_metadata?.published_at)) {
        const linkMateData = selectedTemplate.published_metadata_v2?.find(item => {
          return item.id === previewStore.state.previewAppAcivieId;
        });
        if (linkMateData?.link) {
          const linkUrl = new URL(linkMateData?.link.replace("#/", ""));
          const query = linkUrl.searchParams.get("q") ?? "";
          await brainClient.user.unpublish(query);
        }
      }
      const newTemplate = previewAppStore.clearPublishMetadata();
      await gql.updateObject(parseInt(selectedTemplate.id), {
        name: "studio_project",
        attributes: newTemplate,
      });
      setSelectedTemplate(newTemplate);
      showModal.value = false;
      props.backPublishPage();
    } catch (error) {
      func.messageError(error);
    } finally {
      unpublishLoading.value = false;
    }
  }

  function showUnpublishModal(): void {
    if (disabledUnPublish) return;
    showModal.value = true;
  }

  function handleOk(): void {
    handleUnpublishV2();
  }

  function handleCancel(): void {
    if (unpublishLoading.value) return;
    showModal.value = false;
  }

  return (
    <>
      <div className={styles.settingPage}>
        {/* Header */}
        <div className={styles.header}>
          <div
            onClick={() => {
              props.inOrOutSetting(false);
            }}
          >
            <img
              className={styles.backButton}
              src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Left.svg`}
              alt="icon"
            />
          </div>
          <div className={styles.title}>Publish Settings</div>
        </div>

        {/* Theme Select */}
        <ThemeSelect themes={props.themes} saasTheme={props.saasTheme} setSaasTheme={props.setSaasTheme} />

        {/* Danger Zone */}
        <div className={styles.dangerZone}>
          <div className={styles.dangerZoneTitle}>Danger Zone</div>
          <div className={styles.dangerZoneDesc}>
            Permanently remove this publish view. You cannot undo this action.
          </div>
          <div
            data-testid="Preview-Settings-Unpublish"
            className={`${styles.dangerZoneButton} ${disabledUnPublish ? styles.disabledUnPublish : ""}`}
            onClick={() => showUnpublishModal()}
          >
            Unpublish
          </div>
        </div>
      </div>
      {/* Unpublish Confirm Modal */}
      {showModal.value && (
        <div className={styles.modalRoot}>
          <Modal
            open={showModal.value}
            wrapClassName={styles.modalWrap}
            width="400px"
            centered={true}
            closeIcon={<img src={CLOSE_ICON} width="20" height="20" alt="close" />}
            footer={null}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <ExclamationCircleOutlined className={styles.modalIcon} />
            <span className={styles.modalTitle}>{TITLE}</span>
            <p className={styles.modalSubtitle}>{SUBTITLE}</p>
            <div className={styles.modalFooter}>
              <Button
                data-testid="Preview-Settings-Unpublish-Cancel"
                shape="round"
                size="large"
                type="default"
                className={styles.modalButtonNormal}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                data-testid="Preview-Settings-Unpublish-Confirm"
                shape="round"
                size="large"
                className={styles.modalButtonDanger}
                loading={unpublishLoading.value}
                onClick={handleOk}
              >
                Unpublish
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Setting;
