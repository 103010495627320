import { Node } from "reactflow";
import { DisplayType } from "../Nodes/typing";
import { NodePreviewContentResult } from "./model/NodePreviewContentResult";
import { NodePreviewStock } from "./model/NodePreviewStock";
import { NodePreviewHtml, NodePreviewHtmlCode } from "./model/NodePreviewHtml";
// FIXME: store 应该从方法传入
import store from "@store/index";
import { get } from "lodash";
import isBlank from "@sedan-utils/is-blank";
import func from "@uikit/func";
import { AYLWAYS_SHOW_OUTPUT, WITHOUT_INPUT_ID } from "../AISaas/AddToContent/conts";
import { PreviewAppValueMergeUtil } from "./PreviewAppValueMergeUtil";
import { PreviewAppValueGenUtil } from "./PreviewAppValueGenUtil";
import { PreviewAppValueLangUtil } from "./PreviewAppValueLangUtil";
import { NodeTitleUtil } from "../Nodes/NodeTitleUtil";

export class PreviewContentUtil {
  /**
   * from `HomeUsePlugin` getContentByParam
   *
   * 不在对外部提供，使用 `getPreviewContentByNode`
   *
   * @param type
   * @param value
   * @returns
   */
  static getContentByParam<T extends DisplayType>(
    type: T,
    value: PreviewApp.PreviewValueType
  ): NodePreviewContentResult {
    let content;
    let contentType = "" as PreviewApp.PreviewContentType;
    switch (type) {
      case "stockInfo":
        return new NodePreviewContentResult(
          new NodePreviewStock(
            value?.logo || "",
            value?.name || "-",
            value?.symbol || "-",
            value?.price?.replace("USD", "$") || "-",
            value?.businessSummary || "-",
            value?.volume || "",
            value
          ),
          "stock"
        );
      case "video":
        content = value;
        contentType = "video";
        break;
      case "jdShopping":
        content = value;
        contentType = "jdShopping";
        break;
      case "amazonShopping":
        content = value;
        contentType = "amazonShopping";
        break;
      case "weeeShopping":
        content = value;
        contentType = "weeeShopping";
        break;
      case "showFoodInterface":
        content = value;
        contentType = "showFoodInterface";
        break;
      case "imageGen":
      case "midjourney":
      case "midjourneyV2":
      case "imageSearch":
        content = value;
        contentType = "image";
        break;
      case "htmlTemplate":
        return new NodePreviewContentResult(new NodePreviewHtml([new NodePreviewHtmlCode(value, true)]), "code");
      case "customJsFunction":
        content = value;
        contentType = "customJsFunction";
        break;
      case "customApiFunction":
        content = value;
        contentType = "customApiFunction";
        break;
      case "chatBox":
        content = value;
        contentType = "chatBox";
        break;
      case "map":
        content = value;
        contentType = "map";
        break;
      case "askBrain":
        content = value;
        contentType = "askBrain";
        break;
      case "baseVideo":
        content = value;
        contentType = "baseVideo";
        break;
      case "pdfFile":
        content = value;
        contentType = "pdfFile";
        break;
      case "audio":
        content = value;
        contentType = "audio";
        break;
      case "table":
        content = value;
        contentType = "table";
        break;
      case "uploadImage":
        content = value;
        contentType = "uploadImage";
        break;
      case "unStructured":
        content = value;
        contentType = "unStructured";
        break;
      case "chatInterface":
        content = value;
        contentType = "chatInterface";
        break;
      case "uploadFile":
        content = value;
        contentType = type;
        break;
      case "html":
        content = value;
        contentType = type;
        break;
      case "interviewBot":
        content = value;
        contentType = type;
        break;
      case "json":
        content = value;
        contentType = "textbox";
        break;
      default:
        content = value;
        contentType = "textbox";
    }
    return {
      content,
      contentType,
    };
  }

  /**
   * 用指定输出和节点获取 preview 预览数据
   * - from `HomeUsePlugin` getPreviewData
   * - level 用来控制优先级，如果 level 为 single, 则表示子节点优先，那么返回结果不会有 groupId
   *
   * @param type
   * @param value
   * @returns
   */
  static getPreviewData({
    output,
    node,
    level,
    isShare,
  }: {
    output: AISaasOutput.ThoughtType[];
    node: Node<any>;
    /**
     * 优先级，目前只支持 子节点优先
     */
    level?: "single";
    isShare: boolean;
  }): {
    prevData: AISaasOutput.ThoughtType[];
    result: AISaasOutput.ThoughtType;
  } {
    const editorImageScrollDireaction = (store.getState().fot as any).editorImageScrollDireaction as
      | "horizontalScroll"
      | "verticallyScroll";

    // FIXME: 子节点优先，去掉 parentNode, 为了保证组节点和 组的子节点能够同时在 output 中
    const groupId = level === "single" ? "" : get(node, "parentNode", "");

    const result: AISaasOutput.ThoughtType = {
      angle: "",
      implication: "",
      modifier: "",
      reason: "",
      groupId: groupId,
      results: [],
      grid: false,
    };
    const _previewId = `${groupId}**${node.id}`;
    /**
     * outputFontStyle： 从priview数据获取到设置字体相关数据，并赋值到canvas 数据中，避免后续canvas数据和preview数据合并时字体设置丢失
     */
    let outputFontStyle: any = {};
    if (isShare && output[0]?.results[0]?.nodeFontStyle) {
      outputFontStyle = output[0].results[0].nodeFontStyle;
    }
    const resObj: AISaasOutput.ThoughtResults = {
      previewId: _previewId,
      parentNode: groupId,
      nodeId: node.id,
      fontSize: "Normal",
      fontStyleKey: output[0]?.results[0]?.fontStyleKey,
      layout: "textBeautify",
      angles: [],
      content: null as any,
      dislikes: [],
      filters: [],
      likes: [],
      reactions: [],
      thought: "",
      type: "",
      blank: {
        title: NodeTitleUtil.getNodeTitle(node),
      },
      nodeFontStyle: { ...outputFontStyle },
      viewBuilderData: {
        domList: [],
      },
    };
    const type = node.data.displayType;
    const domList = get(node, "data.viewBuilderData.domList", []);
    if (type === "text" && !isBlank(domList)) {
      resObj.viewBuilderData = Object.assign(
        {},
        {
          domList,
        }
      );
    }

    const { content, contentType } = PreviewContentUtil.getPreviewContentByNode(node);
    resObj.content = content;
    resObj.type = contentType;
    if (output[0]?.results[0]?.isCaptionToggle !== undefined) {
      resObj.isCaptionToggle = output[0].results[0].isCaptionToggle;
    }

    // FIXME: 未来版本是否应该去掉？
    if (
      editorImageScrollDireaction === "horizontalScroll" &&
      (contentType === "image" || (contentType === "customApiFunction" && content?.data?.type === "image"))
    ) {
      const imageIndex = output.findIndex(x => x.angle === "Image List");
      if (imageIndex !== -1) {
        const image = output[imageIndex];
        output.splice(imageIndex, 1, {
          ...image,
          results: image.results.concat([resObj]),
        });
        // return [...output]
        return {
          prevData: output,
          result: {} as AISaasOutput.ThoughtType,
        };
      } else {
        result.angle = "Image List";
      }
    }
    result.results.push(resObj);
    // return [...output, result]
    return {
      prevData: output,
      result,
    };
  }

  /**
   * 用 node 获取preview 的content 内容，包装原来 getContentByParam 逻辑
   * @param node
   */
  static getPreviewContentByNode(node: Node): NodePreviewContentResult {
    const type = node.data.displayType;
    let value = node.data.textAreaValue;

    if (type === "chatBox") {
      value = {
        textAreaValue: node?.data?.textAreaValue || [],
        chatBoxParam: node?.data?.chatBoxParam || [],
        nodeId: node?.id || "",
      };
    }

    return PreviewContentUtil.getContentByParam(type, value);
  }

  /**
   * 兼容首页 原来的 `addGroupNodeToPreview` 核心方法
   *
   * 用来获取当 groupNodes 的 previewList
   *
   * **内部有对返回结果去重，但是该去重范围仅限于传入的 output 作为所有最终的 saasuidata.output, 也就是如果传入的 output 并非 saasuidata.output 或者只是部分 output, 那么在调用该方法的最后需要手动去重**
   * @param param0
   * @returns
   */
  static getPreviewOutputByGroupNode({
    groupChildeNodes,
    addContentId,
    output,
    getResult,
    isShare,
  }: {
    groupChildeNodes: Node[];
    output: AISaasOutput.ThoughtType[];
    getResult: boolean;
    addContentId: string;
    isShare: boolean;
  }): AISaasOutput.ThoughtType[] {
    let resultList = [] as AISaasOutput.ThoughtType[];
    let tempPrevData = [] as any[];
    groupChildeNodes.forEach(node => {
      const { prevData, result } = PreviewContentUtil.getPreviewData({
        output: output,
        node,
        isShare,
      });
      tempPrevData = prevData;
      resultList = func.isEmpty(result) ? resultList : [...resultList, result];
    });
    // mobile-cot 生成的cot 根据边上文字预判模拟输出组 此时组为空 但是也必须加入到output里
    if (groupChildeNodes.length === 0) {
      const groupId = output[0].groupId;
      resultList.push(PreviewAppValueGenUtil.createGroupOutput(groupId));
    }
    if (getResult) {
      return resultList;
    }
    let list = [] as AISaasOutput.ThoughtType[];
    let stopNextIsGroup = false;
    if (addContentId && addContentId !== WITHOUT_INPUT_ID && addContentId !== AYLWAYS_SHOW_OUTPUT) {
      tempPrevData.forEach(item => {
        if ((item.groupId === addContentId && !stopNextIsGroup) || item.results[0].previewId === addContentId) {
          if (item.groupId === addContentId) stopNextIsGroup = true;
          list.push(...resultList);
          list.push(item);
        } else {
          list.push(item);
        }
      });
    } else {
      list = PreviewAppValueMergeUtil.unionOutput([...tempPrevData, ...resultList]);
    }

    return list;
  }

  /**
   * 兼容首页 原来的 `addSingleNodeToPreview` 核心方法
   *
   * 用来获取当node的 previewList（因为某些当个 node 可能会产生多个 preview）
   *
   * **内部有对返回结果去重，但是该去重范围仅限于传入的 output 作为所有最终的 saasuidata.output, 也就是如果传入的 output 并非 saasuidata.output 或者只是部分 output, 那么在调用该方法的最后需要手动去重**
   *
   * @param param0
   * @returns
   */
  static getPreviewOutputBySingleNode({
    saasUIData,
    output,
    addContentId,
    node,
    isShare,
  }: {
    saasUIData: PreviewApp.UISaasValueType;
    output: AISaasOutput.ThoughtType[];
    addContentId: string;
    node: Node;
    isShare: boolean;
  }): AISaasOutput.ThoughtType[] {
    const { prevData, result } = PreviewContentUtil.getPreviewData({
      output: output,
      node,
      // !!! getPreviewOutputBySingleNode 一定是子节点优先，不能将 groupId 在 result 中返回
      // 这样在调用 getPreviewOutputBySingleNode 时一定保证没有 groupId 的值，从源头和组区分
      level: "single",
      isShare,
    });
    // stud-1832 chat
    if (PreviewAppValueLangUtil.isChatAiApp(saasUIData)) {
      return [result];
    }

    let list = [] as AISaasOutput.ThoughtType[];
    let stopNextIsGroup = false;
    if (addContentId && addContentId !== WITHOUT_INPUT_ID && addContentId !== AYLWAYS_SHOW_OUTPUT) {
      prevData.forEach(item => {
        if ((item.groupId === addContentId && !stopNextIsGroup) || item.results[0].previewId === addContentId) {
          if (item.groupId === addContentId) stopNextIsGroup = true;
          list.push(result);
          list.push(item);
        } else {
          list.push(item);
        }
      });
    } else {
      list = PreviewAppValueMergeUtil.unionOutput([...prevData, result]);
    }
    return list;
  }
}
