import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { Transform } from "reactflow";

export class BackgroundValue {
  transform: Transform = [0, 0, 0];
  patternId = "";
}

export class FotReactFlowBackgroundStore extends StoreBase<BackgroundValue> {
  constructor() {
    super(() => new BackgroundValue());
  }

  changeBackground(background: BackgroundValue): void {
    const value = JsonUtil.toModelFromType(BackgroundValue, background);
    value && this.emit(value);
  }
}

export const fotReactFlowBackgroundStore = new FotReactFlowBackgroundStore();
