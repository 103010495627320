import { useMemo } from "react";
import { CotBloc } from "./CotBloc";

export type CurrentStatus = "normal" | "creating" | "completeCreating";

export const useCurrentStatus = (bloc: CotBloc): { currentStatus: CurrentStatus } => {
  const creating = bloc.state.creating.value;
  const completeCreating = bloc.state.completeCreating.value;

  const currentStatus = useMemo(() => {
    if (completeCreating === true) {
      return "completeCreating";
    }
    if (creating === true) {
      return "creating";
    }
    return "normal";
  }, [creating, completeCreating]);

  return {
    currentStatus,
  };
};
