import { map } from "lodash";
import Agent from "./Agent/AgentNode";
import { ChatBox } from "./ChatBox";
import { Food } from "imagica-uikit/dist/nodesV2/Food/FoodNode";
import { Image } from "./Image";
import { Stickie } from "imagica-uikit/dist/nodesV2/Stickie/StickieNode";
import { BluePrintNode } from "./BluePrintNode";
import { UploadFile } from "imagica-uikit/dist/nodesV2/UploadFile/UploadFile";
import { Video } from "imagica-uikit/dist/nodesV2/Video/VideoNode";
import { Map } from "imagica-uikit/dist/nodesV2/Map/MapView";
import { AskBrain } from "imagica-uikit/dist/nodesV2/AskBrain/AskBrain";
import { AmazonShopping } from "imagica-uikit/dist/nodesV2/Shopping/AmazonShopping";
import { JdShopping } from "imagica-uikit/dist/nodesV2/Shopping/JdShopping";
import { WeeeShopping } from "imagica-uikit/dist/nodesV2/Shopping/WeeeShopping";
import { DisplayType, DisplayTypeArray, FunctionType, InitialReportSize, NodeConfig } from "./typing";
import { CustomJsFunction } from "imagica-uikit/dist/nodesV2/CustomJsFunction/CustomJsFunction";
import { BaseVideo } from "imagica-uikit/dist/nodesV2/baseVideo/BaseVideo";
import { UnStructured } from "imagica-uikit/dist/nodesV2/unStructured/UnStructured";
import { Text } from "imagica-uikit/dist/nodesV2/Text/TextNode";
import { Audio } from "imagica-uikit/dist/nodesV2/Audio/Audio";
import { ChatInterface } from "imagica-uikit/dist/nodesV2/ChatInterface/ChatInterface";
import { Html } from "imagica-uikit/dist/nodesV2/Html/Html";
import { InterviewBot } from "imagica-uikit/dist/nodesV2/InterviewBot/InterviewBot";
import { Json } from "imagica-uikit/dist/nodesV2/Json/JsonNode";
import { Stock } from "imagica-uikit/dist/nodesV2/Stock/Stock";
import { List } from "./List";
import { NodeConfigUtil } from "./NodeConfigUtil";
import { XlsxTableView } from "imagica-uikit/dist/nodesV2/XlsxTableView/XlsxTableView";
import { PDFPreview } from "imagica-uikit/dist/nodesV2/PDFPreview/PDFPreview";
import { StockWithShareBuy } from "./StockWithShareBuy/StockWithShareBuy";
import { Code } from "./Code/Code";

/// 兼容老的逻辑，不推荐使用;
/// 推荐使用：NodeConfigUtil.getInitSize / NodeConfigUtil.getNodeLayoutByType
export const initialReportSizeMapping: Record<DisplayType, InitialReportSize> = {
  amazonShopping: NodeConfigUtil.getInitSize("amazonShopping"),
  jdShopping: NodeConfigUtil.getInitSize("jdShopping"),
  weeeShopping: NodeConfigUtil.getInitSize("weeeShopping"),
  imageGen: NodeConfigUtil.getInitSize("imageGen"),
  midjourney: NodeConfigUtil.getInitSize("midjourney"),
  midjourneyV2: NodeConfigUtil.getInitSize("midjourneyV2"),
  imageSearch: NodeConfigUtil.getInitSize("imageSearch"),
  Agent: NodeConfigUtil.getInitSize("Agent"),
  uploadFile: NodeConfigUtil.getInitSize("uploadFile"),
  text: NodeConfigUtil.getInitSize("text"),
  video: NodeConfigUtil.getInitSize("video"),
  customJsFunction: NodeConfigUtil.getInitSize("customJsFunction"),
  stockInfo: NodeConfigUtil.getInitSize("stockInfo"),
  stock: NodeConfigUtil.getInitSize("stockInfo"),
  htmlTemplate: NodeConfigUtil.getInitSize("htmlTemplate"),
  customApiFunction: NodeConfigUtil.getInitSize("customApiFunction"),
  chatBox: NodeConfigUtil.getInitSize("chatBox"),
  inputTextarea: NodeConfigUtil.getInitSize("inputTextarea"),
  showFoodInterface: NodeConfigUtil.getInitSize("showFoodInterface"),
  map: NodeConfigUtil.getInitSize("map"),
  blueprint: NodeConfigUtil.getInitSize("blueprint"),
  json: NodeConfigUtil.getInitSize("json"),
  askBrain: NodeConfigUtil.getInitSize("askBrain"),
  baseVideo: NodeConfigUtil.getInitSize("baseVideo"),
  audio: NodeConfigUtil.getInitSize("audio"),
  unStructured: NodeConfigUtil.getInitSize("unStructured"),
  table: NodeConfigUtil.getInitSize("table"),
  pdfFile: NodeConfigUtil.getInitSize("pdfFile"),
  uploadImage: NodeConfigUtil.getInitSize("uploadImage"),
  chatInterface: NodeConfigUtil.getInitSize("chatInterface"),
  html: NodeConfigUtil.getInitSize("html"),
  interviewBot: NodeConfigUtil.getInitSize("interviewBot"),
  list: NodeConfigUtil.getInitSize("list"),
};

export type ListPermission = (DisplayType | FunctionType)[] | ["ALL"];
export type BlackAndWhiteListPermission = {
  whiteList: ListPermission;
  blackList: ListPermission;
};

/**
 * To configure what need to show in command bar
 */
export const groupNodePermission: BlackAndWhiteListPermission = {
  whiteList: [],
  blackList: ["splitOutput"],
};
export const commandFunctionsConfig: Record<DisplayType, BlackAndWhiteListPermission> = {
  text: {
    whiteList: ["ALL"],
    blackList: [],
  },
  Agent: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  video: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  jdShopping: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  customJsFunction: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  stockInfo: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  htmlTemplate: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  customApiFunction: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  amazonShopping: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  weeeShopping: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  chatBox: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  inputTextarea: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  imageGen: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  midjourney: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  midjourneyV2: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  imageSearch: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  showFoodInterface: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  askBrain: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  map: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
  chatInterface: {
    whiteList: [],
    blackList: ["ALL"],
  },
  uploadFile: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  uploadImage: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  baseVideo: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  unStructured: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  table: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  pdfFile: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  audio: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  html: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  blueprint: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  json: {
    whiteList: ["ALL"],
    blackList: [],
  },
  interviewBot: {
    whiteList: [],
    blackList: [],
  },
  list: {
    whiteList: ["chatInterface"],
    blackList: [],
  },
  stock: {
    whiteList: [],
    blackList: ["chatInterface"],
  },
};

export const nodeConfig: NodeConfig[] = [
  {
    displayType: "jdShopping",
    disableFeatureTag: "",
    component: JdShopping,
    displayLabel: true,
  },
  {
    displayType: "amazonShopping",
    disableFeatureTag: "",
    component: AmazonShopping,
    displayLabel: true,
  },
  {
    displayType: "weeeShopping",
    disableFeatureTag: "",
    component: WeeeShopping,
    displayLabel: true,
  },
  {
    displayType: "Agent",
    disableFeatureTag: "disable_studios_agent",
    component: Agent,
    displayLabel: true,
  },
  {
    displayType: "video",
    disableFeatureTag: "",
    component: Video,
    displayLabel: true,
  },
  {
    displayType: "text",
    disableFeatureTag: "",
    component: Text,
    displayLabel: false,
  },
  {
    displayType: "imageGen",
    disableFeatureTag: "",
    component: Image,
    displayLabel: false,
  },
  {
    displayType: "midjourney",
    disableFeatureTag: "",
    component: Image,
    displayLabel: false,
  },
  {
    displayType: "midjourneyV2",
    disableFeatureTag: "disable_studios_midjourney_v2",
    component: Image,
    displayLabel: false,
  },
  {
    displayType: "imageSearch",
    disableFeatureTag: "",
    component: Image,
    displayLabel: false,
  },
  {
    displayType: "inputTextarea",
    disableFeatureTag: "",
    component: Stickie,
    displayLabel: false,
  },
  {
    displayType: "uploadFile",
    disableFeatureTag: "",
    component: UploadFile,
    displayLabel: false,
  },
  {
    displayType: "chatBox",
    disableFeatureTag: "",
    component: ChatBox,
    displayLabel: true,
  },
  {
    displayType: "stockInfo",
    disableFeatureTag: "",
    component: StockWithShareBuy,
    displayLabel: false,
  },
  {
    displayType: "stock",
    disableFeatureTag: "",
    component: Stock,
    displayLabel: false,
  },
  {
    displayType: "htmlTemplate",
    disableFeatureTag: "",
    component: Code,
    displayLabel: false,
  },
  {
    displayType: "showFoodInterface",
    disableFeatureTag: "disable_food",
    component: Food,
    displayLabel: false,
  },
  {
    displayType: "map",
    disableFeatureTag: "disable_studios_map",
    component: Map,
    displayLabel: true,
  },
  {
    displayType: "customApiFunction",
    disableFeatureTag: "",
    component: Text,
    displayLabel: true,
  },
  {
    displayType: "customJsFunction",
    disableFeatureTag: "",
    component: CustomJsFunction,
    displayLabel: false,
  },
  {
    displayType: "askBrain",
    disableFeatureTag: "",
    component: AskBrain,
    displayLabel: false,
  },
  {
    displayType: "baseVideo",
    disableFeatureTag: "",
    component: BaseVideo,
    displayLabel: false,
  },
  {
    displayType: "audio",
    disableFeatureTag: "",
    component: Audio,
    displayLabel: false,
  },
  {
    displayType: "unStructured",
    disableFeatureTag: "",
    component: UnStructured,
    displayLabel: false,
  },
  {
    displayType: "table",
    disableFeatureTag: "",
    component: XlsxTableView,
    displayLabel: false,
  },
  {
    displayType: "pdfFile",
    disableFeatureTag: "",
    component: PDFPreview,
    displayLabel: false,
  },
  {
    displayType: "uploadImage",
    disableFeatureTag: "",
    component: Image,
    displayLabel: false,
  },
  {
    displayType: "chatInterface",
    disableFeatureTag: "",
    component: ChatInterface,
    displayLabel: false,
  },
  {
    displayType: "html",
    disableFeatureTag: "",
    component: Html,
    displayLabel: false,
  },
  {
    displayType: "blueprint",
    disableFeatureTag: "",
    component: BluePrintNode,
    displayLabel: false,
  },
  {
    displayType: "interviewBot",
    disableFeatureTag: "disable_interview_bot",
    component: InterviewBot,
    displayLabel: false,
  },
  {
    displayType: "json",
    disableFeatureTag: "",
    component: Json,
    displayLabel: false,
  },
  {
    displayType: "list",
    disableFeatureTag: "",
    component: List,
    displayLabel: false,
  },
];

export const nodeTypeOptions: Array<{ value: DisplayType; label: string }> = [
  {
    value: "text",
    label: "Text",
  },
  {
    value: "Agent",
    label: "Agent",
  },
  {
    value: "htmlTemplate",
    label: "HTML Template",
  },
  {
    value: "imageGen",
    label: "Image",
  },
  {
    value: "stockInfo",
    label: "Stock info",
  },
  {
    value: "video",
    label: "Video",
  },
  {
    value: "jdShopping",
    label: "Jd Shopping",
  },
  {
    value: "amazonShopping",
    label: "Amazon Shopping",
  },
  {
    value: "weeeShopping",
    label: "Weee Groceries",
  },
  {
    value: "customJsFunction",
    label: "Custom JS Function",
  },
  {
    value: "customApiFunction",
    label: "Custom API Function",
  },
  {
    value: "inputTextarea",
    label: "Stickie",
  },
  {
    label: "Show Food Interface",
    value: "showFoodInterface",
  },
  {
    label: "Base Video",
    value: "baseVideo",
  },
  {
    label: "table",
    value: "table",
  },
  {
    label: "PDF File",
    value: "pdfFile",
  },
  {
    label: "Audio",
    value: "audio",
  },
  {
    label: "UploadImage",
    value: "uploadImage",
  },
  {
    label: "ChatInterface",
    value: "chatInterface",
  },
  {
    label: "Html",
    value: "html",
  },
  {
    label: "Blue Print",
    value: "blueprint",
  },
  {
    label: "Json",
    value: "json",
  },
  {
    label: "InterviewBot",
    value: "interviewBot",
  },
  {
    label: "List",
    value: "list",
  },
];

export const displayTypeArray: DisplayTypeArray = map(nodeConfig, item => item.displayType);

export const shoppingTypes = ["jdshopping", "weeeShopping", "amazonShopping"];

export const disableEdgesTypeArray: Array<DisplayType> = [
  // "video",
  // "jdShopping",
  // "amazonShopping",
  // "weeeShopping",
  // "map",
  // "showFoodInterface",
  "chatInterface",
  "Agent",
  "interviewBot",
];
// TODO: 该文件中未使用其他依赖的配置应该移动到 CreatorNodesConts 中
