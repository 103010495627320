import React, { useEffect, useRef } from "react";
import { useSignal } from "@preact/signals-react";
import style from "./CodeInput.module.scss";

export function CodeInput(props: {
  verifyCode: string;
  cleanInput: boolean;
  onChange: (verifyNum: string) => void;
  onComplete: (verifyNum: string) => void;
}) {
  /**
   * verifyCode：验证码
   * onChange：验证码改变的回调
   * onComplete：验证码输入完成后的回调
   */
  // const { verifyCode = "", onChange, onComplete } = props;
  // const [codeArray, setCodeArray] = useState([]);
  // const [isFocus, setIsFocus] = useState(false);
  const codeArray = useSignal<string[]>([]); // 用来存放验证码的数组
  const isFocus = useSignal<boolean>(false); // 判断是否获取焦点

  const inputList = [...Array(6)].map((item, index) => index); // 生成模拟输入框数组[0,1,2,3,4,5]

  const inputRef = useRef(null); // 绑定input输入框
  useEffect(() => {
    // setCodeArray([])
    codeArray.value = [];
  }, [codeArray, props.cleanInput]);
  // 获取焦点事件
  const handleInputFocus = () => {
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement).focus(); // 为输入框聚焦
    }
    // setIsFocus(true);
    isFocus.value = true;
  };

  // 获取验证码
  useEffect(() => {
    // setCodeArray(verifyCode.split(""));
    codeArray.value = props.verifyCode.split("");
  }, [codeArray, props.verifyCode]);

  // 当验证码6位时，触发完成事件，校验验证码
  useEffect(() => {
    if (props.verifyCode.length !== 6) return; // 验证码不足6位，不触发校验事件
    props.onComplete && props.onComplete(props.verifyCode);
  }, [props.verifyCode, props.onComplete, props]);

  // 验证码改变事件
  const handleChange = (e: any) => {
    if (e.target.value) {
      // 获取当前输入的数字
      let val = e.target.value.replace(/[^\d]/g, ""); // 只保留数字
      props.onChange?.(val);
    } else {
      props.onChange?.("");
    }
  };

  // 失去焦点
  const handleBlur = () => {
    // setIsFocus(false);
    isFocus.value = false;
  };

  // 获取焦点
  const handleFocus = () => {
    // setIsFocus(true);
    isFocus.value = true;
  };

  // 默认聚焦input输入框，每次进入都执行
  useEffect(() => {
    handleInputFocus();
  });

  return (
    <div className={style.codeInputBox} onClick={handleInputFocus}>
      {/* 验证码数字显示部分 */}
      <div className={style.numberBox}>
        {inputList.map((item, index) => {
          return (
            <div
              className={`${style.inputItem} ${
                item === codeArray.value.length && isFocus.value ? style.showInputCursor : ""
              }`}
              key={index}
            >
              {codeArray.value[item]}
            </div>
          );
        })}
      </div>
      {/* 输入框，用样式隐藏不显示 */}
      <input
        type="number"
        inputMode="numeric" // 可以弹起数字键盘
        maxLength={6}
        className={style.inputValue}
        value={props.verifyCode}
        ref={inputRef}
        onChange={handleChange} // 验证码改变事件
        onBlur={handleBlur} // 失去焦点事件
        onFocus={handleFocus} // 聚焦事件
      />
    </div>
  );
}
