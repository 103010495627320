export function QRCodeIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4181_98090)">
        <path
          d="M7.91797 5.41406V7.91406H5.41797V5.41406H7.91797ZM9.16797 4.16406H4.16797V9.16406H9.16797V4.16406ZM7.91797 12.0807V14.5807H5.41797V12.0807H7.91797ZM9.16797 10.8307H4.16797V15.8307H9.16797V10.8307ZM14.5846 5.41406V7.91406H12.0846V5.41406H14.5846ZM15.8346 4.16406H10.8346V9.16406H15.8346V4.16406ZM10.8346 10.8307H12.0846V12.0807H10.8346V10.8307ZM12.0846 12.0807H13.3346V13.3307H12.0846V12.0807ZM13.3346 10.8307H14.5846V12.0807H13.3346V10.8307ZM10.8346 13.3307H12.0846V14.5807H10.8346V13.3307ZM12.0846 14.5807H13.3346V15.8307H12.0846V14.5807ZM13.3346 13.3307H14.5846V14.5807H13.3346V13.3307ZM14.5846 12.0807H15.8346V13.3307H14.5846V12.0807ZM14.5846 14.5807H15.8346V15.8307H14.5846V14.5807ZM18.3346 5.83073H16.668V3.33073H14.168V1.66406H18.3346V5.83073ZM18.3346 18.3307V14.1641H16.668V16.6641H14.168V18.3307H18.3346ZM1.66797 18.3307H5.83464V16.6641H3.33464V14.1641H1.66797V18.3307ZM1.66797 1.66406V5.83073H3.33464V3.33073H5.83464V1.66406H1.66797Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4181_98090">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
