import React, { useRef, useState } from "react";
import func from "@uikit/func";
import { Popconfirm } from "antd";
import { motion } from "framer-motion";
import style from "../views-css/BuildLayerView.module.css";
import AppView from "./components/AppView";
import LogicLayer from "./components/LogicLayer";
import LayerHomeSearch from "./components/LayerHomeSearch";
import { DragDropContext } from "react-beautiful-dnd";
import { getIt } from "@uikit/getIt";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";

export default function BuildLayerView(props) {
  const postUrlCanAbortService = getIt(PostUrlCanAbortService);

  const bottomVariants = {
    init: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
        type: "tween",
        ease: "easeInOut",
      },
    },
  };
  const [exampleOutput, setExampleOutput] = useState("");
  const [htmlStr, setHtmlStr] = useState("");
  const [explanationStr, setExplanationStr] = useState("");
  const [appViewTextArea, setAppViewTextArea] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [appViewLoading, setAppViewLoading] = useState(false);
  const [showLayerView, setShowLayerView] = useState(false);
  const [htmlResLoading, setHtmlResLoading] = useState(false);

  const apiDescription = useRef("");

  const getAppViewData = async () => {
    const startStr =
      "The system generates one example result of what the API returns. And the parameters of the API with an explanation of each parameter.";
    const apiDescriptionParam = `\n\nAPI: ${apiDescription.current}`;
    const appViewTextAreaParam = `\nInput: ${appViewTextArea}`;
    const endStr = `\nExample Result:`;
    const data = {
      api: "completions",
      engine: "text-davinci-003",
      frequency_penalty: 0,
      max_tokens: 270,
      top_p: 0.7,
      presence_penalty: 0,
      stop: [],
      prompt: `${startStr}${apiDescriptionParam}${appViewTextAreaParam}${endStr}`,
      temperature: 0,
    };
    try {
      setHtmlResLoading(true);
      setAppViewLoading(true);
      const res = await postUrlCanAbortService.postData("/be/bas-demo-v4/nlp/brain_openai", data);
      const str = res?.data?.choices?.[0]?.text || "";
      setExampleOutput(str);
      setAppViewLoading(false);
      return str;
    } catch (error) {
      if (error.name !== "AbortError") {
        setHtmlResLoading(false);
        setAppViewLoading(false);
      }
      return Promise.reject(error);
    }
  };
  const getHtmlTemplate = async str => {
    const prompt = `For a given example API result and explanation of each parameter, the system generates the HTML and inline CSS code for the best layout and explains detailed reasons for the design.

Example Result:${str}

HTML with inline CSS: `;
    const data = {
      api: "completions",
      engine: "text-davinci-003",
      frequency_penalty: 0,
      max_tokens: 917,
      top_p: 0.7,
      presence_penalty: 0,
      stop: [],
      prompt: prompt,
      temperature: 0.7,
    };
    try {
      setHtmlResLoading(true);
      const res = await postUrlCanAbortService.postData("/be/bas-demo-v4/nlp/brain_openai", data);
      const str = res?.data?.choices?.[0]?.text || "";
      const reg = /\n\n\w+:/g;
      const arr = str.split(reg);
      const replaceStr = reg.exec(str)?.[0] || "";

      setHtmlStr(arr?.[0] || "");
      setExplanationStr(`${replaceStr}${arr?.[1] || ""}`);
      setHtmlResLoading(false);
    } catch (error) {
      if (error.name === "AbortError") return;
      setHtmlResLoading(false);
      return Promise.reject(error);
    }
  };
  const handleDrop = droppedItem => {
    if (droppedItem.destination.droppableId !== "app-view") return;
    apiDescription.current = droppedItem.draggableId || "";
    if (func.isEmpty(appViewTextArea)) {
      func.customMsg({
        content: "The app view input field cannot be empty",
        type: "warning",
      });
      return;
    }
    handleDragged();
  };
  const handleDragged = async () => {
    if (func.isEmpty(apiDescription.current)) {
      func.customMsg({
        content: "You have to drag at least one item to app view block first",
        type: "warning",
      });
      return;
    }
    setHtmlStr("");
    setExplanationStr("");
    setExampleOutput("");
    try {
      const str = await getAppViewData();
      await getHtmlTemplate(str);
    } catch (error) {
      console.error("handleDragged", error);
      func.messageError(error);
    }
  };
  const resetAllData = () => {
    apiDescription.current = "";
    setSearchData([]);
    setAppViewTextArea("");
    setHtmlStr("");
    setExplanationStr("");
    setExampleOutput("");
  };

  return (
    <div className={style["build-layer-view"]}>
      <div className="log-out">
        <Popconfirm
          placement="bottomRight"
          title="Log out"
          onConfirm={() => props.clickLogOut()}
          okText="Yes"
          cancelText="No"
        >
          <a className="iconfont icon-sign_out"></a>
        </Popconfirm>
      </div>
      <LayerHomeSearch
        setSearchData={setSearchData}
        setSearchLoading={setSearchLoading}
        setShowLayerView={setShowLayerView}
        resetAllData={resetAllData}
      />

      {showLayerView ? (
        <motion.div className={style["main-content"]} variants={bottomVariants} initial="init" animate="show">
          <DragDropContext onDragEnd={handleDrop}>
            <LogicLayer searchLoading={searchLoading} searchData={searchData} />

            <AppView
              searchLoading={searchLoading}
              exampleOutput={exampleOutput}
              htmlStr={htmlStr}
              explanationStr={explanationStr}
              appViewLoading={appViewLoading}
              htmlResLoading={htmlResLoading}
              appViewTextArea={appViewTextArea}
              setAppViewTextArea={setAppViewTextArea}
              handleDragged={handleDragged}
            />
          </DragDropContext>
        </motion.div>
      ) : null}
    </div>
  );
}
