import { ProcessBoarding } from "@uikit/fotProcess/ProcessBoarding";
import { ProcessImagicaData } from "@uikit/fotProcess/ProcessImagicaData";
import { ProcessLang } from "@uikit/fotProcess/ProcessLang";
import { ProcessService } from "@uikit/fotProcess/ProcessService";
import { ProcessShareDomain } from "@uikit/fotProcess/ProcessShareDomain";
import { ProcessUserAuth } from "@uikit/fotProcess/ProcessUserAuth";
import { ProjectLoader } from "@uikit/fotProcess/ProjectLoader";
import { ImagicaDep, ImagicaUserDataDep, ImagicaShareDataDep } from "@uikit/fotProcess/modles";
import { SubscriberService } from "@uikit/imagicaApp/SubscriberService";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { FotUserAuthService } from "@uikit/service/FotUserAuthService";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { SharedLinkToGetProject } from "@uikit/service/SharedLinkToGetProject";
import { UiFunctionService } from "@uikit/service/UiFunctionService";
import { CotStore } from "@uikit/store/CotStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { customFunctionStore } from "@uikit/store/CustomFunctionStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { AuthStore } from "imagica-corekit/dist/base/store/AuthStore";
import { BlueprintsStore } from "imagica-corekit/dist/base/store/BlueprintsStore";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { FunctionV2Store } from "imagica-corekit/dist/base/store/FunctionV2Store";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { PreferencesStore } from "imagica-corekit/dist/base/store/PreferencesStore";
import { ProjectStore } from "imagica-corekit/dist/base/store/ProjectStore";
import { ToolPieceStore } from "imagica-corekit/dist/base/store/ToolPieceStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { Container } from "inversify";

export class RegisterFotProcess {
  static configure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };
    const subscriberService = new SubscriberService(
      it(BrainClient),
      it(FotReduxStore),
      it(HomePluginStore),
      it(AuthStore),
      it(FotAuthStore)
    );
    const projectLoader = new ProjectLoader(
      it(FotReduxStore),
      it(ProjectStore),
      previewStore,
      it(CreatorSaasAppStore),
      it(CreatorAISaasStore),
      it(PreviewAppStore),
      it(HomeStore),
      creatorRefStore,
      it(CanvasDataRef),
      homeToolBarStore,
      it(CreatorNodesStore),
      it(CreatorEdgesStore),
      customFunctionStore,
      it(ProjectNodeStore)
    );

    const fotUserAuthService = new FotUserAuthService(
      it(FotReduxStore),
      it(MeStore),
      it(ObjectRelationGqlService),
      it(HomePluginStore),
      it(HomeStore)
    );

    const processLang = new ProcessLang(it(FotAuthStore), it(HomePluginStore), window.sessionStorage);
    const processBoarding = new ProcessBoarding(
      it(FotReduxStore),
      it(FotAuthStore),
      it(PreferencesStore),
      it(HomePluginStore),
      creatorRefStore,
      it(UiFunctionService),
      fotUserAuthService,
      it(HomeStore),
      it(CotStore)
    );
    const processShareDomain = new ProcessShareDomain(settings, it(FotReduxStore), it(HomePluginStore));
    const processUserAuth = new ProcessUserAuth(
      processShareDomain,
      fotUserAuthService,
      it(FotReduxStore),
      it(FotAuthStore),
      it(MeStore),
      it(PreferencesStore),
      it(SubscriptionStore),
      it(PersonalFunctionStore)
    );

    const processImagicaData = new ProcessImagicaData(
      new ImagicaDep(
        it(FotReduxStore),
        it(HomePluginStore),
        it(HomeStore),
        it(FunctionV2Store),
        it(PreferencesStore),
        it(ToolPieceStore),
        it(BlueprintsStore),
        it(ProjectFunctionStore),
        creatorRefStore
      ),
      new ImagicaUserDataDep(
        it(BrainClient),
        it(ObjectRelationGqlService),
        it(NavBarStore),
        projectLoader,
        it(PreviewAppStore),
        it(ChatOnboardingStore)
      ),
      new ImagicaShareDataDep(
        it(FotAuthStore),
        it(CreatorAISaasStore),
        it(ViewbuilderStore),
        projectPageStore,
        it(SharedLinkToGetProject),
        it(WebConfigStore)
      )
    );

    container.bind(SubscriberService).toConstantValue(subscriberService);
    container.bind(FotUserAuthService).toConstantValue(fotUserAuthService);
    container.bind(ProjectLoader).toConstantValue(projectLoader);
    container
      .bind(ProcessService)
      .toConstantValue(
        new ProcessService(
          processLang,
          processBoarding,
          processShareDomain,
          processUserAuth,
          processImagicaData,
          it(FotUserAuthService),
          it(SubscriberService)
        )
      );
  }
}
