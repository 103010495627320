import { useSignal } from "@preact/signals-react";
import func from "@uikit/func";
import { ExecuteBlueprintUtil } from "@uikit/util/ExecuteBlueprintUtil";
import { Blueprint } from "imagica-corekit/dist/base/api/blueprintTyped/BlueprintV2";
import { map } from "lodash";
import { useEffect } from "react";
import { BlueprintDetailPanel } from "./BlueprintDetail/BlueprintDetail";
import { GenerativeFunctionBloc } from "./GenerativeFunctionBloc";
import { UnsupportedBlueprintFlowModal } from "./UnsupportedBlueprintFlowModal/UnsupportedBlueprintFlowModal";
import { BlueprintCommandItem } from "./components/BlueprintCommandItem";
import CommandItem from "./components/CommandItem";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import isBlank from "@sedan-utils/is-blank";
import { Popup } from "./Popup";
import { BadSlotModal } from "./UnsupportedBlueprintFlowModal/BadSlotModal";
import { getIt } from "@uikit/getIt";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";

export const BLUEPRINT = "bluePrint";

export enum CreateAGenerativeFunctionType {
  BLUEPRINT_BUILDER = "blueprintBuilder",
  BLUEPRINT_BUILDER_V2 = "blueprintBuilderV2",
  BLUEPRINT_BUILDER_JUST = "blueprintBuilderJust",
}

type BluePrintProps = {
  // edge id
  id: string;
  aiDescription: string;
  onSelect: (value: any) => void;
  disableShowBlueprintDetail: boolean;
  children: React.ReactNode;
  disableExecute: boolean;
  createAGenerativeFunctionType: CreateAGenerativeFunctionType;
  onClickBlueprintToolPiecesDetail: (name: string | undefined) => void;
  currentBlueprintToolPiecesDetail: string | undefined;
  bloc: GenerativeFunctionBloc;
};

export function CreateAGenerativeFunction(props: BluePrintProps): JSX.Element {
  const canvasDataRef = getIt(CanvasDataRef);
  const unsupportedBlueprintFlowModal = useSignal<null | JSX.Element>(null);

  const bloc = props.bloc;

  const handleClose = (): void => {
    unsupportedBlueprintFlowModal.value = null;
  };

  const executeV2 = (blueprint?: Blueprint): void => {
    ExecuteBlueprintUtil.execute(
      {
        id: props.id,
        aiDescription: props.aiDescription,
        blueprintInfo: blueprint!,
        nodeIndexRef: creatorRefStore.nodeIndexRef,
        edgeIndexRef: creatorRefStore.edgeIndexRef,
        newLineDataRef: canvasDataRef.newLineDataRef,
        nodeDataRef: canvasDataRef.nodeDataRef,
        nodes: getIt(CreatorNodesStore).getNodes(),
        edges: getIt(CreatorEdgesStore).getEdges(),
      },
      props.onSelect
    );
  };

  const handleClickUnsupportedBlueprintFlow = (blueprint?: Blueprint): void => {
    unsupportedBlueprintFlowModal.value = (
      <UnsupportedBlueprintFlowModal
        onOk={() => executeV2(blueprint)}
        onClose={handleClose}
        missing_apis={blueprint?.missing_apis}
      />
    );
  };

  const handleClickExecute = (blueprint?: Blueprint): void => {
    if (props.createAGenerativeFunctionType === CreateAGenerativeFunctionType.BLUEPRINT_BUILDER) {
      props.onSelect({ type: BLUEPRINT, label: props.aiDescription, blueJson: bloc.state.printJson.value });
    }
    if (
      props.createAGenerativeFunctionType === CreateAGenerativeFunctionType.BLUEPRINT_BUILDER_V2 ||
      props.createAGenerativeFunctionType === CreateAGenerativeFunctionType.BLUEPRINT_BUILDER_JUST
    ) {
      if (blueprint?.unsupported === true) {
        if (isBlank(blueprint.missing_apis)) {
          return new Popup(BadSlotModal).open({
            badSlotName: blueprint.bad_slot_name,
          });
        }
        return handleClickUnsupportedBlueprintFlow(blueprint);
      }
      executeV2(blueprint);
    }
  };

  const handleClickBtn: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation();
    bloc.setEnableBlueprintDetailPanel(true);
    if (props.createAGenerativeFunctionType === CreateAGenerativeFunctionType.BLUEPRINT_BUILDER) {
      bloc.onLoadBlue(props.aiDescription, props.id);
    }
    if (props.createAGenerativeFunctionType === CreateAGenerativeFunctionType.BLUEPRINT_BUILDER_V2) {
      bloc.onLoadBlueV2(props.aiDescription, props.id);
    }
    if (props.createAGenerativeFunctionType === CreateAGenerativeFunctionType.BLUEPRINT_BUILDER_JUST) {
      bloc.onLoadBlueJust(props.aiDescription, props.id);
    }
  };

  useEffect(() => {
    if (props.disableShowBlueprintDetail) {
      bloc.setEnableBlueprintDetailPanel(false);
    }
    // eslint-disable-next-line
  }, [props.disableShowBlueprintDetail]);

  return (
    <>
      {unsupportedBlueprintFlowModal.value}
      <BlueprintCommandItem
        loading={bloc.state.loading.value}
        disabled={func.isEmpty(props.aiDescription)}
        onClick={handleClickBtn}
      >
        {props.children}
      </BlueprintCommandItem>
      {bloc.state.blueprint.value && (
        <>
          <CommandItem
            name={bloc.state.blueprint.value?.name}
            description={bloc.state.blueprint.value?.human_readable_description}
            onClick={() => handleClickExecute()}
            enableBlueprintDetailPanel={bloc.state.enableBlueprintDetailPanel.value}
            onClickTriggerBlueprintDetailPanel={() => bloc.setEnableBlueprintDetailPanel(true)}
          />
          {bloc.state.enableBlueprintDetailPanel.value === true && (
            <BlueprintDetailPanel
              isNeedToSave={true}
              value={bloc.state.blueprint.value}
              updateFuncGroup={() => {}}
              edgeId={props.id}
            />
          )}
        </>
      )}
      {map(bloc.state.blueprints.value, blueprint => {
        const name = blueprint.name;
        const isActive = name === props.currentBlueprintToolPiecesDetail;
        return (
          <div key={name}>
            <CommandItem
              name={name}
              description={blueprint.human_readable_description}
              onClick={() => handleClickExecute(blueprint)}
              enableBlueprintDetailPanel={isActive}
              onClickTriggerBlueprintDetailPanel={() => props.onClickBlueprintToolPiecesDetail(name)}
            />
            {isActive === true && (
              <BlueprintDetailPanel
                isNeedToSave={true}
                value={blueprint}
                updateFuncGroup={() => {}}
                edgeId={props.id}
              />
            )}
          </div>
        );
      })}
    </>
  );
}
