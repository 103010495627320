import { PreviewOutputItem } from "../PreviewOutputItem/PreviewOutputItem";
import { PreviewOutputUtil } from "../PreviewOutputItem/PreviewOutputUtil";
import { first, get } from "lodash";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { getIt } from "@uikit/getIt";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import SharesBuy from "@views/components/SharesBuy";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppValueLangUtil } from "../PreviewAppValueLangUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

/**
 * 该组件用于过渡preview 老版本组件和和新版本组件
 *
 * - PreviewOutputItem 专门用来渲染新 preview 的组件
 *
 * @param props
 * @returns
 */
export function PreviewOutputItemRoot(props: { thought: any }) {
  const homeStore = getIt(HomeStore);
  const homePluginStore = getIt(HomePluginStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorNodesStore = getIt(CreatorNodesStore);

  const nodeId = get(props.thought, ["results", "0", "nodeId"]);

  const targetNode = nodeId ? ReactFlowNodeUtil.getNodeById(nodeId, creatorNodesStore.getNodes()) : undefined;

  const getPreviewData = () => {
    if (!targetNode) {
      return;
    }
    const result = PreviewOutputUtil.thoughtToPreviewData(props.thought, targetNode);
    // console.log("jj previewData", targetNode.id, targetNode.data.displayType, result);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const previewData = getPreviewData();

  // render stock buy
  const editorIdArr = useSelector((state: RootState) => state.fot.editorIdArr) as any[];
  const locusEditorId = useSelector((state: RootState) => state.fot.locusEditorId);
  const showStockBuy = editorIdArr.some(x => PreviewOutputUtil.genStockBuyid(x.editorId) === locusEditorId);
  const firstResult = first(get(props.thought, "results")) as any;
  const firstIsStock = firstResult && firstResult.type === "stock";

  const setUserInputPrice = (value: string) => {
    creatorNodesStore.setNodes((prevList: any[]) =>
      prevList.map((item: any) => {
        if (item.id === nodeId) {
          return {
            ...item,
            data: {
              ...item.data,
              userRelatedData: {
                ...item.data.userRelatedData,
                userInputPrice: value,
              },
            },
          };
        }
        return item;
      })
    );
  };

  const renderStockBuy = () => {
    return <SharesBuy filteredThoughts={[]} setUserInputPrice={setUserInputPrice} editorId={firstResult.nodeId} />;
  };

  // 强制保证数据有效性
  if (!nodeId || !targetNode || !previewData) {
    // throw new Error("PreviewOutputItem node is not found");
    console.log("PreviewOutputItem node is not found");
    return null;
  }

  return (
    <PreviewOutputItem
      isStaticApp={PreviewAppValueLangUtil.isStaticApp(creatorSaasAppStore.state.saasUIData)}
      isShare={homePluginStore.state.isShare}
      node={targetNode}
      previewData={previewData}
      featureTags={homeStore.state.featureTags}
      children={firstIsStock && showStockBuy ? renderStockBuy : undefined}
    />
  );

  // return (
  //   <div className={css.wrapper} data-testid="PreviewOutputItemV2">
  //     <div className={css.header}>
  //       {previewData.id} {targetNode.id} {targetNode.data.displayType}
  //     </div>
  //     <div className={css.body}>
  //       <PreviewOutputItem isShare={homePluginStore.state.isShare} node={targetNode} previewData={previewData} />;
  //     </div>
  //   </div>
  // );
}
