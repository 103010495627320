"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YoutubeVideosFunction = void 0;
const Video_1 = require("../../api/videoTyped/Video");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const SearchInfoWrapper_1 = require("../../storyV2/model/SearchInfoWrapper");
const FunctionBase_1 = require("./FunctionBase");
const SearchInfoWrapper_2 = require("../../storyV2/model/SearchInfoWrapper");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
class YoutubeVideosFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo, variableList = [], page = 1, next = undefined, pagination = undefined) {
        const res = await this.brainClient.openAi.getYoutube(input, pagination?.youtube, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo));
        const videos = JsonUtil_1.JsonUtil.toArrayFromType(Video_1.VideoItem, res.data.data?.items ?? [], { excludeExtraneousValues: true });
        const continuation = new SearchInfoWrapper_2.SearchInfoPaginationYoutube(res.data.data?.key ?? "", res.data.data?.continuation ?? "", res.data.data?.context ?? {});
        const newPagination = new SearchInfoWrapper_1.SearchInfoPagination(1, undefined, undefined, continuation);
        const searchInfoWrapper = new SearchInfoWrapper_1.SearchInfoWrapper(input, newPagination, videos || []);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.YOUTUBE_VIDEOS, searchInfoWrapper);
    }
}
exports.YoutubeVideosFunction = YoutubeVideosFunction;
