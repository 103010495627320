import { signal } from "@preact/signals-react";
import { AnyAction, Store } from "@reduxjs/toolkit";
import isBlank from "@sedan-utils/is-blank";
import { getIt } from "@uikit/getIt";
import { PreferencesEntry } from "imagica-corekit/dist/base/api/userTyped/PreferencesEntry";
import { PreferencesStore } from "imagica-corekit/dist/base/store/PreferencesStore";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { useRef } from "react";
import { useStore } from "react-redux";

export class TermsPopupState {
  agreementPromptModel = signal(false);
}
export class TermsPopupBloc {
  state = new TermsPopupState();

  preferenceStore: PreferencesStore = getIt(PreferencesStore);
  webConfigStore: WebConfigStore = getIt(WebConfigStore);

  popuTerms = { version: "", read: false };

  constructor(public store: Store<unknown, AnyAction>) {
    this.beginSubsri();
  }

  beginSubsri(): void {
    this.preferenceStore.on(() => {
      this.updateTermsPopup();
    });
    this.webConfigStore.on(() => {
      this.updateTermsPopup();
    });
  }

  async updateTermsPopup(): Promise<void> {
    const webState = await this.webConfigStore.get();
    const version = webState.imagica_terms_popup?.version ?? "";
    // no popup on shared app TODO: revisit after separating shared app
    const isSharedApp = (this.store.getState() as any).fot.isShare;
    if (isSharedApp) {
      this.state.agreementPromptModel.value = false;
      return;
    }

    // 没有version 不显示
    if (isBlank(version)) {
      this.state.agreementPromptModel.value = false;
      return;
    }
    const termValue = await this.preferenceStore.termsRead(version);

    const isSignup = (this.store.getState() as any).editor.isClickSignup;
    //是注册 不显示 并且设置read 为 true
    if (isSignup) {
      this.popuTerms = { version: "", read: false };
      this.state.agreementPromptModel.value = false;
      if (!termValue) {
        this.preferenceStore.setTermsRead(version, true);
      }
      return;
    }

    // version read 相同不做处理
    if (version === this.popuTerms.version && termValue === this.popuTerms.read) {
      return;
    }
    this.popuTerms = { version: version, read: termValue };
    this.state.agreementPromptModel.value = !termValue;
  }

  // 点击按钮
  clickTermsButton(type: string): void {
    if (type === "confirm") {
      const version = this.webConfigStore.state.imagica_terms_popup?.version ?? "";
      this.preferenceStore.setTermsRead(version, true).then(() => {
        this.popuTerms = { version: "", read: false };
      });
    }
    // 默认点击关闭
    this.state.agreementPromptModel.value = false;
  }
}
