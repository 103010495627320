"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class FTheme {
}
exports.FTheme = FTheme;
FTheme.theme = {
    chatAiTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.chatAiTheme,
        chatBackground: "linear-gradient(180deg, #F3F3F3 0%, #F3F3F3 100%)",
        chatBackgroundPad: "linear-gradient(180deg, #F3F3F3 0%, #F3F3F3 100%)",
        chatBackgroundMobile: "linear-gradient(180deg, #F3F3F3 0%, #F3F3F3 100%)",
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBarBorderColor: "transparent",
        hightLightBorderLeft: "0",
        inputPaddingLeft: "25px",
        inputPadding: "15px 25px",
        inputBorderRadius: "30px",
        inputBackground: "rgba(12, 12, 12, 0.07)",
        buttonBackground: "#000101",
        buttonColor: "#fff",
        toolTipBackground: "rgba(12, 12, 12, 0.07)",
        hintTextColor: "#12192B",
        tooltipBoxColor: "#12192B",
        valueColor: "#12192B",
        seeFormatDescriptionColor: "#12192B",
        toolTipBoxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
    },
};
