"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopicWithRelatedPeopleProps = exports.BiographyType = void 0;
var BiographyType;
(function (BiographyType) {
    BiographyType["IMAGE"] = "image";
    BiographyType["TEXT"] = "text";
})(BiographyType || (exports.BiographyType = BiographyType = {}));
class BiographyDetail {
    constructor() {
        this.type = BiographyType.TEXT;
        this.biography_value = "";
    }
}
class Biography {
    constructor() {
        this.biography_detail = new BiographyDetail();
    }
}
class PersonInfo {
    constructor() {
        this.image = "";
        this.lifespan = "";
        this.name = "";
        this.biography = [];
    }
}
class RelatedPerson {
    constructor() {
        this.related_person = new PersonInfo();
    }
}
class TopicDetail {
    constructor() {
        this.topic_title = "";
        this.topic_summary = "";
    }
}
class Topic {
    constructor() {
        this.topic = new TopicDetail();
    }
}
class TopicWithRelatedPeopleProps {
    constructor() {
        this.topic_info = new Topic();
        this.people_list = [];
    }
}
exports.TopicWithRelatedPeopleProps = TopicWithRelatedPeopleProps;
