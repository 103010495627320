import cls from "classnames";
import { map } from "lodash";
import styles from "./PromptTabBar.module.scss";
import { BlueprintData } from "imagica-corekit/dist/base/api/blueprintTyped/Blueprint";
import { useMemo } from "react";
import usePropsValue from "imagica-uikit/dist/hooks/usePropsValue";

const styleConfig = [
  {
    width: "100%",
    activeWidth: "100%",
    threshold: 20,
    threshold2: 35,
    data: new Array(1).fill({ name: "mockData" }),
  },
  {
    width: "100%",
    activeWidth: "100%",
    threshold: 20,
    threshold2: 35,
    data: new Array(1).fill({ name: "mockData" }),
  },
  {
    width: "35%",
    activeWidth: "70%",
    threshold: 30,
    threshold2: 35,
    data: new Array(2).fill({ name: "mockData" }),
  },
  {
    width: "25%",
    activeWidth: "60%",
    threshold: 20,
    threshold2: 35,
    data: new Array(3).fill({ name: "mockData" }),
  },
  {
    width: "20%",
    activeWidth: "55%",
    threshold: 15,
    threshold2: 35,
    data: new Array(4).fill({ name: "mockData" }),
  },
  {
    width: "20%",
    activeWidth: "40%",
    threshold: 15,
    threshold2: 20,
    data: new Array(5).fill({ name: "mockData" }),
  },
  {
    width: "15%",
    activeWidth: "50%",
    threshold: 10,
    threshold2: 35,
    data: new Array(6).fill({ name: "mockData" }),
  },
];

export type PromptTabBarProps = {
  value?: number;
  onChange?: (value: number) => void;
  data: BlueprintData[] | undefined;
};

export function PromptTabBar({ value, onChange = () => {}, data = [] }: PromptTabBarProps) {
  const splicedData = useMemo(() => {
    const spliced = [...data];
    // Temporary resolution: ignore more than sixth one
    spliced.splice(6);
    return spliced;
  }, [data]);

  const { activeWidth, threshold, width, threshold2 } = styleConfig[splicedData.length];
  const [active = 0, setActive] = usePropsValue<number | undefined>(value);

  const handleClick = (index: number) => {
    setActive(index);
    onChange(index);
  };
  const colorConfig = useMemo(() => {
    const colorConfig = ["#FFBB54", "#FFCA7A", "#FFDAA1", "#FFE9C9", "#FBF3E7", "#FFFDFA"];
    colorConfig.splice(splicedData.length);
    return colorConfig;
  }, []);

  const getCurrentBackground = (index: number, active: number) => {
    if (index === active) {
      return colorConfig[0];
    }
    if (index > active) {
      return colorConfig[index - active];
    }
    if (index < active) {
      return colorConfig[colorConfig.length - (active - index)];
    }
  };

  return (
    <div className={styles.promptBar}>
      {map(splicedData, (item, index) => {
        const isActive = active === index;
        return (
          <div
            onClick={() => handleClick(index)}
            className={cls(isActive && styles.active)}
            style={{
              width: isActive ? activeWidth : width,
              zIndex: 10 - index + 1,
              left: index > active ? `${threshold * index + threshold2}%` : `${threshold * index}%`,
              background: getCurrentBackground(index, active),
            }}
            key={item.name}
          >
            {isActive ? item.name : ""}
          </div>
        );
      })}
    </div>
  );
}
