"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryManager = void 0;
const RestaurantItem_1 = require("../../api/foodTyped/RestaurantItem");
const Grocery_1 = require("../../api/groceriesTyped/Grocery");
const Shopping_1 = require("../../api/shoppingTyped/Shopping");
const Video_1 = require("../../api/videoTyped/Video");
const Empty_1 = require("../../cutil/Empty");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const AdapterUtil_1 = require("../../storyV2/adapter/AdapterUtil");
const Story_1 = require("../../storyV2/domain/Story");
const StoryEdge_1 = require("../../storyV2/domain/StoryEdge");
const StoryNode_1 = require("../../storyV2/domain/StoryNode");
const StoryNodeDisplayType_1 = require("../../storyV2/domain/StoryNodeDisplayType");
const StoryNodeState_1 = require("../../storyV2/domain/StoryNodeState");
const StoryNodeType_1 = require("../../storyV2/domain/StoryNodeType");
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const FunctionRegistry_1 = require("../../storyV2/function/FunctionRegistry");
const SearchInfoWrapper_1 = require("../../storyV2/model/SearchInfoWrapper");
const ArrayUtil_1 = require("../../util/ArrayUtil");
const StoryUtil_1 = require("./StoryUtil");
const StoryEventObject_1 = require("./StoryEventObject");
const LangUtil_1 = require("../../cutil/LangUtil");
const StoryManagerUtil_1 = require("../../storyV2/domain/StoryManagerUtil");
const AdapterErrorUtil_1 = require("../../storyV2/adapter/AdapterErrorUtil");
const ErrorUtil_1 = require("../../api/ErrorUtil");
const FunctionNameUtil_1 = require("../../storyV2/function/FunctionNameUtil");
const Eventbus_1 = require("../../cutil/Eventbus");
const RunCustomFunctionMsg_1 = require("../../msg/RunCustomFunctionMsg");
const RelationSetDest_1 = require("../../api/graphqlTyped/RelationSetDest");
const Meta_1 = require("../../cutil/Meta");
const SubStoryUtil_1 = require("../../storyV2/domain/SubStoryUtil");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
const SubStory_1 = require("../../storyV2/domain/SubStory");
class StoryManager {
    constructor(adapterRegistry, funcRegistry, abortManager, messageCenter, responseDistribution, generatePromptService, analytics) {
        this.adapterRegistry = adapterRegistry;
        this.funcRegistry = funcRegistry;
        this.abortManager = abortManager;
        this.messageCenter = messageCenter;
        this.responseDistribution = responseDistribution;
        this.generatePromptService = generatePromptService;
        this.analytics = analytics;
        this.runningStories = [];
        this.listenCustomFunction();
    }
    listenStoryError(story) {
        story.on(result => {
            result.elements.forEach(value => {
                const edge = StoryEventObject_1.StoryEventObject.asEdge(value);
                if (edge?.state === StoryEdge_1.EdgeState.ERROR) {
                    const targetNode = edge.targetNode();
                    const childrenLength = targetNode?.children.length ?? 0;
                    if (childrenLength > 0) {
                        const errors = targetNode?.children
                            .filter(item => item.state === StoryNodeState_1.StoryNodeState.ERROR)
                            .map(item => item.content);
                        errors?.forEach(errorMsg => {
                            this.logErrorEvent(errorMsg);
                        });
                    }
                    else {
                        const errorMsg = targetNode?.content;
                        this.logErrorEvent(errorMsg ?? "unknown error");
                    }
                }
            });
        });
    }
    logErrorEvent(msg) {
        this.analytics.logEvent("imagica_error_occur", {
            error_message: msg,
            error_detail: msg,
        });
    }
    /// all：true：跑全部路径；false：只跑和输入框有关的
    /// cleanContent：true：跑之前，清空路径上的内容（符合clip的需求）；false：不清空路径上的内容，只更新新的值（符合fot的需求）；
    run(story, all = false, cleanContent = true) {
        this.runningStories.push(story);
        this.listenStoryError(story);
        if (all) {
            return this.runStoryAll(story, cleanContent).then(() => {
                this.runningStories = this.runningStories.filter(s => {
                    return s !== story;
                });
            });
        }
        return this.runStoryInput(story, cleanContent).then(() => {
            this.runningStories = this.runningStories.filter(s => {
                return s !== story;
            });
        });
    }
    runEdge(story, edge) {
        this.runningStories.push(story);
        this.listenStoryError(story);
        return this.handleEdgeRun(edge, story, []).then(() => {
            this.runningStories = this.runningStories.filter(s => {
                return s !== story;
            });
        });
    }
    stop(projectId, edgeId) {
        let find = false;
        this.abortManager.abort({
            filter: uuid => {
                if (uuid.includes(projectId + edgeId)) {
                    find = true;
                    return true;
                }
                return false;
            },
        });
        this.messageCenter.offTopic(`${projectId}_${edgeId}`);
        if (find)
            return;
        this.runningStories.forEach(s => {
            if (s.projectId === projectId) {
                const edge = s
                    .findAllEdges()
                    .filter(e => e.options.id === edgeId)
                    .first();
                if (edge) {
                    this.handleErrorResult(s, edge, new AdapterBase_1.RunResponse(AdapterBase_1.RunResponseType.ERROR, ErrorUtil_1.ErrorUtil.defaultErrorMsg), [], edge.targetNode()?.options.displayType || StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT);
                }
            }
        });
    }
    async loadMore(node) {
        const searchInfoWrapper = node.parseContent((SearchInfoWrapper_1.SearchInfoWrapper));
        if (searchInfoWrapper === undefined) {
            return Promise.reject("searchInfoWrapper undefined");
        }
        const functionName = FunctionNameUtil_1.FunctionNameUtil.parse(node);
        if (!functionName) {
            return Promise.reject("functionName undefined");
        }
        const func = this.funcRegistry.getByRegistName(functionName);
        if (func === undefined) {
            return Promise.reject("func undefined");
        }
        node.state = StoryNodeState_1.StoryNodeState.RECEIVING;
        const runRes = await func.run(searchInfoWrapper.searchKey, undefined, [], searchInfoWrapper.pagination.page + 1, searchInfoWrapper.pagination.next, searchInfoWrapper.pagination);
        switch (runRes.type) {
            case FunctionBase_1.FuncResponseType.AMAZON_SHOPPING:
                return this.handleLoadMore(Shopping_1.ShoppingItem, runRes, node);
            case FunctionBase_1.FuncResponseType.JD_SHOPPING:
                return this.handleLoadMore(Shopping_1.ShoppingItem, runRes, node);
            case FunctionBase_1.FuncResponseType.WEEE_SHOPPING:
                return this.handleLoadMore(Grocery_1.WeeeItem, runRes, node);
            case FunctionBase_1.FuncResponseType.WEEE_GROCERIES:
                return this.handleLoadMore(Grocery_1.WeeeItem, runRes, node);
            case FunctionBase_1.FuncResponseType.YOUTUBE_VIDEOS:
                return this.handleLoadMore(Video_1.VideoItem, runRes, node);
            case FunctionBase_1.FuncResponseType.FOOD:
                return this.handleLoadMore(RestaurantItem_1.RestaurantItem, runRes, node);
            default:
                node.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                return Promise.resolve(new Empty_1.Empty());
        }
    }
    handleLoadMore(modelType, runRes, node) {
        const newSearchInfo = runRes.data;
        const oldSearchInfo = node.parseSearchData(modelType);
        if (oldSearchInfo === undefined) {
            return Promise.reject("oldSearchInfo undefined");
        }
        const currentSearchInfo = new SearchInfoWrapper_1.SearchInfoWrapper(newSearchInfo.searchKey, newSearchInfo.pagination, [
            ...oldSearchInfo,
            ...newSearchInfo.result,
        ]);
        const contentStr = JsonUtil_1.JsonUtil.stringify(currentSearchInfo) ?? "";
        node.received(new StoryNode_1.StoryNodeEventObject(contentStr));
        node.state = StoryNodeState_1.StoryNodeState.COMPLETED;
        return Promise.resolve(new Empty_1.Empty());
    }
    async newSearch(node, newKey) {
        const searchInfoWrapper = node.parseContent((SearchInfoWrapper_1.SearchInfoWrapper));
        if (searchInfoWrapper === undefined) {
            return Promise.reject();
        }
        const functionName = FunctionNameUtil_1.FunctionNameUtil.parse(node);
        if (!functionName) {
            return Promise.reject();
        }
        const func = this.funcRegistry.getByRegistName(functionName);
        if (func === undefined) {
            return Promise.reject();
        }
        node.state = StoryNodeState_1.StoryNodeState.RECEIVING;
        const runRes = await func.run(newKey, undefined, [], 1, undefined);
        switch (runRes.type) {
            case FunctionBase_1.FuncResponseType.AMAZON_SHOPPING:
                return this.handleNewSearch(Shopping_1.ShoppingItem, runRes, node);
            case FunctionBase_1.FuncResponseType.JD_SHOPPING:
                return this.handleNewSearch(Shopping_1.ShoppingItem, runRes, node);
            case FunctionBase_1.FuncResponseType.WEEE_SHOPPING:
                return this.handleNewSearch(Grocery_1.WeeeItem, runRes, node);
            case FunctionBase_1.FuncResponseType.WEEE_GROCERIES:
                return this.handleNewSearch(Grocery_1.WeeeItem, runRes, node);
            case FunctionBase_1.FuncResponseType.YOUTUBE_VIDEOS:
                return this.handleNewSearch(Video_1.VideoItem, runRes, node);
            case FunctionBase_1.FuncResponseType.FOOD:
                return this.handleNewSearch(RestaurantItem_1.RestaurantItem, runRes, node);
            default:
                node.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                return Promise.resolve(new Empty_1.Empty());
        }
    }
    handleNewSearch(modelType, runRes, node) {
        const newSearchInfo = runRes.data;
        const currentSearchInfo = new SearchInfoWrapper_1.SearchInfoWrapper(newSearchInfo.searchKey, newSearchInfo.pagination, newSearchInfo.result);
        const contentStr = JsonUtil_1.JsonUtil.stringify(currentSearchInfo) ?? "";
        node.received(new StoryNode_1.StoryNodeEventObject(contentStr));
        node.state = StoryNodeState_1.StoryNodeState.COMPLETED;
        return Promise.resolve(new Empty_1.Empty());
    }
    async runStoryAll(story, cleanContent) {
        if (cleanContent) {
            story.reset();
        }
        else {
            story.resetState();
        }
        return this.handleStoryRun(story, 0, story.startNodes, false, []);
    }
    /// 只跑跟输入框有关的
    runStoryInput(story, cleanContent) {
        if (cleanContent) {
            /// 清空和输入框有关的边上的节点数据
            story.resetInputGroup();
            const inputRelateNodes = story.findInputRelateNodes();
            story.emit(inputRelateNodes);
        }
        else {
            story.resetStateInputGroup();
        }
        /// 把其它节点设为完成状态，这样UI上能马上显示
        story.completeNotInputGroup();
        /// 把和input无关的边先通知出去
        story.emit(StoryUtil_1.StoryUtil.edgesNoRelateInput(story));
        /// 跑和input有关的组
        return this.handleStoryRun(story, 0, story.startNodes, true, []);
    }
    /// 从story的第一个地点开始跑，完整跑完一个起点后接着找下一个起点，直到所有起点跑完
    async handleStoryRun(story, runIndex, startNodes, justInput, waitEdges) {
        if (runIndex > startNodes.length - 1) {
            if (waitEdges.length !== 0) {
                /// 走到这里，说明起点已经运行完，但仍有几合一的边在等待执行
                /// 那么这些边可能是同一起点的几条边并发造成的等待
                /// 在这里运行这些边
                return this.handleEdgesRun(waitEdges, 0, story).then();
            }
            return Promise.resolve();
        }
        const startNode = startNodes[runIndex];
        if (!justInput || startNode.isInput) {
            startNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
            const currentWaitEdges = await this.runNode(story, startNode, waitEdges);
            const newWaitEdges = await this.handleEdgesRun(currentWaitEdges, 0, story);
            return this.handleStoryRun(story, runIndex + 1, startNodes, justInput, newWaitEdges);
        }
        return this.handleStoryRun(story, runIndex + 1, startNodes, justInput, waitEdges);
    }
    async handleEdgesRun(edges, runIndex, story) {
        if (runIndex >= edges.length) {
            return edges;
        }
        const edge = edges[runIndex];
        /// 查看这条边的数据源是不是多个node
        const sourceNodeForEdge = StoryUtil_1.StoryUtil.findEdgeSoures(story, edge);
        const completionSourceInNodes = sourceNodeForEdge.filter(e => e.state == StoryNodeState_1.StoryNodeState.COMPLETED);
        // const inWaitRelatedEdges = edges.filter(element => {
        //   return element.options.target === edge.options.target && element.options.id !== edge.options.id;
        // });
        if (completionSourceInNodes.length < sourceNodeForEdge.length) {
            return this.handleEdgesRun(edges, runIndex + 1, story);
        }
        const newWait = await this.handleNodeOneEdgeRun(story, edge, edges);
        return this.handleEdgesRun(newWait, 0, story);
    }
    async runNode(story, node, waitEdges) {
        const allEdges = [...node.edges(), ...node.children.flatMap(e => e.edges())];
        const nodeEdgesRuns = allEdges.map(edge => {
            return this.handleNodeOneEdgeRun(story, edge, waitEdges);
        });
        const res = await Promise.all(nodeEdgesRuns);
        let newWaitEdges = [];
        res.forEach(arr => {
            newWaitEdges = [...newWaitEdges, ...arr];
        });
        newWaitEdges = ArrayUtil_1.ArrayUtil.deDuplication(newWaitEdges, item => {
            return item.options.id;
        });
        return newWaitEdges;
    }
    async handleNodeOneEdgeRun(story, edge, waitEdges) {
        /// 查看这条边的数据源是不是多个node
        const sourceNodeForEdge = StoryUtil_1.StoryUtil.findEdgeSoures(story, edge);
        const completionSourceInNodes = sourceNodeForEdge.filter(e => e.state == StoryNodeState_1.StoryNodeState.COMPLETED);
        if (sourceNodeForEdge.length <= 1) {
            /// 无相关的边，直接运行
            await this.handleEdgeRun(edge, story, []);
            const nextNode = edge.targetNode();
            if (nextNode != undefined && nextNode.allEdges()?.length !== 0) {
                const newWaitEdges = await this.runNode(story, nextNode, waitEdges);
                return newWaitEdges;
            }
            return waitEdges;
        }
        else {
            /// 有相关的边，查看其源node是否都已完成
            const relatedEdgesInWait = waitEdges.filter(element => {
                return element.options.target === edge.options.target && element.options.id !== edge.options.id;
            });
            if (completionSourceInNodes.length === sourceNodeForEdge.length) {
                /// 前面的已经运行了，运行当前的
                await this.handleEdgeRun(edge, story, relatedEdgesInWait);
                /// waitEdges移除
                const notRelatedEdgesInWait = waitEdges.filter(element => {
                    return element.options.target !== edge.options.target;
                });
                const nextNode = edge.targetNode();
                if (nextNode != undefined && nextNode.allEdges().length !== 0) {
                    const newWaitEdges = await this.runNode(story, nextNode, notRelatedEdgesInWait);
                    return newWaitEdges;
                }
                return notRelatedEdgesInWait;
            }
            else {
                /// 当前的边还需要等待，先运行其它节点
                const newWaitEdges = [...waitEdges, edge];
                return newWaitEdges;
            }
        }
    }
    notifyLoading(story, edge) {
        const loadingElements = [edge];
        const targetNode = edge.targetNode();
        if (targetNode) {
            loadingElements.push(targetNode);
        }
        story.emit(loadingElements);
    }
    async handleEdgeRun(edge, story, relatedEdges) {
        edge.resetState();
        edge.setLoading();
        this.notifyLoading(story, edge);
        /// 前一个节点是ERROR状态，不运行
        const sourceNodes = StoryUtil_1.StoryUtil.findEdgeSoures(story, edge);
        StoryUtil_1.StoryUtil.updateGraphData(story, sourceNodes);
        const hasError = sourceNodes.filter(n => n.state === StoryNodeState_1.StoryNodeState.ERROR).length !== 0;
        if (hasError) {
            StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
            return Promise.resolve();
        }
        /// 普通的边不存在identifierValue 需要创建
        if (AdapterUtil_1.AdapterUtil.isNeedGeneratePromptEdge(edge, sourceNodes)) {
            const identifier = await this.generatePromptService.getEdgeGeneration(edge, sourceNodes);
            if (identifier) {
                edge.options.actionData.identifierType = identifier.type;
                edge.options.actionData.identifierValue = identifier.value;
                story.emit([edge]);
            }
        }
        /// 获取具体的执行适配器
        const adapterInfo = await AdapterUtil_1.AdapterUtil.getAdapter(story, edge, this.adapterRegistry, relatedEdges);
        const adapter = adapterInfo.adapter;
        const actionData = adapterInfo.actionData;
        const sourceNode = StoryUtil_1.StoryUtil.findEdgeSoure(story, edge);
        if (edge.state === StoryEdge_1.EdgeState.ERROR) {
            return Promise.resolve();
        }
        /// 调用run，处理结果
        const response = await (0, LangUtil_1.tryPromise)(adapter.run(story, edge, sourceNode, actionData));
        if (response.error) {
            return this.handleErrorResult(story, edge, new AdapterBase_1.RunResponse(AdapterBase_1.RunResponseType.ERROR, ErrorUtil_1.ErrorUtil.defaultErrorMsg), relatedEdges, edge.targetNode()?.options.displayType || StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT);
        }
        const res = response.data;
        const currentEdge = edge;
        if (currentEdge.state === StoryEdge_1.EdgeState.ERROR) {
            return Promise.resolve();
        }
        return this.responseDistribution.handleRunResponse(story, edge, res, relatedEdges, AdapterUtil_1.AdapterUtil.typeTransform(res.type, actionData, adapter));
    }
    handleErrorResult(story, edge, res, relatedEdges, displayType) {
        const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMNODE, displayType);
        let contentString = "";
        if (res.data === undefined) {
            contentString = undefined;
        }
        else if (typeof res.data === "string") {
            contentString = res.data;
        }
        else {
            contentString = JsonUtil_1.JsonUtil.stringify(res.data) || "";
        }
        if (contentString == undefined || contentString == "") {
            contentString = AdapterErrorUtil_1.AdapterErrorUtil.defaultErrorMessage;
        }
        targetNode.options.displayType = displayType;
        targetNode.state = StoryNodeState_1.StoryNodeState.ERROR;
        targetNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.ERROR, story);
        return Promise.resolve();
    }
    /**
     * 监听customFunction的消息
     * @private
     */
    listenCustomFunction() {
        Eventbus_1.eventbus.on(RunCustomFunctionMsg_1.RunCustomFunctionMsg, async (msg) => {
            if (msg.story instanceof Story_1.Story && msg.customFuncDest instanceof RelationSetDest_1.RelationSetDestAttribute) {
                const newStory = SubStoryUtil_1.SubStoryUtil.fromFunc(msg.story, msg.edge, msg.customFuncDest, false);
                if (!newStory) {
                    msg.runEnded(undefined, undefined, new Meta_1.Meta("Create subStory error", ""));
                    return;
                }
                newStory.lastEdgeOutNodeCreate = msg.substoryLastEdgeTargetNodeCreated;
                const sourceNode = newStory.startNodes.first();
                // 如果custom function 嵌套层级超过两层，那么此时的msg.story  也会是一个SubStory
                // 所以需要排除这个情况
                if (sourceNode && !(msg.story instanceof SubStory_1.SubStory)) {
                    const globalContextFunc = this.funcRegistry.getByRegistName(FunctionRegistry_1.FunctionName.globalContext);
                    await globalContextFunc.updateSourceContentForSubstory(sourceNode, FunctionUtil_1.FunctionUtil.createFuncRunIdentityInfo(msg.story, msg.edge), msg.story.graphData);
                }
                this.runStoryAll(newStory, true)
                    .finally(() => {
                    const resultNode = newStory.startNodes.first()?.findLastNode();
                    msg.runEnded(resultNode, newStory);
                })
                    .catch(error => {
                    msg.runEnded(undefined, undefined, error);
                });
            }
            else {
                msg.runEnded(undefined, undefined, new Meta_1.Meta("Create subStory error", "params dismiss"));
            }
        });
    }
}
exports.StoryManager = StoryManager;
