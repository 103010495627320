"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenUtil = void 0;
const urlAlphabet = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";
class GenUtil {
    // 将 source, params 的值，拷贝到 target，并返回 target
    static copyWith(target, source, params) {
        const object = Object.assign(target, source);
        for (const key of Object.keys(params)) {
            const value = params[key];
            if (value !== undefined) {
                object[key] = value;
            }
        }
        return object;
    }
    static randomString(size = 20) {
        let id = "";
        let i = size;
        while (i--) {
            id += urlAlphabet[(Math.random() * 64) | 0];
        }
        return id;
    }
}
exports.GenUtil = GenUtil;
