export enum Role {
  "user" = "user",
  "assistant" = "assistant",
}

export class Constants {
  constructor() {}

  static summaryBelow = [
    { role: Role.assistant, content: "What do you think about the App summary below?" },
    {
      content:
        "Overview: Caller bot AI is an AI app that allows you to create intelligent agents to make phone calls to perform tasks for your customers, it can be useful for anyone who is maintaining a customer base who want to automate processes such as a sales process.",
    },
    {
      content: `Here’s what I’m thinking:
        An app that unleash your potential with the following features:
          1. **Upload or manually add contacts:** You can upload contacts from csv files or manually add ones through a form.
          2. **Contact Management:** You can edit contacts from the list of contacts uploaded.
          3. **Instruction:** Update AI’s capabilities by describing the instructions.
          4. **Call button:** Initiate call.
          5. **Transcription:** Call history will be transcribed once call initiated.
        What do you think?`,
    },
  ];

  static lookingGood = [
    {
      role: Role.assistant,
      content: "This is what it looks like. ",
    },
    {
      role: Role.assistant,
      content: `To generate a customized call instruction for you, please take a moment to answer the following questions. Type in your answers or choose from the provided options:
        What is the primary purpose of your phone calls?`,
    },
  ];

  static makeItCall = [
    {
      role: Role.assistant,
      content: "call",
    },
  ];

  static callPhone = [
    {
      role: Role.assistant,
      content: `Thank you for making the test call!
        How was your experience? Please share any thoughts or suggestions you may have.`,
    },
  ];

  static happyBehavior = [
    {
      role: Role.assistant,
      content: "Great! You can now test the caller bot AI app on the right. ",
    },
  ];

  static defaultAnswer = [
    {
      role: Role.assistant,
      content: "thinking...",
    },
  ];

  static questions = [
    "Build an app that calls customers for me",
    "I want it to call my customers and remind them to pay for the bill of the month",
  ];

  static componentConfig = {
    "full web article": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/fullWebArticle.png",
    "search bar": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/searchBar.png",
    "audio file": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/audioFile.png",
    bio: "https://dopniceu5am9m.cloudfront.net/static/assets/demo/bio.png",
    button: "https://dopniceu5am9m.cloudfront.net/static/assets/demo/button.png",
    calendar: "https://dopniceu5am9m.cloudfront.net/static/assets/demo/calendar.png",
    chatbot: "https://dopniceu5am9m.cloudfront.net/static/assets/demo/chatbot.png",
    "generated image": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/generatedImage.png",
    "generated images": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/generatedImages.png",
    "generated text": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/generatedText.png",
    header: "https://dopniceu5am9m.cloudfront.net/static/assets/demo/header.png",
    "img slide show": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/imgSlideShow.png",
    "generated text - text title": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/generatedText.png",
    "generated text - text body": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/generatedText.png",
    input: "https://dopniceu5am9m.cloudfront.net/static/assets/demo/input.png",
    "list of ingredients": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/listOfIngredients.png",
    "list of videos": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/listOfVideos.png",
    "list of products": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/listOfProducts.png",
    "list of books": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/listOfProducts.png",
    map: "https://dopniceu5am9m.cloudfront.net/static/assets/demo/map.png",
    "pdf file": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/pdfFile.png",
    "recipe with img": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/recipeWithImg.png",
    "search answer": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/searchAnswer.png",
    spreadsheet: "https://dopniceu5am9m.cloudfront.net/static/assets/demo/spreadsheet.png",
    "stack of books": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/stackOfBooks.png",
    "stack of news": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/stackOfNews.png",
    "stack of videos": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/stackOfVideos.png",
    "stock interface": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/stockInterface.png",
    "upload a file": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/uploadAFile.png",
    "voice AI": "https://dopniceu5am9m.cloudfront.net/static/assets/demo/voiceAi.png",
  };
}
