import { useEffect } from "react";
import Fuse from "fuse.js";
import func from "@uikit/func";
import useFeatureTags from "../../custom-hooks/useFeatureTags";
import { useDebounce } from "usehooks-ts";

type options = {
  includeScore: boolean;
  useExtendedSearch?: boolean;
  threshold?: number;
  keys?: Array<string>;
};

type filterFarget = {
  aliases?: Array<string>;
  description?: string;
  groupTitle?: string;
  groupType?: string;
  label?: string;
  order?: number;
  value?: string;
};

type fuzzyData = {
  filterFarget: Array<filterFarget>;
  options: options;
};

type callback = (arr: Array<filterFarget>) => Array<filterFarget>;

const DEBOUNCE_TIMEOUT = 500;

export function useFuzzySearch(
  disable: boolean,
  searchTerm: string,
  fuzzyData: fuzzyData,
  callback: callback,
  noNeedFilter = false
) {
  const feature_tags = useFeatureTags();

  const debouncedSearchTerm = useDebounce<string>(searchTerm, DEBOUNCE_TIMEOUT);

  useEffect(() => {
    if (disable === false) {
      try {
        if (func.isEmpty(searchTerm) || searchTerm.includes("/") || noNeedFilter) return;
        const fuse = new Fuse(fuzzyData.filterFarget, fuzzyData.options);

        const commonStrings = fuse.search(searchTerm);
        if (!func.isEmpty(commonStrings)) {
          const arr = commonStrings.map(x => x.item);
          callback(arr);
        } else {
          callback([]);
        }
      } catch (error) {
        callback([]);
      }
    }
    // ignore callback if no feature flag
  }, [debouncedSearchTerm]);
}
