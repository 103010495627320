import { useRef, useEffect, useState } from "react";

import style from "./SaasHeadPc.module.scss";

const SaasHeadPc = props => {
  return (
    <div className={style["saasHeadPc"]}>
      <div className={style["saas-top"]}>
        {props.saasUIData?.showTitle && (
          <div className={style["title-box"]}>
            <div className={style["saas-title"]}>{props.saasUIData?.title}</div>
          </div>
        )}
        {props.saasUIData?.showSubTitle && (
          <div className={style["title-box"]}>
            <div className={style["saas-subTitle"]}>{props.saasUIData?.subTitle}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SaasHeadPc;
