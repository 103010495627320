import style from "./ListViewHeaderStyle.module.scss";

export function ListViewHeader() {
  return (
    <div className={style.header}>
      <div className={style.label}>
        <span>Project Name</span>
      </div>
      <div className={style.label}>Last modified</div>
      <div className={style.label}>Created</div>
    </div>
  );
}
