"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RetrievalItemMetaData = exports.RetrievalItem = exports.Retrieval = exports.RetrievalResponse = void 0;
class RetrievalResponse {
    constructor() {
        this.results = [];
    }
}
exports.RetrievalResponse = RetrievalResponse;
class Retrieval {
    constructor() {
        this.query = "";
        this.results = [];
    }
}
exports.Retrieval = Retrieval;
class RetrievalItem {
    constructor() {
        this.id = "";
        this.text = "";
    }
}
exports.RetrievalItem = RetrievalItem;
class RetrievalItemMetaData {
    constructor() {
        this.document_id = "";
    }
}
exports.RetrievalItemMetaData = RetrievalItemMetaData;
