import { chain } from "lodash";
import React from "react";
import { Node } from "reactflow";

type GetTopParentNodeIdParams = {
  id: string;
  nodesRef: React.MutableRefObject<Node<any>[]>;
};

function getTopParentNodeId({ id, nodesRef }: GetTopParentNodeIdParams): string {
  const parentId = chain(nodesRef)
    .get("current")
    .find(node => {
      return node.id === id && !!node.parentNode;
    })
    .value()?.parentNode;
  if (parentId) {
    return getTopParentNodeId({ id: parentId, nodesRef });
  }
  return id;
}

export { getTopParentNodeId };
