import { NewEdgeDataRef } from "@uikit/model/NewEdgeDataRef";
import { DataRefUtil } from "@uikit/util/DataRefUtil";
import { NewLineDataRef } from "./NewLineDataRef";
import { GroupDataRef } from "./GroupDataRef";
import { NodeDataRef, NodeDataRefState } from "./NodeDataRef";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { EdgeDataRef } from "./EdgeDataRef";
import { CanvasNodeType, CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { Node } from "reactflow";
import { isEmpty } from "lodash";

/**
 * 用来管理原来 HomeUsePlugin 以下几个 ref 值
 *
 * - nodeDataRef
 * - edgeDataRef
 * - groupDataRef
 * - newEdgeDataRef
 * - newLineDataRef
 *
 */
export class CanvasDataRef {
  newEdgeDataRef: NewEdgeDataRef;
  newLineDataRef: NewLineDataRef;
  groupDataRef: GroupDataRef;
  nodeDataRef: NodeDataRef;
  edgeDataRef: EdgeDataRef;

  constructor(public creatorRefStore: CreatorRefStore, public creatorNodesStore: CreatorNodesStore) {
    this.newEdgeDataRef = new NewEdgeDataRef();
    this.newLineDataRef = new NewLineDataRef();
    this.groupDataRef = new GroupDataRef();
    this.nodeDataRef = new NodeDataRef();
    this.edgeDataRef = new EdgeDataRef();

    // !!!上面几个 class 建议不要通过构造器传递，为了防止上面数据在被 json 序列化的时候不会出现其他数据
    this.edgeDataRef.state.nodes = {
      get current(): Node<any>[] {
        return creatorNodesStore.getNodes();
      },
    };
    this.edgeDataRef.state.nodeIndexRef = creatorRefStore.nodeIndexRef;
    // 考虑到fot或其他以外地方可能还在使用该数据，暂时没删，但是fot应不在使用
    this.nodeDataRef.state.nodeIndexRef = creatorRefStore.nodeIndexRef;
    this.nodeDataRef.state.edgeIndexRef = creatorRefStore.edgeIndexRef;
  }

  getSimpleNodeV3 = (node: any): any => {
    return DataRefUtil.getSimpleNodeV3(node, this.newEdgeDataRef);
  };

  /**
   * 原来该方法用于返回除 functions 类型的 newEdgeDataRef，也就是不会用到 functions
   * 可暂时直接用 canvasNewEdgeData.state 替换，当 newEdgeDataRef 被移除，则可直接安全使用 newEdgeDataRef
   *
   * 参考原来 HomeUsePluginUtil.getSimpleNodeDataV3, 现移动到 DataRefUtil
   *
   * @param edge
   * @returns
   */
  getSimpleNodeDataV3 = (edge: any): Record<string, any> => {
    return DataRefUtil.getSimpleNodeDataV3(edge, this.newEdgeDataRef);
  };

  /**
   * 原 HomeMethodsServie 中的方法
   * @param node
   * @returns
   */
  getSimpleNode(node: CanvasNodeType): CanvasNodeType {
    const nodeData = this.getSimpleNodeData(node);
    return {
      ...node,
      data: nodeData,
    };
  }

  /**
   * 原来 HomeMethodsServie 中的方法，也是早期的方法（是否需要替换成 getSimpleNodeDataV3）
   * @param node
   * @returns
   */
  getSimpleNodeData = (node?: CanvasNodeType): Record<string, any> | NodeDataRefState => {
    const simpleNodeData: Record<string, any> = {};
    // FIXME: 按住 alt 拖住边连接到另一条边上会报错？？？
    if (!node) {
      return simpleNodeData;
    }

    const nodeDataRef = this.nodeDataRef;
    Object.keys(nodeDataRef.current).forEach(x => {
      if (typeof nodeDataRef.current[x as keyof typeof nodeDataRef.current] !== "function") {
        simpleNodeData[x] = node.data[x];
      }
    });
    return simpleNodeData;
  };

  getSimpleEdgeV3 = (edge: any): any => {
    return DataRefUtil.getSimpleEdgeV3(edge, this.newLineDataRef);
  };

  /**
   * 原来 HomeMethodsService 中的方法，，也是早期的方法（是否需要替换成 getSimpleNodeDataV3）
   * @param edge
   * @returns
   */
  getSimpleEdgeData(edge: CanvasNodeType): Record<string, any> {
    const simpleEdgeData = {} as Record<string, any>;
    const noNeedAttrs = ["nodes", "singleFlowEdgeArrRef"];
    const edgeDataRef = this.edgeDataRef;

    if (isEmpty(edgeDataRef.current) || isEmpty(edge.data)) return simpleEdgeData;
    Object.keys(edgeDataRef.current).forEach(x => {
      if (
        typeof edgeDataRef.current[x as keyof typeof edgeDataRef.current] !== "function" &&
        !noNeedAttrs.includes(x)
      ) {
        simpleEdgeData[x] = edge.data[x];
      }
    });
    return simpleEdgeData;
  }

  getSimpleEdge = (edge: CanvasNodeType): Record<string, any> => {
    const edgeData = this.getSimpleEdgeData(edge);
    return {
      ...edge,
      data: edgeData,
    };
  };
}
