"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class BTheme {
}
exports.BTheme = BTheme;
BTheme.theme = {
    saasAiAppTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.saasAiAppTheme,
        background: "linear-gradient(180deg, #FAFAFA 0%, #FFF 100%)",
    },
    nodeTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.nodeTheme,
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBorder: "0",
        inputBorderBottom: "1px solid rgba(18, 25, 43, 0.10)",
        inputPadding: "15px 25px",
        inputBorderRadius: "0px",
        inputBoxShadow: "none",
        topSuctionBgc: "transparent",
        submitButtonBorder: "2px solid #96F",
        submitButtonColor: "#96F",
        submitButtonBgClr: "transparent",
        submitButtonBorderRadius: "10px",
        nodeWrapperShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
    },
};
