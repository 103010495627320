"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YoutubeVideoDataService = void 0;
const CardDataBaseService_1 = require("../../cardDataSearch/dataSerivce/CardDataBaseService");
const FunctionRegistry_1 = require("../../../base/storyV2/function/FunctionRegistry");
const VBDataYoutubeVideoModel_1 = require("../../../base/api/viewBuilderTyped/VBGraphData/VBDataYoutubeVideoModel");
/**
 * 单个视频的
 */
class YoutubeVideoDataService extends CardDataBaseService_1.CardDataBaseService {
    constructor(functionRegistry) {
        super();
        this.functionRegistry = functionRegistry;
    }
    async search(params) {
        const findFunction = this.functionRegistry.getByRegistName(FunctionRegistry_1.FunctionName.youtubeVideos);
        if (!findFunction) {
            return Promise.reject("can not find function");
        }
        const youtubeFunction = findFunction;
        const funcResponse = await youtubeFunction.run(params.keyWords);
        const resultData = funcResponse.data;
        const data = resultData.result.map(item => {
            const newItem = new VBDataYoutubeVideoModel_1.VBDataYoutubeVideoModel();
            newItem.video_title = item.title;
            newItem.url = item.url;
            newItem.video_image = item.thumbnailUrl;
            newItem.source = new VBDataYoutubeVideoModel_1.VBDataYoutubeVideoSource();
            // TODO: 先写死
            newItem.source.source_icon = "https://dopniceu5am9m.cloudfront.net/static/assets/230705/vb-youtube.png";
            newItem.source.source_text = "youtube";
            return newItem;
        });
        const response = data.first() ?? new VBDataYoutubeVideoModel_1.VBDataYoutubeVideoModel();
        return response;
    }
}
exports.YoutubeVideoDataService = YoutubeVideoDataService;
