import css from "./SortableList.module.scss";
import classNames from "classnames";
import { closestCenter, DndContext, DragOverlay, UniqueIdentifier } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { SortableListProvider, useSortableListContainer } from "./container";
import { useCallback } from "react";
import { find, isFunction } from "lodash";
import { SortableItem } from "./SortableItem/SortableItem";

function SortableListInner<T = any>(props: SortableList.Props<T>): JSX.Element {
  const { sortableOptions, activeId, sensors, onDragStart, onDragEnd, modifiers, disabled, sortableListRef } =
    useSortableListContainer();

  const { className, renderItem, itemClassName, renderItemHead, renderItemChildren, renderActiveChildren } = props;

  const _renderSortableItem = useCallback(
    (item: SortableList.SortableData<T>, index: number) => {
      const itemChild = (
        <SortableItem
          // !!! important
          key={`${item.id}${index}`}
          value={item}
          index={index}
          itemClassName={itemClassName}
          renderItemHead={renderItemHead}
          renderItemChildren={renderItemChildren}
        />
      );

      if (renderItem) {
        return renderItem({ item, index, defaultChild: itemChild });
      }

      return itemChild;
    },
    [itemClassName, renderItem, renderItemChildren, renderItemHead]
  );

  const _renderActiveChildren = useCallback(
    (id: UniqueIdentifier) => {
      const target = find(sortableOptions, ["id", id]);
      if (target && renderActiveChildren) {
        return renderActiveChildren(target);
      }
      return null;
    },
    [renderActiveChildren, sortableOptions]
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={modifiers}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      autoScroll
    >
      <div
        ref={sortableListRef}
        className={classNames(css["SortableList"], className)}
        data-testid="SortableList"
        data-sortablelist
      >
        <SortableContext disabled={disabled} items={sortableOptions} strategy={verticalListSortingStrategy}>
          {sortableOptions.map(_renderSortableItem)}
        </SortableContext>

        {activeId && isFunction(renderActiveChildren) ? (
          <DragOverlay>{_renderActiveChildren(activeId)}</DragOverlay>
        ) : null}
      </div>
    </DndContext>
  );
}

/**
 * 该组件是原来 aisass 里面的组件，用于 input 和 output，但是有大量重复逻辑存在该组件
 *
 * 该组件应该是一个通用组件，其接受的是一个泛型类型，**不应该出现业务相关代码**
 *
 * @param props
 * @returns
 */
export function SortableList<T>(props: SortableList.Props<T>): JSX.Element {
  return (
    <SortableListProvider initialState={props}>
      <SortableListInner<T> {...props} />
    </SortableListProvider>
  );
}
