import { get, isNil } from "lodash";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const useWaitingTodo = (storeListenPath = "", todo = () => {}) => {
  const dependentValue = useSelector(state => get(state, storeListenPath));

  const waitingTodoRef = useRef(0);
  const resolveHandle = useRef(null);

  const handleWaitingTodo = () => {
    waitingTodoRef.current = waitingTodoRef.current + 1;
    return new Promise(resolve => {
      resolveHandle.current = resolve;
    });
  };
  const handleTodo = async () => {
    if (waitingTodoRef.current !== 0 && isNil(dependentValue) === false) {
      waitingTodoRef.current = 0;
      await todo();
      resolveHandle.current();
    }
  };
  useEffect(() => {
    handleTodo();
  }, [dependentValue, storeListenPath, todo, waitingTodoRef]);

  return {
    handleWaitingTodo,
  };
};

export default useWaitingTodo;
