"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBlank = void 0;
String.prototype.defaultAsNull = function () {
    if (isBlank(this)) {
        return undefined;
    }
    return this;
};
Array.prototype.first = function () {
    if (this.length === 0) {
        return undefined;
    }
    return this[0];
};
Array.prototype.last = function () {
    if (this.length === 0) {
        return undefined;
    }
    return this[this.length - 1];
};
Array.prototype.get = function (index) {
    if (this.length <= index) {
        return undefined;
    }
    return this[index];
};
/**
 * // true
 * isBlank([])
 * isBlank({})
 * isBlank(null)
 * isBlank(undefined)
 * isBlank(false)
 * isBlank('')
 * isBlank('    ')
 * isBlank('\n\t\r')
 * isBlank(function () {})
 *
 *
 * // false
 * isBlank(0)
 * isBlank(100)
 * isBlank(true)
 * isBlank(function (a) {})
 * @param value
 */
function isBlank(value) {
    const valueType = typeof value;
    if (value === undefined || value === null) {
        return true;
    }
    else if (valueType === "number" || valueType === "boolean") {
        if (Number.isNaN(value) || value === false) {
            return true;
        }
        else {
            return false;
        }
    }
    else if (typeof value.length !== "undefined") {
        if (/^[\s]*$/.test(value.toString())) {
            return true;
        }
        return value.length === 0;
    }
    else {
        for (let i in value) {
            if (value.hasOwnProperty(i)) {
                return false;
            }
        }
    }
    return true;
}
exports.isBlank = isBlank;
