"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateSubscriber = exports.ImagicaCreatorPlan = exports.ImagicaCreatorPlanElement = void 0;
class ImagicaCreatorPlanElement {
}
exports.ImagicaCreatorPlanElement = ImagicaCreatorPlanElement;
class ImagicaCreatorPlan {
    constructor() {
        this.value = "";
        this.free = false;
        this.name = new ImagicaCreatorPlanElement();
        this.price_month = new ImagicaCreatorPlanElement();
        this.price_annual = new ImagicaCreatorPlanElement();
        this.upgrade_button = new ImagicaCreatorPlanElement();
        this.downgrade_button = new ImagicaCreatorPlanElement();
        this.badges = [];
        this.highlight_border = false;
        this.style = {};
        this.current_plan = false;
        this.expiryDate = "";
        this.is_show_downgrage_button = false;
        this.current_plan_is_canceled = false;
    }
}
exports.ImagicaCreatorPlan = ImagicaCreatorPlan;
class PopUpItem {
    constructor() {
        this.items = [];
    }
}
class UpdateSubscriber {
    constructor() {
        this.id = "";
        this.customer = "";
        this.return_url = "";
        this.url = "";
    }
}
exports.UpdateSubscriber = UpdateSubscriber;
