import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { PreferencesStore } from "imagica-corekit/dist/base/store/PreferencesStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { ReadyStatus } from "imagica-corekit/dist/base/cutil/ReadyStatus";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { UiFunctionService } from "../service/UiFunctionService";
import isBlank from "@sedan-utils/is-blank";
import { FotUserAuthService } from "../service/FotUserAuthService";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import func from "@uikit/func";
import { CotStore } from "@uikit/store/CotStore";

export class ProcessBoarding {
  constructor(
    public fotReduxStore: FotReduxStore,
    public authStore: FotAuthStore,
    public preferencesStore: PreferencesStore,
    public homePluginStore: HomePluginStore,
    public creatorRefStore: CreatorRefStore,
    public uiFunctionService: UiFunctionService,
    public fotUserAuthService: FotUserAuthService,
    public homeStore: HomeStore,
    public cotStore: CotStore
  ) {}

  /**
   * 原来 handleOnboarding 方法
   * @returns
   */
  async init(): Promise<void> {
    if (isBlank(this.authStore.state.brainToken)) return;
    const disableCotOnboarding = this.homeStore.state.featureTags.disableCotOnboarding;
    const disableManualOnboarding = this.homeStore.state.featureTags.disableManualOnboarding;

    if (this.fotUserAuthService.isSubscriber() === false) {
      await this.getImagicaStudioTutorial();

      if (disableManualOnboarding === false) {
        this.cotStore.triggerGuideManual();
      }

      if (disableCotOnboarding === false) {
        this.cotStore.triggerGuideCot();
      }
    }
  }

  async getImagicaStudioTutorial(): Promise<void> {
    try {
      this.homePluginStore.setShowImagicaTutoriralLoading(ReadyStatus.LOADING);
      await this.preferencesStore.get();
      this.creatorRefStore.isLoadPreferences.current = true;
      const imagicaStudioTutorialValue = await this.preferencesStore.getByKey("imagica_studio_tutorial");
      if (imagicaStudioTutorialValue) {
        const value =
          typeof imagicaStudioTutorialValue === "string"
            ? JSON.parse(imagicaStudioTutorialValue)
            : imagicaStudioTutorialValue;
        // !!! 保证showManualGuide 或 showCotGuide 的正确性
        await this.preferencesStore.set("imagica_studio_tutorial", value);
      } else {
        await this.preferencesStore.set("imagica_studio_tutorial", {
          index: 0,
          viewed: false,
          showManualGuide: false,
          showCotGuide: false,
        });
      }

      // await OpenCotGuide.open(navigate);

      this.uiFunctionService.get();

      this.uiFunctionService.onFunctionsChange(functions => {
        //更新ImageGenSelectOptions来同步
        this.fotReduxStore.setImageGenSelectOptions(functions);
      });
    } catch (error) {
      console.error("get preferences failed.", error);
      //FIXME: 将错误抛出去，让外部来显示错误提示
      func.messageUtil(error);
    } finally {
      this.creatorRefStore.isLoadPreferences.current = true;
      this.homePluginStore.setShowImagicaTutoriralLoading(ReadyStatus.DONE);
    }
  }
}
