"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainRequest = exports.ChainResultTextAreaValue = exports.ChainResultInitCreateBlobUrl = void 0;
const AskBrain_1 = require("../../../../api/askBrainTyped/AskBrain");
const StoryNodeDisplayType_1 = require("../../../../storyV2/domain/StoryNodeDisplayType");
const CustomApiTransformUtil_1 = require("../util/CustomApiTransformUtil");
const GlobalClient_1 = require("../../../../cutil/GlobalClient");
const FunctionBase_1 = require("../../../../storyV2/function/FunctionBase");
const WebsocketResponse_1 = require("../../../../storyV2/model/WebsocketResponse");
const JsonUtil_1 = require("../../../../cutil/JsonUtil");
const class_transformer_1 = require("class-transformer");
class ChainResultInitCreateBlobUrl {
    constructor() {
        this.objectURL = "";
        this.loading = false;
        this.streaming = false;
    }
}
exports.ChainResultInitCreateBlobUrl = ChainResultInitCreateBlobUrl;
class ChainResultTextAreaValue {
    constructor() {
        this.value = "";
        this.s3Url = "";
        this.s3Path = "";
        this.needToCreateBlobUrl = "";
        this.uuid = "";
        this.name = "";
        this.format = "";
    }
}
exports.ChainResultTextAreaValue = ChainResultTextAreaValue;
class PartialResult {
    constructor() {
        this.initCreateBlobUrl = new ChainResultInitCreateBlobUrl();
        this.textAreaValue = new ChainResultTextAreaValue();
        this.displayType = StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT;
    }
}
__decorate([
    (0, class_transformer_1.Type)(() => ChainResultInitCreateBlobUrl)
], PartialResult.prototype, "initCreateBlobUrl", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => ChainResultTextAreaValue)
], PartialResult.prototype, "textAreaValue", void 0);
class ChainRequest {
    constructor(query, res, result = null) {
        this.query = query;
        this.res = res;
        this.result = result;
    }
    get isWS() {
        if (this.res instanceof WebsocketResponse_1.WebsocketResponse) {
            return true;
        }
        return false;
    }
    get response() {
        if (this.res instanceof GlobalClient_1.GlobalResponse) {
            return Promise.resolve(this.res.response);
        }
        return Promise.reject();
    }
    get isStream() {
        if (this.res instanceof GlobalClient_1.GlobalResponse) {
            return this.res.isStream;
        }
        return false;
    }
    get contentType() {
        if (this.res instanceof GlobalClient_1.GlobalResponse) {
            const type = this.res.headers?.get("Content-Type");
            if (type) {
                return type;
            }
        }
        return undefined;
    }
    get websocketContent() {
        if (this.res instanceof WebsocketResponse_1.WebsocketResponse) {
            if (this.res.isPlaceholderMessage) {
                return this.res.message;
            }
            return this.res.content;
        }
        return undefined;
    }
    /// 判断请求是否结束
    get isEnded() {
        if (this.res instanceof WebsocketResponse_1.WebsocketResponse) {
            return this.res.isCompleted;
        }
        return true;
    }
    get websocketHtml() {
        if (this.res instanceof WebsocketResponse_1.WebsocketResponse) {
            return this.res.html;
        }
        return undefined;
    }
    get websocketAskBrain() {
        if (this.res instanceof WebsocketResponse_1.WebsocketResponse) {
            return JsonUtil_1.JsonUtil.toModelFromType(AskBrain_1.AskBrainData, this.res.ask_brain);
        }
        return undefined;
    }
    get websocketFileParams() {
        if (this.res instanceof WebsocketResponse_1.WebsocketResponse) {
            return this.res.fileParams;
        }
        return undefined;
    }
    get displayType() {
        if (this.res instanceof GlobalClient_1.GlobalResponse) {
            return CustomApiTransformUtil_1.CustomApiTransformUtil.getDisplayTypeByHeaders(this.res.headers);
        }
        else {
            return this.res.displayType;
        }
    }
    get hasNodeDisplayType() {
        if (this.res instanceof GlobalClient_1.GlobalResponse) {
            return CustomApiTransformUtil_1.CustomApiTransformUtil.getNodeDisplayTypeByHeaders(this.res.headers) !== undefined;
        }
        return false;
    }
    json() {
        if (this.res instanceof GlobalClient_1.GlobalResponse) {
            return this.res.json();
        }
        return Promise.reject();
    }
    text() {
        if (this.res instanceof GlobalClient_1.GlobalResponse) {
            return this.res.text();
        }
        return Promise.reject();
    }
    blob() {
        if (this.res instanceof GlobalClient_1.GlobalResponse) {
            return this.res.blob();
        }
        return Promise.reject();
    }
    stream(handler) {
        if (this.res instanceof GlobalClient_1.GlobalResponse) {
            const response = this.res.stream;
            if (!response) {
                return undefined;
            }
            const streamResponse = new FunctionBase_1.RunStreamResponse();
            let onReceived = (msg) => {
                if (msg.done) {
                    msg.abort();
                }
                const fullText = msg.fullText.trim();
                if (handler) {
                    streamResponse.receive(handler(fullText), msg.done);
                }
                else {
                    streamResponse.receive(fullText, msg.done);
                }
            };
            response.onReceived(re => onReceived(re));
            return streamResponse;
        }
        return undefined;
    }
    updateResponse(res) {
        this.res = res;
    }
    setResult(res) {
        this.result = res;
    }
    getResult() {
        return this.result;
    }
}
exports.ChainRequest = ChainRequest;
