import React from "react";
import "../components-css/Nutrition.css";

function Nutrition(props) {
  const arr = props.content.split(":");
  return (
    <section className="CardHomeNutrition">
      <p className="title">{arr[0].trim()}</p>
      <p className="price">{arr[1].trim()}</p>
    </section>
  );
}

export default Nutrition;
