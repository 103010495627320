"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctionSuggestions = void 0;
class FunctionSuggestions {
    constructor() {
        this.content = "";
        this.context = "";
        this.edge_descriptions = {};
        this.functions = [];
    }
}
exports.FunctionSuggestions = FunctionSuggestions;
