import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// an empty 404 page starter, now it only redirects to home page

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const param = window.location.href.split("?")[1];
    navigate(`/editor${param ? `?${param}` : ""}`);
  }, [navigate]);

  return <></>;
};

export default NotFound;
