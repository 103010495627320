import { map } from "lodash";
import styles from "./PhoneList.module.scss";

export type PhoneInfo = { name: string; phoneNumber: string };

type PhoneListProps = { phoneInfos: PhoneInfo[] };

export function PhoneList(props: PhoneListProps) {
  if (props.phoneInfos.length === 0) {
    return null;
  }
  return (
    <div className={styles.phoneList}>
      {map(props.phoneInfos, phoneInfo => {
        return (
          <div key={phoneInfo.phoneNumber} className={styles.item}>
            <div className={styles.checkRadio}>
              <input type="radio" />
            </div>
            <div className={styles.name}>{phoneInfo.name}</div>
            <div className={styles.number}>{phoneInfo.phoneNumber}</div>
            <div className={styles.operates}>Edit</div>
          </div>
        );
      })}
    </div>
  );
}
