import { Button } from "antd";
import React, { useState } from "react";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { LottieAnimation } from "../../../views/components/LottieAnimation/LottieAnimation";
import styles from "./GuideModal.module.css";
import { VideoView } from "../components/VideoView";
import startGuide from "./startGuide.json";
import { logEvent } from "@uikit/service/amplitude";

type GuideStep = "start" | "playVideo" | "end";
export type GuideModalProps = {
  onClose: () => void;
  onFinish: () => void;
  src: string;
  copy: {
    start: {
      title: string;
      subTitle: string;
    };
    end: {
      title: string;
      subTitle: string;
    };
  };
};

export const GuideModal: React.FC<GuideModalProps> = ({ src, copy, onClose, onFinish }) => {
  const [guideStep, setGuideStep] = useState<GuideStep>("start");
  const [videoModal, setVideoModal] = useState<React.ReactNode>(null);

  const handleClickClose = () => {
    onClose();
    logEvent("close_onboarding_video");
  };

  const handleClickStart = () => {
    setGuideStep("playVideo");
    logEvent("start_onboarding_video");
    setVideoModal(
      <VideoView
        src={src}
        onEnded={() => {
          setGuideStep("end");
          logEvent("close_onboarding_video");
        }}
        onClose={() => {
          setGuideStep("end");
          logEvent("close_onboarding_video");
        }}
      />
    );
  };

  const handleClickFinish = () => {
    logEvent("finish_onboarding_video");
    onFinish();
  };

  const handleClickWatchAgain = () => {
    logEvent("try_again_onboarding_video");
    setGuideStep("start");
  };

  const guideContent = () => {
    switch (guideStep) {
      case "start":
        return (
          <>
            <div className={styles["background-mask"]}></div>
            <div className={styles["content-box"]}>
              <div className={styles["background-gradient"]}></div>
              <div className={styles["background-rectangle"]}></div>

              <span
                className="iconfont icon-shanchu1"
                onClick={handleClickClose}
                data-amplitude="start-guiding-modal-close"
              ></span>
              <div className={styles["subject-matter-box"]}>
                <div className={styles["icon-box"]}>
                  <LottieAnimation className={styles["lottie-animation"]} loader={startGuide} />
                </div>

                <div className={styles["characters-box"]}>
                  <div className={styles["title"]}>{copy.start.title}</div>
                  <div className={styles["subTitle"]}>{copy.start.subTitle}</div>
                </div>
              </div>
              <Button
                type="primary"
                className={styles["button"]}
                onClick={handleClickStart}
                data-amplitude="start-guiding-modal-begin"
              >
                Begin
              </Button>
            </div>
          </>
        );
      case "playVideo":
        return videoModal;
      case "end":
        return (
          <div className={styles["end-box"]}>
            <div className={styles["background-gradient"]}></div>
            <div className={styles["background-rectangle"]}></div>

            <div className={styles["subject-matter-box"]}>
              <div className={styles["icon-box"]}>
                <img src={`${settings.S3CDN}${settings.viewAssetsPath}BootEndIcon2x.png`} alt="" />
              </div>

              <div className={styles["characters-box"]}>
                <div className={styles["title"]}>{copy.end.title}</div>
                <div className={styles["subTitle"]}>{copy.end.subTitle}</div>
              </div>
            </div>
            <Button
              type="primary"
              className={styles["button"]}
              onClick={handleClickFinish}
              data-amplitude="start-guiding-modal-finish"
            >
              Finish
            </Button>
            <Button
              type="text"
              className={styles["auxiliary-button"]}
              onClick={handleClickWatchAgain}
              data-amplitude="start-guiding-modal-try-again"
            >
              Watch again
            </Button>
          </div>
        );
      default:
        break;
    }
  };

  return <div className={styles["StartGuiding-box"]}>{guideContent()}</div>;
};
