import { signal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import dayjs from "dayjs";
import { SubscriptionType } from "imagica-corekit/dist/base/api/saasMonetizationTyped/SubscriptionType";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { HomePluginStoreState } from "imagica-corekit/dist/base/store/HomePluginStore";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";

interface withParamsToSigninParams {
  setIsLogin: (arg0: boolean) => void;
  navigate: (arg0: string) => void;
}

export class ShareAccountState {
  btnLoading = signal<boolean>(false);
  priceId = signal<string>("");
  subData = signal<any>({});
  isCancel = signal<boolean>(false);
  cancelAt = signal<number>(0);
  unsubscribeLoading = signal<boolean>(false);
  resubscribeLoading = signal<boolean>(false);
}

export class ShareAccountBloc {
  state = new ShareAccountState();

  withParamsToSignin(props: withParamsToSigninParams): void {
    const param = window.location.href.split("?")[1];
    props.setIsLogin(false);
    const url = `${window.location.origin}/fot/#/signin${param ? `?${param}` : ""}`;
    window.history.pushState({}, "", url);
  }

  isLogin(brainToken: string, setIsLogin: (arg0: boolean) => void, navigate: (arg0: string) => void): void {
    if (!brainToken) {
      // 未登录直接跳转到登录页
      this.withParamsToSignin({ setIsLogin, navigate });
    }
  }

  //  当用户去订阅后，从订阅页面点击返回时，需要轮询获取订阅状态
  async getSubscribe(search_q: string, store: PublishSubscriptionStore): Promise<SubscriptionType | undefined> {
    let sub_data;
    const hasSubloop = window.location.href.includes("subloop");
    do {
      sub_data = await store.getSubscription(search_q);
      if (!sub_data?.status && hasSubloop) {
        await new Promise(resolve => setTimeout(resolve, 1500));
      }
    } while (!sub_data?.status && hasSubloop);
    const newUrl = window.location.href.replace(/&subloop=true/g, "");
    window.history.pushState({}, "", newUrl);
    return sub_data;
  }

  showModal(store: PublishSubscriptionStore): void {
    store.setIsOpenPayWall(true);
  }

  handleOk(store: PublishSubscriptionStore): void {
    store.setIsOpenPayWall(false);
  }

  handleCancel(store: PublishSubscriptionStore): void {
    store.setIsOpenPayWall(false);
  }

  async purchase(
    metaDataV2: PreviewApp.PublishedMetadataV2Type | undefined,
    store: PublishSubscriptionStore,
    homeState: HomePluginStoreState,
    guest_user_key: string
  ): Promise<void> {
    this.state.btnLoading.value = true;
    if (metaDataV2) {
      const isShareDomain = !isBlank(homeState.customDomain);
      const isTakeSearchQ = isShareDomain
        ? "/fot/#/editor?subloop=true"
        : `/fot/#/editor?q=${guest_user_key}&subloop=true`;
      const redirectUrl = `${window.location.origin}${isTakeSearchQ}`;
      const url = await store.paymentLink(
        this.state.priceId.value,
        Number(metaDataV2.subscription_free_period),
        redirectUrl,
        metaDataV2.stripe_account_id
      );
      if (url) {
        window.open(url, "_self");
      }
    }
    this.state.btnLoading.value = false;
  }

  // 退出登录
  logout(params: {
    authStore: FotAuthStore;
    setMe: (val: any) => void;
    setIsLogin: (val: boolean) => void;
    setLoginMethod: (val: string) => void;
    navigate: (arg0: string) => void;
  }): void {
    params.authStore.removeBrainToken();
    localStorage.removeItem("BRAIN_USER_EMAIL");
    params.setMe({});
    params.setIsLogin(false);
    params.setLoginMethod("");
    params.authStore.logout();
    this.withParamsToSignin({ setIsLogin: params.setIsLogin, navigate: params.navigate });
  }

  // 退订
  async unSubscribeLink(store: PublishSubscriptionStore): Promise<void> {
    this.state.unsubscribeLoading.value = true;
    const link = await store.unSubscripber({
      customer: this.state.subData.value?.customer,
      return_url: window.location.href,
      subscriptionId: this.state.subData.value?.id,
    });
    this.state.unsubscribeLoading.value = false;
    window.location.href = link?.url;
  }

  //  重新订阅
  async resubscribe(store: PublishSubscriptionStore): Promise<void> {
    const parmasData = {
      customer: this.state.subData.value?.customer,
      return_url: window.location.href,
    };
    this.state.resubscribeLoading.value = true;
    const url = await store.resubscribe(parmasData);
    this.state.resubscribeLoading.value = false;
    if (url) window.location.href = url + `/subscriptions/${this.state.subData.value?.id}/reactivate`;
  }

  async judgeSub(props: {
    uuid: string;
    guest_user_key: string;
    store: PublishSubscriptionStore;
    authStore: FotAuthStore;
    setIsLogin: (val: boolean) => void;
    navigate: (arg0: string) => void;
    metaDataV2: PreviewApp.PublishedMetadataV2Type | undefined;
    meJson: any;
    selectedTemplate: any;
  }): Promise<void> {
    /**
     * 是发布者自己不走以下流程
     * 1、如果是付费App
     * 2、判断是否登录，未登录跳转到登录页
     * 3、判断是否订阅过，订阅过则不显示订阅弹窗
     * 4、如果订阅过，设置提示文案
     */
    if (this.isOneself(props.meJson, props.selectedTemplate)) {
      props.store.setIsPaidApp(false);
      return props.store.setLoading(false);
    }
    props.store.setLoading(true);
    try {
      const price_id = await props.store.getPriceId(props.guest_user_key);
      if (price_id) {
        this.state.priceId.value = price_id;
        props.store.setIsPaidApp(true);
        if (!props.authStore?.state?.brainToken) {
          this.notLoginOperation({ metaDataV2: props.metaDataV2, store: props.store });
        } else {
          await this.loginAndPayOperation({ ...props, price_id, search_q: props.guest_user_key });
        }
      } else {
        props.store.setIsPaidApp(false);
      }
    } catch (error) {
    } finally {
      props.store.setLoading(false);
    }
  }

  //  是否是发布者本人
  isOneself(meJson: any, selectedTemplate: any): boolean {
    const selfId = meJson?.id;
    const authorId = selectedTemplate?.published?.author?.id;
    if (!authorId || selfId === authorId) {
      return true;
    }

    return false;
  }

  //  付费APP 已登录
  async loginAndPayOperation(props: {
    uuid: string;
    store: PublishSubscriptionStore;
    authStore: FotAuthStore;
    setIsLogin: (val: boolean) => void;
    navigate: (arg0: string) => void;
    metaDataV2: PreviewApp.PublishedMetadataV2Type | undefined;
    price_id: string;
    search_q: string;
  }): Promise<void> {
    const sub_data = await this.getSubscribe(props.search_q, props.store);
    this.state.subData.value = sub_data;
    this.state.isCancel.value = sub_data?.cancel_at_period_end || false;
    this.state.cancelAt.value = sub_data?.cancel_at || 0;
    if (sub_data?.status === "active" || sub_data?.status === "trialing") {
      props.store.setIsPay(true);
      props.store.setPrice(sub_data?.plan?.amount / 100);
      props.store.setFree(this.computedDays(sub_data?.trial_end));
    } else {
      props.store.setIsPay(false);
      const data = await props.store.getShareAppSubscriptionPrice(props.price_id);
      const price = data?.unit_amount as number;
      props.store.setPrice(price / 100);
      props.metaDataV2?.subscription_free_period &&
        props.store.setFree(Number(props.metaDataV2.subscription_free_period));
      props.store.setIsOpenPayWall(true);
    }
  }

  //  付费APP未登录
  notLoginOperation(props: {
    metaDataV2: PreviewApp.PublishedMetadataV2Type | undefined;
    store: PublishSubscriptionStore;
  }): void {
    const { subscription_price, subscription_free_period } = props.metaDataV2 || {};
    subscription_price && props.store.setPrice(Number(subscription_price));
    subscription_free_period && props.store.setFree(Number(subscription_free_period));
  }

  computedDays(time: number): number {
    if (!time) return 0;
    const now = dayjs();
    const futureDate = dayjs(time * 1000);
    const diffInDays = futureDate.diff(now, "day") + 1;
    return diffInDays;
  }
}
