import { FotUIDependenciesInterface } from "@uikit/infra/FotUIDependenciesInterface";
import { MyModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { Location as ReactLocation, NavigateFunction } from "react-router-dom";

class Dep {
  navigate?: NavigateFunction;
}
/**
 * fot 路由跳转控制类
 *
 * 1. 该类主要为了解决 fot 中用来跳转 router 时,无需关注 是否在 hook(组件环境),在全局环境也能跳转
 * 2. 也为了后期跳转路由做统一处理
 * 3. 同时为其他 fot 的 service 或 class 提供 navigate 方法
 */
export class FotNavigateController implements FotUIDependenciesInterface<Dep> {
  dependencies: Dep = new Dep();

  constructor(private showNavPageStore: ShowNavPageStore, private myModificationDataStroe: MyModificationDataStroe) {}

  get navigate(): NavigateFunction {
    if (!this.dependencies.navigate) {
      throw new Error("Please call setDependencies set navigate in UI");
    }

    return this.dependencies.navigate;
  }

  setDependencies(value: Dep): void {
    Object.assign(this.dependencies, value);
  }

  /**
   * 打开创作者首页(有项目列表,函数列表的页面)
   * TODO: 将代码中 navigate("/editor") 替换为该方法
   */
  openCreatorHomePage(): void {
    this.navigate("/editor");
    // 关闭 creator 组件
    this.showNavPageStore.setShowNavPage(true);

    if (this.myModificationDataStroe.state.showMyModification) {
      // 关闭 function 组件
      this.myModificationDataStroe.setShowMyModification(false);
    }
  }

  /**
   * 跳转到 普通函数 页面
   * @see {@link @uiview/pages/creatorFunctionNormalPage/CreatorFunctionNormalPage}
   */
  openFunctionNormalPage(functionData: any): void {
    this.navigate(`function-normal/${functionData.id}`);

    // FIXME: 下面的代码会在 CreatorFunctionNormalPageBloc success 方法中处理, 后期需要一个能统一处理这类数据的地方
    // this.showNavPageStore.setShowNavPage(false);
    // this.myModificationDataStroe.setShowMyModification(true);
    this.myModificationDataStroe.setMyModificationData(functionData);
  }

  /**
   * 是否是 /editor/function-normal/:id 页面
   */
  private isFunctionNormalPage(location: ReactLocation): boolean {
    return location.pathname.startsWith("/editor/function-normal/");
  }

  /**
   * 该方法暂时用来判断
   */
  isNotEditorLayout(location: ReactLocation): boolean {
    return this.isFunctionNormalPage(location);
  }
}
