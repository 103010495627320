import { createSlice } from "@reduxjs/toolkit";
import func from "@uikit/func";

const feedSlice = createSlice({
  name: "feed",
  initialState: {
    domains: [".", ".", ".", ".", "."],
    selectedDomain: -1,
    thoughts: [". . .", ". . .", ". . .", ". . .", ". . .", ". . .", ". . .", ". . .", ". . .", ". . ."],
    focusedThought: { type: "thought", content: "" },
    focusMode: true,
  },
  reducers: {
    setDomains(state, action) {
      const domains = action.payload;
      state.domains = domains;
    },
    setSelectedDomain(state, action) {
      const selectedDomain = action.payload;
      state.selectedDomain = selectedDomain;
    },
    setThoughts(state, action) {
      const thoughts = action.payload;
      const formatThoughts = thoughts.map(x => {
        // 是object且不为null不为array
        if (!func.isEmpty(x.results) && x.results instanceof Object && !Array.isArray(x.results)) {
          return Object.assign({}, x, { results: [x.results] });
        }
        return x;
      });
      state.thoughts = formatThoughts;
    },
    setFocusedThought(state, action) {
      const focusedThought = action.payload;
      state.focusedThought = focusedThought;
    },
    setFocusMode(state, action) {
      const focusMode = action.payload;
      state.focusMode = focusMode;
    },
    restoreDefaultValues(state, _) {
      state.domains = [".", ".", ".", ".", "."];
      state.selectedDomain = -1;
      state.thoughts = [". . .", ". . .", ". . .", ". . .", ". . .", ". . .", ". . .", ". . .", ". . .", ". . ."];
      state.focusedThought = { type: "thought", content: "" };
      state.focusMode = true;
    },
  },
});

export const feedActions = feedSlice.actions;
export default feedSlice.reducer;
