import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { StoryManager } from "imagica-corekit/dist/base/storyV2/domain/StoryManager";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { MyModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { InitialReportSize } from "imagica-uikit/dist/nodesV2/typing";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { FotUIDependenciesInterface } from "@uikit/infra/FotUIDependenciesInterface";

export type EdgeRunTransformDataType = {
  id: string;
  type: string;
  data: any;
};

class EdgeRunDep {
  /**
   * 用于 ui 层，更新 initialReportSizeMapping 值
   *
   * TODO: initialReportSizeMapping 后期应该是一个绝对的常量值
   */
  initialReportSizeMapping: Record<string, InitialReportSize> = {};
}
/**
 * 原来 useNewEdgeRun hook
 *
 */
export class EdgeRunCore implements FotUIDependenciesInterface<EdgeRunDep> {
  dependencies: EdgeRunDep = new EdgeRunDep();
  constructor(
    public fotReduxStore: FotReduxStore,
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public storyManager: StoryManager,
    public fotSaveService: FotSaveService,
    public myModificationDataStroe: MyModificationDataStroe,
    public homeStore: HomeStore,
    public meStore: MeStore,
    public personalFunctionStore: PersonalFunctionStore
  ) {}

  setDependencies(value: EdgeRunDep): void {
    Object.assign(this.dependencies, value);
  }
}
