import { ImagicaCreatorPlan } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import style from "./DowngradeAlertView.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { usePlanList } from "@uiview/pages/PlansList/index";
import { AModal } from "@uiview/views/AModal/AModal";

type DowngradeAlertViewProps = {
  onClose: () => void;
  plan: ImagicaCreatorPlan | undefined;
  downLoading: boolean;
};

export function DowngradeAlertView(props: DowngradeAlertViewProps): JSX.Element {
  const titleText = props.plan?.downgrade_button.text ?? "Downgrade";
  const items = props.plan?.downgrade_button.popup?.items ?? [];
  const { paybloc } = usePlanList();

  return (
    <AModal open onCancel={props.onClose} className={style.alert} buttons={[]} onOk={() => {}} closable={false}>
      <div className={style.center}>
        <div className={style.content}>
          <span className={style.close} onClick={props.onClose}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#ACAEB5" />
              <path
                d="M6.46484 13.5352L13.5359 6.46409"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5352 13.5352L6.46409 6.46409"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className={style.title}>{titleText}</div>
          <div className={style.tips}>Are you sure you want to {titleText}?</div>
          <div className={style.info_column}>
            {items.map((value, index) => {
              return (
                <div className={style.info} key={index}>
                  <span>●</span> {value}
                </div>
              );
            })}
          </div>
          <div className={style.bottom}>
            <div className={style.cancel_button} onClick={props.onClose}>
              Cancel
            </div>
            <div className={style.downgrade_button} onClick={() => paybloc.handleDowngradeLink()}>
              {props.downLoading ? <LoadingOutlined /> : <>{titleText}</>}
            </div>
          </div>
        </div>
      </div>
    </AModal>
  );
}
