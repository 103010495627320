import { Input, Tooltip, Button } from "antd";
import { InfoCircleOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import func from "@uikit/func";
import style from "./css/BodyParam.module.css";
import { getIt } from "@uikit/getIt";
import { EdgeRunVariable } from "@uikit/edgeRun/EdgeRunVariable";

const { TextArea } = Input;
// const varReg = /\$\{[^\}]*\}/

function ErrorTip(props) {
  return !func.isEmpty(props.errorTips) ? (
    <Tooltip title={props.errorTips} color="#ff4d4f" placement="bottom" className={style["error-tips"]}>
      <InfoCircleOutlined />
    </Tooltip>
  ) : null;
}

export default function BodyParam(props) {
  const [errorTips, setErrorTips] = useState("");
  const [finalEvaluatedBodyString, setFinalEvaluatedBodyString] = useState("");
  const [showEvaluatedBody, setShowEvaluatedBody] = useState(false);
  const edgeRunVariable = getIt(EdgeRunVariable);

  function changeBodyParam(e) {
    let text = e.target.value;
    const currentSourceNodeValue = props.getCurrentSourceNodeValue();
    const evaluatedBodyStr = edgeRunVariable.evaluateFinalBodyStr(currentSourceNodeValue, text);
    setFinalEvaluatedBodyString(evaluatedBodyStr);
    setErrorTips("");
    // const hasVars = varReg.test(text)
    try {
      if (!func.isEmpty(evaluatedBodyStr)) {
        JSON.parse(evaluatedBodyStr);
      }
    } catch (error) {
      setErrorTips(error.message);
    }
    // if (!hasVars) {
    //   try {
    //     text = !util.isEmpty(text) ? JSON.parse(text) : text
    //   } catch (error) {
    //     setErrorTips(error.message)
    //   }
    // }
    props.setRequestData(prevObj => ({
      ...prevObj,
      bodyParam: text,
    }));
  }

  function beautify() {
    try {
      let text = props.requestData.bodyParam;
      if (typeof text !== "string") {
        text = JSON.stringify(text);
      }
      const parsed = JSON.parse(text);
      props.setRequestData(prevObj => ({
        ...prevObj,
        bodyParam: JSON.stringify(parsed, null, 4),
      }));
    } catch (_) {}
  }

  function getDisplayText() {
    return typeof props.requestData.bodyParam !== "string"
      ? JSON.stringify(props.requestData.bodyParam, null, 4)
      : props.requestData.bodyParam;
  }

  useEffect(() => {
    setFinalEvaluatedBodyString("");
    changeBodyParam({ target: { value: getDisplayText() } });
  }, []);

  useEffect(() => {
    changeBodyParam({ target: { value: props.requestData.bodyParam } });
  }, [props.requestData.bodyParam]);

  return (
    <div style={{ height: `${showEvaluatedBody ? "72%" : "95%"}` }}>
      <TextArea value={props.requestData.bodyParam} onChange={changeBodyParam} autoSize />
      {showEvaluatedBody ? (
        <div>
          <Button
            type="link"
            icon={<EyeOutlined style={{ position: "absolute", top: 6 }} />}
            onClick={() => {
              setShowEvaluatedBody(false);
            }}
          >
            <span style={{ position: "absolute", left: 25, bottom: 5 }}>Hide Evaluated Body String</span>
          </Button>
          <TextArea
            value={finalEvaluatedBodyString}
            autoSize={{ minRows: 4, maxRows: 4 }}
            disabled
            className={style["post-body-param-evaluated"]}
          />
        </div>
      ) : (
        <Button
          type="link"
          icon={<EyeOutlined style={{ position: "absolute", top: 6 }} />}
          onClick={() => {
            setShowEvaluatedBody(true);
          }}
        >
          <span style={{ position: "absolute", left: 25, bottom: 5 }}>See Evaluated Body String</span>
        </Button>
      )}
      <ErrorTip errorTips={errorTips} />
      <Button
        type="link"
        onClick={() => {
          beautify();
        }}
        className={style["beautify-btn"]}
      >
        Beautify
      </Button>
    </div>
  );
}
