import styles from "./InvalidShareAppTip.module.scss";
import { useEffect } from "react";
import { getAssetsIcon } from "@views/thinking-layout-editor/utilities";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

export default function InvalidShareAppTip() {
  return (
    <>
      <div className={styles.wrapperUnPublished}>
        <div className={styles.box}>
          <TipTitle />
        </div>
      </div>
    </>
  );
}

function TipTitle() {
  function handleClick() {
    window.location.href = settings.ImagicaUrl;
    if (settings.ImagicaUrl.includes(window.location.host)) {
      window.location.reload();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.location.href = settings.ImagicaUrl;
      if (settings.ImagicaUrl.includes(window.location.host)) {
        window.location.reload();
      }
    }, 5000);
  }, []);

  return (
    <div className={styles.title}>
      <img src={getAssetsIcon("unpublished-icon.png")} alt="" width={"40px"} />
      <div>
        <span>
          This app has been unpublished by the creator and is no longer available. You will be redirected <br /> to
        </span>{" "}
        <span className={styles.link} onClick={handleClick}>
          imagica.ai
        </span>
      </div>
    </div>
  );
}
