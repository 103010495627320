import { GridButton } from "../../Buttons/GridButton";
import { ListButton } from "../../Buttons/ListButton";
import style from "./HeaderBarStyle.module.scss";
import { SortByView, SortType, UIModel } from "../../SortBy/SortByView";
import { useSignal } from "@preact/signals-react";

type HeaderBarProps = {
  onSelectType: (value: string) => void;
  onSortBy: (value: SortType) => void;
};

export function HeaderBar(props: HeaderBarProps): JSX.Element {
  const viewType = useSignal("grid");

  return (
    <div className={style.headerView}>
      <div className={style.contents}>
        <div className={style.title}>My projects </div>
        <div className={style.rightView}>
          <SortByView
            model={UIModel.mobile}
            onSelectSortBy={value => {
              props.onSortBy(value);
            }}
          />
          {viewType.value === "grid" ? (
            <GridButton
              isHeightLight={false}
              onClickBtn={() => {
                viewType.value = "list";
                props.onSelectType("list");
              }}
            />
          ) : (
            <ListButton
              isHeightLight={false}
              onClickBtn={() => {
                viewType.value = "grid";
                props.onSelectType("grid");
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
