"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sleep = exports.retryPromise = exports.tryBlock = exports.tryPromise = exports.TryResult = void 0;
const Meta_1 = require("./Meta");
const Empty_1 = require("./Empty");
class TryResult {
    constructor(data, error) {
        this.data = data;
        this.error = error;
        if (data === undefined && error === undefined) {
            throw new Meta_1.Meta("CannotAllUndefined", "Cannot be all undefined");
        }
    }
}
exports.TryResult = TryResult;
async function tryPromise(promise) {
    try {
        const data = (await promise) ?? Empty_1.Empty.value;
        return new TryResult(data, undefined);
    }
    catch (error) {
        return new TryResult(undefined, error);
    }
}
exports.tryPromise = tryPromise;
function tryBlock(block) {
    try {
        const data = block() ?? Empty_1.Empty.value;
        return new TryResult(data, undefined);
    }
    catch (error) {
        return new TryResult(undefined, error);
    }
}
exports.tryBlock = tryBlock;
async function retryPromise(promise, retryCount = 1) {
    try {
        const data = (await promise) ?? Empty_1.Empty.value;
        return new TryResult(data, undefined);
    }
    catch (error) {
        if (retryCount <= 0) {
            return new TryResult(undefined, error);
        }
        return retryPromise(promise, retryCount - 1);
    }
}
exports.retryPromise = retryPromise;
function sleep(seconds) {
    return new Promise(resolve => setTimeout(resolve, seconds));
}
exports.sleep = sleep;
