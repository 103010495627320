import style from "../components-css/LogicLayer.module.css";
import ThreeBotsLoading from "./ThreeBotsLoading";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import func from "@uikit/func";

export default function LogicLayer(props) {
  const listVariants = {
    init: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeInOut",
      },
    },
  };

  const [logicList, setLogicList] = useState([]);

  const logicInputChange = e => {
    const searchText = e.target.value.toLowerCase();
    const filterArr = props.searchData
      .map(x => {
        const title = x.groupTitle.toLowerCase();
        const filterValues = x.values.filter(
          y => y.title.toLowerCase().includes(searchText.toLowerCase()) || title.includes(searchText)
        );
        return Object.assign({}, x, {
          values: filterValues,
        });
      })
      .filter(x => !func.isEmpty(x.values));
    setLogicList(filterArr);
  };

  useEffect(() => {
    setLogicList(props.searchData);
  }, [props.searchData]);

  return (
    <div className={style["logic-layer"]}>
      <p className={style["title"]}>Logic Layer</p>
      <p className={style["text"]}>
        The system matches your thinking app with the relevant intent-aware functions and App view. Try drag and drop
        the ones you'olike to use to the App view on the right.
      </p>

      <input className={style["logic-input"]} placeholder="Search functions" onChange={logicInputChange}></input>

      <Droppable droppableId="logic-layer">
        {provided => (
          <div className={style["api-list"]} {...provided.droppableProps} ref={provided.innerRef}>
            {!props.searchLoading ? (
              <motion.div variants={listVariants} initial="init" animate="show">
                {!func.isEmpty(logicList) ? (
                  logicList.map((x, index) => {
                    return (
                      <div className={style["logic-api-box"]} key={index}>
                        <p className={style["api-title"]}>{x.groupTitle}</p>
                        {x.values.map((child, cIdx) => {
                          return (
                            <Draggable
                              key={`logic-${child.title}`}
                              draggableId={`${child.title}:${child.description}`}
                              index={cIdx}
                            >
                              {provided => (
                                <div
                                  className={style["api-child-box"]}
                                  key={cIdx}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <p className={style["api-child-title"]}>{child.title}</p>
                                  <p className={style["api-child-description"]}>{child.description}</p>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <p style={{ textAlign: "center" }}>No Data~</p>
                )}
              </motion.div>
            ) : (
              <ThreeBotsLoading class="layer-logic-loading" />
            )}
          </div>
        )}
      </Droppable>
    </div>
  );
}
