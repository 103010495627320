"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebsocketService = void 0;
const WebsocketResponse_1 = require("../model/WebsocketResponse");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const Meta_1 = require("../../cutil/Meta");
const ErrorUtil_1 = require("../../api/ErrorUtil");
class WebsocketService {
    constructor(messageCenter) {
        this.messageCenter = messageCenter;
    }
    on(pubsub_topic, identityInfo, listener) {
        this.messageCenter.on(pubsub_topic, (res) => {
            const response = JsonUtil_1.JsonUtil.toModelFromType(WebsocketResponse_1.WebsocketResponse, res);
            if (!response) {
                if (listener) {
                    listener(undefined, new Meta_1.Meta("Webscoket Error", ErrorUtil_1.ErrorUtil.defaultErrorMsg));
                }
            }
            else {
                if (response.succeed) {
                    // if (!response.isPlaceholderMessage) {
                    //   this.off(pubsub_topic);
                    // }
                    if (listener) {
                        listener(response, undefined);
                    }
                }
                else {
                    if (listener) {
                        listener(undefined, new Meta_1.Meta("Webscoket Error", response.errorMsg || ErrorUtil_1.ErrorUtil.defaultErrorMsg));
                    }
                }
            }
        }, `${identityInfo?.projectId}_${identityInfo?.edgeId}`);
    }
    off(pubsub_topic) {
        this.messageCenter.off(pubsub_topic);
    }
}
exports.WebsocketService = WebsocketService;
