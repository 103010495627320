import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { getIt } from "@uikit/getIt";
import { BrainClient as CorekitBrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { StudioProject } from "imagica-corekit/dist/base/project/domain/StudioProject";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { cloneDeep } from "lodash";
import store from "@store/index";
import { fotActions } from "@store/fot";
import { studioActions } from "@store/studio";
import { editorActions } from "@store/editor";
import { DEFALUT_PROJECT_NAME } from "@views/thinking-layout-editor/constants";
import { logEvent } from "@uikit/service/amplitude";
import { NavigateFunction } from "react-router-dom";
import { CotStore } from "@uikit/store/CotStore";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { AddNode } from "@uikit/service/AddNode";
import { FotSaveEvent } from "@uikit/service/FotSaveEvent";

const setDisableAddPreview = (val: boolean): void => {
  store.dispatch(fotActions.setDisableAddPreview(val));
};
const setLoadingState = (val: { promptSaveBtn: boolean; promptNewBtn: boolean; promptCard: boolean }): void => {
  store.dispatch(studioActions.setLoadingState(val));
};
const setSelectedTemplate = (val: any): void => {
  store.dispatch(studioActions.setSelectedTemplate(val));
};
const setStudioProjectName = (val: any): void => {
  store.dispatch(fotActions.setStudioProjectName(val));
};
const setShowTooltip = (val: string): void => {
  store.dispatch(editorActions.setShowTooltip(val));
};
const setEnableTooltipWhatTodo = (val: boolean): void => {
  store.dispatch(editorActions.setEnableTooltipWhatTodo(val));
};
const setSecondInputNodeId = (val: null): void => {
  store.dispatch(editorActions.setSecondNodeId(val));
};

export class CreateNewProjectService {
  constructor(
    public brainClient: CorekitBrainClient,
    public cotStore: CotStore,
    public homeMethods: HomeMethodsService,
    public addNode: AddNode,
    public fotSaveEvent: FotSaveEvent,
    public showNavPageStore: ShowNavPageStore
  ) {}
  createTemplate = async (templateName: string): Promise<any> => {
    const dataVersion = "v3";
    const params = {
      // 情况项目名两边多余空格
      template_name: templateName.trim(),
      description: "",
      [dataVersion]: {
        edges: [],
        nodes: [],
        showToolTipStatus: "init", // 初始化 init; 显示 show; 关闭 close；
        nodeIndex: 0,
        edgeIndex: 0,
      },
    };
    // 重置node index
    creatorRefStore.nodeIndexRef.current = 1;
    setSecondInputNodeId(null);
    // 清空node
    // emptyNodes();
    this.cotStore.setShowCot(false);

    // selectedFlowItem.value = {};
    // const imagicaNewProjectOnboarding = await preferencesStore.getByKey(IMAGICA_NEW_PROJECT_ONBOARDING);
    // if (imagicaNewProjectOnboarding) {
    //   const data =
    //     typeof imagicaNewProjectOnboarding === "string"
    //       ? JSON.parse(imagicaNewProjectOnboarding)
    //       : imagicaNewProjectOnboarding;
    //   if (
    //     !feature_tags.disableMultiRoundChatOnboarding &&
    //     getIt(ChatOnboardingStore).state.selectTemplateInModal === ChoosingFormat.AUTOIMAGICA
    //   ) {
    //     newOnboardingFlowStore.setOpenOptTempLateModal(true);
    //   } else {
    //     newOnboardingFlowStore.setOpenOptTempLateModal(!data.dontShowAgain);
    //   }
    //   if (data.dontShowAgain) {
    //     // 勾选了下次不再显示，则默认选择thinkingCanvas
    //     newOnboardingFlowStore.setSelectedFlowItem(
    //       newOnboardingFlowStore.getItemByKeyword("ownFlow", "thinkingCanvas") as any
    //     );
    //   }
    // } else {
    //   newOnboardingFlowStore.setOpenOptTempLateModal(true);
    // }

    newOnboardingFlowStore.setOpenOptTempLateModal(true);

    const selectedTemplateObj = cloneDeep(params) as any;
    selectedTemplateObj.name = selectedTemplateObj.name || templateName;
    const projectObj = {
      attributes: selectedTemplateObj,
    };
    const projectJsonStr = JsonUtil.stringify(projectObj);
    const projectData = JsonUtil.toModelFromType(StudioProject, projectJsonStr) as any;
    const projectRes = (await this.brainClient.graphql.createStudioProject(projectData)) as any;
    const resTemplateObj = projectRes.attributesV2;
    resTemplateObj.id = projectRes.id;
    resTemplateObj.uuid = projectRes.uuid;
    resTemplateObj.attrIndexer = projectRes.attrIndexer;
    resTemplateObj.updatedAt = "";

    setStudioProjectName(resTemplateObj.name);
    setSelectedTemplate(resTemplateObj || {});

    logEvent("create_project");

    return projectRes;
    // navigate(`/editor/${projectRes.uuid}`);
    // logEvent("create_project");
  };

  addNewProject = async (navigate?: NavigateFunction): Promise<any> => {
    try {
      this.homeMethods.emptyNodes();

      const projectRes = await this.createTemplate(DEFALUT_PROJECT_NAME);
      setEnableTooltipWhatTodo(true);
      // // 有 todotip 的节点移动视口到合适的位置
      // setTimeout(() => {
      //   // 居中节点，但是需要减掉 todoTip 的高度
      //   focusLastNode({ height: -160 });
      // }, 200);
      setShowTooltip("editor-1");
      setDisableAddPreview(true);
      setLoadingState({
        promptSaveBtn: false,
        promptNewBtn: false,
        promptCard: false,
      });

      // 修改渲染 project 或 canvas 页面的状态，保证进入 homeRoot 后状态正确性
      this.showNavPageStore.setShowNavPage(false);
      // FIXME: 目前 navigate 在 fotSaveEvent 中，后期移除
      (navigate || this.fotSaveEvent.dependencies.navigate)?.(`/editor/${projectRes.uuid}`);

      // 有 todotip 的节点移动视口到合适的位置
      setTimeout(() => {
        // 居中节点，但是需要减掉 todoTip 的高度
        this.addNode.focusLastNode({ height: -160 });
      }, 200);
    } catch (err) {
      console.error(err);
    }
  };
}
