"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class ATheme {
}
exports.ATheme = ATheme;
ATheme.theme = {
    chatAiTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.chatAiTheme,
        chatBackground: "linear-gradient(119deg, rgba(243, 245, 246, 0.95) 27.85%, rgba(255, 255, 255, 0.00) 100.38%), linear-gradient(90deg, #E0C2B8 37.5%, #C6C7E2 68.23%, #C6DBE2 100%)",
        chatBackgroundPad: "linear-gradient(119deg, rgba(243, 245, 246, 0.95) 27.85%, rgba(255, 255, 255, 0.00) 100.38%), linear-gradient(90deg, #E0C2B8 37.5%, #C6C7E2 68.23%, #C6DBE2 100%)",
        chatBackgroundMobile: "linear-gradient(119deg, rgba(243, 245, 246, 0.95) 27.85%, rgba(255, 255, 255, 0.00) 100.38%), linear-gradient(90deg, #E0C2B8 37.5%, #C6C7E2 68.23%, #C6DBE2 100%)",
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBarBorderColor: "transparent",
        hightLightBorderLeft: "0",
        inputPaddingLeft: "25px",
        inputPadding: "15px 25px",
        inputBorderRadius: "30px",
        inputBackground: "rgba(255, 255, 255, 0.65)",
        inputBoxShadow: " 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 10px 20px 0px rgba(0, 0, 0, 0.05)",
        buttonBackground: "rgba(255, 255, 255, 0.65)",
        buttonColor: "rgba(18, 25, 43, 0.65)",
        hintTextColor: "#0A9DFF",
        toolTipBoxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 10px 20px 0px rgba(0, 0, 0, 0.05)",
    },
};
