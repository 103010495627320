import { EdgeState, StoryEdge } from "imagica-corekit/dist/base/storyV2/domain/StoryEdge";
import { chain, get } from "lodash";
import func from "@uikit/func";
import {
  COMPLETE_LINE_STYLE,
  COMPLETE_MARKER_END,
  EDGE_STATE_CONFIG,
  ERROR_LINE_STYLE,
  ERROR_MARKER_END,
  TRANSPARENT_MARKER_END,
} from "../../views/thinking-layout-editor/constants";
import { Identifier } from "imagica-corekit/dist/base/api/IdentifierTyped/Identifier";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { EdgeRunCore } from "./EdgeRunCore";

/**
 * 原来 useNewEdgeRun hook 更新数据的方法
 *
 */
export class EdgeRunUpdater {
  constructor(
    public core: EdgeRunCore,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public creatorPreviewService: CreatorPreviewService
  ) {}

  updateLineState = (
    id: string,
    state: EdgeState,
    uselessLineIds: string[],
    lineId: {
      sourceIds: string[];
      target: string;
    }
  ): void => {
    if (func.isEmpty(id) || func.isEmpty(state)) return;
    // 修改line的状态
    this.core.creatorEdgesStore.setEdges((prevList: any) => {
      return prevList.map((edge: any) => {
        if (lineId.sourceIds.includes(edge.id) || edge.id === lineId.target) {
          let lineStyle =
            state === EdgeState.LOADING
              ? COMPLETE_LINE_STYLE
              : state === EdgeState.ERROR
              ? ERROR_LINE_STYLE
              : COMPLETE_LINE_STYLE;
          if (uselessLineIds.includes(edge.id)) {
            lineStyle = ERROR_LINE_STYLE;
          }
          return {
            ...edge,
            animated: state === EdgeState.LOADING,
            style: lineStyle,
            markerEnd: lineId.sourceIds.includes(edge.id)
              ? TRANSPARENT_MARKER_END
              : state === EdgeState.LOADING
              ? COMPLETE_MARKER_END
              : state === EdgeState.ERROR
              ? ERROR_MARKER_END
              : COMPLETE_MARKER_END,
            data: {
              ...edge.data,
              stateConfig:
                state === EdgeState.LOADING ? EDGE_STATE_CONFIG[EdgeState.COMPLETED] : EDGE_STATE_CONFIG[state],
            },
          };
        }
        return edge;
      });
    });
  };

  updataEdgeRunDisable = (id: string, state: EdgeState): void => {
    const needDisableEdgeIds = this.core.fotReduxStore.getState().editor.needDisableEdgeIds;
    // 加载中或者完成
    if (state === EdgeState.LOADING || state === EdgeState.COMPLETED) {
      const closeRunDisable = needDisableEdgeIds.filter((x: any) => x !== id);
      this.core.fotReduxStore.setNeedDisableEdgeIds(closeRunDisable);
    }

    // 失败
    if (state === EdgeState.ERROR) {
      this.core.fotReduxStore.setNeedDisableEdgeIds([]);
    }
  };

  /**
   * TODO: move to creatorNodeStore
   */
  updateEdgeState = (id: string, state: EdgeState, identifier?: Identifier, storyEdge?: StoryEdge): void => {
    if (func.isEmpty(id) || func.isEmpty(state)) return;
    // 修改边的状态及identifier(可选)
    this.core.creatorNodesStore.setNodes((prevList: any) => {
      return prevList.map((edge: any) => {
        if (edge.id === id) {
          return {
            ...edge,
            data: {
              ...edge.data,
              lineParam: {
                ...edge.data.lineParam,
                loading: state === EdgeState.LOADING,
                identifier: identifier ?? edge.data.lineParam.identifier,
                description: storyEdge?.options.actionData.description,
              },
            },
          };
        }
        return edge;
      });
    });
  };

  /**
   * TODO: move to creatorNodeStore
   */
  updateEdgeBlueprintMessage = (id: string | undefined, message: string): void => {
    if (func.isEmpty(id) || func.isEmpty(message)) return;
    const newNodes = chain(this.core.creatorNodesStore.getNodes())
      .map(edge => {
        if (edge.id === id) {
          const blueprintMessages: string[] = get(edge, "data.blueprintMessages", []);
          if (message === "done" && blueprintMessages.includes("done")) {
            return edge;
          }
          blueprintMessages.push(message);
          return {
            ...edge,
            data: {
              ...edge.data,
              blueprintMessages,
            },
          };
        }
        return edge;
      })
      .value();
    this.core.creatorNodesStore.setNodes(newNodes);
  };
  updateGroupOutput = (updateNode: any): void => {
    //单个node到组，更新saasuidata中的groupId
    const preNodeId = updateNode.options.id;
    const output = this.creatorSaasAppStore.state.saasUIData.output.map((item: any) => {
      if (item.results[0]?.nodeId === preNodeId) {
        return { ...item, groupId: updateNode.id };
      }
      return item;
    });
    this.creatorSaasAppStore.setSaasUIData(prevData => {
      return {
        ...prevData,
        output,
      };
    });
    //更新preview中的group
    this.updateGroupChildNode({
      groupId: updateNode.id,
      // bsf-4912 replace thoughts
      thoughts: output,
      // handleDeleteGroupNodeByPreviewBtn: this.creatorCanvasDeleteMethods.handleDeleteGroupNodeByPreviewBtn,
    });
  };

  updateGroupChildNode(params: {
    groupId: string;
    thoughts: AISaasOutput.ThoughtType[];
    // handleDeleteGroupNodeByPreviewBtn: (groupId: string) => any;
  }): void {
    const { groupId, thoughts } = params;
    const index = thoughts.findIndex((x: any) => x.groupId === groupId);
    if (index === -1) return;

    //不用删除原来的group 在addGroupNodeToPreview 方法中会根据groupChildeNodes生成新的output
    //  handleDeleteGroupNodeByPreviewBtn(groupId);
    //添加新的子node
    const groupChildeNodes = this.core.creatorNodesStore.getNodes().filter((x: any) => x.parentNode === groupId);
    const childList = this.creatorPreviewService.addGroupNodeToPreview(groupChildeNodes, true);
    const copyThought = thoughts.filter((x: any) => x.groupId !== groupId);
    copyThought.splice(index, 0, ...childList);

    console.log("list :>> ", copyThought);

    // bsf-4912 use setSassUIData
    this.creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: copyThought }));
  }
}
