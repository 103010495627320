import { UploadType } from "../typing";

export type FileFormatConfig = Record<UploadType, { formats: string[]; featureTag: string }>;

export const fileFormatConfig: FileFormatConfig = {
  baseVideo: { formats: ["MP4", "WebM", "Ogg"], featureTag: "" },
  unStructured: {
    formats: [],
    featureTag: "disable_upload_node_unStructured",
  },
  table: { formats: ["xlsx", "csv"], featureTag: "" },
  audio: { formats: ["WAV", "mp3", "Ogg", "m4a"], featureTag: "" },
  uploadFile: {
    formats: [],
    featureTag: "",
  },
  pdfFile: {
    formats: ["pdf"],
    featureTag: "",
  },
  uploadImage: {
    formats: ["JPEG", "PNG", "GIF", "BMP", "WebP", "SVG", "ICO", "JPG", "SVG"],
    featureTag: "disable_upload_node_image",
  },
  html: {
    formats: ["html"],
    featureTag: "disable_upload_node_html",
  },
};
