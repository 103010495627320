import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Input, Spin } from "antd";

import { studioActions } from "../store/studio";
import { fotActions } from "../store/fot";
import { Api } from "../uikit/service/api";

const { TextArea } = Input;

const ThoughtInput = props => {
  const dispatch = useDispatch();
  const api = new Api(false);

  const thought = useSelector(state => state.studio.selectedTemplate?.variables?.thought?.value);
  const setThought = val => {
    dispatch(studioActions.setThought(val));
  };

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);
  const setSelectedTemplate = val => {
    dispatch(studioActions.setSelectedTemplate(val));
  };

  const setVariables = val => {
    dispatch(studioActions.setVariables(val));
  };

  // local state
  const [loading, setLoading] = useState(false);

  // methods
  const handleChange = e => {
    setThought(e.target.value);
  };

  // const handleEnter = (e) => {
  //   updateThought();
  // }

  const updateThought = () => {
    dispatch(fotActions.setFetching(true));
    setLoading(true);
    const data = {
      template_name: selectedTemplate.name,
      vname: "thought",
      vtype: "string",
      vvalue: thought,
    };
    api
      .post(`/be/bas-demo-v4/brain_studios/update_variable`, data)
      .then(response => response.json())
      .then(data => {
        const variablesData = data[0];
        setVariables(variablesData);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoading(false);
      });
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <TextArea
        disabled={!selectedTemplate.name || props.UIMode === "editorPreivewMode"}
        rows={2}
        placeholder="Enter thought..."
        value={thought}
        onChange={handleChange}
        onPressEnter={updateThought}
        onBlur={updateThought}
      />
    </Spin>
  );
};

export default ThoughtInput;
