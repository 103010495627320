"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatInterfaceAdapter = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const FunctionRegistry_1 = require("../../storyV2/function/FunctionRegistry");
class ChatInterfaceAdapter extends AdapterBase_1.AdapterBase {
    runResponseType() {
        return AdapterBase_1.RunResponseType.CHAT_INTERFACE;
    }
    async runNode(node, actionData, story, edge) {
        const func = this.funcRegistry.getByRegistName(FunctionRegistry_1.FunctionName.chatInterface);
        const functionParams = this.funcRegistry.getByRegistName(FunctionRegistry_1.FunctionName.param).getByType(FunctionRegistry_1.FunctionName.chatInterface);
        const context = {
            isNested: story.isNested,
        };
        const requestParams = await functionParams.getParams(actionData, node, context);
        const response = await func.run(node.content, { projectId: story.projectId, edgeId: edge.options.id, templateId: story.templateId }, undefined, undefined, undefined, undefined, requestParams, undefined, node.options.displayType);
        return response.data;
    }
}
exports.ChatInterfaceAdapter = ChatInterfaceAdapter;
