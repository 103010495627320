"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CotConstant = exports.MarkerType = void 0;
var MarkerType;
(function (MarkerType) {
    MarkerType["Arrow"] = "arrow";
    MarkerType["ArrowClosed"] = "arrowclosed";
})(MarkerType || (exports.MarkerType = MarkerType = {}));
class CotConstant {
}
exports.CotConstant = CotConstant;
CotConstant.uploadTypes = [
    "uploadFile",
    "uploadImage",
    "baseVideo",
    "unStructured",
    "table",
    "pdfFile",
    "audio",
    "html",
];
// todo 需要一个完整的和后端共享的Map数据
CotConstant.nodeTypeMap = {
    "stock info": "stockInfo",
    "weee groceries": "weeeShopping",
    "amazon shopping": "amazonShopping",
    image: "imageGen",
    blueprint: "blueprint",
    video: "video",
    web: "htmlTemplate",
    text: "text",
    basevideo: "baseVideo",
    audio: "audio",
    uploadimage: "uploadImage",
    pdffile: "pdfFile",
    uploadfile: "uploadFile",
};
CotConstant.defaultMarkerEnd = {
    type: MarkerType.ArrowClosed,
    width: 12,
    height: 8,
    color: "#B0B0B7",
};
CotConstant.transparentMarkerEnd = {
    type: MarkerType.ArrowClosed,
    width: 12,
    height: 8,
    color: "transparent",
};
CotConstant.defaultLineStyle = {
    strokeWidth: 2,
    stroke: "#B0B0B7",
};
CotConstant.webAppDefaultTitle = "Untitled";
CotConstant.webAppDefaultSubTitle = "Welcome! This is a generative AI web app built in Imagica. Give it a try!";
CotConstant.isGroupTypes = ["Split Output", "Show Stock Interface"];
