import React from "react";
import func from "@uikit/func";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
    };
  }

  componentDidCatch(error, _) {
    this.setState({ hasError: true });
    this.setState({ errorMessage: error.message });
    //Do something with err and errorInfo
  }

  render() {
    if (this.state?.hasError) {
      func.messageUtil(this.state.errorMessage);
      return this.props.children;
    }
    return this.props.children;
  }
}
