import { Dropdown, Spin, Switch } from "antd";
import style from "../components-css/UserAvatarV2.module.css";
import useGetUserAvatarUrl from "../../custom-hooks/useGetUserAvatarUrl";
import func, { matchMobile } from "@uikit/func";
import { LoadingOutlined } from "@ant-design/icons";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { callback, getAssetsIcon } from "@views/thinking-layout-editor/utilities";
import { useCallback, useMemo, useState, useEffect } from "react";
import cls from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { fotActions } from "@store/fot";
import { getIt } from "@uikit/getIt";
import { UiFunctionService } from "@uikit/service/UiFunctionService";
import { cloneDeep } from "lodash";
import { PowerMaskView } from "@uiview/views/PowerMask/PowerMaskView";
import { DropdownMonetizationDashboardIcon } from "@views/components/DropdownMonetizationDashboard/DropdownMonetizationDashboardIcon";
import { useSignal } from "@preact/signals-react";
import LoadingOutline from "@views/components/LoadingOutline";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { getQueryParams } from "@views/thinking-layout-editor/util";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { PowerMaskUitl } from "@uiview/views/PowerMask/PowerMaskUtil";
import { isEmpty } from "@uikit/login";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { UserIcon } from "@views/assets/user";
import { LogOutIcon } from "@views/assets/logout";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { AblyStore } from "imagica-corekit/dist/base/store/AblyStore";

const ProfileIcon = {
  myAccount: <UserIcon />,
  logout: <LogOutIcon />,
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

/**
 * @callback onDropdownItemClick
 * @param {DropdownItem} DropdownItem
 * @returns {void}
 */

/**
 * @typedef {object} DropdownItem
 * @property {string} key
 * @property {string} className
 * @property {boolean} closed
 * @property {string} icon
 * @property {string} label
 */

/**
 * @param {object} params
 * @param {DropdownItem[]} params.dropdownItems
 * @param {onDropdownItemClick} params.onDropdownItemClick
 * @param {string | undefined} params.className
 * @param {boolean | undefined} params.userDisable
 * @returns
 */
const UserAvatarV2 = ({ dropdownItems = [], onDropdownItemClick = callback, className = "", userDisable }) => {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const ablyStore = getIt(AblyStore);
  const ablyStoreState = useImagicaStore(ablyStore).value;
  const homeStoreState = useStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;
  const dispatch = useDispatch();
  const userMe = useSelector(state => state.editor.me);
  const { userAvatarUrl, userLoading } = useGetUserAvatarUrl();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const developerMode = useSelector(state => state.fot.developerMode);
  const enableExperimentalFeature = useSelector(state => state.fot.enableExperimentalFeature);
  const enableToolboxFeature = useSelector(state => state.fot.enableToolboxFeature);
  const enableDesignSpaceFeature = useSelector(state => state.fot.enableDesignSpaceFeature);
  const showStudiosDevMode = (userMe?.feature_tags ?? []).every(x => x !== "disable_studios_dev_mode");
  const showExperimentalFeaturesToggle = (userMe?.feature_tags ?? []).every(x => x !== "disable_studios_exp_features");
  const isMonetizationLoading = useSignal(false);
  const subscriptionStore = getIt(PublishSubscriptionStore);
  const monetizationFeatureName = "aaas_monetization";
  // null表示还未请求到权限，此时不显示Monetization Dashboard选项
  const hasMonetizationPermission = useSignal(null);

  const handleDropdownOpenChange = useCallback(
    /** @param {boolean} visible */
    visible => {
      homeToolBarStore.setAllowZoomOrPan(!visible);
      setIsDropdownOpen(visible);
    },
    []
  );

  const handleDropdownItemClick = useCallback(
    /** @param {DropdownItem} dropdownItem */
    dropdownItem => () => {
      if (dropdownItem.closed) {
        handleDropdownOpenChange(false);
      }
      onDropdownItemClick(dropdownItem);
    },
    [handleDropdownOpenChange, onDropdownItemClick]
  );

  const handleDeveloperModeChange = useCallback(
    checked => {
      dispatch(fotActions.setDeveloperMode(checked));
      getIt(UiFunctionService).dirty();
    },
    [dispatch]
  );

  const handleExperimentalChange = useCallback(
    checked => {
      dispatch(fotActions.setEnableExperimentalFeature(checked));
      getIt(UiFunctionService).dirty();
    },
    [dispatch]
  );

  const handleToolboxChange = useCallback(
    checked => {
      dispatch(fotActions.setEnableToolboxFeature(checked));
      getIt(UiFunctionService).dirty();
    },
    [dispatch]
  );

  const handleDesignSpaceChange = useCallback(
    checked => {
      dispatch(fotActions.setEnableDesignSpaceFeature(checked));
      getIt(UiFunctionService).dirty();
    },
    [dispatch]
  );

  const handleMonetizationClick = useCallback(async () => {
    if (!hasMonetizationPermission.value) {
      return;
    }
    if (isMonetizationLoading.value) {
      return;
    }
    isMonetizationLoading.value = true;
    const linkResult = await tryPromise(subscriptionStore.getMonetizationInfo(getQueryParams().type));
    isMonetizationLoading.value = false;
    if (linkResult.error) {
      func.messageUtil("Get stripe link failed", "error");
    } else {
      const result = linkResult.data;
      window.open(result.link, result.isLinkToDashboard ? "_blank" : "_self");
    }
  }, [isMonetizationLoading.value]);

  const imageUrl = useMemo(() => {
    if (!func.isEmpty(userAvatarUrl)) {
      return userAvatarUrl;
    }
    return `${settings.S3CDN}${settings.viewAssetsPath}default.jpg`;
  }, [userAvatarUrl]);

  useEffect(() => {
    const handleStripeReturn = async () => {
      const stripeReturnSign = "?isStripeRegistrationSuccess=true";
      if (window.location.href.includes(stripeReturnSign)) {
        const newUrl = window.location.href.replace(/\?isStripeRegistrationSuccess=true/g, "");
        window.history.replaceState({}, "", newUrl);

        const linkResult = await tryPromise(subscriptionStore.getStripeDashboardLinkSafely());
        if (linkResult.error) {
          func.messageError(linkResult.error);
        } else if (!isEmpty(linkResult.data)) {
          window.open(linkResult.data);
        }
      }
    };

    handleStripeReturn();
  }, []);

  useEffect(() => {
    async function getMonetizationPermission() {
      hasMonetizationPermission.value = !(await PowerMaskUitl.disAbleByFeature([monetizationFeatureName]));
    }

    getMonetizationPermission();
  }, []);

  const dropdownItemsCount = dropdownItems.length;
  const dropdownItemsIsNotEmpty = dropdownItemsCount > 0;
  const monetizationDashboardInsertIndex = dropdownItemsIsNotEmpty ? dropdownItemsCount - 1 : 0;
  const validDropdownItems = cloneDeep(dropdownItems);
  const monetizationDashboardTitle = "Monetization Dashboard";
  validDropdownItems.splice(monetizationDashboardInsertIndex, 0, { key: monetizationDashboardTitle });

  const renderIcon = label => {
    return ProfileIcon[label] || undefined;
  };

  return (
    <div>
      {userLoading && <Spin indicator={antIcon} />}
      {!userLoading && (
        <div className={cls(style.dropdown, className, !userDisable ? style.cursorPointer : style.cursorPointNoDrop)}>
          <Dropdown
            open={isDropdownOpen}
            onOpenChange={handleDropdownOpenChange}
            trigger={["hover"]}
            placement="bottomRight"
            getPopupContainer={e => e}
            overlay={
              <div
                className={cls(style.dropdown_content, {
                  [style.dropdown_content_mobile]: isMobile && featureTags.disableMobilePage,
                })}
              >
                <p className={style.dropdownTitle}>Account</p>
                {validDropdownItems.map((dropdownItem, index) =>
                  index === monetizationDashboardInsertIndex ? (
                    featureTags.enableStudiosPaywall && hasMonetizationPermission.value != null ? (
                      <div key={monetizationDashboardTitle} className={style.dropdown__item}>
                        {isMonetizationLoading.value ? (
                          <div className={style.dropdownItemLoadingContainer}>
                            <LoadingOutline />
                          </div>
                        ) : (
                          <PowerMaskView
                            feature={[monetizationFeatureName]}
                            featureItem={"monetization_dashboard"}
                            defaultWallName={"Business Plan"}
                            onClickUpgrade={() => setIsDropdownOpen(false)}
                          >
                            <div className={style.dropdown__container} onMouseDown={() => handleMonetizationClick()}>
                              <div className={style.dropdownItem}>
                                <div className={style.dropdownIconBox}>
                                  <DropdownMonetizationDashboardIcon />
                                </div>
                                <span className={style.dropdownItem__text}>{monetizationDashboardTitle}</span>
                                {/* <DropdownMonetizationDashboardShareIcon /> */}
                              </div>
                            </div>
                          </PowerMaskView>
                        )}
                      </div>
                    ) : null
                  ) : (
                    <div
                      key={dropdownItem.key}
                      className={style.dropdown__item}
                      // bsf-5585
                      onMouseDown={handleDropdownItemClick(dropdownItem)}
                    >
                      <div className={style.dropdown__container}>
                        <div className={style.dropdownItem}>
                          <div className={style.dropdownIconBox}>{renderIcon(dropdownItem.key)}</div>
                          <span className={style.dropdownItem__text}>{dropdownItem.label}</span>
                          {dropdownItem.showWebsocketStatus && (
                            <span
                              className={style.ablyStatus}
                              style={{ backgroundColor: ablyStoreState.ablyConnected ? "green" : "red" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
                {showStudiosDevMode && (
                  <div className={style.dropdown__item}>
                    <div className={style.dropdown__container}>
                      <div className={cls(style.dropdownItem, style.dropdownSwitchBox)}>
                        <span className={style.dropdownItem__text}>Developer mode &nbsp;</span>
                        <Switch checked={developerMode} onChange={handleDeveloperModeChange} />
                      </div>
                    </div>
                  </div>
                )}
                {showExperimentalFeaturesToggle && (
                  <div className={style.dropdown__item}>
                    <div className={style.dropdown__container}>
                      <div className={cls(style.dropdownItem, style.dropdownSwitchBox)}>
                        <span className={style.dropdownItem__text}>Experimental &nbsp;</span>
                        <Switch checked={enableExperimentalFeature} onChange={handleExperimentalChange} />
                      </div>
                    </div>
                  </div>
                )}
                {featureTags.showEdgeBluePrint && (
                  <div className={style.dropdown__item}>
                    <div className={style.dropdown__container}>
                      <div className={cls(style.dropdownItem, style.dropdownSwitchBox)}>
                        <span className={style.dropdownItem__text}>ToolBox &nbsp;</span>
                        <Switch checked={enableToolboxFeature} onChange={handleToolboxChange} />
                      </div>
                    </div>
                  </div>
                )}
                {featureTags.showDesignSpaceMenu && (
                  <div className={style.dropdown__item}>
                    <div className={style.dropdown__container}>
                      <div className={cls(style.dropdownItem, style.dropdownSwitchBox)}>
                        <span className={style.dropdownItem__text}>Design Space &nbsp;</span>
                        <Switch checked={enableDesignSpaceFeature} onChange={handleDesignSpaceChange} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
          >
            <div className={style.profile}>
              <img className={style.profile__image} src={imageUrl} alt="avatar" data-testid="user-avatar-img" />
            </div>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default UserAvatarV2;
