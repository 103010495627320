"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImagicaTermsPopup = void 0;
const class_transformer_1 = require("class-transformer");
const JsonUtil_1 = require("../../../base/cutil/JsonUtil");
class ImagicaTermsPopup {
    constructor() {
        this.version = "";
        this.title = "";
        this.content = [];
        this.buttons = [];
    }
}
exports.ImagicaTermsPopup = ImagicaTermsPopup;
__decorate([
    (0, class_transformer_1.Type)(() => ContentItem)
], ImagicaTermsPopup.prototype, "content", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => ButtonsItem)
], ImagicaTermsPopup.prototype, "buttons", void 0);
class ContentItem {
    constructor() {
        this.type = "";
        this.value = "";
    }
}
__decorate([
    (0, class_transformer_1.Transform)(({ value }) => {
        if (typeof value === "string") {
            return value;
        }
        return JsonUtil_1.JsonUtil.toModelFromType(ContentItemLink, value);
    })
], ContentItem.prototype, "value", void 0);
class ContentItemLink {
    constructor() {
        this.text = "";
        this.link = "";
    }
}
class ButtonsItem {
    constructor() {
        this.type = "";
        this.text = "";
    }
}
__decorate([
    (0, class_transformer_1.Type)(() => ButtonsItemStyle)
], ButtonsItem.prototype, "style", void 0);
class ButtonsItemStyle {
    constructor() {
        this.background_color = "";
        this.color = "";
    }
}
