"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JDShoppingAdapter = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
class JDShoppingAdapter extends AdapterBase_1.AdapterBase {
    dynamicRunResponseTypeFunctionV2() {
        return AdapterBase_1.RunResponseType.FUNCTION_V2;
    }
    runResponseType() {
        return AdapterBase_1.RunResponseType.JDSHOPPING;
    }
}
exports.JDShoppingAdapter = JDShoppingAdapter;
