import { StudioProjectV3EdgeFlow } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { filter, findIndex, forEach, get } from "lodash";
import { useEffect } from "react";
import { Node } from "reactflow";
import { disableEdgesTypeArray } from "../nodeConfig";
import { DisplayType } from "../typing";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";

type UseRemoveConnectProps = { id: string; displayType: DisplayType };

export const useRemoveConnect = ({ id, displayType }: UseRemoveConnectProps) => {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);

  const removeConnect = () => {
    const restNodes: Node<any>[] = [];
    const needRemoveEdgeIds: string[] = [];

    forEach(creatorNodesStore.getNodes(), node => {
      const flows: StudioProjectV3EdgeFlow[] = get(node, "data.flows");

      const index = findIndex(flows, flow => {
        return flow.sourceNodeId === id;
      });

      if (index === -1) {
        restNodes.push(node);
      } else {
        needRemoveEdgeIds.push(node.data.targetLineId);
        forEach(flows, flow => {
          needRemoveEdgeIds.push(flow.sourceLineId!);
        });
      }
    });
    const restEdges = filter(creatorEdgesStore.getEdges(), edge => needRemoveEdgeIds.includes(edge.id) === false);

    creatorNodesStore.setNodes(restNodes);
    creatorEdgesStore.setEdges(restEdges);
  };

  useEffect(() => {
    if (disableEdgesTypeArray.includes(displayType)) {
      removeConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayType]);
};
