import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import style from "../components-css/ThoughtOfUGCItem.module.css";
import FeedContentSkeleton from "./FeedContentSkeleton";
import Retry from "./Retry";
import func from "@uikit/func";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ThoughtOfUGCItem(props) {
  return (
    <div className={style["thought-item"]}>
      {props.thought.loading ? (
        <FeedContentSkeleton skeletonfill={[".", ".", "."]} />
      ) : props.thought.showRetryView ? (
        <Retry ContentRetrieval={props.ContentRetrieval} content={props.thought} enterQuery={props.enterQuery} />
      ) : props.thought.type === "inputField" ? (
        // 顶部输入框
        <div className={style["top-input"]}>
          <span className={style["add-icon"]}>+</span>
          <input
            value={props.questionText}
            onChange={props.changeInput}
            onKeyUp={props.keyUpEvent}
            type="text"
            placeholder="Add your thought..."
            disabled={props.ugcInputLoading}
          />
          <Spin indicator={antIcon} spinning={props.ugcInputLoading} />
        </div>
      ) : props.thought.type === "openai" ? (
        <div className={style["thought-box"]}>
          {!func.isEmpty(props.thought.title) ? (
            <div className={style["subthought-box"]}>
              <div className={style["thought-title"]}>{props.thought.title}</div>
              <p className={style["thought-created"]}>Created by Jerry</p>
              {props.thought.value ? <div className={style["thought-content"]}>{props.thought.value}</div> : null}
            </div>
          ) : null}

          {!func.isEmpty(props.thought?.subthought?.value) ? (
            <div className={style["subthoughts-content"]}>
              {props.thought.subthought.title ? (
                <div className={style["subthought-title"]}>{props.thought.subthought.title}:</div>
              ) : null}
              {props.thought.subthought.value.map((subthought, sIdx) => {
                return (
                  <div
                    className={style["thought-content"]}
                    key={sIdx}
                    onClick={() => props.clickOpenaiSubThought(props.thought.title, subthought)}
                  >
                    {subthought}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : (
        <>
          {!func.isEmpty(props.thought.process) ? (
            <div className={style["item-top"]}>
              <div className={style["title-box"]}>
                <p>{props.thought.process}</p>
                <span className={(style.iconfont, style["icon-right"])}></span>
              </div>
              {/* 插槽 */}
              {/* {
                  props.children ?
                    props.children
                  : !props.thought.isLiked ?
                    <div className={style['thumb-box']} onClick={() => props.clickThumbLiked(props.tIdx, true)}>
                      <img src={`${settings.S3CDN}${settings.viewAssetsPath}thumb-up.png`} />
                      <span>Like and Save</span>
                    </div>
                    : <div className={style['thumb-box']} onClick={() => props.clickThumbLiked(props.tIdx, false)}>
                      <img src={`${settings.S3CDN}${settings.viewAssetsPath}thumbed-up.png`} />
                      <span className={style['thumbed-text']}>You’ve liked </span>
                    </div>
                } */}
            </div>
          ) : null}

          {/* subthoughts */}
          {!func.isEmpty(props.thought?.subthoughts) ? (
            <div className={style["item-bottom"]}>
              {props.thought.subthoughts.map((r, rIdx) =>
                !func.isEmpty(r.thought) ? (
                  <p key={rIdx} onClick={() => props.clickSubThought(props.tIdx, rIdx, r.thought)}>
                    <span>{r.thought}</span>
                  </p>
                ) : null
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default ThoughtOfUGCItem;
