export function AISaasLogoIcon() {
  return (
    <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0333 30C17.7129 29.4306 13.0638 26.4248 10.4344 20.5487C9.73345 18.9825 9.04143 17.4114 8.32656 15.8516C7.81652 14.7395 6.50348 14.3878 5.48911 15.0806C4.97091 15.4339 4.47393 15.817 3.96063 16.1775C3.55345 16.4622 3.07007 16.6213 2.57148 16.6348C2.07289 16.6483 1.58142 16.5155 1.15909 16.2533C0.329154 15.7339 -0.131103 14.7403 0.0329251 13.8225C0.220619 12.774 0.955075 12.0192 2.01269 11.8062C2.36362 11.7299 2.72737 11.732 3.07738 11.8123C3.42738 11.8927 3.7548 12.0493 4.03571 12.2708C4.53514 12.649 5.01498 13.0531 5.51033 13.4362C6.51735 14.2136 7.85569 13.9499 8.46366 12.8426C9.30828 11.3046 10.1341 9.75613 10.9755 8.21572C13.4237 3.73483 17.1776 1.00565 22.2657 0.197536C25.9615 -0.398852 29.7502 0.365888 32.9134 2.34672C36.0765 4.32756 38.3941 7.38674 39.4267 10.9441C41.0433 16.4976 39.1712 22.5649 34.6935 26.3183C32.3932 28.2711 29.5553 29.5006 26.5435 29.8492C25.738 29.9468 24.9236 29.9492 24.0333 30ZM13.6407 14.5959C13.4278 20.5261 18.1936 25.5344 24.2618 25.7562C30.33 25.978 35.4753 21.3108 35.6875 15.3669C35.8996 9.42305 31.1355 4.43084 25.064 4.20582C18.9925 3.98081 13.8546 8.64881 13.6407 14.5943V14.5959Z"
        fill="#12192B"
      />
    </svg>
  );
}
