"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationManager = exports.LocationCoordinate = void 0;
class LocationCoordinate {
    constructor(lat = 0, lng = 0) {
        this.lat = lat;
        this.lng = lng;
    }
    isEmpty() {
        return this.lat === 0 && this.lng === 0;
    }
}
exports.LocationCoordinate = LocationCoordinate;
class LocationManager {
}
exports.LocationManager = LocationManager;
