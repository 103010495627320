import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import func from "@uikit/func";
import useLinkParamsMetadata from "./useLinkParamsMetadata";
import { useSearchParams } from "react-router-dom";
import { Api } from "@uikit/service/api";
import { editorActions } from "@store/editor";
import { fotActions } from "@store/fot";
import { getIt } from "@uikit/getIt";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { logEvent } from "@uikit/service/amplitude";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { FotUserAuthService } from "@uikit/service/FotUserAuthService";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";

class Prop {
  page?: string = "signIn";
}

class Data {
  authorization_code?: string;
  id_token?: string;
  metadata?: any;
}

enum GAuthKey {
  IDToken = "g_id_token",
  AuthCode = "g_auth_code",
}

const errorMessage = (error: any = undefined): void => {
  console.error("google sign in:", error);
  func.messageError("Unable to sign in with Google at the moment, please try again.");
};

const authStore = getIt(FotAuthStore);

// eslint-disable-next-line
export default function useGoogleOauth(props: Prop) {
  const api = new Api(false);
  const dispatch = useDispatch();
  const fotUserAuthService = getIt(FotUserAuthService);
  const fotProcessManager = FotProcessManager.use();
  const { metadata } = useLinkParamsMetadata();
  const [searchParams, setSearchParams] = useSearchParams();
  const [buttonLoading, setButtonLoading] = useState(false);

  const isLogin = useSelector((state: any) => state.fot.isLogin);
  const setIsLogin = (val: boolean): void => {
    dispatch(fotActions.setIsLogin(val));
  };
  const setLoginMethod = (val: string): void => {
    dispatch(editorActions.setLoginMethod(val));
  };
  const setIsClickSignup = (val: boolean): void => {
    dispatch(editorActions.setIsClickSignup(val));
  };

  const homePluginStore: HomePluginStore = getIt(HomePluginStore);

  const handleGoogleSignInSuccess = async (response: any): Promise<void> => {
    try {
      setButtonLoading(true);
      const data: Data = {};
      if (response.code) {
        data.authorization_code = response.code;
      } else if (response.credential) {
        data.id_token = response.credential;
      }
      if (!func.isEmpty(metadata)) {
        data.metadata = metadata;
      }
      const res = await api.postWithError("/api/auth/google/imagica/token", data);
      const isExisting = (res as any).data?.existing;
      authStore.setBrainToken((res as any).data.token);

      await fotUserAuthService.getUserMe();

      setIsLogin(true);
      setLoginMethod("google");
      const studioData = homePluginStore.state.studioData;
      if (props.page == "signUp" || !isExisting) {
        setIsClickSignup(true);
        if (homePluginStore.state.isShare) {
          logEvent("webapp_signup_google", {
            guest_uuid: studioData?.guest_user_key,
            project_static_id: studioData?.attributes?.project_id,
          });
        } else {
          logEvent("signup_google");
        }
        await fotProcessManager.init();
      } else {
        setIsClickSignup(false);
        if (homePluginStore.state.isShare) {
          logEvent("webapp_login_google", {
            guest_uuid: studioData?.guest_user_key,
            project_static_id: studioData?.attributes?.project_id,
          });
        } else {
          logEvent("login_google");
        }
        await fotProcessManager.init();
      }
    } catch (error) {
      errorMessage(error);
      setButtonLoading(false);
    }
  };

  const signInUsingEmbeddedAuth = (): void => {
    const idToken = searchParams.get(GAuthKey.IDToken);
    const authCode = searchParams.get(GAuthKey.AuthCode);
    if (idToken) {
      searchParams.delete(GAuthKey.IDToken);
      setSearchParams(new URLSearchParams());
      handleGoogleSignInSuccess({ credential: idToken });
    } else if (authCode) {
      searchParams.delete(GAuthKey.AuthCode);
      setSearchParams(new URLSearchParams());
      handleGoogleSignInSuccess({ code: authCode });
    }
  };

  useEffect(() => {
    if (!isLogin) {
      signInUsingEmbeddedAuth();
    }
  }, []);

  return {
    handleGoogleSignInSuccess,
    errorMessage,
    buttonLoading,
    setButtonLoading,
  };
}
