import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Card, List, Radio, Select, Spin, Tag } from "antd";
import { PlayCircleFilled } from "@ant-design/icons";
import func from "@uikit/func";

import { studioActions } from "@store/studio";
import { fotActions } from "@store/fot";
import { feedActions } from "@store/feed";
import { Api } from "@uikit/service/api";
import studioPublicMethod from "@uikit/StudioPublicMethod";

const { Option } = Select;

const StudioLogicCard = () => {
  const dispatch = useDispatch();
  const api = new Api(false);

  // store states
  // const selectedLogic = useSelector((state) => state.studio.loadingState);
  // const setLoadingState = (val) => { dispatch(studioActions.setLoadingState(val)) };

  const loadingState = useSelector(state => state.studio.loadingState);
  const setLoadingState = val => {
    dispatch(studioActions.setLoadingState(val));
  };

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);

  const variables = useSelector(state => state.studio.selectedTemplate.variables);
  const setVariables = val => {
    dispatch(studioActions.setVariables(val));
  };

  const setThoughts = val => {
    dispatch(feedActions.setThoughts(val));
  };

  const setUpdateSelectedTemplate = val => {
    dispatch(studioActions.setUpdateSelectedTemplate(val));
  };

  const updateSelectedLogic = useSelector(state => state.studio.updateSelectedLogic);

  // local states
  const [selectedLogic, setSelectedLogic] = useState({});
  const [availableLogics, setAvailableLogics] = useState([]);

  const [inputs, setInputs] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [selectedInput, setSelectedInput] = useState({});
  const [selectedOutput, setSelectedOutput] = useState({});

  const variableCard = key => {
    const variable = variables?.[key] || {};
    return (
      <Card
        title={key}
        bodyStyle={{ display: "none" }}
        className="Studio-Logic-Item-Card"
        extra={<Tag color="magenta">{variable.type || "N/A"}</Tag>}
      ></Card>
    );
  };

  const IOCard = item => {
    const type = item.type;
    const options = item.options || [];
    const isNotSelectedInput = item.hasRadio && selectedInput.inputName && selectedInput.inputName !== item.name;
    return (
      <Card className="Studio-Logic-IO-Item-Card shadow-shallow">
        <p className="bold" style={{ marginBottom: "10px" }}>
          {item.hasRadio ? <Radio value={item.name}>{item.name}</Radio> : item.name}
          <Tag color="magenta" style={{ position: "absolute", right: "20px", fontWeight: 400 }}>
            {item.dtype || "N/A"}
          </Tag>
        </p>
        <p className="description" style={{ marginBottom: "15px" }}>
          {item.description}
        </p>
        <Select
          disabled={isNotSelectedInput}
          onChange={type === "input" ? handleSelectInput : handleSelectOutput}
          value={type === "input" ? (isNotSelectedInput ? "" : selectedInput.str) : selectedOutput.str}
          style={{ width: "100%" }}
        >
          {options.map((each, idx) => (
            <Option value={each} key={idx}>
              {variableCard(each)}
            </Option>
          ))}
        </Select>
      </Card>
    );
  };

  // list item
  const listItem = item => {
    let alternatives = [];
    if (item.OR) {
      alternatives = objToArr(item.OR);
    }
    if (alternatives.length > 0) {
      item.hasRadio = true;
      alternatives.forEach(each => (each.hasRadio = true));
    }
    return (
      <div>
        <Radio.Group onChange={handleSelectInputRadio} value={selectedInput.inputName}>
          {IOCard(item)}
          {alternatives.map(each => (
            <div key={each.name}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <hr className="horizontal-line hr1" />
                <p className="Studio-Logic-IO-OR">OR</p>
                <hr className="horizontal-line hr2" />
              </div>
              {IOCard(each)}
            </div>
          ))}
        </Radio.Group>
      </div>
    );
  };

  // methods
  const handleClickRun = () => {
    runLogic();
  };

  const objToArr = obj => {
    try {
      if (obj instanceof Array) {
        return obj;
      } else {
        return [obj];
      }
    } catch (_) {
      return [obj];
    }
  };

  const convertIOVariableObj = value => {
    if (!variables[value]) return {};
    const type = (variables[value] || {}).type;
    return {
      inputName: selectedInput.inputName,
      variable: value,
      type,
      str: value ? `${value}(${type})` : "",
    };
  };

  const handleSelectInput = value => {
    const selectedInputObj = convertIOVariableObj(value);
    selectedInputObj.inputName = getInputNameByVariable(value);
    setSelectedInput(selectedInputObj);
  };

  const getInputNameByVariable = (vname, selectedLogicObj = selectedLogic) => {
    if (!vname || !variables[vname]) return "";
    const type = variables[vname].type;
    const inputs = [];
    selectedLogicObj.inputs.forEach(x => {
      inputs.push(x);
      if (x.OR) {
        x.OR.forEach(y => inputs.push(y));
      }
    });
    return (inputs.find(x => x.dtype === type) || {}).name;
  };

  const handleSelectInputRadio = e => {
    let selectedInputObj = JSON.parse(JSON.stringify(selectedInput));
    if (e.target.value !== selectedInputObj.inputName) {
      selectedInputObj = {};
    }
    selectedInputObj.inputName = e.target.value;
    setSelectedInput(selectedInputObj);
  };

  const handleSelectOutput = value => {
    setSelectedOutput(convertIOVariableObj(value));
  };

  const handleLogicMenuChange = value => {
    getAvailabeLogics(value);
  };

  const runLogicDisabled = () => {
    return !selectedLogic?.name || !selectedInput?.variable || !selectedOutput?.variable;
  };

  const convertSelectedVariables = vars => {
    return (Object.values(vars || {}) || []).map(x => {
      const matchingVariable = variables[x];
      return {
        key: x,
        type: matchingVariable ? matchingVariable.type : "string",
      };
    });
  };

  const handleAvailableLogic = logic => {
    const logicObj = JSON.parse(JSON.stringify(logic));
    logicObj.inputs = (logicObj.inputs || []).map(input => {
      const copyObj = JSON.parse(JSON.stringify(input));
      copyObj.type = "input";
      if (copyObj.OR) {
        copyObj.OR = objToArr(copyObj.OR);
        copyObj.OR = copyObj.OR.map(each => {
          return Object.assign({}, each, { type: "input" });
        });
      }
      return copyObj;
    });
    logicObj.outputs = (logicObj.outputs || []).map(output => {
      return Object.assign({}, output, { type: "output" });
    });
    setInputs(logicObj.inputs);
    setOutputs(logicObj.outputs);
    if (logicObj.selected_inputs && logicObj.selected_inputs.length > 0) {
      const inputVar = logicObj.selected_inputs[0];
      // set selected input name by type
      const selectedInputObj = convertIOVariableObj(inputVar);
      selectedInputObj.inputName = getInputNameByVariable(inputVar, logicObj);
      setSelectedInput(selectedInputObj);
    }
    if (logicObj.selected_outputs && logicObj.selected_outputs.length > 0) {
      setSelectedOutput(convertIOVariableObj(logicObj.selected_outputs[0]));
    } else {
      setSelectedOutput({});
    }
  };

  const getApiList = () => {
    dispatch(fotActions.setFetching(true));
    setLoadingState({ logicCard: true });
    api
      .get(`/be/bas-demo-v4/brain_studios/list_apis`)
      .then(response => response.json())
      .then(data => {
        const allLogic = data[0];
        setAvailableLogics(allLogic);
        // select first available logic
        // if (allLogic.length > 0) {
        //   getAvailabeLogics(allLogic[0]);
        // }
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({ logicCard: false });
      });
  };

  const getAvailabeLogics = name => {
    dispatch(fotActions.setFetching(true));
    setLoadingState({ logicCard: true });
    api
      .get(`/be/bas-demo-v4/brain_studios/available_logic?api_name=${name}&template_name=${selectedTemplate.name}`)
      .then(response => response.json())
      .then(data => {
        let logic = data[0];
        // select first available logic
        if (logic) {
          setSelectedLogic(logic);
          setUpdateSelectedTemplate(true);
          handleAvailableLogic(logic);
        }
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({ logicCard: false });
        setUpdateSelectedTemplate(false);
      });
  };

  const runLogic = () => {
    dispatch(fotActions.setFetching(true));
    setLoadingState({ logicRunBtn: true, variableCard: true });
    const data = {
      template_name: selectedTemplate.name,
      api_name: selectedLogic.name,
      logic_data: {
        inputs: [selectedInput.variable],
        outputs: [selectedOutput.variable],
      },
    };
    api
      .post(`/be/bas-demo-v4/brain_studios/run_logic`, data)
      .then(response => response.json())
      .then(async data => {
        const variablesObj = data[0].variables;
        setVariables(variablesObj);
        const defaultObj = variablesObj[(selectedOutput || {}).variable || "clothing_results"] || {};
        const value = defaultObj?.value;
        const outputObj = await studioPublicMethod.handleStockData(selectedLogic.name, value);
        const thoughtsList = [studioPublicMethod.toThoughts(selectedLogic.name, outputObj)];
        setThoughts(thoughtsList);
      })
      .catch(error => {
        func.messageError(error);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({ logicRunBtn: false, variableCard: false });
      });
  };

  // select
  const select = (
    <Select style={{ minWidth: 100, maxWidth: 200 }} value={selectedLogic.name} onChange={handleLogicMenuChange}>
      {availableLogics.map((logic, idx) => (
        <Option value={logic} key={idx}>
          {logic}
        </Option>
      ))}
    </Select>
  );

  // load logic
  useEffect(() => {
    getApiList();
  }, []);

  useEffect(() => {
    if (updateSelectedLogic) {
      getAvailabeLogics(selectedLogic.name);
    }
  }, [updateSelectedLogic]);

  useEffect(() => {
    let logic = (selectedTemplate.logic || [{}])[0] || {};
    if (selectedTemplate.active_api_name) {
      logic = (selectedTemplate.logic || []).find(x => x.name === selectedTemplate.active_api_name) || {};
    }
    if (logic?.name) {
      setSelectedLogic(logic);
    } else {
      setSelectedLogic({});
    }
    handleAvailableLogic(logic);
  }, [selectedTemplate.name]);

  return (
    <Card
      title="Api Logic"
      className="Studio-Logic-Card shadow"
      extra={select}
      actions={[
        <Button
          type="primary"
          icon={<PlayCircleFilled />}
          onClick={handleClickRun}
          loading={loadingState.logicRunBtn}
          disabled={runLogicDisabled()}
        >
          Run Logic
        </Button>,
      ]}
    >
      <Spin tip="Loading..." spinning={loadingState.logicCard}>
        <div className="Studio-Logic-List-Container">
          <p className="bold" style={{ marginBottom: "10px" }}>
            Description
          </p>
          <p className="description">{selectedLogic.description}</p>
          <List
            header={<div className="bold">Inputs</div>}
            className="Studio-Logic-List"
            size="small"
            dataSource={inputs}
            renderItem={listItem}
          />
          <List header={<div className="bold">Outputs</div>} size="small" dataSource={outputs} renderItem={listItem} />
        </div>
      </Spin>
    </Card>
  );
};

export default StudioLogicCard;
