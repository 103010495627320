import func, { matchMobile } from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import AddToContent from "../../AddToContent";
import { AYLWAYS_SHOW_OUTPUT } from "../../AddToContent/conts";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { PreviewOutputItemRoot } from "@uiview/views/PreviewApp/PreviewOutputItemRoot/PreviewOutputItemRoot";
import React, { useCallback, useMemo, useRef, Fragment } from "react";
import { SortableItem } from "@uiview/views/SortableList/SortableItem/SortableItem";
import { useAISaasPreviewItem } from "../../hooks/useAISaasPreviewItem";
import { typeMap } from "@uiview/views/PreviewV2ToolBar/PreviewV2ToolBarUtil";
import { TalkBuilder } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboardingType";
import { NodeTitleUtil } from "@uiview/views/Nodes/NodeTitleUtil";
import { useNodesTitle } from "@custom-hooks/HomeUsePluginRoot/useNodesTitle";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { logEvent } from "@amplitude/analytics-browser";
import { useSelector } from "react-redux";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import classNames from "classnames";
import sortableCommonCSS from "../../SortableItemCommon.module.scss";

export function OutputItem(props: {
  item: SortableList.SortableData<AISaasOutput.DndThoughtWithGroupType>;
  index: number;
  sortableList: SortableList.SortableData<AISaasOutput.DndThoughtWithGroupType>[];
}): React.ReactNode {
  const sortableItemRef = useRef<HTMLDivElement>(null);
  // props data
  const nodeData = props.item.data;
  const id = props.item.id;
  const nodeId = nodeData.groupDnd[0].results[0].nodeId;

  // store
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homePluginStore = getIt(HomePluginStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const fotReduxStore = getIt(FotReduxStore);

  const homeStoreState = useImagicaStore(homeStore).value;
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const chatOnboardingState = useImagicaStore(chatOnboardingStore).value;

  const { inputTitle } = useNodesTitle(nodeId);

  const { onPreviewItemMouseEnter } = useAISaasPreviewItem();
  const editorImageScrollDireaction = useSelector((state: any) => state.fot.editorImageScrollDireaction);
  const { id: talkBuilderId } = chatOnboardingState.talkBuilder;

  // custom var

  const { saasUIData } = creatorSaasAppState;
  const featureTags = homeStoreState.featureTags;
  const isShare = homePluginStore.state.isShare;

  const nextId = props.sortableList[props.index + 1]?.id || AYLWAYS_SHOW_OUTPUT;
  //  input在staticMode下不显示add content
  const hideAdd = saasUIData.staticMode;
  const isShowAdd = !isShare && !hideAdd;
  const showTalkBuilderBorder = talkBuilderId === id;

  const getType = useMemo(() => {
    const type = props.item.data.groupDnd[0].results[0].type;
    // if (nodeData?.groupDnd?.length) {
    //   type = nodeData.groupDnd[0].results?.[0]?.type;
    // } else {
    //   type = nodeData?.data?.displayType;
    // }

    return typeMap[type] || "default";
  }, [props.item.data.groupDnd]);

  const outputV2Item = useMemo(() => {
    const outputV2 = creatorSaasAppStore.state.saasUIData.outputV2;

    return outputV2?.find(x => x.id === nodeData?.groupDnd?.[0]?.id);
  }, [creatorSaasAppStore.state.saasUIData.outputV2, nodeData?.groupDnd]);

  const getNodeIdAndLabel = useMemo(() => {
    const label = outputV2Item?.data?.blank?.title;
    const id = nodeData?.groupDnd?.[0]?.results?.[0]?.nodeId;
    /**
     * judge if it is group node
     */
    // output group
    if (nodeData?.groupDnd?.length > 1 || nodeData?.groupDnd?.[0]?.groupId) {
      return { id: nodeData?.id, label: NodeTitleUtil.outputTitle(label) };
    }
    // // input
    // else if (nodeData?.data) {
    //   return { id: nodeData.id, label: NodeTitleUtil.getNodeTitle(nodeData) };
    // }
    // output
    else {
      return { id, label: inputTitle || label };
    }
  }, [nodeData, outputV2Item, inputTitle]);
  const confirmDeleteBox = (id: any): void => {
    let arr: any[] = [];
    // bsf-4912 replace thoughts
    const tempThoughts = creatorSaasAppStore.state.saasUIData.output;
    //  判断groupDnd有多个或者第一个的groupId如果存在 则表示是group
    if (nodeData?.groupDnd?.length > 1 || nodeData?.groupDnd?.[0]?.groupId) {
      // 删除group
      if (editorImageScrollDireaction !== "horizontalScroll") {
        arr = tempThoughts.filter(x => x.groupId !== id);
      } else {
        const results = tempThoughts[0].results;
        const filterResults = results.filter((x: any) => x.parentNode !== id);
        if (func.isEmpty(filterResults)) {
          fotReduxStore.setImageScrollDireaction("verticallyScroll");
        } else {
          arr = [
            {
              ...tempThoughts[0],
              results: filterResults,
            },
          ];
        }
      }
    } else {
      // 删除单个
      tempThoughts.forEach(x => {
        const noDelArr = x.results.filter((r: any) => r.previewId !== id);
        if (!func.isEmpty(noDelArr)) {
          arr.push({
            ...x,
            results: noDelArr,
          });
        }
      });
    }
    // bsf-4912 use setSassUIData
    creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: arr }));
  };
  /**
   * 原来 openToolBar
   */
  const onClick = (): void => {
    onPreviewItemMouseEnter({
      type: getType,
      talkBuilderEdit: () => {
        chatOnboardingStore.setChatOnboardingState({
          talkBuilder: {
            ...new TalkBuilder(),
            id: id as string,
            content: getNodeIdAndLabel.label || "",
          },
        });
      },
      remove: () => {
        const result = creatorSaasAppStore.state.saasUIData.output.find(item => {
          return item.results[0].previewId === id;
        });
        const tempNode = creatorNodesStore.getNodes().find(node => {
          return node.id === result?.results[0].nodeId;
        });
        logEvent("publish_preview_remove_content", { node: tempNode });

        confirmDeleteBox(id);
      },
      container: sortableItemRef.current,
      id,
    });
  };

  const renderItemChildren: SortableList.ItemRenderer<AISaasOutput.DndThoughtWithGroupType> = useCallback(
    item => (
      <div data-testid="AiSaasOutputItemChild">
        {/* <AISaasItemContent key={groupThought.id} thought={groupThought} index={index} /> */}
        {/* group */}
        {item.value.data.groupDnd.map(thought => (
          <PreviewOutputItemRoot thought={thought} key={thought.id} />
        ))}
      </div>
    ),
    []
  );

  const renderItemHead: SortableList.ItemRenderer<AISaasOutput.DndThoughtWithGroupType> = useCallback(() => {
    if (!featureTags.disableMultiRoundChatOnboarding) {
      return <div className={sortableCommonCSS.outputlabel}>{getNodeIdAndLabel.label}</div>;
    }
    return null;
  }, [featureTags.disableMultiRoundChatOnboarding, getNodeIdAndLabel.label]);

  return (
    <Fragment>
      {isShowAdd && chatOnboardingState.selectTemplateInModal !== ChoosingFormat.AUTOIMAGICA && (
        <AddToContent id={props.item.id} nextId={nextId} type="output" />
      )}

      <SortableItem
        id={getNodeIdAndLabel.label}
        containerRef={sortableItemRef}
        value={props.item}
        index={props.index}
        itemClassName={classNames(
          { [sortableCommonCSS.sortableItemMobile]: isMobile && featureTags.disableMobilePage },
          { [sortableCommonCSS.talkBuilderBorder]: showTalkBuilderBorder }
          // { [css.topIndex]: talkBuilderId === id }
        )}
        onClick={onClick}
        renderItemHead={renderItemHead}
        renderItemChildren={renderItemChildren}
      />
    </Fragment>
  );
}
