"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatAppThemeStore = exports.ThemeStoreState = void 0;
const StoreBase_1 = require("../../../base/cutil/StoreBase");
const ChatAppThemeData_1 = require("./ChatAppThemeData");
const DarkTheme_1 = require("./DarkTheme");
const LightTheme_1 = require("./LightTheme");
const ATheme_1 = require("./ATheme");
const BTheme_1 = require("./BTheme");
const CTheme_1 = require("./CTheme");
const DTheme_1 = require("./DTheme");
const ETheme_1 = require("./ETheme");
const FTheme_1 = require("./FTheme");
const GTheme_1 = require("./GTheme");
class ThemeStoreState {
    constructor() {
        this.chatTheme = ChatAppThemeData_1.ChatThemeType.LIGHT;
    }
    copyWith(params) {
        const state = new ThemeStoreState();
        state.chatTheme = params.chatTheme ?? this.chatTheme;
        return state;
    }
}
exports.ThemeStoreState = ThemeStoreState;
class ChatAppThemeStore extends StoreBase_1.StoreBase {
    constructor() {
        super(() => new ThemeStoreState());
        this.themeDatas = {
            [ChatAppThemeData_1.ChatThemeType.LIGHT]: LightTheme_1.LightTheme.theme,
            [ChatAppThemeData_1.ChatThemeType.DARK]: DarkTheme_1.DarkTheme.theme,
            [ChatAppThemeData_1.ChatThemeType.CHATTHEMEA]: ATheme_1.ATheme.theme,
            [ChatAppThemeData_1.ChatThemeType.CHATTHEMEB]: BTheme_1.BTheme.theme,
            [ChatAppThemeData_1.ChatThemeType.CHATTHEMEC]: CTheme_1.CTheme.theme,
            [ChatAppThemeData_1.ChatThemeType.CHATTHEMED]: DTheme_1.DTheme.theme,
            [ChatAppThemeData_1.ChatThemeType.CHATTHEMEE]: ETheme_1.ETheme.theme,
            [ChatAppThemeData_1.ChatThemeType.CHATTHEMEF]: FTheme_1.FTheme.theme,
            [ChatAppThemeData_1.ChatThemeType.CHATTHEMEG]: GTheme_1.GTheme.theme,
        };
    }
    setChatTheme(chatTheme) {
        const newState = this.state.copyWith({ chatTheme });
        this.emit(newState);
    }
    get chatTheme() {
        return this.state.chatTheme;
    }
    get chatThemeDatas() {
        return this.themeDatas[this.state.chatTheme];
    }
}
exports.ChatAppThemeStore = ChatAppThemeStore;
