"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BluePrintAdapter = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const FunctionRegistry_1 = require("../../storyV2/function/FunctionRegistry");
const StoryUtil_1 = require("../domain/StoryUtil");
class BluePrintAdapter extends AdapterBase_1.AdapterBase {
    constructor(funcRegistry, brainClient) {
        super(funcRegistry);
        this.funcRegistry = funcRegistry;
        this.brainClient = brainClient;
        this.brainClient = brainClient;
    }
    runResponseType() {
        return AdapterBase_1.RunResponseType.BLUEPRINT;
    }
    async runNode(node, actionData, story, edge) {
        const queries = StoryUtil_1.StoryUtil.findSourceQuerys(story, edge);
        const func = this.funcRegistry.getByRegistName(FunctionRegistry_1.FunctionName.blueprint);
        const result = await func.run(node.content, { projectId: story.projectId, edgeId: edge.options.id }, [], 1, undefined, // for food
        undefined, // for youtube
        {}, // for add data source
        undefined, undefined, queries, actionData.blueJson);
        return result.data;
    }
}
exports.BluePrintAdapter = BluePrintAdapter;
