import { useCallback, useEffect } from "react";
import { useCreation } from "ahooks";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { AddNewProjectIcon } from "./assets/svg";
import { studioActions } from "@store/studio";
import { editorActions } from "@store/editor";
import { HomeMobileBloc } from "./HomeMobileBloc";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { getIt } from "@uikit/getIt";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { GetItContext } from "imagica-uikit/dist/base/provider/GetItContext";
import { previewStore } from "@uiview/store/PreviewStore";

import { AppDispatch } from "@store/index";
import styles from "./HomeMobile.module.scss";
import { Header } from "./Header";
import { MobileProjectsView } from "@views/thinking-layout-editor/Home/Project/Mobile/MobileProjectsView";
import { ShowNavPageStore } from "../HomeRoot/store/ShowNavPageStore";
import { MultiRoundChatOnboarding } from "../MultiRoundChatOnboarding/MultiRoundChatOnboarding";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreatorPreviewPanel } from "../ProjectCanvas/CreatorRoot/CreatorPreviewPanel";
import { TemplateModal } from "../NewOnboardingFlow/TemplateModal/TemplateModal";
import { Dropdown } from "antd";
import { useSignal } from "@preact/signals-react";
import { DropDownIcon } from "./assets/svg";
import { PreviewPaneStore } from "../PreviewPane/PreviewPaneStore";
import { HomeMobileUtils } from "./HomeMobileUtils";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { GenUIProcess } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboardingType";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

function HomeMobile(): JSX.Element {
  const menuKey = useSignal("Auto-Imagica Chat");
  const dropdownOpen = useSignal(false);
  const dispatch = useDispatch<AppDispatch>();
  const previewState = useImagicaStore(previewStore).value;
  const homePluginStore = getIt(HomePluginStore);
  const showNavPageStore = getIt(ShowNavPageStore);
  const homePluginState = useImagicaStore(homePluginStore).value;
  const showNavPageState = useImagicaStore(showNavPageStore).value;
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const previewAppStore = getIt(PreviewAppStore);
  const previewPaneStore = getIt(PreviewPaneStore);
  const previewPaneState = useImagicaStore(previewPaneStore).value;
  const chatOnboardingStore = getIt(ChatOnboardingStore);

  const chatOnboardingState = useImagicaStore(chatOnboardingStore).value;

  const creatorNodesStore = getIt(CreatorNodesStore);

  const defaultMenuItem = [
    { key: "Auto-Imagica Chat", event: HomeMobileUtils.showChat.bind(null, previewPaneStore) },
    { key: "App Preview", event: HomeMobileUtils.showPreview.bind(null, previewStore, previewPaneStore) },
    { key: "App Design", event: HomeMobileUtils.showAppDesign.bind(null, previewPaneStore) },
    {
      key: "Advanced Features",
      event: HomeMobileUtils.showAdvancedFeatures.bind(null, previewPaneStore),
    },
  ];
  const menuItem = useSignal([
    { key: "Auto-Imagica Chat", event: HomeMobileUtils.showChat.bind(null, previewPaneStore) },
  ]);

  const setLoadingState = useCallback(
    (val: any) => {
      dispatch(studioActions.setLoadingState(val));
    },
    [dispatch]
  );
  const setSelectedTemplate = useCallback(
    (val: any) => {
      dispatch(studioActions.setSelectedTemplate(val));
    },
    [dispatch]
  );
  const setShowTooltip = useCallback(
    (val: any) => {
      dispatch(editorActions.setShowTooltip(val));
    },
    [dispatch]
  );

  const bloc = useCreation(
    () =>
      new HomeMobileBloc({
        homePluginState,
        previewStore,
        previewPaneStore,
        clearPreviewApp: previewAppStore.clearPreviewApp,
        setLoadingState,
        setSelectedTemplate,
        setShowTooltip,
      }),
    [homePluginState, previewStore, previewPaneStore, setLoadingState, setSelectedTemplate, setShowTooltip]
  );

  const handleDropdownOpenChange = (visible: boolean): void => {
    dropdownOpen.value = visible;
  };

  const selectMenu = (item: any): void => {
    menuKey.value = item.key;
    dropdownOpen.value = false;
    item.event();
  };

  useEffect(() => {
    if (chatOnboardingState.currentStep === GenUIProcess.Build) {
      if (previewPaneState.mobileChatStatus) {
        selectMenu(defaultMenuItem[0]);
      } else {
        menuItem.value = defaultMenuItem;
        //  从chat页点击make it real 或者 update app时条状到preview页
        if (!previewPaneStore.state.settingPageStatus) {
          selectMenu(defaultMenuItem[1]);
        }
      }
    }
  }, [chatOnboardingState.currentStep, previewPaneState.mobileChatStatus]);

  useEffect(() => {
    if (previewState.showPreviewPanel) {
      previewPaneStore.setMobileChatStatus(false);
      menuKey.value = defaultMenuItem[1].key;
    }
  }, [previewState.showPreviewPanel]);

  useEffect(() => {
    creatorNodesStore.on(() => {
      creatorSaasAppStore.forceUpdates();
    });
  }, []);

  const overlay = (
    <div className={styles.overlay}>
      {menuItem.value.map((item, index) => (
        <div
          className={classNames(styles.overlayLi, { [styles.overlayLiSelected]: item.key === menuKey.value })}
          key={item.key + index}
          onClick={selectMenu.bind(null, item)}
        >
          {item.key}
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.HomeMobile}>
      <Header />
      <div className={styles.content}>
        {showNavPageState.showNavPage ? (
          <MobileProjectsView />
        ) : (
          <div className={styles.project}>
            {!chatOnboardingState.showOnBoardingInput && chatOnboardingState.currentStep === GenUIProcess.Build && (
              <div className={styles.menu}>
                <Dropdown
                  open={dropdownOpen.value}
                  onOpenChange={handleDropdownOpenChange}
                  overlayClassName={styles.menuContainer}
                  trigger={["click"]}
                  overlay={overlay}
                >
                  <div className={styles.menuKey}>
                    {menuKey.value}
                    <DropDownIcon />
                  </div>
                </Dropdown>
              </div>
            )}

            <div
              className={`${styles.container} ${
                chatOnboardingState.showOnBoardingInput ||
                (!chatOnboardingState.showOnBoardingInput && chatOnboardingState.currentStep === GenUIProcess.Ideation)
                  ? styles.hasInputContainer
                  : ""
              }`}
            >
              <MultiRoundChatOnboarding
                className={classNames(previewPaneState.mobileChatStatus ? styles.showChat : "")}
              />
              <GetItContext.Provider value={getIt}>
                <CreatorPreviewPanel />
              </GetItContext.Provider>
            </div>
          </div>
        )}
      </div>
      <TemplateModal />
      {showNavPageState.showNavPage && (
        <div
          className={styles.addNewProject}
          onClick={async () => {
            await bloc.createNewProject();
            bloc.handleSelectProjectType();
          }}
        >
          <AddNewProjectIcon />
        </div>
      )}
    </div>
  );
}

export default HomeMobile;
