"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomApiUtil = void 0;
const FunctionBase_1 = require("../../base/storyV2/function/FunctionBase");
const FunctionUtil_1 = require("../../base/storyV2/function/FunctionUtil");
const JsonUtil_1 = require("../../base/cutil/JsonUtil");
const Extensions_1 = require("../../base/cutil/Extensions");
class CustomApiUtil {
    /**
     * 将UI 的转换variableList 转换为 corekit 调用的
     * @param fotVarList
     */
    static translateFotVariableListToCoreKit(fotVarList) {
        return fotVarList.map(item => {
            return new FunctionBase_1.FuncCustomVariable(item.name, item.node.data.textAreaValue);
        });
    }
    /**
     * 用户UI的展示，当时的body字符串
     * @param inputString
     * @param bodyParam
     * @param variableList
     */
    static evaluateFinalBodyStr(inputString, bodyParam, variableList) {
        if ((0, Extensions_1.isBlank)(bodyParam)) {
            return "";
        }
        // 注入currentSourceNodeValue
        // const variableList = getVariableList(currentSourceNodeValue);
        let bodyStr = "";
        if (typeof bodyParam === "object") {
            bodyStr = JSON.stringify(bodyParam);
        }
        if (typeof bodyParam === "string") {
            bodyStr = bodyParam;
        }
        bodyStr = bodyStr.trim();
        bodyStr = FunctionUtil_1.FunctionUtil.customApiBodyParamsReplace(inputString, bodyStr, variableList) ?? bodyStr;
        if (JsonUtil_1.JsonUtil.parse(bodyStr)) {
            return bodyStr;
        }
        // 之前的逻辑还是继续保留
        const varFormatReg = /\${\w+\}/g;
        // 将剩下的没有的变量全部替换为空字符串
        bodyStr =
            bodyStr &&
                bodyStr
                    .replace(varFormatReg, "")
                    .replace(/\n/g, "")
                    .replace(/(\r\n|\n|\r)/g, "")
                    .replace(/^"/, "")
                    .replace(/"$/, "");
        return bodyStr;
    }
    /**
     * body 是否为json 格式
     * @param evaluateFinalBody
     */
    static isJsonForBody(evaluateFinalBody) {
        return (0, Extensions_1.isBlank)(JsonUtil_1.JsonUtil.parse(evaluateFinalBody));
    }
    /**
     * 添加默认值
     * @param headerList
     * @param finalBodyStr
     */
    static addDefaultHeader(headerList, finalBodyStr) {
        let newData = [];
        // 如果body 是一个json 数据，用户没有添加content-type，那么需要我们默认添加
        if (this.isJsonForBody(finalBodyStr)) {
            // 用户是否添加content-type
            const userAddContentType = headerList.some(item => {
                if (item["name"]?.lowercase() === "content-type") {
                    return true;
                }
                return false;
            });
            if (!userAddContentType) {
                const contentType = { "Content-Type": "application/json" };
                contentType["name"] = "Content-Type";
                contentType["value"] = "application/json";
                newData.push(contentType);
            }
        }
        return newData;
    }
}
exports.CustomApiUtil = CustomApiUtil;
