"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFileModel = void 0;
class UploadFileModel {
    constructor(s3Url, s3Path, needToCreateBlobUrl, uuid, name, format) {
        this.s3Url = s3Url;
        this.s3Path = s3Path;
        this.needToCreateBlobUrl = needToCreateBlobUrl;
        this.uuid = uuid;
        this.name = name;
        this.format = format;
    }
}
exports.UploadFileModel = UploadFileModel;
