import { FotClient } from "imagica-corekit/dist/base/api/FotClient";
import { getIt } from "../../../uikit/getIt";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";

export class RestClientPageBloc {
  brainClient = getIt(BrainClient);

  async getMe() {
    console.log("me", this.brainClient);
  }
}
