import { ReadyStatus } from "imagica-corekit/dist/base/cutil/ReadyStatus";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { chooseFormatStore, ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { NavBarStore, SwitcherModeType } from "../navBar/NavBarStore";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { CreateNewProjectService } from "../ProjectCanvas/CreateNewProject";

const homeStore = getIt(HomeStore);
const navBarStore = getIt(NavBarStore);
const fotSaveService = getIt(FotSaveService);

export class HomeMobileBloc {
  homePluginState;
  previewStore;
  previewPaneStore;
  setLoadingState;
  setSelectedTemplate;
  setShowTooltip;
  clearPreviewApp;
  createNewProjectService = getIt(CreateNewProjectService);
  constructor(props: any) {
    this.homePluginState = props.homePluginState;
    this.previewStore = props.previewStore;
    this.previewPaneStore = props.previewPaneStore;
    this.setLoadingState = props.setLoadingState;
    this.setSelectedTemplate = props.setSelectedTemplate;
    this.setShowTooltip = props.setShowTooltip;
    this.clearPreviewApp = props.clearPreviewApp;
  }
  async createNewProject(): Promise<void> {
    if (this.homePluginState.showImagicaTutoriralLoading === ReadyStatus.LOADING) return;
    homeStore.setIsClickAddNewProject(true);
    this.setLoadingState({
      promptSaveBtn: true,
      promptNewBtn: true,
      promptCard: true,
    });
    this.setSelectedTemplate({});
    this.setShowTooltip("");
    this.clearPreviewApp();
    await this.createNewProjectService.addNewProject();
  }

  handleSelectProjectType(type = "autoImagica"): void {
    chooseFormatStore.setChooseFormat(ChoosingFormat.AUTOIMAGICA);
    getIt(ChatOnboardingStore).setChatOnboardingState({ selectTemplateInModal: ChoosingFormat.AUTOIMAGICA });
    navBarStore.setSwtichMode(SwitcherModeType.imagica);
    const tempItem = newOnboardingFlowStore.state.templateListV2.find(item => {
      return (item.value = type);
    });
    if (tempItem) {
      newOnboardingFlowStore.setSelectedFlowItem(
        Object.assign({}, tempItem, {
          selected: true,
        })
      );
      newOnboardingFlowStore.setOpenOptTempLateModal(false);
    }
    homeStore.setIsClickAddNewProject(false);
    fotSaveService.handleSave({ background: true, backgroundCreateRelation: false });
  }
}
