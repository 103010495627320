import { useState, useEffect, JSX } from "react";
import { Spin, Button, Form, Input, Select, InputNumber, Slider, Col, Row, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "@store/editor";
import TextareaAutosize from "react-textarea-autosize";
import style from "./CodeModal.module.scss";
import func from "@uikit/func";
import { useKeyPress } from "reactflow";
import { ENGINE_LIST } from "@views/thinking-layout-editor/constants";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { Signal, useComputed, useSignal } from "@preact/signals-react";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";

const gql = getIt(ObjectRelationGqlService);

export class CodeModalProps {
  setCloseModal: (close: boolean) => void = () => {};
}

export function CodeModal(props: CodeModalProps): JSX.Element {
  const dispatch = useDispatch();
  const webConfigStore = getIt(WebConfigStore);
  const webConfigStoreState = useStore(webConfigStore).value;
  const creatorStoreMethods = getIt(CreatorStoreMethods);

  const [engine, setEngine] = useState("");
  const engineList = useComputed(() => {
    return ENGINE_LIST.filter(x => webConfigStoreState.engineList?.some((y: any) => y.label === x.label));
  }).value;

  const tokensValue = useSignal(1);
  const temperatureValue = useSignal(0.7);
  const frequencyValue = useSignal(0);
  const presenceValue = useSignal(0);
  const stopValue = useSignal("");
  const [queryValue, setQueryValue] = useState("");
  const [querySystem, setQuerySystem] = useState("");
  const [queryTitle, setQueryTitle] = useState("Query");
  const promptObject: Signal<any> = useSignal({});
  const isChatType = useSignal(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [okLoading, setOkLoading] = useState(false);
  const [isSystemEngine, setIsSystemEngine] = useState(false);
  const [deactivateMaxTokens, setDeactivateMaxTokens] = useState(false);

  const escapePressed = useKeyPress("Escape");

  const lineParam = useSelector((state: any) => state.fot.lineParam);

  const setCurrentModalLineParamStr = (val: any): void => {
    dispatch(editorActions.setCurrentModalLineParamStr(val));
  };

  const systemEngine = ["gpt-3.5-turbo", "gpt-4"];

  const edgeTypeList = [
    {
      value: "independent",
      label: "Default",
    },
    {
      value: "chat",
      label: "Chat",
    },
  ];

  const [edgeType, setEdgeType] = useState("independent");

  const fetchPrompt = async (): Promise<void> => {
    setModalLoading(true);
    const objectId = lineParam.identifier?.type === "object_id" ? lineParam.identifier?.value : null;
    const res = await gql.objects({ id: objectId });
    const promptObj = JSON.parse(res[0]?.attributes);

    if (lineParam.isChat) {
      setQueryTitle("First chat message to user (optional)");
    }
    promptObject.value = promptObj;
    setFormValue(promptObj, lineParam.isChat);
    setModalLoading(false);
  };

  const setFormValue = (params: any, isChat: boolean): void => {
    setEdgeType(isChat ? "chat" : "independent");
    isChatType.value = isChat;
    const queryValue = params.queryValue || params.prompt;

    // 普通模式下选择 3.5、4
    if (!isChat && systemEngine.includes(params.engine)) {
      const independentArr = queryValue.split(/<#SYSTEM>|<#USER>/);
      setQuerySystem(independentArr[1]);
      setQueryValue(independentArr[2]);
    } else if (isChat) {
      // chat type 内容
      const chatArr = queryValue.split(/<#SYSTEM>|<#ASSISTANT>|<chatHistory>/);
      setQuerySystem(chatArr[1]);
      setQueryValue(chatArr[2]);
    } else {
      // 普通模式下选择其他engine
      removeSpaces(queryValue);
    }
    const engine = params.engine === "gpt-3.5-turbo" ? "gpt-3.5-turbo-16k" : params.engine;
    setEngine(engine);
    const maxTokens = params.maxTokens || params.max_tokens || null;
    tokensValue.value = maxTokens || 500;
    setDeactivateMaxTokens(func.isEmpty(maxTokens));

    temperatureValue.value = params.temperature;
    frequencyValue.value = params.frequencyPenalty || params.frequency_penalty || 0;
    presenceValue.value = params.presencePenalty || params.presence_penalty || 0;
    stopValue.value = (params?.stopSequences || params?.stop_sequences)?.join() || "";
  };

  const handleCancel = (): void => {
    setEngine(engineList[0].value);
    tokensValue.value = 1;
    temperatureValue.value = 0.7;
    frequencyValue.value = 0;
    presenceValue.value = 0;
    stopValue.value = "";
    props.setCloseModal(false);
  };

  const changedParams = (): boolean => {
    return (
      promptObject.value.max_tokens !== tokensValue.value ||
      promptObject.value.engine !== engine ||
      promptObject.value.temperature !== temperatureValue.value ||
      (promptObject.value.frequency_penalty || 0) !== frequencyValue.value ||
      (promptObject.value.presence_penalty || 0) !== presenceValue.value ||
      (promptObject.value.stop || []).join() !== stopValue.value ||
      func.isEmpty(promptObject.value.max_tokens) !== deactivateMaxTokens
    );
  };
  const setDefault = async (): Promise<void> => {
    let newEdgeParam: Record<string, any> = {};
    const showSystem = systemEngine.includes(engine);
    let inputValue = queryValue?.trim();
    if (showSystem) {
      inputValue = `<#SYSTEM>${querySystem.trim()}${!func.isEmpty(queryValue) ? "<#USER>" + queryValue : ""}`;
    }
    if (lineParam.lineType === "identifier") {
      // save to db if there are changes
      if (!changedParams() && promptObject.value.prompt.trim() === inputValue.trim()) {
        props.setCloseModal(false);
        return;
      }
      setOkLoading(true);
      const data = {
        engine,
        temperature: temperatureValue.value,
        frequency_penalty: frequencyValue.value,
        presence_penalty: presenceValue.value,
        stop: stopValue.value?.split(/,\s?/g)?.filter(x => x) || [],
        prompt: inputValue.trim(),
      } as any;
      if (!deactivateMaxTokens) {
        data.max_tokens = tokensValue.value;
      }
      if ((engineList.find(x => x.value === engine) || {}).chat) {
        data.chat = true;
      }
      const params = {
        name: "studio_prompt",
        isGlobal: true,
        attributes: data,
      };
      const object = await gql.createObject(params, "Successfully updated");
      if (object.id) {
        newEdgeParam = Object.assign({}, lineParam, {
          isChat: false,
          identifier: {
            type: "object_id",
            value: object.id,
          },
        });
        creatorStoreMethods.setEdgeLineParam(newEdgeParam);
      }
      setOkLoading(false);
    } else {
      newEdgeParam = Object.assign({}, lineParam, {
        engine,
        temperature: temperatureValue.value,
        frequencyPenalty: frequencyValue.value,
        presencePenalty: presenceValue.value,
        stopSequences: stopValue.value?.split(/,\s?/g)?.filter(x => x) || [],
        queryValue: inputValue.trim(),
      });
      if (deactivateMaxTokens) {
        delete newEdgeParam.maxTokens;
      }
      creatorStoreMethods.setEdgeLineParam(newEdgeParam);
    }
    // 设置Edge Content Change undo
    setCurrentModalLineParamStr(JSON.stringify(newEdgeParam));
    props.setCloseModal(false);
  };
  const setChat = async (): Promise<void> => {
    let newEdgeParam = {};
    // const inputValue = `${querySystem.trim()}\n${queryValue}`
    const inputValue = `<#SYSTEM>${querySystem.trim()}${
      !func.isEmpty(queryValue) ? "<#ASSISTANT>" + queryValue : ""
    } <chatHistory>`;
    const data = {
      engine,
      chat: true,
      temperature: temperatureValue.value,
      prompt: inputValue.trim(),
    } as any;
    if (!deactivateMaxTokens) {
      data.max_tokens = tokensValue.value;
    }

    const params = {
      name: "studio_prompt",
      isGlobal: true,
      attributes: data,
    };
    const object = await gql.createObject(params, "Successfully updated");
    if (object.id) {
      newEdgeParam = Object.assign({}, lineParam, {
        isChat: true,
        identifier: {
          type: "object_id",
          value: object.id,
        },
      });
      creatorStoreMethods.setEdgeLineParam(newEdgeParam);
    }
    setOkLoading(false);
    // 设置Edge Content Change undo
    setCurrentModalLineParamStr(JSON.stringify(newEdgeParam));
    props.setCloseModal(false);
  };
  const handleOk = async (): Promise<void> => {
    isChatType.value ? setChat() : setDefault();
  };
  //eslint-disable-next-line
  const getEngineList = (type: string) => {
    let arr = engineList;
    if (type === "chat") {
      arr = engineList.filter(x => x.chat);
    }
    return arr;
  };
  const removeSpaces = (value: string, key = "queryValue"): void => {
    if (edgeType === "chat") return;
    let text = value;
    if (typeof text === "string") {
      text = value.trim();
    }
    const obj: Record<string, any> = {
      queryValue: setQueryValue,
      querySystem: setQuerySystem,
    };
    obj[key](text);
  };

  const resetData = (type: string): void => {
    if (type === "chat") {
      const currTypeEngineList = getEngineList(type);
      const data = {
        engine: currTypeEngineList[0].value,
        system: "You are an Al that has a friendly conversation with <input>.",
        queryTitle: "First chat message to user (optional)",
        queryValue: "Hello <input>, how can I help you today?",
      };

      // 重置数据
      setEngine(data.engine);
      setQuerySystem(data.system);
      setQueryTitle(data.queryTitle);
      setQueryValue(data.queryValue);
      return;
    }
    const obj = Object.assign({}, promptObject.value);
    delete obj.chat;

    setQueryTitle("Query");
    setFormValue(obj, false);
  };

  const changeEdgeType = (e: string): void => {
    setEdgeType(e);
    isChatType.value = e === "chat";
    resetData(e);
  };

  useEffect(() => {
    // if lineType is 'identifier', fetch prompt object
    if (lineParam.lineType === "identifier") {
      fetchPrompt();
    } else {
      setFormValue(lineParam, false);
    }
  }, []);

  useEffect(() => {
    if (func.isEmpty(engine)) return;
    const systemEngine = ["gpt-3.5-turbo", "gpt-4"];
    const showSystem = systemEngine.includes(engine);
    setIsSystemEngine(showSystem);
    // 当选择gpt-4或gpt-3.5-turbo聊天模型时，需要显示 system query
    if (showSystem) {
      setDeactivateMaxTokens(true);
      // 拆分 queryValue
      const inputKey = "\nInput:";
      const arr = queryValue?.split(inputKey) || [];
      if (arr.length === 2) {
        setQuerySystem(arr[0]);
        setQueryValue(`${inputKey.trim()}${arr[1]}`);
      }
    } else {
      // 组合 queryValue
      const inputValue = `${querySystem.trim()}\n${queryValue}`;
      setQuerySystem("");
      setQueryValue(inputValue);
      setDeactivateMaxTokens(false);
    }
  }, [engine]);

  useEffect(() => {
    if (escapePressed) {
      handleCancel();
    }
  }, [escapePressed]);

  return (
    <Spin spinning={modalLoading} indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />}>
      <div className={style["modal-box"]}>
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          className={style["adaptive-height"]}
        >
          <Form.Item label="Edge type">
            <Select
              value={edgeType}
              onChange={setEdgeType}
              onSelect={changeEdgeType}
              options={edgeTypeList}
              placeholder="Enter something"
            />
          </Form.Item>

          <Form.Item label="Engine">
            <Select
              value={engine}
              onChange={setEngine}
              options={getEngineList(edgeType)}
              placeholder="Enter something"
            />
          </Form.Item>

          {isSystemEngine ? (
            <Form.Item label="System">
              <TextareaAutosize
                value={querySystem}
                onBlur={e => removeSpaces(e.target.value, "querySystem")}
                onChange={e => setQuerySystem(e.target.value)}
                placeholder="Enter system"
                className={style["textarea-box"]}
              />
            </Form.Item>
          ) : null}
          <Form.Item label={queryTitle}>
            <TextareaAutosize
              value={queryValue}
              onBlur={e => removeSpaces(e.target.value, "queryValue")}
              onChange={e => setQueryValue(e.target.value)}
              placeholder="Enter query"
              className={style["textarea-box"]}
            />
          </Form.Item>

          <Form.Item label="Temperature">
            <Row>
              <Col span={16}>
                <Slider
                  min={0}
                  max={1}
                  onChange={value => {
                    temperatureValue.value = value;
                  }}
                  value={temperatureValue.value}
                  step={0.1}
                />
              </Col>
              <Col span={8}>
                <InputNumber
                  min={0}
                  max={1}
                  style={{ margin: "0 12px" }}
                  value={temperatureValue.value}
                  onChange={value => {
                    temperatureValue.value = value ?? 0;
                  }}
                  step={0.1}
                />
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label="Max Tokens">
            <Row>
              <Col span={16}>
                <Slider
                  min={1}
                  max={2048}
                  onChange={value => {
                    tokensValue.value = value;
                  }}
                  value={tokensValue.value}
                  disabled={deactivateMaxTokens}
                />
              </Col>
              <Col span={7}>
                <InputNumber
                  min={1}
                  max={2048}
                  style={{ margin: "0 12px" }}
                  value={tokensValue.value}
                  onChange={value => {
                    tokensValue.value = value ?? 1;
                  }}
                  disabled={deactivateMaxTokens}
                />
              </Col>
              <Col span={1}>
                {systemEngine.includes(engine) ? (
                  <div className={style["maxTokens-checkbox"]}>
                    <span>Inf</span>
                    <Checkbox
                      checked={deactivateMaxTokens}
                      onChange={e => setDeactivateMaxTokens(e.target.checked)}
                    ></Checkbox>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Form.Item>
          {!isChatType.value ? (
            <>
              <Form.Item label="Frequency Penalty">
                <Row>
                  <Col span={16}>
                    <Slider
                      min={0}
                      max={2}
                      onChange={value => {
                        frequencyValue.value = value;
                      }}
                      value={frequencyValue.value}
                      step={0.1}
                    />
                  </Col>
                  <Col span={8}>
                    <InputNumber
                      min={0}
                      max={2}
                      style={{ margin: "0 12px" }}
                      value={frequencyValue.value}
                      onChange={value => {
                        frequencyValue.value = value ?? 0;
                      }}
                      step={0.1}
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item label="Presence Penalty">
                <Row>
                  <Col span={16}>
                    <Slider
                      min={0}
                      max={2}
                      onChange={value => {
                        presenceValue.value = value;
                      }}
                      value={presenceValue.value}
                      step={0.1}
                    />
                  </Col>
                  <Col span={8}>
                    <InputNumber
                      min={0}
                      max={2}
                      style={{ margin: "0 12px" }}
                      value={presenceValue.value}
                      onChange={value => {
                        presenceValue.value = value ?? 0;
                      }}
                      step={0.1}
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item label="Stop Sequences">
                <Input
                  value={stopValue.value}
                  onChange={value => {
                    stopValue.value = value.target.value;
                  }}
                  placeholder="Enter sequence and press Tab"
                />
              </Form.Item>
            </>
          ) : null}
        </Form>
        <div className={style["button-box"]}>
          <Button type="text" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" onClick={handleOk} loading={okLoading}>
            Ok
          </Button>
        </div>
      </div>
    </Spin>
  );
}
