import { EdgeRunQuery } from "@uikit/edgeRun/EdgeRunQuery";
import { EdgeRunService } from "@uikit/edgeRun/EdgeRunService";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorCotService } from "@uikit/service/CreatorCotService";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { OpenAiParam } from "@uikit/service/OpenAiParam";
import { TakeProjectSnapshot } from "@uikit/service/TakeProjectSnapshot";
import { CotStore } from "@uikit/store/CotStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { FotClient } from "imagica-corekit/dist/base/api/FotClient";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { CotFlow } from "imagica-corekit/dist/cases/cot/CotFlow";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { Container } from "inversify";
import { ClickSlice } from "@uikit/service/ClickSlice";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { RunEdgeAddNodeToPreview } from "@uikit/service/RunEdgeAddNodeToPreview";
import { AddNode } from "@uikit/service/AddNode";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { MultiRoundChatOnboardingService } from "@uikit/service/MultiRoundChatOnboardingService";
import { PreviewPaneStore } from "@uiview/views/PreviewPane/PreviewPaneStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";

export class RegisterAfter {
  static configure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };

    container
      .bind(CreatorCotService)
      .toConstantValue(
        new CreatorCotService(
          it(FotReduxStore),
          it(FotReactFlow),
          it(FotClient),
          it(CreatorNodesStore),
          it(HomeMethodsService),
          it(EdgeRunService),
          it(OpenAiParam),
          it(EdgeRunQuery),
          projectPageStore,
          it(CreatorEdgesStore),
          it(CreatorSaasAppStore),
          it(PreviewAppStore),
          it(ProjectNodeStore),
          it(HomeStore),
          it(CreatorRefStore),
          it(CreatorAISaasStore),
          it(HomeHandleFieldMethods),
          it(CreatorPreviewService),
          it(TakeProjectSnapshot),
          it(CotStore),
          it(CanvasDataRef),
          it(CotFlow)
        )
      );
    container
      .bind(ClickSlice)
      .toConstantValue(
        new ClickSlice(
          it(CreatorNodesStore),
          it(AddNode),
          it(EdgeRunService),
          it(ProjectRunStore),
          it(CanvasDataRef),
          it(CreatorEdgesStore),
          it(CreatorStoreMethods),
          it(RunEdgeAddNodeToPreview)
        )
      );
    container
      .bind(MultiRoundChatOnboardingService)
      .toConstantValue(
        new MultiRoundChatOnboardingService(
          it(FotReduxStore),
          it(CreatorNodesStore),
          it(CreatorEdgesStore),
          it(CreatorSaasAppStore),
          it(CreatorAISaasStore),
          it(PreviewAppStore),
          it(PreviewPaneStore),
          it(ChatOnboardingStore),
          it(ProjectNodeStore),
          it(ProjectRunStore),
          it(CanvasDataRef),
          it(CotFlow),
          it(CreatorCotService),
          it(CreatorPreviewService),
          it(HomeHandleFieldMethods)
        )
      );
  }
}
