import { ApiStatuses, DefaultProps } from "../../types";
import { FC, ReactNode, useMemo } from "react";
import Loader from "../Loader";

export interface ApiProgressProps extends DefaultProps {
  status: ApiStatuses;
  full?: boolean;
  loading?: ReactNode;
  error?: ReactNode;
}

const ApiProgress: FC<ApiProgressProps> = ({ children, status, full = false, loading, error }) => {
  const isLoading = useMemo(() => {
    return status === ApiStatuses.Idle || status === ApiStatuses.Pending;
  }, [status]);

  return (
    <>
      {isLoading && <>{loading || <Loader full={full} style={{ marginTop: "120px" }} />}</>}
      {status === ApiStatuses.Fulfilled && <>{children}</>}
      {status === ApiStatuses.Rejected && <>{error || <span>Something went wrong...</span>}</>}
    </>
  );
};

export default ApiProgress;
