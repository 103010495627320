"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataTransformerUtil = void 0;
const VBDataShoppingSearchResult_1 = require("../../../base/api/viewBuilderTyped/VBGraphData/VBDataShoppingSearchResult");
const VBDataShoppingProduct_1 = require("../../../base/api/viewBuilderTyped/VBGraphData/VBDataShoppingProduct");
const VBDataRestaurantRecommendation_1 = require("../../../base/api/viewBuilderTyped/VBGraphData/VBDataRestaurantRecommendation");
const VBDataDishRecommendation_1 = require("../../../base/api/viewBuilderTyped/VBGraphData/VBDataDishRecommendation");
class DataTransformerUtil {
    /**
     * 获取VBDataShoppingSearchResult
     * @param from
     */
    static shoppingItemToVBShoppingSearchResult(from) {
        const lists = from.map(item => {
            const newItem = new VBDataShoppingProduct_1.VBDataShoppingProductItem();
            newItem.product_image = item.image;
            newItem.product_name = item.title;
            newItem.product_price = `$${item.price}`;
            newItem.product_rating = `${item.rating}`;
            newItem.product_color = "";
            newItem.product_reviews = `${item.reviews}`;
            const source = new VBDataShoppingProduct_1.VBDataShoppingProductSource();
            source.source_icon = "https://dopniceu5am9m.cloudfront.net/static/assets/230705/vb-test/amazon.png";
            source.source_text = "amazon";
            newItem.buy_button = `Buy for ${newItem.product_price}`;
            newItem.source = source;
            return newItem;
        });
        const result = new VBDataShoppingSearchResult_1.VBDataShoppingSearchResult();
        result.product_list = lists;
        return result;
    }
    /**
     * 转为ShoppingProduct
     * @param from
     */
    static shoppingItemToVBShoppingProduct(from) {
        const lists = from.map(item => {
            const newItem = new VBDataShoppingProduct_1.VBDataShoppingProductItem();
            newItem.product_image = item.image;
            newItem.product_name = item.title;
            newItem.product_price = `$${item.price}`;
            newItem.product_rating = `${item.rating}`;
            newItem.product_color = "";
            newItem.product_reviews = `${item.reviews}`;
            const source = new VBDataShoppingProduct_1.VBDataShoppingProductSource();
            source.source_icon = "https://dopniceu5am9m.cloudfront.net/static/assets/230705/vb-test/amazon.png";
            source.source_text = "amazon";
            newItem.buy_button = `Buy for ${newItem.product_price}`;
            newItem.source = source;
            return newItem;
        });
        const result = new VBDataShoppingProduct_1.VBDataShoppingProduct();
        result.product_list = lists;
        return result;
    }
    /**
     * 餐厅转换
     * @param from
     */
    static restaurantsToVBDataRestaurantRecommendation(from) {
        const lists = from.map(restaurant => {
            const item = restaurant.value;
            const newItem = new VBDataRestaurantRecommendation_1.VBDataRestaurant();
            newItem.restaurant_image = item.cover_img_url ?? "";
            newItem.restaurant_name = item.name ?? "";
            const rating = new VBDataRestaurantRecommendation_1.Rating();
            rating.reviews = `(${item.reviews} reviews)`;
            rating.rating = `${item.average_rating.toFixed(2)}`;
            newItem.restaurant_rating = rating;
            newItem.restaurant_type = "type";
            newItem.restaurant_price_range = `${item.price_range}`;
            return newItem;
        });
        const result = new VBDataRestaurantRecommendation_1.VBDataRestaurantRecommendation();
        const source = new VBDataRestaurantRecommendation_1.Source();
        source.source_icon = "https://dopniceu5am9m.cloudfront.net/static/assets/230705/vb-test/sf-eater.png";
        source.source_text = "sf.eater";
        result.restaurant_list = lists;
        result.title = "Essential Restaurants in San Francisco";
        result.description =
            "These are the places you don’t want to miss on your first trip to the Bay Area, and the ones worth returning to even if you’ve lived here for decades.";
        return result;
    }
    /**
     * 餐厅转换
     * @param from
     */
    static dishesToVBDataDishRecommendation(from) {
        const lists = from.items.map(food => {
            const newFood = new VBDataDishRecommendation_1.Dish();
            newFood.dish_image = food.image_url ?? "";
            newFood.dish_name = food.name ?? "";
            newFood.dish_price = food.price ? (food.price / 100).toFixed(2) : "";
            newFood.dish_delivery_time = "";
            return newFood;
        });
        const source = new VBDataRestaurantRecommendation_1.Source();
        source.source_icon = "https://dopniceu5am9m.cloudfront.net/static/assets/230705/vb-test/doordash.png";
        source.source_text = "doordash";
        const result = new VBDataDishRecommendation_1.VBDataDishRecommendation();
        result.dish_list = lists;
        result.title = from.name || "";
        result.description = from.description;
        result.source = source;
        return result;
    }
}
exports.DataTransformerUtil = DataTransformerUtil;
