import { PlanTable } from "./components/PlanTable";
import css from "./index.module.scss";
import classNames from "classnames";
import { getIt } from "@uikit/getIt";
import { useCallback, useEffect, useMemo, useRef } from "react";
import LoadingOutline from "@views/components/LoadingOutline";
import { useLocation } from "react-router-dom";
import { useCreation } from "ahooks";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { createContainer } from "unstated-next";
import { throttle } from "lodash";
import { useSignal } from "@preact/signals-react";
import { ImagicaCreatorPlanElement } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { DomUtil } from "imagica-uikit/dist/base/util/DomUtil";
import { Header } from "@uiview/views/Mobile/Plan/components/header/Header";
import { ProceedPayment } from "@uiview/views/Mobile/Plan/ProceedPayment";
import { PlansListBloc } from "@uikit/planList/PlansListBloc";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";

export const { Provider: PlanListProvider, useContainer: usePlanList } = createContainer(() => {
  const location = useLocation();
  const brainClient = getIt(BrainClient);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const subscriptionStore = getIt(SubscriptionStore);
  const fotSaveService = getIt(FotSaveService);
  const paybloc = useCreation(
    () =>
      new PlansListBloc(
        location.pathname,
        brainClient,
        creatorSaasAppStore,
        subscriptionStore,
        fotSaveService,
        getIt(ShowNavPageStore)
      ),
    []
  );

  const isScrollBottom = useSignal<boolean>(false);
  const popupBodyRef = useRef<HTMLDivElement>(null);

  const _onScrollPopupWrap = useCallback(() => {
    const target = popupBodyRef.current;
    if (!target) {
      return;
    }
    isScrollBottom.value = DomUtil.hasScrollToBottom(target);
  }, []);

  const onScrollPopupWrap = useMemo(() => {
    return throttle(_onScrollPopupWrap, 150);
  }, [_onScrollPopupWrap]);

  useEffect(() => {
    _onScrollPopupWrap();
    window.addEventListener("resize", onScrollPopupWrap);
    return (): void => {
      window.removeEventListener("resize", onScrollPopupWrap);
    };
  }, [paybloc.state.planTablePopupSingle.value.loading]);

  return {
    paybloc,
    onScrollPopupWrap,
    isScrollBottom,
    popupBodyRef,
  };
});

function PlansListInner(): JSX.Element {
  const { paybloc, onScrollPopupWrap, isScrollBottom, popupBodyRef } = usePlanList();

  useEffect(() => {
    paybloc.initData();
  }, []);

  return (
    <div className={css.planMobile}>
      <div className={classNames(css.planPopupWrap, { [css.bottomFade]: !isScrollBottom.value })}>
        <Header
          title={"Choose your right plan"}
          showReturn={false}
          onClose={() => {
            paybloc.state.showAlertView.value = false;
          }}
        />

        <div className={classNames(css.popupBody)} ref={popupBodyRef} onScroll={onScrollPopupWrap}>
          {paybloc.state.planTablePopupSingle.value.loading ? (
            <LoadingOutline />
          ) : (
            <div className={css.tableMain}>
              <PlanTable
                buttonLoading={
                  paybloc.state.showAlertView.value || paybloc.state.showDownAlertView.value
                    ? { value: paybloc.state.selectPlan.value.value, loading: false }
                    : { value: paybloc.state.selectPlan.value.value, loading: paybloc.state.upgrageLoading.value }
                }
                options={paybloc.state.planTablePopupSingle.value.options}
                featureOptions={paybloc.state.planTablePopupSingle.value.features}
                onUpgradeClick={plan => {
                  paybloc.clickUpgradeBtn(plan);
                }}
                onDowngradeClick={plan => {
                  paybloc.clickDowngradeBtn(plan);
                }}
              />
            </div>
          )}
        </div>
      </div>
      {paybloc.state.showAlertView.value && (
        <ProceedPayment
          payLoading={paybloc.state.upgrageLoading.value}
          plan={paybloc.state.selectPlan.value}
          onPay={(priceObj: ImagicaCreatorPlanElement) => {
            paybloc.confirmUpgrade(priceObj);
          }}
          onClose={() => (paybloc.state.showAlertView.value = false)}
        />
      )}
    </div>
  );
}

export function PlanMobile(): JSX.Element {
  return (
    <PlanListProvider>
      <PlansListInner />
    </PlanListProvider>
  );
}
