import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { HomeMethodsService } from "./HomeMethodsService";
import { logEvent } from "@amplitude/analytics-browser";
import func from "@uikit/func";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { previewStore } from "@uiview/store/PreviewStore";
import { cloneDeep, map } from "lodash";

/**
 * 原来 useHomeDeleteMethods
 */
export class CreatorCanvasDeleteMethods {
  constructor(
    public creatorAISaasStore: CreatorAISaasStore,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public homeMethods: HomeMethodsService,
    public creatorNodesStore: CreatorNodesStore,
    public projectNodeStore: ProjectNodeStore,
    public fotReactFlow: FotReactFlow
  ) {}

  handleSaasDeleteNodes(saasDataObj: any, newThoughts: AISaasOutput.ThoughtType[], nodeIds: any[] = []): void {
    const newInputNode = HomeMethodsUtil.deleteInputNode(saasDataObj, nodeIds);
    // stud-2493 set input(preview)
    this.creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInputNode,
        output: newThoughts,
      };
    });
    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);

    const newInputNodeId = newInputNode.map((x: { id: any }) => x.id);
    this.creatorAISaasStore.setInputId(newInputNodeId);
  }

  handleDeleteGroupNodeByPreviewBtn = (groupId: any): void => {
    const uiType = previewStore.state.selectedUI;
    if (func.isEmpty(uiType)) return;
    // bsf-4912 replace thoughts
    let copyThoughts = cloneDeep(this.creatorSaasAppStore.state.saasUIData.output);
    copyThoughts = copyThoughts.filter(x => !x?.groupId || x.groupId !== groupId);
    const saasActionObj = this.homeMethods.getSaasAction();
    if (func.isEmpty(saasActionObj)) return;
    this.handleSaasDeleteNodes(saasActionObj, copyThoughts);
  };

  handleDeleteSingleNodeByPreviewBtn = (nodeId: string): void => {
    const uiType = previewStore.state.selectedUI;
    if (func.isEmpty(uiType)) return;
    // const nodeIds = nodes.map(x => x.id);
    // bsf-4912 replace thoughts
    let copyThoughts = cloneDeep(this.creatorSaasAppStore.state.saasUIData.output);
    copyThoughts = copyThoughts
      .map(item => {
        if (item.groupId) {
          return item;
        }
        item.results = item.results.filter(child => child.nodeId !== nodeId);
        return item;
      })
      .filter(x => x.results.length > 0);
    const saasActionObj = this.homeMethods.getSaasAction();
    if (func.isEmpty(saasActionObj)) return;
    this.handleSaasDeleteNodes(saasActionObj, copyThoughts);
  };

  handleDeleteNodes(nodes: any[]): void {
    const nodesCopy = this.creatorNodesStore.getNodes();
    this.fotReactFlow.deleteNodeByIds(map(nodes, "id"));
    if (nodes.length === 1) {
      const nodeitem = nodes[0];
      const parentNodeId = nodesCopy.find(item => {
        return item.id === nodeitem.id;
      })?.data?.parentNodeId;
      setTimeout(() => {
        this.creatorAISaasStore.setGroupDeleteChildNode({ id: nodeitem.id, parentNodeId: parentNodeId });
      }, 300);
    }
    const uiType = previewStore.state.selectedUI;
    if (func.isEmpty(uiType)) return;
    const nodeIds = nodes.map(x => x.id);
    // bsf-4912 replace thoughts
    let copyThoughts = cloneDeep(this.creatorSaasAppStore.state.saasUIData.output);
    copyThoughts = copyThoughts
      .map(item => {
        item.results = item.results.filter(child => nodeIds.includes(child.nodeId) === false);
        return item;
      })
      .filter(x => x.results.length > 0);
    const saasActionObj = this.homeMethods.getSaasAction();
    if (func.isEmpty(saasActionObj)) return;
    this.handleSaasDeleteNodes(saasActionObj, copyThoughts, nodeIds as any);
    logEvent("delete_node", {
      nodes,
    });
  }
}
