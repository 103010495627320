import { FC, MouseEvent, useCallback, useMemo } from "react";
import cls from "classnames";
import style from "./style.module.scss";
import Tooltip from "../Tooltip";
import { callback, getAssetsIcon } from "../../utilities";
import { TemplateModel } from "../../types";
import { animated, useSpring } from "@react-spring/web";
import Loader from "../Loader";

export interface TemplateItemProps {
  template: TemplateModel;
  loading?: boolean;
  onClick?: (value: TemplateModel) => void;
  onAddClick?: (value: TemplateModel) => void;
}

const TemplateItem: FC<TemplateItemProps> = ({
  template,
  loading = false,
  onClick = callback,
  onAddClick = callback,
}) => {
  const [templateStyle, templateApi] = useSpring(() => ({}));

  const handleAddClick = useCallback(
    (event: MouseEvent<HTMLImageElement>) => {
      event.stopPropagation();
      onAddClick(template);
    },
    [template, onAddClick]
  );

  const handleClick = useCallback(async () => {
    onClick(template);
    await Promise.all(
      templateApi.start({
        config: { duration: 1000 },
        to: [{ transform: "scale(0.9)" }, { transform: "scale(1)" }],
      })
    );
  }, [template, templateApi, onClick]);

  const imageUrl = useMemo(() => template.thumbnail || "/fot/images/template.png", [template]);

  return (
    <div style={{ width: "100%" }}>
      {/* {loading && (
        <div className={style.loading}>
          <Loader full />
        </div>
      )} */}
      <animated.div className={style.template} style={templateStyle} onClick={handleClick}>
        {loading && (
          <div className={style.loading}>
            <Loader full />
          </div>
        )}
        <div className={style.template__image}>
          <img
            className={cls(style.icon, style.icon_add)}
            src={getAssetsIcon("plus-blue.svg")}
            alt="plus"
            onClick={handleAddClick}
          />
          <img className={style.image} src={imageUrl} alt="template" />
        </div>
        <div className={style.template__description}>
          <div className={style.templateDescription}>
            <div className={style.templateDescription__text}>{template.title || "Untitled"}</div>
            <div className={style.templateDescription__icon}>
              <Tooltip className={style.templateTooltip} content={<>{template.description}</>}>
                <img className={style.icon} src={getAssetsIcon("info.svg")} alt="info" />
              </Tooltip>
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default TemplateItem;
