import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from "react";
import c from "classnames";
import style from "./style.module.css";

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon?: ReactNode;
}

const Button: FC<ButtonProps> = ({ icon, children, ...rest }) => {
  return (
    <button {...rest} className={c(style.button, rest.className)}>
      {icon && <div className={style.button__icon}>{icon}</div>}
      <div className={style.button__text}>{children}</div>
    </button>
  );
};

export default Button;
