import React from "react";
import func from "@uikit/func";
import style from "../components-css/Shopping.module.css";

function Shopping(props) {
  return (
    <section className={style["CardHomeShopping"]}>
      <img src={props.image} />
      <article>
        <p className={style["shoppingTitle"]}>{props.title}</p>
        {!func.isEmpty(props.price) ? <p>{props.price}</p> : <p className={style["NADefaults"]}>N/A</p>}
      </article>
    </section>
  );
}

export default Shopping;
