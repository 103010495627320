import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class InvalidShareAppTipStoreState {
  /**
   * show tips when share app has no valid datas
   */
  showInvalidShareAppTip = false;

  copyWith(param: Partial<Omit<InvalidShareAppTipStoreState, "copyWith">>): InvalidShareAppTipStoreState {
    const newState = new InvalidShareAppTipStoreState();
    newState.showInvalidShareAppTip = param.showInvalidShareAppTip ?? this.showInvalidShareAppTip;

    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);

class InvalidShareAppTipStore extends StoreBase<InvalidShareAppTipStoreState> {
  constructor() {
    super(() => new InvalidShareAppTipStoreState());
  }

  setShowInvalidShareAppTip = (showInvalidShareAppTip: boolean): void => {
    this.emit(this.state.copyWith({ showInvalidShareAppTip }));
  };
}
export const invalidShareAppTipStore = new InvalidShareAppTipStore();
