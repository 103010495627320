"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalResponse = exports.GlobalClient = void 0;
const JsonUtil_1 = require("./JsonUtil");
const LangUtil_1 = require("./LangUtil");
const RestClient_1 = require("./RestClient");
const StreamClient_1 = require("./StreamClient");
const GenUtil_1 = require("./GenUtil");
const ResponseUtil_1 = require("../cutil/ResponseUtil");
const RequestTimeoutValidUtil_1 = require("../util/RequestTimeoutValidUtil");
/**
 * 如果状态码不是 200-299, 会抛出异常 [RestResponseError]
 * 如果 JSON 转 Model 出现错误，会抛出异常 [RestTransformError]
 */
class GlobalClient {
    constructor(adapter, abortManager = undefined) {
        this.adapter = adapter;
        this.abortManager = abortManager;
    }
    async request(params) {
        const adapter = await (async () => this.adapter)();
        let contentType = adapter.contentType;
        if (params.contentType) {
            contentType = params.contentType;
        }
        let requestInit = {
            method: params.method,
            headers: (0, RestClient_1.combineHeaders)(adapter.headers, params.headers),
        };
        if (contentType) {
            delete requestInit.headers["Content-Type"];
        }
        if (params.data) {
            let body = params.data;
            if (params.data instanceof FormData) {
                body = params.data;
            }
            else {
                if (contentType == RestClient_1.RestContentType.json) {
                    requestInit.headers["Content-Type"] = contentType;
                    if (typeof params.data !== "string") {
                        body = JsonUtil_1.JsonUtil.stringify(params.data);
                    }
                    else {
                        body = params.data;
                    }
                }
                else if (contentType == RestClient_1.RestContentType.form) {
                    requestInit.headers["Content-Type"] = contentType;
                    if (typeof params.data !== "string") {
                        body = JsonUtil_1.JsonUtil.stringify(params.data);
                    }
                    else {
                        body = params.data;
                    }
                }
                else if (!contentType) {
                    // temporary use JSON, but we should be able to determine the content type from body later
                    // 用户没有自定义content-type，那么需要默认填入
                    if (!this.containKeyIgnoreCase(requestInit.headers, "content-type")) {
                        requestInit.headers["Content-Type"] = RestClient_1.RestContentType.json;
                    }
                    if (typeof params.data !== "string") {
                        body = JsonUtil_1.JsonUtil.stringify(params.data);
                    }
                    else {
                        body = params.data;
                    }
                }
            }
            requestInit = { ...requestInit, body: body };
        }
        const uuid = params.uuid || GenUtil_1.GenUtil.randomString();
        const abortController = new AbortController();
        if (this.abortManager) {
            this.abortManager.add(uuid, abortController, params);
        }
        requestInit = { ...requestInit, signal: abortController.signal };
        let url = (0, RestClient_1.combinePath)(params.path, params.queryParameters);
        if (!(url.startsWith("http://") || url.startsWith("https://"))) {
            url = adapter.baseUrl + url;
        }
        const requestTimeoutValidUtil = new RequestTimeoutValidUtil_1.RequestTimeoutValidUtil(url);
        let res = await (0, LangUtil_1.tryPromise)(fetch(url, requestInit));
        requestTimeoutValidUtil.clear();
        if (res.error && abortController.signal.aborted !== true && requestTimeoutValidUtil.valid) {
            res = requestTimeoutValidUtil.response();
        }
        if (isStreamResponse(res.data?.headers)) {
            return (0, StreamClient_1.handleResponse)(res, adapter, () => {
                if (this.abortManager) {
                    this.abortManager.abort({ uuid: uuid });
                }
            }).then(value => {
                return new GlobalResponse(value);
            });
        }
        else {
            if (this.abortManager) {
                this.abortManager.remove(uuid);
            }
            return handleRestResponse(res, adapter).then(value => {
                return new GlobalResponse(value);
            });
        }
    }
    async post(path, data, queryParameters, headers, contentType = undefined, uuid) {
        return this.request({
            path,
            method: "POST",
            headers,
            data,
            queryParameters,
            contentType,
            uuid,
        });
    }
    /**
     * 遍历header 是否包含某个key
     * @param header
     * @param key
     * @private
     */
    containKeyIgnoreCase(header, key) {
        for (const headerKey in header) {
            if (headerKey.toLowerCase() === key.toLowerCase()) {
                return true;
            }
        }
        return false;
    }
}
exports.GlobalClient = GlobalClient;
async function handleRestResponse(result, adapter) {
    if (result.error) {
        const basicError = new RestClient_1.RestInternalError(result.error);
        adapter?.errorHandler(basicError);
        throw basicError;
    }
    const res = result.data;
    if ((0, RestClient_1.isFileType)(res)) {
        const realRes = await ResponseUtil_1.ResponseUtil.wrap(res);
        const response = new RestClient_1.RestResponse(res.status, res.statusText, res.headers, realRes);
        if (!response.isSuccess) {
            adapter?.errorHandler(response);
        }
        return response;
    }
    const realRes = await ResponseUtil_1.ResponseUtil.wrap(res);
    const response = new RestClient_1.RestResponse(res.status, res.statusText, res.headers, realRes);
    if (!response.isSuccess) {
        adapter?.errorHandler(response);
    }
    return response;
}
class GlobalResponse {
    constructor(res) {
        this.res = res;
        this._text = undefined;
        this._blob = undefined;
    }
    get isStream() {
        if (this.res instanceof StreamClient_1.StreamResponse) {
            return true;
        }
        return false;
    }
    get headers() {
        return this.response.headers;
    }
    get status() {
        return this.response.status;
    }
    get message() {
        return this.response.statusText;
    }
    get response() {
        if (this.res instanceof StreamClient_1.StreamResponse) {
            return this.res.response;
        }
        return this.res.data;
    }
    get stream() {
        if (this.isStream) {
            return this.res;
        }
        return undefined;
    }
    async json() {
        if (this.isStream) {
            return undefined;
        }
        if (!this.response) {
            return undefined;
        }
        /**
         * GlobalResponse，对于text、json 需要提前读取出数据，为了外面同步读取错误信息
         */
        if (ResponseUtil_1.ResponseUtil.unwrapJson(this.response) !== undefined) {
            return ResponseUtil_1.ResponseUtil.unwrapJson(this.response);
        }
        return this.response.json();
    }
    async text() {
        if (this.isStream) {
            return undefined;
        }
        if (!this.response) {
            return undefined;
        }
        if (this._text) {
            return this._text;
        }
        /**
         * GlobalResponse，对于text、json 需要提前读取出数据，为了外面同步读取错误信息
         */
        if (ResponseUtil_1.ResponseUtil.unwrapText(this.response) !== undefined) {
            this._text = ResponseUtil_1.ResponseUtil.unwrapText(this.response);
            return this._text;
        }
        this._text = await this.response.text();
        return this._text;
    }
    async blob() {
        if (this.isStream) {
            return undefined;
        }
        if (!this.response) {
            return undefined;
        }
        if (this._blob) {
            return this._blob;
        }
        this._blob = this.response.blob();
        return this._blob;
    }
    get errorDescription() {
        return this.res.errorDescription;
    }
    get isSuccess() {
        return this.status >= 200 && this.status < 300;
    }
    validate() {
        return this.res.validate();
    }
}
exports.GlobalResponse = GlobalResponse;
function isStreamResponse(headers) {
    if (!headers) {
        return false;
    }
    return headers.get("Content-Type") === "text/event-stream";
}
