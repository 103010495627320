import { Button } from "antd";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { ShareAccountBloc } from "./ShareAccountBloc";
import styles from "./AccountStatus.module.scss";
import { PerMonth } from "./PerMonth";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fotActions } from "@store/fot";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";

const creatorSaasAppStore = getIt(CreatorSaasAppStore);
export function Subscribe(props: {
  store: PublishSubscriptionStore;
  metaDataV2: PreviewApp.PublishedMetadataV2Type | undefined;
  bloc: ShareAccountBloc;
  isLogin: boolean;
}): React.JSX.Element {
  const store = getIt(PublishSubscriptionStore);
  const state = useStore(store).value;
  const creatorSaasAppState = useStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const title = saasUIData?.title || "Untitled";
  return (
    <div className={styles.subscribeWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>
          <div>{title}</div>
          <div>Subscription</div>
        </div>
        <div className={styles.subTitle}>Unlimited access to {title}.</div>
      </div>
      <PerMonth state={state} />
      {props.isLogin ? (
        <Login bloc={props.bloc} store={props.store} />
      ) : (
        <Purchase store={props.store} metaDataV2={props.metaDataV2} bloc={props.bloc} />
      )}
    </div>
  );
}

function Purchase(props: {
  store: PublishSubscriptionStore;
  metaDataV2: PreviewApp.PublishedMetadataV2Type | undefined;
  bloc: ShareAccountBloc;
}): React.JSX.Element {
  const subscriberParamsData = useSelector((state: any) => state.editor.subscriberParamsData);
  const homeStore = getIt(HomePluginStore);
  const homeState = useStore(homeStore).value;

  return (
    <Button
      loading={props.bloc.state.btnLoading.value}
      onClick={() => {
        props.bloc.purchase(props.metaDataV2, props.store, homeState, subscriberParamsData?.guest_user_key);
      }}
      className={styles.btn}
      type="primary"
    >
      Subscribe
    </Button>
  );
}

function Login(props: { bloc: ShareAccountBloc; store: PublishSubscriptionStore }): React.JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setIsLogin = (val: any) => {
    dispatch(fotActions.setIsLogin(val));
  };
  return (
    <Button
      onClick={() => {
        props.store.setOpenPayWallAndLogin(false);
        props.bloc.withParamsToSignin({ setIsLogin, navigate });
      }}
      className={styles.btn}
      type="primary"
    >
      Log in to subscribe
    </Button>
  );
}
