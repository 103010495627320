import func from "@uikit/func";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { ProjectNodeUtil } from "@uikit/projectNode/ProjectNodeUtil";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { OpenAiParamUtil } from "@uikit/util/OpenAiParamUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { PostUrlCanAbortService } from "./PostUrlCanAbortService";

/**
 * 原来 useOpenAiParm hook
 */
export class OpenAiParam {
  constructor(
    public fotReduxStore: FotReduxStore,
    public creatorNodesStore: CreatorNodesStore,
    public projectNodeStore: ProjectNodeStore,
    public postUrlCanAbortService: PostUrlCanAbortService
  ) {}

  getOpenAiParam(param: { lineParams: any; prompt: any; nodeText: any }): Record<string, any> {
    const variableList = this.fotReduxStore.getState().fot.variableList;

    const nodes = this.creatorNodesStore.getNodes();
    let { prompt } = param;

    prompt = OpenAiParamUtil.replaceInputAndCustomVars({
      variableList,
      nodes,
      prompt,
      nodeText: param.nodeText,
      isNode: true,
      setVariableList: (value: any) => {
        this.fotReduxStore.setVariableList(value);
      },
    });
    // const inputTestReg = /<input>/ig
    // const variableRegs = variableList.map(x => {
    //   const currentNode = nodes.find(y => y.id === x.node.id)
    //   const currentNodeTextAreaValue = currentNode?.data?.textAreaValue
    //   let recentValue = x.node.data.textAreaValue

    //   if (!util.isEmpty(currentNodeTextAreaValue)) {
    //     recentValue = currentNodeTextAreaValue
    //     // 更新variableList保存的textAreaValue
    //     const tempVariableList = [...variableList]
    //     const currVarIdx = tempVariableList.findIndex(y => y.name === x.name)
    //     tempVariableList.splice(currVarIdx, 1, {
    //       name: x.name,
    //       node: {
    //         ...x.node,
    //         data: {
    //           ...x.node.data,
    //           textAreaValue: currentNodeTextAreaValue
    //         }
    //       }
    //     })
    //     setVariableList([...tempVariableList])
    //   }

    //   return {
    //     reg: new RegExp(`<${x.name}>`, 'g'),
    //     value: recentValue || ''
    //   }
    // })
    // // 匹配自定义变量并替换值
    // if (!util.isEmpty(variableRegs)) {
    //   variableRegs.forEach(x => {
    //     prompt = prompt.replace(x.reg, x.value)
    //   })
    // }
    // // 替换<input>变量
    // if (inputTestReg.test(prompt)) {
    //   prompt = prompt.replace(inputTestReg, nodeText)
    // } else {
    //   prompt = `${prompt}${nodeText}`
    // }

    return {
      engine: param.lineParams.engine,
      frequency_penalty: param.lineParams.frequencyPenalty,
      max_tokens: param.lineParams.maxTokens,
      presence_penalty: param.lineParams.presencePenalty,
      stop: param.lineParams.stopSequences,
      temperature: param.lineParams.temperature,
      prompt: prompt,
    };
  }

  async getCompletionsGenerationParam(
    identifierType: string,
    identifierValue: any,
    variable: any,
    streaming = true,
    instantIntentDomain = ""
  ): Promise<Record<string, any>> {
    const customVariable: Record<string, any> = {};
    const postParam: Record<string, any> = {
      identifier_type: identifierType,
      identifier_value: identifierValue,
      stream: streaming,
    };
    if (identifierType === "file" && instantIntentDomain) {
      postParam.domain = instantIntentDomain;
    }

    if (identifierType === "object_id") {
      const variableList = this.fotReduxStore.getState().fot.variableList as any[];
      variableList.forEach(x => {
        customVariable[x.name] = x.node.data.textAreaValue;
      });
    }
    const combinedVariables = Object.assign({}, { ...variable }, { ...customVariable });
    const stringifiedVariables: Record<string, any> = {};
    Object.entries(combinedVariables).forEach(x => {
      const key = x[0];
      const val = x[1];
      stringifiedVariables[key] = typeof val !== "string" ? JSON.stringify(val) : val;
    });
    postParam.variables = stringifiedVariables;
    return postParam;
  }

  /**
   * 原来 useHomeService 中的 getEdgeGeneration,
   *
   * 但是 GeneratePromptService 中有个同名方法,所以重命名 getEdgeGenerationParam
   *
   * @param aiValue
   * @param id
   * @returns
   */
  async getEdgeGenerationParam(aiValue: any, id: string): Promise<any> {
    // const query = `The system generates a system description based on the Description of the AI.
    //   ###
    //   Description of AI: AI that generates HTML and inline CSS code based on a description of a UI.
    //   System  Description: You are an AI that generates HTML and inline CSS code based on a given description of a UI.
    //   ###
    //   Description of AI: ${aiValue}
    //   System Description:`
    const postParam = (await this.getCompletionsGenerationParam(
      "file",
      "brain_studios_edge_prompt_generation.model",
      {
        input: aiValue,
      },
      false,
      "brain_studios"
    )) as any;
    // 多连线不创建变量 直接获取sourceNode的title
    const edgeNode = this.creatorNodesStore.getNodes().find(x => x.id === id) as any;
    const sourceIdArr = edgeNode.data.flows.map((x: { sourceNodeId: any }) => x.sourceNodeId);
    if (sourceIdArr.length > 1) {
      // multiple input
      // stud-2493 get input title(nodes)
      const sourceLabels = ProjectNodeUtil.filterTitleByIds(this.projectNodeStore.getNodes(), sourceIdArr);

      postParam.extra_inputs = Object.assign(
        postParam.extra_inputs || {},
        OpenAiParamUtil.arrayKeyToObject(sourceLabels)
      );
    }

    const res = await this.postUrlCanAbortService.postData("/be/bas-demo-v4/nlp/prompt_generation", postParam, false);
    if (res.status !== 200 || !res?.data?.identifier) {
      func.messageUtil("Failed to create AI");
      console.error("Failed to generate prompt", res.statusCode);
      return;
    }
    const resultQuery = res?.data?.identifier;
    return resultQuery;
  }
}
