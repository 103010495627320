"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlHandler = void 0;
const TransformServiceHandlerChain_1 = require("../chain/TransformServiceHandlerChain");
const CustomApiTransformUtil_1 = require("../util/CustomApiTransformUtil");
const LangUtil_1 = require("../../../../cutil/LangUtil");
const CustomApiTransformError_1 = require("../error/CustomApiTransformError");
class UrlHandler extends TransformServiceHandlerChain_1.Handler {
    constructor(brainClient) {
        super();
        this.brainClient = brainClient;
    }
    async process(request) {
        /// 只处理带有 'X-Brain-Imagica-Node' 的数据
        if (!request.hasNodeDisplayType) {
            return false;
        }
        /// 只处理 upload file type数据
        if (!CustomApiTransformUtil_1.CustomApiTransformUtil.isUploadFileType(request.displayType)) {
            return false;
        }
        const result = await (0, LangUtil_1.tryPromise)(request.json());
        if (!result.data) {
            throw CustomApiTransformError_1.CustomApiTransformError.default;
        }
        const json = result.data;
        const url = json.s3_url || json.url;
        /// 如果没有返回 url，不执行后续逻辑
        if (!url && typeof url !== "string") {
            return false;
        }
        let method = "get";
        if (typeof json.method === "string") {
            method = json.method;
        }
        const res = await (0, LangUtil_1.tryPromise)(this.brainClient.global.request(url, method));
        /// 更新 response 继续处理后续内容
        request.updateResponse(res.data);
        const data = await CustomApiTransformUtil_1.CustomApiTransformUtil.getBlobResult(request, url);
        request.setResult(data);
        return true;
    }
}
exports.UrlHandler = UrlHandler;
