"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioProjectAttributesV3Node = void 0;
const class_transformer_1 = require("class-transformer");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const StudioProjectAttributesV2Edge_1 = require("./StudioProjectAttributesV2Edge");
const StudioProjectAttributesV2Node_1 = require("./StudioProjectAttributesV2Node");
const StudioProjectAttributesV3NodeData_1 = require("./StudioProjectAttributesV3NodeData");
class StudioProjectAttributesV3Node {
    constructor() {
        this.id = "";
    }
}
exports.StudioProjectAttributesV3Node = StudioProjectAttributesV3Node;
__decorate([
    (0, class_transformer_1.Transform)(({ value, obj }) => {
        if (obj.type === "customNode" || obj.type === "customGroup") {
            return JsonUtil_1.JsonUtil.toModelFromType(StudioProjectAttributesV3NodeData_1.StudioProjectAttributesV3NodeData, value);
        }
        return JsonUtil_1.JsonUtil.toModelFromType(StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2EdgeData, value);
    }, { toClassOnly: true })
], StudioProjectAttributesV3Node.prototype, "data", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2Node_1.StudioProjectAttributesV2NodePosition)
], StudioProjectAttributesV3Node.prototype, "position", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2Node_1.StudioProjectAttributesV2NodePositionAbsolute)
], StudioProjectAttributesV3Node.prototype, "positionAbsolute", void 0);
