import { computed, Signal, signal } from "@preact/signals-react";
import {
  AlertData,
  AlertOtpData,
  BottomViewData,
  EditData,
  HeaderData,
  UploadState,
  DeleteAccountData,
  DeleteAccountAskData,
  DeleteAlertState,
} from "@uiview/pages/profile/account/AccountData";
import isBlank from "@sedan-utils/is-blank";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { RcFile } from "antd/lib/upload";
import { ErrorUtil } from "imagica-corekit/dist/base/api/ErrorUtil";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { getIt } from "@uikit/getIt";
import { BrainClient as CorekitBrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { title } from "@uiview/views/Guide/constants";
import { Empty } from "imagica-corekit/dist/base/cutil/Empty";
import { logEvent } from "@uikit/service/amplitude";

export class AccountPageState {
  nameEdit = new EditData(signal(""), signal("Please input name"), signal(""), signal("Name"), signal(false));
  phoneEdit = new EditData(signal(""), signal("Please input phone"), signal(""), signal("Phone Number"), signal(false));

  headerData = new HeaderData(
    signal(""),
    signal("JPG, GIF or PNG. Max size of 800K"),
    signal([UploadState.normal, ""]),
    signal(false)
  );
  emailEdit = new EditData(signal("Please input email"), signal(""), signal(""), signal("Email"), signal(false));
  bottomData = computed(() => {
    const header = this.headerData;
    const nameEdit = this.nameEdit;
    const emailEdit = this.emailEdit;
    const bottomViewData = new BottomViewData(false, false, false);
    if (
      nameEdit.loading.value ||
      emailEdit.loading.value ||
      this.headerData.deleteLoading.value ||
      this.headerData.state.value[0] === UploadState.loading ||
      this.headerData.state.value[0] === UploadState.progress
    ) {
      bottomViewData.isEnable = false;
      bottomViewData.updateLoading = true;
      return bottomViewData;
    }
    if (!isBlank(nameEdit.errorText.value) || !isBlank(emailEdit.errorText.value)) {
      bottomViewData.isEnable = false;
      bottomViewData.updateLoading = false;
      return bottomViewData;
    }

    if (nameEdit.textChanged() && !isBlank(nameEdit.value.value)) {
      bottomViewData.isEnable = true;
      return bottomViewData;
    }
    if (emailEdit.textChanged()) {
      bottomViewData.isEnable = true;
      return bottomViewData;
    }
    bottomViewData.isEnable = false;
    return bottomViewData;
  });

  showAlert: Signal<AlertData | null> = signal(null);
  showOtpAlert: Signal<AlertOtpData | null> = signal(null);
  showDeleteAccountAlert: Signal<DeleteAccountData | null> = signal(null);
  deleteLoading = signal(false);
  //
  email_verified = signal(false);
  showVerifyNow = computed(() => {
    // 文本已经变更了，那么肯定是不需要显示的
    if (this.emailEdit.textChanged()) {
      return false;
    }
    // 判断是否验证邮箱，如果未验证，那么显示
    return !this.email_verified.value;
  });
  verifyNowLoading = signal(false);
}
export class AccountPageBloc {
  brainClient = getIt(CorekitBrainClient);
  meStore = getIt(MeStore);
  checkedCode = "";
  userProjectCountStr = "";
  constructor(public state: AccountPageState) {
    this.loadMeData();
    // this.setUserProjectNumText();
  }

  /**
   * 是否在Loading
   */
  isLoading = computed(() => {
    const state = this.state;
    return (
      state.headerData.deleteLoading.value ||
      state.headerData.state.value[0] === UploadState.loading ||
      state.nameEdit.loading.value ||
      state.emailEdit.loading.value ||
      state.bottomData.value.updateLoading
    );
  });

  /**
   * 获取数据
   */
  async loadMeData() {
    const result = await tryPromise(this.meStore.get());
    const me = result.data;
    if (me) {
      this.state.nameEdit.value.value = me.fullname;
      this.state.emailEdit.value.value = me.email ?? "";
      this.state.phoneEdit.value.value = me.profile?.phone_number ?? "";
      this.refreshIconToBlob();
      this.state.email_verified.value = me.profile?.email_verified ?? false;
      this.state.emailEdit.initValue.value = me.email ?? "";
      this.state.nameEdit.initValue.value = me.fullname;
    } else {
      console.log("get me data error: ", result.error);
    }
  }

  /**
   * 输入框失去焦点
   * @param title
   */
  tapNameEnter(title: string) {
    if (title === "Name") {
      this.updateName();
    } else if (title === "Email") {
      this.updateEmail();
    } else {
    }
  }
  onBlur(title: string) {
    if (title === "Name") {
    } else if (title === "Email") {
    } else {
    }
  }

  /**
   * 点击绿色的verifynow文本
   * @param title
   */
  tapVerifyNow(title: string) {
    if (title === "Email") {
      this.state.emailEdit.errorText.value = "";
      this.updateEmail();
    } else {
      this.state.phoneEdit.errorText.value = "";
      // this.updatePhone()
    }
  }

  /**
   * 文本发送变化
   * @param title
   * @param newValue
   */
  textOnChange(title: string, newValue: string) {
    // this.state.lastChangeEditTitle.value = title
    if (title === "Name") {
      this.state.nameEdit.value.value = newValue;

      this.checkName(newValue);
    } else if (title === "Email") {
      this.state.emailEdit.value.value = newValue;
      this.checkEmail(newValue);
    } else {
      this.state.phoneEdit.value.value = newValue;
    }
  }

  /**
   * 验证邮箱是否邮箱
   * @param email
   */
  checkEmail(email: string) {
    const emailValid = this.regExp("Email");
    this.state.emailEdit.errorText.value = emailValid ? "" : "Your text is invalid!";
  }

  checkName(name: string) {
    const nameValid = this.regExp("Name");
    this.state.nameEdit.errorText.value = nameValid ? "" : "Your text is invalid!";
  }

  regExp(title: string) {
    if (title === "Email") {
      const email = this.state.emailEdit.value.value;
      const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      return reg.test(email);
    } else if (title === "Phone") {
    } else if (title === "Name") {
      const name = this.state.nameEdit.value.value;
      // const reg = /^[A-Za-z]*(\s[A-Za-z]*)*$/;
      // return reg.test(name);
      if (name.length > 0 && isBlank(name.trim())) {
        return false;
      }
      if (name.length >= 30) {
        return false;
      }
      return true;
    }
  }

  /**
   * 将s3url 转为blob url
   * @param iconUrl
   * @private
   */
  private async refreshIconToBlob() {
    const result = await tryPromise(this.meStore.profileImgUrl());
    if (result.data) {
      this.state.headerData.icon.value = result.data ?? "";
      return;
    }
    this.state.headerData.icon.value = "";
  }

  /**
   * 删除账号
   */
  tapDeleteAccount(): void {
    this.enterDeleteAccountData();
  }

  /**
   * 上传头像
   * @param file
   */
  async uploadIcon(file: RcFile) {
    // this.brainClient.user.updateProfile()
    console.log("tapUploadIcon");
    const header = this.state.headerData;
    header.state.value = [UploadState.loading, ""];
    // 上传数据到s3
    const readResult = await file.stream().getReader().read();
    if (!readResult.value) {
      header.state.value = [UploadState.error, ErrorUtil.defaultErrorMsg];
      throw new Error("file was emtpy");
    }
    const profileUpload = await tryPromise(
      this.brainClient.upload.profileUploadXhr(readResult.value, (loaded, total) => {
        console.log("percent: ", loaded / total);
        header.state.value = [UploadState.progress, `${loaded / total}`];
      })
    );
    if (!profileUpload.data) {
      throw new Error("upload failed");
    }
    // 组装好s3的url，更新到Profile
    const profile: Record<string, any> = { profile_img_url: profileUpload.data.data?.s3_url };
    logEvent("my_account_click_upload_image_button", {
      file_name: file.name,
      file_extension: file.type,
      s3_location: profileUpload.data.data?.s3_url,
      file_size: file.size,
    });
    const params: Record<string, any> = { profile: profile };
    const result = await tryPromise(this.brainClient.user.updateProfile(params));
    const me = result.data?.data;
    if (me) {
      this.meStore.receive(JsonUtil.parseFromInstance(me));
      await this.refreshIconToBlob();
    } else {
      console.log("update profile error: ", result.error);
    }
    header.state.value = [UploadState.finish, ""];
  }

  /**
   * 删除头像的api，这里后续可能还会有修改
   * @param deleteUrl
   */
  deleteIcon(deleteUrl: string) {
    return this.brainClient.user.deleteIconUrl(deleteUrl);
  }

  /**
   * 删除头像
   */
  async tapDeleteIcon() {
    this.state.headerData.deleteLoading.value = true;
    const result = await tryPromise(this.meStore.get());
    const profileImgUrl = result.data?.profile?.profile_img_url ?? "";
    if (!isBlank(profileImgUrl)) {
      const res = await tryPromise(this.deleteIcon(profileImgUrl));
      if (!res.data?.data) {
        console.log("删除头像失败: ", res.data?.errorDescription);
      }
      const profile: Record<string, any> = { profile_img_url: "" };
      const params: Record<string, any> = { profile: profile };
      const resetProfile = await tryPromise(this.brainClient.user.updateProfile(params));
      const me = resetProfile.data?.data;
      if (me) {
        this.meStore.receive(JsonUtil.parseFromInstance(me));
        this.refreshIconToBlob();
      } else {
        console.log("删除头像失败: ", resetProfile.data?.errorDescription);
      }
    }
    this.state.headerData.deleteLoading.value = false;
  }

  /**
   * 验证码输入完成
   * @param otp
   */
  otpInputComplete(otp: string) {
    if (this.state.showOtpAlert.value?.isEmail) {
      if (this.checkedCode === otp) {
        return;
      }
      this.state.showOtpAlert.value?.stopOtp();
      this.checkedCode = otp;
      this.updateEmail(otp);
      return;
    }
    // this.updatePhone(otp)
  }

  /**
   * 点击otp弹窗 sendgain
   */
  async otpSendAgain() {
    this.checkedCode = "";
    await this.updateEmail();
  }

  /**
   * 更新名字
   */
  async updateName() {
    const nameEdit = this.state.nameEdit;
    if (
      nameEdit.loading.value ||
      !isBlank(nameEdit.errorText.value) ||
      !nameEdit.textChanged() ||
      isBlank(nameEdit.value.value)
    ) {
      return;
    }

    this.state.nameEdit.loading.value = true;
    const nameTextValue = this.state.nameEdit.value.value;
    const firstName = nameTextValue.split(" ")[0];
    const lastName = nameTextValue.replace(firstName, "").trim();
    const params = { first_name: firstName, last_name: lastName };
    const result = await tryPromise(this.brainClient.user.updateProfile(params));
    this.state.nameEdit.loading.value = false;
    this.state.nameEdit.initValue.value = result.data?.data.fullname ?? "";
    if (result.data) {
      console.log("update name success");
    } else {
      console.log("update name error");
    }
  }

  /**
   * 更新邮箱
   * @param otp
   */
  async updateEmail(otp?: string) {
    const email = this.state.emailEdit.value.value;
    let params: Record<string, any> = {};
    if (otp) {
      params["otp"] = otp;
      this.state.showOtpAlert.value = this.getAlertOtpData(true, "", true);
    }
    this.state.emailEdit.loading.value = true;
    const me = await this.meStore.get();
    // 邮箱是否验证
    const emailVerified = me.profile?.email_verified ?? false;
    // 未认证这个邮箱，并且email用户没有改变，
    if (!emailVerified && !this.state.emailEdit.textChanged()) {
      const emailVerifiedRecord = { email_verified: true };
      params["profile"] = emailVerifiedRecord;
      this.state.verifyNowLoading.value = true;
    } else {
      params["email"] = email;
    }
    const result = await tryPromise(this.brainClient.user.updateProfile(params));
    this.state.emailEdit.loading.value = false;
    this.state.verifyNowLoading.value = false;
    if (result.data) {
      logEvent("my_account_change_email", {
        user_email_new: email,
      });
      this.state.showAlert.value = this.getAlertSuccessData(true);
      this.state.showOtpAlert.value = null;
      this.state.emailEdit.initValue.value = this.state.emailEdit.value.value;
      this.checkEmail(this.state.emailEdit.value.value);
      this.state.email_verified.value = true;
      this.meStore.receive(JsonUtil.parseFromInstance(result.data.data));
      console.log("verify email success");
    } else {
      const meta = result.error;
      if (meta?.status === 403 && meta?.data?.detail === "OTP needed for verification") {
        this.state.showOtpAlert.value = this.getAlertOtpData(true);
        const otpexp = meta?.data?.OTP_EXP;
        if (otpexp && Number(otpexp)) {
          this.state.showOtpAlert.value?.beginOtp(Number(otpexp));
        }
      } else {
        if (otp) {
          console.log("update email error otp: ", meta);
          // 获取验证码报错
          const email = meta?.data?.email;
          let errorMessage = ErrorUtil.defaultErrorMsg;
          if (email && email instanceof Array && email.length > 0) {
            errorMessage = email[0];
          }
          if (meta?.data && meta.data instanceof Array && meta?.data.length > 0) {
            errorMessage = meta?.data[0];
          }
          // 验证码验证报错
          this.state.showOtpAlert.value = this.getAlertOtpData(true, errorMessage);
          return;
        } else {
          // 获取验证码报错
          const email = meta?.data?.email;
          console.log("update email error: ", meta);
          if (email && email instanceof Array && email.length > 0) {
            this.state.emailEdit.errorText.value = email[0];
            return;
          }
          this.state.showAlert.value = new AlertData(ErrorUtil.defaultErrorMsg, "");
        }
      }
    }
  }

  /**
   * 需要弹起验证码弹窗的数据组装
   * @param email
   * @param error
   * @param otpLoading
   */
  private getAlertOtpData(email: boolean = true, error: string = "", otpLoading: boolean = false): AlertOtpData {
    if (email) {
      return new AlertOtpData(
        "Verify email",
        `A verification code has been send to:\n${this.state.emailEdit.value.value}`,
        error,
        email,
        otpLoading
      );
    } else {
      return new AlertOtpData(
        "Verify Phone Number",
        `A verification code has been send to:\n${this.state.phoneEdit.value.value}`,
        error,
        email,
        otpLoading
      );
    }
  }

  /**
   * 当修改邮箱成功，弹出一个提示
   * @param isEmail
   * @private
   */
  private getAlertSuccessData(isEmail: boolean = true): AlertData {
    const title = isEmail ? "Email Successfully Verified!" : "Phone Number Successfully Verified!";
    const detail = isEmail ? "Your Email was verified successfully." : "Your phone number was verified successfully.";
    return new AlertData(title, detail);
  }

  /**
   * 点击save changes
   */
  async tapSavesChanges() {
    const state = this.state;
    if (state.nameEdit.textChanged()) {
      logEvent("my_account_change_name", {
        user_name: state.nameEdit.value.value,
      });
      await this.updateName();
    }
    if (state.emailEdit.textChanged()) {
      await this.updateEmail();
    }
  }

  /**
   * 进入delete account
   */
  enterDeleteAccountData(): void {
    const askDeleteData = new DeleteAccountAskData(
      "Are you sure you want to permanently delete your account?",
      "Projects with paid subscribers will be available to them until the end of their subscription.",
      "",
      "",
      "Cancel",
      "Delete Account"
    );
    if (this.userProjectCountStr) {
      askDeleteData.updateSubtitle(this.userProjectCountStr);
    }
    this.state.showDeleteAccountAlert.value = new DeleteAccountData(
      askDeleteData,
      this.deleteAccountConfirmData(),
      signal(DeleteAlertState.askDelete)
    );
  }
  /**
   * 点击 want to delete
   */
  tapSureDeleteAccount(): void {
    let deleteData = this.state.showDeleteAccountAlert.value;
    if (deleteData) {
      deleteData.dataState.value = DeleteAlertState.confirmDelete;
    }
    this.state.showDeleteAccountAlert.value = deleteData;
    this.getDeleteAccpuntOTP();
  }
  /**
   * 获取otp 页面信息
   * @param error 错误信息
   * @param otpLoading
   * @returns
   */
  private deleteAccountConfirmData(error: string = "", otpLoading: boolean = false): AlertOtpData {
    return new AlertOtpData(
      "Confirm deletion",
      `To confirm the deletion of your account enter a verification code has been send to:\n${this.state.emailEdit.value.value}`,
      error,
      otpLoading,
      false,
      false,
      true
    );
  }

  /**
   * 设置正在获取otp
   * @param loading
   */
  setGetOtpLoading(loading: boolean): void {
    const dataValue = this.state.showDeleteAccountAlert.value;
    if (dataValue) {
      let deleteData = dataValue.confirmDeleteData;
      deleteData.sendAgainLoading = loading;
      this.state.showDeleteAccountAlert.value = new DeleteAccountData(
        dataValue.askDeleteData,
        deleteData,
        dataValue.dataState
      );
    }
  }

  /**
   * 设置delete account 错误
   * @param error
   */
  setDeleteAccountError(error: string): void {
    const dataValue = this.state.showDeleteAccountAlert.value;
    if (dataValue) {
      let deleteData = dataValue.confirmDeleteData;
      deleteData.errorText = error;
      this.state.showDeleteAccountAlert.value = new DeleteAccountData(
        dataValue.askDeleteData,
        deleteData,
        dataValue.dataState
      );
    }
  }

  /**
   * 获取otp
   * @returns
   */
  async getDeleteAccpuntOTP() {
    this.setDeleteAccountError("");
    this.setGetOtpLoading(true);
    const otpResult = await tryPromise(this.brainClient.user.getDeleteAccountOTP());
    this.setGetOtpLoading(false);
    const meta = otpResult.error;
    const otp = Number(meta.data?.OTP_EXP);
    if (!otp || meta?.status !== 403) {
      this.setDeleteAccountError("Get otp failed");
      return;
    }

    this.state.showDeleteAccountAlert.value?.confirmDeleteData.beginOtp(otp);
  }
  /**
   * 设置delete loading
   * @param loading
   */
  setDeleteAccountLoading(loading: boolean): void {
    this.state.deleteLoading.value = loading;
  }
  /**
   * 点击delete acount
   * @param otp
   * @returns
   */
  async deleteAccount(otp: string): Promise<void> {
    this.setDeleteAccountLoading(true);
    const deleteAccountRes = await tryPromise(this.brainClient.user.deleteAccount(otp));
    this.setDeleteAccountLoading(false);
    if (deleteAccountRes.data?.status !== 204) {
      this.setDeleteAccountError("Delete account failed");
      return Promise.reject();
    }
    return Promise.resolve();
  }

  /**
   * 设置用户的project数目文字
   */
  async setUserProjectNumText(): Promise<void> {
    const req = await tryPromise(this.brainClient.graphql.getPersonalProjectNumber());
    if (req.error || !req.data) {
      return;
    }
    let countStr = "";
    if (req.data > 100) {
      countStr = `100+ projects`;
    } else if (req.data > 1) {
      countStr = `${req.data} projects`;
    } else if (req.data > 0) {
      countStr = `${req.data} project`;
    } else {
      return;
    }
    const projectText = `This account contains ${countStr}. Projects with paid subscribers will be available to them until the end of their subscription.`;
    let alertData = this.state.showDeleteAccountAlert.value;
    if (alertData?.askDeleteData) {
      alertData.askDeleteData.updateSubtitle(projectText);
      this.state.showDeleteAccountAlert.value = alertData;
    } else {
      this.userProjectCountStr = projectText;
    }
  }
}
