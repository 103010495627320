"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctionV2Attribute = exports.FunctionV2Object = void 0;
const class_transformer_1 = require("class-transformer");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const StudioProjectAttributesV3Line_1 = require("../../project/domain/StudioProjectAttributesV3Line");
const StudioProjectAttributesV2Edge_1 = require("../../project/domain/StudioProjectAttributesV2Edge");
const StudioProjectAttributesV3Node_1 = require("../../project/domain/StudioProjectAttributesV3Node");
class FunctionV2Object {
}
exports.FunctionV2Object = FunctionV2Object;
__decorate([
    (0, class_transformer_1.Type)(() => FunctionV2Attribute)
], FunctionV2Object.prototype, "attributesV2", void 0);
class FunctionV2Attribute {
}
exports.FunctionV2Attribute = FunctionV2Attribute;
__decorate([
    (0, class_transformer_1.Transform)(value => {
        if (value.obj.isV3Function === true) {
            return JsonUtil_1.JsonUtil.toArrayFromType(StudioProjectAttributesV3Line_1.StudioProjectAttributesV3Line, value.value);
        }
        return JsonUtil_1.JsonUtil.toArrayFromType(StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2Edge, value.value);
    }, { toClassOnly: true })
], FunctionV2Attribute.prototype, "edgeArr", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV3Node_1.StudioProjectAttributesV3Node)
], FunctionV2Attribute.prototype, "nodeArr", void 0);
