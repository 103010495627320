import { useSignal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { getIt } from "@uikit/getIt";
import { useCreation } from "ahooks";
import { Input } from "antd";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { Blueprint } from "imagica-corekit/dist/base/api/blueprintTyped/BlueprintV2";
import { BlueprintDetails } from "imagica-corekit/dist/base/api/blueprintDetailTyped/BlueprintDetail";
import { Dataload } from "imagica-corekit/dist/base/cutil/Dataload";
import { ReadyStatus } from "imagica-corekit/dist/base/cutil/ReadyStatus";
import { RestResponse } from "imagica-corekit/dist/base/cutil/RestClient";
import { useDataload } from "imagica-uikit/dist/hooks/useDataload";
import { useEffect } from "react";
import { DeleteButton } from "../Button/DeleteButton";
import { SaveButton } from "../Button/SaveButton";
import styles from "./BlueprintDetail.module.scss";
import { BlueprintDetailBloc } from "./BlueprintDetailBloc";
import { Loading } from "./Loading";
import { PromptTabBar } from "./PromptTabBar";
import func from "@uikit/func";
import { BlueprintService } from "imagica-corekit/dist/cases/blueprint/BlueprintService";
import { EditButton } from "../Button/EditButton";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";

export type BlueprintDetailPanelProps = {
  value?: Blueprint;
  id?: string;
  updateFuncGroup: () => void;
  edgeId?: string;
  isNeedToSave?: boolean;
};

export function BlueprintDetailPanel(props: BlueprintDetailPanelProps): JSX.Element {
  const brainClient = getIt(BrainClient);
  const blueprintService = getIt(BlueprintService);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const canvasDataRef = getIt(CanvasDataRef);

  const bloc = useCreation(() => new BlueprintDetailBloc(), []);

  const currentTabIndexSignal = useSignal<number>(0);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
  };

  const handleChange = (tabIndex: number): void => {
    currentTabIndexSignal.value = tabIndex;
  };

  const previewBlueprint = (blueprintDetails: BlueprintDetails): void => {
    const result = blueprintService.previewBlueprint({
      id: props.edgeId || "",
      nodes: creatorNodesStore.getNodes(),
      edges: creatorEdgesStore.getEdges(),
      inputs: blueprintDetails.inputs,
      outputs: blueprintDetails.outputs,
      nodeIndexRef: creatorRefStore.nodeIndexRef,
      edgeIndexRef: creatorRefStore.edgeIndexRef,
      newLineDataRef: canvasDataRef.newLineDataRef,
      nodeDataRef: canvasDataRef.nodeDataRef,
    });
    if (result === null) {
      return;
    }
    creatorEdgesStore.setEdges(result.edges);
    creatorNodesStore.setNodes(result.nodes);
  };

  const restore = (): void => {
    const { edges, nodes } = blueprintService.previewRestore();
    creatorEdgesStore.setEdges(edges);
    creatorNodesStore.setNodes(nodes);
  };

  const blueprintDetailsData = bloc.state.blueprintDetailsData.value;
  const readyStatus = blueprintDetailsData?.status;
  const blueprintDetails = blueprintDetailsData?.data?.data;

  const blueprintData = blueprintDetails?.blueprint_data;
  const data = blueprintData?.[currentTabIndexSignal.value];
  const id = blueprintDetails?.id;

  bloc.state.blueprintDetailsData.value = useDataload(() => {
    if (isBlank(props.value) === false) {
      return new Dataload(() => Promise.resolve({ data: props.value })) as Dataload<RestResponse<BlueprintDetails>>;
    }
    return new Dataload(() => brainClient.openAi.blueprintDetail({ id: props.id || "" }));
  }, []).value;

  useEffect(() => {
    if (isBlank(blueprintDetails) === false && isBlank(id) === false) {
      previewBlueprint(blueprintDetails!);

      return (): void => {
        restore();
      };
    }
    // eslint-disable-next-line
  }, [blueprintDetails]);

  return (
    <>
      {readyStatus === ReadyStatus.LOADING && <Loading>Loading Blueprint Data...</Loading>}
      {readyStatus === ReadyStatus.DONE && (
        <div className={styles.BlueprintDetail} onClick={e => handleClick(e)}>
          <PromptTabBar data={blueprintData} value={currentTabIndexSignal.value} onChange={handleChange} />
          <div className={styles.content}>
            <div className={styles.desc}>{data?.description}</div>
            <div className={styles.executionMsg}>
              <div className={styles.msgStrong}>execution message:</div>
              <div>{data?.execution_message}</div>
            </div>
            <div className={styles.canvas}>{data?.io}</div>
            {func.isEmpty(data?.piece_type) === false && (
              <div className={styles.info}>
                <div className={styles.infoLabel}>piece type:</div>
                <span>{data?.piece_type}</span>
              </div>
            )}
            {func.isEmpty(data?.selected_toolpiece) === false && (
              <div className={styles.info}>
                <div className={styles.infoLabel}>selected tool:</div>
                <span>{data?.selected_toolpiece}</span>
              </div>
            )}
            {func.isEmpty(data?.prompt) === false && (
              <div className={styles.inputTextarea}>
                <div className={styles.label}>prompt:</div>
                <Input.TextArea
                  className={styles.textArea}
                  value={data?.prompt}
                  rows={4}
                  placeholder="You are a recipe dish suggestor..."
                ></Input.TextArea>
              </div>
            )}
          </div>
          <div className={styles.buttonGroup}>
            <EditButton updateFuncGroup={props.updateFuncGroup} blueprint={blueprintDetails?.blueprint} />
            {props.isNeedToSave === true && (
              <SaveButton updateFuncGroup={props.updateFuncGroup} blueprint={blueprintDetails?.blueprint} />
            )}
            {props.isNeedToSave !== true && (
              <DeleteButton updateFuncGroup={props.updateFuncGroup} deleteType="blueprint" id={id} />
            )}
          </div>
        </div>
      )}
    </>
  );
}
