"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatOnboardingStore = exports.ChatOnboardingState = void 0;
const GenUtil_1 = require("../../base/cutil/GenUtil");
const StoreBase_1 = require("../../base/cutil/StoreBase");
const ChatOnboardingType_1 = require("./ChatOnboardingType");
const ChatCompletionsTyped_1 = require("../../base/api/chatCompletionTyped/ChatCompletionsTyped");
const cotflow_1 = require("../../base/api/cotTyped/cotflow");
class ChatOnboardingState {
    constructor() {
        this.isOpenChat = false; // 是否打开了chat
        this.messages = [{ content: "What would you like to build today?", role: ChatOnboardingType_1.Role.assistant }]; // chat对话内容
        this.lastChatData = new ChatCompletionsTyped_1.OnboardingChatRespose(); // 最后一次chat对话内容
        this.lastBuildData = new cotflow_1.Cotflow(); // 最后一次build对话内容
        this.updateTime = 0; // 最后一次更新时间
        this.saveTime = 0; //  最后一次保存时间
        this.currentStep = ChatOnboardingType_1.GenUIProcess.Ideation;
        this.selectTemplateInModal = ""; // Auto-Imagica or Thinking-Canvas
        this.talkBuilder = new ChatOnboardingType_1.TalkBuilder(); // preview里选中的Talk to AI Builder
        this.isNewUser = false; // 是否新建用户
        this.canvasChangesOutOfSync = false; // 在canvas中对node和edge是否有修改
        this.showMakeItReal = false;
        this.showFunctionalityCheck = false;
        this.showOnBoardingInput = true;
    }
    copyWith(params) {
        return GenUtil_1.GenUtil.copyWith(new ChatOnboardingState(), this, params);
    }
}
exports.ChatOnboardingState = ChatOnboardingState;
class ChatOnboardingStore extends StoreBase_1.StoreBase {
    constructor() {
        super(() => new ChatOnboardingState());
    }
    // TODO 待优化 https://brain-ai.atlassian.net/browse/STUD-2551
    setState(param) {
        const newState = this.state.copyWith(param);
        this.emit(newState);
    }
    setChatOnboardingState(param) {
        const newState = this.state.copyWith(param);
        this.emit(newState);
    }
    setIsOpenChat(isOpenChat, handleSave) {
        this.setChatOnboardingState({ isOpenChat: isOpenChat });
        if (isOpenChat && this.state.canvasChangesOutOfSync && handleSave) {
            this.setChatOnboardingState({
                lastBuildData: {
                    ...this.state.lastBuildData,
                    graphstring: "",
                },
            });
            handleSave({ background: true });
            this.setChatOnboardingState({ canvasChangesOutOfSync: false });
        }
    }
}
exports.ChatOnboardingStore = ChatOnboardingStore;
