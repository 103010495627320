export function DropdownMonetizationDashboardIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="14" viewBox="0 0 23 14" fill="none">
      <path
        fill="currentColor"
        d="M20.4746 12.6598C20.4746 13.1526 20.8518 13.5298 21.3446 13.5298C21.8373 13.5298 22.2146 13.1526 22.2146 12.6598C22.2146 10.8621 21.7796 9.0644 20.939 7.4694C20.7068 7.0344 20.1846 6.8894 19.7496 7.12163C19.3146 7.35386 19.1696 7.8761 19.4018 8.3111C20.0973 9.61497 20.4746 11.1226 20.4746 12.6598Z"
      />
      <path
        fill="currentColor"
        d="M0.87 13.532C1.36277 13.532 1.74 13.1547 1.74 12.662C1.74 7.49969 5.945 3.29469 11.1073 3.29469C11.6001 3.29469 11.9773 2.91746 11.9773 2.42469C11.9773 1.93192 11.6001 1.55469 11.1073 1.55469C4.98771 1.55469 0 6.5424 0 12.662C0 13.1547 0.377232 13.532 0.87 13.532Z"
      />
      <path
        fill="currentColor"
        d="M10.6427 13.4156C10.7877 13.5028 10.9327 13.5606 11.1072 13.5606C11.3972 13.5606 11.6872 13.4156 11.8617 13.1551L18.9089 1.75778C19.1695 1.35225 19.0245 0.800551 18.6189 0.568319C18.2134 0.307771 17.6617 0.452771 17.4295 0.858319L10.3529 12.1967C10.1218 12.6034 10.2372 13.1539 10.6427 13.4156Z"
      />
    </svg>
  );
}
