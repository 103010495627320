"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioProjectAttributesV2VariableNodeData = exports.StudioProjectAttributesV2VariableNode = exports.StudioProjectAttributesV2Variable = void 0;
class StudioProjectAttributesV2Variable {
    constructor() {
        this.name = "";
    }
}
exports.StudioProjectAttributesV2Variable = StudioProjectAttributesV2Variable;
class StudioProjectAttributesV2VariableNode {
    constructor() {
        this.width = 0;
        this.height = 0;
        this.id = "";
        this.type = "";
        this.selected = false;
        this.dragging = false;
        this.position = { x: 0, y: 0 };
        this.positionAbsolute = { x: 0, y: 0 };
    }
}
exports.StudioProjectAttributesV2VariableNode = StudioProjectAttributesV2VariableNode;
class StudioProjectAttributesV2VariableNodeData {
}
exports.StudioProjectAttributesV2VariableNodeData = StudioProjectAttributesV2VariableNodeData;
