"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveCore = void 0;
const lodash_1 = require("lodash");
const SaveErrors_1 = require("./SaveErrors");
/**
 * 该 class 用于 fot handleSave 逻辑拆分类
 *
 * 且用于过滤到 corekit 的实现类，下列是实现目标
 * 1. 不应该依赖任何 fot 相关的东西
 * 2. 传递的 functions 应该被简化
 */
class SaveCore {
    constructor(saveApi, saveCreateService, previewService) {
        this.saveApi = saveApi;
        this.saveCreateService = saveCreateService;
        this.previewService = previewService;
        // TODO: refactor
        this.updateProjectBackground = (data, updateId) => {
            return this.saveApi.updateObject(updateId, {
                name: "studio_project",
                attributes: data,
            });
        };
    }
    // 下面是 save 相关流程拆分方法
    async saveAsBackgroud(saveProcess) {
        console.log("jj saveByBackgroud");
        saveProcess.result.updated_project = await this.updateProjectBackground(saveProcess.saveData, saveProcess.vars.selectedTemplate.id);
        saveProcess.events.updateSelectedTemplate({
            updatedAt: saveProcess.result.updated_project.updatedAt,
        });
        if (saveProcess.args.backgroundCreateRelation) {
            const relation = await this.saveCreateService.createRelationIfNotExists(saveProcess.result.updated_project.id, saveProcess.vars.userMe.objectId);
            if (relation) {
                saveProcess.events.updateSelectedTemplate({ relationId: relation.id });
            }
        }
        return saveProcess.result;
    }
    saveAsLocal(saveProcess) {
        console.log("jj saveByLocal");
        saveProcess.events.updateSelectedTemplate(saveProcess.saveData, true);
        return saveProcess.saveData;
    }
    /**
     * 该方法替换了原来的 saveAsNewTemplateV2
     *
     * 原来 handleSave `isFirstCreatePorject && !showMyModification` if 里面的逻辑
     *
     * 其中 naviage 跳转部分，
     *
     * @param saveProcess
     */
    async saveAsFirstCreate(saveProcess) {
        console.log("jj saveByFirstCreate");
        // from createProject
        const objectId = saveProcess.vars.userMe.objectId;
        if (!objectId) {
            throw new SaveErrors_1.SaveApiFailedError("unable to get me id");
        }
        try {
            saveProcess.result.updated_project = await this.saveCreateService.createProject({
                data: saveProcess.saveData,
                objectId: objectId,
                isGlobal: false,
                saveEvents: saveProcess.events,
            });
            saveProcess.events.setStudioProjectName(saveProcess.result.updated_project.name);
            return saveProcess.result.updated_project;
        }
        catch (error) {
            throw new SaveErrors_1.SaveApiFailedError(error || "Create new project failed");
        }
    }
    /**
     * 原来 saveAsExistingTemplateV2 主要逻辑
     * @param saveProcess
     * @returns
     */
    async saveAsExistingTemplateV2(saveProcess) {
        console.log("jj saveAsExistingTemplateV2");
        return this.saveCreateService.saveAsExistingTemplateV2({
            templateObj: saveProcess.args.templateObj,
            edges: saveProcess.args.edges,
            data: saveProcess.saveData,
            attrIndexer: saveProcess.vars.attrIndexer,
            showMyModification: saveProcess.vars.showMyModification,
            objectId: saveProcess.vars.userMe.objectId,
            saveEvent: saveProcess.events,
        });
    }
    async publish(saveProcess) {
        console.log("jj publish");
        const isFirstCreatePorject = (0, lodash_1.isEmpty)(saveProcess.args.templateObj.id);
        let shareLink = saveProcess.args.shareObj.shareLink;
        const reData = isFirstCreatePorject ? saveProcess.result.updated_project : saveProcess.saveData;
        const [sharedProject, relation] = await this.saveCreateService.publishProject({
            attributes: reData,
            scopedProject: saveProcess.result.updated_project,
            author: {
                id: saveProcess.vars.userMe.id,
                name: saveProcess.vars.userMe.name,
                objectId: saveProcess.vars.userMe.objectId,
            },
        });
        // 从 publishProject 移动过来
        saveProcess.events.updateSelectedTemplate({ relationId: relation.id });
        saveProcess.result.shared_project = sharedProject;
        if ((0, lodash_1.isEmpty)(shareLink)) {
            shareLink = await this.previewService.getLink({
                index: sharedProject?.index,
                structureLinkParams: {
                    ...saveProcess.vars.structureLinkParams,
                    uuid: sharedProject.uuid,
                },
            });
        }
        saveProcess.result.updated_project = await this.saveCreateService.updateObject({
            attributes: reData,
            globalProject: sharedProject,
            shareLink: shareLink,
            objectId: saveProcess.result.updated_project.id,
            saveEvent: saveProcess.events,
        });
    }
    /**
     * save 入口方法
     *
     * @param saveProcess
     * @returns
     */
    async save(saveProcess) {
        console.log("jj STUD-2429 core save", saveProcess);
        // local
        if (saveProcess.args.local) {
            return this.saveAsLocal(saveProcess);
        }
        const isFirstCreatePorject = (0, lodash_1.isEmpty)(saveProcess.args.templateObj.id);
        if (isFirstCreatePorject && !saveProcess.vars.showMyModification) {
            const res = await this.saveAsFirstCreate(saveProcess);
            // from: navigate?.(`/editor/${res.uuid}`);
            saveProcess.events.onSaveSuccessNavigate({ editorAttrIndexer: res.uuid });
        }
        //
        else {
            // update project in the backgroud using updateObject
            if (saveProcess.args.background) {
                return await this.saveAsBackgroud(saveProcess);
            }
            // 上传 canvas 画布截图
            await saveProcess.events.saveAsUploadScreenshot(saveProcess);
            // FIXME: 逻辑可能位置放的不正确
            if ((0, lodash_1.isEmpty)(saveProcess.saveData.index)) {
                saveProcess.saveData.index = saveProcess.vars.attrIndexer || saveProcess.args.templateObj?.uuid;
            }
            // 更新保存
            saveProcess.result.updated_project = await this.saveAsExistingTemplateV2(saveProcess);
        }
        // share
        if (!(0, lodash_1.isEmpty)(saveProcess.args.shareObj) && saveProcess.args.shareObj.share && !saveProcess.args.showPublishTips) {
            await this.publish(saveProcess);
        }
        await saveProcess.events.onSaveAfter?.(saveProcess);
        return saveProcess.result;
    }
}
exports.SaveCore = SaveCore;
