import { Popover } from "antd";
import css from "../PopoverButton.module.scss";
import style from "./RunAllButtom.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import { getIt } from "@uikit/getIt";
import { NavBarStore, SwitcherModeType } from "../NavBarStore";
import { EdgeRunAll } from "@uikit/service/EdgeRunAll";

type pageRunAllBtnPropsType = {
  disable?: boolean;
};
export function RunAllButtom(runAllBtnProps: pageRunAllBtnPropsType): JSX.Element {
  const navBarStore = getIt(NavBarStore);
  const edgeRunAll = getIt(EdgeRunAll);
  const projectRunStore = getIt(ProjectRunStore);
  const selectedTemplate = useSelector(
    (state: RootState) => state.studio.selectedTemplate
  ) as unknown as PreviewApp.ProjectType;
  const showNavPageStore = getIt(ShowNavPageStore);
  const projectRunStoreState = useImagicaStore(projectRunStore).value;
  const showNavPageState = useImagicaStore(showNavPageStore).value;
  const navBarState = useImagicaStore(navBarStore).value;

  const disableRunAllBtn = navBarState.switchMode === SwitcherModeType.imagica;

  const clickRunAllBtn = (): void => {
    if (projectRunStoreState.runAllLoading || disableRunAllBtn) return;
    edgeRunAll.clickRunAllBtn();
  };

  const PopoverContent = (
    <div style={{ display: !disableRunAllBtn ? "block" : "none" }} className={style.popoverContent}>
      <p className={style.popupTip}>Run all</p>
      <p className={style.popupContent}>Re-Run all edges to regenerate your app</p>
    </div>
  );
  return (
    <>
      {showNavPageState.showNavPage ? null : (
        <Popover
          content={!runAllBtnProps.disable ? PopoverContent : ""}
          placement="bottom"
          showArrow={false}
          getPopupContainer={e => e}
          overlayClassName={classNames(css.popoverWrap, style.runallTip)}
          // open
        >
          <div className={style["divider-box"]} data-testid="RunAllButtom">
            {/* <SpaceLineItem /> */}
            <div
              className={classNames(
                !runAllBtnProps.disable ? style.cursorPointer : style.cursorPointNoDrop,
                style["block-box"],
                {
                  [style["not-optional"]]: !selectedTemplate.name,
                  [style["noBackground"]]: projectRunStoreState.runAllLoading || disableRunAllBtn,
                }
              )}
              onClick={clickRunAllBtn}
            >
              {projectRunStoreState.runAllLoading ? (
                <LoadingOutlined style={{ fontSize: 24 }} spin />
              ) : (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="20" height="20" rx="10" fill={disableRunAllBtn ? "rgba(18, 25, 43, 0.1)" : "#0A9DFF"} />
                  <path
                    d="M13.6 8.61436C14.3876 9.06908 14.7814 9.29643 14.9135 9.59326C15.0288 9.85217 15.0288 10.1478 14.9135 10.4067C14.7814 10.7036 14.3876 10.9309 13.6 11.3856L9.4 13.8105C8.61241 14.2652 8.21861 14.4926 7.89547 14.4586C7.61362 14.429 7.35757 14.2812 7.19098 14.0519C7 13.789 7 13.3343 7 12.4249L7 7.57513C7 6.6657 7 6.21098 7.19098 5.94811C7.35757 5.71883 7.61362 5.571 7.89547 5.54138C8.21861 5.50741 8.61241 5.73477 9.4 6.18949L13.6 8.61436Z"
                    fill="white"
                  />
                </svg>
              )}

              {/* <Button
                className={style["run-all-box"]}
                icon={runBtnSvg}
                // bsf-5585
                onMouseDown={clickRunAllBtn}
                loading={projectRunStoreState.runAllLoading}
                type="primary"
                shape="round"
                disabled={!selectedTemplate.name || showNavPageState.showNavPage || inCotCreating}
              >
              </Button> */}
            </div>
          </div>
        </Popover>
      )}
    </>
  );
}
