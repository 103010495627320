import { getInputNodesForNodeLabel } from "@views/thinking-layout-editor/util";
import { get } from "lodash";
import func from "@uikit/func";
import isBlank from "@sedan-utils/is-blank";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { useProjectNode } from "@uikit/projectNode/useProjectNode";
import { useInputTitle } from "@uikit/hooks/useInputTitle";
import { ProjectNodeUtil } from "@uikit/projectNode/ProjectNodeUtil";
import { useCallback } from "react";
import { EdgeRunQuery } from "@uikit/edgeRun/EdgeRunQuery";

// eslint-disable-next-line
export function useNodesTitle(id: string, isInBlueprint = false) {
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const projectNodeStore = getIt(ProjectNodeStore);
  const edgeRunQuery = getIt(EdgeRunQuery);
  const projectNode = useProjectNode(id);
  const [inputTitleState, setInputTitleState] = useInputTitle(id);

  const saveTitle = (title = inputTitleState.title): any => {
    // stud-2493 set input title(nodes)
    creatorNodesStore.updateDataWithNoEmit(id, { inputTitle: title });
    // stud-2493 set input title(preview)
    creatorSaasAppStore.updateInputDataWithNoEmit(id, { inputTitle: title });

    projectNodeStore.updateById(id, { inputTitle: title });
  };

  const handleEnter = (): void => {
    handleBlur();
  };

  const handleBlur = async (): Promise<void> => {
    const node = creatorNodesStore.getNodeById(id);

    setInputTitleState({ id, title: inputTitleState.title?.trim() });

    const oldTitle = node?.data.inputTitle || "";
    await saveTitle(inputTitleState.title || getTitle());

    // 当值有变化的时候才更新prompt
    if (oldTitle !== inputTitleState.title) {
      await refreshPrompt(oldTitle);
    }
    setInputTitleState({ id: "", title: "" });
  };

  const refreshPrompt = async (oldTitle: string): Promise<void> => {
    const edges = creatorEdgesStore.getEdges();
    const edgeIds = edges.filter(x => x.source === inputTitleState.id).map(x => x.target);
    const results: Array<{ edgeId: string; enterText: string }> = [];
    let repeat = false;
    for (const edgeId of edgeIds) {
      const edgeNode = creatorNodesStore.getNodeById(edgeId);
      if (edgeNode && edgeNode?.data.flows.length > 1) {
        const sourceNodeIds = edgeNode?.data.flows.map((x: any) => x.sourceNodeId);
        const sourceTitles = ProjectNodeUtil.filterTitleByIds(projectNodeStore.getNodes(), sourceNodeIds);

        // 重新生成prompt
        if (new Set(sourceTitles).size !== sourceNodeIds.length) {
          repeat = true;
        }
        results.push({ edgeId, enterText: edgeNode?.data.lineParam.enterText });
      }
    }

    if (repeat) {
      // 还原title
      func.customMsg({
        content: "A node with the same name already exists",
        type: "warning",
      });
      await saveTitle(oldTitle);
    } else {
      // 更新prompt
      await results.forEach(async ({ edgeId, enterText }) => {
        await edgeRunQuery.getAndSetQueryV3(edgeId, enterText);
      });
    }
  };

  const handleFocus = (): void => {
    const node = creatorNodesStore.getNodeById(id);
    setInputTitleState({ id, title: get(node, "data.inputTitle") || getTitle() });
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e): void => {
    setInputTitleState({ id, title: e.target.value });
  };

  const showNodeLabel = (): string => {
    const edges = creatorEdgesStore.getEdges();
    const nodes = creatorNodesStore.getNodes();
    const node = creatorNodesStore.getNodeById(id);
    const inputeNodes = getInputNodesForNodeLabel(nodes, edges);
    const isInputNode = inputeNodes.includes(id) || inputeNodes.includes(`editor-${id}`);
    const isBluePrintNode = get(node, "data.displayType") === "blueprint";
    const showBluePrintLabel =
      (isBluePrintNode || isInBlueprint) &&
      !isBlank(node?.data?.textAreaValue?.data?.name ?? node?.data?.textAreaValue?.name);
    return isInputNode || showBluePrintLabel;
  };

  /**
   * Currently, all nodes need to display a label
   *  group children node do not need to show
   */
  const showAllNodesLabel = (): boolean => {
    const node = creatorNodesStore.getNodeById(id);
    // console.log(node, "node");
    // console.log(creatorNodesStore.getNodes(), "creatorNodesStore.getNodes()");
    return isBlank(node?.parentNode);
  };

  const getIsBluePrintNode = (): any => {
    const node = creatorNodesStore.getNodeById(id);
    return get(node, "data.displayType") === "blueprint";
  };

  const getTitle = useCallback((): string => {
    return ProjectNodeUtil.getTitle(projectNode);
  }, [projectNode]);

  return {
    inputTitle: inputTitleState.id !== id ? getTitle() : inputTitleState.title,
    disableInput: getIsBluePrintNode() || isInBlueprint,
    handleEnter,
    handleBlur,
    handleFocus,
    handleChange,
    saveTitle,
    showNodeLabel,
    showAllNodesLabel,
  };
}
