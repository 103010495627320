import { isEmpty } from "../../../uikit/login";
import { AModalProps } from "./types";
import style from "./AModal.module.css";

export function ModalTitle(props: Pick<AModalProps, "title" | "subTitle">) {
  const { title, subTitle } = props;
  return (
    <div>
      {!isEmpty(title) && <h3 className={style["modal-title"]}>{title}</h3>}
      {!isEmpty(subTitle) && <p className={style["modal-sub-title"]}>{subTitle}</p>}
    </div>
  );
}
