import { useCallback, useEffect } from "react";
import { BaseEdge, useStore, EdgeProps, EdgeLabelRenderer } from "reactflow";
import { DEFAULT_LINE_STYLE, NEW_EDGE_REG, ERROR_LINE_STYLE } from "../../../views/thinking-layout-editor/constants";
import { CustomNewLineBloc } from "./CustomNewLineBloc";
import func from "@uikit/func";
import pathStyle from "./CustomNewLine.module.scss";
import { useSignal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

const ChildPage = (props: any): JSX.Element => {
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const sourceNode = useStore(useCallback(store => store.nodeInternals.get(props.source), [props.source])) as any;
  const targetNode = useStore(useCallback(store => store.nodeInternals.get(props.target), [props.target])) as any;

  let firstSourceNodeId = targetNode.data?.flows?.[0]?.sourceNodeId || props.source;
  if (func.isEmpty(targetNode.data?.flows?.[0]?.sourceNodeId) && NEW_EDGE_REG.test(props.source)) {
    const sourceLines = creatorEdgesStore.getEdges().filter((x: any) => x.target === props.source);
    firstSourceNodeId = func.isEmpty(sourceLines) ? firstSourceNodeId : sourceLines[0]?.source;
  }
  const edgeNode = NEW_EDGE_REG.test(targetNode.id) ? targetNode : sourceNode;
  const realTargetNodeId = edgeNode.data?.targetNodeId;
  const firstSourceNode = useStore(
    useCallback(store => store.nodeInternals.get(firstSourceNodeId), [firstSourceNodeId])
  ) as any;
  const realTargetNode = useStore(
    useCallback(store => store.nodeInternals.get(realTargetNodeId), [realTargetNodeId])
  ) as any;

  useEffect(() => {
    const edgePath = props.bloc.getPath({
      sourceNode,
      targetNode,
      firstSourceNode,
      realTargetNode,
    });
    if (edgePath !== props.edgePath.value) {
      props.edgePath.value = edgePath;
    }
  });
  return <></>;
};

export default function CustomNewLine({
  id,
  source,
  target,
  style = DEFAULT_LINE_STYLE,
  markerEnd,
  data,
}: EdgeProps): JSX.Element {
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const creatorNodesStore = getIt(CreatorNodesStore);

  const bloc = new CustomNewLineBloc({
    id,
    getHomeNodes: creatorNodesStore.getNodes,
    setEdges: creatorEdgesStore.setEdges,
  });

  const edgePath = useSignal("");

  useEffect(() => {
    bloc.handleHoverEdgeDataEffect();
    // eslint-disable-next-line
  }, [
    bloc.hoverEdgeData.value.state,
    bloc.hoverEdgeData.value.edgeId,
    bloc.hoverEdgeData.value.isSelected,
    bloc.hoverEdgeData.value.lineIds,
  ]);

  useEffect(() => {
    bloc.handleCheckEdgeArrEffect();
    // eslint-disable-next-line
  }, [bloc.checkEdgeArr.length]);

  const isIncludesId = bloc.checkEdgeArr.includes(id);
  const needHighlight = bloc.needHighlight.value;

  const color = data?.current?.stateConfig?.color || data.stateConfig?.color || "#B0B0B7";
  return (
    <>
      <BaseEdge path={edgePath.value} markerEnd={markerEnd} style={style} />
      {(isIncludesId || needHighlight) && style.stroke !== ERROR_LINE_STYLE.stroke ? (
        <path
          fill="none"
          d={edgePath.value}
          stroke={color}
          strokeWidth={10}
          strokeOpacity={0.25}
          strokeLinecap="round"
          className={`${needHighlight && !isIncludesId ? pathStyle["select-border_animated"] : ""}`}
        />
      ) : null}

      <EdgeLabelRenderer>
        <ChildPage id={id} source={source} target={target} bloc={bloc} data={data} edgePath={edgePath} />
      </EdgeLabelRenderer>
    </>
  );
}
