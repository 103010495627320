import { useEffect, useRef } from "react";
import styles from "./PowerMaskView.module.scss";
import { PowerMaskSmall } from "./PowerMaskSmall";
import { PowerMaskBig } from "./PowerMaskBig";
import { useObserverNodeSize } from "imagica-uikit/dist/hooks/useObserverNodeSize";
import { PowerMaskProps, PowerMaskViewBloc } from "./PowerMaskViewBloc";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreation } from "ahooks";
import { matchMobile } from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { AModal } from "@uiview/views/AModal/AModal";
import { useSignal } from "@preact/signals-react";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import LoadingOutline from "@views/components/LoadingOutline";

export function PowerMaskView(props: PowerMaskProps): JSX.Element {
  const maskBodyRef = useRef<HTMLDivElement>(null);
  const { size } = useObserverNodeSize(maskBodyRef, false);
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const subscriptionStore = getIt(SubscriptionStore);
  const subscriptionStoreState = useStore(subscriptionStore).value;
  const featureTags = homeStoreState.featureTags;
  const location = useLocation();
  const navigate = useNavigate();
  const shouldShowMobileUI = isMobile && featureTags.disableMobilePage;
  const isShowMobileAlert = useSignal(false);

  const bloc = useCreation(() => new PowerMaskViewBloc(props), []);
  const featureDescription = bloc.state.featureDescription.value;
  const mobileAlertTitle = isEmpty(featureDescription) ? "Need to upgrade to unlock this function" : featureDescription;

  function handleClickUpgrade(): void {
    if (props.onClickUpgrade) {
      props.onClickUpgrade();
    }
    bloc.handleUpgrade();
    navigate(bloc.getPlanPath(location.pathname));
  }

  function handleClickMaskOnMobile(): void {
    if (props.onClickUpgrade) {
      props.onClickUpgrade();
    }
    isShowMobileAlert.value = true;
  }

  useEffect(() => {
    bloc.props = props;
  });
  useEffect(() => {
    if (!subscriptionStoreState.creatorPlansLazyLoadLoading) {
      bloc.loadData();
    }
  }, [subscriptionStoreState.creatorPlansLazyLoadLoading]);

  const renderMask = (): React.ReactNode => {
    if (!(featureTags.enableStudiosPaywall && bloc.state.showMask.value)) {
      return null;
    }

    if (shouldShowMobileUI) {
      return (
        <div className={styles.transparentContent} onClick={() => handleClickMaskOnMobile()}>
          <AModal
            open={isShowMobileAlert.value}
            subTitle={mobileAlertTitle}
            buttons={["Cancel", "Upgrade"]}
            onCancel={() => {
              isShowMobileAlert.value = false;
            }}
            onOk={() => {
              isShowMobileAlert.value = false;
              handleClickUpgrade();
            }}
          />
        </div>
      );
    }

    if (size.width > 300) {
      return (
        <PowerMaskBig
          featureDescription={bloc.state.featureDescription}
          minimumPlanObj={bloc.state.minimumPlanObj}
          defaultWallName={props.defaultWallName}
          onClick={handleClickUpgrade}
        />
      );
    }

    return (
      <PowerMaskSmall
        featureDescription={bloc.state.featureDescription}
        minimumPlanObj={bloc.state.minimumPlanObj}
        defaultWallName={props.defaultWallName}
        onClick={handleClickUpgrade}
      />
    );
  };

  return (
    <div
      className={classNames(styles.mask_content, {
        [styles.MaskContentMobile]: props.enableCls && isMobile && featureTags.disableMobilePage,
      })}
      ref={maskBodyRef}
    >
      {props.children}

      {subscriptionStoreState.creatorPlansLazyLoadLoading ? (
        <div className={classNames(styles.transparentContent, styles.loadingMask)}>
          <LoadingOutline />
        </div>
      ) : (
        renderMask()
      )}
    </div>
  );
}
