"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryNodeState = void 0;
var StoryNodeState;
(function (StoryNodeState) {
    StoryNodeState["IDLE"] = "IDLE";
    StoryNodeState["LOADING"] = "LOADING";
    StoryNodeState["RECEIVING"] = "RECEIVING";
    StoryNodeState["COMPLETED"] = "COMPLETED";
    StoryNodeState["ERROR"] = "ERROR";
})(StoryNodeState || (exports.StoryNodeState = StoryNodeState = {}));
