import { useEffect } from "react";
import { useEnableScroll } from "imagica-uikit/dist/hooks/useEnableScroll";
import NodeWrapper from "../../../views/thinking-layout-editor/nodeTypeComponents/NodeWrapper";
import TitleBar from "@uiview/views/Nodes/components/TitleBar";
import styles from "./MapView.module.scss";
import { MapViewBloc } from "./MapViewBloc";
import cls from "classnames";
import { nodeResizeEvent } from "imagica-corekit/dist/base/api/mapTyped/Map";
import { useDidMount } from "imagica-uikit/dist/hooks/useDidMount";

export const initialMapSize = {
  width: 500,
  height: 500,
};

export function MapView(props: {
  id: string;
  isOpenDeleteBox: boolean;
  displayLabel: boolean;
  textAreaValue: number[];
  bloc: MapViewBloc;
  resizeEventParams: nodeResizeEvent;
}) {
  const { id, bloc, isOpenDeleteBox, displayLabel, textAreaValue, resizeEventParams } = props;

  const { width = initialMapSize.width, height = initialMapSize.height } = resizeEventParams || {};

  useEffect(() => {
    if (bloc.map.value) {
      bloc.map.value.resize();
    }
  }, [width, height]);

  useEffect(() => {
    bloc.setMarkNew(textAreaValue);
  }, [textAreaValue]);

  useDidMount(() => {
    bloc.init(id, textAreaValue);
  });

  const { enableScroll, mouseEnterLeave } = useEnableScroll();

  const mapClassName = cls(styles.inner, "nodrag", "nowheel");

  return (
    <NodeWrapper
      mouseEnterLeave={mouseEnterLeave}
      style={{
        maxWidth: initialMapSize.width,
        maxHeight: initialMapSize.height,
      }}
    >
      {displayLabel ? (
        <TitleBar id={id} isOpenDeleteBox={isOpenDeleteBox}>
          Map
        </TitleBar>
      ) : null}
      <div className={styles.mapBoxInner} style={id.includes("preview") ? {} : { width, height }}>
        <div id={id} className={mapClassName}></div>
        <img id={"mapbox" + id} src={bloc.markerIcon} className={styles.imgMapbox} alt="" />
      </div>
    </NodeWrapper>
  );
}
