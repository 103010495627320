import { has } from "lodash";
import { Api } from "@uikit/service/api";

//  * 得到用户信息相关 hook, 比如判断用户是否是 guest,或者已登录等
export class UserIdentity {
  /**
   * 获取带 token 的 headers 对象
   *
   * - 目前只为判断用户
   *
   * @link ../service/api handleHeadersToken
   */
  static getHeaderWithToken() {
    return new Api(false).headers;
  }
  /**
   * 是否是来宾用户
   */
  static hasGuest() {
    const headers = UserIdentity.getHeaderWithToken();
    return has(headers, "Brain-Guest-User-Key");
  }
}
