import { FocusEventHandler, KeyboardEventHandler, Ref, useEffect, useMemo, useRef } from "react";
import { Loading } from "@uiview/views/BluePrint/BlueprintDetail/Loading";
import { BluePrintItem } from "./components/BluePrintItem";
import { flattenBluePrintArray } from "./BluePrintUtil";
import { Input as BluePrintValue } from "imagica-corekit/dist/base/api/blueprintTyped/BlueprintV2";
import style from "./BluePrintNode.module.scss";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

export type BluePrintNodeProps = {
  id: string;
  inputRef: Ref<HTMLElement>;
  textAreaValue: BluePrintValue;
  checkNodeArr: Array<any>;
  placeholder: string;
  isLongPress: boolean;
  isResized: boolean;
  onTextAreaBlur: (e: any, value?: any) => void;
  onTextAreaFocus: FocusEventHandler<HTMLTextAreaElement>;
  onTextAreaEnter: KeyboardEventHandler<HTMLTextAreaElement>;
  setTextAreaValueWithParam: any;
  isTargetNode: boolean;
  showFeedback: boolean;
  showStopGenerateBtn: boolean;
  parentNodeId: string;
  noWheelClassName: string;
  enableFeedback?: boolean;
  responseData: any;
  isStaticApp: boolean;
  blueprintMessage?: string;
  isRootNode?: boolean;
  isInBlueprint?: boolean;
  fromList?: boolean;
};

export function BluePrintNode(props: BluePrintNodeProps): JSX.Element {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const inputBoxRef = useRef<null | HTMLDivElement>(null);
  const components = useMemo(() => flattenBluePrintArray(props.responseData.data), [props.responseData]);
  // const components = useMemo(() => flattenBluePrintArray(props.textAreaValue), [props.textAreaValue]);
  const blueprintExpanded = creatorNodesStore.getNodes().filter((node: any) => node.parentNode === props.id).length > 0;

  const getTextNodeClass = (): string => {
    const classes = [
      `${style.customNode} ${style.designText}`,
      `${props.isLongPress || props.checkNodeArr.includes(props.id) ? "nowheel nopan" : ""}`,
      `${blueprintExpanded ? style.customNodeExpand : ""}`,
      `${style.guideTextnodeDisable}`,
      `${!props.parentNodeId ? style.hasParentNode : ""}`,
      `${props.fromList ? "" : style.minHeight}`,
    ];
    return classes.join(" ");
  };

  const getContentClass = (): string => {
    const classes = [`${style.textContent}`, `${!props.parentNodeId ? style.paddingContent : ""}`];
    return classes.join(" ");
  };

  useEffect(() => {
    if (props.isRootNode && inputBoxRef.current) {
      const distance = inputBoxRef.current.scrollHeight - inputBoxRef.current.clientHeight;
      inputBoxRef.current.scrollTop = distance;
    }
  }, [components.length, props.isRootNode]);

  useEffect(() => {
    if (props.isRootNode && props.blueprintMessage === "done") {
      setTimeout(() => {
        // 滚动到顶部
        if (inputBoxRef.current) {
          inputBoxRef.current.scrollTop = 0;
        }
      }, 200);
    }
  }, [components, props.blueprintMessage, props.isRootNode]);

  return (
    <div className={getTextNodeClass()} ref={inputBoxRef}>
      {!blueprintExpanded && (
        <div className={getContentClass()}>
          {components.map((comp, index) => (
            <BluePrintItem
              {...props}
              {...comp}
              key={index}
              index={index}
              value={comp.value as string}
              reason={comp.description ?? comp.reason}
              blueprintMessage={props.blueprintMessage}
            />
          ))}
          {props.blueprintMessage && props.blueprintMessage !== "done" && <Loading>{props.blueprintMessage}</Loading>}
        </div>
      )}
    </div>
  );
}
