import { get, isString, set } from "lodash";
import type { Edge } from "reactflow";
import { DIRECTIONHANDLE, FUNCTION_REG, GROUPID_REG } from "@views/thinking-layout-editor/constants";

type DirectionType = "top" | "bottom" | "left" | "right";
type CustomEdgeHandleType = "a" | "b" | "2bottom" | "0top";

export type CustomEdgeType = Edge<CustomEdgeDataType>;

// TODO: other type
type CustomEdgeDataType = {
  nodes: any;
  fromDirection?: DirectionType;
  isGetQuery: boolean;
  getQueryLoading: boolean;
  isCreatedBySlice: boolean;
  isSliceAutoRun: boolean;
  isApiFunction: boolean;
  isUndoRedoAdd: boolean;
  originSource: string;
  originTarget: string;
  sourceHandle: CustomEdgeHandleType;
  targetHandle: CustomEdgeHandleType;
  selectedApi: Record<string, any>;
  lineParam: any;
  // imageGenSelectOptions,
  // singleFlowEdgeArrRef,
};

export const DIRECTIONHANDLE_GROUP_MAP = {
  top: {
    source: "0top",
    target: "b",
  },
  bottom: {
    source: "2bottom",
    target: "a",
  },
} as const;

const selectApiReg = /^\//;

/**
 * 画布的 CustomEdge 工具类
 */
export class CustomEdgeUtil {
  /**
   * 是否时普通节点的id
   * @param idString
   */
  static isNodeId(idString: string): boolean {
    return /^editor-/.test(idString);
  }

  /**
   * 是否是组节点的ID
   * @param idString
   */
  static isGroupNodeId(idString: string): boolean {
    return GROUPID_REG.test(idString);
  }

  /**
   * 更新边的 handle
   *
   * 当普通节点连接的节点是组节点时
   *
   * - 从下添加的节点，边连接到组节点的左侧
   * - 从上添加的节点，边连接到组节点的右侧
   *
   * @param customEdge
   * @param direction
   * @returns
   */
  static assignNodeToGroupNodeHandle(customEdge: CustomEdgeType, direction?: DirectionType): CustomEdgeType {
    direction = direction || get(customEdge, "data.fromDirection");

    // 尝试兼容以前的数据
    if (!direction) {
      direction = CustomEdgeUtil.getToGroupDirection(customEdge);
    }

    if ("top" === direction || "bottom" === direction) {
      const sourceHandle = DIRECTIONHANDLE_GROUP_MAP[direction].source;
      const targetHandle = DIRECTIONHANDLE_GROUP_MAP[direction].target;

      set(customEdge, "sourceHandle", sourceHandle);
      set(customEdge, "targetHandle", targetHandle);
      set(customEdge, "data.sourceHandle", sourceHandle);
      set(customEdge, "data.targetHandle", targetHandle);
    }

    return customEdge;
  }

  /**
   * 获取节点连接到组节点的连接方向,
   *
   * - 只为了兼容 bsf-1578 之前的数据
   *
   * @param customEdge
   * @returns
   */
  static getToGroupDirection(customEdge: CustomEdgeType): DirectionType | undefined {
    const fromDirection = get(customEdge, "data.fromDirection");
    if (fromDirection) {
      return fromDirection;
    }

    const { target, source } = customEdge;
    // 当 source 是普通节点， target groupNode
    if (CustomEdgeUtil.isNodeId(source) && CustomEdgeUtil.isGroupNodeId(target)) {
      const { bottom, top } = DIRECTIONHANDLE;
      const sourceHandle = get(customEdge, "sourceHandle");

      if (bottom.source === sourceHandle) {
        return "bottom";
      }

      if (top.source === sourceHandle) {
        return "top";
      }
    }

    return;
  }

  /**
   * 边上的文字是否是函数
   * @param text
   * @returns
   */
  static isSelectApiEnterText(text: string): boolean {
    return !selectApiReg.test(text);
  }

  /**
   * 是否跳过执行的边
   *
   * - 当3条边指向同一个节点的时候，点击runall这3条边都会依次执行，目前的解决办法是当这些边属于同一组的时候并且没有identifier的时候就过滤掉 不执行边
   *
   * @see https://brain-ai.atlassian.net/browse/BSF-2176
   * @param customEdge
   * @returns
   */
  static hasRunWithLineParam(lineParam: any): any {
    const lineType = get(lineParam, "lineType");

    if (lineType === "identifier") {
      const identifier = get(lineParam, "identifier");
      const enterText = get(lineParam, "enterText");
      return identifier || selectApiReg.test(enterText);
    }

    return true;
  }

  /**
   * 边上的输入的内容是否时函数
   * @param text
   * @returns
   */
  static isFunctionInputText(text: any): boolean {
    return isString(text) && FUNCTION_REG.test(text);
  }
  static parseEdgeAndCommandbarText(text: any, defaultCommandBarValue = ""): any[] {
    if (CustomEdgeUtil.isFunctionInputText(text)) {
      return [text.slice(0, 1), text.slice(1)];
    }

    return [text, defaultCommandBarValue];
  }
}
