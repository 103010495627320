"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryEdgeOptionsFuncOriginalData = exports.StoryEdgeOptionsActionDataRequestParams = exports.StoryEdgeOptionsActionData = exports.StoryEdgeOptions = void 0;
class StoryEdgeOptions {
    constructor(id, source, target, actionData) {
        this.id = id;
        this.source = source;
        this.target = target;
        this.actionData = actionData;
    }
}
exports.StoryEdgeOptions = StoryEdgeOptions;
class StoryEdgeOptionsActionData {
    constructor(lineType, enterText, identifierType, identifierValue, queryValue, 
    // FIXME TYPE
    requestParam, funcOriginalData, fileUuid, blueJson, description) {
        this.lineType = lineType;
        this.enterText = enterText;
        this.identifierType = identifierType;
        this.identifierValue = identifierValue;
        this.queryValue = queryValue;
        this.requestParam = requestParam;
        this.funcOriginalData = funcOriginalData;
        this.fileUuid = fileUuid;
        this.blueJson = blueJson;
        this.description = description;
    }
}
exports.StoryEdgeOptionsActionData = StoryEdgeOptionsActionData;
class StoryEdgeOptionsActionDataRequestParams {
    constructor(loader, file, name, s3Url, urls) {
        this.loader = loader;
        this.file = file;
        this.name = name;
        this.s3Url = s3Url;
        this.urls = urls;
    }
}
exports.StoryEdgeOptionsActionDataRequestParams = StoryEdgeOptionsActionDataRequestParams;
class StoryEdgeOptionsFuncOriginalData {
    constructor(id, value, functionName) {
        this.id = id;
        this.value = value;
        this.functionName = functionName;
    }
}
exports.StoryEdgeOptionsFuncOriginalData = StoryEdgeOptionsFuncOriginalData;
