import { createSlice } from "@reduxjs/toolkit";
import func from "@uikit/func";

const DEVELOPERMODE = "DEVELOPERMODE";
const EXPMODE_KEY = "EXPMODE";
const TOOLBOX_KEY = "TOOLBOX";
const DESIGNSPACE_KEY = "DESIGNSPACE";
const fotSlice = createSlice({
  name: "fot",
  initialState: {
    mode: "productionMode",
    previousMode: "",
    view: "home",
    page: "home",
    studioProjectName: "",
    userAvatarUrl: "",
    commandEnterNodeId: "",
    preSelectNodeId: "",
    editorImageScrollDireaction: "verticallyScroll",
    locusEditorId: "",
    inputId: [],
    tutorialSessionId: "",

    developerMode: JSON.parse(localStorage.getItem(DEVELOPERMODE)) || false,
    enableExperimentalFeature: JSON.parse(localStorage.getItem(EXPMODE_KEY)) || false,
    enableToolboxFeature: JSON.parse(localStorage.getItem(TOOLBOX_KEY)) || false,
    enableDesignSpaceFeature: JSON.parse(localStorage.getItem(DESIGNSPACE_KEY)) || false,
    isFirstLoad: true,
    fetching: false,
    collapsed: false,
    domainLoading: false,
    domainDisabled: false,
    contentLoading: false,
    showChainThoughts: false,
    errorState: false,
    isModelOpen: false,
    showSetting: false,
    isShare: false,
    isLogin: false,
    disableAddPreview: true,
    showShareSignin: false,
    hasSelectedArea: false,

    lineParam: {},
    loadingType: {},
    extractDomain: {},
    highlightData: {},
    createFunctionClick: {},
    thoughtChain: [],
    checkNodeArr: [],
    checkEdgeArr: [],
    editorIdArr: [],
    openAiTextData: {},
    variableList: [],
    selectIdArr: [],
    startNodeIds: [],
    scrollToBottomblackList: ["hiring"],
    slicedAutoRunNodes: [],
    imageGenSelectOptions: [
      {
        label: "Stock Prices",
        value: "showStockInterface",
        groupType: "stockInfo",
        description: "",
      },
      {
        // https://github.com/brain/micro-apps/pull/1252
        label: "DALL·E 2",
        value: "imageGen",
        description: "DALL·E 2 based on a textual description, using the DALL·E 2 deep learning model",
      },
      {
        label: "Split Output",
        value: "splitOutput",
      },
    ],
  },
  reducers: {
    setMode(state, action) {
      state.previousMode = state.mode;
      const mode = action.payload;
      state.mode = mode;
    },
    setView(state, action) {
      const view = action.payload;
      state.view = view;
    },
    setIsFirstLoad(state, action) {
      const isFirstLoad = action.payload;
      state.isFirstLoad = isFirstLoad;
    },
    setFetching(state, action) {
      const fetching = action.payload;
      state.fetching = fetching;
    },
    setCollapsed(state, action) {
      state.collapsed = action.payload;
    },
    setDisableAddPreview(state, action) {
      state.disableAddPreview = action.payload;
    },
    setInputId(state, action) {
      state.inputId = action.payload;
    },
    setStartNodeIds(state, action) {
      state.startNodeIds = action.payload;
    },
    setDomain(state, action) {
      // 判定数据是否已经存在
      let objDomain = {};
      for (let i in action.payload) {
        if (!state.extractDomain[i]) {
          objDomain[i] = action.payload[i];
        }
      }
      const obj = { ...state.extractDomain, ...objDomain };
      state.extractDomain = obj;
    },
    emptyDomain(state, action) {
      state.extractDomain = action.payload;
    },
    setThoughtChain(state, { payload }) {
      state.thoughtChain = payload;
    },
    setPage(state, { payload }) {
      state.page = payload;
    },
    setLoadingType(state, { payload }) {
      state.loadingType[payload.page] = payload.value;
    },
    setDomainLoading(state, { payload }) {
      state.domainLoading = payload;
    },
    setDomainDisabled(state, { payload }) {
      state.domainDisabled = payload;
    },
    setContentLoading(state, { payload }) {
      state.contentLoading = payload;
    },
    setStudioProjectName(state, { payload }) {
      state.studioProjectName = payload;
    },
    setChainThoughtsState(state, { payload }) {
      state.showChainThoughts = payload;
    },
    setOpenAiTextData(state, { payload }) {
      if (!func.isEmpty(state.openAiTextData[payload.thought])) return;
      state.openAiTextData[payload.thought] = payload.value;
    },
    setUserAvatarUrl(state, { payload }) {
      state.userAvatarUrl = payload;
    },
    setErrorState(state, { payload }) {
      state.errorState = payload;
    },
    setIsModelOpen(state, { payload }) {
      state.isModelOpen = payload;
    },
    setLineParam(state, { payload }) {
      state.lineParam = payload;
    },
    setCommandEnterNodeId(state, { payload }) {
      state.commandEnterNodeId = payload;
    },
    setCheckNodeArr(state, { payload }) {
      state.checkNodeArr = payload;
    },
    setCheckEdgeArr(state, { payload }) {
      state.checkEdgeArr = payload;
    },
    setHasSelectedArea(state, { payload }) {
      state.hasSelectedArea = payload;
    },
    setEditorIdArr(state, { payload }) {
      state.editorIdArr = payload;
    },
    setHighlightData(state, { payload }) {
      state.highlightData = payload;
    },
    setShowSetting(state, { payload }) {
      state.showSetting = payload;
    },
    setShare(state, { payload }) {
      state.isShare = payload;
    },
    setImageScrollDireaction(state, { payload }) {
      state.editorImageScrollDireaction = payload;
    },
    setSlicedAutoRunNodes(state, { payload }) {
      state.slicedAutoRunNodes = payload;
    },
    setImageGenSelectOptions(state, { payload }) {
      state.imageGenSelectOptions = payload;
    },
    setVariableList(state, { payload }) {
      state.variableList = payload;
    },
    setPreSelectNodeId(state, { payload }) {
      state.preSelectNodeId = payload;
    },
    setSelectIdArr(state, { payload }) {
      state.selectIdArr = payload;
    },
    setSaasEditorId(state, { payload }) {
      state.locusEditorId = payload;
    },
    setTutorialSessionId(state, { payload }) {
      state.tutorialSessionId = payload;
    },
    setIsLogin(state, { payload }) {
      state.isLogin = payload;
    },
    setShowShareSignin(state, { payload }) {
      state.showShareSignin = payload;
    },
    setDeveloperMode(state, { payload }) {
      state.developerMode = payload;
      localStorage.setItem(DEVELOPERMODE, JSON.stringify(payload));
    },
    setEnableExperimentalFeature(state, { payload }) {
      state.enableExperimentalFeature = payload;
      localStorage.setItem(EXPMODE_KEY, JSON.stringify(payload));
    },
    setEnableToolboxFeature(state, { payload }) {
      state.enableToolboxFeature = payload;
      localStorage.setItem(TOOLBOX_KEY, JSON.stringify(payload));
    },
    setEnableDesignSpaceFeature(state, { payload }) {
      state.enableDesignSpaceFeature = payload;
      localStorage.setItem(DESIGNSPACE_KEY, JSON.stringify(payload));
    },
    setCreateFunctionClick(state, { payload }) {
      state.createFunctionClick = payload;
    },
  },
});

export const fotActions = fotSlice.actions;
export default fotSlice.reducer;
