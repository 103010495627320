import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { getIt } from "@uikit/getIt";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";

export class DisableChatUtil {
  public static isDisabled(): boolean {
    const meStore = getIt(MeStore);
    const chatOnboardingStore = getIt(ChatOnboardingStore);
    const enableChatCanvasConnection = meStore.hasChatCanvasConnection();
    if (chatOnboardingStore.state.selectTemplateInModal === ChoosingFormat.AUTOIMAGICA) {
      return chatOnboardingStore.state.canvasChangesOutOfSync && !enableChatCanvasConnection;
    } else {
      return !enableChatCanvasConnection;
    }
  }

  public static disableSwitch(): boolean {
    const chatOnboardingStore = getIt(ChatOnboardingStore);
    return (
      chatOnboardingStore.state.selectTemplateInModal !== ChoosingFormat.AUTOIMAGICA && DisableChatUtil.isDisabled()
    );
  }
}
