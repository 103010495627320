import { useEffect, useRef, useState } from "react";
import { CotChatStatus, OnBoardingInputBloc } from "./OnBoardingInputBloc";
import styles from "./VirtualAssistant.module.scss";
import cls from "classnames";
const assetsPrefix = "https://dopniceu5am9m.cloudfront.net/static/230614_ai_listen";

const LISTENING_MODE_WHEN_THE_PERSON_IS_TYPING_LOOP = `${assetsPrefix}/ASSET_AI_listen_1_loop.mp4`;
const LISTENING_MODE_WHEN_THE_PERSON_IS_TYPING_OUT = `${assetsPrefix}/ASSET_AI_listen_1_out.mp4`;
const LISTENING_MODE_WHEN_THE_PERSON_IS_TYPING_IN = `${assetsPrefix}/ASSET_AI_listen_1_transit_in.mp4`;

const WAITING_MODE_IDLE = `${assetsPrefix}/ASSET_AI_1_loop.mp4`;
const THINKING_MODE_LOADING = `${assetsPrefix}/ASSET_AI_2_3_loop.mp4`;
const TRANSITION_TO_THINKING_LOADING_MODE_IN = `${assetsPrefix}/ASSET_AI_2_transit.mp4`;
const TRANSITION_TO_THINKING_LOADING_MODE_OUT = `${assetsPrefix}/ASSET_AI_3_transit.mp4`;

type VirtualAssistantProps = {
  bloc: OnBoardingInputBloc;
} & React.VideoHTMLAttributes<HTMLDivElement>;

export const VirtualAssistant: React.FC<VirtualAssistantProps> = ({ bloc, className, ...rest }) => {
  const cotChatStatus = bloc.cotChatStatus.value;

  const lastStatusRef = useRef<CotChatStatus>("waiting");
  const videoRefFirst = useRef<HTMLVideoElement>(null);
  const videoRefSecond = useRef<HTMLVideoElement>(null);

  const [loading, setLoading] = useState(false);

  const changeVideo = (): void => {
    if (loading === false) {
      const lastStatus = lastStatusRef.current;

      if (cotChatStatus !== lastStatus) {
        switch (lastStatus) {
          case "typing":
            setInVideoPlay(LISTENING_MODE_WHEN_THE_PERSON_IS_TYPING_OUT, WAITING_MODE_IDLE);
            lastStatusRef.current = "waiting";
            break;
          case "loading":
            setInVideoPlay(TRANSITION_TO_THINKING_LOADING_MODE_OUT, WAITING_MODE_IDLE);
            lastStatusRef.current = "waiting";
            break;
          default:
            break;
        }

        switch (cotChatStatus) {
          case "typing":
            setInVideoPlay(LISTENING_MODE_WHEN_THE_PERSON_IS_TYPING_IN, LISTENING_MODE_WHEN_THE_PERSON_IS_TYPING_LOOP);
            lastStatusRef.current = "typing";
            break;
          case "loading":
            setInVideoPlay(TRANSITION_TO_THINKING_LOADING_MODE_IN, THINKING_MODE_LOADING);
            lastStatusRef.current = "loading";
            break;
          default:
            break;
        }
      }
    }
  };

  const setInVideoPlay = (transitionSrc: string, loopSrc: string): void => {
    const videoFirst = videoRefFirst.current;
    const videoSecond = videoRefSecond.current;

    if (videoFirst && videoSecond) {
      setLoading(true);
      videoSecond.src = transitionSrc;
      const playPromise = videoSecond.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            videoFirst.style.zIndex = "0";
            videoFirst.src = loopSrc;
            videoSecond.onended = (): void => {
              videoFirst.style.zIndex = "10";
              setLoading(false);
            };
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  };

  useEffect(() => {
    changeVideo();
    // eslint-disable-next-line
  }, [cotChatStatus, loading]);

  const VirtualAssistantClassName = cls(styles.VirtualAssistant, className);

  return (
    <div className={VirtualAssistantClassName} {...rest}>
      <video style={{ zIndex: 10 }} ref={videoRefFirst} autoPlay loop src={WAITING_MODE_IDLE} />
      <video ref={videoRefSecond} />
    </div>
  );
};
