"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryVariable = void 0;
class StoryVariable {
    constructor(name, defaultValue, nodeId) {
        this.name = name;
        this.defaultValue = defaultValue;
        this.nodeId = nodeId;
    }
}
exports.StoryVariable = StoryVariable;
