import { Signal, useSignal } from "@preact/signals-react";
import { ProjectProps } from "../../type";
import Thumbnail from "../../Thumbnail";
import { DotsIcon } from "../assets/svg";
import style from "./GridItemCell.module.scss";
import { MobileProjectsViewUtils } from "../MobileProjectsViewUtils";
import { useEffect, useRef } from "react";
import { MoreActionView } from "../MoreActionView/MoreActionView";
import { LongPressDiv } from "../LongPress/LongPressDiv";
import classNames from "classnames";

export function GridItemCell(props: ProjectProps): JSX.Element {
  const ref = useRef(null);
  const thumbnailUrl = useSignal("");

  const isDropDown = useSignal(false);
  const dropDownPostions: Signal<React.CSSProperties> = useSignal({});

  const handleClick = (): void => {
    props.onClick?.(props.project);
  };

  useEffect(() => {
    MobileProjectsViewUtils.setThumbnailUrl(props.project).then(res => {
      thumbnailUrl.value = res;
      props.project.thumbnailUrl = res;
    });
  }, [props.project]);

  const attr = props.project.attributesV2 || JSON.parse(props.project.attributes ?? "{}");

  function showMoreDrop(): void {
    const element = ref.current;
    if (element) {
      const rect1 = (element as HTMLDivElement).getBoundingClientRect();
      if (rect1.top < 60) {
        dropDownPostions.value = { marginTop: "100px" };
      } else {
        dropDownPostions.value = { bottom: "34%" };
      }
    }
    isDropDown.value = true;
  }

  return (
    <LongPressDiv
      ref={ref}
      disable={isDropDown.value}
      className={classNames(style.gridItemCell, isDropDown.value ? style.upIndex : style.donwIndex)}
      onClick={handleClick}
      onLongPress={() => showMoreDrop()}
    >
      <div className={style.img}>
        {thumbnailUrl.value === "" ? <Thumbnail /> : <img src={thumbnailUrl.value} alt="thumbnail" />}
        <div className={style.imgLayer}></div>
      </div>
      <div className={style.des}>
        {isDropDown.value && (
          <MoreActionView
            style={dropDownPostions.value}
            onHidenView={() => {
              isDropDown.value = false;
            }}
            onClickItem={type => {
              isDropDown.value = false;
              props.onDropdownClick?.({ type, project: props.project });
            }}
          />
        )}
        <div className={style.name}>{attr?.name || "Untitled"}</div>
        <div
          className={style.more}
          onClick={e => {
            showMoreDrop();
            e.stopPropagation();
          }}
        >
          <DotsIcon />
        </div>
      </div>
    </LongPressDiv>
  );
}
