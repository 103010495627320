import { getIt } from "@uikit/getIt";
import { ExecuteBlueprintUtil } from "@uikit/util/ExecuteBlueprintUtil";
import { BlueprintDetailPanel } from "@uiview/views/BluePrint/BlueprintDetail/BlueprintDetail";
import { ToolPiecesDetail } from "@uiview/views/BluePrint/BlueprintDetail/ToolPiecesDetail";
import cls from "classnames";
import { Command } from "cmdk";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import styles from "./DropdownItem.module.scss";
import style from "./css/CustomDropdown.module.css";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";

export enum FuncBlueprintGroupType {
  TOOL_PIECES = "toolPieces",
  BLUEPRINTS = "blueprints",
}

type DropdownItemProps = {
  func: {
    icon: string;
    label: string;
    description: string;
    groupDisplayTitle: string;
    value: string;
    hide: boolean;
    isCreate: boolean;
  };
  disableShowBlueprintDetail: number;
  updateFuncGroup: () => void;
  onClickBlueprintToolPiecesDetail: (value: string) => void;
  currentBlueprintToolPiecesDetail: string | undefined;
  id: string;
  onSelect: () => void;
  onSelectBlueprint: () => void;
  // TODO: need to add the real declare
} & any;

export function DropdownItem({
  func,
  disableShowBlueprintDetail,
  updateFuncGroup,
  onClickBlueprintToolPiecesDetail,
  currentBlueprintToolPiecesDetail,
  onSelect,
  onSelectBlueprint,
  ...rest
}: DropdownItemProps) {
  const brainClient = getIt(BrainClient);

  const handleClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, value: string) => {
    e.stopPropagation();
    onClickBlueprintToolPiecesDetail(value);
  };

  const handleSelect = async () => {
    // Blueprint is selected (only from the generative function list for now, not from the list of blueprints–that will conflict with the vocabulary task)
    if (func.groupType === FuncBlueprintGroupType.BLUEPRINTS) {
      const result = await tryPromise(brainClient.openAi.blueprintDetail({ id: func.id || "" }));
      if (result.data) {
        return ExecuteBlueprintUtil.execute(
          {
            id: rest.id,
            aiDescription: func.label || "",
            blueprintInfo: result.data.data.blueprint as any,
            nodeIndexRef: creatorRefStore.nodeIndexRef,
            edgeIndexRef: creatorRefStore.edgeIndexRef,
            newLineDataRef: getIt(CanvasDataRef).newLineDataRef,
            nodeDataRef: getIt(CanvasDataRef).nodeDataRef,
            nodes: getIt(CreatorNodesStore).getNodes(),
            edges: getIt(CreatorEdgesStore).getEdges(),
          },
          value => {
            onSelectBlueprint({ ...value, ...func });
          }
        );
      }
    }
    if (func.groupType !== FuncBlueprintGroupType.TOOL_PIECES) {
      onSelect();
    }
  };

  const isBlueprint = [FuncBlueprintGroupType.TOOL_PIECES, FuncBlueprintGroupType.BLUEPRINTS].includes(func.groupType);
  const enableBlueprintDetailPanel = currentBlueprintToolPiecesDetail === func.value;
  const enableInfoButton = isBlueprint && enableBlueprintDetailPanel === false;

  return (
    <>
      <Command.Item value={func.id} onSelect={() => handleSelect()} {...rest}>
        <div className={styles.inner}>
          <div className={cls(style["commandbar-item-inner"])}>
            <div className={style["commandbar-item-icon"]}>
              <img src={func.icon} alt="" />
            </div>
            <div className={style["commandbar-item-content"]}>
              <div className={style["commndbar-item-label"]}>{func.label}</div>
              {func.description && <div className={style["commndbar-item-desc"]}>{func.description}</div>}
            </div>
          </div>
          {enableInfoButton && (
            <img
              onClick={e => handleClick(e, func.value)}
              alt=""
              src={`${settings.S3CDN}${settings.viewAssetsPath}info.svg`}
            />
          )}
        </div>
      </Command.Item>
      {enableBlueprintDetailPanel === true && (
        <>
          {func.groupType === FuncBlueprintGroupType.BLUEPRINTS && (
            <BlueprintDetailPanel updateFuncGroup={updateFuncGroup} id={func.id} edgeId={rest.id} />
          )}
          {func.groupType === FuncBlueprintGroupType.TOOL_PIECES && (
            <ToolPiecesDetail updateFuncGroup={updateFuncGroup} id={func.id} />
          )}
        </>
      )}
    </>
  );
}
