import React, { useRef, useEffect } from "react";
import styles from "./AgentNode.module.css";
import loader from "./assets/loader.json";
import checkmark from "./assets/checkmark.json";
import lottie from "lottie-web";

const ProgressButton = ({ children, loading = false, isLast = false }) => {
  const lottieRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    if (!lottieRef.current) return;

    animationRef.current = lottie.loadAnimation({
      animationData: loader,
      container: lottieRef.current,
    });
  }, [lottieRef]);

  useEffect(() => {
    if (isLast === false && loading === false) {
      animationRef.current.destroy();
      animationRef.current = lottie.loadAnimation({
        animationData: checkmark,
        container: lottieRef.current,
        loop: false,
      });
    }
  }, [isLast, loading]);

  return (
    <span className={styles.progressBtn}>
      <div ref={lottieRef} className={styles.lottieLoader} />
      <span>{children}</span>
    </span>
  );
};

export default ProgressButton;
