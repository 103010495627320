import { Container } from "inversify";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import store from "@store/index";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreateService } from "@uikit/service/CreateService";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { SaveTemplateService } from "@uikit/service/SaveTemplateService";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { functionPopupStore } from "@uikit/store/FunctionPopupStore";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";
import { FotSaveEvent } from "@uikit/service/FotSaveEvent";
import { SaveCore } from "imagica-corekit/dist/cases/saveService/SaveCore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { SaveCreateService } from "imagica-corekit/dist/cases/saveService/SaveCreateService";
import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { FotNavigateController } from "@uikit/service/FotNavigateController";

/**
 * 该 class 用于注册 保存逻辑实例
 *
 */
export class RegisterSave {
  static configure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };

    const fotSaveEvent = new FotSaveEvent(it(FotReduxStore), it(PreviewAppStore), it(HomePluginStore));

    const createService = new CreateService(
      it(FotReduxStore),
      it(ObjectRelationGqlService),
      it(PersonalFunctionStore),
      it(SaveCreateService),
      fotSaveEvent
    );

    const homeMethodsService = new HomeMethodsService(
      store,
      it(CreatorNodesStore),
      it(CreatorEdgesStore),
      creatorRefStore,
      it(HomePluginStore),
      it(CreatorAISaasStore),
      it(CreatorSaasAppStore),
      previewStore,
      it(ProjectNodeStore)
    );
    const saveTemplateService = new SaveTemplateService(
      store,
      it(CreatorEdgesStore),
      myModificationDataStroe,
      functionPopupStore,
      createService,
      creatorRefStore,
      it(ObjectRelationGqlService),
      it(ShowNavPageStore),
      homeMethodsService,
      it(CreatorNodesStore),
      it(CanvasDataRef),
      it(PersonalFunctionStore),
      it(FotNavigateController)
    );
    const fotSaveService = new FotSaveService(
      it(CreatorEdgesStore),
      it(CreatorNodesStore),
      homeMethodsService,
      it(PreviewAppStore),
      it(CanvasDataRef),
      creatorRefStore,
      myModificationDataStroe,
      homeToolBarStore,
      previewStore,
      fotSaveEvent,
      it(SaveCore)
    );

    container.bind(FotSaveEvent).toConstantValue(fotSaveEvent);
    container.bind(CreateService).toConstantValue(createService);
    container.bind(HomeMethodsService).toConstantValue(homeMethodsService);
    container.bind(SaveTemplateService).toConstantValue(saveTemplateService);
    container.bind(FotSaveService).toConstantValue(fotSaveService);

    // 依赖了 SaveTemplateService 所以目前只能放在这里，不然可以提前到 RegisterUIFunctional
    container
      .bind(ProjectFunctionStore)
      .toConstantValue(new ProjectFunctionStore(it(PersonalFunctionStore), it(SaveTemplateService)));
  }
}
