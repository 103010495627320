"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsHeadlineProps = exports.VBDataNewsHeadline = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataNewsHeadline extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.news_list = [];
    }
}
exports.VBDataNewsHeadline = VBDataNewsHeadline;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => NewsHeadlineProps, [])
], VBDataNewsHeadline.prototype, "news_list", void 0);
class NewsHeadlineProps {
    constructor() {
        this.news_image = "";
        this.news_title = "";
        this.news_description = "";
        this.source = new NewsSource();
    }
}
exports.NewsHeadlineProps = NewsHeadlineProps;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => NewsSource)
], NewsHeadlineProps.prototype, "source", void 0);
class NewsSource {
    constructor() {
        this.source_icon = "";
        this.source_text = "";
    }
}
