import { find } from "lodash";
import { Edge, Node } from "reactflow";
import { FocusNodeUtil, RectType } from "@uikit/util/FocusNodeUtil";
import { CreatorCanvasFocus } from "@uikit/cases/canvasFocus/CreatorCanvasFocus";
import { MoveToHandlerResult, MoveToOptions } from "@uikit/cases/canvasFocus/FocusController";
import { previewStore } from "@uiview/store/PreviewStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";

/**
 * 用于移动视口聚焦到节点或边
 *
 * - tip: 因为聚焦时会用动画,所以核心几个方法建议不要用 useCallbak 包裹
 * - FocusNode 用来计算节点,或边坐标的工具类
 *
 */
// eslint-disable-next-line
export class FocusNode {
  constructor(
    public creatorCanvasFocus: CreatorCanvasFocus,
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore
  ) {}

  /**
   * 聚焦节点到视口中心
   *
   * - extraRect 可为节点扩展 x,y,width,height 属性
   *
   */
  focusNode = (node?: Node<any>, extraRect?: RectType, options?: MoveToOptions): Promise<MoveToHandlerResult> => {
    if (node) {
      // 由于 node 有 toolbar，将toolbar 包含在内
      // 直接调用 focusNodeTopBar
      return this.creatorCanvasFocus.focusNodeTopBar(node, {
        moveId: node.id,
        showPreviewPanel: previewStore.state.showPreviewPanel,
        // FIXME: from useIntroTooltip
        showIntroTooltipObj: false,
        ...options,
        extraRect,
      });
    }
    return Promise.reject("Node not found");
  };
  /**
   * @deprecated use `CreatorCanvasFocus.focusNodeTopBarById`
   * @param id
   * @param options
   */
  focusNodeTopBarById(id: string, options?: MoveToOptions): Promise<MoveToHandlerResult> {
    return this.creatorCanvasFocus.focusNodeTopBarById(id, {
      showPreviewPanel: previewStore.state.showPreviewPanel,
      ...options,
    });
  }
  /**
   * @deprecated use `CreatorCanvasFocus.focusToNodeBottomById`
   * @param id
   * @param options
   */
  focusToNodeBottomById = (id: string, options?: MoveToOptions): Promise<MoveToHandlerResult> => {
    return this.creatorCanvasFocus.focusToNodeBottomById(id, {
      showPreviewPanel: previewStore.state.showPreviewPanel,
      ...options,
    });
  };

  /**
   * 聚焦线到视口中心
   *
   * 实施步骤：
   *
   * 1. 分别得到 target 和 source 两个节点的中心点
   * 2. 计算两个中心点得到以中心点为基准的矩形区域
   * 3. 最后视口移动到 中心点组成的矩形区域的中心
   *
   * **此处的 focusEdge 的 edge 并非 node**
   *
   * @param  edge
   */
  focusEdge = (edge?: Edge<any>, options?: MoveToOptions): Promise<MoveToHandlerResult> => {
    if (edge) {
      const centerPos = FocusNodeUtil.getEdgeCenterPosition(edge, undefined, this.creatorNodesStore.state.nodes);
      if (centerPos) {
        const moveId = edge.id;
        return this.creatorCanvasFocus.focus(centerPos, {
          showPreviewPanel: previewStore.state.showPreviewPanel,
          moveId,
          ...options,
        });
      }
    }
    return Promise.reject("Edge not found");
  };
  /**
   * v2版本将v1的边也作为一个node了
   * 基于focusEdge新写的一个方法
   * @param node
   * @param options
   * @param extraRect
   */
  focusEdgeV2 = (edge?: Node<any>, options?: MoveToOptions, extraRect?: RectType): Promise<MoveToHandlerResult> => {
    if (edge) {
      return this.creatorCanvasFocus.focusEdge(edge, {
        moveId: edge.id,
        showPreviewPanel: previewStore.state.showPreviewPanel,
        // FIXME: from useIntroTooltip
        showIntroTooltipObj: false,
        ...options,
        extraRect,
      });
    }
    return Promise.reject("Edge not found");
  };

  focusNodeById = (id: string): Promise<MoveToHandlerResult> => {
    const node = find(this.creatorNodesStore.state.nodes, ["id", id]);
    return this.focusNode(node);
  };

  focusEdgeById = (id: string, options?: MoveToOptions): Promise<MoveToHandlerResult> => {
    const edge = find(this.creatorEdgesStore.getEdges(), ["id", id]);
    return this.focusEdge(edge, options);
  };

  focusEdgeByIdV2 = (id: string, options?: MoveToOptions): Promise<MoveToHandlerResult> => {
    const nodes = find(this.creatorNodesStore.state.nodes, ["id", id]);
    return this.focusEdgeV2(nodes, options);
  };
}
