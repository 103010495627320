"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BgGTheme = void 0;
const CDNPathUtil_1 = require("../../../base/util/CDNPathUtil");
const DarkTheme_1 = require("./DarkTheme");
class BgGTheme {
}
exports.BgGTheme = BgGTheme;
BgGTheme.theme = {
    saasAiAppTheme: {
        ...DarkTheme_1.DarkTheme.theme.saasAiAppTheme,
        background: `url(${CDNPathUtil_1.CDNPathUtil.getFilePath("themeBgG.jpg")})`,
        shareFooterUrlInfo: "#000",
        shareFooterUrlColor: "#000",
    },
    nodeTheme: {
        ...DarkTheme_1.DarkTheme.theme.nodeTheme,
    },
    appSignUpButtonTheme: {
        ...DarkTheme_1.DarkTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBorder: "0",
        inputBorderBottom: "0",
        inputPadding: "15px 25px",
        renderingTransitionColors: "#1a142d",
    },
};
