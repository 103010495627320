"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImagicaOnboardingVideoGuide = void 0;
class ManualAndCot {
    constructor() {
        this.title = "";
        this.subtitle = "";
        this.end_title = "";
        this.end_subtitle = "";
        this.video_url = "";
    }
}
class ImagicaOnboardingVideoGuide {
    constructor() {
        this.manual = new ManualAndCot();
        this.cot = new ManualAndCot();
    }
}
exports.ImagicaOnboardingVideoGuide = ImagicaOnboardingVideoGuide;
