import { getIt } from "@uikit/getIt";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { AISaasApp } from "../AISaas";
import { ChatAIPage } from "../ChatAI/ChatAISharedPage";
import LoadingOutline from "@views/components/LoadingOutline";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { previewStore } from "@uiview/store/PreviewStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { useEffect } from "react";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

const homePluginStore = getIt(HomePluginStore);
const creatorSaasAppStore = getIt(CreatorSaasAppStore);
const creatorNodesStore = getIt(CreatorNodesStore);

export function SharePage(): JSX.Element {
  const homePluginState = useImagicaStore(homePluginStore).value;
  const previewState = useImagicaStore(previewStore).value;
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  useEffect(() => {
    creatorNodesStore.on(() => {
      creatorSaasAppStore.forceUpdates();
    });
  }, []);

  if (saasUIData?.v2App?.type !== "chat_ai" && homePluginState.isShare && previewState.showPreviewPanel) {
    // fixme:opt: clickRunAllBtn
    return <AISaasApp />;
  }

  if (saasUIData?.v2App?.type === "chat_ai" && previewState.showPreviewPanel) {
    return <ChatAIPage />;
  }

  return <LoadingOutline />;
}
