export var VBComponentsNameIdMapping = {
  NewsVideo: "NewsVideo",
  YoutubeVideo: "YoutubeVideo",
  YoutubeSearchResults: "YoutubeSearchResults",
  "Card with Image and Text Below": "C1",
  "Card with Multiple Images in a Story Format": "C2",
  "Card with Image and Text Side by Side (50-50)": "C3",
  "Card with Image and Text Side by Side (20-80)": "C4",
  "show trip on a map": "showTrip",
  "text body": "Text",
  ShoppingSearchResults: "ShoppingSearchResults",
  WeatherCurrent: "WeatherCurrent",
  WeatherWeekly: "WeatherWeekly",
  ItineraryPlanning: "ItineraryPlanning",
  TouristAttraction: "TouristAttraction",
  NewsHeadline: "NewsHeadline",
  ShoppingProduct: "ShoppingProduct",
  RestaurantRecommendation: "RestaurantRecommendation",
  DishRecommendation: "DishRecommendation",
  NewsVideoList: "NewsVideoList",
  TopicWithRelatedPeople: "TopicWithRelatedPeople",
  TopicWithRelatedDetail: "TopicWithRelatedDetail",
  SeasonalTravelDestinationPlan: "SeasonalTravelDestinationPlan",
  NewsDigest: "NewsDigest",
  ImageNewsGrid: "ImageNewsGrid",
  NewsDigestDetails: "NewsDigestDetails",
  "map view": "mapView"
};