"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorebeAdapter = void 0;
const BrainAdapter_1 = require("../api/BrainAdapter");
const Settings_1 = require("../kernel/Settings");
class CorebeAdapter extends BrainAdapter_1.BrainAdapter {
    constructor(authStore, projectStore, featureAccess) {
        super(authStore, projectStore);
        this.featureAccess = featureAccess;
    }
    get baseUrl() {
        const url = this.featureAccess.can("use_custom_corebe_url");
        if (url) {
            return url;
        }
        return Settings_1.settings.corebeUrl;
    }
}
exports.CorebeAdapter = CorebeAdapter;
