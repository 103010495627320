import { ThemeType } from "imagica-corekit/dist/cases/theme/appTheme/AiAppThemeData";
import { ChatThemeType } from "imagica-corekit/dist/cases/theme/chatTheme/ChatAppThemeData";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

class CarouselType {
  key?: ThemeType | ChatThemeType;
  image?: string;
  features: string[] = [];
}

export interface IFontType {
  fontFamily: string;
  features: string[];
  fontFaceUrl: string;
}

// 本地已有的字体
const localFontFaceList = [
  `${settings.S3CDN}${settings.naturalAiPath}/Ak-bold.woff2`,
  `${settings.S3CDN}${settings.naturalAiPath}/Ak-Light.woff2`,
  `${settings.S3CDN}${settings.naturalAiPath}/Ak-Medium.woff2`,
  `${settings.S3CDN}${settings.naturalAiPath}/Ak-Regular.woff2`,
  `${settings.S3CDN}${settings.naturalAiPath}/SF-Pro-Display-Bold.otf`,
  `${settings.S3CDN}${settings.naturalAiPath}/SF-Pro-Display-Medium.otf`,
  `${settings.S3CDN}${settings.naturalAiPath}/SF-Pro-Display-Regular.otf`,
  `${settings.S3CDN}${settings.naturalAiPath}/SF-Pro-Display-Light.otf`,
  `${settings.S3CDN}${settings.naturalAiPath}/AktivGrotesk-Regular.otf`,
  `${settings.S3CDN}${settings.viewAssetsPath}fonts/Lora-Regular.ttf`,
  `${settings.S3CDN}${settings.viewAssetsPath}fonts/Lato-Regular.ttf`,
  `${settings.S3CDN}${settings.viewAssetsPath}fonts/PlayfairDisplay-Regular.ttf`,
  `${settings.S3CDN}${settings.viewAssetsPath}fonts/Raleway-Regular.ttf`,
  `${settings.S3CDN}${settings.viewAssetsPath}fonts/Roboto-Regular.ttf`,
];
// 静态imagica_app_templates 数据
const imagicaAppTemplates = [
  {
    name: "AI App",
    value: "ai_app",
    themes: [
      {
        key: "light",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_1.png",
        features: [],
      },
      {
        key: "dark",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_2.png",
        features: [],
      },
      {
        key: "themeA",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_3.png",
        features: [],
      },
      {
        key: "themeB",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_4.png",
        features: [],
      },
      {
        key: "themeC",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_5.png",
        features: [],
      },
      {
        key: "themeD",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_6.png",
        features: [],
      },
      {
        key: "themeE",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_7.png",
        features: [],
      },
      {
        key: "themeF",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_8.png",
        features: [],
      },
      {
        key: "themeG",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_9.png",
        features: [],
      },
      {
        key: "themeBgA",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_10.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "themeBgB",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_11.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "themeBgC",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_12.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "themeBgD",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_13.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "themeBgE",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_14.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "themeBgF",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_15.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "themeBgG",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_16.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "themeBgH",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_17.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "themeBgI",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_18.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "themeBgJ",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ai_template_19.png",
        features: ["premium_app_publish_formats"],
      },
    ],
  },
  {
    name: "Chat AI",
    value: "chat_ai",
    themes: [
      {
        key: "light",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat_template_1.png",
        features: [],
      },
      {
        key: "dark",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat_template_2.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "chatThemeA",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat_template_3.png",
        features: [],
      },
      {
        key: "chatThemeB",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat_template_4.png",
        features: [],
      },
      {
        key: "chatThemeC",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat_template_5.png",
        features: [],
      },
      {
        key: "chatThemeD",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat_template_6.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "chatThemeE",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat_template_7.png",
        features: ["premium_app_publish_formats"],
      },
      {
        key: "chatThemeF",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat_template_8.png",
        features: [],
      },
      {
        key: "chatThemeG",
        image: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat_template_9.png",
        features: [],
      },
    ],
  },
];
// 静态imagica_app_fonts 数据
const imagicaAppfonts = [
  {
    fontFamily: "Ak-Regular",
    fontFaceUrl: "https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/Ak-Regular.woff2",
    features: ["premium_app_publish_formats"],
  },
  {
    fontFamily: "Raleway",
    fontFaceUrl: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/Raleway-Regular.ttf",
    features: ["premium_app_publish_formats"],
  },
  {
    fontFamily: "Lato",
    fontFaceUrl: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/Lato-Regular.ttf",
    features: ["premium_app_publish_formats"],
  },
  {
    fontFamily: "Roboto",
    fontFaceUrl: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/Roboto-Regular.ttf",
    features: ["premium_app_publish_formats"],
  },
  {
    fontFamily: "Lora",
    fontFaceUrl: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/Lora-Regular.ttf",
    features: ["premium_app_publish_formats"],
  },
  {
    fontFamily: "PlayfairDisplay",
    fontFaceUrl: "https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/PlayfairDisplay-Regular.ttf",
    features: ["premium_app_publish_formats"],
  },
];

export { CarouselType, imagicaAppTemplates, imagicaAppfonts, localFontFaceList };
