"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EdgeRunMsg = void 0;
class EdgeRunMsg {
    /// edgeId: 边id
    /// 是不是手动触发的run
    /// run回调（ableRun：是否可以run）
    constructor(edgeId, isManualRun, runResultCall) {
        this.edgeId = edgeId;
        this.isManualRun = isManualRun;
        this.runResultCall = runResultCall;
        this.naming = "EdgeRunMsg";
    }
}
exports.EdgeRunMsg = EdgeRunMsg;
