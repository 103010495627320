import { FotNavigateController } from "@uikit/service/FotNavigateController";
import func from "@uikit/func";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { FunctionPopupStore } from "@uikit/store/FunctionPopupStore";
import { MyModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { CreateService } from "./CreateService";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { ObjectRelationGqlService } from "./ObjectRelationGqlService";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { DataRefUtil } from "@uikit/util/DataRefUtil";
import { NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import { HomeMethodsService } from "./HomeMethodsService";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { isEmpty } from "lodash";
import { HandleSaveUtil } from "@uikit/util/HandleSaveUtil";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { FotUIDependenciesInterface } from "@uikit/infra/FotUIDependenciesInterface";

class SaveTemplateDep {
  /**
   * 该属性用于 HandleSaveUtil.handleUserSavedFunctions 方法，但是目前该属性是ui层数据
   */
  allPreDefinedFunctions: any[] = [];
}

/**
 * 用来替换原来 HomeUsePlugin 中的 saveAsExistingTemplateV2 逻辑
 *
 * 由于 handleSave 变成纯 service 被独立出去了, 所以 saveAsExistingTemplateV2 逻辑已经被移
 *
 * TODO: 该名字需要改成 CreatorFunctionsService
 */
export class SaveTemplateService implements FotUIDependenciesInterface<SaveTemplateDep> {
  dependencies: SaveTemplateDep = new SaveTemplateDep();

  constructor(
    public reduxStore: typeof import("@store/index").default,
    public creatorEdgesStore: CreatorEdgesStore,
    public myModificationDataStroe: MyModificationDataStroe,
    public functionPopupStore: FunctionPopupStore,
    public createService: CreateService,
    public creatorRefStore: CreatorRefStore,
    public gql: ObjectRelationGqlService,
    public showNavPageStore: ShowNavPageStore,
    public homeMethods: HomeMethodsService,
    public creatorNodesStore: CreatorNodesStore,
    public canvasDataRef: CanvasDataRef,
    public personalFunctionStore: PersonalFunctionStore,
    public fotNavigateController: FotNavigateController
  ) {}

  setDependencies(value: SaveTemplateDep): void {
    Object.assign(this.dependencies, value);
  }

  async clickModalSaveBtn(): Promise<void> {
    if (isEmpty(this.functionPopupStore.state.funcName)) return;
    this.creatorRefStore.singleFlowEdgesRef.current.name = this.functionPopupStore.state.funcName;
    this.creatorRefStore.singleFlowEdgesRef.current.description = this.functionPopupStore.state.funcDescription;
    this.functionPopupStore.setSaveFunctionLoading(true);
    try {
      if (this.myModificationDataStroe.state.showMyModification) {
        await this.saveUpdateFunction();
      } else {
        await this.saveAsFunction();
      }
    } catch (error) {
      console.error(error);
      func.messageUtil(error);
    }
    this.functionPopupStore.setSaveFunctionLoading(false);
    this.functionPopupStore.setCreateNewFuncModal(false);
    func.customMsg({
      content: "Saved successfully",
      type: "success",
    });
  }

  getCurrentStateOfFunctionForSaving(): any {
    const edgeArr = this.creatorEdgesStore.getEdges().map(x => {
      const edgeObj = DataRefUtil.getSimpleEdgeDataV3(x, this.canvasDataRef.newLineDataRef);
      return Object.assign({}, { ...x }, { data: edgeObj });
    });

    const nodeArr = this.creatorNodesStore.getNodes().map(x => {
      const nodeData = NEW_EDGE_REG.test(x.id)
        ? this.canvasDataRef.getSimpleNodeDataV3(x)
        : this.canvasDataRef.getSimpleNodeData(x);
      return Object.assign({}, { ...x }, { data: nodeData });
    });

    // opt:
    const selectedTemplate = this.reduxStore.getState().studio.selectedTemplate as any;

    return {
      description: this.functionPopupStore.state.funcDescription,
      edgeArr: edgeArr,
      name: this.functionPopupStore.state.funcName,
      nodeArr: nodeArr,
      projectName: selectedTemplate.template_name,
      isV3Function: this.myModificationDataStroe.state.myModificationData.isV3Function,
    };
  }

  private async saveUpdateFunction(): Promise<void> {
    const attributes = this.getCurrentStateOfFunctionForSaving();
    await this.gql.updateObject(this.myModificationDataStroe.state.myModificationData.id, {
      name: "studio_function",
      attributes: attributes,
    });

    this.fotNavigateController.openCreatorHomePage();
  }

  private async saveAsFunction(): Promise<any> {
    const data = this.creatorRefStore.singleFlowEdgesRef.current;
    const edgeArr = data.edgeArr.map(x => {
      const newObj = DataRefUtil.getSimpleEdgeDataV3(x, this.canvasDataRef.newLineDataRef);

      if ("imageGenSelectOptions" in x.data) {
        newObj.imageGenSelectOptions = x.data.imageGenSelectOptions;
      }
      if ("isFromSaveData" in x.data) {
        newObj.isFromSaveData = x.data.isFromSaveData;
      }
      return Object.assign({}, { ...x }, { data: newObj });
    });

    const nodeArr = data.nodeArr
      .map(x => (NEW_EDGE_REG.test(x.id) ? this.canvasDataRef.getSimpleNodeV3(x) : this.canvasDataRef.getSimpleNode(x)))
      .filter((el, index, self) => {
        return self.findIndex(e => e.id === el.id) === index;
      });

    // opt:
    const selectedTemplate = this.reduxStore.getState().studio.selectedTemplate as any;

    const attributes = {
      isV3Function: true,
      description: data.description,
      edgeArr,
      name: data.name,
      nodeArr,
      projectName: selectedTemplate.template_name,
    };

    const callback = (object: any): void => {
      HandleSaveUtil.handleUserSavedFunctions({
        each: object,
        isUpdate: false,
        // 原来调用方法并没有传入 nodeDataRef???, 所以这里变为空
        // nodeDataRef: nodeDataRef,
        nodeDataRef: { current: {} },
        personalFunctionStore: this.personalFunctionStore,
        creatorRefStore: this.creatorRefStore,
        allPreDefinedFunctions: this.dependencies.allPreDefinedFunctions,
        isSort: true,
      });
    };

    return await this.createService.createFunction(attributes, false, callback);
  }
}
