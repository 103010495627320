import { signal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { Blueprint } from "imagica-corekit/dist/base/api/blueprintTyped/Blueprint";
import { BlueprintRequest } from "imagica-corekit/dist/base/api/blueprintTyped/BlueprintRequest";
import { Blueprint as Blueprint2 } from "imagica-corekit/dist/base/api/blueprintTyped/BlueprintV2";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { Node } from "reactflow";
import { BluePrintUtil } from "./BluePrintUtil";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { AutoAddInputNode } from "@uikit/service/AutoAddInputNode";

export class GenerativeFunctionState {
  printJson = signal<string>("");
  loading = signal<boolean>(false);
  blueprints = signal<Blueprint2[]>([]);
  blueprint = signal<Blueprint | undefined>(undefined);
  enableBlueprintDetailPanel = signal<boolean>(true);
}

export class GenerativeFunctionBloc {
  state = new GenerativeFunctionState();
  brainClient = getIt(BrainClient);
  projectNodeStore = getIt(ProjectNodeStore);
  autoAddInputNode = getIt(AutoAddInputNode);

  constructor(public nodes: React.MutableRefObject<Node<any>[]>) {}

  setEnableBlueprintDetailPanel = (enable: boolean): void => {
    this.state.enableBlueprintDetailPanel.value = enable;
  };

  async onLoadBlue(edge_description: string, edgeId: string): Promise<void> {
    this.state.loading.value = true;

    const nodes = this.nodes.current;
    const targetNode = nodes.find(x => x.id === edgeId);

    let inputs = BluePrintUtil.findTargetNodes(edgeId, nodes, this.projectNodeStore);

    const result = await tryPromise(this.brainClient.openAi.toolBoxInputs({ edge_description, inputs: inputs }));

    if (result?.data?.data?.inputs) {
      inputs = result.data.data.inputs;
    }

    this.autoAddInputNode.autoAddInputNodeByBluePrint({ nodes, inputs, edgeNode: targetNode as Node });

    this.state.loading.value = false;

    await this.blueprintBuilder({ edge_description, inputs });
  }

  async onLoadBlueV2(edge_description: string, edgeId: string): Promise<void> {
    const inputs = BluePrintUtil.findTargetNodes(edgeId, this.nodes.current, this.projectNodeStore);
    await this.blueprintBuilderV2({ edge_description, inputs });
  }

  async onLoadBlueJust(edge_description: string, edgeId: string): Promise<void> {
    const inputs = BluePrintUtil.findTargetNodes(edgeId, this.nodes.current, this.projectNodeStore);
    await this.blueprintBuilderJust({ edge_description, inputs });
  }

  async blueprintBuilder(data: BlueprintRequest): Promise<void> {
    this.state.loading.value = true;

    const builderResult = await tryPromise(this.brainClient.openAi.blueprintBuilder(data));

    this.state.printJson.value = JSON.stringify(builderResult.data?.data.blueprint);
    const blueprint = builderResult.data?.data.blueprint;

    this.state.blueprint.value = blueprint;

    this.state.loading.value = false;
  }

  async blueprintBuilderV2(data: BlueprintRequest): Promise<void> {
    this.state.loading.value = true;

    const builderResult = await tryPromise(this.brainClient.openAi.blueprintBuilderV2(data));

    this.state.blueprints.value = builderResult.data?.data.blueprints || [];

    this.state.loading.value = false;
  }

  async blueprintBuilderJust(data: BlueprintRequest): Promise<void> {
    this.state.loading.value = true;

    const builderResult = await tryPromise(this.brainClient.openAi.blueprintBuilderJust(data));

    this.state.blueprints.value = builderResult.data?.data.blueprints || [];

    this.state.loading.value = false;
  }
}
