import { Button } from "antd";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useState from "react-usestateref";
import styles from "./AgentNode.module.css";
import { api } from "../../../../views/thinking-layout-editor/utils/InstanceAPI";
import func from "@uikit/func";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";
import { getIt } from "@uikit/getIt";

const MakeACall = ({ endFlag, AIDesc, msg, onFocus }) => {
  const currentAIDescRef = useRef(null);
  const postUrlCanAbortService = getIt(PostUrlCanAbortService);

  const [loading, setLoading] = useState(false);
  const [, setPreambleLoading, preambleLoadingRef] = useState(false);

  const [preamble, setPreamble] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [intro, setIntro] = useState("Hi how's your day going?");

  const disableAddPreview = useSelector(state => state.fot.disableAddPreview);

  const handleChangePhoneNumber = e => {
    setPhoneNumber(e.target.value);
  };

  const handleChangeIntro = e => {
    setIntro(e.target.value);
  };

  const handleClickMakeACall = async () => {
    if (!AIDesc)
      return func.customMsg({
        content: "Need input what do you want the AI to do",
        type: "warning",
      });
    if (!phoneNumber)
      return func.customMsg({
        content: "Need input the phone number",
        type: "warning",
      });
    if (!intro)
      return func.customMsg({
        content: "Need input the intro",
        type: "warning",
      });

    setLoading(true);

    let preambleParams = preamble;

    if (preambleLoadingRef.current === true) {
      await (async () => {
        return new Promise(resolve => {
          let time = 0;
          let timer = setInterval(() => {
            time = time + 100;
            if (preambleLoadingRef.current === false) {
              clearInterval(timer);
              resolve("Successfully fetched");
            }

            // If it exceeds 5 seconds, force a stop to avoid a dead cycle
            if (time >= 5 * 1000) {
              clearInterval(timer);
              resolve("Failed fetched");
            }
          }, 100);
        });
      })();
    }

    // If user reenter the `AI desc` or there is no preamble, fetch the preamble data
    if (!preamble || currentAIDescRef.current !== AIDesc) {
      preambleParams = await fetchPreambleData();
    }
    await fetchModelData(preambleParams);

    setLoading(false);
  };

  const fetchModelData = async preambleParams => {
    try {
      const makeACallRes = await api.post(`/be/fot/conversation`, { to: phoneNumber, preamble: preambleParams, intro });
      if (makeACallRes.status !== 200) {
        func.customMsg({
          content: "Failed to make a call",
          type: "warning",
        });
        console.error("Failed to make a call", makeACallRes.statusCode);
        return;
      }
    } catch (error) {
      func.customMsg({
        content: "Failed to make a call",
        type: "warning",
      });
      console.error("Failed to make a call", error);
      return;
    }
    func.customMsg({
      content: "Call placed successfully",
      type: "success",
    });
  };

  const fetchPreambleData = async (input = AIDesc) => {
    setPreambleLoading(true);
    try {
      let temp = "";
      await postUrlCanAbortService.postWithStreaming(
        "/stream/bas-demo-v4/nlp/completions_generation",
        {
          domain: "brain_studios",
          identifier_type: "file",
          identifier_value: input.toLowerCase().includes("interview")
            ? "interview_agent_prompt_generation_demo.model"
            : "create_any_agent_prompt_generation.model",
          stream: true,
          variables: {
            input: input,
            intro,
            phone_number: phoneNumber,
          },
        },
        message => {
          temp += message.choices?.[0]?.text || message.choices?.[0]?.delta?.content || "";
        },
        false
      );
      currentAIDescRef.current = input;
      setPreamble(temp);
      return temp;
    } catch (error) {
      console.error(error);
      // If fetch failed, use `AIDesc`
      setPreamble(AIDesc);
      return AIDesc;
    } finally {
      setPreambleLoading(false);
    }
  };

  useEffect(() => {
    if (endFlag) {
      fetchPreambleData(msg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endFlag]);

  return (
    <div className={styles.form}>
      <label className={styles.label}>Phone number</label>
      <input type="text" onChange={handleChangePhoneNumber} onFocus={() => onFocus()} className={styles.input} />
      <label className={styles.label}>Intro</label>
      <input
        type="text"
        onChange={handleChangeIntro}
        value={intro}
        onFocus={() => onFocus()}
        className={styles.input}
      />
      <Button
        disabled={endFlag === false || disableAddPreview === false}
        loading={loading}
        onClick={handleClickMakeACall}
        type="primary"
        className={styles.makeACall}
      >
        Make a call
      </Button>
    </div>
  );
};

export default MakeACall;
