"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewBuilderGraphComponent_8 = exports.ViewBuilderGraphComponent_7 = exports.ViewBuilderGraphComponent_6 = exports.ViewBuilderGraphComponent_5 = exports.ViewBuilderGraphComponent_4 = exports.ViewBuilderGraphComponent_3 = exports.ViewBuilderGraphComponent_2 = exports.ViewBuilderGraphComponent_1 = exports.ViewBuilderGraphMappings = exports.ViewBuilderGraphDesignSectionComponent = exports.ViewBuilderGraphDesignSection = exports.ViewBuilderGraphDesign = exports.ViewBuilderGraphComponent = exports.ViewBuilderAudio = exports.ViewBuilderGraphOutputs = exports.ViewBuilderGraphInput = exports.ViewBuilderGraphData = exports.UserInputs = exports.ViewBuilderGraph = void 0;
const VBGraphDataBase_1 = require("../../api/viewBuilderTyped/VBGraphData/VBGraphDataBase");
const ChainRequest_1 = require("../../storyV2/response/customApiHandler/chain/ChainRequest");
const CotTemplateResponseData_1 = require("../../../cases/cot/typed/CotTemplateResponseData");
const ComponentId_1 = require("../viewBuilderTyped/VBGraphData/ComponentId");
const blueprintInfo_1 = require("./blueprintInfo");
const ClassTransformerDecorators_1 = require("../../cutil/ClassTransformerDecorators");
const class_transformer_1 = require("class-transformer");
class ViewBuilderGraph {
    constructor() {
        this.blueprints = [];
        this.data = new ViewBuilderGraphData();
        this.design = [];
        this.mappings = new ViewBuilderGraphMappings();
        this.graph = new CotTemplateResponseData_1.CotTemplateGraph();
        this.user_inputs = [];
        this.app_description = "";
    }
}
exports.ViewBuilderGraph = ViewBuilderGraph;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => blueprintInfo_1.BlueprintInfo, [])
], ViewBuilderGraph.prototype, "blueprints", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphData)
], ViewBuilderGraph.prototype, "data", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphDesign, [])
], ViewBuilderGraph.prototype, "design", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphMappings)
], ViewBuilderGraph.prototype, "mappings", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => CotTemplateResponseData_1.CotTemplateGraph)
], ViewBuilderGraph.prototype, "graph", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => UserInputs, [])
], ViewBuilderGraph.prototype, "user_inputs", void 0);
class UserInputs {
    constructor() {
        this.description = "";
        this.name = "";
        this.type = "";
        this.value = "";
        this.id = "";
    }
}
exports.UserInputs = UserInputs;
class ViewBuilderGraphData {
    constructor() {
        this.inputs = [];
        this.outputs = new ViewBuilderGraphOutputs();
        this.description_text = "";
        this.header_text = "";
        this.image_url = undefined;
        this.items = "";
        this.image_urls = "";
        this.text_body = "";
        this.audio = new ViewBuilderAudio();
        this.mapData = new MapData();
    }
}
exports.ViewBuilderGraphData = ViewBuilderGraphData;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphInput, [])
], ViewBuilderGraphData.prototype, "inputs", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphOutputs)
], ViewBuilderGraphData.prototype, "outputs", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderAudio)
], ViewBuilderGraphData.prototype, "audio", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => MapData)
], ViewBuilderGraphData.prototype, "mapData", void 0);
class MapData {
    constructor() {
        this.mapKey = "";
        this.startLocation = new LocationData();
        this.endLocation = new LocationData();
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => LocationData)
], MapData.prototype, "startLocation", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => LocationData)
], MapData.prototype, "endLocation", void 0);
class LocationData {
    constructor() {
        this.address = "";
        this.latlng = [];
    }
}
class ViewBuilderGraphInput {
    constructor() {
        this.allow_custom_value = false;
        this.components = [];
        this.default_value = undefined;
        this.description = "";
        this.id = "";
        this.inputs = [];
        this.list = false;
        this.name = "";
        this.optional = false;
        this.reason = "";
        this.type = "";
        this.value = "";
    }
}
exports.ViewBuilderGraphInput = ViewBuilderGraphInput;
class ViewBuilderGraphOutputs {
    constructor() {
        this.allow_custom_value = false;
        this.components = [];
        this.default_value = undefined;
        this.description = "";
        this.id = "";
        this.inputs = [];
        this.list = false;
        this.name = "";
        this.optional = false;
        this.reason = "";
        this.type = "";
        this.value = undefined;
    }
}
exports.ViewBuilderGraphOutputs = ViewBuilderGraphOutputs;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphComponent, [])
], ViewBuilderGraphOutputs.prototype, "components", void 0);
class ViewBuilderAudio {
    constructor() {
        this.initCreateBlobUrl = new ChainRequest_1.ChainResultInitCreateBlobUrl();
        this.textAreaValue = new ChainRequest_1.ChainResultTextAreaValue();
    }
}
exports.ViewBuilderAudio = ViewBuilderAudio;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ChainRequest_1.ChainResultInitCreateBlobUrl)
], ViewBuilderAudio.prototype, "initCreateBlobUrl", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ChainRequest_1.ChainResultTextAreaValue)
], ViewBuilderAudio.prototype, "textAreaValue", void 0);
class ViewBuilderGraphComponent {
    constructor() {
        this.allow_custom_value = false;
        this.components = [];
        this.default_value = undefined;
        this.description = "";
        this.id = "";
        this.inputs = [];
        this.list = false;
        this.name = "";
        this.optional = false;
        this.reason = "";
        this.type = "";
        this.value = undefined;
    }
}
exports.ViewBuilderGraphComponent = ViewBuilderGraphComponent;
class ViewBuilderGraphDesign {
    constructor() {
        this.section_list = [];
        this.screen_descriptions = "";
        this.screen_name = "";
        this.layout = "";
    }
}
exports.ViewBuilderGraphDesign = ViewBuilderGraphDesign;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphDesignSection, [])
], ViewBuilderGraphDesign.prototype, "section_list", void 0);
class ViewBuilderGraphDesignSection {
    constructor() {
        this.section_description = "";
        this.section_name = "";
        this.layout = "";
        this.component_list = [];
    }
}
exports.ViewBuilderGraphDesignSection = ViewBuilderGraphDesignSection;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphDesignSectionComponent, [])
], ViewBuilderGraphDesignSection.prototype, "component_list", void 0);
class ViewBuilderGraphDesignSectionComponent {
    constructor() {
        this.loading = false;
        this.component_id = "";
        this.blueprint_id = "";
        this.noUseMockData = undefined;
        this.interface_id = "";
        this.layout = "";
        // @Type(options => {
        //   const type = getVBDataType(options?.newObject?.component_id ?? "") ?? VBGraphDataBase
        //   return type;
        // })
        // 先用默认的
        this.data = [];
        // /**
        //  * 传入一个类型，会判断当前实例是否真的就是这个类型
        //  * @param cls
        //  */
        // getData<T>(cls: new (...args: any[]) => T): T | undefined {
        //   if (this.data && this.data instanceof cls) {
        //     return this.data;
        //   }
        //   return undefined;
        // }
        //
        // /**
        //  * data 可能是一个数组 同 getData
        //  * @param cls
        //  */
        // getDataArray<T>(cls: new (...args: any[]) => T): T[] | undefined {
        //   if (this.data && this.data instanceof Array) {
        //     if (this.data[0] instanceof cls) {
        //       return this.data as T[];
        //     }
        //     return [];
        //   }
        //   return undefined;
        // }
    }
}
exports.ViewBuilderGraphDesignSectionComponent = ViewBuilderGraphDesignSectionComponent;
__decorate([
    (0, class_transformer_1.Type)(options => {
        const type = (0, ComponentId_1.getVBDataType)(options?.newObject?.component_id ?? "") ?? VBGraphDataBase_1.VBGraphDataBase;
        return type;
    })
], ViewBuilderGraphDesignSectionComponent.prototype, "data", void 0);
class ViewBuilderGraphMappings {
    constructor() {
        this.component_1 = new ViewBuilderGraphComponent_1();
        this.component_2 = new ViewBuilderGraphComponent_2();
        this.component_3 = new ViewBuilderGraphComponent_3();
        this.component_4 = new ViewBuilderGraphComponent_4();
        this.component_5 = new ViewBuilderGraphComponent_5();
        this.component_6 = new ViewBuilderGraphComponent_6();
        this.component_7 = new ViewBuilderGraphComponent_7();
        this.component_8 = new ViewBuilderGraphComponent_8();
    }
}
exports.ViewBuilderGraphMappings = ViewBuilderGraphMappings;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphComponent_1)
], ViewBuilderGraphMappings.prototype, "component_1", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphComponent_2)
], ViewBuilderGraphMappings.prototype, "component_2", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphComponent_3)
], ViewBuilderGraphMappings.prototype, "component_3", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphComponent_4)
], ViewBuilderGraphMappings.prototype, "component_4", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphComponent_5)
], ViewBuilderGraphMappings.prototype, "component_5", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphComponent_6)
], ViewBuilderGraphMappings.prototype, "component_6", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphComponent_7)
], ViewBuilderGraphMappings.prototype, "component_7", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderGraphComponent_8)
], ViewBuilderGraphMappings.prototype, "component_8", void 0);
class ViewBuilderGraphComponent_1 {
    constructor() {
        this.description_text = [];
        this.header_text = [];
    }
}
exports.ViewBuilderGraphComponent_1 = ViewBuilderGraphComponent_1;
class ViewBuilderGraphComponent_2 {
    constructor() {
        this.items = [];
    }
}
exports.ViewBuilderGraphComponent_2 = ViewBuilderGraphComponent_2;
class ViewBuilderGraphComponent_3 {
    constructor() {
        this.description_text = [];
        this.header_text = [];
    }
}
exports.ViewBuilderGraphComponent_3 = ViewBuilderGraphComponent_3;
class ViewBuilderGraphComponent_4 {
    constructor() {
        this.description_text = [];
        this.header_text = [];
    }
}
exports.ViewBuilderGraphComponent_4 = ViewBuilderGraphComponent_4;
class ViewBuilderGraphComponent_5 {
    constructor() {
        this.description_text = [];
        this.header_text = [];
    }
}
exports.ViewBuilderGraphComponent_5 = ViewBuilderGraphComponent_5;
class ViewBuilderGraphComponent_6 {
    constructor() {
        this.image_urls = [];
    }
}
exports.ViewBuilderGraphComponent_6 = ViewBuilderGraphComponent_6;
class ViewBuilderGraphComponent_7 {
    constructor() {
        this.description_text = [];
        this.header_text = [];
    }
}
exports.ViewBuilderGraphComponent_7 = ViewBuilderGraphComponent_7;
class ViewBuilderGraphComponent_8 {
    constructor() {
        this.image_urls = [];
    }
}
exports.ViewBuilderGraphComponent_8 = ViewBuilderGraphComponent_8;
