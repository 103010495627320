import { signal } from "@preact/signals-react";
import { BlueprintDetails } from "imagica-corekit/dist/base/api/blueprintDetailTyped/BlueprintDetail";
import { DataloadState } from "imagica-corekit/dist/base/cutil/Dataload";
import { RestResponse } from "imagica-corekit/dist/base/cutil/RestClient";

class BlocState {
  blueprintDetailsData = signal<DataloadState<RestResponse<BlueprintDetails>>>(new DataloadState());
}

export class BlueprintDetailBloc {
  state = new BlocState();
}
