import store from "@store/index";
import { rightMenuStore } from "@uikit/store/rightMenuStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { getIt } from "@uikit/getIt";
import func from "@uikit/func";
import { Signal } from "@preact/signals-react";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { DomUtil } from "imagica-uikit/dist/base/util/DomUtil";
import { CotStore } from "@uikit/store/CotStore";

let instance: CanvasRightMenuManager;
/**
 * 内部实现，为UI组件使用的 hook
 * @returns
 */
function useCanvasRightMenuManager(config?: { showRightClickPop: Signal<boolean> }): CanvasRightMenuManager {
  const showNavPageStore = getIt(ShowNavPageStore);
  if (!instance) {
    instance = new CanvasRightMenuManager(showNavPageStore);
  }

  return instance;
}

export class CanvasRightMenuManager {
  static use = useCanvasRightMenuManager;

  store: typeof store;
  homePluginStore: HomePluginStore;
  cotStore: CotStore;

  constructor(public showNavPageStore: ShowNavPageStore) {
    this.store = store;
    this.homePluginStore = getIt(HomePluginStore);
    this.cotStore = getIt(CotStore);
  }

  // !!!保证this指向绑定到 CanvasRightMenuManager, oncontextmenu 事件会把this绑定到 window
  onContextMenu = (e: any): void => {
    /**
     * 介于次方法是挂载在document上的，只有通过如下判断
     * 只有目标元素在 Canvas 容器内才允许弹出右键菜单
     */
    if (!DomUtil.hasAttr(e.target, "data-creator", "Canvas")) {
      return;
    }
    this.onMenuCancel();

    if (
      document.activeElement?.nodeName === "TEXTAREA" ||
      document.activeElement?.nodeName === "INPUT" ||
      previewStore.state.isClickAddToContent
    ) {
      return;
    }
    if (this.cotStore.state.showCot === true || this.cotStore.state.oldCotAnimationState.creating === true) return;
    if (
      this.showNavPageStore.state.showNavPage ||
      // homePluginState.isShare ||
      this.homePluginStore.state.isShare ||
      !func.isEmpty(store.getState().editor.currentGuideTitlePage)
    ) {
      return;
    }
    e.preventDefault(); //取消默认的浏览器自带右键
    rightMenuStore.setCustomFuncRightContextMenu({});
    rightMenuStore.setEdgeRight(false);
    rightMenuStore.setNodeRight(false);

    this.showRightPopView();
    this.setupKeydown();
  };

  onMenuCancel = (): void => {
    this.unloadKeydown();
    this.hiddenRightPopView();
  };

  onEscRightMenu = (e: any): void => {
    if (e.code === "Escape") {
      this.onMenuCancel();
    }
  };

  showRightPopView(): void {
    // eslint-disable-next-line
    const evt = window.event || arguments[0];
    const menu = this.getRightMenuElement(); //获取右键菜单
    const container = this.getHomeElement(); //获取区域
    if (!menu || !container) {
      return;
    }
    /*获取当前鼠标右键按下后的位置，据此定义菜单显示的位置*/
    const rightEdge = container.clientWidth - evt.clientX;
    const bottomEdge = container.clientHeight - evt.clientY;
    /*如果从鼠标位置到容器右边的空间小于菜单的宽度，就定位菜单的左坐标（Left）为当前鼠标位置向左一个菜单宽度*/
    if (rightEdge < menu.offsetWidth) {
      menu.style.left = container.scrollLeft + evt.clientX - menu.offsetWidth + "px";
    } else {
      /*否则，就定位菜单的左坐标为当前鼠标位置*/
      menu.style.left = container.scrollLeft + evt.clientX + "px";
    }
    /*如果从鼠标位置到容器下边的空间小于菜单的高度，就定位菜单的上坐标（Top）为当前鼠标位置向上一个菜单高度*/
    if (bottomEdge < menu.offsetHeight) {
      menu.style.top = container.scrollTop + evt.clientY - menu.offsetHeight + "px";
    } else {
      /*否则，就定位菜单的上坐标为当前鼠标位置*/
      menu.style.top = container.scrollTop + evt.clientY + "px";
    }

    /*设置菜单可见*/
    menu.style.visibility = "visible";
    homeToolBarStore.setAllowZoomOrPan(false);
    rightMenuStore.setShowRightClickPop(true);
  }

  hiddenRightPopView(): void {
    const rightMenu = this.getRightMenuElement();
    if (rightMenu) {
      rightMenu.style.visibility = "hidden";
    }

    homeToolBarStore.setAllowZoomOrPan(true);
    rightMenuStore.setShowRightClickPop(false);
  }

  getHomeElement(): HTMLElement | null {
    const element = creatorRefStore.homeRef.current;
    return element || document.getElementById("HomeRoot");
  }
  getRightMenuElement(): HTMLElement | null {
    const element = creatorRefStore.rightMenuRef.current;
    return element || document.getElementById("RightClickPopView");
  }

  // event
  setupContextMenu = (): void => {
    document.addEventListener("contextmenu", this.onContextMenu);
  };
  setupKeydown = (): void => {
    document.addEventListener("keydown", this.onEscRightMenu);
  };

  unloadContextMenu = (): void => {
    document.removeEventListener("contextmenu", this.onContextMenu);
  };
  unloadKeydown = (): void => {
    document.removeEventListener("keydown", this.onEscRightMenu);
  };
}
