import { settings } from "imagica-corekit/dist/base/kernel/Settings";

const agentOptions = [
  {
    value: "make a call",
    label: "Make a call",
    img: `${settings.S3CDN}${settings.viewAssetsPath}call.svg`,
  },
  {
    value: "whatsapp",
    label: "Chat over WhatsApp",
    disabled: true,
    img: `${settings.S3CDN}${settings.viewAssetsPath}whatsapp.svg`,
  },
  {
    value: "slack",
    label: "Chat over Slack",
    disabled: true,
    img: `${settings.S3CDN}${settings.viewAssetsPath}slack.svg`,
  },
  {
    value: "do research online",
    label: "Browse internet",
    img: `${settings.S3CDN}${settings.viewAssetsPath}internet.svg`,
  },
];

export default agentOptions;
