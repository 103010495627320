import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Card, Input, Spin } from "antd";
import { EyeInvisibleOutlined, EyeOutlined, LoadingOutlined, PlayCircleFilled, SaveOutlined } from "@ant-design/icons";
import func from "@uikit/func";

import { studioActions } from "../store/studio";
import { fotActions } from "../store/fot";
import { Api } from "../uikit/service/api";

const { TextArea } = Input;

const StudioPromptCard = () => {
  const dispatch = useDispatch();
  const api = new Api(false);

  // store states
  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);

  const prompt = useSelector(state => state.studio.prompt);
  const setPrompt = val => {
    dispatch(studioActions.setPrompt(val));
  };

  const setVariables = val => {
    dispatch(studioActions.setVariables(val));
  };

  const loadingState = useSelector(state => state.studio.loadingState);
  const setLoadingState = val => {
    dispatch(studioActions.setLoadingState(val));
  };

  const saveAsExistingTemplate = useSelector(state => state.studio.saveAsExistingTemplate);
  const setSaveAsExistingTemplate = val => {
    dispatch(studioActions.setSaveAsExistingTemplate(val));
  };

  // local states
  const [autoSaving, setAutoSaving] = useState(false);
  const [rawResponse, setRawResponse] = useState("");
  const [showRawResponse, setShowRawResponse] = useState(false);

  // methods
  const handlePromptChange = e => {
    setPrompt(e.target.value);
  };

  const handleClickRun = () => {
    runPrompt();
  };

  const handleSaveAsExistingTemplate = () => {
    setSaveAsExistingTemplate(true);
  };

  const toggleShowRaw = () => {
    setShowRawResponse(!showRawResponse);
  };

  const handlePromptBlur = () => {
    setAutoSaving(true);
    setSaveAsExistingTemplate(true);
  };

  const runPrompt = () => {
    dispatch(fotActions.setFetching(true));
    setLoadingState({ promptRunBtn: true, promptCard: true, variableCard: true });
    const data = {
      template_name: selectedTemplate.name,
      prompt,
    };
    api
      .post(`/be/bas-demo-v4/brain_studios/run_prompt`, data)
      .then(response => response.json())
      .then(data => {
        const variablesObj = data[0];
        setRawResponse(variablesObj.raw_GPT3_response);
        delete variablesObj.raw_GPT3_response;
        setVariables(variablesObj);
      })
      .catch(error => {
        func.messageError(error);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({ promptRunBtn: false, promptCard: false, variableCard: false });
      });
  };

  useEffect(() => {
    if (!saveAsExistingTemplate) {
      setAutoSaving(false);
    }
  }, [saveAsExistingTemplate]);

  useEffect(() => {
    setRawResponse("");
    setShowRawResponse(false);
  }, [selectedTemplate.name]);

  return (
    <Card
      className="Studio-Prompt-Card shadow"
      title="Prompt"
      bordered={false}
      actions={[
        <Button
          icon={<SaveOutlined />}
          onClick={handleSaveAsExistingTemplate}
          loading={loadingState.promptSaveBtn}
          disabled={!selectedTemplate.template_name}
        >
          Save
        </Button>,
        <Button
          type="primary"
          icon={<PlayCircleFilled />}
          onClick={handleClickRun}
          loading={loadingState.promptRunBtn}
          disabled={!selectedTemplate.template_name}
        >
          Run Prompt
        </Button>,
      ]}
    >
      {autoSaving ? (
        <div className="description">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 14, marginRight: "15px" }} spin />} />
          Auto saving...
        </div>
      ) : (
        ""
      )}
      <Spin tip="Loading..." spinning={!autoSaving && loadingState.promptCard}>
        <TextArea
          autoSize
          className="Studio-Prompt-Text"
          value={prompt}
          onChange={handlePromptChange}
          onBlur={handlePromptBlur}
          disabled={!selectedTemplate.template_name}
        />
        {rawResponse ? (
          showRawResponse ? (
            <Button
              type="link"
              style={{ marginTop: "25px", marginBottom: "20px" }}
              onClick={toggleShowRaw}
              icon={<EyeInvisibleOutlined />}
            >
              Hide
            </Button>
          ) : (
            <Button
              type="link"
              style={{ marginTop: "25px", marginBottom: "20px" }}
              onClick={toggleShowRaw}
              icon={<EyeOutlined />}
            >
              Raw Response
            </Button>
          )
        ) : (
          ""
        )}
        {showRawResponse ? (
          <TextArea autoSize className="Studio-Prompt-Text Studio-Prompt-Raw" value={rawResponse} disabled />
        ) : (
          ""
        )}
      </Spin>
    </Card>
  );
};

export default StudioPromptCard;
