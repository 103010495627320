import { Api } from "./api";

export interface PromiseAbort<T> extends Promise<T> {
  cancel?(): void;
}

type ReturnPromiseHandler = (req: PromiseAbort<any> | null) => void;

/**
 * 原来 usePostUrlCanAbort
 */
export class PostUrlCanAbortService {
  private api = new Api(false);
  private getApi: PromiseAbort<Response> | null = null;
  private postApi: PromiseAbort<Response> | null = null;

  postData = (
    url: any,
    data: any,
    abort = true,
    returnPromise: ReturnPromiseHandler = (): void => {}
  ): PromiseAbort<Record<string, any>> => {
    if (abort) {
      this.postApi?.cancel?.();
    }
    // setLoading(true);
    this.postApi = this.api.postWithErrorCanAbort(url, data);
    returnPromise(this.postApi);
    return this.postApi;
    //   .then((res: any) => {
    //     return res;
    //   })
    //   .catch((error: any) => {
    //     // console.log('postData', error)
    //     return Promise.reject(error);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  /**
   * post请求， 支持返回 event-stream
   * @param url
   * @param data
   * @param onMessage 接收到数据的处理方法
   * @param abort
   * @returns {*}
   */
  postWithStreaming = (
    url: any,
    data: Record<string, any>,
    onMessage: (arg0: any) => void,
    abort = true,
    returnPromise: ReturnPromiseHandler = (): void => {},
    onDone: (args: string) => void = (): void => {}
  ): Promise<Response | undefined> => {
    if (abort) {
      this.postApi?.cancel?.();
    }
    // setLoading(true);
    this.postApi = this.api.postCanAbort(url, data);
    returnPromise(this.postApi);
    let allDataStr = "";
    return this.postApi.then(async res => {
      if (!data.stream) {
        const result = await res.json();
        onMessage(result);
        return;
      }
      const reader = res.body?.getReader();
      const reg = /data: (\{.*\})\s/g;
      const writeMessage = (temp: string): string => {
        let index = 0;
        do {
          const msg = reg.exec(temp);
          if (reg.lastIndex > 0) {
            index = reg.lastIndex;
            onMessage(JSON.parse((msg || [])[1]));
          }
        } while (reg.lastIndex > 0);
        return temp.substring(index);
      };
      const decoder = new TextDecoder();
      const readFun = (start: string): Promise<void> | undefined => {
        return reader?.read().then(async ({ done, value }) => {
          const currStr = decoder.decode(value);
          allDataStr += currStr;
          if (done) {
            if (onDone) onDone(allDataStr);
            return;
          }
          const temp = writeMessage(start + decoder.decode(value));
          await readFun(temp);
        });
      };
      await readFun("");

      return res;
    });
    //   .catch((error: any) => {
    //     // console.error('postWithStreaming', error)
    //     return Promise.reject(error);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  getWithStreaming = (
    url: any,
    onMessage: (arg0: any) => void,
    onDone: (arg0: string) => void,
    abort = true,
    returnPromise: ReturnPromiseHandler = (): void => {}
  ): Promise<Response> => {
    if (abort) {
      this.getApi?.cancel?.();
    }
    this.getApi = this.api.getCanAbort(url);
    returnPromise(this.getApi);
    let allDataStr = "";
    return this.getApi
      .then(async res => {
        const reader = res.body?.getReader();

        const reg = /data: (\{.*\})\s/g;
        const writeMessage = (temp: string): string => {
          let index = 0;
          do {
            const msg = reg.exec(temp);
            if (reg.lastIndex > 0) {
              index = reg.lastIndex;
              onMessage(JSON.parse((msg || [])[1]));
            }
          } while (reg.lastIndex > 0);
          return temp.substring(index);
        };

        const decoder = new TextDecoder();
        const readFun = (start: string): Promise<void> | undefined => {
          return reader?.read().then(async ({ done, value }) => {
            const currStr = decoder.decode(value);
            allDataStr += currStr;
            if (done) {
              onDone(allDataStr);
              return;
            }
            const temp = writeMessage(start + currStr);
            await readFun(temp);
          });
        };
        await readFun("");

        return res;
      })
      .catch((error: any) => {
        // console.error('getWithStreaming', error)
        return Promise.reject(error);
      });
  };
}
