import { Input } from "antd";
import { ReactNode } from "react";
import { ModalInputProps } from "./types";
import style from "./AModal.module.css";

const { TextArea } = Input;

export function ModalInput(props: ModalInputProps) {
  const { require, value, type, placeholder, onChangeText, onPressEnterKey } = props;

  if (require === false) {
    return null;
  }

  if (type === "input") {
    return (
      <TextArea
        className={style["modal-input"]}
        value={value}
        autoSize={{ minRows: 1, maxRows: 4 }}
        placeholder={placeholder}
        onChange={onChangeText}
        onPressEnter={onPressEnterKey}
      />
    );
  }

  return (
    <TextArea
      className={style["modal-textarea"]}
      rows={4}
      value={value}
      placeholder={placeholder}
      onChange={onChangeText}
    />
  );
}

export function ModalIcon(props: { icon: ReactNode | null }) {
  const { icon } = props;

  if (icon === null) {
    return icon;
  }

  return <div className={style["modal-icon"]}>{icon}</div>;
}
