import { WifiOutlined } from "@ant-design/icons";
import { Badge, Button } from "antd";
import cls from "classnames";
import { chain, findIndex, get, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { delTopic, pullMsg } from "../../../../uikit/service/websocket";
import { editorActions } from "../../../../store/editor";
import func from "@uikit/func";
import styles from "./AgentNode.module.css";
import HighlightLinks from "./HighlightLinks";
import ProgressButton from "./ProgressButton";
import { MessageCenter } from "imagica-corekit/dist/base/kernel/MessageCenter";
import { getIt } from "../../../../uikit/getIt";
import { api } from "../../../../views/thinking-layout-editor/utils/InstanceAPI";

const needToKeepEvents = ["on_llm_start", "on_agent_finish", "on_agent_action", "on_tool_start", "on_tool_end"];

const keywordsMapping = {
  "on_chain_start LLMChain": "Thinking..",
  "Action: on_llm_start webagent": "Reading the internet",
  "Action: linkedin_search": "Searching on LinkedIn",
  "Action: google_search": "Searching on Google",
  "Action: twitter_search": "Searching on Twitter",
  Question: "Asking myself a question",
  Thought: "I'm thinking",
  "Action Input": "Searching content below",
};

const keywords = Object.keys(keywordsMapping);

const DoResearchOnline = ({ noWheelClassName, AIDesc, nodeWSKey }) => {
  const scrollBoxRef = useRef(null);
  const messageCenter = getIt(MessageCenter);

  const dispatch = useDispatch();

  const chatBoxInnerClassName = cls(styles.chatBoxInner, noWheelClassName, "nodrag");

  const userId = useSelector(state => state.editor.me.id);
  // const websocketData = useSelector((state) => state.editor.researchAgentWebsocketData[nodeWSKey] || []);
  const websocketData = useSelector(state => state.editor.researchAgentWebsocketData);

  const setWSData = res => {
    dispatch(editorActions.setResearchAgentWebsocketData(res));
  };

  const setTopicData = res => {
    dispatch(editorActions.setResearchAgentWebsocketData({ data: res, key: res.key.topic }));
  };

  const developerMode = useSelector(state => state.fot.developerMode);
  const isConnected = useSelector(state => state.editor.socket.isConnected);
  const experimental = useSelector(state => state.fot.enableExperimentalFeature);
  const disableAddPreview = useSelector(state => state.fot.disableAddPreview);

  const [proceedLoading, setProceedLoading] = useState(false);
  const [endResult, setEndResult] = useState("");

  const getWSTopic = () => {
    return `/studios/${userId}/wsuid_${nodeWSKey}/ask_brain_v4`;
  };

  const handleClickProceed = () => {
    setProceedLoading(true);
    setEndResult("");
    setWSData({ key: getWSTopic(), clearAll: true });
    // assemble topic
    const topic = getWSTopic();
    // start listening to topic
    messageCenter.on(topic, setTopicData);
    api
      .getWithError(
        `/be/langchain/v4/ask_brain?query=${AIDesc}&developerMode=${developerMode}&experimental=${experimental}&pubsub_topic=${topic}`
      )
      .then(res => {
        if (typeof res.data === "object") {
          res.data = res.data?.message;
        }
        setEndResult(res.data);
      })
      .catch(error => {
        console.error(error);
        setEndResult(error.message);
        func.messageError(error);
      })
      .finally(_ => {
        setProceedLoading(false);
        // stop listening to topic
        // delTopic(topic);
        messageCenter.off(topic);
      });
  };

  useEffect(() => {
    setWSData({ key: getWSTopic(), clearAll: true });
    messageCenter.on("askBrain", setTopicData);
  }, []);

  const getUsefulData = () => {
    const topic = getWSTopic();
    const usefulData = chain(get(websocketData, topic, []))
      .filter(item => needToKeepEvents.includes(item.event))
      .uniqWith((a, b) => {
        if (a.event === b.event) {
          if (a.event === "on_tool_start" || a.event === "on_tool_end") {
            return a.run_id === b.run_id;
          }
          if (a.event === "on_agent_finish") {
            return a.message === b.message;
          }
        }
      })
      .value();
    return usefulData.map(({ event, message = "", run_id }, index, array) => {
      const isLast = endResult ? false : index === array.length - 1;
      switch (event) {
        case "on_llm_start":
          return (
            <p key={index}>
              <ProgressButton key={index} isLast={isLast}>
                {message}
              </ProgressButton>
            </p>
          );
        case "on_tool_start":
          const thisIndex = findIndex(array, item => item.event === "on_tool_end" && item.run_id === run_id);
          return (
            <p key={index}>
              <ProgressButton key={index} isLast={isLast} loading={thisIndex === -1}>
                {message}
              </ProgressButton>
            </p>
          );
        case "on_agent_finish":
          return (
            <p key={index}>
              <HighlightLinks text={message} />
            </p>
          );
        case "on_agent_action":
          const msgList = message.split("\n");
          return map(msgList, (msg, i) => {
            const [, key, value] = /(.*):\s(.*)/.exec(msg) || [];
            if (keywords.includes(key)) {
              return (
                <p style={{ marginBottom: 0 }} key={i}>
                  <ProgressButton isLast={isLast}>{keywordsMapping[key]}</ProgressButton>
                  <br />
                  <HighlightLinks text={value} />
                </p>
              );
            } else if (msg) {
              return (
                <p key={i}>
                  <HighlightLinks text={msg} />
                </p>
              );
            }
          });
        default:
          return null;
      }
    });
  };

  const handleToScrollBottom = () => {
    scrollBoxRef.current?.scrollTo({
      top: scrollBoxRef.current?.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    handleToScrollBottom();
  }, [scrollBoxRef.current?.scrollHeight]);

  useEffect(() => {
    if (endResult) {
      handleToScrollBottom();
    }
  }, [endResult]);

  return (
    <div className={styles.form}>
      <Button
        loading={proceedLoading}
        disabled={disableAddPreview === false}
        onClick={handleClickProceed}
        type="primary"
        className={styles.makeACall}
      >
        Proceed
      </Button>
      <div className={styles.chatBox}>
        <div className={chatBoxInnerClassName} ref={scrollBoxRef}>
          <div className={styles["socketIndicator"]}>
            <Badge count={<WifiOutlined style={{ color: isConnected ? "#52c41a" : "#f5222d" }} />} />
          </div>
          {getUsefulData()}
          {!func.isEmpty(endResult) ? (
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                marginBottom: 0,
                paddingBottom: 5,
              }}
            >
              Result:{" "}
            </p>
          ) : null}
          <p>
            <HighlightLinks text={endResult} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default DoResearchOnline;
