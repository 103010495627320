"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JDShoppingFunction = void 0;
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const SearchInfoWrapper_1 = require("../../storyV2/model/SearchInfoWrapper");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
class JDShoppingFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo, variableList = [], page = 1) {
        const streamResponse = await this.socketRestClient.requestRest(topic => {
            return this.brainClient.openAi.getJdShoppingBase(input, page, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo), topic);
        }, result => {
            const res = result.data?.data;
            const pagination = new SearchInfoWrapper_1.SearchInfoPagination(res?.page.currIndex || page, res?.page.totalPage);
            const searchInfoWrapper = new SearchInfoWrapper_1.SearchInfoWrapper(input, pagination, res?.items || [], res?.source);
            return new FunctionBase_1.FuncDataSource(FunctionBase_1.FuncDataSourceType.JD_SHOPPING, searchInfoWrapper);
        }, "", identityInfo);
        // const res = await this.getJdShoppingInfo(input, page, FunctionUtil.createAbortUuid(identityInfo));
        // const pagination = new SearchInfoPagination(res?.page.currIndex || page, res?.page.totalPage);
        // const searchInfoWrapper = new SearchInfoWrapper(input, pagination, res?.items || [], res?.source);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.JD_SHOPPING, streamResponse);
    }
}
exports.JDShoppingFunction = JDShoppingFunction;
