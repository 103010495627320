"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateNodeResponseOutputNodes = exports.InputNode = exports.UpdateNodeResponse = void 0;
const class_transformer_1 = require("class-transformer");
class UpdateNodeResponse {
    constructor() {
        this.input_nodes = [];
        this.node_descriptions = "";
        this.output_nodes = new UpdateNodeResponseOutputNodes();
    }
}
exports.UpdateNodeResponse = UpdateNodeResponse;
__decorate([
    (0, class_transformer_1.Type)(() => InputNode)
], UpdateNodeResponse.prototype, "input_nodes", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => UpdateNodeResponseOutputNodes)
], UpdateNodeResponse.prototype, "output_nodes", void 0);
class InputNode {
    constructor() {
        this.intermediate = undefined;
        this.node_id = "";
        this.node_label = "";
        this.node_placeholder = "";
        this.node_value = "";
    }
}
exports.InputNode = InputNode;
class UpdateNodeResponseOutputNodes {
    constructor() {
        this.node_description = "";
        this.node_id = "";
        this.node_label = "";
    }
}
exports.UpdateNodeResponseOutputNodes = UpdateNodeResponseOutputNodes;
