import React, { useEffect, useMemo, useState } from "react";
import style from "./addTooltip.module.scss";
import { useNodePreview, useStartNode } from "@custom-hooks/useNodePreview";
import { Action } from "@uiview/views/NodeToolBar/Action";
import { PreviewAppUtil } from "@uiview/views/PreviewApp/PreviewAppUtil";
import { previewStore } from "@uiview/store/PreviewStore";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";

const groupReg = /^group/;
export const ADD_TO_CONTENT_FLAG = "ADD_TO_CONTENT_FLAG";

interface IProps {
  hasAdded: boolean;
  id: string;
  data: any;
  handlePreviewDeleteNode: any;
  clickToPreviewNode: any;
}

export const AddNodeConfig = (
  hasAdded: boolean,
  isInput: boolean
): {
  text: string;
  iconClass: string;
} => {
  const config = {
    text: "",
    iconClass: "",
  };
  config.text = hasAdded ? "Remove from preview" : isInput ? "Publish as Input" : "Publish as Content";
  config.iconClass = hasAdded ? "tool-bar-item-sta-remove" : "tool-bar-item-sta-add";
  return config;
};

const AddToolTip: React.FC<IProps> = props => {
  const { id, handlePreviewDeleteNode, clickToPreviewNode } = props;
  const creatorNodesStore = getIt(CreatorNodesStore);
  const homeHandleFieldMethods = getIt(HomeHandleFieldMethods);
  const previewComposeState = getIt(PreviewComposeState);
  const [hasAdded, setHasAdded] = useState(false);
  const previewState = useImagicaStore(previewStore).value;
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const appStates = useMemo(() => {
    return previewComposeState.appStates();
  }, [previewState.previewAppAcivieId, saasUIData]);
  const { isStartNode, isStartNodeAdded } = useStartNode(id);
  const { handlePreview } = useNodePreview({ id, handlePreviewDeleteNode, clickToPreviewNode });
  const addConfig = AddNodeConfig(hasAdded, previewState.isAddInputNode);
  const disabledChatAI = useMemo(() => {
    return PreviewAppUtil.needDisableSendToAppBtn({
      isChat: appStates.isChatApp,
      nodeId: id,
      isStartNode: !!isStartNode,
      nodes: creatorNodesStore.getNodes(),
    });
  }, [appStates.isChatApp, id, isStartNode]);

  useEffect(() => {
    /**
     * 判断触发的是添加input，还是output
     */
    if (previewState.isAddInputNode) {
      if (isStartNode) {
        setHasAdded(!!isStartNodeAdded);
      }
    } else if (previewState.isAddOutputNode) {
      if (groupReg.test(id)) {
        const thoughtsArr = saasUIData.output.map((x: any) => x?.groupId);
        setHasAdded(thoughtsArr.includes(id));
      } else {
        let thoughtsArr = [];
        thoughtsArr = saasUIData.output
          .filter((x: any) => !x?.groupId)
          .map((x: any) => (x?.results || []).map((r: any) => r.nodeId))
          ?.flat(Infinity);
        setHasAdded(thoughtsArr.includes(id));
      }
    }
  }, [
    id,
    previewState.isAddInputNode,
    previewState.isAddOutputNode,
    isStartNode,
    isStartNodeAdded,
    saasUIData.output,
    saasUIData.output.length,
  ]);

  // 添加完，往按钮位置滚动
  const scrollView = (): void => {
    document
      .querySelector(`div[addcontent-id="${previewState.addContentId}"]`)
      ?.scrollIntoView({ block: "nearest", inline: "nearest", behavior: "smooth" });
  };

  const clickSendToApp = (): void => {
    /**
     * 如果isAddInputNode === true, 执行添加或移除input操作
     * 如果isAddOutputNode === true, 执行添加或移除到output操作
     */
    if (previewStore.state.isAddInputNode) {
      const nodes = creatorNodesStore.getNodes();
      const node = nodes.find((item: any) => item.id === id);
      homeHandleFieldMethods.addOrRemoveInputFeild(node);
      scrollView();
      return;
    }
    if (previewStore.state.isAddOutputNode) {
      handlePreview();
      scrollView();
      return;
    }
  };

  const itemStyle = { "--back-color": hasAdded ? "#FF335C" : "#0A9DFF" } as any;

  if (appStates.isChatApp) {
    // 不显示
    if (disabledChatAI) {
      return null;
    }
    //  如果是添加output，则不显示input的tooltip
    if (isStartNode && previewState.isAddOutputNode) {
      return null;
    }
    return (
      <Action
        data-click={ADD_TO_CONTENT_FLAG}
        className={style["tool-bar-item"]}
        tipText={<p className={style["tool-tip"]}>{addConfig.text}</p>}
        disabled={disabledChatAI}
        onClick={clickSendToApp}
        added={hasAdded}
      />
    );
  }

  return (
    <div
      data-click={ADD_TO_CONTENT_FLAG}
      className={`${style["tool-bar-item"]}`}
      style={itemStyle}
      onClick={clickSendToApp}
    >
      <p
        className={`${style["tool-bar-item-republic"]} ${style["tool-bar-item-sta"]} ${style[addConfig.iconClass]}`}
      ></p>
      <p className={style["tool-tip"]}>{addConfig.text}</p>
    </div>
  );
};

export default AddToolTip;
