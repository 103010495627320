import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ThoughtInput from "./ThoughtInput";

import plus from "../media/plus.png";
import "../views-css/CardFocus.css";
import { fotActions } from "../store/fot";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

function CardFocus(props) {
  const dispatch = useDispatch();

  const mode = useSelector(state => state.fot.mode);
  const setChainThoughtsState = val => {
    dispatch(fotActions.setChainThoughtsState(val));
  };

  return (
    <article className="CardFocus">
      {props.card.type == "thought" ? (
        mode !== "studioMode" ? (
          <section className="CardHomeThought">
            <p>{props.card.content}</p>
            {props.thoughtChain.length > 0 ? (
              <div className="Chain-Thought-Icon" onClick={() => setChainThoughtsState(true)}>
                <span>{props.thoughtChain.length}</span>
                <img src={`${settings.S3CDN}${settings.viewAssetsPath}down-arrow.svg`} />
              </div>
            ) : null}
          </section>
        ) : (
          <ThoughtInput />
        )
      ) : props.card.type == "video" ? (
        <section className="CardHomeVideo">
          <iframe src={props.card.content} />
        </section>
      ) : props.card.type == "image" ? (
        <section className="CardHomeImage">{mode === "studioMode" ? <img src={props.card.content} /> : null}</section>
      ) : props.card.type == "shopping" ? (
        <section className="CardHomeShopping">
          {mode === "studioMode" ? <img src={props.card.content.image} /> : null}
          <article>
            <p className="shoppingTitle">{props.card.content.title}</p>
          </article>
        </section>
      ) : props.card.type == "info" ? (
        <section className={props.card.content.platform == "" ? "CardHomeInfo CardHomeInfo1col" : "CardHomeInfo"}>
          <p>{props.card.content.title}</p>
          <h4 className={props.card.content.platform == "" ? "Hidden" : ""}>{props.card.content.platform}</h4>
        </section>
      ) : props.card.type == "travel" ? (
        <section className="CardHomeTravel">
          <h3>{props.card.content.city}</h3>
          <h4>{props.card.content.time}</h4>
          <article>
            <p>{props.card.content.reason}</p>
          </article>
        </section>
      ) : props.card.type == "task" ? (
        <section className="CardHomeTask">
          <p>{props.card.content}</p>
          {mode === "studioMode" ? <img src={plus} /> : null}
        </section>
      ) : props.card.type == "list" ? (
        <section className="CardFocusList">
          <p>{props.card.content}</p>
        </section>
      ) : props.card.type == "flight" ? (
        <section className="CardHomeFlight">
          <article className="FlightCities">
            <b>{props.card.content.from}</b>
            <b>&#x2195;</b>
            <b>{props.card.content.to}</b>
          </article>
          <article className="FlightTimes">
            <p>{props.card.content.departure}</p>
            <p>{props.card.content.arrival}</p>
          </article>
          <article className="FlightPrice">
            <i>${props.card.content.price}</i>
          </article>
        </section>
      ) : props.card.type == "outfit" ? (
        <section className="CardHomeOutfit CardFocusOutfit">
          {mode === "studioMode" ? <img src={props.card.content.image} /> : null}
          <h4>{props.card.content.name}</h4>
          <p>{props.card.content.price}</p>
        </section>
      ) : props.card.type == "document" ? (
        <section className="CardHomeThought">
          <p>{props.card.thought}</p>
        </section>
      ) : props.card.type == "food" ? (
        <section className="CardHomeFood CardFocusFood">
          {mode === "studioMode" ? <img src={props.card.content.image} /> : null}
          <h4>{props.card.content.dish}</h4>
          <p>{props.card.content.restaurant}</p>
        </section>
      ) : (
        <section className="CardHomeThought">
          <p>{props.card.content}</p>
        </section>
      )}
    </article>
  );
}

export default CardFocus;
