import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { isEmpty } from "lodash";
import { GlobalResponse } from "imagica-corekit/dist/base/cutil/GlobalClient";
import { ApiResponse } from "@uiview/pages/customApi/CustomApiPageBloc";

/**
 * handleResponseStatusData 和 handleGlobalResponseStatusData 其中就多了一个 clone 调用
 */
export class ResponseDataUtil {
  static async handleGlobalResponseStatusData(
    globalResponse: GlobalResponse | null,
    start: number
  ): Promise<null | ApiResponse> {
    // https://github.com/brain/micro-apps/commit/fdfa8ba6500cad7dc1f13f225b5bbbe391b63dcd
    if (!globalResponse) {
      return null;
    }
    const res = globalResponse.response;
    if (!res) return null;

    const end = Date.now();
    const diff = end - start;
    const size = res.headers.get("content-length");
    const contentType = res.headers.get("content-type");
    const responseHeaders = Object.fromEntries(res.headers.entries());
    let data: { type: string; value: any } = { type: "", value: "" };
    switch (true) {
      case contentType?.includes("image"):
        data.value = await ResponseDataUtil.getImageData(res);
        data.type = "image";
        break;
      case contentType?.includes("json"):
        try {
          data.value = await globalResponse.json();
          data.type = "json";
        } catch (_) {
          data.value = await globalResponse.text();
          data.type = "text";
        }
        break;
      case contentType?.includes("html"):
        data.value = await ResponseDataUtil.decoderToText(res.body);
        data.type = "html";
        break;
      default: {
        data.value = await ResponseDataUtil.decoderToText(res.body);
        let finalRes = data.value;
        try {
          finalRes = JSON.parse(data.value);
          // eslint-disable-next-line
        } catch (error) {}
        data = {
          type: "text",
          value: finalRes,
        };
      }
    }
    return {
      status: Number(res.status),
      statusText: res.statusText,
      time: `${(diff / 1000).toFixed(2)} s`,
      size: ResponseDataUtil.humanizeBytes(Number(size)),
      data,
      responseHeaders,
    };
  }

  static async handleResponseStatusData(res: Response | null, start: number): Promise<null | ApiResponse> {
    if (!res) return null;
    const end = Date.now();
    const diff = end - start;
    const size = res.headers.get("content-length");
    const contentType = res.headers.get("content-type");
    const responseHeaders = Object.fromEntries(res.headers.entries());
    let data: { type: string; value: any } = { type: "", value: "" };
    let value = "";

    switch (true) {
      case contentType?.includes("image"):
        value = await ResponseDataUtil.getImageData(res);
        data = {
          type: "image",
          value,
        };
        break;
      case contentType?.includes("json"):
        try {
          // https://github.com/brain/micro-apps/commit/2707a3839812a4f294530ebf4f1b7076d22fe5eb#diff-57fdbf9e9e518531a3425a964dd67298e0240ddfea15189ea523bc7e1a4aef83
          value = await res.clone().json();
          data = {
            type: "json",
            value,
          };
        } catch (_) {
          value = await res.text();
          data = {
            type: "text",
            value,
          };
        }
        break;
      case contentType?.includes("html"):
        value = await ResponseDataUtil.decoderToText(res.body);
        data = {
          type: "html",
          value,
        };
        break;
      default: {
        value = await ResponseDataUtil.decoderToText(res.body);
        let finalRes = value;
        try {
          finalRes = JSON.parse(value);
          // eslint-disable-next-line
        } catch (error) {}
        data = {
          type: "text",
          value: finalRes,
        };
      }
    }

    return {
      status: res.status,
      statusText: res.statusText,
      time: `${(diff / 1000).toFixed(2)} s`,
      size: ResponseDataUtil.humanizeBytes(Number(size)),
      data,
      responseHeaders,
    };
  }

  static humanizeBytes(bytes: number): string {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const size: number = parseFloat((bytes / Math.pow(k, i)).toFixed(2))!; // Non-null assertion
    return `${size} ${sizes[i]}`;
  }

  static async getImageData(res: Response): Promise<string> {
    const s3Path = res.headers.get("X-Brain-S3-Path") || "";
    let value = "";
    if (!isEmpty(s3Path)) {
      // TODO: settings 需要被移除，不该出现在 util 中
      value = `${settings.S3CDN}${encodeURI(s3Path)}`;
    } else {
      const blob = await res.blob();
      value = URL.createObjectURL(blob);
      URL.revokeObjectURL(value);
    }
    return value;
  }

  static async decoderToText(res: ReadableStream<Uint8Array> | null): Promise<string> {
    const reader = res?.getReader();

    // from customApiPageBloc
    if (!reader) {
      // Handle the case where the reader is null or undefined
      return "";
    }

    const result = await reader.read();
    const decoder = new TextDecoder();
    return decoder.decode(result.value);
  }
}
