import { getIt } from "@uikit/getIt";
import { useAISaasContainer } from "@uiview/views/AISaas/container";
import { NodeTitleUtil } from "@uiview/views/Nodes/NodeTitleUtil";
import { PreviewOutputItemRoot } from "@uiview/views/PreviewApp/PreviewOutputItemRoot/PreviewOutputItemRoot";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import classNames from "classnames";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { useStore as useImagicaStore, useStore } from "imagica-uikit/dist/hooks/useStore";
import { AiSaasOutputProvider, useAiSaasOutputContainer } from "..";
import { AIViewBuilder } from "../../AIViewBuilder/AIViewBuilder";
import css from "./index.module.scss";

const homeStore = getIt(HomeStore);

function AiSaasOutputListInner(): JSX.Element {
  const { dndThoughts, className } = useAiSaasOutputContainer();
  const { outputListRef } = useAISaasContainer();
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const store = getIt(PublishSubscriptionStore);
  const state = useStore(store).value;
  /**
   * 默认loading为ture，即默认情况先隐藏output
   * loading结束才获取到完整数据，再进行以下判断
   * static下付费APP且没有付费才隐藏
   */
  const hideOutputNode = (): boolean => {
    if (saasUIData?.staticMode) {
      if (state.loading) {
        return true;
      } else {
        return state.isPaidApp && !state.isPay;
      }
    }
    return false;
  };

  return (
    <div className={css["moddle-box"]}>
      <div className={css["max-width-box"]}>
        <div className={css["output-list"]} ref={outputListRef}>
          <div className={classNames(css["AiSaasOutputList"], className, "globalAiSaasOutputList")}>
            {!hideOutputNode() &&
              dndThoughts.map(item => (
                <div className={css["groupThought"]} key={item.id}>
                  {!homeStore.state.featureTags.disableMultiRoundChatOnboarding && (
                    <div className={css.outputlabel}>
                      {NodeTitleUtil.outputTitle(
                        creatorSaasAppStore.getOutputV2(item.data?.groupDnd?.[0])?.data?.blank?.title || ""
                      )}
                    </div>
                  )}
                  {item.data.groupDnd.map(thought => (
                    <PreviewOutputItemRoot thought={thought} key={thought.id} />
                  ))}
                </div>
              ))}
          </div>

          <AIViewBuilder />
        </div>
      </div>
    </div>
  );
}

export default function AiSaasOutputList(props: AISaasOutput.AiSaasOutputListProps): JSX.Element {
  return (
    <AiSaasOutputProvider initialState={props}>
      <AiSaasOutputListInner />
    </AiSaasOutputProvider>
  );
}
