"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdapterResponseUtil = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const StoryNodeDisplayType_1 = require("../../storyV2/domain/StoryNodeDisplayType");
class AdapterResponseUtil {
    static responseTypeTransform(funcResType) {
        switch (funcResType) {
            case FunctionBase_1.FuncResponseType.ASK_BRAIN:
                return AdapterBase_1.RunResponseType.ASK_BRAIN;
            case FunctionBase_1.FuncResponseType.IMAGE_TEXT:
                return AdapterBase_1.RunResponseType.IMAGE;
            case FunctionBase_1.FuncResponseType.STREAM_TEXT:
                return AdapterBase_1.RunResponseType.STREAM;
            case FunctionBase_1.FuncResponseType.AMAZON_SHOPPING:
                return AdapterBase_1.RunResponseType.AMAZONSHOPPING;
            case FunctionBase_1.FuncResponseType.CHAT_INTERFACE:
                return AdapterBase_1.RunResponseType.CHAT_INTERFACE;
            case FunctionBase_1.FuncResponseType.PROTEIN:
                return AdapterBase_1.RunResponseType.PROTEIN;
            case FunctionBase_1.FuncResponseType.TEXT:
                return AdapterBase_1.RunResponseType.TEXT;
            case FunctionBase_1.FuncResponseType.STRING_GROUP:
                return AdapterBase_1.RunResponseType.STRING_GROUP;
            case FunctionBase_1.FuncResponseType.STOCK:
            case FunctionBase_1.FuncResponseType.STOCK_GROUP:
                return AdapterBase_1.RunResponseType.STOCK;
            case FunctionBase_1.FuncResponseType.JD_SHOPPING:
                return AdapterBase_1.RunResponseType.JDSHOPPING;
            case FunctionBase_1.FuncResponseType.YOUTUBE_VIDEOS:
                return AdapterBase_1.RunResponseType.YOUTUBE_VIDEOS;
            case FunctionBase_1.FuncResponseType.WEEE_GROCERIES:
                return AdapterBase_1.RunResponseType.WEEEGRPCERIES;
            case FunctionBase_1.FuncResponseType.WEEE_SHOPPING:
                return AdapterBase_1.RunResponseType.WEEESHOPPING;
            case FunctionBase_1.FuncResponseType.MAP:
                return AdapterBase_1.RunResponseType.MAP;
            case FunctionBase_1.FuncResponseType.IMAGE_GROUP:
                return AdapterBase_1.RunResponseType.IMAGE_GROUP;
            case FunctionBase_1.FuncResponseType.FOOD:
                return AdapterBase_1.RunResponseType.FOOD;
            case FunctionBase_1.FuncResponseType.MIDJOURNEY:
                return AdapterBase_1.RunResponseType.MIDJOURNEY;
            case FunctionBase_1.FuncResponseType.CUSTOM_API:
                return AdapterBase_1.RunResponseType.CUSTOM_API;
            case FunctionBase_1.FuncResponseType.INTERVIEW_BOT:
                return AdapterBase_1.RunResponseType.INTERVIEW_BOT;
            case FunctionBase_1.FuncResponseType.BLUEPRINT:
                return AdapterBase_1.RunResponseType.BLUEPRINT;
            case FunctionBase_1.FuncResponseType.FUNCTION_V2:
                return AdapterBase_1.RunResponseType.FUNCTION_V2;
        }
    }
    static funcResponseTypeTransform(funcResType) {
        switch (funcResType) {
            case AdapterBase_1.RunResponseType.ASK_BRAIN:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.ASKBRAIN;
            case AdapterBase_1.RunResponseType.IMAGE:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.IMAGEGEN;
            case AdapterBase_1.RunResponseType.TEXT:
            case AdapterBase_1.RunResponseType.STREAM:
            case AdapterBase_1.RunResponseType.STRING_GROUP:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT;
            case AdapterBase_1.RunResponseType.AMAZONSHOPPING:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.AMAZONSHOPPING;
            case AdapterBase_1.RunResponseType.CHAT_INTERFACE:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.CHATINTERFACE;
            case AdapterBase_1.RunResponseType.PROTEIN:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.CUSTOMJSFUNCTION;
            case AdapterBase_1.RunResponseType.STOCK:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.STOCKINFO;
            case AdapterBase_1.RunResponseType.JDSHOPPING:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.JDSHOPPING;
            case AdapterBase_1.RunResponseType.YOUTUBE_VIDEOS:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.VIDEO;
            case AdapterBase_1.RunResponseType.WEEEGRPCERIES:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.WEEEGROCERIES;
            case AdapterBase_1.RunResponseType.WEEESHOPPING:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.WEEESHOPPING;
            case AdapterBase_1.RunResponseType.MAP:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.MAP;
            case AdapterBase_1.RunResponseType.IMAGE_GROUP:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.IMAGEGEN;
            case AdapterBase_1.RunResponseType.FOOD:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.SHOWFOODINTERFACE;
            case AdapterBase_1.RunResponseType.MIDJOURNEY:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.MIDJOURNEY;
            case AdapterBase_1.RunResponseType.INTERVIEW_BOT:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.INTERVIEW_BOT;
            case AdapterBase_1.RunResponseType.BLUEPRINT:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.BLUEPRINT;
        }
        return undefined;
    }
}
exports.AdapterResponseUtil = AdapterResponseUtil;
