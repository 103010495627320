import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

type Param = Partial<Omit<CotStoreState, "copyWith">>;

class OldCotAnimationState {
  creating: boolean = false;
  inputIds: string[] = [];
  currentInputId: string = "";
}

class GuideTriggerMessage {
  cot: number | null | (() => void) = null;
  manual: number | null | (() => void) = null;
}

export class CotStoreState {
  showCot = false;
  showUnsupported = false;
  cotAutomaticNode = true;
  showMultiRoundChatOnboarding = false;
  guideTriggerMessage: GuideTriggerMessage = {
    cot: null,
    manual: null,
  };
  oldCotAnimationState: OldCotAnimationState = {
    creating: false,
    inputIds: [],
    currentInputId: "",
  };

  copyWith(params: Param): CotStoreState {
    const state = new CotStoreState();
    state.showCot = params.showCot ?? this.showCot;
    state.showUnsupported = params.showUnsupported ?? this.showUnsupported;
    state.cotAutomaticNode = params.cotAutomaticNode ?? this.cotAutomaticNode;
    state.guideTriggerMessage = params.guideTriggerMessage ?? this.guideTriggerMessage;
    state.oldCotAnimationState = params.oldCotAnimationState ?? this.oldCotAnimationState;
    state.showMultiRoundChatOnboarding = params.showMultiRoundChatOnboarding ?? this.showMultiRoundChatOnboarding;
    return state;
  }
}

export class CotStore extends StoreBase<CotStoreState> {
  constructor() {
    super(() => new CotStoreState());
  }

  setOldCotAnimationState = (oldCotAnimationState: OldCotAnimationState): void => {
    this.emit(this.state.copyWith({ oldCotAnimationState }));
  };

  setShowUnsupported = (showUnsupported: boolean): void => {
    this.emit(this.state.copyWith({ showUnsupported }));
  };

  setShowCot = (showCot: boolean): void => {
    this.emit(this.state.copyWith({ showCot }));
  };

  setCotAutomaticNode = (cotAutomaticNode: boolean): void => {
    this.emit(this.state.copyWith({ cotAutomaticNode }));
  };

  triggerGuideManual = (callback?: () => void): void => {
    const newGuideTriggerMessage = { ...this.state.guideTriggerMessage, manual: callback || Date.now() };
    this.emit(this.state.copyWith({ guideTriggerMessage: newGuideTriggerMessage }));
  };

  triggerGuideCot = (callback?: () => void): void => {
    const newGuideTriggerMessage = { ...this.state.guideTriggerMessage, cot: callback || Date.now() };
    this.emit(this.state.copyWith({ guideTriggerMessage: newGuideTriggerMessage }));
  };

  setMultiRoundChatOnboarding = (showMultiRoundChatOnboarding: boolean): void => {
    const newState = this.state.copyWith({ showMultiRoundChatOnboarding });
    this.emit(newState);
  };
}
