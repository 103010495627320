export function LinkIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2308 4.10999L11.1708 3.17001C11.5426 2.79868 11.9838 2.50422 12.4694 2.30347C12.955 2.10272 13.4754 1.99959 14.0008 2C14.7863 2.0052 15.5528 2.24154 16.2048 2.67954C16.8568 3.11754 17.3655 3.7378 17.6673 4.46298C17.9691 5.18816 18.0507 5.98616 17.9019 6.75742C17.7532 7.52868 17.3806 8.23907 16.8308 8.79999L13.8308 11.8C13.3989 12.2326 12.8736 12.5606 12.2952 12.7585C11.7168 12.9565 11.1008 13.0193 10.4944 12.942C9.88793 12.8648 9.30727 12.6496 8.797 12.3129C8.28672 11.9763 7.8604 11.5271 7.55078 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.76831 15.89L8.82831 16.83C8.07775 17.5806 7.05978 18.0022 5.99832 18.0022C4.93687 18.0022 3.9189 17.5806 3.16833 16.83C2.41777 16.0794 1.99609 15.0615 1.99609 14C1.99609 12.9385 2.41777 11.9206 3.16833 11.17L6.16833 8.17001C6.54009 7.79868 6.98134 7.50422 7.46692 7.30347C7.9525 7.10272 8.47288 6.99959 8.99832 7C9.69516 6.99941 10.3801 7.18086 10.9852 7.5264C11.5903 7.87194 12.0947 8.36957 12.4483 8.97"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
