import { useRef } from "react";
import { Skeleton } from "antd";

import style from "../components-css/FeedContentSkeleton.module.css";

export default function FeedContentSkeleton(props) {
  const skeletonfill = useRef(props.skeletonfill || [".", ".", ".", ".", ".", ".", ".", ".", ".", "."]);

  return (
    <>
      <div className={style["Feed-Top-Skeleton"]}>
        <div className={style["Feed-Top-Box"]}>
          <Skeleton.Button className={style["Feed-Top-First"]} shape="round" block active />
          <Skeleton.Button className={style["Feed-Top-Second"]} shape="round" block active />
        </div>

        <Skeleton.Button className={style["Feed-Top-Third"]} shape="round" block active />
      </div>
      {skeletonfill.current.map((thought, id) => (
        <Skeleton
          className={style["Feed-Content-Skeleton"]}
          key={id}
          loading={true}
          title={{
            width: "60%",
          }}
          paragraph={{
            width: "20%",
            rows: 1,
          }}
          round
          active
        />
      ))}
    </>
  );
}
