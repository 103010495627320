import { Button, Modal } from "antd";
import style from "./ConfirmModal.module.scss";
import func from "@uikit/func";
import classNames from "classnames";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

const CloseIcon = <img src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`} alt="" />;

export class ConfirmModalProps {
  className?: string = "";
  confirmModalState: boolean = false;
  confirmLoading?: boolean = false;
  showCloseIcon?: boolean = false;
  showFooter?: boolean = true;
  diabledConfirm?: boolean = false;
  title?: React.ReactNode = "";
  discardBtnText?: string = "";
  saveBtnText?: string = "";
  description?: string = "";
  children?: any;
  setConfirmModalState?: (param: boolean) => void = () => {};
  handleDiscard?: (param: any) => void = () => {};
  handleOk?: (param: any) => void = () => {};
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const {
    className,
    confirmModalState,
    confirmLoading,
    showCloseIcon = false,
    diabledConfirm = false,
    showFooter = true,
    children,
    title = "Save Changes?",
    discardBtnText = "Discard and exit",
    saveBtnText = "Save and exit",
    description = "You have unsaved changes. Do you want to save your changes before exiting?",
    setConfirmModalState,
    handleDiscard,
    handleOk,
  } = props;

  return (
    <Modal
      className={classNames(style["confirmModal"], className)}
      open={confirmModalState}
      onCancel={() => setConfirmModalState && setConfirmModalState(false)}
      title={title}
      closable={showCloseIcon}
      closeIcon={showCloseIcon ? CloseIcon : null}
      footer={
        showFooter
          ? [
              discardBtnText ? (
                <Button
                  key="discard"
                  disabled={confirmLoading}
                  onClick={handleDiscard}
                  data-testid="confirmModal-discard"
                >
                  {discardBtnText}
                </Button>
              ) : null,
              <Button
                key="save"
                type="primary"
                loading={confirmLoading}
                onClick={handleOk}
                data-testid="confirmModal-save"
                disabled={diabledConfirm}
              >
                {saveBtnText}
              </Button>,
            ]
          : null
      }
      centered
    >
      <p data-testid="confirmModal-main">{description}</p>
      {!func.isEmpty(children) ? children : null}
    </Modal>
  );
}
