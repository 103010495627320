"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveApi = void 0;
const client_1 = require("@apollo/client");
const SaveErrors_1 = require("./SaveErrors");
const GraphqlClient_1 = require("../../base/cutil/GraphqlClient");
const GraphqlUtil_1 = require("../../base/api/GraphqlUtil");
const RelationSetDest_1 = require("../../base/api/graphqlTyped/RelationSetDest");
/**
 * 该类主要用于替换原来 ObjectRelationGqlService 部分方法
 *
 * 用来替换 fot 请求接口
 *
 * 会将该 class 移动到 corekit 中
 *
 */
class SaveApi {
    constructor(apolloRequest) {
        this.apolloRequest = apolloRequest;
    }
    async updateObject(id, data) {
        console.log("jj saveapi updateObject");
        if (data.attributes) {
            data.attributes = JSON.stringify(data.attributes);
        }
        const query = new GraphqlClient_1.GraphqlQuery("updateObject", (0, client_1.gql) `
        mutation updateObject($data: ObjInput!) {
            createOrUpdateObject(id: ${id}, data: $data) {
            ok
            object {
                attributes
                attributesV2
                id
                attrIndexer
                createdAt
                name
                uuid
                updatedAt
            }
            }
        }
    `, {
            data,
        });
        const response = await this.apolloRequest.mutate(query);
        const ok = response?.data?.createOrUpdateObject?.ok;
        if (!ok) {
            throw new SaveErrors_1.SaveApiFailedError("Failed to update object");
        }
        const object = response?.data?.createOrUpdateObject?.object;
        if (!object?.id) {
            throw new SaveErrors_1.SaveApiFailedError("Failed to update object, empty id");
        }
        return object;
    }
    async createRelation(data) {
        console.log("jj saveapi createRelation");
        const query = new GraphqlClient_1.GraphqlQuery("createRelation", (0, client_1.gql) `
        mutation createRelation($data: RelInput!) {
          createRelation(rel: $data) {
            ok
            relation {
              id
              name
              createdAt
              updatedAt
            }
          }
        }
      `, {
            data,
        });
        const response = await this.apolloRequest.mutate(query);
        const ok = response?.data?.createRelation?.ok;
        if (!ok) {
            throw new SaveErrors_1.SaveApiFailedError("Failed to create relation");
        }
        const relation = response?.data?.createRelation?.relation;
        if (!relation?.id) {
            throw new SaveErrors_1.SaveApiFailedError("Failed to create relation, empty id");
        }
        return relation;
    }
    async relationSet(filters = {}, params = {}, idFilter = {}) {
        console.log("jj saveapi relationSet");
        const { size = 1000, page = 1 } = params;
        if (typeof idFilter?.destId === "string") {
            idFilter.destId = parseInt(idFilter.destId);
        }
        const filterStr = (0, GraphqlUtil_1.getFilterString)(Object.assign({}, { page, size }, filters, idFilter));
        const query = new GraphqlClient_1.GraphqlQuery("relationSet", (0, client_1.gql) `
        query relationSet{
            me {
                relationSet(${filterStr}){
                    id
                    dest {
                        id
                        uuid
                        attrIndexer
                        name
                        attributes
                        attributesV2
                        createdAt
                        updatedAt
                        user {
                            id
                            firstName
                            lastName
                        }
                    }

                }
            }
        }`);
        return this.apolloRequest
            .query(query)
            .then(res => {
            // !!! fot gql service 是直接返回的数据
            const resData = res.toArray(RelationSetDest_1.RelationSetDest, () => {
                return (res?.data?.me.relationSet || []).map((x) => {
                    let dest = x?.dest;
                    if (dest && typeof dest === "object") {
                        dest = Object.assign({}, dest, { relationId: x.id });
                    }
                    return dest;
                });
            });
            return resData?.data || [];
        })
            .catch(error => {
            // 原来 ObjectRelationGqlService 中没有抛出错误，除了 ApolloError
            // use console log for pagination errors
            // console.log("relationSet", error);
            if (error instanceof client_1.ApolloError) {
                throw error;
            }
            return [];
        });
    }
    async createObject(data) {
        if (data.attributes) {
            data.attributes = JSON.stringify(data.attributes);
        }
        const operationName = data.isGlobal ? "createGlobalObject" : "createObject";
        const query = new GraphqlClient_1.GraphqlQuery(operationName, (0, client_1.gql) `
        mutation ${operationName}($data: ObjInput!) {
          createObject(data: $data) {
            ok
            object {
              attributes
              attributesV2
              id
              attrIndexer
              createdAt
              name
              uuid
              updatedAt
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
      `, {
            data,
        });
        const response = await this.apolloRequest.mutate(query);
        const ok = response?.data?.createObject?.ok;
        if (!ok) {
            throw new SaveErrors_1.SaveApiFailedError("Failed to create object");
        }
        const object = response?.data?.createObject?.object;
        if (!object?.id) {
            throw new SaveErrors_1.SaveApiFailedError("Failed to create object, empty id");
        }
        return object;
    }
    /**
     * 原来 fot gql 中的deleteRelations 方法
     *
     * 1. 内部的消息提示已经去掉，需要在外部手动提示
     * 2. 错误消息通过抛出统一错误类，在外部捕获
     *
     * @param ids
     * @returns
     */
    async deleteRelations(ids) {
        if (!(ids instanceof Array)) {
            ids = [ids];
        }
        const query = new GraphqlClient_1.GraphqlQuery("deleteRelations", (0, client_1.gql) `
        mutation deleteRelations {
          deleteRelations(ids: ${ids}) {
            ok
          }
        }`);
        const response = await this.apolloRequest.mutate(query);
        const ok = response?.data?.deleteRelations?.ok;
        if (!ok) {
            throw new SaveErrors_1.SaveApiFailedError("Failed to delete relations");
            // func.messageUtil("Failed to delete relations", "error");
            // return;
        }
        return;
    }
}
exports.SaveApi = SaveApi;
