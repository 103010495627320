export enum SidebarItems {
  Unknown,
  CreateNewProject,
  Templates,
  MyProjects,
  MyFunctions,
}

export interface SidebarMenuItem {
  index: number;
  item: SidebarItems;
  icon: string;
  title: string;
}
