import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logEvent } from "@uikit/service/amplitude";
import { editorActions } from "../../store/editor";
import { studioActions } from "../../store/studio";
import { fotActions } from "../../store/fot";
import { AModal } from "../../uiview/views/AModal/AModal";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { getIt } from "@uikit/getIt";
import { ProjectNetDataStore, ProjectUpdateParam } from "@base/ProjectNetDataStore";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { popupFeedbackStore } from "@uikit/store/PopupFeedback";
import { ChatOnboardingStore, ChatOnboardingState } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CotStore } from "@uikit/store/CotStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreateNewProjectService } from "@uiview/views/ProjectCanvas/CreateNewProject";

const gql = getIt(ObjectRelationGqlService);

export default function BackHomeModal(props) {
  const projectUpdateStore = getIt(ProjectNetDataStore);
  const cotStore = getIt(CotStore);
  const createNewProjectService = getIt(CreateNewProjectService);

  const [saveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
  const setEnableCOTStartAgain = val => {
    dispatch(editorActions.setEnableCOTStartAgain(val));
  };

  const {
    description = "You have unsaved changes. Do you want to save your changes before exiting?",
    title = "Save Changes?",
    saveBtnText = "Save and exit",
    onOKMethod = "saveAndExit",
    discardBtnText = "Discard and exit",
    discardMethod = "discard",
  } = useSelector(state => state.editor.discardExitSaveRestartConfirm);

  const previewAppStore = getIt(PreviewAppStore);

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);

  const buttons = [discardBtnText, saveBtnText];
  const chatOnboardingStore = getIt(ChatOnboardingStore);

  const exitEmptyOnboardingChat = () => {
    chatOnboardingStore.setState(new ChatOnboardingState());
  };

  const handleClick = async operate => {
    switch (operate) {
      case "cancel":
        handleCancel();
        return;
      case "ok":
        if (onOKMethod === "saveAndExit") {
          await handleOk();
        }
        if (onOKMethod === "saveAndStart") {
          await handleSaveStart();
        }
        exitEmptyOnboardingChat();
        break;
      case "discard":
        if (discardMethod === "discard") {
          await handleDiscard();
        }
        if (discardMethod === "cancel") {
          handleCancel();
        }
        exitEmptyOnboardingChat();
        break;
      default:
        break;
    }
    // 返回首页清空 selectTemplate
    dispatch(studioActions.setSelectedTemplate({}));
    dispatch(editorActions.setDiscardExitSaveRestartConfirm({}));
  };

  const handleCancel = () => {
    logEvent("click_modal_cancel", { title: title, description: description });

    props.setBackHomeModal(false);
  };

  const handleOk = async () => {
    projectUpdateStore.stopAutoUpdate();
    setSaveLoading(true);
    await props.saveAndExit();
    setSaveLoading(false);
    props.setBackHomeModal(false);
    projectPageStore.setIsPublish(false);
    popupFeedbackStore.setEnableCOTFeedbackPopup(false);
    dispatch(fotActions.setVariableList([]));
    getIt(HomePluginStore).setInitWithCot(false);
    previewAppStore.clearPreviewApp();
  };
  const handleDiscard = () => {
    if (!projectUpdateStore.hasUnmount()) {
      previewAppStore.saasUiDataToPreview();
      projectUpdateStore.stopAutoUpdate();
      if (chatOnboardingStore.state.selectTemplateInModal !== ChoosingFormat.AUTOIMAGICA) {
        projectUpdateStore.updateProjectData(
          new ProjectUpdateParam(previewStore.state.previewAppList, selectedTemplate, gql)
        );
      }
    }
    props.setBackHomeModal(false);
    projectPageStore.setIsPublish(false);
    props.handleReset();
    setEnableCOTStartAgain(null);
    popupFeedbackStore.setEnableCOTFeedbackPopup(false);
    cotStore.setOldCotAnimationState({
      creating: false,
      inputIds: [],
      currentInputId: "",
    });
    dispatch(fotActions.setVariableList([]));
    previewAppStore.clearPreviewApp();
  };

  const handleSaveStart = async () => {
    logEvent("click_modal_save_start", { title: title, description: description });
    setSaveLoading(true);
    cotStore.setOldCotAnimationState({
      creating: false,
      inputIds: [],
      currentInputId: "",
    });
    await handleOk();
    await createNewProjectService.addNewProject();
    setSaveLoading(false);
  };

  return (
    <AModal
      open={props.backHomeModal}
      title={title}
      subTitle={description}
      loading={saveLoading}
      buttons={buttons}
      shouldExecuteOkOnEnter={true}
      onDiscard={() => handleClick("discard")}
      onCancel={() => handleClick("cancel")}
      onOk={() => handleClick("ok")}
    />
  );
}
