import { Rate } from "antd";
import "../components-css/Travel.css";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

function Travel(props) {
  const reviews = Math.round(Math.random() * 9000 + 1000);
  const scoreControl = Math.round(Math.random() * 2 + 3);
  const image =
    scoreControl == 3
      ? `${settings.S3CDN}${settings.viewAssetsPath}Travel1.png`
      : scoreControl == 4
      ? `${settings.S3CDN}${settings.viewAssetsPath}Travel2.png`
      : `${settings.S3CDN}${settings.viewAssetsPath}Travel3.png`;

  const toThousands = num => {
    return num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  };
  return (
    <section className="CardHomeTravel">
      <section className="upperPart">
        <section className="imgBox">
          <img src={image}></img>
        </section>

        <section>
          <p className="title">{props.city}</p>
          <p className="supplement">{props.time}</p>
          <section className="scoreBox">
            <Rate value={scoreControl} disabled className="scoreControl" />
            <section className="reviews"> {toThousands(reviews)} reviews</section>
          </section>
        </section>
      </section>
      <section className="describe">{props.reason}</section>
    </section>
  );
}

export default Travel;
