import { ChangeEvent, FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import style from "./style.module.scss";
import Dropdown, { Triggers } from "../Dropdown";
import Text from "../Text";
import Icon from "../Icon";
import { ProjectDropdownItems, ProjectProps } from "./type";
import { callback, downloadFileAsBlobFromS3ByObjectKey, getAssetsIcon, getDataUrlByBlob } from "../../utilities";
import dayjs from "dayjs";
import Thumbnail from "./Thumbnail";
import { FocusEvent } from "react";
import { animated, useSpring } from "@react-spring/web";
import Loader from "../Loader";

const ProjectItem: FC<ProjectProps> = ({
  project,
  loading = false,
  onClick = callback,
  onNameChange = callback,
  onDropdownClick = callback,
}) => {
  const [templateStyle, templateApi] = useSpring(() => ({}));
  const [name, setName] = useState("");
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");

  const setThumbnailUrlByS3ObjectKey = useCallback(async (objectKey: string) => {
    try {
      const blob = await downloadFileAsBlobFromS3ByObjectKey(objectKey);
      const dataUrl = await getDataUrlByBlob(blob);
      project.thumbnailUrl = dataUrl;
      setThumbnailUrl(dataUrl);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!project) {
      setThumbnailUrl("");
      return;
    }
    if (project.thumbnailUrl) {
      setThumbnailUrl(project.thumbnailUrl);
      return;
    }
    try {
      const json = project.attributesV2 || JSON.parse(project.attributes ?? "");
      if (!json.thumbnail_s3ObjectKey) {
        return;
      }
      setThumbnailUrlByS3ObjectKey(json.thumbnail_s3ObjectKey);
    } catch (error) {
      console.error(error);
      return;
    }
  }, [project, setThumbnailUrlByS3ObjectKey]);

  useEffect(() => {
    try {
      const attr = project.attributesV2 || JSON.parse(project.attributes ?? "");
      const newName = attr.name;
      setName(newName);
    } catch (error) {
      setName("");
      console.error(error);
    }
  }, [project]);

  const handleDropdownOpenChange = useCallback((visible: boolean) => {
    setIsDropdownOpen(visible);
  }, []);

  const handleDropdownClick = useCallback(
    (type: ProjectDropdownItems) => () => {
      setIsDropdownOpen(false);
      onDropdownClick({
        type,
        project,
      });
    },
    [project, onDropdownClick]
  );

  const handleTextClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      // if (isNameEditing) {
      //   return;
      // }
      // setIsNameEditing(true);
    },
    [
      // isNameEditing
    ]
  );

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  }, []);

  const handleInputKeyup = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onNameChange(name, project);
        setIsNameEditing(false);
      }
      if (event.key === "Escape") {
        setName(project.attributesV2?.name ?? "");
        setIsNameEditing(false);
      }
    },
    [project, name, onNameChange]
  );

  const handleInputBlur = useCallback(() => {
    setName(project.attributesV2?.name ?? "");
    setIsNameEditing(false);
  }, [project]);

  const datetime = useMemo(() => {
    return dayjs(project.updatedAt).fromNow();
  }, [project]);

  const handleContextMenu = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setIsDropdownOpen(true);
    return false;
  }, []);

  const handleClick = useCallback(async () => {
    onClick(project);
    templateApi.start({
      config: { duration: 1000 },
      to: [{ transform: "scale(0.9)" }, { transform: "scale(1)" }],
    });
  }, [project, templateApi, onClick]);

  const handleFocus = useCallback((event: FocusEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDropdownOpen(false);
  }, []);

  const handleInputFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.stopPropagation();
  }, []);

  const trigger = useMemo<Triggers>(() => (isDropdownOpen ? Triggers.Click : Triggers.Focus), [isDropdownOpen]);

  return (
    <div style={{ width: "100%" }}>
      {/* {loading && (
        <div className={style.loading}>
          <Loader full />
        </div>
      )} */}
      <Dropdown
        isOpen={isDropdownOpen}
        trigger={trigger}
        placement="bottom"
        className={style.templateToolTipsBox}
        content={
          <div className={style.dropdown}>
            <div className={style.dropdown__item}>
              <div className={style.dropdownItem} onClick={handleDropdownClick(ProjectDropdownItems.Duplicate)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("copy.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Duplicate</Text>
                </div>
              </div>
              <div className={style.dropdownItem} onClick={handleDropdownClick(ProjectDropdownItems.Delete)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("bin.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Delete</Text>
                </div>
              </div>
            </div>
          </div>
        }
        onOpenChange={handleDropdownOpenChange}
      >
        <animated.div
          className={style.template}
          style={templateStyle}
          onClick={handleClick}
          onFocus={handleFocus}
          onContextMenu={handleContextMenu}
        >
          {loading && (
            <div className={style.loading}>
              <Loader full />
            </div>
          )}
          <div className={style.template__image}>
            {thumbnailUrl === "" ? <Thumbnail /> : <img src={thumbnailUrl} alt="thumbnail" />}
          </div>
          <div className={style.template__description}>
            <div className={style.templateDescription}>
              <div className={style.templateDescription__text} onClick={handleTextClick}>
                {!isNameEditing && <>{name ?? "Untitled"}</>}
                {isNameEditing && (
                  <input
                    className={style.input}
                    type="text"
                    title="text"
                    name="name"
                    value={name}
                    onFocus={handleInputFocus}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyUp={handleInputKeyup}
                  />
                )}
              </div>
              <div className={style.templateDescription__datetime}>Edited {datetime}</div>
            </div>
          </div>
        </animated.div>
      </Dropdown>
    </div>
  );
};

export default ProjectItem;
