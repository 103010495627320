"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterPreview = void 0;
const BrainClient_1 = require("../base/api/BrainClient");
const MeStore_1 = require("../base/store/MeStore");
const LinkService_1 = require("../cases/preview/LinkService");
const PreviewService_1 = require("../cases/preview/PreviewService");
const TitleService_1 = require("../cases/preview/TitleService");
class RegisterPreview {
    static configure(container) {
        const it = (serviceIdentifier) => {
            return container.get(serviceIdentifier);
        };
        container.bind(TitleService_1.TitleService).toConstantValue(new TitleService_1.TitleService(it(BrainClient_1.BrainClient)));
        container.bind(LinkService_1.LinkService).toConstantValue(new LinkService_1.LinkService(it(BrainClient_1.BrainClient), it(MeStore_1.MeStore)));
        container.bind(PreviewService_1.PreviewService).toConstantValue(new PreviewService_1.PreviewService(it(TitleService_1.TitleService), it(LinkService_1.LinkService)));
    }
}
exports.RegisterPreview = RegisterPreview;
