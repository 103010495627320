import { debounce } from "lodash";
import { getIt } from "@uikit/getIt";
import { useEffect, useState } from "react";
import { WindowSizeManager } from "@uikit/manager/WindowSizeManager";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { WindowSizeStore } from "@uikit/store/WindowSizeStore";
import classNames from "classnames";
import FotReactFlowPanel from "../ProjectCanvas/CreatorRoot/FotReactFlow/FotReactFlowPanel";

const AutoResizablePanel = (props: any): JSX.Element | null => {
  const { children, className, position, ...childProps } = props;
  const windowSizeManager = WindowSizeManager.use();
  const windowSizeState = useStore(getIt(WindowSizeStore)).value;
  const [zoom, setZoom] = useState(windowSizeManager.getBodyZoom());
  const cls = classNames("react-flow__panel", className, position ? position.split("-") : "");

  const handleZoom = (): void => {
    setZoom(windowSizeState.zoom);
  };

  useEffect(() => {
    debounce(handleZoom, 200)();
  }, [windowSizeState.zoom]);

  return (
    <>
      {/* STUD-2683 此处丢失pointerEvents, 参考源码 https://github.com/xyflow/xyflow/blob/v11/packages/core/src/components/Panel/index.tsx/#L14 */}
      <FotReactFlowPanel className={cls} style={{ zoom: zoom }} {...childProps}>
        {children}
      </FotReactFlowPanel>
    </>
  );
};

export default AutoResizablePanel;
