"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VBDataShoppingProduct = exports.VBDataShoppingProductItem = exports.VBDataShoppingProductSource = void 0;
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
const VBGraphDataBase_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBGraphDataBase");
class VBDataShoppingProductSource {
    constructor() {
        this.source_icon = "";
        this.source_text = "";
    }
}
exports.VBDataShoppingProductSource = VBDataShoppingProductSource;
class VBDataShoppingProductItem {
    constructor() {
        this.product_image = "";
        this.product_name = "";
        this.product_price = "";
        this.product_rating = "";
        this.product_color = "";
        this.product_reviews = "";
        this.buy_button = "";
        this.source = new VBDataShoppingProductSource();
    }
}
exports.VBDataShoppingProductItem = VBDataShoppingProductItem;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => VBDataShoppingProductSource)
], VBDataShoppingProductItem.prototype, "source", void 0);
class VBDataShoppingProduct extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.product_list = [];
    }
}
exports.VBDataShoppingProduct = VBDataShoppingProduct;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => VBDataShoppingProductItem, [])
], VBDataShoppingProduct.prototype, "product_list", void 0);
