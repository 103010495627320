import { LoadingOutlined } from "@ant-design/icons";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { ShareAccountBloc } from "./ShareAccountBloc";
import styles from "./AccountStatus.module.scss";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { ShareAccountUtils } from "./ShareAccountUtils";
import { Button } from "antd";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";

export function UnSubscribe(props: { bloc: ShareAccountBloc; store: PublishSubscriptionStore }): React.JSX.Element {
  const store = getIt(PublishSubscriptionStore);
  const state = useStore(store).value;

  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const title = saasUIData?.title || "Untitled";

  return (
    <div className={styles.unsubscribe}>
      <div className={styles.titleWrap}>
        <div className={styles.header}>My Subscription</div>
        <div className={styles.title}>{title} Subscription</div>
        <div className={styles.subTitle}>Unlimited access to {title}.</div>
      </div>
      <div className={styles.per}>
        <div>
          <span className={styles.price}>${state.price}</span>
          <span className={styles.month}>per month</span>
        </div>
        {!!state.free && <div>{state.free} days left in free trial</div>}
      </div>
      {props.bloc.state.isCancel.value ? (
        <div className={styles.resubWrap}>
          <div className={styles.cancelAt}>
            Your plan will remain active until{" "}
            <span className={styles.date}>{ShareAccountUtils.formatTime(props.bloc.state.cancelAt.value)}</span>, You
            can always resubscribe here
          </div>
          <Button
            onClick={() => {
              props.bloc.resubscribe(props.store);
            }}
            className={styles.resubBtn}
          >
            {props.bloc.state.resubscribeLoading.value ? <LoadingOutlined /> : "resubscribe"}
          </Button>
        </div>
      ) : (
        <div className={styles.unsubWrap}>
          <Button
            onClick={() => {
              props.bloc.unSubscribeLink(props.store);
            }}
            className={styles.unSubBtn}
          >
            {props.bloc.state.unsubscribeLoading.value ? <LoadingOutlined /> : "Unsubscribe"}
          </Button>
        </div>
      )}
    </div>
  );
}
