import { useCallback, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { useSignal } from "@preact/signals-react";
import { UseModalProps } from "./types";
import { AModal } from "./AModal";
import { getIt } from "@uikit/getIt";
import { AmodalStore } from "@uikit/store/AmodalStore";

export const useModal = (): ((props: UseModalProps) => void) => {
  const amodalStore = getIt(AmodalStore);
  const isOpen = useSignal(false);
  const isLoading = useSignal(false);
  const okFn = useRef((text?: string) => {
    console.log(text);
  });
  const cancelFn = useRef(() => {});
  const modalProps = useSignal<UseModalProps>({
    title: "",
    buttons: ["Cancel", "Ok"],
  });

  const setLoading = (loading: boolean): void => {
    isLoading.value = loading;
  };

  const close = useCallback((): void => {
    isOpen.value = false;
  }, []);

  useEffect(() => {
    const modalDom = document.createElement("div");
    document.body.appendChild(modalDom);
    modalDom.className = "a-modal-root";

    if (!amodalStore.state.root && modalDom) {
      amodalStore.updateRoot(createRoot(modalDom));
    }
  }, []);

  useEffect(() => {
    if (amodalStore.state.root) {
      amodalStore.state.root.render(
        <AModal
          {...modalProps.value}
          loading={isLoading.value}
          open={isOpen.value}
          onOk={okFn.current}
          onCancel={cancelFn.current}
        />
      );
    }
  }, [modalProps.value, isOpen.value, okFn, isLoading.value]);

  const showModal: (props: UseModalProps) => void = useCallback(
    props => {
      isOpen.value = true;
      modalProps.value = props;

      okFn.current = (e, reason?: string): void => {
        if (props.onOk) {
          props.onOk({
            reason: reason || "",
            setLoading,
            close,
          });
        } else {
          close();
        }
      };

      cancelFn.current = (): void => {
        close();
        props.onCancel && props.onCancel();
      };
    },
    [close]
  );

  return showModal;
};
