import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import func from "@uikit/func";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";

/**
 * 原来 useHandleChatBox
 *
 * 用于运行 chatBox 类型的边的逻辑
 */
export class EdgeRunTypeChatBox {
  constructor(
    public gql: ObjectRelationGqlService,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public creatorNodesStore: CreatorNodesStore,
    public creatorStoreMethods: CreatorStoreMethods
  ) {}

  async setChatBoxParam(params: { edgeId: any; target: any; lineParams: any }): Promise<boolean> {
    const { lineParams, target, edgeId } = params;

    if (lineParams.isChat) {
      const currentParam = await this.gql.objects({
        id: lineParams.identifier.value,
      });
      this.creatorNodesStore.setNodes(prevNodes => {
        return prevNodes.map(l => {
          if (l.id === target) {
            return {
              ...l,
              data: {
                ...l.data,
                displayType: "chatBox",
                chatBoxParam: currentParam,
              },
            };
          }
          return l;
        });
      });
      this.creatorStoreMethods.setEdgeLineParam({
        id: edgeId,
        loading: false,
      });

      // 如果右侧preview有这个node，应该重新设置引入变量的值
      // bsf-4912 replace thoughts
      const thoughts = this.creatorSaasAppStore.state.saasUIData.output;
      if (!func.isEmpty(thoughts)) {
        const newThoughts = thoughts.map(x => {
          return {
            ...x,
            results: x.results.map(y => {
              if (y.nodeId === target) {
                return {
                  ...y,
                  content: {
                    ...y.content,
                    chatBoxParam: currentParam,
                  },
                };
              }
              return y;
            }),
          };
        });
        // bsf-4912 use setSassUIData
        this.creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: newThoughts }));
      }
      return true;
    } else {
      // 清空chatBoxParam
      this.creatorNodesStore.setNodes(prevNodes => {
        return prevNodes.map(l => {
          if (l.id === target) {
            return {
              ...l,
              data: {
                ...l.data,
                chatBoxParam: [],
              },
            };
          }
          return l;
        });
      });
    }
    return false;
  }
}
