"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class DTheme {
}
exports.DTheme = DTheme;
DTheme.theme = {
    chatAiTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.chatAiTheme,
        chatBackground: "linear-gradient(180deg, #FDF6EC 0%, #F3DCCD 100%)",
        chatBackgroundPad: "linear-gradient(180deg, #FDF6EC 0%, #F3DCCD 100%)",
        chatBackgroundMobile: "linear-gradient(180deg, #FDF6EC 0%, #F3DCCD 100%)",
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBarBorderColor: "transparent",
        hightLightBorderLeft: "0",
        inputPaddingLeft: "25px",
        inputPadding: "15px 25px",
        inputBorderRadius: "30px",
        inputBorder: "1px solid rgba(18, 25, 43, 0.10)",
        inputBorderBottom: "1px solid rgba(18, 25, 43, 0.10)",
        inputBackground: "transparent",
        buttonColor: "#fff",
        buttonBackground: "#000101",
        valueColor: "#fff",
        toolTipBackground: "rgba(80, 35, 0, 0.40)",
        hintTextColor: "#12192B",
        tooltipBoxColor: "#12192B",
        seeFormatDescriptionColor: "#12192B",
    },
};
