/**
 * 原来 HomeUsePlugin 用到的这四个类型的基类
 *
 * - nodeDataRef
 * - edgeDataRef
 * - newEdgeDataRef
 * - newLineDataRef
 *
 */
export class BaseDataRef<S extends Record<any, any>, F extends Record<string, any> = Record<string, any>> {
  constructor(public state: S, public functions: F = {} as F) {}

  /**
   * 兼容原来使用 .current 语法调用
   */
  get current(): S & F {
    // 防止外部对 current 的监听，保证 current 不会发生改变
    return Object.assign(this.state, this.functions);
  }

  /**
   * 考虑暂时会手动赋值 functions
   *
   * @param functions
   */
  updateFunctions(functions: Partial<F>): void {
    Object.assign(this.functions, functions);
  }
}
