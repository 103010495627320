import lottie from "lottie-web";

import React, { useEffect, useRef } from "react";
import styles from "./StockLoading.module.css";
import loader from "./assets/generatingLoader.json";

function StockLoading({ loading }) {
  const lottieRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    if (lottieRef.current && loading) {
      animationRef.current = lottie.loadAnimation({
        animationData: loader,
        container: lottieRef.current,
      });

      return () => {
        animationRef.current.destroy();
      };
    }
  }, [lottieRef, loading]);

  if (loading !== true) return null;

  return (
    <div className={styles.loadingWrapper}>
      <div ref={lottieRef} className={styles.lottieLoader} />
    </div>
  );
}

export default StockLoading;
