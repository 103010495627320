import func from "@uikit/func";
import { PreviewAppValue } from "@uiview/views/PreviewApp/model/PreviewAppValue";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";

export class BlueprintExecution {
  private featureTags: FeatureTags;
  private saasUIInputData: any[];
  private viewbuilderStore: ViewbuilderStore;
  private saasUIData: PreviewAppValue;

  constructor(featureTags: FeatureTags, saasUIInputData: any[], viewbuilderStore: ViewbuilderStore, saasUIData: any) {
    this.featureTags = featureTags;
    this.saasUIInputData = saasUIInputData;
    this.viewbuilderStore = viewbuilderStore;
    this.saasUIData = saasUIData;
  }

  public async execute(): Promise<boolean> {
    if (
      this.featureTags.disableViewBuilderToolBoxIntegration === false &&
      !func.isEmpty(this.saasUIData?.viewBuilderGraph)
    ) {
      const inputs = this.viewbuilderStore.transformToInputs(this.saasUIInputData);
      await this.viewbuilderStore.executeAllBlueprint(inputs);
      return true;
    }
    return false;
  }
}
