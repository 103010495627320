import { Modal, Checkbox, Button } from "antd";
import { useSignal } from "@preact/signals-react";
import { useSelector } from "react-redux";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

import { WarningIcon } from "./assets/svg";
import styles from "./OneProjectOneApp.module.scss";
import { useEffect } from "react";
import { getIt } from "@uikit/getIt";
import { BrainClient as CorekitBrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { StudioProject } from "imagica-corekit/dist/base/project/domain/StudioProject";
import { AppItem } from "./AppItem";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { useHomePublicMethods } from "@uiview/views/ProjectCanvas/useHomeUtilMethods";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { ProjectLoader } from "@uikit/fotProcess/ProjectLoader";
import { FotSaveService } from "@uikit/service/FotSaveService";

export function OneProjectOneApp(): JSX.Element {
  const open = useSignal(false);
  const checked = useSignal<CheckboxValueType[]>([]);
  const gql = getIt(ObjectRelationGqlService);
  const brainClient = getIt(CorekitBrainClient);
  const previewAppStore = getIt(PreviewAppStore);
  const projectLoader = getIt(ProjectLoader);
  const fotSaveService = getIt(FotSaveService);

  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);
  const userMe = useSelector((state: any) => state.editor.me);
  const homePublicMethods = useHomePublicMethods();

  //  Preview App 大于等于2 则显示弹窗
  useEffect(() => {
    if (Array.isArray(selectedTemplate?.v3?.UI)) {
      if (selectedTemplate?.v3?.UI.length > 1) {
        open.value = true;
      }
    }
    // eslint-disable-next-line
  }, [selectedTemplate?.v3?.UI]);

  /**
   * 1、保留选择的previewApp中的第一个
   * 2、移除当前project上未选择的previewApp
   *    a.unpublish preview App
   * 3、create project，基于选择的除了第一个外的所有previewApp
   */
  const gotIt = (): void => {
    if (!checked.value?.length) {
      // 一个也没有选 直接全部清空previewApp
      open.value = false;
      previewAppStore.setShowPreviewPanel(false);
      previewAppStore.clearCurrentPreviewApp();
      previewAppStore.clearPublishMetadata();
      previewAppStore.clearPreviewApp();
      fotSaveService.handleSave({ background: true });
      return;
    }
    const oldUI = selectedTemplate?.v3?.UI;
    const oldPublished_metadata_v2 = selectedTemplate?.published_metadata_v2;
    if (!Array.isArray(oldUI)) return;
    const firstUI = keepCurrentProjectPreviewApp(oldUI, oldPublished_metadata_v2);
    createNewProjectFromOtherPreviewApp(firstUI, oldUI, oldPublished_metadata_v2);
  };

  // 保留选中的previewApp中的第一个作为当前project的唯一previewApp
  const keepCurrentProjectPreviewApp = (oldUI: any, oldPublished_metadata_v2: any): any => {
    let firstUI: any;
    let flag = false;
    oldUI.forEach((item: any) => {
      if (flag) return;
      if (checked.value.includes(item.id)) {
        flag = true;
        firstUI = item;
      }
    });
    open.value = false;
    if (firstUI) {
      const currentPublishMetadata = oldPublished_metadata_v2.filter((item: any) => {
        return item.id === firstUI.id;
      });
      const newSelectedTemplate = {
        ...selectedTemplate,
        published_metadata: currentPublishMetadata?.[0] || {},
        published_metadata_v2: currentPublishMetadata,
        v3: { ...selectedTemplate.v3, UI: [firstUI] },
        previewAppId: firstUI.id,
      };
      projectLoader.loadFromExistingTemplateV2(newSelectedTemplate);
      homePublicMethods.showPreviewContent();
      fotSaveService.handleSave({ background: true });
    }
    return firstUI;
  };

  // 依据除了第一个被选择之外的previewApp，创建新的project
  const createNewProjectFromOtherPreviewApp = async (
    firstUI: any,
    oldUI: any,
    oldPublished_metadata_v2: any
  ): Promise<void> => {
    const selectedUI: any[] = [];
    const remainUI: any[] = [];
    oldUI.forEach((item: any) => {
      if (checked.value.includes(item.id) && item.id !== firstUI?.id) {
        selectedUI.push(item);
      } else if (item.id !== firstUI?.id) {
        remainUI.push(item);
      }
    });
    await unpublish(remainUI);
    if (selectedUI.length) {
      createAndUpdateProject(selectedUI, oldPublished_metadata_v2);
    }
  };

  // unpublish remove preview app
  const unpublish = async (remainUI: any[]): Promise<void> => {
    let publishedMetadata: any[] = [];
    for (let i = 0; i < remainUI.length; i++) {
      const published = selectedTemplate.published_metadata_v2.filter((item: any) => item.id === remainUI[i].id);
      if (published?.[0]?.link) {
        publishedMetadata = [...publishedMetadata, ...published];
      }
    }
    for (let i = 0; i < publishedMetadata.length; i++) {
      const linkUrl = new URL(publishedMetadata[i]?.link.replace("#/", ""));
      const query = linkUrl.searchParams.get("q") ?? "";
      await brainClient.user.unpublish(query);
    }
  };

  const createAndUpdateProject = async (selectedUI: any, oldPublished_metadata_v2: any): Promise<void> => {
    for (let i = 0; i < selectedUI.length; i++) {
      const item = selectedUI[i];
      const currentPublishMetadata = oldPublished_metadata_v2.filter((meta: any) => {
        return meta.id === item.id;
      });
      const params = fotSaveService.getSaveDataV3();
      delete params.id;
      delete params.uuid;
      delete params.index;
      const projectObj = {
        attributes: {
          ...params,
          name: item.title,
          template_name: item.title,
          published_metadata: currentPublishMetadata?.[0] || {},
          published_metadata_v2: currentPublishMetadata,
          legacy_project_attr_indexer: selectedTemplate.index,
          v3: {
            ...params.v3,
            UI: [item],
            previewAppId: item.id,
          },
        },
      };
      const projectJsonStr = JsonUtil.stringify(projectObj);
      const projectData = JsonUtil.toModelFromType(StudioProject, projectJsonStr) as any;
      const newProject: any = await brainClient.graphql.createStudioProject(projectData);
      await gql.createRelation(
        {
          sourceId: parseInt(userMe.objectId),
          destId: newProject.id,
          type: "studio_project",
        },
        ""
      );
    }
  };

  const checkboxOnChange = (checkedValues: CheckboxValueType[]): void => {
    checked.value = checkedValues;
  };

  return (
    <Modal
      centered
      closable={false}
      footer={null}
      maskClosable={true}
      open={open.value}
      className={styles.oneProjectOneAppModal}
    >
      <div className={styles.oneProjectOneApp}>
        <div className={styles.header}>
          <WarningIcon />
          <span className={styles.headerTitle}>Important Update</span>
        </div>
        <div className={styles.des}>
          <div className={styles.content}>
            We're streamlining our app creation process to focus on quality and simplicity. As part of this, we've
            updated how projects work:
          </div>
          <div className={styles.title}>One App Per Project</div>
          <div className={styles.content}>Going forward, each project will support one app only.</div>
          <div className={styles.title}>Project Duplication</div>
          <div className={styles.content}>
            This project currently contains multiple apps; we can duplicate the project into multiple projects, one per
            app, allowing you to continue developing each app separately.{" "}
          </div>
          <div className={styles.title}>Please select which apps you'd like to keep as projects. </div>
          <div className={styles.hint}>If you don’t keep an app as a project, it will be deleted and unpublished.</div>
        </div>
        <div className={styles.appList}>
          <Checkbox.Group onChange={checkboxOnChange}>
            {Array.isArray(selectedTemplate?.v3?.UI) &&
              selectedTemplate?.v3?.UI?.map((item: any, index: number) => {
                return (
                  <AppItem
                    key={item.id + index}
                    {...item}
                    published_metadata_v2={selectedTemplate.published_metadata_v2}
                  />
                );
              })}
          </Checkbox.Group>
        </div>
        <div className={styles.btn}>
          <Button onClick={gotIt} type="primary" className={styles.gotIt}>
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
}
