import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import "../views-css/ChainThought.css";
import { fotActions } from "../store/fot";
import func from "@uikit/func";

function ChainThought(props) {
  const dispatch = useDispatch();

  const thoughtChain = useSelector(state => state.fot.thoughtChain);
  const showChainThoughts = useSelector(state => state.fot.showChainThoughts);
  const setThoughtChain = val => {
    dispatch(fotActions.setThoughtChain(val));
  };
  const setChainThoughtsState = val => {
    dispatch(fotActions.setChainThoughtsState(val));
  };

  const container = {
    init: {
      opacity: 0,
      y: "100%",
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        ease: "easeInOut",
        delayChildren: 0.1,
        staggerChildren: 0.05,
      },
    },
    hide: {
      opacity: 0,
      y: "100%",
      transition: {
        duration: 0.6,
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    init: {
      opacity: 0,
      y: "100%",
      scale: 0.8,
    },
    show: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
    hide: {
      opacity: 0,
      y: "100%",
      scale: 0.8,
      transition: {
        duration: 0.5,
        type: "spring",
        ease: "easeOut",
      },
    },
  };

  const clickThoughtItem = async function (thought) {
    // 设置chains为只有当前thought
    const prevChains = thoughtChain;
    try {
      // 关闭chain thought页面
      setChainThoughtsState(false);
      // 点击start a new thinking
      await props.enterQuery(thought.thought, "fromChainThought");
    } catch (error) {
      // 重置chains
      setThoughtChain(prevChains);
      console.error("clickThoughtItem", error);
      func.messageError(error);
    }
  };

  return (
    <AnimatePresence>
      {showChainThoughts ? (
        <motion.div
          className="Chain-Thought-Box"
          key="chainThoughtBox"
          variants={container}
          initial="init"
          animate="show"
          exit="hide"
        >
          <header>
            <p>Your journeys</p>
            <span
              className="iconfont icon-guanbi1"
              onClick={() => {
                setChainThoughtsState(false);
              }}
            ></span>
          </header>

          <div className="Thought-List">
            {thoughtChain.map((thought, id) => (
              <motion.div className="Thought-Item" key={id} variants={item} onClick={() => clickThoughtItem(thought)}>
                <p>{thought.thought}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export default ChainThought;
