"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeeeResponse = exports.WeeeData = exports.WeeePage = exports.WeeeItem = void 0;
class WeeeItem {
    constructor() {
        this.id = "";
        this.name = "";
        this.img = "";
        this.price = 1;
        this.base_price = 1;
        this.unit_range = "";
        this.sub_name = "";
    }
}
exports.WeeeItem = WeeeItem;
class WeeePage {
    constructor() {
        this.page = 1;
        this.pageSize = 1;
        this.totalCount = 1;
        this.totalPage = 1;
    }
}
exports.WeeePage = WeeePage;
class WeeeData {
    constructor() {
        this.source = "";
        this.items = [];
        this.link = "";
    }
}
exports.WeeeData = WeeeData;
class WeeeResponse {
    constructor() {
        this.done = false;
    }
}
exports.WeeeResponse = WeeeResponse;
