import { useCallback } from "react";
import { useStore } from "react-redux";
import func from "@uikit/func";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";
// import { creatorRefStore } from "./store/CreatorRefStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";

// eslint-disable-next-line
export default function useCompositionKeypressMethods() {
  const homePluginStore = getIt(HomePluginStore);
  const canvasDataRef = getIt(CanvasDataRef);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);

  const store = useStore();

  const homePluginState = useImagincStore(homePluginStore).value;

  const undoRef = homePluginStore.undoData;
  const share = homePluginState.isShare;
  const setSimplifiedEdgeNode = HomeMethodsUtil.setSimplifiedEdgeNode;

  const removeNodesEdgesAndRelevantNodesPosition = useCallback(
    (prevUndo: { nodes: any[]; relevantNodes: any[]; edges: any[] }, operateType: string | number) => {
      const typeObj = {
        undo: "startPosition",
        redo: "endPosition",
      } as any;
      const currKey = typeObj[operateType];

      creatorNodesStore.setNodes(prevList => {
        return prevList
          .filter(x => !prevUndo.nodes.some((y: { id: string }) => y.id === x.id))
          .map(x => {
            let newData = x;
            if (!func.isEmpty(prevUndo.relevantNodes)) {
              prevUndo.relevantNodes.forEach((y: { type: string; data: any[] }) => {
                if (y.type === "relevantMoveNode") {
                  y.data.forEach((z: { [x: string]: any; id: string }) => {
                    if (z.id === x.id) {
                      newData = {
                        ...x,
                        position: z[currKey],
                      };
                    }
                  });
                }
              });
            }
            return newData;
          });
      });
      creatorEdgesStore.setEdges(prevList => {
        return prevList.filter(x => {
          return !prevUndo.edges.some((y: { id: string }) => y.id === x.id);
        });
      });
    },
    []
  );
  const addNodesEdges = useCallback(
    (prevUndo: { nodes: any[]; edges: any[]; relevantNodes: any[] }, operateType: string | number) => {
      const originNodes = prevUndo.nodes.map((x: any) => {
        return setSimplifiedEdgeNode(x, canvasDataRef);
      });
      const originEdges = prevUndo.edges.map((x: any) => {
        const originEdge = setSimplifiedEdgeNode(x, canvasDataRef);
        return {
          ...originEdge,
          data: {
            ...originEdge.data,
            isUndoRedoAdd: true, // isUndoRedoAdd标记edge数据触发了undo/redo的add操作
          },
        };
      });
      const typeObj = {
        undo: "startPosition",
        redo: "endPosition",
      } as any;
      const currKey = typeObj[operateType];

      creatorNodesStore.setNodes(prevList => {
        const newPostionList = prevList.map(x => {
          let newData = x;
          if (!func.isEmpty(prevUndo.relevantNodes)) {
            prevUndo.relevantNodes.forEach((y: { type: string; data: any[] }) => {
              if (y.type === "relevantMoveNode") {
                y.data.forEach((z: { [x: string]: any; id: string }) => {
                  if (z.id === x.id) {
                    newData = {
                      ...x,
                      position: z[currKey],
                    };
                  }
                });
              }
            });
          }
          return newData;
        });
        return [...newPostionList, ...originNodes];
      });
      //  creatorNodesStore.setNodes(prevList => [...prevList, ...prevUndo.nodes])
      creatorEdgesStore.setEdges(prevList => [...prevList, ...originEdges]);
    },
    [setSimplifiedEdgeNode]
  );

  const setMoveNodesPosition = useCallback((prevUndo: { nodes: any[] }, operateType: string | number) => {
    const typeObj = {
      undo: "startPosition",
      redo: "endPosition",
    } as any;
    creatorNodesStore.setNodes(prevList => {
      return prevList.map(x => {
        const currUndoNode = prevUndo.nodes.find((y: { id: string }) => y.id === x.id);
        if (!func.isEmpty(currUndoNode)) {
          return {
            ...x,
            position: {
              ...currUndoNode[typeObj[operateType]],
            },
          };
        }
        return x;
      });
    });
  }, []);
  const resizeNodes = useCallback(
    (prevUndo: { node: { [x: string]: any }; callback: (params: any) => any }, operateType: string | number) => {
      const typeObj = {
        undo: "startResizeNode",
        redo: "endReizeNode",
      } as any;
      const currentNode = prevUndo.node[typeObj[operateType]];

      creatorNodesStore.setNodes(prevList => {
        return prevList.map(x => {
          if (currentNode.id === x.id) {
            return {
              ...x,
              style: {
                width: currentNode.width,
                height: currentNode.height,
              },
              position: {
                ...currentNode.position,
              },
            };
          }
          return x;
        });
      });
      prevUndo.callback({ width: currentNode.width, height: currentNode.height });
    },
    []
  );
  const setNodesContentAndType = useCallback((prevUndo: { nodes: any[] }, operateType: string | number) => {
    const typeObj = {
      undo: "startNodeContent",
      redo: "endNodeContent",
    } as any;
    const currKey = typeObj[operateType];
    creatorNodesStore.setNodes(prevList => {
      return prevList.map(x => {
        const currPrevNode = prevUndo.nodes.find((y: { [x: string]: { id: string } }) => y[currKey].id === x.id)?.[
          currKey
        ];
        if (!func.isEmpty(currPrevNode)) {
          return {
            ...x,
            data: {
              ...x.data,
              textAreaValue: currPrevNode.textAreaValue ?? x.data.textAreaValue,
              displayType: currPrevNode.displayType || x.data.displayType,
            },
          };
        }
        return x;
      });
    });
  }, []);
  const setEdgesContent = useCallback((prevUndo: Record<string, any>, operateType: string | number) => {
    const typeObj = {
      undo: "startEdgeData",
      redo: "endEdgeData",
    } as any;
    const currEdge = prevUndo.edges[0][typeObj[operateType]];
    creatorNodesStore.setNodes(prevList => {
      return prevList.map(x => {
        if (x.id === prevUndo.edges?.[0]?.id) {
          return {
            ...x,
            data: {
              ...x.data,
              isGetQuery: currEdge.isGetQuery,
              lineParam: JSON.parse(currEdge.lineParamStr),
            },
          };
        }
        return x;
      });
    });
  }, []);
  const handleUndo = useCallback(() => {
    if (!func.isEmpty(undoRef.current)) {
      const prevUndo = undoRef.current[homeToolBarStore.state.historyIndex + 1];

      if (func.isEmpty(prevUndo)) return;

      const handleTypeObj = {
        add: removeNodesEdgesAndRelevantNodesPosition,
        delete: addNodesEdges,
        drag: setMoveNodesPosition,
        resize: resizeNodes,
        nodeContentChange: setNodesContentAndType,
        edgeContentChange: setEdgesContent,
      } as any;
      handleTypeObj[prevUndo.type]?.(prevUndo, "undo");

      homeToolBarStore.setHistoryIndex(homeToolBarStore.state.historyIndex + 1);
    }
  }, [
    addNodesEdges,
    removeNodesEdgesAndRelevantNodesPosition,
    resizeNodes,
    setEdgesContent,
    setMoveNodesPosition,
    setNodesContentAndType,
    undoRef,
  ]);

  const handleRedo = useCallback(() => {
    if (!func.isEmpty(undoRef.current)) {
      const prevRedo = undoRef.current[homeToolBarStore.state.historyIndex];
      if (func.isEmpty(prevRedo)) return;
      const handleTypeObj = {
        add: addNodesEdges,
        delete: removeNodesEdgesAndRelevantNodesPosition,
        drag: setMoveNodesPosition,
        resize: resizeNodes,
        nodeContentChange: setNodesContentAndType,
        edgeContentChange: setEdgesContent,
      } as any;
      handleTypeObj[prevRedo.type]?.(prevRedo, "redo");

      homeToolBarStore.setHistoryIndex(homeToolBarStore.state.historyIndex - 1);
    }
  }, [
    addNodesEdges,
    removeNodesEdgesAndRelevantNodesPosition,
    resizeNodes,
    setEdgesContent,
    setMoveNodesPosition,
    setNodesContentAndType,
    undoRef,
  ]);
  const handleCompositionKeyPress = useCallback(
    (e: {
      keyCode: any;
      which: any;
      charCode: any;
      ctrlKey: any;
      metaKey: any;
      shiftKey: any;
      preventDefault: () => void;
    }) => {
      const keyCode = e.keyCode || e.which || e.charCode;
      const ctrlKey = e.ctrlKey || e.metaKey;
      const shiftKey = e.shiftKey;
      const isCreateApiFuncOpen = (store.getState() as any).editor.createAPIFuncData.open;
      const isCreateJSFuncOpen = (store.getState() as any).editor.createJSFuncData.open;
      const isCustomNodeInputFocus = (store.getState() as any).editor.isCustomNodeInputFocus;
      // share页面或者create api function弹窗打开时不执行
      if (share || isCreateApiFuncOpen || isCreateJSFuncOpen || isCustomNodeInputFocus || !ctrlKey || keyCode !== 90)
        return;

      e.preventDefault();
      // ctrl/command+z撤销上次操作
      if (!shiftKey) {
        handleUndo();
        return;
      }
      // ctrl/command+shift+z撤销undo
      handleRedo();
    },
    [handleRedo, handleUndo, share, store]
  );

  return {
    handleUndo,
    handleRedo,
    handleCompositionKeyPress,
  };
}
