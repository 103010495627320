import { PreviewStore } from "@uiview/store/PreviewStore";
import { PreviewPaneStore, SettingPageContentType } from "../PreviewPane/PreviewPaneStore";

export class HomeMobileUtils {
  static showChat(previewPaneStore: PreviewPaneStore): void {
    previewPaneStore.setMobileChatStatus(true);
    previewPaneStore.settingPageStatus(false);
    previewPaneStore.setSettingPageContentType(SettingPageContentType.none);
  }

  static showPreview(previewStore: PreviewStore, previewPaneStore: PreviewPaneStore): void {
    previewPaneStore.setMobileChatStatus(false);
    previewStore.setShowPreviewPanel(true);
    previewPaneStore.settingPageStatus(false);
    previewPaneStore.setSettingPageContentType(SettingPageContentType.none);
  }

  static showAppDesign(previewPaneStore: PreviewPaneStore): void {
    previewPaneStore.setMobileChatStatus(false);
    previewPaneStore.settingPageStatus(true);
    previewPaneStore.setSettingPageContentType(SettingPageContentType.design);
  }

  static showAdvancedFeatures(previewPaneStore: PreviewPaneStore): void {
    previewPaneStore.setMobileChatStatus(false);
    previewPaneStore.settingPageStatus(true);
    previewPaneStore.setSettingPageContentType(SettingPageContentType.features);
  }
}
