// ResponseStatus.tsx
import React from "react";
import style from "./Response.module.css";
import { ApiResponse } from "./CustomApiPageBloc";

interface ResponseStatusProps {
  responseData: ApiResponse | null;
}

const failedReg = /^4|^5/;

const ResponseStatus: React.FC<ResponseStatusProps> = ({ responseData }) => {
  if (!responseData) return <></>;
  const stringValue: string = responseData.status.toString();

  return (
    <div className={style["response-status"]} data-testid="response-status">
      <p>
        <span>Status: </span>
        <span
          data-testid="response-status-value"
          className={!failedReg.test(stringValue) ? style["ok-status"] : style["failed-status"]}
        >
          {responseData.status}&nbsp;{responseData.statusText}
        </span>
      </p>
      <p>
        <span>Time: </span>
        <span>{responseData.time}</span>
      </p>
      <p>
        <span>Size: </span>
        <span>{responseData.size}</span>
      </p>
    </div>
  );
};

export default ResponseStatus;
