import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import cls from "classnames";
import style from "./style.module.scss";
import { SidebarItems, SidebarMenuItem } from "./types";
import { getAssetsIcon } from "@views/thinking-layout-editor/utilities";
import { animated, useSpring } from "@react-spring/web";

export interface SidebarProps {
  sidebarItem: SidebarItems;
  onSidebarItemChange: (sidebarItem: SidebarItems) => void;
}

const Sidebar: FC<SidebarProps> = ({ sidebarItem, onSidebarItemChange }) => {
  // const [active, activeApi] = useSpring(() => ({}));
  const [activeTop, setActiveTop] = useState(0);
  const createNewProjectMenuItem = useMemo<SidebarMenuItem>(
    () => ({
      icon: "",
      index: 0,
      item: SidebarItems.CreateNewProject,
      title: "Create new project",
    }),
    []
  );
  const sidebarMenuItems = useMemo<SidebarMenuItem[]>(
    () => [
      {
        index: 1,
        item: SidebarItems.Templates,
        icon: getAssetsIcon("grid.svg"),
        title: "Templates",
      },
      {
        index: 2,
        item: SidebarItems.MyProjects,
        icon: getAssetsIcon("file-document.svg"),
        title: "My Projects",
      },
      {
        index: 3,
        item: SidebarItems.MyFunctions,
        icon: getAssetsIcon("function.svg"),
        title: "My Functions",
      },
    ],
    []
  );

  useEffect(() => {
    if (sidebarItem === SidebarItems.CreateNewProject) {
      return;
    }
    const sidebarMenuItem = sidebarMenuItems.find(v => v.item === sidebarItem);
    if (!sidebarMenuItem) {
      return;
    }
    let top = 70 + (sidebarMenuItem.index - 1) * 70;
    setActiveTop(top);
    // activeApi.start({
    //   config: { duration: 500 },
    //   to: {
    //     top: `${top}px`,
    //   },
    // });
  }, [sidebarItem, sidebarMenuItems]);

  const handleSidebarItemClick = useCallback(
    (item: SidebarMenuItem) => (_event: MouseEvent<HTMLDivElement>) => {
      onSidebarItemChange(item.item);
    },
    [onSidebarItemChange]
  );

  return (
    <aside className={style.sidebar}>
      {/* <animated.div className={cls(style.sidebar__active)} style={active}></animated.div> */}
      <animated.div className={cls(style.sidebar__active)} style={{ top: `${activeTop}px` }}></animated.div>
      <div className={style.sidebar__item}>
        <div
          className={cls(style.sidebarItem, style.sidebarItem_new)}
          onClick={handleSidebarItemClick(createNewProjectMenuItem)}
        >
          <div className={style.sidebarItem__icon}>
            <img className={style.icon} src={getAssetsIcon("plus.svg")} alt="add" />
          </div>
          <div className={style.sidebarItem__text}>
            <span className={cls(style.text, style.text_sidebarItemNew)}>Create new project</span>
          </div>
        </div>
      </div>

      {sidebarMenuItems.map(item => (
        <div className={style.sidebar__item} key={item.index}>
          <div className={cls(style.sidebarItem)} onClick={handleSidebarItemClick(item)}>
            <div className={style.sidebarItem__icon}>
              <img className={style.icon} src={item.icon} alt="templates" />
            </div>
            <div className={style.sidebarItem__text}>
              <span className={cls(style.text, style.text_sidebarItem)}>{item.title}</span>
            </div>
          </div>
        </div>
      ))}
    </aside>
  );
};

export default Sidebar;
