import { UploadFile, UploadProps } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { Api } from "../uikit/service/api";
import func from "@uikit/func";
import { supportList } from "@uiview/views/Nodes/constants";
import { isNumber } from "lodash";

type ArgsType = {
  setEdgeLineParam: (args: any) => void;
  lineParam: any;
};

type FileType<T = any> = UploadFile<T> & {
  s3Path?: string;
  s3Url?: string;
};

type LoaderType = "PDFReader" | "BeautifulSoupWebReader";

type RequestParamType = {
  loader: LoaderType;

  /**
   * loader=BeautifulSoupWebReader
   */
  urls?: string[];

  /**
   * loader=PDFReader
   * s3Path
   */
  file?: string;

  /**
   * loader=PDFReader
   */
  s3Url?: string;

  /**
   * loader=PDFReader
   */
  name?: string;
};

const getNameByS3Path = (s3Path: string): string => {
  const reg = /\/([^\/]*)\b$/g;
  const arr = reg.exec(s3Path);
  return arr?.[1] || "";
};

function isSupportFormat(fileName: string): boolean {
  const [, fileFormat] = /(?:\.([^.]+))?$/.exec(fileName) || [];
  return supportList.includes(fileFormat.toUpperCase());
}

const unixTimestamp = (): number => Math.ceil(Date.now() / 1000);
const getUnioFilename = (filename: string): string => {
  const files = filename.split(".");
  const ext = files.length > 1 ? files.pop() || "" : "";
  const time = unixTimestamp().toString();

  files.push(time);
  files.push(ext);
  return files.join(".");
};

/**
 * 整合 sourceOfTurth 和 dragDropFile 组件逻辑，为了同时满足 /add data source 和上传文件操作
 *
 * @todo DragDropFile
 * @param param0
 * @returns
 */
export default function useSourceOfTurth({ setEdgeLineParam, lineParam }: ArgsType) {
  const api = new Api(false);

  const [fileList, setFileList] = useState<FileType<any>[]>([]);
  const [preFileUrl, setPreFileUrl] = useState("");
  const [fileUuid, setFileUuid] = useState<string | undefined>(lineParam.fileUuid);

  /**
   * 用于通过初始化 lineParam 数据更新状态(fileList,preFileUrl)
   */
  const initLineParamRef = useRef(0);

  const beforeUpload: UploadProps<any>["beforeUpload"] = (file, files): any => {
    // only support one file

    if (fileList.length > 0) {
      func.customMsg({
        content: "Only one file can be uploaded",
        type: "warning",
      });
      return false;
    }

    if (supportList.every(item => !file.name.toUpperCase().endsWith(item))) {
      func.customMsg({
        content: "The file format is not supported",
        type: "warning",
      });
      return false;
    }
    setFileList([file]);
    return true;
  };

  const removeFile = (): void => {
    setFileList([]);
  };

  // const uploadFileWithMeta = async () => {
  //   const newFile = await uploadS3();
  //   // if (newFile.status === 'error') {
  //   if (newFile.status !== "error" && newFile.s3Path) {
  //     const { s3key } = createTmpVal(newFile.s3Path);
  //     const downloadUrl = getDownloadURL(s3key);
  //     const previewUrl = await downloadMetaData(downloadUrl);
  //     console.log("previewUrl", previewUrl);
  //   }

  //   setFileList([newFile]);
  // };

  // const uploadFileWithS3 = async () => {
  //   const newFile = await uploadS3();
  //   setFileList([newFile]);
  // };

  const onFileError = (file: any, error: any): void => {
    func.messageError(error);
    file.status = "error";
    setFileList([file]);
  };

  const uploadS3 = (): void => {
    const file = fileList[0] as FileType;
    file.status = "uploading";
    setFileList([file]);

    if (isNumber(file.size) && file.size > 50 * 1024 * 1000) {
      // throw new Error("We accept the following files, under 50 Mb size");
      onFileError(file, { message: "We accept the following files, under 50 Mb size" });
      return;
    }
    if (!isSupportFormat(file.name)) {
      // throw new Error("Not a supported file type");
      onFileError(file, { message: "Not a supported file type" });
      return;
    }

    const form = new FormData();
    // 上传文件时，将文件名携带一个时间戳保证文件唯一
    const uploadFileName = getUnioFilename(file.name);
    form.append("file", file as unknown as Blob, uploadFileName);

    api
      .postOtherTypeDataWithError(`/api/me/upload/studios`, form)
      .then(res => {
        file.status = "success";
        file.s3Path = res?.data?.object_key;
        // from DragDropFile
        file.s3Url = res?.data?.object_s3_url || res?.data?.s3_https_url || "";
        setFileList([file]);
        setFileUuid(res?.data?.uuid);

        if (!file.s3Path) {
          throw Error("Failed to get s3 path");
        }
      })
      .catch(error => {
        onFileError(file, error);
      });
  };

  const setPDFReader = useCallback(() => {
    const file = fileList[0];
    setEdgeLineParam({
      requestParam: {
        loader: "PDFReader",
        file: file?.s3Path,
        name: file?.name,
        s3Url: file?.s3Url,
      } as RequestParamType,
      fileUuid: fileUuid,
    });
  }, [fileList, setEdgeLineParam]);

  const setBeautifulSoupWebReader = useCallback(() => {
    setEdgeLineParam({
      requestParam: {
        loader: "BeautifulSoupWebReader",
        // 当删除文件时，需要将 urls 也重置，`useCustomEdgeDisabled` 中有对 lineParam 的 urls 和 file 做判断
        urls: preFileUrl ? [preFileUrl.trim()] : undefined,
      } as RequestParamType,
      fileUuid: fileUuid,
    });
  }, [setEdgeLineParam, preFileUrl]);

  useEffect(() => {
    setEdgeLineParam({ lineType: "function" });
    //   const fileNameFromProps = lineParam?.file?.name;
    //   setPreFileUrl(lineParam?.webUrl || "");
    //   if (fileNameFromProps) {
    //     const fileFromProps = new File([], fileNameFromProps);
    //     const file = fileFromProps as unknown as FileType;

    //     file.s3Path = lineParam?.file?.s3Path;
    //     file.s3Url = lineParam?.file?.s3Url;
    //     setFileList([file]);
    //   }
  }, []);

  //
  // 通过保存的边的数据初始化 fileList 和 preFileUrl 状态
  const requestParam = lineParam?.requestParam as RequestParamType | undefined;
  const loader = requestParam?.loader;
  const pdfReaderFile = requestParam?.file;
  const beautifulSoupWebURL = requestParam?.urls?.[0] || "";

  useEffect(() => {
    if (initLineParamRef.current) {
      return;
    }

    if (loader === "PDFReader" && pdfReaderFile) {
      // 确保数据完整性
      const fileName = requestParam?.name ? requestParam.name : getNameByS3Path(pdfReaderFile);
      const s3Url = requestParam?.s3Url; //? requestParam?.s3Url : getS3UrlByS3Path(pdfReaderFile);
      const file = new File([], fileName) as unknown as FileType;
      file.s3Path = pdfReaderFile;
      file.s3Url = s3Url;
      file.status = "success";

      setFileList([file]);

      // 置为true,标识只通过一次对外部数据的操作
      initLineParamRef.current++;
    }

    if (loader === "BeautifulSoupWebReader") {
      setPreFileUrl(beautifulSoupWebURL);

      // 置为true,标识只通过一次对外部数据的操作
      initLineParamRef.current++;
    }
  }, [pdfReaderFile, beautifulSoupWebURL]);

  // Use the latest change, use another when the latest is empty.
  useEffect(() => {
    const file = fileList[0];

    if (file) {
      setPDFReader();
    } else {
      setBeautifulSoupWebReader();
    }
  }, [fileList, fileUuid]);

  useEffect(() => {
    if (preFileUrl.trim()) {
      setBeautifulSoupWebReader();
    } else {
      setPDFReader();
    }
  }, [preFileUrl, fileUuid]);

  // useEffect(() => {
  //   if (util.isEmpty(fileList)) return;
  //   if (fileList[0].status !== "success") {
  //     uploadFileWithMeta();
  //   }
  // }, [fileList?.[0]]);

  return {
    removeFile,
    uploadS3,
    beforeUpload,
    fileList,
    setPreFileUrl,
    preFileUrl,
  };
}
