import { Spin } from "antd";
import style from "./ProjectNameButton.module.scss";
import { LoadingOutlined } from "@ant-design/icons";

type ProjectNameBtnProps = {
  loading: boolean;
  disable?: boolean;
};

type TitleItemProps = {
  title: string;
};

export function MenuGroupTitle(props: TitleItemProps): JSX.Element {
  return <div className={style.menu_group_title}>{props.title}</div>;
}

export function EditName(props: ProjectNameBtnProps): JSX.Element {
  return (
    <div className={style.menu_item} style={{ color: props.disable ? "#00000040" : "#000000d9" }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.5 18.0003H11.5M1 18.5003L6.54927 16.366C6.90421 16.2295 7.08168 16.1612 7.24772 16.0721C7.3952 15.9929 7.5358 15.9015 7.66804 15.7989C7.81692 15.6834 7.95137 15.5489 8.22028 15.28L17.5 6.0003C18.6046 4.89574 18.6046 3.10487 17.5 2.0003C16.3955 0.895734 14.6046 0.895732 13.5 2.0003L4.22028 11.28C3.95138 11.5489 3.81692 11.6834 3.70139 11.8323C3.59877 11.9645 3.5074 12.1051 3.42823 12.2526C3.33911 12.4186 3.27085 12.5961 3.13433 12.951L1 18.5003ZM1 18.5003L3.05812 13.1493C3.2054 12.7663 3.27903 12.5749 3.40534 12.4872C3.51572 12.4105 3.6523 12.3816 3.7843 12.4068C3.93533 12.4356 4.08038 12.5807 4.37048 12.8708L6.62957 15.1299C6.91967 15.4199 7.06472 15.565 7.09356 15.716C7.11877 15.848 7.08979 15.9846 7.01314 16.095C6.92545 16.2213 6.73399 16.2949 6.35107 16.4422L1 18.5003Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span>Rename</span>
      {props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
    </div>
  );
}

export function SaveProject(props: ProjectNameBtnProps): JSX.Element {
  return (
    <div className={style.menu_item} style={{ color: props.disable ? "#00000040" : "#000000d9" }}>
      <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 1.26946V5.4C12 5.96005 12 6.24008 12.109 6.45399C12.2049 6.64215 12.3578 6.79513 12.546 6.89101C12.7599 7 13.0399 7 13.6 7H17.7305M18 8.98822V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V5.8C2 4.11984 2 3.27976 2.32698 2.63803C2.6146 2.07354 3.07354 1.6146 3.63803 1.32698C4.27976 1 5.11984 1 6.8 1H10.0118C10.7455 1 11.1124 1 11.4577 1.08289C11.7638 1.15638 12.0564 1.27759 12.3249 1.44208C12.6276 1.6276 12.887 1.88703 13.4059 2.40589L16.5941 5.59411C17.113 6.11297 17.3724 6.3724 17.5579 6.67515C17.7224 6.94356 17.8436 7.2362 17.9171 7.5423C18 7.88757 18 8.25445 18 8.98822Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span>Save project</span>
      {props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
    </div>
  );
}

export function ShareProject(props: ProjectNameBtnProps): JSX.Element {
  return (
    <div className={style.menu_item} style={{ color: props.disable ? "#00000040" : "#000000d9" }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 10L18 2M18 2H13M18 2V7"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 3H6.8C5.11984 3 4.27976 3 3.63803 3.32698C3.07354 3.6146 2.6146 4.07354 2.32698 4.63803C2 5.27976 2 6.11984 2 7.8V13.2C2 14.8802 2 15.7202 2.32698 16.362C2.6146 16.9265 3.07354 17.3854 3.63803 17.673C4.27976 18 5.11984 18 6.8 18H12.2C13.8802 18 14.7202 18 15.362 17.673C15.9265 17.3854 16.3854 16.9265 16.673 16.362C17 15.7202 17 14.8802 17 13.2V12"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span>Share project</span>
      {props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
    </div>
  );
}

export function DuplicateProject(props: ProjectNameBtnProps): JSX.Element {
  return (
    <div className={style.menu_item} style={{ color: props.disable ? "#00000040" : "#000000d9" }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 6V4.2C6 3.07989 6 2.51984 6.21799 2.09202C6.40973 1.71569 6.71569 1.40973 7.09202 1.21799C7.51984 1 8.07989 1 9.2 1H15.8C16.9201 1 17.4802 1 17.908 1.21799C18.2843 1.40973 18.5903 1.71569 18.782 2.09202C19 2.51984 19 3.07989 19 4.2V10.8C19 11.9201 19 12.4802 18.782 12.908C18.5903 13.2843 18.2843 13.5903 17.908 13.782C17.4802 14 16.9201 14 15.8 14H14M4.2 19H10.8C11.9201 19 12.4802 19 12.908 18.782C13.2843 18.5903 13.5903 18.2843 13.782 17.908C14 17.4802 14 16.9201 14 15.8V9.2C14 8.07989 14 7.51984 13.782 7.09202C13.5903 6.71569 13.2843 6.40973 12.908 6.21799C12.4802 6 11.9201 6 10.8 6H4.2C3.07989 6 2.51984 6 2.09202 6.21799C1.71569 6.40973 1.40973 6.71569 1.21799 7.09202C1 7.51984 1 8.07989 1 9.2V15.8C1 16.9201 1 17.4802 1.21799 17.908C1.40973 18.2843 1.71569 18.5903 2.09202 18.782C2.51984 19 3.07989 19 4.2 19Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span>Duplicate project</span>
      {props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
    </div>
  );
}

export function DeleteProject(props: ProjectNameBtnProps): JSX.Element {
  return (
    <div className={style.menu_item} style={{ color: props.disable ? "#00000040" : "#DE1C22" }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 5V4.2C13 3.07989 13 2.51984 12.782 2.09202C12.5903 1.71569 12.2843 1.40973 11.908 1.21799C11.4802 1 10.9201 1 9.8 1H10.2C9.07989 1 8.51984 1 8.09202 1.21799C7.71569 1.40973 7.40973 1.71569 7.21799 2.09202C7 2.51984 7 3.07989 7 4.2V5M2 5H18M16 5V14.2C16 15.8802 16 16.7202 15.673 17.362C15.3854 17.9265 14.9265 18.3854 14.362 18.673C13.7202 19 12.8802 19 11.2 19H8.8C7.11984 19 6.27976 19 5.63803 18.673C5.07354 18.3854 4.6146 17.9265 4.32698 17.362C4 16.7202 4 15.8802 4 14.2V5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span style={{ color: props.disable ? "#00000040" : "#DE1C22" }}>Delete project</span>

      {props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
    </div>
  );
}

export function PublishApp(props: ProjectNameBtnProps): JSX.Element {
  return (
    <div className={style.menu_item} style={{ color: props.disable ? "#00000040" : "#000000d9" }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 11L2 6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2L13.2 2C14.8802 2 15.7202 2 16.362 2.32698C16.9265 2.6146 17.3854 3.07354 17.673 3.63803C18 4.27976 18 5.11984 18 6.8L18 11"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 12L10 8M10 8L14 12M10 8L10 19"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span>Publish app</span>
      {props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
    </div>
  );
}

export function CopyLink(props: ProjectNameBtnProps): JSX.Element {
  return (
    <div className={style.menu_item} style={{ color: props.disable ? "#00000040" : "#000000d9" }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.2308 4.10999L11.1708 3.17001C11.5426 2.79868 11.9838 2.50422 12.4694 2.30347C12.955 2.10272 13.4754 1.99959 14.0008 2C14.7863 2.0052 15.5528 2.24154 16.2048 2.67954C16.8568 3.11754 17.3655 3.7378 17.6673 4.46298C17.9691 5.18816 18.0507 5.98616 17.9019 6.75742C17.7532 7.52868 17.3806 8.23907 16.8308 8.79999L13.8308 11.8C13.3989 12.2326 12.8736 12.5606 12.2952 12.7585C11.7168 12.9565 11.1008 13.0193 10.4944 12.942C9.88793 12.8648 9.30727 12.6496 8.797 12.3129C8.28672 11.9763 7.8604 11.5271 7.55078 11"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.76831 15.89L8.82831 16.83C8.07775 17.5806 7.05978 18.0022 5.99832 18.0022C4.93687 18.0022 3.9189 17.5806 3.16833 16.83C2.41777 16.0794 1.99609 15.0615 1.99609 14C1.99609 12.9385 2.41777 11.9206 3.16833 11.17L6.16833 8.17001C6.54009 7.79868 6.98134 7.50422 7.46692 7.30347C7.9525 7.10272 8.47288 6.99959 8.99832 7C9.69516 6.99941 10.3801 7.18086 10.9852 7.5264C11.5903 7.87194 12.0947 8.36957 12.4483 8.97"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span style={{ color: props.disable ? "#00000040" : "#000000d9" }}>Copy published link</span>
      {props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
    </div>
  );
}

export function QRCodePNBItem(props: ProjectNameBtnProps): JSX.Element {
  return (
    <div className={style.menu_item} style={{ color: props.disable ? "#00000040" : "#000000d9" }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4016_97056)">
          <path
            d="M7.91797 5.41406V7.91406H5.41797V5.41406H7.91797ZM9.16797 4.16406H4.16797V9.16406H9.16797V4.16406ZM7.91797 12.0807V14.5807H5.41797V12.0807H7.91797ZM9.16797 10.8307H4.16797V15.8307H9.16797V10.8307ZM14.5846 5.41406V7.91406H12.0846V5.41406H14.5846ZM15.8346 4.16406H10.8346V9.16406H15.8346V4.16406ZM10.8346 10.8307H12.0846V12.0807H10.8346V10.8307ZM12.0846 12.0807H13.3346V13.3307H12.0846V12.0807ZM13.3346 10.8307H14.5846V12.0807H13.3346V10.8307ZM10.8346 13.3307H12.0846V14.5807H10.8346V13.3307ZM12.0846 14.5807H13.3346V15.8307H12.0846V14.5807ZM13.3346 13.3307H14.5846V14.5807H13.3346V13.3307ZM14.5846 12.0807H15.8346V13.3307H14.5846V12.0807ZM14.5846 14.5807H15.8346V15.8307H14.5846V14.5807ZM18.3346 5.83073H16.668V3.33073H14.168V1.66406H18.3346V5.83073ZM18.3346 18.3307V14.1641H16.668V16.6641H14.168V18.3307H18.3346ZM1.66797 18.3307H5.83464V16.6641H3.33464V14.1641H1.66797V18.3307ZM1.66797 1.66406V5.83073H3.33464V3.33073H5.83464V1.66406H1.66797Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_4016_97056">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span style={{ color: props.disable ? "#00000040" : "#000000d9" }}>View published QR Code</span>
      {props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
    </div>
  );
}

export function UnpublishApp(props: ProjectNameBtnProps): JSX.Element {
  return (
    <div className={style.menu_item} style={{ color: props.disable ? "#00000040" : "#DE1C22" }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9.99844" cy="9.99844" r="8.1" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
        <path d="M4 4L16 16" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      </svg>
      <span style={{ color: props.disable ? "#00000040" : "#DE1C22" }}>Unpublish app</span>
      {props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
    </div>
  );
}
