import { GROUPID_REG, NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import { StudioProjectAttributesV3Line } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Line";
import { StudioProjectAttributesV3Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Node";
import { StudioProjectAttributesV3NodeData } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3NodeData";

export class CopyUtil {
  static copyFlow(
    checkEdges: string[],
    checkNodes: string[],
    nodes: StudioProjectAttributesV3Node[],
    lines: StudioProjectAttributesV3Line[]
  ) {
    let copyNodes: string[] = [];
    let copyLines: string[] = [];

    checkEdges.forEach(lineId => {
      const line = this.getLine(lineId, lines);
      let edgeNodeId = "";
      let others: string[] = [];
      let sourceLineSelect = false;

      if (NEW_EDGE_REG.test(line.source)) {
        edgeNodeId = line.source;
        others = lines.filter(l => l.target === edgeNodeId).map(l => l.id);
        // 看是否有source边被选中
        sourceLineSelect = this.hasSourceLineSelect(others, checkEdges);
      }

      if (NEW_EDGE_REG.test(line.target)) {
        edgeNodeId = line.target;
        others = lines.filter(l => l.source === edgeNodeId).map(l => l.id);
      }

      if (checkNodes.includes(edgeNodeId) || sourceLineSelect) {
        copyLines.push(lineId);
        copyLines.push(...others);
      }
    });

    copyLines.forEach((lineId: string) => {
      const copys = this.getNeedCopyNode(lineId, lines, nodes);
      copyNodes.push(...copys);
    });

    // checknode 中有group node，需要把child node 也copy
    checkNodes.forEach((nodeId: string) => {
      const childNodes = this.getNeedCopyChildNode(nodeId, nodes);
      childNodes.forEach((childNodeId: string) => {
        if (!checkNodes.includes(childNodeId)) {
          copyNodes.push(childNodeId);
        }
      });
    });

    return {
      nodeIds: Array.from(new Set([...copyNodes, ...checkNodes])),
      lineIds: Array.from(new Set(copyLines)),
    };
  }

  // 获取node 是group 的childnode
  static getNeedCopyChildNode(nodeId: string, nodes: StudioProjectAttributesV3Node[]): string[] {
    const copys: string[] = [];
    if (GROUPID_REG.test(nodeId)) {
      const node = this.getNode(nodeId, nodes);
      copys.push(...((node.data as StudioProjectAttributesV3NodeData).childNode || []));
    }
    return copys;
  }

  static pasteFlow(edgeId: string, nodes: StudioProjectAttributesV3Node[]) {}

  // 根据linId获取需要copy的NodeId
  static getNeedCopyNode(
    lineId: string,
    lines: StudioProjectAttributesV3Line[],
    nodes: StudioProjectAttributesV3Node[]
  ): string[] {
    const copys: string[] = [];
    const line = this.getLine(lineId, lines);

    if (GROUPID_REG.test(line.source)) {
      const node = this.getNode(line.source, nodes);
      copys.push(...((node.data as StudioProjectAttributesV3NodeData).childNode || []));
    }

    if (GROUPID_REG.test(line.target)) {
      const node = this.getNode(line.target, nodes);
      copys.push(...((node.data as StudioProjectAttributesV3NodeData).childNode || []));
    }

    copys.push(line.source);
    copys.push(line.target);

    return copys;
  }

  static getLine(lineId: string, lines: StudioProjectAttributesV3Line[]): StudioProjectAttributesV3Line {
    return lines.find(x => x.id === lineId) as StudioProjectAttributesV3Line;
  }

  static getNode(nodeId: string, nodes: StudioProjectAttributesV3Node[]): StudioProjectAttributesV3Node {
    return nodes.find(x => x.id === nodeId) as StudioProjectAttributesV3Node;
  }

  // 是否有sourceLine被选中
  static hasSourceLineSelect(sourceLines: string[], checkEdges: string[]): boolean {
    return sourceLines.reduce((flag, id) => {
      return flag || checkEdges.includes(id);
    }, false);
  }

  // 获取选中的targetLines
  static filterTargetLine<T>(targetLines: T[], checkEdges: T[]): T[] {
    return targetLines.reduce((result, id) => {
      return checkEdges.includes(id) ? result.concat(id) : result;
    }, [] as T[]);
  }
}
