"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueprintFunction = exports.BlueprintFunctionType = void 0;
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
const StoryNodeDisplayType_1 = require("../domain/StoryNodeDisplayType");
const FunctionBase_1 = require("./FunctionBase");
var BlueprintFunctionType;
(function (BlueprintFunctionType) {
    BlueprintFunctionType["EXECUTE_BLUEPRINT"] = "execute_blueprint";
    BlueprintFunctionType["BLUEPRINTS"] = "blueprints";
    BlueprintFunctionType["BLUEPRINTS_V2"] = "blueprintsV2";
})(BlueprintFunctionType || (exports.BlueprintFunctionType = BlueprintFunctionType = {}));
class BlueprintFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo, variableList = [], page = 1, next = undefined, // for food
    pagination = undefined, // for youtube
    requestParams = {}, // for add data source
    checkAbort, sourceNodeType = StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT, queries = [], blueprintJSON = "") {
        const { functionType, ...blueprintData } = JSON.parse(blueprintJSON);
        switch (functionType) {
            case BlueprintFunctionType.EXECUTE_BLUEPRINT:
                return await this.executeBlueprint(queries, blueprintData);
            case BlueprintFunctionType.BLUEPRINTS:
                return await this.blueprints(input, identityInfo);
            case BlueprintFunctionType.BLUEPRINTS_V2:
                return await this.blueprintsV2(blueprintData, identityInfo);
            default:
                return await this.executeBlueprint(queries, blueprintData);
        }
    }
    async executeBlueprint(queries, blueprintData) {
        const result = await this.brainClient.bluePrint.run(queries, blueprintData);
        const finalResult = result?.data?.execution_results?.final_result;
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.BLUEPRINT, finalResult);
    }
    async blueprints(input, identityInfo) {
        const streamResponse = new FunctionBase_1.RunStreamResponse();
        const onReceived = (msg) => {
            if (msg.done) {
                msg.abort();
            }
            const fullText = msg.fullText.trim();
            streamResponse.receive(fullText, msg.done);
        };
        this.brainClient.openAi
            .blueprints(input, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo))
            .then(re => {
            re.onReceived(re => onReceived(re));
        })
            .catch(error => {
            streamResponse.receive("", true, error);
        });
        return Promise.resolve(new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.BLUEPRINT, streamResponse));
    }
    async blueprintsV2(data, identityInfo) {
        const streamResponse = new FunctionBase_1.RunStreamResponse();
        const onReceived = (msg) => {
            if (msg.done) {
                msg.abort();
            }
            const fullText = msg.fullText.trim();
            streamResponse.receive(fullText, msg.done);
        };
        this.brainClient.openAi
            .blueprintsV2(data, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo))
            .then(re => {
            re.onReceived(re => onReceived(re));
        })
            .catch(error => {
            streamResponse.receive("", true, error);
        });
        return Promise.resolve(new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.BLUEPRINT, streamResponse));
    }
}
exports.BlueprintFunction = BlueprintFunction;
