import { Input } from "antd";
import { useCreation } from "ahooks";
import style from "./ChatAISharedPage.module.scss";
import { ChatContainer } from "./ChatContainer/ChatContainer";
import classNames from "classnames";
import { useEffect, useMemo, useRef } from "react";
import { debounce, isEmpty } from "lodash";
import { getIt } from "@uikit/getIt";
import { ChatAIStore } from "./ChatAIStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { useSignal } from "@preact/signals-react";
import { PreviewV2ToolBar } from "../PreviewV2ToolBar/PreviewV2ToolBar";
import { useAISaasPreviewItem } from "../AISaas/hooks/useAISaasPreviewItem";
import { setThemeProperty } from "@custom-hooks/useSaasTheme";
import AddToContent from "../AISaas/AddToContent";
import { AYLWAYS_SHOW_OUTPUT } from "@uiview/views/AISaas/AddToContent/conts";
import { PreviewPaneBloc } from "../PreviewPane/PreviewPaneBloc";
import { ChatThemeType } from "imagica-corekit/dist/cases/theme/chatTheme/ChatAppThemeData";
import { FontType } from "imagica-corekit/dist/cases/theme/fontTheme/FontData";
import useFeatureTags from "@custom-hooks/useFeatureTags";
import { Api } from "@uikit/service/api";
import { AccountStatus } from "../ShareAccount/AccountStatus";
import func from "@uikit/func";
import { useDidMount } from "imagica-uikit/dist/hooks/useDidMount";
import { FooterUrl } from "../ShareAccount/FooterUrl";
import { settings as baseSettings } from "imagica-corekit/dist/base/kernel/Settings";
import { previewStore } from "@uiview/store/PreviewStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

/* const title = "Hello, I am an Al trained to answer questions";
const subtitle = "Learn how to build your own Code without using a CODE";
const type: any = "InputText"; // InputText chat upload */

const brainLogoStaticClass = [style["shared-assistant"], style["assistant-align"]];

export function ChatAIPage(): JSX.Element {
  const chatAIStore = getIt(ChatAIStore);
  const chatAIState = useStore(chatAIStore).value;
  const featureTags = useFeatureTags();
  const api = new Api(false);

  const homePluginStore = getIt(HomePluginStore);
  const isShare = homePluginStore.state.isShare;
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const { id, theme, settings } = saasUIData;
  const { onPreviewItemMouseEnter } = useAISaasPreviewItem();
  const previewComposeState = getIt(PreviewComposeState);
  const previewAppStore = getIt(PreviewAppStore);
  const previewAppState = useImagicaStore(previewAppStore).value;
  const isInHistory = useMemo(() => {
    return previewComposeState.isInHistory();
  }, [
    previewAppState.historyShowStatus,
    previewAppState.publishShowStatus,
    previewAppState.previewShowStatus,
    previewAppState.settingShowStatus,
  ]);

  const sharedContainerClass = {
    [style["sharedContainerAllHeight"]]: chatAIState.isEnterInputLoadingDone,
  };

  const uploadLoge = useSignal("");
  const showEditTitle = useSignal(false);
  const showEditSubTitle = useSignal(false);
  const brainLogoStyle = useSignal({});
  const titleBoxRef = useRef(null);
  const titleRef = useRef(null);
  const subTitleBoxRef = useRef(null);
  const subTitleRef = useRef(null);
  const sharedBodyBoxRef = useRef<HTMLDivElement>(null);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const hoverTitle = useSignal(false);
  const hoverSubTitle = useSignal(false);

  const sharePageClass = [isShare ? style["shared-page"] : style.canvasSharePage];

  const handleSharedBodyBoxRefScroll = debounce((e: any) => {
    const target = e?.target || e;
    chatAIStore.sharedBodyBoxRefBottom(target.scrollTop + target.clientHeight >= target.scrollHeight - 50);
  }, 10);

  useDidMount(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", handleSharedBodyBoxRefScroll);
    }
    return () => {
      contentRef.current?.removeEventListener("scroll", handleSharedBodyBoxRefScroll);
    };
  });

  useEffect(() => {
    if (chatAIState.isEnterInputLoadingDone) {
      // 显示对话列表时主动执行一次滚动条是否在最底部的判定
      contentRef.current && handleSharedBodyBoxRefScroll(contentRef.current);
    }
    // eslint-disable-next-line
  }, [chatAIState.isEnterInputLoadingDone, chatAIState.chatLogoTop]);

  const bloc = useCreation(
    () =>
      new PreviewPaneBloc({
        saasUIData,
        setSaasUIData: creatorSaasAppStore.setSaasUIData,
        themeContainer: contentRef,
      }),
    []
  );

  useEffect(() => {
    // 移除feature tag时，要把两个判断一并移除
    if (isShare || featureTags?.enablePublishV3) {
      if (!id) return; // 当saasUIData被赋值app数据后再设置主题
      bloc.changeChatTheme(theme as ChatThemeType);
    } else {
      setThemeProperty(contentRef.current, theme);
    }
  }, [bloc, featureTags?.enablePublishV3, theme, isShare, id]);

  useEffect(() => {
    // 移除feature tag时，要把两个判断一并移除
    if (isShare || featureTags?.enablePublishV3) {
      const fontFamily = settings?.fontFamily || FontType.AKREGULAR;
      bloc.changeFont(fontFamily);
    }
  }, [bloc, featureTags?.enablePublishV3, settings?.fontFamily, isShare]);

  const onTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    creatorSaasAppStore.setSaasUIData(prevData => {
      return {
        ...prevData,
        title: e.target?.value,
        titleV3: {
          ...prevData.titleV3,
          title: e.target?.value,
        },
      };
    });
  };
  const onSubTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    creatorSaasAppStore.setSaasUIData(prevData => {
      return {
        ...prevData,
        subTitle: e.target?.value,
        subTitleV3: {
          ...prevData.subTitleV3,
          subTitle: e.target?.value,
        },
      };
    });
  };

  const initializationLogo = async (): Promise<void> => {
    uploadLoge.value = "";
    const url = saasUIData?.settings?.logoImg || "";
    if (saasUIData.id === previewStore.state.previewAppAcivieId && !func.isEmpty(url)) {
      const { fileUrl } = await api.getByCustomType(`/api/me/upload/im?url=${url}`, "blob");
      uploadLoge.value = fileUrl || "";
    }
  };

  useEffect(() => {
    initializationLogo();
    // eslint-disable-next-line
  }, [saasUIData?.settings?.logoImg]);

  useEffect(() => {
    setTimeout(() => {
      if (isShare) document.title = saasUIData?.title || "Untitled";
    });
  }, [saasUIData?.title, isShare]);

  useEffect(() => {
    if (isInHistory) {
      // 组件离开
      uploadLoge.value = "";
    }
    // eslint-disable-next-line
  }, [isInHistory]);

  const editTitle = (): void => {
    if (isShare) {
      return;
    }
    showEditTitle.value = true;
    setTimeout(() => {
      (titleRef.current as any)?.focus({
        cursor: "end",
      });
    });
    onPreviewItemMouseEnter({
      page: "chat",
      type: "chatTitle",
      container: titleBoxRef.current,
      edit: editTitle,
      remove: () => {
        creatorSaasAppStore.setSaasUIData((prevData: any) => {
          return {
            ...prevData,
            title: "",
            titleV3: {},
          };
        });
      },
    });
  };

  const editSubTitle = (): void => {
    if (isShare) {
      return;
    }
    showEditSubTitle.value = true;
    setTimeout(() => {
      (subTitleRef.current as any)?.focus({
        cursor: "end",
      });
    });
    onPreviewItemMouseEnter({
      page: "chat",
      type: "description",
      container: subTitleBoxRef.current,
      edit: editSubTitle,
      remove: () => {
        creatorSaasAppStore.setSaasUIData((prevData: any) => {
          return {
            ...prevData,
            subTitle: "",
            subTitleV3: {},
          };
        });
      },
    });
  };

  const subTitleOnMouseEnter = (): void => {
    if (isShare) {
      return;
    }
    hoverSubTitle.value = true;
  };

  const subTitleOnMouseLeave = (): void => {
    if (isShare) {
      return;
    }
    hoverSubTitle.value = false;
    // onPreviewItemMouseLeave(e);
  };

  const navigateLogo = (): void => {
    chatAIStore.enterInputLoadingDone(false);
    if (isShare) {
      window.location.href = baseSettings.ImagicaUrl;
      //  同域名下需要手动刷新
      if (baseSettings.ImagicaUrl.includes(window.location.host)) {
        window.location.reload();
      }
    }
  };

  // preview 一直显示
  const showSubTitle = isShare ? saasUIData.showSubTitle : true;

  return (
    <div ref={contentRef} data-creator="PreviewAppChat" className={classNames(sharePageClass, style.chatAiPage)}>
      {isShare && <AccountStatus className={style.account} />}
      <div ref={canvasContainerRef} className={style["chatShareBox"]}>
        {/* 顶部logo */}
        <header className={style["shared-logo-box"]} onClick={navigateLogo}>
          {!func.isEmpty(uploadLoge.value) ? (
            <img className={style.logoBoxImg} src={uploadLoge.value} alt="" />
          ) : (
            <div className={style["shared-logo"]}></div>
          )}
        </header>
        <div ref={sharedBodyBoxRef} className={classNames(style["shared-container"], sharedContainerClass)}>
          <section className={classNames(style["shared-body-box"])}>
            {!chatAIState.isEnterInputLoadingDone && (
              <div className={style["shared-title-box"]}>
                {!chatAIState.isEnterInputLoadingDone && (
                  <div className={classNames(brainLogoStaticClass, style.marginRow)} style={brainLogoStyle.value}></div>
                )}
                {/* title */}
                {saasUIData.showTitle && (
                  <div
                    ref={titleBoxRef}
                    className={classNames(style.titleBox, style.paddingRow, { [style.hoverBorderWrap]: !isShare })}
                    // onMouseEnter={titleOnMouseEnter}
                    // onMouseLeave={titleOnMouseLeave}
                  >
                    {!showEditTitle.value ? (
                      <div onClick={editTitle} className={`${style["shared-title"]}`} style={saasUIData.titleV3?.style}>
                        {!saasUIData.title && hoverTitle.value ? (
                          <span className={style.hoverPlaceholder}>Click to edit title</span>
                        ) : (
                          saasUIData.title
                        )}
                      </div>
                    ) : (
                      <Input.TextArea
                        ref={titleRef}
                        className={`${style["shared-title"]}`}
                        value={saasUIData.title}
                        style={saasUIData.titleV3?.style}
                        onBlur={() => {
                          showEditTitle.value = false;
                        }}
                        onChange={onTitleChange}
                        bordered={false}
                        autoSize
                      />
                    )}
                  </div>
                )}
                {/* subtitle */}
                {showSubTitle && (
                  <div
                    className={classNames(style.subTitleBox, style.paddingRow, { [style.hoverBorderWrap]: !isShare })}
                    ref={subTitleBoxRef}
                    onMouseEnter={subTitleOnMouseEnter}
                    onMouseLeave={subTitleOnMouseLeave}
                  >
                    {!showEditSubTitle.value ? (
                      <div
                        onClick={editSubTitle}
                        className={`${style["shared-subtitle"]}`}
                        style={saasUIData.subTitleV3?.style}
                      >
                        {!saasUIData.subTitle && hoverSubTitle.value ? (
                          <span className={style.hoverPlaceholder}>Click to edit Subtitle</span>
                        ) : (
                          saasUIData.subTitle
                        )}
                      </div>
                    ) : (
                      <Input.TextArea
                        ref={subTitleRef}
                        className={`${style["shared-subtitle"]}`}
                        value={saasUIData.subTitle}
                        style={saasUIData.subTitleV3?.style}
                        onBlur={() => {
                          showEditSubTitle.value = false;
                        }}
                        onChange={onSubTitleChange}
                        bordered={false}
                        autoSize
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            {/* {!isShare && (
              <div className={style.addContentWrap}>
                <AddToContent id={WITHOUT_INPUT_ID} type="input" />
              </div>
            )} */}
            {/* output 只有一个可以这样直接给外层加一个 border */}
            <div className={style.chatOutputWrap}>
              <div
                className={classNames(style.paddingRow, style.chatWrap, {
                  [style.hoverBorderWrap]: !isShare,
                })}
              >
                <ChatContainer container={contentRef} />
              </div>
              <div className={classNames(style.footer)}>
                {/* 对话页页脚 */}
                {!isShare && isEmpty(saasUIData?.output) && (
                  <AddToContent id={AYLWAYS_SHOW_OUTPUT} type="output" isAlwaysShow />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      <FooterUrl showInfo={chatAIState.hasChatList} className={!isShare && style.chatAiShareFooterUrl} />
      <PreviewV2ToolBar />
    </div>
  );
}
