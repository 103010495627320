"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthStore = exports.AuthStoreState = void 0;
const StoreBase_1 = require("../cutil/StoreBase");
class AuthStoreState {
    constructor() {
        this.brainToken = "";
        this.guestToken = "";
    }
    copyWith(params) {
        const state = new AuthStoreState();
        state.brainToken = params.brainToken ?? this.brainToken;
        state.guestToken = params.guestToken ?? this.guestToken;
        return state;
    }
}
exports.AuthStoreState = AuthStoreState;
class AuthStore extends StoreBase_1.StoreBase {
    constructor(storageManager) {
        super(() => {
            const brainToken = storageManager.getItem("BRAIN_TOKEN");
            const guestToken = storageManager.getItem("GUEST_TOKEN");
            return new AuthStoreState().copyWith({ brainToken, guestToken });
        });
        this.storageManager = storageManager;
        this.on(value => {
            storageManager.setItem("BRAIN_TOKEN", value.brainToken);
            storageManager.setItem("GUEST_TOKEN", value.guestToken);
        });
    }
    login(token) {
        const newState = this.state.copyWith({ brainToken: token });
        this.emit(newState);
    }
    loginGuest(guestUserKey) {
        const newState = this.state.copyWith({ guestToken: guestUserKey });
        this.emit(newState);
    }
    logout() {
        const newState = this.state.copyWith(new AuthStoreState());
        this.emit(newState);
    }
}
exports.AuthStore = AuthStore;
