import React from "react";
import "../components-css/ThreeBotsLoading.css";

function ThreeBotsLoading(props) {
  return (
    <div className={`ThreeBotsLoading ${props.class}`}>
      <span className="BotOne" style={{ background: props.backColor }}></span>
      <span className="BotTwo" style={{ background: props.backColor }}></span>
      <span className="BotThree" style={{ background: props.backColor }}></span>
    </div>
  );
}

export default ThreeBotsLoading;
