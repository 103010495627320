import { MutableRefObject, RefObject } from "react";

// 重载签名
function createRef<T>(initialValue: T): MutableRefObject<T>;
function createRef<T>(initialValue: T | null): RefObject<T>;
// function createRef<T = undefined>(): MutableRefObject<T | undefined>;

// 实现签名
function createRef<T>(initialValue: T | null): unknown {
  if (initialValue != null) {
    return { current: initialValue };
  }

  return {
    current: null,
  };
}

type CurrentProjectRefType = {
  getInnerState: () => any;
  triggerInput: (show?: boolean) => void;
  resetInnerState: () => void;
};

class SendToAppStagingData {
  cb?: () => void;
  outputCb?: () => void;
}

/**
 * 该类用于实现homeV1 中使用 useRef() 的变量
 */
export class CreatorRefStore {
  /**
   * FIXME: 绑定 DOM 可能会存在问题
   */
  homeRef = createRef<HTMLDivElement>(null);
  /**
   * FIXME: 绑定 DOM 可能会存在问题
   */
  rightMenuRef = createRef<HTMLDivElement | null>(null);
  duplicateEdgeRef = createRef<any>(null);
  nodeContextMenuRef = createRef<any>(null);
  multipleEdgeHostRef = createRef<any>(null);
  manuallyConnectedEdges = createRef<any>(null);
  canvasMovingLoadingRef = createRef<any>(null);
  dragOverTimer = createRef<any>(null);
  sliceLevelRef = createRef(1);
  copyingNodeIdRef = createRef("");
  cotDescription = createRef("");
  isMouseInNode = createRef(false);
  isFireNodeDragStopEvent = createRef(false);
  isSelectionStart = createRef(false);
  isLoadPreferences = createRef(false);
  copyNodes = createRef<any[]>([]);
  copyEdges = createRef<any[]>([]);
  currentDragNodes = createRef<any[]>([]);
  singleFlowEdgeArrRef = createRef<any[]>([]);
  prevRunAllNodesContent = createRef<any[]>([]);
  COTInputOutputNodes = createRef<any>(undefined);
  extractDescriptionRef = createRef("");
  selectedNodeIdRef = createRef("");
  ideaDescriptionRef = createRef(new Map());
  singleFlowEdgesRef = createRef({
    name: "",
    description: "",
    edgeArr: [] as any[],
    nodeArr: [] as any[],
  });
  dragOverClientXY = createRef({
    x: 0,
    y: 0,
  });

  nodeIndexRef = createRef(1);
  edgeIndexRef = createRef(0);
  aiSaasRef = createRef(null);
  currentProjectRef = createRef<CurrentProjectRefType>(null);
  /**
   * 第一次运行边，运行后赋值为true
   */
  firstRunEdgeRef = createRef(false);

  sendToAppStagingData: SendToAppStagingData = {};
}

export const creatorRefStore = new CreatorRefStore();
