import { createContainer } from "unstated-next";
import { useCallback, useState, useEffect, useRef } from "react";
import {
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { findIndex } from "lodash";
import useStateRef from "react-usestateref";
import { SortableListUtil } from "./util";

// eslint-disable-next-line
function useHook(props?: SortableList.Props) {
  const { options, onChange, disabled, listType, isShare } = props as SortableList.Props;

  const [sortableOptions, setSortableOptions, sortableOptionsRef] =
    useStateRef<SortableList.SortableData<any>[]>(options);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const sortableListRef = useRef<HTMLDivElement>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (options) {
      setSortableOptions(options);
    }
  }, [options]);

  const [modifiers] = useState([restrictToVerticalAxis]);

  const _onChange = useCallback(
    (options: typeof sortableOptions) => {
      setSortableOptions(options);
      onChange?.(options);
    },
    [onChange]
  );

  const moveById = useCallback(
    (oldId: UniqueIdentifier, newId: UniqueIdentifier) => {
      const items = sortableOptionsRef.current;
      const oldIndex = findIndex(items, item => item.id === oldId);
      const newIndex = findIndex(items, item => item.id === newId);
      const newSortableOptions = arrayMove(items, oldIndex, newIndex);
      _onChange(newSortableOptions);
    },
    [_onChange]
  );

  const onDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id);
  }, []);

  const onDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      moveById(active.id, over.id);
    }

    setActiveId(null);
  }, []);

  const onSwitch: SortableList.OnSwitchHandler = useCallback(({ id, type }) => {
    const targetIndex = findIndex(sortableOptionsRef.current, item => item.id == id);
    const length = sortableOptionsRef.current.length;

    // 保证当是在第1个元素和倒数第二个元素内
    if (targetIndex == -1) {
      return;
    }

    if (type === "up" && targetIndex >= 1) {
      const over = sortableOptionsRef.current.at(targetIndex - 1);
      if (over) {
        // FIXME: 目前交换动画使用 style 手动控制，后期可使用成熟的动画库
        switchWithAnimation(id, over.id).then(() => {
          // moveById(id, over.id);
        });
      }
    }

    if (type === "down" && targetIndex <= length - 2) {
      const over = sortableOptionsRef.current.at(targetIndex + 1);
      if (over) {
        // FIXME: 目前交换动画使用 style 手动控制，后期可使用成熟的动画库
        switchWithAnimation(id, over.id).then(() => {
          // moveById(id, over.id);
        });
      }
    }
  }, []);

  const switchWithAnimation = (targetID: UniqueIdentifier, sourceID: UniqueIdentifier) => {
    return new Promise<boolean>(reslove => {
      console.log("1555 sortableListRef.current", sortableListRef.current);

      if (!sortableListRef.current) {
        reslove(false);
        return;
      }

      const result = SortableListUtil.switchYWithTransform(targetID, sourceID, sortableListRef.current);

      if (result.duration) {
        setTimeout(() => {
          moveById(targetID, sourceID);
          result.clear();
          reslove(true);
        }, result.duration);
      } else {
        reslove(true);
      }
    });
  };

  return {
    sortableOptions,
    isShare,
    sensors,
    activeId,
    setActiveId,
    onDragStart,
    onDragEnd,
    onSwitch,
    // isShare,
    disabled,
    listType,
    /**
     * 拖拽修改器
     *
     * - 目前是只运行垂直拖拽
     *
     * @see https://docs.dndkit.com/api-documentation/modifiers
     */
    modifiers,
    sortableListRef,
  };
}

/**
 * SortableList 容器
 *
 * - 只要是 SortableList 组件子节点都可以通过 `useSortableList` 得到内部数据和逻辑，可避免深度传递
 *
 * @returns
 */
export const SortableListContainer = createContainer(useHook);

export const { Provider: SortableListProvider, useContainer: useSortableListContainer } = SortableListContainer;
