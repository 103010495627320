import store from "../../store";
import { StudioProject } from "imagica-corekit/dist/base/project/domain/StudioProject";
import { BrainClient as CorekitBrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { getIt } from "@uikit/getIt";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";

export class AppSnapshotService {
  private brainClient: CorekitBrainClient = getIt(CorekitBrainClient);
  private projectData: StudioProject | undefined;

  buildProjectData(data: any): AppSnapshotService {
    const projectObj = {
      attributes: data,
    };
    const projectJsonStr = JsonUtil.stringify(projectObj);
    this.projectData = JsonUtil.toModelFromType(StudioProject, projectJsonStr);
    return this;
  }

  async createAppSnapshot(): Promise<void> {
    const isSharedApp = store.getState().fot.isShare;
    if (isSharedApp && this.projectData) {
      this.brainClient.graphql.createImagicaAppSnapshot(this.projectData);
    }
    return;
  }
}
