import { useSignal } from "@preact/signals-react";
import { HandleDownIcon, HandleDragIcon, HandleUpIcon } from "./icons";
import css from "./index.module.scss";
import classNames from "classnames";

export type HandleDndProps = {
  sortableProps?: SortableList.SortableProps;
  onSwitch?: SortableList.OnSwitchHandler;
};

export function HandleDnd({ sortableProps, onSwitch }: HandleDndProps) {
  const { id, setNodeRef, attributes, listeners } = sortableProps || {};
  const nodeType = useSignal(sortableProps?.type);
  return (
    <div
      className={classNames(
        css["toolbar-dnd"],
        css["vertical-icons"],
        css[nodeType.value === "input" ? "toolbarInputPosition" : "toolbarOutputPosition"]
      )}
      data-testid="Toolbar-Handle"
    >
      <div
        className={css["handle-prev"]}
        data-testid="Toolbar-Handle-Prev"
        onClick={e => {
          id && onSwitch?.({ id, type: "up" });
        }}
      >
        {HandleUpIcon}
      </div>
      <div
        className={css["handle-drag"]}
        data-testid="Toolbar-Handle-Drag"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        {HandleDragIcon}
      </div>
      <div
        className={css["handle-next"]}
        data-testid="Toolbar-Handle-Down"
        onClick={e => {
          id && onSwitch?.({ id, type: "down" });
        }}
      >
        {HandleDownIcon}
      </div>
    </div>
  );
}
