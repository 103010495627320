import { getIt } from "@uikit/getIt";
import { RectType } from "@uikit/util/FocusNodeUtil";
import { PreviewPaneStore } from "@uiview/views/PreviewPane/PreviewPaneStore";

export class FocusPositionUtil {
  /**
   * 获取 previewPanel 宽度
   *
   * FIXME: 需要获取真实元素的准确尺寸
   *
   * @returns
   */
  static getPreviewPanelWidth(): number {
    const previewPaneStore = getIt(PreviewPaneStore);
    // 这里宽度直接使用固定值，setting涉及到动画，动态取值修改涉及比较多
    return previewPaneStore.state.settingPageStatus ? 800 : 460;
  }
  /**
   * 获取边到下一个节点的距离, 用该距离减去 blockWidth
   *
   * 也就是当有 previewPanel 时左侧区域聚集中心点到 previewPanel 到距离,这段距离在 showPreviewPanel 时应该减掉
   *
   * FIXME: 现在是直接使用一半的 blockWidth
   * @returns
   */
  static getDistanceToPreviewPanel(): number {
    return FocusPositionUtil.getPreviewPanelWidth() / 2;
  }

  /**
   * 向左移动坐标, 为什么是 +,是因为底层移动是 画布而不是坐标,所以需要将画布向右移动
   * @param value
   * @param diff
   * @returns
   */
  static toLeft(value: number, diff: number = 0): number {
    return value + diff;
  }

  /**
   * 向右移动坐标, 为什么是 -,是因为底层移动是 画布而不是坐标,所以需要将画布向左移动
   * @param value
   * @param diff
   * @returns
   */
  static toRight(value: number, diff: number): number {
    return value - diff;
  }

  /**
   * 获取flow 渲染真实的区域
   * @returns
   */
  static getRenderWidth(): number {
    const blockwidth = FocusPositionUtil.getPreviewPanelWidth();
    const renderElement = document.querySelector<HTMLDivElement>(".react-flow__renderer .react-flow__pane");

    if (!renderElement) {
      return window.innerWidth - blockwidth;
    }
    return renderElement.offsetWidth - blockwidth;
  }

  /**
   * 获取 node 对应的真实节点
   * @param id
   * @returns
   */
  static getNodeElementById(id: string): HTMLDivElement | null {
    return document.querySelector<HTMLDivElement>(`[data-flow-node-id="${id}"]`);
  }
  /**
   * 获取 edge 对应的真实节点
   * @param id
   * @returns
   */
  static getEdgeElementById(id: string): HTMLDivElement | null {
    return document.querySelector<HTMLDivElement>(`[data-flow-edge-id="${id}"]`);
  }

  /**
   * 获取一个真实节点的尺寸
   *
   * 且 width 和 height 需要计算 zoom 后的值
   * @param element
   * @returns
   */
  static getElementRect<T extends HTMLElement>(element: T, zoom: number = 1): Required<RectType> {
    const rect = element.getBoundingClientRect();

    if (zoom !== 1) {
      return {
        x: rect.x,
        y: rect.y,
        width: rect.width / zoom,
        height: rect.height / zoom,
      };
    }

    // getBoundingClientRect rect is readOnly
    return {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
    };
  }

  /**
   * 获取节点真实的dom尺寸,并非 node.width 或 node.height
   * @param id
   * @returns
   */
  static getNodeRealRect(id: string, zoom: number = 1): Required<RectType> | undefined {
    const element = document.querySelector<HTMLDivElement>(
      `[data-testid="${id}"] [data-testid="CustomNode_directionRight"]`
    );

    if (!element) {
      return;
    }

    const rect = FocusPositionUtil.getElementRect(element, zoom);
    // if (rect && this.reactFlow) {
    //   return {
    //     ...rect,
    //     width: rect.width / this.reactFlow.getZoom(),
    //     height: rect.height / this.reactFlow.getZoom()
    //   }
    // }

    return rect;
  }

  /**
   * 是否是有效的 rect 区域
   * @param rect
   * @returns
   */
  static isValidRect(rect: RectType): boolean {
    return (!!rect.x || !!rect.y) && !!rect.width && !!rect.height;
  }

  // static updateTranslateNode(newNode: Node, direction: Direction, offsetDistance: number, count = 10) {
  //   // TODO: 重新 homeuseplugin 中 translateNode
  // }
}
