"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AiAppThemeStore = exports.AppThemeStoreState = void 0;
const AiAppThemeData_1 = require("./AiAppThemeData");
const StoreBase_1 = require("../../../base/cutil/StoreBase");
const DarkTheme_1 = require("./DarkTheme");
const LightTheme_1 = require("./LightTheme");
const ATheme_1 = require("./ATheme");
const BTheme_1 = require("./BTheme");
const CTheme_1 = require("./CTheme");
const DTheme_1 = require("./DTheme");
const ETheme_1 = require("./ETheme");
const FTheme_1 = require("./FTheme");
const GTheme_1 = require("./GTheme");
const BgATheme_1 = require("./BgATheme");
const BgBTheme_1 = require("./BgBTheme");
const BgCTheme_1 = require("./BgCTheme");
const BgDTheme_1 = require("./BgDTheme");
const BgETheme_1 = require("./BgETheme");
const BgFTheme_1 = require("./BgFTheme");
const BgGTheme_1 = require("./BgGTheme");
const BgHTheme_1 = require("./BgHTheme");
const BgITheme_1 = require("./BgITheme");
const BgJTheme_1 = require("./BgJTheme");
class AppThemeStoreState {
    constructor() {
        this.theme = AiAppThemeData_1.ThemeType.LIGHT;
    }
    copyWith(params) {
        const state = new AppThemeStoreState();
        state.theme = params.theme ?? this.theme;
        return state;
    }
}
exports.AppThemeStoreState = AppThemeStoreState;
class AiAppThemeStore extends StoreBase_1.StoreBase {
    constructor() {
        super(() => new AppThemeStoreState());
        this.appThemeDatas = {
            [AiAppThemeData_1.ThemeType.LIGHT]: LightTheme_1.LightTheme.theme,
            [AiAppThemeData_1.ThemeType.DARK]: DarkTheme_1.DarkTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEA]: ATheme_1.ATheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEB]: BTheme_1.BTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEC]: CTheme_1.CTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMED]: DTheme_1.DTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEE]: ETheme_1.ETheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEF]: FTheme_1.FTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEG]: GTheme_1.GTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGA]: BgATheme_1.BgATheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGB]: BgBTheme_1.BgBTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGC]: BgCTheme_1.BgCTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGD]: BgDTheme_1.BgDTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGE]: BgETheme_1.BgETheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGF]: BgFTheme_1.BgFTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGG]: BgGTheme_1.BgGTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGH]: BgHTheme_1.BgHTheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGI]: BgITheme_1.BgITheme.theme,
            [AiAppThemeData_1.ThemeType.THEMEBGJ]: BgJTheme_1.BgJTheme.theme,
        };
    }
    setTheme(theme) {
        const newState = this.state.copyWith({ theme });
        this.emit(newState);
    }
    get theme() {
        return this.state.theme;
    }
    get themeDatas() {
        return this.appThemeDatas[this.state.theme];
    }
}
exports.AiAppThemeStore = AiAppThemeStore;
