import { Input, Select } from "antd";
import cls from "classnames";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useState from "react-usestateref";
import styles from "./AgentNode.module.css";
import DoResearchOnline from "./DoResearchOnline";
import MakeACall from "./MakeACall";
import agentOptions from "./agentOptions";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { NO_WHEEL } from "../constants";

const { TextArea } = Input;

const AgentNode = props => {
  const { id, agentData = {}, noWheelClassName } = props;
  const { msg, endFlag, agentType } = agentData;

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);

  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(agentType);
  const [AIDesc, setAIDesc] = useState(msg);
  const [focused, setFocused] = useState(false);

  const textareaRef = useRef(null);

  const handleFocus = () => {
    props.onTextAreaFocus();
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleChangeSelect = value => {
    setSelectValue(value);
  };

  const handleChangeTextarea = e => {
    setAIDesc(e.target.value);
  };

  const handleUpdateAgentDescription = () => {
    console.error("called");
  };

  useEffect(() => {
    if (msg) {
      setAIDesc(msg);
    }
  }, [msg]);

  const options = agentOptions.map(option => ({
    label: (
      <div key={option.value} className={cls(styles.selectItem, option.disabled && styles.selectItemDisabled)}>
        <img src={option.img} alt="" />
        {option.label}
      </div>
    ),
    value: option.value,
  }));

  const showDetail = () => {
    switch (selectValue) {
      case "make a call":
        return <MakeACall endFlag={endFlag} AIDesc={AIDesc} msg={msg} onFocus={props.onTextAreaFocus} />;
      case "do research online":
        return (
          <DoResearchOnline
            noWheelClassName={noWheelClassName}
            AIDesc={AIDesc}
            nodeWSKey={`${selectedTemplate.id}_${id}`}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (endFlag === false) {
      setSelectValue(agentType);
    }
  }, [endFlag]);

  const whatTodoClassName = cls(styles.whatTodo, NO_WHEEL, focused && styles.focused, focused && "nodrag");

  return (
    <div className={styles.inner}>
      <TextArea
        ref={textareaRef}
        rows={8}
        onClick={handleFocus}
        onBlur={handleBlur}
        onChange={handleChangeTextarea}
        className={whatTodoClassName}
        value={AIDesc || ""}
        onPressEnter={handleUpdateAgentDescription}
        placeholder="What do you want the AI to do"
      >
        <div>{AIDesc}</div>
      </TextArea>
      <div className={styles.bottom_content}>
        <label className={styles.label}>Platform</label>
        <Select
          onClick={() => setOpen(!open)}
          className={styles.select}
          open={open}
          onBlur={() => setOpen(false)}
          onChange={handleChangeSelect}
          value={selectValue}
          dropdownMatchSelectWidth={true}
          placeholder={
            <div className={styles.selectItem}>
              <img src={`${settings.S3CDN}${settings.viewAssetsPath}selectoption.svg`} alt="" />
              Select
            </div>
          }
          options={options}
        />
        {showDetail()}
      </div>
    </div>
  );
};

export default AgentNode;
