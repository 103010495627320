import { Button, Popover } from "antd";
import css from "../PopoverButton.module.scss";
import { LinkIcon } from "../icons/LinkIcon";
import { QRCodeIcon } from "../icons/QRCodeIcon";
import { PublishV2Item } from "@uiview/views/HomeNavBarViews/PublishV2/PublishV2Item";
import isBlank from "@sedan-utils/is-blank";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { NavBarStore } from "../NavBarStore";
import { getIt } from "@uikit/getIt";
import { UnpublishIcon } from "../icons/UnpublishIcon";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

const unPublishTip = "This app is not published";
const publishTip = "Last published at";

type PublishButtonProps = {
  copylink: () => void;
  QRCode: () => void;
  unpublish: () => void;
  beforePublish: () => boolean;
  disable?: boolean;
};

export function PublishButton(props: PublishButtonProps): JSX.Element {
  const previewAppStore = getIt(PreviewAppStore);
  const navBarStore = getIt(NavBarStore);
  const mettadata = previewAppStore.getPublishMetadata();
  const publishedAt = mettadata?.published_at ? new Date(mettadata?.published_at).toLocaleString() : "";
  const navBarState = useImagicaStore(navBarStore).value;
  const PopoverContent = (
    <div className={css.popoverContent}>
      <div className={css.popoverHead}>
        <div className={css.popverHeadTitle}>Publish</div>
        <div className={css.popverHeadDetailsBox}>
          <div>{mettadata?.link ? publishTip : unPublishTip}</div>
          {mettadata?.link ? <div>{publishedAt}</div> : null}
        </div>
      </div>

      <div className={css.popoverActionList}>
        <Button
          style={{ color: !mettadata?.link ? "#00000040" : "#12192b" }}
          disabled={!mettadata?.link}
          icon={<LinkIcon />}
          className={css.actionItem}
          type="text"
          onClick={props.copylink}
        >
          <span className={css.actionLabel}>Copy published link</span>
        </Button>

        <Button
          style={{ color: !mettadata?.link ? "#00000040" : "#12192b" }}
          disabled={!mettadata?.link}
          icon={<QRCodeIcon />}
          className={css.actionItem}
          type="text"
          onClick={props.QRCode}
        >
          <span className={css.actionLabel}>View published QR Code</span>
        </Button>
        <Button
          style={{ color: !mettadata?.link ? "#00000040" : "#DE1C22" }}
          disabled={!mettadata?.link}
          icon={<UnpublishIcon />}
          className={css.actionItem}
          type="text"
          onClick={props.unpublish}
        >
          <span className={css.actionLabel} style={!mettadata?.link ? {} : { color: "#DE1C22" }}>
            Unpublish app
          </span>
        </Button>
      </div>
      {!isBlank(navBarState.showPublishDisableTip) ? (
        <div className={css.popverErrorMsg}>{navBarState.showPublishDisableTip}</div>
      ) : null}
    </div>
  );

  return (
    <Popover
      content={!props.disable ? PopoverContent : ""}
      placement="bottom"
      showArrow={false}
      getPopupContainer={e => e}
      overlayClassName={css.popoverWrap}
      // open
    >
      <div className={css.popoverTrigger} data-testid="NavbarPublishButton">
        <PublishV2Item
          publishBtnDisable={props.disable}
          beforePublish={props.beforePublish}
          showQRCode={props.QRCode}
          cleanStyle={true}
        />
      </div>
    </Popover>
  );
}
