import React from "react";
import { Helmet } from "react-helmet";
import useLocalsText from "../../custom-hooks/useLocalsText";
import isBlank from "@sedan-utils/is-blank";

/**
 * 页面 meta,title,head 等标签管理组件 `react-helmet`
 *
 * @tag STUD-496 https://brain-ai.atlassian.net/browse/STUD-496
 * @param {*} props
 * @returns
 */
function DocumentMeta(props) {
  const { t } = useLocalsText();
  const showedProName = props.isShare && !isBlank(props.shareProjectName) ? props.shareProjectName : "";

  const pageTitle = () => {
    if (!isBlank(showedProName)) {
      return showedProName;
    }
    return t(props.isLogin ? "loginTitle" : "defaultTitle");
  };
  return (
    <Helmet>
      <title>{pageTitle()}</title>
    </Helmet>
  );
}

export default DocumentMeta;
