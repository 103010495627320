"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultTheme = void 0;
class DefaultTheme {
}
exports.DefaultTheme = DefaultTheme;
DefaultTheme.theme = {
    chatAiTheme: {
        chatBackground: "linear-gradient(180deg, #F3F5F6 22.4%, #DEE1E3 81.25%)",
        chatBackgroundPad: "linear-gradient(180deg, #F3F5F6 22.4%, #DEE1E3 81.25%)",
        chatBackgroundMobile: "linear-gradient(180deg, #F3F5F6 22.4%, #DEE1E3 81.25%)",
        logoBrightness: 0,
        shareTitleColor: "#12192b",
        hintTextColor: "#12192b",
        valueColor: "#12192b",
        uploadTipBackground: "rgba(255, 255, 255, 0)",
        toolTipBackground: "rgba(255, 255, 255, 0.65)",
        uploadTipPadding: "0",
        uploadDesColor: "rgba(18, 25, 43, 0.65)",
        uploadLimitSizeColor: "#12192b59",
        uploadLoadingWrapBackground: "#fff",
        uploadLoadingBorderColor: "rgba(2, 12, 38, 0.05)",
        inputBarBorderColor: "#12192B1A",
        inputPlaceholderColor: "rgba(18, 25, 43, 0.35)",
        buttonBackground: "rgba(18, 25, 43, 0.05)",
        buttonColor: "rgba(18, 25, 43, 0.65)",
        seeFormatDescriptionColor: "#0a9dff",
        toolTipBoxBg: "rgba(255, 255, 255, 0.65)",
        toolTipBoxShadow: "none",
        addContentColor: "#12192B",
        priceTextColor: "#12192B",
        shareFooterUrlInfo: "rgba(18, 25, 43, 0.35)",
        shareFooterUrlColor: "rgba(18, 25, 43, 0.65)",
        priceBtnBg: "#fff",
        priceDesColor: "rgba(18, 25, 43, 0.65)",
        inputColor: "#12192B",
    },
    appSignUpButtonTheme: {
        appSignUpHeight: "50px",
        appSignUpPadding: "10px 25px",
        appSignUpBorderRadius: "50px",
        appSignUpBackgroundColor: "#12192b",
        appSignUpTextColor: "#fff",
        appSignUpFontFamily: "'Ak-Regular'",
        appSignUpFontWeight: 500,
        appSignUpFontSize: "20px",
        appSignUpLineHeight: "30px",
        appSignUpPositionTop: "0",
        appSignUpPositionRight: "0",
    },
};
