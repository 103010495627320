"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubStory = void 0;
const Story_1 = require("../../storyV2/domain/Story");
class SubStory extends Story_1.Story {
    constructor() {
        super(...arguments);
        this.isNested = true;
    }
}
exports.SubStory = SubStory;
