import cls from "classnames";
import { ToolPiece } from "imagica-corekit/dist/base/api/toolPiecesTyped/ToolPieces";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import prettyBytes from "pretty-bytes";
import { useMemo } from "react";
import { Percent, UploadStatus } from "./UploadFile";
import styles from "./UploadLoading.module.scss";

type UploadLoadingProps = {
  size: number | undefined;
  fileName: string | undefined;
  percent: Percent;
  errorMsg?: string;
  loading?: string;
  onClose?: () => void;
  onReUpload: () => void;
  uploadStatus: UploadStatus;
  toolPiece: ToolPiece | undefined;
};

export function UploadLoading({
  fileName,
  size = 0,
  errorMsg = "",
  percent: { loaded, total } = { loaded: 0, total: 0 },
  onClose,
  onReUpload,
  uploadStatus,
  toolPiece,
}: UploadLoadingProps) {
  const wrapCls = cls(
    styles.wrap,
    uploadStatus === "SUCCESS" && styles.infoSuccess,
    uploadStatus === "FAILED" && styles.infoError
  );

  const fileSize = useMemo(() => {
    switch (uploadStatus) {
      case "PENDING":
        return (
          <>
            <span>{prettyBytes(loaded)}</span> <span>/</span> <span>{prettyBytes(size)}</span>
          </>
        );
      case "VALIDATING":
        return <span>validating...</span>;
      case "FAILED":
        return (
          <>
            <img src={`${settings.S3CDN}${settings.viewAssetsPath}close_red.svg`} alt="" />{" "}
            <span>{prettyBytes(size)}</span>
          </>
        );
      case "SUCCESS":
        return (
          <>
            <img src={`${settings.S3CDN}${settings.viewAssetsPath}upload_success.svg`} alt="" /> <span>Uploaded </span>
          </>
        );

      default:
        break;
    }
  }, [loaded, size, uploadStatus]);

  const statusTitle = useMemo(() => {
    switch (uploadStatus) {
      case "PENDING":
        return <div>Uploading...</div>;
      case "VALIDATING":
        return <div>Adding your API...</div>;
      case "FAILED":
        return <div>Failed to add API</div>;
      case "SUCCESS":
        return <div>API was added successfully!</div>;

      default:
        break;
    }
  }, [uploadStatus]);

  return (
    <div className={styles.uploadLoading}>
      <div className={wrapCls}>
        <div className={styles.info}>
          <div className={styles.infoLeft}>
            <div className={styles.icon}>
              <img src={settings.getViewAssetsPath("csv_icon.svg")} alt="" />
            </div>
            <div className={styles.content}>
              <div className={styles.fileName}>{fileName}</div>
              <div className={styles.fileSize}>{fileSize}</div>
            </div>
          </div>
          <div className={styles.operation}>
            <span></span>
            <img onClick={onReUpload} src={`${settings.S3CDN}${settings.viewAssetsPath}close_black.svg`} alt="" />
          </div>
        </div>

        {(uploadStatus === "PENDING" || uploadStatus === "VALIDATING") && (
          <div className={cls(styles.progressBar, uploadStatus === "VALIDATING" && styles.validating)}>
            <div style={{ width: `${Math.round((loaded / total) * 100).toFixed(2) || 0}%` }}></div>
          </div>
        )}
      </div>
      {uploadStatus === "FAILED" && (
        <div className={styles.errorMsg}>
          There is a problem with the API file you submitted. Refer to the guidelines and try again.{" "}
        </div>
      )}
    </div>
  );
}
