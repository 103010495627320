"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CotFlow = void 0;
const CotDataGraphToProjectUtil_1 = require("../cot/CotDataGraphToProjectUtil");
class CotFlow {
    constructor(cotService, titleService) {
        this.cotService = cotService;
        this.titleService = titleService;
        this.nodeInitData = {};
        this.edgeNodeInitData = {};
        this.lineInitData = {};
    }
    async getTemplate(input, nodeInitData = {}, edgeNodeInitData = {}, lineInitData = {}) {
        /// cot web需要一些基础数据
        this.nodeInitData = nodeInitData;
        this.edgeNodeInitData = edgeNodeInitData;
        this.lineInitData = lineInitData;
        const flow = await this.cotService.getTemplate(input);
        this.flow = flow;
        const extraSaasUIData = await this.titleService.getAppTitle(input, flow);
        const result = CotDataGraphToProjectUtil_1.CotDataGraphToProjectUtil.transformTemplate(input, flow, extraSaasUIData, nodeInitData, edgeNodeInitData, lineInitData);
        return result;
    }
    async transformTemplate(input, flow, nodeInitData, edgeNodeInitData, lineInitData) {
        const extraSaasUIData = await this.titleService.getAppTitle(input, flow);
        const result = CotDataGraphToProjectUtil_1.CotDataGraphToProjectUtil.transformTemplate(input, flow, extraSaasUIData, nodeInitData, edgeNodeInitData, lineInitData);
        return result;
    }
}
exports.CotFlow = CotFlow;
