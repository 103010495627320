import { AISaasInputItem } from "./item";
import { useAiSaasInputContainer } from "./container";
import React, { useEffect } from "react";
import { WITHOUT_INPUT_ID } from "../AddToContent/conts";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { SortableList } from "@uiview/views/SortableList/SortableList";
import classNames from "classnames";
import { matchMobile } from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import sortableCommonCSS from "../SortableItemCommon.module.scss";

export function AISaasInput(): React.ReactNode {
  const { sortableList, onChangeSaasUIInputData } = useAiSaasInputContainer();
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const homeStore = getIt(HomeStore);
  const chatOnboardingStore = getIt(ChatOnboardingStore);

  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const chatOnboardingState = useImagicaStore(chatOnboardingStore).value;
  const previewState = useImagincStore(previewStore).value;

  const { saasUIData } = creatorSaasAppState;
  const isMobile = matchMobile();
  const featureTags = homeStore.state.featureTags;
  const { id: talkBuilderId } = chatOnboardingState.talkBuilder;

  /**
   * 当addContentId对应的input node在preview中被移除时
   * 1、把nextAddContentId赋值给addContentId
   * 2、把已点击click here的状态重置
   *
   * 当nextAddContentId对应的input node在preview中被移除时
   * 1、nextAddContentId重新赋值，如果没有下一个则是undefined
   * */
  useEffect(() => {
    if (!previewState.isAddInputNode || previewState.addContentId === WITHOUT_INPUT_ID) return;
    const { input } = saasUIData;
    if (input?.every(item => item.id !== previewState.addContentId)) {
      //  当移除addContentId对应的node时
      previewStore.setAddContentId(previewState.nextAddContentId);
      input.forEach((item, index, array) => {
        if (item.id === previewState.nextAddContentId) {
          previewStore.setNextAddContentId(array[index + 1]?.id || WITHOUT_INPUT_ID);
        }
      });
    } else if (input?.every(item => item.id !== previewState.nextAddContentId)) {
      //  当移除nextAddContentId对应的node时
      input.forEach((item, index, array) => {
        if (item.id === previewState.addContentId) {
          previewStore.setNextAddContentId(array[index + 1]?.id || WITHOUT_INPUT_ID);
        }
      });
    }
  }, [saasUIData, previewState.addContentId, previewState.nextAddContentId, previewState.isAddInputNode]);

  return (
    <SortableList
      animation
      options={sortableList}
      onChange={onChangeSaasUIInputData}
      itemClassName={({ value }) => {
        const showTalkBuilderBorder = talkBuilderId === value.id;
        return classNames(
          { [sortableCommonCSS.sortableItemMobile]: isMobile && featureTags.disableMobilePage },
          { [sortableCommonCSS.talkBuilderBorder]: showTalkBuilderBorder }
        );
      }}
      renderItemChildren={({ index, value }) => (
        <AISaasInputItem key={value.id} x={value} index={index} isShare={false} />
      )}
    />
  );
}
