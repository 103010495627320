"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamHandler = void 0;
const TransformServiceHandlerChain_1 = require("../chain/TransformServiceHandlerChain");
const CustomApiTransformError_1 = require("../error/CustomApiTransformError");
class StreamHandler extends TransformServiceHandlerChain_1.Handler {
    async process(request) {
        if (request.isStream) {
            const stream = request.stream();
            if (!stream) {
                throw CustomApiTransformError_1.CustomApiTransformError.default;
            }
            request.setResult(stream);
            return true;
        }
        return false;
    }
}
exports.StreamHandler = StreamHandler;
