import io from "socket.io-client";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import func from "@uikit/func";
import store from "../../store";
import { editorActions } from "../../store/editor";
import { getIt } from "@uikit/getIt";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";

let socket = null;
let topicObj = {};
let eventArr = [];

const BRAIN_WS_URI = settings.WS;

const setSocketConnected = () => {
  store.dispatch(editorActions.setSocket({ isConnected: true, isDisConnect: false }));
};

const setSocketDisconnect = newValue => {
  const isDisConnect = store.getState().editor.socket.isDisConnect;
  if (newValue === isDisConnect) {
    return;
  }
  store.dispatch(editorActions.setSocket({ isConnected: false, isDisConnect: true }));
};

const getTopicObj = (topic = "") => {
  if (topic.includes("ask_brain")) {
    topicObj[topic] = {
      name: topic,
      topic: topic,
      getMsg: getAskBrain,
    };
  }
  topicObj.askBrain = {
    name: "askBrain",
    topic: `/studios/${store.getState().editor.me.id}/ask_brain`,
    getMsg: getAskBrain,
  };
};

export function pullMsg(name) {
  getTopicObj(name);

  if (!func.isEmpty(name)) {
    const event = eventArr.find(x => x.name === name);
    if (func.isEmpty(event)) {
      let topic = topicObj[name];
      eventArr.push(topic);
    }
  }
  if (socket == null) {
    setWS();
    return;
  }

  const isConnected = store.getState().editor.socket.isConnected;
  if (!isConnected) {
    socket.open();
    return;
  }

  listenTopic();
}

//删除所有topic
export function delEvent() {
  console.info("socket delEvent::");
  if (socket == null) return;
  eventArr.forEach(x => {
    socket.off(x.topic);
  });
  socket.close();
  socket = null;
}
//删除topic
export function delTopic(val) {
  val = Array.isArray(val) ? val : [val];
  let index = -1;
  eventArr.forEach((x, i) => {
    if (val.includes(x.name)) {
      index = i;
      return;
    }
  });
  if (index > -1) {
    socket.off(eventArr[index].topic);
    eventArr.splice(index, 1);
    if (eventArr.length === 0) {
      socket.close();
    }
  }
  console.info("socket delTopic::", val, eventArr);
}

function doConnect() {
  // 去掉 socket.io 自动重连，通过 fib 数列手动重连
  socket = io(BRAIN_WS_URI, { reconnection: false });
  socket.on("connect", () => {
    console.info("socket connected");
    setSocketConnected();
    const isConnected = store.getState().editor.socket.isConnected;
    if (isConnected) {
      if (func.isEmpty(socket)) return;
      const token = getIt(FotAuthStore).state.brainToken;
      socket.emit("authentication", { token });
      socket.on("authenticated", res => {
        console.info("ws auth: " + res);
        listenTopic();
      });
    }
  });
}

function setWS() {
  doConnect();

  listenEvent();
}

//监听socket各事件
function listenEvent() {
  // console.debug('socket',socket)
  //链接超时
  socket.on("connect_timeout", timeout => {
    console.error("socket connect_timeout:::", timeout);
    socket = null;
    setSocketDisconnect(true);
  });

  //断掉链接
  socket.on("disconnect", reason => {
    console.error("socket disconnect:::", reason);
    setSocketDisconnect(true);
  });

  //链接出错
  socket.on("connect_error", error => {
    console.error("socket connect_error:::", error);
    socket = null;
    setSocketDisconnect(true);
  });

  //发生错误
  socket.on("error", error => {
    console.error("socket error:::", error);
    socket = null;
    setSocketDisconnect(true);
  });
}

//监听topic
function listenTopic() {
  eventArr.forEach(x => {
    if (socket !== null) {
      console.error("topic", x.topic);
      socket.off(x.topic);
      socket.on(x.topic, x.getMsg);
    }
  });
  console.info("socket listenTopic::", eventArr);
}

function getAskBrain(res) {
  console.log("getAskBrain socket::::", res);
  const topic = res.key.topic;
  store.dispatch(editorActions.setResearchAgentWebsocketData({ key: topic, data: res }));
}
