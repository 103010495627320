import { Modal } from "antd";
import { useCallback, useEffect } from "react";
import { useSignal } from "@preact/signals-react";
import { ModalIcon, ModalInput } from "./ModalInput";
import { AModalProps, InputType } from "./types";
import { isEmpty } from "../../../uikit/login";
import { ModalFooter } from "./ModalFooter";
import { ModalTitle } from "./ModalTitle";
import style from "./AModal.module.css";
import classNames from "classnames";

const CLOSE_ICON = "https://dopniceu5am9m.cloudfront.net/static/assets/230705/ACAEB5Cross.svg";

const OK_TEXT = { text: "Ok", loading: true };
const CANCEL_TEXT = { text: "Cancel", loading: false };
const DEFAULR_PLACEHOLDER = "Please Input";
const DEFAULR_INPUT = {
  require: false,
  type: "textarea" as InputType,
  placeholder: DEFAULR_PLACEHOLDER,
};

export function AModal(props: AModalProps) {
  const {
    title,
    width = 400,
    loading = false,
    subTitle = "",
    danger = false,
    icon = null,
    input = DEFAULR_INPUT,
    buttons = [CANCEL_TEXT, OK_TEXT],
    shouldExecuteOkOnEnter = false,
    onCancel,
    onOk,
    onDiscard,
    children,
    disableConfirm,
    ...rest
  } = props;

  const reason = useSignal("");
  const disableOkButton = useSignal(true);

  const onInputReason = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    reason.value = e.target.value;
  };

  const handleOk = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (loading) return;
      if (input.require && isEmpty(reason.value)) {
        return;
      }
      if (input.require) {
        //使用输入框内容时，去掉前后空格
        const text = reason.value?.trim();
        onOk(e, text);
      } else {
        onOk(e);
      }
    },
    [input.require, loading, onOk, reason.value]
  );

  const handleCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (loading) return;
      if (input.require) {
        onCancel(e, reason.value);
      } else {
        onCancel(e);
      }
    },
    [input.require, loading, onCancel, reason.value]
  );

  const handleDiscard = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const method = onDiscard || onCancel;
      if (loading) return;
      if (input.require) {
        method(e, reason.value);
      } else {
        method(e);
      }
    },
    [input.require, loading, onCancel, onDiscard, reason.value]
  );

  const handleKeyDown = (e: any) => {
    if (shouldExecuteOkOnEnter && e.key === "Enter") {
      handleOk(e);
    }
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
  };

  useEffect(() => {
    // disabel confirm button
    if (isEmpty(reason.value) && input.require) {
      disableOkButton.value = true;
    } else {
      disableOkButton.value = false;
    }
  }, [reason.value, input]);

  useEffect(() => {
    if (props.open) {
      reason.value = "";
    }
  }, [props.open, reason]);

  return (
    <div onKeyDown={key => handleKeyDown(key)} onClick={handleClick}>
      <Modal
        {...rest}
        width={width}
        title={null}
        footer={null}
        centered={true}
        keyboard={!loading}
        maskClosable={!loading}
        wrapClassName={classNames(style["a-modal-confirm-wrapper"], "a-modal")}
        closeIcon={<img src={CLOSE_ICON} width="20" height="20" alt="close" />}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <ModalIcon icon={icon} />
        <ModalTitle title={title} subTitle={subTitle} />
        {children}
        <ModalInput
          type={input.type}
          require={input.require}
          placeholder={input.placeholder}
          value={reason.value}
          onChangeText={onInputReason}
          onPressEnterKey={handleOk}
        />
        <ModalFooter
          loading={loading}
          danger={danger}
          buttons={buttons}
          disableConfirm={disableConfirm === undefined ? disableOkButton.value : disableConfirm}
          handleDiscard={handleDiscard}
          handleOk={handleOk}
        />
      </Modal>
    </div>
  );
}
