import { Button, ButtonProps } from "antd";
import { Command } from "cmdk";
import style from "../BluePrint.module.css";

export type BlueprintCommandItemProps = {
  children?: React.ReactNode;
} & ButtonProps;

export function BlueprintCommandItem({ children, ...reset }: BlueprintCommandItemProps) {
  return (
    <Command.List>
      <Button type="text" className={style["commandbar-blue-print-button"]} {...reset}>
        {children}
      </Button>
    </Command.List>
  );
}
