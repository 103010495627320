import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../store";
import func from "@uikit/func";

export default function useLinkParamsMetadata() {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const selectedTemplate = useSelector(state => (state as any).studio.selectedTemplate);

  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    // get metadata from link params
    const paramsObj: any = {};
    searchParams.forEach((value: any, key: string) => {
      paramsObj[key] = value;
    });
    paramsObj.attrIndexer = selectedTemplate.index || location?.pathname?.split("/")?.at(-1);
    paramsObj.uuid = selectedTemplate.uuid;
    // get metadata from link params

    // extract metadata from q param
    const subscriberParamsData: any = store.getState().editor.subscriberParamsData;
    // prioritize using extracted metadata from q, if it does not exist, use metadata from link params
    let metadata = null;
    if (!func.isEmpty(subscriberParamsData)) {
      metadata = subscriberParamsData.attributes;
    } else if (paramsObj) {
      metadata = paramsObj;
    }
    setMetadata(metadata);
  }, []);

  return {
    metadata,
  };
}
