import { useEffect } from "react";
export function useDidMount(effect) {
  useEffect(function () {
    var _willUnmount$construc;
    var willUnmount = effect();
    if (typeof willUnmount == "function") {
      return function () {
        willUnmount();
      };
    } else if ((willUnmount === null || willUnmount === void 0 || (_willUnmount$construc = willUnmount.constructor) === null || _willUnmount$construc === void 0 ? void 0 : _willUnmount$construc.name) === "Promise") {
      return function () {
        willUnmount.then(function (value) {
          if (typeof value == "function") {
            value();
          }
        });
      };
    }
  }, []);
}