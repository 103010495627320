"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VBDataC4 = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
class VBDataC4 extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.header_text = "";
        this.description_text = "";
        this.image = "";
        this.source = "";
        this.time = "";
    }
}
exports.VBDataC4 = VBDataC4;
