import { useAiSaasInputContainer } from "../container";
import css from "./index.module.scss";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { getIt } from "@uikit/getIt";

export function InputEnter(props: AISaas.AISaasInputItemActionProps): JSX.Element | null {
  const { x } = props;
  const { id: itemId } = x.data;
  const projectRunStore = getIt(ProjectRunStore);
  const { isFocus, isDarkMode, onInputEnter } = useAiSaasInputContainer();
  const projectRunStoreState = useImagicaStore(projectRunStore).value;

  if (projectRunStoreState.runAllLoading || !isFocus.value[itemId]) {
    return null;
  }

  return (
    <div
      className={`${css["enter-bottom"]} ${isDarkMode ? css["dark-enter"] : ""}`}
      onClick={e => onInputEnter(e, itemId, x)}
    ></div>
  );
}
