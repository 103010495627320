import { BaseDataRef } from "./BaseDataRef";
import { Node } from "reactflow";

export class EdgeDataRefState {
  // nodes: nodesRef;
  // // 考虑到fot或其他以外地方可能还在使用该数据，暂时没删，但是fot应不在使用
  // nodeIndexRef = creatorRefStore.nodeIndexRef;
  isGetQuery = false;
  getQueryLoading = false;
  isCreatedBySlice = false;
  isSliceAutoRun = false;
  isApiFunction = false;
  isUndoRedoAdd = false;
  originSource = "";
  originTarget = "";
  sourceHandle = "b";
  targetHandle = "a";
  selectedApi = {};
  lineParam = {
    loading: false,
    queryValue: "",
    enterText: "",
    lineType: "prompt",
    createFromCot: false,
    // 用来禁用边上的 run 按钮
    disabledRun: false,
  };
  /**
   * @deprecated use `CreatorNodesStore`
   */
  nodes: React.MutableRefObject<Node<any>[]> = { current: [] };
  /**
   *@deprecated use `creatorRefStore.nodeIndexRef`
   */
  nodeIndexRef: React.MutableRefObject<number> = { current: 0 };
}

/**
 * 原来 HomeUsePlugin 中 EdgeDataRef 的数据结构
 */
export class EdgeDataRef extends BaseDataRef<EdgeDataRefState> {
  constructor() {
    super(new EdgeDataRefState());
  }
}
