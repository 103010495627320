import { FC, useCallback } from "react";
import style from "./style.module.scss";
import Icon from "../Icon";
import cls from "classnames";
import { callback, getAssetsIcon } from "../../utilities";
import { MutableRefObject } from "react";

export enum Controls {
  Control,
  ArrowLeft,
  ArrowRight,
}

export interface SliderProps {
  position?: number;
  positions?: number[];
  controlsRef?: MutableRefObject<HTMLDivElement | null>;
  onPositionClick?: (selectedPosition: number, control: Controls) => void;
}

const Slider: FC<SliderProps> = ({ position = 0, positions = [], controlsRef = null, onPositionClick = callback }) => {
  const handleIndexClick = useCallback(
    (index: number, control: Controls) => () => {
      onPositionClick(index, control);
    },
    [onPositionClick]
  );

  return (
    <div className={style.sliderControlBox}>
      <div className={style.sliderControlLftArrow} onClick={handleIndexClick(position - 1, Controls.ArrowLeft)}>
        <Icon url={getAssetsIcon("dropdown-left.svg")} />
      </div>

      <div style={{ height: "20px" }}>
        <div ref={controlsRef}>
          {positions.map(index => (
            <div key={index} onClick={handleIndexClick(index, Controls.Control)}>
              <div className={cls(style.sliderControlBlocks, position === index && style.sliderControlBlocks_active)} />
            </div>
          ))}
        </div>
      </div>

      <div className={style.sliderControlRgtArrow} onClick={handleIndexClick(position + 1, Controls.ArrowRight)}>
        <Icon url={getAssetsIcon("dropdown-right.svg")} />
      </div>
    </div>
    // <div className={style.slider}>
    //   <div
    //     className={cls(style.slider__button, style.slider__button_left)}
    //     onClick={handleIndexClick(position - 1, Controls.ArrowLeft)}
    //   >
    //     <Icon url={getAssetsIcon("dropdown-left.svg")} />
    //   </div>
    //   <div className={style.slider__controls}>
    //     <div className={style.sliderControls} ref={controlsRef}>
    //       {positions.map(index => (
    //         <div
    //           className={style.sliderControls__control}
    //           key={index}
    //           onClick={handleIndexClick(index, Controls.Control)}
    //         >
    //           <div className={cls(style.sliderControl, position === index && style.sliderControl_active)} />
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    //   <div
    //     className={cls(style.slider__button, style.slider__button_right)}
    //     onClick={handleIndexClick(position + 1, Controls.ArrowRight)}
    //   >
    //     <Icon url={getAssetsIcon("dropdown-right.svg")} />
    //   </div>
    // </div>
  );
};

export default Slider;
