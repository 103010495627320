import { Direction, GetFitViewResult, HomeUsePluginUtil } from "@uikit/util/HomeUsePluginUtil";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { Node } from "reactflow";
import { FocusOptions } from "./FocusOptions";
import { FocusCore } from "./FocusCore";
import { FocusPositionUtil } from "./FocusPositionUtil";
import { FocusNodeUtil, PositionUtil, RectType } from "@uikit/util/FocusNodeUtil";
import { MoveToHandlerResult } from "./FocusController";
import { last } from "lodash";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { FotReactFlow } from "@uikit/model/FotReactFlow";

/**
 * FocusCore 实现核心的聚集 node/edge 方法
 *
 * CreatorCanvasFocus 在这 focusCore 基础上,实现了 autofit
 *
 * 聚焦边方案:
 *
 * - 方案1: 使用真实dom
 * - 方案2: 使用边的两边的节点的
 *
 * 注意: 聚焦边时,由于会从 run 状态变成 input 状态,会导致变化的过程中坐标发生轻微改变,
 * TODO: 后续应该解决该问题
 *
 * @see https://brain-ai.atlassian.net/browse/BSF-5974
 */
export class CreatorCanvasFocus extends FocusCore {
  /**
   * 内部用于判断使用聚焦边的那种方案(用于测试)
   * 1: 真实 dom
   * 2: canvas node
   */
  _focusEdgeType = 1;
  _focusNodeType = 1;

  constructor(protected fotReactFlow: FotReactFlow, public creatorNodesStore: CreatorNodesStore) {
    super(fotReactFlow);
  }

  /**
   * 获取 autofit 适应的尺寸，包含x,y,width,height 和 zoom
   * @param options
   * @returns
   */
  getFitViewRect(flowOptions: FocusOptions): GetFitViewResult | undefined {
    const nodes = this.creatorNodesStore.getNodes();

    const blockWidth = FocusPositionUtil.getPreviewPanelWidth();
    const blockData = flowOptions.showPreviewPanel ? { width: blockWidth, direction: Direction.Horizontal } : undefined;

    return HomeUsePluginUtil.getFitViewRect({
      nodes: nodes,
      isShowToolTips: flowOptions.showIntroTooltipObj,
      blockData: blockData,
      zoom: flowOptions.zoom,
    });
  }

  /**
   * 将视口**聚焦**到 canvas 上的一个区域
   *
   * - 支持 canvas 区域
   * - 支持 真实 dom 区域
   *
   * @param rect
   * @param flowOptions
   * @param realDom 是否是真实 dom 区域
   * @returns
   */
  focusRect(rect: RectType, flowOptions: FocusOptions, realDom = false): Promise<MoveToHandlerResult> {
    let centerPosition = PositionUtil.getCenterPositionByRect(rect, flowOptions?.extraRect);

    if (!centerPosition) {
      return Promise.reject("focusRect centerPosition error");
    }

    // 将真实 dom 坐标转成 canvas 上的坐标
    if (realDom) {
      centerPosition = this.domPotisionToCanvasPostion(centerPosition);
    }

    if (!centerPosition) {
      return Promise.reject("focusRect centerPosition error");
    }

    return this.focus(centerPosition, flowOptions);
  }

  /**
   * 将视口**聚焦**到 canvas 上的一个 node **真实的 dom 节点**
   *
   * 可以是 node 或 edge
   *
   * 内部会将 html 中真实的坐标转成 canvas 中的坐标，其核心使用的是 `ReactFlow.project`
   *
   * @param element
   * @param options
   * @param extraRect
   * @returns
   */
  focusDomElement<T extends HTMLElement>(element: T | null, flowOptions: FocusOptions): Promise<MoveToHandlerResult> {
    if (!element) {
      return Promise.reject("element is undefined");
    }

    const rect = FocusPositionUtil.getElementRect(element);

    return this.focusRect(rect, flowOptions, true);
  }

  /**
   * 聚焦边
   *
   * - 方案2: 使用边的两边的节点的
   *
   * @param edge
   * @param options
   * @returns
   */
  focusCanvasEdge(edge: Node, flowOptions?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    const nodes = this.creatorNodesStore.getNodes();
    const centerPos = FocusNodeUtil.getNodeCenterPosition(edge, flowOptions?.extraRect, nodes);
    if (!centerPos) {
      return Promise.reject("position is error");
    }

    return this.focus(centerPos, { moveId: edge.id, ...flowOptions });
  }

  /**
   * 聚焦 node,使用 canvas 上的节点
   *
   * @param edge
   * @param options
   * @returns
   */
  focusCanvasNode(node: Node, flowOptions?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    const centerPos = FocusNodeUtil.getNodeCenterPosition(node, flowOptions?.extraRect);
    if (!centerPos) {
      return Promise.reject("position is error");
    }

    return this.focus(centerPos, { moveId: node.id, ...flowOptions });
  }

  /**
   * 聚焦 canvas 上的两个 rect 区域
   *
   * 严格判断应该是两个区域组成的一个大区域，现在的实现是获取到了两个区域的中心的
   * @param sourceRect
   * @param targetRect
   * @param options
   */
  focusRectLink(
    sourceRect: Required<RectType>,
    targetRect: Required<RectType>,
    options?: Omit<FocusOptions, "extraRect">
  ): Promise<MoveToHandlerResult> {
    const nodes = this.creatorNodesStore.getNodes();

    // 两个节点的中心点

    // TODO: 类型完善
    const sourceCenterPos = FocusNodeUtil.getNodeCenterPosition(sourceRect as unknown as Node, undefined, nodes);
    const targetCenterPos = FocusNodeUtil.getNodeCenterPosition(targetRect as unknown as Node, undefined, nodes);

    if (!sourceCenterPos || !targetCenterPos) {
      return Promise.reject("focusRectLink position error");
    }
    const centerPosition = PositionUtil.getCenterPostionByPositionLink(sourceCenterPos, targetCenterPos);

    return this.focus(centerPosition, options);
  }

  /**
   * 适应画布
   *
   * 1. 只有一个边的时候
   * 2. 一个节点的时候
   * 3. 多个节点的时候
   *
   * @param options
   * @returns
   */
  autoFit(options?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    const flowOptions = FocusCore.createFlowOptions(options);

    // 查询出所有 node 和 edge
    const nodes = this.creatorNodesStore.getNodes();
    const customNodes = ReactFlowNodeUtil.getCustomNodes(nodes);
    const customNewEdges = ReactFlowNodeUtil.getCustomNewEdge(nodes);

    // 一条边的情况,
    // TODO: 可能一条边连接了一个组，或者是有”孤独的“节点，没有连接任何边
    if (customNewEdges.length === 1 && customNodes.length > 1) {
      return this.focusEdge(customNewEdges[0], flowOptions);
    }

    // 一个节点的情况
    if (customNodes.length === 1) {
      return this.focusNode(customNodes[0], flowOptions);
    }

    // 自适应
    const fitviewRect = this.getFitViewRect(flowOptions);

    if (!fitviewRect) {
      return Promise.reject("autoFit rect error");
    }

    return this.autoFitTo(fitviewRect, flowOptions);
  }
  // ==================== 上述方法用于底层方法

  // ==================== 下列方法来自于之前的 `useFocusNode`
  // 如果没有使用应该被移除

  /**
   * 聚焦 node, 使用真实 dom
   * @param edge
   * @param options
   * @returns
   */
  focusNode(node: Node, options?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    // FIXME: 适应一个节点时使用 focusNodeTopBar
    return this.focusNodeTopBar(node, options);

    // if (this._focusNodeType === 2) {
    //   return this.focusCanvasNode(node, options);
    // }

    // const element = FlowPositionUtil.getNodeElementById(node.id);
    // const flowOptions = FlowCore.createFlowOptions(options);

    // return this.focusDomElement(element, flowOptions);
  }

  /**
   * 聚焦边
   *
   * - 方案1: 使用真实dom
   *
   * @param edge
   * @param options
   * @returns
   */
  focusEdge(edge: Node, options?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    if (this._focusEdgeType === 2) {
      return this.focusCanvasEdge(edge, options);
    }

    const element = FocusPositionUtil.getEdgeElementById(edge.id);
    const flowOptions = FocusCore.createFlowOptions(options);

    return this.focusDomElement(element, flowOptions);
  }

  focusNodeTopBar(node: Node<any>, flowOptions?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    const nodes = this.creatorNodesStore.getNodes();
    const extraRect = flowOptions?.extraRect;

    const centerPos = FocusNodeUtil.getNodeTopBarPosition(node, extraRect, nodes);

    if (!centerPos) {
      return Promise.reject("position is error");
    }

    const moveId = node.id;
    return this.focus(centerPos, {
      moveId,
      zoom: this.fotReactFlow.getZoom(),
      ...flowOptions,
    });
  }
  /**
   * 聚焦节点到视口中心，从上下上，水平居中
   *
   * - extraRect 可为节点扩展 x,y,width,height 属性
   *
   */
  focusNodeTopBarById(nodeId: string, flowOptions?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    const node = ReactFlowNodeUtil.getNodeById(nodeId, this.creatorNodesStore.getNodes());

    if (!node) {
      return Promise.reject("Node not found");
    }

    return this.focusNodeTopBar(node, flowOptions);
  }

  private focusToBottomInput(node: Node<any>, flowOptions?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    const centerPos = FocusNodeUtil.getNodeBottomPosition(node, flowOptions?.extraRect);

    if (!centerPos) {
      return Promise.reject("position is error");
    }

    const moveId = node.id;
    return this.focus(centerPos, { moveId, ...flowOptions });
  }
  /**
   * 聚焦节点底部输入框，从下往上，水平居中
   *
   * - extraRect 可为节点扩展 x,y,width,height 属性
   *
   */
  focusToNodeBottomById(nodeId: string, flowOptions?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    const node = ReactFlowNodeUtil.getNodeById(nodeId, this.creatorNodesStore.getNodes());

    if (!node) {
      return Promise.reject("Node not found");
    }

    return this.focusToBottomInput(node, flowOptions);
  }

  focusLastNode(flowOptions?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    const lastNode = last(this.creatorNodesStore.getNodes());
    if (!lastNode) {
      return Promise.reject("Node not found");
    }

    return this.focusNode(lastNode, flowOptions);
  }
}
