"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardDataServiceRegistry = void 0;
/**
 * 容器
 */
class CardDataServiceRegistry {
    constructor() {
        this.getInstanceMap = {};
    }
    registry(name, getInstance) {
        this.getInstanceMap[name] = getInstance;
    }
    get(name) {
        return this.getInstanceMap[name]?.();
    }
}
exports.CardDataServiceRegistry = CardDataServiceRegistry;
