"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Address = exports.Food = exports.Openhour = exports.Deliveryfee = exports.Servicefee = exports.Flatcentsvalue = exports.Fee = exports.Smallorderfee = exports.Ordertier = exports.Nextopenclosedinfo = exports.Facetedratinglist = exports.FoodResponseData = exports.FoodResponse = exports.FoodResult = exports.SearchParam = void 0;
const class_transformer_1 = require("class-transformer");
class SearchParam {
}
exports.SearchParam = SearchParam;
class FoodResult {
    constructor() {
        this.name = "";
        this.id = -1;
        this.storeId = "";
        this.logo = "";
        this.description = "";
        this.price = 0;
    }
}
exports.FoodResult = FoodResult;
class FoodResponse {
}
exports.FoodResponse = FoodResponse;
class FoodResponseData {
    constructor() {
        this.items = [];
    }
}
exports.FoodResponseData = FoodResponseData;
__decorate([
    (0, class_transformer_1.Type)(() => Food)
], FoodResponseData.prototype, "items", void 0);
class Facetedratinglist {
}
exports.Facetedratinglist = Facetedratinglist;
class Nextopenclosedinfo {
}
exports.Nextopenclosedinfo = Nextopenclosedinfo;
class Ordertier {
}
exports.Ordertier = Ordertier;
class Smallorderfee {
}
exports.Smallorderfee = Smallorderfee;
class Fee {
}
exports.Fee = Fee;
class Flatcentsvalue {
}
exports.Flatcentsvalue = Flatcentsvalue;
class Servicefee {
}
exports.Servicefee = Servicefee;
class Deliveryfee {
}
exports.Deliveryfee = Deliveryfee;
class Openhour {
}
exports.Openhour = Openhour;
class Food {
}
exports.Food = Food;
class Address {
}
exports.Address = Address;
