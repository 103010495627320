import { makeACallRegex, doResearchOnlineRegex } from "@uiview/views/Nodes/Agent/constants";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { get, forEach, map } from "lodash";
import { PostUrlCanAbortService, PromiseAbort } from "./PostUrlCanAbortService";
import func from "@uikit/func";

/**
 * 原来 useConnectAgentNode 和 useNodeTypeData hook
 */
export class ConnectAgentService {
  static streamUrl = "/stream/bas-demo-v4/nlp/completions_generation";

  static url = "/be/bas-demo-v4/nlp/completions_generation";

  constructor(public creatorNodesStore: CreatorNodesStore, public postUrlCanAbortService: PostUrlCanAbortService) {}

  fetchMakeACallData = async (
    lineText: string,
    nodeText: string,
    callback: any,
    callbackPromiseHandle?: any
  ): Promise<Response | undefined> => {
    return await this.postUrlCanAbortService.postWithStreaming(
      ConnectAgentService.streamUrl,
      {
        domain: "brain_studios",
        identifier_type: "file",
        identifier_value: "agent_node_description_generation_from_previous_node_and_edge.model",
        stream: true,
        variables: {
          instruction: lineText,
          context: nodeText,
        },
      },
      msg => callback(msg.choices?.[0]?.text || msg.choices?.[0]?.delta?.content || ""),
      false,
      callbackPromiseHandle
    );
  };

  fetchDoResearchData = async (
    lineText: string,
    nodeText: string,
    callback: any,
    callbackPromiseHandle?: any
  ): Promise<Response | undefined> => {
    return await this.postUrlCanAbortService.postWithStreaming(
      ConnectAgentService.streamUrl,
      {
        domain: "brain_studios",
        identifier_type: "file",
        identifier_value: "research_agent_goal_generation.model",
        stream: true,
        variables: {
          instruction: lineText,
          context: nodeText,
        },
      },
      msg => callback(msg.choices?.[0]?.text || msg.choices?.[0]?.delta?.content || ""),
      false,
      callbackPromiseHandle
    );
  };

  async getNodeTypeData(
    lineText: string,
    nodeText: any,
    onResponseStreamText: (msg: string, agentType: any) => any = (): any => {}
  ): Promise<boolean> {
    const nodeTypeMappings = [
      {
        key: "make a call",
        promise: this.fetchMakeACallData,
        isCurrentType: false,
        promiseHandle: null as null | PromiseAbort<any>,
        text: "",
      },
      {
        key: "do research online",
        promise: this.fetchDoResearchData,
        isCurrentType: false,
        promiseHandle: null as null | PromiseAbort<any>,
        text: "",
      },
    ];

    const sendMessage = (index: number, text: string): void => {
      const currentMapping = nodeTypeMappings[index];
      nodeTypeMappings[index].text = nodeTypeMappings[index].text + text;
      if (currentMapping.isCurrentType === true) {
        onResponseStreamText(nodeTypeMappings[index].text, currentMapping.key);
      }
    };

    // Make a call
    if (makeACallRegex.test(lineText)) {
      nodeTypeMappings[0].isCurrentType = true;
      await this.fetchMakeACallData(lineText, nodeText, (text: any) => sendMessage(0, text));
      return true;
    }

    // Do research online
    if (doResearchOnlineRegex.test(lineText)) {
      nodeTypeMappings[1].isCurrentType = true;
      await this.fetchDoResearchData(lineText, nodeText, (text: any) => sendMessage(1, text));
      return true;
    }

    try {
      this.postUrlCanAbortService
        .postData(
          ConnectAgentService.url,
          {
            domain: "brain_studios",
            identifier_type: "file",
            identifier_value: "edge_domain_mapping.model",
            stream: false,
            variables: {
              input: lineText,
            },
          },
          false
        )
        .then(res => {
          if (res.status === 200) {
            const key = get(res, "data.choices.0.text", "").trim().toLowerCase();
            forEach(nodeTypeMappings, mapping => {
              if (mapping.key === key) {
                mapping.isCurrentType = true;
              } else {
                mapping.promiseHandle?.cancel?.();
              }
            });
          } else {
            func.customMsg({
              content: "Failed to fetch prompt",
            });
          }
        });
    } catch (error) {
      func.messageError(error);
    }

    try {
      await Promise.all(
        map(nodeTypeMappings, (item, index, array) => {
          return item.promise(
            lineText,
            nodeText,
            (text: any) => {
              sendMessage(index, text);
            },
            (promiseHandle: null) => {
              array[index].promiseHandle = promiseHandle;
            }
          );
        })
      );
    } catch (error) {
      console.error(error);
    }
    return nodeTypeMappings.some(mapping => mapping.isCurrentType);
  }

  async setConnectAgentNode(params: {
    target: any;
    edgeId: any;
    setEdgeLineParam: any;
    selectValueParam?: "" | undefined;
    nodeText: any;
    /**
     * 该参数可能不在需要
     */
    lineParams?: any;
  }): Promise<boolean | undefined> {
    const { target, edgeId, setEdgeLineParam, selectValueParam = "", nodeText } = params;

    let agentData = {};
    const nText = nodeText || "none";

    const isAgentNode = await this.getNodeTypeData(
      selectValueParam,
      typeof nText === "string" ? nText : JSON.stringify(nText),
      (msg: string, agentType: any) =>
        this.creatorNodesStore.setNodes(prevNodes => {
          return prevNodes.map(node => {
            if (node.id === target) {
              // console.log(msg, agentType);
              agentData = { msg: msg.trim(), endFlag: false, agentType };
              return {
                ...node,
                data: {
                  ...node.data,
                  displayType: "Agent",
                  agentData,
                },
              };
            }
            return node;
          });
        })
    );

    if (isAgentNode) {
      setTimeout(() => {
        this.creatorNodesStore.setNodes(prevNodes => {
          return prevNodes.map(node => {
            if (node.id === target) {
              return {
                ...node,
                data: {
                  ...node.data,
                  agentData: { ...agentData, endFlag: true },
                },
              };
            }
            return node;
          });
        });
      });

      setEdgeLineParam({
        id: edgeId,
        loading: false,
      });
      return true;
    }
  }
}
