export class PageManager {
  /**
   * 打开一个页面到当前页面
   * @param url
   */
  static openPage(url: string): void {
    window.open(url, "_self");
  }

  /**
   * 打开一个页面到新窗口
   * @param url
   */
  static openPageInNewWindow(url: string): void {
    window.open(url, "_blank");
  }
}
