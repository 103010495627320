export function getBottomOrRightPosition(
  arr,
  direction,
  offsetDistance,
  inputNodePosition,
  newNodeWidth = 250,
  newNodeHeight = 250
) {
  const distance = 30;
  const toolbarHeight = 50;
  const isLeftOrRight = direction === "left" || direction === "right";
  let location = isLeftOrRight ? inputNodePosition.y : inputNodePosition.x;

  let newNodeX = 0;
  let newNodeY = 0;
  if (isLeftOrRight) {
    newNodeX = direction === "left" ? inputNodePosition.x - offsetDistance : inputNodePosition.x + offsetDistance;
    newNodeY = inputNodePosition.y;
  } else {
    newNodeX = inputNodePosition.x;
    newNodeY = direction === "top" ? inputNodePosition.y - offsetDistance : inputNodePosition.y + offsetDistance;
  }
  //只使用在新增node范围内的node,并且按从左往右、从上往下排序
  const needCompareNode = arr
    .filter(node => {
      if (isLeftOrRight) {
        return (
          node.position.x > newNodeX - node.width - distance && node.position.x < newNodeX + newNodeWidth + distance
        );
      }
      return (
        node.position.y > newNodeY - node.height - distance && node.position.y < newNodeY + newNodeHeight + distance
      );
    })
    ?.sort((a, b) => {
      if (isLeftOrRight) {
        return a.position.y - b.position.y;
      }
      return a.position.x - b.position.x;
    });
  //计算新node的位置
  needCompareNode.some(node => {
    //从source node中心点向下或者右开始查询是否能放下新增node
    const shouldExcludeNodeAtTop = isLeftOrRight && node.position.y + node.height + distance < newNodeY;
    const shouldExcludeNodeAtLeft = !isLeftOrRight && node.position.x + node.width + distance < newNodeX;
    if (shouldExcludeNodeAtTop || shouldExcludeNodeAtLeft) return false;
    if (isLeftOrRight) {
      if (location + newNodeHeight + distance + toolbarHeight <= node.position.y) {
        return true;
      }
      location = node.position.y + node.height + distance + toolbarHeight;
      return false;
    }
    if (location + newNodeWidth + distance <= node.position.x) {
      return true;
    }
    location = node.position.x + node.width + distance;
    return false;
  });

  return location;
}
