import isBlank from "@sedan-utils/is-blank";
import styles from "./BeautifyImage.module.scss";
import { Signal, useSignal } from "@preact/signals-react";
import { useEffect } from "react";
import { Skeleton } from "antd";
import classNames from "classnames";

export function BeautifyImage(props: {
  value: string;
  className?: string;
  isImgLoaded?: Signal<boolean>;
  handleGroupStyle?: () => void;
}): JSX.Element {
  const imgSrc = useSignal("");

  useEffect(() => {
    if (props.value) {
      const image = new Image();
      image.src = props.value;
      image.onload = (): void => {
        imgSrc.value = props.value;
        if (props.isImgLoaded) {
          props.isImgLoaded.value = true;
        }
      };
    }
    // eslint-disable-next-line
  }, [props.value]);
  return (
    <>
      {isBlank(props.value) ? (
        <Skeleton.Image className={styles.blankNode} />
      ) : (
        <img alt="" src={imgSrc.value} className={classNames(styles.beautifyImage, styles[props.className || ""])} />
      )}
    </>
  );
}
