import { UniqueIdentifier } from "@dnd-kit/core";
import { identity } from "lodash";

export const SortableTransitionDuration = 500;

export class SortableListUtil {
  static generateDomIdAttr(id: UniqueIdentifier) {
    return {
      "data-sortableitemid": SortableListUtil.getDomItemId(id),
    };
  }

  static getDomItemId(id: UniqueIdentifier) {
    return `sortable-item-${id}`;
  }

  static getDomItemById(id: UniqueIdentifier, parent: HTMLDivElement) {
    return parent.querySelector<HTMLElement>(`[data-sortableitemid="${SortableListUtil.getDomItemId(id)}"]`);
  }

  static switchYWithTransform(target: UniqueIdentifier, source: UniqueIdentifier, parent: HTMLDivElement) {
    const targetDom = SortableListUtil.getDomItemById(target, parent);
    const sourceDom = SortableListUtil.getDomItemById(source, parent);

    if (!targetDom || !sourceDom) {
      return {
        clear: identity,
        duration: 0,
      };
    }

    const switchY = SortableListUtil.getSwitchY(targetDom, sourceDom);

    if (!switchY) {
      return {
        clear: identity,
        duration: 0,
      };
    }

    targetDom.style.transform = `translateY(${switchY.target}px)`;
    sourceDom.style.transform = `translateY(${switchY.source}px)`;
    targetDom.style.transition = `transform ${SortableTransitionDuration}ms ease`;
    sourceDom.style.transition = `transform ${SortableTransitionDuration}ms ease`;

    return {
      clear() {
        targetDom.style.transform = "";
        sourceDom.style.transform = "";
        targetDom.style.transition = "";
        sourceDom.style.transition = "";
      },
      duration: SortableTransitionDuration,
    };
  }

  /**
   * 获取两个元素相差的 top 值用于做动画切换
   */
  static getSwitchY(targetDom: HTMLElement, sourceDom: HTMLElement) {
    if (targetDom && sourceDom) {
      const targetDomTop = targetDom.getBoundingClientRect().y;
      const sourceDomTop = sourceDom.getBoundingClientRect().y;

      if (targetDomTop > sourceDomTop) {
        console.log("1555 target > souce");

        const diff = targetDomTop - sourceDomTop;
        return {
          target: -diff,
          source: diff,
        };
      }

      const diff = sourceDomTop - targetDomTop;
      return {
        target: diff,
        source: -diff,
      };
    }
    return;
  }
}
