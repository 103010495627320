"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyStorage = exports.SyncStorage = void 0;
class SyncStorage {
}
exports.SyncStorage = SyncStorage;
class EmptyStorage extends SyncStorage {
    clear() { }
    getItem(key) {
        return undefined;
    }
    removeItem(key) { }
    setItem(key, value) { }
}
exports.EmptyStorage = EmptyStorage;
