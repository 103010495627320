import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { MessageCenter } from "imagica-corekit/dist/base/kernel/MessageCenter";
import { isEmpty } from "lodash";

/**
 * 原来 useHandleAskBrainData
 */
export class EdgeRunTypeAskBrain {
  constructor(
    public fotReduxStore: FotReduxStore,
    public postUrlCanAbortService: PostUrlCanAbortService,
    public creatorStoreMethods: CreatorStoreMethods,
    public messageCenter: MessageCenter,
    public creatorNodesStore: CreatorNodesStore
  ) {}

  getAskBrainData = async (
    text: string | number | boolean,
    edgeParam: { target: any; id: any; source: any },
    defaultData: any
  ): Promise<void> => {
    const { target, id, source } = edgeParam;
    const meId = (this.fotReduxStore.getState().editor.me as any)?.id;
    const selectedTemplateId = (this.fotReduxStore.getState().studio.selectedTemplate as any)?.id;
    const topic = `/studios/${meId}/wsuid_${
      selectedTemplateId ? selectedTemplateId + "_" : ""
    }${target}/ask_brain/${Date.now()}`;
    const postBody: Record<string, any> = { query: encodeURIComponent(text) };
    // websocket
    this.messageCenter.on(topic, res => {
      const result = {
        query: text,
        answer: res.message,
        latency: {},
        sources_data: [],
      };
      this.creatorStoreMethods.setNodeParam({
        id: target,
        data: {
          ...defaultData,
          displayType: "askBrain",
          textAreaValue: result,
        },
      });
    });
    postBody.pubsub_topic = topic;

    postBody.stream_version = "v1";
    postBody.stream = true;
    let temp = "";
    await this.postUrlCanAbortService.postWithStreaming(
      `/stream/langchain/v5/ask_brain`,
      postBody,
      msg => {
        temp += msg.choices?.[0]?.text || msg.choices?.[0]?.delta?.content || "";
        this.creatorStoreMethods.setNodeParam({
          id: target,
          data: {
            showWarning: false,
            displayType: "askBrain",
            textAreaValue: {
              answer: temp.trim(),
            },
          },
        });
      },
      false,
      postInstance => {
        this.creatorStoreMethods.setNodeParam({
          id: target,
          data: {
            showStopGenerateBtn: true,
            abortNode: {
              source,
              target,
              edgeId: id,
              apiInstance: postInstance,
            },
          },
        });
      },
      allDataStr => {
        // eslint-disable-next-line
        const sourceReg = /(\{\"sources_data\": \{.*\}\})/g;
        const execArr = sourceReg.exec(allDataStr);
        if (!isEmpty(execArr)) {
          try {
            const obj = JSON.parse((execArr || [])[1]);
            const sourcesData = Object.entries(obj.sources_data).map(([, value]) => value);
            this.creatorNodesStore.setNodes(prevList => {
              return prevList.map(l => {
                if (l.id === target) {
                  return {
                    ...l,
                    data: {
                      ...l.data,
                      textAreaValue: {
                        ...l.data.textAreaValue,
                        sources_data: sourcesData,
                      },
                    },
                  };
                }
                return l;
              });
            });
          } catch (error) {
            // eslint-disable-next-line
          }
        }
      }
    );
  };
}
