import { AnyAction, Store } from "@reduxjs/toolkit";

export class CustomApiPageUtil {
  static getSourceNodeTextAreaValue(store: Store<unknown, AnyAction>, nodes: any[]) {
    const groupPatt = /^group/;
    const { sourceNodeId } = (store.getState() as any).editor.createAPIFuncData;
    let node: any = nodes.find(x => x.id === sourceNodeId) || [];
    if (groupPatt.test(sourceNodeId)) {
      node = nodes.find(x => x.parentNode === sourceNodeId);
    }

    let currentSourceNodeValue = node.data?.textAreaValue;

    if (typeof currentSourceNodeValue !== "string") {
      currentSourceNodeValue = JSON.stringify(currentSourceNodeValue) || "";
    }
    return currentSourceNodeValue;
  }
}
