"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueprintService = void 0;
class BlueprintService {
    constructor(generateService) {
        this.generateService = generateService;
    }
    previewBlueprint(params) {
        return this.generateService.previewBlueprint(params);
    }
    previewRestore() {
        return this.generateService.previewRestore();
    }
    generateInputs(params) {
        return this.generateService.generateInputs(params);
    }
}
exports.BlueprintService = BlueprintService;
