import React, { MouseEvent, useRef } from "react";
import styles from "./ResizableDiv.module.css";
import classNames from "classnames";
import { useSignal } from "@preact/signals-react";
interface ResizableDivProps {
  topContent: React.ReactNode;
  bottomContent: React.ReactNode;
}

const ResizableDiv: React.FC<ResizableDivProps> = ({ topContent, bottomContent }) => {
  const topHeight = useSignal(400);
  const bottomHeight = useSignal(400);
  const startYRef = useRef<number | null>(null);

  const minHeight = 100;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.button !== 0) return;

    startYRef.current = event.clientY;

    document.addEventListener("mousemove", handleMouseMove as any);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mouseleave", handleMouseLeave);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (startYRef.current !== null) {
      const deltaY = event.clientY - startYRef.current;

      // 需要移动位置
      const newTopHeight = Math.max(minHeight, topHeight.value + deltaY);
      const newBottomHeight = Math.max(minHeight, bottomHeight.value - deltaY);
      if (newTopHeight === minHeight || newBottomHeight === minHeight) return;

      startYRef.current = event.clientY;
      topHeight.value = newTopHeight;
      bottomHeight.value = newBottomHeight;
    }
  };

  const handleMouseUp = () => {
    cleanupEventListeners();
  };

  const handleMouseLeave = () => {
    cleanupEventListeners();
  };

  const cleanupEventListeners = () => {
    startYRef.current = null;
    document.removeEventListener("mousemove", handleMouseMove as any);
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("mouseleave", handleMouseLeave);
  };

  return (
    <div className={classNames(styles["resizable-container"], styles["resizable-div-container"])}>
      <div className={classNames(styles["resizable-div"], styles["top"])} style={{ height: `${topHeight.value}px` }}>
        {topContent}
      </div>
      <div className={styles["resize-bar"]} onMouseDown={handleMouseDown}></div>
      <div
        className={classNames(styles["resizable-div"], styles["bottom"])}
        style={{ height: `${bottomHeight.value}px` }}
      >
        {bottomContent}
      </div>
    </div>
  );
};

export default ResizableDiv;
