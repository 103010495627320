import { useSortable } from "@dnd-kit/sortable";
import classNames from "classnames";
import { CSS } from "@dnd-kit/utilities";
import css from "./SortableItem.module.scss";
import { useMemo } from "react";
import { SortableListUtil } from "../util";
import { useSortableListContainer } from "../container";
import { SortableToolbar } from "../SortableToolbar";
import { isFunction } from "lodash";

export function SortableItem<T>(props: SortableList.ItemProps<T>): JSX.Element {
  const { index, value, children, renderItemChildren, renderItemHead, itemClassName, onClick } = props;
  const { onSwitch, disabled } = useSortableListContainer();
  const { id } = value;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    disabled: !id,
    id: id,
  });

  const sortableItemId = useMemo(() => {
    return SortableListUtil.generateDomIdAttr(id);
  }, [id]);

  const innerStyle = useMemo(() => {
    return {
      transform: CSS.Transform.toString(transform),
      transition,
    };
  }, [transform, transition]);

  const sortableProps = useMemo(() => {
    return { id, attributes, listeners, setNodeRef, style: innerStyle };
  }, [attributes, id, innerStyle, listeners, setNodeRef]);

  const renderProps = {
    value,
    sortableProps,
    index,
  };

  const renderChild = (): any => {
    if (renderItemChildren) {
      return renderItemChildren(renderProps);
    }

    return children;
  };

  const _className = isFunction(itemClassName) ? itemClassName({ value, index }) : itemClassName;

  return (
    <div
      id={props.id}
      onClick={onClick}
      className={classNames(css["sortable-item"], _className)}
      data-testid="SortableItem"
      ref={props.containerRef}
      {...sortableItemId}
    >
      {renderItemHead && renderItemHead(renderProps)}
      <SortableToolbar hideHandle={disabled || props.disabledHandle} sortableProps={sortableProps} onSwitch={onSwitch}>
        {renderChild()}
      </SortableToolbar>
    </div>
  );
}
