import { StoryNodeDisplayType } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeDisplayType";
import { uploadTypes } from "../Nodes/constants";

export const PREVIEW_APP_Fields = {
  PREVIEW_APP_ID: "previewAppId",
  PREVIEW_METADATA_V2: "published_metadata_v2",
  DATA_APP_ID: "id",
} as const;

// FIXME: 发布时会重新更新 published_metadata 导致该字段被覆盖，暂时保存到其他字段上
// const PREVIEW_APP_METADATA_V2_PATH = ["published_metadata", Fields.PREVIEW_METADATA_V2];
export const PREVIEW_APP_METADATA_V2_PATH = [PREVIEW_APP_Fields.PREVIEW_METADATA_V2];
export const PREVIEW_APP_ID_PATH = ["v3", PREVIEW_APP_Fields.PREVIEW_APP_ID];

export const PreviewAppTypeMap = {
  AI_APP: "ai_app",
  STATIC_WEB: "static_web",
  MOBILE_APP: "mobile_app",
  CHAT_AI: "chat_ai",
} as const;

/**
 * 允许 chatai app 发布的输入节点类型
 */
export const PREVIEW_CHATAI_PUBLISH_INPUT_TYPES = [StoryNodeDisplayType.TEXT, ...uploadTypes];

/**
 * 允许 chatai app 发布的输出节点类型
 */
export const PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES = [StoryNodeDisplayType.CHATINTERFACE];

const defaultPreviewAppTypeList = [
  {
    title: "AI App",
    isStaticMode: false,
    describe: ["Allow users to generate content based on their inputs"],
    logoSrcName: "template-ai.png",
    infoContent: "Your users can enter inputs and generate results using AI functions you've created.",
    disabled: false,
    visible: true,
    appType: PreviewAppTypeMap.AI_APP,
    initLoadInput: true,
  },
  {
    title: "Static Website",
    isStaticMode: true,
    describe: ["Share fixed content generated from your project"],
    logoSrcName: "template-static.png",
    disabled: false,
    visible: true,
    appType: PreviewAppTypeMap.STATIC_WEB,
    initLoadInput: false,
  },
  {
    title: "AI Chat Agent",
    isStaticMode: false,
    describe: ["Create an AI chat agent using your source of knowledge"],
    logoSrcName: "template-chat.png",
    disabled: false,
    visible: true,
    appType: PreviewAppTypeMap.CHAT_AI,
    initLoadInput: false,
  },
  {
    title: "Mobile App",
    describe: ["Publish a mobile app", "Coming soon..."],
    logoSrcName: "template-mobile.png",
    disabled: true,
    visible: true,
    appType: PreviewAppTypeMap.MOBILE_APP,
    initLoadInput: false,
  },
] as PreviewApp.FormatChooseAppType[];

export const getDefaultPreviewAppTypeList = (props?: PreviewApp.GetPreviewAppTypeListProps) => {
  const { visibleStaticWeb, disabledChatAi } = props || {};
  return defaultPreviewAppTypeList.map(item => {
    return {
      ...item,
      visible: item.appType === PreviewAppTypeMap.AI_APP ? !!visibleStaticWeb : item.visible,
      disabled: item.appType === PreviewAppTypeMap.CHAT_AI ? !!disabledChatAi : item.disabled,
    };
  });
};

export const CHAT_APP_DEFAULTS = {
  title: "Hello, I am an AI trained to answer questions",
  subTitle: "",
};

/**
 * 合并 node 时排除的字段
 */
export const PREVIEW_MERGE_UPLOAD_EXCLUDE_PATHS = ["data.displayType", "data.textAreaValue"];

export const CHOOSE_FORMAT_CONFIRM = "choose formar confirm";

export const APP_NAME_TEMPLATE = "My {type} {count}";
