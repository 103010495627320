"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectSetObjectAttributesV2 = exports.ObjectSetObject = exports.ObjectSet = exports.ObjectSetRaceObject = void 0;
class ObjectSetRaceObject {
}
exports.ObjectSetRaceObject = ObjectSetRaceObject;
class ObjectSet {
    constructor() {
        this.objectSet = [];
    }
}
exports.ObjectSet = ObjectSet;
class ObjectSetObject {
}
exports.ObjectSetObject = ObjectSetObject;
class ObjectSetObjectAttributesV2 {
}
exports.ObjectSetObjectAttributesV2 = ObjectSetObjectAttributesV2;
