"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoItem = exports.VBDataNewsVideoList = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataNewsVideoList extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.news_video_collection = [];
    }
}
exports.VBDataNewsVideoList = VBDataNewsVideoList;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => VideoItem, [])
], VBDataNewsVideoList.prototype, "news_video_collection", void 0);
class VideoItem {
    constructor() {
        this.news_video = new NewsVideo();
    }
}
exports.VideoItem = VideoItem;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => NewsVideo)
], VideoItem.prototype, "news_video", void 0);
class NewsVideo {
    constructor() {
        this.video_thumbnail = "";
        this.video_duration = "";
        this.video_headline = "";
        this.video_url = "";
        this.source = new Source();
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Source)
], NewsVideo.prototype, "source", void 0);
class Source {
    constructor() {
        this.source_icon = "";
        this.source_text = "";
    }
}
