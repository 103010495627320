import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { getIt } from "@uikit/getIt";
import { Signal, signal } from "@preact/signals-react";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { FotNavigateController } from "@uikit/service/FotNavigateController";
import { isEmpty } from "lodash";

class FunctionPageState {
  success: Signal<boolean> = signal(false);
}

export class CreatorFunctionNormalPageBloc {
  state = new FunctionPageState();

  projectFunctionStore = getIt(ProjectFunctionStore);
  fotNavigateController = getIt(FotNavigateController);
  showNavPageStore = getIt(ShowNavPageStore);
  myModificationDataStroe = myModificationDataStroe;

  success(target: any): void {
    this.showNavPageStore.setShowNavPage(false);
    this.myModificationDataStroe.setShowMyModification(true);
    this.myModificationDataStroe.setMyModificationData(target);

    this.state.success.value = !isEmpty(target);
  }

  load(functionId?: string): void {
    if (!functionId) {
      return;
    }

    this.state.success.value = false;

    // 1. 首先保证用户function 已经设置完成
    this.projectFunctionStore.init().then(() => {
      // 已经设置了 function 数据
      if (
        !isEmpty(this.myModificationDataStroe.state.myModificationData) &&
        this.myModificationDataStroe.state.myModificationData.id === functionId
      ) {
        this.success(this.myModificationDataStroe.state.myModificationData);
        return;
      }

      // type 需要为空才是普通函数
      const target = this.projectFunctionStore.getFunctionNormal(functionId);
      if (!target) {
        console.error("Page Not Found");
        this.fotNavigateController.openCreatorHomePage();
        return;
      }

      this.success(target);
    });
  }
}
