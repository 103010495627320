import lottie from "lottie-web";
import { useEffect, useRef } from "react";

type FeedbackAnimationProps = {
  style: any;
  loader: any;
};
export function FeedbackAnimation(props: FeedbackAnimationProps) {
  const lottieRef = useRef(null);
  const animationRef = useRef<any>(null);

  useEffect(() => {
    if (lottieRef.current) {
      animationRef.current = lottie.loadAnimation({
        animationData: props.loader,
        container: lottieRef.current,
      });

      return () => {
        animationRef.current.destroy();
      };
    }
  }, [lottieRef]);

  return (
    <div style={props.style}>
      <div ref={lottieRef} />
    </div>
  );
}
