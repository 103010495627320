import { useEffect, useRef, useState } from "react";
import { GuideModalProps } from "./GuideModal";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { ShowNavPageStore } from "../HomeRoot/store/ShowNavPageStore";
import { PreferencesStore } from "imagica-corekit/dist/base/store/PreferencesStore";
import { CotStore } from "@uikit/store/CotStore";
import { CreateNewProjectService } from "../ProjectCanvas/CreateNewProject";

export type TriggerMessage = number | null | (() => void);
type TriggerType = "cot" | "manual";

export const useGuideContainer = (): any => {
  const showNavPageStore = getIt(ShowNavPageStore);
  const webConfigState = useStore(getIt(WebConfigStore)).value;
  const imagica_onboarding_video_guide = webConfigState.imagica_onboarding_video_guide;
  const cotStoreState = useStore(getIt(CotStore)).value;
  const preferencesStore: PreferencesStore = getIt(PreferencesStore);
  const createNewProjectService = getIt(CreateNewProjectService);

  const guideModalRef = useRef<{ triggerType: TriggerType; triggerCallback: () => void } | null>(null);

  const [guideConfig, setGuideConfig] = useState<GuideModalProps | null>();

  const handleTrigger = async (triggerType: TriggerType, triggerMessage: TriggerMessage): Promise<void> => {
    const triggerCallback = triggerMessage instanceof Function ? triggerMessage : (): void => {};
    const imagicaStudioTutorial = await preferencesStore.getByKey("imagica_studio_tutorial");
    if (guideModalRef.current !== null) {
      return triggerCallback();
    }
    if (triggerType === "cot" && imagicaStudioTutorial?.showCotGuide === true) {
      return triggerCallback();
    }
    if (triggerType === "manual" && imagicaStudioTutorial?.showManualGuide === true) {
      return triggerCallback();
    }
    executeTrigger(triggerType, triggerCallback);
  };

  const executeTrigger = (triggerType: TriggerType, triggerCallback: () => void): void => {
    guideModalRef.current = { triggerType, triggerCallback };
    handleSetModal();
  };

  const handleEnded = (): void => {
    if (showNavPageStore.state.showNavPage) {
      createNewProjectService.addNewProject();
    }

    closeGuide();
  };

  const closeGuide = (): void => {
    setGuideConfig(null);
    guideModalRef.current?.triggerCallback();
    guideModalRef.current = null;
  };

  const handleSetModal = async (): Promise<void> => {
    const imagicaStudioTutorial = await preferencesStore.getByKey("imagica_studio_tutorial");
    switch (guideModalRef.current?.triggerType) {
      case "cot":
        setGuideConfig({
          copy: {
            start: {
              title: imagica_onboarding_video_guide?.cot.title || "",
              subTitle: imagica_onboarding_video_guide?.cot.subtitle || "",
            },
            end: {
              title: imagica_onboarding_video_guide?.cot.end_title || "",
              subTitle: imagica_onboarding_video_guide?.cot.end_subtitle || "",
            },
          },
          src: imagica_onboarding_video_guide?.cot.video_url || "",
          onClose: handleEnded,
          onFinish: handleEnded,
        });
        await preferencesStore.set("imagica_studio_tutorial", { ...imagicaStudioTutorial, showCotGuide: true });
        break;
      case "manual":
        setGuideConfig({
          copy: {
            start: {
              title: imagica_onboarding_video_guide?.manual.title || "",
              subTitle: imagica_onboarding_video_guide?.manual.subtitle || "",
            },
            end: {
              title: imagica_onboarding_video_guide?.manual.end_title || "",
              subTitle: imagica_onboarding_video_guide?.manual.end_subtitle || "",
            },
          },
          src: imagica_onboarding_video_guide?.manual.video_url || "",
          onClose: closeGuide,
          onFinish: closeGuide,
        });
        await preferencesStore.set("imagica_studio_tutorial", { ...imagicaStudioTutorial, showManualGuide: true });
        break;
      default:
        setGuideConfig(null);
        break;
    }
  };
  useEffect(() => {
    if (cotStoreState.guideTriggerMessage.cot) {
      handleTrigger("cot", cotStoreState.guideTriggerMessage.cot);
    }

    // eslint-disable-next-line
  }, [cotStoreState.guideTriggerMessage.cot]);

  useEffect(() => {
    if (cotStoreState.guideTriggerMessage.manual) {
      handleTrigger("manual", cotStoreState.guideTriggerMessage.manual);
    }

    // eslint-disable-next-line
  }, [cotStoreState.guideTriggerMessage.manual]);

  return {
    guideConfig,
  };
};
