"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestaurantModel = exports.RestaurantState = void 0;
var RestaurantState;
(function (RestaurantState) {
    RestaurantState["IDLE"] = "IDLE";
    RestaurantState["LOADING"] = "LOADING";
    RestaurantState["COMPLETED"] = "COMPLETED";
    RestaurantState["ERROR"] = "ERROR";
})(RestaurantState || (exports.RestaurantState = RestaurantState = {}));
class RestaurantModel {
    constructor(value, lat, lng, mode) {
        this.value = value;
        this.lat = lat;
        this.lng = lng;
        this.mode = mode;
    }
}
exports.RestaurantModel = RestaurantModel;
