import isBlank from "@sedan-utils/is-blank";
import { some } from "lodash";

// 某个属性为空则返回true
var defaultEmpty = function defaultEmpty(data) {
  return some(data, function (value) {
    return isBlank(value);
  });
};
// 传入的指定属性都为空则返回 true
var isAllAttrsEmpty = function isAllAttrsEmpty(attrs, data) {
  return attrs.every(function (value) {
    return isBlank(data[value]);
  });
};
export var VBComponentsDataEmptyMapping = {
  NewsVideo: defaultEmpty,
  YoutubeVideo: defaultEmpty,
  YoutubeSearchResults: defaultEmpty,
  C1: function C1(data) {
    var attrs = ["image", "header_text", "description_text"];
    return isAllAttrsEmpty(attrs, data);
  },
  C2: function C2(data) {
    var attrs = ["image_list"];
    return isAllAttrsEmpty(attrs, data);
  },
  C3: function C3(data) {
    var attrs = ["image", "header_text", "description_text"];
    return isAllAttrsEmpty(attrs, data);
  },
  C4: function C4(data) {
    var attrs = ["image", "header_text", "description_text"];
    return isAllAttrsEmpty(attrs, data);
  },
  ShoppingSearchResults: defaultEmpty,
  mapView: defaultEmpty,
  Text: defaultEmpty,
  WeatherCurrent: defaultEmpty,
  WeatherWeekly: defaultEmpty,
  ItineraryPlanning: defaultEmpty,
  TouristAttraction: defaultEmpty,
  NewsHeadline: defaultEmpty,
  ShoppingProduct: defaultEmpty,
  NewsVideoList: defaultEmpty,
  DishRecommendation: defaultEmpty,
  RestaurantRecommendation: defaultEmpty,
  TopicWithRelatedPeople: defaultEmpty,
  TopicWithRelatedDetail: defaultEmpty,
  SeasonalTravelDestinationPlan: defaultEmpty,
  NewsDigest: defaultEmpty,
  ImageNewsGrid: defaultEmpty,
  NewsDigestDetails: defaultEmpty,
  showTrip: defaultEmpty
};