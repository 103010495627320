import { Api } from "@uikit/service/api";
import func from "@uikit/func";

export default {
  async getUIFromIntelligentAppProject(projectName, thought, loadingFunc) {
    const api = new Api(false);
    let res = {};
    try {
      if (typeof loadingFunc === "function") {
        loadingFunc(true);
      }
      let url = `/be/bas-demo-v4/brain_studios/run_intelligent_app?template_name=${projectName}`;
      if (thought) {
        url = `${url}&thought=${thought}`;
      }
      res = await api.getWithError(url);
      res = res.data;
    } catch (error) {
      func.messageUtil("Failed to run intelligent app project", "error");
      console.error(error);
    } finally {
      if (typeof loadingFunc === "function") {
        loadingFunc(false);
      }
      return res;
    }
  },
};
