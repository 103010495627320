"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioProjectAttributesV2Node = exports.StudioProjectAttributesV2NodePositionAbsolute = exports.StudioProjectAttributesV2NodePosition = exports.StudioProjectAttributesV2NodeDataEdgeIndexRef = exports.StudioProjectAttributesV2NodeDataNodeIndexRef = exports.StudioProjectAttributesV2NodeData = void 0;
const class_transformer_1 = require("class-transformer");
require("reflect-metadata");
class StudioProjectAttributesV2NodeData {
}
exports.StudioProjectAttributesV2NodeData = StudioProjectAttributesV2NodeData;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2NodeDataNodeIndexRef)
], StudioProjectAttributesV2NodeData.prototype, "nodeIndexRef", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2NodeDataEdgeIndexRef)
], StudioProjectAttributesV2NodeData.prototype, "edgeIndexRef", void 0);
class StudioProjectAttributesV2NodeDataNodeIndexRef {
}
exports.StudioProjectAttributesV2NodeDataNodeIndexRef = StudioProjectAttributesV2NodeDataNodeIndexRef;
class StudioProjectAttributesV2NodeDataEdgeIndexRef {
}
exports.StudioProjectAttributesV2NodeDataEdgeIndexRef = StudioProjectAttributesV2NodeDataEdgeIndexRef;
class StudioProjectAttributesV2NodePosition {
}
exports.StudioProjectAttributesV2NodePosition = StudioProjectAttributesV2NodePosition;
class StudioProjectAttributesV2NodePositionAbsolute {
}
exports.StudioProjectAttributesV2NodePositionAbsolute = StudioProjectAttributesV2NodePositionAbsolute;
class StudioProjectAttributesV2Node {
    constructor() {
        this.id = "";
    }
}
exports.StudioProjectAttributesV2Node = StudioProjectAttributesV2Node;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2NodeData)
], StudioProjectAttributesV2Node.prototype, "data", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2NodePosition)
], StudioProjectAttributesV2Node.prototype, "position", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2NodePositionAbsolute)
], StudioProjectAttributesV2Node.prototype, "positionAbsolute", void 0);
