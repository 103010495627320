import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class WindowSizeStoreState {
  width = 0;
  height = 0;
  zoom = 1;
}

export class WindowSizeStore extends StoreBase<WindowSizeStoreState> {
  constructor() {
    super(() => new WindowSizeStoreState());
  }

  updateSize(state: WindowSizeStoreState) {
    this.emit(Object.assign(new WindowSizeStoreState(), state));
  }
}
