"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class LightTheme {
}
exports.LightTheme = LightTheme;
LightTheme.theme = {
    saasAiAppTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.saasAiAppTheme,
    },
    nodeTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.nodeTheme,
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        renderingTransitionColors: "#ecf2fc",
        nodeWrapperShadow: "0px 30px 60px 0px rgba(0, 0, 0, 0.15)",
        nodeBackdropFilter: "blur(20px)",
    },
};
