import { MoveToHandlerResult } from "@uikit/cases/canvasFocus/FocusController";

/**
 * 该工具类用来控制 commandbar 状态和 edgeStateSignal 两个状态之间的关系
 *
 * 1. 现在项目中有两个状态分别是
 *  - edgeStateSignal 表示输入框的状态有input，run，generating 三种状态
 *  - `showImageGenSelectSingle` 表示是否显示 commandbar 下拉框 ture｜false
 *  - 但是改变 `edgeStateSignal` 状态时并不能直接通过逻辑调用 `onChangeVisibleCommandBar` 显示commandbar，需要等待聚焦视口结束后才能正确的显示 comnandbar dropdown
 *  - 在此修改之前没有找到准确的api能够获取动画执行完成的事件
 *
 * 2. 该工具类只针对 onChangeVisibleCommandBar(true) 且 `useFocusCustomEdge/setEdgeState/focusEdgeOrDropdown()`
 *  - 情况的处理其他情况保持不变
 *
 */
export class CommandBarController {
  public starting = false;
  private handlers: ((param: MoveToHandlerResult) => void)[] = [];

  close(): void {
    this.starting = false;
    this.handlers = [];
  }

  hasTriggering(): boolean {
    return this.starting;
  }

  start(): void {
    this.close();

    this.starting = true;
  }

  observer(fn: (result: MoveToHandlerResult) => void): void {
    this.handlers.push(fn);
  }
  notify(param: MoveToHandlerResult): void {
    this.handlers.forEach(item => {
      item(param);
    });
  }
}
