import { Api } from "@uikit/service/api";
import dayjs from "dayjs";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { getIt } from "@uikit/getIt";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
// import { useStore as useImagicaStore} from "imagica-uikit/dist/hooks/useStore";
import { Meta } from "imagica-corekit/dist/base/cutil/Meta";
import isBlank from "@sedan-utils/is-blank";
import store from "@store/index";
import qs from "qs";
import { PreviewUtil } from "imagica-corekit/dist/cases/preview/PreviewUtil";
import { previewStore } from "@uiview/store/PreviewStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import func from "@uikit/func";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

const gql = getIt(ObjectRelationGqlService);
class ProductParams {
  name = "";
  description?: string;
  metadata = {
    type: "",
    uuid: "",
    guest_id: "",
  };
}
export class PreviewHeaderViewBlocV2 {
  api = new Api(false);
  brainClient = getIt(BrainClient);
  publishSubscriptionStore = getIt(PublishSubscriptionStore);
  creatorNodesStore: CreatorNodesStore = getIt(CreatorNodesStore);

  featureTags: any;

  previewAppStore = getIt(PreviewAppStore);
  state = this.publishSubscriptionStore.state;
  constructor() {
    const homeStore = getIt(HomeStore);
    this.featureTags = homeStore.state.featureTags;
  }

  publishStr(): string {
    let str = "Never published";
    const published_metadata = this.previewAppStore.getPublishMetadata();
    // sutd-1722: published_metadata
    if (published_metadata?.published_at) {
      const time = dayjs(published_metadata.published_at).fromNow();
      str = `Published ${time}`;
    }
    // if(this.selectedTemplate?.published_metadata?.published_at) {
    //   const time = dayjs(this.selectedTemplate.published_metadata.published_at).fromNow()
    //   str = `Published ${time}`
    // }
    return str;
  }

  // TODO: refactor
  get hasPermission(): boolean {
    return (
      !this.featureTags.disableMultiRoundChatOnboarding &&
      getIt(ChatOnboardingStore).state.selectTemplateInModal === ChoosingFormat.AUTOIMAGICA
    );
  }

  async handleSubscriptionPrice(
    index: string,
    guest_id: string,
    v2item: PreviewApp.PublishedMetadataV2Type
  ): Promise<void> {
    const item = previewStore.state.previewAppList.find(x => x.id === previewStore.state.previewAppAcivieId);
    if (item) {
      // 获取 product id
      const productData: ProductParams = {
        name: item.title || "",
        metadata: {
          type: "studios",
          uuid: index,
          guest_id,
        },
      };
      if (item.v2App?.appDescription) {
        productData.description = item.v2App.appDescription;
      }

      let stripe_product_id = v2item.stripe_product_id;
      if (isBlank(stripe_product_id)) {
        const res = await tryPromise(this.brainClient.saaSMonetization.subscriptionProduct(productData));

        if (!res.data?.data.id) {
          throw new Error("Request subscription product failed.");
        }

        stripe_product_id = res.data.data.id;
      } else {
        const res = await tryPromise(
          this.brainClient.saaSMonetization.putSubscriptionProduct(productData, stripe_product_id)
        );

        if (!res.data?.data.id) {
          throw new Error("put subscription product failed.");
        }
      }
      let prev_unit_amount: number | undefined = undefined;
      let stripe_price_id: string = v2item.stripe_price_id;
      if (!isBlank(stripe_price_id)) {
        const res = await this.publishSubscriptionStore.getShareAppSubscriptionPrice(stripe_price_id);
        prev_unit_amount = res?.unit_amount;
      }
      // 获取price id
      if (v2item.subscription_price) {
        const unit_amount = parseInt(v2item.subscription_price) * 100;
        if (prev_unit_amount !== unit_amount) {
          if (!isBlank(stripe_price_id)) {
            const deactivePriceRes = await tryPromise(
              this.brainClient.saaSMonetization.deactiveSubscriptionPrice(stripe_price_id)
            );
            if (deactivePriceRes.error) {
              console.error("deactive price error", deactivePriceRes.error);
            }
          }
          const priceData = {
            currency: "usd",
            unit_amount: unit_amount,
            product: stripe_product_id,
            recurring: {
              interval: "month",
              interval_count: 1,
            },
            metadata: {
              type: "studios",
              uuid: index,
              guest_id,
            },
          };
          const newPriceRes = await tryPromise(this.brainClient.saaSMonetization.subscriptionPrice(priceData));
          if (!newPriceRes.data?.data.id) {
            throw new Error("Request subscription price failed.");
          }
          stripe_price_id = newPriceRes.data.data.id;
        }
        previewStore.setChargeOptionUpdated("Subscription");
        this.previewAppStore.updatePublishedMetadataV2({
          stripe_product_id: stripe_product_id,
          stripe_price_id: stripe_price_id,
          stripe_account_id: isBlank(this.state.accountId) ? v2item.stripe_account_id : this.state.accountId,
        });
      }
    }
  }
  getActivePublishMetadata(selectedTemplate: any): any {
    const published_metadata_v2 = selectedTemplate?.published_metadata_v2 || [];
    const item = published_metadata_v2.find(
      (x: PreviewApp.PublishedMetadataV2Type) => x.id === previewStore.state.previewAppAcivieId
    );
    return item;
  }
  async handleSubscription(selectedTemplate: any): Promise<void> {
    const item = this.getActivePublishMetadata(selectedTemplate);
    if (!item) {
      return;
    }
    if (item.charge_option === "Subscription") {
      if (!item.subscription_price) {
        throw new Meta("Error", "Please fill in the subscription price");
      }
      const linkParams = qs.parse(selectedTemplate.published_metadata.link.split("?").last());
      const guest_id: string = linkParams.q?.toString() || "";
      await this.handleSubscriptionPrice(selectedTemplate.globaluuid, guest_id, item);
    }
    if (item.charge_option === "Free") {
      if (item.stripe_product_id) {
        await tryPromise(
          this.brainClient.saaSMonetization.putSubscriptionProduct({ active: false }, item.stripe_product_id)
        );
      }

      if (item.stripe_price_id) {
        await tryPromise(this.brainClient.saaSMonetization.deactiveSubscriptionPrice(item.stripe_price_id));
      }

      this.previewAppStore.updatePublishedMetadataV2({
        subscription_price: "",
        subscription_free_period: "",
        policy_url: "",
        policy_privacy_url: "",
        stripe_account_id: "",
        stripe_price_id: "",
        stripe_product_id: "",
        agreement_checked: false,
      });
      previewStore.setChargeOptionUpdated("Free");
    }
    const newTemplate = store.getState().studio.selectedTemplate;
    await gql.updateObject(parseInt(selectedTemplate.id), {
      name: "studio_project",
      attributes: newTemplate,
    });
  }

  fullUrl(inputValue: string, hostInputValue: string): string {
    let host = hostInputValue.trim();
    host = isBlank(host) ? "www" : host;
    return PreviewUtil.combineHostAndDomain(host, inputValue);
  }

  getDomainName(link: string): string {
    if (link) {
      const regResult = link.match(/[^?]+/);
      return regResult?.[0] ?? "";
    }
    return "";
  }

  getPublishEventProps(selectedTemplate: any, shareLink: string, template: any): Record<string, any> {
    const mateData = this.getActivePublishMetadata(selectedTemplate);
    const setingUIData = selectedTemplate.v3?.UI?.find((item: any) => {
      return item.id === mateData.id;
    });
    if (setingUIData && setingUIData.settings) {
      const custom_domain_flag = setingUIData.settings.domain?.data?.open ?? false;
      const custom_domain_name = PreviewUtil.getCustomDomain(setingUIData);
      const domain_name = this.getDomainName(shareLink);
      const isFreeApp = mateData.charge_option === "Free";
      return {
        name: setingUIData.v2App.appName,
        uuid: mateData.uuid,
        publish_view_name: setingUIData.title,
        design_template: setingUIData.theme,
        font_style: setingUIData.settings.fontFamily,
        logo: setingUIData.settings.logoImg,
        imagica_brainding: setingUIData.settings.branding,
        custom_domain_flag: custom_domain_flag,
        custom_domain_name: custom_domain_name,
        embed_size_width: setingUIData.settings.width,
        embed_size_length: setingUIData.settings.height,
        charging_option: mateData.charge_option && mateData.charge_option.toLowerCase(),
        tos_env_agreement: mateData.agreement_checked,
        subscription_price: isFreeApp ? 0 : mateData.subscription_price,
        free_trial_period: isFreeApp ? 0 : mateData.subscription_free_period,
        copyright_info: setingUIData?.v2App?.copyright || "Copyright © 2023 Brain Technologies Inc",
        domain_name: domain_name,
        new_project: PreviewUtil.isNewProject(template),
        num_nodes: this.creatorNodesStore.getOnlyNodes().length,
        num_edges: this.creatorNodesStore.getOnlyEdges().length,
        template_name: selectedTemplate.defaultTemplateName,
      };
    } else {
      return {
        uuid: mateData.uuid,
      };
    }
  }

  subscriptionAndNoPrice(): boolean {
    const template = store.getState().studio.selectedTemplate;
    const item = this.getActivePublishMetadata(template);
    return (
      item?.charge_option === "Subscription" &&
      (func.isEmpty(item?.subscription_price) || Number(item.subscription_price) === 0)
    );
  }
}
