"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdapterName = exports.AdapterRegistry = void 0;
const AdapterUtil_1 = require("../../storyV2/adapter/AdapterUtil");
class AdapterRegistry {
    constructor(personalFuncStore, globalFuncStore, functionV2Store, meStore) {
        this.personalFuncStore = personalFuncStore;
        this.globalFuncStore = globalFuncStore;
        this.functionV2Store = functionV2Store;
        this.meStore = meStore;
        this.map = {};
        this.constructorMap = {};
    }
    register(adapter, name) {
        this.map[name] = adapter;
    }
    registConstructor(adapter, name) {
        this.constructorMap[name] = adapter;
    }
    getFromMap(name) {
        const inMap = this.map[name];
        if (inMap === undefined) {
            const adaConstructor = this.constructorMap[name];
            if (adaConstructor != undefined) {
                return adaConstructor();
            }
        }
        return inMap;
    }
    async getApapterByAlias(name) {
        const aliasMap = {};
        const findV2Func = await this.functionV2Store.get();
        findV2Func.forEach(f => {
            f.attributesV2?.aliases?.forEach(alia => {
                aliasMap[alia] = f.attributesV2?.value ?? "";
            });
        });
        const newName = aliasMap[name];
        return this.getFromMap(newName);
    }
    async get(name, funcId, funcValue, isBluePrint) {
        if (isBluePrint) {
            return this.get(AdapterName.blue_print);
        }
        if (funcValue) {
            const exsitAdapter = this.getFromMap(funcValue);
            if (exsitAdapter != undefined) {
                return exsitAdapter;
            }
        }
        let exsitAdapter = this.getFromMap(name);
        if (exsitAdapter === undefined) {
            exsitAdapter = await this.getApapterByAlias(name.replace("/", ""));
        }
        if (exsitAdapter != undefined) {
            return exsitAdapter;
        }
        const findV2Func = await this.functionV2Store.findDest({
            edgeName: name,
            funcId: funcId,
            funcValue: funcValue ?? name.replace("/", ""),
        });
        if (findV2Func) {
            const customFuncType = AdapterUtil_1.AdapterUtil.customFuncType(findV2Func);
            if (customFuncType !== undefined) {
                const customAdapter = this.getFromMap(customFuncType);
                customAdapter.customFuncDest = findV2Func;
                return customAdapter;
            }
        }
        const findPersonalFunc = await this.personalFuncStore.findDest({
            edgeName: name,
            funcId: funcId,
            funcValue: funcValue ?? name.replace("/", ""),
        });
        if (findPersonalFunc) {
            const customFuncType = AdapterUtil_1.AdapterUtil.customFuncType(findPersonalFunc);
            if (customFuncType !== undefined) {
                const customAdapter = this.getFromMap(customFuncType);
                customAdapter.customFuncDest = findPersonalFunc;
                return customAdapter;
            }
        }
        const findGlobalFunc = await this.globalFuncStore.findDest({
            edgeName: name,
            funcId: funcId,
            funcValue: funcValue ?? name.replace("/", ""),
        });
        if (findGlobalFunc) {
            const customFuncType = AdapterUtil_1.AdapterUtil.customFuncType(findGlobalFunc);
            if (customFuncType !== undefined) {
                const customAdapter = this.getFromMap(customFuncType);
                customAdapter.customFuncDest = findGlobalFunc;
                return customAdapter;
            }
        }
        return exsitAdapter;
    }
}
exports.AdapterRegistry = AdapterRegistry;
var AdapterName;
(function (AdapterName) {
    AdapterName["splitOutput"] = "/Split Output";
    AdapterName["stockPrices"] = "/Stock Prices";
    AdapterName["amazonShoppingOld"] = "/Amazon Shopping(Old)";
    AdapterName["weeeShoppingOld"] = "/Weee Shopping(Old)";
    AdapterName["jdShopping"] = "/Jd Shopping";
    AdapterName["dallE2"] = "/DALL\u00B7E 2";
    AdapterName["imageSearch"] = "/Image search";
    AdapterName["maps"] = "/Maps";
    AdapterName["food"] = "/Show Food Interface";
    AdapterName["midjourney"] = "/Midjourney";
    AdapterName["video"] = "/Video";
    AdapterName["customApi"] = "customApi";
    AdapterName["customCode"] = "customCode";
    AdapterName["customFunction"] = "customFunction";
    AdapterName["addDataSource"] = "/Add Data Source";
    AdapterName["protein"] = "/Protein";
    AdapterName["askImagica"] = "/Ask Imagica";
    AdapterName["chatInterface"] = "/Chat Interface";
    AdapterName["identifier"] = "identifier";
    AdapterName["blue_print"] = "blue_print";
    AdapterName["interviewBot"] = "/Interview Bot";
    // // v2
    AdapterName["stockValue"] = "showStockInterface";
    AdapterName["addDataSourceValue"] = "SourceOfTruth";
    AdapterName["dallE2Value"] = "imageGen";
    AdapterName["imageSearchValue"] = "imageSearch";
    AdapterName["proteinValue"] = "Protein";
    AdapterName["videoValue"] = "video";
    AdapterName["mapValue"] = "map";
    AdapterName["jdShoppingValue"] = "jdShopping";
    AdapterName["amazonShoppingOldValue"] = "amazonShopping";
    AdapterName["weeeShoppingOldValue"] = "weeeShopping";
    AdapterName["splitOutputValue"] = "splitOutput";
    AdapterName["askImagicaValue"] = "askBrain";
    AdapterName["showFoodInterfaceValue"] = "showFoodInterface";
    AdapterName["midjourneyValue"] = "midjourneyV2";
    AdapterName["chatInterfaceValue"] = "chatInterface";
    AdapterName["interviewBotValue"] = "interviewBot";
})(AdapterName || (exports.AdapterName = AdapterName = {}));
