import CustomDropdown from "@views/thinking-layout-editor/CustomDropdown";
import { CommandDropdown } from "./CommandDropdown";

/**
 *
 * 边上的 commandBar 应是一个单独存在的重要组件 现在冗余在了 Edge 中
 *
 * @todo 该组件应该得到重写
 */
export function CommandBar() {
  throw new Error("CommandBar not implement");
}

CommandBar.Dropdown = CommandDropdown;
// CommandBar.CommandPanel = CommandPanel
CommandBar.CommandPanel = CustomDropdown;
