import { createSlice } from "@reduxjs/toolkit";

const studioSlice = createSlice({
  name: "studio",
  initialState: {
    selectedDomain: "outfit",
    templates: [],
    layoutComponents: [],
    selectedTemplate: {
      variables: {
        thought: {
          type: "string",
          value: "",
        },
      },
    },
    thought: "",
    prompt: "",
    variables: {
      thought: {
        type: "string",
        value: "",
      },
    },
    loadingState: {
      promptCard: false,
      variableCard: false,
      logicCard: false,
      layoutCard: false,
      promptNewBtn: false,
      promptSaveBtn: false,
      promptRunBtn: false,
      logicRunBtn: false,
      feedScreen: false,
      studioDomainSelect: false,
      domainNewBtn: false,
      layoutRunBtn: false,
      existingProjectModalOK: false,
      debounceSelectLoading: false,
    },
    updateSelectedTemplate: false,
    updateSelectedLogic: false,
    updateLayout: false,
    saveAsExistingTemplate: false,
    isNewProjectModalOpen: false,
  },
  reducers: {
    setTemplates(state, action) {
      state.templates = action.payload;
    },
    setSelectedTemplate(state, action) {
      state.selectedTemplate = action.payload;
    },
    setPrompt(state, action) {
      state.prompt = action.payload;
    },
    setThought(state, action) {
      if (state.variables && state.selectedTemplate.variables) {
        state.variables.thought.value = action.payload;
        state.selectedTemplate.variables.thought.value = action.payload;
      }
    },
    setVariables(state, action) {
      state.variables = action.payload;
      state.selectedTemplate.variables = action.payload;
    },
    setLoadingState(state, action) {
      Object.entries(action.payload).forEach(each => {
        state.loadingState[each[0]] = each[1];
      });
    },
    setSelectedDomain(state, action) {
      state.selectedDomain = action.payload;
    },
    setUpdateSelectedTemplate(state, action) {
      state.updateSelectedTemplate = action.payload;
    },
    setUpdateSelectedLogic(state, action) {
      state.updateSelectedLogic = action.payload;
    },
    setUpdateLayout(state, action) {
      state.updateLayout = action.payload;
    },
    setSaveAsExistingTemplate(state, action) {
      state.saveAsExistingTemplate = action.payload;
    },
    setLayoutComponents(state, action) {
      state.layoutComponents = action.payload;
    },
    setIsNewProjectModalOpen(state, action) {
      state.isNewProjectModalOpen = action.payload;
    },
  },
});

export const studioActions = studioSlice.actions;
export default studioSlice.reducer;
