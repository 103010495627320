export const RegenerateIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Update">
      <rect width="20" height="20" rx="10" fill="#ACAEB5" />
      <path
        id="Icon"
        d="M4.43945 8.88889C4.43945 8.88889 5.55333 7.37123 6.45825 6.46569C7.36317 5.56015 8.61367 5 9.99501 5C12.7564 5 14.995 7.23858 14.995 10C14.995 12.7614 12.7564 15 9.99501 15C8.86918 15 7.83027 14.6279 6.99452 14M4.43945 8.88889V5.55556M4.43945 8.88889H7.77279"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
