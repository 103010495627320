import { ReactNode } from "react";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import { getIt } from "@uikit/getIt";
import { Signal, signal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { ImagicaCreatorPlan } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { ImagicaFeature } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaFeature";
import { logEvent } from "@uikit/service/amplitude";
import func from "@uikit/func";

export type PowerMaskProps = {
  children: ReactNode;
  feature: string[];
  featureItem: string; // if a feature has child items, like a signle theme under premium_design_templates
  defaultWallName?: string;
  onClickUpgrade?: () => void;
  enableCls?: boolean;
};

export type PowerChildProps = {
  defaultWallName?: string;
  featureDescription: Signal<string>;
  minimumPlanObj: Signal<ImagicaCreatorPlan>;
  onClick: () => void;
};

class PowerMaskState {
  showMask: Signal<boolean> = signal(true);
  featureDescription: Signal<string> = signal("");
  minimumPlanObj: Signal<ImagicaCreatorPlan> = signal(new ImagicaCreatorPlan());
}

export class PowerMaskViewBloc {
  store: SubscriptionStore = getIt(SubscriptionStore);

  constructor(public props: PowerMaskProps) {}
  state = new PowerMaskState();

  async loadData(): Promise<void> {
    if (this.props.feature === undefined || this.props.feature.length === 0) {
      this.state.showMask.value = false;
      return;
    }
    const imagicaFeatures = await this.store.imagicaFeatures();
    const planList = await this.store.imagicaCreatorPlans().catch(() => {
      func.messageUtil("Get plan list failed.", "error");
      return [];
    });
    const currentPlan = await this.store.currentPlan();

    const featureValue = this.props.feature[0];
    const featureObject = imagicaFeatures.find((item: ImagicaFeature) => {
      return item.value === featureValue;
    });
    //feature description
    if (featureObject) {
      this.state.featureDescription.value = featureObject.description;
    }
    //minimum plan
    const minimumPlan = featureObject?.minimum_plan;
    const minimumPlanObj = planList.find(plan => {
      return plan.value === minimumPlan;
    });
    if (minimumPlanObj && !isBlank(minimumPlanObj)) {
      this.state.minimumPlanObj.value = minimumPlanObj;
    }

    if (featureObject?.plans.includes(currentPlan.value)) {
      this.state.showMask.value = false;
    } else {
      this.state.showMask.value = true;
    }
  }

  getPlanPath(currentPath: string): string {
    return `${currentPath}/upgrade_plans`;
  }

  handleUpgrade(): void {
    logEvent("upgrade_from_paywall_feature", this.props);
  }
}
