import React, { useEffect } from "react";
import classNames from "classnames";
import styles from "./FooterUrl.module.scss";
import { logEvent } from "@uikit/service/amplitude";
import { getIt } from "@uikit/getIt";
import { WebConfigState, WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useSignal } from "@preact/signals-react";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
interface FooterUrlProps {
  className?: string;
  showInfo: boolean;
}

export function FooterUrl(props: FooterUrlProps): React.JSX.Element {
  const privacyUrl = useSignal("https://brain.ai/privacy");
  const tosPlatformUrl = useSignal("https://brain.ai/tos-platform");
  const tosEnvironmentUrl = useSignal("https://brain.ai/tos-environment");
  const copyrightUrl = useSignal("https://brain.ai/copyright-policy");
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const showBuilt = saasUIData?.settings?.branding || false;

  const homePluginStore: HomePluginStore = getIt(HomePluginStore);

  const isShare = homePluginStore.state.isShare;

  useEffect(() => {
    getIt(WebConfigStore)
      .get()
      .then((webConfig: WebConfigState) => {
        privacyUrl.value = webConfig?.static_links?.privacy || privacyUrl.value;
        tosPlatformUrl.value = webConfig?.static_links?.terms_of_service_platform || tosPlatformUrl.value;
        tosEnvironmentUrl.value = webConfig?.static_links?.terms_of_service_environment || tosEnvironmentUrl.value;
        copyrightUrl.value = webConfig?.static_links?.copyright_policy || copyrightUrl.value;
      });
    // eslint-disable-next-line
  }, []);

  const clickCopyright = (): void => {
    copyrightUrl.value && window.open(copyrightUrl.value);
  };

  const copyright = saasUIData?.settings?.copyright || "Copyright © 2023 Brain Technologies Inc";

  return (
    <footer className={classNames(styles.shareFooterUrl, props.className, isShare && styles.shareBox)}>
      <div className={styles.info}>
        {props.showInfo && <RenderInfo showBuilt={showBuilt} />}
        {showBuilt && <RenderBuilt />}
        <span className={styles.copyright} onClick={clickCopyright}>
          {copyright}
        </span>
      </div>
      {isShare && (
        <div className={styles.url}>
          <span
            className={styles.privacy}
            onClick={() => {
              logEvent("click_privacy_policy", {
                project_static_id: homePluginStore.state.studioData?.attributes?.project_id,
                source: isShare ? "webapp" : "preview",
              });
              window.open(privacyUrl.value);
            }}
          >
            Privacy policy
          </span>
          <span
            className={styles.terms}
            onClick={() => {
              logEvent("click_terms_of_use", {
                project_static_id: homePluginStore.state.studioData?.attributes?.project_id,
                source: isShare ? "webapp" : "preview",
              });
              window.open(tosPlatformUrl.value);
            }}
          >
            Terms of Service (Platform)
          </span>
          <span
            className={styles.terms}
            onClick={() => {
              logEvent("click_terms_of_use", {
                project_static_id: homePluginStore.state.studioData?.attributes?.project_id,
                source: isShare ? "webapp" : "preview",
              });
              window.open(tosEnvironmentUrl.value);
            }}
          >
            Terms of Service (Environment)
          </span>
        </div>
      )}
    </footer>
  );
}

function RenderBuilt(): React.JSX.Element {
  const isMobile = window.navigator.userAgent.includes("Mobile");

  const homePluginStore: HomePluginStore = getIt(HomePluginStore);

  const isShare = homePluginStore.state.isShare;

  async function onClickBrainStudios(): Promise<void> {
    if (isShare) {
      logEvent("click_imagica_home", {
        project_static_id: homePluginStore.state.studioData?.attributes?.project_id,
        source: "webapp",
      });
    } else {
      logEvent("click_imagica_home", {
        source: "preview",
      });
    }
    window.open("https://get.imagica.ai", "target");
  }

  return (
    <>
      {isMobile ? (
        <span onClick={onClickBrainStudios}>Built with Imagica by Brain.ai.</span>
      ) : (
        <span>
          {"Built with "}
          <span className={styles.imagica} onClick={onClickBrainStudios}>
            Imagica
          </span>
          {" by Brain.ai"}
        </span>
      )}
    </>
  );
}

function RenderInfo({ showBuilt }: { showBuilt: boolean }): React.JSX.Element {
  return <span>Information may be inaccurate due to its use of a General AI model.</span>;
}
