import { useSignal } from "@preact/signals-react";
import { RightClickPopView } from "@uiview/views/HomeViews/RightClickPopView/RightClickPopView";
import { rightMenuStore } from "@uikit/store/rightMenuStore";
import { get } from "lodash";
import { forbidAddToPreviewType, transparentMarkerEnd } from "../homeConst";
import { useHomePublicMethods } from "../useHomeUtilMethods";
import { useHomeDataRefMethods } from "../useHomeDataRefMethods";
import { useCallback, useEffect } from "react";
import { getIt } from "@uikit/getIt";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "@store/editor";
import { useNavigate } from "react-router-dom";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import func from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import { RootState } from "@store/index";
import { fotActions } from "@store/fot";
import { Node } from "reactflow";
import { CanvasRightMenuManager } from "../manager/CanvasRightMenuManager";
import { useRightMenuMethods } from "../useRightMenuMethods";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { functionPopupStore } from "@uikit/store/FunctionPopupStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { GROUPID_REG } from "@views/thinking-layout-editor/constants";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";

// FIXME:OPT 右键菜单显影状态会刷新 homeuseplugin
export function CreatorRightMenu(): JSX.Element {
  const homePluginStore = getIt(HomePluginStore);
  const meStore = getIt(MeStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const previewAppStore = getIt(PreviewAppStore);
  const creatorPreviewService = getIt(CreatorPreviewService);
  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const canvasDataRef = getIt(CanvasDataRef);
  const showRightClickPop = useSignal(false);
  const homePublicMethods = useHomePublicMethods();
  const homeDataRefMethods = useHomeDataRefMethods();
  const homeRightMenuMethods = useRightMenuMethods();

  const projectPageState = useImagicaStore(projectPageStore).value;

  const canvasRightMenuTrigger = CanvasRightMenuManager.use({ showRightClickPop });

  useEffect(() => {
    canvasRightMenuTrigger.setupContextMenu();
    creatorRefStore.homeRef.current?.addEventListener("click", canvasRightMenuTrigger.onMenuCancel);

    return (): void => {
      canvasRightMenuTrigger.unloadContextMenu();
      creatorRefStore.homeRef.current?.removeEventListener("click", canvasRightMenuTrigger.onMenuCancel);
    };
  }, [canvasRightMenuTrigger, creatorRefStore.homeRef, rightMenuStore.state.showRightClickPop]);

  // redux
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkEdgeArr = useSelector((state: RootState) => state.fot.checkEdgeArr) as any[];
  const setIsModelOpen = useCallback(
    (val: boolean) => {
      dispatch(fotActions.setIsModelOpen(val));
    },
    [dispatch]
  );
  const setLineParam = useCallback(
    (val: any) => {
      dispatch(fotActions.setLineParam(val));
    },
    [dispatch]
  );
  const setCreateJSFuncData = useCallback(
    (val: any) => {
      dispatch(editorActions.setCreateJSFuncData(val));
    },
    [dispatch]
  );
  const setCreateAPIFuncData = useCallback(
    (val: any) => {
      dispatch(editorActions.setCreateAPIFuncData(val));
    },
    [dispatch]
  );

  const enableAddNodetoPreview =
    projectPageState.isGroupNode === false &&
    forbidAddToPreviewType.includes(get(creatorRefStore.nodeContextMenuRef, "current.data.displayType")) === false;

  const clickAddNodeAgent = useCallback(
    (e: any) => {
      homeDataRefMethods.generateNewNode(e, "Agent");
    },
    // eslint-disable-next-line
    [homeDataRefMethods.generateNewNode]
  );

  const handleClickCreateCallerBot = (): void => {
    homePluginStore.setShowCallerBot(true);
  };

  const editCustomJsApiFunction = useCallback(async () => {
    const data = Object.assign(
      {},
      { ...rightMenuStore.state.customFuncRightContextMenu },
      {
        state: "modify",
        parameter: {
          name: rightMenuStore.state.customFuncRightContextMenu.select.label,
          description: rightMenuStore.state.customFuncRightContextMenu.select.description,
        },
      }
    );
    if (rightMenuStore.state.customFuncRightContextMenu.select?.type === "CustomCode") {
      setCreateJSFuncData(data);
      return;
    }
    if (rightMenuStore.state.customFuncRightContextMenu.select?.type === "customApi") {
      const hasPermission = await meStore.hasCustomApiV2();
      if (hasPermission) {
        navigate(`function/${data.select.id}`);
      }
      setCreateAPIFuncData(data);
    }
  }, [navigate, setCreateAPIFuncData, setCreateJSFuncData]);

  const judgeSingleFlowEdges: (firstSelectedEdgeId: any, edgeArr: any[], nodeArr: Node<any>[]) => boolean = useCallback(
    (firstSelectedEdgeId: any, edgeArr: any[], nodeArr: Node<any>[]) => {
      const firstSelectedEdge = creatorEdgesStore.getEdges().find(x => x.id === firstSelectedEdgeId);
      if (func.isEmpty(firstSelectedEdge)) return false;
      const relevanceNodeArr: any[] = [];
      const nodes = creatorNodesStore.getNodes();
      nodes.forEach(x => {
        if (x.id === firstSelectedEdge.source || x.id === firstSelectedEdge.target) {
          if (GROUPID_REG.test(x.id)) {
            const children = nodes.filter(y => y.data.parentNodeId === x.id);
            relevanceNodeArr.push(...[x, ...children]);
          } else {
            relevanceNodeArr.push(x);
          }
        }
      });

      const firstEdgeTargetNodeId = firstSelectedEdge.target;
      const targetNodeAsSourceNodeEdges = creatorEdgesStore.getEdges().filter(x => x.source === firstEdgeTargetNodeId);

      // 选中的边包含切分的edge提示并不继续执行
      const hasSlicedEdges = checkEdgeArr.some(edgeId =>
        creatorEdgesStore.getEdges().some(x => x.id === edgeId && x.data.isCreatedBySlice)
      );
      if (hasSlicedEdges) {
        func.customMsg({
          content: "Cannot include edges generated by sharding",
          type: "info",
        });
        return false;
      }
      //暂不支持有手动创建的边
      // const hasManualEdges = checkEdgeArr.some(edgeId => edges.some(
      //   x => x.id === edgeId && selectApiReg.test(x.data?.lineParam?.enterText) && imageGenSelectOptions.some(
      //     api => api.label === x.data?.lineParam?.enterText?.replace('/', '') && api.isCreatedManual
      //   )
      // ))
      // if (hasManualEdges) {
      //   message.info('Cannot support edges containing manually created api')
      //   return false
      // }
      if (!edgeArr.some((x: { id: any }) => x.id === firstSelectedEdge.id)) {
        edgeArr.push(firstSelectedEdge);
      }
      relevanceNodeArr.forEach(node => {
        if (!nodeArr.some((x: { id: string }) => x.id === node.id)) {
          nodeArr.push(node);
        }
      });
      // 被选中的边的数量
      const selectedEdges = targetNodeAsSourceNodeEdges.filter(x => checkEdgeArr.includes(x.id));
      if (func.isEmpty(selectedEdges)) {
        if (edgeArr.length > 0) return true;
        // message.info('Need to select the edges of one-way flow')
        return false;
      }
      // 如果有多个终点也不继续执行
      if (selectedEdges.length > 1) {
        func.customMsg({
          content: "The selected edge cannot have multiple endpoints",
          type: "info",
        });
        return false;
      }

      return judgeSingleFlowEdges(selectedEdges[0].id, edgeArr, nodeArr);
    },
    [checkEdgeArr]
  );

  const clickToCreateFunction = useCallback(() => {
    let edgeArr = [] as any;
    let nodeArr = [] as any;
    // 获取起始 edge 数据
    const sourceEdgeData = creatorEdgesStore
      .getEdges()
      .filter(x => checkEdgeArr.includes(x.id))
      .filter((el, index, self) => {
        return !self.some(e => e.target === el.source);
      });
    if (sourceEdgeData.length >= 2) {
      func.customMsg({
        content: "Need to select the edges of one-way flow",
        type: "info",
      });
      return;
    }
    const isSingleFlowEdges = judgeSingleFlowEdges(sourceEdgeData[0]?.id, edgeArr, nodeArr);
    if (!isSingleFlowEdges) {
      edgeArr = [];
      nodeArr = [];
      return;
    }
    creatorRefStore.singleFlowEdgesRef.current.edgeArr = edgeArr;
    creatorRefStore.singleFlowEdgesRef.current.nodeArr = nodeArr;
    functionPopupStore.setCreateFunctionTitle("Create a new function");
    functionPopupStore.setFuncName("");
    functionPopupStore.setFuncDescription("");
    functionPopupStore.setCreateNewFuncModal(true);
    // eslint-disable-next-line
  }, [checkEdgeArr, judgeSingleFlowEdges]);

  const duplicateEdge = useCallback(() => {
    // opt:
    const duplicateEdgeRef = creatorRefStore.duplicateEdgeRef;

    if (func.isEmpty(duplicateEdgeRef.current)) return;
    const prevTargetNode = creatorNodesStore.getNodeById(duplicateEdgeRef.current.target) as any;
    creatorRefStore.nodeIndexRef.current++;
    const newTargetNodeId = `editor-${creatorRefStore.nodeIndexRef.current}`;
    const newTargetNode = {
      id: newTargetNodeId,
      type: "customNode",
      data: {
        ...canvasDataRef.nodeDataRef.current,
      },
      position: {
        x: prevTargetNode.position.x,
        y: prevTargetNode.position.y - 210,
      },
    };

    creatorNodesStore.setNodes(prevList => [...prevList, newTargetNode]);
    // 新增连线
    creatorRefStore.edgeIndexRef.current++;
    const newEdge = {
      id: `edge-${creatorRefStore.edgeIndexRef.current}`,
      type: "customEdge",
      animated: false,
      source: duplicateEdgeRef.current.source,
      target: newTargetNodeId,
      markerEnd: transparentMarkerEnd,
      data: {
        ...duplicateEdgeRef.current.data,
      },
    };
    creatorEdgesStore.setEdges(prevList => [...prevList, newEdge]);

    creatorStoreMethods.onNodesEdgesAdd(
      [canvasDataRef.getSimpleNode(newTargetNode)],
      [canvasDataRef.getSimpleEdge(newEdge as any)]
    );
    // duplicateEdgeRef.current = null
    logEvent("duplicate_edge");
  }, [creatorStoreMethods]);

  const editPrompt = useCallback(() => {
    setIsModelOpen(true);
    setLineParam({
      id: creatorRefStore.duplicateEdgeRef?.current?.id,
      ...creatorRefStore.duplicateEdgeRef?.current?.data?.lineParam,
    });
  }, [setIsModelOpen, setLineParam]);

  const publishAsOutPut = useCallback(
    (e: any) => {
      if (e.target.getAttribute("aria-disabled") === "true") return;
      if (
        previewAppStore.handleSendToAppV2({
          cb: () => {
            creatorPreviewService.addIndividual(creatorRefStore.nodeContextMenuRef.current);
          },
        })
      )
        return;
      creatorPreviewService.addIndividual(creatorRefStore.nodeContextMenuRef.current);
    },
    [creatorPreviewService, previewAppStore]
  );

  return (
    <RightClickPopView
      showPopView={undefined}
      homeRef={creatorRefStore.homeRef}
      duplicateEdgeEnterText={creatorRefStore.duplicateEdgeRef?.current?.data?.lineParam?.enterText}
      enableAddNodetoPreview={enableAddNodetoPreview}
      handleClickUpload={homePublicMethods.handleClickUpload}
      clickAddNodeAgent={clickAddNodeAgent}
      handleClickCreateCallerBot={handleClickCreateCallerBot}
      copyNodeAndEdge={creatorStoreMethods.copyNodeAndEdge}
      editCustomJsApiFunction={editCustomJsApiFunction}
      clickToCreateVariable={homeRightMenuMethods.clickToCreateVariable}
      clickToCreateFunction={clickToCreateFunction}
      handleMenuDelete={homeRightMenuMethods.handleMenuDelete}
      duplicateEdge={duplicateEdge}
      editPrompt={editPrompt}
      publishAsOutPut={publishAsOutPut}
    />
  );
}
