"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthToken = exports.Permissions = exports.Profile = exports.Me = void 0;
const class_transformer_1 = require("class-transformer");
class Me {
    get fullname() {
        return `${this.first_name} ${this.last_name}`;
    }
}
exports.Me = Me;
__decorate([
    (0, class_transformer_1.Type)(() => Profile)
], Me.prototype, "profile", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => AuthToken)
], Me.prototype, "auth_token", void 0);
class Profile {
}
exports.Profile = Profile;
__decorate([
    (0, class_transformer_1.Type)(() => Permissions)
], Profile.prototype, "permissions", void 0);
class Permissions {
}
exports.Permissions = Permissions;
class AuthToken {
}
exports.AuthToken = AuthToken;
