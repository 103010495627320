"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SplitOutputAdapter = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const StoryNodeType_1 = require("../../storyV2/domain/StoryNodeType");
const LangUtil_1 = require("../../cutil/LangUtil");
const AdapterErrorUtil_1 = require("../../storyV2/adapter/AdapterErrorUtil");
const FunctionRegistry_1 = require("../../storyV2/function/FunctionRegistry");
class SplitOutputAdapter extends AdapterBase_1.AdapterBase {
    async run(story, edge, sourceNode, actionData) {
        if (sourceNode === undefined) {
            return Promise.reject();
        }
        let runNodes = [sourceNode];
        if (sourceNode.options.type === StoryNodeType_1.StoryNodeType.CUSTOMGROUP) {
            runNodes = sourceNode.children ?? [];
        }
        const res = await (0, LangUtil_1.tryPromise)(this.runAll(runNodes, { projectId: story.projectId, edgeId: edge.options.id }));
        if (res.error) {
            return new AdapterBase_1.RunResponse(AdapterBase_1.RunResponseType.ERROR, AdapterErrorUtil_1.AdapterErrorUtil.getErrorMsg(res));
        }
        if (!res.data) {
            return new AdapterBase_1.RunResponse(AdapterBase_1.RunResponseType.ERROR, "empty data");
        }
        return new AdapterBase_1.RunResponse(AdapterBase_1.RunResponseType.STRING_GROUP, res.data);
    }
    async runAll(nodes, identityInfo) {
        const requestArr = nodes.map(value => {
            return this.runSingle(value.content, identityInfo);
        });
        const res = await Promise.all(requestArr);
        return res.flat();
    }
    async runSingle(input, identityInfo) {
        let newIdentityInfo = identityInfo;
        if (newIdentityInfo) {
            newIdentityInfo = {
                ...newIdentityInfo,
                fromCustomFunction: true,
            };
        }
        const func = this.funcRegistry.getByRegistName(FunctionRegistry_1.FunctionName.splitOutput);
        const res = await func.run(input, newIdentityInfo);
        return res.data;
    }
    runResponseType() {
        return AdapterBase_1.RunResponseType.STRING_GROUP;
    }
}
exports.SplitOutputAdapter = SplitOutputAdapter;
