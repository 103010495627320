import { useAiSaasInputContainer } from "../container";
import isBlank from "@sedan-utils/is-blank";
import css from "./index.module.scss";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { getIt } from "@uikit/getIt";

export function InputDelete(props: AISaas.AISaasInputItemDeleteProps): JSX.Element | null {
  const { x, isEditPlaceholder } = props;
  const { id: itemId } = x.data;
  const projectRunStore = getIt(ProjectRunStore);
  const { isFocus, isDarkMode, onClickCloseContent } = useAiSaasInputContainer();

  const projectRunStoreState = useImagicaStore(projectRunStore).value;
  const showDeleteIcon =
    !projectRunStoreState.runAllLoading &&
    !isBlank(x?.data?.textAreaValue) &&
    !isFocus.value[itemId] &&
    !isEditPlaceholder;

  if (!showDeleteIcon) {
    return null;
  }

  return (
    <div
      className={`${css["close-bottom"]} ${isDarkMode ? css["dark-cross"] : ""}`}
      onClick={e => onClickCloseContent(e, itemId)}
    ></div>
  );
}
