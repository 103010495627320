"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XMLHttpRequestClient = void 0;
const RestClient_1 = require("../cutil/RestClient");
class XMLHttpRequestClient {
    constructor(adapter, abortManager = undefined) {
        this.adapter = adapter;
        this.abortManager = abortManager;
    }
    async request(params) {
        function fetchImpl(input, requestInit) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open(requestInit?.method, input.toString(), true);
                Object.entries(requestInit?.headers).forEach(([name, value]) => xhr.setRequestHeader(name, value));
                xhr.onload = () => {
                    resolve(new Response(xhr.response, {
                        status: xhr.status,
                        statusText: xhr.statusText,
                    }));
                };
                xhr.upload.onprogress = ({ loaded, total }) => {
                    params?.callbackMethods?.onprogress?.({ loaded, total });
                };
                xhr.onerror = () => {
                    if (xhr.status) {
                        reject(new Response(xhr.response, {
                            status: xhr.status,
                            statusText: xhr.statusText,
                        }));
                    }
                    else {
                        reject("Network error occurred");
                    }
                };
                xhr.send(requestInit?.body);
            });
        }
        const restClient = new RestClient_1.RestClient(this.adapter, this.abortManager, fetchImpl);
        return restClient.request(params);
    }
    async post(path, data, queryParameters, headers, contentType = undefined, uuid, callbackMethods) {
        return this.request({
            path,
            method: "POST",
            headers,
            data,
            queryParameters,
            contentType,
            uuid,
            callbackMethods,
        });
    }
}
exports.XMLHttpRequestClient = XMLHttpRequestClient;
