import { Signal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";

export class UpgradeSuccessState {
  title: Signal<string> = new Signal("");
  sub_itle: Signal<string> = new Signal("");
  feature: string[] = [];
  isLoading: Signal<boolean> = new Signal(true);
}

export class UpgradeSuccessBloc {
  store: SubscriptionStore = getIt(SubscriptionStore);

  state = new UpgradeSuccessState();

  constructor(public path: string) {
    this.loadData();
  }
  get nextPagePath(): string {
    return this.path.split("/").slice(0, -1).join("/");
  }

  async loadData(): Promise<void> {
    const myPlan = await this.store.currentPlan();
    const plans = await this.store.subscribeSuccessContent();
    const contentData = plans.find(item => {
      return item.plan === myPlan.value;
    });
    if (contentData) {
      this.state.isLoading.value = false;
      this.state.title.value = contentData.content.title;
      this.state.sub_itle.value = contentData.content.sub_itle;
      this.state.feature = contentData.content.feature;
    }
  }
}
