"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVBDataType = exports.ComponentId = void 0;
const VBDataYoutubeSearchResult_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataYoutubeSearchResult");
const VBDataYoutubeVideoModel_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataYoutubeVideoModel");
const VBDataShoppingProduct_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataShoppingProduct");
const VBDataShoppingSearchResult_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataShoppingSearchResult");
const VBDataWeatherWeekly_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataWeatherWeekly");
const VBDataWeatherCurrent_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataWeatherCurrent");
const VBDataNewsVideo_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataNewsVideo");
const VBDataC1_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataC1");
const VBDataC2_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataC2");
const VBDataC3_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataC3");
const VBDataC4_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataC4");
const VBDataMap_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataMap");
const VBDataText_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataText");
const VBDataItineraryPlanning_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataItineraryPlanning");
const VBDataNewsHeadline_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataNewsHeadline");
const VBDataDishRecommendation_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataDishRecommendation");
const VBDataTouristAttraction_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataTouristAttraction");
const VBDataSeasonalTravelDestinationPlan_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataSeasonalTravelDestinationPlan");
const VBDataRestaurantRecommendation_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataRestaurantRecommendation");
const VBDataTopicWithRelatedPeople_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataTopicWithRelatedPeople");
const VBDataTopicListResponse_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataTopicListResponse");
const VBDataNewsVideoList_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataNewsVideoList");
const VBDataNewsDigest_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataNewsDigest");
const VBDataImageNewsGrid_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataImageNewsGrid");
const VBDataNewsDigestDetails_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBDataNewsDigestDetails");
/**
 * 所有的组件id，后续会把uikit 的那一部分拿出来
 */
var ComponentId;
(function (ComponentId) {
    ComponentId["YoutubeVideo"] = "YoutubeVideo";
    ComponentId["YoutubeSearchResults"] = "YoutubeSearchResults";
    ComponentId["ShoppingProduct"] = "ShoppingProduct";
    ComponentId["ShoppingSearchResults"] = "ShoppingSearchResults";
    ComponentId["WeatherWeekly"] = "WeatherWeekly";
    ComponentId["WeatherCurrent"] = "WeatherCurrent";
    ComponentId["NewsVideo"] = "NewsVideo";
    ComponentId["C1"] = "Card with Image and Text Below";
    ComponentId["C2"] = "Card with Multiple Images in a Story Format";
    ComponentId["C3"] = "Card with Image and Text Side by Side (50-50)";
    ComponentId["C4"] = "Card with Image and Text Side by Side (20-80)";
    ComponentId["mapView"] = "map view";
    ComponentId["showTrip"] = "show trip on a map";
    ComponentId["Text"] = "text body";
    ComponentId["ItineraryPlanning"] = "ItineraryPlanning";
    ComponentId["NewsHeadline"] = "NewsHeadline";
    ComponentId["DishRecommendation"] = "DishRecommendation";
    ComponentId["TouristAttraction"] = "TouristAttraction";
    ComponentId["SeasonalTravelDestinationPlan"] = "SeasonalTravelDestinationPlan";
    ComponentId["RestaurantRecommendation"] = "RestaurantRecommendation";
    ComponentId["TopicWithRelatedPeople"] = "TopicWithRelatedPeople";
    ComponentId["TopicWithRelatedDetail"] = "TopicWithRelatedDetail";
    ComponentId["NewsVideoList"] = "NewsVideoList";
    ComponentId["NewsDigest"] = "NewsDigest";
    ComponentId["ImageNewsGrid"] = "ImageNewsGrid";
    ComponentId["NewsDigestDetails"] = "NewsDigestDetails";
})(ComponentId || (exports.ComponentId = ComponentId = {}));
const componentToModelMap = {
    [ComponentId.YoutubeSearchResults]: VBDataYoutubeSearchResult_1.VBDataYoutubeSearchResult,
    [ComponentId.YoutubeVideo]: VBDataYoutubeVideoModel_1.VBDataYoutubeVideoModel,
    [ComponentId.ShoppingProduct]: VBDataShoppingProduct_1.VBDataShoppingProduct,
    [ComponentId.ShoppingSearchResults]: VBDataShoppingSearchResult_1.VBDataShoppingSearchResult,
    [ComponentId.WeatherWeekly]: VBDataWeatherWeekly_1.VBDataWeatherWeekly,
    [ComponentId.WeatherCurrent]: VBDataWeatherCurrent_1.VBDataWeatherCurrent,
    [ComponentId.NewsVideo]: VBDataNewsVideo_1.VBDataNewsVideo,
    [ComponentId.C1]: VBDataC1_1.VBDataC1,
    [ComponentId.C2]: VBDataC2_1.VBDataC2,
    [ComponentId.C3]: VBDataC3_1.VBDataC3,
    [ComponentId.C4]: VBDataC4_1.VBDataC4,
    [ComponentId.mapView]: VBDataMap_1.VBDataMap,
    [ComponentId.showTrip]: VBDataMap_1.VBDataMap,
    [ComponentId.Text]: VBDataText_1.VBDataText,
    [ComponentId.ItineraryPlanning]: VBDataItineraryPlanning_1.VBDataItineraryPlanning,
    [ComponentId.NewsHeadline]: VBDataNewsHeadline_1.VBDataNewsHeadline,
    [ComponentId.DishRecommendation]: VBDataDishRecommendation_1.VBDataDishRecommendation,
    [ComponentId.TouristAttraction]: VBDataTouristAttraction_1.VBDataTouristAttraction,
    [ComponentId.SeasonalTravelDestinationPlan]: VBDataSeasonalTravelDestinationPlan_1.VBDataSeasonalTravelDestinationPlan,
    [ComponentId.RestaurantRecommendation]: VBDataRestaurantRecommendation_1.VBDataRestaurantRecommendation,
    [ComponentId.TopicWithRelatedPeople]: VBDataTopicWithRelatedPeople_1.VBDataTopicWithRelatedPeople,
    [ComponentId.TopicWithRelatedDetail]: VBDataTopicListResponse_1.VBDataTopicListResponse,
    [ComponentId.NewsVideoList]: VBDataNewsVideoList_1.VBDataNewsVideoList,
    [ComponentId.NewsDigest]: VBDataNewsDigest_1.VBDataNewsDigest,
    [ComponentId.ImageNewsGrid]: VBDataImageNewsGrid_1.VBDataImageNewsGrid,
    [ComponentId.NewsDigestDetails]: VBDataNewsDigestDetails_1.VBDataNewsDigestDetails,
};
/**
 * 获取VBDataModel
 * @param componentId
 */
function getVBDataType(componentId) {
    return componentToModelMap[componentId];
}
exports.getVBDataType = getVBDataType;
