import { useSignal } from "@preact/signals-react";

export class CustomizeBrandingBlocState {
  bottomPromptLoading = useSignal(false);
  bottomPrompt = useSignal(true);
  uploadPrompt = useSignal(false);
  uploadLoge = useSignal("");
  copyright = useSignal("");
}

export class CustomizeBrandingBloc {
  constructor(public state: CustomizeBrandingBlocState) {}
}
