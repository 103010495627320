import { useEffect } from "react";
import { useSelector, useStore, useDispatch } from "react-redux";
import { Button, Input, Form, Select, Tabs } from "antd";
import style from "./css/Request.module.css";
import HeaderList from "./HeaderList";
import BodyParam from "./BodyParam";
// import * as curlconverter from 'curlconverter'
import curlconverter from "parse-curl";
import func from "@uikit/func";
import { editorActions } from "../../../store/editor";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { EdgeRunHandlerRequest } from "@uikit/edgeRun/EdgeRunHandlerRequest";
import { EdgeRunVariable } from "@uikit/edgeRun/EdgeRunVariable";

const groupPatt = /^group/;
const methodOptions = [
  { value: "get", label: "GET" },
  { value: "post", label: "POST" },
];

export default function Request(props) {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const edgeRunHandlerRequest = getIt(EdgeRunHandlerRequest);
  const store = useStore();
  const dispatch = useDispatch();

  const createAPIFuncData = useSelector(state => state.editor.createAPIFuncData);
  const setCreateAPIFuncData = val => {
    dispatch(editorActions.setCreateAPIFuncData(val));
  };

  const onInputChange = e => {
    const text = e.target.value;
    // props.setUrlInputValue(text)
    props.setRequestData(prevObj => ({
      ...prevObj,
      url: text,
    }));

    // 是curl再解析
    const curlReg = /^curl\s+/i;
    if (!curlReg.test(text)) return;
    const parseTextData = curlconverter(text);
    // 设置header
    const headerList = Object.keys(parseTextData.header).map((x, index) => {
      return {
        key: `${index}_${x.toLowerCase()}`,
        name: x,
        value: parseTextData.header[x],
        description: "",
        selected: true,
      };
    });
    // 设置body, body should be string from now on
    const bodyParam = parseTextData.body;
    props.setRequestData({
      method: parseTextData.method.toLowerCase(),
      url: parseTextData.url,
      headerList,
      bodyParam,
    });
  };

  const getCurrentSourceNodeValue = () => {
    const { sourceNodeId } = store.getState().editor.createAPIFuncData;
    const nodes = creatorNodesStore.getNodes();
    let node = nodes.find(x => x.id === sourceNodeId) || {};
    // 如果源节点是group，则取第一个node内容
    if (groupPatt.test(sourceNodeId)) {
      node = nodes.find(x => x.parentNode === sourceNodeId);
    }
    let currentSourceNodeValue = node.data?.textAreaValue;
    // currentSourceNode value is not always string, stringify it if it's not
    if (typeof currentSourceNodeValue !== "string") {
      currentSourceNodeValue = JSON.stringify(currentSourceNodeValue) || "";
    }
    return currentSourceNodeValue;
  };

  async function clickSendBtn() {
    const currentSourceNodeValue = getCurrentSourceNodeValue();
    const respones = await edgeRunHandlerRequest.sendRequest(
      currentSourceNodeValue,
      props.requestData,
      props.setSendLoading
    );
    props.setResponseData(respones);
    setCreateAPIFuncData({ loading: false });
  }

  const items = [
    {
      key: "headersParam",
      label: "Headers",
      children: (
        <HeaderList
          headerList={props.requestData.headerList}
          setHeaderList={param =>
            props.setRequestData(prevObj => ({
              ...prevObj,
              headerList: typeof param === "function" ? param(prevObj.headerList) : param,
            }))
          }
        />
      ),
    },
    {
      key: "bodyParam",
      label: "Body",
      children: (
        <BodyParam
          requestData={props.requestData}
          setRequestData={props.setRequestData}
          getCurrentSourceNodeValue={getCurrentSourceNodeValue}
        />
      ),
    },
  ];

  useEffect(() => {
    if (func.isEmpty(createAPIFuncData.function)) return;
    const data = {
      clickRun: clickSendBtn,
    };
    if (typeof data[createAPIFuncData.function] != "function") return;
    data[createAPIFuncData.function]();
    setCreateAPIFuncData({ function: "" });
  }, [createAPIFuncData.function]);

  return (
    <div className={style["request-box"]}>
      {/* url输入栏 */}
      <Form layout="inline" size="large">
        <Form.Item
          style={{
            flex: 0.1,
          }}
        >
          <Select
            data-testid="apifunc-select"
            value={props.requestData.method}
            options={methodOptions}
            onSelect={value =>
              props.setRequestData(prevObj => ({
                ...prevObj,
                method: value,
              }))
            }
          />
        </Form.Item>
        <Form.Item
          style={{
            flex: 1,
          }}
        >
          <Input data-testid="apifunc-input" value={props.requestData.url} onChange={onInputChange} />
        </Form.Item>
        <Form.Item
          style={{
            flex: 0.1,
            marginRight: 0,
          }}
        >
          <Button
            data-testid="apifunc-send-button"
            loading={props.sendLoading}
            type="primary"
            block
            onClick={clickSendBtn}
          >
            Send
          </Button>
        </Form.Item>
      </Form>
      {/* 参数栏 */}
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}
