import { useSignal } from "@preact/signals-react";
import { Dropdown, Input, Menu } from "antd";
import style from "./ProjectNameButton.module.scss";
import { PROJECT_NAME_LENGTH } from "@views/thinking-layout-editor/constants";
import { useCallback, useEffect } from "react";
import { isString } from "lodash";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { useRef } from "react";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { NavBarStore } from "../NavBarStore";
import cls from "classnames";

import {
  CopyLink,
  DeleteProject,
  DuplicateProject,
  EditName,
  MenuGroupTitle,
  PublishApp,
  QRCodePNBItem,
  SaveProject,
  ShareProject,
  UnpublishApp,
} from "./ProjectNameBtnItems";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

type ProjectNameButtonProps = {
  saveProject: (type: string) => Promise<void>;
  saveProjectName: (name: string) => Promise<void>;
  shareProject: () => Promise<void>;
  duplicateProject: () => Promise<void>;
  deleteProject: () => Promise<void>;
  copylink: () => void;
  QRCode: () => void;
  unpublishApp: () => void;
  publishApp: () => Promise<void>;
  disable?: boolean;
};

const SaveKey = "Save project";
const ShareKey = "Share project";
const DuplicateKey = "Duplicate project";
const PublishAppKey = "Publish app";

const homeStore = getIt(HomeStore);
const navBarStore = getIt(NavBarStore);
const previewAppStore = getIt(PreviewAppStore);

export function ProjectNameButton(props: ProjectNameButtonProps): JSX.Element | null {
  const isEdit = useSignal(false);
  const dropOpen = useSignal(false);
  const projectButtonRef = useRef<HTMLDivElement>(null);
  const showNavPageStore = getIt(ShowNavPageStore);
  const selectedTemplate = previewAppStore.getSelectedTemplate();

  const homeStoreState = useImagicaStore(homeStore).value;
  const navBarState = useImagicaStore(navBarStore).value;

  const projectName = selectedTemplate?.template_name ?? "";
  const inputName = useSignal(projectName);
  const disableEdit = useSignal(false);
  const loadingKey = useSignal("");

  useEffect(() => {
    inputName.value = projectName;
  }, [projectName]);

  useEffect(() => {
    if (homeStoreState.homeNavSaveLoading) {
      if (loadingKey.value === SaveKey) return;
      loadingKey.value = SaveKey;
    } else {
      loadingKey.value = "";
    }
  }, [homeStoreState.homeNavSaveLoading]);

  useEffect(() => {
    if (navBarState.shareLoading) {
      if (loadingKey.value === ShareKey) return;
      loadingKey.value = ShareKey;
    } else {
      loadingKey.value = "";
    }
  }, [navBarState.shareLoading]);

  useEffect(() => {
    if (navBarState.publishLoading) {
      if (loadingKey.value === PublishAppKey) return;
      loadingKey.value = PublishAppKey;
    } else {
      loadingKey.value = "";
    }
  }, [navBarState.publishLoading]);

  function dropOpenChange(value: boolean): void {
    dropOpen.value = value;
  }

  function editName(): void {
    if (loadingKey.value !== "") {
      return;
    }
    isEdit.value = true;
    dropOpen.value = false;
  }

  async function saveProject(): Promise<void> {
    if (loadingKey.value !== "") {
      return;
    }
    loadingKey.value = SaveKey;
    await props.saveProject("save button");
    loadingKey.value = "";
    dropOpen.value = false;
  }

  async function shareProject(): Promise<void> {
    if (loadingKey.value !== "") {
      return;
    }
    loadingKey.value = ShareKey;
    navBarState.shareLoading = true;
    await props.shareProject();
    loadingKey.value = "";
    dropOpen.value = false;
    navBarState.shareLoading = false;
  }

  async function duplicateProject(): Promise<void> {
    if (loadingKey.value !== "") {
      return;
    }
    loadingKey.value = DuplicateKey;
    await props.duplicateProject();
    loadingKey.value = "";
    dropOpen.value = false;
  }

  async function deleteProject(): Promise<void> {
    dropOpen.value = false;
    await props.deleteProject();
  }

  async function onPublishApp(): Promise<void> {
    if (loadingKey.value !== "") {
      return;
    }
    dropOpen.value = false;
    await props.publishApp();
  }

  function copylink(): void {
    dropOpen.value = false;
    props.copylink();
  }

  function QRCode(): void {
    dropOpen.value = false;
    props.QRCode();
  }

  function unpublishApp(): void {
    dropOpen.value = false;
    props.unpublishApp();
  }

  function _onChange(event: any): void {
    const inputValue = checkValue(event.target.value);
    inputName.value = inputValue;
  }

  async function _onSave(): Promise<void> {
    dropOpen.value = false;
    // 如果修改名相同则跳过
    if (projectName.trim() === inputName.value.trim()) {
      isEdit.value = false;
      return;
    }
    disableEdit.value = true;
    await props.saveProjectName(inputName.value);
    disableEdit.value = false;
    isEdit.value = false;
    if (inputName.value.trim() === "") {
      inputName.value = selectedTemplate?.template_name ?? "";
    }
  }

  function doubleClick(): void {
    dropOpen.value = false;
    isEdit.value = true;
  }

  const checkValue = useCallback((value: string) => {
    return isString(value) ? value.slice(0, PROJECT_NAME_LENGTH.max) : "";
  }, []);

  const pubData = previewAppStore.getPublishMetadata();

  const itemSource = [
    {
      key: "title1",
      type: "group",
      label: <MenuGroupTitle title="Project" />,
    },
    {
      key: "Rename",
      onClick: editName,
      label: <EditName disable={loadingKey?.value.length > 0} loading={false} />,
      disabled: loadingKey?.value.length > 0,
    },
    {
      key: SaveKey,
      label: <SaveProject disable={loadingKey?.value.length > 0} loading={loadingKey.value === SaveKey} />,
      disabled: loadingKey?.value.length > 0,
      onClick: async (): Promise<void> => {
        await saveProject();
      },
    },
    {
      key: "line1",
      type: "divider",
      label: <Menu.Divider className={style.line} />,
      diable: true,
    },
    {
      key: ShareKey,
      label: <ShareProject disable={loadingKey?.value.length > 0} loading={loadingKey.value === ShareKey} />,
      disabled: loadingKey?.value.length > 0,
      onClick: async (): Promise<void> => {
        await shareProject();
      },
    },
    {
      key: DuplicateKey,
      label: <DuplicateProject disable={loadingKey?.value.length > 0} loading={loadingKey.value === DuplicateKey} />,
      disabled: loadingKey?.value.length > 0,
      onClick: async (): Promise<void> => {
        await duplicateProject();
      },
    },
    {
      key: "Delete project",
      label: <DeleteProject disable={loadingKey?.value.length > 0} loading={false} />,
      disabled: loadingKey?.value.length > 0,
      onClick: async (): Promise<void> => {
        await deleteProject();
      },
    },
    {
      key: "line2",
      type: "divider",
      label: <Menu.Divider className={style.line} />,
      diable: true,
    },
    {
      key: "title2",
      type: "group",
      label: <MenuGroupTitle title="Published app" />,
    },
    {
      key: PublishAppKey,
      label: <PublishApp disable={loadingKey?.value.length > 0} loading={navBarState.publishLoading} />,
      // disabled: false,
      disabled: loadingKey?.value.length > 0,
      onClick: onPublishApp,
    },
    {
      key: "Copy link",
      label: (
        <CopyLink
          loading={false}
          disable={pubData?.link === undefined || pubData?.link === "" || loadingKey?.value.length > 0}
        />
      ),
      disabled: pubData?.link === undefined || pubData?.link === "" || loadingKey?.value.length > 0,
      onClick: copylink,
    },
    {
      key: "QR Code",
      label: (
        <QRCodePNBItem
          loading={false}
          disable={pubData?.link === undefined || pubData?.link === "" || loadingKey?.value.length > 0}
        />
      ),
      disabled: pubData?.link === undefined || pubData?.link === "" || loadingKey?.value.length > 0,
      onClick: QRCode,
    },
    {
      key: "Unpublish app",
      label: (
        <UnpublishApp
          loading={false}
          disable={pubData?.link === undefined || pubData?.link === "" || loadingKey?.value.length > 0}
        />
      ),
      disabled: pubData?.link === undefined || pubData?.link === "" || loadingKey?.value.length > 0,
      onClick: unpublishApp,
    },
  ];

  const items = (
    <Menu className={style.menu_box} style={{ paddingTop: 10, paddingBottom: 10 }} items={itemSource}></Menu>
  );

  return showNavPageStore.state.showNavPage ? null : (
    <Dropdown
      overlay={items}
      placement="bottom"
      trigger={["hover"]}
      onOpenChange={dropOpenChange}
      open={isEdit.value ? false : dropOpen.value}
      getPopupContainer={e => e}
      overlayClassName={style.overlayWrap}
    >
      <div
        className={cls(style.content, !props.disable ? style.cursorPointer : style.cursorPointNoDrop)}
        ref={projectButtonRef}
        data-testid="ProjectNameButton"
      >
        {isEdit.value ? (
          <Input
            autoFocus
            disabled={disableEdit.value}
            value={inputName.value}
            className={style.input}
            onChange={_onChange}
            onPressEnter={async () => {
              await _onSave();
            }}
            onBlur={async () => {
              await _onSave();
            }}
            onKeyDown={e => {
              e.stopPropagation();
            }}
          />
        ) : (
          <div className={style.title} data-testid="ProjectNameButtonTrigger" onDoubleClick={doubleClick}>
            {inputName.value}
          </div>
        )}
      </div>
    </Dropdown>
  );
}
