import { motion } from "framer-motion";
import { chain, map, set } from "lodash";
import styles from "./BlueprintMessagePanel.module.scss";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

const variants = {
  loading: { opacity: 1 },
  done: { opacity: 0 },
};

type BlueprintMessagePanelProps = {
  blueprintMessages: string[] | undefined;
  loading: boolean;
  id: string;
};

export function BlueprintMessagePanel({
  blueprintMessages = [],
  loading,
  id,
}: BlueprintMessagePanelProps): JSX.Element {
  const creatorNodesStore = getIt(CreatorNodesStore);

  const removeBlueprintMessages = (): void => {
    const newNode = chain(creatorNodesStore.getNodes())
      .map(node => {
        if (node.id === id) {
          set(node, "data.blueprintMessages", []);
        }
        return node;
      })
      .value();
    creatorNodesStore.setNodes(newNode);
  };

  const handleMotionComplete = (): void => {
    if (loading === false) {
      removeBlueprintMessages();
    }
  };

  return (
    <motion.div
      initial={variants.done}
      variants={variants}
      animate={loading ? "loading" : "done"}
      transition={{ duration: 0.5 }}
      style={{ top: `${-40 - (blueprintMessages?.length - 1) * 16}px` }}
      className={styles.blueprintMessagePanel}
      onAnimationComplete={handleMotionComplete}
    >
      {map(blueprintMessages, (msg, index) => {
        return <div key={`${msg}${index}`}>{msg}</div>;
      })}
    </motion.div>
  );
}
