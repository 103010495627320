import styles from "./FontStyle.module.scss";
import { useSignal } from "@preact/signals-react";
import { useCallback, useEffect } from "react";
import { PreviewPaneBloc } from "../../PreviewPaneBloc";
import { FontType } from "imagica-corekit/dist/cases/theme/fontTheme/FontData";
import { PowerMaskView } from "@uiview/views/PowerMask/PowerMaskView";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { PowerMaskUitl } from "@uiview/views/PowerMask/PowerMaskUtil";
import { Popover } from "antd";
import { fontUtil } from "@uiview/views/ProjectCanvas/useDownloadFont";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { matchMobile } from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import classNames from "classnames";

export function FontStyle(props: { bloc: PreviewPaneBloc }): JSX.Element {
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const webConfigState = useImagicaStore(getIt(WebConfigStore)).value;

  const currentSelectFont = useSignal(FontType.AKREGULAR);
  const fontList = webConfigState.imagicaAppfonts;
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useImagicaStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;

  const clickFont = async (feature: string[], str: FontType): Promise<void> => {
    if (await PowerMaskUitl.disAbleByFeature(feature)) {
      return;
    }
    props.bloc.changeFont(str);
    currentSelectFont.value = str;
  };

  const handleFontFace = useCallback(() => {
    fontUtil.downLoadFont(fontList);
  }, [fontList]);

  useEffect(() => {
    handleFontFace();
  }, [fontList]);

  useEffect(() => {
    if (saasUIData?.settings?.fontFamily) {
      const preFontFamily = saasUIData.settings.fontFamily as unknown as FontType;
      currentSelectFont.value = preFontFamily === FontType.AKTIVGROTESK ? FontType.AKREGULAR : preFontFamily;
    } else {
      currentSelectFont.value = FontType.AKREGULAR;
    }
  }, [saasUIData?.settings?.fontFamily]);
  return (
    <div className={styles.FontStyle}>
      <div className={styles.title}>Font style</div>
      <div
        className={classNames(styles.fontBox, {
          [styles.FontBoxMobile]: isMobile && featureTags.disableMobilePage,
        })}
      >
        {fontList.map((item: any, index: number) => {
          return (
            <div className={styles.item_content} key={item.fontFamily + index}>
              <PowerMaskView feature={item.features} featureItem={`FontStyle-${item.fontFamily}`}>
                <div
                  className={`${isMobile && featureTags.disableMobilePage ? styles.mobile_item_div : styles.item_div} ${
                    currentSelectFont.value === item.fontFamily ? styles.activeFont : ""
                  }`}
                >
                  <Popover
                    content={item.fontFamily === "Ak-Regular" ? "AkRegular" : item.fontFamily}
                    placement="top"
                    trigger="hover"
                    overlayClassName={styles.popover}
                    getPopupContainer={e => e}
                  >
                    <div
                      key={index}
                      style={{ fontFamily: item.fontFamily }}
                      onClick={() => clickFont(item.features, item.fontFamily)}
                    >
                      Aa
                    </div>
                  </Popover>
                </div>
              </PowerMaskView>
            </div>
          );
        })}
      </div>
    </div>
  );
}
