import { useSignal } from "@preact/signals-react";
import func from "@uikit/func";
import { InputRef } from "antd";
import { FotClient } from "imagica-corekit/dist/base/api/FotClient";
import { History, QueryBuilderResponse, Question } from "imagica-corekit/dist/base/api/cotTyped/Cot";
import { isEmpty, last } from "lodash";
import { useRef } from "react";
import { getIt } from "@uikit/getIt";
import { WHAT_TO_DO } from "./constants";
import { CoTInputOption } from "./types";

function resetQuestion(list: History[]): Question[] {
  const results: Question[] = [];
  let current = "";
  list.forEach((item, index) => {
    if ((index + 1) % 2 === 0) {
      results.push({
        question: current,
        answer: item.content,
      });
    } else {
      current = item.content;
    }
  });

  if (list.length % 2 === 1) {
    results.push({
      question: current,
      answer: "",
    });
  }

  return results;
}

export type CotChatStatus = "waiting" | "loading" | "typing";

export class OnBoardingInputBloc {
  timer: NodeJS.Timeout | undefined;
  brainClient = getIt(FotClient);

  thinkValue = useSignal<string>("");
  history = useSignal<Array<History>>([]);
  questionList = useSignal<Array<Question>>([]);
  selectCursorRef = useRef<InputRef>(null);
  currentStep = useSignal(1);
  creating = useSignal(false);
  completeCreating = useSignal(false);
  openPopover = useSignal(false);
  inputSwitching = useSignal(false);
  question = useSignal(WHAT_TO_DO);
  waitingThinking = useSignal<boolean>(false);
  historyBox = useSignal<boolean>(false);
  inputStatus = useSignal<boolean>(false);
  operationList = useSignal<CoTInputOption[]>([]);
  defaultOperationList = useSignal<CoTInputOption[]>([]);
  cotChatStatus = useSignal<CotChatStatus>("waiting");
  focus = useSignal<boolean>(false);

  constructor(inputOptions: Array<CoTInputOption>) {
    this.defaultOperationList.value = inputOptions;
  }

  // Only used in animation
  operationClickInfo = useSignal<{ rect: DOMRect; content: string } | undefined>(undefined);

  thinkChange(value: string): void {
    this.thinkValue.value = value;
    this.changeCotChatStatus("typing");
    this.setInputStatus(!func.isEmpty(value));
  }

  changeCotChatStatus(cotChatStatus: CotChatStatus): void {
    clearTimeout(this.timer);
    this.cotChatStatus.value = cotChatStatus;
    this.timer = setTimeout(() => {
      this.cotChatStatus.value = "waiting";
    }, 1000);
  }

  selectCursor(): void {
    // 选中光标
    if (func.isEmpty(this.selectCursorRef.current)) return;
    this.selectCursorRef.current?.focus();
  }

  nextThink(history: Array<History>): void {
    this.thinkValue.value = "";
    this.currentStep.value = this.currentStep.value + 1;
    if (history) {
      this.history.value = history ?? [];
      this.questionList.value = resetQuestion(history ?? []);
      this.question.value = last(history)?.content ?? "";
    }
  }

  setCreating(bool: boolean): void {
    this.creating.value = bool;
  }

  onBlur(): void {
    setTimeout(() => {
      this.openPopover.value = false;
      if (isEmpty(this.thinkValue.value)) {
        this.inputSwitching.value = false;
      }
    }, 300);
  }

  setInputStatus(bool: boolean): void {
    if (bool) {
      // this.setInputSwitching(false);
      this.setOpenPopover(false);
    } else {
      this.setInputSwitching(true);
    }
    this.inputStatus.value = bool;
  }

  resetThink(): void {
    this.thinkValue.value = "";
    this.currentStep.value = 1;
    this.question.value = WHAT_TO_DO;
    this.operationList.value = this.defaultOperationList.value;
    this.creating.value = false;
    this.completeCreating.value = false;
    this.operationClickInfo.value = undefined;
    this.thinkChange("");
  }

  cotReStart(thinkValue: string): void {
    this.thinkValue.value = thinkValue;
  }

  setHistoryBox(bool: boolean): void {
    this.historyBox.value = bool;
  }

  selectedRecords(data: Question, step: number): void {
    this.setHistoryBox(false);
    this.thinkChange(data.answer);
    this.currentStep.value = step;
    this.question.value = data.question;
  }

  clickEditBox(): void {
    if (func.isEmpty(this.thinkValue.value)) {
      this.setOpenPopover(true);
      this.setInputSwitching(true);
    }
  }

  setOpenPopover(key: boolean): void {
    this.openPopover.value = key;
  }

  setInputSwitching(key: boolean): void {
    this.inputSwitching.value = key;
  }

  pasteText(): void {
    this.setOpenPopover(false);
    this.setInputSwitching(false);
    this.selectCursor();
  }

  async queryBuilder(): Promise<QueryBuilderResponse> {
    const result = await this.brainClient.cot.queryBuilder(this.thinkValue.value);
    return result;
  }
}
