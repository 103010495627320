import React from "react";
import { find } from "lodash";
import { useMemo } from "react";
import { DisplayType } from "../typing";
import { nodeConfig } from "../nodeConfig";
import { Text } from "imagica-uikit/dist/nodesV2/Text/TextNode";
import { BlankV2 } from "imagica-uikit/dist/nodesV2/Blank/BlankV2";
import { transformNodeValue } from "../BluePrintNode/BluePrintUtil";
import style from "./List.module.scss";

type ListProps = {
  textAreaValue: string[];
  type?: DisplayType;
};

export function List({ textAreaValue = [], type = "text", ...rest }: ListProps): JSX.Element {
  const { component } = useMemo(() => {
    const config = find(nodeConfig, item => item.displayType === type) || {
      component: Text,
      disableFeatureTag: "",
      displayType: "text",
    };

    return config;
  }, [type]);

  if (!Array.isArray(textAreaValue)) {
    return <BlankV2 />;
  }

  return (
    <div className={style.listNodeBox}>
      <ul>
        {textAreaValue.map(x => (
          <li className={style.listNodeItem} key={x}>
            {React.createElement(component, {
              ...rest,
              textAreaValue: transformNodeValue(type, false, x),
              type,
              isInBlueprint: true,
            })}
          </li>
        ))}
      </ul>
    </div>
  );
}
