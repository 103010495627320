import { getIt } from "@uikit/getIt";
import cls from "classnames";
import { motion } from "framer-motion";
import { useDidMount } from "imagica-uikit/dist/hooks/useDidMount";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { ChatPanel } from "./ChatPanel";
import { MultiRoundChatOnboardingBloc } from "./MultiRoundChatOnboardingBloc";
import styles from "./OnboardingInput.module.scss";
import { transition } from "./motion/motionConfig";
import { useCurrentStatus } from "./useCurrentStatus";
import { useSignal } from "@preact/signals-react";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { SwitcherModeType, NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { DisableChatUtil } from "../../DisableChatUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CotStore } from "@uikit/store/CotStore";
import { AddNode } from "@uikit/service/AddNode";

const variants = {
  normal: { top: "30%" },
  creating: { top: "40%" },
  completeCreating: {
    top: "80%",
    right: "180px",
    transition: {
      delay: 0.2,
    },
  },
};

export const OnboardingInput = ({
  bloc,
  setIsCallerBotAI,
}: {
  setIsCallerBotAI: (bool: boolean) => void;
  bloc: MultiRoundChatOnboardingBloc;
}): JSX.Element => {
  // const chatBloc = useCreation(() => new ChatBloc(PromptInfos.classifier), []);

  const creatorNodesStore = getIt(CreatorNodesStore);
  const addNode = getIt(AddNode);
  const { currentStatus } = useCurrentStatus(bloc);

  const [startMotionComplete, setStartMotionComplete] = useState(false);
  const pannelWidth = useSignal(0);
  const isColumnDirectionSuggestion = useSignal(false);
  const isSmallScreen = useSignal(false);

  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const chatOnboardingState = useStore(chatOnboardingStore).value;

  const navBarStore = getIt(NavBarStore);
  const cotStore = getIt(CotStore);

  const webConfigState = useStore(getIt(WebConfigStore)).value;
  let suggestions = webConfigState?.cot_v2?.examples;
  if (suggestions && suggestions.length > 2) {
    suggestions = suggestions.slice(0, 2);
  }
  // const handleThinkEnter = async () => {
  //   bloc.cotChatStatus.value = "loading";
  //   await chatBloc.chatOnce(bloc.thinkValue.value);
  //   const message = last(chatBloc.state.message.value)?.content || "";
  //   const result = tryBlock<{ caller_bot_ai: boolean }>(() => JSON.parse(message));
  //   if (result.data) {
  //     setIsCallerBotAI(result.data.caller_bot_ai);
  //   }
  // };

  const handleThinkEnter = async (): Promise<void> => {
    setIsCallerBotAI(false);
  };

  const handleStartMotionComplete = (): void => {
    setStartMotionComplete(true);
  };

  useDidMount(() => {
    bloc.selectCursor();
  });

  const handleClose = async (e: any): Promise<void> => {
    if (isEmpty(creatorNodesStore.getNodes())) {
      await addNode.addNodeV2(e, { isEnableTooltipNode: true });
    }
    chatOnboardingStore.setIsOpenChat(false);
    navBarStore.setSwtichMode(SwitcherModeType.canvas);
    // 关闭时重置changeCanvas状态
    if (DisableChatUtil.isDisabled() === false) {
      chatOnboardingStore.setChatOnboardingState({ canvasChangesOutOfSync: false });
    }
    cotStore.setMultiRoundChatOnboarding(false);
  };

  useEffect(() => {
    if (!chatOnboardingState.showOnBoardingInput) {
      return;
    }
    const windowWidth = window.innerWidth;
    isSmallScreen.value = windowWidth < 520; // 480 + 左右margin 20
    const pannelHMargin = isSmallScreen.value ? 20 : 30;
    const width = windowWidth - pannelHMargin * 2;
    pannelWidth.value = Math.min(width, 1000);
    isColumnDirectionSuggestion.value = windowWidth < 1000;
  }, [window.innerWidth]);

  if (chatOnboardingState.showOnBoardingInput === false) {
    return <></>;
  }

  const handleClickSuggestion = (suggestion: string): void => {
    bloc.thinkChange(suggestion);
    handleThinkEnter();
  };

  const fotBoxClassName = cls(styles.fotInputBox, startMotionComplete === false && styles.startAnimation);
  const suggestionClassNames = cls(
    styles.suggestion,
    { [styles.columnDirection]: isColumnDirectionSuggestion.value },
    { [styles.smallScreen]: isSmallScreen.value }
  );

  return (
    <>
      <div className={styles.retryMask} data-creator="OnboardingInputMask" data-testid="OnboardingInputMask" />
      <motion.div
        initial={variants.normal}
        variants={variants}
        animate={currentStatus}
        transition={transition}
        className={styles["fotInput-wrapper"]}
        data-creator="OnboardingInput"
        data-testid="OnboardingInput"
      >
        <motion.div
          animate={{ width: ["10px", "10px", pannelWidth.value + "px"], marginTop: ["50px", "0px", "0px"] }}
          transition={{ duration: 1, times: [0, 0.25, 1] }}
          className={fotBoxClassName}
          onAnimationComplete={handleStartMotionComplete}
        >
          <div>
            <ChatPanel
              bloc={bloc}
              onClose={handleClose}
              startMotionComplete={startMotionComplete}
              setIsCallerBotAI={setIsCallerBotAI}
            />
            {!isEmpty(suggestions) && (
              <div className={suggestionClassNames}>
                {suggestions?.map((item: any) => (
                  <Suggestion text={item.label} send={() => handleClickSuggestion(item.label)} key={item.label} />
                ))}
              </div>
            )}
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

function Suggestion(props: { text: string; send: () => void }): JSX.Element {
  return (
    <div className={styles.suggestionItem} onClick={() => props.send()}>
      <div className={styles.suggestionItemText}>{props.text}</div>
    </div>
  );
}
