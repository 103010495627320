"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeUtil = exports.TemplateType = void 0;
// preview中当前模板
var TemplateType;
(function (TemplateType) {
    TemplateType["AIAPP"] = "AIAPP";
    TemplateType["CHATAIAPP"] = "CHATAIAPP";
})(TemplateType || (exports.TemplateType = TemplateType = {}));
class ThemeUtil {
    // 给需要配置主题的根元素设置style属性变量
    static setThemeProperty(params) {
        let themeVar;
        switch (params.template) {
            case TemplateType.AIAPP: {
                const theme = params.themeData;
                themeVar = { ...theme.saasAiAppTheme, ...theme.nodeTheme, ...theme.appSignUpButtonTheme, ...theme.patchTheme };
                break;
            }
            case TemplateType.CHATAIAPP: {
                const chatTheme = params.chatThemeData;
                themeVar = { ...chatTheme.chatAiTheme, ...chatTheme.appSignUpButtonTheme, ...chatTheme.patchTheme };
                break;
            }
        }
        params.ref.current?.removeAttribute("style");
        Object.keys(themeVar).forEach(key => {
            params.ref.current?.style.setProperty(`--${key}`, themeVar[key]);
        });
    }
}
exports.ThemeUtil = ThemeUtil;
