import HomeToolBar from "@views/thinking-layout-editor/HomeToolBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { CotInputView } from "@views/thinking-layout-editor/CotInput/CotInputView";
import { editorActions } from "@store/editor";
import useCompositionKeypressMethods from "../useCompositionKeypressMethods";
import { useHomePublicMethods } from "../useHomeUtilMethods";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { CotStore } from "@uikit/store/CotStore";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { AddNode } from "@uikit/service/AddNode";
import { CotInputBloc } from "@views/thinking-layout-editor/CotInput/CotInputBloc";

const homePluginStore = getIt(HomePluginStore);

export function CreatorToolBar(): JSX.Element | null {
  const homePluginState = useImagicaStore(homePluginStore).value;
  const cotStore = getIt(CotStore);
  const showNavPageStore = getIt(ShowNavPageStore);
  const addNode = getIt(AddNode);
  const homePublicMethods = useHomePublicMethods();
  const myModificationDataState = useImagicaStore(myModificationDataStroe).value;
  const showNavPageState = useImagicaStore(showNavPageStore).value;

  const { handleUndo, handleRedo } = useCompositionKeypressMethods();

  const cotInputBloc = new CotInputBloc();

  //   ======= redux
  const dispatch = useDispatch();
  const selectedTemplate = useSelector(
    (state: RootState) => state.studio.selectedTemplate as unknown as PreviewApp.ProjectType
  );
  const setCurrentGuideTitlePage = (val: boolean): void => {
    dispatch(editorActions.setCurrentGuideTitlePage(val));
  };

  // ====  conts
  const undoRef = homePluginStore.undoData;

  const enableCOT =
    selectedTemplate?.name &&
    !myModificationDataState.showMyModification &&
    !showNavPageState.showNavPage &&
    homePluginState.isShare !== true;

  // ======= functions
  const addNewNode = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    // cot状态下不允许添加node
    if (cotStore.state.showCot || cotStore.state.oldCotAnimationState.creating === true) return;
    addNode.addNodeV2(e);
  };

  // ==== render

  if (showNavPageState.showNavPage) {
    return null;
  }

  return (
    <HomeToolBar
      addNewNode={addNewNode}
      addUploadNode={homePublicMethods.handleClickUpload}
      CotComponents={
        enableCOT === true && getIt(ChatOnboardingStore).state.selectTemplateInModal === ChoosingFormat.AUTOIMAGICA ? (
          <CotInputView bloc={cotInputBloc} clickClosePreview={homePublicMethods.clickClosePreview} />
        ) : (
          <></>
        )
      }
      undoRef={undoRef}
      handleUndo={handleUndo}
      handleRedo={handleRedo}
      setCurrentGuideTitlePage={setCurrentGuideTitlePage}
    />
  );
}
