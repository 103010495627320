import { useCreation } from "ahooks";
import { HeaderBar } from "./HeaderBar/HeaderBar";
import style from "./MobileProjectsStyle.module.scss";
import { ProjectListViewBloc } from "../ProjectListViewBloc";
import InfiniteScroll from "react-infinite-scroll-component";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { ProjectTemplateStore } from "imagica-corekit/dist/cases/store/ProjectTemplateStore";
import { TableItemCell } from "./TableCell/TableItemCell";
import { GridItemCell } from "./GridCell/GridItemCell";
import { RelationSetDest } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { useCallback, useEffect, useRef } from "react";
import { ChatOnboardingState, ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { NavBarStore, SwitcherModeType } from "@uiview/views/navBar/NavBarStore";
import { ProjectDropdownItemArgs, ProjectDropdownItems } from "../type";
import func from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@store/index";
import { editorActions } from "@store/editor";
import { FotSaveEvent } from "@uikit/service/FotSaveEvent";
import { useNavigate } from "react-router-dom";
import { fotActions } from "@store/fot";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { MobileProjectBloc } from "./MobileProjectBloc";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { OpenProjectLoading } from "./Loading/OpenProjectLoading";
import { ApiStatuses } from "@views/thinking-layout-editor/types";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { AutoImagicAlert } from "./AutoImagicAlert/AutoImagicAlert";
import { ProjectLoader } from "@uikit/fotProcess/ProjectLoader";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { useAModalProvider } from "@uikit/context/AModalContext/useAModalProvider";
import { CotStore } from "@uikit/store/CotStore";

export function MobileProjectsView(): JSX.Element {
  const mobileBloc = useCreation(() => new MobileProjectBloc(), []);
  const projectLoader = getIt(ProjectLoader);
  const showNavPageStore = getIt(ShowNavPageStore);
  const bloc = useCreation(() => new ProjectListViewBloc(), []);
  const projectState = useImagincStore(getIt(ProjectTemplateStore)).value;
  const navBarStore = getIt(NavBarStore);
  const cotStore = getIt(CotStore);
  const navigate = useNavigate();

  const homeMethods = getIt(HomeMethodsService);

  const dispatch = useDispatch<AppDispatch>();

  const previewAppStore = getIt(PreviewAppStore);
  const { showModal } = useAModalProvider();

  const fotSaveService = getIt(FotSaveEvent);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bloc.initData();
  }, []);

  const setEditorIdArr = useCallback(
    (val: any) => {
      dispatch(fotActions.setEditorIdArr(val));
    },
    [dispatch]
  );
  const setCheckEdgeArr = useCallback(
    (val: any) => {
      dispatch(fotActions.setCheckEdgeArr(val));
    },
    [dispatch]
  );
  const setCheckNodeArr = useCallback(
    (val: any) => {
      dispatch(fotActions.setCheckNodeArr(val));
    },
    [dispatch]
  );

  const isProjectLoading = useCallback(
    (project: RelationSetDest) => !!bloc.loadingOfProject.value && bloc.loadingOfProject.value.id === project.id,
    [bloc.loadingOfProject.value]
  );

  async function checkNext(doubleCheck: boolean): Promise<void> {
    const clientHeight = document.getElementById("scollWrap")?.clientHeight ?? 0;
    const windowH = window.innerHeight;
    const minheight = windowH - 120;
    if (clientHeight < minheight) {
      await bloc.nextPage();
      if (doubleCheck) {
        checkNext(false);
      }
    }
  }

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        checkNext(true);
      }, 1000);
    }
  }, [ref.current]);

  async function handleSelectedProject(value: any): Promise<void> {
    try {
      await openProject(value);
    } catch (error) {
      console.log("error", error);
    }
    showNavPageStore.setShowNavPage(false);
  }

  async function handleProjectClick(project: RelationSetDest): Promise<void> {
    const selectTemplateInModal = project?.attributesV2?.multiRoundChatData?.selectTemplateInModal;
    if (selectTemplateInModal !== ChoosingFormat.AUTOIMAGICA) {
      mobileBloc.showAutoImagicAlert.value = true;
      return;
    }
    if (bloc.loadingOfProject.value) {
      return;
    }
    // 进入project时，赋值一次chatOnboardingStore
    const chatOnboardingStore = getIt(ChatOnboardingStore);
    chatOnboardingStore.setState(project?.attributesV2?.multiRoundChatData || new ChatOnboardingState());
    if (project?.attributesV2?.multiRoundChatData?.isOpenChat) {
      navBarStore.setSwtichMode(SwitcherModeType.imagica);
    }
    bloc.loadingOfProject.value = project;
    await handleSelectedProject(project);
    bloc.loadingOfProject.value = undefined;
  }

  async function handleProjectDropdownClick(args: ProjectDropdownItemArgs): Promise<void> {
    if (args.type === ProjectDropdownItems.Duplicate) {
      const destinationProject = await mobileBloc.handleClickDuplicate("project", args.project);
      logEvent("duplicate_Function", {
        source_id: args.project.attrIndexer ?? args.project.uuid,
        destination_id: destinationProject.uuid,
      });
      func.messageUtil("Successfully duplicated project", "success");
      /// TODO
      bloc.duplicateProject(destinationProject);
    }
    if (args.type === ProjectDropdownItems.Delete) {
      const project: any = args.project;
      const isOk = await mobileBloc.handleClickDelete(
        "project",
        project.relationId,
        project.attributesV2?.name || "Untitled",
        project,
        showModal
      );
      logEvent("delete_project_confirmed", args.project);
      if (isOk) {
        func.messageUtil("The project has been deleted successfully", "success");
        bloc.deleteProject(args.project);
      }
    }
  }

  const setEnableTooltipWhatTodo = useCallback(
    (val: any) => {
      dispatch(editorActions.setEnableTooltipWhatTodo(val));
    },
    [dispatch]
  );

  function disableClick(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  const adpotSelectedExistingProject = useCallback((defaultTemplateName: any) => {
    const project = {
      ...mobileBloc.selectedExistingProject,
      defaultTemplateName,
    } as PreviewApp.ProjectType;
    if (typeof projectLoader.loadFromExistingTemplateV2 === "function") {
      try {
        fotSaveService.setSelectedTemplate(project);
        projectLoader.loadFromExistingTemplateV2(project);
      } catch (error: any) {
        func.customMsg({
          type: "error",
          content: error.message,
        });
        return;
      }
    }
    fotSaveService.setSelectedTemplate(project);
  }, []);

  /**
   * 打开项目
   * @param {import('./types').FormatTemplateType} value
   */
  const openProject = useCallback(
    async (value: any) => {
      /// loading 中禁用全页面点击
      document.addEventListener("click", disableClick, true);
      // 清空上一次的 previewApp 数据
      previewAppStore.clearPreviewApp();
      // 清空上个项目显示数据
      homeMethods.emptyNodes();
      setEnableTooltipWhatTodo(false);
      // 进入已有项目显示 cot 图标
      cotStore.setShowCot(false);

      const param = mobileBloc.getAppropriateParam(value);
      await mobileBloc.getTemplateByName(param, value.relationId);
      adpotSelectedExistingProject(value.defaultTemplateName);
      navigate(`/editor/${value.attrIndexer || value.uuid || value.id}`);
      fotSaveService.setStudioProjectName(value.label);
      // 重置股票购买数据
      setEditorIdArr([]);
      setCheckEdgeArr([]);
      setCheckNodeArr([]);

      logEvent("open_project");
      /// 移除禁用
      setTimeout(() => {
        document.removeEventListener("click", disableClick, true);
      }, 1000);
    },
    [
      adpotSelectedExistingProject,
      navigate,
      setCheckEdgeArr,
      setCheckNodeArr,
      setEditorIdArr,
      setEnableTooltipWhatTodo,
      disableClick,
    ]
  );

  return (
    <>
      <div className={style.mobileProjectsView} ref={ref}>
        <HeaderBar
          onSelectType={value => {
            bloc.changeListView(value === "list");
          }}
          onSortBy={sort => {
            bloc.sortByRefresh(sort.value);
          }}
        />
        <div id="scollWrap" className={style.scollWrap}>
          <InfiniteScroll
            className={bloc.showlist.value ? style.viewList : style.viewGrid}
            style={{ overflow: undefined }}
            dataLength={projectState.projects.length}
            loader={null}
            hasMore={true}
            next={() => {
              bloc.nextPage();
            }}
            scrollableTarget="scollWrap"
          >
            {bloc.showlist.value ? (
              <>
                {projectState.projects.map((item: RelationSetDest, index: number) => (
                  <TableItemCell
                    key={item.id + "" + index}
                    project={item}
                    loading={isProjectLoading(item)}
                    onClick={handleProjectClick}
                    onNameChange={() => {}}
                    onDropdownClick={handleProjectDropdownClick}
                  />
                ))}
              </>
            ) : (
              <>
                {projectState.projects &&
                  projectState.projects.map((item: RelationSetDest, index: number) => (
                    <GridItemCell
                      key={item.id + "" + index}
                      project={item}
                      loading={isProjectLoading(item)}
                      onClick={handleProjectClick}
                      onNameChange={() => {}}
                      onDropdownClick={handleProjectDropdownClick}
                    />
                  ))}
              </>
            )}
            <div className={style.loader_more}>
              {projectState.isGetAllProject ? (
                <span>No more data</span>
              ) : (
                <Spin style={{ fontSize: 30 }} indicator={<LoadingOutlined spin />} />
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      {(bloc.loadingOfProject.value || bloc.apiStatusOfProjects.value === ApiStatuses.Pending) && (
        <OpenProjectLoading />
      )}
      {mobileBloc.showAutoImagicAlert.value && (
        <AutoImagicAlert
          onClose={() => {
            mobileBloc.showAutoImagicAlert.value = false;
          }}
          onConfirm={async () => {
            mobileBloc.showAutoImagicAlert.value = false;
            // await bloc.filterAutoImagic();
            /// 同步到账号中，未实现
          }}
        />
      )}
    </>
  );
}
