import { DisplayType } from "imagica-uikit/dist/nodesV2/typing";
import { BaseDataRef } from "./BaseDataRef";

export class NodeDataRefState {
  // static
  isResized = false;
  loadingNodeHtml = false;
  showWarning = false;
  showStopGenerateBtn = false;
  showFeedback = false;
  fromList = false;
  showGroupCollapseData = false;
  textAreaValue = "";
  inputTitle = undefined;
  generatedLabel = undefined;
  displayType: DisplayType = "text";
  originId = "";
  parentNode = "";
  parentNodeId = "";
  errorText = "";
  childNode = [];
  chatBoxParam = [];
  dropUploadFileData = {};
  abortNode = {};
  viewBuilderData = {
    loadingHtml: false,
    domList: [],
  };
  showBuilderUI = false;
  // dync
  // 考虑到fot或其他以外地方可能还在使用该数据，暂时没删，但是fot应不在使用
  /**
   *@deprecated use `creatorRefStore.nodeIndexRef`
   */
  nodeIndexRef: React.MutableRefObject<number> = { current: 0 };
  /**
   *@deprecated use `creatorRefStore.nodeIndexRef`
   */
  edgeIndexRef: React.MutableRefObject<number> = { current: 0 };

  /**
   * 该属性比较特殊，原来 share 是声明在 homeUsePlugin 中的，使用的 state 的值
   *
   * 但是 useRef 内部是只会将值声明一次，也就是说无论后面组件如何刷新，其内部值都不会被改变
   * @deprecated `HomePluginStoreState.share`
   */
  share = false;

  /**
   * 该属性来自
   * @see https://brain-ai.atlassian.net/browse/BSF-8036
   * @see https://github.com/brain/micro-apps/pull/4533
   */
  userRelatedData = {};
}

/**
 * 原来 HomeUsePlugin 中 NodeDataRef 的数据结构
 */
export class NodeDataRef extends BaseDataRef<NodeDataRefState> {
  constructor() {
    super(new NodeDataRefState());
  }
}
