"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VBDataText = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
const ChainRequest_1 = require("../../../storyV2/response/customApiHandler/chain/ChainRequest");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataText extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.item = new DomObject();
        this.isInCanvas = false;
        this.setNodes = () => { };
    }
}
exports.VBDataText = VBDataText;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => DomObject)
], VBDataText.prototype, "item", void 0);
class DomObject {
    constructor() {
        this.type = "";
        this.imgKey = "";
        this.audio = new ViewBuilderAudio();
        this.mapData = new MapData();
        this.head = "";
        this.paragraph = "";
        this.lis = [];
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ViewBuilderAudio)
], DomObject.prototype, "audio", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => MapData)
], DomObject.prototype, "mapData", void 0);
class ViewBuilderAudio {
    constructor() {
        this.initCreateBlobUrl = new ChainRequest_1.ChainResultInitCreateBlobUrl();
        this.textAreaValue = new ChainRequest_1.ChainResultTextAreaValue();
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ChainRequest_1.ChainResultInitCreateBlobUrl)
], ViewBuilderAudio.prototype, "initCreateBlobUrl", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => ChainRequest_1.ChainResultTextAreaValue)
], ViewBuilderAudio.prototype, "textAreaValue", void 0);
class MapData {
    constructor() {
        this.mapKey = "";
        this.startLocation = new LocationData();
        this.endLocation = new LocationData();
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => LocationData)
], MapData.prototype, "startLocation", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => LocationData)
], MapData.prototype, "endLocation", void 0);
class LocationData {
    constructor() {
        this.address = "";
        this.latlng = [];
    }
}
