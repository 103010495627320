import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { PreviewStore } from "../../uiview/store/PreviewStore";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { CreatorSaasAppStore } from "./CreatorSaasAppStore";
import { PreviewSerialize } from "@uikit/projectModified/PreviewSerialize";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";
import { cloneDeep, find } from "lodash";
import { PreviewAppValueMergeUtil } from "@uiview/views/PreviewApp/PreviewAppValueMergeUtil";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { PreviewAppValueAssignUtil } from "@uiview/views/PreviewApp/PreviewAppValueAssignUtil";
import { CreatorRefStore } from "./CreatorRefStore";
import { PreviewAppValue } from "@uiview/views/PreviewApp/model/PreviewAppValue";
import { PreviewPaneStore } from "@uiview/views/PreviewPane/PreviewPaneStore";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { ViewBuilderGraph } from "imagica-corekit/dist/base/api/viewBuilderTyped/viewBuilderGraph";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";
import { CreatorAISaasStore } from "./CreatorAISaasStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { PreviewAppUtil } from "@uiview/views/PreviewApp/PreviewAppUtil";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import isBlank from "@sedan-utils/is-blank";
import func from "@uikit/func";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { DomainCheckStatus } from "@uiview/views/PreviewPane/Settings/DomainName/DomainNameBloc";
import { PreviewAppValueGenUtil } from "@uiview/views/PreviewApp/PreviewAppValueGenUtil";
import { PreviewAppTypeMap } from "@uiview/views/PreviewApp/const";

export enum PublishState {
  idle = "idle",
  publish = "publish",
  unpublish = "unpublish",
}
export class PreviewAppStoreState {
  historyShowStatus: null | boolean = true;
  publishShowStatus: null | boolean = null;
  previewShowStatus: null | boolean = null;
  settingShowStatus: null | boolean = null;
  publishState: string = PublishState.idle;
  copyWith(param: {
    historyShowStatus?: null | boolean;
    previewShowStatus?: null | boolean;
    settingShowStatus?: null | boolean;
    publishShowStatus?: null | boolean;
    publishState?: string;
  }): PreviewAppStoreState {
    const newState = new PreviewAppStoreState();
    newState.historyShowStatus = param.historyShowStatus ?? this.historyShowStatus;
    newState.previewShowStatus = param.previewShowStatus ?? this.previewShowStatus;
    newState.settingShowStatus = param.settingShowStatus ?? this.settingShowStatus;
    newState.publishShowStatus = param.publishShowStatus ?? this.publishShowStatus;
    newState.publishState = param.publishState ?? this.publishState;

    return newState;
  }
}

/**
 * 用来替换原来 usePreviewAppStore
 *
 * 由于方法较多，后期部分方法需分离出去
 */
export class PreviewAppStore extends StoreBase<PreviewAppStoreState> {
  constructor(
    public fotReduxStore: FotReduxStore,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public previewStore: PreviewStore,
    public chatOnboardingStore: ChatOnboardingStore,
    public creatorRefStore: CreatorRefStore,
    public creatorNodesStore: CreatorNodesStore,
    public previewPaneStore: PreviewPaneStore,
    public viewbuilderStore: ViewbuilderStore,
    public creatorAISaasStore: CreatorAISaasStore,
    public homeStore: HomeStore,
    public projectNodeStore: ProjectNodeStore
  ) {
    super(() => new PreviewAppStoreState());
  }

  getSaasUIData(): PreviewAppValue {
    return PreviewSerialize.reset(this.creatorSaasAppStore.state.saasUIData);
  }

  setPreviewShowStatus(previewShowStatus: boolean | null): void {
    this.emit(this.state.copyWith({ previewShowStatus }));
  }
  setHistoryShowStatus(historyShowStatus: boolean | null): void {
    this.emit(this.state.copyWith({ historyShowStatus }));
  }
  setSettingShowStatus(settingShowStatus: boolean | null): void {
    this.emit(this.state.copyWith({ settingShowStatus }));
  }
  setPublishState(publishState: string): void {
    this.emit(this.state.copyWith({ publishState }));
  }

  saasUiDataToPreview = (): false | undefined => {
    if (!this.previewStore.state.previewAppAcivieId) {
      return false;
    }
    const saasUIData = this.getSaasUIData();
    //console.log("saasUiDataToPreview: ", saasUIData.settings?.domain);
    // 如果不是默认值,则同步到 app list 中
    if (PreviewAppValueLangUtil.isDefaultPreviewApp(this.creatorSaasAppStore.state.saasUIData)) {
      return;
    }
    this.previewStore.setPreviewAppList(
      this.previewStore.state.previewAppList.map(item => {
        if (this.previewStore.state.previewAppAcivieId === item.id) {
          const target = cloneDeep(item);
          const source = cloneDeep(saasUIData);
          // FIXME: 不知道为什么 app 列表中的input或output会有 重复的现象
          // mergeValue 此处是 sassuidata 和 applist 最后一处防止重复的屏障
          const newApp = PreviewAppValueMergeUtil.mergeValue(target, source);
          // const newApp = {...target, ...source, v2App: {
          //   ...target.v2App,
          //   ...source.v2App,
          // }} as PreviewApp.UISaasValueType
          return newApp;
        }
        return item;
        // // 去掉 saas数据中的 function
        // // ... 扩展运算符会丢失第二级的字段
        // return previewAppActiveIdSignal.value === item.id
        //   ? PreviewAppUtil.merge(target, source)
        //   : item;
      })
    );
  };

  /**
   * 该方法早期用来只用来扩展 preview app list 的相关数据，后面扩展的数据变多
   *
   * TODO: 该方法需要被拆分分离
   *
   * @param target
   * @param extra
   * @returns
   */
  assignPreviewAppData = (target: any, extra?: PreviewApp.ExtraArgsType): any => {
    this.saasUiDataToPreview();
    const multiRoundChatData = cloneDeep(this.chatOnboardingStore.state);
    // 扩展 publish metadata
    return PreviewAppValueAssignUtil.assignPreviewAppData(target, {
      appId: this.previewStore.state.previewAppAcivieId,
      appList: this.previewStore.state.previewAppList,
      isNewEdge: true,
      nodes: this.creatorNodesStore.getNodes(),
      lastIndex: this.previewStore.state.vDataLastIndex,
      multiRoundChatData: multiRoundChatData,
      projectNodes: this.projectNodeStore.getNodes(),
      ...extra,
    });
  };

  changePreviewShowStatus(publishShowStatus: null | boolean): void {
    this.emit(this.state.copyWith({ publishShowStatus }));
  }

  setShowPreviewPanel(value: boolean): void {
    this.previewStore.setShowPreviewPanel(value);
    if (!value && this.state.publishShowStatus) {
      // FIXME: 在某种情况下会打开previewpanl 后activeid 被清空
      // 如果 关闭面板 同步 saasuidata 同 preview
      this.saasUiDataToPreview();
    }
  }

  previewToSaasUiData(id: PreviewApp.UISaasValueType["id"]): void {
    let target = find(this.previewStore.state.previewAppList, ["id", id]);
    //console.log("saasUiDataToPreview: ", target?.settings?.domain);
    if (target) {
      // 合并最新的 nodes 到 app 中
      target = PreviewAppValueMergeUtil.mergeNodes(target, this.creatorNodesStore.getNodes());
      // stud-2493 set input(preview)
      this.creatorSaasAppStore.setSaasUIData(target);
      this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);

      const viewBuilderGraph = JsonUtil.toModelFromType(ViewBuilderGraph, target.viewBuilderGraph);
      if (viewBuilderGraph) {
        this.viewbuilderStore.init(viewBuilderGraph);
      }
      this.previewStore.setSelectedUI(target.value);
      // 每次选择app后需要设置inputIdArr
      const inputIdArr = (target.input || []).map(x => x.id);
      this.creatorAISaasStore.setInputId(inputIdArr);
      this.previewStore.setPreviewAppAcivieId(id);
    }
  }

  resetPreviewData(): void {
    // stud-2493 set input(preview)(clear)
    this.creatorSaasAppStore.emptySaasUIData();
    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);
    this.setShowPreviewPanel(false);
    this.previewStore.setSelectedUI("");
  }

  /// usePreviewAppStroe 迁移逻辑
  openPreviewPage(): void {
    this.previewStore.setShowPreviewPanel(true);
    this.setPreviewShowStatus(true);
    this.setHistoryShowStatus(true);
    this.changePreviewShowStatus(null);
    this.setSettingShowStatus(null);
  }

  returnToPreviewList(): void {
    this.setHistoryShowStatus(true);
    this.changePreviewShowStatus(false);
    this.setPreviewShowStatus(false);
    this.setSettingShowStatus(false);
    this.saasUiDataToPreview();
    this.clearCurrentPreviewApp();

    this.previewPaneStore.settingPageStatus(false);

    // 如果只有一个publish view，删除后应该直接带用户到choose a format页面
    if (this.previewStore.state.previewAppList.length === 0) {
      this.setHistoryShowStatus(true);
      this.changePreviewShowStatus(true);
    }
  }

  clearCurrentPreviewApp(): void {
    this.previewStore.setPreviewAppAcivieId("");
    this.creatorSaasAppStore.emptySaasUIData();
  }

  handleSendToAppV2(param: { cb: () => void; outputCb?: () => void; showPreviewContent?: () => void }): boolean {
    // 如果在preview页面，不额外增加操作，直接添加
    if (this.previewStore.state.showPreviewPanel && this.state.previewShowStatus) return false;
    // 暂存应当继续调用的方法
    this.creatorRefStore.sendToAppStagingData = param;
    // 打开preview
    this.setShowPreviewPanel(true);
    if (param.showPreviewContent) {
      param.showPreviewContent();
    }
    return true;
  }

  isInHistory(): boolean | null {
    const featureTags = this.homeStore.state.featureTags;
    if (featureTags.enablePublishV3) {
      return this.state.historyShowStatus && !this.state.publishShowStatus && !this.state.previewShowStatus;
    }
    return (
      this.state.historyShowStatus &&
      !this.state.publishShowStatus &&
      !this.state.previewShowStatus &&
      !this.state.settingShowStatus
    );
  }

  enableRemovePreview(hasAdded = true): boolean {
    // 有previewv2 featuretag且当前node在preview app 中
    if (hasAdded) {
      // 未打开preview 面板不做任务动作修改
      if (!this.previewStore.state.showPreviewPanel) {
        return true;
      }
      // 列表页不作修改
      if (this.isInHistory()) {
        return true;
      }
      return !!this.state.previewShowStatus;
    }
    return true;
  }

  clearPreviewApp(): void {
    this.previewStore.setPreviewAppList([]);
    this.previewStore.setPreviewAppAcivieId("");
  }

  getSelectedTemplate(clone: boolean = false): PreviewApp.ProjectType | undefined {
    const selectedTemplate = this.fotReduxStore.getState().studio.selectedTemplate as any as
      | PreviewApp.ProjectType
      | undefined;
    if (selectedTemplate) {
      // FIXME: 当input 中有 function 类型时应该去掉,否则设置为redux时会报错?
      return clone ? { ...selectedTemplate } : selectedTemplate;
    }
    return;
  }

  getPublishMetadata(id?: PreviewApp.UISaasValueType["id"]): PreviewApp.PublishedMetadataV2Type | undefined {
    const project = this.getSelectedTemplate();
    // v2 preview
    if (id) {
      return PreviewAppUtil.getTargetPublishMetaById(project, id);
    }
    return PreviewAppUtil.getTargetPublishMetaById(project, this.previewStore.state.previewAppAcivieId);
  }

  updateSelectTemplate(updateObj: any): void {
    const selectedTemplate = this.getSelectedTemplate();
    const updatedSelectedTemplate = Object.assign({}, selectedTemplate, {
      ...updateObj,
    });
    this.fotReduxStore.setSelectedTemplate(updatedSelectedTemplate);
  }

  updatePublishedMetadataV2(updateObj: Partial<PreviewApp.PublishedMetadataV2Type>): void {
    const obj = {
      id: this.previewStore.state.previewAppAcivieId,
      ...updateObj,
    };
    const project: any = { ...this.getSelectedTemplate() };
    // eslint-disable-next-line
    let published_metadata_v2 = project?.published_metadata_v2 ? [...project?.published_metadata_v2] : [];

    if (isBlank(published_metadata_v2)) {
      published_metadata_v2 = [obj];
    } else {
      const idx = published_metadata_v2.findIndex(
        (x: PreviewApp.PublishedMetadataV2Type) => x.id === this.previewStore.state.previewAppAcivieId
      );
      if (idx !== -1) {
        const newData = Object.assign({}, published_metadata_v2[idx], obj);
        published_metadata_v2[idx] = newData;
      } else {
        published_metadata_v2.push(obj);
      }
    }
    project.published_metadata_v2 = published_metadata_v2;
    this.fotReduxStore.setSelectedTemplate(project);
  }

  delPublishedMetadataV2(id: string): PreviewApp.PublishedMetadataV2Type[] {
    const project: any = { ...this.getSelectedTemplate() };
    // eslint-disable-next-line
    const publishedMetadataV2 = project?.published_metadata_v2 ? [...project?.published_metadata_v2] : [];
    const idx = publishedMetadataV2.findIndex((x: PreviewApp.PublishedMetadataV2Type) => x.id === id);

    if (idx !== -1) {
      publishedMetadataV2.splice(idx, 1);
    }
    return publishedMetadataV2;
  }

  previewAppActivePublishMetadataV2Item(): any {
    const project: any = { ...this.getSelectedTemplate() };
    // eslint-disable-next-line
    const published_metadata_v2 = project?.published_metadata_v2 ? [...project?.published_metadata_v2] : [];
    return published_metadata_v2.find(
      (x: PreviewApp.PublishedMetadataV2Type) => x.id === this.previewStore.state.previewAppAcivieId
    );
  }

  // open 为true 代表只打开preview app不打开setting
  openPreviewApp(id: string, open = false): void {
    const target = find(this.previewStore.state.previewAppList, ["id", id]);
    if (target) {
      this.previewToSaasUiData(id);
      this.setPreviewShowStatus(true);
      this.setHistoryShowStatus(true);
      if (!open) {
        this.previewPaneStore.settingPageStatus(true);
        this.updatePublishedMetadataV2({ charge_option: "Subscription" });
      }
      // 匹配appId=
      let newUrl = window.location.href.replace(/(\?|&?)(appId=([^&]*&?))$|(appId=([^&]*&?))/g, "");
      // 匹配type=stripeConnectExpired
      newUrl = newUrl.replace(/(\?|&?)(type=stripeConnectExpired&?)$|(type=stripeConnectExpired&?)/g, "");
      // 匹配open=true
      newUrl = newUrl.replace(/(\?|&?)(open=true&?)$|(open=true&?)/g, "");
      window.history.pushState({}, "", newUrl);
    } else {
      func.messageUtil("App ID does not exist");
    }
  }

  /**
   * - 如果没有开启了v2 preview 权限,直接返回当前项目的 published_metadata
   * - 如果传入 id 且开启 v2 preview 权限，获取对应ID的 published_metadata
   * - 如果没有ID，且开启了v2 preview 默认使用当前activeID的 published_metadata
   */
  getPreviewAppActiveLable(): PreviewApp.PreviewAppType | undefined {
    const activeItem = this.previewStore.state.previewAppList.find(
      (x: PreviewApp.UISaasValueType) => x.id === this.previewStore.state.previewAppAcivieId
    );
    return activeItem?.v2App?.type;
  }

  clearPublishMetadata(): PreviewApp.ProjectType | undefined {
    const selectTemp = this.getSelectedTemplate(true);
    if (!selectTemp) {
      return;
    }
    const mateData = selectTemp.published_metadata_v2?.filter(item => {
      return item.id !== this.previewStore.state.previewAppAcivieId;
    });
    selectTemp.published_metadata_v2 = mateData;
    return selectTemp;
  }
  resetDomainName(newTemplate: any): any {
    const domain = newTemplate.v3?.UI?.[0]?.settings?.domain?.data;
    if (isBlank(domain)) return newTemplate;
    return {
      ...newTemplate,
      v3: {
        ...newTemplate.v3,
        UI: [
          {
            ...newTemplate.v3.UI[0],
            settings: {
              ...newTemplate.v3.UI[0].settings,
              domain: {
                data: {
                  ...newTemplate.v3.UI[0].settings.domain.data,
                  domainCheckStatus: DomainCheckStatus.idle,
                  domainCheckInstructions: null,
                  requestDomain: "",
                  requestHost: "",
                  domainCheckError: "",
                },
              },
            },
          },
        ],
      },
    };
  }

  updatedv2AppData(): any {
    let updatedProject: any = this.getSelectedTemplate(true);
    updatedProject = {
      ...updatedProject,
      v3: { ...updatedProject.v3, UI: this.previewStore.state.previewAppList },
    };
    return updatedProject;
  }

  deletePreviewV2App(id: any): void {
    this.previewStore.setPreviewAppList(this.previewStore.state.previewAppList.filter(item => item.id !== id));

    const selectedTemplate = this.getSelectedTemplate();
    const updatedUISelectedTemplate = this.assignPreviewAppData(selectedTemplate);

    this.updateSelectTemplate({
      ...updatedUISelectedTemplate,
      published_metadata_v2: this.delPublishedMetadataV2(id),
    });
  }

  // 选择app
  onSelectPreviewApp(option: PreviewApp.UISaasValueType): void {
    // TODO 设置被选中的数据
    this.previewToSaasUiData(option.id);
    this.setShowPreviewPanel(true);
    this.setPreviewShowStatus(true);
  }
  addPreview = (args: PreviewApp.AddPrviewArgs): PreviewAppValue | undefined => {
    const editorStore = this.fotReduxStore.getState().editor;
    const selectTemp = this.getSelectedTemplate(true);
    if (!selectTemp) {
      return;
    }
    // FIXME: 注意 原来的App被删除后，再次添加会重复。需要保存一次过后，就没有这个问题。
    this.previewStore.setVDataLastIndex((selectTemp?.lastIndex || this.previewStore.state.previewAppList.length) + 1);
    const newTarget = PreviewAppValueGenUtil.genPreviewApp(this.getSaasUIData(), {
      theme: editorStore.applicationTheme,
      fontSize: editorStore.fontSize,
      // TODO: 老版本使用 redux isStaticMode, v2 使用 type 区分
      staticMode: args.format.appType === PreviewAppTypeMap.STATIC_WEB,
      ...args.uiData,
      v2App: PreviewAppValueGenUtil.genV2App({
        format: args.format,
        lastIndex: this.previewStore.state.vDataLastIndex,
        lastTypeIndex: PreviewAppValueGenUtil.getAppTypeCountByFormat(
          this.previewStore.state.previewAppList,
          args.format
        ),
      }),
    });

    this.previewStore.setPreviewAppList([...this.previewStore.state.previewAppList, newTarget]);

    this.previewToSaasUiData(newTarget.id);
    // syncPreviewAppData(newTarget.id)
    return newTarget;
  };
}
