import { Node } from "reactflow";
import { CanvasLineUtil } from "@uikit/util/CanvasLineUtil";
import { StoryNodeValidator } from "imagica-corekit/dist/base/util/StoryNodeValidator";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import func from "@uikit/func";

type Input = {
  description?: string | undefined;
  name?: string | undefined;
  type?: string | undefined;
};

export class AutoAddInputNode {
  constructor(
    private creatorNodesStore: CreatorNodesStore,
    private creatorEdgesStore: CreatorEdgesStore,
    private projectNodeStore: ProjectNodeStore,
    private creatorRefStore: CreatorRefStore,
    private canvasDataRef: CanvasDataRef,
    private creatorSaasAppStore: CreatorSaasAppStore
  ) {}

  autoAddInputNodeByBluePrint({
    edgeNode,
    nodes,
    inputs,
  }: {
    edgeNode: Node;
    nodes: Array<Node<any>>;
    inputs: Array<Input> | undefined;
  }): void {
    const flows = edgeNode?.data.flows.map((x: any) => x.sourceNodeId);
    // 先更新现有node的数据
    let maxX = 0;
    let maxY = 0;
    let nodeIndex = 0;
    const inputArr = inputs || [];
    let newNodes = nodes.map(x => {
      if (flows.includes(x.id) && !func.isEmpty(inputArr)) {
        const currentInput = inputArr[nodeIndex];
        let displayType = currentInput?.type;
        if (StoryNodeValidator.isValidStoryNodeDisplayType(currentInput?.type || "text") === false) {
          displayType = x.data.displayType;
          console.error("The displayType is illegal, please check");
        }

        nodeIndex++;
        if (currentInput) {
          maxX = Math.max(x.position.x, maxX);
          maxY = Math.max(x.position.y, maxY);
          return {
            ...x,
            data: {
              ...x.data,
              // stud-2493 set input title(nodes)
              inputTitle: currentInput.name,
              displayType,
              description: currentInput.description,
            },
          };
        }
      }
      return x;
    });

    const addedFlows: Array<{ sourceNodeId: string; sourceLineId: string; creationMethod: boolean }> = [];
    const addedLines: any[] = [];

    if (!func.isEmpty(inputArr) && nodeIndex < inputArr.length) {
      inputArr.slice(nodeIndex).forEach((input: Input) => {
        const newNodeId = `editor-${this.creatorRefStore.nodeIndexRef.current++}`;
        let displayType = input?.type;
        if (StoryNodeValidator.isValidStoryNodeDisplayType(input?.type || "text") === false) {
          displayType = "text";
          console.error("The displayType is illegal, please check");
        }
        const newNode = {
          id: newNodeId,
          type: "customNode",
          data: Object.assign(
            {},
            { ...this.canvasDataRef.nodeDataRef.current },
            {
              // stud-2493 set input title(nodes)
              inputTitle: input?.name,
              displayType,
              description: input?.description,
              showFeedback: false,
            }
          ),
          position: {
            x: maxX,
            y: maxY + 400,
          },
        };
        const newLine = CanvasLineUtil.generateNewLine({ source: newNode.id, target: edgeNode.id, type: "source" });
        const newLineParam = { sourceNodeId: newNodeId, sourceLineId: newLine.id, creationMethod: false };
        addedFlows.push(newLineParam);
        newNodes.push(newNode);
        addedLines.push(newLine);
      });
    }

    newNodes = newNodes.map(node => {
      if (node.id === edgeNode.id) {
        return {
          ...node,
          data: {
            ...node.data,
            flows: node.data.flows.concat(addedFlows),
          },
        };
      }
      return node;
    });

    this.creatorNodesStore.setNodes(newNodes);
    this.creatorEdgesStore.setEdges((prevList: any) => [...prevList, ...addedLines]);
    this.projectNodeStore.composeNodes(newNodes, this.creatorSaasAppStore.state.saasUIData);
  }
}
