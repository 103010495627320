import func from "@uikit/func";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";

export class SharedLinkToGetProject {
  constructor(private gql: ObjectRelationGqlService) {}

  /**
   *
   * @param attributes
   * @param routerQparams searchParmas.get('q')
   * @param routerProjectId urlParams().projectId 也就是 "/editor/shared_intelligent_app/:projectId" 路由的 projectId
   * @returns
   */
  async getSharedProjectData(
    attributes: Record<string, any>,
    routerQparams?: string | null,
    routerProjectId?: string
  ): Promise<any> {
    try {
      //使用uuid(多个app) || project_id(单个app) || url中的q(代表attrIndexer) || url传的projectId(最早版本)
      const projectId = attributes?.uuid || attributes?.project_id || routerQparams || routerProjectId || "";
      let data: any = {};
      // check if the shared id is a uuid or object id
      const params: any = {
        name: "studio_project",
        globalOnly: true,
        orderBy: "-created_at",
        page: 1,
        size: 1,
      };
      // 如果uuid有值则为preview v2存储的最新数据，需要通过uuid去获取app相关信息
      if (!func.isEmpty(attributes?.uuid)) {
        params.uuid = attributes.uuid;
        params.name = "studio_shared_app";
      } else if (isNaN(projectId)) {
        // get via uuid
        params.attrIndexer = projectId;
      } else {
        params.id = projectId;
      }
      data = await this.gql.objects(params);

      const project = data[0];
      if (!project?.attributes) {
        throw new Error("Empty project data");
      }
      const projectData = JSON.parse(project?.attributes);
      if (!projectData) {
        throw new Error("Empty project data");
      }
      projectData.id = project.id;
      projectData.uuid = project.uuid;
      return projectData;
    } catch (error) {
      func.messageError(error);
      return {};
    }
  }
}
