import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { OpenAiParam } from "@uikit/service/OpenAiParam";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";
import { Api } from "@uikit/service/api";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { EdgeRunResultParseUtil } from "@uikit/util/EdgeRunResultParseUtil";
import { ResponseDataUtil } from "@uikit/util/ResponseDataUtil";
import { ApiResponse } from "@uiview/pages/customApi/CustomApiPageBloc";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { isEmpty } from "lodash";
import { EdgeRunVariable } from "./EdgeRunVariable";

type MethodTypeResult = {
  textAreaValue?: string | Record<string, any>;
  displayType?: "customJsFunction" | "customApiFunction";
  loadingNodeHtml?: boolean;
};

type SendRequestResult =
  | ApiResponse
  | {
      data: {
        type: string;
        value: Response | Record<string, any> | null;
      };
    }
  | null
  | undefined;

/**
 * 原来 useHomeService 中的下列方法
 *
 * - sendRequest
 * - methodType
 * - normalCall
 * - customMethod
 */
export class EdgeRunHandlerRequest {
  api: Api = new Api(false);

  constructor(
    private fotReduxStore: FotReduxStore,
    private edgeRunVariable: EdgeRunVariable,
    private creatorNodesStore: CreatorNodesStore,
    private creatorStoreMethods: CreatorStoreMethods,
    private openAiParam: OpenAiParam,
    private postUrlCanAbortService: PostUrlCanAbortService
  ) {}

  async sendRequest(
    currentSourceNodeValue: any,
    requestData: { url: any },
    setLoadingFunc: (value: boolean) => any = (): void => {}
  ): Promise<SendRequestResult> {
    if (isEmpty(requestData.url)) return;
    const params = this.edgeRunVariable.handleVarInject(currentSourceNodeValue, requestData);
    if (isEmpty(params)) return;
    const { url, method, headerList, bodyParam } = params as any;
    const headers = {} as any;
    headerList.forEach((x: any) => {
      headers[x.name] = x.value;
    });
    const start = Date.now();
    let result: Record<string, any> | Response | null = null;
    try {
      setLoadingFunc(true);
      const res = await this.api.commonRequest(url, method.toUpperCase(), {
        headers,
        body: bodyParam,
      });
      if (!res.ok) {
        throw res;
      }
      if (this.fotReduxStore.getState().editor.createAPIFuncData.open) {
        result = res;
      } else {
        await res.json().then((res2: Record<string, any>) => {
          result = res2;
        });
      }
    } catch (error: any) {
      if (!isEmpty(error.name)) {
        result = error.response;
      }
      console.error("send Request", error);
      return Promise.reject(error);
    } finally {
      setLoadingFunc(false);
      if (this.fotReduxStore.getState().editor.createAPIFuncData.open) {
        // 这里存在问题，finally 不应该存在，且如果 result 不会为 null
        // FIXME: 但 result 为 json 时是不正确的情况
        // eslint-disable-next-line no-unsafe-finally
        return await ResponseDataUtil.handleResponseStatusData(result as Response, start);
      }
      // eslint-disable-next-line no-unsafe-finally
      return {
        data: { type: "json", value: result },
      };
    }
  }

  async methodType(currentSingleFlow: any, nodeText: any): Promise<MethodTypeResult> {
    try {
      let obj = {};

      switch (currentSingleFlow?.type) {
        case "CustomCode": {
          const htmlSrc = this.edgeRunVariable.getHtmlStr(currentSingleFlow, nodeText);
          obj = {
            textAreaValue: htmlSrc,
            displayType: "customJsFunction",
            loadingNodeHtml: true,
          };
          break;
        }
        case "customApi": {
          const requestData = currentSingleFlow.apiData.requestData;
          const responseData = await this.sendRequest(nodeText, requestData);
          obj = {
            textAreaValue: responseData || {},
            displayType: "customApiFunction",
          };
          // opt:
          break;
        }

        default:
          break;
      }
      return obj;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async normalCall(
    lineParams: { lineType: string; identifier: { value: any }; queryValue: any },
    nodeText: any,
    takeLastDisplay: boolean,
    edgeParam: { id: any; target: any; source: any; lineParam?: any },
    isRecursiveCall = false
  ): Promise<string> {
    const { source, target, id } = edgeParam;
    try {
      let postParam: Record<string, any> = {};
      let url = "/be/bas-demo-v4/nlp/brain_openai";
      if (lineParams.lineType === "identifier") {
        url = `/${takeLastDisplay ? "stream" : "be"}/bas-demo-v4/nlp/completions_generation`;
        postParam = await this.openAiParam.getCompletionsGenerationParam(
          "object_id",
          lineParams.identifier.value,
          {
            input: nodeText,
          },
          takeLastDisplay ? true : false
        );
      } else {
        postParam = this.openAiParam.getOpenAiParam({
          lineParams,
          prompt: lineParams.queryValue || "",
          nodeText,
        });
      }

      let temp = "";
      await this.postUrlCanAbortService.postWithStreaming(
        url,
        postParam,
        (msg: any) => {
          temp += msg.choices?.[0]?.text || msg.choices?.[0]?.delta?.content || "";
          // takeLastDisplay，作为自定义func最后一项时才使用streaming输出
          // isRecursiveCall 是最外部的自定义func调用
          if (takeLastDisplay && !isRecursiveCall) {
            this.creatorNodesStore.setNodes(prevNodes => {
              return prevNodes.map(l => {
                const displayType = EdgeRunResultParseUtil.getAutomaticDisplayType(l.data, temp);
                if (l.id === target) {
                  return {
                    ...l,
                    data: {
                      ...l.data,
                      textAreaValue: temp,
                      displayType,
                    },
                  };
                }
                return l;
              });
            });
          }
        },
        false,
        ((postInstance: any) => {
          this.creatorStoreMethods.setNodeParam({
            id: target,
            data: {
              showStopGenerateBtn: true,
              abortNode: {
                source,
                target,
                edgeId: id,
                apiInstance: postInstance,
              },
            },
          });
        }) as any
      );

      return temp;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      this.creatorStoreMethods.setNodeParam({
        id: target,
        data: {
          showStopGenerateBtn: false,
          abortNode: {},
        },
      });
    }
  }

  async customMethod(
    nodeText: any,
    customFunc: { type: any; edgeArr?: string | any[] | undefined; apiData?: any },
    lineParams: any,
    takeLastDisplay: boolean,
    edgeParam: { id: any; target: any; source: any; lineParam?: any }
  ): Promise<MethodTypeResult | string> {
    try {
      let lastRunNodeText = nodeText;
      if (customFunc?.type) {
        return this.methodType(customFunc, nodeText);
      }
      lastRunNodeText = await this.normalCall(lineParams, lastRunNodeText, takeLastDisplay, edgeParam);
      return lastRunNodeText;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
