import { FotUserAuthService } from "@uikit/service/FotUserAuthService";
import { ProcessBoarding } from "./ProcessBoarding";
import { ProcessImagicaData } from "./ProcessImagicaData";
import { ProcessLang } from "./ProcessLang";
import { ProcessShareDomain } from "./ProcessShareDomain";
import { ProcessUserAuth } from "./ProcessUserAuth";
import { SubscriberService } from "@uikit/imagicaApp/SubscriberService";
import { FotProcessDep } from "./modles";

export class ProcessService {
  constructor(
    public processLang: ProcessLang,
    public processBoarding: ProcessBoarding,
    public processShareDomain: ProcessShareDomain,
    public processUserAuth: ProcessUserAuth,
    public processImagicaData: ProcessImagicaData,
    public fotUserAuthService: FotUserAuthService,
    public subscriberService: SubscriberService
  ) {}

  async init(fotProcessDep: FotProcessDep): Promise<void> {
    this.fotUserAuthService.init();

    await this.subscriberService.init();

    await this.processShareDomain.init(fotProcessDep);

    await this.processUserAuth.init(fotProcessDep);

    await this.processBoarding.init();

    // 原来 useHomeInit handleInit 在页面初始化之后再调用的
    // FIXME: 从代码上来看 initImagicaInit 方法应该在这里调用，但是，现在内部依赖了 useParams 和的 attrIndexer， Editor 组件还不能直接区分详细组件，下面方法还是需要在homeUsePluginV2 调用
    // await this.initImagicaInit(fotProcessUIParam.initImagicaDataParams);
  }
}
