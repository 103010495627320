import { MarkerType } from "reactflow";

// 间距
export const distance = 10;
export const selectApiReg = /^\//;
export const forbidAddToPreviewType = ["uploadFile", "Agent"];

export const markerEnd = {
  type: MarkerType.ArrowClosed,
  width: 12,
  height: 8,
  color: "#B0B0B7",
};

export const transparentMarkerEnd = {
  type: MarkerType.ArrowClosed,
  width: 12,
  height: 12,
  color: "transparent",
};

export const selectedMarkerEnd = {
  type: MarkerType.ArrowClosed,
  width: 12,
  height: 12,
  color: "#e84e4e",
};

export const bottomVariants = {
  init: {
    opacity: 0,
    scale: 0,
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      ease: "easeInOut",
    },
  },
  hide: {
    opacity: 0,
    scale: 0,
    transition: {
      duration: 0.5,
      type: "spring",
      ease: "easeOut",
    },
  },
};
