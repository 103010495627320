import React, { useState, useEffect, useCallback } from "react";

import { Button } from "antd";
import { useSearchParams, useNavigate } from "react-router-dom";
import LoadingOutline from "../components/LoadingOutline";
import style from "./css/SharedCanvasView.module.css";

import { Api } from "../../uikit/service/api";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";

const gql = getIt(ObjectRelationGqlService);
const LOGO = "https://dopniceu5am9m.cloudfront.net/natural.ai/assets/png/natural.png";
const SharedCanvasView = props => {
  const api = new Api(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // states
  const [shareLoading, setShareLoading] = useState(true);
  const [copyLoading, setCopyLoading] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [tipError, setTipError] = useState("");
  const [authorUserInfo, setAuthorUserInfo] = useState({});
  const [newProjectId, setNewProjectId] = useState(null);

  const initData = async (userId = null, projectId = null) => {
    try {
      setShareLoading(true);
      const res = await api.getWithErrorCanAbort(`/api/users/${userId}`);
      setAuthorUserInfo(res.data);
      setShareLoading(false);
      setCopyLoading(true);
      const projectInfo = await gql.shareStudioProject(projectId, res?.data?.id);

      const attributes = Object.assign({}, projectInfo.attributesV2, {
        published_metadata: {},
        published_metadata_v2: [],
      });
      await gql.updateObject(parseInt(projectInfo.id), {
        name: "studio_project",
        attributes,
      });

      if (func.isEmpty(projectInfo?.uuid)) {
        throw { message: "failed to grant access" };
      }
      setNewProjectId(projectInfo.uuid);
    } catch (error) {
      const str = `Unable to access shared canvas, ${error.message || "failed to grant access"}.`;
      func.messageUtil(str);
      setTipError(str);
      console.error(error);
    } finally {
      setCopyLoading(false);
      setShareLoading(false);
    }
  };

  const handleGoToProjectClick = () => {
    if (func.isEmpty(newProjectId)) {
      tipError && func.messageUtil(tipError);
      return;
    }
    navigate(`/editor/${newProjectId}`);
  };

  useEffect(() => {
    const authorId = searchParams.get("author");
    const linkProjectId = searchParams.get("projectId");
    if (!linkProjectId || !authorId) {
      setShareLoading(false);
      func.messageUtil("Unable to access shared canvas, link is invalid.");
      return;
    }
    setProjectName(searchParams.get("projectName") || "");
    initData(authorId, linkProjectId);
  }, []);

  return (
    <div className={style.SharedCanvasView}>
      {shareLoading ? (
        <LoadingOutline size={40} />
      ) : (
        <div className={style["get-access-container"]}>
          <img className={style.logoImg} src={LOGO} />
          <div className={style.shareDescription}>
            <p>{authorUserInfo.name || "Someone"} shared an Imagica project </p>
            <p>
              <span>{projectName || ""}</span> with you.
            </p>
          </div>
          <Button type="primary" onClick={handleGoToProjectClick} loading={copyLoading}>
            Open Project
          </Button>
        </div>
      )}
    </div>
  );
};

export default SharedCanvasView;
