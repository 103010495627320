"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeType = void 0;
var ThemeType;
(function (ThemeType) {
    ThemeType["LIGHT"] = "light";
    ThemeType["DARK"] = "dark";
    ThemeType["THEMEA"] = "themeA";
    ThemeType["THEMEB"] = "themeB";
    ThemeType["THEMEC"] = "themeC";
    ThemeType["THEMED"] = "themeD";
    ThemeType["THEMEE"] = "themeE";
    ThemeType["THEMEF"] = "themeF";
    ThemeType["THEMEG"] = "themeG";
    ThemeType["THEMEBGA"] = "themeBgA";
    ThemeType["THEMEBGB"] = "themeBgB";
    ThemeType["THEMEBGC"] = "themeBgC";
    ThemeType["THEMEBGD"] = "themeBgD";
    ThemeType["THEMEBGE"] = "themeBgE";
    ThemeType["THEMEBGF"] = "themeBgF";
    ThemeType["THEMEBGG"] = "themeBgG";
    ThemeType["THEMEBGH"] = "themeBgH";
    ThemeType["THEMEBGI"] = "themeBgI";
    ThemeType["THEMEBGJ"] = "themeBgJ";
})(ThemeType || (exports.ThemeType = ThemeType = {}));
