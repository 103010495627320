import { getIt } from "@uikit/getIt";
import { MessageCenter } from "imagica-corekit/dist/base/kernel/MessageCenter";
import { useEffect, useRef } from "react";
import func from "@uikit/func";

export const useReconnect = (token: string) => {
  const reconnectOnce = useRef(false);
  const messageCenter = getIt(MessageCenter);

  useEffect(() => {
    if (!func.isEmpty(token) && reconnectOnce.current === false) {
      reconnectOnce.current = true;
      messageCenter.reconnect();
    }
  }, [messageCenter, token]);
};
