import { getAssetsImage } from "@views/thinking-layout-editor/utilities";

export const defaultImageAssets: string[] = [
  getAssetsImage("preview-0.png"),
  getAssetsImage("preview-1.png"),
  getAssetsImage("preview-2.png"),
  getAssetsImage("preview-3.png"),
  getAssetsImage("preview-4.png"),
  getAssetsImage("preview-5.png"),
  getAssetsImage("preview-6.png"),
  getAssetsImage("preview-7.png"),
  getAssetsImage("preview-8.png"),
  getAssetsImage("preview-9.png"),
];
