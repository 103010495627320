import * as amplitude from "@amplitude/analytics-browser";
import { Identify } from "@amplitude/analytics-browser";
import store from "../../store";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import events from "./events";
import { isEmpty } from "lodash";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";

class EventManager {
  constructor(props) {
    this.user = props;

    const userId = this.user.id.toString();
    //TODO: fix init user properties
    amplitude.init(settings.amplitudeAPIKey, userId, {
      minIdLength: 1,
    });
    amplitude.setUserId(userId);

    const i = new Identify();
    i.set("email", this.user.email);
    i.set("name", this.user.name || "");
    i.set("user_roles", this.user.roles);
    // i.unset('email');
    // i.unset('name');
    // i.unset('roles');
    amplitude.identify(i);
  }

  async logEvent(event, rawData, curProject) {
    if (!(event in events)) {
      throw new Error(`event ${event} not supported`);
    }
    // if (!events[event].rules(rawData)) return; //TODO: rules?
    const data = await events[event].processData(rawData, curProject);
    // if (util.isEmpty(data)) return; //FIXME: if no event property: not implemented
    if (isEmpty(data)) return;
    if (isEmpty(data.project_static_id)) {
      data.project_static_id = curProject.attrIndexer || curProject.uuid;
    }
    const formatData = this.checkPropsJsonObj(data);
    return amplitude.track(event, formatData);
  }

  async track(event, properties) {
    return amplitude.track(event, properties);
  }

  checkPropsJsonObj(props) {
    const newData = {};
    for (let key in props) {
      const value = props[key];
      if (value && typeof value === "object") {
        try {
          const jsonString = JSON.stringify(value); // JSON.stringify(value);
          newData[key] = jsonString;
        } catch (error) {
          console.log(error, "json error");
        }
      } else {
        newData[key] = value;
      }
    }
    return newData;
  }
}

/**
 *
 * @param {import('../../services/types').Events} event
 * @param {*} [rawData]
 */
const logEvent = async (event, rawData) => {
  try {
    const curProject = store.getState().studio.selectedTemplate;
    let curUser = await store.getState().editor.me; //getting currunt user from global storage
    // 需要讨论未登录用户的amplitude的id设置
    if (!curUser.id) {
      curUser = { ...curUser, id: "unsigned_user" };
    }
    const manager = new EventManager(curUser);
    await manager.logEvent(event, rawData, curProject);
  } catch (err) {
    console.error(`amplitude event failed: ${event} - ${err}`);
  }
};

export { EventManager, logEvent };
