import { logEvent } from "@uikit/service/amplitude";
import { useCallback, useMemo } from "react";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { getDefaultPreviewAppTypeList } from "@uiview/views/PreviewApp/const";
import func from "@uikit/func";
import { studioActions } from "@store/studio";
import { useDispatch } from "react-redux";
import { previewStore } from "@uiview/store/PreviewStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { AddNode } from "@uikit/service/AddNode";
import { FotSaveService } from "@uikit/service/FotSaveService";

/**
 * 获取首页中多处引用到方法
 */
// eslint-disable-next-line
export function useHomePublicMethods() {
  const gql = getIt(ObjectRelationGqlService);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const previewAppStore = getIt(PreviewAppStore);
  const projectNodeStore = getIt(ProjectNodeStore);
  const homeHandleFieldMethods = getIt(HomeHandleFieldMethods);
  const addNode = getIt(AddNode);

  const setSelectedTemplate = (val: PreviewApp.ProjectType): void => {
    dispatch(studioActions.setSelectedTemplate(val));
  };
  const dispatch = useDispatch();
  const fotSaveService = getIt(FotSaveService);
  const previewState = useImagicaStore(previewStore).value;

  const handleClickUpload = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // to avoid reversed event order received on amplitude because of 2 events are triggered in parallel
    setTimeout(() => {
      logEvent("create_file_upload");
    }, 50);
    addNode.addNodeV2(event, { displayType: "uploadFile" });
  }, []);

  // 点击 AI 图标，关闭已有弹出层
  const clickClosePreview = useCallback(() => {
    projectPageStore.setIsPublish(false);
    const homeStore = getIt(HomeStore);
    if (homeStore.state.featureTags.disableMultiRoundChatOnboarding) {
      previewAppStore.setShowPreviewPanel(false);
    }
  }, []);

  const getCurrentApp = useCallback(async () => {
    const existedApp = previewState.previewAppList[0];
    if (existedApp) {
      return existedApp;
    }

    homeHandleFieldMethods.autoSelectTemplate(false, true);
    previewAppStore.addPreview({
      format: getDefaultPreviewAppTypeList()[0],
    });

    // 2. 保存/新建
    let updatedProject = previewAppStore.getSelectedTemplate(true);

    if (!updatedProject) {
      // 发生不该出现的错误，没有 template
      return null;
    }

    try {
      if (func.isEmpty(updatedProject.id)) {
        const { updated_project } = await fotSaveService.handleSave({ notShowSuccessTip: true });
        setSelectedTemplate(updated_project);
      } else {
        updatedProject = previewAppStore.assignPreviewAppData(updatedProject);
        if (!updatedProject) {
          return;
        }

        await gql.updateObject(parseInt(updatedProject.id), {
          name: "studio_project",
          attributes: updatedProject,
        });
        setSelectedTemplate(updatedProject);
      }
      // stud-2493 set input(preview)(clear)
      creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, input: [], output: [] }));
      projectNodeStore.composeNodes(creatorNodesStore.getNodes(), creatorSaasAppStore.state.saasUIData);

      // resetStartNodeIds(false);
      homeHandleFieldMethods.autoSelectTemplate(false, true);
      return updatedProject;
    } catch (error) {
      func.messageError(error);
    }
  }, [previewState, getDefaultPreviewAppTypeList, gql, setSelectedTemplate]);

  const showPreviewContent = useCallback(async () => {
    const app = (await getCurrentApp()) as PreviewApp.UISaasValueType;
    if (app) {
      previewAppStore.onSelectPreviewApp(app);
    }
  }, [getCurrentApp]);

  return useMemo(
    () => ({
      handleClickUpload,
      clickClosePreview,
      showPreviewContent,
    }),
    [handleClickUpload, clickClosePreview, showPreviewContent]
  );
}
