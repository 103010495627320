import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import Cookie from "js-cookie";
import dayjs from "dayjs";
import { injectable } from "inversify";
import { StorageManager } from "imagica-corekit/dist/base/port/StorageManager";

/**
 * 目前在项目中发现使用的键名
 */
type PreStoreKey = "BRAIN_TOKEN" | "BRAIN_USER_EMAIL" | "studioMode" | "testMode";

type ShareStorageConfig = Cookies.CookieAttributes;

function getDefaultDomain() {
  // prod use publicDomain
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    return settings.publicDomain;
  }

  // 获取当前地址顶级域名
  return window.location.hostname.split(".").slice(-2).join(".");
}

function getDefaultExpires() {
  return dayjs().add(1, "months").toDate();
}

/**
 * 为前端跨二级域名共享存储，目前使用 `cookies` 实现
 *
 * - 支持二级域名访问
 *
 * @fixme 使用该方案并不是最优方案，需要重新使用, 且目前为了解决 bsf-2028, 暂时只将 token 重置为 `ShareStorage` 其余不变
 *
 */
@injectable()
export class CookieStorageManagerImpl implements StorageManager {
  config: ShareStorageConfig;
  constructor(config?: Partial<ShareStorageConfig>) {
    this.config = {
      domain: getDefaultDomain(),
      expires: getDefaultExpires(),
      ...config,
    };
  }

  clear(): void {}

  getItem(key: string): string | undefined {
    return Cookie.get(key) || undefined;
  }

  removeItem(key: string): void {
    return Cookie.remove(key);
  }

  setItem(key: string, value: string): void {
    Cookie.set(key, value, this.config);
  }
}
