import css from "./ProceedPayment.module.scss";
import { Header } from "@uiview/views/Mobile/Plan/components/header/Header";
import {
  ImagicaCreatorPlan,
  ImagicaCreatorPlanElement,
} from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { useSignal } from "@preact/signals-react";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import { PlansTableUtil } from "@uikit/planList/PlanTableUtil";

export function ProceedPayment(props: {
  onClose: () => void;
  onPay: (priceObj: ImagicaCreatorPlanElement) => void;
  payLoading: boolean;
  plan: ImagicaCreatorPlan | undefined;
}): JSX.Element {
  const selectYear = useSignal(true);
  const price = selectYear.value ? props.plan?.price_annual : props.plan?.price_month;
  const originAmount = price ? PlansTableUtil.getUiPlanElement(price).originAmount : 0;

  function changePriceType(): void {
    selectYear.value = !selectYear.value;
  }
  return (
    <div className={css.proceedPayment}>
      <Header title={`Subscribe to ${props.plan?.name.text}`} showReturn={true} onClose={props.onClose} />
      <div className={css.content}>
        <div className={css.tab}>
          <div className={classNames(!selectYear.value && css.activePay)} onClick={changePriceType}>
            Pay monthly
          </div>
          <div className={classNames(selectYear.value && css.activePay)} onClick={changePriceType}>
            Pay annually
          </div>
        </div>
        <div className={css.descriptipn}>
          <div className={css.priceRow}>
            <div>Annual business plan</div>
            <div>{"$" + props.plan?.price_annual.amount}</div>
          </div>
          <div className={css.discount}>{props.plan?.price_annual?.price_discount} discount</div>
        </div>
        <div className={css.priceRow}>
          <div className={css.weight}>Total</div>
          <div className={css.weight}>${originAmount}</div>
        </div>
      </div>

      <footer className={css.footer}>
        <div
          className={css.proceed}
          onClick={() => {
            const priceObj = selectYear.value ? props.plan?.price_annual : props.plan?.price_month;
            if (priceObj) props.onPay(priceObj);
          }}
        >
          {props.payLoading ? <LoadingOutlined /> : "Proceed to Payment"}
        </div>
        <div
          className={css.footerDes}
          onClick={() => {
            window.open(`${window.location.origin}${window.location.pathname}#/editor/profile/account`, "target");
          }}
        >
          By clicking the ‘Proceed to Payment’ button and confirming your subscription, you authorize Brain to charge
          your payment method a recurring charge of ${originAmount} each {selectYear.value ? "year" : "month"}, which
          continues automatically until cancelled. Cancel any time by visiting Account Settings. You must cancel before
          your next billing period begins to avoid being charged for renewal.
        </div>
      </footer>
    </div>
  );
}
