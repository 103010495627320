function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { StreamSimulator, sperator } from "imagica-corekit/dist/base/util/StreamSimulator";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
function getDefaultState(props) {
  return {
    streamContent: props !== null && props !== void 0 && props.disabled ? props === null || props === void 0 ? void 0 : props.content : "",
    streaming: false,
    streamList: []
  };
}
export function useStreamText(props) {
  var _ref = props || {},
    _ref$content = _ref.content,
    content = _ref$content === void 0 ? "" : _ref$content,
    disabled = _ref.disabled,
    onStreamingStateChanged = _ref.onStreamingStateChanged,
    onStreamingContentChanged = _ref.onStreamingContentChanged,
    onStreamingContentStopped = _ref.onStreamingContentStopped;
  var streamSimulator = useRef(new StreamSimulator({
    delay: 150
  }));
  var _useState = useState(getDefaultState(props)),
    _useState2 = _slicedToArray(_useState, 2),
    innerState = _useState2[0],
    setInnerState = _useState2[1];
  var stop = useCallback(function (isManual) {
    var _streamSimulator$curr;
    (_streamSimulator$curr = streamSimulator.current) === null || _streamSimulator$curr === void 0 || _streamSimulator$curr.stop();
    setInnerState({
      streamContent: content,
      streaming: false,
      streamList: innerState.streamList
    });
    if (isManual) {
      /// 手动停止动画，通知外部整个动画执行完成，并返回当前输出内容
      onStreamingContentStopped === null || onStreamingContentStopped === void 0 || onStreamingContentStopped(innerState.streamList.join(sperator));
    }
  }, [innerState.streamList]);
  var refresh = useCallback(function () {
    var _streamSimulator$curr2;
    (_streamSimulator$curr2 = streamSimulator.current) === null || _streamSimulator$curr2 === void 0 || _streamSimulator$curr2.stop();
    if (content && !disabled) {
      setInnerState(_objectSpread(_objectSpread({}, getDefaultState(props)), {}, {
        streaming: true
      }));
      streamSimulator.current.reset({
        content: content
      }).startByWord(function (_ref2) {
        var status = _ref2.status,
          streamList = _ref2.streamList;
        setInnerState({
          streamContent: content,
          streaming: status === "pending",
          streamList: streamList
        });
        onStreamingContentChanged === null || onStreamingContentChanged === void 0 || onStreamingContentChanged(innerState.streamList.join(sperator));
      });
    }
  }, [disabled, content]);
  useEffect(function () {
    onStreamingStateChanged === null || onStreamingStateChanged === void 0 || onStreamingStateChanged(innerState.streaming);
    if (!innerState.streaming && !isEmpty(innerState.streamList)) {
      /// 通知外部整个动画执行完成
      onStreamingContentStopped === null || onStreamingContentStopped === void 0 || onStreamingContentStopped();
    }
  }, [innerState.streaming]);
  useEffect(function () {
    refresh();
    return function () {
      var _streamSimulator$curr3;
      (_streamSimulator$curr3 = streamSimulator.current) === null || _streamSimulator$curr3 === void 0 || _streamSimulator$curr3.stop();
    };
  }, [disabled, content]);
  return _objectSpread(_objectSpread({}, innerState), {}, {
    refresh: refresh,
    stop: stop
  });
}