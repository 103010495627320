import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { Api } from "@uikit/service/api";
import { getIt } from "@uikit/getIt";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import html2canvas, { Options } from "html2canvas";
import { S3Response, UploadFileToS3Result } from "../types";

export const delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

export const callback = () => {};

export const springCallback = () => ({});

export const getAssetsPath = (path: string): string => {
  return `${settings.S3CDN}${settings.assets}${path}`;
};

export const getAssetsImage = (filename: string): string => {
  return getAssetsPath(`/images/${filename}`);
};

export const getAssetsIcon = (filename: string): string => {
  return getAssetsPath(`/icons/${filename}`);
};

export const getAssetsVideo = (filename: string): string => {
  return getAssetsPath(`/videos/${filename}`);
};

export const getAssetsAnimation = (filename: string): string => {
  return getAssetsPath(`/animations/${filename}`);
};

export const getScreenshotAsDataUrlByHtmlElement = async (
  element: HTMLElement = document.body,
  options: Partial<Options> = {}
): Promise<string | never> => {
  const canvas = await html2canvas(element, options);
  const dataUrl = canvas.toDataURL("image/png");
  return dataUrl;
};

export const getScreenshotAsBlobByHtmlElement = async (
  element: HTMLElement = document.body,
  options: Partial<Options> = {
    backgroundColor: "transparent",
  }
): Promise<Blob | never> => {
  const canvas = await html2canvas(element, options);
  const blob = await new Promise<Blob | null>(resolve => canvas.toBlob((value: Blob | null) => resolve(value)));
  if (!blob) {
    throw Error();
  }
  return blob;
};

export const uploadFileToS3 = async (file: File): Promise<UploadFileToS3Result> => {
  const api = new Api(false);
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("by", "imagica");
  const response: S3Response = await api.uploadUseXhr(`/api/me/upload/studios`, formData, {
    onProgress: () => {},
  });
  return {
    s3: response.data,
  };
};

export const downloadFileAsBlobFromS3ByObjectKey = async (objectKey: string): Promise<Blob> => {
  const token = getIt(FotAuthStore).state.brainToken;
  const url = `${settings.API}/api/me/upload/studios?url=${encodeURI(objectKey)}`;
  const fileResponse = await fetch(url, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  const blob = await fileResponse.blob();
  return blob;
};

export const getDataUrlByBlob = async (blob: Blob): Promise<string> => {
  const dataUrl = await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (!reader.result) {
        return reject(Error());
      }
      const result: string = reader.result.toString();
      resolve(result);
    };
    reader.onerror = () => {
      reject(Error());
    };
    reader.readAsDataURL(blob);
  });
  return dataUrl;
};

export const getHTMLElementOfReactFlow = (): HTMLElement => {
  const element = document.querySelector<HTMLElement>(".react-flow__renderer");
  if (!element) {
    throw Error();
  }
  return element;
};
