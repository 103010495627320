"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AirRevalidateResponse = exports.AirRevalidateResult = exports.FareItineraries = exports.FareItinerary = exports.AirItineraryFareInfo = void 0;
const ClassTransformerDecorators_1 = require("../../cutil/ClassTransformerDecorators");
class AirItineraryFareInfo {
    constructor() {
        this.FareSourceCode = "";
    }
}
exports.AirItineraryFareInfo = AirItineraryFareInfo;
class FareItinerary {
    constructor() {
        this.AirItineraryFareInfo = new AirItineraryFareInfo();
        this.IsPassportMandatory = false;
    }
}
exports.FareItinerary = FareItinerary;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => AirItineraryFareInfo, new AirItineraryFareInfo())
], FareItinerary.prototype, "AirItineraryFareInfo", void 0);
class FareItineraries {
    constructor() {
        this.FareItinerary = new FareItinerary();
    }
}
exports.FareItineraries = FareItineraries;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => FareItinerary, new FareItinerary())
], FareItineraries.prototype, "FareItinerary", void 0);
class AirRevalidateResult {
    constructor() {
        this.IsValid = "";
        this.FareItineraries = new FareItineraries();
    }
    get isValid() {
        return this.IsValid === "true";
    }
}
exports.AirRevalidateResult = AirRevalidateResult;
class AirRevalidateResponse {
    constructor() {
        this.AirRevalidateResult = new AirRevalidateResult();
    }
}
exports.AirRevalidateResponse = AirRevalidateResponse;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => AirRevalidateResult, new AirRevalidateResult())
], AirRevalidateResponse.prototype, "AirRevalidateResult", void 0);
