import { useEffect } from "react";
import styles from "./ChargeYourCustomers.module.scss";
import useCreation from "ahooks/lib/useCreation";
import { ChargeOption, ChargeYourCustomersBloc } from "./ChargeYourCustomersBloc";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { ChargeOptions } from "./ChargeOptions";
import { ChargeSubscription } from "./ChargeSubscription";
import { ChargeActiveLink } from "./ChargeActiveLink";
import { ReadyStatus } from "imagica-corekit/dist/base/cutil/ReadyStatus";
import isBlank from "@sedan-utils/is-blank";
import { getQueryParams } from "@views/thinking-layout-editor/util";
import { ChargeAgreement } from "./ChargeAgreement";
import { PowerMaskView } from "@uiview/views/PowerMask/PowerMaskView";
import { previewStore } from "@uiview/store/PreviewStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

export function ChargeYourCustomers(): JSX.Element {
  const bloc = useCreation(() => new ChargeYourCustomersBloc(), []);
  const store = getIt(PublishSubscriptionStore);
  const state = useStore(store).value;
  const urlParams: any = getQueryParams();
  const previewAppStore = getIt(PreviewAppStore);
  const previewState = useImagicaStore(previewStore).value;

  function resetStatus(): void {
    previewAppStore.updatePublishedMetadataV2({
      subscription_price: "1",
      subscription_free_period: "3",
      policy_url: "",
      policy_privacy_url: "",
      stripe_account_id: "",
      stripe_price_id: "",
      stripe_product_id: "",
      agreement_checked: false,
    });
    bloc.state.selectedChargeOption.value = ChargeOption.FREE;
    bloc.state.isAgreementChecked.value = false;
    bloc.state.removeCheckBox.value = false;
    bloc.state.price.value = "1";
    bloc.state.trialPeriod.value = "3";
    bloc.state.privacyLink.value = "";
    bloc.state.termsLink.value = "";
  }

  async function chargeOptionClicked(option: ChargeOption): Promise<void> {
    switch (option) {
      case ChargeOption.FREE:
        bloc.clickFree();
        break;
      case ChargeOption.SUBSCRIPTION:
        {
          bloc.clickSubscription();
          const res = await bloc.loadStripLink(previewStore.state.previewAppAcivieId);
          if (res) {
            previewAppStore.updatePublishedMetadataV2({ stripe_account_id: res });
          }
        }
        break;
    }
  }

  const changeActivedId = async (): Promise<void> => {
    const item = previewAppStore.previewAppActivePublishMetadataV2Item();
    if (!isBlank(item)) {
      if (item.charge_option === "Subscription") {
        store.showSubscriptionForm(true);
        bloc.clickSubscription();
        const res = await bloc.loadStripLink(previewStore.state.previewAppAcivieId);
        if (res) {
          previewAppStore.updatePublishedMetadataV2({ stripe_account_id: res });
        }
      }
      bloc.state.selectedChargeOption.value =
        ChargeOption[item.charge_option?.toUpperCase() as keyof typeof ChargeOption] || ChargeOption.FREE;
      bloc.state.isAgreementChecked.value = item.agreement_checked || false;
      if (bloc.state.isAgreementChecked.value) {
        bloc.state.removeCheckBox.value = true;
      }
      bloc.state.price.value = item.subscription_price || "";
      bloc.state.trialPeriod.value = item.subscription_free_period || "";
      bloc.state.privacyLink.value = item.policy_privacy_url || "";
      bloc.state.termsLink.value = item.policy_url || "";
    } else {
      store.showSubscriptionForm(false);
      resetStatus();
    }
  };

  useEffect(() => {
    changeActivedId();
  }, [previewState.previewAppAcivieId]);

  useEffect(() => {
    if (previewState.chargeOptionUpdated === "Free") {
      resetStatus();
    }
    ///
  }, [previewState.chargeOptionUpdated]);

  const handleConnectExpired = async (): Promise<void> => {
    if (urlParams?.type === "stripeConnectExpired") {
      bloc.clickSubscription();
      const res = await bloc.loadStripLink(urlParams?.appId);
      previewAppStore.updatePublishedMetadataV2({ stripe_account_id: res });
    }
  };

  useEffect(() => {
    handleConnectExpired();
  }, []);

  return (
    <PowerMaskView feature={["aaas_monetization"]} featureItem={"aaas_monetization"}>
      <div className={styles.chargeYourCustomers}>
        <div className={styles.sectionTitle}>CHARGE YOUR CUSTOMERS</div>
        <ChargeOptions
          selected={bloc.state.selectedChargeOption}
          loading={state.status === ReadyStatus.LOADING}
          onClicked={option => {
            chargeOptionClicked(option);
            previewAppStore.updatePublishedMetadataV2({ charge_option: option });
          }}
        />
        {bloc.state.selectedChargeOption.value === ChargeOption.SUBSCRIPTION && state.status !== ReadyStatus.LOADING ? (
          state.showSubscriptionForm ? (
            <>
              <ChargeAgreement
                selected={bloc.state.isAgreementChecked}
                removeCheckBox={bloc.state.removeCheckBox}
                onAgreementToggled={checked => {
                  bloc.toggleAgreement(checked);
                  previewAppStore.updatePublishedMetadataV2({ agreement_checked: checked });
                }}
              />
              <ChargeSubscription
                price={bloc.state.price}
                isChecked={bloc.state.isAgreementChecked.value}
                onPriceChanged={value => {
                  bloc.updatePrice(value);
                  previewAppStore.updatePublishedMetadataV2({ subscription_price: value });
                }}
                trialPeriod={bloc.state.trialPeriod}
                onTrialPeriodChanged={value => {
                  bloc.updateTrialPeriod(value);
                  previewAppStore.updatePublishedMetadataV2({ subscription_free_period: value });
                }}
                // copyright={bloc.state.copyright}
                // onCopyrightChanged={value => {
                //   bloc.updateCopyright(value);
                // }}
                termsLink={bloc.state.termsLink}
                onTermsLinkChanged={value => {
                  bloc.updateTermsLink(value);
                  previewAppStore.updatePublishedMetadataV2({ policy_url: value });
                }}
                privacyLink={bloc.state.privacyLink}
                onPrivacyLinkChanged={value => {
                  bloc.updatePrivacyLink(value);
                  previewAppStore.updatePublishedMetadataV2({ policy_privacy_url: value });
                }}
              />
            </>
          ) : (
            <ChargeActiveLink link={state.activeLink} />
          )
        ) : null}
      </div>
    </PowerMaskView>
  );
}
