import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import style from "./css/Response.module.css";
import func from "@uikit/func";
import HeaderList from "./HeaderList";
import ResponseContent from "./ResponseContent";

const failedReg = /^4|^5/;

export default function Response(props) {
  const [headerList, setHeaderList] = useState([]);

  const responesStatus = !func.isEmpty(props.responseData) ? (
    <div className={style["response-status"]} data-testid="response-status">
      <p>
        <span>Status: </span>
        <span
          data-testid="response-status-value"
          className={!failedReg.test(props.responseData.status) ? style["ok-status"] : style["failed-status"]}
        >
          {props.responseData.status}&nbsp;{props.responseData.statusText}
        </span>
      </p>
      <p>
        <span>Time: </span>
        <span>{props.responseData.time}</span>
      </p>
      <p>
        <span>Size: </span>
        <span>{props.responseData.size}</span>
      </p>
    </div>
  ) : null;
  const items = [
    {
      key: "bodyParam",
      label: "Body",
      children: <ResponseContent responseData={props.responseData} className="response-body" />,
    },
    {
      key: "headersParam",
      label: "Headers",
      children: <HeaderList isResponse={true} headerList={headerList} setHeaderList={setHeaderList} />,
    },
  ];

  useEffect(() => {
    const responseHeaders = props.responseData?.responseHeaders;
    if (func.isEmpty(responseHeaders)) return;
    const headerList = Object.keys(responseHeaders).map((x, index) => {
      return {
        key: `${index}_${x.toLowerCase()}`,
        name: x,
        value: responseHeaders[x],
      };
    });

    setHeaderList(headerList);
  }, [props.responseData?.responseHeaders]);

  return (
    <div className={style["response-box"]}>
      <Tabs defaultActiveKey="1" items={items} tabBarExtraContent={responesStatus} />
    </div>
  );
}
