import { XYPosition, getTransformForBounds } from "reactflow";
import { FocusOptions } from "./FocusOptions";
import { FocusPositionUtil } from "./FocusPositionUtil";
import { MoveToHandlerResult, MoveToOptions, FocusController } from "./FocusController";
import { GetFitViewResult } from "@uikit/util/HomeUsePluginUtil";
import { pick } from "lodash";
import { FotReactFlow } from "@uikit/model/FotReactFlow";

/**
 * 扩展了 reactflow, `fitBounts`, `setCenter` 的行为
 *
 * 1. 支持 fitBounts,setCenter Promise 调用(在这之前 useFocusNode 中有这样的需求, 需要在动画结束之后,并且同样的动画可能会重复执行)
 *  1.1 (setCenter) 可获取动画是否执行完成
 *  1.2 (setCenter) 重复的动画会被跳过
 *  1.3 TODO: fitBounds 执行回调还未实现
 * 2. 支持聚焦 canvas 中真实的 node 节点或 edge
 * 3. 支持 focus 和 autoFit 到 canvas 且可过滤掉右侧 preview panel 面板的宽度
 *
 * 注意:
 * reactflow 的 `fitBounds`,`setCenter`,`fitView`,`setViewport`,`zoomTo` 这些方法都能改变整个画布聚焦点和视口位置
 * 但是当改变视口位置时, zoom 会发生变化,能够改变视口位置或控制 zoom,目前只有 `fitBounds`,`setCenter` 两个方法
 * `fitBounds` 可以控制视口自适应,但不能控制 zoom
 * `setCenter` 可以控制 zoom和视口,但是不能自适应, 所以需要两个方法结合使用
 *
 */
export class FocusCore {
  controller: FocusController = new FocusController();
  constructor(protected fotReactFlow: FotReactFlow) {}

  /**
   * 创建一个新的 FlowOptions, 方便在不同情况下修改数据
   * @param props
   * @returns
   */
  static createFlowOptions(props?: Partial<FocusOptions>): FocusOptions {
    const options = new FocusOptions();
    return Object.assign(options, props);
  }

  resetPrevOptions(): void {
    this.controller.resetPrevOptions();
  }

  /**
   * 真实 dom 的坐标转成 canvas 中的坐标
   * @param element
   * @returns
   */
  domPotisionToCanvasPostion(position: XYPosition): XYPosition | undefined {
    return this.fotReactFlow.dependencies.reactFlowInstance?.project({ x: position.x, y: position.y });
  }

  /**
   * TODO: canvas 中的坐标转成真实的坐标
   * @param position
   */
  canvasPositionToDomPostion(position: XYPosition): XYPosition {
    console.warn("canvasPositionToDomPostion no implement");
    // const rect = element.getBoundingClientRect();
    // const movePostion = reactFlow.project({x: rect.x, y: rect.y})
    return position;
  }

  /**
   * 将视口聚焦到 canvas 上的指定位置
   *
   * **注意：不管 position 是真实 dom 坐标还是 canvas 上node坐标，最后都只是聚焦到 canvas 上，并不能作用到真实的其他 dom上**
   *
   * - 默认 duration:800, zomm:1
   * - 并返回 Promise
   *
   * 建议将 moveTo 修改为 focusTo
   *
   * TODO: 判断是否是重复动画，目前发现点击node add 按钮会出现 skip
   * @param flowOptions
   */
  moveTo(position: XYPosition, flowOptions?: MoveToOptions): Promise<MoveToHandlerResult> {
    const transform = getTransformForBounds(
      {
        width: window.innerWidth,
        height: window.innerHeight,
        ...position,
      },
      window.innerWidth,
      window.innerHeight,
      0.1,
      2
    );
    const composeOptions = this.controller.compose({
      ...flowOptions,
      position,
      transform,
      viewport: this.fotReactFlow.getViewport(),
      container: { width: window.innerWidth, height: window.innerHeight },
    });

    // 是否跳过
    if (this.controller.isSkip(composeOptions)) {
      return this.controller.skip(composeOptions);
    }

    const setCenter = flowOptions?.setCenter || this.fotReactFlow.setCenter;
    setCenter(position.x, position.y, composeOptions);

    return this.controller.finish(composeOptions);
  }

  /**
   * 将视口**适应**到 canvas 上的一个区域
   *
   * TODO: 当有大量节点，应该用其他方式适应 canvas 的视口
   *
   * - 默认 duration:800, zomm:1
   * - 并返回 Promise
   *
   * @param flowOptions
   */
  autoFitTo(fitviewRect: GetFitViewResult, flowOptions: FocusOptions): Promise<MoveToHandlerResult> {
    const position = pick(fitviewRect, "x", "y");
    const transform = getTransformForBounds(
      {
        width: window.innerWidth,
        height: window.innerHeight,
        ...position,
      },
      window.innerWidth,
      window.innerHeight,
      0.1,
      2
    );
    const composeOptions = this.controller.compose({
      ...flowOptions,
      zoom: fitviewRect.zoom || flowOptions.zoom,
      position,
      transform,
      viewport: this.fotReactFlow.getViewport(),
      container: { width: window.innerWidth, height: window.innerHeight },
    });

    // 是否跳过
    if (this.controller.isSkip(composeOptions)) {
      return this.controller.skip(composeOptions);
    }

    this.fotReactFlow.dependencies.reactFlowInstance?.fitBounds(fitviewRect, {
      duration: flowOptions.duration,
      // FIXME: 多节点时,padding 默认 0.15
      padding: flowOptions.padding !== 0.1 ? flowOptions.padding : 0.15,
    });

    if (flowOptions.zoom) {
      this.fotReactFlow.zoomTo(flowOptions.zoom, { duration: flowOptions.duration });
    }

    return this.controller.finish(composeOptions);
  }

  /**
   * 聚焦 canvas 视口到指定坐标
   * @param position
   * @param options
   * @returns
   */
  focus(position: XYPosition, options?: Partial<FocusOptions>): Promise<MoveToHandlerResult> {
    const flowOptions = FocusCore.createFlowOptions(options);

    // ======= 处理 showPreviewPanel 的情况
    if (flowOptions?.showPreviewPanel) {
      const diffX = FocusPositionUtil.getDistanceToPreviewPanel();
      // 聚焦时需要一个固定的 zoom
      flowOptions.zoom = 1;
      // 将 x 变成向左移动的坐标
      position.x = FocusPositionUtil.toLeft(position.x, diffX);
    }

    return this.moveTo(position, flowOptions);
  }
}
