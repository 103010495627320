import React from "react";
import "../components-css/Implication.css";

function Food(props) {
  return (
    <section className="CardImplication">
      <p className="title">{props.children}</p>
    </section>
  );
}

export default Food;
