"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoppingSearchResultDataService = void 0;
const CardDataBaseService_1 = require("../../cardDataSearch/dataSerivce/CardDataBaseService");
const FunctionRegistry_1 = require("../../../base/storyV2/function/FunctionRegistry");
const DataTransformerUtil_1 = require("../../cardDataSearch/dataSerivce/DataTransformerUtil");
class ShoppingSearchResultDataService extends CardDataBaseService_1.CardDataBaseService {
    constructor(functionRegistry) {
        super();
        this.functionRegistry = functionRegistry;
    }
    async search(params) {
        const findFunction = this.functionRegistry.getByRegistName(FunctionRegistry_1.FunctionName.amazonShopping);
        if (!findFunction) {
            return Promise.reject("can not find function");
        }
        const youtubeFunction = findFunction;
        const funcResponse = (await youtubeFunction.run(params.keyWords));
        const streamResponse = funcResponse.data;
        const funcDataSource = await streamResponse.promise();
        const wrapper = funcDataSource.value;
        const result = DataTransformerUtil_1.DataTransformerUtil.shoppingItemToVBShoppingSearchResult(wrapper.result);
        return result;
    }
}
exports.ShoppingSearchResultDataService = ShoppingSearchResultDataService;
