"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolPieceStore = void 0;
const Lazyload_1 = require("../cutil/Lazyload");
const StoreBase_1 = require("../cutil/StoreBase");
class ToolPieceStore extends StoreBase_1.StoreBase {
    constructor(brainClient) {
        super(() => []);
        this.brainClient = brainClient;
        this.lazyload = new Lazyload_1.Lazyload(() => {
            return this.brainClient.openAi.getToolPiecesList().then(result => {
                const state = result.data.toolpieces;
                this.emit(state);
                return state;
            });
        });
    }
    async get() {
        return this.lazyload.get();
    }
    async renew() {
        await this.lazyload.renew();
    }
}
exports.ToolPieceStore = ToolPieceStore;
