"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioProjectAttributesV2UIOutputData = exports.Code = exports.StudioProjectAttributesV2UIOutputResultsContent = exports.StudioProjectAttributesV2UIOutputResults = exports.StudioProjectAttributesV2UIOutput = void 0;
const class_transformer_1 = require("class-transformer");
require("reflect-metadata");
class StudioProjectAttributesV2UIOutput {
}
exports.StudioProjectAttributesV2UIOutput = StudioProjectAttributesV2UIOutput;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UIOutputResults)
], StudioProjectAttributesV2UIOutput.prototype, "results", void 0);
class StudioProjectAttributesV2UIOutputResults {
    constructor() {
        this.nodeId = "";
    }
}
exports.StudioProjectAttributesV2UIOutputResults = StudioProjectAttributesV2UIOutputResults;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UIOutputResultsContent)
], StudioProjectAttributesV2UIOutputResults.prototype, "content", void 0);
class StudioProjectAttributesV2UIOutputResultsContent {
}
exports.StudioProjectAttributesV2UIOutputResultsContent = StudioProjectAttributesV2UIOutputResultsContent;
__decorate([
    (0, class_transformer_1.Type)(() => Code)
], StudioProjectAttributesV2UIOutputResultsContent.prototype, "code", void 0);
class Code {
}
exports.Code = Code;
class StudioProjectAttributesV2UIOutputData {
}
exports.StudioProjectAttributesV2UIOutputData = StudioProjectAttributesV2UIOutputData;
