import React, {
  CSSProperties,
  // JSX,
  // ReactElement,
  // ReactNode,
  // Ref,
  // useCallback,
  // useContext,
  // useEffect,
  useRef,
} from "react";
import styles from "./AccountPage.module.scss";
import isBlank from "@sedan-utils/is-blank";
import classNames from "classnames";
import { Modal, Spin, Upload } from "antd";
import { UploadState } from "@uiview/pages/profile/account/AccountData";
import { VerifyNow } from "@uiview/pages/profile/account/view/VerifyNow";
import { RcFile } from "antd/lib/upload";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { useCreation } from "ahooks";
import { AccountPageBloc, AccountPageState } from "@uiview/pages/profile/account/AccountPageBloc";
import { AuthCodeView } from "@uiview/pages/profile/account/view/AuthCodeView";
import { AccountAlert } from "@uiview/pages/profile/account/view/AccountAlert";
import style from "@uiview/pages/profile/account/view/AuthCodeView.module.scss";
import { DeleteAccountAlert } from "./view/DeleteAccountAlert";
import { logEvent } from "@uikit/service/amplitude";
import func from "@uikit/func";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";

const s3Path = `${settings.S3CDN}${settings.viewAssetsPath}`;
const placeHolderIcon = `${s3Path}myaccount_icon_placeholder.svg`;
export const inputInValidIcon = `${s3Path}myaccount_input_invalid.svg`;
export const deleteIcon = `${s3Path}myaccount_delete.svg`;
export function AccountPage(): JSX.Element {
  const state = new AccountPageState();
  const bloc = useCreation(() => new AccountPageBloc(state), []);
  const nameEditData = bloc.state.nameEdit;
  const emailEditData = bloc.state.emailEdit;
  const headerData = bloc.state.headerData;
  const bottomData = bloc.state.bottomData.value;
  const fotProcessManager = FotProcessManager.use();

  return (
    <div className={styles.container}>
      <IconHeader
        deleteLoading={headerData.deleteLoading.value}
        progress={headerData.state.value[1]}
        loading={
          headerData.state.value[0] === UploadState.loading || headerData.state.value[0] === UploadState.progress
        }
        icon={headerData.icon.value}
        typeDescription={headerData.typeDescription.value}
        clickDeleteBtn={() => {
          console.log("handle tap delete btn");
          logEvent("my_account_click_delete_image_button");
          bloc?.tapDeleteIcon();
        }}
        clickUpdateBtn={file => {
          // file.size使用MB，800k等于0.78125MB
          const isLt800K = file.size / 1024 / 1024 <= 0.78125;
          if (!isLt800K) {
            func.customMsg({
              content: "Image should be no more than 800K!",
              type: "warning",
            });
          } else {
            bloc?.uploadIcon(file);
          }
          return isLt800K;
          // bloc?.uploadIcon(file);
        }}
      />
      <EditView
        showLoading={nameEditData.loading.value}
        name={nameEditData.title.value}
        placeHolder={nameEditData.placeHolder.value}
        inputValue={nameEditData.value.value}
        errorText={nameEditData.errorText.value}
        onBlur={() => {
          bloc?.onBlur(nameEditData.title.value);
        }}
        tapEnter={() => {
          bloc?.tapNameEnter(nameEditData.title.value);
        }}
        onChange={text => {
          bloc?.textOnChange(nameEditData.title.value, text);
        }}
      ></EditView>
      <EditView
        showLoading={emailEditData.loading.value}
        style={{ flex: "1" }}
        name={emailEditData.title.value}
        placeHolder={emailEditData.placeHolder.value}
        inputValue={emailEditData.value.value}
        errorText={emailEditData.errorText.value}
        onBlur={() => {
          bloc?.onBlur(emailEditData.title.value);
        }}
        tapEnter={() => {
          bloc?.tapNameEnter(emailEditData.title.value);
        }}
        onChange={text => {
          bloc?.textOnChange(emailEditData.title.value, text);
        }}
      ></EditView>
      {bloc.state.showVerifyNow.value && <VerifyNow bloc={bloc} />}

      <BottomView
        isEnable={bottomData.isEnable}
        deleteLoading={bottomData.deleteLoading}
        saveLoading={bottomData.updateLoading || emailEditData.loading.value}
        clickDelete={() => {
          bloc?.tapDeleteAccount();
        }}
        clickSave={() => {
          bloc?.tapSavesChanges();
        }}
      />
      {bloc.state.showOtpAlert.value && (
        <AuthCodeView
          sendAgainLoading={false}
          otpLoading={false}
          alertData={bloc.state.showOtpAlert.value}
          otpInputComplete={otp => {
            bloc.otpInputComplete(otp);
          }}
          close={() => {
            bloc.state.showOtpAlert.value = null;
          }}
          sendAgain={() => {
            bloc.otpSendAgain();
          }}
          count={bloc.state.showOtpAlert.value.otp.value}
        />
      )}
      {bloc.state.showAlert.value && (
        <Modal
          title=""
          maskClosable={false}
          keyboard={false}
          open={true}
          wrapClassName={style.myAccountVerifyModal}
          footer={[]}
          centered={true}
        >
          <AccountAlert
            title={bloc.state.showAlert.value.title}
            detail={bloc.state.showAlert.value.detail}
            tapDone={() => {
              bloc.state.showAlert.value = null;
            }}
          ></AccountAlert>
        </Modal>
      )}
      {bloc.state.showDeleteAccountAlert.value && (
        <DeleteAccountAlert
          deleteLoading={bloc.state.deleteLoading.value}
          alertData={bloc.state.showDeleteAccountAlert.value}
          tapSureDelete={() => {
            bloc.tapSureDeleteAccount();
          }}
          confirmDelete={code => {
            bloc.deleteAccount(code).then(() => {
              logEvent("delete_account");
              fotProcessManager.logout();
            });
          }}
          confirmClose={() => {
            bloc.state.showDeleteAccountAlert.value = null;
          }}
          sendAgain={() => {
            bloc.getDeleteAccpuntOTP();
          }}
        ></DeleteAccountAlert>
      )}
      {bloc.isLoading.value && (
        <Modal
          title=""
          maskClosable={false}
          keyboard={false}
          open={true}
          wrapClassName={styles.loaderModal}
          footer={[]}
          centered={true}
          closeIcon={<></>}
        >
          <div className={styles.loader}>
            <Spin spinning={true}></Spin>
          </div>
        </Modal>
      )}
    </div>
  );
}

/**
 * 头像组件
 * @constructor
 */
function IconHeader(props: {
  icon: string;
  typeDescription: string;
  clickUpdateBtn: (file: RcFile) => void;
  clickDeleteBtn: () => void;
  loading: boolean;
  progress: string;
  deleteLoading: boolean;
}): JSX.Element {
  let updateBtn = isBlank(props.icon) ? "Upload" : "Change";
  if (props.loading) {
    const progress = Number(props.progress);
    updateBtn = `... ${Math.floor(progress * 100 * 100) / 100}%`;
  }

  return (
    <div className={styles.header}>
      <img className={styles.img} src={isBlank(props.icon) ? placeHolderIcon : props.icon}></img>
      <div className={styles.right}>
        <div className={styles.buttonContaer}>
          {
            <Upload
              accept={".png, .jpg, .jpeg, image/jpeg"}
              maxCount={1}
              showUploadList={false}
              disabled={props.deleteLoading || props.loading}
              beforeUpload={file => {
                props.clickUpdateBtn(file);
              }}
            >
              <button type="button" className={styles.updateBtn}>
                {updateBtn}
              </button>
            </Upload>
          }

          {!isBlank(props.icon) && (
            <button type="button" className={styles.deleteBtn} onClick={() => props.clickDeleteBtn()}>
              <img src={deleteIcon} />
            </button>
          )}
        </div>
        <div className={styles.description}>{props.typeDescription}</div>
      </div>
    </div>
  );
}

/**
 * 输入框组件
 * @constructor
 */
function EditView(props: {
  showLoading: boolean;
  key?: string;
  name: string;
  inputValue: string;
  onBlur: () => void;
  placeHolder: string;
  errorText?: string;
  tapEnter?: () => void;
  className?: string;
  onChange?: (text: string) => void;
  setInputRef?: (input: HTMLInputElement) => void;
  style?: CSSProperties;
}): JSX.Element {
  const inputRef = useRef<HTMLElement | null>(null);
  const inputClass = classNames(styles.editName, styles.edit, isBlank(props.errorText) ? "" : styles.error);
  return (
    <div key={props.key} className={styles.editContainer} style={props.style}>
      <div className={styles.title}>{props.name}</div>
      <div style={{ position: "relative" }}>
        <input
          value={props.inputValue}
          className={inputClass}
          placeholder={props.placeHolder}
          onBlur={() => props.onBlur()}
          onKeyUp={e => {
            if (e.code === "Enter" && props.tapEnter) {
              props.tapEnter();
              inputRef.current?.blur();
            }
          }}
          onChange={e => props.onChange && props.onChange(e.target.value)}
          ref={ref => {
            if (props.setInputRef && ref) {
              props?.setInputRef(ref);
            }
            inputRef.current = ref;
          }}
        />
        <img
          src={inputInValidIcon}
          className={styles.editIcon}
          style={{ visibility: !isBlank(props.errorText) ? "visible" : "hidden" }}
        />
      </div>
      {!isBlank(props.errorText) && <div className={styles.errorText}> {props.errorText} </div>}
    </div>
  );
}

/**
 * 底部组件
 * @constructor
 */
function BottomView(props: {
  isEnable: boolean;
  deleteLoading?: boolean;
  saveLoading?: boolean;
  clickDelete?: () => void;
  clickSave?: () => void;
}): JSX.Element {
  const saveClass = props.isEnable ? styles.save : styles.disableSave;
  return (
    <div className={styles.bottom}>
      <button className={styles.delete} onClick={() => props.clickDelete && props.clickDelete()}>
        Delete account
      </button>
      <button
        className={saveClass}
        onClick={() => props.isEnable && !props.saveLoading && props.clickSave && props.clickSave()}
      >
        Save changes
      </button>
    </div>
  );
}
