"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayUtil = void 0;
class ArrayUtil {
    static deDuplication(array, filter) {
        let newArray = [];
        for (let i = 0; i < array.length; i++) {
            let index = undefined;
            const oldItem = array[i];
            for (let j = 0; j < newArray.length; j++) {
                let newArrayItem = newArray[j];
                if (filter(newArrayItem) === filter(oldItem)) {
                    index = j;
                    break;
                }
            }
            if (index !== undefined) {
                newArray[index] = oldItem;
            }
            else {
                newArray.push(oldItem);
            }
        }
        return newArray;
    }
    static contains(array, object, filter) {
        for (const element of array) {
            if (filter(element) === filter(object)) {
                return true;
            }
        }
        return false;
    }
}
exports.ArrayUtil = ArrayUtil;
