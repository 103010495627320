import { useCallback, useRef } from "react";
import { useMemo } from "react";
import { createContainer } from "unstated-next";
import { ConverterUtil } from "./_util";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { getIt } from "@uikit/getIt";
import { previewStore } from "@uiview/store/PreviewStore";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";

// eslint-disable-next-line
function useAiSaasOutput(props?: AISaasOutput.AiSaasOutputListProps) {
  const { thoughtOptions = [], onChangeThoughts } = props || {};
  const homePluginStore = getIt(HomePluginStore);
  const previewState = useImagincStore(previewStore).value;

  const isShare = homePluginStore.state.isShare;
  // 原来 sortableItem disable 第二个条件
  const disabledDnd = isShare || previewState.isClickAddToContent;

  const outputsRef = useRef<{
    [key: string]: HTMLDivElement;
  }>({});

  const dndThoughts: SortableList.SortableData<AISaasOutput.DndThoughtWithGroupType>[] = useMemo(() => {
    return ConverterUtil.toDndData(thoughtOptions).map(item => {
      return {
        id: item.id,
        data: item,
      };
    });
  }, [thoughtOptions]);

  const onChange = useCallback(
    (options: SortableList.SortableData<AISaasOutput.DndThoughtWithGroupType>[]) => {
      const newOptions = ConverterUtil.toAiSaasThoughts(
        options.map(item => {
          return {
            id: item.id,
            groupDnd: item.data.groupDnd,
          };
        })
      );

      onChangeThoughts?.(newOptions);
    },
    [onChangeThoughts]
  );

  return {
    ...props,
    dndThoughts,

    onChange,
    isShare,
    disabledDnd,
    outputsRef,
  };
}

/**
 * AiSaasOutput 容器
 *
 * - 只要是 AiSaasOutput 组件子节点都可以通过 `useAiSaasOutput` 得到内部数据和逻辑，可避免深度传递
 *
 * @returns
 */
export const AiSaasOutputContainer = createContainer(useAiSaasOutput);

export const { Provider: AiSaasOutputProvider, useContainer: useAiSaasOutputContainer } = AiSaasOutputContainer;

// container components
export { default as AiSaasOutputList } from "./List";
