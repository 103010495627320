import styles from "./ChargeSubscription.module.scss";
import { Signal, useSignal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { Input } from "antd";

export interface Props {
  price: Signal<string>;
  onPriceChanged: (value: string) => void;

  trialPeriod: Signal<string>;
  onTrialPeriodChanged: (value: string) => void;

  // copyright: Signal<string>;
  // onCopyrightChanged: (value: string) => void;

  termsLink: Signal<string>;
  onTermsLinkChanged: (value: string) => void;

  privacyLink: Signal<string>;
  onPrivacyLinkChanged: (value: string) => void;
  isChecked: boolean;
}

export function ChargeSubscription(props: Props) {
  const isShowPricePrifix = useSignal<boolean>(!isBlank(props.price.value));
  const onPriceChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const reg = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (reg.test(value) || value === "" || value === "0" || value === "0.0") {
      props.onPriceChanged(e.target.value);
    }
  };

  const onPriceBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    isShowPricePrifix.value = !isBlank(props.price.value);
  };

  const onPriceFoucs = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    isShowPricePrifix.value = true;
  };

  const onTrialPeriodChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const reg = /^[1-9]\d*$/;

    if (reg.test(value) || value === "" || value === "0") {
      if (Number(e.target.value) > 7) {
        props.onTrialPeriodChanged("7");
        return;
      }
      props.onTrialPeriodChanged(e.target.value);
    }
  };

  const getPublishFee = () => {
    const publishPrice = Number(props.price.value);
    if (props.price.value === "" || publishPrice === 0) {
      return "0.00";
    }
    return (publishPrice * 0.3).toFixed(2);
  };

  return (
    <div className={styles.container}>
      <div className={styles.settings}>
        <div className={styles.title}>Settings</div>
        {/** price */}
        <div className={styles.subtitle}>Subscription Price</div>
        <div className={styles.inputWrap}>
          <Input
            className={styles.input}
            disabled={!props.isChecked}
            prefix={isShowPricePrifix.value ? "$" : " "}
            suffix={"Per Month"}
            placeholder="Minimum price $1"
            allowClear
            onFocus={e => onPriceFoucs(e)}
            onBlur={e => onPriceBlur(e)}
            value={props.price.value}
            onChange={e => onPriceChanged(e)}
          />
        </div>
        <div className={styles.publishFee}>30% publishing fee of $ {getPublishFee()}</div>
        {/** trial */}
        <div className={styles.subtitle}>Free trial period</div>
        <div className={styles.inputWrap}>
          <Input
            className={styles.input}
            disabled={!props.isChecked}
            placeholder="Maximum 7 days"
            suffix={"Days"}
            allowClear
            value={props.trialPeriod.value}
            onChange={e => onTrialPeriodChanged(e)}
          />
        </div>
      </div>
      {/* <div className={styles.privacy}>
        <div className={styles.title}>Terms of use</div>
        <div className={styles.subtitle}>Add a link to your own terms of service</div>
        <Input
          className={styles.input}
          placeholder="https://apps.imagica.ai/tos/"
          allowClear
          value={props.termsLink.value}
          onChange={e => {
            props.onTermsLinkChanged(e.target.value);
          }}
        />
        <Input
          className={styles.input}
          placeholder="https://apps.imagica.ai/privacy/"
          allowClear
          value={props.privacyLink.value}
          onChange={e => {
            props.onPrivacyLinkChanged(e.target.value);
          }}
        />
      </div> */}
    </div>
  );
}
