import { useDispatch, useSelector } from "react-redux";
import { fotActions } from "../../store/fot";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

export default function ChainThoughtIcon(props) {
  const dispatch = useDispatch();

  const thoughtChain = useSelector(state => state.fot.thoughtChain);
  const setChainThoughtsState = val => {
    dispatch(fotActions.setChainThoughtsState(val));
  };

  return thoughtChain.length > 0 ? (
    <div className="Chain-Thought-Icon" onClick={() => setChainThoughtsState(true)}>
      <span>{thoughtChain.length}</span>
      <img src={`${settings.S3CDN}${settings.viewAssetsPath}down-arrow.svg`} />
    </div>
  ) : null;
}
