"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayPlanPrice = exports.PayPlanPriceRecurring = exports.PayPlan = exports.PayPlanMetadata = void 0;
class PayPlanMetadata {
    constructor() {
        this.name = "";
        this.purpose = "";
        this.value = "";
    }
}
exports.PayPlanMetadata = PayPlanMetadata;
class PayPlan {
    constructor() {
        this.id = ""; // product id
        this.object = "";
        this.active = false;
        this.attributes = [];
        this.default_price = ""; // default price id
        this.description = "";
        this.features = [];
        this.images = [];
        this.livemode = false;
        this.metadata = new PayPlanMetadata();
        this.name = "";
        this.type = "";
    }
}
exports.PayPlan = PayPlan;
class PayPlanPriceRecurring {
    constructor() {
        this.interval = "";
        this.interval_count = 0;
        this.usage_type = "";
    }
}
exports.PayPlanPriceRecurring = PayPlanPriceRecurring;
class PayPlanPrice {
    constructor() {
        this.id = "";
        this.object = "";
        this.active = false;
        this.billing_scheme = "";
        this.currency = "";
        this.livemode = false;
        this.metadata = {};
        this.product = "";
        this.tax_behavior = "";
        this.type = "";
        this.recurring = new PayPlanPriceRecurring();
        this.unit_amount = 0;
        this.unit_amount_decimal = "";
    }
}
exports.PayPlanPrice = PayPlanPrice;
