import React from "react";
import { Button } from "antd";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import style from "./GoogleOauthButton.module.css";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import useGoogleOauth from "@custom-hooks/useGoogleOauth";

class Prop {
  page?: string = "signIn";
  enableOneTap: boolean = true;
}

export const GoogleOauthButton = React.memo((props: Prop) => {
  const { handleGoogleSignInSuccess, errorMessage, buttonLoading, setButtonLoading } = useGoogleOauth(props);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async codeResponse => {
      handleGoogleSignInSuccess(codeResponse);
    },
    onError: errorResponse => errorMessage(errorResponse),
  });

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setButtonLoading(true);
          googleLogin();
        }}
        loading={buttonLoading}
        className={style["log-in-button"]}
        icon={
          <img src={`${settings.S3CDN}${settings.viewAssetsPath}google-logo.svg`} className={style["google-icon"]} />
        }
      >
        {props.page !== "signUp" ? "Sign in" : "Sign up"} with Google
      </Button>
      {props.enableOneTap && (
        <div style={{ display: "none" }}>
          <GoogleLogin
            onSuccess={handleGoogleSignInSuccess}
            onError={errorMessage}
            useOneTap
            context={props.page !== "signUp" ? "signin" : "signup"}
            cancel_on_tap_outside={false}
          />
          ;
        </div>
      )}
    </>
  );
});
