import classNames from "classnames";
import { PreviewPaneBloc } from "../../PreviewPaneBloc";
import styles from "./Appearance.module.scss";
import { TextStyle } from "./TextStyle/TextStyle";
import { DesignTemplate } from "./DesignTemplate";
import { FontStyle } from "./FontStyle";
import { matchMobile } from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";

export function Appearance(props: { bloc: PreviewPaneBloc }): JSX.Element {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;

  return (
    <div
      className={classNames(styles.Appearance, {
        [styles.AppearanceMobile]: isMobile && featureTags.disableMobilePage,
      })}
    >
      <div className={styles.title}>APPEARANCE</div>
      {!(isMobile && featureTags.disableMobilePage) && <TextStyle />}
      <DesignTemplate bloc={props.bloc} />
      <FontStyle bloc={props.bloc} />
    </div>
  );
}
