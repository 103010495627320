export function NavBarCanvasIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.3" y="2" width="18" height="16" rx="3" stroke="currentColor" strokeWidth="1.5" />
      <path d="M1 7H19" stroke="currentColor" strokeWidth="1.5" />
      <path d="M6 14H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6 11H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
