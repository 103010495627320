import { BaseDataRef } from "./BaseDataRef";

export class NewEdgeDataRefState {
  isGetQuery = false;
  getQueryLoading = false;
  isCreatedBySlice = false;
  isSliceAutoRun = false;
  isApiFunction = false;
  isUndoRedoAdd = false;
  isFromSaveData = false;
  showBuilderUI = false;
  originSource = "";
  originTarget = "";
  sourceHandle = "b";
  targetHandle = "a";
  selectedApi = {};
  lineParam = {
    id: "",
    loading: false,
    description: "",
    functionDescription: "",
    queryValue: "",
    enterText: "",
    lineType: "identifier",
    identifier: {},
    createFromCot: false,
    // 用来禁用边上的 run 按钮
    disabledRun: false,
  };
  textAreaValue = "";
  targetNodeId = "";
  targetLineId = "";
  flows = [] as any[];
}

/**
 * 原来 HomeUsePlugin 中 newEdgeDataRef 的数据结构
 */
export class NewEdgeDataRef extends BaseDataRef<NewEdgeDataRefState> {
  constructor() {
    super(new NewEdgeDataRefState());
  }

  /**
   * 用于兼容老版本的 `newEdgeDataRef`
   *
   * @deprecated 分开使用 state 和 functions
   */
  get newEdgeDataRef(): NewEdgeDataRefState {
    return this.current;
  }
}
