import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useSignal } from "@preact/signals-react";
import { map } from "lodash";
import styles from "./Sortable.module.scss";

export function Sortable<T extends { id: string }>(props: {
  value: T[];
  onChange: (data: T[]) => void;
  renderItem: (data: T, index: number) => React.ReactElement;
}) {
  const activeId = useSignal(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = (event: any) => {
    const { active } = event;
    if (active) {
      activeId.value = active.id;
    }
  };

  const handleDragEnd = (event: any) => {
    const { over } = event;

    if (over) {
      const oldIndex = props.value.findIndex(item => item.id === activeId.value);
      const newIndex = props.value.findIndex(item => item.id === over.id);

      const newItems = arrayMove(props.value, oldIndex, newIndex);
      props.onChange(newItems);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={props.value}>
        {map(props.value, (item, index) => (
          <SortableItem key={item.id} id={item.id}>
            {props.renderItem(item, index)}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
}

function SortableItem(props: { id: string; children: React.ReactElement }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className={styles.sortable}>
      <div className={styles.dragBar}>
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}></div>
      </div>
      {props.children}
    </div>
  );
}
