import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import isBlank from "@sedan-utils/is-blank";
import { getCollapseBluePrintNode } from "@uiview/views/Nodes/BluePrintNode/BluePrintUtil";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useStore as reduxStore } from "react-redux";
import { useStore as reactflowStore } from "reactflow";
import { useIsStaticApp, useNodePreview, useStartNode } from "../../custom-hooks/useNodePreview";
import { logEvent } from "@uikit/service/amplitude";
import func from "@uikit/func";
import style from "./css/NodeToolBar.module.scss";
import { get } from "lodash";
import { Action } from "@uiview/views/NodeToolBar/Action";
import { PreviewAppUtil } from "@uiview/views/PreviewApp/PreviewAppUtil";
import { HomeStore, GroupCOEStatus } from "imagica-corekit/dist/cases/store/HomeStore";
import { getIt } from "@uikit/getIt";
import { TooltipMessage } from "@uiview/views/TooltipMessage/TooltipMessage";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { feedBluePrintMessage } from "@uiview/views/Nodes/BluePrintNode/BluePrintUtil";
import { eventbus } from "imagica-corekit/dist/base/cutil/Eventbus";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { BlueprintBuildMsg } from "imagica-corekit/dist/base/msg/BlueprintBuildMsg";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { useSignal } from "@preact/signals-react";
import { Popover } from "antd";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { useHomePublicMethods } from "@uiview/views/ProjectCanvas/useHomeUtilMethods";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { getExpandedBluePrintNodes } from "@uiview/views/Nodes/BluePrintNode/BluePrintUtil";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import { CreatorCanvasDeleteMethods } from "@uikit/service/CreatorCanvasDeleteService";
import { previewStore } from "@uiview/store/PreviewStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { useAModalProvider } from "@uikit/context/AModalContext/useAModalProvider";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { ClickSlice } from "@uikit/service/ClickSlice";

const groupReg = /^group/;
const enableSplitType = ["text", "inputTextarea"];
const enableShrinkAndExpandTypes = ["text", "inputTextarea", "askBrain"];
const enableCopyTextType = ["text", "inputTextarea", "askBrain"];
const shrinkAndExpand = [
  {
    className: "optShrink",
    value: "Fit to size",
    label: "FitSize",
  },
  {
    className: "optCollapse",
    value: "Reset size",
    label: "ResetSize",
  },
];
export const collapseOrExpand = [
  {
    className: "optExpand",
    value: "Expand",
    label: "Expand",
  },
  {
    className: "optCollapse",
    value: "Collapse",
    label: "Collapse",
  },
];
export const AddNodeConfig = (isInput, hasAdded) => {
  let config = {
    text: "",
    iconClass: "",
  };
  if (isInput) {
    config.text = hasAdded ? "Remove from preview" : "Publish as Input";
  } else {
    config.text = hasAdded ? "Remove from preview" : "Publish as Content";
  }
  config.iconClass = hasAdded ? "tool-bar-item-sta-remove" : "tool-bar-item-sta-add";
  return config;
};

export default function NodeToolBar(props) {
  const {
    id,
    handlePreviewDeleteNode,
    clickToPreviewNode,
    displayType,
    fitItemStatus,
    onChangeShowBluePrintFeedbackModal,
  } = props;

  const store = reduxStore();

  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const homeStore = getIt(HomeStore);
  const previewAppStore = getIt(PreviewAppStore);
  const clickSlice = getIt(ClickSlice);
  const homeStoreState = useStore(homeStore).value;
  const currGroupCOEData = homeStoreState.groupCOEData.find(x => x.groupId === props.id) || {
    groupId: "",
    groupCOEStatus: GroupCOEStatus.Expand,
    collapseChildNodes: [],
  };
  // FIXME:nodesRef
  const nodesRef = useMemo(
    () => ({
      get current() {
        return creatorNodesStore.getNodes();
      },
    }),
    []
  );
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorCanvasDeleteMethods = getIt(CreatorCanvasDeleteMethods);
  const creatorPreviewService = getIt(CreatorPreviewService);
  const homeHandleFieldMethods = getIt(HomeHandleFieldMethods);
  const previewComposeState = getIt(PreviewComposeState);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const triggerNodeChanges = reactflowStore(state => state.triggerNodeChanges);
  const previewState = useImagicaStore(previewStore).value;
  const appStates = useMemo(() => {
    return previewComposeState.appStates();
  }, [previewState.previewAppAcivieId, saasUIData]);
  const canvasDataRef = getIt(CanvasDataRef);

  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const { isStartNode, isStartNodeAdded } = useStartNode(id);
  const { handlePreview } = useNodePreview({ id, handlePreviewDeleteNode, clickToPreviewNode });

  const [, setSliceLoading] = useState(false);

  const enableDesignSpaceFeature = useSelector(state => state.fot.enableDesignSpaceFeature);

  const featureTags = homeStoreState.featureTags;

  const { isStaticApp } = useIsStaticApp();

  const [hasAdded, setHasAdded] = useState(false);
  const showBrush = useSignal(true);

  /// 是否是Group内部子Node顶部
  const isChildNode = props.data.parentNodeId !== "";
  const homePublicMethods = useHomePublicMethods();
  const { showModal } = useAModalProvider();

  useEffect(() => {
    if (isStartNode && !isStaticApp) {
      setHasAdded(isStartNodeAdded);
    } else if (groupReg.test(id)) {
      const thoughtsArr = saasUIData.output.map(x => x?.groupId);
      setHasAdded(thoughtsArr.includes(id));
    } else {
      let thoughtsArr = [];
      thoughtsArr = saasUIData.output
        .filter(x => !x?.groupId)
        .map(x => (x?.results || []).map(r => r.nodeId))
        ?.flat(Infinity);
      setHasAdded(thoughtsArr.includes(id));
    }
  }, [id, isStartNodeAdded, saasUIData.output]);

  const clickSendToApp = async () => {
    if (isStartNode && !isStaticApp) {
      const addInputFunc = () => {
        const nodes = creatorNodesStore.getNodes();
        const node = nodes.find(item => item.id === id);
        // 如果是v2版本且不显示preview page页面时，如果已经有了，就不删除，不添加
        const inputIds = store.getState().fot.inputId;
        const hasInput = inputIds.includes(node?.id);
        if (!previewAppStore.state.previewShowStatus && hasInput) return;

        homeHandleFieldMethods.addOrRemoveInputFeild(node);
      };
      if (
        previewAppStore.handleSendToAppV2({
          cb: addInputFunc,
          outputCb: handlePreview,
          showPreviewContent: homePublicMethods.showPreviewContent,
        })
      )
        return;

      addInputFunc();
    } else {
      if (
        previewAppStore.handleSendToAppV2({
          cb: handlePreview,
          showPreviewContent: homePublicMethods.showPreviewContent,
        })
      )
        return;
      handlePreview();
    }

    if (hasAdded) {
      return;
    }

    const selectedTemplate = newOnboardingFlowStore.state.templateList
      .flatMap(obj => obj.list)
      .find(item => item.selected);
    logEvent("node_publish_content");
    if (isBlank(selectedTemplate)) return;
    logEvent("entrypoint_format", { format: selectedTemplate.value, event: selectedTemplate.eventName });
  };

  const clickSliceIcon = async () => {
    if (func.isEmpty(props.data.textAreaValue)) {
      func.customMsg({
        content: "Content is empty",
        type: "warning",
      });
      return;
    }
    try {
      setSliceLoading(true);
      await clickSlice.clickSliceBtn(props.nodeAttr.id);

      const input = props.data.textAreaValue.hasOwnProperty("answer")
        ? props.data.textAreaValue.answer
        : props.data.textAreaValue;
      logEvent("shard_node", {
        input: input,
      });
    } catch (error) {
    } finally {
      setSliceLoading(false);
    }
  };
  const clickResizeNode = e => {
    e.stopPropagation();
    props.onShrinkAndExpand(shrinkAndExpand[fitItemStatus].label);
  };
  const clickCopyNode = e => {
    e.stopPropagation();
    if (
      props.data.displayType === "text" &&
      func.isEmpty(props.data.textAreaValue) &&
      props.nodeAttr.type !== "customGroup"
    ) {
      func.customMsg({
        content: "Content is empty",
        type: "warning",
      });
      return;
    }
    let arr = [props.nodeAttr.id];
    if (props.nodeAttr.type === "customGroup") {
      arr = [...arr, ...props.data.childNode];
    }
    creatorStoreMethods.copyNode(arr, true);
    func.customMsg({
      content: "Copied",
      type: "info",
    });
  };
  const clickCopyText = () => {
    const txt = props.data.textAreaValue.hasOwnProperty("answer")
      ? props.data.textAreaValue.answer
      : props.data.textAreaValue;
    if (func.isEmpty(txt)) {
      func.customMsg({
        content: "Content is empty",
        type: "warning",
      });
      return;
    }
    navigator.clipboard.writeText(txt);
    func.customMsg({
      content: "Copied",
      type: "info",
    });

    logEvent("node_copy_text", {
      input: txt,
    });
  };
  const clickDeleteNode = () => {
    // stud-1639: delete node
    /**
     *
     * @type {PreviewApp.RemovePreviewAppNodeArgs}
     */
    const parmas = {
      ids: ReactFlowNodeUtil.getSubNodeIds(creatorNodesStore.getNodes(), props.nodeAttr.id),
      onSuccess() {
        creatorCanvasDeleteMethods.handleDeleteNodes([{ id: props.nodeAttr.id }]);
      },
      onOpenModal({ subTitle, removeIds }) {
        showModal({
          title: "Warning",
          width: 520,
          subTitle,
          buttons: ["Cancel", "Confirm"],
          onOk: ({ close }) => {
            parmas.onSuccess(removeIds);
            previewStore.removeByNodeIds(removeIds);
            close();
          },
        });
      },
    };
    creatorPreviewService.triggerRemovePreviewAppNode(parmas);
  };

  const handleClickBlueprint = enableBlueprintCollapse => {
    if (enableBlueprintCollapse) {
      const { nodes, changedTypeNodeId } = getCollapseBluePrintNode({ id, nodesRef });
      creatorNodesStore.setNodes(nodes);
      if (changedTypeNodeId) {
        setTimeout(() => {
          triggerNodeChanges([
            {
              id: changedTypeNodeId,
              type: "dimensions",
              updateStyle: true,
              resizing: true,
              dimensions: {
                width: 400,
                height: 400,
              },
            },
          ]);
          setTimeout(() => {
            triggerNodeChanges({
              id: id,
              type: "dimensions",
              resizing: false,
            });
          });
        });
      }
    } else {
      // 兼容旧数据props.data.textAreaValue
      const bluePrintValue = props.data.textAreaValue.data ?? props.data.textAreaValue.data;
      const nodes = getExpandedBluePrintNodes({
        id,
        bluePrintValue,
        nodeDataRef: canvasDataRef.nodeDataRef,
        nodeIndexRef: creatorRefStore.nodeIndexRef,
        nodesRef,
      });
      creatorNodesStore.setNodes(nodes);
    }
  };

  const textAreaValue = props.data.textAreaValue?.data ?? props.data.textAreaValue;

  const previewV2ShowToolbar = !previewAppStore.isInHistory();
  const addConfig = AddNodeConfig(
    isStartNode &&
      ((previewAppStore.state.previewShowStatus && !isStaticApp) || !previewAppStore.state.previewShowStatus),
    hasAdded && previewV2ShowToolbar
  );
  const enablePublish = CreatorNodesConsts.disablePublishDisplayType.includes(displayType) === false;

  const enableBlueprintExpandOrCollapse = props.data.displayType === "blueprint";
  const isBlueprintListNode =
    enableBlueprintExpandOrCollapse && textAreaValue?.list && Array.isArray(textAreaValue.value);
  const showDesignSpace = featureTags.showDesignSpaceMenu && enableDesignSpaceFeature;

  const enableBlueprintCollapse = enableBlueprintExpandOrCollapse && props.type === "customGroup";
  const enableBlueprintExpand =
    enableBlueprintExpandOrCollapse &&
    props.type === "customNode" &&
    (isBlank(textAreaValue?.components) === false || isBlueprintListNode);
  const isParentBluePrintNode = props.data.displayType === "blueprint" && isBlank(props.data.parentNodeId);

  const enableBlueprintShowInfo =
    props.data.displayType === "blueprint" &&
    isBlank(textAreaValue?.description) === false &&
    isBlank(props.data.parentNodeId);

  const enableGroupCollapse = featureTags.enableGroupCollapse && props.type === "customGroup";

  const disabledChatAI = useMemo(() => {
    return PreviewAppUtil.needDisableSendToAppBtn({
      isChat: appStates.isChatApp,
      nodeId: id,
      isStartNode: !!isStartNode,
      nodes: nodesRef.current,
    });
  }, [appStates.isChatApp, id, isStartNode, nodesRef.current]);

  const clickMessgeBluePrint = useCallback(
    async feedback => {
      const nodes = creatorNodesStore.getNodes();
      const edges = creatorEdgesStore.getEdges();
      const line = edges.find(x => x.target == props.id);
      const edgeNode = nodes.find(x => x.id === line.source);
      const blueJson = JSON.parse(get(edgeNode, "data.lineParam.blueJson", JSON.stringify({})));
      const blueprintId = blueJson.id ?? blueJson.name ?? "recipe_maker";
      const result = await tryPromise(feedBluePrintMessage({ feedback, id: blueprintId }));
      if (result.data) {
        eventbus.emit(new BlueprintBuildMsg(result.data.data.blueprints, id));
        func.messageUtil("Feedback successfully", "success");
      }
    },
    [props.id]
  );

  const PublishIcon = () => {
    if (featureTags.disableToolBarPublish) {
      return null;
    }
    if (!previewAppStore.enableRemovePreview(hasAdded) || !enablePublish) {
      return null;
    }

    // chat ai app 使用
    // FIXME: 只有当 disabled 时才显示 action 组件
    if (appStates.isChatApp) {
      return (
        <Popover
          content={!disabledChatAI ? addConfig.text : null}
          autoAdjustOverflow={false}
          overlayClassName={style.canvasPopover}
        >
          <Action
            className={style["tool-bar-item"]}
            onClick={clickSendToApp}
            // tipText={!disabledChatAI ? <p className={style["tool-tip"]}>{addConfig.text}</p> : null}
            disabled={disabledChatAI}
            added={hasAdded && previewV2ShowToolbar}
          />
        </Popover>
      );
    }

    return (
      <Popover content={addConfig.text} autoAdjustOverflow={false} overlayClassName={style.canvasPopover}>
        <div
          className={`${style["tool-bar-item"]}`}
          style={{ "--back-color": hasAdded && previewV2ShowToolbar ? "#FF335C" : "#0A9DFF" }}
          onClick={e => clickSendToApp(e)}
        >
          <p
            className={`${style["tool-bar-item-republic"]} ${style["tool-bar-item-sta"]} ${style[addConfig.iconClass]}`}
          ></p>
          {/* <p className={style["tool-tip"]}>{addConfig.text}</p> */}
        </div>
      </Popover>
    );
  };

  const handleChangeBrushShow = () => {
    showBrush.value = !showBrush.value;
  };
  const handleClickBrush = () => {
    props.onChangeDesignBoxShow?.(true);
  };

  function countWords(str) {
    if (typeof str !== "string") {
      return 0;
    }

    if (str) {
      const chineseArr = str.match(/[\u4e00-\u9fa5]/g);
      if (chineseArr?.length > 1) {
        return chineseArr?.length;
      }
      str = str.trim();
      var wordsArray = str.split(/\b\w/);
      return wordsArray.length - 1;
    }
    return 0;
  }
  const showSplit =
    enableSplitType.includes(props.displayType) && !isChildNode && countWords(props.textAreaChangeValue?.value) > 1;

  return (
    <div className={`${style["tool-bar"]} nodrag`} style={props.styl}>
      <div className={style["tool-bar-list-box"]} style={{ "--back-color": "#0A9DFF" }}>
        {PublishIcon()}
        {showSplit ? (
          <Popover content={"Split"} autoAdjustOverflow={false} overlayClassName={style.canvasPopover}>
            <div className={style["tool-bar-item"]} onClick={clickSliceIcon}>
              <p className={`${style["tool-bar-item-republic"]} ${style["optSplit"]}`}></p>
              {/* <p className={style["tool-tip"]}>Split</p> */}
            </div>
          </Popover>
        ) : null}
        {featureTags.enableShrinkAndExpand &&
        enableShrinkAndExpandTypes.includes(props.displayType) &&
        props.type !== "customGroup" &&
        !isChildNode ? (
          <Popover
            content={shrinkAndExpand[fitItemStatus].value}
            autoAdjustOverflow={false}
            overlayClassName={style.canvasPopover}
          >
            <div className={style["tool-bar-item"]} onClick={e => clickResizeNode(e)}>
              <p
                className={`${style["tool-bar-item-republic"]} ${style[shrinkAndExpand[fitItemStatus].className]}`}
              ></p>
              {/* <p className={style["tool-tip"]}>{shrinkAndExpand[fitItemStatus].value}</p> */}
            </div>
          </Popover>
        ) : null}
        <Popover content={"Copy Node"} autoAdjustOverflow={false} overlayClassName={style.canvasPopover}>
          <div className={style["tool-bar-item"]} onClick={e => clickCopyNode(e)}>
            <p className={`${style["tool-bar-item-republic"]} ${style["optCopy"]}`}></p>
            {/* <p className={style["tool-tip"]}>Copy Node</p> */}
          </div>
        </Popover>
        {enableGroupCollapse && (
          <Popover
            content={collapseOrExpand[currGroupCOEData.groupCOEStatus].value}
            autoAdjustOverflow={false}
            overlayClassName={style.canvasPopover}
          >
            <div className={style["tool-bar-item"]} onClick={() => props.clickGroupCollapseOrExpand()}>
              <p
                className={`${style["tool-bar-item-republic"]} ${
                  style[collapseOrExpand[currGroupCOEData.groupCOEStatus].className]
                }`}
              ></p>
              {/* <p className={style["tool-tip"]}>{collapseOrExpand[currGroupCOEData.groupCOEStatus].value}</p> */}
            </div>
          </Popover>
        )}
        {enableCopyTextType.includes(props.displayType) ? (
          <Popover content={"Copy text"} autoAdjustOverflow={false} overlayClassName={style.canvasPopover}>
            <div className={style["tool-bar-item"]} onClick={e => clickCopyText(e)}>
              <p className={`${style["tool-bar-item-republic"]} ${style["optCopyTxt"]}`}></p>
              {/* <p className={style["tool-tip"]}>Copy text</p> */}
            </div>
          </Popover>
        ) : null}
        {enableBlueprintShowInfo && (
          <Popover content={"Details"} autoAdjustOverflow={false} overlayClassName={style.canvasPopover}>
            <div className={style["tool-bar-item"]} onClick={() => props.clickNodeInfo?.()}>
              <p className={`${style["tool-bar-item-republic"]} ${style["optDetail"]}`}></p>
              {/* <p className={style["tool-tip"]}>Details</p> */}
            </div>
          </Popover>
        )}
        <Popover content={"Delete"} autoAdjustOverflow={false} overlayClassName={style.canvasPopover}>
          <div className={style["tool-bar-item"]} onClick={clickDeleteNode}>
            <p className={`${style["tool-bar-item-republic"]} ${style["optDelete"]}`}></p>
            {/* <p className={style["tool-tip"]}>Delete</p> */}
          </div>
        </Popover>
        {isParentBluePrintNode && (
          <Popover content={"Feedback"} autoAdjustOverflow={false} overlayClassName={style.canvasPopover}>
            <div className={style["tool-bar-item"]}>
              <TooltipMessage
                title="Describe what needs to change"
                placeholder="Make the ..."
                onConfirm={clickMessgeBluePrint}
                onChangeShowBluePrintFeedbackModal={onChangeShowBluePrintFeedbackModal}
              >
                <p className={`${style["tool-bar-item-republic"]} ${style["optMessage"]}`}></p>
              </TooltipMessage>
              {/* <p className={style["tool-tip"]}>Feedback</p> */}
            </div>
          </Popover>
        )}
        {isParentBluePrintNode && showDesignSpace && (
          <Popover
            content={showBrush.value ? "Hide Design" : "Show Design"}
            autoAdjustOverflow={false}
            overlayClassName={style.canvasPopover}
          >
            <div className={style["tool-bar-item"]} onClick={handleChangeBrushShow}>
              <p
                className={`${style["tool-bar-item-republic"]} ${
                  showBrush.value ? style["optEye"] : style["optEyeClose"]
                }`}
              ></p>
              {/* <p className={style["tool-tip"]}>{showBrush.value ? "Hide Design" : "Show Design"}</p> */}
            </div>
          </Popover>
        )}
        {enableBlueprintCollapse || enableBlueprintExpand ? (
          <Popover
            content={enableBlueprintCollapse ? "Collapse" : "Expand"}
            autoAdjustOverflow={false}
            overlayClassName={style.canvasPopover}
          >
            <div className={`${style["tool-bar-item"]}`} onClick={() => handleClickBlueprint(enableBlueprintCollapse)}>
              <p
                className={`${style["tool-bar-item-republic"]} ${
                  enableBlueprintCollapse ? style["optBlueCollapse"] : style["optBlueExpand"]
                }`}
              ></p>
              {/* <p className={style["tool-tip"]}>{enableBlueprintCollapse ? "Collapse" : "Expand"}</p> */}
            </div>
          </Popover>
        ) : null}
        {showDesignSpace && showBrush.value && (
          <Popover content={"Change Design"} autoAdjustOverflow={false} overlayClassName={style.canvasPopover}>
            <div className={style["tool-bar-item"]} onClick={handleClickBrush}>
              <p className={`${style["tool-bar-item-republic"]} ${style["optBrush"]}`}></p>
              {/* <p className={style["tool-tip"]}>Change Design</p> */}
            </div>
          </Popover>
        )}
      </div>
    </div>
  );
}
