import { Container } from "inversify";
import { CookieStorageManagerImpl } from "@infra/CookieStorageManagerImpl";
import { CorekitRegistry, CorekitRegistryParams } from "imagica-corekit/dist/registry/CorekitRegistry";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { LocalStorageManagerImpl } from "imagica-corekit/dist/infra/LocalStorageManagerImpl";
import { LocationManagerImpl } from "imagica-corekit/dist/infra/LocationManagerImpl";
import { Analytics } from "imagica-corekit/dist/base/port/Analytics";
import { AnalyticsImpl } from "@infra/AnalyticsImpl";
import { FotRegistry } from "register/FotRegister";
export class InjectConfig {
  private static container?: Container;

  static get<T>(serviceIdentifier: ServiceIdentifier<T>): T {
    return InjectConfig.configure().get<T>(serviceIdentifier);
  }

  private static configure(): Container {
    if (InjectConfig.container != null) {
      return InjectConfig.container;
    }
    const container = new Container({
      autoBindInjectable: true,
      defaultScope: "Singleton",
      skipBaseClassChecks: true,
    });
    InjectConfig.container = container;
    InjectConfig.before(container);
    const params: CorekitRegistryParams = {
      cookieStorageManager: new CookieStorageManagerImpl(),
      defaultStorageManager: new LocalStorageManagerImpl(),
      locationManager: new LocationManagerImpl(),
      location: window.location.href,
    };
    CorekitRegistry.configure(container, params);
    FotRegistry.configure(container);

    // InjectConfig.after(container);
    console.log("InjectConfig complete");
    return container;
  }

  private static before(container: Container): void {
    container.bind(Analytics).toConstantValue(new AnalyticsImpl());
  }

  // /**
  //  *
  //  * @param {Container} container
  //  * @param {CorekitRegistryParams} params
  //  */
  // private static after(container: Container): void {
  //   // const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
  //   //   return container.get(serviceIdentifier);
  //   // };
  // }
}
