import { useMemo, useRef } from "react";
import { InputMain } from "./InputMain";
import { InputTitle } from "./InputTitle";
import { InputDelete } from "./InputAction/InputDelete";
import { InputEnter } from "./InputAction/InputEnter";
import { useAiSaasInputContainer } from "./container";
import css from "./index.module.scss";
import { useAISaasPreviewItem } from "../hooks/useAISaasPreviewItem";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { getIt } from "@uikit/getIt";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { TalkBuilder } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboardingType";
import { matchMobile } from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";

function InputInner(props: AISaas.AISaasInputItemInnerProps): JSX.Element {
  const inputRef = useRef(null);
  const { x, index } = props;
  const { id: itemId } = x.data;
  const { isFocus, isDarkMode, isEditPlaceholder, onMouseLeaveBox, onMouseOverBox } = useAiSaasInputContainer();

  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;

  const isUploadFileInputNode = useMemo(() => {
    return featureTags.disableUploadTypesAsInputNode === false && ReactFlowNodeUtil.isUploadNode(x.data);
  }, [featureTags.disableUploadTypesAsInputNode, x]);

  /* const isEditPlaceholder = useMemo(() => {
    return !isBlank(editPlaceholderData.value) && editPlaceholderData.value.id === itemId;
  }, [editPlaceholderData, itemId]); */

  /* useEffect(() => {
    // 当监听到selectTips变化了，那么需要刷新提示框的tips
    if (selectTips.value.id === x.id && selectTips.value.type === "inputField") {
      onPreviewItemMouseEnter(getMouceEnterParams());
    }
  }, [selectTips.value]); */

  /* const getMouceEnterParams = () => {
    return isUploadFileInputNode
      ? {
          type: "fileUpload",
          id: x.id,
          container: inputRef.current,
          remove: () => onDeleteInput(null, itemId),
        }
      : {
          type: "inputField",
          id: x.id,
          container: inputRef.current,
          edit: (tips: string) => {
            setSelectTips(itemId, tips);
            onInputFocus(itemId, index);
          },
          remove: () => onDeleteInput(null, itemId),
          editPlaceholder: (tips: string) => setEditPlaceholder(itemId, true, tips),
          selectTips: selectTips.value,
        };
  }; */
  const isEditPlaceholderVlue = isEditPlaceholder.id === itemId ? isEditPlaceholder.isEdit : false;

  return (
    <div
      className={css["transparent-storey"]}
      onMouseLeave={onMouseLeaveBox}
      onMouseOver={() => onMouseOverBox(itemId)}
    >
      <div
        ref={inputRef}
        className={`${css["input-box"]} ${isFocus.value[itemId] ? css["enlarge-input-box"] : ""}
        ${isEditPlaceholderVlue ? css["convention-padding"] : ""}
        ${isDarkMode ? "dark" : ""} ${isUploadFileInputNode ? css["upload-file-box"] : ""}`}
      >
        <InputMain
          x={x}
          index={index}
          isEditPlaceholder={isEditPlaceholderVlue}
          isUploadFileInputNode={isUploadFileInputNode}
        />
        {!(isMobile && featureTags.disableMobilePage) && <InputEnter x={x} />}
        <InputDelete x={x} isEditPlaceholder={isEditPlaceholderVlue} />
      </div>
    </div>
  );
}

export function AISaasInputItem(props: AISaas.AISaasInputItemProps): JSX.Element {
  const inputRef = useRef(null);
  // const longPressTimer = useRef<any>(null);
  const { x, index } = props;
  const itemId = x.data.id;
  const { onPreviewItemMouseEnter } = useAISaasPreviewItem();
  const { setEditPlaceholder, onDeleteInput, onClickInputTitle, selectTips } = useAiSaasInputContainer();
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const projectNodeStore = getIt(ProjectNodeStore);

  const isUploadFileInputNode = useMemo(() => {
    return ReactFlowNodeUtil.isUploadNode(x.data);
  }, [x]);

  const getMouceEnterParams = (): any => {
    return isUploadFileInputNode
      ? {
          type: "fileUpload",
          id: x.id,
          container: inputRef.current,
          remove: () => onDeleteInput(null, itemId),
        }
      : {
          type: "input",
          id: x.id,
          container: inputRef.current,
          talkBuilderEdit: (): void => {
            chatOnboardingStore.setChatOnboardingState({
              talkBuilder: {
                ...new TalkBuilder(),
                id: x.id as string,
                // stud-2493 get input title(preview)
                content: projectNodeStore.getNode(x.data.id)?.inputTitle || "",
              },
            });
          },
          edit: (): void => {
            onClickInputTitle(null, x, "Edit text");
          },
          remove: () => onDeleteInput(null, itemId),
          editPlaceholder: (tips: string) => setEditPlaceholder(itemId, true, tips),
          selectTips: selectTips.value,
        };
  };

  /* const touchStart = (): void => {
    longPressTimer.current = setTimeout(() => {
      onPreviewItemMouseEnter(getMouceEnterParams());
    }, 500);
  };

  const touchEnd = (): void => {
    longPressTimer.current && clearTimeout(longPressTimer.current);
  }; */

  const handleClickInput = (): void => {
    // if (!(isMobile && featureTags.disableMobilePage)) {
    onPreviewItemMouseEnter(getMouceEnterParams());
    // }
  };

  return (
    <div key={itemId} ref={inputRef} onClick={handleClickInput}>
      <InputTitle x={x.data} />
      <InputInner x={x} index={index} />
    </div>
  );
}
