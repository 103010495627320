import { PreviewOutputItemProps } from "./PreviewOutputItem";
import { Nodes } from "@uiview/views/Nodes/Nodes";
import { displayTypeArray } from "@uiview/views/Nodes/nodeConfig";
import { DisplayType } from "@uiview/views/Nodes/typing";
import { ImageProps } from "@uiview/views/Nodes/Image/ImageNode";
import { TextProps } from "imagica-uikit/dist/nodesV2/Text/TextNodeBloc";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import css from "./PreviewOutputItem.module.scss";
import { ViewBuilderWorker } from "imagica-uikit/dist/nodesV2/ViewBuilderComponents/ViewBuilderWorker";
import { noop } from "lodash";
import { PreviewOutputLocusMap } from "./PreviewOutputUtil";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { FC } from "react";

type PreviewOutputChild = (typeof PreviewOutputChildComponentMaps)[number];
export type PreviewChildType = PreviewOutputChild["displayType"];

/**
 * 重写部分 `Nodes/nodeConfig` 用于 preview output 渲染的组件配置
 */
const PreviewOutputChildComponentMaps = [] as {
  displayType: string;
  component: FC;
}[];

export class PreviewOutputChildGetter {
  // 下面方法返回类型组合关系比较强
  // eslint-disable-next-line
  private static getNodesProps<T extends Record<string, any>>(props: PreviewOutputItemProps<T>) {
    const homeStore = getIt(HomeStore);
    const creatorNodesStore = getIt(CreatorNodesStore);

    const nodeProps = {
      // node wrapper
      isInCanvas: false,
      isShare: !!props.isShare,
      id: props.previewData.nodeId,
      nodeId: props.node.id,
      initCreateBlobUrl: props.node.data.initCreateBlobUrl,
      loading: props.node.data.loading,
    };

    const isTargetNode = false;
    const featureTags = homeStore.state.featureTags as Record<string, any>;
    // const cavnasShowBuilderUI =
    //   props.node.data.displayType === "text" &&
    //   featureTags.enableViewBuilder &&
    //   isTargetNode &&
    //   props.previewData.data.content.length > 0;
    const showBuilderUI = ViewBuilderWorker.isShowBuilderUI(!!props.node.data.showBuilderUI);
    const caption = props.previewData.data.content.context;
    switch (props.node.data.displayType) {
      // case "htmlTemplate":
      // case "code":
      //   return {
      //     fontSize: props.previewData.data.fontSize,
      //     // htmlTemplate node props
      //     displayType: props.node.data.displayType,
      //     content: props.previewData.data.content.code,
      //     isResized: false,
      //     stockLoading: false,
      //     isTargetNode: false,
      //     id: props.node.id,
      //     handleGroupStyle: noop,
      //   } as CodeProps;
      case "json":
      case "text":
      case "inputTextarea":
        return {
          ...(nodeProps as any),
          inputRef: { current: null },
          // FIXME: customNode 使用的是 saasuidata 原来 output 的结构，为了兼容 nodes 中的text组件，暂时转成 `AISaasOutput.ThoughtType`
          // 需要将 customNode 使用 AISaasOutput.ThoughtType 方式去掉
          textAreaValue: [
            {
              angle: "",
              results: [
                {
                  angles: [],
                  content: props.previewData.data.content,
                  dislikes: [],
                  filters: [],
                  hc_tag: -1,
                  likes: [],
                  reactions: [],
                  thought: "",
                  type: "",
                },
              ],
            },
          ] as any,
          checkNodeArr: [] as any[],
          placeholder: "",
          isLongPress: false,
          isResized: false,
          onTextAreaBlur: noop as any,
          onTextAreaFocus: noop as any,
          onTextAreaEnter: noop as any,
          setTextAreaValueWithParam: noop as any,
          setNodes: creatorNodesStore.setNodes,
          isTargetNode: isTargetNode,
          showStopGenerateBtn: false,
          parentNodeId: props.previewData.parentId,
          noWheelClassName: "",
          enableFeedback: false,
          isStaticApp: false,
          isInBlueprint: false,
          displayType: props.node.data.displayType,
          type: props.node.type,
          featureTags: featureTags,
          // FIXME: view builder 是否显示
          showBuilderUI: showBuilderUI,
          // preview 默认开启 view builder, 只受权限控制 但不能修改
          enableViewBuilder: true,
          readonlyStyle: props.previewData.data.nodeFontStyle,
          className: css.previewOutputText,
          readOnly: true,
          initialReportSize: { width: "unset", height: "unset" },
          resizeEventParams: { width: "unset", height: "unset" },
          viewBuilderData: props.node.data.viewBuilderData,
          // createBlobUrl: undefined,
          // initialReportSize: {
          //   width: 0,
          //   height: 0,
          // },
          // isStartNode: false,
          // isStartNodeAdded: false,
          // focusNodeTopBarById: noop,
        } as TextProps;
      case "imageGen":
      case "midjourney":
      case "midjourneyV2":
      case "imageSearch":
        return {
          ...nodeProps,
          // image props
          style: props.previewData.data.nodeFontStyle,
          isInBlueprint: false,
          isResized: true,
          isLongPress: false,
          errorText: "",
          checkNodeArr: [],
          handleGroupStyle: noop,
          parentNodeId: props.previewData.parentId,
          textAreaValue: props.previewData.data.content,
          isTargetNode: false,
          showFeedback: false,
          enableFeedback: false,
          displayType: props.node.data.displayType,
          createBlobUrl: undefined,
          className: props.className,
          disableModalPreview: true,
          caption: caption,
          showCaption: !!props.previewData.data.isCaptionToggle && !!caption,
        } as ImageProps;
      case "stockInfo":
        return {
          style: props.previewData.data.nodeFontStyle,
          id: props.node.id,
          nodeId: props.node.id,
          isInCanvas: false,
          isShare: !!props.isShare,
          displayType: props.node.data.displayType,
          content: props.previewData.data.content,
          textAreaValue: props.previewData.data.content,
          canvasType: "saas",
          isStaticApp: props.isStaticApp,

          editorId: props.node.id || "fotId",
          // FIXME: locus, 原来逻辑用于 stock buy，如果 stock 组件被移除，该属性则需要被删除
          // 并且 cardHomeStock 组件只有 saas 和 cavnas 区分
          locus: PreviewOutputLocusMap.preview,
          fontSize: props.previewData.data.fontSize,
          stockLoading: props.node.data.stockLoading,
          ...props,
        };
      case "uploadImage":
        return {
          ...nodeProps,
          disableModalPreview: true,
          handleGroupStyle: noop,
          style: props.previewData.data.nodeFontStyle,
          id: props.node.id,
          nodeId: props.node.id,
          displayType: props.node.data.displayType,
          content: props.previewData.data.content,
          textAreaValue: props.previewData.data.content,
          canvasType: "saas",
          isStaticApp: props.isStaticApp,
          caption: caption,
          showCaption: !!props.previewData.data.isCaptionToggle && !!caption,
          ...props,
        };
      default:
        return {
          ...nodeProps,
          style: props.previewData.data.nodeFontStyle,
          id: props.node.id,
          nodeId: props.node.id,
          displayType: props.node.data.displayType,
          content: props.previewData.data.content,
          textAreaValue: props.previewData.data.content,
          canvasType: "saas",
          isStaticApp: props.isStaticApp,
          ...props,
        };
    }
  }

  /**
   * 用于 node 类型获取 preview 渲染的组件实例的属性
   * @param displayType
   * @returns
   */
  // eslint-disable-next-line
  static getPreviewOutputChildComponentProps<T extends Record<string, any>>(props: PreviewOutputItemProps<T>) {
    const displayType = props.node.data.displayType;

    // const previewOutputChild = find(PreviewOutputChildComponentMaps, ["displayType", displayType]);

    // // rewrite component
    // if (previewOutputChild) {
    //   return PreviewOutputChildGetter.getPreviewOutputChildProps(displayType, props);
    // }

    // general component
    if (displayTypeArray.includes(displayType)) {
      // use customNode Nodes
      return PreviewOutputChildGetter.getNodesProps(props);
    }

    return PreviewOutputChildGetter.getNodesProps(props);
  }

  /**
   * 用于 node 类型获取 preview 渲染的组件实例
   * @param displayType
   * @returns
   */
  // eslint-disable-next-line
  static getPreviewOutputChildComponent<T extends DisplayType>(displayType: T) {
    // rewrite component
    // const itemChild = find(PreviewOutputChildComponentMaps, ["displayType", displayType]);

    // general component
    if (displayTypeArray.includes(displayType)) {
      // rewrite component
      // if (itemChild) {
      //   console.log(`previewoutput ${displayType} use OutputChild`);
      //   return itemChild.component as unknown as typeof Nodes;
      // }

      console.log(`previewoutput ${displayType} use Nodes`);
      // use customNode Nodes
      return Nodes;
    }
    console.warn(`PreviewOutput displaType ${displayType} not preview component!`);

    return Nodes;
  }
}
