import { useRef, useState } from "react";
import func from "@uikit/func";
import { TypeLabelKey } from "./ChatBoxV2";
import { get } from "lodash";
import { getIt } from "@uikit/getIt";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";
import { OpenAiParam } from "@uikit/service/OpenAiParam";

const typeLabel = {
  user: {
    label: "User",
    flag: "<#USER>",
  },
  assistant: {
    label: "Assistant",
    flag: "<#ASSISTANT>",
  },
};
// eslint-disable-next-line
export const useChat = (props: any) => {
  const { prevAttribute, currEdge, sourceNode, nodeId } = props;
  const postUrlCanAbortService = getIt(PostUrlCanAbortService);
  const openAiParam = getIt(OpenAiParam);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  const [submitValue, setSubmitValue] = useState("");
  const [chatList, setChatList] = useState<any[]>([]);

  const chatBoxListRef = useRef<null | HTMLDivElement>(null);

  const clickRegenerateBtn = async (): Promise<void> => {
    if (submitLoading || regenerateLoading) return;
    const filterChatList = chatList.filter((x, idx) => !(x.type === "assistant" && idx === chatList.length - 1));
    setRegenerateLoading(true);
    await postStreamingApi(filterChatList);
    setRegenerateLoading(false);
  };

  const moveListScrollBarToBottom = (): void => {
    if (chatBoxListRef.current) {
      if (chatBoxListRef.current.scrollHeight <= chatBoxListRef.current.clientHeight) return;
      chatBoxListRef.current.scrollTo({
        top: chatBoxListRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const listToFlagStr = (newChatList: any[]): string => {
    let str = "";
    newChatList
      .filter((x, idx) => !(x.type === "assistant" && idx === 0))
      .forEach(x => {
        str += `${typeLabel[x.type as TypeLabelKey].flag}${x.value}`;
      });
    return str;
  };

  const postStreamingApi = async (newChatList: any[]): Promise<void> => {
    try {
      // 这里需要组装最新的prompt
      prevAttribute.current.prompt = listToFlagStr(newChatList);
      // 请求completions
      const url = "/stream/bas-demo-v4/nlp/completions_generation";
      const postParam = nodeId
        ? await openAiParam.getCompletionsGenerationParam(
            "object_id",
            get(currEdge, "current.data.lineParam.identifier.value", ""),
            {
              input: get(sourceNode, "sourceNode.current.data.textAreaValue", ""),
              chatHistory: prevAttribute.current.prompt,
            }
          )
        : {
            domain: "brain_studios",
            identifier_type: "file",
            identifier_value: "custom_js_function_chat_agent.model",
            stream: true,
            variables: {
              chatHistory: prevAttribute.current.prompt,
              // context: '',
              // chatHistory: '',
              input: "",
            },
          };

      let temp = "";
      await postUrlCanAbortService.postWithStreaming(
        url,
        postParam,
        (msg: any) => {
          temp += msg.choices?.[0]?.text || msg.choices?.[0]?.delta?.content || "";
          setChatList(() => {
            return [
              ...newChatList,
              {
                type: "assistant",
                value: temp,
              },
            ];
          });
          // 需要置滚动条到最底部
          moveListScrollBarToBottom();
        },
        false
      );
    } catch (error: any) {
      func.messageError(error);
    }
  };

  const onPressEnter = async (): Promise<void> => {
    if (func.isEmpty(submitValue)) {
      func.customMsg({
        content: "Message cannot be empty",
        type: "warning",
      });
      return;
    }
    if (submitLoading || regenerateLoading) return;

    setSubmitValue("");
    const newChatList = [
      ...chatList,
      {
        type: "user",
        value: submitValue,
      },
    ];
    setChatList(newChatList);
    setSubmitLoading(true);
    await postStreamingApi(newChatList);
    setSubmitLoading(false);
  };

  return {
    submitValue,
    setSubmitValue,
    submitLoading,
    regenerateLoading,
    onPressEnter,
    clickRegenerateBtn,
    chatList,
    setChatList,
    chatBoxListRef,
  };
};
