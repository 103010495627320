import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export enum ChoosingFormat {
  AIAPP = "AIAPP",
  STATICWEBSITE = "STATICWEBSITE",
  AICHATAGENT = "AICHATAGENT",
  MOBILEAPP = "MOBILEAPP",
  THINKINGCANVAS = "THINKINGCANVAS",
  AUTOIMAGICA = "AUTOIMAGICA",
}

export class ChooseFormatStoreState {
  chooseFormat: ChoosingFormat | null = null;

  copyWith(param: Partial<Omit<ChooseFormatStoreState, "copyWith">>): ChooseFormatStoreState {
    const newState = new ChooseFormatStoreState();
    newState.chooseFormat = param.chooseFormat !== undefined ? param.chooseFormat : this.chooseFormat;

    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);

/**
 * start by choosing a publishing format state
 */
class ChooseFormatStore extends StoreBase<ChooseFormatStoreState> {
  constructor() {
    super(() => new ChooseFormatStoreState());
  }

  setChooseFormat = (chooseFormat: ChoosingFormat | null): void => {
    this.emit(this.state.copyWith({ chooseFormat }));
  };
}
export const chooseFormatStore = new ChooseFormatStore();
