export function ChromeIcon() {
  return (
    <svg
      data-testid="ChromeIcon"
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4517_387227)">
        <path
          d="M30.0006 45.4999C38.2854 45.4999 45.0013 38.7837 45.0013 30.499C45.0013 22.2143 38.2854 15.498 30.0006 15.498C21.7159 15.498 15 22.2143 15 30.499C15 38.7837 21.7159 45.4999 30.0006 45.4999Z"
          fill="white"
        />
        <path
          d="M17.0094 38.0015L4.02047 15.5039C1.38677 20.0642 0 25.2374 0 30.5039C0 35.7704 1.38614 40.9436 4.01953 45.5039C6.65291 50.0642 10.4406 53.8513 15.0016 56.4834C19.5625 59.1156 24.7364 60.5008 30.0025 60.4992L42.9915 38.0015V37.9981C41.6759 40.2797 39.7827 42.1745 37.5024 43.4919C35.2221 44.8094 32.6351 45.5032 30.0016 45.5035C27.368 45.5039 24.7808 44.8109 22.5002 43.4941C20.2195 42.1773 18.3258 40.2831 17.0094 38.0022V38.0015Z"
          fill="#229342"
        />
        <path
          d="M42.9901 38.0014L30.0014 60.4991C35.2676 60.5 40.4411 59.1139 45.0018 56.4811C49.5624 53.8483 53.3495 50.0609 55.9822 45.5C58.6149 40.9391 60.0004 35.7655 59.9996 30.4994C59.9986 25.2332 58.6112 20.06 55.9769 15.5H29.9992L29.9961 15.5022C32.6296 15.501 35.217 16.1931 37.4981 17.5091C39.7794 18.8254 41.6736 20.7186 42.9907 22.9992C44.3078 25.2798 45.0012 27.8669 45.0012 30.5005C45.0011 33.1341 44.3076 35.7212 42.9904 38.0017L42.9901 38.0014Z"
          fill="#FBC116"
        />
        <path
          d="M30.0006 42.3765C36.5593 42.3765 41.8762 37.0597 41.8762 30.5006C41.8762 23.9415 36.5593 18.625 30.0006 18.625C23.4419 18.625 18.125 23.9419 18.125 30.5006C18.125 37.0593 23.4419 42.3765 30.0006 42.3765Z"
          fill="#1A73E8"
        />
        <path
          d="M30.0013 15.5009H55.979C53.3465 10.94 49.5598 7.15229 44.9991 4.5189C40.4386 1.88569 35.2652 0.4996 29.9991 0.5C24.733 0.500315 19.5598 1.8871 14.9998 4.52111C10.4396 7.15497 6.65325 10.943 4.02148 15.5044L17.0105 38.0021L17.0139 38.0039C15.6961 35.7238 15.0018 33.1369 15.001 30.5033C15.0001 27.8697 15.6928 25.2824 17.0092 23.0014C18.3254 20.7205 20.2196 18.8263 22.5002 17.5098C24.7809 16.1932 27.3683 15.5 30.002 15.5006L30.0013 15.5009Z"
          fill="#E33B2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_4517_387227">
          <rect width="60" height="60" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
