"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockApi = void 0;
class StockApi {
    constructor(restClient) {
        this.restClient = restClient;
    }
    buyStock(price, stockSymbol) {
        const proxyUrl = `http://35.163.73.88:8010/remote/buyStockFromRobinhood?stock_id=${stockSymbol}&budget=${price}`;
        const encodeProxyUrl = encodeURIComponent(proxyUrl);
        return this.restClient.get(`/be/svc-adapter/natural/proxy?url=${encodeProxyUrl}`).then(res => {
            res.validate();
            return;
        });
    }
}
exports.StockApi = StockApi;
