export class NodePreviewStock {
  constructor(
    public logo: string,
    public name: string,
    public symbol: string,
    public price: string,
    public summary: string,
    public volume: string,
    public value: PreviewApp.PreviewContentType
  ) {}
}
