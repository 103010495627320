"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddDataSourceFunction = void 0;
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
class AddDataSourceFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo, variableList = [], page = 1, next = undefined, pagination = undefined, requestParams = {}) {
        const data = requestParams || {};
        data.query = data.query || input;
        const fileUuid = data.fileUuid;
        delete data.fileUuid;
        const streamResponse = this.socketRestClient.requestRestGlobal(topic => {
            const params = {
                data: data,
                pubsub_topic: topic,
                uuid: fileUuid,
            };
            return this.brainClient.user.searchInDataSourceV2(params, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo));
        }, "addDataSourceFunction", identityInfo);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.CUSTOM_API, streamResponse);
    }
}
exports.AddDataSourceFunction = AddDataSourceFunction;
