import { Signal } from "@preact/signals-react";
import { chain, findLastIndex } from "lodash";
import { Role } from "./Constants";

export type Message = {
  content: string;
  role?: Role | string;
  title?: string | undefined;
  loading?: boolean;
  suggestion?: string[];
  [key: string]: any;
};

export type MessageKey = keyof Message;

export class OnboardingState {
  title = new Signal("");
  messages = new Signal<Message[]>([]);
  submitLoading = new Signal(false);
  showPreview = new Signal(true);
}

export enum JsonItem {
  current_response = "current_response",
  narration = "narration",
  suggested_responses = "suggested_responses",
}

export class OnboardingBloc {
  state = new OnboardingState();

  public jsonItemRegex(jsonItem: JsonItem, value: string): string | null {
    const regexPattern = new RegExp(`"${jsonItem}": "(.*?)"`);
    const matched = `${value}"`.match(regexPattern);
    if (matched) {
      return matched[1];
    }
    return null;
  }

  public jsonArrayRegex(jsonItem: JsonItem, value: string): string | null {
    const regexPattern = new RegExp(`"${jsonItem}"\\s*:\\s*\\[([^\\]]*)\\]`);
    const matched = value.match(regexPattern)?.[1];
    return matched || null;
  }

  public getLastMessage(role?: Role): string {
    return chain(this.state.messages.value)
      .filter(msg => {
        if (role) {
          return msg.role === role;
        }
        return true;
      })
      .last()
      .get("content", "")
      .value();
  }

  public modifyLastMessage(key: string, value: any): void {
    const index = findLastIndex(this.state.messages.value);
    const newMsg = [...this.state.messages.value];
    newMsg[index][key] = value;
    this.state.messages.value = newMsg;
  }
}
