"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnboardingAppInfoRespose = exports.OnboardingChatRespose = exports.PromptModelResponse = exports.PromptModel = void 0;
class PromptModel {
    constructor() {
        this.model_params = "";
        this.domain = "";
    }
}
exports.PromptModel = PromptModel;
class PromptModelResponse {
    constructor() {
        this.prompt = "";
    }
}
exports.PromptModelResponse = PromptModelResponse;
class OnboardingChatRespose {
}
exports.OnboardingChatRespose = OnboardingChatRespose;
class OnboardingAppInfoRespose {
    constructor() {
        this.name = "";
        this.features = [];
        this.audience = "";
        this.description = "";
        this.goal = "";
    }
}
exports.OnboardingAppInfoRespose = OnboardingAppInfoRespose;
