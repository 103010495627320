"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lateload = void 0;
class Lateload {
    constructor(handler) {
        this.state = undefined;
        this.handler = handler;
    }
    dirty() {
        this.state = undefined;
    }
    get() {
        if (!this.state) {
            this.state = this.handler();
        }
        return this.state;
    }
    renew() {
        this.state = this.handler();
        return this.state;
    }
}
exports.Lateload = Lateload;
