import { Dropdown, DropDownProps } from "antd";
import { ReactNode } from "react";
import css from "./index.module.scss";
import classNames from "classnames";
import { isFunction } from "lodash";

type CommandDropdownProps = {
  children: ReactNode;
  overlay?: DropDownProps["overlay"];
  open: boolean;
  dropdownAlign: DropDownProps["align"];
};

const dropdownAlign = {
  offset: [100, 10],
  overflow: {
    adjustX: false,
    adjustY: false,
  },
} as DropDownProps["align"];

export function CommandDropdown(props: CommandDropdownProps) {
  const { children, overlay, open } = props;

  const RenderOverlay = () => {
    return (
      <div data-testid="CommandbarOverlay" className={classNames(css["commandbar-overlay"])}>
        {isFunction(overlay) ? overlay() : overlay}
      </div>
    );
  };
  return (
    <Dropdown
      overlay={RenderOverlay}
      open={open}
      placement="bottom"
      arrow={false}
      align={props.dropdownAlign}
      // TODO: remove animation
      // transitionName={'zoom-up'}
      // 前期使用 customDropdown 时是条件渲染，所以组件会多次被挂载，在保持之前的逻辑上，让弹出层消失后销毁组件，打开时重新挂载
      destroyPopupOnHide
    >
      <div data-testid="CommandbarTrigger" className={classNames(css["commandbar-trigger"])}>
        {children}
      </div>
    </Dropdown>
  );
}
