import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { isFunction } from "lodash";

export class MyModificationDataStroeState {
  myModificationData = {} as any;
  showMyModification = false;

  copyWith(param: Partial<Omit<MyModificationDataStroeState, "copyWith">>): MyModificationDataStroeState {
    const newState = new MyModificationDataStroeState();
    newState.myModificationData = param.myModificationData ?? this.myModificationData;
    newState.showMyModification = param.showMyModification ?? this.showMyModification;
    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);
/**
 * 有关 api/js/普通 functions 相关状态
 */
export class MyModificationDataStroe extends StoreBase<MyModificationDataStroeState> {
  constructor() {
    super(() => new MyModificationDataStroeState());
  }

  setMyModificationData = (value: DispatchType<MyModificationDataStroeState["myModificationData"]>): void => {
    if (isFunction(value)) {
      const myModificationData = value(this.state.myModificationData);

      this.emit(this.state.copyWith({ myModificationData }));
      return;
    }

    this.emit(this.state.copyWith({ myModificationData: value }));
  };

  setShowMyModification = (value: boolean): void => {
    this.emit(this.state.copyWith({ showMyModification: value }));
  };
}

export const myModificationDataStroe = new MyModificationDataStroe();
