import func from "@uikit/func";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { getIt } from "@uikit/getIt";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";

const creatorAISaasStore = getIt(CreatorAISaasStore);
// eslint-disable-next-line
export function useAISaasPreviewItem() {
  const homePluginStore = getIt(HomePluginStore);
  const creatorAISassState = useImagicaStore(creatorAISaasStore).value;

  const onPreviewItemMouseEnter = (param: any): void => {
    if (homePluginStore.state.isShare) return;

    if (func.isEmpty(param.container)) return;

    creatorAISaasStore.setAiSaasPreviewHoverData({ show: true, ...param });
  };

  const onPreviewItemMouseLeave = (e: any): void => {
    if (e?.relatedTarget?.className?.includes?.("toolbar-main")) return;

    creatorAISaasStore.setAiSaasPreviewHoverData({
      show: false,
      container: null,
      type: "",
    });
  };
  return {
    previewHoverData: creatorAISassState.aiSaasPreviewHoverData,
    setPreviewHoverData: creatorAISaasStore.setAiSaasPreviewHoverData,
    onPreviewItemMouseEnter,
    onPreviewItemMouseLeave,
  };
}
