import func from "@uikit/func";
import React, { useMemo } from "react";
import { find } from "lodash";
import { Text } from "imagica-uikit/dist/nodesV2/Text/TextNode";
import { Button, Popover } from "antd";
import { nodeConfig } from "../../nodeConfig";
import { InfoCircleOutlined } from "@ant-design/icons";
import { transformNodeType, transformNodeValue } from "../BluePrintUtil";
import { IMAGE_TYPES } from "@views/thinking-layout-editor/constants";
import { Input as BluePrintValue } from "imagica-corekit/dist/base/api/blueprintTyped/BlueprintV2";
import { BluePrintNodeProps } from "../BluePrintNode";
import style from "../BluePrintNode.module.scss";

type BluePrintItemProps = BluePrintNodeProps & {
  name?: string;
  value: BluePrintValue | string | string[] | null;
  type?: string;
  reason?: string;
  list?: boolean;
  level?: number;
  index: number;
};

export function BluePrintItem({ name, value, index, level, type = "text", list = false, ...rest }: BluePrintItemProps) {
  // 后台类型不匹配 暂时强制转换
  const nodeType = useMemo(() => transformNodeType(type, list), [type, list]);
  const isImageNode = IMAGE_TYPES.includes(nodeType);
  const textAreaValue = useMemo(() => transformNodeValue(nodeType, list, value), [isImageNode, nodeType, value]);

  const { component } = useMemo(() => {
    const config = find(nodeConfig, item => item.displayType === nodeType) || {
      component: Text,
      disableFeatureTag: "",
      displayType: "text",
    };

    return config;
  }, [nodeType]);

  const getItemClass = () => {
    const classes = [`${style.componentItem}`, `${style["padding-" + level]}`];
    return classes.join(" ");
  };

  const getItemTitleClass = () => {
    const classes = [`${style.componentItemTitle}`, `${isImageNode ? style.componentItemTitleImage : ""}`];
    return classes.join(" ");
  };

  return (
    <div className={getItemClass()}>
      {!!name && (
        <span className={getItemTitleClass()}>
          {name}
          {func.isEmpty(rest.reason) === false && (
            <Popover
              content={rest.reason}
              placement="topLeft"
              trigger="click"
              overlayClassName={style.reasonPopover}
              overlayInnerStyle={{ width: "300px" }}
            >
              <Button type="text" icon={<InfoCircleOutlined />} />
            </Popover>
          )}
        </span>
      )}
      {/* OTHER-NODE */}
      {React.createElement(component, {
        ...rest,
        textAreaValue,
        type,
        isInBlueprint: true,
      })}
    </div>
  );
}
