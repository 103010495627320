"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterCot = void 0;
const BrainClient_1 = require("../base/api/BrainClient");
const MeStore_1 = require("../base/store/MeStore");
const CotFlow_1 = require("../cases/cot/CotFlow");
const CotService_1 = require("../cases/cot/CotService");
const TitleService_1 = require("../cases/preview/TitleService");
const CotDataTransformService_1 = require("../cases/cot/CotDataTransformService");
class RegisterCot {
    static configure(container) {
        const it = (serviceIdentifier) => {
            return container.get(serviceIdentifier);
        };
        container
            .bind(CotFlow_1.CotFlow)
            .toConstantValue(new CotFlow_1.CotFlow(new CotService_1.CotService(it(BrainClient_1.BrainClient), it(MeStore_1.MeStore)), new TitleService_1.TitleService(it(BrainClient_1.BrainClient))));
        container.bind(CotDataTransformService_1.CotDataTransformService).toConstantValue(new CotDataTransformService_1.CotDataTransformService());
    }
}
exports.RegisterCot = RegisterCot;
