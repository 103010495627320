import { EdgeState } from "imagica-corekit/dist/base/storyV2/domain/StoryEdge";
import { StoryNodeState } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeState";
import { isArray } from "lodash";
import func from "@uikit/func";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { ViewBuilderService } from "imagica-corekit/dist/base/service/ViewBuilderService";
import { UploadService } from "imagica-corekit/dist/base/service/UploadService";
import { ViewBuilderData, ViewBuilderWorker } from "imagica-uikit/dist/nodesV2/ViewBuilderComponents/ViewBuilderWorker";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { InitialReportSize } from "imagica-uikit/dist/nodesV2/typing";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { StoryNode } from "imagica-corekit/dist/base/storyV2/domain/StoryNode";
import { EdgeRunCore, EdgeRunTransformDataType } from "./EdgeRunCore";
import { EdgeRunGroupHandler } from "./EdgeRunGroupHandler";

export type EdgeRunSizeMapping = Record<string, InitialReportSize>;

/**
 * 原来 useNewEdgeRun hook 中的 handler 方法
 *
 * 其中 `updateNodeData` 更名为 `handleUpdateNodeData`
 *
 */
export class EdgeRunHandler {
  constructor(
    private core: EdgeRunCore,
    private groupHandler: EdgeRunGroupHandler,
    private viewBuilderService: ViewBuilderService,
    private uploadService: UploadService,
    private creatorStoreMethods: CreatorStoreMethods,
    private canvasDataRef: CanvasDataRef
  ) {}

  handleUIdata = (nodeId: string): Record<string, any> | { data: any; id: string; type: string } => {
    const nodes = this.core.creatorNodesStore.getNodes();
    const currentNode = nodes.find(x => x.id === nodeId);
    if (func.isEmpty(currentNode)) return {};
    const data = this.canvasDataRef.getSimpleNodeDataV3(currentNode);
    return {
      id: nodeId,
      type: currentNode?.type,
      data,
    };
  };

  handleNodeAndEdgeRef = (transformData: Array<EdgeRunTransformDataType>): void => {
    // x.data.nodeIndexRef 来自 homeUsePlugin nodeDataRef, 此处不在需要,可直接替换 creatorRefStore
    const customNode = transformData.filter(
      (x: any) => !func.isEmpty(x.data?.nodeIndexRef) || !func.isEmpty(x.data?.edgeIndexRef)
    );
    if (func.isEmpty(customNode)) return;
    const corekitNodeIndex = customNode[0].data.nodeIndexRef.current;
    const corekitEdgeIndex = customNode[0].data.edgeIndexRef.current;
    if (!isNaN(corekitNodeIndex) && corekitNodeIndex > creatorRefStore.nodeIndexRef.current)
      creatorRefStore.nodeIndexRef.current = corekitNodeIndex;
    if (!isNaN(corekitEdgeIndex) && corekitEdgeIndex > creatorRefStore.edgeIndexRef.current)
      creatorRefStore.edgeIndexRef.current = corekitEdgeIndex;
  };

  handleToolTip = (transformData: Array<EdgeRunTransformDataType>, state: StoryNodeState): void => {
    //运行完后才显示toolTip
    if (state !== StoryNodeState.COMPLETED && state !== StoryNodeState.ERROR) return;

    const getShowTooltip = this.core.fotReduxStore.getState().editor.showTooltip;
    if (getShowTooltip === "new-edge-2") {
      this.core.fotReduxStore.setCloseToolTipArr(["new-edge-2"]);
      const nodeStr = transformData.some((x: any) => x.id === "group-3") ? "group-3" : "editor-3";
      this.core.fotReduxStore.setShowTooltip(nodeStr);
    }
  };

  handleViewBuilder = (param: { updateNode: any; transformData: any; onUpdateNode?: (args: any) => void }): void => {
    let { transformData } = param;
    const { updateNode, onUpdateNode } = param;
    // 是group不执行
    if (isArray(transformData)) return;
    const isNodeCompleted = (updateNode.state as any) === EdgeState.COMPLETED;
    const currentNode = this.core.creatorNodesStore.getNodeById(transformData.id);
    const updateShareAppData = (viewBuilderData: ViewBuilderData): void => {
      // 这里重新赋值transformData的内容以便给share app更新
      transformData = {
        ...transformData,
        data: {
          ...transformData.data,
          // 如果run时显示的是text界面，那么这里闭包加入时showBuilderUI变量为false，需要更新为true，以便后续判定状态正确
          showBuilderUI: true,
          viewBuilderData: {
            ...transformData.data.viewBuilderData,
            ...viewBuilderData,
          },
        },
      };
      onUpdateNode?.({ updateNode, transformData });
    };

    // 如果已经显示view builder界面需要在text streaming时就显示loading
    if (transformData.data?.showBuilderUI && !currentNode?.data.viewBuilderData?.loadingHtml) {
      this.creatorStoreMethods.setNodeParam({
        id: transformData.id,
        data: {
          viewBuilderData: {
            loadingHtml: true,
            domList: [],
            originalText: transformData.data.textAreaValue,
          },
        },
      });
    }

    if (isNodeCompleted) {
      if (transformData.data?.displayType !== "text") return;
      // 显示view builder时直接请求数据并处理
      if (transformData.data.showBuilderUI) {
        if (currentNode?.data.viewBuilderData?.isRequestStart) return;
        new ViewBuilderWorker({
          id: transformData.id,
          textAreaValue: transformData.data.textAreaValue,
          viewBuilderService: this.viewBuilderService,
          uploadService: this.uploadService,
          setNodes: this.core.creatorNodesStore.setNodes,
          updateShareAppData,
        }).getViewBuilderData();
      }
    }
  };

  handleResolveRun = (param: {
    isRunAll: boolean;
    updateNode: any;
    transformData: any;
    resolve: (res?: any) => void;
    onUpdateNode?: (args: any) => void;
    /**
     * 原来 updateGroupOutput 方法逻辑
     * @param args
     * @returns
     */
    onUpdateGroupOutput?: (args: any) => void;
  }): void => {
    const { isRunAll, updateNode, transformData, resolve, onUpdateNode, onUpdateGroupOutput } = param;
    const isNodeCompleted = (updateNode.state as any) === EdgeState.COMPLETED;
    const showBuilderUI = ViewBuilderWorker.isShowBuilderUI(transformData?.data?.showBuilderUI ?? false);
    if (isNodeCompleted) {
      // 不显示view builder ui时，调用onUpdateNode
      if (!showBuilderUI) {
        //单个node
        if (typeof transformData === "object" && !Array.isArray(transformData)) {
          onUpdateNode?.({ updateNode, transformData });
        } else {
          onUpdateGroupOutput?.(updateNode);
        }
      }
      if (!isRunAll) {
        resolve({
          updateNode,
          transformData,
        });
      }
    }
  };
  /**
   * 原来 `updateNodeData`
   * @param updateNode
   * @param transformData
   * @returns
   */
  handleUpdateNodeData = (
    updateNode: StoryNode,
    transformData: EdgeRunTransformDataType | Array<EdgeRunTransformDataType>
  ): void => {
    const preNodeId = updateNode.options.id;
    if (typeof transformData === "object" && !Array.isArray(transformData)) {
      if (
        updateNode.id !== undefined &&
        updateNode.options.id !== updateNode.id &&
        updateNode.options.id.includes("group")
      ) {
        /// group变node
        const newData = transformData;
        // 此步会让transformData的id也被修改
        newData.id = updateNode.id;
        // 此时preNodeId是group的id
        this.groupHandler.handleGroupToCustom(preNodeId, newData);
        // 更新新node的内容
        this.core.creatorNodesStore.setQueryLoadingV3({
          id: newData.id,
          data: newData.data,
        });
        this.handleNodeAndEdgeRef([transformData]);
        this.handleToolTip([transformData], updateNode.state);
        return;
      }
      const object = transformData as EdgeRunTransformDataType;
      if (func.isEmpty(object.id) || func.isEmpty(object.data)) return;
      this.core.creatorNodesStore.setNodes((prevList: any) => {
        return prevList.map((node: any) => {
          if (node.id === object.id) {
            const newData = {
              ...node,
              type: object.type,
              data: {
                ...node.data,
                ...object.data,
              },
            };
            newData.data.initCreateBlobUrl = object.data?.initCreateBlobUrl;
            return newData;
          }
          return node;
        });
      });

      this.handleNodeAndEdgeRef([transformData]);
      this.handleToolTip([transformData], updateNode.state);
    } else {
      this.groupHandler.handleCustomToGroup(preNodeId, transformData);
      this.handleNodeAndEdgeRef(transformData);
      this.handleToolTip(transformData, updateNode.state);
    }
  };
}
