import { Signal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { ApiStatuses } from "@views/thinking-layout-editor/types";
import { RelationSetDest } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { ProjectTemplateStore } from "imagica-corekit/dist/cases/store/ProjectTemplateStore";
import func from "@uikit/func";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";

export class ProjectListViewBloc {
  store = getIt(ProjectTemplateStore);
  meStore = getIt(MeStore);
  brainClient = getIt(BrainClient);

  loadingOfProject: Signal<RelationSetDest | undefined> = new Signal(undefined);

  screenLoader = new Signal(false);

  apiStatusOfProjects = new Signal(ApiStatuses.Idle);

  showlist = new Signal(false);

  onInitData?: () => void = () => {};

  constructor(onInitData?: () => void) {
    this.onInitData = onInitData;
  }

  async initData(): Promise<void> {
    this.screenLoader.value = true;
    this.apiStatusOfProjects.value = ApiStatuses.Pending;
    try {
      await this.store.init();
      this.apiStatusOfProjects.value = ApiStatuses.Fulfilled;
    } catch (error) {
      this.apiStatusOfProjects.value = ApiStatuses.Rejected;
      func.customMsg({
        content: "Something went wrong",
        type: "error",
      });
    } finally {
      this.screenLoader.value = false;
      if (this.onInitData) {
        this.onInitData();
      }
    }
  }

  public changeListView(value: boolean): void {
    this.showlist.value = value;
  }

  async nextPage(): Promise<void> {
    try {
      await this.store.nextPage();
    } catch (error) {
      func.customMsg({
        content: "Something went wrong",
        type: "error",
      });
    }
  }

  deleteProject(item: RelationSetDest): void {
    this.store.deletelProjectById(item.id ?? "");
  }

  duplicateProject(item: RelationSetDest): void {
    this.store.addProject(item);
  }

  async searchProjects(e: any): Promise<void> {
    const query = e.target.value;
    if (!query) {
      // loadProjects();
    } else {
      this.apiStatusOfProjects.value = ApiStatuses.Pending;
      // setMyProjects([]);
      const res = await this.queryItems(query);
      // const uuids = res.map(item => item.metadata.document_id);
      /// TODO
      this.apiStatusOfProjects.value = ApiStatuses.Fulfilled;
      console.log("🚀 ~ searchProjects ~ res:", res);
    }
  }

  async sortByRefresh(sortBy: string): Promise<void> {
    this.apiStatusOfProjects.value = ApiStatuses.Pending;
    try {
      await this.store.init(sortBy);
      this.apiStatusOfProjects.value = ApiStatuses.Fulfilled;
    } catch (error) {
      this.apiStatusOfProjects.value = ApiStatuses.Rejected;
      func.customMsg({
        content: "Something went wrong",
        type: "error",
      });
    } finally {
      this.screenLoader.value = false;
    }
  }

  async queryItems(query: string): Promise<any[]> {
    const me = await this.meStore.get();
    const response = await this.brainClient.retrieval.query(query, me.id!, 10, "imagica");
    console.log("response", response);
    return response.data.results[0].results;
  }

  async filterAutoImagic(): Promise<void> {
    this.apiStatusOfProjects.value = ApiStatuses.Pending;
    await this.store.filterAutoImagic(true);
    this.apiStatusOfProjects.value = ApiStatuses.Fulfilled;
  }
}
