import { Switch } from "antd";
import styles from "./PriceAlertView.module.scss";
import { useSignal } from "@preact/signals-react";
import {
  ImagicaCreatorPlan,
  ImagicaCreatorPlanElement,
} from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { LoadingOutlined } from "@ant-design/icons";
import { AModal } from "@uiview/views/AModal/AModal";
import { get } from "lodash";
import { usePlanList } from "@uiview/pages/PlansList/index";
import { PlansTableUtil } from "@uikit/planList/PlanTableUtil";

const empty = () => {};

type PriceAlertViewProps = {
  onClose: () => void;
  onPay: (priceObj: ImagicaCreatorPlanElement) => void;
  payLoading: boolean;
  plan: ImagicaCreatorPlan | undefined;
};

export function PriceAlertView(props: PriceAlertViewProps) {
  const selectYear = useSignal(true);
  const { paybloc } = usePlanList();

  function onchange() {
    selectYear.value = !selectYear.value;
    console.log("selectYear", selectYear.value);
  }

  const price = selectYear.value ? props.plan?.price_annual : props.plan?.price_month;
  const originAmount = price ? PlansTableUtil.getUiPlanElement(price).originAmount : 0;

  const discount_description = get(props.plan, ["price_annual", "discount_description"], "");
  const annualPrefix = ` ${discount_description}`;

  return (
    <AModal open onCancel={props.onClose} className={styles.alert} buttons={[]} onOk={empty} closable={false}>
      <div className={styles.center}>
        <div className={styles.content}>
          <div>
            {paybloc.state.priceSelectPopOption.value.currentOperate} to {props.plan?.name.text}
          </div>
          <span className={styles.close} onClick={props.onClose}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#ACAEB5" />
              <path
                d="M6.46484 13.5352L13.5359 6.46409"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5352 13.5352L6.46409 6.46409"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className={styles.priceinfo}>
            <div className={styles.price_switch}>
              <div>
                <span>{"$" + props.plan?.price_month.amount}</span> Monthly
              </div>
              <Switch checked={selectYear.value} className={styles.switch} onChange={onchange} />
              <div>
                <span>{"$" + props.plan?.price_annual.amount}</span> Annually {annualPrefix}
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.today}>
              Amount due today
              <span>${originAmount}</span>
            </div>
          </div>

          <a
            className={styles.payDisClaimer}
            onClick={() => {
              window.open(
                `${window.location.origin}${window.location.pathname}#/editor/profile/subscription`,
                "target"
              );
            }}
          >
            By clicking the ‘Proceed to Payment’ button and confirming your subscription, you authorize Brain to charge
            your payment method a recurring charge of ${originAmount} each {selectYear.value ? "year" : "month"}, which
            continues automatically until cancelled. Cancel any time by visiting Account Settings. You must cancel
            before your next billing period begins to avoid being charged for renewal.
          </a>

          <div
            className={styles.pay_button}
            onClick={() => {
              const priceObj = selectYear.value ? props.plan?.price_annual : props.plan?.price_month;
              if (priceObj) props.onPay(priceObj);
            }}
          >
            {props.payLoading ? <LoadingOutlined /> : <>{paybloc.state.priceSelectPopOption.value.currentBtnStr}</>}
          </div>
        </div>
      </div>
    </AModal>
  );
}
