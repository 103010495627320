"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioProjectAttributesV3 = exports.StudioProjectAttributesV2Function = exports.StudioProjectAttributesV2UI = exports.StudioProjectAttributesV2 = exports.StudioProjectAttributes = void 0;
const class_transformer_1 = require("class-transformer");
require("reflect-metadata");
const StudioProjectAttributesV3Line_1 = require("../../project/domain/StudioProjectAttributesV3Line");
const StudioProjectAttributesV3Node_1 = require("../../project/domain/StudioProjectAttributesV3Node");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const StudioProjectAttributesV2Edge_1 = require("../../project/domain/StudioProjectAttributesV2Edge");
const StudioProjectAttributesV2Node_1 = require("../../project/domain/StudioProjectAttributesV2Node");
const StudioProjectAttributesV2UIInput_1 = require("../../project/domain/StudioProjectAttributesV2UIInput");
const StudioProjectAttributesV2UIOutput_1 = require("../../project/domain/StudioProjectAttributesV2UIOutput");
const StudioProjectAttributesV2Variable_1 = require("../../project/domain/StudioProjectAttributesV2Variable");
class StudioProjectAttributes {
}
exports.StudioProjectAttributes = StudioProjectAttributes;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2)
], StudioProjectAttributes.prototype, "v2", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV3)
], StudioProjectAttributes.prototype, "v3", void 0);
class StudioProjectAttributesV2 {
}
exports.StudioProjectAttributesV2 = StudioProjectAttributesV2;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2Node_1.StudioProjectAttributesV2Node)
], StudioProjectAttributesV2.prototype, "nodes", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2Edge)
], StudioProjectAttributesV2.prototype, "edges", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UI)
], StudioProjectAttributesV2.prototype, "UI", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2Edge),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toArrayFromType(StudioProjectAttributesV2Variable_1.StudioProjectAttributesV2Variable, value.value);
    }, { toClassOnly: true })
], StudioProjectAttributesV2.prototype, "variablesList", void 0);
class StudioProjectAttributesV2UI {
    constructor() {
        this.id = "";
        this.staticMode = false;
    }
}
exports.StudioProjectAttributesV2UI = StudioProjectAttributesV2UI;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UIInput_1.StudioProjectAttributesV2UIInput),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toArrayFromType(StudioProjectAttributesV2UIInput_1.StudioProjectAttributesV2UIInput, value.value);
    }, { toClassOnly: true })
], StudioProjectAttributesV2UI.prototype, "input", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UIOutput_1.StudioProjectAttributesV2UIOutput),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toArrayFromType(StudioProjectAttributesV2UIOutput_1.StudioProjectAttributesV2UIOutput, value.value);
    }, { toClassOnly: true })
], StudioProjectAttributesV2UI.prototype, "output", void 0);
class StudioProjectAttributesV2Function {
    constructor() {
        this.description = "";
        this.name = "";
        this.codeData = {
            cssStr: "",
            htmlStr: "",
            jsStr: "",
        };
    }
}
exports.StudioProjectAttributesV2Function = StudioProjectAttributesV2Function;
class StudioProjectAttributesV3 {
}
exports.StudioProjectAttributesV3 = StudioProjectAttributesV3;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV3Node_1.StudioProjectAttributesV3Node)
], StudioProjectAttributesV3.prototype, "nodes", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV3Line_1.StudioProjectAttributesV3Line)
], StudioProjectAttributesV3.prototype, "edges", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UI)
], StudioProjectAttributesV3.prototype, "UI", void 0);
__decorate([
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toArrayFromType(StudioProjectAttributesV2Variable_1.StudioProjectAttributesV2Variable, value.value);
    }, { toClassOnly: true })
], StudioProjectAttributesV3.prototype, "variablesList", void 0);
