import { uploadTypes } from "@uiview/views/Nodes/constants";
import { Node } from "reactflow";
import { GROUPID_REG } from "@views/thinking-layout-editor/constants";
import { find, get, isString } from "lodash";
import { UploadType } from "@uiview/views/Nodes/typing";
import { StoryNodeType } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeType";

export class ReactFlowNodeUtil {
  static getNodeById(nodeId: string, nodes: Node<any>[]) {
    return find(nodes, ["id", nodeId]);
  }
  /**
   * 是否是组节点的ID
   * @param idString
   */
  static isGroupNodeId(idString: string) {
    return isString(idString) && GROUPID_REG.test(idString);
  }

  /**
   * 是否是 uploadFile 节点
   * @param node
   * @returns
   */
  static isUploadNode(node: Node) {
    const type = get(node, ["data", "displayType"]);
    return isString(type) && type ? uploadTypes.includes(type as UploadType) : false;
  }

  static getSubNodeIds(nodes: Node[], id: string, withSelf = true) {
    if (ReactFlowNodeUtil.isGroupNodeId(id)) {
      const ids = nodes.filter(node => node.parentNode === id).map(node => node.id);

      withSelf && ids.push(id);

      return ids;
    }
    return [id];
  }

  static getSubNodesByGroupId(groupId: string, nodes: Node[]) {
    if (ReactFlowNodeUtil.isGroupNodeId(groupId)) {
      return nodes.filter(x => x.parentNode === groupId);
    }
    return [];
  }

  /**
   * 仅获取边
   * @param nodes
   * @returns
   */
  static getCustomNewEdge(nodes: Node[]): Node[] {
    return nodes.filter(node => node.type === "customNewEdge");
  }

  /**
   * 仅获取普通节点
   * @param nodes
   * @returns
   */
  static getCustomNodes(nodes: Node[]): Node[] {
    return nodes.filter(node => node.type === StoryNodeType.CUSTOMNODE);
  }
}
