import styles from "./VeriryNow.module.scss";
import { AccountPageBloc } from "@uiview/pages/profile/account/AccountPageBloc";
export function VerifyNow(props: { bloc: AccountPageBloc }) {
  const text = "Your email is not verified with Imagica. Click Verify Now to complete email verification.";
  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
      <button className={styles.button} onClick={() => props.bloc.tapVerifyNow("Email")}>
        Verify Now
      </button>
    </div>
  );
}
