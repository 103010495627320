import { useSignal } from "@preact/signals-react";
import { logEvent } from "@uikit/service/amplitude";
import AddToolTip from "@uiview/views/AISaas/AddToContent/addTooltip";
import { AddEdgeBackView } from "@views/thinking-layout-editor/AddEdgeBackView/AddEdgeBackView";
import cls from "classnames";
import { find, get } from "lodash";
import { BluePrintInfoModal } from "@uiview/views/Nodes/BluePrintNode/components/BluePrintInfoModal";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Handle, Position } from "reactflow";
import useIntroTooltip from "../../custom-hooks/useIntroTooltip";
import { useObserverNodeSize } from "imagica-uikit/dist/hooks/useObserverNodeSize";
import func from "@uikit/func";
import { Feedback } from "../components/Feedback/Feedback";
import { TooltipWhatTodo } from "../components/TooltipWhatTodo";
import NodeToolBar, { collapseOrExpand } from "./NodeToolBar";
import { AI_INPUT_PLACEHOLDER, AI_INPUT_PLACEHOLDER_MULTI_NODES, HANDLES_V3 } from "./constants";
import style from "./css/CustomGroup.module.css";
import useGroupDraggable from "../../custom-hooks/useGroupDraggable";
import { getTopParentNodeId } from "../../custom-hooks/useGroupDraggable/GroupDragUtil";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { CustomNodeUtil } from "./CustomNodeUtil";
import { getIt } from "@uikit/getIt";
import { HomeStore, GroupCOEStatus } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { EdgeAddIcon } from "@views/assets/EdgeAddIcon";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { NodeEdgeInfoUpdater } from "@uikit/service/NodeEdgeInfoUpdater";
import { LineParam } from "@uikit/project/HomeUsePluginData";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { isBlank } from "imagica-corekit/dist/base/cutil/Extensions";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { NodeTitleUtil } from "@uiview/views/Nodes/NodeTitleUtil";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { CreatorCanvasDeleteMethods } from "@uikit/service/CreatorCanvasDeleteService";
import { AddGroup } from "@uikit/service/AddGroup";

export default memo(({ data, ...props }) => {
  const fotReactFlow = getIt(FotReactFlow);
  const gql = getIt(ObjectRelationGqlService);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const creatorCanvasDeleteMethods = getIt(CreatorCanvasDeleteMethods);
  const nodeEdgeInfoUpdater = getIt(NodeEdgeInfoUpdater);
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const creatorPreviewState = useStore(previewStore).value;
  const creatorPreviewService = getIt(CreatorPreviewService);
  const addGroup = getIt(AddGroup);
  // FIXME:nodesRef getTopParentNodeId
  const nodesRef = useMemo(
    () => ({
      get current() {
        return creatorNodesStore.getNodes();
      },
    }),
    []
  );
  const currGroupCOEData = homeStoreState.groupCOEData.find(x => x.groupId === props.id) || {
    groupId: "",
    groupCOEStatus: GroupCOEStatus.Expand,
    collapseChildNodes: [],
  };
  const isOpenBluePrintInfo = useSignal(false);

  const feature_tags = homeStoreState.featureTags;

  const customGroupRef = useRef(null);
  const groupHovered = useSignal(false);

  // use creatorNodeStore
  const nodeId = getTopParentNodeId({ id: data.parentNodeId, nodesRef });
  useGroupDraggable({ nodeId, nodeRef: customGroupRef });

  const { size } = useObserverNodeSize(customGroupRef, false);

  const [feedback] = useState(creatorNodesStore.getNodes().find(x => x.id === props.id)?.feedback);
  const [likeActive, setLikeActive] = useState(feedback?.user_input == 1);
  const [dislikeActive, setDislikeActive] = useState(feedback?.user_input == -1);

  const checkNodeArr = useSelector(state => state.fot.checkNodeArr);
  const highlightData = useSelector(state => state.fot.highlightData);
  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);
  const isDraggingCreatingNewLine = useSelector(state => state.editor.isDraggingCreatingNewLine);
  const disableAddPreview = useSelector(state => state.fot.disableAddPreview);

  const isBluePrintParentNode = data.displayType === "blueprint" && func.isEmpty(highlightData.parentNode);

  const { introTooltipObj } = useIntroTooltip(props.id, "group");

  const onMouseEnter = () => {
    groupHovered.value = true;
  };
  const onMouseLeave = () => {
    groupHovered.value = false;
  };

  const showFeedback = useSignal(false);

  const edges = creatorEdgesStore.getEdges();
  useEffect(() => {
    if (data.isInBlueprint) {
      return false;
    }
    const targetNodes = edges.map(x => x.target);
    showFeedback.value = targetNodes.includes(props.id);
  }, [edges]);

  const clickGroupNodeAdd = handle => {
    // clickGroupAddBtn(handle.direction, props, setCenter, getZoom, displayType)

    const currentNode = creatorNodesStore.getNodes().find(x => x.id === props.id);
    addGroup.clickGroupAddBtn(
      handle.direction,
      {
        ...props,
        offsetHeight: get(customGroupRef, "current.offsetHeight", 0),
        width: currentNode.style?.width || currentNode.width,
        height: currentNode.style?.height || currentNode.height,
      },
      fotReactFlow.setCenter,
      fotReactFlow.getZoom,
      "text"
    );
  };
  const highlightName = () => {
    let highlightClass = "";
    if (!func.isEmpty(highlightData) && !func.isEmpty(highlightData.parentNode)) {
      highlightClass = highlightData.parentNode === props.id ? style["highlight"] : style["lowBright"];
    }
    return highlightClass;
  };
  const customGroupClassName = useMemo(() => {
    let arr = [
      style["custom-group"],
      data.displayType === "blueprint" ? style.blueprint : "",
      checkNodeArr.includes(props.id) ? style["selected-highlight-border"] : "",
      highlightName(),
    ];
    // 没有feedback
    if (!showFeedback.value) {
      // 有group折叠
      if (homeStore.state.featureTags.enableGroupCollapse) {
        arr = arr.concat([style["noFeedbackCollapseGroup"]]);
      } else {
        arr = arr.concat([style["noOthers"]]);
      }
    } else {
      // 有feedback且没有group折叠
      if (!homeStore.state.featureTags.enableGroupCollapse) {
        arr = arr.concat([style["noCollapseGroup"]]);
      }
    }
    return arr.join(" ");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkNodeArr.length,
    data.displayType,
    highlightName,
    homeStore.state.featureTags.enableGroupCollapse,
    props.id,
    showFeedback.value,
  ]);

  const groupCollapseClass = useMemo(() => {
    const arr = [
      style.collapseOrExpand,
      homeStore.state.featureTags.enableGroupCollapse && !showFeedback.value ? style.adjustGroupCollapse : "",
    ];
    return arr.join(" ");
  }, [homeStore.state.featureTags.enableGroupCollapse, showFeedback.value]);

  const childNodeStockLoading = () => {
    creatorNodesStore.setNodes(prevList => {
      return prevList.map(x => {
        if (data.childNode.includes(x.id)) {
          const newData = Object.assign({}, x.data, { stockLoading: data.stockLoading });
          return {
            ...x,
            data: newData,
          };
        }
        return x;
      });
    });
  };

  const handleLike = () => {
    if (!likeActive) {
      if (dislikeActive) {
        updateFeedbackDislikeCancel();
        setDislikeActive(false);
      }
      //like
      updateFeedbackLike();
    } else {
      //cancel like
      updateFeedbackLikeCancel();
    }
    setLikeActive(!likeActive);
  };
  const handleDislike = () => {
    if (!dislikeActive) {
      if (likeActive) {
        updateFeedbackLikeCancel();
        setLikeActive(false);
      }
      //dislike
      updateFeedbackDislike();
    } else {
      //cancel dislike
      updateFeedbackDislikeCancel();
    }
    setDislikeActive(!dislikeActive);
  };

  const updateFeedbackDislike = () => {
    setNodeFeedback(-1, false, false);
  };

  const updateFeedbackDislikeCancel = () => {
    setNodeFeedback(0, false, true);
  };

  const updateFeedbackLike = () => {
    setNodeFeedback(1, true, false);
  };

  const updateFeedbackLikeCancel = () => {
    setNodeFeedback(0, true, true);
  };

  const setNodeFeedback = async (feedbackValue, isLike, isCancel) => {
    const nodesArr = creatorNodesStore.getNodes();
    const edgesArr = creatorEdgesStore.getEdges();
    const isCurrNodeSaved =
      !func.isEmpty(selectedTemplate.v2?.nodes?.find(x => x.id === props.id)) ||
      !func.isEmpty(selectedTemplate.v3?.nodes?.find(x => x.id === props.id)); //if curr project / node is not saved, save the feedback later when the project is saved

    try {
      let currEdge = edgesArr.find(x => x.target === props.id);
      let sourceNode = nodesArr.find(x => x.id === currEdge.source);
      currEdge = sourceNode;
      const flowsData = sourceNode?.data?.flows.find(x => x?.creationMethod) || sourceNode?.data?.flows[0] || {};
      const sourceNodeId = flowsData?.sourceNodeId || "";
      sourceNode = nodesArr.find(x => x.id === sourceNodeId);

      const lineType = currEdge?.data?.lineParam?.lineType || ""; //'identifier' for user-entered prompts, 'prompt' for functions
      const promptId = currEdge?.data?.lineParam?.identifier?.value || ""; //prompt id for user-entered prompts
      const function_name = currEdge?.data?.lineParam?.enterText || ""; //function name for functions

      let feedback = null;
      if (lineType == "prompt") {
        //functions
        feedback = {
          user_input: feedbackValue,
          type: lineType,
          function_name: function_name,
        };
      } else {
        //user-entered prompts, lineType == "identifier"
        feedback = {
          user_input: feedbackValue,
          type: lineType,
          id: promptId,
        };
      }

      const updatednNodes = nodesArr.map(l => {
        //to be saved to db
        if (l.id === props.id) {
          return {
            ...l,
            feedback: feedback,
          };
        }
        return l;
      });

      creatorNodesStore.setNodes(updatednNodes);

      // update selectedTemplate.v2.nodes
      const updatedProject = {
        ...selectedTemplate,
        v2: { ...selectedTemplate.v2, nodes: updatednNodes },
        v3: {
          ...selectedTemplate.v3,
          nodes: updatednNodes,
        },
      };

      // save to db
      if (!func.isEmpty(selectedTemplate.index) && isCurrNodeSaved) {
        //if project is not newly created AND current node is already saved, call api to save feedback to node
        await gql.updateObject(parseInt(selectedTemplate.id), {
          name: "studio_project",
          attributes: updatedProject,
        });
      }

      const eventProperties = {
        line_param: currEdge?.data?.lineParam,
        feedback: feedback,
        created_from_cot: currEdge?.data?.lineParam?.createFromCot,
        source_type: sourceNode?.data?.displayType || "Group",
        source_value: sourceNode?.data?.textAreaValue || "",
        target_type: data?.displayType || "Group",
        target_value: data?.textAreaValue || "",
      };

      if (isLike) {
        if (isCancel) {
          //cancel like
          logEvent("user_feedback_like_cancel", eventProperties);
        } else {
          //like
          logEvent("user_feedback_like", eventProperties);
        }
      } else {
        if (isCancel) {
          //cancel dislike
          logEvent("user_feedback_dislike_cancel", eventProperties);
        } else {
          //dislike
          logEvent("user_feedback_dislike", eventProperties);
        }
      }
    } catch (error) {
      func.messageError(error);
    }
  };

  function syncCollapseStatus({ nodeId, nodes }) {
    const currChildNodes = nodes.filter(x => x.parentNode === props.id);
    const isGroupCollapse = currChildNodes.some(x => x.hidden);
    if (isGroupCollapse) {
      homeStore.setGroupCOEData({
        groupId: props.id,
        groupCOEStatus: GroupCOEStatus.Collapse,
        collapseChildNodes: currChildNodes,
      });
      // 显示第一个子 node 下的背景
      creatorNodesStore.setNodes(prevList => {
        return prevList.map(x => {
          if (x.id === nodeId) {
            return {
              ...x,
              data: {
                ...x.data,
                showGroupCollapseData: true,
              },
            };
          }
          return x;
        });
      });
    }
  }

  useEffect(() => {
    const nodeId = data?.childNode?.[0];
    if (func.isEmpty(nodeId)) return;
    const nodes = creatorNodesStore.getNodes();
    // 同步保存的折叠状态
    syncCollapseStatus({ nodeId, nodes });
    // 同步 group 的 displayType为第一个child node的displayType
  }, []);

  useEffect(() => {
    if (data.stockLoading) {
      childNodeStockLoading();
    }
  }, [data.stockLoading]);

  // 3123 解决copy后group宽度太宽的问题(暂时解决办法)
  useEffect(() => {
    if (size.width > 800) {
      creatorNodesStore.setNodes(prevList => {
        return prevList.map(x => {
          if (x.id === props.id) {
            return {
              ...x,
              width: 270,
              style: {
                ...x.style,
                width: 270,
              },
            };
          }
          return x;
        });
      });
    }
  }, [size]);

  useEffect(() => {
    const nodes = creatorNodesStore.getNodes();
    const newEdgeNode = find(nodes, node => node.data.targetNodeId === props.id);
    if (isBlank(newEdgeNode) === false) {
      const lineParam = JsonUtil.toModelFromType(LineParam, newEdgeNode.data.lineParam) ?? new LineParam();
      nodeEdgeInfoUpdater.updateNodeText(lineParam);
    }
  }, []);

  const isLeftHandleConnectable = useCallback(
    handle => {
      return (
        handle.direction === "left" &&
        (!isDraggingCreatingNewLine.state || isDraggingCreatingNewLine.nodeId === props.id)
      );
    },
    [isDraggingCreatingNewLine.state, isDraggingCreatingNewLine.nodeId, props.id]
  );

  const getCorrespondingHiddenNodes = (needHideChildNodes, hidden) => {
    const allNodes = creatorNodesStore.getNodes();
    return allNodes.map(x => {
      if (needHideChildNodes.some(y => y.id === x.id)) {
        return {
          ...x,
          hidden,
        };
      }
      return x;
    });
  };

  const clickGroupCollapseOrExpand = () => {
    const allNodes = creatorNodesStore.getNodes();
    // 将所有child nodes保存到store
    let childNodes = [];
    if (currGroupCOEData.groupCOEStatus === GroupCOEStatus.Expand) {
      const ids = ReactFlowNodeUtil.getSubNodeIds(allNodes, props.id, false);
      childNodes = allNodes.filter(x => ids.includes(x.id));
      homeStore.setGroupCOEData({
        groupId: props.id,
        collapseChildNodes: childNodes,
      });
    }
    const realChildNodes = !func.isEmpty(childNodes) ? childNodes : currGroupCOEData.collapseChildNodes;
    // 保留第一个node显示
    const firstNode = realChildNodes[0];
    let newNodes = [];
    const needHideChildNodes = realChildNodes.slice(1);
    // 展开
    if (currGroupCOEData.groupCOEStatus === GroupCOEStatus.Collapse) {
      newNodes = getCorrespondingHiddenNodes(needHideChildNodes, false);
    } else if (currGroupCOEData.groupCOEStatus === GroupCOEStatus.Expand) {
      // 收起
      newNodes = getCorrespondingHiddenNodes(needHideChildNodes, true);
    }
    // 第一个node底部显示折叠后的样式
    newNodes = newNodes.map(x => {
      if (x.id === firstNode.id) {
        return {
          ...x,
          data: {
            ...x.data,
            showGroupCollapseData: currGroupCOEData.groupCOEStatus === GroupCOEStatus.Expand,
          },
        };
      }
      return x;
    });
    // 0、1状态互转
    homeStore.setGroupCOEData({
      groupId: props.id,
      groupCOEStatus: 1 - currGroupCOEData.groupCOEStatus,
    });
    // 确保在折叠状态更改后再执行
    setTimeout(() => {
      // 重新计算group高度、位置
      const nodes = CustomNodeUtil.resetGroupPosition({
        init: false,
        disableAddPreview,
        nodeData: firstNode.data,
        nodesCurrent: newNodes,
      });
      creatorNodesStore.setNodes(nodes);
    });
  };

  const defaultBotBoxClassName = cls(style["default-bot-box"], "nodrag");

  const isShowAddToContentToolTip = creatorPreviewState.isClickAddToContent && creatorPreviewState.isAddOutputNode;

  const toggleIsOpenBluePrintInfo = useCallback(bool => {
    isOpenBluePrintInfo.value = bool;
  }, []);
  const nodeGroupTitle = useMemo(() => NodeTitleUtil.getNodeTitle({ data }), [data]);

  const isBluePrintType = data.displayType === "blueprint";
  return (
    <div ref={customGroupRef} className={customGroupClassName} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {/* <p>{props.id}</p> */}
      {/* group title */}
      {isBluePrintType && <header className={style.customGroupTitle}>{get(data, "textAreaValue.name", "")}</header>}
      {/* tool bar */}
      <div className={style.groupTitle}>{nodeGroupTitle}</div>
      {groupHovered.value && !creatorPreviewState.isClickAddToContent ? (
        <div className={style.customGroup}>
          {!introTooltipObj.show ? (
            <>
              <NodeToolBar
                id={props.id}
                data={data}
                type={props.type}
                styl={{ top: "-5px", zIndex: 2 }}
                nodeAttr={props}
                displayType={data.displayType}
                guideShowTop={data.guideShowTop}
                clickToPreviewNode={creatorPreviewService.clickGroupToPreview}
                clickNodeInfo={() => toggleIsOpenBluePrintInfo(true)}
                clickGroupCollapseOrExpand={clickGroupCollapseOrExpand}
                handlePreviewDeleteNode={creatorCanvasDeleteMethods.handleDeleteGroupNodeByPreviewBtn}
              />
              <BluePrintInfoModal
                isOpen={isOpenBluePrintInfo.value}
                name={data?.textAreaValue?.data?.name ?? data?.textAreaValue?.name ?? "Tips"}
                description={data?.textAreaValue?.data?.description ?? data?.textAreaValue?.description ?? ""}
                closeInfo={() => toggleIsOpenBluePrintInfo(false)}
              />
            </>
          ) : null}
        </div>
      ) : null}
      {
        // v3中没有top，以前的版本有top，所以这段代码不影响v3以前的版本
        HANDLES_V3.map(handle => handle.direction).includes("top") || (
          <div
            className={`${style["custom-handle-box"]} ${style["custom-handle-box-hover-top"]}`}
            onClick={() => {
              clickGroupNodeAdd({ direction: "right" });
            }}
          />
        )
      }
      {/* add to content tool bar */}
      {isShowAddToContentToolTip && (
        <AddToolTip
          id={props.id}
          data={data}
          clickToPreviewNode={creatorPreviewService.clickGroupToPreview}
          handlePreviewDeleteNode={creatorCanvasDeleteMethods.handleDeleteGroupNodeByPreviewBtn}
        />
      )}
      {HANDLES_V3.map((handle, dIdx) => {
        // group拖动

        // if(handle.direction === 'top') {
        //   return (
        //     <div
        //       key={dIdx}
        //       className={`${style['custom-handle-box']} ${style['custom-handle-box-' + handle.direction]}`}
        //     > {
        //         showGroupDragBar ? <div className={`${style['top-bar-visible']}`}></div> : null
        //       }
        //     </div>
        //   )
        // }
        return (
          <div
            key={dIdx}
            className={`${style["custom-handle-box"]} ${style["custom-handle-box-" + handle.direction]}`}
            onClick={() => {
              if (handle.direction == "right") {
                clickGroupNodeAdd(handle);
              }
            }}
          >
            {/*右侧添加按钮的底部白色效果*/}
            {handle.direction === "right" && (
              <AddEdgeBackView fill={"#E4F3FE"} className={`${style["add-edge-back-view"]}`}></AddEdgeBackView>
            )}
            {handle.direction === "top" ? (
              <div
                key={dIdx}
                className={`${style["custom-handle-box"]} ${style["custom-handle-box-" + handle.direction]}`}
              ></div>
            ) : null}

            {handle.types.map((handleType, hIdx) => {
              return (
                <Handle
                  key={hIdx}
                  id={handleType.handleId || dIdx + handle.direction}
                  className={`${style["custom-handle"]} ${style["custom-handle-" + handle.direction]}`}
                  // style={handleType.type === 'source' ? { zIndex: 2 } : targetHandleStyle}
                  type={handleType.type}
                  isConnectable={!isLeftHandleConnectable(handle)}
                  position={Position[handle.position]}
                ></Handle>
              );
            })}
            {handle.direction === "right" ? (
              <div className={defaultBotBoxClassName}>
                {/* 添加icon */}
                <span data-testid="edge-addicon" className={`${style["edge-add-icon"]}`}>
                  <EdgeAddIcon />
                </span>
                {/* 预览块 */}
                <div className={style["preview-box"]}>
                  {/* 模拟输入框 */}
                  <div className={style["line-input"]}>
                    {creatorNodesStore.getNodes().length > 1 ? AI_INPUT_PLACEHOLDER_MULTI_NODES : AI_INPUT_PLACEHOLDER}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}

      <TooltipWhatTodo
        id={props.id}
        type={"group"}
        show={introTooltipObj.show}
        title={introTooltipObj.title}
        content={introTooltipObj.content}
        classNameSuffix={introTooltipObj.classNameSuffix}
        tooltipClassName={style.tooltipClassName}
      >
        <div className={style["group-node-box"]}>
          {feature_tags.enableGroupCollapse && (
            <div className={groupCollapseClass}>
              <div className={style.collapseOrExpandBtn} onClick={clickGroupCollapseOrExpand}>
                {collapseOrExpand[currGroupCOEData.groupCOEStatus].value}
              </div>
            </div>
          )}
          {/* 删除节点的图标及弹窗 */}
          {showFeedback.value && (
            <Feedback
              class={"groupNode"}
              id={props.id}
              likeActive={likeActive}
              dislikeActive={dislikeActive}
              handleLike={handleLike}
              handleDislike={handleDislike}
              isBluePrintParentNode={isBluePrintParentNode}
            />
          )}
        </div>
      </TooltipWhatTodo>
    </div>
  );
});
