import { MutableRefObject } from "react";
import {
  StudioProjectAttributesV2Edge,
  StudioProjectAttributesV2EdgeData,
  StudioProjectAttributesV2EdgeDataLineParam,
} from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { StudioProjectAttributesV2Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Node";
import { StudioProjectAttributesV3Line } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Line";
import { StudioProjectAttributesV3Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Node";
import { createNewLineParam } from "@uikit/project/HomeUsePluginData";
import func from "@uikit/func";
import {
  DEFAULT_LINE_STYLE,
  DEFAULT_MARKER_END,
  OFFSET_DISTANCE,
  TRANSPARENT_MARKER_END,
  NEW_EDGE_REG,
} from "../../views/thinking-layout-editor/constants";
import { Rect, Node } from "reactflow";
import isBlank from "@sedan-utils/is-blank";
import { getNodeCenter } from "./EdgeCalculate";
import store from "@store/index";
import { studioActions } from "@store/studio";

import { DataRefUtil } from "./DataRefUtil";
import { StoryNodeDisplayType } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeDisplayType";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";

export enum Direction {
  Horizontal = "horizontal",
  Vertical = "vertical",
}
type overlappingType = { position: number; node: Node } | undefined;
class BlockData {
  public width?: number;
  public height?: number;
  public direction: Direction = Direction.Horizontal;
}

export type GetFitViewResult = Rect & {
  zoom: number;
};

export class HomeUsePluginUtil {
  static getFunctionData(data: any): { [key: string]: any } {
    const simpleNodeData: {
      [key: string]: any;
    } = {};
    Object.keys(data).forEach(x => {
      if (typeof data[x] === "function") {
        simpleNodeData[x] = data[x];
      }
    });
    return simpleNodeData;
  }
  static resetEdgeLineParamDataV3(
    edge: StudioProjectAttributesV3Node,
    lineParamData: StudioProjectAttributesV2EdgeDataLineParam
  ): StudioProjectAttributesV3Node {
    const edgeData = edge?.data as StudioProjectAttributesV2EdgeData;
    if (!edgeData || edge.type !== "customNewEdge") return edge;

    return {
      ...edge,
      data: {
        ...edgeData,
        lineParam: {
          ...edgeData.lineParam,
          ...lineParamData,
        },
      },
    };
  }
  static transformToV3EdgeLines(params: {
    nodes: any;
    edges: any;
    nodeIndexRef: MutableRefObject<number>;
    edgeIndexRef: MutableRefObject<number>;
    newEdgeDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV3Line>, any>;
    newLineDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV2EdgeData>, any>;
  }): { v3Edges: any[]; v3Lines: any[] } {
    if (typeof params.nodes === "string") {
      params.nodes = JSON.parse(params.nodes);
    }
    if (typeof params.edges === "string") {
      params.edges = JSON.parse(params.edges);
    }
    const v3Edges: any[] = [];
    const v3Lines: any[] = [];
    // 升序排列
    params.edges = [...params.edges].sort((a: any, b: any) => {
      return Number(a.id.split("-")[1]) - Number(b.id.split("-")[1]);
    });
    // v2边需要转换成v3边
    params.edges.forEach((edge: StudioProjectAttributesV2Edge) => {
      const existedV3EdgeIdx = v3Edges.findIndex(x => edge.target === x.data.targetNodeId);
      let existedV3Edge = v3Edges[existedV3EdgeIdx];
      // 多个输入
      if (!func.isEmpty(existedV3Edge)) {
        // 生成source线
        const sourceLine = HomeUsePluginUtil.generateTransformV3NewLine({
          newLineParam: {
            source: edge.source,
            target: existedV3Edge.id,
            type: "source",
          },
          edgeIndexRef: params.edgeIndexRef,
          data: DataRefUtil.getSimpleEdgeDataV3(edge, params.newLineDataRef),
        });
        // 添加新的flow
        existedV3Edge = HomeUsePluginUtil.addTransformV3EdgeFlow(existedV3Edge, sourceLine, edge);
        v3Edges.splice(existedV3EdgeIdx, 1, existedV3Edge);
        v3Lines.push(sourceLine);
      } else {
        // 生成边
        let v3Edge = HomeUsePluginUtil.transformToV3Edge({
          nodes: params.nodes,
          edge,
          newEdgeDataRef: params.newEdgeDataRef,
          nodeIndexRef: params.nodeIndexRef,
        });
        // 生成source、target线
        const sourceTargetV3Line = HomeUsePluginUtil.transformToV3Line({
          edge,
          v3Edge,
          edgeIndexRef: params.edgeIndexRef,
          newLineDataRef: params.newLineDataRef,
        });
        // 更新边
        v3Edge = HomeUsePluginUtil.updateTransformV3Edge(v3Edge, sourceTargetV3Line, edge);
        v3Edges.push(v3Edge);
        v3Lines.push(...sourceTargetV3Line);
      }
    });

    return {
      v3Edges,
      v3Lines,
    };
  }
  //eslint-disable-next-line
  static handleV2TransformV3Data(param: {
    attributesV2: {
      v2: any;
    };
    nodeIndexRef: MutableRefObject<number>;
    edgeIndexRef: MutableRefObject<number>;
    newLineDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV3Line>, any>;
    newEdgeDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV2EdgeData>, any>;
  }): { v3: any; v2: any } {
    const { attributesV2, nodeIndexRef, edgeIndexRef, newEdgeDataRef, newLineDataRef } = param;
    const { v2 } = attributesV2;
    const { nodes, edges } = v2;
    const { v3Edges, v3Lines } = HomeUsePluginUtil.transformToV3EdgeLines({
      nodes,
      edges,
      nodeIndexRef,
      edgeIndexRef,
      newEdgeDataRef,
      newLineDataRef,
    });

    return {
      ...attributesV2,
      v3: {
        ...attributesV2.v2,
        nodes: [...nodes, ...v3Edges],
        edges: v3Lines,
      },
    };
  }
  //eslint-disable-next-line
  // 将传入的edge转换为v3的edge
  static transformToV3Edge(param: {
    nodes: StudioProjectAttributesV2Node[];
    edge: StudioProjectAttributesV2Edge;
    nodeIndexRef: MutableRefObject<number>;
    newEdgeDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV2EdgeData>, any>;
  }): Record<string, any> {
    const { nodes, edge, newEdgeDataRef, nodeIndexRef } = param;
    const sourceNode = nodes.find((node: StudioProjectAttributesV2Node) => node.id === edge.source);
    const simpleEdgeData = Object.assign({}, DataRefUtil.getSimpleNodeDataV3(edge, newEdgeDataRef), {
      targetNodeId: edge.target,
    });
    const newEdge = {
      id: `new-edge-${nodeIndexRef.current++}`,
      type: "customNewEdge",
      zIndex: 1,
      data: {
        ...simpleEdgeData,
        flows: [
          {
            sourceNodeId: sourceNode?.id,
            sourceLineId: "",
            creationMethod: true,
          },
        ],
      },
      position: {
        x: (sourceNode?.position?.x || sourceNode?.positionAbsolute?.x || 0) + OFFSET_DISTANCE,
        y: sourceNode?.position?.y || sourceNode?.positionAbsolute?.y,
      },
    };
    return newEdge;
  }
  //eslint-disable-next-line
  static generateTransformV3NewLine(param: {
    newLineParam: Partial<createNewLineParam>;
    data: StudioProjectAttributesV2EdgeData;
    edgeIndexRef: MutableRefObject<number>;
  }): Record<string, any> {
    const { newLineParam, edgeIndexRef, data } = param;
    const { source, target, type } = newLineParam;

    return {
      id: `new-line-${edgeIndexRef.current++}`,
      type: "customNewLine",
      source,
      target,
      sourceHandle: "b",
      targetHandle: "a",
      markerEnd: type === "source" ? TRANSPARENT_MARKER_END : DEFAULT_MARKER_END,
      style: DEFAULT_LINE_STYLE,
      data,
    };
  }

  //eslint-disable-next-line
  static transformToV3Line(param: {
    edge: StudioProjectAttributesV2Edge;
    v3Edge: any;
    edgeIndexRef: MutableRefObject<number>;
    newLineDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV3Line>, any>;
  }): Record<string, any>[] {
    const { edge, v3Edge, edgeIndexRef, newLineDataRef } = param;
    const sourceLine = HomeUsePluginUtil.generateTransformV3NewLine({
      newLineParam: {
        source: edge.source,
        target: v3Edge.id,
        type: "source",
      },
      edgeIndexRef,
      data: DataRefUtil.getSimpleEdgeDataV3(edge, newLineDataRef),
    });
    const targetLine = HomeUsePluginUtil.generateTransformV3NewLine({
      newLineParam: {
        source: v3Edge.id,
        target: edge.target,
        type: "target",
      },
      edgeIndexRef,
      data: DataRefUtil.getSimpleEdgeDataV3(edge, newLineDataRef),
    });

    return [sourceLine, targetLine];
  }

  static updateTransformV3Edge(v3Edge: any, sourceTargetV3Line: any, edge: StudioProjectAttributesV2Edge): any {
    return Object.assign({}, v3Edge, {
      ...v3Edge,
      data: {
        ...v3Edge.data,
        targetLineId: sourceTargetV3Line[1].id,
        flows: v3Edge.data.flows.map((y: any) => {
          if (y.sourceNodeId === edge.source) {
            return {
              ...y,
              sourceLineId: sourceTargetV3Line[0].id,
            };
          }
          return y;
        }),
      },
    });
  }

  static addTransformV3EdgeFlow(existedV3Edge: any, sourceLine: any, edge: StudioProjectAttributesV2Edge): any {
    return Object.assign({}, existedV3Edge, {
      ...existedV3Edge,
      data: {
        ...existedV3Edge.data,
        flows: [
          ...existedV3Edge.data.flows,
          {
            sourceLineId: sourceLine.id,
            sourceNodeId: edge.source,
          },
        ],
      },
    });
  }

  /**
   * @deprecated
   * @param params
   * @returns
   */
  static updateGroupChildNode(params: {
    groupId: string;
    previewUI: any;
    allNode: Node[];
    thoughts: AISaasOutput.ThoughtType[];
    setSaasUIData: React.Dispatch<React.SetStateAction<PreviewApp.UISaasValueType>>;
    handleDeleteGroupNodeByPreviewBtn: (groupId: string) => any;
    creatorPreviewService: CreatorPreviewService;
  }): void {
    const { groupId, allNode, thoughts, setSaasUIData, creatorPreviewService } = params;
    const index = thoughts.findIndex((x: any) => x.groupId === groupId);
    if (index === -1) return;

    //不用删除原来的group 在addGroupNodeToPreview 方法中会根据groupChildeNodes生成新的output
    //  handleDeleteGroupNodeByPreviewBtn(groupId);
    //添加新的子node
    const groupChildeNodes = allNode.filter((x: any) => x.parentNode === groupId);
    const childList = creatorPreviewService.addGroupNodeToPreview(groupChildeNodes, true);
    const copyThought = thoughts.filter((x: any) => x.groupId !== groupId);
    copyThought.splice(index, 0, ...childList);

    console.log("list :>> ", copyThought);

    // bsf-4912 use setSassUIData
    setSaasUIData(prev => ({ ...prev, output: copyThought }));
  }

  static getNodePosition(node: StudioProjectAttributesV3Node, key: string): number {
    return (node.positionAbsolute as any)?.[key] || (node.position as any)?.[key] || 0;
  }
  static getNodeStyle(node: StudioProjectAttributesV3Node, key: string): number {
    return (node as any)?.[key] || (node as any).style?.[key] || 0;
  }
  static getNodesBlockRect(nodes: StudioProjectAttributesV3Node[]): {
    leftX: number;
    leftY: number;
    nodesBlockWidth: number;
    nodesBlockHeight: number;
  } {
    let leftX = this.getNodePosition(nodes[0], "x");
    let leftY = this.getNodePosition(nodes[0], "y");

    let rightX = this.getNodePosition(nodes[0], "x") + this.getNodeStyle(nodes[0], "width");
    let rightY = this.getNodePosition(nodes[0], "y") + this.getNodeStyle(nodes[0], "height");

    nodes.forEach(x => {
      const xAxis = this.getNodePosition(x, "x");
      const yAxis = this.getNodePosition(x, "y");
      const width = this.getNodeStyle(x, "width");
      const height = this.getNodeStyle(x, "height");

      leftX = leftX > xAxis ? xAxis : leftX;
      leftY = leftY > yAxis ? yAxis : leftY;

      rightX = rightX < xAxis + width ? xAxis + width : rightX;
      rightY = rightY < yAxis + height ? yAxis + height : rightY;
    });

    return {
      leftX,
      leftY,
      nodesBlockWidth: rightX - leftX,
      nodesBlockHeight: rightY - leftY,
    };
  }

  /**
   * 获取 canvas 适配合适到除开 blockData 区域外的整块画布到尺寸数据
   *
   * @param param
   * @returns
   */
  static getFitViewRect(param: {
    isShowToolTips?: boolean;
    nodes: StudioProjectAttributesV3Node[] | any;
    blockData?: BlockData;
    zoom?: number;
  }): GetFitViewResult | undefined {
    const { zoom: argsZoom } = param;
    let { nodes } = param;
    const { blockData, isShowToolTips = false } = param;
    if (isBlank(nodes)) return;
    nodes = nodes.filter(
      (x: StudioProjectAttributesV3Node) => func.isEmpty(x.parentNode) && x.type !== "customNewEdge"
    );

    let { leftX, leftY, nodesBlockWidth, nodesBlockHeight } = this.getNodesBlockRect(nodes);

    // 考虑显示 tooltip 的情况
    if (isShowToolTips) {
      const tooltipWidth = 350;
      const tooltipHeight = 150;
      leftX -= tooltipWidth / 6;
      leftY -= tooltipHeight;
      nodesBlockWidth = nodes.length > 1 ? nodesBlockWidth : tooltipWidth;
      nodesBlockHeight = nodesBlockHeight + tooltipHeight;
    }

    const distance = 1;
    const topNavBarHeight = 60 + 2 * distance;
    const bottomNavBarHeight = 60 + 2 * distance;
    const TopBottomNavBarHeight = topNavBarHeight + bottomNavBarHeight;
    const heightZoom = (window.innerHeight - TopBottomNavBarHeight) / nodesBlockHeight;
    // 这里添加默认canvas画布区域适配
    if (isBlank(blockData) || !blockData) {
      // FIXME: is 30,这里为了控制没有 previewpanel 时偏移量
      const widthZoom = window.innerWidth / nodesBlockWidth;
      const widthOrHeightZoom = Math.min(widthZoom, heightZoom);
      // 这里 5%的误差是由将 nodes 区域 zoom 到屏幕最大值时，此时nodesBlockWidth/window.innerWidth的值与当前 zoom 存在 5%的差值（可能是padding 造成的）
      const zoom = argsZoom || widthOrHeightZoom + 0.05;
      const currZoomTopNavBarHeight = topNavBarHeight / zoom;
      const currZoomBottomNavBarHeight = bottomNavBarHeight / zoom;

      return {
        zoom,
        x: leftX,
        y: leftY - currZoomTopNavBarHeight,
        width: nodesBlockWidth, // nodes区域总宽度
        height: nodesBlockHeight + currZoomTopNavBarHeight + currZoomBottomNavBarHeight, // 同理
      };
    }
    // 除开指定横向区域后的适配
    if (blockData.direction === Direction.Horizontal && blockData.width) {
      const blockDataWidth = blockData.width + 2 * distance;
      // 这里 5%的误差是由将 nodes 区域 zoom 到屏幕最大值时，此时nodesBlockWidth/window.innerWidth的值与当前 zoom 存在 5%的差值（可能是padding 造成的）
      // 填满指定横向区域合适的zoom
      const widthZoom = (window.innerWidth - blockDataWidth) / nodesBlockWidth;
      const widthOrHeightZoom = Math.min(widthZoom, heightZoom);
      const zoom = argsZoom || widthOrHeightZoom + 0.05;
      const currZoomTopNavBarHeight = topNavBarHeight / zoom;
      const currZoomBottomNavBarHeight = bottomNavBarHeight / zoom;
      // 在指定 zoom 下横向区域应该的值
      const currZoomBlockWidth = blockDataWidth / zoom;
      return {
        zoom,
        x: leftX,
        y: leftY - currZoomTopNavBarHeight,
        width: nodesBlockWidth + currZoomBlockWidth, // nodes区域总宽度与要避开的横向区域宽度之和
        height: nodesBlockHeight + currZoomTopNavBarHeight + currZoomBottomNavBarHeight, // 同理
      };
    }
    // if(blockData.direction === Direction.Vertical && blockData.height) {
    // todo: 这里可以继续添加处理纵向区域的代码
    // }
    return;
  }

  //只往下移和右移，返回当前是不是左右重叠
  static judgeXDirection(nodeA: Node, nodeB: Node): boolean {
    const centerA = getNodeCenter(nodeA);
    const centerB = getNodeCenter(nodeB);
    return Math.abs(centerA.x - centerB.x) > Math.abs(centerA.y - centerB.y);
  }

  static getOverlapNode(
    allNodes: Node[],
    currentNode: Node
  ): {
    leftOverlap: overlappingType;
    topOverlap: overlappingType;
    belowCurrentNode: Node[];
    rightOfCurrentNode: Node[];
  } {
    const space = 100;
    let leftOverlap: overlappingType = undefined;
    let topOverlap: overlappingType = undefined;
    const belowCurrentNode: Node[] = [];
    const rightOfCurrentNode: Node[] = [];
    //上面重复的Nodes
    allNodes.forEach((node: Node) => {
      const currentNodeX = currentNode.positionAbsolute?.x || currentNode.position.x || 0;
      const currentNodeY = currentNode.positionAbsolute?.y || currentNode.position.y || 0;
      const currentNodeWidth = Number(currentNode.style?.width || currentNode?.width || 200);
      const currentNodeHeight = Number(currentNode.style?.height || currentNode?.height || 200);
      const nodeX = node.positionAbsolute?.x || node.position.x || 0;
      const nodeY = node.positionAbsolute?.y || node.position.y || 0;
      const nodeWidth = Number(node.style?.width || node?.width || 200);
      const nodeHeight = Number(node.style?.height || node?.height || 200);
      const xOverlapping = nodeX + nodeWidth + space > currentNodeX && nodeX < currentNodeX + currentNodeWidth + space;
      const yOverlapping =
        nodeY + nodeHeight + space > currentNodeY && nodeY < currentNodeY + currentNodeHeight + space;
      //当前node右方的nodes
      if (nodeX > currentNodeX && yOverlapping) {
        rightOfCurrentNode.push(node);
      }
      //当前node下方的nodes
      if (nodeY > currentNodeY && xOverlapping) {
        belowCurrentNode.push(node);
      }
      //没有重叠，不继续
      if (!(xOverlapping && yOverlapping)) return false;
      // X轴重叠
      if (HomeUsePluginUtil.judgeXDirection(node, currentNode)) {
        //获取和当前node重叠的最右侧一个node位置
        if (!leftOverlap || nodeX + nodeWidth > leftOverlap.position) {
          leftOverlap = {
            position: nodeX + nodeWidth,
            node,
          };
        }
        return;
      }
      //获取和当前node重叠的最下方一个node位置
      if (!topOverlap || nodeY + nodeHeight > topOverlap.position) {
        topOverlap = {
          position: nodeY + nodeHeight,
          node,
        };
      }
    });
    return {
      leftOverlap,
      topOverlap,
      belowCurrentNode,
      rightOfCurrentNode,
    };
  }

  static hanleNodeOverlapping(args: {
    setNode: (pre: any) => any;
    allNodes: Node[];
    currentNode: Node;
    distance?: number;
  }): void {
    const distance = args.distance || 250;
    const otherNode = args.allNodes.filter((x: any) => x.id !== args.currentNode?.id);
    if (isBlank(otherNode)) return;

    const currentNodeX = args.currentNode.positionAbsolute?.x || args.currentNode.position?.x || 0;
    const currentNodeY = args.currentNode.positionAbsolute?.y || args.currentNode.position?.y || 0;
    let moveXDistance = 0;
    let moveYDistance = 0;
    const { leftOverlap, topOverlap, belowCurrentNode, rightOfCurrentNode } = HomeUsePluginUtil.getOverlapNode(
      otherNode,
      args.currentNode
    );

    //往下移动
    if (topOverlap && !isBlank(topOverlap)) {
      moveYDistance = topOverlap.position + distance - currentNodeY;
    }
    //往右移动
    if (leftOverlap && !isBlank(leftOverlap)) {
      moveXDistance = leftOverlap.position + distance - currentNodeX;
    }
    //不需要移动
    if (moveXDistance === 0 && moveYDistance === 0) return;

    args.setNode((preList: Node[]) => {
      return preList.map(pre => {
        let positionX = pre.positionAbsolute?.x || pre.position?.x || 0;
        let positionY = pre.positionAbsolute?.y || pre.position?.y || 0;
        //当前node
        if (pre.id === args.currentNode.id) {
          positionX += moveXDistance;
          positionY += moveYDistance;
        }
        //下方node, 重叠的node不动，当前node和其他node移动
        if (belowCurrentNode.some(node => node.id === pre.id && node.id !== topOverlap?.node?.id)) {
          positionY += moveYDistance;
        }
        //右方node，重叠的node不动，当前node和其他node移动
        if (rightOfCurrentNode.some(node => node.id === pre.id && node.id !== leftOverlap?.node?.id)) {
          positionX += moveXDistance;
        }
        return {
          ...pre,
          position: {
            x: positionX,
            y: positionY,
          },
        };
      });
    });
  }

  /**
   *
   * @param startNodeIds 起始node
   * @param allNodes 所有node
   * @param targetId 查找的目标node
   * @returns 在所有node中从startNodeId开始递归查找是否有target id为targetId
   */
  static findTargetHaveNode(startNodeIds: string[], allNodes: any[], targetId: string): boolean {
    const newTargetIds = [];
    for (let i = 0; i < startNodeIds.length; i++) {
      const startNodeId = startNodeIds[i];
      if (NEW_EDGE_REG.test(startNodeId)) {
        newTargetIds.push(allNodes.find(x => x.id === startNodeId)?.data?.targetNodeId);
      } else {
        // 考虑一个node 可能链接多条edge
        const allTarget = allNodes
          .filter(x => x.data.flows?.find((y: { sourceNodeId: string }) => y.sourceNodeId === startNodeId))
          .map(x => x.id);
        newTargetIds.push(...allTarget);
      }
    }

    if (isBlank(newTargetIds)) return false;
    if (newTargetIds.includes(targetId)) return true;
    return HomeUsePluginUtil.findTargetHaveNode(newTargetIds, allNodes, targetId);
  }

  static updateSelectedTemplate(args: Record<string, any>): void {
    const selectedTemplate = store.getState().studio.selectedTemplate;
    store.dispatch(studioActions.setSelectedTemplate({ ...selectedTemplate, ...args }));
  }
  static setStockNodeLoading(node: Node): Node {
    if (node.data?.displayType !== StoryNodeDisplayType.STOCKINFO) return node;
    return {
      ...node,
      data: {
        ...node.data,
        stockLoading: true,
      },
    };
  }
}
