import { internalsSymbol, NodeProps, Position } from "reactflow";
import { NEW_EDGE_REG } from "../../views/thinking-layout-editor/constants";
import { isEmpty } from "lodash";
import func from "@uikit/func";

type point = {
  x: number;
  y: number;
};
type WidthHeightType = "width" | "height";

// returns the position (top,right,bottom or right) passed node compared to
function getParams(nodeA: NodeProps, nodeB: NodeProps, fromTarget: boolean = false) {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  const position = getPosition(centerA, centerB, fromTarget);
  //连接边的点，指向边的center
  if (fromTarget) {
    return [centerA.x, centerA.y, position];
  }
  const [x, y] = getHandleCoordsByPosition(nodeA, position);

  return [x, y, position];
}

function getPosition(centerA: point, centerB: point, fromTarget: boolean) {
  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  // when the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
  if ((horizontalDiff > verticalDiff && !fromTarget) || (horizontalDiff < verticalDiff && fromTarget)) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    // here the vertical difference between the nodes is bigger, so we use Position.Top or Position.Bottom for the handle
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }
  return position;
}

function getHandleCoordsByPosition(node: any, handlePosition: Position) {
  // all handles are from type source, that's why we use handleBounds.source here
  const handle = node[internalsSymbol].handleBounds.source.find((h: any) => h.position === handlePosition);
  if (isEmpty(handle)) return [0, 0];
  let offsetX = handle?.width / 2;
  let offsetY = handle?.height / 2;

  // this is a tiny detail to make the markerEnd of an edge visible.
  // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
  // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
  // switch (handlePosition) {
  //   case Position.Left:
  //     offsetX = 0;
  //     break;
  //   case Position.Right:
  //     offsetX = handle.width;
  //     break;
  //   case Position.Top:
  //     offsetY = 0;
  //     break;
  //   case Position.Bottom:
  //     offsetY = handle.height;
  //     break;
  // }

  const x = node.positionAbsolute.x + handle?.x + offsetX;
  const y = node.positionAbsolute.y + handle?.y + offsetY;
  return [x, y];
}

export function getNodeCenter(node: any) {
  if (func.isEmpty(node))
    return {
      x: 0,
      y: 0,
    };
  return {
    x: (node?.positionAbsolute?.x || node.position.x) + getNodeWidthHeight(node, "width") / 2,
    y: (node?.positionAbsolute?.y || node.position.y) + getNodeWidthHeight(node, "height") / 2,
  };
}
export function getNodePosIncludesChild(node: any, allNode: any) {
  if (func.isEmpty(node))
    return {
      x: 0,
      y: 0,
    };

  if (!func.isEmpty(node.parentNode) && func.isEmpty(node?.positionAbsolute?.x)) {
    const parentNode = allNode.find((x: any) => x.id === node.parentNode);
    if (func.isEmpty(parentNode))
      return {
        x: 0,
        y: 0,
      };
    return {
      x: parentNode.position.x + node.position.x,
      y: parentNode.position.y + node.position.y,
    };
  }
  return {
    x: node?.positionAbsolute?.x || node.position.x,
    y: node?.positionAbsolute?.y || node.position.y,
  };
}

export function getNodeCenterIncludesChild(node: any, allNode: any) {
  const location = getNodePosIncludesChild(node, allNode);
  const width = getNodeWidthHeight(node, "width");
  const height = getNodeWidthHeight(node, "height");

  return {
    x: location.x + width / 2,
    y: location.y + height / 2,
  };
}
function getEdgeNodePosition(onePosition: Position, nodeHandlePos: point, edgeNodeCenter: point, fromTarget: boolean) {
  let position;
  if (onePosition === Position.Left || onePosition === Position.Right) {
    position = edgeNodeCenter.y > nodeHandlePos.y ? Position.Top : Position.Bottom;
  } else {
    position = edgeNodeCenter.x > nodeHandlePos.x ? Position.Left : Position.Right;
  }
  return position;
}

function getEdgePosition(
  source: NodeProps,
  target: NodeProps,
  firstSourceNode: NodeProps,
  realTargetNode: NodeProps,
  allNode: Array<NodeProps>
) {
  const fromEdge = NEW_EDGE_REG.test(source.id);
  const centerA = getNodeCenter(source);
  const centerB = getNodeCenter(target);
  //first source node and target node position should be same as obtained when calculating edge node position
  const centerFirstSourceNode = getNodeCenter(firstSourceNode);
  const centerRealTargetNode = getNodeCenter(realTargetNode);
  const sourceNodePos = getPosition(centerFirstSourceNode, centerRealTargetNode, false);
  const targetNodePos = getPosition(centerRealTargetNode, centerFirstSourceNode, false);
  const sourceHandlePos = getHandlePosition(firstSourceNode, allNode, sourceNodePos);
  const targetHandlePos = getHandlePosition(realTargetNode, allNode, targetNodePos);

  let sourcePos = Position.Right;
  let targetPos = Position.Left;
  if (!fromEdge) {
    sourcePos = sourceNodePos;
    targetPos = getEdgeNodePosition(sourceNodePos, sourceHandlePos, centerB, fromEdge);
  } else {
    sourcePos = getEdgeNodePosition(targetNodePos, targetHandlePos, centerA, fromEdge);
    targetPos = targetNodePos;
  }
  return [sourcePos, targetPos];
}
// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(
  source: NodeProps,
  target: NodeProps,
  firstSourceNode: NodeProps,
  realTargetNode: NodeProps,
  allNode: Array<NodeProps>
) {
  const fromEdge = NEW_EDGE_REG.test(source.id);
  //position
  const [sourcePos, targetPos] = getEdgePosition(source, target, firstSourceNode, realTargetNode, allNode);
  let [sx, sy] = getParams(source, target, fromEdge);
  let [tx, ty] = getParams(target, source, !fromEdge);

  // const padding = 0
  // const pos = fromEdge ? targetPos : sourcePos
  // const offsetX = (pos === Position.Top || pos === Position.Bottom) ? 0 : (pos === Position.Left ? padding : -padding)
  // const offsetY = (pos === Position.Left || pos === Position.Right) ? 0 : (pos === Position.Top ? padding : -padding)
  const offsetX = 0;
  const offsetY = 0;
  //when node is not edge node, get handle position
  if (fromEdge) {
    const targetLocation = getHandlePosition(realTargetNode, allNode, targetPos);
    tx = targetLocation.x + offsetX;
    ty = targetLocation.y + offsetY;
  } else {
    const sourceLocation = getHandlePosition(firstSourceNode, allNode, sourcePos);
    sx = sourceLocation.x + offsetX;
    sy = sourceLocation.y + offsetY;
  }

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}

function getMultiEdgeSourcePosition(source: NodeProps, firstSourcePos: Position, middlePoint: point) {
  const sourceCenter = getNodeCenter(source);
  if (firstSourcePos === Position.Left || firstSourcePos === Position.Right) {
    return sourceCenter.x < middlePoint.x ? Position.Right : Position.Left;
  }
  return sourceCenter.y < middlePoint.y ? Position.Bottom : Position.Top;
}

function getMultiEdgeSourceXY(source: any, sourcePos: Position, middlePoint: point) {
  const centerA = getNodeCenter(source);
  const handle = source[internalsSymbol].handleBounds.source.find((h: any) => h.position === sourcePos);
  let offsetX = handle.width;
  let offsetY = handle.height;
  //如果线会突出去一部分，就将起点设置为source node的中心店
  switch (sourcePos) {
    case Position.Left:
    case Position.Right:
      if (source.position.x - offsetX < middlePoint.x && source.position.x + source.width + offsetX > middlePoint.x) {
        return [centerA.x, centerA.y];
      }
      break;
    case Position.Top:
    case Position.Bottom:
      if (source.position.y - offsetY < middlePoint.y && source.position.y + source.height + offsetY > middlePoint.y) {
        return [centerA.x, centerA.y];
      }
  }

  const [sx, sy] = getHandleCoordsByPosition(source, sourcePos);
  return [sx, sy];
}

export function getMultiEdgeParams(source: NodeProps, target: NodeProps, firstSourcePos: Position, middlePoint: point) {
  const fromEdge = NEW_EDGE_REG.test(source.id);
  const [tx, ty, targetPos] = getParams(target, source, !fromEdge);

  const sourcePos = getMultiEdgeSourcePosition(source, firstSourcePos, middlePoint);
  const [sx, sy] = getMultiEdgeSourceXY(source, sourcePos, middlePoint);
  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}

export function getNodeWidthHeight(
  node: any,
  type: WidthHeightType,
  initWidth: number | undefined = undefined,
  initHieght: number | undefined = undefined
) {
  const baseSize = node?.[type] || 0;
  if (type === "width") {
    const styleWidth = node?.style?.[type] || baseSize;
    const maxWidth = node?.style?.["maxWidth"];
    if (maxWidth === "100%") {
      return styleWidth;
    }
    const styleMaxWidth = maxWidth || baseSize;
    if (styleWidth === 0 && styleMaxWidth === 0) {
      return initWidth;
    }
    return Math.min(styleWidth, styleMaxWidth);
  } else if (type === "height") {
    const styleHeight = node?.style?.[type] || baseSize;
    const styleMaxHeight = node?.style?.["maxHeight"] || baseSize;
    if (styleHeight === 0 && styleMaxHeight === 0) {
      return initHieght;
    }
    return Math.min(styleHeight, styleMaxHeight);
  }
  return baseSize;
}

export function getHandlePosition(node: any, allNode: any, position: Position) {
  const location = getNodePosIncludesChild(node, allNode);
  const width = getNodeWidthHeight(node, "width");
  const height = getNodeWidthHeight(node, "height");

  switch (position) {
    case Position.Left:
      location.y += height / 2;
      break;
    case Position.Right:
      location.x += width;
      location.y += height / 2;
      break;
    case Position.Top:
      location.x += width / 2;
      break;
    case Position.Bottom:
      location.x += width / 2;
      location.y += height;
      break;
  }
  return location;
}

export function getRcentHandlePos(
  sourceNode: NodeProps,
  targetNode: NodeProps,
  allNode: Array<NodeProps>
): Array<point> {
  const centerA = getNodeCenter(sourceNode);
  const centerB = getNodeCenter(targetNode);
  const sourcePos = getPosition(centerA, centerB, false);
  const targetPos = getPosition(centerB, centerA, false);

  const sourceHandlePos = getHandlePosition(sourceNode, allNode, sourcePos);
  const targetHandlePos = getHandlePosition(targetNode, allNode, targetPos);
  return [sourceHandlePos, targetHandlePos];
}
