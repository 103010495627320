"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lazyload = void 0;
class Lazyload {
    constructor(handler) {
        this.dataHandler = handler;
    }
    dirty() {
        this.dataPromise = undefined;
    }
    // 保留现有数据，直到加载成功后用新数据替换旧数据
    async renew() {
        const promise = this.dataHandler();
        return promise.finally(() => {
            this.dataPromise = promise;
        });
    }
    get() {
        if (!this.dataPromise) {
            this.dataPromise = this.dataHandler();
        }
        return this.dataPromise;
    }
}
exports.Lazyload = Lazyload;
