import { logEvent } from "@amplitude/analytics-browser";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import func from "@uikit/func";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { previewStore, PreviewStore } from "@uiview/store/PreviewStore";
import { PreviewContentUtil } from "@uiview/views/PreviewApp/PreviewContentUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { ProjectPageStore } from "@uikit/store/ProjectPageStore";
import { GROUPID_REG, NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { ViewBuilderWorker, ViewBuilderData } from "imagica-uikit/dist/nodesV2/ViewBuilderComponents/ViewBuilderWorker";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";
import isBlank from "@sedan-utils/is-blank";

/**
 * 原来 useHomePreviewMethods
 */
export class CreatorPreviewService {
  disablePublishDisplayType = CreatorNodesConsts.disablePublishDisplayType;

  constructor(
    public fotReduxStore: FotReduxStore,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public homePluginStore: HomePluginStore,
    public creatorNodesStore: CreatorNodesStore,
    public previewAppStore: PreviewAppStore,
    public projectNodeStore: ProjectNodeStore,
    public projectPageStore: ProjectPageStore,
    public previewStore: PreviewStore
  ) {}

  private addSingleNodeToPreview(node: any, isCotAddToPreview: boolean): AISaasOutput.ThoughtType[] {
    if (func.isEmpty(node)) return [];
    if (func.isEmpty(node.data.textAreaValue) && isCotAddToPreview === false) {
      func.customMsg({
        content: "The node is empty",
        type: "info",
      });
      return [];
    }
    const list = PreviewContentUtil.getPreviewOutputBySingleNode({
      saasUIData: this.creatorSaasAppStore.state.saasUIData as any,
      output: this.creatorSaasAppStore.state.saasUIData.output,
      addContentId: this.previewStore.state.addContentId,
      node,
      isShare: this.homePluginStore.state.isShare,
    });
    // stud-1832 chat ai input
    this.creatorSaasAppStore.setSaasUIData(prevData => {
      return {
        ...prevData,
        output: list,
      };
    });
    return list;
  }

  // 添加单个到 preview
  addIndividual = (node: any, previewUI = "", isCotAddToPreview = false): void => {
    previewUI = previewUI || this.previewStore.state.selectedUI;
    if (previewUI === "") {
      this.previewAppStore.setShowPreviewPanel(true);
      this.projectPageStore.state.awaitAddNode = node;
      return;
    }
    // OPT:
    if (this.disablePublishDisplayType.includes(node?.data?.displayType)) {
      return;
    }
    // bsf-4915 single 外部去掉了 parentNode, 未来可将下一行去掉， getPreviewOutputBySingleNode 内部实现了优先级
    const obj = Object.assign({}, { ...node }, { parentNode: "" });
    const list = this.addSingleNodeToPreview(obj, isCotAddToPreview);
    if (func.isEmpty(list)) return;
    // bsf-4912 use setSassUIData
    this.creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: list }));
    if (isCotAddToPreview === false) {
      this.previewAppStore.setShowPreviewPanel(true);
    }
    creatorRefStore.nodeContextMenuRef.current = null;
  };

  addGroupNodeToPreview(groupChildeNodes: any, getResult = false): AISaasOutput.ThoughtType[] {
    const list = PreviewContentUtil.getPreviewOutputByGroupNode({
      output: this.creatorSaasAppStore.state.saasUIData.output,
      groupChildeNodes,
      getResult,
      addContentId: this.previewStore.state.addContentId,
      isShare: this.homePluginStore.state.isShare,
    });
    this.creatorSaasAppStore.setSaasUIData(prevData => {
      return {
        ...prevData,
        output: list,
      };
    });
    return list;
  }

  // 添加group到 preview
  clickGroupToPreview = (node: any, previewUI = "", isCotAddToPreview = false): void => {
    previewUI = previewUI || this.previewStore.state.selectedUI;
    if (!GROUPID_REG.test(node.id)) return;
    if (previewUI === "") {
      this.previewAppStore.setShowPreviewPanel(true);
      this.projectPageStore.state.awaitAddNode = node;
      return;
    }
    const groupChildeNodes = this.creatorNodesStore.getNodes().filter(x => x.parentNode === node.id);

    // group
    const list = this.addGroupNodeToPreview(groupChildeNodes);
    // bsf-4912 use setSassUIData
    this.creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: list }));
    if (isCotAddToPreview === false) {
      this.previewAppStore.setShowPreviewPanel(true);
    }
    creatorRefStore.nodeContextMenuRef.current = null;
  };

  updatePreviewNodeContent(param: any): void {
    const { nodeId, textAreaValue, displayType, errorText, data = {} } = param;
    // bsf-4912 replace thoughts
    const tempThoughts = [...this.creatorSaasAppStore.state.saasUIData.output];

    // 处理 viewbuilderdata
    const showBuilderUI = ViewBuilderWorker.isShowBuilderUI(data?.showBuilderUI ?? false);
    let viewBuilderData = new ViewBuilderData();
    if (displayType === "text" && showBuilderUI) {
      viewBuilderData = data?.viewBuilderData || new ViewBuilderData();
    }

    const { content, contentType } = PreviewContentUtil.getPreviewContentByNode(param);
    // 更新ui数据
    const newSaasArr = HomeMethodsUtil.generateNewData(
      this.creatorSaasAppStore.state.saasUIData.output,
      nodeId,
      content,
      contentType,
      errorText,
      viewBuilderData
    ) as any[];
    // 更新SaasUIData
    const newSaasInput = HomeMethodsUtil.getNewInputData(
      this.creatorSaasAppStore.state.saasUIData.input,
      nodeId,
      textAreaValue,
      this.homePluginStore.state.isShare
    ) as any[];

    // stud-2493 set input(preview)
    this.creatorSaasAppStore.setSaasUIData(prevData => {
      return {
        ...prevData,
        input: newSaasInput,
        output: newSaasArr,
      };
    });
    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);

    //
    // 更新显示数据
    const newArr = HomeMethodsUtil.generateNewData(
      tempThoughts,
      nodeId,
      content,
      contentType,
      errorText,
      viewBuilderData
    );
    // bsf-4912 use setSassUIData
    this.creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: newArr as any[] }));
  }

  cancelCreatePreview(): void {
    this.projectPageStore.state.awaitAddNode = undefined;
  }

  // 关闭preview
  closePreviewApp(): void {
    logEvent("close_publish_x");
    this.previewAppStore.setShowPreviewPanel(false);
    this.fotReduxStore.setDisableAddPreview(true);
    this.cancelCreatePreview();
    this.previewAppStore.saasUiDataToPreview();
    // FIXME: 清空当前激活的 app, 判断是否时 chat app 时会根据当前的 saasuidata 判断, saasuidata 没有清空则数据没有发生变化 canvas addto 按钮状态会出现错误
    this.previewAppStore.clearCurrentPreviewApp();
  }

  // 添加多个到 preview
  clickToPreviewNode(node: any): void {
    let list = [] as any[];
    // opt:
    // const groupChildeNodes = isGroupChildeNodes(node);
    const groupChildeNodes = HomeMethodsUtil.isGroupChildeNodes(node, this.creatorNodesStore.getNodes());
    if (!func.isEmpty(groupChildeNodes)) {
      // group
      list = this.addGroupNodeToPreview(groupChildeNodes);
    }
    // bsf-4912 use setSassUIData
    this.creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: list }));
    this.previewAppStore.setShowPreviewPanel(true);
    creatorRefStore.nodeContextMenuRef.current = null;
  }

  edgeSourceNodeAsInput(nodeids: string[]): string[] {
    const edgeNodes = nodeids?.filter(id => NEW_EDGE_REG.test(id));

    if (!isBlank(edgeNodes)) {
      const allSourceNodes = this.creatorNodesStore
        .getNodes()
        .flatMap(node => {
          if (node.data.flows && edgeNodes.includes(node.id)) {
            const flows = node.data.flows as { sourceNodeId: string }[];
            return flows.map(flow => flow.sourceNodeId);
          }
          return "";
        })
        ?.filter(id => !isBlank(id));
      return allSourceNodes;
    }
    return [];
  }

  triggerRemovePreviewAppNode(args: PreviewApp.RemovePreviewAppNodeArgs): void {
    const { ids, edgeNodeIds = [], onSuccess } = args;

    let removeIds: string[] = [];

    let modalDes = "";
    const edgeSource = this.edgeSourceNodeAsInput(edgeNodeIds);
    if (
      PreviewAppValueLangUtil.hasNodeIds(
        previewStore.state.previewAppList,
        this.creatorSaasAppStore.state.saasUIData,
        edgeSource
      )
    ) {
      // 暂时由开发确定为如下文字 后续有变化再改
      modalDes = "This edge sourceNode is currently being used by preview app";
      removeIds = edgeSource;
    }

    if (
      PreviewAppValueLangUtil.hasNodeIds(
        previewStore.state.previewAppList,
        this.creatorSaasAppStore.state.saasUIData,
        ids
      )
    ) {
      removeIds = [...edgeSource, ...ids];
      modalDes = "This node is currently being used by preview app";
    }

    if (modalDes) {
      args.onOpenModal({
        subTitle: modalDes,
        removeIds,
      });
    } else {
      onSuccess(removeIds);
    }
  }
}
