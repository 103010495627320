"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlightApi = void 0;
const FlightRevalidate_1 = require("../api/flightTyped/FlightRevalidate");
class FlightApi {
    constructor(restRequest) {
        this.restRequest = restRequest;
    }
    async revalidate(session_id, fare_source_code) {
        const params = {
            session_id,
            fare_source_code,
        };
        const response = await this.restRequest
            .post("/be/flight/travelopro/api/aero/revalidate", params)
            .then(x => x.toModel(FlightRevalidate_1.AirRevalidateResponse, x => x.data.AirRevalidateResponse));
        return response;
    }
}
exports.FlightApi = FlightApi;
