import { DefaultProps } from "../../types";
import { FC, useMemo } from "react";
import cls from "classnames";
import style from "./style.module.scss";
import { TextTypes } from "./types";

export interface TextProps extends DefaultProps {
  className?: string;
  type?: TextTypes;
}

const Text: FC<TextProps> = ({ className, type = TextTypes.Default, children }) => {
  const imageClassName = useMemo(() => {
    return cls(
      style.text,
      type === TextTypes.Navigation && style.text_nav,
      type === TextTypes.ButtonGreen && style.text_buttonGreen,
      type === TextTypes.ButtonTransparent && style.text_buttonTransparent,
      type === TextTypes.ButtonBlack && style.text_buttonBlack,
      type === TextTypes.SidebarNew && style.text_sidebar,
      type === TextTypes.Sidebar && style.text_sidebarNew,
      className
    );
  }, [className, type]);

  return <span className={imageClassName}>{children}</span>;
};

export default Text;
