import { useEffect, useMemo } from "react";
import { Api } from "@uikit/service/api";
import { useCreation } from "ahooks";
import { Input, Switch, Upload } from "antd";
import func from "@uikit/func";
import { RcFile } from "antd/lib/upload";
import { AISaasLogoIcon } from "./icons";
import isBlank from "@sedan-utils/is-blank";
import styles from "./CustomizeBranding.module.scss";
import LoadingOutline from "@views/components/LoadingOutline";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { CustomizeBrandingBloc, CustomizeBrandingBlocState } from "./CustomizeBrandingBloc";
import { PowerMaskView } from "@uiview/views/PowerMask/PowerMaskView";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { PowerMaskUitl } from "@uiview/views/PowerMask/PowerMaskUtil";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

export function CustomizeBranding() {
  const api = new Api(false);
  const state = new CustomizeBrandingBlocState();
  const bloc = useCreation(() => new CustomizeBrandingBloc(state), []);
  const previewComposeState = getIt(PreviewComposeState);
  const previewAppStore = getIt(PreviewAppStore);
  const previewAppState = useImagicaStore(previewAppStore).value;
  const isInHistory = useMemo(() => {
    return previewComposeState.isInHistory();
  }, [
    previewAppState.historyShowStatus,
    previewAppState.publishShowStatus,
    previewAppState.previewShowStatus,
    previewAppState.settingShowStatus,
  ]);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const beforeUpload = async (file: RcFile): Promise<void> => {
    if (await PowerMaskUitl.disAbleByFeature(["add_your_own_branding"])) {
      return;
    }
    if (file.size > 50 * 1024 * 1000) {
      throw new Error("File Size Limit Exceeded: Max 5MB allowed.");
    }
    const objectURL = URL.createObjectURL(file);
    // 本地图片替换
    bloc.state.uploadLoge.value = objectURL;
  };

  const uploadLogo = async ({ file }: any): Promise<void> => {
    try {
      bloc.state.uploadPrompt.value = true;
      const formData = new FormData();
      formData.append("file", file, file.name);
      const res = await api.uploadUseXhr(`/api/me/upload/studios`, formData, {
        onProgress: () => {},
      });
      const s3Url = res?.data?.object_s3_url || res?.data?.s3_https_url || "";
      changeIconS3ToUrl(s3Url);
      creatorSaasAppStore.setSaasUIData((prev: any) => ({ ...prev, settings: { ...prev.settings, logoImg: s3Url } }));
    } catch (error) {
      func.messageError(error);
    } finally {
      bloc.state.uploadPrompt.value = false;
    }
  };

  async function changeIconS3ToUrl(iconUrl: string): Promise<void> {
    if (isBlank(iconUrl)) {
      bloc.state.uploadLoge.value = "";
      return;
    }
    const { fileUrl } = await api.getByCustomType(`/api/me/upload/im?url=${iconUrl}`, "blob");
    bloc.state.uploadLoge.value = fileUrl || "";
  }

  const onChange = async (checked: boolean): Promise<void> => {
    if (await PowerMaskUitl.disAbleByFeature(["add_your_own_branding"])) {
      return;
    }
    if (bloc.state.bottomPromptLoading.value) {
      return;
    }

    try {
      bloc.state.bottomPromptLoading.value = true;
      bloc.state.bottomPrompt.value = checked;

      creatorSaasAppStore.setSaasUIData((prev: any) => ({
        ...prev,
        settings: { ...prev.settings, branding: checked },
      }));
    } catch (error) {
      bloc.state.bottomPrompt.value = !checked;
      func.messageError(error);
    } finally {
      bloc.state.bottomPromptLoading.value = false;
    }
  };

  const onCopyrightChange = async (value: string): Promise<void> => {
    if (await PowerMaskUitl.disAbleByFeature(["add_your_own_branding"])) {
      return;
    }
    bloc.state.copyright.value = value;
    creatorSaasAppStore.setSaasUIData((prev: any) => ({ ...prev, settings: { ...prev.settings, copyright: value } }));
  };

  useEffect(() => {
    if (!func.isEmpty(bloc.state.uploadLoge.value)) return;
    const logoImg = saasUIData?.settings?.logoImg || "";
    if (!func.isEmpty(logoImg)) {
      changeIconS3ToUrl(logoImg);
    }
  }, [saasUIData?.settings?.logoImg]);

  useEffect(() => {
    bloc.state.bottomPrompt.value = !!saasUIData?.settings?.branding;
  }, [JsonUtil.stringify(saasUIData?.settings)]);

  useEffect(() => {
    if (isInHistory) {
      // 组件离开
      bloc.state.uploadLoge.value = "";
    }
  }, [isInHistory]);

  return (
    <PowerMaskView feature={["add_your_own_branding"]} featureItem="add_your_own_branding">
      <div className={styles.embedBox}>
        <div className={styles.title}>Customize Branding</div>

        <div className={styles.embedSizeBox}>
          <div className={styles.blackTitle}>Logo</div>
          <div className={styles.remindTitle}>Upload a 256 x 256 pixel ICO, PNG, GIF with transparent background.</div>

          <div className={styles.uploadCenterBox}>
            <div className={styles.logoBox}>
              {!func.isEmpty(bloc.state.uploadLoge.value) ? (
                <img className={styles.logoBoxImg} src={bloc.state.uploadLoge.value} alt="" />
              ) : (
                <AISaasLogoIcon />
              )}
            </div>
            <div className={styles.uploadTxtBox}>
              <Upload
                accept={".png, .jpg, .jpeg, image/jpeg"}
                maxCount={1}
                customRequest={uploadLogo}
                showUploadList={false}
                beforeUpload={file => {
                  beforeUpload(file);
                }}
              >
                <div className={styles.uploadTxt}>{bloc.state.uploadPrompt.value ? <LoadingOutline /> : "Upload"}</div>
              </Upload>
            </div>
          </div>

          <div className={styles.blackTitle}>Imagica Branding</div>

          <div className={styles.bottomPromptBox}>
            <div className={styles.testPrompt}>Use “Built with Imagica by Brain.ai” branding</div>
            <Switch
              checked={bloc.state.bottomPrompt.value}
              loading={bloc.state.bottomPromptLoading.value}
              onChange={e => onChange(e)}
            />
          </div>

          {/** Copyright */}
          <div>
            <div className={styles.blackTitle}>Copyright info</div>
            <Input
              className={styles.copyrightInput}
              placeholder="Copyright © 2023 Brain Technologies Inc"
              allowClear
              value={bloc.state.copyright.value}
              onChange={e => onCopyrightChange(e.target.value)}
              // onPressEnter={e => {e.target.blur()}}
            />
          </div>
        </div>
      </div>
    </PowerMaskView>
  );
}
