import { logEvent } from "@amplitude/analytics-browser";
import store from "@store/index";
import { EdgeRunService } from "@uikit/edgeRun/EdgeRunService";
import func from "@uikit/func";
import { Node } from "reactflow";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { uploadTypes } from "@uiview/views/Nodes/constants";
import { selectApiReg } from "@uiview/views/ProjectCanvas/homeConst";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { FotClient } from "imagica-corekit/dist/base/api/FotClient";
import { OpenAiParam } from "./OpenAiParam";
import { EdgeRunQuery } from "@uikit/edgeRun/EdgeRunQuery";
import { sleep } from "@views/thinking-layout-editor/util";
import { CotMsg } from "imagica-corekit/dist/base/msg/CotMsg";
import { HomeUsePluginUtil } from "@uikit/util/HomeUsePluginUtil";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import { ProjectPageStore } from "@uikit/store/ProjectPageStore";
import isBlank from "@sedan-utils/is-blank";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { uniqBy } from "lodash";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { HomeHandleFieldMethods } from "./HomeHandleFieldMethods";
import { CreatorPreviewService } from "./CreatorPreviewService";
import { editorActions } from "@store/editor";
import { TakeProjectSnapshot } from "./TakeProjectSnapshot";
import { eventbus } from "imagica-corekit/dist/base/cutil/Eventbus";
import { getDefaultPreviewAppTypeList } from "@uiview/views/PreviewApp/const";
import { previewStore } from "@uiview/store/PreviewStore";
import { CotStore } from "@uikit/store/CotStore";
import { popupFeedbackStore } from "@uikit/store/PopupFeedback";
import { CotFlow } from "imagica-corekit/dist/cases/cot/CotFlow";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";

const setProcessingCOT = (val: boolean): void => {
  store.dispatch(editorActions.setProcessingCOT(val));
};

/**
 * 原来 useCotFlow 和 useCotFlowV3
 */
export class CreatorCotService {
  constructor(
    public fotReduxStore: FotReduxStore,
    public fotReactFlow: FotReactFlow,
    public brainClient: FotClient,
    public creatorNodesStore: CreatorNodesStore,
    public homeMethodsService: HomeMethodsService,
    public edgeRunService: EdgeRunService,
    public openAiParam: OpenAiParam,
    private edgeRunQuery: EdgeRunQuery,
    private projectPageStore: ProjectPageStore,
    private creatorEdgesStore: CreatorEdgesStore,
    private creatorSaasAppStore: CreatorSaasAppStore,
    private previewAppStore: PreviewAppStore,
    private projectNodeStore: ProjectNodeStore,
    private homeStore: HomeStore,
    private creatorRefStore: CreatorRefStore,
    private creatorAISaasStore: CreatorAISaasStore,
    private homeHandleFieldMethods: HomeHandleFieldMethods,
    private creatorPreviewService: CreatorPreviewService,
    private takeProjectSnapshot: TakeProjectSnapshot,
    private cotStore: CotStore,
    private canvasDataRef: CanvasDataRef,
    private cotFlow: CotFlow
  ) {}

  showAllInputs = async (saasInputNodeIds: any[], saasNodes: any, index = 0): Promise<void> => {
    if (func.isEmpty(saasInputNodeIds) || func.isEmpty(saasNodes)) {
      func.messageUtil("Not found input node");
      return;
    }
    const show = async (i: number): Promise<void> => {
      if (i >= saasInputNodeIds.length || !this.cotStore.state.oldCotAnimationState.creating) {
        // 结束
        this.cotStore.setOldCotAnimationState({
          creating: false,
          inputIds: saasInputNodeIds,
          currentInputId: "",
        });
        return;
      }
      const inputNode = saasNodes.find((x: { id: any }) => x.id === saasInputNodeIds[i]);
      if (func.isEmpty(inputNode)) {
        func.messageUtil("Not found input node");
        return;
      }
      //   setCenter(inputNode.position.x + 250, inputNode.position.y + 100, { duration: 300, zoom: 1.2 });
      if (uploadTypes.includes(inputNode.data.displayType)) {
        return;
      }
      const result = await this.brainClient.cot.suggest(inputNode.data.placeholder);
      await this.setInputValueByWriter(inputNode.id, result?.suggested_input);
      await sleep(800);
      await show(i + 1);
    };

    // link: https://github.com/brain/micro-apps/pull/1761 去掉 tooltip
    this.fotReduxStore.setEnableTooltipWhatTodo(false);
    this.fotReduxStore.setShowTooltip("");

    await show(index);
  };

  async setInputValueByWriter(nodeId: any, fullText: any): Promise<void> {
    logEvent("click_submit", fullText);
    const write = async (i: number): Promise<void> => {
      await sleep(20);
      const txt = fullText.slice(0, i);
      this.creatorNodesStore.setNodes(prevList => {
        return prevList.map(node => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                textAreaValue: txt,
              },
            };
          }
          return node;
        });
      });
      if (i < fullText.length) {
        await write(i + 2);
      }
    };
    await write(1);
  }

  async updateNode(id: string, nodeData: any): Promise<any> {
    this.creatorNodesStore.setNodes((prevList: any) => {
      return prevList.map((l: any) => {
        if (l.id === id) {
          return {
            ...l,
            data: {
              ...l.data,
              lineParam: {
                ...l.data.lineParam,
                ...nodeData,
              },
            },
          };
        }
        return l;
      });
    });
  }

  async setQuery(group: { node: any; edgeNode: any; edges: any[] }): Promise<void> {
    let nodeData = {};
    const firstEdge = group.edges[0];
    const { node, edgeNode } = group;
    const enterText = firstEdge.data.lineParam.enterText;

    // 普通边并且后台没有返回identifier
    if (selectApiReg.test(enterText) === false && node.data && func.isEmpty(node.data.identifier)) {
      await this.edgeRunQuery.getAndSetQueryV3(edgeNode.id, enterText, true);
      return;
    }

    if (node.data && !func.isEmpty(node.data.identifier)) {
      // 后台接口返回了identifier
      const { identifier_type: type, identifier_value: value } = node.data.identifier;
      nodeData = {
        lineType: "identifier",
        identifier: { type, value },
      };
    } else {
      // 函数
      nodeData = { lineType: "identifier" };
    }

    await this.updateNode(edgeNode.id, nodeData);
    this.creatorNodesStore.setQueryLoadingV3({
      id: edgeNode.id,
      data: {
        isGetQuery: true,
        getQueryLoading: false,
      },
    });
    await this.edgeRunQuery.setEdgeValueByWriterV3(edgeNode.id, enterText, true);
  }

  async showFlowByGroupV3(group: { node: any; edgeNode: any; edges: any[] }): Promise<void> {
    const { node, edgeNode } = group;
    this.fotReactFlow.setCenter(edgeNode.position.x - 200, edgeNode.position.y, { duration: 300, zoom: 1.2 });
    await this.setQuery(group);
    await sleep(800);
    this.fotReactFlow.setCenter(node.position.x, node.position.y, { duration: 300, zoom: 1.2 });
    await this.edgeRunService.corekitRun({ edgeId: edgeNode.id });
    await sleep(800);
  }

  /**
   * TODO: 流程中的 `setCetner` 与节点和边的聚焦出现重复行为，需要优化
   * @see `useFocusNode`
   */
  async showNode(i: number): Promise<void> {
    // 所有的node都展示完了
    if (i > this.projectPageStore.state.saasEdgesStaging.length - 1) {
      await this.cotEndCallback();
    }

    // 退出当前项目停止流程
    if (!this.cotStore.state.cotAutomaticNode) return;

    const current = this.projectPageStore.state.saasEdgesStaging[i];
    if (current) {
      await this.processNode(current);
      await setTimeout(async () => {
        await this.showNode(i + 1);
      }, 1000);
    }
  }

  async processNode(currentFlow: any): Promise<void> {
    // 设置loading状态
    currentFlow.node = HomeUsePluginUtil.setStockNodeLoading(currentFlow.node);

    this.creatorEdgesStore.setEdges(prevList => [...prevList, ...currentFlow.edges]);
    this.creatorNodesStore.setNodes(prevList => [...prevList, currentFlow.node, currentFlow.edgeNode]);

    // Overlapping
    this.handleNodeOverlapping(currentFlow.node);

    // 展示当前分组的flow
    await this.showFlowByGroupV3(currentFlow);

    //运行后id有可能改变了, 重新获取最后一个node作为当前node
    const mainNode = this.creatorNodesStore
      .getNodes()
      .filter(node => !CreatorNodesConsts.NEW_EDGE_REG.test(node.id) && isBlank(node.parentNode));

    this.handleNodeOverlapping(mainNode.last() as unknown as Node);
  }

  handleNodeOverlapping(node: Node): void {
    HomeUsePluginUtil.hanleNodeOverlapping({
      setNode: this.creatorNodesStore.setNodes,
      allNodes: this.creatorNodesStore.getNodes().filter(x => !CreatorNodesConsts.NEW_EDGE_REG.test(x.id)),
      currentNode: node,
    });
  }

  /**
   * 此方法只在旧版本的COT流程中使用，新版本的COT流程不需要展示flow的动画
   */
  async cotEndCallback(): Promise<void> {
    const feature_tags = this.homeStore.state.featureTags;
    setProcessingCOT(false);

    if (feature_tags.showCotUserFeedback) {
      // when the graph is generated, take a snapshot for internal COT evaluation
      this.takeProjectSnapshot.takeProjectSnapshot();
    }

    this.cotStore.setCotAutomaticNode(false);

    await setTimeout(() => {
      eventbus.emit(new CotMsg());
      this.fotReactFlow.zoomTo(0.5, { duration: 500 });
    }, 800);

    await setTimeout(async () => {
      if (isBlank(this.creatorRefStore.COTInputOutputNodes.current) === false) {
        this.defaultOpenWebApp();
        await this.cotEndSetPlatForm();
        this.previewAppStore.openPreviewPage();
        this.previewAppStore.clearPreviewApp();
        this.previewAppStore.addPreview({ format: getDefaultPreviewAppTypeList()[0] });
        popupFeedbackStore.setEnableCOTFeedbackPopup(true);
      }
    }, 300);
  }

  /**
   * 此方法只在旧版本的COT流程中使用，新版本的COT流程不需要展示flow的动画
   */
  cotEndSetPlatForm(): void {
    const platforms = ["saas"];
    // stud-2493 set input(preview)(clear)
    // Empty input and output first
    this.creatorSaasAppStore.setSaasUIData(prevData => {
      return {
        ...prevData,
        output: [],
        input: [],
      };
    });
    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);
    const { input, output } = this.creatorRefStore.COTInputOutputNodes.current as any;
    const inputNodeIds = input.map((item: any) => `editor-${item}`);
    const outNodeIds = output.map((item: any) => `editor-${item}`);
    const currentNodes = this.creatorNodesStore.getNodes();
    const uniqNodes = uniqBy(currentNodes, node => node.id);
    const inputNodes = uniqNodes.filter(node => inputNodeIds.includes(node.id));
    const outPutNodes = uniqNodes.filter(node => outNodeIds.includes(node.id));
    const outPutGroupNodes = uniqNodes.filter(node => outNodeIds.includes(node.id));
    const inputIds = store.getState().fot.inputId;
    const addInputs = inputNodes.map(x => x.id);
    const newInputId = [...inputIds, ...addInputs];
    this.creatorAISaasStore.setInputId(newInputId);
    platforms.forEach(platform => {
      const needAddInput = inputNodes.filter(node =>
        (this.creatorSaasAppStore.state.saasUIData.input || []).every(input => input.id !== node.id)
      );
      needAddInput.forEach(input => {
        this.homeHandleFieldMethods.handleInputField(input, platform, this.creatorRefStore.cotDescription.current);
      });
      outPutNodes.forEach(node => this.creatorPreviewService.addIndividual(node, platform, true));
      outPutGroupNodes.forEach(node => this.creatorPreviewService.clickGroupToPreview(node, platform, true));
    });
  }

  addThink = async (thinkValue: any): Promise<void> => {
    if (func.isEmpty(thinkValue)) {
      func.customMsg({
        content: "Data cannot be empty.",
        type: "warning",
      });
      return;
    }
    /// corekit注册的
    this.homeMethodsService.emptyNodes();
    this.homeMethodsService.emptyPreviewApp();
    try {
      // setThinkLoading(true);
      /// STUD-1975 使用corekit里的cot解析方法
      const {
        inputs = [],
        previewData,
        extraSaasUIData,
        flowGroup,
      } = await this.cotFlow.getTemplate(
        thinkValue,
        this.canvasDataRef.nodeDataRef.current,
        this.canvasDataRef.newEdgeDataRef.current,
        this.canvasDataRef.newLineDataRef.current
      );
      const saasInputNodeIds = inputs.map(node => node.id);
      this.creatorSaasAppStore.setSaasUIData(prev => ({
        ...prev,
        fromCotTitle: extraSaasUIData?.title,
        fromCotSubtitle: extraSaasUIData?.subTitle,
      }));

      setTimeout(() => {
        this.creatorRefStore.COTInputOutputNodes.current = previewData;
        this.creatorNodesStore.setNodes(inputs as any[]);
        this.projectPageStore.setSaasEdgesStaging(flowGroup as any[]);
        // setThinkLoading(false);
        if (typeof thinkValue === "string") {
          this.creatorRefStore.cotDescription.current = thinkValue;
        }

        if (func.isEmpty(saasInputNodeIds)) {
          // util.messageUtil('Empty node');
          logEvent("empty_cot_result", previewData?.templateValue as any);
          this.cotStore.setShowUnsupported(true);
          return;
        }
        setTimeout(() => {
          this.cotStore.setOldCotAnimationState({
            creating: true,
            inputIds: saasInputNodeIds,
            currentInputId: saasInputNodeIds[0],
          });
          // bsf-1919 重置状态
          this.cotStore.setCotAutomaticNode(true);
          this.showAllInputs(saasInputNodeIds, inputs);
        }, 300);
      });
    } catch (error) {
      // setThinkLoading(false);
    }
  };

  defaultOpenWebApp(): void {
    // Cot 完成加载 默认打开 Web App
    // Cot Input 打开状态，不自动展开 Preview app
    if (this.cotStore.state.showCot) return;
    const webData = [
      {
        label: "AI App",
        value: "saas",
        isStaticApp: false,
      },
    ];

    this.projectPageStore.setIsPublish(false);
    this.previewAppStore.setShowPreviewPanel(true);
    previewStore.setSelectedUI(webData[0].value);
  }
}
