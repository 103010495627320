"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolPiece = exports.ToolPieces = void 0;
const Blueprint_1 = require("../blueprintTyped/Blueprint");
class ToolPieces {
}
exports.ToolPieces = ToolPieces;
class ToolPiece extends Blueprint_1.ListItem {
}
exports.ToolPiece = ToolPiece;
