"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MidjourneyAdapter = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const FunctionRegistry_1 = require("../../storyV2/function/FunctionRegistry");
class MidjourneyAdapter extends AdapterBase_1.AdapterBase {
    runResponseType() {
        return AdapterBase_1.RunResponseType.MIDJOURNEY;
    }
    async runNode(node, actionData, story, edge) {
        if (node === undefined) {
            return Promise.reject();
        }
        const func = this.funcRegistry.getByRegistName(FunctionRegistry_1.FunctionName.midjourney);
        const response = await func.run(node.content, {
            projectId: story.projectId,
            edgeId: edge.options.id,
            templateId: story.templateId,
        }, undefined, 1, undefined, undefined, undefined, () => edge.state === "ERROR");
        return response.data;
    }
}
exports.MidjourneyAdapter = MidjourneyAdapter;
