import React from "react";
import style from "./css/Setting.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fotActions } from "../../store/fot";
import { Menu } from "antd";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";

const creatorSaasAppStore = getIt(CreatorSaasAppStore);
const fontSizeArr = ["Large", "Normal", "Small"];

function Setting(props) {
  const dispatch = useDispatch();

  const setImageScrollDireaction = val => {
    dispatch(fotActions.setImageScrollDireaction(val));
  };
  const editorImageScrollDireaction = useSelector(state => state.fot.editorImageScrollDireaction);

  const updateNodeFormat = param => {
    // bsf-4912 replace thoughts
    const tempThoughts = creatorSaasAppStore.state.saasUIData.output;
    let newThoughts = [];
    // group
    if (!func.isEmpty(props.card?.parentNode)) {
      newThoughts = tempThoughts.map(x => {
        if (x.groupId === props.card.parentNode) {
          return {
            ...x,
            results: [
              {
                ...x.results[0],
                ...param,
              },
            ],
          };
        }
        return x;
      });
    } else {
      // single
      newThoughts = tempThoughts.map(x => {
        if (x.results[0]?.nodeId === props.card?.nodeId && x.results[0]?.previewId === props.card?.previewId) {
          return {
            ...x,
            results: [
              {
                ...x.results[0],
                ...param,
              },
            ],
          };
        }
        return x;
      });
    }
    creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: newThoughts }));
  };
  const selectImageScroll = val => {
    setImageScrollDireaction(val);
    // bsf-4912 replace thoughts
    const thoughts = creatorSaasAppStore.state.saasUIData.output;
    let arr = [];

    if (val === "horizontalScroll") {
      const imgObj = {
        angle: "Image List",
        implication: "",
        modifier: "",
        reason: "",
        results: [],
        grid: false,
      };
      thoughts.forEach(x => {
        const hasImage = x.results.some(j => props.isImageType(j));
        if (hasImage) {
          imgObj.results = imgObj.results.concat(x.results);
        } else {
          arr.push(x);
        }
      });
      if (imgObj.results) {
        arr.unshift(imgObj);
      }
    } else {
      arr = [...thoughts];
      const idx = thoughts.findIndex(x => x.angle === "Image List");
      if (idx === -1) return;
      const imgObj = thoughts[idx];
      arr.splice(
        idx,
        1,
        ...imgObj.results.map(x => {
          return {
            angle: "",
            implication: "",
            modifier: "",
            reason: "",
            groupId: x?.parentNode || "",
            results: [x],
            grid: false,
          };
        })
      );
    }
    // bsf-4912 use setSassUIData
    creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: arr }));
  };

  const textStyleArr = ["textBeautify", "noStyle"];
  const onSettingMenuClick = val => {
    if (textStyleArr.includes(val.key)) {
      updateNodeFormat({
        layout: val.key,
      });
      return;
    }
    if (fontSizeArr.includes(val.key)) {
      updateNodeFormat({
        fontSize: val.key,
      });
      return;
    }
    selectImageScroll(val.key);
  };

  return (
    <Menu onClick={onSettingMenuClick}>
      {!props.isImageType(props.card) ? (
        <Menu.ItemGroup key="fontSize" title="Font Size">
          <Menu.Item
            key="Large"
            className={`${style["setting-item"]} ${props.card.fontSize === "Large" ? style["selected-font"] : ""}`}
          >
            Large
          </Menu.Item>
          <Menu.Item
            key="Normal"
            className={`${style["setting-item"]} ${props.card.fontSize === "Normal" ? style["selected-font"] : ""}`}
          >
            Normal
          </Menu.Item>
          <Menu.Item
            key="Small"
            className={`${style["setting-item"]} ${props.card.fontSize === "Small" ? style["selected-font"] : ""}`}
          >
            Small
          </Menu.Item>
        </Menu.ItemGroup>
      ) : (
        <Menu.ItemGroup key="imageDirection" title="Image Direction">
          <Menu.Item
            key="horizontalScroll"
            className={`${style["setting-item"]} ${
              editorImageScrollDireaction === "horizontalScroll" ? style["selected-font"] : ""
            }`}
          >
            horizontal scroll
          </Menu.Item>
          <Menu.Item
            key="verticallyScroll"
            className={`${style["setting-item"]} ${
              editorImageScrollDireaction === "verticallyScroll" ? style["selected-font"] : ""
            }`}
          >
            vertically scroll
          </Menu.Item>
        </Menu.ItemGroup>
      )}
      {props.card.type === "textbox" ? (
        <Menu.ItemGroup key="TextDirection" title="Text Style">
          <Menu.Item
            key="textBeautify"
            className={`${style["setting-item"]} ${props.card.layout === "textBeautify" ? style["selected-font"] : ""}`}
          >
            Beautify
          </Menu.Item>
          <Menu.Item
            key="noStyle"
            className={`${style["setting-item"]} ${props.card.layout === "noStyle" ? style["selected-font"] : ""}`}
          >
            Nothing
          </Menu.Item>
        </Menu.ItemGroup>
      ) : null}
    </Menu>
  );
}

export default Setting;
