import React from "react";
import "../components-css/Food.css";

function Food(props) {
  return (
    <section className="CardHomeFood">
      <img src={props.image} />
      <section className="cardFood-middle">
        <section className="cardFood-left">
          <section className="FoodTitle">{props.dish}</section>
          <section className="SubInformation">Takeout, Delivery</section>
        </section>
        <section className="cardFood-right">Order here</section>
      </section>
      <p className="cardFood-descreption">{props.restaurant}</p>
    </section>
  );
}

export default Food;
