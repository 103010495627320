import React from "react";
import "../components-css/CookingStep.css";

function CookingStep(props) {
  return (
    <section className="CardHomeCookingStep">
      <section className={`signBox ${props.len > 9 ? "reduceText" : ""}`}>
        <span className="subscript">{props.id + 1}</span>
        <span className="splitLine"></span>
        <span className="total">{props.len}</span>
      </section>
      <p className="title">{props.content}</p>
    </section>
  );
}

export default CookingStep;
