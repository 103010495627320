"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardDataManager = void 0;
const CardDataServiceUtil_1 = require("../cardDataSearch/CardDataServiceUtil");
const LangUtil_1 = require("../../base/cutil/LangUtil");
/**
 * 数据请求的入口
 */
class CardDataManager {
    constructor(serviceRegistry, brainClient, bluePrintDataService) {
        this.serviceRegistry = serviceRegistry;
        this.brainClient = brainClient;
        this.bluePrintDataService = bluePrintDataService;
    }
    /**
     * 之前外接调用onboardingBuildVbTb的地方替换成这个，并且采用消息流的方式返回
     * @param data
     */
    async requestVBData(data) {
        return this.brainClient.openAi.onboardingBuildVbTb(data);
    }
    /**
     * 获取单个component 相关的谁
     * @param blueprintInfo
     * @param vbComponent
     * @param textAreaValue
     */
    async searchComponentData(blueprintInfo, componentId, appDescription, textAreaValue) {
        // blueprint 能获取到数据，就返回，如果无法获取数据，那么就走function 逻辑
        const result = await (0, LangUtil_1.tryPromise)(this.bluePrintDataService.executeBlueprint(blueprintInfo, componentId, appDescription));
        if (result.error) {
            const service = this.serviceRegistry.get(componentId);
            const searchParams = CardDataServiceUtil_1.CardDataServiceUtil.getParams(componentId, textAreaValue);
            if (service && searchParams) {
                const data = await service.search(searchParams);
                return data;
            }
            return Promise.reject(result.error);
        }
        return result.data;
    }
}
exports.CardDataManager = CardDataManager;
