import { DisplayType } from "@uiview/views/Nodes/typing";

export type ProjectNodeType = DisplayType | "node-group";

//============== text area value 类型声明
type ProjectTextValue = string;

//============== text area value 类型声明

/**
 * TODO: 补充其他类型
 */
export type ProjectNodeValues = {
  [key in Exclude<ProjectNodeType, "text">]: any;
} & {
  text: ProjectTextValue;
};

export class ProjectNode<T extends ProjectNodeType = "text"> {
  displayType: T;
  errorText? = "";
  inputTitle? = "";
  generatedLabel? = "";
  placeholder? = "";

  /**
   * TODO: 根据不同 displayType 设置默认值
   */
  value?: ProjectNodeValues[T] = "";

  /**
   * 用来保存原来 app list 的 app id
   */
  appId = "";

  /**
   * 该id用于标识原来 nodes 和 preview 中唯一的 id
   */
  id = "";

  constructor(displayType?: T) {
    this.displayType = (displayType || "text") as T;
  }
}
