import React from "react";

import "../components-css/Outfit.css";

function Outfit(props) {
  return (
    <section className="CardHomeOutfit">
      <section className="imgBox">
        <img src={props.image} />
      </section>
      <div className="outfitName">{props.name}</div>
      <div className="outfitPrice">{props.price}</div>
    </section>
  );
}

export default Outfit;
