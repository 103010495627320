import { useState } from "react";
import { useSelector } from "react-redux";
import ThreeBotsLoading from "./ThreeBotsLoading";

function VirtuosoFooter({ context: { contentFrameLoading } }) {
  const domains = useSelector(state => state.feed.domains);
  const selectedDomain = useSelector(state => state.feed.selectedDomain);
  const scrollToBottomblackList = useSelector(state => state.fot.scrollToBottomblackList);

  const [currentDomain] = useState(() => {
    return domains[selectedDomain];
  });

  return (
    <div
      style={{
        padding: "1rem",
        height: "50px",
      }}
    >
      {!scrollToBottomblackList.includes(currentDomain) && contentFrameLoading ? (
        <ThreeBotsLoading backColor="#adb4bc" />
      ) : null}
    </div>
  );
}

export default VirtuosoFooter;
