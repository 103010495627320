import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import "../views-css/UI.css";
import { Popconfirm } from "antd";
import HomeScreen from "./HomeScreen";
import Feed from "./Feed";
import ThinkingInput from "./components/ThinkingInput";
import { Api } from "../uikit/service/api";
import { homeScreenActions } from "../store/homeScreen";
import { fotActions } from "../store/fot";
import { feedActions } from "../store/feed";
import func from "@uikit/func";

const otherModes = ["studioMode", "editorMode"];

function UI({ UIMode, clickLogOut, className }) {
  const store = useStore();
  const dispatch = useDispatch();
  const api = new Api();

  const isMobile = window.navigator.userAgent.includes("Mobile");
  /* UI Variables */
  const mode = useSelector(state => state.fot.mode);
  const isFirstLoad = useSelector(state => state.fot.isFirstLoad);
  const previousMode = useSelector(state => state.fot.previousMode);
  const showChainThoughts = useSelector(state => state.fot.showChainThoughts);

  const homeCards = useSelector(state => state.homeScreen.homeCards);
  const setHomeCards = val => {
    dispatch(homeScreenActions.setHomeCards(val));
  };

  const view = useSelector(state => state.fot.view);
  const setView = val => {
    dispatch(fotActions.setView(val));
  };

  const setErrorState = val => {
    dispatch(fotActions.setErrorState(val));
  };

  const focusMode = useSelector(state => state.feed.focusMode);
  const setFocusMode = val => {
    dispatch(feedActions.setFocusMode(val));
  };

  const domains = useSelector(state => state.feed.domains);
  const setDomains = val => {
    dispatch(feedActions.setDomains(val));
  };

  const selectedDomain = useSelector(state => state.feed.selectedDomain);
  const setSelectedDomain = val => {
    dispatch(feedActions.setSelectedDomain(val));
  };

  const thoughts = useSelector(state => state.feed.thoughts);
  const setThoughts = val => {
    dispatch(feedActions.setThoughts(val));
  };

  const focusedThought = useSelector(state => state.feed.focusedThought);
  const setFocusedThought = val => {
    dispatch(feedActions.setFocusedThought(val));
  };

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);

  const extractDomain = useSelector(state => state.fot.extractDomain);
  const setDomain = val => {
    dispatch(fotActions.setDomain(val));
  };
  const emptyDomain = val => {
    dispatch(fotActions.emptyDomain(val));
  };

  const thoughtChain = useSelector(state => state.fot.thoughtChain);
  const setThoughtChain = val => {
    dispatch(fotActions.setThoughtChain(val));
  };

  const setPage = val => {
    dispatch(fotActions.setPage(val));
  };

  const setLoadingType = val => {
    dispatch(fotActions.setLoadingType(val));
  };
  const setDomainLoading = val => {
    dispatch(fotActions.setDomainLoading(val));
  };
  const setDomainDisabled = val => {
    dispatch(fotActions.setDomainDisabled(val));
  };
  const setContentLoading = val => {
    dispatch(fotActions.setContentLoading(val));
  };

  const [lastViews, setLastViews] = useState([]);
  // const [doaminFetch, setDoaminFetch] = useState(null);
  // var [selectedDomain, setSelectedDomain] = useState(-1);
  // var [isThought, setIsThought] = useState(false);
  const [reactions, setReactions] = useState([". . .", ". . .", ". . .", ". . .", ". . ."]);
  const changeDomainApiGet = useRef(null);
  const selectThoughtApiGet = useRef(null);
  const contentRetrievalGet = useRef(null);

  // var [showReactions, setShowReactions] = useState(false);
  // var [placeHolder, setPlaceHolder] = useState("Start Thinking...");
  // const [homeCardInitState, setHomeCardInitState] = useState(true);

  /** */

  /* Input */

  // var [query, setQuery] = useState("");

  // const onChangeQuery = e => {
  //   setQuery(e.target.value);
  //   if (view != "home") {
  //     setShowReactions(true);
  //   }
  // };

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   setQuery("");
  //   enter_query(query);
  // };

  /** */
  /* Bottom Menu */

  const [bottomMenuVisible, setBottomMenuVisible] = useState(true);

  const toggle_bottom_menu = () => {
    setBottomMenuVisible(!bottomMenuVisible);
    // setShowReactions(false);
  };

  /** */

  /* Constants */
  const dummy_card = useRef({
    type: "dummy",
    content: ". . .",
    thought: ". . .",
    filters: [".", ".", "."],
    angles: [".", ".", "."],
    reactions: [".", ".", "."],
    likes: [".", ".", "."],
    dislikes: [".", ".", "."],
  });

  const dummy_angle_block = {
    angle: ". . .",
    modifier: "",
    results: [dummy_card.current, dummy_card.current, dummy_card.current],
  };

  const dummy_thoughts = [dummy_angle_block, dummy_angle_block];

  const dummy_domains = [".", ".", ".", ".", "."];
  const dummy_reactions = [". . .", ". . .", ". . .", ". . .", ". . ."];

  const loadingDummy = {
    loading: true,
  };

  const retryViewDummy = {
    showRetryView: true,
  };

  /* Studio Mode -- Hardcoded for now*/
  const getStateCopy = () => {
    return {
      view,

      homeCards,

      focusMode,
      domains,
      selectedDomain,
      thoughts,
      focusedThought,
    };
  };

  /** */

  /* UI Functions */

  const initState = key => {
    switch (key) {
      case "productionMode":
        dispatch(feedActions.restoreDefaultValues());
        getUrlParameter();
        break;
      case "testMode":
        dispatch(feedActions.restoreDefaultValues());
        getUrlParameter();
        break;
      case "studioMode":
        setView("feed");
        setHomeCards([]);
        setDomains(["outfit"]);
        setSelectedDomain("0");
        setFocusedThought({
          type: "thought",
          thought: "some thought",
          content: "some thought",
        });
        setThoughts([]);
        break;
      case "editorMode":
        setHomeCards([]);
        break;
    }
  };

  const initOrRestoreState = (key, savedState) => {
    if (savedState) {
      setHomeCards(savedState.homeCards);
      setDomains(savedState.domains);
      setSelectedDomain(savedState.selectedDomain);
      setFocusedThought(savedState.focusedThought);
      setThoughts(savedState.thoughts);
      setView(savedState.view);
    } else {
      initState(key);
    }
  };

  const get_home_cards = () => {
    setHomeCards([
      dummy_card.current,
      dummy_card.current,
      dummy_card.current,
      dummy_card.current,
      dummy_card.current,
      dummy_card.current,
    ]);

    const url = "/homecards";
    setPage("home");
    setLoadingType({
      page: "home",
      value: true,
    });
    // 设置domain，thought为空白
    setDomains(dummy_domains);
    setThoughts(dummy_reactions);

    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res?.data.homecards || res?.data || [];
        if (data.length > 0) {
          setHomeCards(data);
        }
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingType({
          page: "home",
          value: false,
        });
      });
  };

  // useEffect(() => {
  //   console.error(mode)
  //   if (mode === 'testMode') {
  //     get_home_cards();
  //   } else {
  //     setView('feed');
  //     setDomains(['outfit']);
  //     setSelectedDomain('outfit');
  //     setFocusedThought({
  //       type: 'thought',
  //       thought: 'some thought',
  //       content: 'some thought'
  //     });
  //     setThoughts([]);
  //   }

  // }, []);
  const getUrlParameter = () => {
    const parameter = !func.isEmpty(window.location.search)
      ? window.location.search.replace("?", "")
      : window.location.hash.split("?")[1];

    if (func.isEmpty(parameter)) {
      setView("home");
      get_home_cards();
      return;
    }
    const arr = parameter.split("&");
    let obj = {};
    arr.map(x => {
      let param = x.split("=");
      if (param.length == 2) {
        obj[param[0]] = param[1];
      }
    });
    if (!func.isEmpty(obj.thought)) {
      setView("feed");
      enter_query(decodeURI(obj.thought), "fromUrlThought");
    } else {
      setView("home");
      get_home_cards();
    }
  };

  useEffect(() => {
    dispatch(fotActions.setMode(mode));
  }, []);

  useEffect(() => {
    if (mode !== "productionMode") {
      const modeToCopy = previousMode;
      if (!isFirstLoad && previousMode !== "productionMode") {
        func.setLocalStorage(modeToCopy, getStateCopy());
      }
      const savedState = func.getLocalStorage(mode);
      initOrRestoreState(mode, savedState);
    } else {
      initOrRestoreState(mode);
    }
  }, [mode]);

  // useEffect(() => {

  //   if (view == "home") {
  //     setPlaceHolder("Start Thinking...");
  //   } else {
  //     setPlaceHolder("What's Your Reaction...?");
  //   }

  // }, [view]);

  // useEffect(() => {
  //   const state = homeCards.some(x => x.type === 'dummy')
  //   setHomeCardInitState(state)
  // }, [homeCards])

  // 点击首页列表项最终触发
  const select_home_card = (id, text) => {
    if (text === ". . .") return;
    // setThoughts(dummy_thoughts);

    // setDomains(dummy_domains);
    // setReactions(dummy_reactions);

    const oldFocusedThought = { ...focusedThought };
    const oldChain = [...thoughtChain];

    setFocusedThought({
      ...focusedThought,
      thought: text,
    });
    setThoughtChain([
      ...thoughtChain,
      {
        thought: text,
      },
    ]);

    // 清空预加载数据
    emptyDomain({});

    const url = "/homecard/" + id;
    setContentLoading(true);
    setDomainLoading(true);
    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res.data;
        setDomains([...data["domains"]]);
        setThoughts([...data["thoughts"]]);
        setReactions([...data["reactions"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }
        // 默认请求
        lazy_domain(-1);
        setPage("thought");
        setThoughtChainWithData(data);
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);

        // handle error go back to previous state
        if (oldChain.length === 0) {
          setView("home");
          get_home_cards();
        }
        setFocusedThought(oldFocusedThought);
        setThoughtChain(oldChain);
      })
      .finally(() => {
        setContentLoading(false);
        setDomainLoading(false);
        dispatch(fotActions.setFetching(false));
      });
    setView("feed");
    setLastViews(lastViews.concat([view]));
    setFocusMode(true);
  };

  const setThoughtChainWithData = data => {
    const currentThought = data?.["focusedThought"]?.["thought"] || "";
    const hasCurrentThought = data?.["chain"]?.some(x => x.thought === currentThought);
    const arr = !func.isEmpty(data["chain"]) ? [...data["chain"]] : [];
    if (!hasCurrentThought) {
      arr.push({
        thought: currentThought,
      });
    }
    setThoughtChain(arr);
  };

  const enter_query = async (query, usingStr) => {
    // setThoughts(dummy_thoughts);
    // setDomains(dummy_domains);
    // setReactions(dummy_reactions);
    // setFocusedThought(dummy_card.current);
    const usingNewThoughtArr = ["fromNewThoughtEnter", "fromUrlThought", "fromChainThought"];
    let url = "";
    const isHomeView = view === "home";

    const oldFocusedThought = { ...focusedThought };
    const oldChain = [...thoughtChain];
    if (usingNewThoughtArr.includes(usingStr)) {
      setFocusedThought({
        ...focusedThought,
        thought: query,
      });
      if (usingStr !== "fromChainThought") {
        setThoughtChain([
          ...thoughtChain,
          {
            thought: query,
          },
        ]);
      }
      setView("feed");
      url = `/${usingStr === "fromChainThought" ? "back" : "newthought/domains"}/${query}`;
    } else {
      url = "/reaction/" + query;
    }
    setDomainLoading(true);
    setContentLoading(true);
    dispatch(fotActions.setFetching(true));
    // if 2nd api call to load content is needed
    const needLoadContentCall = url.includes("domains");

    try {
      // 取消正在请求的domain, thought的api调用
      changeDomainApiGet.current?.cancel?.();
      selectThoughtApiGet.current?.cancel?.();

      const res = await api.getWithError(url);
      const data = res.data;
      const thoughtsCopy = [...data["thoughts"]];

      if (needLoadContentCall) {
        // add loading block if content retrieval is not done
        thoughtsCopy.push(loadingDummy);
        // start content retrieval call
        ContentRetrieval("newThought");
      }

      setDomains([...data["domains"]]);
      setThoughts(thoughtsCopy);
      setFocusedThought(data["focusedThought"]);
      if (isHomeView) {
        setReactions([...data["reactions"]]);
      }
      if (usingNewThoughtArr.includes(usingStr)) {
        setThoughtChainWithData(data);
      }
      if (data["is_thought"]) {
        setSelectedDomain(-1);
      } else {
        setSelectedDomain(0);
      }

      setView("feed");
    } catch (error) {
      console.error("error::::", error);
      let errorStr = "Get new thought failed.";
      if (!usingNewThoughtArr.includes(usingStr)) {
        errorStr = "Get reaction failed.";
      }
      func.messageError(error);
      // 设置domain，thought为空白
      // setDomains(dummy_domains);
      // setThoughts(dummy_reactions);

      // handle error go back to previous state
      if (oldChain.length === 0) {
        setView("home");
        get_home_cards();
      }
      setFocusedThought(oldFocusedThought);
      setThoughtChain(oldChain);

      return Promise.reject(error);
    } finally {
      setDomainLoading(false);
      setContentLoading(false);
      dispatch(fotActions.setFetching(false));
    }

    setLastViews(lastViews.concat([view]));
    setFocusMode(isHomeView);
    // toggle_bottom_menu();

    // if (view == "home") { // Start with own idea
    //   setView("feed");
    //   url = '/newthought/' + query;

    //   setContentLoading(true)
    //   dispatch(fotActions.setFetching(true));
    //   api.getWithError(url)
    //     .then((res) => {
    //       const data = res.data
    //       setDomains([...data["domains"]]);
    //       setThoughts([...data["thoughts"]]);
    //       setFocusedThought(data["focusedThought"]);
    //       setReactions([...data["reactions"]]);
    //       if (data["is_thought"]) {
    //         setSelectedDomain(-1);
    //       } else {
    //         setSelectedDomain(0);
    //       }
    //       setView("feed");
    //     })
    //     .catch(error => {
    //       console.error('error::::', error)
    //       util.messageUtil(error.statusText || 'Get new thought failed.');
    //       setView("home");
    //     })
    //     .finally((_) => {
    //       setContentLoading(false)
    //       dispatch(fotActions.setFetching(false))
    //     });

    //   console.log(domains);
    //   console.log(thoughts);

    //   setLastViews(lastViews.concat([view]));
    //   setFocusMode(true);
    //   toggle_bottom_menu();

    // } else {

    //   const url = '/reaction/' + query;

    //   setContentLoading(true)
    //   dispatch(fotActions.setFetching(true));
    //   api.getWithError(url)
    //     .then((res) => {
    //       const data = res.data
    //       setDomains([...data["domains"]]);
    //       setThoughts([...data["thoughts"]]);
    //       setFocusedThought(data["focusedThought"]);
    //       if (data["is_thought"]) {
    //         setSelectedDomain(-1);
    //       } else {
    //         setSelectedDomain(0);
    //       }
    //       setView("feed");
    //     })
    //     .catch(error => {
    //       console.error('error::::', error)
    //       util.messageUtil(error.statusText || 'Get reaction failed.');
    //     })
    //     .finally((_) => {
    //       setContentLoading(false)
    //       dispatch(fotActions.setFetching(false))
    //     });

    //   console.log(domains);
    //   console.log(thoughts);
    //   console.log(focusedThought);

    //   setLastViews(lastViews.concat([view]));
    //   setFocusMode(false);
    //   toggle_bottom_menu();
    // }
  };

  const ContentRetrieval = async from => {
    const urlObj = {
      newThought: "/newthought/results",
      selectThought: "/thought/results",
    };
    const url = urlObj[from];
    if (!url) return;
    contentRetrievalGet?.current?.cancel?.();

    setDomainDisabled(true);
    dispatch(fotActions.setFetching(true));

    contentRetrievalGet.current = api.getWithErrorCanAbort(url);
    contentRetrievalGet.current
      .then(res => {
        const data = res.data;
        setThoughts([...data.thoughts]);
        setView("feed");
      })
      .catch(error => {
        if (error.constructor === DOMException && error.name === "AbortError") {
          return;
        }
        console.error("error::::", error);
        // util.messageUtil(error.statusText || 'Get content failed');
        // remove loading dummy
        const thoughtsCopy = [...store.getState().feed.thoughts].filter(x => !x.loading);
        thoughtsCopy.push(Object.assign({}, retryViewDummy, { from }));
        setThoughts(thoughtsCopy);
        setView("feed");
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setDomainDisabled(false);
      });
  };

  const select_thought = ({ id, rid, text, type }) => {
    // setThoughts(dummy_thoughts);
    // setDomains(dummy_domains);
    // setReactions(dummy_reactions);

    // // 使用点击的文本更新
    // dummy_card.current = {
    //   ...dummy_card.current,
    //   content: text,
    //   thought: text
    // }
    // setFocusedThought(dummy_card.current);
    const oldFocusedThought = { ...focusedThought };
    const oldChain = [...thoughtChain];

    setFocusedThought({
      ...focusedThought,
      thought: text,
    });
    setThoughtChain([
      ...thoughtChain,
      {
        thought: text,
      },
    ]);

    const obj = {
      thought: `/thought/domains/${id}&${rid}`,
      thoughtEdit: `/thought/edit/${text}`,
      openai: `/thought/add/${id}&${text}`,
    };
    const url = !func.isEmpty(obj[type]) ? obj[type] : `/thought/domains/${id}&${rid}`;

    console.log(url);

    setErrorState(false);

    setDomainLoading(true);
    setContentLoading(true);
    dispatch(fotActions.setFetching(true));

    // if 2nd api call to load content is needed
    const needLoadContentCall = url.includes("domains");

    // 可取消请求
    selectThoughtApiGet.current = api.getWithErrorCanAbort(url);
    selectThoughtApiGet.current
      .then(res => {
        const data = res.data;
        const thoughtsCopy = [...data["thoughts"]];

        if (needLoadContentCall) {
          // add loading block if content retrieval is not done
          thoughtsCopy.push(loadingDummy);
          // start content retrieval call
          ContentRetrieval("newThought");
        }

        setDomains([...data["domains"]]);
        setThoughts(thoughtsCopy);
        setReactions([...data["reactions"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }

        setThoughtChainWithData(data);
        setDomainLoading(false);
        setContentLoading(false);
        dispatch(fotActions.setFetching(false));
      })
      .catch(error => {
        if (error?.name === "AbortError") {
          console.info(`%c${error.message}`, "color:#2b85e4");
          return;
        }
        // setThoughts(dummy_reactions);
        setDomainLoading(false);
        setContentLoading(false);
        dispatch(fotActions.setFetching(false));

        console.error("error::::", error);
        func.messageError(error);

        // handle error go back to previous state
        if (oldChain.length === 0) {
          setView("home");
          get_home_cards();
        }
        setFocusedThought(oldFocusedThought);
        setThoughtChain(oldChain);
        setErrorState(true);
      });

    console.log(domains);
    console.log(thoughts);

    setLastViews(lastViews.concat([view]));
    setFocusMode(true);
  };

  const lazy_domain = id => {
    // 已经缓存的数据
    // if(store.getState().fot.extractDomain[id]) return;

    const url = "/domain/fetch/" + id;
    console.log(url);

    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(_ => {
        // 更新数据并保存
        // setDomain({ [id]: res.data })
      })
      .catch(error => {
        console.error("lazy_domain::::", error);
        // util.messageUtil(error.statusText || 'Get domain detail failed.');
      })
      .finally(_ => dispatch(fotActions.setFetching(false)));
  };

  const change_domain = id => {
    // 已经缓存的数据
    // if(extractDomain[id]) {
    //   const thought = extractDomain[id]["thoughts"]
    //   const noErr = thought.every(x => x.results.every(r => r.content !== 'ERROR'))
    //   if(noErr){
    //     setThoughts([...extractDomain[id]["thoughts"]]);
    //     setReactions([...extractDomain[id]["reactions"]]);
    //     return;
    //   }
    // }
    // setThoughts(dummy_thoughts);
    // setReactions(dummy_reactions);
    // const prevSelectedDomain = selectedDomain
    const url = "/domain/select/" + id;
    console.log(url);

    setContentLoading(true);
    dispatch(fotActions.setFetching(true));
    setSelectedDomain(id);

    // 可取消请求
    changeDomainApiGet.current?.cancel?.();
    changeDomainApiGet.current = api.getWithErrorCanAbort(url);
    return changeDomainApiGet.current
      .then(res => {
        const data = res.data;
        // 更新数据并保存
        setDomain({ [id]: data });
        setThoughts([...data["thoughts"]]);
        setReactions([...data["reactions"]]);
        setContentLoading(false);
        dispatch(fotActions.setFetching(false));
      })
      .catch(error => {
        if (error?.name === "AbortError") {
          console.info(`%c${error.message}`, "color:#2b85e4");
          return Promise.reject(error);
        }
        // 重置内容部分的thought值为空白
        setThoughts(dummy_reactions);
        setContentLoading(false);
        dispatch(fotActions.setFetching(false));
        console.error("error::::", error);
        // setSelectedDomain(prevSelectedDomain);
        func.messageError(error);
        return Promise.reject(error);
      });
  };

  const add_angle = (id, text) => {
    // setThoughts(dummy_thoughts);

    // setDomains(dummy_domains);
    // setReactions(dummy_reactions);

    // setFocusedThought(dummy_card.current);

    const url = "/angle/" + id;
    console.log(url);
    setDomainLoading(true);
    setContentLoading(true);
    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res.data;
        setDomains([...data["domains"]]);
        setThoughts([...data["thoughts"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }
        setView("feed");
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);
      })
      .finally(_ => {
        setDomainLoading(false);
        setContentLoading(false);
        dispatch(fotActions.setFetching(false));
      });

    console.log(domains);
    console.log(thoughts);

    setLastViews(lastViews.concat([view]));
    setFocusMode(false);
  };

  const delete_angle = angle => {
    // setThoughts(dummy_thoughts);

    // setDomains(dummy_domains);
    // setReactions(dummy_reactions);

    // setFocusedThought(dummy_card.current);

    const url = "/angle/del/" + angle;
    console.log(url);

    setDomainLoading(true);
    setContentLoading(true);
    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res.data;
        setDomains([...data["domains"]]);
        setThoughts([...data["thoughts"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }
        setView("feed");
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);
      })
      .finally(_ => {
        setDomainLoading(false);
        setContentLoading(false);
        dispatch(fotActions.setFetching(false));
      });

    console.log(domains);
    console.log(thoughts);

    setLastViews(lastViews.concat([view]));
    setFocusMode(false);
  };

  const add_reaction = id => {
    setThoughts(dummy_thoughts);

    setDomains(dummy_domains);
    setReactions(dummy_reactions);

    setFocusedThought(dummy_card.current);

    const url = "/reaction/" + reactions[id];
    console.log(url);

    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res.data;
        setDomains([...data["domains"]]);
        setThoughts([...data["thoughts"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);
      })
      .finally(_ => dispatch(fotActions.setFetching(false)));

    console.log(domains);
    console.log(thoughts);
    console.log(focusedThought);

    setLastViews(lastViews.concat([view]));
    setView("feed");
    setFocusMode(false);
    toggle_bottom_menu();
  };

  const delete_reaction = reaction => {
    setThoughts(dummy_thoughts);

    setDomains(dummy_domains);
    setReactions(dummy_reactions);

    setFocusedThought(dummy_card.current);

    const url = "/reaction/del/" + reaction;
    console.log(url);

    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res.data;
        setDomains([...data["domains"]]);
        setThoughts([...data["thoughts"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);
      })
      .finally(_ => dispatch(fotActions.setFetching(false)));

    console.log(domains);
    console.log(thoughts);

    setLastViews(lastViews.concat([view]));
    setView("feed");
    setFocusMode(false);
  };

  const add_like = id => {
    setThoughts(dummy_thoughts);

    setDomains(dummy_domains);
    setReactions(dummy_reactions);

    setFocusedThought(dummy_card.current);

    const url = "/like/" + id;
    console.log(url);

    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res.data;
        setDomains([...data["domains"]]);
        setThoughts([...data["thoughts"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);
      })
      .finally(_ => dispatch(fotActions.setFetching(false)));

    console.log(domains);
    console.log(thoughts);

    setLastViews(lastViews.concat([view]));
    setView("feed");
    setFocusMode(false);
  };

  const delete_like = like_query => {
    setThoughts(dummy_thoughts);

    setDomains(dummy_domains);
    setReactions(dummy_reactions);

    setFocusedThought(dummy_card.current);

    const url = "/like/del/" + like_query;
    console.log(url);

    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res.data;
        setDomains([...data["domains"]]);
        setThoughts([...data["thoughts"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);
      })
      .finally(_ => dispatch(fotActions.setFetching(false)));

    console.log(domains);
    console.log(thoughts);

    setLastViews(lastViews.concat([view]));
    setView("feed");
    setFocusMode(false);
  };

  const add_dislike = id => {
    setThoughts(dummy_thoughts);

    setDomains(dummy_domains);
    setReactions(dummy_reactions);

    setFocusedThought(dummy_card.current);

    const url = "/dislike/" + id;
    console.log(url);

    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res.data;
        setDomains([...data["domains"]]);
        setThoughts([...data["thoughts"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);
      })
      .finally(_ => dispatch(fotActions.setFetching(false)));

    console.log(domains);
    console.log(thoughts);

    setLastViews(lastViews.concat([view]));
    setView("feed");
    setFocusMode(false);
  };

  const delete_dislike = dislike_query => {
    setThoughts(dummy_thoughts);

    setDomains(dummy_domains);
    setReactions(dummy_reactions);

    setFocusedThought(dummy_card.current);

    const url = "/dislike/del/" + dislike_query;
    console.log(url);

    dispatch(fotActions.setFetching(true));
    api
      .getWithError(url)
      .then(res => {
        const data = res.data;
        setDomains([...data["domains"]]);
        setThoughts([...data["thoughts"]]);
        setFocusedThought(data["focusedThought"]);
        if (data["is_thought"]) {
          setSelectedDomain(-1);
        } else {
          setSelectedDomain(0);
        }
      })
      .catch(error => {
        console.error("error::::", error);
        func.messageError(error);
      })
      .finally(_ => dispatch(fotActions.setFetching(false)));

    console.log(domains);
    console.log(thoughts);

    setLastViews(lastViews.concat([view]));
    setView("feed");
    setFocusMode(false);
  };

  const go_back = () => {
    /*
    if (!focusMode) {
      setFocusMode(true);
    } else {

      const url = '/back';
      console.log(url);

      dispatch(fotActions.setFetching(true));
      api.get(url)
      .then((response) => response.json())
      .then((data) => {

        if (data["screen"] == "home") {
          setView("home");

        } else {
          setDomains([...data["domains"]]);
          setThoughts([...data["thoughts"]]);
          setFocusedThought(data["focusedThought"]);
          setReactions([...data["reactions"]]);
          setThoughtChain([...data["chain"]]);

          setSelectedDomain(data["selectedDomain"]);
          setView("feed");
          setFocusMode(false);

        }
      })
      .finally((_) => dispatch(fotActions.setFetching(false)));

    }
    */
  };

  /** */

  /* Animation Code */

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 2,
        staggerChildren: 2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };
  /** */

  const getUIClassNameByMode = () => {
    let uiClass = "Home-UI";
    switch (mode) {
      case "studioMode":
        uiClass = "Studio-UI";
        break;
      case "editorMode":
        uiClass = "Editor-UI";
        break;
    }
    return uiClass;
  };

  return (
    <div className={`UI ${getUIClassNameByMode()}`}>
      <article className={mode === "studioMode" ? "Studio-UI-Body" : "UI-Body"}>
        {/* { mode !== 'studioMode' ? <section className="Left-Section">
          <h2>Chain of Thought</h2>
          <article className="SectionList">
            {thoughtChain.map((thought, id) => (
              <section key={id} className="ThoughtChainCard">
                <article className="ThoughtChainThought">
                  <h2>{thought.thought}</h2>
                </article>
                <h3>{thought.domain}</h3>
                {
                  (thought.angles.length > 0) ?
                  <article className="ThoughtChainList">
                    <h4>Angles</h4>
                    <p>{thought.angles.join(" __ ")}</p>
                  </article>
                  :
                  <p className="Hidden"></p>
                }
                {
                  (thought.reactions.length > 0) ?
                  <article className="ThoughtChainList">
                    <h4>Reactions</h4>
                    <p>{thought.reactions.join(" __ ")}</p>
                  </article>
                  :
                  <p className="Hidden"></p>
                }
                {
                  (thought.likes.length > 0) ?
                  <article className="ThoughtChainList">
                    <h4>Likes</h4>
                    <p>{thought.likes.join(" __ ")}</p>
                  </article>
                  :
                  <p className="Hidden"></p>
                }
                {
                  (thought.dislikes.length > 0) ?
                  <article className="ThoughtChainList">
                    <h4>Dislikes</h4>
                    <p>{thought.dislikes.join(" __ ")}</p>
                  </article>
                  :
                  <p className="Hidden"></p>
                }
              </section>
            ))}
          </article>
        </section> : null } */}
        <section className="Mid-Section">
          <article className={bottomMenuVisible ? "IPhone" : "IPhone IPhone-Hidden-Bottom"}>
            <section className="UI-View">
              {view == "home" ? (
                <>
                  <HomeScreen
                    homeCards={homeCards}
                    select_home_card={select_home_card}
                    enterQuery={enter_query}
                    get_home_cards={get_home_cards}
                    setView={setView}
                    setFocusedThought={setFocusedThought}
                  />
                  {isMobile && !showChainThoughts ? (
                    <div className={`log-out ${thoughtChain.length > 0 ? "move-left" : ""}`}>
                      <Popconfirm
                        placement="bottomRight"
                        title="Log out"
                        onConfirm={() => clickLogOut()}
                        okText="Yes"
                        cancelText="No"
                      >
                        <a className="iconfont icon-sign_out"></a>
                      </Popconfirm>
                    </div>
                  ) : null}
                </>
              ) : (
                <Feed
                  domains={domains}
                  selectedDomain={selectedDomain}
                  lazy_domain={lazy_domain}
                  change_domain={change_domain}
                  thoughts={thoughts}
                  focusedThought={focusedThought}
                  select_thought={select_thought}
                  add_angle={add_angle}
                  delete_angle={delete_angle}
                  go_back={go_back}
                  delete_reaction={delete_reaction}
                  add_like={add_like}
                  delete_like={delete_like}
                  add_dislike={add_dislike}
                  delete_dislike={delete_dislike}
                  focusMode={focusMode}
                  setFocusMode={setFocusMode}
                  enterQuery={enter_query}
                  get_home_cards={get_home_cards}
                  ContentRetrieval={ContentRetrieval}
                  otherModes={otherModes}
                  UIMode={UIMode}
                  className={className}
                />
              )}
            </section>
            {!otherModes.includes(mode) && view !== "home" ? (
              <ThinkingInput
                // Hardcoded need to remove
                disableInput={["pitch deck", "build landing page", "hiring"].includes(domains[selectedDomain])}
                enterQuery={enter_query}
                get_home_cards={get_home_cards}
              />
            ) : null}

            {/* { <motion.div className="UI-Bottom">
              {
                bottomMenuVisible ?
                  <article className="UI-Bottom-Grid">
                    <button type="button" className="UI-Bottom-Button" onClick={toggle_bottom_menu} disabled={mode === 'studioMode'}>&#65086;</button>
                    <button type="button" className="UI-Bottom-Button" onClick={toggle_bottom_menu} disabled={mode === 'studioMode'}>&#65086;</button>
                    <button type="button" className="UI-Bottom-Button" onClick={toggle_bottom_menu} disabled={mode === 'studioMode'}>&#65086;</button>
                  </article>
                :
                  <article className="UI-Bottom-Hidden">
                    <motion.div
                      className="ReactionsArea"
                      variants={container}
                      initial="hidden"
                      animate={showReactions ? "show" : "hidden"}
                    >
                      {reactions.map((reaction, id) => (
                        <motion.div
                          className="CardHomeThought ReactionBubble"
                          variants={item}
                        >
                          <ReactionBubble key={id} id={id} on_select_reaction={add_reaction} label={reaction} />
                        </motion.div>
                      ))}
                    </motion.div>
                    <section className="Input">
                      <article className="Input-Holder">
                        <span className="Microphone" />
                        <form className="HomeInputForm" onSubmit={handleSubmit}>
                          <input className="HomeInputQuery" type="text" placeholder={placeHolder} value={query} onChange={onChangeQuery} />
                          <input type="submit" className="SubmitButton" />
                        </form>
                      </article>
                    </section>

                    <section className="Bottom-Menu-Button-Holder">
                      <button type="button" className="UI-Bottom-Hidden-Button" onClick={toggle_bottom_menu}>&#65085;</button>

                    </section>
                  </article>
              }
            </motion.div> } */}
          </article>
        </section>
      </article>
    </div>
  );
}

export default UI;
