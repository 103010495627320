import { Signal, signal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { BaseUploadNodeProps } from "imagica-uikit/dist/nodesV2/typing";
import { Api } from "@uikit/service/api";
import { getIt } from "@uikit/getIt";
import { UrlUtil } from "imagica-corekit/dist/base/util/UrlUtil";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";

class NodesState {
  objectURL: Signal<string | undefined> = signal(undefined);
  fileBlob: Signal<Blob | undefined> = signal(undefined);
  loading: Signal<boolean> = signal(false);
  streaming: Signal<boolean | undefined> = signal(undefined);
  // showBuilderUI = signal(false);
}

export class NodesBloc {
  state: NodesState = new NodesState();
  api: Api = new Api(false);
  brainClient = getIt(BrainClient);
  constructor() {}

  cleanData(): void {
    this.state.objectURL.value = undefined;
    this.state.fileBlob.value = undefined;
    this.state.loading.value = false;
    this.state.streaming.value = undefined;
  }

  async setCreateBlobUrl(
    initCreateBlobUrl: BaseUploadNodeProps["createBlobUrl"],
    unStructuredDownloadFailedText?: string
  ): Promise<void> {
    if (isBlank(initCreateBlobUrl)) return;
    this.state.objectURL.value = initCreateBlobUrl?.objectURL;
    this.state.fileBlob.value = initCreateBlobUrl?.fileBlob;
    this.state.loading.value = initCreateBlobUrl?.loading || false;
    this.state.streaming.value = initCreateBlobUrl?.streaming;
    unStructuredDownloadFailedText = this.state.loading.value ? undefined : unStructuredDownloadFailedText;
    if (initCreateBlobUrl?.objectURL && isBlank(initCreateBlobUrl?.fileBlob)) {
      const blob = await fetch(initCreateBlobUrl?.objectURL).then(res => res.blob());
      this.state.fileBlob.value = blob;
    }
  }

  async downloadMetaData(url: string, unStructuredDownloadFailedText?: string, originalUrl?: string): Promise<void> {
    const preText = unStructuredDownloadFailedText;
    try {
      this.state.loading.value = true;
      unStructuredDownloadFailedText = undefined;
      // handle old urls with duplicated api root
      if (originalUrl && !UrlUtil.isS3Url(originalUrl)) {
        // 有可能返回的是一个非s3的url，那么这个时候应该用originalUrl 请求
        const data = await this.brainClient.global
          .request(originalUrl, "get")
          .then(res => {
            return res.blob();
          })
          .then(blob => {
            if (!blob) {
              throw new Error(`fetch blob error ${originalUrl} `);
            }
            const fileUrl = URL.createObjectURL(blob);
            return { fileUrl, blob };
          });
        this.state.objectURL.value = data.fileUrl;
        this.state.fileBlob.value = data.blob;
      } else {
        const { fileUrl, blob } = await this.api.getByCustomType(url);
        this.state.objectURL.value = fileUrl;
        this.state.fileBlob.value = blob;
      }
    } catch (error) {
      console.error(error);
    }
    this.state.loading.value = false;
    unStructuredDownloadFailedText = preText;
  }
}
