import { DisplayType } from "imagica-uikit/dist/nodesV2/typing";

/**
 * 该类用于保存 creator canvas 画布上的常量值
 */
export class CreatorNodesConsts {
  static disablePublishDisplayType: Readonly<Array<DisplayType>> = ["Agent", "interviewBot", "blueprint"];
  static titleDistance = 30;
  static collapseDistance = 110;

  /**
   * 可以用于判断 node id 是否是组节点类型的id
   *
   * node 中组id 为 group-1,group-2 ...
   */
  static GROUPID_REG = /^group/;
  static NEW_EDGE_REG = /^new-edge/;

  /**
   * node 默认错误文本
   */
  static DEFAULT_ERROR_TEXT = "";

  static selectApiReg = /^\//;
}
