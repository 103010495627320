"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnsupportedGraph = exports.Interface = exports.Position = exports.Content = exports.Data = exports.Node = exports.Edge = exports.Graph = exports.Cotflow = void 0;
const class_transformer_1 = require("class-transformer");
class Cotflow {
    constructor() {
        this.action_sequence = [];
        this.detail = "";
        this.graph = new Graph();
        this.graphstring = "";
        this.interface = [];
        this.unsupported_action_sequence = [];
        this.unsupported_graph = new UnsupportedGraph();
        this.unsupported_interface = [];
    }
}
exports.Cotflow = Cotflow;
__decorate([
    (0, class_transformer_1.Type)(() => Graph)
], Cotflow.prototype, "graph", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Interface)
], Cotflow.prototype, "interface", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => UnsupportedGraph)
], Cotflow.prototype, "unsupported_graph", void 0);
class Graph {
    constructor() {
        this.edges = [];
        this.nodes = [];
    }
}
exports.Graph = Graph;
__decorate([
    (0, class_transformer_1.Type)(() => Edge)
], Graph.prototype, "edges", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Node)
], Graph.prototype, "nodes", void 0);
class Edge {
    constructor() {
        this.id = "";
        this.source = "";
        this.sourceHandle = "";
        this.target = "";
        this.targetHandle = "";
        this.type = "";
    }
}
exports.Edge = Edge;
class Node {
    constructor() {
        this.data = new Data();
        this.id = "";
        this.inputs = [];
        this.interface_position = 0;
        this.position = new Position();
        this.type = "";
    }
}
exports.Node = Node;
__decorate([
    (0, class_transformer_1.Type)(() => Data)
], Node.prototype, "data", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Position)
], Node.prototype, "position", void 0);
class Data {
    constructor() {
        this.content = new Content();
        this.description = "";
        this.executed = false;
        this.function_description = "";
        this.function_type = "";
        this.id = "";
        this.identifier = undefined;
        this.modality = "";
        this.mode = "";
        this.name = "";
        this.node_type = "";
        this.position = new Position();
        this.realtime = false;
        this.value = "";
    }
}
exports.Data = Data;
__decorate([
    (0, class_transformer_1.Type)(() => Content)
], Data.prototype, "content", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Position)
], Data.prototype, "position", void 0);
class Content {
    constructor() {
        this.text = "";
    }
}
exports.Content = Content;
class Position {
    constructor() {
        this.x = 0;
        this.y = 0;
    }
}
exports.Position = Position;
class Interface {
    constructor() {
        this.description = "";
        this.id = "";
        this.name = "";
        this.type = "";
    }
}
exports.Interface = Interface;
class UnsupportedGraph {
    constructor() {
        this.edges = [];
        this.nodes = [];
    }
}
exports.UnsupportedGraph = UnsupportedGraph;
__decorate([
    (0, class_transformer_1.Type)(() => Edge)
], UnsupportedGraph.prototype, "edges", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Node)
], UnsupportedGraph.prototype, "nodes", void 0);
