"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraphqlMutate = exports.GraphqlQuery = exports.GraphqlClient = void 0;
const client_1 = require("@apollo/client");
const LangUtil_1 = require("./LangUtil");
const RestClient_1 = require("./RestClient");
const JsonUtil_1 = require("./JsonUtil");
const GenUtil_1 = require("./GenUtil");
class GraphqlClient {
    constructor(adapter, abortManager = undefined) {
        this.adapter = adapter;
        this.abortManager = abortManager;
    }
    async query(graphqlQuery, uuid) {
        const adapter = await (async () => this.adapter)();
        const options = {
            query: graphqlQuery.query,
            variables: graphqlQuery.variables,
        };
        let requestUuid = uuid || GenUtil_1.GenUtil.randomString();
        const abortController = new AbortController();
        if (this.abortManager) {
            this.abortManager.add(requestUuid, abortController, {
                path: graphqlQuery.name,
                method: "POST",
                data: options,
                headers: adapter.headers,
            });
        }
        const client = this.create(graphqlQuery.name, JsonUtil_1.JsonUtil.stringify(options) || "", abortController);
        const result = await (0, LangUtil_1.tryPromise)(client.query(options));
        if (this.abortManager) {
            this.abortManager.remove(requestUuid);
        }
        if (result.error) {
            adapter.errorHandler(result.error);
            throw result.error;
        }
        const res = result.data;
        if (res.error) {
            adapter.errorHandler(res.error);
            throw res.error;
        }
        const response = new RestClient_1.RestResponse(200, "", new Headers(), result.data?.data);
        if (response.status < 200 || response.status >= 300) {
            adapter.errorHandler(response);
        }
        return response;
    }
    async mutate(graphqlMutate, uuid) {
        const adapter = await (async () => this.adapter)();
        const options = {
            mutation: graphqlMutate.query,
            variables: graphqlMutate.variables,
        };
        let requestUuid = uuid || GenUtil_1.GenUtil.randomString();
        const abortController = new AbortController();
        if (this.abortManager) {
            this.abortManager.add(requestUuid, abortController, {
                path: graphqlMutate.name,
                method: "POST",
                data: options,
                headers: adapter.headers,
            });
        }
        const client = this.create(graphqlMutate.name, JsonUtil_1.JsonUtil.stringify(options) || "", abortController);
        const result = await (0, LangUtil_1.tryPromise)(client.mutate(options));
        if (this.abortManager) {
            this.abortManager.remove(requestUuid);
        }
        if (result.error) {
            adapter.errorHandler(result.error);
            throw result.error;
        }
        const res = result.data;
        if (res.errors && res.errors.length > 0) {
            const error = res.errors[0];
            adapter.errorHandler(error);
            throw error;
        }
        return new RestClient_1.RestResponse(200, "", new Headers(), res.data);
    }
    create(operationName, body, abortController) {
        const url = `${this.adapter.baseUrl}?operationName=${operationName}`;
        const memoryCache = new client_1.InMemoryCache({
            possibleTypes: {
                xyz: ["abc", "def"],
            },
        }).restore({});
        const link = new client_1.HttpLink({
            uri: url,
            headers: this.adapter.headers,
            fetchOptions: {
                signal: abortController.signal,
            },
        });
        return new client_1.ApolloClient({
            link: link,
            cache: memoryCache,
            connectToDevTools: true,
        });
    }
}
exports.GraphqlClient = GraphqlClient;
class GraphqlQuery {
    constructor(name, query, variables) {
        this.name = name;
        this.query = query;
        this.variables = variables;
    }
}
exports.GraphqlQuery = GraphqlQuery;
class GraphqlMutate extends GraphqlQuery {
}
exports.GraphqlMutate = GraphqlMutate;
