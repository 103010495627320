import { FC, ReactNode } from "react";
import { DefaultProps } from "../../types";
import { Popover } from "antd";
import cls from "classnames";
import style from "./style.module.scss";
import { TooltipPlacement } from "antd/lib/tooltip";

export enum Triggers {
  Click = "click",
  Focus = "focus",
  Hover = "hover",
}

export interface DropdownProps extends DefaultProps {
  isOpen?: boolean;
  className?: string;
  overlayClassName?: string;
  content?: ReactNode;
  trigger?: Triggers;
  showArrow?: boolean;
  placement?: TooltipPlacement;
  onOpenChange?: (visible: boolean) => void;
}

const Dropdown: FC<DropdownProps> = ({
  children,
  className,
  overlayClassName,
  isOpen,
  content,
  showArrow = false,
  trigger = Triggers.Click,
  placement = "bottom",
  onOpenChange,
}) => {
  return (
    <Popover
      open={isOpen}
      title={null}
      content={content}
      trigger={trigger}
      onOpenChange={onOpenChange}
      getPopupContainer={e => e}
      placement={placement}
      className={cls(style.dropdown, className)}
      overlayClassName={cls(style.overlayDropdown, overlayClassName)}
      showArrow={showArrow}
    >
      {children}
    </Popover>
  );
};

export default Dropdown;
