import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Tabs } from "antd";
import { fotActions } from "../store/fot";
import { studioActions } from "../store/studio";

import "./studio.css";
import { MenuUnfoldOutlined, VerticalRightOutlined } from "@ant-design/icons";
import UI from "./UI";
import StudioPromptCard from "./StudioPromptCard";
import StudioVariableCard from "./StudioVariableCard";
import StudioLogicCard from "./StudioLogicCard";
import StudioLayoutCard from "./StudioLayoutCard";
import StudioMenu from "./StudioMenu";
import StudioSwitch from "./components/StudioSwitch";
import { DragDropContext } from "react-beautiful-dnd";
import func from "@uikit/func";

const Studio = () => {
  const dispatch = useDispatch();

  const collapsed = useSelector(state => state.fot.collapsed);
  const setCollapsed = val => {
    dispatch(fotActions.setCollapsed(val));
  };

  const mode = useSelector(state => state.fot.mode);

  const layoutComponents = useSelector(state => state.studio.layoutComponents);
  const setLayoutComponents = val => {
    dispatch(studioActions.setLayoutComponents(val));
  };

  const variables = useSelector(state => state.studio.selectedTemplate.variables);

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);

  const setUpdateLayout = val => {
    dispatch(studioActions.setUpdateLayout(val));
  };

  const clickShow = () => {
    setCollapsed(!collapsed);
  };

  const handleDrop = droppedItem => {
    const source = droppedItem?.source;
    const dest = droppedItem?.destination;
    if (!dest) return;
    switch (source.droppableId) {
      case "layout-components":
        if (dest.droppableId !== "layout-components") return;
        if (droppedItem.source.index === droppedItem.destination.index) return;
        const updatedList = [...layoutComponents];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        setLayoutComponents(updatedList);
        setUpdateLayout(true);
        break;
      case "variables":
        if (dest.droppableId !== "layout-components") return;
        const updatedComponents = [...layoutComponents];
        const draggedVariable = variables[droppedItem.draggableId];
        if (updatedComponents.find(x => x.name === droppedItem.draggableId)) {
          func.messageUtil(
            `Cannot drop variable ${droppedItem.draggableId}, component with this variable already exists`
          );
          return;
        }
        const converted = {
          name: droppedItem.draggableId,
          component_template: draggedVariable.type,
        };
        updatedComponents.splice(droppedItem.destination.index, 0, converted);
        setLayoutComponents(updatedComponents);
        setUpdateLayout(true);
        break;
    }
  };

  const items = [
    { label: "Prompt", key: "prompt", children: <StudioPromptCard />, disabled: !selectedTemplate.template_name },
    { label: "Api Logic", key: "apiLogic", children: <StudioLogicCard />, disabled: !selectedTemplate.template_name },
    {
      label: "Variables",
      key: "variables",
      children: <StudioVariableCard />,
      disabled: !selectedTemplate.template_name,
    },
  ];

  useEffect(() => {
    dispatch(fotActions.setMode("studioMode"));
  }, []);

  return (
    <div>
      {mode === "studioMode" ? <StudioMenu /> : ""}
      <div className={`Route-Select ${mode === "studioMode" ? "Studio-Background" : ""}`}>
        {mode === "testMode" ? <StudioSwitch /> : ""}
      </div>
      <div className={`Main-Container ${mode === "studioMode" ? "Studio-Background" : ""}`}>
        {mode === "studioMode" ? (
          <DragDropContext onDragEnd={handleDrop}>
            <Row className="Studio-Container">
              <Col span={7} className="Studio-Left-Section">
                {/* <StudioTemplateSelectCard /> */}
                <Tabs
                  defaultActiveKey="prompt"
                  className={collapsed ? "Studio-Left-Section-Stow" : "Studio-Left-Section-Bottom"}
                  items={items}
                />
                {!collapsed ? (
                  <VerticalRightOutlined className="Studio-Left-Section-Icon" onClick={clickShow} />
                ) : (
                  <MenuUnfoldOutlined className="Studio-Left-Section-display" onClick={clickShow} />
                )}
              </Col>

              <Col span={8} offset={1} className={mode === "studioMode" ? "Studio-Fot-UI" : ""}>
                <UI UIMode="studioMode" />
              </Col>

              <Col span={7} offset={1} className="Studio-Right-Section">
                {/* <StudioTemplateSelectCard /> */}
                <StudioLayoutCard />
              </Col>
            </Row>
          </DragDropContext>
        ) : mode === "testMode" ? (
          <UI UIMode="testMode" />
        ) : null}
      </div>
    </div>
  );
};

export default Studio;
