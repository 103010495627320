import React, { useCallback, useEffect, useMemo } from "react";
import { useCreation } from "ahooks";
import classNames from "classnames";
import { Dropdown, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import styles from "./AccountStatus.module.scss";
import { editorActions } from "@store/editor";
import { fotActions } from "@store/fot";
import { useNavigate } from "react-router-dom";
import useGetUserAvatarUrl from "@custom-hooks/useGetUserAvatarUrl";
import func from "@uikit/func";
import LoadingOutline from "@views/components/LoadingOutline";
import { ShareAccountBloc } from "./ShareAccountBloc";
import { UnSubscribe } from "./UnSubscribePane";
import { Subscribe } from "./SubScribePane";
import ToSignUpView from "../toSignUp/ToSignUpView";
import { ToSignUpViewBloc } from "@uiview/views/toSignUp/ToSignUpViewBloc";
import { logEvent } from "@uikit/service/amplitude";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

interface AccountStatusProps {
  className?: string;
}

export function AccountStatus(props: AccountStatusProps): React.JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = getIt(PublishSubscriptionStore);
  const previewAppStore = getIt(PreviewAppStore);
  const state = useStore(store).value;
  const metaDataV2 = previewAppStore.getPublishMetadata();
  const authStore = getIt(FotAuthStore);
  const { userAvatarUrl } = useGetUserAvatarUrl();
  const subscriberParamsData = useSelector((state: any) => state.editor.subscriberParamsData);
  const meJson = useSelector((state: any) => state.editor.me);
  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);

  const bloc = useCreation(() => new ShareAccountBloc(), []);
  const toSignUpViewBloc = new ToSignUpViewBloc();

  const imageUrl = useMemo(() => {
    if (!func.isEmpty(userAvatarUrl)) {
      return userAvatarUrl;
    }
    return `${settings.S3CDN}${settings.viewAssetsPath}default.jpg`;
  }, [userAvatarUrl]);

  const setMe = (val: any): void => {
    dispatch(editorActions.setMe(val));
  };

  const setIsLogin = useCallback(
    (val: any) => {
      dispatch(fotActions.setIsLogin(val));
    },
    [dispatch]
  );

  const setLoginMethod = (val: any): void => {
    dispatch(editorActions.setLoginMethod(val));
  };

  useEffect(() => {
    bloc.judgeSub({
      uuid: subscriberParamsData?.attributes?.uuid,
      guest_user_key: subscriberParamsData?.guest_user_key,
      store,
      authStore,
      setIsLogin,
      navigate,
      metaDataV2: previewAppStore.getPublishMetadata(),
      meJson,
      selectedTemplate,
    });
  }, [
    authStore,
    bloc,
    bloc.judgeSub,
    meJson,
    navigate,
    selectedTemplate,
    setIsLogin,
    store,
    subscriberParamsData?.attributes?.uuid,
    subscriberParamsData?.guest_user_key,
  ]);

  const showModal = (): void => {
    store.setIsOpenPayWall(true);
  };

  const handleOk = (): void => {
    store.setIsOpenPayWall(false);
  };

  const handleCancel = (): void => {
    store.setIsOpenPayWall(false);
  };

  const NotLogIn = (): JSX.Element => {
    return <ToSignUpView bloc={toSignUpViewBloc}></ToSignUpView>;
  };

  return (
    <div className={classNames(styles.accountComponent, props.className)}>
      {authStore?.state?.brainToken ? (
        <LoggedIn
          imageUrl={imageUrl}
          showModal={showModal}
          isPaidApp={state.isPaidApp}
          bloc={bloc}
          authStore={authStore}
          setMe={setMe}
          setIsLogin={setIsLogin}
          setLoginMethod={setLoginMethod}
          navigate={navigate}
        />
      ) : (
        <NotLogIn />
      )}
      <Modal
        centered
        closable={false}
        footer={null}
        maskClosable={true}
        open={state.isOpenPayWall}
        onOk={handleOk}
        onCancel={handleCancel}
        className={styles.payContainer}
      >
        {state.isPay ? (
          <UnSubscribe bloc={bloc} store={store} />
        ) : (
          <Subscribe isLogin={state.isOpenLogin} metaDataV2={metaDataV2} store={store} bloc={bloc} />
        )}
      </Modal>
      {state.loading && (
        <div className={styles.loading}>
          <LoadingOutline />
        </div>
      )}
    </div>
  );
}

function LoggedIn(props: {
  imageUrl: string;
  showModal: () => void;
  isPaidApp: boolean;
  bloc: ShareAccountBloc;
  authStore: FotAuthStore;
  setMe: (val: any) => void;
  setIsLogin: (val: any) => void;
  setLoginMethod: (val: any) => void;
  navigate: (val: any) => void;
}): JSX.Element {
  const meJson = useSelector((state: any) => state.editor.me);
  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);
  const subscriberParamsData = useSelector((state: any) => state.editor.subscriberParamsData);
  const homePluginStore: HomePluginStore = getIt(HomePluginStore);
  const studioData = homePluginStore.state.studioData;

  function clickToLink(): void {
    const url = `${settings.ImagicaUrl}${subscriberParamsData?.attributes?.project_id}?appId=${subscriberParamsData?.attributes?.appId}&open=true`;
    window.open(url, "_blank");
    logEvent("click_profile_imagica", {
      guest_uuid: studioData?.guest_user_key,
      project_static_id: studioData?.attributes?.project_id,
    });
  }
  function Menu(): JSX.Element {
    return (
      <div className={styles.menuWrap}>
        {props.isPaidApp && (
          <div className={styles.menuItem} onClick={props.showModal}>
            Subscription
          </div>
        )}

        {props.bloc.isOneself(meJson, selectedTemplate) && subscriberParamsData?.attributes?.project_id && (
          <div className={styles.menuItem} onClick={() => clickToLink()}>
            Imagica.ai
          </div>
        )}
        <div
          className={styles.menuItem}
          onClick={() => {
            logEvent("logout");
            props.bloc.logout({
              authStore: props.authStore,
              setMe: props.setMe,
              setIsLogin: props.setIsLogin,
              setLoginMethod: props.setLoginMethod,
              navigate: props.navigate,
            });
          }}
        >
          Log out
        </div>
      </div>
    );
  }

  return (
    <Dropdown overlay={Menu}>
      <div className={styles.userAvatar}>
        <img src={props.imageUrl} alt="" />
      </div>
    </Dropdown>
  );
}
