"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctionName = exports.FunctionRegistry = void 0;
const RelationSetDest_1 = require("../../api/graphqlTyped/RelationSetDest");
const TextGenFunction_1 = require("../../storyV2/function/TextGenFunction");
const StudioProjectAttributesV2Edge_1 = require("../../project/domain/StudioProjectAttributesV2Edge");
const JsonUtil_1 = require("../../cutil/JsonUtil");
class FunctionRegistry {
    constructor(brainClient, globalFunctionStore, personalFunctionStore, authStore, functionV2Store, meStore, socketRestClient) {
        this.brainClient = brainClient;
        this.globalFunctionStore = globalFunctionStore;
        this.personalFunctionStore = personalFunctionStore;
        this.authStore = authStore;
        this.functionV2Store = functionV2Store;
        this.meStore = meStore;
        this.socketRestClient = socketRestClient;
        this.map = {};
        this.constructorMap = {};
    }
    register(adapter, name) {
        this.map[name] = adapter;
    }
    registConstructor(functionBase, name) {
        this.constructorMap[name] = functionBase;
    }
    async getByEdge(actionData, sourceNode) {
        // use function registered with the adapter (one-to-one mapping)
        const functionName = actionData.funcOriginalData.functionName;
        const functionFromAdapter = this.getByName(functionName || "");
        if (functionFromAdapter) {
            return [functionFromAdapter];
        }
        const edgeName = actionData.enterText;
        const found = await this.findInSavedFunc({
            edgeName: edgeName,
            funcId: actionData.funcOriginalData.id,
            funcValue: actionData.funcOriginalData.value,
            sourceNode,
            actionData,
        });
        if (found.length !== 0) {
            /// 代表这条边在全局func中找到了对应需要执行的func
            return found;
        }
        const directValueRegister = this.getByName(actionData.funcOriginalData.value ?? "");
        if (directValueRegister !== undefined) {
            return [directValueRegister];
        }
        let directRegist = this.getByName(edgeName);
        if (directRegist === undefined) {
            directRegist = await this.getFunctionByAlias(edgeName.replace("/", ""));
        }
        if (directRegist !== undefined) {
            /// 代表这条边的名字本身就对应一个func，例如Split Output
            return [directRegist];
        }
        return [];
    }
    async getFunctionByAlias(name) {
        const aliasMap = {};
        const findV2Func = await this.functionV2Store.get();
        findV2Func.forEach(f => {
            f.attributesV2?.aliases?.forEach(alia => {
                aliasMap[alia] = f.attributesV2?.value ?? "";
            });
        });
        const newName = aliasMap[name];
        return this.getByName(newName);
    }
    getByRegistName(name) {
        return this.getByName(name);
    }
    getByName(name) {
        const functionBase = this.map[name];
        if (!functionBase) {
            const adaConstructor = this.constructorMap[name];
            if (adaConstructor != undefined) {
                return adaConstructor();
            }
        }
        return functionBase;
    }
    createTextGenFunction(url = undefined, identifierType, identifierValue, streaming, domain = undefined) {
        const func = new TextGenFunction_1.TextGenFunction(this.brainClient, this.authStore, this.socketRestClient);
        func.url = url;
        func.domain = domain;
        func.identifierType = identifierType;
        func.identifierValue = identifierValue;
        func.streaming = streaming;
        return func;
    }
    getByLineInfo(lineInfo, attributes, actionData, sourceNode) {
        const func = this.getByName(lineInfo.enterText || "");
        if (func === undefined) {
            const deepFind = this.syncFindInGlobalFunc({
                edgeName: lineInfo.enterText || "",
                funcId: lineInfo.originFunctionData?.id,
                funcValue: lineInfo.originFunctionData?.value,
                sourceNode: sourceNode,
                actionData: actionData,
            }, attributes);
            return deepFind;
        }
        return [func];
    }
    async findInSavedFunc(findParams) {
        const destsInGlobal = await this.globalFunctionStore.get();
        const attributesInGlobal = destsInGlobal
            .map(item => {
            const attri = item.attributesV2;
            if (attri) {
                attri.destId = item.id;
            }
            return attri;
        })
            .filter(item => item !== undefined);
        const attributesInPerson = await this.personalFunctionStore.get();
        let attriInFuncV2 = [];
        const objInFuncV2 = await this.functionV2Store.get();
        attriInFuncV2 = objInFuncV2
            .map(obj => {
            const attri = JsonUtil_1.JsonUtil.toModelFromType(RelationSetDest_1.RelationSetDestAttribute, obj.attributesV2);
            if (attri) {
                attri.destId = obj.id;
            }
            return attri;
        })
            .filter(item => item !== undefined);
        return this.syncFindInGlobalFunc(findParams, [...attributesInGlobal, ...attributesInPerson, ...attriInFuncV2]);
    }
    syncFindInGlobalFunc(findParams, attributes) {
        const findById = attributes.find(attri => {
            return findParams.funcId && attri.destId === findParams.funcId;
        });
        const findByValue = attributes.find(attri => {
            return findParams.funcValue && attri.value === findParams.funcValue;
        });
        const cleanEdgeName = findParams.edgeName.replace("/", "");
        const findByName = attributes.find(attri => {
            return attri.name === cleanEdgeName;
        });
        const findAttri = findById || findByValue || findByName;
        if (!findAttri) {
            return [];
        }
        let edgeArr = [];
        if (findAttri.isV3Function === true) {
            const nodes = findAttri.nodeArr || [];
            const filterNodes = nodes
                .map(n => n.data)
                .filter(n => {
                return n instanceof StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2EdgeData;
            })
                .map(data => {
                const edge = new StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2Edge();
                edge.data = data;
                return edge;
            });
            edgeArr = filterNodes;
        }
        else {
            edgeArr = findAttri.edgeArr || [];
        }
        if (edgeArr.length === 0) {
            return [];
        }
        const funcArrs = edgeArr.map((value, index) => {
            const arr = this.getByLineInfo(value.data?.lineParam || new StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2EdgeDataLineParam(), attributes, findParams.actionData, index === 0 ? findParams.sourceNode : undefined);
            return arr.filter(x => x !== undefined);
        });
        let funcs = [];
        funcArrs.forEach(arr => {
            funcs = [...funcs, ...arr];
        });
        if (funcs.last() instanceof TextGenFunction_1.TextGenFunction) {
            funcs.last().streaming = true;
        }
        return funcs;
    }
    getAuthStore() {
        return this.authStore;
    }
}
exports.FunctionRegistry = FunctionRegistry;
var FunctionName;
(function (FunctionName) {
    FunctionName["splitOutput"] = "/Split Output";
    FunctionName["stockPrices"] = "/Stock Prices";
    FunctionName["youtubeVideos"] = "/Video";
    FunctionName["amazonShopping"] = "/Amazon Shopping(Old)";
    FunctionName["jdShopping"] = "/Jd Shopping";
    FunctionName["weeeShopping"] = "/Weee Shopping(Old)";
    FunctionName["dallE2"] = "/DALL\u00B7E 2";
    FunctionName["imageSearch"] = "/Image search";
    FunctionName["maps"] = "/Maps";
    FunctionName["midjourney"] = "/Midjourney";
    FunctionName["food"] = "/Show Food Interface";
    FunctionName["unknown"] = "/Unknown";
    FunctionName["customApi"] = "customApi";
    FunctionName["customCode"] = "customCode";
    FunctionName["addDataSource"] = "/Add Data Source";
    FunctionName["protein"] = "/Protein";
    FunctionName["askImagica"] = "/Ask Imagica";
    FunctionName["chatInterface"] = "/Chat Interface";
    FunctionName["globalContext"] = "/Global_Context";
    FunctionName["interviewBot"] = "/Interview Bot";
    //
    // // v2
    FunctionName["stockValue"] = "showStockInterface";
    FunctionName["addDataSourceValue"] = "SourceOfTruth";
    FunctionName["dallE2Value"] = "imageGen";
    FunctionName["imageSearchValue"] = "imageSearch";
    FunctionName["proteinValue"] = "Protein";
    FunctionName["videoValue"] = "video";
    FunctionName["mapValue"] = "map";
    FunctionName["jdShoppingValue"] = "jdShopping";
    FunctionName["amazonShoppingOldValue"] = "amazonShopping";
    FunctionName["weeeShoppingOldValue"] = "weeeShopping";
    FunctionName["splitOutputValue"] = "splitOutput";
    FunctionName["askImagicaValue"] = "askBrain";
    FunctionName["showFoodInterfaceValue"] = "showFoodInterface";
    FunctionName["midjourneyValue"] = "midjourneyV2";
    FunctionName["chatInterfaceValue"] = "chatInterface";
    FunctionName["interviewBotValue"] = "interviewBot";
    FunctionName["textGeneration"] = "textGeneration";
    FunctionName["empty"] = "";
    FunctionName["blueprint"] = "blueprint";
    FunctionName["param"] = "param";
})(FunctionName || (exports.FunctionName = FunctionName = {}));
