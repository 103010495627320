import React from "react";

import "../components-css/Google.css";

function Google(props) {
  return (
    <section className="CardHomeGoogle">
      <a href={props.link} target="_blank">
        <section>
          <section className="title">{props.text}</section>
          <section className="subheading">Search on Google</section>
        </section>
        <span className="iconfont icon-right"></span>
      </a>
    </section>
  );
}

export default Google;
