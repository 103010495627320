import isBlank from "@sedan-utils/is-blank";
import { Api } from "@uikit/service/api";
import { Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import cls from "classnames";
import { ToolPiece } from "imagica-corekit/dist/base/api/toolPiecesTyped/ToolPieces";
import { tryBlock } from "imagica-corekit/dist/base/cutil/LangUtil";
import { useEffect, useState } from "react";
import styles from "./DragDropFile.module.scss";
import { UploadLoading } from "./UploadLoading";

const { Dragger } = Upload;

export const DEFAULT_ERROR_MSG =
  "There is a problem with the API file you submitted. Refer to the guidelines and try again.";

const api = new Api(false);

export type Percent = { loaded: number; total: number };

export type UploadStatus = "PENDING" | "SUCCESS" | "FAILED" | "VALIDATING";

type UploadFileProps = {
  onUploaded?: () => void;
  onClose?: () => void;
  onSuccess: () => void;
  onReUpload?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export function UploadFile({ style, className, children, onReUpload, onSuccess, ...props }: UploadFileProps) {
  const [file, setFile] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [percent, setPercent] = useState<Percent>({ loaded: 0, total: 0 });
  const [fileText, setFileText] = useState<string | null>();
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>("PENDING");
  const [toolPiece, setToolPiece] = useState<ToolPiece>();

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    return true;
  };

  const initialUploadFile = () => {
    onReUpload?.();
    setFile(undefined);
    setPercent({ loaded: 0, total: 0 });
    setErrorMsg(undefined);
    setFileText(undefined);
    setUploadStatus("PENDING");
    setToolPiece(undefined);
    // TODO need to abort the upload file
  };

  const uploadS3 = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file as unknown as Blob, file.name);
      try {
        setUploadStatus("PENDING");
        const result = await api.uploadUseXhr(`/api/me/upload/studios`, formData, {
          onProgress: (p: Percent) => {
            setPercent(p);
            if (p.loaded === p.total) {
            }
          },
        });
        if (isBlank(result.data)) {
          throw new Error("Upload failed");
        }

        const reader = new FileReader();
        reader.onload = async function (event: ProgressEvent<FileReader>) {
          const fileText = event.target?.result;
          setFileText(fileText as string);
        };
        reader.readAsText(file);
      } catch (error: any) {
        setErrorMsg((error as Error).message);
        setUploadStatus("FAILED");
      }
    }
  };

  const checkFile = async () => {
    if (fileText) {
      // const jsonData = tryBlock(() => JSON.parse(fileText));
      // if (jsonData.error) {
      //   setErrorMsg(jsonData.error);
      //   setUploadStatus("FAILED");
      //   return;
      // }
      try {
        // setUploadStatus("VALIDATING");
        // const result = await brainClient.openAi.addToolPiece({ api_data: jsonData.data });
        // if (isBlank(result.data)) {
        //   throw new Error("Validating failed");
        // }
        // props?.onUploaded?.();
        setUploadStatus("SUCCESS");
        onSuccess();

        // setToolPiece(result.data);
        // getIt(ToolPieceStore).renew();
      } catch (error: any) {
        setErrorMsg((error as Error).message);
        setUploadStatus("FAILED");
      }
    }
  };

  useEffect(() => {
    if (fileText) {
      checkFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileText]);

  useEffect(() => {
    if (file) {
      uploadS3();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <div style={style} className={cls(className, styles.sourceBox)} onClick={e => e.stopPropagation()}>
      {file === undefined && (
        <>
          <div className={styles.uploadWrapper}>
            <Dragger
              name="file"
              // accept=".json"
              className={styles.dragger}
              multiple={false}
              onRemove={initialUploadFile}
              customRequest={() => {}}
              beforeUpload={beforeUpload}
            >
              {children}
            </Dragger>
          </div>
        </>
      )}

      {file && (
        <UploadLoading
          fileName={file.name}
          onClose={props.onClose}
          percent={percent}
          onReUpload={initialUploadFile}
          size={file.size}
          errorMsg={errorMsg}
          uploadStatus={uploadStatus}
          toolPiece={toolPiece}
        />
      )}
    </div>
  );
}
