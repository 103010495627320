import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CodeEditor from "@uiw/react-textarea-code-editor";
import css from "./Code.module.scss";
import func from "@uikit/func";
import publicMethod from "@uikit/publicMethod";
import { useHtmlIframe } from "imagica-uikit/dist/hooks/useHtmlIframeV1";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import BetterLooking from "@views/components/BetterLooking";
import { RootState } from "@store/index";
import { get, isArray, isEmpty } from "lodash";
import { BlankV2 } from "imagica-uikit/dist/nodesV2/Blank/BlankV2";
import classNames from "classnames";
import { NodePreviewHtml } from "@uiview/views/PreviewApp/model/NodePreviewHtml";

export type CodeProps = {
  // code props
  fontSize: string;
  content?: any[] | NodePreviewHtml;
  /**
   * 已经不能搜索到该方法
   * @deprecated
   */
  handleImageLoaded: string | (() => void);

  // htmlTemplate node props
  displayType: any;
  textAreaValue: any;
  isResized: any;
  stockLoading: any;
  isTargetNode: any;
  id: any;
  handleGroupStyle: any;
};
/**
 * 该组件仅用来兼容原来 htmlTemplate 和 code 类型的组件
 *
 * - htmlTemplate 和 code 在现版本不能手动生成，只能使用原来的项目
 */
export function Code(props: CodeProps): React.ReactNode {
  const codeEditorRef = useRef<HTMLTextAreaElement>(null);
  const timers = useRef<any[]>([]);
  // local state
  const [showDemo, setShowDemo] = useState(false);
  const [demoStr, setDemoStr] = useState("");
  const [codeContent, setCodeContent] = useState<Record<string, any>>({});
  const [showGenerative, setShowGenerative] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fontSizePX, setFontSizePX] = useState(12);

  const focusedThought = useSelector((state: RootState) => state.feed.focusedThought) as Record<string, any>;
  const disableAddPreview = useSelector((state: RootState) => state.fot.disableAddPreview);
  const mode = useSelector((state: RootState) => state.fot.mode);

  const [iframeRef] = useHtmlIframe({
    htmlStr: codeContent.codex_generation,
    deps: [showDemo, showGenerative],
  });

  const content = useMemo(() => {
    if (props.content instanceof NodePreviewHtml) {
      return props.content.code;
    }
    if (isArray(props.content)) {
      return props.content;
    }
    // 只获取第一条
    return get(props.textAreaValue, ["0", "results", "0", "content", "code"], []);
  }, [props.textAreaValue, props.content]);

  const projectName = "jerry_beautify_landing_page_demo";

  const handleShowDemo = (): void => {
    setShowDemo(true);
    setDemoStr(codeContent.codex_generation);
    timers.current.forEach(x => clearTimeout(x));
  };

  const handleShowCodeResult = (): void => {
    setShowDemo(false);
  };

  const handleClickCard = (e: { stopPropagation: () => void }): void => {
    e.stopPropagation();
  };
  const getFirstHtml = (codeObj: any[]): any => {
    let htmlResult = codeObj[0];
    if (mode !== "editorMode") {
      htmlResult = codeObj.find((x: { codex_generation: string }) => {
        if (typeof x.codex_generation !== "string") return false;
        const code = x.codex_generation.replace("\n/g", "").trim();
        return (
          code.match(/^<!DOCTYPE html>/) ||
          code.match(/^<!doctype html>/) ||
          code.match(/^<html>/) ||
          code.match(/^<body>/) ||
          code.match(/^</)
        );
      });
    }
    const codeContentObj = htmlResult
      ? htmlResult
      : {
          instruction: "Unable to parse codex generation",
          codex_generation: "<html><h1>Unable to parse codex generation</h1></html>",
          title: codeObj[0]?.title,
          description: codeObj[0]?.description,
          notUseAnimation: codeObj[0]?.notUseAnimation || false,
        };
    return codeContentObj;
  };
  const handleClickRefresh = async (): Promise<void> => {
    const res: Record<string, any> = await publicMethod.getUIFromIntelligentAppProject(
      projectName,
      focusedThought.thought,
      setLoading
    );

    const codeResult = res?.variables?.code_result?.value;
    const description = res?.variables?.description?.value;
    const title = res?.variables?.title?.value;
    if (title && description && codeResult.length > 0) {
      codeResult[0].title = title;
      codeResult[0].description = description;
    }
    if (codeResult) {
      setCodeContent(getFirstHtml(codeResult));
    }
  };

  const handleHtmlAnimation = (): void => {
    const strArr = codeContent.codex_generation.split("\n");

    if (!codeEditorRef.current) return;

    const codeEditorEl = codeEditorRef.current.parentNode;
    if (!codeEditorEl) return;

    const codeEditorDivChild = codeEditorEl.children[1];
    for (let index = 0; index < strArr.length; index++) {
      timers.current[index] = setTimeout(() => {
        setDemoStr(preStr => `${preStr}${strArr[index]}\n`);
        (codeEditorEl as HTMLElement).scrollTo({
          top: codeEditorDivChild.scrollHeight,
        });
      }, 50 * index);
    }
  };

  const gptGenerate = (e: { stopPropagation: () => void }): void => {
    e.stopPropagation();
    setShowGenerative(!showGenerative);
  };

  useEffect(() => {
    // use first html result
    setCodeContent(getFirstHtml(content));
  }, []);
  useEffect(() => {
    switch (props.fontSize) {
      case "Large":
        setFontSizePX(15);
        break;
      default:
        setFontSizePX(12);
    }
  }, [props.fontSize]);

  // html改变需要更新
  useEffect(() => {
    if (func.isEmpty(content?.[0]?.codex_generation)) return;
    setCodeContent(getFirstHtml(content));
  }, [content?.[0]?.codex_generation]);

  useEffect(() => {
    if (!codeContent.codex_generation) return;
    setShowDemo(false);
    if (!codeContent.notUseAnimation) {
      handleHtmlAnimation();
    } else {
      setDemoStr(codeContent.codex_generation);
    }
    return (): void => timers.current.forEach(x => clearTimeout(x));
  }, [codeContent]);

  useEffect(() => {
    if (typeof props.handleImageLoaded === "function") {
      setTimeout(() => {
        if (typeof props.handleImageLoaded === "function") {
          props.handleImageLoaded();
        }
      });
    }
  }, [demoStr]);

  useEffect(() => {
    const editor = codeEditorRef.current;
    if (!editor) {
      return;
    }
    const editorHeight = editor.scrollHeight;
    const editorWidth = editor.scrollWidth;
    const textarea = editor.querySelector("textarea");
    if (!textarea) {
      return;
    }
    textarea.style.height = `${editorHeight}px`;
    textarea.style.width = `${editorWidth}px`;
  }, [codeContent]);

  // from htmlTemplate component
  // https://github.com/brain/micro-apps/issues/4744
  if (props.displayType === "htmlTemplate" && isEmpty(content)) {
    return <BlankV2 />;
  }

  return (
    <div data-testid="Code" className={classNames(css.wraper)} onClick={handleClickCard}>
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="refreshing...">
        <div className={css.code}>
          {showGenerative ? (
            <BetterLooking codeContent={codeContent} />
          ) : (
            <div className={css.codeMain}>
              {showDemo ? (
                <iframe ref={iframeRef} />
              ) : (
                <CodeEditor
                  ref={codeEditorRef}
                  className={css.codeEditor}
                  value={demoStr}
                  language="html"
                  disabled
                  padding={15}
                  style={{
                    fontSize: fontSizePX,
                    backgroundColor: "#f5f5f5",
                    fontFamily: "ui-monospace,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                  }}
                />
              )}

              <footer>
                {showDemo ? (
                  <Button
                    className={disableAddPreview ? css.rightBtn : css.rightDisableBtn}
                    disabled={disableAddPreview === false}
                    onClick={handleShowCodeResult}
                  >
                    Code Result
                  </Button>
                ) : (
                  <Button
                    className={disableAddPreview ? css.rightBtn : css.rightDisableBtn}
                    disabled={disableAddPreview === false}
                    onClick={handleShowDemo}
                  >
                    Demo
                  </Button>
                )}
                {mode !== "editorMode" ? (
                  <div className="refresh-box refresh-box-code" onClick={handleClickRefresh}>
                    <img src={`${settings.S3CDN}${settings.viewAssetsPath}refresh.svg`} alt="refresh" />
                  </div>
                ) : null}
              </footer>
            </div>
          )}
        </div>
      </Spin>
      {mode !== "editorMode" ? (
        <div className="Bottom-box">
          {showGenerative ? (
            <Button
              className={disableAddPreview ? css.rightBtn : css.rightDisableBtn}
              disabled={disableAddPreview === false}
              onClick={gptGenerate}
              loading={loading}
            >
              Go back
            </Button>
          ) : (
            <Button
              className={disableAddPreview ? css.rightBtn : css.rightDisableBtn}
              disabled={disableAddPreview === false}
              onClick={gptGenerate}
              loading={loading}
            >
              Beautify
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
}
