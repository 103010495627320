import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { isFunction } from "lodash";

export class CustomFunctionStoreState {
  customFunction = [] as any[];
}

export type DispatchType<T> = T | ((prevState: T) => T);

export class CustomFunctionStore extends StoreBase<CustomFunctionStoreState> {
  constructor() {
    super(() => new CustomFunctionStoreState());
  }

  setCustomFunction = (value: DispatchType<CustomFunctionStoreState["customFunction"]>): void => {
    const newState = new CustomFunctionStoreState();

    if (isFunction(value)) {
      newState.customFunction = value(this.state.customFunction);

      this.emit(newState);
      return;
    }

    newState.customFunction = value;
    this.emit(newState);
  };

  customFunctionRef = {
    get current(): any[] {
      return customFunctionStore.state.customFunction;
    },
  };
}

export const customFunctionStore = new CustomFunctionStore();
