import { Input } from "antd";
import { Nodes } from "../../../../Nodes/Nodes";
import { useAiSaasInputContainer } from "../container";
import { useRenderData } from "../../../hooks/useRenderData";
import css from ".././index.module.scss";

export function InputMain(props: AISaas.AISaasInputItemMainProps): JSX.Element {
  const { x, index, isUploadFileInputNode, isEditPlaceholder } = props;
  const { id: itemId } = x.data;

  const renderData = useRenderData({ type: "inputValue", id: itemId });

  const {
    inputListRef,
    onInputEnter,
    onMouseLeave,
    onInputBlur,
    onInputFocus,
    onMouseOver,
    onSaasInputChange,
    getByPlaceholderText,
  } = useAiSaasInputContainer();

  if (isUploadFileInputNode) {
    return (
      <Nodes
        {...x.data}
        {...x.data.data}
        isInCanvas={false}
        canvasType={"saas"}
        asInputNodeChange={(value: any) => {
          onSaasInputChange({ target: { value } }, itemId);
        }}
      />
    );
  }

  return (
    <Input.TextArea
      ref={(r: any) =>
        (inputListRef.current[index] = {
          refKey: itemId,
          refValue: r,
        })
      }
      style={renderData?.style}
      // ref={index === 0 ? inputTextArea : undefined}
      placeholder={getByPlaceholderText(x, index)}
      className={`${css["saas-input"]} ${isEditPlaceholder && css["disappears"]}`}
      autoSize={{ minRows: 1, maxRows: 10 }}
      bordered={false}
      value={x?.data?.data?.textAreaValue}
      disabled={props.disabledInput}
      onChange={(e: any) => onSaasInputChange(e, itemId)}
      // allowClear
      onMouseLeave={onMouseLeave}
      onMouseOver={() => onMouseOver(itemId)}
      // onPressEnter={(e) => e.preventDefault()}
      onPressEnter={(e: any) => onInputEnter(e, itemId, x)}
      onBlur={() => onInputBlur(itemId, index)}
      onFocus={() => onInputFocus(itemId, index)}
    />
  );
}
