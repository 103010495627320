import func, { matchMobile } from "@uikit/func";
import { getIt } from "@uikit/getIt";
import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { invalidShareAppTipStore } from "@uikit/store/InvalidShareAppTipStore";
import InvalidShareAppTip from "@uiview/views/InvalidShareAppTip/InvalidShareAppTip";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { EdgeRunService } from "@uikit/edgeRun/EdgeRunService";
import { WindowSizeManager } from "@uikit/manager/WindowSizeManager";
import { FotSaveEvent } from "@uikit/service/FotSaveEvent";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { initialReportSizeMapping } from "@uiview/views/Nodes/nodeConfig";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { GuideContainer } from "@uiview/views/Guide";
import { PageNavBar } from "@uiview/views/HomeRoot/PageNavBar";
import { CreatorCustomJSFunction } from "@uiview/views/ProjectCanvas/CreatorRoot/CreatorCustomJSFunction";
import { CreatorCustomAPIFunction } from "@views/thinking-layout-editor/CustomAPIFunction";
import classNames from "classnames";
import css from "./DashboardLayout.module.scss";
import creatorCss from "@uiview/views/HomeRoot/CreatorRoot.module.scss";
import { ProjectPage } from "@uiview/views/HomeRoot/ProjectPage";
import { CreatorRoot } from "@uiview/views/ProjectCanvas/CreatorRoot";
import { CreatorPreviewPanel } from "@uiview/views/ProjectCanvas/CreatorRoot/CreatorPreviewPanel";
import { GetItContext } from "imagica-uikit/dist/base/provider/GetItContext";
import store, { RootState } from "@store/index";
import { CreatorCotService } from "@uikit/service/CreatorCotService";
import { CotStore } from "@uikit/store/CotStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { SharePage } from "@uiview/views/HomeRoot/SharePage";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import HomeMobile from "@uiview/views/Mobile/HomeMobile";
import { MultiRoundChatOnboarding } from "@uiview/views/MultiRoundChatOnboarding/MultiRoundChatOnboarding";
import { PreviewPaneStore, SettingPageContentType } from "@uiview/views/PreviewPane/PreviewPaneStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { ProjectStore } from "imagica-corekit/dist/base/store/ProjectStore";
import { ChatOnboardingStore, ChatOnboardingState } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { HomeUtil } from "imagica-corekit/dist/cases/home/HomeUtil";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";
import { useSelector } from "react-redux";
//!!!该样式从 homeuseplugin 来,为了让 simple-bar 组件样式正常,不能移除
import "simplebar-react/dist/simplebar.min.css";
import { CreatorBackground } from "@uiview/views/ProjectCanvas/CreatorRoot/CreatorBackground";
import LoadingOutline from "@views/components/LoadingOutline";

/**
 *
 * 原 HomeRoot 和 HomeUsePluginV2 组件
 *
 * project,creator,share 通用的逻辑组件
 *
 * TODO: 拆分逻辑,为了后期能够路由渲染
 *
 * @param props
 * @returns
 */
export function DashboardLayout(props: {
  children?: React.ReactNode;
  hideHeader?: boolean;
  loading?: boolean;
}): React.JSX.Element {
  const navigate = useNavigate();
  const urlParam = useParams();
  const invalidShareAppTipState = useImagicaStore(invalidShareAppTipStore).value;
  const edgeRunService = getIt(EdgeRunService);
  const fotSaveService = getIt(FotSaveService);
  const fotSaveEvent = getIt(FotSaveEvent);
  const windowSizeManager = WindowSizeManager.use();
  const fotProcessManager = FotProcessManager.use();
  const isMobile = matchMobile();
  const projectStore = getIt(ProjectStore);
  const homePluginStore = getIt(HomePluginStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const previewPaneStore = getIt(PreviewPaneStore);
  const viewbuilderStore = getIt(ViewbuilderStore);
  const homeStore = getIt(HomeStore);
  const cotStore = getIt(CotStore);
  const showNavPageStore = getIt(ShowNavPageStore);
  const chatOnboardingState = useImagicaStore(chatOnboardingStore).value;
  const viewbuilderState = useImagicaStore(viewbuilderStore).value;
  const cotStoreState = useImagicaStore(cotStore).value;
  const location = useLocation();
  // TODO: 挪动selectedTemplate
  const selectedTemplate: any = useSelector((state: RootState) => state.studio.selectedTemplate);
  const featureTags = homeStore.state.featureTags;
  const creatorCotService = getIt(CreatorCotService);
  const showNavPageState = useImagicaStore(showNavPageStore).value;
  const previewState = useImagicaStore(previewStore).value;

  useEffect(() => {
    fotProcessManager.initImagicaInit();

    func.disableScale();

    const unloadWindowSize = windowSizeManager.init();
    //Logging Amplitude click events
    window.addEventListener("click", HomeMethodsUtil.handleClickAmplitude);
    return () => {
      window.removeEventListener("click", HomeMethodsUtil.handleClickAmplitude);
      unloadWindowSize();

      func.moveDisableScale();
    };
    // eslint-disable-next-line
  }, []);

  // ===== 将ui层的东西添加到其他 class 里面
  // handleSaveService 用到的 urlParam.attrIndexer 需要在 /editor/:attrIndexer 路由下面才能获取
  // 所以没有放在 fot/index.js 中
  useEffect(() => {
    edgeRunService.core.setDependencies({ initialReportSizeMapping });
    // saveTemplateService.setDependencies({ allPreDefinedFunctions });
    fotSaveService.setDependencies({ urlParam });
    fotSaveEvent.setDependencies({ navigate });
  });

  // 监听cot-input结束时候执行runall逻辑
  // FIXME:OPT: cot 执行逻辑在 homeuseplugin，这样会导致 cotStore 变化刷新整个 homeuseplugin
  useEffect(() => {
    /**
     * 此方法只在旧版本的COT流程中使用，新版本的COT流程不需要展示flow的动画
     */
    const loadingForRendering = async (): Promise<void> => {
      cotStore.setCotAutomaticNode(true);
      await creatorCotService.showNode(0);
    };

    // v2的情况下才走原来的逻辑生成剩下的flow
    if (
      cotStoreState.oldCotAnimationState.creating === false &&
      cotStoreState.oldCotAnimationState.inputIds.length > 0 &&
      cotStoreState.cotAutomaticNode
    ) {
      loadingForRendering();
    }
  }, [cotStoreState.oldCotAnimationState.creating]);

  // shownavpage effect
  useEffect(() => {
    if (homePluginStore.state.isShare) {
      return;
    }
    const template = store.getState().studio.selectedTemplate as any;
    const attrIndexer = showNavPageState.showNavPage ? "" : HomeUtil.getAttrIndexerByTemplateProject(template);
    projectStore.setAttrIndexer(attrIndexer);
    // eslint-disable-next-line
  }, [showNavPageState.showNavPage, selectedTemplate]);

  // FIXME: 是否需要静态模式??? static effect
  const isStaticMode = useSelector((state: RootState) => state.editor.isStaticMode);
  useEffect(() => {
    creatorSaasAppStore.setSaasUIData(prevData => ({
      ...prevData,
      staticMode: isStaticMode,
    }));
    // eslint-disable-next-line
  }, [isStaticMode]);

  // TODO: 将下面逻辑移动到 fotReduxFot setSelectedTemplate 中线性执行,避免过度监听状态
  useEffect(() => {
    if (homePluginStore.state.isShare) {
      return;
    }

    const defalutTitle = "Imagica A new way to think and create with computers | Build a no-code AI app in minutes";

    if (!func.isEmpty(selectedTemplate) && !func.isEmpty(selectedTemplate?.name)) {
      document.title = selectedTemplate.name;
    } else {
      document.title = defalutTitle;
    }
  }, [selectedTemplate]);

  // TODO: 将下列逻辑移动到 setShowNavPage 中线性执行
  useEffect(() => {
    /**
     * 回到首页需要清空一些数据
     */
    const resetDataWhenBackToHome = (): void => {
      chatOnboardingStore.setState(new ChatOnboardingState());
      homePluginStore.setInitWithCot(false);
      previewStore.setPreviewCollapseState(false);
      previewPaneStore.setSettingPageContentType(SettingPageContentType.none);
    };

    // 回到首页时
    if (location.pathname === "/editor" && showNavPageState.showNavPage) {
      resetDataWhenBackToHome();
    }
    // eslint-disable-next-line
  }, [location.pathname, showNavPageState.showNavPage]);

  useEffect(() => {
    if (viewbuilderState.viewBuilderGraph) {
      creatorSaasAppStore.setSaasUIData((prevData: any) => {
        return {
          ...prevData,
          viewBuilderGraph: viewbuilderState.viewBuilderGraph,
        };
      });
    }
    // eslint-disable-next-line
  }, [viewbuilderState.viewBuilderGraph]);

  const RenderMultiRoundChatOnboarding = useMemo(() => {
    if (
      featureTags.disableMultiRoundChatOnboarding === false &&
      showNavPageState.showNavPage === false &&
      (cotStoreState.showMultiRoundChatOnboarding || chatOnboardingState.isOpenChat)
    ) {
      return <MultiRoundChatOnboarding />;
    }
    return null;
  }, [
    featureTags.disableMultiRoundChatOnboarding,
    showNavPageState.showNavPage,
    cotStoreState.showMultiRoundChatOnboarding,
    chatOnboardingState.isOpenChat,
    chatOnboardingState.selectTemplateInModal,
    previewState.showPreviewPanel,
  ]);

  if (invalidShareAppTipState.showInvalidShareAppTip) {
    return <InvalidShareAppTip />;
  }

  // render
  if (homePluginStore.state.isShare) {
    return (
      <GetItContext.Provider value={getIt}>
        <SharePage />
      </GetItContext.Provider>
    );
  }

  //  移动端
  if (isMobile && featureTags.disableMobilePage) {
    return <HomeMobile />;
  }

  const renderChildrenElement = (): React.ReactNode => {
    if (props.loading) {
      return (
        <CreatorBackground>
          <LoadingOutline />
        </CreatorBackground>
      );
    }

    if (props.children) {
      return props.children;
    }

    return showNavPageState.showNavPage ? <ProjectPage /> : <CreatorRoot />;
  };

  return (
    <div
      className={classNames(css.wrap, creatorCss["creatorRoot"])}
      ref={creatorRefStore.homeRef}
      id="HomeRoot"
      data-testid="HomeRoot"
      data-id="DashboardLayout"
    >
      {!props.hideHeader && <PageNavBar />}

      {renderChildrenElement()}

      <CreatorCustomJSFunction />

      <CreatorCustomAPIFunction />

      {showNavPageState.showNavPage === false && (
        <GetItContext.Provider value={getIt}>
          <CreatorPreviewPanel />
        </GetItContext.Provider>
      )}

      <GuideContainer />

      {RenderMultiRoundChatOnboarding}
    </div>
  );
}
