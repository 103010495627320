"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioProjectAttributesV2UIInputPositionAbsolute = exports.StudioProjectAttributesV2UIInputPosition = exports.StudioProjectAttributesV2UIInputEdgeIndexRef = exports.StudioProjectAttributesV2UIInputNodeIndexRef = exports.StudioProjectAttributesV2UIInputData = exports.StudioProjectAttributesV2UIInput = void 0;
const class_transformer_1 = require("class-transformer");
require("reflect-metadata");
class StudioProjectAttributesV2UIInput {
}
exports.StudioProjectAttributesV2UIInput = StudioProjectAttributesV2UIInput;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UIInputData)
], StudioProjectAttributesV2UIInput.prototype, "data", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UIInputPosition)
], StudioProjectAttributesV2UIInput.prototype, "position", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UIInputPositionAbsolute)
], StudioProjectAttributesV2UIInput.prototype, "positionAbsolute", void 0);
class StudioProjectAttributesV2UIInputData {
}
exports.StudioProjectAttributesV2UIInputData = StudioProjectAttributesV2UIInputData;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UIInputNodeIndexRef)
], StudioProjectAttributesV2UIInputData.prototype, "nodeIndexRef", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2UIInputEdgeIndexRef)
], StudioProjectAttributesV2UIInputData.prototype, "edgeIndexRef", void 0);
class StudioProjectAttributesV2UIInputNodeIndexRef {
}
exports.StudioProjectAttributesV2UIInputNodeIndexRef = StudioProjectAttributesV2UIInputNodeIndexRef;
class StudioProjectAttributesV2UIInputEdgeIndexRef {
}
exports.StudioProjectAttributesV2UIInputEdgeIndexRef = StudioProjectAttributesV2UIInputEdgeIndexRef;
class StudioProjectAttributesV2UIInputPosition {
}
exports.StudioProjectAttributesV2UIInputPosition = StudioProjectAttributesV2UIInputPosition;
class StudioProjectAttributesV2UIInputPositionAbsolute {
}
exports.StudioProjectAttributesV2UIInputPositionAbsolute = StudioProjectAttributesV2UIInputPositionAbsolute;
