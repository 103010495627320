import { useSignal } from "@preact/signals-react";
import { get } from "lodash";
import { Food, SearchParam as SearchFoodParam } from "imagica-corekit/dist/base/api/foodTyped/Food";
import { Restaurant, RestaurantResponseData, SearchParam } from "imagica-corekit/dist/base/api/foodTyped/Restaurant";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { getIt } from "../../../uikit/getIt";
import { FotClient } from "imagica-corekit/dist/base/api/FotClient";

export class FoodNodeBloc {
  fotClient = getIt(FotClient);

  loading = useSignal<boolean>(false);
  restaurantList = useSignal<Array<Restaurant>>([]);
  offset = useSignal<number>(0);
  query = useSignal<string>("");

  async getRestaurantList(param: SearchParam): Promise<Array<Restaurant>> {
    const res = await this.fotClient.foodApi.getRestaurantList(param);
    return res.stores;
  }

  async getFoodList(param: SearchFoodParam): Promise<Array<Food>> {
    const res = await tryPromise(this.fotClient.foodApi.getFoodList(param));
    return get(res, "data.items", []);
  }

  async resetData(restaurantList: RestaurantResponseData) {
    this.restaurantList.value = [];
    this.offset.value = 0;
    this.query.value = restaurantList.query;
    this.getData(null, get(restaurantList, "stores"));
  }

  async getData(params: SearchParam | null, restaurantList: Restaurant[] = []) {
    let list = restaurantList;

    if (params) {
      this.loading.value = true;
      const { data } = await tryPromise(this.getRestaurantList(params));
      this.loading.value = false;
      list = data || [];
    }

    this.offset.value = params?.offset ?? 0;
    this.restaurantList.value = [...this.restaurantList.value, ...list];
  }

  async getNextPageData() {
    this.getData({ offset: this.offset.value + 5, query: this.query.value });
  }
}
