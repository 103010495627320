import React from "react";
import style from "./VariablePopoverContent.module.css";

const GuidanceContent = (props: { type: "customJsFunc" | "customApiFunction" }) => {
  const CurrentSourceNodeValue = () => (
    <div className={style["input-node"]}>
      Use
      <span className={style["hight-light-text"]}>currentSourceNodeValue</span>
      to inject the value of the input node.
    </div>
  );

  const BrainAuthToken = () => (
    <div className={style["input-node"]}>
      Use
      <span className={style["hight-light-text"]}>brainAuthToken</span>
      to inject the value of the user token.
    </div>
  );

  return (
    <>
      {props.type === "customJsFunc" && (
        <div className={style["guidance-content"]}>
          <h5>Variables in custom JS functions</h5>
          <div className={style["input-node"]}>
            HTML/CSS format:
            <span className={style["hight-light-text"]}>{"${var_name}"}</span>
          </div>
          <div>
            JS format:
            <span className={style["hight-light-text"]}>var_name</span>
          </div>

          <CurrentSourceNodeValue />

          <BrainAuthToken />
        </div>
      )}
      {props.type === "customApiFunction" && (
        <div className={style["guidance-content"]}>
          <h5>Variables in custom API functions</h5>
          <div className={style["input-node"]}>
            Header/Body/Url format:
            <span className={style["hight-light-text"]}>{"${var_name}"}</span>
          </div>

          <CurrentSourceNodeValue />

          <BrainAuthToken />
        </div>
      )}
    </>
  );
};

export default GuidanceContent;
