import React, { useEffect, useState } from "react";
import style from "./components-css/ActivateError.module.css";
import func from "@uikit/func";
import { getQueryParams } from "./thinking-layout-editor/util";
import { Button } from "antd";

const errorTips = "The activation link has expired, please click the button to generate the link again.";
const ActivateError = props => {
  const [redirectUrl, setRedirectUrl] = useState("");

  const regenerateLink = () => {
    window.open(redirectUrl, "_self");
  };

  useEffect(() => {
    const urlParams = getQueryParams();
    if (!func.isEmpty(urlParams?.redirect_url)) {
      const url = decodeURIComponent(urlParams.redirect_url);
      setRedirectUrl(url);
    }
  }, []);

  return (
    <div className={style["error-stripe"]}>
      <span>{errorTips}</span>
      <Button type="primary" onClick={regenerateLink}>
        Regenerate
      </Button>
    </div>
  );
};

export default ActivateError;
