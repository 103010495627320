"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomePluginStore = exports.HomePluginStoreState = void 0;
const ReadyStatus_1 = require("../cutil/ReadyStatus");
const StoreBase_1 = require("../cutil/StoreBase");
/**
 * 该 store 应该只控制 imagica 入口相关状态(fot/index.js)
 *
 * 比如是否是 share，其他状态不应该影响 画布/preview/ 等组件，否则会导致所有组件rerender
 *
 * 应该移动到靠近绑定ui的组件，而不是挂在到 index 根组件，因为 share 需要将ui绑定在根组件，用来渲染是否时 share或canvas
 */
class HomePluginStoreState {
    constructor() {
        /**
         * @deprecated 应该从根组件移走
         */
        this.disablePublishAsContent = false;
        /**
         * 是否通过cot创建的项目
         *
         * @deprecated 应该从根组件移走
         */
        this.initWithCot = false;
        //是否为分享链接
        this.isShare = false;
        //当前绑定的第3方domain
        this.customDomain = "";
        //是否显示callerBot
        /**
         * @deprecated 应该从根组件移走
         */
        this.showCallerBot = false;
        this.historyIndex = { current: -1 };
        this.showImagicaTutoriralLoading = ReadyStatus_1.ReadyStatus.PENDING;
    }
    copyWith(params) {
        const state = new HomePluginStoreState();
        state.disablePublishAsContent = params.disablePublishAsContent ?? this.disablePublishAsContent;
        state.initWithCot = params.initWithCot ?? this.initWithCot;
        state.isShare = params.isShare ?? this.isShare;
        state.customDomain = params.customDomain ?? this.customDomain;
        state.studioData = params.studioData ?? this.studioData;
        state.showCallerBot = params.showCallerBot ?? this.showCallerBot;
        state.historyIndex = params.historyIndex ?? this.historyIndex;
        state.showImagicaTutoriralLoading = params.showImagicaTutoriralLoading ?? this.showImagicaTutoriralLoading;
        return state;
    }
}
exports.HomePluginStoreState = HomePluginStoreState;
class HomePluginStore extends StoreBase_1.StoreBase {
    constructor() {
        super(() => {
            return new HomePluginStoreState();
        });
        this.undoData = { current: [] };
    }
    setDisablePublishAsContent(disablePublishAsContent) {
        const newState = this.state.copyWith({ disablePublishAsContent });
        this.emit(newState);
    }
    setInitWithCot(initWithCot) {
        const newState = this.state.copyWith({ initWithCot });
        this.emit(newState);
    }
    setIsShare(isShare) {
        const newState = this.state.copyWith({ isShare });
        this.emit(newState);
    }
    setCustomDomain(customDomain) {
        const newState = this.state.copyWith({ customDomain });
        this.emit(newState);
    }
    setStudioData(studioData) {
        const newState = this.state.copyWith({ studioData });
        this.emit(newState);
    }
    setShowCallerBot(showCallerBot) {
        const newState = this.state.copyWith({ showCallerBot });
        this.emit(newState);
    }
    setHistoryIndex(historyIndex) {
        const newState = this.state.copyWith({
            historyIndex: {
                current: historyIndex,
            },
        });
        this.emit(newState);
    }
    setShowImagicaTutoriralLoading(showImagicaTutoriralLoading) {
        const newState = this.state.copyWith({ showImagicaTutoriralLoading });
        this.emit(newState);
    }
    resetUndoData() {
        this.undoData.current = [];
    }
}
exports.HomePluginStore = HomePluginStore;
