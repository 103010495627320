import { getCommandData } from "@uiview/views/CommandBar/_util";
import { useFunctionsPermission } from "@uiview/views/Nodes/hooks/useFunctionsPermission";
import { Command } from "cmdk";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import SimpleBar from "simplebar-react";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import useFeatureTags from "../../custom-hooks/useFeatureTags";
import { fotActions } from "../../store/fot";
import func from "@uikit/func";
import style from "./css/CustomDropdown.module.css";
import { groupTitleOptions } from "./nodeTypeComponents/nodeTypeDispose";
import { isNil } from "lodash";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";

function CustomDropdown(props) {
  const { getFilterList } = useFunctionsPermission();
  const store = useStore();
  const dispatch = useDispatch();
  const feature_tags = useFeatureTags();
  const commandInputRef = useRef(null);

  const developerMode = useSelector(state => state.fot.developerMode);
  const setImageGenSelectOptions = val => {
    dispatch(fotActions.setImageGenSelectOptions(val));
  };
  const scrollRef = useRef();
  const [createFunctions, setCreateFunctions] = useState([
    {
      name: "Create new custom JS function",
      value: "createCustomJSFunc",
      display: true,
    },
    {
      name: "Create API function",
      value: "createAPIFunc",
      display: true,
    },
  ]);

  const [groupSortArr, setGroupSortArr] = useState([]);
  const [filteredApiArr, setFilteredApiArr] = useState([]);
  const [groupTypeSelectOptions, setGroupTypeSelectOptions] = useState([]);

  const getGroupingTitle = key => {
    const other = {
      value: "other",
      label: "Other",
    };
    // const arr = [...nodeTypeOptions]
    const arr = [...groupTitleOptions];
    arr.push(other);
    const obj = arr.find(x => x.value?.toLowerCase() === key?.toLowerCase());
    return obj?.label || "";
  };

  const groupFilter = () => {
    const arr = [...filteredApiArr];
    // use pre-defined order to sort, then use 首字母排序
    arr.sort((a, b) => {
      if (a.order !== b.order) {
        return a.order - b.order;
      }
      const aFirstLetter = a.label.substring(0, 1).toLowerCase();
      const bFirstLetter = b.label.substring(0, 1).toLowerCase();
      return aFirstLetter.localeCompare(bFirstLetter);
    });
    const groups = {};
    arr.forEach(x => {
      const group = x?.groupTitle || "other";
      const newObj = Object.assign({}, x, {
        // icon: group === 'imageGeneration' ?  `${settings.S3CDN}${settings.viewAssetsPath}image-icon.png` :  VALUE_ICON[x.value]
        // icon: getCommandBarIcon(x)
        commandBar: getCommandData(x),
      });
      groups[group] = groups[group] || [];
      groups[group].push(newObj);
    });
    return groups;
  };

  const isPermission = (functionsConfig, value) => {
    if (isNil(functionsConfig)) return true;
    const isInclude = functionsConfig.filterList.includes(value);
    if (
      (functionsConfig.filterType === "include" && isInclude) ||
      (functionsConfig.filterType === "exclude" && isInclude === false)
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    props.inputOnRef(commandInputRef.current);
  }, []);

  useEffect(() => {
    if (func.isEmpty(props.apiArr)) {
      setGroupTypeSelectOptions([]);
      setGroupSortArr([]);
      return;
    }
    const groupTypeSelectOptions = groupFilter();

    if (scrollRef.current) {
      scrollRef.current.contentWrapperEl.scrollTop = 0;
    }

    setGroupTypeSelectOptions(groupTypeSelectOptions);
    // image分组排第一
    // const firstData = 'imageGen'
    let groups = Object.keys(groupTypeSelectOptions);
    const groupSortOrder = groupTitleOptions.map(x => x.value);
    let groupSort = [];
    groupSortOrder.forEach(x => {
      if (groupTypeSelectOptions[x]) {
        groupSort.push(x);
      }
      groups = groups.filter(y => y !== x);
    });
    groupSort = groupSort.concat(groups);
    // const topFunctionsIndex = groupSort.findIndex(x => x === firstData)
    // if(topFunctionsIndex !== -1) {
    //   groupSort.splice(topFunctionsIndex, 1)
    //   groupSort.unshift(firstData)
    // }

    // remove empty group
    groupSort = groupSort.filter(x => {
      const group = groupTypeSelectOptions[x];
      if (group === undefined) {
        return false;
      }
      const nonEmpty = group.some(x => {
        return (
          isPermission(groupFunctionsConfig, x.value) === true &&
          isPermission(functionsConfig, x.value) === true &&
          x.hide !== true
        );
      });
      return nonEmpty === true;
    });

    setGroupSortArr([...groupSort]);
  }, [filteredApiArr]);

  useEffect(() => {
    const imageGenSelectOptionsArr = [...store.getState().fot.imageGenSelectOptions];
    const proteinIndex = imageGenSelectOptionsArr.findIndex(item => item.label === "Protein");
    if (proteinIndex !== -1) {
      const newProtein = Object.assign(
        {},
        { ...imageGenSelectOptionsArr[proteinIndex] },
        { hide: !feature_tags.showProtein }
      );
      imageGenSelectOptionsArr[proteinIndex] = newProtein;
    }
    setImageGenSelectOptions(imageGenSelectOptionsArr);
  }, [feature_tags.showProtein]);

  useEffect(() => {
    // create func受限于develop mode
    setCreateFunctions(prevList => {
      return prevList.map(x => {
        return {
          ...x,
          display: developerMode,
        };
      });
    });
  }, [developerMode]);

  useEffect(() => {
    /**
     * 有需要过滤的function 就往这个Map添加
     */
    let arr = [...props.apiArr].filter(x => x.hide !== true);
    setFilteredApiArr(arr);
  }, [props.apiArr]);

  useEffect(() => {
    const onDocumentClick = e => {
      props.onHideDropdown(e);
    };

    const onContextMenu = e => {
      props.onHideDropdown(e);
    };

    const onKeydown = e => {
      if (e.code === "Escape") {
        props.onHideDropdown(e);
      }
    };

    document.addEventListener("click", onDocumentClick, false);
    document.addEventListener("contextmenu", onContextMenu, false);
    document.addEventListener("keydown", onKeydown, true);

    return () => {
      document.removeEventListener("click", onDocumentClick, false);
      document.removeEventListener("contextmenu", onContextMenu, false);
      document.removeEventListener("keydown", onKeydown, true);
    };
  }, []);

  useEffect(() => {
    if (props.aiDescription.includes("/")) {
      commandInputRef.current?.focus();
    }
  }, [props.aiDescription]);

  /**
   * 当edge的输入框聚焦，并且此时dropdown也显示，那么这个时候如果继续输入，serchfunDescription就会将在edge输入框的文本追加起来，并且此时应该聚焦
   * 避免影响到v2，所以单独谢了一个focus
   */
  useEffect(() => {
    if (props.aiDescription.includes("/") && commandInputRef.current !== document.activeElement) {
      commandInputRef.current?.focus();
    }
  }, [props.serchFunDescription]);

  const [functionsConfig, groupFunctionsConfig] = useMemo(() => {
    return getFilterList(props.sourceId);
  }, []);

  return (
    <div
      style={props.style}
      className={`${style["raycast"]} nowheel ${props.disableHover ? style["disableHover"] : ""}`}
      onClick={e => e.stopPropagation()}
      data-testid="CustomDropdown"
    >
      <Command shouldFilter={false} onKeyDown={props.onCommandKeyDown}>
        <div className={style["commandbar-head"]} data-testid="CommandBar-SearchInput-Wrap">
          {/* <SearchOutlined className={style['searchInput-icon']} /> */}
          <span className={style["searchInput-icon"]}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.75 9C2.75 5.54822 5.54822 2.75 9 2.75C12.4518 2.75 15.25 5.54822 15.25 9C15.25 12.4518 12.4518 15.25 9 15.25C5.54822 15.25 2.75 12.4518 2.75 9ZM9 1.25C4.71979 1.25 1.25 4.71979 1.25 9C1.25 13.2802 4.71979 16.75 9 16.75C10.87 16.75 12.5853 16.0877 13.9242 14.9848L17.4697 18.5303C17.7626 18.8232 18.2374 18.8232 18.5303 18.5303C18.8232 18.2374 18.8232 17.7626 18.5303 17.4697L14.9848 13.9242C16.0877 12.5853 16.75 10.87 16.75 9C16.75 4.71979 13.2802 1.25 9 1.25Z"
                fill="#020C26"
                fillOpacity="0.35"
              />
            </svg>
          </span>
          <Command.Input
            className={style["searchInput"]}
            data-testid="CommandBar-SearchInput"
            ref={commandInputRef}
            value={props.searchFunc}
            onValueChange={props.handleValueChange}
            placeholder="Search for functions"
          />
        </div>

        <div className={style["commandbar-content"]}>
          {/* functions list */}
          <SimpleBar className={style["commandScroll"]} ref={scrollRef} forceVisible={true} autoHide={false}>
            <Command.List data-testid="CommandBar-SearchList">
              <Command.Empty>No results found</Command.Empty>
              {!func.isEmpty(groupTypeSelectOptions)
                ? groupSortArr.map(y => {
                    return (
                      <Command.Group key={y} heading={getGroupingTitle(y)}>
                        {!func.isEmpty(groupTypeSelectOptions[y])
                          ? groupTypeSelectOptions[y].map((x, index) => {
                              const { commandBar, value } = x;

                              if (isPermission(groupFunctionsConfig, value) === false) {
                                return null;
                              }
                              if (isPermission(functionsConfig, value) === false) {
                                return null;
                              }

                              return (
                                <Command.Item
                                  key={x.value + index}
                                  className={style["commandbar-item"]}
                                  style={x.hide === true ? { display: "none" } : undefined}
                                  onSelect={() => props.clickToSelectValue(x)}
                                >
                                  <div className={style["commandbar-item-inner"]}>
                                    <div className={style["commandbar-item-icon"]}>
                                      <img src={commandBar.icon} />
                                    </div>
                                    <div className={style["commandbar-item-content"]}>
                                      <div className={style["commndbar-item-label"]}>{x.label}</div>
                                      {commandBar.description ? (
                                        <div className={style["commndbar-item-desc"]}>{commandBar?.description}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Command.Item>
                              );
                            })
                          : null}
                      </Command.Group>
                    );
                  })
                : null}
            </Command.List>

            {/* create function */}
            {/* STUD-1519: 确保两个 Command List 同时渲染，除非第一个 List 确实没有数据 */}
            {developerMode && (func.isEmpty(filteredApiArr) || !func.isEmpty(groupTypeSelectOptions)) ? (
              <Command.List data-testid="CommandBar-SearchList">
                <Command.Group heading="Create">
                  {createFunctions.map((x, index) => {
                    return x.display ? (
                      <Command.Item
                        className={style["commandbar-item"]}
                        key={x.value}
                        onSelect={() => props.clickCreateFunction(x)}
                      >
                        <div className={style["commandbar-item-inner"]}>
                          <div className={style["commandbar-item-icon"]}>
                            <img src={`${settings.S3CDN}${settings.viewAssetsPath}brain-logo.png`} />
                          </div>
                          <div className={style["commandbar-item-content"]}>
                            <div className={style["commndbar-item-label"]}>{x.name}</div>
                          </div>
                        </div>
                      </Command.Item>
                    ) : null;
                  })}
                </Command.Group>
              </Command.List>
            ) : null}
          </SimpleBar>
        </div>
      </Command>
    </div>
  );
}

export default CustomDropdown;
