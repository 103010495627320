import {
  StudioProjectAttributesV2EdgeData,
  StudioProjectAttributesV2EdgeDataLineParam,
  StudioProjectV3EdgeFlow,
} from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { StudioProjectAttributesV3Line } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Line";
import { StudioProjectAttributesV3Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Node";
import func from "@uikit/func";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { isEmpty, uniqBy } from "lodash";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";

export class NodeDeletionHandler {
  constructor(
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public projectNodeStore: ProjectNodeStore,
    public fotReduxStore: FotReduxStore
  ) {}

  onNodesDeleteV3(
    delNodes: StudioProjectAttributesV3Node[],
    oldEdges: StudioProjectAttributesV3Line[],
    oldNodes: StudioProjectAttributesV3Node[]
  ): {
    delLines: (StudioProjectAttributesV3Line | undefined)[];
    delEdgeNodes: (StudioProjectAttributesV3Node | undefined)[];
  } {
    const delEdgeNodes: Array<StudioProjectAttributesV3Node | undefined> = [];
    let delLines: Array<StudioProjectAttributesV3Line | undefined> = [];

    delNodes.forEach(node => {
      const sourceNodeLines = oldEdges.filter(edge => edge.source === node.id);
      if (!isEmpty(sourceNodeLines)) {
        const currEdges = oldNodes.filter(x => sourceNodeLines.some(y => y.target === x.id));
        currEdges.forEach(currEdge => {
          this.handelDelSourceNode(currEdge, oldEdges, node, delEdgeNodes, delLines);
        });
      }

      const targetNodeLine = oldEdges.find(edge => edge.target === node.id);
      if (!isEmpty(targetNodeLine)) {
        const currEdge = oldNodes.find(n => n.id === targetNodeLine?.source);
        const currEdgeData = currEdge?.data as StudioProjectAttributesV2EdgeData;
        if (!currEdge || !currEdgeData || !currEdgeData?.flows) return;
        const sourceLines = this.getSourceLinesByEdge(currEdge, oldEdges);
        delLines = [...delLines, ...sourceLines, targetNodeLine];

        if (!isEmpty(currEdge)) {
          this.delNodesNoTriggerListener([currEdge.id]);
          delEdgeNodes.push(currEdge);
        }
      }
    });

    this.delEdgesNoTriggerListener(delLines.map(line => line?.id as string));
    return {
      delLines: uniqBy(delLines, "id"),
      delEdgeNodes: uniqBy(delEdgeNodes, "id"),
    };
  }

  handelDelSourceNode(
    currEdge: StudioProjectAttributesV3Node | undefined,
    oldEdges: StudioProjectAttributesV3Line[],
    node: StudioProjectAttributesV3Node,
    delEdgeNodes: (StudioProjectAttributesV3Node | undefined)[],
    delLines: any[]
  ): void {
    const currEdgeData = currEdge?.data as StudioProjectAttributesV2EdgeData;
    if (!currEdge || !currEdgeData || !currEdgeData?.flows) return;
    const sourceLines = this.getSourceLinesByEdge(currEdge, oldEdges);
    const targetLine = oldEdges.find(edge => edge.id === currEdgeData.targetLineId);

    if (currEdgeData.flows.length === 1) {
      this.delNodesNoTriggerListener([currEdge.id]);
      delEdgeNodes.push(currEdge);
      delLines.push(...sourceLines, targetLine);
    } else {
      const delLineId = currEdgeData.flows.find(x => x.sourceNodeId === node.id)?.sourceLineId;
      if (delLineId) {
        const delLine = oldEdges.find(edge => edge.id === delLineId);
        if (delLine) {
          delLines.push(delLine);
        }
      }

      this.creatorNodesStore.setNodes(prevList =>
        prevList.map(n => {
          if (n.id === currEdge.id) {
            const data = n?.data as StudioProjectAttributesV2EdgeData;
            const flows = this.getFlows(data?.flows as StudioProjectV3EdgeFlow[], node.id);
            const newVarilableNames = this.getVarilableNames(
              data?.lineParam as StudioProjectAttributesV2EdgeDataLineParam,
              node.id
            );
            return {
              ...n,
              data: {
                ...n.data,
                flows,
                lineParam: {
                  ...data.lineParam,
                  varilableNames: newVarilableNames,
                },
              },
            };
          }
          return n;
        })
      );
    }
  }

  getSourceLinesByEdge(
    currEdge: StudioProjectAttributesV3Node,
    oldEdges: StudioProjectAttributesV3Line[]
  ): StudioProjectAttributesV3Line[] {
    return oldEdges.filter(
      edge =>
        currEdge.data &&
        (currEdge.data as StudioProjectAttributesV2EdgeData).flows?.some(f => f.sourceLineId === edge.id)
    );
  }

  delNodesNoTriggerListener(delNodeIds: string[]): void {
    this.creatorNodesStore.setNodes(prevList => prevList.filter(n => !delNodeIds.includes(n.id)));
  }

  delEdgesNoTriggerListener(delEdgeIds: string[]): void {
    this.creatorEdgesStore.setEdges(prevList => prevList.filter(n => !delEdgeIds.includes(n.id)));
  }

  getFlows(flows: StudioProjectV3EdgeFlow[], id: string): StudioProjectV3EdgeFlow[] {
    let newFlows = flows.filter(f => f.sourceNodeId !== id);
    if (newFlows.every(x => !x.creationMethod)) {
      newFlows = newFlows.map((y, index) => {
        if (index === 0) {
          return { ...y, creationMethod: true };
        }
        return y;
      });
    }
    return newFlows;
  }

  getVarilableNames(lineParam: StudioProjectAttributesV2EdgeDataLineParam, id: string): string[] {
    const varilableNames = lineParam?.varilableNames || [];
    const variableList = this.fotReduxStore.getState().fot.variableList || [];
    const variableNodeData: any = variableList.find((x: any) => x?.node?.id === id);
    if (func.isEmpty(variableNodeData)) return varilableNames;
    return varilableNames.filter(x => x !== variableNodeData.name);
  }
}
