import { useEffect, useRef } from "react";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import styles from "./VideoView.module.css";

type VideoViewProps = {
  src: string;
  onEnded?: React.ReactEventHandler<HTMLVideoElement>;
  onClose?: () => void;
  volume?: number;
};

export const VideoView: React.FC<VideoViewProps> = ({ src, onEnded, onClose, volume = 0.5 }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleClickClose = () => {
    onClose?.();
  };

  const handleEnded: React.ReactEventHandler<HTMLVideoElement> = event => {
    onEnded?.(event);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }, [videoRef, volume]);

  return (
    <div className={styles.videoView}>
      <div className={styles.videoBox}>
        <span className={`${styles["iconClose"]}`} onClick={handleClickClose}>
          <img src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`} alt="" />
        </span>

        <div>
          <video ref={videoRef} autoPlay controls width="100%" height="100%" src={src} onEnded={handleEnded} />
        </div>
      </div>
    </div>
  );
};
