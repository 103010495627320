import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import style from "../views-css/StudiosSignUp.module.css";
import func from "@uikit/func";
import { Button, Form, Input } from "antd";
import { Api } from "../uikit/service/api";
import { getDefaultRole } from "../uikit/login";
import { editorActions } from "../store/editor";
import { GoogleOauthButton } from "./components/GoogleOauthButton";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import useLinkParamsMetadata from "../custom-hooks/useLinkParamsMetadata";
import { getIt } from "@uikit/getIt";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import isBlank from "@sedan-utils/is-blank";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { useSignal } from "@preact/signals-react";
import { matchMobile } from "@uikit/func";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";

const StudiosSignUp = props => {
  const isMobile = matchMobile();
  const homePluginStore = getIt(HomePluginStore);
  const homeUsePluginState = useStore(homePluginStore).value;
  const webConfigState = useStore(getIt(WebConfigStore)).value;
  const fotProcessManager = FotProcessManager.use();

  const api = new Api(false);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const bgVideoRef = useRef(null);

  const inputBoxRef = useRef(null);
  const signUpContentRef = useRef(null);
  const emailInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [textData] = useState({
    title: "Let’s get started",
  });
  const [logInLoading, setLogInLoading] = useState(false);
  const [errorPrompt, setErrorPrompt] = useState("");
  const [otp, setOtp] = useState("");
  const [msg, setMsg] = useState(0);
  const statementList = useSignal([]);
  const [isOtp, setIsOtp] = useState(false);
  const [showResetText, setShowResetText] = useState(false);
  const isInputFocus = useSignal(false);

  const { metadata } = useLinkParamsMetadata();
  const setIsClickLogin = val => {
    dispatch(editorActions.setIsClickLogin(val));
  };
  const setIsClickSignup = val => {
    dispatch(editorActions.setIsClickSignup(val));
  };
  const isLogin = useSelector(state => state.fot.isLogin);
  const navigate = useNavigate();

  const clickCreate = isReacquire => {
    setErrorPrompt("");
    if (func.isEmpty(formRef.current)) return;
    // 验证 email password first_name 不能为空
    formRef.current
      .validateFields()
      .then(res => {
        loginInterface(res, isReacquire);
      })
      .catch(console.error);
  };

  const loginInterface = async (value, isReacquire) => {
    // 账号登录
    if (!func.isEmpty(value.email) && !func.isEmpty(value.password)) {
      const nameArr = value.firstName.split(" ");
      const firstName = nameArr[0];
      nameArr.splice(0, 1);
      const lastName = nameArr.join(" ");

      const userData = {
        email: value.email,
        password: value.password,
        first_name: firstName,
        last_name: lastName,
        profile: {},
      };
      if (!func.isEmpty(metadata)) {
        userData.metadata = metadata;
      }

      if (getDefaultRole()) {
        userData.roles = getDefaultRole();
      }

      if (!func.isEmpty(otp) && !isReacquire) {
        userData.otp = otp;
      }
      setLogInLoading(true);
      api
        .post("/api/users/signup.json", userData)
        .then(response => response.json())
        .then(data => {
          // 注册成功
          if (data?.auth_token?.key) {
            localStorage.setItem("BRAIN_USER_EMAIL", data.email);
            getIt(FotAuthStore).setBrainToken(data.auth_token.key);
            return Promise.resolve();
          }

          if (data?.email || data?.[0] === "Invalid OTP") {
            setLogInLoading(false);
            setErrorPrompt(data?.email || data?.[0] || "sign up failed");
            return Promise.reject("resloved error");
          }

          // 验证 otp 消息
          if (!func.isEmpty(data?.OTP_EXP)) {
            setLogInLoading(false);
            const otpExp = Number(data?.OTP_EXP) || 60;
            timeTransition(otpExp);
            setIsOtp(true);
            return Promise.reject("resloved error");
          }

          setErrorPrompt("sign up failed");
        })
        .then(() => {
          setIsClickSignup(true);
          return fotProcessManager.init();
          // return getUserMe();
        })
        .then(() => {
          setLogInLoading(false);
          setIsClickSignup(true);
          setIsClickLogin(true);
          if (searchParams.get("mode") === "subscriber") {
            searchParams.delete("mode");
            setSearchParams(searchParams);
            const url = window.location.href.replace("/signup", "/editor");
            window.history.pushState({}, "", url);
          }
          props.setIsLogin(true);
        })
        .catch(error => {
          // resloved error 已经处理
          if (error === "resloved error") return;
          setLogInLoading(false);
          if (error.name === 403) {
            setIsOtp(true);
            setErrorPrompt(error?.email || "sign up failed");
            return;
          }
          setErrorPrompt("sign up failed");
        });
    }
  };

  const clickResend = () => {
    clickCreate(true);
  };

  const timeTransition = ms => {
    let maxtime = ms;
    let timer = null;

    setTimeout(function f() {
      if (maxtime >= 0) {
        setMsg(maxtime);
        --maxtime;
      } else {
        setShowResetText(true);
        setMsg(0);
        clearTimeout(timer);
        return;
      }
      timer = setTimeout(f, 1000);
    }, 0);
  };

  const onFieldsChange = (field, form) => {
    // 输入框的 change 事件

    // 错误提示在内容变更后消失
    if (!func.isEmpty(errorPrompt)) {
      setErrorPrompt("");
    }
  };

  const onInputFocus = type => {
    if (!isMobile) return;
    isInputFocus.value = true;

    const allItemsTopConfig = {
      email: 50 + 20,
      name: inputBoxRef.current.clientHeight / 4 + 90 + 20,
      password: inputBoxRef.current.clientHeight / 2 + 30 + 20,
    };
    if (isBlank(allItemsTopConfig[type])) return;
    signUpContentRef.current.scrollTo({
      top: allItemsTopConfig[type],
      behavior: "smooth",
    });
  };
  const onInputBlur = () => {
    if (!isMobile) return;
    setTimeout(() => {
      if (document.activeElement.tagName.toLowerCase() === "input") return;
      isInputFocus.value = false;
      signUpContentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  };

  useEffect(() => {
    const googleTxt = isBlank(homeUsePluginState.customDomain) ? ` or "Sign up with Google" ` : " ";
    const result = [
      {
        isMatch: false,
        orginalText: `By selecting "Create an account"${googleTxt}you agree to our `,
      },
      {
        isMatch: true,
        orginalText: "Terms of Service (Platform)",
        link: webConfigState.static_links?.terms_of_service_platform || "https://brain.ai/tos-platform",
      },
      { isMatch: false, orginalText: ", " },
      {
        isMatch: true,
        orginalText: "Terms of Service (Environment)",
        link: webConfigState.static_links?.terms_of_service_environment || "https://brain.ai/tos-environment",
      },
      { isMatch: false, orginalText: " and " },
      {
        isMatch: true,
        orginalText: "Privacy Policy",
        link: webConfigState.static_links?.privacy || "https://brain.ai/privacy",
      },
    ];
    statementList.value = result;
    // eslint-disable-next-line
  }, [webConfigState.static_links]);

  useEffect(() => {
    const preFillEmail = searchParams.get("email");
    if (preFillEmail) {
      props.setEmail(preFillEmail);
      formRef.current.setFieldValue("email", preFillEmail);
    }

    if (func.isEmpty(bgVideoRef.current)) return;
    bgVideoRef.current.play();
  }, []);

  useEffect(() => {
    getIt(WebConfigStore).get();
    if (isLogin) {
      navigate("/editor");
    }
    window.addEventListener("focusout", onInputBlur);
    return () => {
      window.removeEventListener("focusout", onInputBlur);
    };
  }, []);

  return (
    <div className={style.login_imagica}>
      <video
        className={style["bg-video"]}
        ref={bgVideoRef}
        src={`${settings.S3CDN}${settings.viewAssetsPath}site_bg_1.mp4`}
        muted
        autoPlay
        loop
        x5-playsinlinee="true"
        playsInline
        webkit-playsinline="true"
      ></video>

      {/* 顶部logo */}
      <div
        className={classNames(
          isBlank(homeUsePluginState.customDomain) && !isInputFocus.value
            ? style["logo-box"]
            : style["logo-box-default"]
        )}
      >
        <img src={`${settings.S3CDN}${settings.viewAssetsPath}logoX1.svg`} alt="" />
      </div>
      <div className={style["signUp-content"]} ref={signUpContentRef}>
        <div className={style["signUp-box"]}>
          <div>
            <div className={style["main-information"]}>{textData.title}</div>
            <div className={style["error-prompt"]}>{errorPrompt}</div>
          </div>
          <div className={style["input-box"]} ref={inputBoxRef}>
            <Form ref={formRef} onValuesChange={onFieldsChange}>
              <Form.Item
                name="email"
                className={style["underline"]}
                hasFeedback
                rules={[
                  { required: true, message: "Please enter your email" },
                  { type: "email", message: "Please enter a valid email address" },
                ]}
              >
                <Input
                  ref={emailInputRef}
                  className={style["enter-input"]}
                  placeholder="Enter your email"
                  bordered={false}
                  onFocus={() => onInputFocus("email")}
                />
              </Form.Item>

              <Form.Item
                name="firstName"
                className={style["underline"]}
                hasFeedback
                rules={[{ required: true, whitespace: true, message: "your name cannot be empty" }]}
              >
                <Input
                  ref={nameInputRef}
                  className={style["enter-input"]}
                  placeholder="Enter your name"
                  bordered={false}
                  onFocus={() => onInputFocus("name")}
                />
              </Form.Item>

              <Form.Item
                name="password"
                className={style["underline"]}
                dependencies={["password"]}
                hasFeedback
                rules={[{ required: true, message: "Please enter your password" }]}
              >
                <Input.Password
                  ref={passwordInputRef}
                  className={style["enter-input"]}
                  placeholder="Enter password"
                  bordered={false}
                  onFocus={() => onInputFocus("password")}
                />
              </Form.Item>

              <Form.Item
                name="repeatPassword"
                className={style["underline"]}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please repeat your password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("The two passwords that you entered do not match!"));
                    },
                  }),
                ]}
              >
                <Input.Password className={style["enter-input"]} placeholder="Repeat Password" bordered={false} />
              </Form.Item>

              {/* https://brain-ai.atlassian.net/browse/STUD-1813 : Remove OTP requirement from Sign up page */}
              {isOtp ? (
                <Form.Item className={style["underline"]}>
                  <div className={style["sign-box"]}>
                    <Input
                      type="text"
                      value={otp}
                      className={style["enter-input"]}
                      onChange={e => setOtp(e.target.value)}
                      placeholder="A 6-digit verification code has been sent to your email"
                      bordered={false}
                    />
                    <div className={style["resend-msg"]}>{!func.isEmpty(msg) && <span>{msg}s</span>}</div>
                    {showResetText && (
                      <div className={style["resend-text"]}>
                        {msg === 0 ? (
                          <span className={style["clickable"]} onClick={() => clickResend()}>
                            Resend code
                          </span>
                        ) : (
                          <span>Code resent</span>
                        )}
                      </div>
                    )}
                  </div>
                </Form.Item>
              ) : null}
            </Form>
          </div>
          {/* Terms of Service */}
          <div className={style["declaration-consent"]}>
            {statementList.value.map((item, index) => {
              if (item.isMatch) {
                return (
                  <a href={item.link} target="_blank" rel="noreferrer" key={`${index}`} className={style["text-bold"]}>
                    {item.orginalText}
                  </a>
                );
              }
              return <span key={`${index}`}>{item.orginalText}</span>;
            })}
          </div>

          <Button
            type="primary"
            loading={logInLoading}
            className={style["log-in-button"]}
            onClick={() => clickCreate()}
          >
            Create an account
          </Button>
          {isBlank(homeUsePluginState.customDomain) ? (
            <>
              <p className={style["or-text"]}>Or</p>
              <GoogleOauthButton page="signUp" enableOneTap={true} />
            </>
          ) : null}
        </div>
        <p className={style["sign-up-link"]}>
          Already have an account?{" "}
          <span
            onClick={() => {
              props.toLogin();
            }}
          >
            Log in
          </span>
          {
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M9 14L13 10L9 6"
                stroke="#12192B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        </p>
      </div>
    </div>
  );
};

export default StudiosSignUp;
