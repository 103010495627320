"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryManagerUtil = void 0;
const StoryNode_1 = require("../../storyV2/domain/StoryNode");
const StoryEdge_1 = require("../../storyV2/domain/StoryEdge");
const StoryNodeState_1 = require("../../storyV2/domain/StoryNodeState");
const StoryNodeType_1 = require("../../storyV2/domain/StoryNodeType");
const StoryNodeUtil_1 = require("../../storyV2/domain/StoryNodeUtil");
const StoryNodeOptions_1 = require("../../storyV2/domain/StoryNodeOptions");
const StoryLineOptionsUtil_1 = require("../../storyV2/domain/StoryLineOptionsUtil");
const StoryLine_1 = require("../../storyV2/domain/StoryLine");
const StoryLineOptions_1 = require("../../storyV2/domain/StoryLineOptions");
const AdapterErrorUtil_1 = require("../adapter/AdapterErrorUtil");
const SubStory_1 = require("../../storyV2/domain/SubStory");
class StoryManagerUtil {
    static checkGroupEdgeState(targetNode) {
        const hasError = targetNode.children.filter(n => n.state === StoryNodeState_1.StoryNodeState.ERROR).length != 0;
        const edgeState = hasError ? StoryEdge_1.EdgeState.ERROR : StoryEdge_1.EdgeState.COMPLETED;
        return edgeState;
    }
    static setEdgesStateAndNotify(edges, state, story) {
        edges.forEach(element => {
            element.state = state;
        });
        story.emit(edges);
    }
    /// 找到边执行后的输出节点
    /// 如果边的输出节点没有，则创建
    static getOrCreateOutputNode(story, edge, nodeType, displayType) {
        let targetNode = edge.targetNode();
        if (targetNode) {
            const idSegments = targetNode.options.id.split("-");
            idSegments[0] = nodeType == StoryNodeType_1.StoryNodeType.CUSTOMGROUP ? "group" : "editor";
            targetNode.id = idSegments.join("-");
            targetNode.options.type = nodeType;
            targetNode.options.displayType = displayType;
            return targetNode;
        }
        const idPre = nodeType == StoryNodeType_1.StoryNodeType.CUSTOMGROUP ? "group" : "editor";
        const idSuf = StoryNodeUtil_1.StoryNodeUtil.maxIdNumber(story) + 1;
        const id = idPre + "-" + idSuf;
        targetNode = new StoryNode_1.StoryNode(new StoryNodeOptions_1.StoryNodeOptions(id, undefined, nodeType, displayType));
        const lineIdSuf = StoryLineOptionsUtil_1.StoryLineOptionsUtil.maxIdNumber(story) + 1;
        const line = new StoryLine_1.StoryOutputLine(new StoryLineOptions_1.StoryLineOptions("new-line-" + lineIdSuf), edge.options.id, targetNode);
        edge.target = line;
        return targetNode;
    }
    static cleanAndSetNewChildren(node, newChildren) {
        const disconnectedLines = node.cleanChildren();
        disconnectedLines.forEach(value => {
            const child = newChildren.get(value.indexInParent);
            if (child) {
                child.lines = value.lines;
            }
        });
        node.received(new StoryNode_1.StoryNodeEventObject(newChildren));
    }
    static idNumberForGroup(story, count, targetNode) {
        const existIds = targetNode.childIds();
        // const existIdNumbers: number[] = existIds.map(id => id.split("-").last() ?? "1").map(id => parseInt(id));
        const splitIds = existIds.filter(id => {
            if (!id)
                return false;
            const num = id.split("-").last();
            return num != undefined && !isNaN(parseInt(num));
        });
        const existIdNumbers = splitIds.map(id => {
            const num = id.split("-").last() ?? "1";
            return parseInt(num);
        });
        if (count <= existIdNumbers.length) {
            const returnArr = [];
            for (let i = 0; i < count; i++) {
                returnArr.push(existIdNumbers[i]);
            }
            return returnArr;
        }
        const returnArr = existIdNumbers;
        const currentStartId = StoryNodeUtil_1.StoryNodeUtil.maxIdNumber(story) + 1;
        const length = count - returnArr.length;
        for (let i = 0; i < length; i++) {
            returnArr.push(currentStartId + i);
        }
        return returnArr;
    }
    static setEdgesErrorStateAndNotify(story, targetNode, edge, relatedEdges, error) {
        let msg = AdapterErrorUtil_1.AdapterErrorUtil.getErrorMsg(error);
        if (msg == undefined || msg == "") {
            msg = AdapterErrorUtil_1.AdapterErrorUtil.defaultErrorMessage;
        }
        targetNode.state = StoryNodeState_1.StoryNodeState.ERROR;
        targetNode.received(new StoryNode_1.StoryNodeEventObject(msg));
        StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.ERROR, story);
    }
    static setGroupEdgesErrorStateAndNotify(story, targetNode, parentNode, edge, relatedEdges, error) {
        let msg = AdapterErrorUtil_1.AdapterErrorUtil.getErrorMsg(error);
        if (msg == undefined || msg == "") {
            msg = AdapterErrorUtil_1.AdapterErrorUtil.defaultErrorMessage;
        }
        targetNode.state = StoryNodeState_1.StoryNodeState.ERROR;
        targetNode.received(new StoryNode_1.StoryNodeEventObject(msg));
        // StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], EdgeState.ERROR, story);
        const completionResult = parentNode.children.filter(node => node.state === StoryNodeState_1.StoryNodeState.COMPLETED || node.state === StoryNodeState_1.StoryNodeState.ERROR);
        if (completionResult.length === parentNode.children.length) {
            const edgeState = StoryManagerUtil.checkGroupEdgeState(parentNode);
            StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], edgeState, story);
            return true;
        }
        return false;
    }
    static isCompletionForGroup(groupNode) {
        const completionResult = groupNode.children.filter(node => node.state === StoryNodeState_1.StoryNodeState.COMPLETED || node.state === StoryNodeState_1.StoryNodeState.ERROR);
        return completionResult.length === groupNode.children.length;
    }
    /**
     * 如果是substory的最后一个节点更新了，那么需要告知story
     * @param story
     * @param edge
     * @param targetNode
     */
    static updateLastEdgeOutnode(story, edge, targetNode) {
        if (story instanceof SubStory_1.SubStory && story.lastEdgeOutNodeCreate && story.findFirstStartNodesLastEdge() === edge) {
            story.lastEdgeOutNodeCreate(targetNode);
        }
    }
}
exports.StoryManagerUtil = StoryManagerUtil;
