import { useDispatch, useSelector, useStore } from "react-redux";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { getIt } from "@uikit/getIt";
import { editorActions } from "@store/editor";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { logEvent } from "@uikit/service/amplitude";
import { ProjectModifiedUtil } from "@uikit/projectModified/ProjectModifiedUtil";
import { previewStore } from "@uiview/store/PreviewStore";
import { fotActions } from "@store/fot";
import { ProjectNetDataStore } from "@base/ProjectNetDataStore";
import BackHomeModal from "@views/thinking-layout-editor/BackHomeModal";
import { RootState } from "@store/index";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { studioActions } from "@store/studio";
import { NavBarStore } from "../NavBarStore";
import { popupFeedbackStore } from "@uikit/store/PopupFeedback";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import style from "./NavBarHomeIcon.module.scss";
import classNames from "classnames";
import { CotStore } from "@uikit/store/CotStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { FotNavigateController } from "@uikit/service/FotNavigateController";

type pageNavBarPropsType = {
  resetUndoData: () => any;
  emptyNodes: () => void;
  saveProject: (source: any) => void;
  disable?: boolean;
};
export function NavBarHomeIcon(pageNavBarProps: pageNavBarPropsType): JSX.Element {
  // 在父组件实现，props传递
  const { resetUndoData, emptyNodes, saveProject } = pageNavBarProps;
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const navBarStore = getIt(NavBarStore);
  const cotStore = getIt(CotStore);
  const showNavPageStore = getIt(ShowNavPageStore);
  const showNavPageState = useImagicaStore(showNavPageStore).value;
  const fotNavigateController = getIt(FotNavigateController);
  const store = useStore();
  const dispatch = useDispatch();
  const previewAppStore = getIt(PreviewAppStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const dataVersion = "v3";

  const setBackHomeModal = (val: boolean): void => {
    dispatch(editorActions.setBackHomeModal(val));
  };
  const setEnableCOTStartAgain = (val: null): void => {
    dispatch(editorActions.setEnableCOTStartAgain(val));
  };
  const setShowTooltip = (val: string): void => {
    dispatch(editorActions.setShowTooltip(val));
  };
  const setSelectedTemplate = (val: any): void => {
    dispatch(studioActions.setSelectedTemplate(val));
  };

  const backHomeModal = useSelector((state: RootState) => state.editor.backHomeModal);
  const selectedTemplate = useSelector(
    (state: RootState) => state.studio.selectedTemplate
  ) as unknown as PreviewApp.ProjectType;
  // const createJSFuncData = useSelector((state: RootState) => state.editor.createJSFuncData);
  // const createAPIFuncData = useSelector((state: RootState) => state.editor.createAPIFuncData);

  const setPromptStatus = (status: any): void => {
    const selectedTemplate: any = (store.getState() as RootState).studio.selectedTemplate;
    const v = Object.assign({}, selectedTemplate[dataVersion], { showToolTipStatus: status });
    const data = Object.assign({}, selectedTemplate, { [dataVersion]: v });
    setSelectedTemplate(data);
  };

  const closePromptHide = (): void => {
    setPromptStatus("close");
    newOnboardingFlowStore.setSelectedFlowItem({
      ...newOnboardingFlowStore.state.selectedFlowItem,
      showTooltip: false,
    });
  };

  const handleReset = (): void => {
    // 重置unde数据
    closePromptHide();
    resetUndoData();
    emptyNodes();

    // 重置右侧preview数据
    previewAppStore.resetPreviewData();
    // 重置按钮默认颜色
    navBarStore.setClickItemButton(false);
    // 重置project inputvalue 内容
    creatorRefStore.currentProjectRef.current?.resetInnerState();

    // 重置Onboarding input
    cotStore.setMultiRoundChatOnboarding(false);

    // 显示首页
    fotNavigateController.openCreatorHomePage();
  };

  const resetCot = (): void => {
    popupFeedbackStore.setEnableCOTFeedbackPopup(false);
    setBackHomeModal(false);
    projectPageStore.setIsPublish(false);
    handleReset();
    cotStore.setCotAutomaticNode(false);
    setEnableCOTStartAgain(null);
    cotStore.setOldCotAnimationState({
      creating: false,
      inputIds: [],
      currentInputId: "",
    });
  };

  const saveAndExit = async (): Promise<void> => {
    closePromptHide();
    // if(createJSFuncData.open) {
    //   setCreateJSFuncData({function: 'onCancel'})
    //   return;
    // }
    // if(createAPIFuncData.open) {
    //   setCreateAPIFuncData({function: 'onCancel'})
    //   return;
    // }
    // function和project都需要保存
    if (!showNavPageStore.state.showNavPage) {
      // 切换到首页保存一次
      await saveProject("home button");
    }
    handleReset();
  };
  const clickLogo = (): void => {
    if (chatOnboardingStore.state.updateTime !== chatOnboardingStore.state.saveTime) {
      return setBackHomeModal(true);
    }
    // if (createJSFuncData.open) {
    //   eventbus.emit(new CreateFunctionPageBackMsg(createJSFuncData.edgeId));
    //   setEnableCOTStartAgain(null);
    //   setCreateJSFuncData({ function: "onCancel" });
    //   clearPreviewApp();
    //   return;
    // }
    // if (createAPIFuncData.open) {
    //   eventbus.emit(new CreateFunctionPageBackMsg(createAPIFuncData.edgeId));
    //   setEnableCOTStartAgain(null);
    //   setCreateAPIFuncData({ function: "onCancel" });
    //   clearPreviewApp();
    //   return;
    // }
    // 在首页时不再触发
    // if (showNavPageState.showNavPage) return;
    const smallScreenWidth = 979;
    if (showNavPageState.showNavPage) {
      if (window.innerWidth < smallScreenWidth) {
        showNavPageStore.setShowSmallScreenSideBar(!showNavPageStore.state.isShowSmallScreenSideBar);
      }
      return;
    }
    logEvent("click_home");
    newOnboardingFlowStore.setSelectedFlowItem({
      title: "",
      value: "",
      eventName: "",
      description: [],
      image: "",
      selected: false,
      disabled: false,
    });
    // 没有改动直接返回首页， 并且当前不是通过cot生成的项目
    // util.isEmpty(props.undoRef.current) && !initWithCot
    if (
      !ProjectModifiedUtil.hasModified({
        withoutCot: true,
        saasUIData: saasUIData,
        appList: previewStore.state.previewAppList,
        selectedTemplate: selectedTemplate,
      })
    ) {
      setEnableCOTStartAgain(null);
      setShowTooltip("close");
      handleReset();
      resetCot();
      dispatch(fotActions.setVariableList([]));
      getIt(ProjectNetDataStore).stopAutoUpdate();
      previewAppStore.clearPreviewApp();
      dispatch(studioActions.setSelectedTemplate({}));
      return;
    }
    // 显示确认保存弹窗
    setBackHomeModal(true);
  };
  return (
    <>
      <div
        className={classNames(
          !pageNavBarProps.disable ? style.cursorPointer : style.cursorPointNoDrop,
          style.logoButton,
          {
            [style["isListPage"]]: showNavPageStore.state.showNavPage,
          }
        )}
        onClick={clickLogo}
      >
        <div className={style.content}>
          {/* <Player
          className={cls(showNavPageStore.state.showNavPage && style.isShowLogoBtn)}
          ref={playerRef}
          keepLastFrame={true}
          autoplay
          src={getAssetsAnimation("logo-button.json")}
        /> */}
          <div
            className={classNames(style.houseBox, {
              [style["isShowLogoBtn"]]: showNavPageStore.state.showNavPage,
            })}
          >
            {showNavPageStore.state.showNavPage ? (
              <img
                src="https://dopniceu5am9m.cloudfront.net/natural.ai/assets/svg/logo.svg"
                className={classNames(style.logoImage, {
                  [style.isShowLogoBtn]: showNavPageStore.state.showNavPage,
                })}
                alt=""
              />
            ) : (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 19.9988V12.5988C7 12.0387 7 11.7587 7.10899 11.5448C7.20487 11.3566 7.35785 11.2036 7.54601 11.1078C7.75992 10.9988 8.03995 10.9988 8.6 10.9988H11.4C11.9601 10.9988 12.2401 10.9988 12.454 11.1078C12.6422 11.2036 12.7951 11.3566 12.891 11.5448C13 11.7587 13 12.0387 13 12.5988V19.9988M9.0177 1.76278L2.23539 7.0379C1.78202 7.39052 1.55534 7.56683 1.39203 7.78764C1.24737 7.98322 1.1396 8.20356 1.07403 8.43783C1 8.7023 1 8.98948 1 9.56383V16.7988C1 17.9189 1 18.4789 1.21799 18.9067C1.40973 19.2831 1.71569 19.589 2.09202 19.7808C2.51984 19.9988 3.07989 19.9988 4.2 19.9988H15.8C16.9201 19.9988 17.4802 19.9988 17.908 19.7808C18.2843 19.589 18.5903 19.2831 18.782 18.9067C19 18.4789 19 17.9189 19 16.7988V9.56383C19 8.98948 19 8.7023 18.926 8.43783C18.8604 8.20356 18.7526 7.98322 18.608 7.78764C18.4447 7.56683 18.218 7.39052 17.7646 7.03791L10.9823 1.76278C10.631 1.48953 10.4553 1.3529 10.2613 1.30038C10.0902 1.25404 9.9098 1.25404 9.73865 1.30038C9.54468 1.3529 9.36902 1.48953 9.0177 1.76278Z"
                  stroke="#676E7F"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>

          {showNavPageStore.state.showNavPage && (
            <img
              className={style.isShowHomeTopToolBarMenu}
              src={`${settings.S3CDN}${settings.viewAssetsPath}homeMenu.svg`}
              alt="menu"
            />
          )}
        </div>
      </div>
      <BackHomeModal
        backHomeModal={backHomeModal}
        setBackHomeModal={setBackHomeModal}
        saveAndExit={saveAndExit}
        handleReset={handleReset}
      />
    </>
  );
}
