"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringUtil = void 0;
class StringUtil {
    static capitalize(str) {
        if (!str) {
            return "";
        }
        const upperChar = str.charAt(0).toUpperCase();
        return upperChar + str.slice(1);
    }
    static removeNumberPrefix(str) {
        if (!str) {
            return "";
        }
        return str.replace(/(^|\n|\s+)\d+\.\s*/g, "$1");
    }
    static maskToken(token) {
        if (token.length < 40)
            return token;
        const startIndex = Math.floor((token.length - 30) / 2);
        const endIndex = startIndex + 30;
        return token.substring(0, startIndex) + "-".repeat(30) + token.substring(endIndex);
    }
}
exports.StringUtil = StringUtil;
