import { FC, useEffect, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import style from "./style.module.scss";
import { getAssetsAnimation } from "@views/thinking-layout-editor/utilities";

export interface HelpButtonProps {
  showAnimation: boolean;
}

const HelpButton: FC<HelpButtonProps> = ({ showAnimation = true }) => {
  const playerRef = useRef<Player>(null);

  useEffect(() => {
    if (showAnimation) {
      const player = playerRef.current;
      if (!player) {
        return;
      }
      player.setSeeker(0, true);
      player.play();
    }
  }, [showAnimation]);

  return (
    <div className={style.helpButton}>
      <Player
        ref={playerRef}
        keepLastFrame={true}
        autoplay
        src={getAssetsAnimation("help-button.json")}
        rendererSettings={{
          viewBoxSize: "30 30 60 60",
        }}
      />
    </div>
  );
};

export default HelpButton;
