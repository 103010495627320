"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageCenter = void 0;
const AblyImplement_1 = require("../../infra/AblyImplement");
const WebsocketImplement_1 = require("../../infra/WebsocketImplement");
class MessageCenter {
    constructor(authStore, meStore, ablyStore) {
        this.authStore = authStore;
        this.meStore = meStore;
        this.ablyStore = ablyStore;
        this.ably = new AblyImplement_1.AblyImp(authStore, meStore, ablyStore);
        this.websocket = new WebsocketImplement_1.WebsocketImp(authStore);
    }
    messageTransform(message, socketName) {
        if (socketName === this.ably.socketName) {
            return message.data;
        }
        return message;
    }
    on(topic, listener, topicKey) {
        /**
         * 那个socket 最先到到达就用那个
         */
        let firstReceivedSocket = null;
        const firstReceivedSocketListener = (msg, socketName) => {
            if (firstReceivedSocket === null || firstReceivedSocket === socketName) {
                firstReceivedSocket = socketName;
                return listener(this.messageTransform(msg, socketName));
            }
        };
        this.ably.on(topic, msg => {
            firstReceivedSocketListener(msg, this.ably.socketName);
        }, topicKey);
        this.websocket.on(topic, msg => {
            firstReceivedSocketListener(msg, this.websocket.socketName);
        }, topicKey);
    }
    off(topic, listener) {
        this.ably.off(topic, listener);
        this.websocket.off(topic, listener);
    }
    offTopic(topicKey) {
        this.ably.offTopic(topicKey);
        this.websocket.offTopic(topicKey);
    }
    reconnect() {
        this.ably.reconnect();
        this.websocket.reconnect();
    }
}
exports.MessageCenter = MessageCenter;
