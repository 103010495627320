import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

type Param = Partial<Omit<ChatAiStoreState, "copyWith">>;
export class ChatAiStoreState {
  isEnterInputLoadingDone = false;
  isInSharedBodyBoxRefBottom = true;
  hasChatList = false;

  chatLogoTop = 0;
  editingType = "textAreaValue";

  chatNodePreEdgeId: Array<string | undefined> = [];

  copyWith(params: Param): ChatAiStoreState {
    const state = new ChatAiStoreState();
    state.isEnterInputLoadingDone = params.isEnterInputLoadingDone ?? this.isEnterInputLoadingDone;
    state.isInSharedBodyBoxRefBottom = params.isInSharedBodyBoxRefBottom ?? this.isInSharedBodyBoxRefBottom;
    state.chatLogoTop = params.chatLogoTop ?? this.chatLogoTop;
    state.chatNodePreEdgeId = params.chatNodePreEdgeId ?? this.chatNodePreEdgeId;
    state.editingType = params.editingType ?? this.editingType;
    state.hasChatList = params.hasChatList ?? this.hasChatList;
    return state;
  }
}

export class ChatAIStore extends StoreBase<ChatAiStoreState> {
  constructor() {
    super(() => {
      return new ChatAiStoreState();
    });
  }

  private setState(param: Param) {
    let newState = this.state.copyWith(param);
    this.emit(newState);
  }
  enterInputLoadingDone(isEnterInputLoadingDone: boolean) {
    this.setState({ isEnterInputLoadingDone });
  }
  sharedBodyBoxRefBottom(isInSharedBodyBoxRefBottom: boolean) {
    this.setState({ isInSharedBodyBoxRefBottom });
  }
  setChatLogoTop(chatLogoTop: number) {
    this.setState({ chatLogoTop });
  }
  setChatNodePreEdgeId(chatNodePreEdgeId: Array<string | undefined>) {
    this.setState({ chatNodePreEdgeId });
  }
  setEditingType(editingType: string) {
    this.setState({ editingType });
  }
  setHasChatList(hasChatList: boolean) {
    this.setState({ hasChatList });
  }
}
