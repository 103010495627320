import { Switch } from "antd";
import { useState } from "react";
import AutoResizablePanel from "../AutoResizablePanel/AutoResizablePanel";
import styles from "./DesignPanel.module.scss";

const quickModeImg = "https://dopniceu5am9m.cloudfront.net/static/assets/demo/quickMode.png";
const expertModeImg = "https://dopniceu5am9m.cloudfront.net/static/assets/demo/expertMode.png";

type DesignPanelProps = {
  onClose: () => void;
};

export function DesignPanel(props: DesignPanelProps) {
  const [checked, setChecked] = useState(false);
  return (
    <AutoResizablePanel position="top-right" className={styles.designPreviewPane} data-testid="genPreviewPane">
      <div className={styles.previewPane}>
        <div className={styles.previewLayout}>
          <div className={styles.closeIcon} onClick={props.onClose}></div>
          <div className={styles.header}>
            <h3 className={styles.title}>Designer Mode</h3>
            <p className={styles.description}>Control the look and feel of your content</p>
          </div>
          <div className={styles.mode}>
            <span className={styles.modeText}>{checked ? "Expert Mode" : "Quick Mode"}</span>
            <Switch checked={checked} onChange={e => setChecked(e)} />
          </div>
          <div className={styles.modeImgWrapper}>
            <img className={styles.modeImg} src={checked ? expertModeImg : quickModeImg} />
          </div>
        </div>
      </div>
    </AutoResizablePanel>
  );
}
