import { useCallback } from "react";
import { Position, useStore } from "reactflow";
import { getHandlePosition } from "../util/EdgeCalculate";
import { getSmoothStepPath } from "../util/getSmoothStepPath";
import func from "@uikit/func";
import { map } from "lodash";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { getIt } from "@uikit/getIt";

type point = {
  x: number;
  y: number;
};
export function getMidllePoint(points: Array<point>): {
  x: number;
  y: number;
} {
  if (func.isEmpty(points)) return { x: 0, y: 0 };
  const sumPoint = points.reduce(
    (sum, point) => {
      sum.x += point.x;
      sum.y += point.y;
      return sum;
    },
    { x: 0, y: 0 }
  );
  const len = points.length;
  const x = sumPoint.x / len;
  const y = sumPoint.y / len;
  return { x, y };
}
export default function useCalculateEdgePosition(
  data: any,
  id: string
): {
  middlePostion: {
    x: number;
    y: number;
  };
} {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const borderRadius = 20;
  const sourceLines = creatorEdgesStore.getEdges().filter((x: any) => x.target === id);
  const sourceNodeIds = (sourceLines as any).map((x: any) => x.source);

  const nodes = creatorNodesStore.getNodes();
  const sourceNodes = nodes.filter((x: any) => (sourceNodeIds as any).includes(x.id));
  const targetLine = creatorEdgesStore.getEdges().find((x: any) => x.source === id);

  /// 使用sourceNodeIds中第一个作为souce node
  const sourceHomeIds = sourceNodeIds.filter((x: string) => map(nodes, "id").includes(x));

  const sourceNode = useStore(useCallback(store => store.nodeInternals.get(sourceHomeIds[0]), [sourceNodes])) as any;
  const targetNode = useStore(
    useCallback(store => store.nodeInternals.get(targetLine?.target), [targetLine?.target])
  ) as any;

  // const [sourceHandlePos, targetHandlePos] = getRcentHandlePos(sourceNode, targetNode, nodes)
  // const middlePostion = {
  //   x: (sourceHandlePos.x + targetHandlePos.x) / 2,
  //   y: (sourceHandlePos.y + targetHandlePos.y) / 2
  // }
  const sourceHandlePos = getHandlePosition(sourceNode, nodes, Position.Right);
  const targetHandlePos = getHandlePosition(targetNode, nodes, Position.Left);
  const [, points] = getSmoothStepPath({
    sourceX: sourceHandlePos.x,
    sourceY: sourceHandlePos.y,
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    targetX: targetHandlePos.x,
    targetY: targetHandlePos.y,
    borderRadius,
  });
  const middlePostion = getMidllePoint(points);
  return {
    middlePostion,
  };
}
