"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionStore = exports.SubscriptionGetPlansError = void 0;
const Lazyload_1 = require("../../base/cutil/Lazyload");
const StoreBase_1 = require("../../base/cutil/StoreBase");
const PayWallUtil_1 = require("../util/PayWallUtil");
class SubscriptionStoreState {
    constructor() {
        this.creatorPlansLazyLoadLoading = false;
        this.stripePlans = [];
        this.currentPlan = new PayWallUtil_1.CurrentPlan();
    }
    copyWith(params) {
        const subscriptionStoreState = new SubscriptionStoreState();
        subscriptionStoreState.creatorPlansLazyLoadLoading =
            params.creatorPlansLazyLoadLoading ?? this.creatorPlansLazyLoadLoading;
        params.stripePlans ?? this.stripePlans;
        params.currentPlan ?? this.currentPlan;
        return subscriptionStoreState;
    }
}
class SubscriptionGetPlansError extends Error {
    constructor(error) {
        super();
        this.error = error;
    }
}
exports.SubscriptionGetPlansError = SubscriptionGetPlansError;
class SubscriptionStore extends StoreBase_1.StoreBase {
    constructor(brainClient, preferencesStore, webConfigStore) {
        super(() => new SubscriptionStoreState());
        this.brainClient = brainClient;
        this.preferencesStore = preferencesStore;
        this.webConfigStore = webConfigStore;
        this.stripePlansLazyLoad = new Lazyload_1.Lazyload(() => {
            return this.brainClient.payWall.plans().then(res => {
                const state = res;
                this.emit(this.state.copyWith({ stripePlans: state.data }));
                return state;
            });
        });
        this.currentPlanLazyLoad = new Lazyload_1.Lazyload(async () => {
            const webFeature = await this.webConfigStore.imagicaCreatorPlans();
            return await PayWallUtil_1.PayWallUtil.currentPlan(this.preferencesStore, this.brainClient, webFeature).then(res => {
                const state = res;
                this.emit(this.state.copyWith({ currentPlan: state }));
                return state;
            });
        });
        this.imagicaCreatorPlansLazyLoad = new Lazyload_1.Lazyload(async () => {
            this.changeImagicaCreatorPlansLoading(true);
            try {
                const currentPlan = await this.currentPlan();
                const stripePlans = await this.stripePlansLazyLoad.get().then(res => res.data);
                const metadatas = await this.webConfigStore.imagicaCreatorPlans();
                const imagicaCreatorPlans = await PayWallUtil_1.PayWallUtil.getImagicaPlans({
                    currentPlan,
                    stripePlans,
                    metadatas,
                    brainClient: this.brainClient,
                });
                this.changeImagicaCreatorPlansLoading(false);
                return imagicaCreatorPlans;
            }
            catch (e) {
                this.changeImagicaCreatorPlansLoading(false);
                // 抛出这个错误，内部只处理 loading
                throw new SubscriptionGetPlansError(e);
            }
        });
    }
    changeImagicaCreatorPlansLoading(loading) {
        if (this.state.creatorPlansLazyLoadLoading !== loading) {
            this.emit(this.state.copyWith({ creatorPlansLazyLoadLoading: loading }));
        }
    }
    async renew() {
        this.imagicaCreatorPlansLazyLoad.renew();
        this.stripePlansLazyLoad.renew();
        this.currentPlanLazyLoad.renew();
    }
    async dirty() {
        this.currentPlanLazyLoad.dirty();
        this.stripePlansLazyLoad.dirty();
        this.imagicaCreatorPlansLazyLoad.dirty();
    }
    async currentPlan() {
        return await this.currentPlanLazyLoad.get();
    }
    /**
     * @throws {SubscriptionGetPlansError}
     * @returns {Promise<ImagicaCreatorPlan[]>}
     */
    async imagicaCreatorPlans() {
        return this.imagicaCreatorPlansLazyLoad.get();
    }
    async imagicaFeatures() {
        return this.webConfigStore.imagicaFeatures();
    }
    async imagicaAppTemplates() {
        return this.webConfigStore.imagicaAppTemplates();
    }
    async imagicaFontList() {
        return this.webConfigStore.imagicaAppfonts();
    }
    async stripeManagementLink() {
        return this.webConfigStore.stripeManagementLink();
    }
    async subscribeSuccessContent() {
        return this.webConfigStore.subscribeSuccessContent();
    }
}
exports.SubscriptionStore = SubscriptionStore;
