import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class SharePreviewStoreState {
  saasLoading = false;
  saasV2Loading = false;

  copyWith(param: Partial<Omit<SharePreviewStoreState, "copyWith">>) {
    const newState = new SharePreviewStoreState();
    newState.saasLoading = param.saasLoading ?? this.saasLoading;

    return newState;
  }
}

/// Share 和 Preview 都会使用到的状态
export class SharePreviewStore extends StoreBase<SharePreviewStoreState> {
  constructor() {
    super(() => new SharePreviewStoreState());
  }

  setSaasLoading = (saasLoading: boolean) => {
    this.emit(this.state.copyWith({ saasLoading }));
  };

  setSaasV2Loading = (saasV2Loading: boolean) => {
    this.emit(this.state.copyWith({ saasV2Loading }));
  };
}

export const sharePreviewStore = new SharePreviewStore();
