import React, { useEffect, useMemo } from "react";
import { useSignal } from "@preact/signals-react";
import { Popover } from "antd";

import styles from "./TextStyle.module.scss";
import { getIt } from "@uikit/getIt";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { ToolBarConfigValue, TextStyle_CONFIG } from "@uiview/views/PreviewV2ToolBar/PreviewV2ToolBarUtil";
import { GROUPID_REG } from "@views/thinking-layout-editor/constants";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { logEvent } from "@uikit/service/amplitude";

export function TextStyle(): JSX.Element {
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const creatorAISaasStore = getIt(CreatorAISaasStore);
  const creatorAISassState = useImagicaStore(creatorAISaasStore).value;
  const { aiSaasPreviewHoverData } = creatorAISassState;

  const currentConfig = useSignal<ToolBarConfigValue>(TextStyle_CONFIG["default"]);

  const isGroup = GROUPID_REG.test(aiSaasPreviewHoverData?.id || "");

  const currentOutput = useMemo(() => {
    return (
      saasUIData.output.find((x: any) => {
        const currentId = isGroup ? x.groupId : x.results?.[0]?.previewId;
        return currentId === aiSaasPreviewHoverData?.id;
      }) || {}
    );
  }, [saasUIData.output, isGroup, aiSaasPreviewHoverData?.id]);

  const logClickEvent = (title: string): void => {
    logEvent("click_preview_toolbar_button", { button_name: title });
  };

  const clickOutputCssStyleCp = (key: string): void => {
    const keyObj: {
      [key: string]: any;
    } = {
      isBold: {
        styleKey: "fontWeight",
        style: {
          fontWeight: "bold",
        },
      },
      isItalic: {
        styleKey: "fontStyle",
        style: {
          fontStyle: "italic",
        },
      },
      isUnderLine: {
        styleKey: "textDecoration",
        style: {
          textDecoration: "underline",
        },
      },
      isBeautify: {
        styleKey: "background",
        defaultStyle: saasUIData.theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.1)",
        style: {
          background: "transparent",
        },
      },
    };

    const newOutputs = saasUIData.output.map((i: any) => {
      const currentId = isGroup ? i.groupId : i.results[0].previewId;
      if (currentId === aiSaasPreviewHoverData?.id) {
        // fontLabel是通过fontFamily来设定是否加粗
        let boldReset = {};
        const needHandleBold = key === "isBold" && i.results[0]?.fontStyleKey === "fontLabel";
        const value = i.results[0]?.nodeFontStyle?.[keyObj[key].styleKey];

        if (needHandleBold && (!value || value === "unset")) {
          boldReset = {
            fontFamily: "ak-regular",
            fontWeight: 800,
          };
        }

        const fontStyle =
          value && value !== "unset"
            ? {
                [keyObj[key].styleKey]: "unset",
              }
            : { ...keyObj[key].style };

        const newNodeFontStyle = {
          ...(i.results[0].nodeFontStyle || {}),
          ...fontStyle,
          ...boldReset,
        };

        if (key === "isBeautify" && value) {
          if (value) {
            delete newNodeFontStyle.background;
          } else {
            newNodeFontStyle.background = "transparent";
          }
        }
        return {
          ...i,
          results: [
            {
              ...i.results[0],
              [key]: !i.results[0]?.[key],
              nodeFontStyle: newNodeFontStyle,
            },
          ],
        };
      }
      return i;
    });

    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        output: newOutputs,
      };
    });

    /// BSF-4552: 同时更新 thoughts 数据
    // bsf-4912 use setSassUIData
    creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: newOutputs }));

    logClickEvent(key);
  };

  const getCurrentTips = (param: { dataStateKey?: string; tips: string | string[] }): string[] | string => {
    const { tips, dataStateKey } = param;
    if (Array.isArray(tips) === false) return tips;
    if (dataStateKey) {
      return (currentOutput as any).results?.[0]?.[dataStateKey] ? tips[1] : tips[0];
    }
    return tips;
  };

  useEffect(() => {
    currentConfig.value = TextStyle_CONFIG[aiSaasPreviewHoverData.type] || TextStyle_CONFIG["default"];
    // eslint-disable-next-line
  }, [aiSaasPreviewHoverData.type]);

  return (
    <div id="AppDesignTextStyle" className={styles.textStyle}>
      <div className={styles.header}>Text style</div>
      <div className={styles.des}>Select a text from the app preview</div>
      <div className={styles.option}>
        {currentConfig.value.componentsConfig.map((item, index) => {
          const tips = getCurrentTips(item);
          return (
            item.component && (
              <Popover
                autoAdjustOverflow={false}
                overlayClassName={styles.textPopover}
                getPopupContainer={e => e}
                placement={tips.includes("Background") ? "topRight" : "top"}
                content={<div className={styles.tooltipContainer}>{tips}</div>}
                key={index}
              >
                <React.Fragment key={index}>
                  {React.createElement(item.component, {
                    ...aiSaasPreviewHoverData,
                    isGroup,
                    fontStyleKey: currentConfig.value.fontStyleKey,
                    currentOutput,
                    saasUIData,
                    setSaasUIData: creatorSaasAppStore.setSaasUIData,
                    clickOutputCssStyleCp,
                    className: styles.item,
                  })}
                </React.Fragment>
              </Popover>
            )
          );
        })}
      </div>
    </div>
  );
}
