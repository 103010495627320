import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { cloneDeep, isEmpty } from "lodash";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { DomainStudio } from "imagica-corekit/dist/base/api/DomainTyped/DomainStudio";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { AuthStore } from "imagica-corekit/dist/base/store/AuthStore";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";

/**
 * 原 `useSaveSubscriber` 逻辑
 */
export class SubscriberService {
  constructor(
    public brainClient: BrainClient,
    public fotReduxStore: FotReduxStore,
    public homePluginStore: HomePluginStore,
    public authStore: AuthStore,
    public fotAuthStore: FotAuthStore
  ) {}

  private getSearchParams(originHref: string): URLSearchParams {
    return new URL(originHref.replace("#/", "")).searchParams;
  }

  /**
   * 该方法目前只为了兼容 cot 调用逻辑
   * @param id
   * @returns
   */
  async getSubscriberParamsByQ(id: string | null): Promise<DomainStudio | undefined> {
    if (!id || isEmpty(id)) {
      this.fotReduxStore.setSubscriberParamsData({});
      return;
    }

    const domainStudio = await this.getSubscriberByID(id);

    if (isEmpty(domainStudio)) {
      this.fotReduxStore.setSubscriberParamsData({});
      return;
    }

    // Cannot assign to read only property 'guest_user_key' of object '#<Object>'
    const data = cloneDeep(domainStudio);
    data.attributes.guest_user_key = data.guest_user_key;
    this.fotReduxStore.setSubscriberParamsData(data);

    return data;
  }

  /**
   * cot 那边有 tryPromise, 这里就不在 trypromise
   * @param id
   * @returns
   */
  async getSubscriberByID(id: string): Promise<DomainStudio | undefined> {
    const res = await this.brainClient.domain.getDomainStudio(id);
    const data = res?.data;

    // const res = await tryPromise(this.brainClient.domain.guestUserKey(id));
    // const data = res?.data?.data
    return data;
  }

  updateSubscriberParamsData(domainStudio?: DomainStudio): DomainStudio | undefined {
    if (isEmpty(domainStudio)) {
      this.fotReduxStore.setSubscriberParamsData({});
      return;
    }

    // Cannot assign to read only property 'guest_user_key' of object '#<Object>'
    const data = cloneDeep(domainStudio);
    data.attributes.guest_user_key = data.guest_user_key;
    data.attributes.imagica_byod = data.imagica_byod;
    this.fotReduxStore.setSubscriberParamsData(data);
    return data;
  }

  reviceDomainStudio(data?: DomainStudio): void {
    // 原来 useInitPage init 方法中调用 getSubscriberParamsData 的逻辑
    this.updateSubscriberParamsData(data);

    // 原来 useInitPage getSubScriber 逻辑
    const searchParams = this.getSearchParams(window.location.href);
    this.homePluginStore.setStudioData(data);
    const subGuestToken = data?.guest_user_key;
    if (subGuestToken) {
      sessionStorage.setItem("GUEST_TOKEN", subGuestToken);
      this.authStore.loginGuest(subGuestToken);
      this.fotAuthStore.loginGuest(subGuestToken);
    } else {
      const guestToken = searchParams.get("guest_user_key");
      if (guestToken) {
        sessionStorage.setItem("GUEST_TOKEN", guestToken);
        this.authStore.loginGuest(guestToken);
        this.fotAuthStore.loginGuest(guestToken);
      }
    }
  }

  async init(): Promise<void> {
    try {
      const searchParams = this.getSearchParams(window.location.href);

      const studioData = await this.getSubscriberByID(searchParams.get("q") || "");

      this.reviceDomainStudio(studioData);
    } catch (error) {
      console.error("get studios failed.", error);
      // STUD-1646：获取分享链接数据失败，无需登录直接显示 unpublish tips 页面
      this.fotReduxStore.setShowShareSignin(true);
    }
  }
}
