import CustomGroup from "@views/thinking-layout-editor/CustomGroup";
import CustomNode from "@views/thinking-layout-editor/CustomNode";
import { useCallback, useEffect, useRef } from "react";
import ReactFlow, {
  EdgeChange,
  OnSelectionChangeFunc,
  SelectionMode,
  applyEdgeChanges,
  applyNodeChanges,
  useKeyPress,
  useNodesInitialized,
  useOnSelectionChange,
  useReactFlow,
  useStoreApi,
  ReactFlowProvider,
  useViewport,
} from "reactflow";
import CustomNewEdge from "@uiview/views/CustomNewEdge/CustomNewEdge";
import CustomNewLine from "@uiview/views/CustomNewLine/CustomNewLine";
import style from "./index.module.css";
import { CreatorBackground } from "./CreatorBackground";
import { useManualLine } from "@uikit/hooks/useManualLine";
import store, { RootState } from "@store/index";
import { NewEdgeData, OldEdgeData } from "@uikit/project/HomeUsePluginData";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "@store/editor";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";
import func from "@uikit/func";
import { fotActions } from "@store/fot";
import { isString, uniqBy } from "lodash";
import { GROUPID_REG, NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { NodeEdgeInfoUpdater } from "@uikit/service/NodeEdgeInfoUpdater";
import { CustomNewEdgeUtil } from "@uiview/views/CustomNewEdge/CustomNewEdgeUtil";
import { PreviewAppUtil } from "@uiview/views/PreviewApp/PreviewAppUtil";
import { CustomNewConnectionLine } from "@uiview/views/CustomNewConnectionLine/CustomNewConnectionLine";
import useHandleReactFlowDrag from "@custom-hooks/useHandleReactFlowDrag";
import classNames from "classnames";
import { useReactFlowMethods } from "../useReactFlowMethods";
import { useRightMenuMethods } from "../useRightMenuMethods";
import { rightMenuStore } from "@uikit/store/rightMenuStore";
import { useSignal } from "@preact/signals-react";
import useNodeEdgeMaxIndex from "@custom-hooks/useNodeEdgeMaxIndex";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { useFitCanvas } from "@uikit/hooks/useFitCanvas";
import { StudioProjectAttributesV3Line } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Line";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { getIt } from "@uikit/getIt";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorCanvasDeleteMethods } from "@uikit/service/CreatorCanvasDeleteService";
import { fotReactFlowBackgroundStore } from "./FotReactFlow/FotReactFlowBackgroundStore";
import { fotReactFlowPanelStore } from "./FotReactFlow/FotReactFlowPanelStore";
import { EdgeRunQuery } from "@uikit/edgeRun/EdgeRunQuery";
import { CotStore } from "@uikit/store/CotStore";
import { NodeDeletionHandler } from "@uikit/service/NodeDeletionHandler";
import { StudioProjectAttributesV3Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Node";

const nodeTypes = {
  customNode: CustomNode,
  customGroup: CustomGroup,
  customNewEdge: CustomNewEdge,
} as any;
const edgeTypes = {
  customNewLine: CustomNewLine,
};

export function CreatorCanvasInner(): JSX.Element {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const homeMethods = getIt(HomeMethodsService);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const fotReactFlow = getIt(FotReactFlow);
  const canvasDataRef = getIt(CanvasDataRef);
  const creatorCanvasDeleteMethods = getIt(CreatorCanvasDeleteMethods);

  const showCopyMouseStyl = useSignal(false);
  const reactFlowInstance = useReactFlow();
  const reactFlowStore = useStoreApi();
  const { zoom } = useViewport();
  const nodeEdgeInfoUpdater = getIt(NodeEdgeInfoUpdater);
  const homeRightMenuMethods = useRightMenuMethods();
  const homeHandleFieldMethods = getIt(HomeHandleFieldMethods);
  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const cotStore = getIt(CotStore);

  const isStable = useSignal(false);
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const edgeRunQuery = getIt(EdgeRunQuery);
  const nodeDeletionHandler = getIt(NodeDeletionHandler);

  const { handleManualLine } = useManualLine({
    onNodesEdgesAdd: creatorStoreMethods.onNodesEdgesAdd as any,
  });

  const needAddNodeByDrag = useCallback(() => {
    return creatorNodesStore
      .getNodes()
      .every(
        x =>
          x.data.displayType !== "uploadFile" ||
          (x.data.displayType === "uploadFile" && !func.isEmpty(x.data.textAreaValue))
      );
  }, []);
  const { onDragOver, onDrop, onDragLeave } = useHandleReactFlowDrag({
    needAddNodeByDrag: needAddNodeByDrag,
  });

  const homeToolbarState = useImagicaStore(homeToolBarStore).value;
  const previewState = useImagicaStore(previewStore).value;

  const creatorNodesState = useImagicaStore(creatorNodesStore).value;
  const creatorEdgesState = useImagicaStore(creatorEdgesStore).value;

  const nodes = creatorNodesState.nodes;
  const edges = creatorEdgesState.edges;

  const reactFlowMethods = useReactFlowMethods();
  const dispatch = useDispatch();
  const setIsDraggingCreatingNewLine = useCallback(
    (val: { state: boolean; nodeId: any }) => {
      dispatch(editorActions.setIsDraggingCreatingNewLine(val));
    },
    [dispatch]
  );
  const altOptionPressed = useKeyPress(["Alt"]);

  const checkNodeArr = useSelector((state: RootState) => state.fot.checkNodeArr);
  const checkEdgeArr = useSelector((state: RootState) => state.fot.checkEdgeArr);

  const setCheckNodeArr = useCallback(
    (val: any[]) => {
      dispatch(fotActions.setCheckNodeArr(val));
    },
    [dispatch]
  );
  const setCheckEdgeArr = useCallback(
    (val: any[]) => {
      dispatch(fotActions.setCheckEdgeArr(val));
    },
    [dispatch]
  );
  const setHoverEdgeData = useCallback(
    (val: any) => {
      dispatch(editorActions.setHoverEdgeData(val));
    },
    [dispatch]
  );
  const setCloseToolTipArr = useCallback(
    (val: any[]) => {
      dispatch(editorActions.setCloseToolTipArr(val));
    },
    [dispatch]
  );
  const setCommandEnterNodeLineParam = useCallback(
    (val: any) => {
      dispatch(editorActions.setCommandEnterNodeLineParam(val));
    },
    [dispatch]
  );

  useEffect(() => {
    fotReactFlow.setDependencies({ reactFlowInstance, reactFlowStore });
  });

  useEffect(() => {
    showCopyMouseStyl.value = altOptionPressed && creatorRefStore.isMouseInNode.current;

    // 如果按住alt快捷复制过程中松开了alt，则不复制
    if (!altOptionPressed && creatorRefStore.isMouseInNode.current) {
      setTimeout(() => {
        const copyNewNodeId = `editor-${creatorRefStore.nodeIndexRef.current - 1}`;
        const copyNewNode = nodes.find(x => x.id === copyNewNodeId);
        // 为了保证下面 getSimpleNode 类型安全
        if (!copyNewNode) {
          return;
        }
        if (creatorRefStore.isFireNodeDragStopEvent.current) {
          creatorStoreMethods.onNodesEdgesAdd([canvasDataRef.getSimpleNode(copyNewNode)], []);
          return;
        }
        const tempCopyingNodeId = creatorRefStore.copyingNodeIdRef.current;
        // 重置保存的复制id
        creatorRefStore.copyingNodeIdRef.current = "";
        // 删除新增的复制node
        creatorNodesStore.setNodes((prevList: any) => {
          const filterArr = prevList.filter((x: any) => x.id !== copyNewNodeId);
          const arr = filterArr.map((x: any) => {
            if (x.data.targetNodeId === copyNewNodeId) {
              return {
                ...x,
                data: {
                  ...x.data,
                  targetNodeId: tempCopyingNodeId,
                },
              };
            }
            const index = x.data?.flows?.findIndex((source: any) => source.sourceNodeId === copyNewNodeId);
            if (index !== -1 && x.data.flows) {
              const flows = [...(x.data.flows as Array<any>)];
              const flow = flows[index];
              flows.splice(index, 1, {
                ...flow,
                sourceNodeId: tempCopyingNodeId,
              });
              return {
                ...x,
                data: {
                  ...x.data,
                  flows,
                },
              };
            }
            return x;
          });
          return arr;
        });
        // 重置边的连接node
        creatorEdgesStore.setEdges(prevList => {
          return prevList.map(x => {
            if (x.source === copyNewNodeId) {
              return {
                ...x,
                source: tempCopyingNodeId,
              };
            }
            if (x.target === copyNewNodeId) {
              return {
                ...x,
                target: tempCopyingNodeId,
              };
            }
            return x;
          });
        });
      }, 500);
    }
    // eslint-disable-next-line
  }, [altOptionPressed]);

  // opt:effect 11
  const { getNodeEdgeMaxIndex } = useNodeEdgeMaxIndex();
  const length1 = nodes.filter(x => !func.isEmpty(x.data.textAreaValue)).length;
  const length2 = nodes.filter(x => func.isEmpty(x.data.textAreaValue)).length;
  useEffect(() => {
    const { maxNodeIndex, maxEdgeIndex } = getNodeEdgeMaxIndex();
    creatorRefStore.nodeIndexRef.current = Math.max(creatorRefStore.nodeIndexRef.current, maxNodeIndex);
    creatorRefStore.edgeIndexRef.current = Math.max(creatorRefStore.edgeIndexRef.current, maxEdgeIndex);
    if (needAddNodeByDrag()) {
      creatorRefStore.dragOverTimer.current = null;
    }
    homeMethods.judgePlaceHolderDisplay(nodes, edges);
    // eslint-disable-next-line
  }, [length1, length2]);

  // opt:effect 10
  useEffect(() => {
    homeHandleFieldMethods.resetStartNodeIds();
    // eslint-disable-next-line
  }, [creatorEdgesStore.getEdges().length, previewState.showPreviewPanel]);

  useEffect(() => {
    // 如果node具有多个输入边，则不显示除第一条边之外的边上的输入框和run按钮
    creatorStoreMethods.hideMultiEdgeRenderBox();
    const { maxNodeIndex, maxEdgeIndex } = getNodeEdgeMaxIndex();
    creatorRefStore.nodeIndexRef.current = Math.max(creatorRefStore.nodeIndexRef.current, maxNodeIndex);
    creatorRefStore.edgeIndexRef.current = Math.max(creatorRefStore.edgeIndexRef.current, maxEdgeIndex);
    // eslint-disable-next-line
  }, [edges.length]);

  // effect 16
  const deleteKeysPress = useKeyPress(["Backspace", "Del", "Delete"]);
  useEffect(() => {
    if (!deleteKeysPress || cotStore.state.oldCotAnimationState.creating === true) return;

    // deleteSelectedElements(() => onPaneClick());
    homeRightMenuMethods.handleMenuDelete();
    // eslint-disable-next-line
  }, [deleteKeysPress]);

  const onSelectionChange: OnSelectionChangeFunc = useCallback(
    ({ nodes, edges }) => {
      // 有可能选中了组的一些node 没全选
      const nodeIds = nodes.reduce((prev: any[], x) => {
        const arr = [x.id] as any[];
        if (x.type === "customGroup") {
          arr.push(...x.data.childNode);
        }
        return prev.concat(arr);
      }, []);
      const allNodes = Array.from(new Set(nodeIds));
      const edgeIds = edges.map(x => x.id) || [];
      // 鼠标拖拽选区选中
      if (creatorRefStore.isSelectionStart.current) {
        // const edgesInSection = edges.filter(x => allNodes.includes(x.source) && allNodes.includes(x.target))
        setCheckNodeArr(allNodes);
        setCheckEdgeArr(edgeIds);
      }
      if (!func.isEmpty(nodes)) {
        // opt:
        creatorRefStore.selectedNodeIdRef.current = nodes[0].id;
      }
    },
    [setCheckEdgeArr, setCheckNodeArr]
  );

  useOnSelectionChange({ onChange: onSelectionChange });

  const onNodesChange = useCallback((changes: any[]) => {
    creatorNodesStore.setNodes(nds => applyNodeChanges(changes, nds));
  }, []);

  // 手动连线触发
  // opt:
  const onConnect = useCallback(
    (params: any) => {
      // v3
      handleManualLine(params);
      // 第二个input node连线后 关闭tooltip
      const secondInputId = store.getState().editor.secondInputNodeId;
      if (secondInputId === params.source) {
        setCloseToolTipArr([secondInputId]);
      }
    },
    [handleManualLine, setCloseToolTipArr]
  );
  const onConnectStart = useCallback(
    (e: any, params: { nodeId: any }) => {
      setIsDraggingCreatingNewLine({
        state: true,
        nodeId: params.nodeId,
      });
    },
    [setIsDraggingCreatingNewLine]
  );

  const onConnectEnd = useCallback(() => {
    setIsDraggingCreatingNewLine({
      state: false,
      nodeId: "",
    });
  }, [setIsDraggingCreatingNewLine]);

  const onNodeClick = useCallback(
    (e: { target: any; shiftKey: any }, node: any) => {
      let arr = [...checkNodeArr] as any[];
      let edgeArr = [...checkEdgeArr] as any[];

      const target = e.target;
      // class 为空不往下执行, 某些特殊元素 className 不是字符串
      if (
        !target ||
        func.isEmpty(target.className) ||
        (isString(target.className) && target.className?.includes("app"))
      )
        return;
      const isFocusOnTarget = target === document.activeElement;

      if (NEW_EDGE_REG.test(node.id)) {
        const lineIds = HomeMethodsUtil.getNewEdgeAllLineIdsByNode(node, creatorEdgesStore.getEdges());
        const filterArr = checkEdgeArr.filter(x => !lineIds.includes(x));
        const newArr = filterArr.length < checkEdgeArr.length ? filterArr : [...checkEdgeArr, ...lineIds];
        setCheckEdgeArr(newArr);
        if (!checkNodeArr.find(x => x === node.id)) {
          setCheckNodeArr([...checkNodeArr, node.id]);
        }
        return;
      }

      //没有按shift
      if (!e.shiftKey) {
        if (arr.includes(node.id)) {
          arr = [];
        } else {
          // STUD-1259：在编辑状态点击输入框不显示选中框
          if (target.tagName === "INPUT" && isFocusOnTarget) {
            return;
          }
          if (target.tagName === "TEXTAREA" && isFocusOnTarget) {
            return;
          }
          arr = [node.id];
        }
        // 清空选中的line
        edgeArr = [];
      } else {
        // 是否保存过
        if (arr.includes(node.id)) {
          const index = arr.findIndex(x => x === node.id);
          arr.splice(index, 1);
        } else {
          arr.push(node.id);
        }
      }

      setCheckNodeArr(arr);
      setCheckEdgeArr(edgeArr);
    },
    // eslint-disable-next-line
    [checkEdgeArr, checkNodeArr, setCheckEdgeArr, setCheckNodeArr]
  );

  const onNodeDragStart = (e: any, node: any): void => {
    if (!e?.target?.className?.includes?.("iconfont")) {
      creatorRefStore.currentDragNodes.current = [
        {
          id: node.id,
          position: node.position,
        },
      ];
    }

    creatorRefStore.isFireNodeDragStopEvent.current = false;
    if (!showCopyMouseStyl.value) return;
    creatorRefStore.copyingNodeIdRef.current = node.id;
    // 复制node
    const newNodeId = `editor-${creatorRefStore.nodeIndexRef.current++}`;
    const newNode = {
      id: newNodeId,
      type: "customNode",
      data: {
        ...node.data,
      },
      position: {
        x: node.position.x,
        y: node.position.y,
      },
    };
    creatorNodesStore.setNodes(prevList => [...prevList, newNode]);

    // 将被复制的node的边重新使用新node连接，使视觉效果上看起来拖拽复制的是新的node
    creatorEdgesStore.setEdges(prevList => {
      return prevList.map(x => {
        if (x.source === node.id) {
          return {
            ...x,
            source: newNodeId,
          };
        }
        if (x.target === node.id) {
          return {
            ...x,
            target: newNodeId,
          };
        }
        return x;
      });
    });
    // opt:
    creatorNodesStore.setNodes(prevList => {
      return [...prevList, newNode].map(x => {
        if (NEW_EDGE_REG.test(x.id)) {
          /// copy edge source
          if (x.data?.flows?.find((x: { sourceNodeId: any }) => x.sourceNodeId === node.id)) {
            return {
              ...x,
              data: {
                ...x.data,
                flows: x.data?.flows.map((flow: any) => {
                  if (flow.sourceNodeId === node.id) {
                    return {
                      ...flow,
                      sourceNodeId: newNodeId,
                    };
                  }
                  return flow;
                }),
              },
            };
          }
          /// copy edge target
          if (x.data?.targetNodeId === node.id) {
            return {
              ...x,
              data: {
                ...x.data,
                targetNodeId: newNodeId,
              },
            };
          }
        }
        return x;
      });
    });
  };

  const onNodeDragStop = useCallback(
    (e: any, node: any) => {
      // 这里dragging判定是因为点击node也会触发
      if (node.dragging) {
        const startEndPositionArr = HomeMethodsUtil.getStartEndPositionArr(creatorRefStore.currentDragNodes.current, [
          node,
        ]);
        creatorStoreMethods.onNodesEdgesDrag(startEndPositionArr);
      }
      creatorRefStore.currentDragNodes.current = [];
      creatorRefStore.isFireNodeDragStopEvent.current = true;
      // 先松开alt则判定是否为复制状态拖拽
      if (showCopyMouseStyl.value || !func.isEmpty(creatorRefStore.copyingNodeIdRef.current)) {
        creatorRefStore.copyingNodeIdRef.current = "";
        const newNodeId = `editor-${creatorRefStore.nodeIndexRef.current - 1}`;
        // 交换新增复制node与被复制node的id
        creatorNodesStore.setNodes(prevList => {
          return prevList.map(x => {
            if (x.id === node.id) {
              return {
                ...x,
                id: newNodeId,
              };
            }
            if (x.id === newNodeId) {
              return {
                ...x,
                id: node.id,
              };
            }

            if (NEW_EDGE_REG.test(x.id)) {
              /// restore copy edge source
              if (x.data?.flows?.find((x: { sourceNodeId: string }) => x.sourceNodeId === newNodeId)) {
                return {
                  ...x,
                  data: {
                    ...x.data,
                    flows: x.data?.flows.map((flow: { sourceNodeId: string }) => {
                      if (flow.sourceNodeId === newNodeId) {
                        return {
                          ...flow,
                          sourceNodeId: node.id,
                        };
                      }
                      return flow;
                    }),
                  },
                };
              }
              /// restore copy edge target
              if (x.data?.targetNodeId === newNodeId) {
                return {
                  ...x,
                  data: {
                    ...x.data,
                    targetNodeId: node.id,
                  },
                };
              }
            }
            return x;
          });
        });
        // 将新增复制node的边重新连接到被复制的node上
        creatorEdgesStore.setEdges(prevList => {
          return prevList.map(x => {
            if (x.source === newNodeId) {
              return {
                ...x,
                source: node.id,
              };
            }
            if (x.target === newNodeId) {
              return {
                ...x,
                target: node.id,
              };
            }
            return x;
          });
        });
      }
    },
    [creatorStoreMethods, creatorRefStore.currentDragNodes, showCopyMouseStyl.value]
  );
  const onNodeMouseEnter = useCallback(() => {
    creatorRefStore.isMouseInNode.current = true;
    showCopyMouseStyl.value = altOptionPressed;
    // eslint-disable-next-line
  }, [altOptionPressed]);

  const onNodeMouseLeave = useCallback(() => {
    creatorRefStore.isMouseInNode.current = false;
    showCopyMouseStyl.value = false;
  }, []);

  const watchDelGroupNode = useCallback(
    (delNodes: any[]) => {
      setTimeout(() => {
        const currParentId = delNodes[0].parentNode;
        const nodes = delNodes.map(item => ({ id: item.id }));
        // 不是删除的group中的node则不执行
        if (func.isEmpty(currParentId)) {
          return;
        }
        // 过滤出来当前group中剩余的nodes
        const restCurrParentIdNodes = creatorNodesStore.getNodes().filter(x => x.parentNode === currParentId);
        if (!func.isEmpty(restCurrParentIdNodes)) {
          creatorCanvasDeleteMethods.handleDeleteNodes(nodes);
          return;
        }
        // 如果当前group中node被删完，则删除当前group
        // fraser删除逻辑
        creatorCanvasDeleteMethods.handleDeleteNodes([{ id: currParentId }]);
      });
    },
    // eslint-disable-next-line
    []
  );
  const handleLineParam = async (delEdges: (StudioProjectAttributesV3Line | undefined)[]): Promise<void> => {
    const lineParam = HomeMethodsUtil.getEdgeNode(delEdges, creatorNodesStore.getNodes());
    if (!func.isEmpty(lineParam)) {
      const edgeEnterText = await nodeEdgeInfoUpdater.updateEdgeText(lineParam.id);
      if (!func.isEmpty(lineParam.blueJson)) {
        await nodeEdgeInfoUpdater.updateBlueprintEdge(lineParam.id, edgeEnterText || lineParam.enterText);
      } else {
        await edgeRunQuery.getAndSetQueryV3(lineParam.id, edgeEnterText || lineParam.enterText);
      }
      setCommandEnterNodeLineParam(lineParam);
    }
  };
  const onNodesDelete = async (delNodes: any[]): Promise<void> => {
    let delEdges = [];
    let allDelNodes = delNodes;

    delEdges = edges.filter(x => delNodes.some(y => y.id === x.source || y.id === x.target));
    const result = nodeDeletionHandler.onNodesDeleteV3(delNodes, edges, nodes as StudioProjectAttributesV3Node[]);
    delEdges = result.delLines;
    allDelNodes = uniqBy(allDelNodes.concat(result.delEdgeNodes), "id");
    handleLineParam(delEdges);
    creatorStoreMethods.onNodesEdgesDel(allDelNodes, delEdges);
    // 如果组里的node都删除了, 把组也删除掉
    watchDelGroupNode(delNodes);
  };
  const onEdgeClick = useCallback(
    (e: { shiftKey: any }, line: { id: any }) => {
      const edgeData = HomeMethodsUtil.getNewEdgeDataByLine(line, creatorNodesStore.getNodes());
      const lineIds = edgeData.lineIds;
      const edge = edgeData.edge;
      setHoverEdgeData({
        state: true,
        lineIds,
        edgeId: edge.id,
        isSelected: true,
      });
      if (!e.shiftKey) {
        setCheckEdgeArr(lineIds);
        setCheckNodeArr([]);
      }
      if (!checkNodeArr.find(x => x === edge.id)) {
        setCheckNodeArr([...checkNodeArr, edge.id]);
      }
      return;
    },
    [checkNodeArr, setCheckEdgeArr, setCheckNodeArr, setHoverEdgeData]
  );

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    creatorEdgesStore.setEdges(eds => applyEdgeChanges(changes, eds));
  }, []);

  const setPreSelectNodeId = useCallback(
    (val: string) => {
      dispatch(fotActions.setPreSelectNodeId(val));
    },
    [dispatch]
  );
  const setHasSelectedArea = useCallback(
    (val: boolean) => {
      dispatch(fotActions.setHasSelectedArea(val));
    },
    [dispatch]
  );

  const handleFuncContextMenu = useCallback((edge: { data: NewEdgeData & OldEdgeData; id: any }) => {
    const imageGenSelectOptionsArr = store.getState().fot.imageGenSelectOptions;
    const enterText = edge.data?.lineParam?.enterText || "";
    const reg = /^\//;
    const aiStr = enterText.replace(reg, "");
    const select = imageGenSelectOptionsArr.find(x => x.label === aiStr) as any;
    const functionTypes = ["CustomCode", "customApi"];
    // 进入编辑页面
    if (functionTypes.includes(select?.type)) {
      rightMenuStore.setCustomFuncRightContextMenu({
        open: true,
        edgeId: edge.id,
        sourceNodeId: CustomNewEdgeUtil.getSourceNodeId(edge.data),
        targetNodeId: edge.data.targetNodeId,
        select,
      });
    }
    rightMenuStore.setEdgeRight(true);
    creatorRefStore.duplicateEdgeRef.current = edge;
  }, []);
  const startNodeIds = useSelector((state: RootState) => state.fot.startNodeIds) as any[];
  const isCurrentGroupNode = useCallback(() => {
    const nodeId = creatorRefStore.nodeContextMenuRef.current.id;
    projectPageStore.setIsGroupNode(GROUPID_REG.test(nodeId));
  }, []);

  const onNodeContextMenu = useCallback(
    (ev: any, node: any) => {
      setTimeout(() => {
        if (node.type === "customNewEdge") {
          handleFuncContextMenu(node);
          // 点击右键菜单，此时跟点击左键一样，先选中。然后弹窗
          const lineIds = HomeMethodsUtil.getNewEdgeAllLineIdsByNode(node, creatorNodesStore.getNodes());
          const filterArr = checkEdgeArr.filter(x => !lineIds.includes(x));
          const newArr = filterArr.length < checkEdgeArr.length ? filterArr : [...checkEdgeArr, ...lineIds];
          setCheckEdgeArr(newArr);
          if (!checkNodeArr.find(x => x === node.id)) {
            setCheckNodeArr([...checkNodeArr, node.id]);
          }
          setHoverEdgeData({
            state: true,
            lineIds,
            edgeId: node.id,
            isSelected: true,
          });
        } else {
          rightMenuStore.setNodeRight(true);
          creatorRefStore.nodeContextMenuRef.current = node;
          PreviewAppUtil.disabledPublishToOutput({
            nodeId: node.id,
            saasUIData: creatorSaasAppStore.state.saasUIData,
            nodes: creatorNodesStore.getNodes(),
          });
          const isStartNodeContextMenu = startNodeIds.includes(node.id);
          rightMenuStore.setShowAddInputField(isStartNodeContextMenu);
          isCurrentGroupNode();
        }
      }, 0);
    },
    [
      checkEdgeArr,
      checkNodeArr,
      handleFuncContextMenu,
      isCurrentGroupNode,
      setCheckEdgeArr,
      setCheckNodeArr,
      setHoverEdgeData,
      startNodeIds,
    ]
  );

  const onEdgeContextMenu = useCallback(
    (ev: any, edge: any) => {
      if (edge.type === "customNewLine") {
        return;
      }
      setTimeout(() => {
        handleFuncContextMenu(edge);
      }, 0);
    },
    [handleFuncContextMenu]
  );

  const onSelectionStart = useCallback(() => {
    setPreSelectNodeId("");
    setHasSelectedArea(true);
    creatorRefStore.isSelectionStart.current = true;
    window.getSelection()?.removeAllRanges();
  }, [creatorRefStore.isSelectionStart, setHasSelectedArea, setPreSelectNodeId]);
  const onSelectionEnd = useCallback(() => {
    if (creatorRefStore.isSelectionStart.current) {
      window.getSelection()?.removeAllRanges();
    }
    creatorRefStore.isSelectionStart.current = false;
  }, [creatorRefStore.isSelectionStart]);
  const onSelectionDragStart = useCallback(
    (e: any, nodes: any[]) => {
      creatorRefStore.currentDragNodes.current = nodes.map((x: { id: any; position: any }) => ({
        id: x.id,
        position: x.position,
      }));
    },
    [creatorRefStore.currentDragNodes]
  );
  const onSelectionDragStop = useCallback(
    (e: any, nodes: any[]) => {
      if (nodes[0].dragging) {
        const startEndPositionArr = HomeMethodsUtil.getStartEndPositionArr(creatorNodesStore.getNodes(), nodes);
        creatorStoreMethods.onNodesEdgesDrag(startEndPositionArr);
      }
      creatorRefStore.currentDragNodes.current = [];
    },
    // eslint-disable-next-line
    [creatorStoreMethods.onNodesEdgesDrag, creatorRefStore.currentDragNodes]
  );

  const nodesInitialized = useNodesInitialized({
    includeHiddenNodes: false,
  });

  // FIXME: 下列逻辑需要被优化
  const { fitCanvas } = useFitCanvas();
  const fitFlag = useRef(false);
  const onInit = useCallback(() => {
    fitFlag.current = true;
    fitCanvas();
  }, [nodesInitialized]);
  useEffect(() => {
    // 每个节点渲染完成...

    if (nodesInitialized && fitFlag.current) {
      // 同时必须初始化
      fitCanvas();
      fitFlag.current = false;
    }
    // eslint-disable-next-line
  }, [nodesInitialized]);

  useEffect(() => {
    /**
     * 页面状态稳定后，监听node数量的变化
     * 当chatonboarding关闭的情况，node数量一旦变化则disable chat
     * */
    if (isStable.value && !chatOnboardingStore.state.isOpenChat) {
      chatOnboardingStore.setChatOnboardingState({ canvasChangesOutOfSync: true });
    }
    // eslint-disable-next-line
  }, [isStable, nodes.length]);

  useEffect(() => {
    const simulateStableState = (): void => {
      setTimeout(() => {
        isStable.value = true;
      }, 1000);
    };
    simulateStableState();
  }, [isStable]);

  useEffect(() => {
    const zoomPercent = (zoom * 100).toFixed(0);
    homeToolBarStore.setZoom(+zoomPercent);
  }, [zoom]);

  useEffect(() => {
    const off = reactFlowStore.subscribe(state => {
      const pointerEvents = state.userSelectionActive ? "none" : "all";
      if (pointerEvents !== fotReactFlowPanelStore.state.pointerEvents) {
        fotReactFlowPanelStore.changePanel({ pointerEvents: pointerEvents });
      }

      const patternId = `pattern-${state.rfId}`;
      const transform = state.transform;
      if (
        transform !== fotReactFlowBackgroundStore.state.transform ||
        patternId !== fotReactFlowBackgroundStore.state.patternId
      ) {
        fotReactFlowBackgroundStore.changeBackground({ transform, patternId });
      }
    });

    return () => {
      off();
    };
  }, []);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onInit={onInit}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodeClick={onNodeClick}
      onNodeDragStart={onNodeDragStart}
      onNodeDragStop={onNodeDragStop}
      onNodesChange={onNodesChange}
      onNodeMouseEnter={onNodeMouseEnter}
      onNodeMouseLeave={onNodeMouseLeave}
      onNodesDelete={onNodesDelete}
      onEdgeClick={onEdgeClick}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      onPaneClick={reactFlowMethods.onPaneClick}
      onNodeContextMenu={onNodeContextMenu}
      onEdgeContextMenu={onEdgeContextMenu}
      connectionLineComponent={CustomNewConnectionLine}
      onSelectionStart={onSelectionStart}
      onSelectionEnd={onSelectionEnd}
      onSelectionDragStart={onSelectionDragStart}
      onSelectionDragStop={onSelectionDragStop}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
      connectOnClick={false}
      zoomOnDoubleClick={false}
      deleteKeyCode={null}
      zoomActivationKeyCode={null}
      panActivationKeyCode={null}
      panOnDrag={homeToolbarState.panOnDrag}
      panOnScroll={homeToolbarState.panOnScroll}
      selectionOnDrag={homeToolbarState.selectionOnDrag}
      nodesDraggable={homeToolbarState.nodesDraggable}
      zoomOnScroll={homeToolbarState.zoomOnScroll}
      zoomOnPinch={homeToolbarState.zoomOnPinch}
      elementsSelectable={homeToolbarState.elementsSelectable}
      fitView={false}
      selectionMode={SelectionMode.Partial}
      minZoom={0.1}
      className={classNames(
        homeToolbarState.figmaMode ? style["figma-mode"] : "",
        showCopyMouseStyl.value ? style["copy-mouse"] : "",
        style["canvas-overflow-hidden"]
      )}
      id="reactFlow"
      style={{ height: "calc(100% -  46px)" }}
      data-creator="Canvas"
    >
      <CreatorBackground />
    </ReactFlow>
  );
}

export function CreatorCanvas(): JSX.Element {
  return (
    <ReactFlowProvider>
      <CreatorCanvasInner />
    </ReactFlowProvider>
  );
}
