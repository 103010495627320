"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImgUtil = void 0;
class ImgUtil {
    static isValidUrl(url) {
        try {
            new URL(url);
            return true;
        }
        catch (err) {
            return false;
        }
    }
    // 从列表中加载图片直到可用的首条为止
    static getAvailableImg(imgUrls) {
        return new Promise(resolve => {
            let img = new Image();
            let idx = 0;
            img.onerror = () => {
                idx++;
                if (idx < imgUrls.length) {
                    img.src = imgUrls[idx];
                }
            };
            img.onload = () => {
                resolve(img.src);
                img = null;
            };
            img.src = imgUrls[idx];
        });
    }
    // 从列表中提取所有可加载的图片
    static getAvailableNumberOfImgs(imgUrls, num) {
        return new Promise(resolve => {
            const allImg = [];
            let img = new Image();
            let idx = 0;
            const tempNum = num ? num : 0;
            img.onerror = () => {
                idx++;
                if (idx < imgUrls.length) {
                    img.src = imgUrls[idx];
                }
            };
            img.onload = () => {
                allImg.push(img.src);
                if (allImg.length >= tempNum) {
                    resolve(allImg);
                    idx = 0;
                    img = null;
                }
                else {
                    idx++;
                    img.src = imgUrls[idx];
                }
            };
            img.src = imgUrls[idx];
        });
    }
}
exports.ImgUtil = ImgUtil;
