import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class ProjectRunStoreState {
  runAllLoading = false;

  copyWith(param: Partial<Omit<ProjectRunStoreState, "copyWith">>): ProjectRunStoreState {
    const newState = new ProjectRunStoreState();
    newState.runAllLoading = param.runAllLoading ?? this.runAllLoading;

    return newState;
  }
}

/**
 * 项目 run 时状态
 */
export class ProjectRunStore extends StoreBase<ProjectRunStoreState> {
  constructor() {
    super(() => new ProjectRunStoreState());
  }

  setRunAllLoading = (runAllLoading: boolean): void => {
    this.emit(this.state.copyWith({ runAllLoading }));
  };
}
