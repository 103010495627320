import { Container } from "inversify";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { NodesTitleStore } from "@uikit/store/NodesTitleStore";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { CreatorCanvasDeleteMethods } from "@uikit/service/CreatorCanvasDeleteService";
import { EdgeRunTypeStock } from "@uikit/edgeRun/EdgeRunTypeStock";
import { NodeEdgeInfoUpdater } from "@uikit/service/NodeEdgeInfoUpdater";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { SharePreviewStore } from "@uiview/views/AISaas/SharePreviewStore";
import { PreviewService } from "imagica-corekit/dist/cases/preview/PreviewService";
import { CreatorNodeNewPosition } from "@uikit/service/CreatorNodeNewPosition";
import { TakeProjectSnapshot } from "@uikit/service/TakeProjectSnapshot";
import { AutoAddInputNode } from "@uikit/service/AutoAddInputNode";
import { NodeDeletionHandler } from "@uikit/service/NodeDeletionHandler";
import { AddNode } from "@uikit/service/AddNode";
import { CreateProjectService } from "@uikit/service/CreateProjectService";
import { CreatorCanvasFocus } from "@uikit/cases/canvasFocus/CreatorCanvasFocus";
import { previewStore } from "@uiview/store/PreviewStore";
import { FocusNode } from "@uikit/service/FocusNode";
import { AddHandle } from "@uikit/service/AddHandle";
import { AddGroup } from "@uikit/service/AddGroup";
import { CreateNewProjectService } from "@uiview/views/ProjectCanvas/CreateNewProject";
import { CotStore } from "@uikit/store/CotStore";
import { FotSaveEvent } from "@uikit/service/FotSaveEvent";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
/**
 * 该 class 用于注册 创作者 相关也就是画布相关的逻辑实例
 *
 */
export class RegisterCreator {
  static configure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };

    const creatorCanvasDeleteMethods = new CreatorCanvasDeleteMethods(
      it(CreatorAISaasStore),
      it(CreatorSaasAppStore),
      it(HomeMethodsService),
      it(CreatorNodesStore),
      it(ProjectNodeStore),
      it(FotReactFlow)
    );

    container
      .bind(CreateProjectService)
      .toConstantValue(
        new CreateProjectService(
          it(FotReduxStore),
          it(CreatorNodesStore),
          it(CreatorEdgesStore),
          it(PreviewAppStore),
          creatorRefStore,
          it(CanvasDataRef)
        )
      );

    container
      .bind(AutoAddInputNode)
      .toConstantValue(
        new AutoAddInputNode(
          it(CreatorNodesStore),
          it(CreatorEdgesStore),
          it(ProjectNodeStore),
          creatorRefStore,
          it(CanvasDataRef),
          it(CreatorSaasAppStore)
        )
      );

    container
      .bind(NodeEdgeInfoUpdater)
      .toConstantValue(
        new NodeEdgeInfoUpdater(
          it(BrainClient),
          it(CreatorNodesStore),
          it(CreatorEdgesStore),
          it(ProjectNodeStore),
          it(CreatorSaasAppStore)
        )
      );
    container.bind(NodesTitleStore).toConstantValue(new NodesTitleStore());
    container
      .bind(TakeProjectSnapshot)
      .toConstantValue(
        new TakeProjectSnapshot(
          it(FotReduxStore),
          it(ObjectRelationGqlService),
          it(CreatorNodesStore),
          it(CreatorEdgesStore),
          it(CanvasDataRef),
          it(CreatorStoreMethods),
          it(HomeMethodsService)
        )
      );
    container
      .bind(HomeHandleFieldMethods)
      .toConstantValue(
        new HomeHandleFieldMethods(
          it(CreatorEdgesStore),
          it(CreatorNodesStore),
          it(CreatorSaasAppStore),
          it(ProjectNodeStore),
          it(CreatorAISaasStore),
          it(SharePreviewStore),
          it(PreviewService),
          it(EdgeRunTypeStock),
          it(PreviewAppStore),
          creatorRefStore,
          it(HomeStore),
          it(HomeMethodsService),
          creatorCanvasDeleteMethods,
          projectPageStore,
          it(FotReduxStore)
        )
      );
    container
      .bind(CreatorNodeNewPosition)
      .toConstantValue(new CreatorNodeNewPosition(it(CreatorNodesStore), it(CreatorEdgesStore), it(FotReactFlow)));
    container.bind(CreatorCanvasDeleteMethods).toConstantValue(creatorCanvasDeleteMethods);

    container
      .bind(NodeDeletionHandler)
      .toConstantValue(
        new NodeDeletionHandler(it(CreatorNodesStore), it(CreatorEdgesStore), it(ProjectNodeStore), it(FotReduxStore))
      );
    container
      .bind(AddNode)
      .toConstantValue(
        new AddNode(
          creatorRefStore,
          it(HomeMethodsService),
          it(CreatorNodeNewPosition),
          it(CanvasDataRef),
          it(CreatorNodesStore),
          it(CreatorStoreMethods),
          it(CreatorCanvasFocus),
          previewStore
        )
      );
    container
      .bind(FocusNode)
      .toConstantValue(new FocusNode(it(CreatorCanvasFocus), it(CreatorNodesStore), it(CreatorEdgesStore)));
    container
      .bind(AddHandle)
      .toConstantValue(
        new AddHandle(
          creatorRefStore,
          it(CanvasDataRef),
          it(CreatorNodesStore),
          it(CreatorStoreMethods),
          it(CreatorCanvasFocus),
          previewStore
        )
      );
    container
      .bind(AddGroup)
      .toConstantValue(
        new AddGroup(
          it(CreatorNodesStore),
          creatorRefStore,
          it(CanvasDataRef),
          projectPageStore,
          it(CreatorStoreMethods)
        )
      );
    container
      .bind(CreateNewProjectService)
      .toConstantValue(
        new CreateNewProjectService(
          it(BrainClient),
          it(CotStore),
          it(HomeMethodsService),
          it(AddNode),
          it(FotSaveEvent),
          it(ShowNavPageStore)
        )
      );
  }
}
