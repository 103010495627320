import { signal } from "@preact/signals-react";
import { WHAT_TO_DO } from "./constants";

export type CotChatStatus = "waiting" | "loading" | "typing";

export class CotState {
  thinkValue = signal<string>("");
  creating = signal(false);
  completeCreating = signal(false);
  cotChatStatus = signal<CotChatStatus>("waiting");
  question = signal(WHAT_TO_DO);
  waitingThinking = signal<boolean>(false);
  sharableLink = signal<string | undefined>(undefined);
  guest_uuid = "";
}

export class CotBloc {
  state = new CotState();
}
