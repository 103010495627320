import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class ProjectPageStoreState {
  /**
   * 仅用于 previewV1-> sassAIV2 相关
   */
  shareSelectedLoading = false;
  isGroupNode = false;
  /**
   * 仅用于 previewV1-> sassAIV2 相关
   */
  shareLoading = false;
  /**
   * FIXME: 已失效？ 没有搜索到为 true 的时候
   */
  isPublish = false;
  /**
   * 仅用于 previewV1-> sassAIV2 相关
   */
  showSettingPreview = false;
  /**
   * 仅用于 previewV1-> sassAIV2 相关
   */
  settingPreviewLoading = false;
  /**
   * @deprecated 可能已经不需要了，现在只用于 aisassv2
   */
  stripePaymentActivated = false;

  /**
   * @deprecated 该状态只用于 homeV1 版本，v2不在需要
   */
  isSetFeatureTag = false;

  /**
   *  仅用于 previewV1-> sassAIV2 相关
   * @deprecated 可能已经不需要了，现在只用于 aisassv2
   */
  subscriptionPrice = {} as any;

  /**
   * 该状态没有绑定ui，可能只需要作为一个普通变量
   *
   * 仅用于 cot
   */
  saasEdgesStaging = [] as any[];

  /**
   * 该状态没有绑定ui，可能只需要作为一个普通变量
   */
  alreadyRun = [] as any[];

  /**
   * 目前只有 WebOnlyPromptView 组件需要
   *
   * 由于 editor 组件中的已经将 mobile 过滤掉，WebOnlyPromptView 目前不会被显示出来
   */
  hasClosedMobilePromptView = false;

  previewHeight = 0;

  /**
   * 用于函数获取-加载状态
   *
   * 目前只用于 `CustomApiPage` 组件
   */
  userFunctionsLoading = false;

  /**
   * output node, 弹出创建Preview, 等待添加
   */
  awaitAddNode = undefined as any;

  copyWith(param: Partial<Omit<ProjectPageStoreState, "copyWith">>): ProjectPageStoreState {
    const newState = new ProjectPageStoreState();
    newState.shareSelectedLoading = param.shareSelectedLoading ?? this.shareSelectedLoading;
    newState.isGroupNode = param.isGroupNode ?? this.isGroupNode;
    newState.shareLoading = param.shareLoading ?? this.shareLoading;
    newState.isPublish = param.isPublish ?? this.isPublish;
    newState.showSettingPreview = param.showSettingPreview ?? this.showSettingPreview;
    newState.settingPreviewLoading = param.settingPreviewLoading ?? this.settingPreviewLoading;
    newState.saasEdgesStaging = param.saasEdgesStaging ?? this.saasEdgesStaging;
    newState.alreadyRun = param.alreadyRun ?? this.alreadyRun;
    newState.hasClosedMobilePromptView = param.hasClosedMobilePromptView ?? this.hasClosedMobilePromptView;
    newState.previewHeight = param.previewHeight ?? this.previewHeight;
    newState.userFunctionsLoading = param.userFunctionsLoading ?? this.userFunctionsLoading;
    newState.awaitAddNode = param.awaitAddNode ?? this.awaitAddNode;

    // deprecated 需要被删除
    newState.stripePaymentActivated = param.stripePaymentActivated ?? this.stripePaymentActivated;
    newState.isSetFeatureTag = param.isSetFeatureTag ?? this.isSetFeatureTag;
    newState.subscriptionPrice = param.subscriptionPrice ?? this.subscriptionPrice;

    return newState;
  }
}

/**
 * 原来 homeuseplugin 中直接用的状态,零零散散的状态
 *
 * 现在归于 projectPageStore 统一管理,还可以继续将其中的状态拆分到子组件中
 */
export class ProjectPageStore extends StoreBase<ProjectPageStoreState> {
  constructor() {
    super(() => new ProjectPageStoreState());
  }

  setShareSelectedLoading = (shareSelectedLoading: boolean): void => {
    this.emit(this.state.copyWith({ shareSelectedLoading }));
  };

  setIsGroupNode = (isGroupNode: boolean): void => {
    this.emit(this.state.copyWith({ isGroupNode }));
  };

  setShareLoading = (shareLoading: boolean): void => {
    this.emit(this.state.copyWith({ shareLoading }));
  };

  setIsPublish = (isPublish: boolean): void => {
    this.emit(this.state.copyWith({ isPublish }));
  };

  setShowSettingPreview = (showSettingPreview: boolean): void => {
    this.emit(this.state.copyWith({ showSettingPreview }));
  };

  setSettingPreviewLoading = (settingPreviewLoading: boolean): void => {
    this.emit(this.state.copyWith({ settingPreviewLoading }));
  };

  setStripePaymentActivated = (stripePaymentActivated: boolean): void => {
    this.emit(this.state.copyWith({ stripePaymentActivated }));
  };

  setIsSetFeatureTag = (isSetFeatureTag: boolean): void => {
    this.emit(this.state.copyWith({ isSetFeatureTag }));
  };

  setSubscriptionPrice = (subscriptionPrice: Record<string, any>): void => {
    this.emit(this.state.copyWith({ subscriptionPrice }));
  };

  setSaasEdgesStaging = (saasEdgesStaging: any[]): void => {
    this.emit(this.state.copyWith({ saasEdgesStaging }));
  };

  setAlreadyRunArr = (alreadyRun: any[]): void => {
    this.emit(this.state.copyWith({ alreadyRun }));
  };

  setHasClosedMobilePromptView = (hasClosedMobilePromptView: boolean): void => {
    this.emit(this.state.copyWith({ hasClosedMobilePromptView }));
  };

  setPreviewHeight = (previewHeight: number): void => {
    this.emit(this.state.copyWith({ previewHeight }));
  };

  setUserFunctionsLoading = (userFunctionsLoading: boolean): void => {
    this.emit(this.state.copyWith({ userFunctionsLoading }));
  };
}

export const projectPageStore = new ProjectPageStore();
