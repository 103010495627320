import { GUIDE_NODE_PATTERN } from "@views/thinking-layout-editor/constants";
import { logEvent } from "@uikit/service/amplitude";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fotActions } from "@store/fot";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

export function useAddToPreview(): any {
  const creatorNodesStore = getIt(CreatorNodesStore);

  const dispatch = useDispatch();
  const disableAddPreview = useSelector((state: any) => state.fot.disableAddPreview);
  const setDisableAddPreview = (val: boolean): void => {
    dispatch(fotActions.setDisableAddPreview(val));
  };

  const filterGuideNode = useCallback(() => {
    creatorNodesStore.setNodes(prevList => {
      return prevList.filter(x => !GUIDE_NODE_PATTERN.test(x.id));
    });
  }, []);

  const changeDisableAddPreview = (): void => {
    if (disableAddPreview) {
      filterGuideNode();
    }
    setDisableAddPreview(!disableAddPreview);
    if (disableAddPreview) {
      logEvent("click_add_content");
    } else {
      logEvent("click_add_content_done");
    }
  };

  return {
    disableAddPreview,
    changeDisableAddPreview,
  };
}
