import { MessageCenter } from "imagica-corekit/dist/base/kernel/MessageCenter";
import { useSignal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { getIt } from "@uikit/getIt";
import { RestResponse, RestResponseError } from "imagica-corekit/dist/base/cutil/RestClient";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { ChatAIStore } from "../ChatAIStore";
import { logEvent } from "@uikit/service/amplitude";
import func from "@uikit/func";
import { useRef } from "react";
import { DisplayType, UploadType } from "@uiview/views/Nodes/typing";
import { fileFormatConfig } from "@uiview/views/Nodes/UploadFile/fileFormatConfig";
import { some } from "lodash";
import { RcFile } from "antd/lib/upload";
import { EdgeRunAll } from "@uikit/service/EdgeRunAll";

const INDEXERS_IS_READY = "INDEXERS_IS_READY";

export type ChatHistoryType = {
  role: string;
  content: string;
  additional_kwargs: object;
};

export type ChatType = {
  type: string; // User 用户输入 | Assistant 助手返回
  value: string;
};

const ERRORTIP = "Either the engine you requested does not exist or there was another issue processing your request.";

const defaultErrorChatObj = {
  type: "Error",
  value: ERRORTIP,
};

export interface uploadFileChatHistoryType {
  filename?: string;
  chatList: ChatType[];
}

export class ChatContainerState {
  rcFile = useSignal<any>({});
  file = useSignal<string>(""); // 这里的file是upload接口返回的object_key 不是File文件
  inputText = useSignal("");
  file_uuid = useSignal<string | undefined>(undefined);
  filename = useSignal<string>("");
  s3Url = useSignal<string>("");
  displayType = useSignal<string>("");
  fileFormat = useSignal<string>("");
  isLoading = useSignal<boolean>(false); // 请求ai的loading
  prevInput = useSignal<string>("");
  chatList = useSignal<ChatType[]>([]); // 用于渲染的列表数据
  chatHistory = useSignal<Array<ChatHistoryType>>([]); // chatinterface的历史列表数据
  messageCenter = getIt(MessageCenter);
  isIndexers = useSignal<boolean>(false);
  pubsub_topic = "";
  chatContentRef = useRef(null);
  chatUploadRef = useRef(null);
  preOutputId = useRef("");
  isStreamStopped = useSignal<boolean>(true);
}

/**
 * ChatContent的输入框输入内容后
 * 点击Enter调用send方法请求AI接口
 */

export class ChatContainerBloc {
  brainClient = getIt(BrainClient);
  chatAIStore = getIt(ChatAIStore);

  constructor(
    public state: ChatContainerState,
    private isShare: boolean,
    private featureTags: string[],
    private edgeRunAll: EdgeRunAll,
    private inputNode: any
  ) {}

  private addChatList(item: ChatType): void {
    this.state.chatList.value = [...this.state.chatList.value, item];
  }

  updateChatInfo(props: any): void {
    if (func.isEmpty(props.textAreaValue?.file)) return;
    this.state.file.value = props.textAreaValue.file;
    this.state.pubsub_topic = props.textAreaValue.pubsub_topic;
    this.state.file_uuid.value = props.textAreaValue.file_uuid;
    this.state.isStreamStopped.value = props.textAreaValue.isStreamStopped;
    //  替换chat时，更新数据
    if (!func.isEmpty(props.textAreaValue.chatList) && !this.isShare) {
      this.state.chatList.value = props.textAreaValue.chatList || [];
      this.state.prevInput.value = props.textAreaValue.preQuery || "";
      this.state.chatHistory.value = props.textAreaValue.chatHistory || [];
    } else {
      this.state.chatList.value = [];
      this.state.prevInput.value = "";
      this.state.chatHistory.value = [];
      this.state.inputText.value = "";
      this.state.isLoading.value = false;
    }
  }

  /**
   * isAddUser: 是否在ChatList中添加用户输入
   * query: 用户输入
   * */
  private async aiApi(isAddUser: boolean, inputText?: string, chatHistory?: any): Promise<void> {
    if (isAddUser) {
      this.addChatList({
        type: "User",
        value: this.state.inputText.value,
      });
    }
    const query = inputText ? { query: inputText } : {};
    const chat_history = chatHistory ? { chat_history: chatHistory } : {};
    this.state.isLoading.value = true;
    try {
      const result: RestResponse<any> = await this.brainClient.openAi.indexers({
        mode: "chat",
        data: {
          file: this.state.file.value,
          loader: "PDFReader",
          ...query,
        },
        ...chat_history,
        uuid: this.state.file_uuid.value,
      });
      const res = result.data.data.response;
      logEvent("chat_ai_response", { chat_ai_response: res });
      this.addChatList({
        type: "Assistant",
        value: res,
      });
      this.state.isStreamStopped.value = false;
    } catch (error) {
      if (error instanceof RestResponseError && !isBlank(error.data.detail)) {
        this.addChatList({ ...defaultErrorChatObj, value: error.data.detail });
      } else {
        this.addChatList({ ...defaultErrorChatObj });
      }
    }
    this.state.isLoading.value = false;
  }

  async onPressEnter(txt: string, type: string): Promise<void> {
    if (isBlank(txt)) {
      func.customMsg({
        content: "Need to input something",
        type: "warning",
      });
      return;
    }
    if (!this.isShare) {
      this.edgeRunAll.clickRunAllBtn([this.inputNode], true);
      return;
    }
    this.chatAIStore.enterInputLoadingDone(true);
    this.state.inputText.value = txt;
    if (type === "text") {
      const blob = new Blob([txt], { type: "text/plain" });
      const formData = new FormData();
      formData.append("file", blob, `text_${+new Date()}.txt`);
      const result: any = await this.brainClient.openAi.upload(formData);
      this.state.file.value = result?.data?.object_key || "";
      this.state.file_uuid.value = result?.data?.uuid;
      this.state.chatList.value = [];
    }
    this.send();
  }

  getDisplayTypeByFileFormat = (filename: string): { displayType: DisplayType; format: string } => {
    const [, fileFormat] = /(?:\.([^.]+))?$/.exec(filename) || [];

    if (fileFormat) {
      const uploadType = Object.entries(fileFormatConfig).find(([, { formats }]) => {
        return some(formats, (format: string) => format.toLowerCase() === fileFormat.toLowerCase());
      });
      if (uploadType) {
        const [displayType, { featureTag }] = uploadType as unknown as [
          UploadType,
          {
            formats: string[];
            featureTag: string;
          }
        ];
        if (this.featureTags.includes(featureTag) !== true) {
          return { displayType, format: fileFormat };
        }
      }
    }

    if (this.featureTags.includes("disable_upload_node_unStructured")) {
      return { displayType: "uploadFile", format: fileFormat };
    }

    return { displayType: "unStructured", format: fileFormat };
  };

  uploadFile(file: RcFile, res: any): void {
    this.state.rcFile.value = file;
    this.state.filename.value = file.name;
    if (!this.isShare) {
      const { displayType, format } = this.getDisplayTypeByFileFormat(file.name);
      this.state.displayType.value = displayType;
      this.state.fileFormat.value = format;
    }
    this.state.file_uuid.value = res?.uuid || "";
    this.state.file.value = res?.object_key || "";
    this.state.s3Url.value = res?.data?.object_s3_url || res?.data?.s3_https_url || "";
  }

  // Upload 类型首次请求AI执行这个方法
  fileSend(): void {
    this.aiApi(false);
  }

  //  chat输入框发送消息
  async send(): Promise<void> {
    if (func.isEmpty(this.state.inputText.value) || this.state.isLoading.value) {
      return;
    }
    logEvent("chat_user_input", { chat_user_input: this.state.inputText.value });
    const chat_history = !func.isEmpty(this.state.chatHistory.value) ? this.state.chatHistory.value : null;
    this.state.prevInput.value = this.state.inputText.value;
    this.aiApi(true, this.state.inputText.value, chat_history);
  }

  // 重新生成
  async onRegenerate(): Promise<void> {
    if (func.isEmpty(this.state.chatList.value)) return;
    const arr = [...this.state.chatList.value];
    const last = arr.pop();
    last && logEvent("click_chat_regenerate", { chat_regenerate: last.value });
    this.state.chatList.value = arr;
    this.aiApi(false, this.state.prevInput.value);
  }

  // 重新生成
  async onStop(chatList?: ChatType[]): Promise<void> {
    if (chatList) {
      this.state.chatList.value = chatList;
    }
    this.state.isStreamStopped.value = true;
  }

  pollingRequest(query: string): Promise<any> {
    return Promise.race([this.stockRequest(), this.postIndexers(query)]).then(async res => {
      if (res === INDEXERS_IS_READY) {
        return await this.postIndexers(query);
      }
      return res;
    });
  }

  stockRequest(): Promise<any> {
    return new Promise(resolve => {
      if (this.state.isIndexers.value === false) {
        const timer = setInterval(() => {
          if (this.state.isIndexers.value === true) {
            clearInterval(timer);
            resolve(INDEXERS_IS_READY);
          }
        }, 2000);
      }
    });
  }

  //  ai请求
  async postIndexers(query: string): Promise<RestResponse<any>> {
    const isIndexers = this.state.isIndexers.value;
    try {
      return await this.brainClient.openAi.indexers({
        mode: "chat",
        data: {
          file: this.state.file.value,
          query,
          loader: "PDFReader",
        },
        chat_history: !func.isEmpty(this.state.chatHistory.value) ? this.state.chatHistory.value : undefined,
        uuid: this.state.file_uuid.value,
      });
    } catch (error: any) {
      if (isIndexers === true) {
        return Promise.resolve(error);
      } else {
        return new Promise(() => {});
      }
    }
  }

  listenTopic(): void {
    this.state.messageCenter.on(this.state.pubsub_topic, () => {
      this.state.isIndexers.value = true;
      this.state.messageCenter.off(this.state.pubsub_topic);
    });
  }
}
