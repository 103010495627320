"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestaurantListResponse = exports.RestaurantListData = exports.RestaurantItem = void 0;
class RestaurantItem {
    constructor() {
        this.by = "";
        this.id = 0;
        this.name = "";
        this.description = "";
        this.logo = "";
        this.phone_number = "";
        this.price_range = 0;
        this.media_image = "";
        this.cover_img_url = "";
        this.average_rating = 0;
        this.reviews = 0;
        this.displayRating = "";
        this.dishNum = 0;
        this.open = false;
        this.delivery = false;
        this.pickup = false;
        this.delivery_fee = 0;
        this.delivery_minimum = 0;
        this.delivery_time_estimate = 0;
        this.pickup_time_estimate = 0;
        this.cuisines = [];
        this.distance = "";
        this.url = "";
        this.next_delivery_time = "";
        this.next_pickup_time = "";
        this.next_open_at = "";
        this.next_closed_at = "";
        this.isGrubhubPlus = false;
        this.isOrder = false;
        this.next_close_second = 0;
        this.time_zone = "";
        this.orderLatestAt = "";
        this.soft_blackouted = false;
        this.convenience = false;
        this.modality_display_string = "";
        this.shipping = false;
    }
}
exports.RestaurantItem = RestaurantItem;
class RestaurantListData {
    constructor() {
        this.source = "";
        this.sourceLink = "";
        this.cache = false;
        this.total_time = "";
        this.by = "";
        this.stores = [];
        this.query = "";
        this.next = "";
        this.hasRestaurant = false;
        this.queryString = "";
    }
}
exports.RestaurantListData = RestaurantListData;
class RestaurantListResponse {
    constructor() {
        this.name = "";
        this.message = "";
        this.apiTime = "";
        this.done = false;
    }
}
exports.RestaurantListResponse = RestaurantListResponse;
