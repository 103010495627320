import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { filter, find, first, get, isArray, isEmpty, isString, map, some } from "lodash";
import {
  PREVIEW_APP_METADATA_V2_PATH,
  PREVIEW_CHATAI_PUBLISH_INPUT_TYPES,
  PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES,
} from "./const";
import { WEB_APP_DEFAULT_SUBTITLE, WEB_APP_DEFAULT_TITLE } from "@views/thinking-layout-editor/constants";
import { Node, Edge } from "reactflow";
import func from "@uikit/func";
import { WITHOUT_INPUT_ID } from "../AISaas/AddToContent/conts";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { isOnlyChatInterface } from "@views/thinking-layout-editor/util";
import { uploadTypes } from "../Nodes/constants";
import { getIt } from "@uikit/getIt";
import { ChatAIStore } from "../ChatAI/ChatAIStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { StoryNodeDisplayType } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeDisplayType";
import { previewStore } from "@uiview/store/PreviewStore";
import { PreviewAppValueLangUtil } from "./PreviewAppValueLangUtil";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";

export class PreviewAppUtil {
  static removeByNodeIds(value: PreviewApp.UISaasValueType[], ids: string[]): PreviewApp.UISaasValueType[] {
    return value.map(app => {
      return {
        ...app,
        input: app.input.filter(item => !ids.includes(item.id)),
        output: app.output.filter(item => !ids.includes(item.results[0].nodeId)),
      };
    });
  }

  /**
   * 获取目标对象里的 publish_metadata_v2
   * @param target
   * @returns
   */
  static getPublishMeta(target: any): PreviewApp.PublishedMetadataV2Type[] {
    return get(target, PREVIEW_APP_METADATA_V2_PATH, []) as PreviewApp.PublishedMetadataV2Type[];
  }

  static getTargetPublishMetaById(
    target: any,
    id: PreviewApp.PublishedMetadataV2Type["id"]
  ): PreviewApp.PublishedMetadataV2Type | undefined {
    const metalist = PreviewAppUtil.getPublishMeta(target);
    return find(metalist, ["id", id]);
  }

  /**
   * 用来判断 nodeid 是否已经被添加到 output 中
   *
   * - nodeid 为 group id 的情况, 组节点是否添加按照当前 output 到preview 的逻辑来看，output 中只要有 groupid 则表示已经添加, 因为组的子节点单独添加不会包含 groupID
   * - nodeid 为普通节点的情况，
   * @param nodeId
   * @param output
   */
  static hasAddedOutputByNode(nodeId: string, outputList: AISaasOutput.ThoughtType[]): boolean {
    if (ReactFlowNodeUtil.isGroupNodeId(nodeId)) {
      // FIXME: 组的子节点单独添加不会包含 groupID， 需要完善判断逻辑
      return !!find(outputList, ["groupId", nodeId]);
    }

    // 普通节点
    for (const output of outputList) {
      for (const result of output.results) {
        // 如果该节点找到，且groupId 存在则表示该节点是通过组节点增加进 output, 否则才是子节点单独增加
        if (result.nodeId === nodeId && !output.groupId) {
          return true;
        }
      }
    }

    return false;
  }

  static checkInputLengthAndType(input: Node[], types: string[], length = 1): boolean {
    if (isArray(input) && input.length !== length) {
      return false;
    }
    const displayType = first(input)?.data.displayType;
    return isString(displayType) ? types.includes(displayType) : false;
  }
  static checkOutputLengthAndType(output: AISaasOutput.ThoughtType[], types: string[], length = 1): boolean {
    if (isArray(output) && output.length !== length) {
      return false;
    }
    const displayType = get(output, ["0", "results", "0", "type"]);
    return isString(displayType) ? types.includes(displayType) : false;
  }

  /**
   * 该方法用于验证当前 saasuidata 中的 input 和 output 是否满足以下两个条件
   *
   * 1. input 和 output 分别只存在一个节点
   * 2. 其中的节点应该是同一条边上的节点
   *
   * @param args
   * @returns
   */
  static isRelationNodeWithChatAI(args: PreviewApp.GetChatAiModeArgs): boolean {
    const { saasUIData, nodes } = args;
    const { input, output } = saasUIData;

    if (
      PreviewAppUtil.checkInputLengthAndType(input, PREVIEW_CHATAI_PUBLISH_INPUT_TYPES) &&
      PreviewAppUtil.checkOutputLengthAndType(output, PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES)
    ) {
      // 并且检查 input, output 两个节点是否为同一组,也就是 input 边连接的就是 output
      const startNodeId = get(input, ["0", "id"]);
      const endNodeId = get(output, ["0", "results", "0", "nodeId"]);

      if (startNodeId && endNodeId) {
        return PreviewAppUtil.isRelationNode({ nodes, startNodeId: [startNodeId], endNodeId });
      }
    }

    return false;
  }

  static getChatAiMode(args: PreviewApp.GetChatAiModeArgs): PreviewApp.ChatAppModeType | undefined {
    const { input, output } = args.saasUIData;

    const hasOutput = !isEmpty(output);
    const hasInput = !isEmpty(input);

    // 有输出/有输入
    // 2.1 只有输出, 只有一个 chatinterface 可发布
    if (hasOutput && !hasInput && isOnlyChatInterface(output)) {
      return "chat";
    }

    // 2.2 只有输入
    if (
      hasInput &&
      !hasOutput &&
      PreviewAppUtil.checkInputLengthAndType(
        input,
        PREVIEW_CHATAI_PUBLISH_INPUT_TYPES.concat(PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES)
      )
    ) {
      if (PreviewAppUtil.checkInputLengthAndType(input, PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES)) {
        return "chat";
      }

      if (PreviewAppUtil.checkInputLengthAndType(input, [StoryNodeDisplayType.TEXT])) {
        return "text";
      }
      return "upload";
    }

    // 2.3 有输出 && 有输入, 只有一个 text/upload 作为输入, chatinterface 作为输出 可发布
    if (hasInput && hasOutput) {
      if (PreviewAppUtil.checkInputLengthAndType(input, [StoryNodeDisplayType.TEXT])) {
        return "text";
      }
      if (PreviewAppUtil.checkInputLengthAndType(input, PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES)) {
        return "chat";
      }
      return "upload";
    }

    return;
  }

  /**
   * 1. 没有输入和输出 禁止发布
   * 2. 有输出/有输入
   *  2.1 只有输出, 只有一个 chatinterface 可发布
   *  2.2 只有输入, 只有一个 chatinterface 可发布
   *  2.3 有输出 && 有输入, 只有一个 text/upload 作为输入, chatinterface 作为输出 可发布
   * 3. 其他情况 禁止发布
   *
   * @param saasUIData
   * @returns
   */
  static isDisabledPublishByChatAi(args: PreviewApp.DisabledPublishArgs): boolean {
    const { saasUIData } = args;
    const { input, output } = saasUIData;

    const hasOutput = !isEmpty(output);
    const hasInput = !isEmpty(input);

    // 输入输出都没有 禁用发布
    if (!hasOutput && !hasInput) {
      return true;
    }

    const chatMode = PreviewAppUtil.getChatAiMode(args);
    //只有input时，只能是chat可发布，其他的不可以发布
    if (hasInput && !hasOutput && chatMode !== "chat") {
      return true;
    }
    return !chatMode;
  }
  /**
   *
   * aiapp,static 类型: 使用上一版本逻辑
   *
   * chat ai 类型: `isDisabledPublishByChatAi`
   *
   * @param args
   */
  static isDisabledPublish(args: PreviewApp.DisabledPublishArgs): boolean {
    const result = this.isDisabledPublishByOther(args);
    if (result !== undefined) {
      return result;
    }
    const hasInput = !func.isEmpty(args.saasUIData.input);
    const hasOutput = !func.isEmpty(args.saasUIData.output);
    return !(hasInput && hasOutput);
  }

  static isDisabledPublishV2(args: PreviewApp.DisabledPublishArgs): boolean {
    const result = this.isDisabledPublishByOther(args);
    if (result !== undefined) {
      return result;
    }
    return false;
  }

  static isDisabledPublishByOther(args: PreviewApp.DisabledPublishArgs): boolean | undefined {
    const { saasUIData, isStaticMode, thoughts } = args;
    // FIXME: 不应该使用 thoughts
    const isEmptyOutput = isEmpty(thoughts);

    // chat ai disabled
    if (PreviewAppValueLangUtil.isChatAiApp(saasUIData)) {
      return PreviewAppUtil.isDisabledPublishByChatAi(args);
    }

    // 上一次的逻辑
    if (isOnlyChatInterface(saasUIData.output)) {
      return false;
    }

    if (isStaticMode && isEmptyOutput === false) {
      return false;
    }
  }

  static isDisabledPublishByInput(args: PreviewApp.DisabledPublishArgs): boolean {
    const result = this.isDisabledPublishByOther(args);
    if (result !== undefined) {
      return result;
    }
    return func.isEmpty(args.saasUIData.input);
  }

  /**
   * 开始节点和结束节点是否有关联(在同一条边上)
   * @param args
   * @returns
   */
  static isRelationNode(args: { startNodeId: string[]; endNodeId: string; nodes: Node[] }): boolean {
    const relatedEdgeNode = args.nodes.filter(node =>
      node.data?.flows?.some((source: any) => args.startNodeId.includes(source.sourceNodeId))
    );
    //如果找不到start node连的边，没有关联
    if (func.isEmpty(relatedEdgeNode)) return false;
    const nextNodesId = relatedEdgeNode.map(node => node?.data?.targetNodeId);
    if (func.isEmpty(nextNodesId)) return false;
    //target中能找到endNodeId，有关联
    if (nextNodesId.includes(args.endNodeId)) return true;

    return PreviewAppUtil.isRelationNode({ ...args, startNodeId: nextNodesId });
  }
  static isChatNode(node: Node): boolean {
    return node?.data?.displayType === "chatInterface";
  }
  //判定当前节点之后是否有chat node
  static hasRelatedChatNode(args: { startNodeId: string[]; nodes: Node[] }): boolean {
    const relatedEdgeNode = args.nodes.filter(node =>
      node.data?.flows?.some((source: any) => args.startNodeId.includes(source.sourceNodeId))
    );
    //如果找不到start node连的边，没有关联
    if (func.isEmpty(relatedEdgeNode)) return false;
    const nextNodesId: string[] = relatedEdgeNode.map(node => node?.data?.targetNodeId);
    if (func.isEmpty(nextNodesId)) return false;
    const nextNodes = args.nodes.filter(node => nextNodesId.includes(node.id));
    const nextNodeHasChatNode = nextNodes.some(node => PreviewAppUtil.isChatNode(node));
    //target中能找到chat node，有关联
    if (nextNodeHasChatNode) return true;

    return PreviewAppUtil.hasRelatedChatNode({ ...args, startNodeId: nextNodesId });
  }
  //判定是否需要显示禁用的tool bar提示
  static needDisableSendToAppBtn(args: {
    isChat: boolean;
    nodeId: string;
    isStartNode: boolean;
    nodes: Node[];
  }): boolean {
    if (!args.isChat) return false;
    const currentNode = args.nodes.find(node => node.id === args.nodeId);
    if (!currentNode) return true;

    return !PreviewAppUtil.isChatNode(currentNode);
  }

  /**
   * from 'homeUsePlugin' HandleInputField `UIConfigRef.current[typeSelected].setUIDataFunc(prevData` 部分
   *
   * 用来获取handleInputField 处理后的数据
   *
   * @param args
   * @returns
   */
  static genHandleInputField(args: {
    saasUIData: PreviewApp.UISaasValueType;
    currentNode: Node;
    selectedTemplate: PreviewApp.ProjectType;
  }): any {
    const oldInputNode = args.saasUIData.input || [];
    const prevData = args.saasUIData;
    const { selectedTemplate, currentNode } = args;

    let obj = {};
    if (isEmpty(oldInputNode)) {
      // stud-1221 change title&subTitle
      obj = {
        title: prevData.fromCotTitle || selectedTemplate?.name || WEB_APP_DEFAULT_TITLE,
        subTitle: prevData.fromCotSubtitle || WEB_APP_DEFAULT_SUBTITLE,
      };
    }
    /**
     * 如果uploadTypes中的类型作为input node
     * 修改类型为uploadFile
     */
    const isUploadTypesNode = uploadTypes.includes(currentNode?.data?.displayType);
    const newCurrentNode = isUploadTypesNode
      ? { ...currentNode, data: { ...currentNode.data, displayType: "uploadFile", textAreaValue: "" } }
      : { ...currentNode };
    let newInputNode = [] as any[];
    if (previewStore.state.addContentId && previewStore.state.addContentId !== WITHOUT_INPUT_ID) {
      oldInputNode.forEach(item => {
        if (item.id === previewStore.state.addContentId) {
          newInputNode.push(newCurrentNode);
          newInputNode.push(item);
        } else {
          newInputNode.push(item);
        }
      });
    } else {
      newInputNode = [...oldInputNode, newCurrentNode];
    }
    return {
      ...prevData,
      ...obj,
      input: [...newInputNode],
    };
  }

  static handleChatAIAppField(args: {
    creatorSaasAppStore: CreatorSaasAppStore;
    projectNodeStore: ProjectNodeStore;
    creatorNodesStore: CreatorNodesStore;
    currentNode: Node;
    // nodes: Node[];
    // selectedTemplate: PreviewApp.ProjectType;
    setInputId: (param: any) => void;
  }): void {
    const { currentNode } = args;
    const inputId = currentNode.id;

    // 查找上一次 saasuidata 中的 input 和 output 的关系
    // 1. 检查 currentNode 在input 和 output 中的关系
    //
    // 1.1 如果是单边边
    //  输入时: 直接替换当前input
    //  输出时: 直接替换对应输出
    // 1.2 多边: 替换当前input; 添加output时，替换当前output
    //
    // 2. 如果 input 或 output 中和 currentNode 没有关联,则直接移除这些节点
    // FIXME: 内部判断使用output 第一个节点，有可能会因为数据不正确，第一个节点数据错误
    // stud-1832 chat ai output
    // chat 只有单输入输出
    function genChaiAiInput(): Node[] {
      return [currentNode];
    }

    // stud-2493 set input(preview)
    args.creatorSaasAppStore.setSaasUIData(prev => {
      return {
        ...prev,
        input: genChaiAiInput(),
      };
    });
    args.projectNodeStore.composeNodes(args.creatorNodesStore.getNodes(), args.creatorSaasAppStore.state.saasUIData);
    args.setInputId([inputId]);

    // PreviewAppUtil.handleChatPreviewOutputByNewInput({
    //   inputId,
    //   output: args.saasUIData.output,
    //   nodes: args.nodes,
    //   setSaasUIData: args.setSaasUIData,
    // });
  }

  static clearChatOutput(setSaasUIData: React.Dispatch<React.SetStateAction<PreviewApp.UISaasValueType>>): void {
    setSaasUIData((prev: any) => {
      return {
        ...prev,
        output: [],
      };
    });
  }
  //chat添加input，处理output
  static handleChatPreviewOutputByNewInput(args: {
    inputId: string;
    output: AISaasOutput.ThoughtType[];
    nodes: Node[];
    setSaasUIData: React.Dispatch<React.SetStateAction<PreviewApp.UISaasValueType>>;
  }): void {
    if (func.isEmpty(args.output)) return;
    const outputNodeId = args.output[0]?.results?.[0]?.nodeId;
    if (func.isEmpty(outputNodeId)) return;
    const isRelated = PreviewAppUtil.isRelationNode({
      startNodeId: [args.inputId],
      endNodeId: outputNodeId,
      nodes: args.nodes,
    });
    //如果和当前input无关，删除当前output
    if (!isRelated) {
      PreviewAppUtil.clearChatOutput(args.setSaasUIData);
    }
  }

  //chat添加output，处理input
  static handleChatPreviewInputByNewOutput(args: {
    outputId: string;
    input: Node[];
    nodes: Node[];
    setInputId: (param: any) => void;
    creatorSaasAppStore: CreatorSaasAppStore;
    projectNodeStore: ProjectNodeStore;
  }): void {
    if (func.isEmpty(args.input)) return;
    const inputNodeId = args.input[0]?.id;
    if (func.isEmpty(inputNodeId)) return;
    const isRelated = PreviewAppUtil.isRelationNode({
      startNodeId: [inputNodeId],
      endNodeId: args.outputId,
      nodes: args.nodes,
    });
    //如果和当前output无关，删除当前input
    if (!isRelated) {
      // stud-2493 set input(preview)(clear)
      args.creatorSaasAppStore.setSaasUIData((prev: any) => {
        return {
          ...prev,
          input: [],
        };
      });
      args.projectNodeStore.composeNodes(args.nodes, args.creatorSaasAppStore.state.saasUIData);
      args.setInputId([]);
    }
  }

  static getChatOutputEdgeId(output: AISaasOutput.ThoughtType[], nodes: Node[]): string | undefined {
    const chatNodeId = output?.[0]?.results?.[0]?.nodeId;
    if (!chatNodeId) return undefined;

    const edgeNode = nodes.find(node => node.data?.targetNodeId === chatNodeId);
    if (!edgeNode) return undefined;
    return edgeNode?.id;
  }
  //根据saasUIData和previewAppList，拿到chat ai app相关的id
  static handleChatOutputEdgeId(args: {
    saasUIData: PreviewApp.UISaasValueType;
    previewAppList: PreviewApp.UISaasValueType[];
    nodes: Node[];
  }): Array<string | undefined> {
    const arr: Array<string | undefined> = [];
    //当前saasUIData是chat ai app,如果有chat的output，则添加chat的边id
    const previewDetailIsChat = PreviewAppValueLangUtil.isChatAiApp(args.saasUIData);
    if (previewDetailIsChat) {
      const edgeId = PreviewAppUtil.getChatOutputEdgeId(args.saasUIData.output, args.nodes);
      if (edgeId) arr.push(edgeId);
    }
    //preview app list挨个循环判定，如果是chat ai app，有chat的output，则添加chat的边id
    args.previewAppList?.forEach(previewApp => {
      const previewAppIsChat = PreviewAppValueLangUtil.isChatAiApp(previewApp);
      if (!previewAppIsChat) return;
      const edgeId = PreviewAppUtil.getChatOutputEdgeId(previewApp.output, args.nodes);
      if (edgeId && !arr.includes(edgeId)) arr.push(edgeId);
    });
    const chatAIStore = getIt(ChatAIStore);
    chatAIStore.setChatNodePreEdgeId(arr);
    return arr;
  }
  //chat边变化后，删除相关的output
  static deleteChatAiOutput(args: {
    edgeId: string;
    saasUIData: PreviewApp.UISaasValueType;
    previewAppList: PreviewApp.UISaasValueType[];
    nodes: Node[];
    setSaasUIData: React.Dispatch<React.SetStateAction<PreviewApp.UISaasValueType>>;
    replacePreviewAppList: (newPreviewAppList: PreviewApp.UISaasValueType[]) => void;
  }): void {
    //saasUIData
    const saasEdgeId = PreviewAppUtil.handleChatOutputEdgeId({
      saasUIData: args.saasUIData,
      previewAppList: [],
      nodes: args.nodes,
    });
    if (!func.isEmpty(saasEdgeId) && saasEdgeId.includes(args.edgeId)) {
      PreviewAppUtil.clearChatOutput(args.setSaasUIData);
    }
    const newPreviewAppList = args.previewAppList.map((app: PreviewApp.UISaasValueType) => {
      const currentOutputPreEdgeId = PreviewAppUtil.handleChatOutputEdgeId({
        saasUIData: app,
        previewAppList: [],
        nodes: args.nodes,
      });
      if (!func.isEmpty(currentOutputPreEdgeId) && currentOutputPreEdgeId.includes(args.edgeId)) {
        return {
          ...app,
          output: [],
        };
      }
      return app;
    });
    //previewAppList
    args.replacePreviewAppList(newPreviewAppList);
  }
  static disabledPublishToOutput(args: {
    nodeId: string;
    saasUIData: PreviewApp.UISaasValueType;
    nodes: Node[];
  }): boolean | undefined {
    const homePluginStore = getIt(HomePluginStore);
    const hasAddedToPreview = args.saasUIData.output
      .filter(x => !x?.groupId)
      .map(x => (x?.results || []).map(r => r.nodeId))
      ?.flat(Infinity)
      ?.includes(args.nodeId);
    const isChat = PreviewAppValueLangUtil.isChatAiApp(args.saasUIData);

    if (!isChat) {
      homePluginStore.setDisablePublishAsContent(hasAddedToPreview);
      return;
    }

    const currentNode = args.nodes.find(node => node.id === args.nodeId);
    const isChatNode = currentNode && PreviewAppUtil.isChatNode(currentNode);
    homePluginStore.setDisablePublishAsContent(hasAddedToPreview || !isChatNode);
    return hasAddedToPreview || !isChatNode;
  }

  /**
   * 判断 output 中的 result 结构是否显示
   * @param result
   * @returns
   */
  static isVisibleOutputResult(result: AISaasOutput.ThoughtResults): boolean {
    // 如果有报错，那么也需要保留并显示出来。
    if (!func.isEmpty(result?.errorText)) return !!result;
    switch (result.type) {
      case "stock":
        return !func.isEmpty(result?.content?.symbol) && !func.isEmpty(result?.content?.value);
      case "code":
        return !func.isEmpty(result?.content?.code?.[0]?.codex_generation);
      case "chatBox":
        return !!result;
      default:
        return !func.isEmpty(result.content);
    }
  }

  /**
   * 获取扁平化后的 output 列表中的所有 results
   * @param outputList
   * @returns
   */
  static getFlatResults(outputList: AISaasOutput.ThoughtType[]): AISaasOutput.ThoughtResults[] {
    const results: AISaasOutput.ThoughtResults[] = [];
    for (const output of outputList) {
      for (const result of output.results) {
        results.push(result);
      }
    }
    return results;
  }

  /**
   * 合并上一次的 output 和 新 outputList
   *
   * !!! 改方法用于是否在替换
   *
   * @param prevOutput
   * @param outputList
   * @returns
   */
  static mergeOutputList(
    prevOutput: AISaasOutput.ThoughtType[],
    outputList: AISaasOutput.ThoughtType[]
  ): AISaasOutput.ThoughtType[] {
    const prevResutsList = PreviewAppUtil.getFlatResults(prevOutput);
    return outputList.map(output => {
      const newResults = output.results.map(result => {
        if (!PreviewAppUtil.isVisibleOutputResult(result)) {
          const prevResult = prevResutsList.find(item => {
            return item.nodeId === result.nodeId && item.parentNode === result.parentNode;
          });
          if (prevResult) {
            return {
              ...result,
              content: prevResult.content,
            };
          }
        }
        return result;
      });
      return {
        ...output,
        results: newResults,
      };
    });
  }

  static needDisableBluePrintCollapse(props: { nodeId: string; nodes: Node[]; edges: Edge[] }): boolean {
    const allNodes = [props.nodeId];
    const parentIds = [props.nodeId];

    while (parentIds.length) {
      const current = parentIds.pop();
      const childs = filter(props.nodes, x => x.data.parentNodeId === current);
      const childIds = map(childs, x => x.id);
      allNodes.push(...childIds);
      parentIds.push(...childIds);
    }

    return some(props.edges, edge => allNodes.includes(edge.source));
  }

  /**
   * 获取默认的 preview app 中的 subtitle
   *
   * bsf-6211 的时候 subtitle 默认没有了
   *
   * @param previewApp
   * @returns
   */
  static getPreviewAppDefualtSubtitle(previewApp: PreviewApp.UISaasValueType): string {
    if (previewApp.subTitle) {
      return previewApp.subTitle;
    }

    if (PreviewAppValueLangUtil.isChatAiApp(previewApp)) {
      return "";
    }
    return WEB_APP_DEFAULT_SUBTITLE;
  }
}
