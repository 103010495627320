import classNames from "classnames";
import css from "./index.module.scss";
import { isArray } from "lodash";
import { ImagicaCreatorPlan } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { LoadingOutlined } from "@ant-design/icons";
import isBlank from "@sedan-utils/is-blank";
import { PageConstants } from "@uiview/pages/PageConstants";
import { isEmpty } from "lodash";
import { Buttonloading } from "@uikit/planList/PlansListBloc";

type PlanCellProps = {
  record: ImagicaCreatorPlan;
  buttonLoading: Buttonloading;
  onUpgradeClick: (plan: ImagicaCreatorPlan) => void;
  onDowngradeClick: (plan: ImagicaCreatorPlan) => void;
};

export function PlanCell(props: PlanCellProps): JSX.Element {
  const record = props.record;
  const name = record.name;
  const price_month = record.price_month;
  const price_annual = record.price_annual;

  const upgrade_button = record.upgrade_button;
  const downgrade_button = record.downgrade_button;

  // 将年付和月付调换
  const yearAmount = price_annual?.monthly_amount;
  const monthAmount = price_month?.amount;

  const monthAmountInt = Math.floor(Number(monthAmount));
  const loading = record.value === props.buttonLoading.value ? props.buttonLoading.loading : false;
  const renderFootButton = (): JSX.Element | null => {
    if (record.current_plan) {
      return (
        <>
          <div className={css.currentPlanButton}>Current plan</div>
          {!isBlank(record.expiryDate) ? (
            <div className={css.cancelTipBox}>
              <span className={css.cancelTip}>
                {PageConstants.planCancelTip} {record.expiryDate}
              </span>
            </div>
          ) : null}
        </>
      );
    }
    // 当前 plan index 是否小于 is_show_downgrage_button
    else if (record.is_show_downgrage_button && !isEmpty(downgrade_button)) {
      return (
        <div
          className={classNames(css.footButton, { [css.disableBtn]: props.record.current_plan_is_canceled })}
          style={props.record.current_plan_is_canceled ? undefined : downgrade_button.style}
          onClick={() => props.onDowngradeClick(props.record)}
        >
          {loading && (
            <div className={css.loadingBox}>
              <LoadingOutlined className={css.btnLoading} />
            </div>
          )}
          {!loading && (
            <>
              <span>{downgrade_button.text}</span>
            </>
          )}
        </div>
      );
    } else if (!record.is_show_downgrage_button && upgrade_button) {
      return (
        <div className={css.footButton} style={upgrade_button.style} onClick={() => props.onUpgradeClick(props.record)}>
          {loading && (
            <div className={css.loadingBox}>
              <LoadingOutlined className={css.btnLoading} />
            </div>
          )}
          <span className={loading ? css.hidden : ""}>Upgrade</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classNames(css.planCell)} data-value={record.value}>
      <div data-planbody className={css.planBody} style={record.style}>
        {name.text === "Starter" && <div className={css.gridLine}></div>}
        <div className={css.planName}>
          <span style={name.style}>{name.text === "Business Plan" ? "Business" : name.text}</span>
        </div>

        <div className={classNames(css.priceRow, name.text === "Starter" ? css.noPriceRowMar : "")}>
          <span className={css.priceBlock} style={price_month.style}>
            <span className={css.priceMonthValue}>{yearAmount ? `$${monthAmountInt}/mo` : "Free"}</span>
          </span>
        </div>

        {monthAmount && price_annual ? (
          <div className={css.priceAnnualRow} style={price_annual.style}>
            <span className={css.mount}>{"$" + price_annual.amount}</span>
            <span className={css.unit}>annual</span>
          </div>
        ) : null}

        {isArray(record.badges) ? (
          <div className={css.badgeList}>
            {record.badges.map(item => (
              <div key={item.value} data-value={item.value} style={item.style} className={css.badge}>
                <span>{item.name}</span>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <div className={css.planFoot}>{renderFootButton()}</div>
    </div>
  );
}
