import { CreatorCanvasFocus } from "@uikit/cases/canvasFocus/CreatorCanvasFocus";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { UiFunctionService } from "@uikit/service/UiFunctionService";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { HomeToolBarStore } from "@uikit/store/homeToolBarStore";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { BlueprintsStore } from "imagica-corekit/dist/base/store/BlueprintsStore";
import { FunctionV2Store } from "imagica-corekit/dist/base/store/FunctionV2Store";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { ToolPieceStore } from "imagica-corekit/dist/base/store/ToolPieceStore";
import { Container } from "inversify";

/**
 * 有关ui层封装的 class 在这里注册，比如 reactFlow
 */
export class RegisterUIFunctional {
  static configure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };

    container.bind(FotReactFlow).toConstantValue(new FotReactFlow(it(CreatorNodesStore), it(HomeToolBarStore)));
    container.bind(CreatorCanvasFocus).toConstantValue(new CreatorCanvasFocus(it(FotReactFlow), it(CreatorNodesStore)));
    container
      .bind(UiFunctionService)
      .toConstantValue(
        new UiFunctionService(it(FunctionV2Store), it(PersonalFunctionStore), it(ToolPieceStore), it(BlueprintsStore))
      );
  }
}
