import { clone, cloneDeep, findIndex, get, isArray, isEmpty, omit, set } from "lodash";
import { PREVIEW_APP_Fields, PREVIEW_APP_ID_PATH, PREVIEW_APP_METADATA_V2_PATH, PreviewAppTypeMap } from "./const";
import { PreviewAppValueLangUtil } from "./PreviewAppValueLangUtil";
import { Node } from "reactflow";
import { PreviewAppValue } from "./model/PreviewAppValue";
import func from "@uikit/func";

type ProjectType = Record<string, any>;
export class PreviewAppValueAssignUtil {
  static assignAppId<T extends ProjectType>(target: T, appId?: PreviewAppValue["id"]): T {
    return appId ? set(target, PREVIEW_APP_ID_PATH, appId) : target;
  }

  static assignAppList<T extends ProjectType>(target: T, extra: PreviewApp.ExtraArgsType): T {
    const appList = get(extra, "appList", []);
    const newAppList = appList;

    newAppList.forEach(x => {
      if (func.isEmpty(x.input)) {
        // FIXME: 不能直接修改 staticMode, 需要通过 v2app 字段确定, 后面需要移除 staticMode
        // 因为原来有 static app, ai app,chat app, 现在没有类型区分,那么需要自动更正
        x.v2App && (x.v2App.type = PreviewAppTypeMap.STATIC_WEB);
        x.staticMode = true;
      }
    });
    return set(target, ["v3", "UI"], newAppList);
  }

  static assignPublishMeta<T extends ProjectType>(target: T, extra: PreviewApp.ExtraArgsType): T {
    const { appId, publishedMetadata } = extra;

    if (!appId || !publishedMetadata) {
      return target;
    }

    // 扩展 publish_meta data
    // 扩展一份出来,防止无法修改
    const metadataV2List = get(target, PREVIEW_APP_METADATA_V2_PATH) as
      | PreviewApp.PublishedMetadataV2Type[]
      | undefined;
    const metadataList = isArray(metadataV2List) ? clone(metadataV2List) : [];
    const targetIndex = findIndex(metadataList, [PREVIEW_APP_Fields.DATA_APP_ID, appId]);

    // 考虑到可能会有 v2 在 publishedMetadata 中
    const _publishedMetadata = omit(publishedMetadata, PREVIEW_APP_Fields.PREVIEW_METADATA_V2);

    // 注入 appid
    set(_publishedMetadata, PREVIEW_APP_Fields.DATA_APP_ID, appId);

    if (targetIndex < 0) {
      metadataList.push(_publishedMetadata);
    } else {
      const newPublishedMetadata = omit(_publishedMetadata, [
        "stripe_account_id",
        "stripe_price_id",
        "stripe_product_id",
      ]);
      // 因为preview v2 版本之前的数据是直接通过publishMetadata的数据更新的， v2是通过PublishedMetadataV2 更新的
      metadataList[targetIndex] = {
        ...metadataList[targetIndex],
        ...newPublishedMetadata,
      };
    }
    return set(target, PREVIEW_APP_METADATA_V2_PATH, metadataList);
  }

  static assignChatAIType<T extends ProjectType>(target: T, nodes: Node[]): T {
    const UIList = get(target, ["v3", "UI"]);
    if (!isArray(UIList)) {
      return target;
    }

    const newUIList = UIList.map((item: PreviewAppValue) => {
      if (!PreviewAppValueLangUtil.isChatAiApp(item)) {
        return item;
      }
      const chatMode = PreviewAppValueLangUtil.getChatAiMode({ saasUIData: item, nodes });
      return {
        ...item,
        v2App: {
          ...item.v2App,
          chatMode,
        },
      };
    });

    return set(target, ["v3", "UI"], newUIList);
  }

  static assignLastIndex<T extends ProjectType>(target: T, lastIndex: number): T {
    return Object.assign({}, target, { lastIndex });
  }

  static assignMultiRoundChatData<T extends ProjectType>(target: T, multiRoundChatData: any): T {
    return Object.assign({}, target, { multiRoundChatData });
  }

  static assignPreviewAppData<T extends ProjectType>(target: T, extra: PreviewApp.ExtraArgsType): T {
    if (isEmpty(extra)) {
      return target;
    }

    // 防止项目数据被污染 深拷贝一份
    target = cloneDeep(target);

    if (extra.appId) {
      target = PreviewAppValueAssignUtil.assignAppId(target, extra.appId);
    }

    if (extra.appList) {
      target = PreviewAppValueAssignUtil.assignAppList(target, extra);
    }

    if (extra.publishedMetadata) {
      target = PreviewAppValueAssignUtil.assignPublishMeta(target, extra);
    }

    if (extra.nodes) {
      target = PreviewAppValueAssignUtil.assignChatAIType(target, extra.nodes);
    }

    if (extra.lastIndex) {
      target = PreviewAppValueAssignUtil.assignLastIndex(target, extra.lastIndex);
    }

    if (extra.multiRoundChatData) {
      target = PreviewAppValueAssignUtil.assignMultiRoundChatData(target, extra.multiRoundChatData);
    }

    if (extra.projectNodes) {
      target = set(target, ["v3", "projectNodes"], extra.projectNodes);
    }

    return target;
  }
}
