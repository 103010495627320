import { OffsetPercentType, RectType } from "@uikit/util/FocusNodeUtil";
import { FocusConsts } from "./FocusConsts";
import { SetCenter } from "reactflow";

export class FocusOptions {
  /**
   * 动画持续时间
   */
  duration = FocusConsts.FLOW_DURTAOIN;

  /**
   * fitbounds padding default is: `0.1`
   */
  padding = FocusConsts.FLOW_FIT_PADDING;

  /**
   * 如果为 undefined 则自动使用 zoom
   * 如果有值则直接使用
   *
   * 取值范围 [1,2]
   */
  zoom?: number;

  /**
   * 偏移百分比
   *
   * - 如果值为 负数则表示减,相反则加
   * @type {OffsetPercentType | undefined}
   */
  offsetPercent?: OffsetPercentType;

  /**
   * 该属性用于标识移动动画的唯一ID， 用于作重复动画的判断，防止在同样的环境下执行多次 setCenter
   *
   * 一般可能是节点ID，边的ID
   */
  moveId?: string;

  /**
   * 扩展的 rect 尺寸, 用于在计算真实坐标或尺寸后为其扩展
   *
   * 主要用于偏移坐标,或增减宽度
   */
  extraRect?: RectType;

  // 下列两个状态来自首页其他状态,不应该在该数据中,
  showPreviewPanel: boolean = false;
  showIntroTooltipObj: boolean = false;

  // 可接受外部传入的 setCenter
  setCenter?: SetCenter;
}
