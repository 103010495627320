import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { SharedLinkToGetProject } from "@uikit/service/SharedLinkToGetProject";
import { Api } from "@uikit/service/api";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { ProjectPageStore } from "@uikit/store/ProjectPageStore";
import { NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { BlueprintsStore } from "imagica-corekit/dist/base/store/BlueprintsStore";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { FunctionV2Store } from "imagica-corekit/dist/base/store/FunctionV2Store";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { PreferencesStore } from "imagica-corekit/dist/base/store/PreferencesStore";
import { ToolPieceStore } from "imagica-corekit/dist/base/store/ToolPieceStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";
import { NavigateFunction, Params, Location as ReactLocation } from "react-router-dom";
import { ProjectLoader } from "./ProjectLoader";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";

export class FotProcessDep {
  onShareDomain?(isShare: boolean): void;
  setBrowerSuggestionTip?(isShare: boolean): void;
  navigate?: NavigateFunction;

  constructor(
    public reactLocation: ReactLocation<any>,
    public searchParams: URLSearchParams,
    public urlParam: Readonly<Params<string>>,
    public history: History,
    public location: Location
  ) {}
}

export type InitImagicaDataParams = {
  /**
   * 原来 URLSearchParams.get('urlSelectedUI')
   */
  urlSelectedUI?: string | null;

  /**
   * 原来 URLSearchParams.get('stripePaymentActivated')
   */
  urlStripePaymentActivated?: string | null;

  /**
   * 原来 URLSearchParams.get('q')
   */
  urlQparams?: string | null;

  /**
   * ui 层 defaultApis 数据
   */
  defaultApis: any[];

  /**
   * 原来 "/editor/:attrIndexer" 路由的 attrIndexer 值
   */
  attrIndexer?: string;

  /**
   * 原来 "/editor/shared_intelligent_app/:projectId" 路由的 projectId 值
   */
  routerProjectId?: string;

  /**
   * getProject 获取项目列表时发生错误的回调，因为原来 useHomeInit 在该方法发生错误的时候没有停止后续流程
   * @param error
   * @returns
   */
  onProjectError?: (error: unknown) => void;
};

export class ImagicaDep {
  constructor(
    public fotReduxStore: FotReduxStore,
    public homePluginStore: HomePluginStore,
    public homeStore: HomeStore,
    public functionV2Store: FunctionV2Store,
    public preferencesStore: PreferencesStore,
    public toolPieceStore: ToolPieceStore,
    public blueprintsStore: BlueprintsStore,
    public projectFunctionStore: ProjectFunctionStore,
    public creatorRefStore: CreatorRefStore
  ) {}
}

export class ImagicaUserDataDep {
  constructor(
    public brainClient: BrainClient,
    public gql: ObjectRelationGqlService,
    public navBarStore: NavBarStore,
    public projectLoader: ProjectLoader,
    public previewAppStore: PreviewAppStore,
    public chatOnboardingStore: ChatOnboardingStore
  ) {}
}

export class ImagicaShareDataDep {
  api = new Api(false);

  constructor(
    public authStore: FotAuthStore,
    public creatorAISaasStore: CreatorAISaasStore,
    public viewbuilderStore: ViewbuilderStore,
    public projectPageStore: ProjectPageStore,
    public sharedLinkToGetProject: SharedLinkToGetProject,
    public webConfigStore: WebConfigStore
  ) {}
}
