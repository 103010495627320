import React from "react";

function HighlightLinks({ text }) {
  const highlightedText = text?.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a style="color: blue; text-decoration: underline;" href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
  );

  return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
}

export default HighlightLinks;
