import "../components-css/Ingredient.css";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

function Ingredient(props) {
  const image =
    props.id % 2 === 0
      ? `${settings.S3CDN}${settings.viewAssetsPath}Ingredients1.png`
      : `${settings.S3CDN}${settings.viewAssetsPath}Ingredients2.png`;
  return (
    <section className="recipeCardHomeIngredient">
      <img src={image}></img>
      <p className="title">{props.content}</p>
    </section>
  );
}

export default Ingredient;
