"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryNodeOptions = void 0;
const StoryNodeDisplayType_1 = require("../../storyV2/domain/StoryNodeDisplayType");
const StoryNodeType_1 = require("../../storyV2/domain/StoryNodeType");
class StoryNodeOptions {
    static getNodeType(type) {
        return StoryNodeType_1.StoryNodeType[type.toUpperCase()];
    }
    static getDisplayTypeType(type) {
        return StoryNodeDisplayType_1.StoryNodeDisplayType[type.toUpperCase()];
    }
    constructor(id, content, type, displayType, parentId = undefined, children = [], inputTitle = "", generatedLabel = "") {
        this.id = id;
        this.content = content;
        this.type = type;
        this.displayType = displayType;
        this.parentId = parentId;
        this.children = children;
        this.inputTitle = inputTitle;
        this.generatedLabel = generatedLabel;
    }
}
exports.StoryNodeOptions = StoryNodeOptions;
