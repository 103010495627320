import { getIt } from "@uikit/getIt";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { createNewLineParam } from "@uikit/project/HomeUsePluginData";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import {
  DEFAULT_LINE_STYLE,
  DEFAULT_MARKER_END,
  TRANSPARENT_MARKER_END,
} from "@views/thinking-layout-editor/constants";

export class CanvasLineUtil {
  static generateNewLine(param: Partial<createNewLineParam>): any {
    const { source, target, type } = param;
    const newLineId = `new-line-${creatorRefStore.edgeIndexRef.current++}`;
    return {
      id: newLineId,
      type: "customNewLine",
      source,
      target,
      sourceHandle: "b",
      targetHandle: "a",
      markerEnd: type === "source" ? TRANSPARENT_MARKER_END : DEFAULT_MARKER_END,
      style: DEFAULT_LINE_STYLE,
      data: getIt(CanvasDataRef).newLineDataRef.current,
    };
  }

  // 保存sourceLineId/targetLineId到边的data中
  static handleUpdateLine({
    type,
    edgeId,
    sourceId,
    newLineId,
  }: {
    type: "source" | "target";
    edgeId: string;
    sourceId: string;
    newLineId: string;
  }): void {
    const creatorNodesStore = getIt(CreatorNodesStore);
    creatorNodesStore.setNodes((prevList: any[]) => {
      return prevList.map(x => {
        if (x.id === edgeId) {
          if (type === "source") {
            return {
              ...x,
              data: {
                ...x.data,
                flows: x.data.flows.map((y: any) => {
                  if (y.sourceNodeId === sourceId) {
                    return {
                      ...y,
                      sourceLineId: newLineId,
                    };
                  }
                  return y;
                }),
              },
            };
          }
          return {
            ...x,
            data: {
              ...x.data,
              targetLineId: newLineId,
            },
          };
        }
        return x;
      });
    });
  }
  // 创建与边的连线
  static createNewLine(param: createNewLineParam): any {
    const { sourceNode, source, target, type } = param;
    const creatorEdgesStore = getIt(CreatorEdgesStore);

    const newLine: any = CanvasLineUtil.generateNewLine(param);

    const edgeId = type === "source" ? target : source;
    const sourceId = type === "source" ? source : sourceNode.id;

    CanvasLineUtil.handleUpdateLine({
      type,
      edgeId,
      sourceId,
      newLineId: newLine.id,
    });

    creatorEdgesStore.setEdges(prevList => {
      return [...prevList, newLine];
    });
    return newLine;
  }
}
