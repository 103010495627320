"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutocompleteResponse = exports.MissingApi = exports.MissingApis = exports.Component = exports.Data = exports.BlueprintStreaming = exports.ListItem = exports.ToolPieceData = exports.BlueprintData = exports.Blueprint = exports.BlueprintQuery = exports.BlueprintResponse = void 0;
class BlueprintResponse {
}
exports.BlueprintResponse = BlueprintResponse;
class BlueprintQuery {
}
exports.BlueprintQuery = BlueprintQuery;
class Blueprint {
}
exports.Blueprint = Blueprint;
class BlueprintData {
    constructor() {
        this.description = "";
        this.name = "";
        this.execution_message = "";
        this.prompt = "";
        this.type = "";
        this.io = "";
        this.piece_type = "";
        this.selected_toolpiece = "";
    }
}
exports.BlueprintData = BlueprintData;
class ToolPieceData {
}
exports.ToolPieceData = ToolPieceData;
class ListItem {
    constructor() {
        this.description = "";
        this.name = "";
        this.id = "";
    }
}
exports.ListItem = ListItem;
class BlueprintStreaming {
}
exports.BlueprintStreaming = BlueprintStreaming;
class Data {
}
exports.Data = Data;
class Component {
}
exports.Component = Component;
class MissingApis {
}
exports.MissingApis = MissingApis;
class MissingApi {
}
exports.MissingApi = MissingApi;
class AutocompleteResponse {
    constructor() {
        this.id = "";
        this.phrase = "";
        this.query = "";
        this.selected_blueprints = [];
    }
}
exports.AutocompleteResponse = AutocompleteResponse;
