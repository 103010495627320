"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockDistribution = void 0;
const StoryEdge_1 = require("../../../storyV2/domain/StoryEdge");
const StoryManagerUtil_1 = require("../../../storyV2/domain/StoryManagerUtil");
const StoryNodeType_1 = require("../../../storyV2/domain/StoryNodeType");
const FunctionBase_1 = require("../../../storyV2/function/FunctionBase");
const JsonUtil_1 = require("../../../cutil/JsonUtil");
const StoryNodeOptions_1 = require("../../../storyV2/domain/StoryNodeOptions");
const StoryNodeDisplayType_1 = require("../../../storyV2/domain/StoryNodeDisplayType");
const StoryNode_1 = require("../../../storyV2/domain/StoryNode");
const StoryNodeState_1 = require("../../../storyV2/domain/StoryNodeState");
const BaseDistribution_1 = require("../../../storyV2/response/responseDistribution/BaseDistribution");
class StockDistribution extends BaseDistribution_1.BaseDistribution {
    handleRunResponse(story, edge, res, relatedEdges) {
        const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMGROUP, undefined);
        /// 构造下一个节点的子节点
        let stockInfos = [];
        if (res.data.streamResponse instanceof FunctionBase_1.RunStreamResponse) {
            stockInfos = [];
            const datas = res.data.streamResponse.response.value;
            datas.forEach(value => {
                if (value.data) {
                    stockInfos = stockInfos.concat(value.data);
                }
            });
        }
        else {
            stockInfos = res.data;
        }
        const newChildIdNumbers = StoryManagerUtil_1.StoryManagerUtil.idNumberForGroup(story, stockInfos.length, targetNode);
        const childNodes = stockInfos.map((value, index) => {
            const jsonStr = JsonUtil_1.JsonUtil.stringify(value) ?? "";
            const nodeOptions = new StoryNodeOptions_1.StoryNodeOptions(`editor-${newChildIdNumbers.get(index)}`, jsonStr, StoryNodeType_1.StoryNodeType.CUSTOMNODE, StoryNodeDisplayType_1.StoryNodeDisplayType.STOCKINFO, targetNode.id);
            const node = new StoryNode_1.StoryNode(nodeOptions);
            node.state = StoryNodeState_1.StoryNodeState.COMPLETED;
            node.received(new StoryNode_1.StoryNodeEventObject(jsonStr));
            return node;
        });
        targetNode.options.children = childNodes.map(node => node.options.id);
        targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
        /// 触发下一个节点的event，表示有子节点了，可以订阅子节点的event了
        StoryManagerUtil_1.StoryManagerUtil.cleanAndSetNewChildren(targetNode, childNodes);
        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
        return Promise.resolve();
    }
}
exports.StockDistribution = StockDistribution;
