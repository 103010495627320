"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AskBrainData = exports.AskBrainSourceData = void 0;
class AskBrainSourceData {
    constructor() {
        this.index = 0;
        this.source = "";
        this.domain_name = "";
        this.favicon = "";
        this.excerpt = "";
    }
}
exports.AskBrainSourceData = AskBrainSourceData;
class AskBrainData {
    constructor() {
        this.query = "";
        this.answer = "";
    }
}
exports.AskBrainData = AskBrainData;
