export const HAPPY_WITH_WHAT_I_HAVE = "I'm happy with what I have so far.";
export const WHAT_TO_DO = "What would you like to build today?";
export const NEED_MORE_INFO = "We need more info to create your app. Please select a suggestion below.";
export const INITIAL_OPERATION_LIST = [
  {
    label:
      "When users ask for stock advice, the app generates 3 stock options with explanations and a stock price interface",
  },
  {
    label: "When users enter the ingredients in their fridge and cupboard, the app generates quick recipes and photos",
  },
  { label: "When users enter their destination and interests, the app generates an itinerary" },
];
export const OPTION_PLACEHOLDER_WHEN_ENTERING = "Try these example starter prompts";
export const OPTION_PLACEHOLDER_WHEN_SUGGESTING = "Try one of these prompts instead";
export const CHAT_BAR_POP_UP = "The AI command bar can help you get started";
export const AI_POP_UP = "Click here to start \n again with a new prompt";
export const TRY_AUTO_IMAGICA = "Try Auto-Imagica";
