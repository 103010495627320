import locales from "../locales/index.json";
import { useCallback } from "react";
import { get } from "lodash";

export default function useLocalsText() {
  const t = useCallback((key, defaultText = "") => {
    return get(locales, key, defaultText);
  }, []);

  return { t };
}
