import store from "@store/index";
import func from "@uikit/func";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { HandleSaveUtil } from "@uikit/util/HandleSaveUtil";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { PreviewStore } from "@uiview/store/PreviewStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { MyModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { HomeToolBarStore } from "@uikit/store/homeToolBarStore";
import { NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import { SaveCore } from "imagica-corekit/dist/cases/saveService/SaveCore";
import { SaveApiFailedError } from "imagica-corekit/dist/cases/saveService/SaveErrors";
import {
  HandleSaveArgs,
  SaveData,
  SaveProcess,
  SaveResult,
  SaveVars,
} from "imagica-corekit/dist/cases/saveService/models";
import { isEmpty } from "lodash";
import { FotSaveEvent } from "./FotSaveEvent";
import { HomeMethodsService } from "./HomeMethodsService";
import { FotUIDependenciesInterface } from "@uikit/infra/FotUIDependenciesInterface";
import { Params } from "react-router-dom";

class FotSaveDep {
  urlParam: Readonly<Params<string>> = {};
  noSaveDataAttrs = ["errorText"];
}

/**
 * 原来 useHandleService
 */
export class FotSaveService implements FotUIDependenciesInterface<FotSaveDep> {
  dependencies: FotSaveDep = new FotSaveDep();

  constructor(
    public creatorEdgesStore: CreatorEdgesStore,
    public creatorNodesStore: CreatorNodesStore,
    public homeMethodsService: HomeMethodsService,
    public previewAppStore: PreviewAppStore,
    public canvasDataRef: CanvasDataRef,
    public creatorRefStore: CreatorRefStore,
    public myModificationDataStroe: MyModificationDataStroe,
    public homeToolBarStore: HomeToolBarStore,
    public previewStore: PreviewStore,
    public fotSaveEvent: FotSaveEvent,
    public saveCore: SaveCore
  ) {}
  setDependencies(value: Partial<FotSaveDep>): void {
    Object.assign(this.dependencies, value);
  }

  getSaveDataV3 = (
    templateObj = store.getState().studio.selectedTemplate as any,
    edges?: any[],
    variableList = store.getState().fot.variableList as any[],
    params: any = {},
    updateParam?: Record<string, any>
  ): any => {
    edges = edges || this.creatorEdgesStore.getEdges();
    let UI = this.homeMethodsService.getUIListParam();
    let nodes = this.creatorNodesStore.getNodes();

    if (!isEmpty(updateParam)) {
      nodes = nodes.map((x: any) => {
        if (x.id === updateParam.id || x.id === updateParam.edgeId) {
          if (updateParam.type === "lineParam") {
            return {
              ...x,
              data: {
                ...x.data,
                lineParam: {
                  ...x.data.lineParam,
                  ...updateParam.lineParam,
                },
              },
            };
          }
          return x;
        }
        return x;
      });
    }
    const nodeArr = nodes
      .map(x => {
        if (NEW_EDGE_REG.test(x.id)) {
          return this.canvasDataRef.getSimpleNodeV3(x);
        }
        return this.canvasDataRef.getSimpleNode(x);
      })
      .map((x: any) => HomeMethodsUtil.getFilterDataAttrsNode(x, this.dependencies.noSaveDataAttrs));

    const edgeArr = edges.map(node => this.canvasDataRef.getSimpleEdgeV3(node));
    const relevantFunctionList = HomeMethodsUtil.getItemRelavantFunctionsV3(
      this.creatorEdgesStore.getEdges(),
      this.creatorNodesStore.getNodes()
    );
    // 简化上传参数
    const simplifySingleFlowEdgeArr = relevantFunctionList.map((x: { edgeArr: any[] }) => {
      const newEdgeArr = x.edgeArr.map(node => this.canvasDataRef.getSimpleEdge(node));
      return {
        ...x,
        edgeArr: newEdgeArr,
      };
    });
    UI = UI.map(x => {
      x.input = x.input.map(input => {
        return {
          ...input,
          data: HandleSaveUtil.getDeleteFunctionData(input.data),
        };
      });
      return x;
    });

    const tempData = {} as Record<string, any>;
    const defaultInit = {
      navBarData: {
        figmaMode: this.homeToolBarStore.state.figmaMode,
      },
      version: 1,
      nodes: nodeArr,
      edges: edgeArr,
      functions: simplifySingleFlowEdgeArr,
      UI,
      nodeIndex: this.creatorRefStore.nodeIndexRef.current,
      edgeIndex: this.creatorRefStore.edgeIndexRef.current,
      variablesList: JSON.stringify(variableList),
    };

    if (params?.published_metadata) {
      tempData.published_metadata = params.published_metadata;
    }
    const v3 = Object.assign({}, templateObj.v3, defaultInit);
    tempData.v3 = v3;
    const data = Object.assign({}, { ...templateObj }, tempData);
    delete data.id;
    delete data.uuid;
    return data;
  };

  private createSaveArgs(args?: Partial<HandleSaveArgs>): HandleSaveArgs {
    return Object.assign(
      new HandleSaveArgs(),
      // 三个默认值
      {
        templateObj: store.getState().studio.selectedTemplate,
        variableList: store.getState().fot.variableList,
        edges: this.creatorEdgesStore.getEdges(),
      },
      args
    );
  }

  private createSaveData(saveArgs: HandleSaveArgs): SaveData {
    let initSaveData = this.getSaveDataV3(saveArgs.templateObj, saveArgs.edges, saveArgs.variableList, saveArgs.params);

    if (!isEmpty(saveArgs.uiObj)) {
      initSaveData = { ...initSaveData, v3: { ...initSaveData.v3, UI: saveArgs.uiObj } };
    }
    // 可能不需要发布, 但是每次保存需要注入最新的 activeAppId
    initSaveData = this.previewAppStore.assignPreviewAppData(initSaveData);

    return initSaveData;
  }

  private createSaveVars(): SaveVars {
    const selectedTemplate = store.getState().studio.selectedTemplate as Record<string, any>;
    return Object.assign(new SaveVars(), {
      showMyModification: this.myModificationDataStroe.state.showMyModification,
      attrIndexer: this.dependencies.urlParam?.attrIndexer,
      userMe: store.getState().editor.me,
      selectedTemplate: selectedTemplate,
      // genlink 所需要的参数
      structureLinkParams: {
        appId: this.previewStore.state.previewAppAcivieId,
        enablePreviewApp: true,
        selectedUI: this.previewStore.state.selectedUI,
        stripe_price_id: selectedTemplate.published_metadata?.stripe_price_id,
        type: this.previewStore.getPreviewAppActiveLable(),
        // !!! 内部 update 会重置该属性
        uuid: "",
        legacy_project_attr_indexer: selectedTemplate.legacy_project_attr_indexer,
      },
    } as SaveVars);
  }

  createSaveProcess(args?: Partial<HandleSaveArgs>): SaveProcess {
    // 1. 初始化方法的参数
    const initSaveArgs = this.createSaveArgs(args);

    // 2. 初始化保存数据
    const initSaveData = this.createSaveData(initSaveArgs);

    // 3. 初始化一些额外变量
    const initSaveVars = this.createSaveVars();

    // 4. 初始化 save 的返回结果对象
    const initSaveResult = new SaveResult();

    return new SaveProcess(initSaveArgs, initSaveData, initSaveResult, initSaveVars, this.fotSaveEvent);
  }

  handleSave = async (args?: Partial<HandleSaveArgs>): Promise<any> => {
    console.log("jj STUD-2429 handleSave");
    // 获取一个 saveProcess， 并追加一些 fot 的状态或方法
    const saveProcess = this.createSaveProcess(args);

    try {
      return await this.saveCore.save(saveProcess);
    } catch (error) {
      // 捕获 saveApi 的出错
      if (error instanceof SaveApiFailedError) {
        console.log("jj catch SaveApiFailedError:", error.error);
        // FIXME: AdapterErrorUtil 内部使用的 message 获取信息
        func.messageError({ message: error.error });
        return;
      }

      console.error("handle Save", error);
      return Promise.reject(error);
    }
  };
}
