"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseDistribution = void 0;
const StoryNodeDisplayType_1 = require("../../../storyV2/domain/StoryNodeDisplayType");
const Meta_1 = require("../../../cutil/Meta");
const FunctionBase_1 = require("../../../storyV2/function/FunctionBase");
const StoryEdge_1 = require("../../../storyV2/domain/StoryEdge");
const StoryUtil_1 = require("../../../storyV2/domain/StoryUtil");
const StoryManagerUtil_1 = require("../../../storyV2/domain/StoryManagerUtil");
const StoryNodeType_1 = require("../../../storyV2/domain/StoryNodeType");
const LangUtil_1 = require("../../../cutil/LangUtil");
const StoryNodeState_1 = require("../../../storyV2/domain/StoryNodeState");
const StoryNode_1 = require("../../../storyV2/domain/StoryNode");
const JsonUtil_1 = require("../../../cutil/JsonUtil");
const StoryNodeOptions_1 = require("../../../storyV2/domain/StoryNodeOptions");
const AdapterResponseUtil_1 = require("../../../storyV2/adapter/AdapterResponseUtil");
const StoryTransformerUtil_1 = require("../../../storyV2/Transformer/StoryTransformerUtil");
const CustomApiTransformUtil_1 = require("../../../storyV2/response/customApiHandler/util/CustomApiTransformUtil");
const ResponseDistributionAbstract_1 = require("../../../storyV2/response/responseDistribution/ResponseDistributionAbstract");
/**
 * Response 分发处理, 处理FunctionV2的类型，这个类型是既支持http 返回，有支持 websocket返回
 * 后续可能都需要转到这个类型
 * 其他的distribution 都是这个的子类，可以重新handleRunResponse 方法
 * 如/split 边等，和默认的处理是不一样的，单个节点会变成一个组
 */
class BaseDistribution extends ResponseDistributionAbstract_1.ResponseDistributionAbstract {
    getHandler(res) {
        return this.registry.getByResponse(res);
    }
    handleRunResponse(story, edge, res, relatedEdges) {
        console.log("BaseDistribution: ", res);
        const sourceNode = StoryUtil_1.StoryUtil.findEdgeSoure(story, edge);
        if (!sourceNode) {
            return Promise.reject();
        }
        const isGroup = sourceNode.children.length > 0;
        if (isGroup) {
            return this.handleGroupResponse(story, sourceNode, edge, res, relatedEdges);
        }
        return this.handleNormalResponse(story, sourceNode, edge, res, relatedEdges);
    }
    handleGroupResponse(story, sourceNode, edge, res, relatedEdges) {
        return new Promise(resolve => {
            // 这里的data 值会是一个streamResponse
            if (!(res.data instanceof FunctionBase_1.RunStreamResponse)) {
                console.error("functionv2 res.data is not RunStreamResponse");
                Promise.resolve();
            }
            const streamResponse = res.data;
            // console.log("data: ", data);
            // hardcode对应的边，再run function时候就能知道返回的displaty type，所以这里能先反回一个displatyType
            const maybeDisplayType = this.getDefaultDisplayType(edge, story, res);
            const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMGROUP, maybeDisplayType);
            const newChildIdNumbers = StoryManagerUtil_1.StoryManagerUtil.idNumberForGroup(story, sourceNode.children.length, targetNode);
            const createdChildNodes = sourceNode.children.map((_, index) => {
                const nodeOptions = new StoryNodeOptions_1.StoryNodeOptions(`editor-${newChildIdNumbers.get(index)}`, undefined, StoryNodeType_1.StoryNodeType.CUSTOMNODE, maybeDisplayType, targetNode.id);
                const node = new StoryNode_1.StoryNode(nodeOptions);
                node.state = StoryNodeState_1.StoryNodeState.RECEIVING;
                return node;
            });
            targetNode.options.children = createdChildNodes.map(node => node.options.id);
            /// 触发下一个节点的event，表示有子节点了，可以订阅子节点的event了
            StoryManagerUtil_1.StoryManagerUtil.cleanAndSetNewChildren(targetNode, createdChildNodes);
            targetNode.state = StoryNodeState_1.StoryNodeState.RECEIVING;
            StoryManagerUtil_1.StoryManagerUtil.updateLastEdgeOutnode(story, edge, targetNode);
            streamResponse.onReceived(async (result) => {
                // console.log("data 11: ", result);
                if (result.error) {
                    StoryManagerUtil_1.StoryManagerUtil.setEdgesErrorStateAndNotify(story, targetNode, edge, relatedEdges, result.error);
                    // 整个group 报错，那么直接返回
                    return resolve();
                }
                result.value.forEach((value, key) => {
                    const currentChildNode = createdChildNodes[key];
                    if (result.error) {
                        // 某一个节点报错，只需要标记当前节点为错误，并且判断是否已经结束
                        const isCompletion = StoryManagerUtil_1.StoryManagerUtil.setGroupEdgesErrorStateAndNotify(story, currentChildNode, targetNode, edge, [], result.error);
                        if (isCompletion) {
                            return resolve();
                        }
                        return;
                    }
                    // console.log("data 22: ", result);
                    value.data?.onReceived(async (result) => {
                        // console.log("data 33: ", result);
                        if (result.error) {
                            // 某一个节点报错，只需要标记当前节点为错误，并且判断是否已经结束
                            const isCompletion = StoryManagerUtil_1.StoryManagerUtil.setGroupEdgesErrorStateAndNotify(story, currentChildNode, targetNode, edge, [], result.error);
                            if (isCompletion) {
                                return resolve();
                            }
                            return;
                        }
                        let displayType = this.getDisplayType(result.value);
                        // console.log("displayType: ", displayType);
                        currentChildNode.state = StoryNodeState_1.StoryNodeState.RECEIVING;
                        const rs = await (0, LangUtil_1.tryPromise)(this.contentTransform(sourceNode?.content || "", result.value));
                        displayType = await CustomApiTransformUtil_1.CustomApiTransformUtil.resetDisplayType(displayType, result.value);
                        currentChildNode.options.displayType = displayType;
                        if (rs.error) {
                            const isCompletion = StoryManagerUtil_1.StoryManagerUtil.setGroupEdgesErrorStateAndNotify(story, currentChildNode, targetNode, edge, [], result.error);
                            if (isCompletion) {
                                return resolve();
                            }
                            return;
                        }
                        const data = rs.data?.result;
                        const isEnded = rs.data?.isEnded || false;
                        // console.log("44: ", data);
                        let contentString = "";
                        if (typeof data === "string") {
                            contentString = data || "";
                        }
                        else {
                            contentString = JsonUtil_1.JsonUtil.stringify(data) || "";
                        }
                        if (isEnded) {
                            currentChildNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                            if (StoryManagerUtil_1.StoryManagerUtil.isCompletionForGroup(targetNode)) {
                                targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                                currentChildNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
                                StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
                                return resolve();
                            }
                        }
                        currentChildNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
                    });
                });
            });
        });
    }
    handleNormalResponse(story, sourceNode, edge, res, relatedEdges) {
        return new Promise(resolve => {
            const data = res.data;
            // data new FuncResponse(FuncResponseType.FUNCTION_V2, streamResponse);
            // console.log("data: ", data);
            const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMNODE, this.getDefaultDisplayType(edge, story, res));
            targetNode.cleanChildren();
            StoryManagerUtil_1.StoryManagerUtil.updateLastEdgeOutnode(story, edge, targetNode);
            if (data instanceof FunctionBase_1.RunStreamResponse) {
                data.onReceived(async (result) => {
                    // console.log("data 11: ", result);
                    if (result.error) {
                        StoryManagerUtil_1.StoryManagerUtil.setEdgesErrorStateAndNotify(story, targetNode, edge, relatedEdges, result.error);
                        return resolve();
                    }
                    let displayType = this.getDisplayType(result.value);
                    const rs = await (0, LangUtil_1.tryPromise)(this.contentTransform(sourceNode?.content || "", result.value));
                    displayType = await CustomApiTransformUtil_1.CustomApiTransformUtil.resetDisplayType(displayType, result.value);
                    targetNode.options.displayType = displayType;
                    if (rs.error) {
                        StoryManagerUtil_1.StoryManagerUtil.setEdgesErrorStateAndNotify(story, targetNode, edge, relatedEdges, rs.error);
                        return resolve();
                    }
                    const data = rs.data?.result;
                    const isEnded = rs.data?.isEnded || false;
                    // console.log("data 22: ", data);
                    if (data instanceof FunctionBase_1.RunStreamResponse) {
                        const streamResponse = data;
                        streamResponse.onReceived(srs => {
                            targetNode.state = StoryNodeState_1.StoryNodeState.RECEIVING;
                            if (srs.error) {
                                StoryManagerUtil_1.StoryManagerUtil.setEdgesErrorStateAndNotify(story, targetNode, edge, relatedEdges, srs.error);
                                resolve();
                                return;
                            }
                            if (srs.completion) {
                                targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                                targetNode.received(new StoryNode_1.StoryNodeEventObject(srs.value));
                                StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
                                return resolve();
                            }
                            targetNode.received(new StoryNode_1.StoryNodeEventObject(srs.value));
                        });
                    }
                    else {
                        let contentString = "";
                        if (typeof data === "string") {
                            contentString = data || "";
                        }
                        else {
                            contentString = JsonUtil_1.JsonUtil.stringify(data) || "";
                        }
                        if (result.completion) {
                            if (isEnded) {
                                targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                            }
                            targetNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
                            StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
                            return resolve();
                        }
                        targetNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
                    }
                });
            }
            else {
                let contentString = "";
                if (typeof data === "string") {
                    contentString = data || "";
                }
                else {
                    contentString = JsonUtil_1.JsonUtil.stringify(data) || "";
                }
                targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                targetNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
                StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
                resolve();
            }
        });
    }
    getDisplayType(res) {
        return this.getHandler(res).getDisplayType(res);
    }
    async contentTransform(query, res) {
        const handler = this.getHandler(res);
        if (!handler) {
            throw new Meta_1.Meta("ResponseTransformService", "Unsupported response type.");
        }
        return handler.handle(query, res);
    }
    getDefaultDisplayType(edge, story, res) {
        const maybeDisplayType = AdapterResponseUtil_1.AdapterResponseUtil.funcResponseTypeTransform(res.functionType);
        if (maybeDisplayType) {
            return maybeDisplayType;
        }
        // 优先使用node原来的DisplayType
        let defaultDisplayType = StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT;
        const OriTarget = edge.targetNode();
        if (OriTarget?.options.displayType) {
            defaultDisplayType = OriTarget.options.displayType;
        }
        else if (OriTarget) {
            const type = StoryTransformerUtil_1.StoryTransformerUtil.createDisplayType(OriTarget, story);
            if (type)
                defaultDisplayType = type;
        }
        return defaultDisplayType;
    }
}
exports.BaseDistribution = BaseDistribution;
