import { UploadTypeArray } from "./typing";

export const NO_WHEEL = "nowheel";

export const uploadTypes: UploadTypeArray = [
  "uploadFile",
  "uploadImage",
  "baseVideo",
  "unStructured",
  "table",
  "pdfFile",
  "audio",
  "html",
];

export const supportList = [
  "PDF",
  "XLSX",
  "CSV",
  "TXT",
  "DOC",
  "DOCX",
  "PNG",
  "JPG",
  "JPEG",
  "SVG",
  "MP4",
  "MP3",
  "M4A",
];
