import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class PopupFeedbackStoreState {
  enableCOTFeedbackPopup = false;
  copyWith(param: Partial<Omit<PopupFeedbackStoreState, "copyWith">>): PopupFeedbackStoreState {
    const newState = new PopupFeedbackStoreState();
    newState.enableCOTFeedbackPopup = param.enableCOTFeedbackPopup ?? this.enableCOTFeedbackPopup;

    return newState;
  }
}

/**
 * cot 生成时底部图标的提示层
 */
class PopupFeedbackStore extends StoreBase<PopupFeedbackStoreState> {
  constructor() {
    super(() => new PopupFeedbackStoreState());
  }

  setEnableCOTFeedbackPopup = (enableCOTFeedbackPopup: boolean): void => {
    this.emit(this.state.copyWith({ enableCOTFeedbackPopup }));
  };
}
export const popupFeedbackStore = new PopupFeedbackStore();
