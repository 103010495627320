"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterResponseHandler = void 0;
const AskBrainResponseHandler_1 = require("../base/storyV2/response/handler/AskBrainResponseHandler");
const ResponseHandlerRegistry_1 = require("../base/storyV2/response/handler/ResponseHandlerRegistry");
const FunctionBase_1 = require("../base/storyV2/function/FunctionBase");
const ImageGenResponseHandler_1 = require("../base/storyV2/response/handler/ImageGenResponseHandler");
const TextGenResponseHandler_1 = require("../base/storyV2/response/handler/TextGenResponseHandler");
const CustomApiTransfromService_1 = require("../base/storyV2/response/customApiHandler/CustomApiTransfromService");
const CustomApiResponseHandler_1 = require("../base/storyV2/response/handler/CustomApiResponseHandler");
const ResponseHandler_1 = require("../base/storyV2/response/handler/ResponseHandler");
class RegisterResponseHandler {
    static configure(container) {
        const it = (serviceIdentifier) => {
            return container.get(serviceIdentifier);
        };
        const responseHandlerRegistry = it(ResponseHandlerRegistry_1.ResponseHandlerRegistry);
        responseHandlerRegistry.registerCreateNewHandler(() => {
            return new ResponseHandler_1.ResponseHandler(it(CustomApiTransfromService_1.CustomApiTransfromService));
        }, FunctionBase_1.FuncDataSourceType.DEFAULT);
        responseHandlerRegistry.registerCreateNewHandler(() => {
            return new AskBrainResponseHandler_1.AskBrainResponseHandler(it(CustomApiTransfromService_1.CustomApiTransfromService));
        }, FunctionBase_1.FuncDataSourceType.ASK_BRAIN);
        responseHandlerRegistry.registerCreateNewHandler(() => {
            return new CustomApiResponseHandler_1.CustomApiResponseHandler(it(CustomApiTransfromService_1.CustomApiTransfromService));
        }, FunctionBase_1.FuncDataSourceType.CUSTOMAPI);
        responseHandlerRegistry.registerCreateNewHandler(() => {
            return new ImageGenResponseHandler_1.ImageGenResponseHandler(it(CustomApiTransfromService_1.CustomApiTransfromService));
        }, FunctionBase_1.FuncDataSourceType.IMAGE_TEXT);
        responseHandlerRegistry.registerCreateNewHandler(() => {
            return new TextGenResponseHandler_1.TextGenResponseHandler(it(CustomApiTransfromService_1.CustomApiTransfromService));
        }, FunctionBase_1.FuncDataSourceType.TEXT);
    }
}
exports.RegisterResponseHandler = RegisterResponseHandler;
