import {
  getHTMLElementOfReactFlow,
  getScreenshotAsBlobByHtmlElement,
  uploadFileToS3,
} from "@views/thinking-layout-editor/utilities";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { logEvent } from "./amplitude";
import { isEmpty } from "lodash";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { SaveTemplateService } from "./SaveTemplateService";
import func from "@uikit/func";
import { NavigateFunction } from "react-router-dom";
import { SaveEvent, SaveProcess } from "imagica-corekit/dist/cases/saveService/models";
import { getIt } from "@uikit/getIt";
import { functionPopupStore } from "@uikit/store/FunctionPopupStore";
import { FotUIDependenciesInterface } from "@uikit/infra/FotUIDependenciesInterface";
import { FotReduxStore } from "@uikit/store/FotReduxStore";

class FotSaveEventDep {
  navigate?: NavigateFunction;
}

/**
 * 该类用于实现 save core 中的 saveEvent 用到的方法
 *
 * 注意这里面的方法可能会直接传递方法，可能会丢失this，兼容传递方法是使用 Function.bind 绑定this
 */
export class FotSaveEvent implements SaveEvent, FotUIDependenciesInterface<FotSaveEventDep> {
  dependencies: FotSaveEventDep = new FotSaveEventDep();
  constructor(
    public fotReduxStore: FotReduxStore,
    public previewAppStore: PreviewAppStore,
    public homePluginStore: HomePluginStore
  ) {}

  setDependencies(value: FotSaveEventDep): void {
    Object.assign(this.dependencies, value);
  }

  /**
   * @param saveProcess
   * 获取 logEvent save_project 事件属性
   */
  static getAmplitudeEventProperties(saveProcess: SaveProcess): {
    project_static_id: any;
    name: any;
    isFirstCreatePorject: boolean;
    source: undefined;
  } {
    const isFirstCreatePorject = isEmpty(saveProcess.args.templateObj.id);

    return {
      project_static_id: saveProcess.result.updated_project.index,
      name: saveProcess.result.updated_project.name, //new name
      isFirstCreatePorject: isFirstCreatePorject,
      source: saveProcess.args.source, //TODO: share also auto saves
    };
  }

  setSelectedTemplate(val: any): void {
    this.fotReduxStore.setSelectedTemplate(val);
  }

  setFuncName(name: string): void {
    functionPopupStore.setFuncName(name);
  }

  onOpenCreatorFunctionPopup(): Promise<any> {
    return getIt(SaveTemplateService).clickModalSaveBtn();
  }
  onSuccessMessge(message: string): void {
    func.messageUtil(message, "success");
  }
  onSaveSuccessNavigate(updatedProject: { editorAttrIndexer?: string | undefined }): void {
    if (updatedProject.editorAttrIndexer) {
      console.log("save to naviate ");

      this.dependencies.navigate?.(`/editor/${updatedProject.editorAttrIndexer}`);
    }
  }

  updateSelectedTemplate(data: Record<string, any>, reset?: boolean | undefined): void {
    if (reset) {
      this.setSelectedTemplate(data);
      return;
    }

    const selectedTemplate = this.fotReduxStore.getState().studio.selectedTemplate;
    this.fotReduxStore.setSelectedTemplate({ ...selectedTemplate, ...data } as any);
  }

  assignPreviewAppData<T>(target: T, extra: Record<string, any>): T {
    return this.previewAppStore.assignPreviewAppData(target, extra);
  }

  async onSaveAfter(saveProcess: SaveProcess): Promise<void> {
    console.log("jj saveAfter");

    this.homePluginStore.resetUndoData();

    if (!saveProcess.vars.showMyModification) {
      saveProcess.events.updateSelectedTemplate(saveProcess.result.updated_project, true);
      logEvent("save_project", FotSaveEvent.getAmplitudeEventProperties(saveProcess));
    }
  }
  setStudioProjectName<T>(target: T): void {
    this.fotReduxStore.setStudioProjectName(target as string);
  }

  /**
   * 该方法是原来 handlesave 执行过程中的一个逻辑，但是和 save 没有关联
   *
   * 目前为了保证该逻辑不会丢失，在外部声明，内部调用，通过将该方法传递给 save 方法
   *
   * @param saveProcess
   */
  async saveAsUploadScreenshot(saveProcess: SaveProcess): Promise<any> {
    console.log("jj saveAsUploadScreenshot");

    try {
      const element = getHTMLElementOfReactFlow();
      const blob = await getScreenshotAsBlobByHtmlElement(element);
      const file = new File([blob], saveProcess.saveData.index, { type: "image/png" });
      const uploadedResult = await uploadFileToS3(file);
      saveProcess.saveData.thumbnail_s3ObjectKey = uploadedResult.s3.object_key;
    } catch (error) {
      console.error(error);
    }
  }

  onErrorMessge(error: any): void {
    func.messageUtil(error, "error");
  }
}
