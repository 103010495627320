import styles from "./ChargeAgreement.module.scss";
import { Signal, useSignal } from "@preact/signals-react";
import { CheckedBoxIcon, NoCheckeBoxIcon } from "./SvgIcon";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
export interface Props {
  selected: Signal<boolean>;
  removeCheckBox: Signal<boolean>;
  onAgreementToggled: (checked: boolean) => void;
}

export function ChargeAgreement(props: Props) {
  const webConfigState = useStore(getIt(WebConfigStore)).value;

  function termsOfService(): void {
    const link = webConfigState?.static_links?.terms_of_service_environment || "https://brain.ai/tos-environment";
    window.open(link);
  }

  function clickCheckBox(): void {
    if (props.selected.value) {
      return;
    }
    props.onAgreementToggled(!props.selected.value);
  }

  return (
    <div className={styles.container}>
      {!props.removeCheckBox.value && (
        <div className={styles.checkbox} onClick={() => clickCheckBox()}>
          {props.selected.value ? <CheckedBoxIcon /> : <NoCheckeBoxIcon />}
        </div>
      )}

      <div>
        <span className={styles.plain}>Agree to </span>
        <span onClick={termsOfService} className={styles.link}>
          Terms of Service (Environment)
        </span>
      </div>
    </div>
  );
}
