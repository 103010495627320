import { useSignal } from "@preact/signals-react";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { Spin } from "antd";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { Blueprint2 } from "imagica-corekit/dist/base/api/blueprintTyped/BlueprintV2";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { BlueprintsStore } from "imagica-corekit/dist/base/store/BlueprintsStore";
import styles from "./Button.module.scss";

type SaveButtonProps = {
  blueprint?: Blueprint2;
  updateFuncGroup: () => void;
};

export function SaveButton(props: SaveButtonProps) {
  const brainClient = getIt(BrainClient);

  const loading = useSignal(false);
  const visible = useSignal(true);

  const handleClick = async () => {
    if (props.blueprint) {
      loading.value = true;

      const result = await tryPromise(brainClient.openAi.saveBlueprints({ blueprint_data: props.blueprint }));

      loading.value = false;

      if (result.error) {
        return func.customMsg({
          content: "Save failed",
          type: "warning",
        });
      }

      func.customMsg({
        content: "Save successfully",
        type: "success",
      });

      await getIt(BlueprintsStore).renew();
      props.updateFuncGroup();
      visible.value = false;
    }
  };

  if (visible.value === false) {
    return null;
  }

  return (
    <div className={styles.btnWrapper}>
      <Spin spinning={loading.value}>
        <div className={styles.saveButton} onClick={() => handleClick()}>
          Save Blueprint
        </div>
      </Spin>
    </div>
  );
}
