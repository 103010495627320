import { useSignal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { useCallback, useEffect } from "react";
import { isEqual } from "lodash";
import { InputTitle, NodesTitleStore } from "@uikit/store/NodesTitleStore";

/**
 * 该 hook 仅用于需要绑定 NodesTitleStore 数据的组件
 * @param id
 * @returns
 */
export function useInputTitle(id: string): [InputTitle, (value: InputTitle) => void] {
  const nodesTitleStore = getIt(NodesTitleStore);
  const inputTitle = useSignal(nodesTitleStore.getInputTitleById(id));

  useEffect(() => {
    inputTitle.value = nodesTitleStore.getInputTitleById(id);

    const subscription = nodesTitleStore.on(() => {
      const newNode = nodesTitleStore.getInputTitleById(id);
      if (!isEqual(newNode, inputTitle.value)) {
        // if (newNode !== projectNode.value) {
        inputTitle.value = newNode;
      }
      // !!! 同步，不能使用 setTimeout 等异步行为
    }, true);

    return () => {
      subscription.off();
    };
    // eslint-disable-next-line
  }, [id]);

  const setInputTitleState = useCallback(
    (value: InputTitle) => {
      nodesTitleStore.updateInputTitle(id, value);
    },
    [id, nodesTitleStore]
  );

  return [inputTitle.value, setInputTitleState];
}
