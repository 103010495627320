import style from "./PasswordPage.module.scss";
import { logEvent } from "@uikit/service/amplitude";

export default function PasswordPage() {
  const resetUrl = document.location.origin + "/password_reset";

  return (
    <div className={style.passwordBox}>
      {/*<div className={style.textPrompt}>*/}
      {/*  Reset your password?*/}
      {/*</div>*/}
      {/* <div className={style.buttonBox}> */}

      <a
        className={style.pwResetBtn}
        onClick={() => {
          logEvent("my_account_reset_password");
          window.open(resetUrl, "_blank");
        }}
      >
        Reset
      </a>
      {/* </div> */}
    </div>
  );
}
