import { ProcessShareDomain } from "./ProcessShareDomain";
import { FotProcessDep } from "./modles";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { PreferencesStore } from "imagica-corekit/dist/base/store/PreferencesStore";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import isBlank from "@sedan-utils/is-blank";
import { FotUserAuthService } from "@uikit/service/FotUserAuthService";

export class ProcessUserAuth {
  constructor(
    public processShareDomain: ProcessShareDomain,
    public fotUserAuthService: FotUserAuthService,
    public fotReduxStore: FotReduxStore,
    public authStore: FotAuthStore,
    public meStore: MeStore,
    public preferencesStore: PreferencesStore,
    public subscriptionStore: SubscriptionStore,
    public personalFunctionStore: PersonalFunctionStore
  ) {}

  async init(fotProcessDep: FotProcessDep): Promise<void> {
    //查看是否有页面权限 || 是否需要跳转editor
    this.processShareDomain.clickEnableBrainStudios(fotProcessDep);

    const userMe = this.fotReduxStore.getState().editor.me as Record<string, any>;
    //获取用户信息
    if ((!isBlank(this.authStore.state.brainToken) || !isBlank(this.authStore.state.guestToken)) && isBlank(userMe)) {
      await this.fotUserAuthService.getUserMe();
    }
  }

  logout(): void {
    this.authStore.removeBrainToken();
    this.meStore.dirty();
    this.preferencesStore.dirty();
    this.subscriptionStore.dirty();
    this.personalFunctionStore.clean();
    localStorage.removeItem("BRAIN_USER_EMAIL");
    this.fotReduxStore.setMe({});
    this.fotReduxStore.setIsLogin(false);
    this.fotReduxStore.setLoginMethod("");

    this.authStore.logout();
  }
}
