"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalFunctionStore = void 0;
const RelationSetDest_1 = require("../api/graphqlTyped/RelationSetDest");
const StoreBase_1 = require("../cutil/StoreBase");
const LangUtil_1 = require("../cutil/LangUtil");
const JsonUtil_1 = require("../cutil/JsonUtil");
const ArrayUtil_1 = require("../util/ArrayUtil");
const Lazyload_1 = require("../cutil/Lazyload");
class GlobalFunctionStore extends StoreBase_1.StoreBase {
    constructor(brainClient) {
        super(() => []);
        this.brainClient = brainClient;
        this.lazyload = new Lazyload_1.Lazyload(() => {
            if (this.state.length !== 0) {
                /// 已从fot收到数据，无需自己调api
                return Promise.resolve(this.state);
            }
            return this.getFunctions(1, []).then(data => {
                this.emit(data);
                return data;
            });
        });
    }
    receive(globalFunctions) {
        if (globalFunctions == undefined || globalFunctions.length === 0) {
            return;
        }
        const relationSetDests = JsonUtil_1.JsonUtil.toArrayFromType(RelationSetDest_1.RelationSetDest, globalFunctions) || [];
        const nonRepetitive = this.state.filter(element => {
            return !ArrayUtil_1.ArrayUtil.contains(relationSetDests, element, r => r.id);
        });
        const newArr = nonRepetitive.concat(relationSetDests);
        this.lazyload.dirty();
        this.emit(newArr);
    }
    async get() {
        return this.lazyload.get();
    }
    async findDest(params) {
        const functions = await this.get();
        const findById = functions.find(dest => {
            return params.funcId && dest.id === params.funcId;
        });
        if (findById) {
            return findById.attributesV2;
        }
        const findByValue = functions.find(dest => {
            return params.funcValue && dest.name === params.funcValue;
        });
        if (findByValue) {
            return findByValue.attributesV2;
        }
        const cleanName = params.edgeName.replace("/", "");
        const findByName = functions.find(dest => {
            return dest.attributesV2?.name === cleanName;
        });
        if (findByName) {
            return findByName.attributesV2;
        }
        return undefined;
    }
    async getFunctions(page, completionResult) {
        const size = 20;
        const res = await this.requestFuncs(page, size);
        const newResult = [...completionResult, ...res];
        if (res.length < size) {
            return newResult;
        }
        return this.getFunctions(page + 1, newResult);
    }
    async requestFuncs(page, size) {
        const tryRes = await (0, LangUtil_1.tryPromise)(this.brainClient.graphql.getEdgeFunctions({
            name: "studio_function",
            globalOnly: true,
            page: page,
            size: size,
        }));
        if (tryRes.error) {
            console.error(tryRes.error);
        }
        return tryRes.data?.data || [];
    }
}
exports.GlobalFunctionStore = GlobalFunctionStore;
