import { getIt } from "@uikit/getIt";
import { useCallback, useMemo } from "react";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { FotReactFlow } from "@uikit/model/FotReactFlow";

// eslint-disable-next-line
export function useHomeDataRefMethods() {
  const canvasDataRef = getIt(CanvasDataRef);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const fotReactFlow = getIt(FotReactFlow);

  const creatorStoreMethods = getIt(CreatorStoreMethods);

  const generateNewNode = useCallback((e: { clientX: number; clientY: number }, displayType: any) => {
    const { x, y, zoom } = fotReactFlow.getViewport();

    const newOriginX = (e.clientX - x) / zoom;
    const newOriginY = (e.clientY - y) / zoom;
    const newNode = {
      id: `editor-${creatorRefStore.nodeIndexRef.current++}`,
      type: "customNode",
      data: {
        ...canvasDataRef.nodeDataRef.current,
        displayType,
      },
      position: {
        x: newOriginX,
        y: newOriginY,
      },
    };

    creatorNodesStore.setNodes(prevList => {
      return [...prevList, newNode];
    });

    creatorStoreMethods.onNodesEdgesAdd([canvasDataRef.getSimpleNode(newNode)], []);
  }, []);

  return useMemo(
    () => ({
      generateNewNode,
    }),
    [generateNewNode]
  );
}
