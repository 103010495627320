import { BlackAndWhiteListPermission, commandFunctionsConfig, groupNodePermission } from "../nodeConfig";
import { DisplayType } from "../typing";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { getIt } from "@uikit/getIt";

const defaultPermission: BlackAndWhiteListPermission = {
  whiteList: [],
  blackList: [],
};

type FilterList = {
  filterList: string[];
  filterType: string;
};

export function useFunctionsPermission(): any {
  const fotReactFlow = getIt(FotReactFlow);

  const getFilterList = (id: string): [FilterList?, FilterList?] => {
    const node = fotReactFlow.getNode(id);

    if (node?.type === "customNode") {
      return [calculatorFilterList(commandFunctionsConfig[node?.data.displayType as DisplayType])];
    }

    const childNode = fotReactFlow.getNode(node?.data.childNode?.[0] || "");
    const childPermission = commandFunctionsConfig[childNode?.data.displayType as DisplayType];

    return [calculatorFilterList(childPermission), calculatorFilterList(groupNodePermission)];
  };

  const calculatorFilterList = ({ whiteList = [], blackList = [] }: BlackAndWhiteListPermission = defaultPermission):
    | FilterList
    | undefined => {
    if (whiteList.length) {
      if (whiteList[0] === "ALL") {
        return {
          filterList: [],
          filterType: "exclude",
        };
      }

      return {
        filterList: whiteList,
        filterType: "include",
      };
    }

    if (blackList.length) {
      if (blackList[0] === "ALL") {
        return {
          filterList: [],
          filterType: "include",
        };
      }

      return {
        filterList: blackList,
        filterType: "exclude",
      };
    }
  };

  return {
    getFilterList,
  };
}
