"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignificanceType = exports.VBDataTopicListResponse = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataTopicListResponse extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.topic_list = [];
    }
}
exports.VBDataTopicListResponse = VBDataTopicListResponse;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Topic, [])
], VBDataTopicListResponse.prototype, "topic_list", void 0);
class Topic {
    constructor() {
        this.topic_title = "";
        this.topic_summary = "";
        this.topic_details = [];
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => TopicDetail, [])
], Topic.prototype, "topic_details", void 0);
class TopicDetail {
    constructor() {
        this.type = SignificanceType.TEXT;
        this.value = "";
    }
}
var SignificanceType;
(function (SignificanceType) {
    SignificanceType["IMAGE"] = "image";
    SignificanceType["TEXT"] = "text";
})(SignificanceType || (exports.SignificanceType = SignificanceType = {}));
class TopicDetailText {
    constructor() {
        this.topic_detail_title = "";
        this.topic_detail_body = "";
    }
}
