import { downloadFileAsBlobFromS3ByObjectKey, getDataUrlByBlob } from "@views/thinking-layout-editor/utilities";

export class MobileProjectsViewUtils {
  static async setThumbnailUrlByS3ObjectKey(objectKey: string): Promise<string> {
    try {
      const blob = await downloadFileAsBlobFromS3ByObjectKey(objectKey);
      const dataUrl = await getDataUrlByBlob(blob);
      return dataUrl;
    } catch (error) {
      return "";
    }
  }

  static async setThumbnailUrl(project: any): Promise<string> {
    let url = "";
    if (project) {
      if (project.thumbnailUrl) {
        url = project.thumbnailUrl;
      } else {
        try {
          const json = project.attributesV2 || JSON.parse(project.attributes ?? "{}");
          if (json?.thumbnail_s3ObjectKey) {
            url = await MobileProjectsViewUtils.setThumbnailUrlByS3ObjectKey(json.thumbnail_s3ObjectKey);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    return url;
  }
}
