import { CDNPathUtil } from "imagica-corekit/dist/base/util/CDNPathUtil";
import { get } from "lodash";

const CommandBarMetaDataMap = {
  "Stock Prices": {
    description: "Generate the latest stock prices based on your input.",
    icon: CDNPathUtil.getFilePath("stock.svg"),
  },
  "Add Data Source": {
    description: "Add documents or URLs to use in a project.",
    icon: CDNPathUtil.getFilePath("dataSource.svg"),
  },
  "DALL·E 2": {
    description: "Create an image using DALL.E 2",
    icon: CDNPathUtil.getFilePath("DALLE2.svg"),
  },
  "Image search": {
    description: "Search for an image based on your input.",
    icon: CDNPathUtil.getFilePath("imageSearch2.svg"),
  },
  Maps: {
    description: "Generate a map based on your input.",
    icon: CDNPathUtil.getFilePath("map.svg"),
  },
  Amazon: {
    description: "Generate a list of products on Amazon based on your input.",
    icon: CDNPathUtil.getFilePath("amazon.svg"),
  },
  "Weee Groceries": {
    description: "Generate a list of products from Weee based on your input.",
    icon: CDNPathUtil.getFilePath("weee.svg"),
  },
  "Split Output": {
    description: "Split large content into smaller pieces.",
    icon: CDNPathUtil.getFilePath("split.svg"),
  },
  "Ask Imagica": {
    description: "Ask a question and get a real-time answer.",
    icon: CDNPathUtil.getFilePath("askBrain.svg"),
  },
  "Interview Bot": {
    description: "Create an AI agent to call and prep for job interviews.",
    icon: CDNPathUtil.getFilePath("interviewBot.svg"),
  },
  Midjourney: {
    description: "Create an image using Midjourney.",
    icon: CDNPathUtil.getFilePath("midjourney.svg"),
  },
  "Generate an Image": {
    description: "Create an image based on your input.",
    icon: CDNPathUtil.getFilePath("generateImage.svg"),
  },
  "Generate a Photo": {
    description: "Create a photo-realistic image from your input.",
    icon: CDNPathUtil.getFilePath("cameraPhoto.svg"),
  },
  "Animation Style": {
    description: "Create an image in the style of animated art.",
    icon: CDNPathUtil.getFilePath("AnimationStyle.svg"),
  },
  "Cute Emoji Style": {
    description: "Creates an image in the style of an emoji.",
    icon: CDNPathUtil.getFilePath("faceSmile.svg"),
  },
  YouTube: {
    description: "Show YouTube videos based on the input.",
    icon: CDNPathUtil.getFilePath("youtube.svg"),
  },
  "Cybernetic Painting Style": {
    description: "Create an image in the style",
    icon: CDNPathUtil.getFilePath("paintingStyle.svg"),
  },
  "Chat Interface": {
    description: "Generate a chat interface to answer questions from a data source",
    icon: CDNPathUtil.getFilePath("chatInterface.svg"),
  },
};

export function getCommandBarIconWithGroup(value: any) {
  const group = get(value, "groupTitle", "other");
  if (group === "imageGeneration") {
    return CDNPathUtil.getFilePath("image-icon.png");
  }
  return CDNPathUtil.getFilePath("brain-logo.png");
}

/**
 * 由于 nodeTypeDispose 中的项目会经过不同逻辑处理，导致直接增加的数据无法在 customDropdown 直接使用
 *
 * 增加该方法为 commandBar 中的项目扩展数据
 */
export function getCommandData(value: any) {
  const label = get(value, "label", "");

  const commandBarMeat = CommandBarMetaDataMap[label as keyof typeof CommandBarMetaDataMap] || {};

  let { icon, description } = commandBarMeat;

  icon = icon || getCommandBarIconWithGroup(value);

  return {
    icon,
    description,
  };
}
