import func from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import { Api } from "@uikit/service/api";
import { useSignal } from "@preact/signals-react";

export class QRCodeViewState {
  isQRCodeBox = useSignal(false);
  codeLodaing = useSignal(false);
  qrCodeUrl = useSignal("");
  titleStr = useSignal("");
  isClicked = (node: any) => node.contains(document.activeElement);
}

export class LinksAndQRCodesViewBloc {
  constructor(public state: QRCodeViewState) {}

  copyShareLink(url: string, uuid: string) {
    navigator.clipboard.writeText(url);
    func.customMsg({
      content: "Copied successfully",
      type: "success",
    });
    logEvent("copy_published_link", { URL: url, uuid: uuid });
  }

  clickToShare(e: any, url: string) {
    e.stopPropagation();
    window.open(url, "_blank");
    logEvent("open_published_link", { URL: url });
  }

  clickOpen() {
    this.state.isQRCodeBox.value = true;
  }

  clickClose() {
    this.state.isQRCodeBox.value = false;
  }

  async getStudioDomainList(url: string, title: string) {
    this.state.titleStr.value = title;
    const api = new Api(false);

    this.state.codeLodaing.value = true;
    api
      .get(`/be/lambda/function/qrcode_gen/?url=${encodeURIComponent(url)}`)
      .then(response => {
        if (response.ok) {
          return response.body;
        }
      })
      .then(body => {
        const reader = body?.getReader();
        reader?.read().then(({ done, value }) => {
          if (done) return;
          const blob = new Blob([value], { type: "image/png" });
          const url = URL.createObjectURL(blob);

          this.state.qrCodeUrl.value = url;
          this.clickOpen();
        });
      })
      .catch(error => {
        func.messageError(error);
      })
      .finally(() => {
        this.state.codeLodaing.value = false;
      });
  }
}
