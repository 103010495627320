import UAParser from "ua-parser-js";

const parser = new UAParser(window.navigator.userAgent);

type BrowserType =
  | "Amaya"
  | "Android Browser"
  | "Arora"
  | "Avant"
  | "Baidu"
  | "Blazer"
  | "Bolt"
  | "Camino"
  | "Chimera"
  | "Chrome"
  | "Chromium"
  | "Comodo Dragon"
  | "Conkeror"
  | "Dillo"
  | "Dolphin"
  | "Doris"
  | "Edge"
  | "Epiphany"
  | "Fennec"
  | "Firebird"
  | "Firefox"
  | "Flock"
  | "GoBrowser"
  | "iCab"
  | "ICE Browser"
  | "IceApe"
  | "IceCat"
  | "IceDragon"
  | "Iceweasel"
  | "IE [Mobile]"
  | "Iron"
  | "Jasmine"
  | "K-Meleon"
  | "Konqueror"
  | "Kindle"
  | "Links"
  | "Lunascape"
  | "Lynx"
  | "Maemo"
  | "Maxthon"
  | "Midori"
  | "Minimo"
  | "MIUI Browser"
  | "[Mobile] Safari"
  | "Mosaic"
  | "Mozilla"
  | "Netfront"
  | "Netscape"
  | "NetSurf"
  | "Nokia"
  | "OmniWeb"
  | "Opera [Mini/Mobi/Tablet]"
  | "Phoenix"
  | "Polaris"
  | "QQBrowser"
  | "RockMelt"
  | "Silk"
  | "Skyfire"
  | "SeaMonkey"
  | "SlimBrowser"
  | "Swiftfox"
  | "Tizen"
  | "UCBrowser"
  | "Vivaldi"
  | "w3m"
  | "Yandex";

export class UAParseUtil {
  static getParser() {
    return parser;
  }

  /**
   * 获取浏览器类型
   * @returns
   */
  static getBrowserType() {
    return parser.getBrowser().name as BrowserType | undefined;
  }

  static isIpad() {
    return parser.getDevice().model === "iPad";
  }

  static isMobile() {
    return parser.getDevice().type === "mobile";
  }

  static isPc() {
    return parser.getDevice().model !== "iPad" && parser.getDevice().type !== "mobile";
  }
}
