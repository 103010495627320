import func from "@uikit/func";
import { OpenAiParam } from "@uikit/service/OpenAiParam";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { sleep } from "@views/thinking-layout-editor/util";

/**
 * 该 class 用于运行边时的 loading 相关的方法
 */
export class EdgeRunQuery {
  constructor(
    private creatorEdgesStore: CreatorEdgesStore,
    private creatorNodesStore: CreatorNodesStore,
    private openAiParam: OpenAiParam
  ) {}

  async setEdgeValueByWriterV3(id: string, enterText: string, isWriter: boolean = false): Promise<void> {
    const write = async (i: number): Promise<void> => {
      await sleep(20);
      const txt = isWriter && i < 60 ? enterText.slice(0, i) : enterText;
      this.creatorNodesStore.setNodes((prevList: any) => {
        return prevList.map((l: any) => {
          if (l.id === id) {
            return {
              ...l,
              data: {
                ...l.data,
                lineParam: {
                  ...l.data.lineParam,
                  enterText: txt,
                },
              },
            };
          }
          return l;
        });
      });
      const needNext = i < enterText.length && txt.length < enterText.length && isWriter;
      if (needNext) {
        await write(i + 2);
      }
    };
    await write(1);
  }

  async getAndSetQuery(
    id: any,
    text: any
  ): Promise<
    | {
        lineType: string;
        identifier: any;
      }
    | undefined
  > {
    try {
      this.creatorEdgesStore.setQueryLoading(id, "getQueryLoading", true);
      const identifier = await this.openAiParam.getEdgeGenerationParam(text, id);
      this.creatorEdgesStore.setQueryLoading(id, "getQueryLoading", false);
      if (identifier) {
        this.creatorEdgesStore.setEdges((prevList: any[]) => {
          return prevList.map((l: { id: any; data: { lineParam: any } }) => {
            if (l.id === id) {
              return {
                ...l,
                data: {
                  ...l.data,
                  lineParam: {
                    ...l.data.lineParam,
                    lineType: "identifier",
                    identifier,
                    enterText: text,
                  },
                },
              };
            }
            return l;
          });
        });
        this.creatorEdgesStore.setQueryLoading(id, "isGetQuery", true);
      }
      return {
        lineType: "identifier",
        identifier,
      };
    } catch (error) {
      func.messageUtil(error);
      console.error("get And Set Query", error);
      this.creatorEdgesStore.setQueryLoading(id, "getQueryLoading", false);
    }
  }

  async getAndSetQueryV3(
    id: string,
    text: string,
    isWriter: boolean = false
  ): Promise<{ lineType: string; identifier: any }> {
    try {
      // todo: 这里需要更新node边数据
      this.creatorNodesStore.setQueryLoadingV3({
        id,
        data: {
          getQueryLoading: true,
        },
      });
      const identifier = await this.openAiParam.getEdgeGenerationParam(text, id);
      this.creatorNodesStore.setQueryLoadingV3({
        id,
        data: {
          getQueryLoading: false,
        },
      });
      if (identifier) {
        this.creatorNodesStore.setNodes((prevList: any) => {
          return prevList.map((l: any) => {
            if (l.id === id) {
              return {
                ...l,
                data: {
                  ...l.data,
                  lineParam: {
                    ...l.data.lineParam,
                    lineType: "identifier",
                    identifier,
                  },
                },
              };
            }
            return l;
          });
        });
        this.creatorNodesStore.setQueryLoadingV3({
          id,
          data: {
            isGetQuery: true,
          },
        });
        await this.setEdgeValueByWriterV3(id, text, isWriter);
      }
      return {
        lineType: "identifier",
        identifier,
      };
    } catch (error) {
      func.messageError(error);
      console.error("get And Set Query", error);
      this.creatorNodesStore.setQueryLoadingV3({
        id,
        data: {
          isGetQuery: false,
          getQueryLoading: false,
        },
      });
      return Promise.reject(error);
    }
  }
}
