import { useRef } from "react";
import { Edge } from "reactflow";
import { EdgeParam, ManualLineParam } from "@uikit/project/HomeUsePluginData";
import { FUNCTION_REG, NEW_EDGE_REG } from "../../views/thinking-layout-editor/constants";
import func from "@uikit/func";
import { StudioProjectAttributesV2Edge } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { StudioProjectAttributesV3Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Node";
import { ProjectUtil } from "imagica-corekit/dist/cases/util/ProjectUtil";
import { HomeUsePluginUtil } from "@uikit/util/HomeUsePluginUtil";
import { logEvent } from "@uikit/service/amplitude";
import { find, get, map } from "lodash";
import { DataRefUtil } from "@uikit/util/DataRefUtil";
import { getIt } from "@uikit/getIt";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CanvasEdgeUtil } from "@uikit/util/CanvasEdgeUtil";
import { CanvasLineUtil } from "@uikit/util/CanvasLineUtil";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { ProjectNodeUtil } from "@uikit/projectNode/ProjectNodeUtil";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { EdgeRunService } from "@uikit/edgeRun/EdgeRunService";
import { NodeEdgeInfoUpdater } from "@uikit/service/NodeEdgeInfoUpdater";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { EdgeRunQuery } from "@uikit/edgeRun/EdgeRunQuery";
import { useAModalProvider } from "@uikit/context/AModalContext/useAModalProvider";

export const useManualLine = ({
  onNodesEdgesAdd,
}: ManualLineParam): {
  handleManualLine: (param: EdgeParam) => void;
  setNodeLabel: (id: string, label: string) => void;
} => {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const projectNodeStore = getIt(ProjectNodeStore);
  const edgeRunService = getIt(EdgeRunService);
  const nodeEdgeInfoUpdater = getIt(NodeEdgeInfoUpdater);
  const fotReactFlow = getIt(FotReactFlow);
  const edgeRunQuery = getIt(EdgeRunQuery);
  const projectRunStore = getIt(ProjectRunStore);

  const { showModal } = useAModalProvider();

  const manuallyConnectedEdges = useRef<EdgeParam>({
    source: "",
    sourceHandle: "",
    target: "",
    targetHandle: "",
  });

  function createLineByTwoNode({ source, target }: { source: string; target: string }): void {
    // 创建两条line
    // 创建一个边节点
    const nodes = creatorNodesStore.getNodes();
    const sourceNode = nodes.find(x => x.id === source);
    const targetNode = nodes.find(x => x.id === target);

    const sourceProjectNode = projectNodeStore.getNode(source);
    // 默认设置为Input
    // stud-2493 get input title(nodes)
    if (func.isEmpty(sourceProjectNode?.inputTitle)) {
      setNodeLabel(source, "Input");
    }

    if (sourceNode) {
      const prevNode = {
        id: source,
        xPos: sourceNode?.position?.x,
        yPos: sourceNode?.position?.y,
      };
      const newEdge = CanvasEdgeUtil.createNewEdge(prevNode);
      creatorNodesStore.handleUpdateTarget({
        newNode: targetNode,
        edgeNode: newEdge,
      });
      setTimeout(() => {
        const sourceLine = CanvasLineUtil.createNewLine({
          source,
          target: newEdge.id,
          type: "source",
        });
        const targetLine = CanvasLineUtil.createNewLine({
          target,
          sourceNode: prevNode,
          source: newEdge.id,
          type: "target",
        });

        const nodes = creatorNodesStore.getNodes();
        const updatedNewEdge = nodes.find(x => x.id === newEdge.id) as StudioProjectAttributesV3Node;
        onNodesEdgesAdd(
          [getIt(CanvasDataRef).getSimpleNodeV3(updatedNewEdge)],
          [
            DataRefUtil.getSimpleEdgeV3(sourceLine, getIt(CanvasDataRef).newLineDataRef),
            DataRefUtil.getSimpleEdgeV3(targetLine, getIt(CanvasDataRef).newLineDataRef),
          ]
        );
      }, 0);
    }
  }

  function drawLine(param: Pick<EdgeParam, "source" | "target">): void {
    const { source, target } = param;
    const sourceNewLine: StudioProjectAttributesV2Edge = CanvasLineUtil.createNewLine({
      source,
      target,
      type: "source",
    });

    onNodesEdgesAdd([], [sourceNewLine]);
    // 线
    handleEdgeAddLine({
      id: target,
      flow: { sourceNodeId: source, sourceLineId: sourceNewLine.id, creationMethod: false },
    });
  }

  const handleEdgeAddLine = ({
    id,
    flow,
  }: {
    id: string;
    flow: { sourceNodeId: string; sourceLineId: string; creationMethod: boolean };
  }): void => {
    creatorNodesStore.setNodes((prevList: any) => {
      return prevList.map((n: any) => {
        if (n.id === id) {
          return {
            ...n,
            data: {
              ...n.data,
              flows: [...n.data.flows, flow],
            },
          };
        }
        return n;
      });
    });
  };

  function setNodeLabel(id: string, label: string): void {
    // update label
    // stud-2493 deprecated
    creatorNodesStore.setNodes((prevList: any) => {
      return prevList.map((l: any) => {
        if (l.id === id) {
          return {
            ...l,
            data: {
              ...l.data,
              // stud-2493 set input title(nodes)
              inputTitle: label,
            },
          };
        }
        return l;
      });
    });

    projectNodeStore.updateById(id, { inputTitle: label });
  }

  function clickToCreateVariable(): void {
    const { source, target } = manuallyConnectedEdges.current;
    const sourceProjectNode = projectNodeStore.getNode(source);

    // stud-2493 get input title(nodes)
    if (
      sourceProjectNode &&
      (!func.isEmpty(sourceProjectNode.inputTitle) || !func.isEmpty(sourceProjectNode.generatedLabel)) &&
      sourceProjectNode.inputTitle !== "Text"
    ) {
      // clickCreateVariableModalSave(sourceNode.data.inputTitle)
      // 已经有了title并且连线
      // stud-2493 get input title(nodes)
      const title = sourceProjectNode.inputTitle || sourceProjectNode.generatedLabel || "";
      if (getHasSameName(target, title)) {
        func.customMsg({
          content: "A node with the same name already exists",
          type: "warning",
        });
        return;
      }

      drawLineAndRun("");
      return;
    }

    showModal({
      title: "What is this Node?",
      subTitle: "Give this node a name so the Edge knows how to use it in your function.",
      buttons: ["Cancel", "Confirm"],
      input: {
        type: "input",
        require: true,
        placeholder: "Enter variable name",
      },
      onOk: ({ close, reason }) => {
        // clickCreateVariableModalSave(reason)
        if (getHasSameName(target, reason)) {
          func.customMsg({
            content: "A node with the same name already exists",
            type: "warning",
          });
          return;
        }

        close();
        drawLineAndRun(reason);
      },
    });
  }

  function getHasSameName(edgeId: string, name: string): boolean {
    const edgeNode = fotReactFlow.getNode(edgeId);

    const sourceNodeIds = edgeNode?.data.flows.map((x: any) => x.sourceNodeId);
    // stud-2493 get input title(nodes)
    const sourceTitles = ProjectNodeUtil.filterTitleByIds(projectNodeStore.getNodes(), sourceNodeIds);

    return sourceTitles.includes(name);
  }

  function handleDrawEvent(edgeEnterText: string, result: any, target: string): void {
    const nodes = creatorNodesStore.getNodes();
    const targetNode: any = nodes.find(x => x.id === target);
    const input_nodes = map(get(targetNode, "data.flows"), ({ sourceNodeId }) => {
      const node = find(nodes, node => node.id === sourceNodeId);
      const textareaValue = get(node, "data.textAreaValue");
      return {
        id: node?.id || "",
        data: typeof textareaValue === "string" ? textareaValue : undefined,
        display_type: get(node, "data.displayType", ""),
        type: node?.type,
      };
    });
    const eventProperties = {
      input: input_nodes,
      prompt: edgeEnterText,
      function_name: "",
      isManualClick: false,
      type: "other",
      output: result.transformData,
      prompt_id: "",
      edge_id: targetNode.id,
    };
    logEvent("run_edge", eventProperties);
  }

  async function drawLineAndRun(label: string): Promise<void> {
    const nodes = creatorNodesStore.getNodes();
    const { source, target } = manuallyConnectedEdges.current;
    const targetNode: any = nodes.find(x => x.id === target);
    // const sourceNode: any = nodes.find(x => x.id === source);

    // update label
    if (!func.isEmpty(label)) {
      setNodeLabel(source, label);
    }

    // draw line
    drawLine({ source, target });
    logEvent("add_input_to_edge");
    const edgeEnterText = await nodeEdgeInfoUpdater.updateEdgeText(target);
    if (targetNode.data.lineParam.blueJson) {
      await nodeEdgeInfoUpdater.updateBlueprintEdge(target, edgeEnterText || targetNode?.data.lineParam.enterText);
    } else {
      await edgeRunQuery.getAndSetQueryV3(target, edgeEnterText || targetNode?.data.lineParam.enterText);
    }

    // run
    const sourceNodesFill = ProjectUtil.sourceNodesAllFill(target, nodes as StudioProjectAttributesV3Node[]);
    if (!sourceNodesFill) {
      return;
    }

    const result = await edgeRunService.corekitRun({
      edgeId: target,
      onErrorClosure() {
        projectRunStore.setRunAllLoading(false);
      },
    });

    handleDrawEvent(edgeEnterText, result, target);
  }

  function handleManualLine(param: EdgeParam): void {
    const edges = creatorEdgesStore.getEdges();
    const nodes = creatorNodesStore.getNodes();
    const { source, target } = param;
    const line = edges.find(x => x.target === target);
    let repeat = !!edges.some(x => x.source === source && x.target === target);

    manuallyConnectedEdges.current = param;
    // 直接链接到边上
    if (NEW_EDGE_REG.test(target) && repeat === false) {
      const targetNode = nodes.find(x => x.id === target);
      // 边内容为空不连接
      if (
        func.isEmpty(targetNode?.data?.lineParam?.enterText) ||
        FUNCTION_REG.test(targetNode?.data?.lineParam?.enterText)
      )
        return;
      if (HomeUsePluginUtil.findTargetHaveNode([target], nodes, source)) {
        return;
      }
      clickToCreateVariable();
      return;
    }

    // 没有任何链接
    if (func.isEmpty(line)) {
      createLineByTwoNode({ source, target });
      return;
    }

    // target已有线链接 此时只需要直接链接到边上
    repeat = !!edges.some(x => x.target === (line as Edge).source && x.source === source);
    if (line && repeat === false) {
      const targetNode = nodes.find(x => x.id === line.source);
      // 边内容为空 || 边上是自定义function, 不连接
      if (
        func.isEmpty(targetNode?.data?.lineParam?.enterText) ||
        FUNCTION_REG.test(targetNode?.data?.lineParam?.enterText)
      )
        return;
      manuallyConnectedEdges.current = { ...param, target: line.source };
      clickToCreateVariable();
    }
  }

  return {
    handleManualLine,
    setNodeLabel,
  };
};
