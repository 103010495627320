import { useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { PreviewAppUtil } from "@uiview/views/PreviewApp/PreviewAppUtil";
import { logEvent } from "@uikit/service/amplitude";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

const creatorNodesStore = getIt(CreatorNodesStore);

const TEXT_ADD = "Publish as Content";
const TEXT_DELETE = "Remove from preview";
const TEXT_GROUP_ADD = "Add group to preview";
const TEXT_GROUP_DELETE = "Remove Group from preview";
const CLASSNAME_ADD = "btn-add-to-app";
const CLASSNAME_ADD_ICON = "btn-add-icon";
const CLASSNAME_DELETE = "btn-delete-from-app";
const groupReg = /^group/;
const creatorSaasAppStore = getIt(CreatorSaasAppStore);

export const useNodePreview = ({ id, handlePreviewDeleteNode, clickToPreviewNode }) => {
  const isGroupNode = groupReg.test(id);
  const previewAppStore = getIt(PreviewAppStore);

  const [hasAdded, setHasAdded] = useState(false);
  const [nodeClassName, setNodeClassName] = useState(CLASSNAME_ADD);
  const [nodeAddIconClassName] = useState(CLASSNAME_ADD_ICON);
  const [btnText, setBtnText] = useState(isGroupNode ? TEXT_GROUP_ADD : TEXT_ADD);

  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  useEffect(() => {
    setHasAdded(PreviewAppUtil.hasAddedOutputByNode(id, saasUIData.output));
  }, [id, isGroupNode, saasUIData.output.length]);

  useEffect(() => {
    setNodeClassName(hasAdded ? CLASSNAME_DELETE : CLASSNAME_ADD);
    if (isGroupNode) {
      setBtnText(hasAdded ? TEXT_GROUP_DELETE : TEXT_GROUP_ADD);
    } else {
      setBtnText(hasAdded ? TEXT_DELETE : TEXT_ADD);
    }
  }, [hasAdded, isGroupNode]);

  const handlePreview = useCallback(() => {
    const nodes = creatorNodesStore.getNodes();
    const node = nodes.find(item => item.id === id);
    // 子node
    if (hasAdded) {
      // 如果是v2版本且不显示preview page页面时，如果已经有了，就不删除，不添加
      if (previewStore.state.previewAppList.length === 0 || previewAppStore.state.previewShowStatus) {
        // 删除
        handlePreviewDeleteNode(id);
      }
    } else {
      // 新增
      clickToPreviewNode(node, undefined, undefined);
      logEvent("publish_preview_add_content", { node: node });
    }
  }, [hasAdded, id, handlePreviewDeleteNode, clickToPreviewNode]);

  return {
    nodeClassName,
    nodeAddIconClassName,
    hasAdded,
    btnText,
    handlePreview,
  };
};

export const useIsStaticApp = () => {
  const [isStaticApp, setIsStaticApp] = useState();

  const creatorPreviewState = useImagicaStore(previewStore).value;
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  useEffect(() => {
    setIsStaticApp(PreviewAppValueLangUtil.isStaticApp(saasUIData));
  }, [creatorPreviewState.selectedUI, saasUIData]);

  return { isStaticApp };
};

export const useStartNode = id => {
  const { isStaticApp } = useIsStaticApp();
  const [isStartNode, setIsStartNode] = useState();
  const [isStartNodeAdded, setIsStartNodeAdded] = useState();
  const inputId = useSelector(state => state.fot.inputId);
  const startNodeIds = useSelector(state => state.fot.startNodeIds);

  useEffect(() => {
    if (isStaticApp) {
      setIsStartNode(false);
      setIsStartNodeAdded(false);
    } else {
      const isStart = startNodeIds.includes(id);
      setIsStartNode(isStart);
      setIsStartNodeAdded(inputId.includes(id));
    }
  }, [inputId, id, startNodeIds, isStaticApp]);

  return {
    isStartNode,
    isStartNodeAdded,
  };
};
