"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Analytics = void 0;
class Analytics {
    // event 是自定义的事件名称，properties 是事件的属性
    track(event, properties) {
        return Promise.resolve();
    }
    // event 是 fot/src/uikit/service/events.js 定义的事件名称，properties 是事件的属性
    logEvent(event, properties) {
        return Promise.resolve();
    }
}
exports.Analytics = Analytics;
