import { PowerChildProps } from "./PowerMaskViewBloc";
import styles from "./PowerMaskView.module.scss";

export function PowerMaskSmall(props: PowerChildProps) {
  return (
    <div className={styles.mask_view}>
      {/* <div className={styles.small_vip_view}>
        <span>{props.minimumPlanObj.value?.name?.text ?? props.defaultWallName}</span>
      </div> */}
      <div className={styles.mask_view_content}>
        <div className={styles.small_button} onClick={props.onClick}>
          <span>Upgrade</span>
        </div>
      </div>
    </div>
  );
}
