import { useSignal } from "@preact/signals-react";
import { Spin } from "antd";
import { Blueprint2 } from "imagica-corekit/dist/base/api/blueprintTyped/BlueprintV2";
import styles from "./Button.module.scss";
import { Popup } from "../Popup";
import { EditModal } from "./EditModal";

type EditButtonProps = {
  blueprint?: Blueprint2;
  updateFuncGroup: () => void;
};

export function EditButton(props: EditButtonProps) {
  const loading = useSignal(false);

  const handleClick = async () => {
    const popup = new Popup(EditModal);
    popup.open();
  };

  return (
    <div className={styles.btnWrapper}>
      <Spin spinning={loading.value}>
        <div className={styles.editButton} onClick={() => handleClick()}>
          Edit Blueprint
        </div>
      </Spin>
    </div>
  );
}
