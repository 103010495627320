"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETheme = void 0;
const DarkTheme_1 = require("./DarkTheme");
class ETheme {
}
exports.ETheme = ETheme;
ETheme.theme = {
    chatAiTheme: {
        ...DarkTheme_1.DarkTheme.theme.chatAiTheme,
        chatBackground: "linear-gradient(180deg, #203481 0%, #2654E8 100%)",
        chatBackgroundPad: "linear-gradient(180deg, #203481 0%, #2654E8 100%)",
        chatBackgroundMobile: "linear-gradient(180deg, #203481 0%, #2654E8 100%)",
    },
    appSignUpButtonTheme: {
        ...DarkTheme_1.DarkTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBarBorderColor: "transparent",
        hightLightBorderLeft: "0",
        inputPaddingLeft: "25px",
        inputPadding: "15px 25px",
        inputBorder: "0",
        inputBorderBottom: "1px solid rgba(255, 255, 255, 0.10)",
        buttonBackground: "transparent",
        buttonColor: "#fff",
        buttonBorder: " 2px solid #fff",
        toolTipBackground: "linear-gradient(94deg, #0090F9 0%, #00A0C2 100%)",
        hintTextColor: "#fff",
        tooltipBoxColor: "#fff",
        valueColor: "#fff",
        addContentColor: "#fff",
        seeFormatDescriptionColor: "#fff",
    },
};
