import func from "@uikit/func";
import { CanvasNodeData } from "@uikit/store/CreatorNodesStore";

/**
 * 该 class 是原来 `imagica\fot\src\views\thinking-layout-editor\utils\index.js` 中包含对 edge run 数据解析或处理的部分方法
 */
export class EdgeRunResultParseUtil {
  static splitText(textAreaValue = "", paramText?: string): string[] {
    let text = (textAreaValue || paramText || "").trim();
    if (func.isEmpty(text)) {
      func.messageUtil("The segmented text is empty.");
      return [];
    }
    const reg = /\n{2,}/;
    const numReg = /\n+\d*\.*-*:*\s*/;
    const onlyNumReg = /^\n+\d*\.*-*:*\s*$/;
    //两个换行符切分
    const twoBlankLineArr = (text.split(reg) || []).filter(x => !func.isEmpty(x));
    if (twoBlankLineArr.length > 1) return twoBlankLineArr;
    //以数字开头的切分
    text = text.replace(/^\d*\.*-*:*\s*/, "");
    const numSplitArr = (text.split(numReg) || []).filter(x => !func.isEmpty(x?.trim()) && !onlyNumReg.test(x?.trim()));
    if (numSplitArr.length > 1) return numSplitArr;
    return [text];
  }

  static isContentHtml(value: string): RegExpMatchArray | null {
    const code = value.replace("\n/g", "").trim();
    return (
      code.match(/^<!DOCTYPE html>/) ||
      code.match(/^<!doctype html>/) ||
      code.match(/^<html>/) ||
      code.match(/^<body>/) ||
      code.match(/^</)
    );
  }

  static getAutomaticDisplayType(data: CanvasNodeData, tempText: string): string {
    const textTypes = ["inputTextarea", "text"];
    let displayType = data.displayType && textTypes.includes(data.displayType) ? data.displayType : "text";

    if (displayType !== "htmlTemplate" && EdgeRunResultParseUtil.isContentHtml(tempText)) {
      displayType = "htmlTemplate";
    }
    return displayType;
  }

  static loadAvailableImg(
    images: any[],
    imgKey: string
  ): Promise<{
    loading: boolean;
    value: string;
  }> {
    return new Promise(resolve => {
      if (func.isEmpty(images)) {
        resolve({
          loading: false,
          value: "", // todo:这里应该是一张占位图
        });
        return;
      }
      const img = new Image();
      let imgIdx = 0;
      img.onload = (): void => {
        resolve({
          loading: false,
          value: img.src,
        });
      };
      img.onerror = (): void => {
        if (imgIdx >= images.length) {
          resolve({
            loading: false,
            value: "", // todo:这里应该是一张占位图
          });
          return;
        }
        imgIdx++;
        img.src = images[imgIdx]?.[imgKey] || "";
      };
      img.src = images[imgIdx]?.[imgKey] || "";
    });
  }
}
