import { FunctionModel } from "@views/thinking-layout-editor/types";
import { RelationSetDest, RelationSetDestAttribute } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { isEmpty } from "lodash";

export class ProjectFunctionUtil {
  static relationSetDestToFunctionModel<T extends RelationSetDestAttribute>(
    fun: T,
    each: RelationSetDest & { author_user_id?: string },
    allPreDefinedFunctions: any[] = []
  ): FunctionModel {
    const edgeArr = (fun?.edgeArr || []) as any[];
    const nodeArr = (fun?.nodeArr || []) as any[];

    let groupType = fun?.type || "other";

    // 获取终点 edge 数据
    const targetEdgeData = edgeArr.filter((el, index, self) => {
      return !self.some(e => e.source === el.target);
    });
    // 获取终点 node 显示类型
    if (!isEmpty(fun.nodeArr)) {
      groupType = nodeArr.find(x => x.id === targetEdgeData[0]?.target)?.data?.displayType || "";
    }

    let groupTitle = "";
    // title
    // hardcoded title type mapping to function name
    const preDefinedFunctionConfig = allPreDefinedFunctions.find(x => x.label === fun.name) as any;
    if (preDefinedFunctionConfig?.groupTitle) {
      groupTitle = preDefinedFunctionConfig.groupTitle;
    } else if (each.author_user_id) {
      // marketplace (other global)
      groupTitle = "marketPlace";
    } else {
      // my functions
      groupTitle = "myFunctions";
    }

    // order
    const order = [null, undefined].includes(preDefinedFunctionConfig?.order)
      ? Infinity
      : preDefinedFunctionConfig.order;

    // 兼容数据
    const obgData = {
      CustomCode: "customJsFunction",
      customApi: "customApiFunction",
      customApiFunc: "customApiFunction",
    } as any;
    if (obgData[groupType]) {
      groupType = obgData[groupType];
    }

    return {
      id: each.id,
      author: fun.author || "",
      // FIXME: type error
      editable: !isEmpty((each as any).user),
      codeData: fun?.codeData || {},
      apiData: fun?.apiData || {},
      type: fun?.type || "",
      // FIXME: type error
      relationId: (each as any).relationId,
      isCreatedManual: true,
      isV3Function: fun.isV3Function || false,
      value: fun.name || fun.function_name,
      label: fun.name || fun.function_name || "",
      description: fun.description,
      projectName: fun.projectName,
      edgeArr: JSON.stringify(edgeArr),
      nodeArr: JSON.stringify(nodeArr),
      groupType,
      groupTitle,
      order,
      hide: preDefinedFunctionConfig?.hide,
      updatedAt: each.updatedAt,
      createdAt: each.createdAt,
      originElement: each,
    } as any;
  }
}
