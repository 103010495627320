"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorekitRegistry = void 0;
const RegisterFunction_1 = require("./RegisterFunction");
const RegisterAdapter_1 = require("./RegisterAdapter");
const RegisterBasic_1 = require("./RegisterBasic");
const RegisterCot_1 = require("./RegisterCot");
const RegisterPreview_1 = require("./RegisterPreview");
const RegisterBlueprint_1 = require("./RegisterBlueprint");
const RegisterResponseHandler_1 = require("./RegisterResponseHandler");
const RegisterSave_1 = require("./RegisterSave");
const RegistryDataSerivce_1 = require("./RegistryDataSerivce");
class CorekitRegistry {
    static configure(container, params) {
        RegisterBasic_1.RegisterBasic.configure(container, params);
        RegisterFunction_1.RegisterFunction.configure(container);
        RegisterAdapter_1.RegisterAdapter.configure(container);
        RegisterResponseHandler_1.RegisterResponseHandler.configure(container);
        RegisterCot_1.RegisterCot.configure(container);
        RegisterPreview_1.RegisterPreview.configure(container);
        RegisterBlueprint_1.RegisterBlueprint.configure(container);
        RegisterSave_1.RegisterSave.configure(container);
        RegistryDataSerivce_1.RegisterDataService.configure(container);
    }
}
exports.CorekitRegistry = CorekitRegistry;
