import { Signal, useSignal } from "@preact/signals-react";
import { Timeout } from "ahooks/lib/useRequest/src/types";
import React, { CSSProperties, MouseEventHandler, ReactNode, useRef } from "react";

type LongPressProps = {
  className?: string | undefined;
  onClick?: MouseEventHandler<Element> | undefined;
  onLongPress?: () => void;
  children?: ReactNode;
  disable: boolean;
};

export const LongPressDiv = React.forwardRef<HTMLDivElement, LongPressProps>((props, ref): JSX.Element => {
  const isMove = useSignal(false);
  const timerRef: React.MutableRefObject<Timeout | undefined> = useRef();

  const longPressDuration = 600; // 长按时间阈值（毫秒）

  const style: Signal<CSSProperties> = useSignal({});

  function handleMouseDown(): void {
    if (props.disable) {
      return;
    }
    if (isMove.value) {
      return;
    }
    style.value = { transform: "scale(0.9)", transition: "transform 0.25s" };
    // 当鼠标按下时，设置isPressed为true并启动计时器
    timerRef.current = setTimeout(() => {
      // 如果鼠标持续按下超过longPressDuration，则触发长按事件
      if (!isMove.value) {
        // 你可以在这里添加额外的逻辑，例如显示提示信息、调用函数等
        if ("vibrate" in navigator) {
          navigator.vibrate(300);
        } else {
          console.log("不支持");
          // alert("不支持")
        }
        style.value = { transition: "transform 0.1s" };
        props.onLongPress?.();
        setTimeout(() => {
          style.value = {};
        }, 100);
      }
    }, longPressDuration);
  }

  function handleMouseUp(): void {
    style.value = { transition: "transform 0.1s" };
    clearTimeout(timerRef.current);
    isMove.value = false;
    setTimeout(() => {
      style.value = {};
    }, 100);
  }

  return (
    <div
      ref={ref}
      className={props.className}
      style={style.value}
      onClick={props.onClick}
      onTouchStart={() => {
        handleMouseDown();
      }}
      onTouchMove={() => {
        isMove.value = true;
        style.value = {};
      }}
      onTouchEnd={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {props.children}
    </div>
  );
});
