import { Signal, signal } from "@preact/signals-react";
import { useCreation } from "ahooks";
import { DependencyList } from "react";

export class SetSignal<T> {
  private lastValue: Signal<T | undefined> = signal(undefined);

  constructor(
    newValue: T,
    public enableSet: (newData?: T, lastData?: T) => boolean = () => {
      return true;
    },
    public fixData: (newData?: T, lastData?: T) => T | undefined = (newData, _) => {
      return newData;
    }
  ) {
    this.lastValue.value = newValue;
  }

  get value(): T | undefined {
    return this.lastValue.value;
  }

  set value(value: T | undefined) {
    const callBack = this.enableSet(value, this.lastValue.value);
    if (callBack) {
      const realData = this.fixData(value, this.lastValue.value);
      this.lastValue.value = realData;
    }
  }
}

export function useSetSignal<T>(
  value: T,
  enableSet?: (newValue?: T, lastValue?: T) => boolean,
  fixData?: (newData?: T, lastData?: T) => T | undefined,
  deps?: DependencyList
): SetSignal<T> {
  return useCreation(() => {
    return new SetSignal<T>(value, enableSet, fixData);
  }, deps ?? []);
}
