export class FocusConsts {
  /**
   * reactflow fitBounds,setCenter 等动画执行时间
   */
  static FLOW_DURTAOIN = 200;
  /**
   * reactflow fitBounds padding 默认 0.1
   */
  static FLOW_FIT_PADDING = 0.1;

  static FLOW_ZOOM = 1;
}
