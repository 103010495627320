import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";
import { ShareAppService } from "@uikit/service/ShareAppService";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { SharePreviewStore, sharePreviewStore } from "@uiview/views/AISaas/SharePreviewStore";
import { PreviewPaneStore } from "@uiview/views/PreviewPane/PreviewPaneStore";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";
import { Container } from "inversify";

/**
 * 该 class 用于注册预览或分享页面的逻辑实例
 *
 */
export class RegisterPreviewShare {
  static configure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };

    const previewAppStore = new PreviewAppStore(
      it(FotReduxStore),
      it(CreatorSaasAppStore),
      previewStore,
      it(ChatOnboardingStore),
      creatorRefStore,
      it(CreatorNodesStore),
      it(PreviewPaneStore),
      it(ViewbuilderStore),
      it(CreatorAISaasStore),
      it(HomeStore),
      it(ProjectNodeStore)
    );

    const previewComposeState = new PreviewComposeState(
      it(CreatorNodesStore),
      previewAppStore,
      it(CreatorSaasAppStore),
      it(HomeStore)
    );

    const creatorPreviewService = new CreatorPreviewService(
      it(FotReduxStore),
      it(CreatorSaasAppStore),
      it(HomePluginStore),
      it(CreatorNodesStore),
      previewAppStore,
      it(ProjectNodeStore),
      projectPageStore,
      previewStore
    );

    const shareAppService = new ShareAppService(
      creatorPreviewService,
      it(CreatorNodesStore),
      it(CreatorSaasAppStore),
      it(HomePluginStore)
    );

    container.bind(SharePreviewStore).toConstantValue(sharePreviewStore);
    container.bind(PreviewAppStore).toConstantValue(previewAppStore);
    container.bind(PreviewComposeState).toConstantValue(previewComposeState);
    container.bind(CreatorPreviewService).toConstantValue(creatorPreviewService);
    container.bind(ShareAppService).toConstantValue(shareAppService);
  }
}
