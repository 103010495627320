import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Result } from "antd";
import style from "../components-css/Retry.module.css";
import { feedActions } from "../../store/feed";

export default function Retry(props) {
  const dispatch = useDispatch();

  const thoughtChain = useSelector(state => state.fot.thoughtChain);

  const thoughts = useSelector(state => state.feed.thoughts);
  const setThoughts = val => {
    dispatch(feedActions.setThoughts(val));
  };

  const hasGoBack = () => {
    return typeof props.enterQuery === "function" && thoughtChain.length > 1;
  };

  const hasRetry = () => {
    return typeof props.ContentRetrieval === "function" && props.content.from;
  };

  const handleRetry = () => {
    if (!hasRetry()) return;
    props.ContentRetrieval(props.content.from);
    // add a loading dummy and remove this component
    const thoughtsCopy = [...thoughts].filter(x => !x.showRetryView);
    setThoughts(thoughtsCopy.concat({ loading: true }));
  };

  const handleGoBack = () => {
    if (!hasGoBack()) return;
    const prev = thoughtChain[thoughtChain.length - 2];
    props.enterQuery(prev.thought, "fromChainThought");
  };

  const handleClickCard = e => {
    e.stopPropagation();
  };

  return (
    <div className={style["Retry"]} onClick={handleClickCard}>
      <Result
        title="Unable to get results at this time."
        extra={
          <div className={style["Right-Btns"]}>
            {hasRetry() ? (
              <div className={style["Post-Btn"]} onClick={handleRetry}>
                Retry
              </div>
            ) : null}
            {hasGoBack() ? (
              <div className={style["Post-Btn"]} onClick={handleGoBack}>
                Go Back
              </div>
            ) : null}
          </div>
        }
      />
    </div>
  );
}
