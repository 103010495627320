"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageGenResponseHandler = void 0;
const ResponseHandler_1 = require("../../../storyV2/response/handler/ResponseHandler");
class ImageGenResponseHandler extends ResponseHandler_1.ResponseHandler {
    async handleFuncDataSource(query, res) {
        // console.log("ImageGenResponseHandler data: ", res);
        return new ResponseHandler_1.TransformResponse(res.value, res.isEnded);
    }
}
exports.ImageGenResponseHandler = ImageGenResponseHandler;
