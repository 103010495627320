import { Popover } from "antd";
import classNames from "classnames";
import { DefaultProps } from "../../types";
import { callback } from "../../utilities";
import { FC, ReactNode } from "react";
import style from "./style.module.scss";

export interface TooltipProps extends DefaultProps {
  title?: ReactNode;
  content?: ReactNode;
  onOpenChange?: (visible: boolean) => void;
  className?: string;
}

const Tooltip: FC<TooltipProps> = ({ children, title = null, content = null, onOpenChange = callback, className }) => {
  return (
    <Popover
      getPopupContainer={e => e}
      overlayClassName={classNames(style.tooltipWrap, className)}
      title={title}
      content={content}
      trigger="hover"
      placement="top"
      onOpenChange={onOpenChange}
    >
      <>{children}</>
    </Popover>
  );
};

export default Tooltip;
