import { Signal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { CotFlow } from "imagica-corekit/dist/cases/cot/CotFlow";
import { findLastIndex } from "lodash";
import {
  BuildingBloc,
  ConfirmBloc,
  IdeationChatBloc,
  PromptInfos,
  PrototypingChatBloc,
  PrototypingSortableComponent,
  TitleChatBloc,
} from "./ChatBloc";
import { Constants, Role } from "./Constants";
import { JsonItem, Message, OnboardingBloc, OnboardingState } from "./OnboardingBloc";
import { ProtypingComponent, ProtypingMessage } from "./types";

export enum GenUIProcess {
  Ideation,
  Prototype,
  Build,
}

class GenUIState extends OnboardingState {
  public messages = new Signal<Message[]>([]);
  public currentStep = new Signal(GenUIProcess.Ideation);
  public submitValue = new Signal("");
  public answerIndex = new Signal(0);
  public buildEnd = new Signal(false);
  public collapsed = new Signal(false);
  public showPreview = new Signal(true);
  public selectedComponent = new Signal<PrototypingSortableComponent | null>(null);
  public isCallerBotBloc = new Signal(false);
  public process = new Signal(["Ideation", "Prototype", "Build"]);
  public outputs = new Signal<ProtypingComponent[]>([]);
  public graph = new Signal<string>();
}

export type ComponentKey = keyof typeof Constants.componentConfig;
type ShowFlowFn = (flowData: ReturnType<CotFlow["getTemplate"]>) => void;

const selectReg = /\[([^>]*)\]\:/g;

export class GenUIBloc extends OnboardingBloc {
  homePluginStore = getIt(HomePluginStore);
  brainClient = getIt(BrainClient);
  cotFlow = getIt(CotFlow);

  private showFlow;
  constructor(showFlow: ShowFlowFn) {
    super();
    this.showFlow = showFlow;
  }

  state = new GenUIState();

  public ideationBloc = new IdeationChatBloc(PromptInfos.ideation);
  public prototypingBloc = new PrototypingChatBloc(PromptInfos.prototyping);
  public buildBloc = new BuildingBloc(PromptInfos.building);
  public titleBloc = new TitleChatBloc(PromptInfos.title);
  public confirmBloc = new ConfirmBloc(PromptInfos.confirmation);

  setSubmitValue(value: string) {
    this.state.submitValue.value = value;
  }

  updateMessage(latestMsg: string, role: Role): void {
    this.state.messages.value = [
      ...this.state.messages.value,
      {
        content: latestMsg,
        role: role as unknown as string,
      },
    ];
  }

  private addOrUpdateAssistantMsg(msg: string): void {
    const index = findLastIndex(this.state.messages.value);
    if ((this.state.messages.value[index]?.role as unknown as Role) === Role.user) {
      return this.updateMessage(msg, Role.assistant);
    }
    if (this.state.messages.value[index].content === msg) {
      return;
    }
    const newMsg = [...this.state.messages.value];
    newMsg[index].content = msg;
    this.state.messages.value = newMsg;
  }

  private async generateTitle() {
    await this.titleBloc.transformAssistantMessage(this.ideationBloc.ideation.value.one_line_description);
    this.state.title.value = this.titleBloc.title.value.title;
  }

  async onPressEnter() {
    if (func.isEmpty(this.state.submitValue.value)) {
      func.customMsg({
        content: "Message cannot be empty",
        type: "warning",
      });
      return;
    }
    if (this.state.submitLoading.value) return;

    const submitValue = this.state.submitValue.value;

    if (
      submitValue.length &&
      isBlank(this.state.selectedComponent.value) === false &&
      submitValue.match(selectReg)?.length
    ) {
      this.prototypingComponent();
      return;
    }

    this.state.submitValue.value = "";

    this.state.submitLoading.value = true;

    this.updateMessage(submitValue, Role.user);
    await this.chooseToNextStep(submitValue);

    if (this.state.currentStep.value === GenUIProcess.Ideation) {
      await this.ideation();
    }

    if (this.state.currentStep.value === GenUIProcess.Prototype) {
      await this.prototyping();
    }

    if (this.state.currentStep.value === GenUIProcess.Build) {
      await this.building();
    }

    this.state.submitLoading.value = false;
  }

  async chooseToNextStep(message: string) {
    if (this.ideationBloc.ideation.value.app_generated === true || this.prototypingBloc.components.value.length !== 0) {
      const result = await this.confirmBloc.transformAssistantMessage(message);
      if (result.confirmation) {
        this.state.currentStep.value = this.state.currentStep.value + 1;
      }
    }
  }

  async ideation() {
    this.state.submitLoading.value = true;
    this.updateMessage("", Role.assistant);
    await this.ideationBloc.transformAssistantMessage(this.getLastMessage(Role.user), msg => {
      const value = this.jsonItemRegex(JsonItem.current_response, msg);

      if (value) {
        this.addOrUpdateAssistantMsg(value);
      }
    });
    this.modifyLastMessage("suggestion", this.ideationBloc.ideation.value.suggested_response);
    this.state.submitLoading.value = false;
  }

  async prototypingComponent() {
    this.state.submitLoading.value = true;
    const submitValue = this.state.submitValue.value;

    const content = submitValue.replace(selectReg, "");

    this.updateMessage(submitValue, Role.user);
    this.updateMessage("Prototyping...", Role.assistant);
    this.state.submitValue.value = "";

    await this.prototypingBloc.transformAssistantMessage(`
      ${JsonUtil.stringify(this.state.selectedComponent.value)}
      ${content}
    `);

    this.addOrUpdateAssistantMsg("I've completed your prototype! What do you think?");
    this.state.selectedComponent.value = null;
    this.state.submitLoading.value = false;
  }

  async prototyping() {
    this.state.submitLoading.value = true;
    this.state.currentStep.value = GenUIProcess.Prototype;
    this.state.showPreview.value = true;

    const value =
      this.prototypingBloc.components.value.length === 0
        ? this.ideationBloc.ideation.value.one_line_description
        : this.getLastMessage(Role.user);

    if (isBlank(this.state.title.value)) {
      this.generateTitle();
    }

    this.updateMessage("Prototyping...", Role.assistant);
    await this.prototypingBloc.transformAssistantMessage(value);

    this.addOrUpdateAssistantMsg("I've completed your prototype! What do you think?");
    this.state.submitLoading.value = false;
  }

  async building() {
    this.state.submitLoading.value = true;
    this.state.currentStep.value = GenUIProcess.Build;
    this.state.collapsed.value = true;
    this.state.buildEnd.value = true;

    const params = `Ideas: ["${this.ideationBloc.ideation.value.one_line_description}"]
    Interface: ${JSON.stringify(this.prototypingBloc.components, null, 4)}
    `;
    console.log(params);

    this.updateMessage("Building...", Role.assistant);
    await this.buildBloc.transformAssistantMessage(params);

    const result = await tryPromise(
      this.brainClient.openAi.buildGraphV2({
        graph: this.buildBloc.graph.value,
        components: this.prototypingBloc.components.value,
      })
    );

    this.addOrUpdateAssistantMsg("I've completed your building");

    console.log(result.data?.data);

    if (result.data) {
      this.showFlow(result.data.data as any);
    }

    if (result.error) {
      console.error(result.error);
    }
    this.state.submitLoading.value = false;
  }

  handleSelected(component: PrototypingSortableComponent) {
    const name = component.name.replace(/<|>/g, "");
    const old = this.state.selectedComponent.value;
    if (old && old.id === component.id) {
      this.state.selectedComponent.value = null;
      this.state.submitValue.value = "";
      return;
    }
    this.state.selectedComponent.value = component;
    this.state.submitValue.value = `[${name}]: `;
  }

  closeCallerBot() {
    this.homePluginStore.setShowCallerBot(false);
  }

  closePreview() {
    this.state.showPreview.value = false;
  }

  handleClickCollapse() {
    this.state.collapsed.value = !this.state.collapsed.value;
  }

  updateOutput(message: Message) {
    const result = JsonUtil.toModelFromType(ProtypingMessage, message.content);
    if (result) {
      this.state.outputs.value = result.components.map(item => ({
        ...item,
        key: item.name.replace(/<|>/g, "") as ComponentKey,
      }));
    }
  }

  clear() {
    if (this.state.outputs.value.length === 0) return;
    this.state.outputs.value = [];
  }
}
