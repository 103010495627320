import { useContext } from "react";
import { AModalContext, AModalContextType } from "./AModalContext";

export const useAModalProvider = (): AModalContextType => {
  const context = useContext(AModalContext);

  if (!context) {
    throw new Error("useAModalProvider must be used within a AModalContext");
  }

  return context;
};
