import { logEvent } from "@amplitude/analytics-browser";
import { CreatorCanvasFocus } from "@uikit/cases/canvasFocus/CreatorCanvasFocus";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CanvasEdgeUtil } from "@uikit/util/CanvasEdgeUtil";
import { CanvasLineUtil } from "@uikit/util/CanvasLineUtil";
import { DataRefUtil } from "@uikit/util/DataRefUtil";
import { FocusNodeUtil } from "@uikit/util/FocusNodeUtil";
import { PreviewStore } from "@uiview/store/PreviewStore";
import { CreatorStoreMethods } from "./CreatorStoreMethods";
import utilIndex from "@views/thinking-layout-editor/utils";

export class AddHandle {
  constructor(
    private creatorRefStore: CreatorRefStore,
    private canvasDataRef: CanvasDataRef,
    private creatorNodesStore: CreatorNodesStore,
    private creatorStoreMethods: CreatorStoreMethods,
    private creatorCanvasFocus: CreatorCanvasFocus,
    private previewStore: PreviewStore
  ) {}
  clickAddBtn(
    direction: string,
    otherProps: { width: number; xPos: any; yPos: any; autoSelectFunc: string; height: any; id: any },
    setCenter: any,
    displayType: string
  ): void {
    if (this.creatorRefStore.canvasMovingLoadingRef.current) return;
    this.creatorRefStore.canvasMovingLoadingRef.current = true;

    const isStickie = displayType === "inputTextarea";
    let offsetDistance = !isStickie ? 370 : 270;
    offsetDistance += otherProps.width;
    const startPointValue = 300;
    const newAddEdge = CanvasEdgeUtil.createNewEdge(otherProps);
    const newNodeId = `editor-${this.creatorRefStore.nodeIndexRef.current++}`;
    const needMoveNode = this.creatorNodesStore
      .getNodes()
      .filter((x: { id: string }) => !CreatorNodesConsts.NEW_EDGE_REG.test(x.id));

    //获取最下方或者最右方的node位置
    const adjacentNodepPosition = utilIndex.getBottomOrRightPosition(needMoveNode, direction, offsetDistance, {
      x: otherProps.xPos,
      y: otherProps.yPos,
    });

    //  通过AIChat模板创建的chatInterface，需要show tooltip
    const isChatAiOfNode = otherProps.autoSelectFunc === "chatInterface" ? { showChatInterfaceTooltip: true } : {};
    const newNode = {
      id: newNodeId,
      type: "customNode",
      data: Object.assign(
        {},
        { ...this.canvasDataRef.nodeDataRef.current },
        { displayType: isStickie ? "inputTextarea" : "text" },
        { showFeedback: false },
        { ...isChatAiOfNode }
      ),
      position: {
        x: otherProps.xPos,
        y: otherProps.yPos,
      },
    };
    //新node按照已有的输出node位置调整
    if (direction === "left" || direction === "right") {
      newNode.position.y = adjacentNodepPosition;
    } else {
      newNode.position.x = adjacentNodepPosition;
    }

    // 计算中心点
    const sourceCenterPos = {
      width: otherProps.width,
      height: otherProps.height,
      position: {
        x: otherProps.xPos,
        y: otherProps.yPos,
      },
    };
    // 620 是动画完成后真实的位置
    const targetCenterPos = {
      width: otherProps.width,
      height: otherProps.height,
      position: {
        x: newNode.position.x + 620,
        y: newNode.position.y,
      },
    };
    const centerData = FocusNodeUtil.focusPosition(
      sourceCenterPos as any,
      targetCenterPos as any,
      this.creatorNodesStore.state.nodes
    );
    // 创建边时边伸出的动画和边居中的动画需要同时进行
    if (centerData) {
      // FIXME: 创建边伸出去的动画和边聚焦的动画坐标计算， x 有 52 左右的偏差, 导致两个动画都被执行
      this.creatorCanvasFocus
        .focus(centerData, {
          duration: 800,
          zoom: 1,
          // FIXME: clickAddBtn 可能被缓存，导致 setCenter 和 showPreviewPanel 状态无法更新
          setCenter,
          showPreviewPanel: this.previewStore.state.showPreviewPanel,
        })
        .finally(() => {
          this.creatorRefStore.canvasMovingLoadingRef.current = false;
        });
    } else {
      this.creatorRefStore.canvasMovingLoadingRef.current = false;
    }

    // 从300开始缓慢移动
    if (direction === "top") {
      newNode.position.y -= startPointValue;
    } else if (direction === "right") {
      newNode.position.x += startPointValue;
    } else if (direction === "bottom") {
      newNode.position.y += startPointValue;
    } else if (direction === "left") {
      newNode.position.x -= startPointValue;
    }

    this.creatorNodesStore.handleUpdateTarget({
      newNode,
      edgeNode: newAddEdge,
    });
    const sourceLine = CanvasLineUtil.createNewLine({
      source: otherProps.id,
      target: newAddEdge.id,
      type: "source",
    });
    const targetLine = CanvasLineUtil.createNewLine({
      sourceNode: otherProps,
      source: newAddEdge.id,
      target: newNode.id,
      type: "target",
    });
    // 移动节点
    this.creatorStoreMethods.translateNode(newNode, direction, offsetDistance - startPointValue, 10);
    logEvent("create_edge");
    // 这里需要重新获取最新的node, 因为flows及id等数据存在更新
    const updatedNewNode = this.creatorNodesStore.getNodes().find((x: { id: string }) => x.id === newNodeId);
    const updatedNewEdge = this.creatorNodesStore.getNodes().find((x: { id: string }) => x.id === newAddEdge.id);
    this.creatorStoreMethods.onNodesEdgesAdd(
      [this.canvasDataRef.getSimpleNodeV3(updatedNewNode), this.canvasDataRef.getSimpleNodeV3(updatedNewEdge)],
      [
        DataRefUtil.getSimpleEdgeV3(sourceLine, this.canvasDataRef.newLineDataRef),
        DataRefUtil.getSimpleEdgeV3(targetLine, this.canvasDataRef.newLineDataRef),
      ]
    );
  }
}
