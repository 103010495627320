"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Eventsource = void 0;
const Emitter_1 = require("./Emitter");
class Eventsource {
    constructor(initState) {
        this.emitter = new Emitter_1.Emitter();
        this.eventName = "event";
        this.latestState = initState();
    }
    get state() {
        return this.latestState;
    }
    // sync：
    // false 以异步的方法执行 callback
    // true 以同步的方法执行 callback
    on(callback, sync = false) {
        const subscription = this.emitter.on(this.eventName, callback, sync);
        return {
            off() {
                subscription.off();
            },
        };
    }
    // callback 为空时，取消所有订阅
    off(callback) {
        this.emitter.off(this.eventName, callback);
    }
    emit(value) {
        this.latestState = value;
        this.emitter.emit(this.eventName, value);
    }
}
exports.Eventsource = Eventsource;
