import { Signal } from "@preact/signals-react";
import { chain } from "lodash";
import { Role } from "./Constants";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { getIt } from "@uikit/getIt";

export class OnboardingState {
  title = new Signal("");
  submitLoading = new Signal(false);
  showPreview = new Signal(true);
}

export enum JsonItem {
  choices = "choices",
  response = "response",
  current_response = "current_response",
  narration = "narration",
  suggested_responses = "suggested_responses",
  show_interface = "show_interface",
  check_function = "check_function",
}

export class OnboardingBloc {
  state = new OnboardingState();
  chatOnboardingStore = getIt(ChatOnboardingStore);

  public jsonItemRegex(jsonItem: JsonItem, value: string): string | null {
    const regexPattern = new RegExp(`"${jsonItem}": "(.*?)"`);
    const matched = `${value}"`.match(regexPattern);

    // 必须匹配解析后才返回正确值，否则返回""
    if (matched) {
      const replaceLineMatched = matched[1].replace(/\\n/g, "\n").replace(/\\/g, "");
      return replaceLineMatched;
    } else {
      return "";
    }
  }

  public jsonBooleanRegex(jsonItem: JsonItem, value: string): boolean | null {
    const regexPattern = new RegExp(`"${jsonItem}": (.*),`);
    const matched = `${value}"`.match(regexPattern);
    // 必须匹配解析后才返回正确值，否则返回""
    if (matched) {
      return matched[1] === "true";
    } else {
      return false;
    }
  }

  public jsonArrayRegex(jsonItem: JsonItem, value: string): string | null {
    const regexPattern = new RegExp(`"${jsonItem}"\\s*:\\s*\\[([^\\]]*)\\]`);
    const matched = value.match(regexPattern)?.[1];
    return matched || null;
  }

  public getLastMessage(role?: Role): string {
    return chain(this.chatOnboardingStore.state.messages)
      .filter(msg => {
        if (role) {
          return msg.role === role;
        }
        return true;
      })
      .last()
      .get("content", "")
      .value();
  }

  public modifyLastMessage(key: string, value: any): void {
    const message = this.chatOnboardingStore.state.messages;
    if (message?.length) {
      const lastMessage = message[message.length - 1];
      const newMessage = message.slice(0, -1);
      this.chatOnboardingStore.setChatOnboardingState({ messages: [...newMessage, { ...lastMessage, [key]: value }] });
    }
  }

  public removeEmptyContentMessage(): void {
    const newMsg = [...this.chatOnboardingStore.state.messages];
    if (!newMsg[newMsg.length - 1]?.content) {
      this.chatOnboardingStore.setChatOnboardingState({ messages: newMsg.slice(0, -1) });
    }
  }
}
