import { Checkbox } from "antd";
import classNames from "classnames";

import styles from "./AppItem.module.scss";
import { useSignal } from "@preact/signals-react";

export function AppItem(props: any) {
  const more = useSignal(false);
  const isPublished = () => {
    if (Array.isArray(props.published_metadata_v2)) {
      const currentApp = props.published_metadata_v2.filter((item: any) => item.id === props.id);
      return !!currentApp?.[0]?.link;
    }
  };
  const handleClickMore = () => {
    more.value = true;
  };
  return (
    <div className={styles.appItem}>
      <div className={styles.appName}>{props.title}</div>
      <div className={styles.appDetail}>
        <div className={classNames(styles.content, more.value ? "" : styles.contentEllipsis)}>{props.subTitle}</div>
        <span onClick={handleClickMore} className={styles.more}></span>
      </div>
      <div className={styles.publishStatus}>{isPublished() ? "Published" : "Never Published"}</div>
      <Checkbox value={props.id} className={styles.appCheckbox} />
    </div>
  );
}
