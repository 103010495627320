"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class CTheme {
}
exports.CTheme = CTheme;
CTheme.theme = {
    saasAiAppTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.saasAiAppTheme,
        background: "linear-gradient(180deg, #E0E5E8 0%, #E0E5E8 100%)",
    },
    nodeTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.nodeTheme,
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBorderRadius: "30px",
        inputPadding: "15px 25px",
        inputBoxShadow: "rgba(255, 255, 255, 0.35)",
        submitButtonBgClr: "#000101",
        submitButtonColor: "#fff",
        nodeWrapperShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.10)",
    },
};
