import { AModal } from "../../../AModal/AModal";

type BluePrintInfoModalProps = {
  isOpen: boolean;
  name: string;
  description: string;
  closeInfo: () => void;
};

export function BluePrintInfoModal(props: BluePrintInfoModalProps) {
  return (
    <AModal
      open={props.isOpen}
      title={props.name}
      subTitle={props.description}
      buttons={["Close", "Save"]}
      onOk={props.closeInfo}
      onCancel={props.closeInfo}
    />
  );
}
