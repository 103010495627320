import { apollo_gql } from "@uikit/service/apolloIntercept";
import { gql, ApolloError } from "@apollo/client";
import func from "@uikit/func";
import { eventbus } from "imagica-corekit/dist/base/cutil/Eventbus";
import { UnauthorizedMsg } from "imagica-corekit/dist/base/msg/UnauthorizedMsg";

const getFilterString = (filters: { [s: string]: unknown } | ArrayLike<unknown>): any => {
  return Object.entries(filters)
    .map(([key, val]) => `${key}: ${typeof val === "string" ? `"${val}"` : val}`)
    .join(", ");
};

export class ObjectRelationGqlService {
  redirectToLogin(): void {
    eventbus.emit(new UnauthorizedMsg());
  }

  async catchApolloGQL(clientPromise: any): Promise<any> {
    try {
      const response = await clientPromise;
      return response;
    } catch (error) {
      if (error instanceof ApolloError) {
        if ((error.networkError as any)?.statusCode === 401) {
          this.redirectToLogin();
        }
      }
      throw error;
    }
  }
  /* Queries */
  async me(): Promise<any> {
    const query = {
      query: gql`
        query me {
          me {
            id
            objectId
          }
        }
      `,
    };
    try {
      const res = await this.catchApolloGQL(apollo_gql("/api/social/?operationName=me").query(query));
      const me = res?.data?.me;
      if (!me || !me.objectId) {
        throw "Failed to get gql me object";
      }
      return me;
    } catch (error) {
      console.log("useObjectRelationGqlRequest me:", error);
      func.messageUtil("Failed to get gql me object", "error");
    }
  }

  // 获取全局
  async objects(filters = {} as any): Promise<any> {
    if (typeof filters?.id === "string") {
      filters.id = parseInt(filters.id);
    }
    if (!func.isEmpty(filters.attrIndexer)) {
      filters = {
        ...filters,
        attrIndexer: filters.attrIndexer,
        orderBy: "-created_at",
        page: 1,
        size: 1,
      };
    }
    const filterStr = getFilterString(filters);
    const query = {
      query: gql`
            query objects{
              objects(${filterStr}) {
                id
                uuid
                attrIndexer
                name
                attributes
                attributesV2
                createdAt
                updatedAt
                uuid
                attrIndexer
                user {
                  id
                  firstName
                  lastName
                }
              }
            }`,
    };
    const res = await this.catchApolloGQL(apollo_gql("/api/social/?operationName=objects").query(query));
    return res?.data?.objects || [];
  }

  //  获取个人的
  async objectSet(filters: { id?: string | number; attrIndexer?: string; [key: string]: any } = {}): Promise<any> {
    if (typeof filters?.id === "string") {
      filters.id = parseInt(filters.id);
    }
    if (!func.isEmpty(filters.attrIndexer)) {
      filters = {
        ...filters,
        attrIndexer: filters.attrIndexer,
        orderBy: "-created_at",
        page: 1,
        size: 1,
      };
    }
    const filterStr = getFilterString(filters);
    const query = {
      query: gql`
            query objectSet{
              me{
                objectSet(${filterStr}){
                  id
                  uuid
                  attrIndexer
                  name
                  attributes
                  attributesV2
                  createdAt
                  updatedAt
                  uuid
                  attrIndexer
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }`,
    };
    const res = await this.catchApolloGQL(apollo_gql("/api/social/?operationName=objectSet").query(query));
    return res?.data?.me?.objectSet || [];
  }
  /**
   * TODO: 逻辑需要被 SaveApi.relationSet 替换
   */
  async relationSet(
    filters = {},
    params: { size?: number; page?: number } = {},
    idFilter: { destId?: string | number } = {}
  ): Promise<any> {
    const { size = 1000, page = 1 } = params;
    if (typeof idFilter?.destId === "string") {
      idFilter.destId = parseInt(idFilter.destId);
    }
    // temp increase size to 1000, need pagination UI
    const filterStr = getFilterString(Object.assign({}, { page, size }, filters, idFilter));
    const query = {
      query: gql`
            query relationSet{
              me {
                relationSet(${filterStr}){
                  id
                  dest {
                    id
                    uuid
                    attrIndexer
                    name
                    attributes
                    attributesV2
                    createdAt
                    updatedAt
                    user {
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }`,
    };
    try {
      const res = await this.catchApolloGQL(apollo_gql("/api/social/?operationName=relationSet").query(query));
      return (res?.data?.me?.relationSet || []).map((x: { dest: any; id: any }) => {
        let dest = x?.dest;
        if (dest && typeof dest === "object") {
          dest = Object.assign({}, dest, { relationId: x.id });
        }
        return dest;
      });
    } catch (error) {
      // use console log for pagination errors
      // console.log("relationSet", error);
      return [];
    }
  }

  /**
   * TODO: 逻辑需要被 SaveApi.createObject 替换
   */
  async createObject(data: any, successMessage = "Successfully created"): Promise<any> {
    if (data.attributes) {
      data.attributes = JSON.stringify(data.attributes);
    }
    const operationName = data.isGlobal ? "createGlobalObject" : "createObject";
    const mutation = {
      mutation: gql`
            mutation ${operationName}($data: ObjInput!) {
              createObject(data: $data) {
                ok
                object {
                  attributes
                  attributesV2
                  id
                  attrIndexer
                  createdAt
                  name
                  uuid
                  updatedAt
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }`,
      variables: {
        data,
      },
    };
    const res = await this.catchApolloGQL(apollo_gql(`/api/social/?operationName=${operationName}`).mutate(mutation));
    const ok = res?.data?.createObject?.ok;
    if (!ok) {
      func.messageUtil("Failed to create object", "error");
      return;
    }
    const object = res?.data?.createObject?.object;
    if (!object?.id) {
      func.messageUtil("Failed to create object, empty id", "error");
      return;
    }
    if (successMessage) {
      func.messageUtil(successMessage, "success");
    }
    return object;
  }

  /**
   * TODO: 逻辑需要被 SaveApi.updateObject 替换
   */
  async updateObject(id: any, data: any): Promise<any> {
    if (data.attributes) {
      data.attributes = JSON.stringify(data.attributes);
    }
    const mutation = {
      mutation: gql`
            mutation updateObject($data: ObjInput!) {
              createOrUpdateObject(id: ${id}, data: $data) {
                ok
                object {
                  attributes
                  attributesV2
                  id
                  attrIndexer
                  createdAt
                  name
                  uuid
                  updatedAt
                }
              }
            }`,
      variables: {
        data,
      },
    };
    const res = await this.catchApolloGQL(apollo_gql("/api/social/?operationName=updateObject").mutate(mutation));
    const ok = res?.data?.createOrUpdateObject?.ok;
    if (!ok) {
      func.messageUtil("Failed to update object", "error");
      return;
    }
    const object = res?.data?.createOrUpdateObject?.object;
    if (!object?.id) {
      func.messageUtil("Failed to update object, empty id", "error");
      return;
    }
    return object;
  }

  /**
   * TODO: 逻辑需要被 SaveApi.createRelation 替换
   */
  async createRelation(data: any, successMessage = "Successfully created"): Promise<any> {
    const mutation = {
      mutation: gql`
        mutation createRelation($data: RelInput!) {
          createRelation(rel: $data) {
            ok
            relation {
              id
              name
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        data,
      },
    };
    const res = await this.catchApolloGQL(apollo_gql("/api/social/?operationName=createRelation").mutate(mutation));
    const ok = res?.data?.createRelation?.ok;
    if (!ok) {
      func.messageUtil("Failed to create relation", "error");
      return;
    }
    const relation = res?.data?.createRelation?.relation;
    if (!relation?.id) {
      func.messageUtil("Failed to create relation, empty id", "error");
      return;
    }
    if (successMessage) {
      func.messageUtil(successMessage, "success");
    }
    return relation;
  }

  /**
   * TODO: 逻辑需要被 SaveApi.deleteRelations 替换
   */
  async deleteRelations(ids: any[], successMessage = "Successfully deleted", hideDialog = false): Promise<any> {
    if (!(ids instanceof Array)) {
      ids = [ids];
    }
    const mutation = {
      mutation: gql`
            mutation deleteRelations {
              deleteRelations(ids: ${ids}) {
                ok
              }
            }`,
    };
    const res = await this.catchApolloGQL(apollo_gql("/api/social/?operationName=deleteRelations").mutate(mutation));
    const ok = res?.data?.deleteRelations?.ok;
    if (!ok) {
      func.messageUtil("Failed to delete relations", "error");
      return;
    }
    if (successMessage && !hideDialog) {
      func.messageUtil(successMessage, "success");
    }
    return;
  }

  shareStudioProject = async (
    projectId: any,
    authorId: any,
    successMessage = "",
    copyProject = false,
    newProjectName = "",
    fileds = ""
  ): Promise<any> => {
    fileds = fileds || ["name", "attributes", "attributesV2", "id", "user { id }", "uuid"].join("\n");
    const mutation = {
      mutation: gql`
            mutation shareStudioProject {
              shareStudioProject(authorUserId: ${authorId}, projectId: ${projectId}, copyProject: ${copyProject} ${
        newProjectName ? `, newProjectName: "${newProjectName}"` : ""
      }) {
                ok
                object {
                  ${fileds}
                }
              }
            }`,
    };
    const res = await this.catchApolloGQL(apollo_gql("/api/social/?operationName=shareStudioProject").mutate(mutation));
    const ok = res?.data?.shareStudioProject?.ok;
    if (!ok) {
      func.messageUtil("Failed to share studio project", "error");
      return;
    }
    if (successMessage) {
      func.messageUtil(successMessage, "success");
    }
    return res?.data?.shareStudioProject?.object;
  };

  shareStudioFunction = async (
    functionId: any,
    authorId: any,
    successMessage = "",
    copyFunction = false,
    newFunctionName = ""
  ): Promise<any> => {
    const mutation = {
      mutation: gql`
            mutation shareStudioFunction {
              shareStudioFunction(authorUserId: ${authorId}, functionId: ${functionId}, copyFunction: ${copyFunction} ${
        newFunctionName ? `, newFunctionName: "${newFunctionName}"` : ""
      }) {
                ok
                object {
                  name
                  attributes
                  attributesV2
                  id
                  user {
                    id
                  }
                }
              }
            }`,
    };
    const res = await this.catchApolloGQL(
      apollo_gql("/api/social/?operationName=shareStudioFunction").mutate(mutation)
    );
    const ok = res?.data?.shareStudioFunction?.ok;
    if (!ok) {
      func.messageUtil("Failed to share studio function", "error");
      return;
    }
    if (successMessage) {
      func.messageUtil(successMessage, "success");
    }
    return res?.data?.shareStudioFunction?.object;
  };
}
