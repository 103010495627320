import { v4 as uuidv4 } from "uuid";

let tutorialSessionId = "";
let videoSessionId = "";
let runEdgeId = ""; //uuid for run_edge and run_edge_http
let cotSessionId = ""; //uuid for cot propmt history

// const defaultRules = (data) => {
//     return false;
//   };
const getVideoSessionId = () => {
  return videoSessionId;
};

const setVideoSessionId = () => {
  const uuid = uuidv4();
  videoSessionId = uuid;
  return uuid;
};

const clearVideoSessionId = () => {
  videoSessionId = "";
};

const getCotSessionId = () => {
  return cotSessionId;
};

const setCotSessionId = () => {
  const uuid = uuidv4();
  cotSessionId = uuid;
  return uuid;
};

const clearCotSessionId = () => {
  cotSessionId = "";
};
const getRunEdgeId = () => {
  return runEdgeId;
};

const setRunEdgeId = () => {
  const uuid = uuidv4();
  runEdgeId = uuid;
  return uuid;
};

const clearRunEdgeId = () => {
  runEdgeId = "";
};

const getSessionId = () => {
  return tutorialSessionId;
};

const setSessionId = () => {
  const sessionid = uuidv4();
  tutorialSessionId = sessionid;
  return sessionid;
};

const clearSessionId = () => {
  tutorialSessionId = "";
};

const events = {
  save_project: {
    // rules: (data) => {
    //   return defaultRules(data.query);
    // },
    processData: (data, curProject) => {
      return {
        //TODO: give as much detail as possible
        project_static_id: curProject.index,
        source: data.source, //TODO: publish and share also auto saves
        name: curProject.name,
        is_first_created: data.isFirstCreatePorject,
        description: "triggered when a project is saved (from home button, project title, save button)",
      };
    },
  },
  login: {
    processData: data => {
      return {
        //TODO
        description: "triggered when a user logs in",
      };
    },
  },
  webapp_login: {
    processData: data => {
      return {
        guest_uuid: data.guest_uuid,
        project_static_id: data.project_static_id,
        description: "triggered when a user logs in from a shared webapp page",
      };
    },
  },
  login_google: {
    processData: data => {
      return {
        //TODO
        description: "triggered when a user logs in using googleoauth",
      };
    },
  },
  logout: {
    processData: data => {
      return {
        //TODO
        description: "triggered when a user logs out from their imagica account",
      };
    },
  },
  signup: {
    processData: data => {
      return {
        //TODO
        description: "triggered when a user signs up",
      };
    },
  },
  webapp_signup: {
    processData: data => {
      return {
        //TODO
        project_static_id: data.project_static_id,
        guest_uuid: data.guest_uuid,
        description: "triggered when a user signs up from a published webapp",
      };
    },
  },
  signup_google: {
    processData: data => {
      return {
        //TODO
        description: "triggered when a user signs up using googleoauth",
      };
    },
  },
  webapp_signup_google: {
    processData: data => {
      return {
        //TODO
        project_static_id: data.project_static_id,
        guest_uuid: data.guest_uuid,
        description: "triggered when a user signs up from a published webapp using their google account",
      };
    },
  },
  webapp_login_google: {
    processData: data => {
      return {
        //TODO
        project_static_id: data.project_static_id,
        guest_uuid: data.guest_uuid,
        description: "triggered when a user logs in from a published webapp using their google account",
      };
    },
  },
  run_shared_webapp: {
    processData: data => {
      return {
        project_static_id: data.project_static_id,
        user_webapp_input: data.user_webapp_input,
        description: "triggered when a logged-in user inputs something on a shared webapp and runs the webapp",
      };
    },
  },
  click_imagica_home: {
    processData: (data, curProject) => {
      return {
        project_static_id: data.project_static_id ?? curProject.index,
        source: data.source,
        description:
          "triggered when a logged in user clicks on the Imagica link at the bottom of a shared webapp or preview",
      };
    },
  },
  click_privacy_policy: {
    processData: (data, curProject) => {
      return {
        project_static_id: data.project_static_id ?? curProject.index,
        source: data.source,
        description:
          "triggered when a user clicks on the Privacy Policy link at the bottom of the publish preview or published app",
      };
    },
  },
  click_terms_of_use: {
    processData: (data, curProject) => {
      return {
        project_static_id: data.project_static_id ?? curProject.index,
        source: data.source,
        description:
          "triggered when a user clicks on the Terms of Use link at the bottom of the publish preview or published app",
      };
    },
  },
  publish_preview_caption: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index ?? data.uuid,
        value: data.value,
        description:
          "triggered when a user toggles the Show/Hide Caption button on the publish preview page for an image or visual node",
      };
    },
  },
  create_project: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index, //FIXME: no uuid or attribute indexer when a project is created
        name: curProject.name,
        template_name: data?.template_name,
        //TODO
        description: "triggered when a user creates a new project",
      };
    },
  },
  open_project: {
    processData: (data, curProject) => {
      return {
        name: curProject.name,
        project_static_id: curProject.index ?? curProject.uuid,
        description: "triggered when a user opens a project from the home page",
      };
    },
  },
  share_project: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        name: curProject.name,
        URL: data.URL,
        description: "triggered when a user clicks on the share project button to generate a sharable link",
      };
    },
  },
  publish_as_saas: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index, //note event property naming change for query
        uuid: curProject.uuid,
        name: curProject.name,
        URL: data.URL,
        description: "triggered when a project is succesfully published as a saas website",
      };
    },
  },
  publish_as_webapp: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        uuid: data.uuid ?? curProject.uuid,
        name: data.name ?? curProject.name,
        URL: data.URL,
        publish_view_name: data.publish_view_name,
        design_template: data.design_template,
        font_style: data.font_style,
        logo: data.logo,
        imagica_brainding: data.imagica_brainding,
        custom_domain_flag: data.custom_domain_flag,
        custom_domain_name: data.custom_domain_name,
        embed_size_width: data.embed_size_width,
        embed_size_length: data.embed_size_length,
        charging_option: data.charging_option,
        tos_env_agreement: data.tos_env_agreement,
        subscription_price: data.subscription_price,
        free_trial_period: data.free_trial_period,
        copyright_info: data.copyright_info,
        domain_name: data.domain_name,
        template_name: data.template_name,
        description: "triggered when a project is successfully published as a web application",
        new_project: data.new_project,
        num_nodes: data.num_nodes,
        num_edges: data.num_edges,
      };
    },
  },
  publish_as_thinkingapp: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        uuid: curProject.uuid,
        name: curProject.name,
        URL: data.URL,
        description: "triggered when a project is succesfully published as a thinking app",
      };
    },
  },
  unpublish: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        URL: data.URL,
        publish_view_name: data.publish_view_name,
        name: data.name,
        uuid: data.uuid,
        description: "triggered when a published project is successfully unpublished from the publish preview page",
      };
    },
  },
  delete_publish_view: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        URL: data.URL,
        publish_view_name: data.publish_view_name,
        name: data.name,
        uuid: data.uuid,
        description: "triggered when a publish preview is deleted from the publish preview page",
      };
    },
  },
  click_function: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        function_name: data.function_name,
        description: "triggered when a function in the edge dropdown menu is clicked",
      };
    },
  },
  open_function: {
    processData: (data, curProject) => {
      return {
        //TODO
        function_static_id: curProject.index ?? data.function_static_id,
        function_name: data.function_name,
        description: "triggered when a user opens a function from the home page",
      };
    },
  },
  start_onboarding_video: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        session_id: setSessionId(),
        description: "triggered when a user clicks on the Begin button on the onboarding landing page",
      };
    },
  },
  close_tutorial: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      clearSessionId();
      return {
        //TODO
        type: data.type,
        new_user: data.new_user,
        project_static_id: curProject.index,
        session_id: sessionId,
        description: "triggered when a user clicks on X on the onboarding landing page",
      };
    },
  },
  close_onboarding_video: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      return {
        project_static_id: curProject.index,
        session_id: sessionId,
        description: "triggered when a user clicks on X on the onboarding video page",
      };
    },
  },
  finish_onboarding_video: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      clearSessionId();
      return {
        project_static_id: curProject.index,
        session_id: sessionId,
        description: "triggered when a user clicks on the Finish button on the onboarding video finish modal",
      };
    },
  },
  try_again_onboarding_video: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      return {
        project_static_id: curProject.index,
        session_id: sessionId,
        description: "triggered when a user clicks on the Watch/Try Again button on the onboarding video finish modal",
      };
    },
  },
  start_tutorial: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        new_user: data.new_user, //TODO: if imagicaStudioTutorial.viewed == false, new user
        session_id: setSessionId(),
        // type: data.type, //TODO
        description: "triggered when a user starts the interactive tutorial",
      };
    },
  },
  exit_tutorial: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      clearSessionId();
      return {
        //TODO
        project_static_id: curProject.index,
        step: data.step,
        session_id: sessionId,
        description: "triggered when a user exits the interactive tutorial by clicking the exit button",
      };
    },
  },
  finish_tutorial: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      clearSessionId();
      return {
        //TODO
        project_static_id: curProject.index,
        session_id: sessionId,
        description: "triggered when a user finishs the interactive tutorial and clicks on the finish button",
      };
    },
  },
  user_guide: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on Imagica User Guide in the Help menu",
      };
    },
  },
  interactive_tutorial: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on Interactive Tutorial in the Help menu",
      };
    },
  },
  quickstart_video: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on Quick Start Video in the Help menu",
      };
    },
  },
  quickstart_video_close: {
    processData: (data, curProject) => {
      return {
        //TODO
        description:
          "triggered when a user closes the quickstart video by clicking on the x button or outside the video frame",
      };
    },
  },
  report_bug_recording: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on Take a screenshot in Report a bug in the Help menu",
      };
    },
  },
  report_bug_screenshot: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on Start screen recording in Report a bug in the Help menu",
      };
    },
  },
  developer_mode: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        developer_mode: data.developer_mode,
        description: "triggered when a user toggles the developer mode button",
      };
    },
  },
  experimental_mode: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        experimental_mode: data.experimental_mode,
        description: "triggered when a user toggles the experimental mode button",
      };
    },
  },
  create_node: {
    processData: (data, curProject) => {
      if (data.displayType === "uploadFile") return {};
      return {
        //TODO
        project_static_id: curProject.index,
        description:
          "triggered when a user clicks on the Add node button in home tool bar, or when a user right clicks on canvas -> New",
      };
    },
  },
  create_variable: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        name: curProject.name,
        description: "triggered when a variable is created",
      };
    },
  },
  run_edge: {
    processData: (data, curProject) => {
      //TODO: move analytics logic here
      return {
        uuid: getRunEdgeId(), //FIXME: clear after use
        project_static_id: curProject.index,
        input: data.input,
        prompt: data.prompt,
        function_name: data.function_name,
        is_manual_click: data.isManualClick,
        type: data.type,
        prompt_id: data.prompt_id,
        edge_id: data.edge_id,
        output: data.output,
        description: "triggered when an edge is run successfully",
      };
    },
  },
  add_input_to_edge: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        description: "triggered when a user drags a node to an edge to assign the node as an input to the edge",
      };
    },
  },
  // run_edge_http: { //TODO
  //   processData: (data, curProject) => {
  //     return {
  //       uuid: setRunEdgeId(),
  //       project_static_id: curProject.index,
  //       function_name: data.function_name,
  //       url: data.url,
  //       status_code: data.status_code,
  //       description: 'triggered when an api for a prompt or an edge drop down function is called',
  //     };
  //   }
  // },
  http: {
    //TODO
    processData: (data, curProject) => {
      return {
        uuid: setRunEdgeId(),
        project_static_id: curProject.index,
        function_name: data.function_name,
        url: data.url,
        status_code: data.status_code,
        description: "triggered when an api call is not status ok",
      };
    },
  },
  run_all: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        source_nodes: data.source_nodes, //FIXME: better data format?
        description: "triggered when a users clicks on the Run All button and all edges are run",
      };
    },
  },
  create_edge: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when an edge is created by clicking on the add icon on a node",
      };
    },
  },
  click_home: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        // save: data.save, //save and exit -> true, discard and exit -> false
        description:
          "triggered when a user clicks on the home button to go back to the home page (clicks on home -> clicks on save and exit / discard and exit)",
      };
    },
  },
  shard_node: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on the shard node button and the output is split succesfully",
        input: data.input,
      };
    },
  },
  create_agent: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when an agent is created",
      };
    },
  },
  duplicate_edge: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when the user selects an edge -> right clicks the edge -> Duplicate",
      };
    },
  },
  duplicate_Function: {
    processData: (data, curProject) => {
      return {
        project_static_id_source: data.source_id,
        project_static_id_destination: data.destination_id,
        description: "triggered when a user presses the duplicate on a function",
      };
    },
  },
  //Publish-related events
  click_publish_as: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on the Publish As button in Home Nav Bar",
      };
    },
  },
  close_publish_1: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on the x button on the publish format selection modal",
      };
    },
  },
  entrypoint_format: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        format: data.format,
        format_event: data.event,
        description: "triggered when a user clicks a publish format and saves the selected format as event property",
      };
    },
  },
  close_publish_format: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user clicks on the x button on the publish format selection modal",
      };
    },
  },
  click_publish_confirm: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks the confirm button on the publish format selection modal",
      };
    },
  },
  select_donot_show_screen: {
    processData: (data, curProject) => {
      return {
        description:
          "triggered when a user selected 'Don't show this screen next time' on the publish format selection modal",
      };
    },
  },
  close_publish_x: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on x on the publish preview window",
      };
    },
  },
  click_publish_settings: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on the setting button on the saas website preview page",
      };
    },
  },
  exit_publish_settings: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on < in publish settings",
      };
    },
  },
  click_publish_option: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        option: data.option,
        description: "triggered when a user clicks on a publish option in publish settings",
      };
    },
  },
  click_publish_setting_ok: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        option: data.option,
        description: "triggered when a user clicks on OK in publish settings",
      };
    },
  },
  click_stripe_link: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on the stripe link in Subscription in publish settings",
      };
    },
  },
  click_add_content: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks on the +Add Content button in publish preview",
      };
    },
  },
  click_add_content_done: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when a user clicks Done (for add content) in publish preview",
      };
    },
  },
  click_preview_toolbar_button: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        button_name: data.button_name,
        description: "triggered when a user clicks any button in Preview Tool Bar",
      };
    },
  },
  publish_preview_add_content: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        node_value: data.node,
        description: "triggered when a user clicks 'Publish as Content' in Node Tool Bar",
      };
    },
  },
  publish_preview_remove_content: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        node_value: data.node,
        description: "triggered when a user delete content in preview",
      };
    },
  },
  publish_preview_settings: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        color_theme: data.color_theme,
        description: "triggered when a user selected a color theme",
      };
    },
  },
  toggle_publish_preview_node_button: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        node_class_name: data.node_class_name,
        button_text: data.button_text,
        description:
          "triggered when a user toggles the Add to app/ Remove from app button for nodes after clicking Add content in publish preview",
      };
    },
  },
  /// v2版本（preview列表），使用uuid，老版本使用index
  copy_published_link: {
    processData: (data, curProject) => {
      return {
        URL: data.URL,
        project_static_id: data.uuid ?? curProject.index,
        description: "triggered when a user clicks on the published link and the link is copied successfully",
      };
    },
  },
  open_published_link: {
    processData: (data, curProject) => {
      return {
        //TODO
        URL: data.URL,
        project_static_id: curProject.index,
        description: "triggered when the user clicks on the open in new window button next to the published link",
      };
    },
  },
  click_edit_preview_text: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        target: data.target,
        old_text: data.old_text,
        new_text: data.new_text,
        delete: data.delete ?? false,
        description: "triggered when user edited text or placeholder on publish preview content",
      };
    },
  },
  click_try_ai_now: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when user clicks on the Try AI Now button in Saas Website publish preview",
      };
    },
  },
  save_function: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        name: data.name,
        function_description: data.description,
        type: data.type,
        is_first_created: data.is_first_created,
        source: data.source,
        description: "triggered when user creates a custom js or api function successfully from the edge dropdown menu",
      };
    },
  },
  click_publish_dismiss: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when user clicks on Dismiss on the Publish tip panel",
      };
    },
  },
  click_try_it: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when user clicks on Try it on the Publish tip panel",
      };
    },
  },
  //-----COT events-----
  close_cot: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when user clicks on x on cot initial step",
      };
    },
  },
  click_close_cot_x: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when user clicks on x on the exit modal",
      };
    },
  },
  click_close_cot_cancel: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when user clicks on Cancel on the exit modal",
      };
    },
  },
  click_close_cot_enter: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when user clicks on Enter on the exit modal",
      };
    },
  },
  start_cot: {
    processData: (data, curProject) => {
      return {
        //TODO
        session_id: setCotSessionId(),
        project_static_id: curProject.index,
        description: "triggered when user clicks on cot text history to end edit",
      };
    },
  },
  enter_cot_input: {
    processData: (data, curProject) => {
      return {
        input: data,
        session_id: getCotSessionId(),
        project_static_id: curProject.index,
        description: "triggered when user hits enter after typing cot user input OR selecting a cot prompt",
      };
    },
  },
  enter_cot_done: {
    processData: (data, curProject) => {
      let sessionId = getCotSessionId();
      clearCotSessionId();
      return {
        history: data,
        session_id: sessionId,
        project_static_id: curProject.index,
        description: "triggered when the cot elicitation is done",
      };
    },
  },
  empty_cot_result: {
    processData: (data, curProject) => {
      return {
        input: data,
        project_static_id: curProject.index,
        description: "triggered when an empty template is generated from cot user input",
      };
    },
  },
  click_suggest_for_me: {
    processData: (data, curProject) => {
      return {
        //TODO
        input: data,
        project_static_id: curProject.index,
        description: "triggered when user clicks on the Suggest For Me button on a cot input node",
      };
    },
  },
  click_submit: {
    processData: (data, curProject) => {
      return {
        //TODO
        input: data,
        project_static_id: curProject.index,
        description: "triggered when user clicks on the Submit button on a cot input node",
      };
    },
  },
  click_cot_ai_icon: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when user clicks on the cot (virtural assistant) icon in home tool bar",
      };
    },
  },
  click_modal_cancel: {
    processData: (data, curProject) => {
      return {
        //TODO
        modal_title: data.title,
        modal_description: data.description,
        project_static_id: curProject.index,
        description: "triggered when user clicks on Cancel or x on a modal",
      };
    },
  },
  click_modal_save_start: {
    //also saves
    processData: (data, curProject) => {
      return {
        //TODO
        modal_title: data.title,
        modal_description: data.description,
        project_static_id: curProject.index,
        description:
          "triggered when user clicks on save and start on the cot modal (click on cot icon -> save and start)",
      };
    },
  },
  click_cot_unsupported_icon: {
    processData: (data, curProject) => {
      return {
        //TODO
        project_static_id: curProject.index,
        description: "triggered when user clicks on the cot unsupported node type icon",
      };
    },
  },
  close_tooltip: {
    processData: (data, curProject) => {
      return {
        //TODO
        title: data.title,
        content: data.content,
        project_static_id: curProject.index,
        description: "triggered when user clicks x to close a tooltip",
      };
    },
  },
  delete_project_confirmed: {
    processData: (data, curProject) => {
      return {
        project_static_id: data.attrIndexer ?? data.uuid,
        title: data.label,
        object_id: data.id,
        description: "triggered when user successfully deletes a project from the home page",
      };
    },
  },
  delete_function_confirmed: {
    processData: (data, curProject) => {
      return {
        function_name: data.value,
        function_description: data.description,
        object_id: data.id,
        description: "triggered when user successfully deletes a function from the home page",
      };
    },
  },
  user_feedback_like: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        line_param: data.line_param,
        feedback: data.feedback,
        created_from_cot: data.created_from_cot,
        source_type: data.source_type,
        source_value: data.source_value,
        target_type: data.target_type,
        target_value: data.target_value,
        description: "triggered when user clicks on the thumb up button on a non-source node to provide feedback",
      };
    },
  },
  user_feedback_like_cancel: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        line_param: data.line_param,
        feedback: data.feedback,
        created_from_cot: data.created_from_cot,
        source_type: data.source_type,
        source_value: data.source_value,
        target_type: data.target_type,
        target_value: data.target_value,
        description: "triggered when user clicks on the thumb up button on a non-source node to cancel a like feedback",
      };
    },
  },
  user_feedback_dislike: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        line_param: data.line_param,
        feedback: data.feedback,
        created_from_cot: data.created_from_cot,
        source_type: data.source_type,
        source_value: data.source_value,
        target_type: data.target_type,
        target_value: data.target_value,
        description: "triggered when user clicks on the thumb down button on a non-source node to provide feedback",
      };
    },
  },
  user_feedback_dislike_cancel: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        line_param: data.line_param,
        feedback: data.feedback,
        created_from_cot: data.created_from_cot,
        source_type: data.source_type,
        source_value: data.source_value,
        target_type: data.target_type,
        target_value: data.target_value,
        description:
          "triggered when user clicks on the thumb down button on a non-source node to cancel a dislike feedback",
      };
    },
  },
  cot_graph_user_feedback_like: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        user_input: data.user_input,
        project_id: data.project_id,
        url: data.url,
        cot_input: data.cot_input,
        description: "triggered when user clicks on like button for a COT graph",
      };
    },
  },
  cot_graph_user_feedback_dislike: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        user_input: data.user_input,
        feedback_text: data.feedback_text,
        project_id: data.project_id,
        url: data.url,
        cot_input: data.cot_input,
        description: "triggered when user clicks on dislike button for a COT graph",
      };
    },
  },
  cot_graph_user_feedback_dislike_cancel: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        user_input: data.user_input,
        feedback_text: data.feedback_text,
        project_id: data.project_id,
        url: data.url,
        cot_input: data.cot_input,
        description: "triggered when user clicks on the cancel button on the dislike feedback modal for a COT graph",
      };
    },
  },
  click_manual_mode_template: {
    processData: (data, curProject) => {
      return {
        project_static_id: data.project_static_id,
        template_name: data.template_name,
        description: "triggered when user clicks on a manual mode template",
      };
    },
  },
  // TODO: refactor click events to use event listener
  click: {
    processData: (data, curProject) => {
      let sessionId = "";
      switch (data.target) {
        case "example_video":
          sessionId = setVideoSessionId();
          break;
        case "close_example_video":
          sessionId = getVideoSessionId();
          clearVideoSessionId();
          break;
        case "cot_prompt":
          sessionId = getCotSessionId();
          break;
        default:
          break;
      }
      return {
        //TODO
        session_id: sessionId,
        target: data.target,
        option: data.option,
        project_static_id: curProject.index ?? curProject.uuid,
        description: "triggered when user clicks an UI element",
        content: data.content,
      };
    },
  },
  chat_user_input: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        content: data.chat_user_input,
        description: "chat user input",
      };
    },
  },
  chat_ai_response: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        content: data.chat_ai_response,
        description: "chat ai response",
      };
    },
  },
  click_chat_regenerate: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        content: data.chat_regenerate,
        description: "chat user click regenerate",
      };
    },
  },
  click_chat_start_new: {
    processData: (data, curProject) => {
      return {
        project_static_id: curProject.index,
        description: "chat user click start new",
      };
    },
  },
  pay_wall_upgrade: {
    processData: (data, curProject) => {
      return {
        current_plan_name: data.currentPlanName,
        new_plan_name: data.name.text,
        pay_mode: data.payMode,
        price_id: data.priceId,
        description: "triggered when user clicks <" + data.upgrade_button.text + "> button",
      };
    },
  },
  // pay_wall_downgrade: {
  //   processData: (data, curProject) => {
  //     return {
  //       current_plan_name: data.planName,
  //       description: "triggered when user clicks downgrade button",
  //     };
  //   },
  // },
  discord: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user clicks on Discord community in the Help menu",
      };
    },
  },
  upload_file: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user uploads a file",
        file_name: data.name,
        file_extension: data.extension,
        project_static_id: curProject.index ?? curProject.uuid,
      };
    },
  },
  click_profile_imagica: {
    processData: (data, curProject) => {
      return {
        guest_uuid: data.guest_uuid,
        project_static_id: data.project_static_id,
        description: "triggered when a user clicks on the imagica.ai link from profile menu",
      };
    },
  },
  my_account_access: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user click on 'My account' in the personal menu",
      };
    },
  },
  my_account_x_btn_close: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user click on close button in the profile page",
      };
    },
  },
  my_account_click_upload_image_button: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user click on upload image button in the account page",
        file_name: data.file_name,
        file_extension: data.file_extension,
        s3_location: data.s3_location,
        file_size: data.file_size,
      };
    },
  },
  my_account_click_delete_image_button: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user click on delete image button in the account page",
      };
    },
  },
  my_account_change_name: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user changed name then click on 'Save changes' button in the account page",
        user_name: data.user_name,
      };
    },
  },
  my_account_change_email: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user changed email then click on 'Save changes' button in the account page",
        user_email_new: data.user_email_new,
      };
    },
  },
  my_account_reset_password: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user click on 'Reset' button in the password page",
      };
    },
  },
  delete_account: {
    processData: (data, curProject) => {
      return {
        description:
          "triggered when a user clicks on 'Delete Account' in the My Account section and inputs the confirmation code",
      };
    },
  },
  interview_bot_make_call: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user clicks on the Make a Call button on the Interview Bot node",
        prompt: data.prompt,
        phone_number: data.phone_number,
        intro: data.intro,
        project_static_id: curProject.index ?? curProject.uuid,
      };
    },
  },
  create_file_upload: {
    processData: (data, curProject) => {
      return {
        description:
          "triggered when a user clicks on the Upload File button in home tool bar, or when a user right clicks on canvas -> Upload File",
        project_static_id: curProject.index ?? curProject.uuid,
      };
    },
  },
  node_copy_text: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user clicks on the Copy Text button on the Node ToolBar Menu",
        project_static_id: curProject.index ?? curProject.uuid,
        input: data.input,
      };
    },
  },
  delete_node: {
    processData: (data, curProject) => {
      return {
        description:
          "triggered when a user clicks delete icon on the Node ToolBar Menu or selects nodes and clicks delete on the right click menu or selects nodes and type backspace key",
        project_static_id: curProject.index ?? curProject.uuid,
        nodes: data.nodes,
      };
    },
  },
  node_publish_content: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user clicks on the Publish as Content button on the Node ToolBar Menu",
        project_static_id: curProject.index ?? curProject.uuid,
      };
    },
  },
  create_project_for_mobile: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user creates a new imagica project",
        project_static_id: curProject.index ?? curProject.uuid,
        cot_prompt: data.cot_prompt,
      };
    },
  },
  back_create_project_for_mobile: {
    processData: (data, curProject) => {
      return {
        description: "triggered when a user goes back to previous page after creating a new project on mobile",
        guest_uuid: data.guest_uuid,
      };
    },
  },
  upgrade_from_paywall_feature: {
    processData: (data, curProject) => {
      console.error("el");
      return {
        description: "triggered when a user clicks upgrade button from a feature behind a paywall",
        project_static_id: curProject.index ?? curProject.uuid,
        feature: data.feature,
        featureItem: data.featureItem,
      };
    },
  },
  auto_imagica_chat_user_message: {
    processData: (data, curProject) => {
      return {
        message: data.message,
        words_count: data.words_count,
        talk_to_ai_builder_component: data.talk_to_ai_builder_component,
      };
    },
  },
  auto_imagica_click_action_button: {
    processData: (data, curProject) => {
      return {
        button_text: data.button_text,
      };
    },
  },
  auto_imagica_chat_system_message: {
    processData: (data, curProject) => {
      return {
        message: data.message,
        words_count: data.words_count,
        talk_to_ai_builder_component: data.talk_to_ai_builder_component,
      };
    },
  },
  click_view_builder_toggle_button: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      return {
        project_static_id: curProject.index,
        session_id: sessionId,
        button_text: data.button_text,
        description: "triggered when a user clicks on 'View text' or 'Build UI' button of the ViewBuilder Node",
      };
    },
  },
  click_app_detail: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      return {
        project_static_id: curProject.index,
        session_id: sessionId,
        description: "triggered when a user clicks on 'App Details' button of the Preview Header",
      };
    },
  },
  start_template_video: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      return {
        project_static_id: curProject.index,
        session_id: sessionId,
        name: data.name,
        description: "triggered when a user start watching videos",
      };
    },
  },
  close_template_video: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      return {
        project_static_id: curProject.index,
        session_id: sessionId,
        name: data.name,
        description: "triggered when a user closes a video by clicking on the x button or outside the video frame",
      };
    },
  },
  imagica_error_occur: {
    processData: (data, curProject) => {
      const sessionId = getSessionId();
      return {
        project_static_id: curProject.index,
        session_id: sessionId,
        error_message: data.error_message,
        error_detail: data.error_detail,
        description: "triggered when an imagica error occurs",
      };
    },
  },
};

export default events;
