import func from "@uikit/func";
import { localFontFaceList } from "../PreviewPane/Settings/appearance/Const";

export class fontUtil {
  static downLoadFont(fontList: any, onlyFont?: string): void {
    if (!func.isEmpty(onlyFont)) {
      fontList = fontList.filter((x: any) => x.fontFamily === onlyFont);
    }
    // 创建一个新的style元素
    const style = document.createElement("style");
    style.type = "text/css";
    fontList.forEach((x: any) => {
      if (!func.isEmpty(x.fontFaceUrl) && !localFontFaceList.includes(x.fontFaceUrl)) {
        const fontFace = `
          @font-face {
            font-family: ${x.fontFamily};
            src: url(${x.fontFaceUrl})
          }
        `;
        style.appendChild(document.createTextNode(fontFace));
        document.head.appendChild(style);
      }
    });
  }
}
