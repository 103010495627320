import { useEffect, useRef } from "react";
import style from "./ChatContent.module.scss";
import { ChatItem } from "./ChatItem";
import { useSignal, useSignalEffect } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { ChatAIStore } from "../../ChatAIStore";
import { ChatInput } from "../ChatInput/ChatInput";
import { ChatContainerState } from "../../ChatContainer/ChatContainerBloc";
import { ChatTooltip } from "./../ChatTooltip/ChatTooltip";
import React from "react";
import { LottieAnimation } from "@views/components/LottieAnimation";
import generateJson from "../../generate.json";
import classNames from "classnames";
import { cloneDeep, set } from "lodash";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";

interface ChatContentProps {
  type?: string;
  state: ChatContainerState;
  chatUpload?: boolean;
  fileName?: string;
  bloc?: any;
  container: React.RefObject<HTMLElement>;
  inputNode?: any;
}

export function ChatContent(props: ChatContentProps) {
  const homePluginStore = getIt(HomePluginStore);
  const isShare = homePluginStore.state.isShare;

  const isReload = useSignal(false);
  const chatAIStore = getIt(ChatAIStore);

  const chatContentRef = useRef<HTMLDivElement>(null);
  const chatListUpdateCountRef = useRef(0);

  useSignalEffect(() => {
    if (chatContentRef.current) {
      const childNodes = Array.from(chatContentRef.current.childNodes);
      const lastChild = childNodes[childNodes.length - 1] as HTMLDivElement;
      chatAIStore.setChatLogoTop(lastChild.offsetTop);
    }
  });

  useEffect(() => {
    if (props.state.chatList.value?.length) {
      chatListUpdateCountRef.current = chatListUpdateCountRef.current + 1;
    }
  }, [props.state.chatList.value]);

  const onPressEnter = (txt: string) => {
    props.state.inputText.value = txt;
    props.bloc.send();
  };

  const handleReloadFile = () => {
    isReload.value = true;
    props.state.chatList.value = [];
  };

  return (
    <div className={classNames(style.ChatContent, !isShare ? style.canvasChatContent : "")} ref={chatContentRef}>
      {/** upload有reload a file */}
      {props.state.chatList.value.map((item, index, array) => {
        const isLastOne = index === array.length - 1;
        const disabledStream = !isLastOne && !isShare && chatListUpdateCountRef.current > 1;

        return (
          <ChatItem
            container={props.container}
            key={item.type + index}
            type={item.type}
            value={item.value}
            isLastOne={isLastOne}
            disableStream={disabledStream}
            reloadFile={props.type === "upload" ? handleReloadFile : null}
            regenerate={() => props.bloc.onRegenerate()}
            isStreamStopped={props.bloc.state.isStreamStopped.value}
            stop={content => {
              if (content) {
                const chatList = cloneDeep(props.state.chatList.value);
                set(chatList, index, { type: "Assistant", value: content });
                props.bloc.onStop(chatList);
              } else {
                props.bloc.onStop();
              }
            }}
          />
        );
      })}
      {props.state.isLoading.value && (
        <div className={style.generating}>
          <LottieAnimation loader={generateJson} className={style.generateAnimation} />
          <span>Generating...</span>
        </div>
      )}
      {/* {isReload.value ? (
        <ChatUpload
          page={"home"}
          uploadFile={(file: RcFile, res: any) => {
            isReload.value = false;
            props.bloc.uploadFile(file, res);
          }}
        />
      ) : ( */}
      <ChatInput
        isLoading={props.state.isLoading.value}
        onPressEnter={onPressEnter}
        type="chat"
        placeholder="Ask me anything..."
        label="How can I help you?"
      />
      <ChatTooltip />
    </div>
  );
}
