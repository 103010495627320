import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import isBlank from "@sedan-utils/is-blank";
import { Settings } from "imagica-corekit/dist/base/kernel/Settings";
import { FotProcessDep } from "./modles";

export class ProcessShareDomain {
  constructor(
    public settings: Settings,
    public fotReduxStore: FotReduxStore,
    public homePluginStore: HomePluginStore
  ) {}

  async init(fotProcessDep: FotProcessDep): Promise<boolean> {
    //处理是否为share
    const data = this.fotReduxStore.getState().editor.subscriberParamsData as any;
    // data为空，表示unpublish
    const isShare =
      fotProcessDep.reactLocation.pathname.includes("/editor/shared_intelligent_app") ||
      isBlank(data) ||
      data?.attributes?.mode === "subscriber";
    this.homePluginStore.setCustomDomain(data?.imagica_byod || "");
    this.homePluginStore.setIsShare(isShare);

    // 原来 onShare 方法的逻辑
    //如果是creator,清空GUEST_TOKEN，修复BSF-7328
    fotProcessDep.onShareDomain?.(isShare);

    return isShare;
  }

  clickEnableBrainStudios(fotProcessDep: FotProcessDep): void {
    const subscriber = this.fotReduxStore.getState().editor.subscriberParamsData as any;
    // subscriber为空，表示unpublish, 直接显示提示
    if (isBlank(subscriber)) {
      this.fotReduxStore.setShowShareSignin(true);
      return;
    }
    //没有页面权限，去到登录页面
    // if (!homePluginStore.state.isShare && !enableBrainStudios) {
    //   // 清除token
    //   this.logout();
    //   // func.customMsg({
    //   //   content: (
    //   //     <p>
    //   //       Please request early access at <a href="https://www.imagica.ai/">Imagica.ai</a>
    //   //     </p>
    //   //   ),
    //   // });
    // }

    this.linkToCreator(fotProcessDep);
    // 进入/editor/shared_intelligent_app，不是订阅，跳转到editor.经测试create.imagica.ai会自动补全路径，加上/editor，因此不需要配置完整路径
    // else if (location.pathname.includes("denied-tip") || location.pathname.includes("/editor/shared_intelligent_app")) {
    //   navigate("/editor");
    // }
  }

  linkToCreator(fotProcessDep: FotProcessDep): void {
    const localDomain = fotProcessDep.location.hostname;
    const isShareDomain =
      !isBlank(this.homePluginStore.state.customDomain) || this.settings.shareDomain.includes(localDomain);
    // 我们自己创作者方的域名不跳转，解决一直跳转陷入无限循环
    // 不为第三方的域名，也不是我们自己专门的share域名，都是创作者域名
    if (!isShareDomain) return;
    // 当前是subscriber, 去掉fot/#/editor
    if (this.homePluginStore.state.isShare) {
      const url = fotProcessDep.location.href.replace(/\/fot(\/)*#\/editor(\/)*/, "");
      fotProcessDep.history.pushState({}, "", url);
      return;
    }
    // 当前是creator, 跳转到我们自己的editor链接
    const imagicaUrl = this.settings.ImagicaUrl.replace(/\/editor\/$/, "");
    let path = fotProcessDep.reactLocation.pathname;
    if (path === "/") {
      path = "/editor";
    }
    const hash = `${path}${fotProcessDep.reactLocation.search}`;
    const url = `${imagicaUrl}${hash}`;
    fotProcessDep.location.href = url;
  }
}
