"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.preDefinedGlobalFunctions = exports.defaultApis = void 0;
const EXPMODE_KEY = "EXPMODE";
const getEnableExperimentalFeature = () => {
    // 这里不能用JSONUtil，用JSON需要捕捉错误。所以这样写最简单
    const value = localStorage.getItem(EXPMODE_KEY) ?? "";
    if (value === "true") {
        return true;
    }
    return false;
};
const enableExperimentalFeature = getEnableExperimentalFeature();
exports.defaultApis = [
    {
        label: "Stock Prices",
        value: "showStockInterface",
        groupType: "stockInfo",
        groupTitle: "interfaceGeneration",
        description: "Check stock price and information, buy and sell stock",
        aliases: ["Show Stock Interface", "Stock API"],
        order: 2,
    },
    {
        label: "Add Data Source",
        value: "SourceOfTruth",
        groupType: "general",
        groupTitle: "general",
        description: "",
        order: 1,
    },
    {
        label: "DALL·E 2",
        value: "imageGen",
        groupType: "imageGen",
        groupTitle: "imageGeneration",
        description: "DALL·E 2 based on a textual description, using the DALL·E2 deep learning model",
        aliases: ["Brain Stable Diffusion", "Generate an Image "],
        order: 6,
    },
    {
        label: "Image search",
        value: "imageSearch",
        groupType: "imageGen",
        groupTitle: "imageGeneration",
        description: "",
        order: 2,
    },
    {
        label: "Protein",
        value: "protein",
        groupType: "customJsFunction",
        groupTitle: "myFunctions",
        description: "",
        hide: true,
    },
    {
        label: "Video",
        value: "video",
        groupType: "video",
        description: "",
        hide: !enableExperimentalFeature,
    },
    {
        label: "Maps",
        value: "map",
        description: "",
        groupType: "interfaceGeneration",
        groupTitle: "interfaceGeneration",
        hide: true,
        aliases: ["Show Map Interface"],
        order: 1,
    },
    {
        label: "Jd Shopping",
        value: "jdShopping",
        groupType: "jdShopping",
        description: "",
        hide: !enableExperimentalFeature,
    },
    // Waiting to provide Amazon's interface
    {
        label: "Amazon Shopping(Old)",
        value: "amazonShopping",
        description: "",
        hide: !enableExperimentalFeature,
    },
    {
        label: "Weee Shopping(Old)",
        value: "weeeShopping",
        description: "",
        hide: !enableExperimentalFeature,
    },
    {
        label: "Split Output",
        value: "splitOutput",
        groupType: "general",
        groupTitle: "general",
        description: "",
        order: 2,
    },
    {
        label: "Ask Imagica",
        value: "askBrain",
        groupType: "general",
        groupTitle: "general",
        description: "",
        order: 0,
    },
    {
        label: "Show Food Interface",
        value: "showFoodInterface",
        description: "",
        hide: true,
    },
    {
        // STUD-1008 use Midjourney
        label: "Midjourney",
        value: "midjourneyV2",
        groupType: "imageGen",
        groupTitle: "imageGeneration",
        description: "",
        aliases: ["MidjourneyV2"],
    },
    {
        label: "Chat Interface",
        value: "chatInterface",
        groupType: "interfaceGeneration",
        groupTitle: "interfaceGeneration",
        description: "",
        order: 2,
    },
];
exports.preDefinedGlobalFunctions = [
    {
        label: "Generate an Image",
        groupType: "imageGen",
        groupTitle: "imageGeneration",
        description: "",
        order: 0,
    },
    {
        label: "Generate a Photo",
        groupType: "imageGen",
        groupTitle: "imageGeneration",
        description: "",
        order: 1,
    },
    {
        label: "Animation Style",
        groupType: "imageGen",
        groupTitle: "imageGeneration",
        description: "",
        aliases: ["Animation Style Image"],
        order: 3,
    },
    {
        label: "Cybernetic Painting Style",
        groupType: "imageGen",
        groupTitle: "imageGeneration",
        description: "",
        aliases: ["Cybernetic Digital Painting"],
        order: 4,
    },
    {
        label: "Cute Emoji Style",
        groupType: "imageGen",
        groupTitle: "imageGeneration",
        description: "",
        aliases: ["Tiny Cute Emoji"],
        order: 5,
    },
    {
        label: "Amazon",
        groupType: "interfaceGeneration",
        groupTitle: "interfaceGeneration",
        description: "",
        aliases: ["Amazon Shopping"],
        order: 0,
    },
    {
        label: "YouTube",
        groupType: "interfaceGeneration",
        groupTitle: "interfaceGeneration",
        description: "",
        aliases: ["YouTube Videos"],
        order: 4,
    },
    {
        label: "Weee Groceries",
        groupType: "interfaceGeneration",
        groupTitle: "interfaceGeneration",
        description: "",
        order: 3,
    },
];
