import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

const PUBLIC_DOMAIN = settings.publicDomain;
const PUBLIC_APP_PATH = ["/editor", "/signup", "/signin"];

const DomainBasedPermission = props => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const domain = window.location.hostname;
    const path = location.pathname;
    if (domain.toLowerCase().includes(PUBLIC_DOMAIN.toLowerCase()) && PUBLIC_APP_PATH.every(x => !path.includes(x))) {
      navigate("/editor");
    }
  }, []);

  return <>{props.children}</>;
};

export default DomainBasedPermission;
