"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Emitter = void 0;
class Emitter {
    constructor() {
        this.subscribers = {};
        this.nextId = 0;
    }
    getNextId() {
        return this.nextId++;
    }
    emit(event, arg) {
        const subscriber = this.subscribers[event];
        if (subscriber === undefined) {
            return;
        }
        Object.keys(subscriber).forEach(key => {
            const entry = subscriber[key];
            if (entry.sync) {
                entry.callback(arg);
            }
            else {
                setTimeout(() => entry.callback(arg), 0);
            }
        });
    }
    on(event, callback, sync = false) {
        const id = this.getNextId();
        if (!this.subscribers[event])
            this.subscribers[event] = {};
        this.subscribers[event][id] = new Entry(callback, sync);
        return {
            off: () => {
                delete this.subscribers[event][id];
                if (Object.keys(this.subscribers[event]).length === 0)
                    delete this.subscribers[event];
            },
        };
    }
    off(event, callback) {
        const subscriber = this.subscribers[event];
        if (!subscriber) {
            return;
        }
        if (callback === undefined) {
            delete this.subscribers[event];
            return;
        }
        const list = Object.entries(subscriber).filter((item) => item[1].callback === callback);
        if (list.length === 0) {
            return;
        }
        const id = list[0][0];
        delete this.subscribers[event][id];
        if (Object.keys(this.subscribers[event]).length === 0)
            delete this.subscribers[event];
    }
}
exports.Emitter = Emitter;
class Entry {
    constructor(callback, sync) {
        this.callback = callback;
        this.sync = sync;
    }
}
