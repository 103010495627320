import { Signal } from "@preact/signals-react";
import { ApiStatuses, FunctionModel } from "@views/thinking-layout-editor/types";
import dayjs from "dayjs";

export class FunctionListBloc {
  isFuncLoading = new Signal(false);
  isFuncId = new Signal("");
  screenLoader = new Signal(false);

  showList = new Signal(false);

  loadingOfFunction?: FunctionModel;

  apiStatusOfFunctions = new Signal(ApiStatuses.Idle);

  showItems: Signal<FunctionModel[]> = new Signal([]);

  constructor(public functionList: FunctionModel[]) {
    this.showItems.value = functionList;
  }

  public changeListView(value: boolean): void {
    this.showList.value = value;
  }

  sortByRefresh(sortBy: string): void {
    // this.showItems.value = [];
    if (sortBy === "-updated_at") {
      const newSort = this.functionList.sort((a, b) => {
        return dayjs(b.updatedAt).unix() - dayjs(a.updatedAt).unix();
      });
      this.showItems.value = [...newSort];
    } else {
      const newSort = this.functionList.sort((a, b) => {
        return dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix();
      });
      this.showItems.value = [...newSort];
    }
  }

  // async searchFunction(value: string): Promise<void>  {

  // }
}
