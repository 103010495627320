// ResponseTabs.tsx
import React from "react";
import { Tabs } from "antd";
import ResponseContent from "./ResponseContent";
import ResponseStatus from "./ResponseStatus";
import style from "./Response.module.css";
import { ApiResponse } from "./CustomApiPageBloc";
import HeaderList from "./HeaderList";

interface ResponseTabsProps {
  responseData: ApiResponse | null;
  bloc: any;
}

const ResponseTabs: React.FC<ResponseTabsProps> = ({ responseData, bloc }) => {
  const items = [
    {
      key: "bodyParam",
      label: "Body",
      children: <ResponseContent responseData={responseData} className="response-body" />, // Use data prop here
    },
    {
      key: "headersParam",
      label: "Headers",
      children: <HeaderList isResponse={true} headerList={bloc.state.responseHeaderList.value} />,
    },
  ];

  return (
    <div className={style["response-box"]}>
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={<ResponseStatus responseData={responseData} />}
        items={items}
      ></Tabs>
    </div>
  );
};

export default ResponseTabs;
