import func from "@uikit/func";
import { findIndex, set } from "lodash";
import { getIt } from "@uikit/getIt";
import { useCallback, useRef } from "react";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { AddNode } from "@uikit/service/AddNode";

export default function useHandleReactFlowDrag({ needAddNodeByDrag }) {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const fotReactFlow = getIt(FotReactFlow);
  const addNode = getIt(AddNode);
  const zoom = fotReactFlow.getZoom();
  const currNewNodeId = useRef("");
  const nodes = creatorNodesStore.getNodes();

  const onDragOver = useCallback(
    event => {
      event.preventDefault();
      if (
        Math.abs(event.clientX - creatorRefStore.dragOverClientXY.current.x) <= 0 &&
        Math.abs(event.clientY - creatorRefStore.dragOverClientXY.current.y) <= 0
      ) {
        if (func.isEmpty(creatorRefStore.dragOverTimer.current)) {
          creatorRefStore.dragOverTimer.current = setTimeout(() => {
            if (needAddNodeByDrag()) {
              const { nodeId } = addNode.addNodeV2(event, {
                displayType: "uploadFile",
              });
              currNewNodeId.current = nodeId;
            }
          }, 500);
        }
      } else {
        clearTimeout(creatorRefStore.dragOverTimer.current);
        creatorRefStore.dragOverTimer.current = null;
      }
      creatorRefStore.dragOverClientXY.current = {
        x: event.clientX,
        y: event.clientY,
      };
    },
    [nodes.length, zoom]
  );

  const onDrop = useCallback(
    event => {
      event.preventDefault();
      creatorRefStore.dragOverTimer.current = null;
      currNewNodeId.current = "";
      // 快速drop时如果还没有创建新node则创建
      // event.target.className === 'react-flow__pane' 为drop 文件到 canvas 空白处
      if (needAddNodeByDrag() || event.target.className === "react-flow__pane") {
        currNewNodeId.current = addNode.addNodeV2(event, {
          displayType: "uploadFile",
        });
      }
      creatorNodesStore.setNodes(prevList => {
        const index = findIndex(prevList, node => {
          return (
            node.data.displayType === "uploadFile" &&
            func.isEmpty(node.data.textAreaValue) &&
            node.id === currNewNodeId.current.nodeId
          );
        });
        if (index !== -1) {
          set(prevList, `${index}.data.dropUploadFileData`, {
            nodeId: prevList[index].id,
            fileData: event.dataTransfer.files[0],
          });
        }
        return [...prevList];
      });
      // zoom改变需要更新onDrop函数以便获取最新zoom level
    },
    [zoom]
  );

  const onDragLeave = useCallback(event => {
    if (!func.isEmpty(event.relatedTarget)) return;
    const nodeId = currNewNodeId.current;
    currNewNodeId.current = "";
    // 删掉新创建的没有上传数据的uploadfile node
    creatorNodesStore.setNodes(prevList => {
      return prevList.filter(x => {
        return !(x.id === nodeId && x.data.displayType === "uploadFile" && func.isEmpty(x.data.textAreaValue));
      });
    });
  }, []);

  return {
    onDragOver,
    onDrop,
    onDragLeave,
  };
}
