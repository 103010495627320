import styles from "./AccountAlert.module.scss";
export function AccountAlert(props: { title: string; detail: string; tapDone: () => void }) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.detail}>{props.detail}</div>
      <button className={styles.done} onClick={() => props.tapDone()}>
        Done
      </button>
    </div>
  );
}
