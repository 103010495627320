import { AModal } from "@uiview/views/AModal/AModal";

type BadSlotModalProps = { onClose: () => void; onOk: () => void; badSlotName: string };

export function BadSlotModal(props: BadSlotModalProps) {
  return (
    <AModal
      title={<div>{props.badSlotName}</div>}
      open={true}
      onCancel={props.onClose}
      onOk={props.onOk}
      buttons={[]}
      width={620}
    ></AModal>
  );
}
