import { useCallback, useMemo } from "react";
import store from "@store/index";
import { useDispatch } from "react-redux";
import { fotActions } from "@store/fot";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import func from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import { GROUPID_REG, NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import { map } from "lodash";
import { useReactFlowMethods } from "./useReactFlowMethods";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { getIt } from "@uikit/getIt";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorCanvasDeleteMethods } from "@uikit/service/CreatorCanvasDeleteService";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { previewStore } from "@uiview/store/PreviewStore";
import { EdgeRunAll } from "@uikit/service/EdgeRunAll";
import { EdgeRunQuery } from "@uikit/edgeRun/EdgeRunQuery";
import { useAModalProvider } from "@uikit/context/AModalContext/useAModalProvider";

// 右键菜单中通用的方法
export function useRightMenuMethods(): {
  handleMenuDelete: () => void;
  setManualRecall: (variableName: string) => Promise<void>;
  clickCancelVariableModal: () => void;
  clickCreateVariableModalSave: (variableName: string, isManualConnection: any) => void;
  clickToCreateVariable: (isManualConnection: any) => void;
  setStartingPointInput: (
    sourceNodes: {
      data: any;
    },
    variableName: string
  ) => Promise<any>;
} {
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const edgeRunAll = getIt(EdgeRunAll);
  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const creatorCanvasDeleteMethods = getIt(CreatorCanvasDeleteMethods);
  const creatorPreviewService = getIt(CreatorPreviewService);

  const reactFlowMethods = useReactFlowMethods();

  const edgeRunQuery = getIt(EdgeRunQuery);
  const { showModal } = useAModalProvider();

  // redux
  const dispatch = useDispatch();
  const setVariableList = useCallback(
    (val: any) => {
      dispatch(fotActions.setVariableList(val));
    },
    [dispatch]
  );

  const setStartingPointInput = useCallback(
    async (sourceNodes: { data: any }, variableName: string) => {
      const variableList = store.getState().fot.variableList as any[];
      if (variableList.some(x => x.name === variableName)) return;
      const obj = {
        name: variableName,
        node: {
          ...sourceNodes,
          data: JSON.parse(JSON.stringify(sourceNodes.data)), // 去掉函数引用
        },
      };
      setVariableList([...variableList, obj]);
    },
    [setVariableList]
  );
  const setManualRecall = useCallback(
    async (variableName: string) => {
      try {
        const edge = creatorRefStore.multipleEdgeHostRef.current;
        const sourceNodes = creatorNodesStore.getNodes().find((x: { id: any }) => x.id === edge.source) as any;
        // 把变量放进边的数据里
        if (!edge.data.lineParam.createFromCot) {
          // 非 cot 需要将起始点input, 保存至变量列表。
          await setStartingPointInput(sourceNodes, "Input");
          // 将 input 设置为变量
          creatorStoreMethods.setVarilableNames(edge, "Input");
        }
        // cot 创建 新变量保存
        creatorStoreMethods.setVarilableNames(edge, variableName);
        const { identifier } = (await edgeRunQuery.getAndSetQuery(edge.id, edge.data.lineParam.enterText)) as any;
        creatorStoreMethods.setIdentifier(edge.id, identifier);
        // 打字效果
        await creatorStoreMethods.setEdgeValueByWriter(edge);
        // run
        // fixme:opt: clickRunAllBtn
        await edgeRunAll.clickRunAllBtn([sourceNodes]);
      } catch (error) {
        console.error("error", error);
      }
    },
    // eslint-disable-next-line
    [creatorStoreMethods.setVarilableNames, creatorStoreMethods.setEdgeValueByWriter, setStartingPointInput]
  );
  const clickCancelVariableModal = useCallback(() => {
    if (!func.isEmpty(creatorRefStore.manuallyConnectedEdges.current)) {
      // 取消删除新增边
      creatorEdgesStore.setEdges(prevList => {
        return prevList.filter(x => x.id !== creatorRefStore.manuallyConnectedEdges.current.id);
      });
    }
  }, []);

  const clickCreateVariableModalSave = useCallback(
    (variableName: string, isManualConnection: any) => {
      if (func.isEmpty(creatorRefStore.nodeContextMenuRef.current.data.textAreaValue)) {
        func.customMsg({
          content: "The node is empty",
          type: "info",
        });
        clickCancelVariableModal();
        return;
      }
      const variableList = store.getState().fot.variableList;
      if (variableList.some((x: { name: any }) => x.name === variableName)) {
        func.customMsg({
          content: "A variable with the same name already exists",
          type: "info",
        });
        clickCancelVariableModal();
        return;
      }

      setStartingPointInput(creatorRefStore.nodeContextMenuRef.current, variableName);

      func.customMsg({
        content: "Saved successfully",
        type: "success",
      });
      const eventProperties = {
        name: variableName, //TODO
      };
      logEvent("create_variable", eventProperties);

      // 手动链接后，重新调用 prompt_generation api去创建新的 edge prmpt
      if (isManualConnection) {
        setManualRecall(variableName);
      }
    },
    [clickCancelVariableModal, setManualRecall, setStartingPointInput]
  );

  const clickToCreateVariable = useCallback(
    (isManualConnection: any) => {
      showModal({
        title: "What is this Node?",
        subTitle: "Give this node a name so the Edge knows how to use it in your function.",
        buttons: ["Cancel", "Confirm"],
        input: {
          type: "input",
          require: true,
          placeholder: "Enter variable name",
        },
        onOk({ reason, close }: any) {
          clickCreateVariableModalSave(reason, isManualConnection);
          close();
        },
        onCancel() {
          clickCancelVariableModal();
        },
      });
    },
    [clickCancelVariableModal, clickCreateVariableModalSave, showModal]
  );

  const deleteSelectedElements = useCallback(
    (callback = (): any => {}): void => {
      // opt:
      const checkNodeArr = store.getState().fot.checkNodeArr as any[];

      if (func.isEmpty(checkNodeArr)) return;
      const nodes = checkNodeArr.reduce((prev, x) => {
        const arr = [{ id: x }] as any[];
        if (GROUPID_REG.test(x)) {
          // const node = getNode(x);
          // opt:
          const node = ReactFlowNodeUtil.getNodeById(x, creatorNodesStore.getNodes()) as any;

          arr.push(...node.data.childNode.map((n: any) => ({ id: n })));
        }
        return prev.concat(arr);
      }, []);

      const normalNodes = nodes.filter((node: any) => !NEW_EDGE_REG.test(node.id));

      const edgeNodes = nodes.filter((node: any) => NEW_EDGE_REG.test(node.id));

      // stud-1639: delete node
      const params: PreviewApp.RemovePreviewAppNodeArgs = {
        ids: map(normalNodes, "id"),
        edgeNodeIds: map(edgeNodes, "id"),
        onSuccess() {
          creatorCanvasDeleteMethods.handleDeleteNodes(nodes);
          callback();
        },
        onOpenModal({ subTitle, removeIds }) {
          showModal({
            title: "Warning",
            width: 520,
            subTitle,
            buttons: ["Cancel", "Confirm"],
            onOk: ({ close }: any) => {
              params.onSuccess(removeIds);
              previewStore.removeByNodeIds(removeIds);
              close();
            },
          });
        },
      };
      creatorPreviewService.triggerRemovePreviewAppNode(params);
    },
    [creatorCanvasDeleteMethods]
  );

  const handleMenuDelete = useCallback(() => {
    deleteSelectedElements(() => reactFlowMethods.onPaneClick());
  }, [deleteSelectedElements, reactFlowMethods]);

  return useMemo(
    () => ({
      handleMenuDelete,
      setManualRecall,
      clickCancelVariableModal,
      clickCreateVariableModalSave,
      clickToCreateVariable,
      setStartingPointInput,
    }),
    [
      handleMenuDelete,
      setStartingPointInput,
      setManualRecall,
      clickCancelVariableModal,
      clickCreateVariableModalSave,
      clickToCreateVariable,
    ]
  );
}
