import React, { useEffect, useRef, useState } from "react";
import func from "@uikit/func";

import "../components-css/Video.css";

function Video(props) {
  const [videoShow, setVideoShow] = useState(true);

  const iframePlayerRef = useRef();

  const handleHeaderClick = e => {
    // 阻止冒泡
    e.stopPropagation();
    setVideoShow(false);
  };

  const onPlayerReady = event => {
    if (func.isEmpty(event)) return;
    event.target.playVideo();
  };

  const initYTPlayer = () => {
    const globalId = props.src.content.split("embed/");
    try {
      const YTplayer = new window.YT.Player(iframePlayerRef.current, {
        videoId: globalId[1],
        events: {
          onReady: onPlayerReady,
        },
      });
    } catch (error) {
      console.error("Player not loaded successfully, please refresh the page");
    }
  };

  useEffect(() => {
    if (func.isEmpty(iframePlayerRef.current)) return;
    initYTPlayer();
  }, [videoShow]);

  return (
    <section className="CardHomeVideo">
      <section className="videoIframeBox">
        {videoShow ? (
          <section className="videoPlayBox" onClick={handleHeaderClick}>
            <section className="videoPlayButton">
              <span className="iconfont icon-bofangqi-bofang"></span>
            </section>
            <img className="videoThumbnail" src={props.src.thumbnail}></img>
          </section>
        ) : (
          <div ref={iframePlayerRef} className="videoThumbnail"></div>
        )}
      </section>
      {!func.isEmpty(props.src.thought) ? <section className="angleDescription">{props.src.thought}</section> : null}
    </section>
  );
}

export default Video;
