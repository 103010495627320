import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PlayCircleFilled } from "@ant-design/icons";
import { Button } from "antd";

import { studioActions } from "@store/studio";
import { fotActions } from "@store/fot";
import { feedActions } from "@store/feed";
import { Api } from "@uikit/service/api";
import studioPublicMethod from "@uikit/StudioPublicMethod";
import func from "@uikit/func";

const StudioRunAllButton = () => {
  const dispatch = useDispatch();
  const api = new Api(false);

  const setLoadingState = val => {
    dispatch(studioActions.setLoadingState(val));
  };

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);
  const setSelectedTemplate = val => {
    dispatch(studioActions.setSelectedTemplate(val));
  };

  const setThoughts = val => {
    dispatch(feedActions.setThoughts(val));
  };

  const [computeGraph, setComputeGraph] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const getComputeGraph = async () => {
    dispatch(fotActions.setFetching(true));
    const res = await api.getWithError(
      `/be/bas-demo-v4/brain_studios/compute_graph?template_name=${selectedTemplate.name}`
    );

    dispatch(fotActions.setFetching(false));
    setComputeGraph(res.data);
  };

  const runIntelligentApp = () => {
    return api.getWithError(`/be/bas-demo-v4/brain_studios/run_intelligent_app?template_name=${selectedTemplate.name}`);
  };

  const clickRunAllButton = async () => {
    if (!selectedTemplate.name) {
      func.messageUtil("No project selected", "warning");
      return;
    }

    if (!computeGraph || !computeGraph.chain || computeGraph.chain.length === 0) {
      func.messageUtil("No compute graph found for this project, please run each component separately.", "warning");
      return;
    }
    // set laoding
    dispatch(fotActions.setFetching(true));
    setButtonLoading(true);
    setLoadingState({
      promptCard: true,
      layoutCard: true,
      variableCard: true,
      logicCard: true,
      layoutRunBtn: true,
      promptRunBtn: true,
      logicRunBtn: true,
    });
    try {
      // run all and get result
      const res = await runIntelligentApp();
      // set template and UI
      setSelectedTemplate(Object.assign({}, res.data, { name: res.data.template_name }));
      const layoutData = (res.data || {}).layout_ui;
      const resultsArr = [];

      for (let i = 0; i < layoutData.length; i++) {
        const type = layoutData[i].template || "textbox";
        const value = layoutData[i].value[type];
        const outputObj = await studioPublicMethod.handleStockData(type, value);
        const results = studioPublicMethod.toThoughts(type, outputObj);
        resultsArr.push(results);
      }

      setThoughts(resultsArr);
    } catch (error) {
      func.messageUtil("Failed to run intelligent app");
      console.error(error);
    } finally {
      // set loading
      setButtonLoading(false);
      setLoadingState({
        promptCard: false,
        layoutCard: false,
        variableCard: false,
        logicCard: false,
        layoutRunBtn: false,
        promptRunBtn: false,
        logicRunBtn: false,
      });
      dispatch(fotActions.setFetching(false));
    }
  };

  useEffect(() => {
    if (selectedTemplate.name) {
      getComputeGraph();
    }
    // setComputeGraph(graph);
  }, [selectedTemplate.name]);

  return (
    <Button
      className="Run-All-Button"
      type="primary"
      icon={<PlayCircleFilled />}
      onClick={clickRunAllButton}
      loading={buttonLoading}
    >
      Run All
    </Button>
  );
};

export default StudioRunAllButton;
