import { ObjectRelationGqlService } from "./ObjectRelationGqlService";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { SaveCreateService } from "imagica-corekit/dist/cases/saveService/SaveCreateService";
import { SaveApiFailedError } from "imagica-corekit/dist/cases/saveService/SaveErrors";
import { FotSaveEvent } from "./FotSaveEvent";
import { FotReduxStore } from "@uikit/store/FotReduxStore";

type SaveProjectParams = {
  /** updated attributes to be saved */
  attributes: Record<string, any>; // = {};
  /** objectId of existing project */
  projectId: string;
  notShowSuccessTip?: any;
  /** attr_indexer or uuid of existing project */
  attrIndexer?: string;
};
/**
 * 包含原来部分 hook 逻辑
 *
 * - `useCreateProject`
 * - `useCreateFunction`
 * - `useSaveProject`
 *
 * TODO: 该文件现对应 SaveCreateService, 应该是 FotSaveCreateService, 当createFunction 被替换后，需要移除该文件
 */
export class CreateService {
  constructor(
    public fotReduxStore: FotReduxStore,
    public gql: ObjectRelationGqlService,
    public personalFunctionStore: PersonalFunctionStore,
    public saveCreateService: SaveCreateService,
    public fotSaveEvent: FotSaveEvent
  ) {}

  /**
   * from `HomeUsePluginUtil.updateSelectedTemplate`
   *
   * 未来应该会有一个 fotReduxStore 这样的实现类，单数放该类方法
   *
   * @param args
   */
  updateSelectedTemplate(args: Record<string, any>): void {
    const selectedTemplate = this.fotReduxStore.getState().studio.selectedTemplate;
    this.fotReduxStore.setSelectedTemplate({ ...selectedTemplate, ...args } as any);
  }

  /**
   * @param data
   * @param isGlobal
   * @returns
   */
  async createProject(data: any, isGlobal = false): Promise<any> {
    const userMe = this.fotReduxStore.getState().editor.me as any;

    try {
      return await this.saveCreateService.createProject({
        data,
        objectId: userMe.objectId,
        isGlobal,
        saveEvents: this.fotSaveEvent,
      });
    } catch (error) {
      if (error instanceof SaveApiFailedError) {
        this.fotSaveEvent.onErrorMessge(error);
      }
      return Promise.reject(error);
    }
  }

  /**
   * TODO: 需要移动到 SaveCreateService 中
   * @param data
   * @param isGlobal
   * @param callback
   * @returns
   */
  async createFunction(data: any, isGlobal = false, callback: (object: any) => void): Promise<any> {
    const userMe = this.fotReduxStore.getState().editor.me as any;

    if (!userMe.objectId) {
      throw Error("unable to get me id");
    }

    // create new function object
    const object = await this.gql.createObject(
      {
        name: "studio_function",
        isGlobal,
        attributes: data,
      },
      ""
    );

    // if not global function, create relation between user and function object
    if (!isGlobal) {
      await this.gql.createRelation(
        {
          destId: parseInt(object.id),
          sourceId: parseInt(userMe.objectId),
          type: "studio_function",
        },
        ""
      );
    }
    // return attributes
    const attributes = JSON.parse(object.attributes);
    attributes.id = object.id;
    if (typeof callback === "function") {
      // FIXME: 原来使用的 HomeUsePluginUtil.handleUserSavedFunctions 回调传入，但是只传了一个参数
      // 现在把 SaveTemplateService 在 store 中注册时，原来 HomeUsePluginUtil 提前调用了 getIt 会导致报错，暂时兼容这里的写法
      callback(object);
    }
    return attributes;
  }

  /**
   * @param params
   * @returns
   */
  async saveProject(params: SaveProjectParams): Promise<any> {
    const userMe = this.fotReduxStore.getState().editor.me as any;

    try {
      return await this.saveCreateService.saveProject({
        attributes: params.attributes,
        attrIndexer: params.attrIndexer,
        objectId: userMe.objectId,
        notShowSuccessTip: false,
        saveEvent: this.fotSaveEvent,
        projectId: "",
      });
    } catch (error) {
      this.fotSaveEvent.onErrorMessge({ message: error });
      return Promise.reject(error);
    }
  }
}
