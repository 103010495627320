import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { PreviewAppUtil } from "@uiview/views/PreviewApp/PreviewAppUtil";
import { previewStore } from "@uiview/store/PreviewStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";

export class PreviewComposeState {
  constructor(
    public creatorNodesStore: CreatorNodesStore,
    public previewAppStore: PreviewAppStore,
    public creatorSaasAppStore: CreatorSaasAppStore,
    public fotHomeStore: HomeStore
  ) {}
  // preview panel 显示状态, null 未挂载, false 不显示,true 显示
  isInHistory(): boolean | null {
    if (this.fotHomeStore.state.featureTags.enablePublishV3) {
      return (
        this.previewAppStore.state.historyShowStatus &&
        !this.previewAppStore.state.publishShowStatus &&
        !this.previewAppStore.state.previewShowStatus
      );
    }
    return (
      this.previewAppStore.state.historyShowStatus &&
      !this.previewAppStore.state.publishShowStatus &&
      !this.previewAppStore.state.previewShowStatus &&
      !this.previewAppStore.state.settingShowStatus
    );
  }
  appStates(): {
    isChatApp: boolean;
    chatMode: PreviewApp.ChatAppModeType | undefined;
  } {
    const chatApp = PreviewAppValueLangUtil.isChatAiApp(this.creatorSaasAppStore.state.saasUIData);
    return {
      isChatApp: PreviewAppValueLangUtil.isChatAiApp(this.creatorSaasAppStore.state.saasUIData),
      chatMode: chatApp
        ? PreviewAppUtil.getChatAiMode({
            saasUIData: this.creatorSaasAppStore.state.saasUIData,
            nodes: this.creatorNodesStore.getNodes(),
          })
        : undefined,
    };
  }
  disabledPublish(): boolean {
    // bsf-4912 replace thoughts
    return PreviewAppUtil.isDisabledPublish({
      saasUIData: this.creatorSaasAppStore.state.saasUIData,
      isStaticMode: !!PreviewAppValueLangUtil.isStaticApp(this.creatorSaasAppStore.state.saasUIData),
      thoughts: this.creatorSaasAppStore.state.saasUIData.output,
      selectedUI: previewStore.state.selectedUI,
      nodes: this.creatorNodesStore.getNodes(),
    });
  }
  disabledPublishV2(): boolean {
    // bsf-4912 replace thoughts
    return PreviewAppUtil.isDisabledPublishV2({
      saasUIData: this.creatorSaasAppStore.state.saasUIData,
      isStaticMode: !!PreviewAppValueLangUtil.isStaticApp(this.creatorSaasAppStore.state.saasUIData),
      thoughts: this.creatorSaasAppStore.state.saasUIData.output,
      selectedUI: previewStore.state.selectedUI,
      nodes: this.creatorNodesStore.getNodes(),
    });
  }
  disabledPublishByInput(): boolean {
    return PreviewAppUtil.isDisabledPublishByInput({
      saasUIData: this.creatorSaasAppStore.state.saasUIData,
      isStaticMode: !!PreviewAppValueLangUtil.isStaticApp(this.creatorSaasAppStore.state.saasUIData),
      thoughts: this.creatorSaasAppStore.state.saasUIData.output,
      selectedUI: previewStore.state.selectedUI,
      nodes: this.creatorNodesStore.getNodes(),
    });
  }
}
