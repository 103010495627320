import { Tooltip, Upload } from "antd";
import useSourceOfTurth from "../../custom-hooks/useSourceOfTurth";
import styles from "../components-css/SourceOfTruth.module.css";
import func from "@uikit/func";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { supportList } from "@uiview/views/Nodes/constants";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import classNames from "classnames";

const { Dragger } = Upload;

function SourceOfTruth({ setEdgeLineParam, lineParam }) {
  const creatorNodesStore = getIt(CreatorNodesStore);

  const {
    removeFile,
    uploadS3,
    beforeUpload,
    fileList,
    setPreFileUrl: setWebUrl,
    preFileUrl: webUrl,
  } = useSourceOfTurth({ setEdgeLineParam, lineParam });

  const onDrop = e => {
    e.stopPropagation();
    // 删掉新创建的没有上传数据的uploadfile node
    creatorNodesStore.setNodes(prevList => {
      return prevList.filter(x => !(x.data.displayType === "uploadFile" && func.isEmpty(x.data.textAreaValue)));
    });
  };

  return (
    <div
      className={styles.sourceBox}
      onDragOver={e => e.stopPropagation()}
      onDrop={onDrop}
      onDragLeave={e => e.stopPropagation()}
    >
      <div className={styles.title}>Add a Knowledge Source</div>
      <div className={classNames(styles.uploadWrapper, styles.wrapperFromScale)}>
        <div className={styles.desc}>Upload files from your computer.</div>
        <SourceTooltip title={<SurportFile />}>
          <a onClick={e => e.preventDefault()}>Supported file formats</a>
        </SourceTooltip>
        <Dragger
          name="file"
          className={styles.dragger}
          multiple={false}
          onRemove={removeFile}
          customRequest={uploadS3}
          // customRequest={() => {}}
          beforeUpload={beforeUpload}
          fileList={fileList}
        >
          <div className={styles.upload}>
            <div className={styles.icon}>
              <img alt="" src={`${settings.S3CDN}${settings.viewAssetsPath}upload.svg`} />
            </div>
            <div className={styles.desc}>Drag and drop your files here to upload them.</div>
            <div className={styles.limitSize}>50 Mb max file size.</div>
          </div>
        </Dragger>
      </div>
      {/* 没有功能暂时屏蔽 */}
      <div className={styles.linkWrapper}>
        {/* <div className={styles.desc}>Select a service to connect</div>
        <ul>
          <li>
            <a href="javascript:void(0);">
              <img alt="" src={service1Svg} />
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">
              <img alt="" src={service2Svg} />
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">
              <img alt="" src={service3Svg} />
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">
              <img alt="" src={service4Svg} />
            </a>
          </li>
        </ul> */}
        <SourceTooltip title={"Please enter an HTML web link. We currently do not support maps."}>
          <input
            type="text"
            placeholder="Paste a link..."
            value={webUrl}
            onChange={e => {
              setWebUrl(e.target.value);
            }}
          />
        </SourceTooltip>
      </div>
    </div>
  );
}

function SurportFile() {
  // const surportList = ["PDF", "CSV", "DOCX", "TXT", "AAC", "EPUB"];
  return (
    <div className={styles.surportWrapper}>
      {supportList.map(s => (
        <span key={s} className={styles.surportItem}>
          {s}
        </span>
      ))}
    </div>
  );
}

function SourceTooltip({ children, title }) {
  return (
    <Tooltip
      placement="top"
      title={title}
      overlayInnerStyle={{
        borderRadius: "10px",
        backgroundColor: "rgba(0, 0, 3, 0.85)",
        padding: "20px 30px",
      }}
    >
      {children}
    </Tooltip>
  );
}

export default SourceOfTruth;
