import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export enum SwitcherModeType {
  imagica = "imagica",
  canvas = "canvas",
}

export class NavBarStoreState {
  clickItemButton: boolean = false;
  isNewCustomFunctionOpen: boolean = false;
  newFunctionData: any = {};
  customFunctionTitle: string = "";

  /**
   * 切换按钮模式 imagica 和 canvas按钮
   */
  switchMode: SwitcherModeType = SwitcherModeType.canvas;

  shareLoading = false;

  showPublishFunctionConfirm = false;
  publishFunctionLoading = false;
  publishFunctionName = "";
  showPublishDisableTip: string = "";

  publishLoading = false;

  copyWith(params: {
    clickItemButton?: boolean;
    isNewCustomFunctionOpen?: boolean;
    newFunctionData?: any;
    customFunctionTitle?: string;
    switchMode?: SwitcherModeType;
    shareLoading?: boolean;
    showPublishFunctionConfirm?: boolean;
    publishFunctionLoading?: boolean;
    publishFunctionName?: string;
    showPublishDisableTip?: string;
    publishLoading?: boolean;
  }) {
    const newState = new NavBarStoreState();
    newState.clickItemButton = params.clickItemButton ?? this.clickItemButton;
    newState.isNewCustomFunctionOpen = params.isNewCustomFunctionOpen ?? this.isNewCustomFunctionOpen;
    newState.newFunctionData = params.newFunctionData ?? this.newFunctionData;
    newState.customFunctionTitle = params.customFunctionTitle ?? this.customFunctionTitle;
    newState.switchMode = params.switchMode || this.switchMode;
    newState.shareLoading = params.shareLoading ?? this.shareLoading;
    newState.showPublishFunctionConfirm = params.showPublishFunctionConfirm ?? this.showPublishFunctionConfirm;
    newState.publishFunctionLoading = params.publishFunctionLoading ?? this.publishFunctionLoading;
    newState.publishFunctionName = params.publishFunctionName ?? this.publishFunctionName;
    newState.showPublishDisableTip = params.showPublishDisableTip ?? this.showPublishDisableTip;
    newState.publishLoading = params.publishLoading ?? this.publishLoading;

    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);

export class NavBarStore extends StoreBase<NavBarStoreState> {
  constructor() {
    super(() => new NavBarStoreState());
  }

  setClickItemButton(clickItemButton: boolean) {
    this.emit(this.state.copyWith({ clickItemButton }));
  }

  setNewFunctionData(newFunctionData: any) {
    this.emit(this.state.copyWith({ newFunctionData }));
  }

  setCustomFunctionTitle(customFunctionTitle: string) {
    this.emit(this.state.copyWith({ customFunctionTitle }));
  }

  setIsNewCustomFunctionOpen(isNewCustomFunctionOpen: boolean) {
    this.emit(this.state.copyWith({ isNewCustomFunctionOpen }));
  }

  setSwtichMode(switchMode: SwitcherModeType) {
    this.emit(this.state.copyWith({ switchMode }));
  }
  setShareLoading(shareLoading: boolean) {
    this.emit(this.state.copyWith({ shareLoading }));
  }

  setShowPublishFunctionConfirm(showPublishFunctionConfirm: boolean) {
    this.emit(this.state.copyWith({ showPublishFunctionConfirm }));
  }
  setPublishFunctionLoading(publishFunctionLoading: boolean) {
    this.emit(this.state.copyWith({ publishFunctionLoading }));
  }
  setPublishFunctionName(publishFunctionName: string) {
    this.emit(this.state.copyWith({ publishFunctionName }));
  }

  setShowPublishDisableTip(showPublishDisableTip: string) {
    this.emit(this.state.copyWith({ showPublishDisableTip }));
  }

  setPublishLoading(publishLoading: boolean) {
    this.emit(this.state.copyWith({ publishLoading }));
  }
}
// export const navBarStore = new NavBarStore();
