import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { FotReduxStore } from "./FotReduxStore";

class GroupDeleteChildNode {
  id = "";
  parentNodeId = "";
}

export class CreatorAISaasStoreState {
  showSaasOutputList = true;

  aiSaasPreviewHoverData: AISaas.previewHoverDataType = {
    show: false,
    container: null,
    type: "",
    id: "",
  };
  /// group 手动删除某个node
  // FIXME: 该状态会刷新所有 customNode
  groupDeleteChildNode: GroupDeleteChildNode = new GroupDeleteChildNode();

  copyWith(param: {
    showSaasOutputList?: boolean;
    aiSaasPreviewHoverData?: AISaas.previewHoverDataType;
    groupDeleteChildNode?: GroupDeleteChildNode;
  }): CreatorAISaasStoreState {
    const newState = new CreatorAISaasStoreState();
    newState.showSaasOutputList = param.showSaasOutputList ?? this.showSaasOutputList;
    newState.aiSaasPreviewHoverData = param.aiSaasPreviewHoverData ?? this.aiSaasPreviewHoverData;
    newState.groupDeleteChildNode = param.groupDeleteChildNode ?? this.groupDeleteChildNode;

    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);

// 原 useAisassState 状态
export class CreatorAISaasStore extends StoreBase<CreatorAISaasStoreState> {
  constructor(public fotReduxStore: FotReduxStore) {
    super(() => new CreatorAISaasStoreState());
  }

  setShowSaasOutputList(showSaasOutputList: boolean): void {
    this.emit(this.state.copyWith({ showSaasOutputList }));
  }
  setAiSaasPreviewHoverData = (aiSaasPreviewHoverData: AISaas.previewHoverDataType): void => {
    this.emit(this.state.copyWith({ aiSaasPreviewHoverData }));
  };
  setGroupDeleteChildNode(groupDeleteChildNode: any): void {
    this.emit(this.state.copyWith({ groupDeleteChildNode }));
  }

  /**
   * @deprecated `FotReduxStore.setInputId`
   * @param val
   */
  setInputId = (val: any): void => {
    this.fotReduxStore.setInputId(val);
  };

  onCloseInputId = (id: string): void => {
    const inputId = (this.fotReduxStore.getState() as any).fot.inputId as any[];
    const newInputId = inputId.filter(x => x !== id);
    this.setInputId(newInputId);
  };
}
