import { HTMLMotionProps, motion } from "framer-motion";
import { get } from "lodash";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { CurrentStatus } from "../useCurrentStatus";
import styles from "./LoadingTextsMotion.module.scss";
import { transition } from "./motionConfig";

const loadingTexts = ["Generating user flow...", "Generating app logic..."];

export const LoadingTexts = ({ currentStatus }: { currentStatus: CurrentStatus }) => {
  const [count, setCount] = useState(0);

  const [motionElement, setMotionElement] = useState<React.ReactElement | null>(null);

  const loadingText = loadingTexts[count % loadingTexts.length];

  const handleMotionComplete = () => {
    setCount(count + 1);
  };

  const setNewMotionElement = () => {
    setMotionElement(
      <LoadingMotion key={Date.now()} onAnimationComplete={handleMotionComplete}>
        {loadingText}
      </LoadingMotion>
    );
  };

  const setFadeoutElement = () => {
    setMotionElement(<FadeOutMotion key={Date.now()}>{loadingText}</FadeOutMotion>);
  };

  useEffect(() => {
    setNewMotionElement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (currentStatus === "completeCreating") {
      setFadeoutElement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  return <>{motionElement}</>;
};

const FadeOutMotion: React.FC<PropsWithChildren & HTMLMotionProps<"div">> = ({ children = "loading...", ...rest }) => {
  return (
    <motion.div
      className={styles.loadingTextsMotion}
      {...rest}
      initial={{ opacity: 1, scale: 1 }}
      animate={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: get(transition, "duration", 0) / 2 }}
    >
      {children}
    </motion.div>
  );
};

const LoadingMotion: React.FC<PropsWithChildren & HTMLMotionProps<"div">> = ({ children = "loading...", ...rest }) => {
  return (
    <motion.div
      className={styles.loadingTextsMotion}
      {...rest}
      animate={{ opacity: [0, 1, 0, 1, 0], scale: [0.9, 1, 1, 1, 1] }}
      transition={{ duration: 3, times: [0, 0.25, 0.5, 0.75, 1] }}
    >
      {children}
    </motion.div>
  );
};
