import { useObserverNodeSize } from "imagica-uikit/dist/hooks/useObserverNodeSize";
import { Input } from "antd";
import cls from "classnames";
import { motion } from "framer-motion";
import { get, isEmpty, isNil } from "lodash";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { MultiRoundChatOnboardingBloc } from "./MultiRoundChatOnboardingBloc";
import styles from "./InputBar.module.scss";

export const InputBar: React.FC<
  PropsWithChildren & {
    bloc: MultiRoundChatOnboardingBloc;
    onPressEnter: () => void;
  }
> = ({ bloc, onPressEnter }) => {
  const operationClickInfo = bloc.operationClickInfo.value;

  const inputMotionRef = useRef<HTMLDivElement>(null);

  const {
    size: { height },
  } = useObserverNodeSize(inputMotionRef);

  const [freeze, setFreeze] = useState(false);
  const [inputMotionLoading, setInputMotionLoading] = useState(false);
  const [{ inputMotionKey, top }, setInputMotion] = useState({
    inputMotionKey: Date.now(),
    top: 0,
  });

  const calcMotionPosition = (): void => {
    if (isNil(operationClickInfo) === false) {
      const inputRect = (
        get(bloc, "selectCursorRef.current.resizableTextArea.textArea") as unknown as HTMLInputElement
      )?.getBoundingClientRect();
      const top = get(operationClickInfo, "rect.top", 0) - get(inputRect, "top", 0);
      setInputMotion({ inputMotionKey: Date.now(), top });
      setTimeout(() => {
        bloc.thinkValue.value = get(operationClickInfo, "content", "");
        bloc.pasteText();
      });
    }
  };

  useEffect(() => {
    if (bloc.waitingThinking.value === true) {
      setFreeze(true);
    }

    if (bloc.waitingThinking.value === false && freeze === true) {
      setFreeze(false);
    }
  }, [bloc.waitingThinking.value, freeze]);

  useEffect(() => {
    calcMotionPosition();
    // eslint-disable-next-line
  }, [operationClickInfo]);

  if (bloc.creating.value === true) {
    return null;
  }

  const inputBoxClassName = cls(styles["input-box"], inputMotionLoading === true && styles.inputMotionLoading);

  return (
    <div className={styles.inputBar}>
      <motion.div
        className={styles.inputBarMotion}
        initial={{ opacity: 0, margin: "0 15%" }}
        animate={{ opacity: 1, margin: "0 0%" }}
        transition={{
          duration: 0.3,
        }}
      >
        <div className={inputBoxClassName} style={{ minHeight: `${height + 33}px` }}>
          <motion.div
            id="inputMotionKey"
            key={inputMotionKey}
            ref={inputMotionRef}
            initial={{ top: `${top}px`, fontFamily: "Ak-Regular", fontWeight: 500 }}
            animate={{ top: "0px", fontFamily: "Ak-Regular" }}
            transition={{
              duration: 0.5,
            }}
            onAnimationStart={() => setInputMotionLoading(true)}
            onAnimationComplete={() => setInputMotionLoading(false)}
            className={styles.inputMotion}
          >
            <Input.TextArea
              ref={bloc.selectCursorRef}
              placeholder={"Describe what Al app you would like to create..."}
              className={styles["add-input"]}
              value={bloc.thinkValue.value}
              onChange={e => bloc.thinkChange(e.target.value)}
              onPressEnter={e => {
                e.preventDefault();
                onPressEnter();
              }}
              onBlur={() => {
                setTimeout(() => {
                  bloc.focus.value = false;
                }, 200);
                bloc.onBlur();
              }}
              onClick={() => {
                bloc.focus.value = true;
                bloc.clickEditBox();
              }}
              autoSize
              bordered={false}
            />
            <InputStatus bloc={bloc} onClick={onPressEnter} />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

const InputStatus = ({
  bloc,
  onClick,
}: {
  bloc: MultiRoundChatOnboardingBloc;
  onClick: () => void;
}): JSX.Element | null => {
  const focus = bloc.focus.value;
  const buttonClassName = cls(styles.InputStatusButton, bloc.focus.value === true ? styles.enter : styles.close);

  if (isEmpty(bloc.thinkValue.value) === false) {
    return (
      <div
        className={buttonClassName}
        onClick={() => {
          if (focus) {
            return onClick();
          }
          bloc.thinkValue.value = "";
        }}
      />
    );
  }

  return null;
};
