"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextGenFunction = void 0;
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
class TextGenFunction extends FunctionBase_1.FunctionBase {
    constructor() {
        super(...arguments);
        this.url = undefined;
        this.domain = undefined;
        this.identifierType = "";
        this.identifierValue = "";
        this.streaming = false;
    }
    async run(input, identityInfo, variableList = [], page = 1, next = undefined, pagination = undefined, requestParam = {}) {
        this.url = requestParam.url || this.url;
        this.domain = requestParam.domain || this.domain;
        this.identifierType = requestParam.identifierType || this.identifierType;
        this.identifierValue = requestParam.identifierValue || this.identifierValue;
        this.streaming = requestParam.streaming || this.streaming;
        if (this.streaming) {
            const streamResponse = await this.runStream(input, variableList, identityInfo);
            return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.TEXT, streamResponse);
        }
        else {
            const streamResponse = await this.runNoStream(input, variableList, identityInfo);
            return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.TEXT, streamResponse);
        }
    }
    async runNoStream(input, variableList, identityInfo) {
        const variables = {
            input: input,
        };
        variableList.forEach(element => {
            variables[element.name] = element.value;
        });
        if (this.url) {
            const url = this.url;
            const streamResponse = await this.socketRestClient.requestRest(topic => {
                return this.brainClient.openAi.completionsGenerationNoStream(url, this.domain, this.identifierType, this.identifierValue, this.streaming, variables, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo), topic);
            }, res => {
                return new FunctionBase_1.FuncDataSource(FunctionBase_1.FuncDataSourceType.TEXT, res.data.choices?.first()?.text?.trim() ?? "");
            }, "textGenNoStream", identityInfo);
            return streamResponse;
        }
        else {
            const streamResponse = await this.socketRestClient.requestRest(topic => {
                return this.brainClient.openAi.completionsGenerationNoStream("/stream/bas-demo-v4/nlp/completions_generation", this.domain, this.identifierType, this.identifierValue, this.streaming, variables, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo), topic);
            }, res => {
                return new FunctionBase_1.FuncDataSource(FunctionBase_1.FuncDataSourceType.TEXT, res.data.choices?.first()?.text?.trim() ?? "");
            }, "textGenNoStream", identityInfo);
            return streamResponse;
        }
    }
    async runStream(input, variableList, identityInfo) {
        let postParam = await this.getCompletionsGenerationParam(this.identifierValue, input, variableList);
        let url = "/stream/bas-demo-v4/nlp/completions_generation";
        return this.socketRestClient.requestStream(topic => {
            postParam.pubsub_topic = topic;
            return this.brainClient.openAi.postWithStreaming(url, postParam, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo));
        }, "textGenStream", FunctionBase_1.FuncDataSourceType.TEXT, identityInfo);
    }
    /// todo：构造param的逻辑暂未完全按照web的实现做，目前对于第一个用例可以跑通，后面需要完善这个方法
    async getCompletionsGenerationParam(identifierValue, input, variableList) {
        const variables = {
            input: input,
        };
        variableList.forEach(element => {
            variables[element.name] = element.value;
        });
        return {
            identifier_type: "object_id",
            identifier_value: identifierValue,
            stream: true,
            variables: variables,
        };
    }
}
exports.TextGenFunction = TextGenFunction;
