import { useCallback, useRef } from "react";
import { createContainer } from "unstated-next";
import { useEdgeCommonState } from "./useEdgeCommonState";
import { useFocusCustomEdge } from "@custom-hooks/useFocusCustomEdge";
import { CommandBarController } from "./CommandBarController";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";

function useContainer(props?: CustomEdge.CustomNewEdgeProps): any {
  const { data, source = "", id = "", isV3 } = props || {};
  const homePluginStore = getIt(HomePluginStore);
  const homePluginState = useStore(homePluginStore).value;

  const commandBarController = useRef(new CommandBarController()).current;
  // state
  const edgeCommonState = useEdgeCommonState();
  const { setDisableHover, edgeStateSignal, aiDescriptionInputRef, aiDescriptionSingle, showImageGenSelectSingle } =
    edgeCommonState;

  const focusEdge = useFocusCustomEdge({
    edgeId: id,
    edgeStateSignal,
    v2: !!isV3,
    controller: commandBarController,
  });

  // loggic

  /**
   * 为了管理 showImageGenSelect 和 edge 聚焦状态，在 set showImageGenSelect 做一个拦截
   */
  const onChangeVisibleCommandBar = useCallback(
    (visible: boolean, targetDom?: HTMLElement, showImageGenSelect?: boolean) => {
      // 选中光标后默认 hover 效果
      if (aiDescriptionSingle.value.includes("/")) {
        setDisableHover(false);
      }
      if (!visible && !data.isUndoRedoAdd) {
        // const edgeInput = get(aiDescriptionInputRef.current, 'resizableTextArea.textArea')
        const edgeInput = aiDescriptionInputRef.current;

        // 聚焦边的输入框
        if (edgeInput && edgeInput === targetDom) {
          edgeInput.focus();
        }
      }

      if (!homePluginState.isShare) {
        if (visible) {
          // FIXME: 需要等待 onFocusFinished 完成，但是 edgeType 是在 useEffect 中改变，和 onChangeVisibleCommandBar(true) 不在同一次渲染中
          // !!! 将执行任务放入宏任务，在下一次事件循环中确保 edgeType 在  onChangeVisibleCommandBar(true) 执行
          const showSelect = typeof showImageGenSelect === "boolean" ? showImageGenSelect : visible;
          setTimeout(() => {
            if (commandBarController.hasTriggering() && visible) {
              // 准备接受通知并显示 commandbar
              commandBarController.observer(() => {
                showImageGenSelectSingle.value = showSelect;
              });
            } else {
              showImageGenSelectSingle.value = showSelect;
            }
          }, 16);
        } else {
          showImageGenSelectSingle.value = visible;
        }
      }
      // 设置画板移动或缩放
      homeToolBarStore.setAllowZoomOrPan(!visible);
    },
    [aiDescriptionSingle.value, data.isUndoRedoAdd]
  );

  return {
    ...edgeCommonState,
    onChangeVisibleCommandBar,

    sourceId: source,
    edgeId: id,

    focusEdge,
    setEdgeState: focusEdge.setEdgeState,
    commandBarController: commandBarController,
  };
}

export const { Provider: CustomEedgProvider, useContainer: useCustomEdgeContainer } = createContainer(useContainer);
