import { groupBy, forOwn, transform, get } from "lodash";

/**
 * DndThoughtType 拖拽类型
 *
 * AISaasOutput.ThoughtType 预览数据类型
 *
 *
 */
export class ConverterUtil {
  static aiThought2DndThought(thought: AISaasOutput.ThoughtType, index: number): AISaasOutput.DndThoughtType {
    const dndId = thought?.results?.[0]?.previewId;
    const sort = (index + 1) * 100;
    return {
      ...thought,
      id: dndId,
      dndData: {
        id: dndId,
        sort,
      },
    };
  }

  /**
   * 将之前 预览的 aiSaasList 转换成 dnd拖拽List
   * @param thoughts
   * @returns
   */
  static aiThoughtList2DndThoughtList(thoughts: AISaasOutput.ThoughtType[]): AISaasOutput.DndThoughtType[] {
    return thoughts.map(ConverterUtil.aiThought2DndThought);
  }

  /**
   * 将之前 预览的 aiSaasList 转换成 分组后的 dnd拖拽List
   *
   * - 处理分组
   * @param thoughts
   */
  static aiThoughtList2DndGroupThoughts(thoughts: AISaasOutput.ThoughtType[]): AISaasOutput.DndThoughtWithGroupType[] {
    let result: AISaasOutput.DndThoughtWithGroupType[] = [];
    const map = new Map();
    thoughts?.forEach((item, index) => {
      if (item.groupId) {
        if (!map.has(item.groupId)) {
          map.set(item.groupId, true);
          result.push({
            id: item.groupId,
            groupDnd: [ConverterUtil.aiThought2DndThought(item, index)],
          });
        } else {
          const groupItem = result.find(it => it.id === item.groupId);
          groupItem?.groupDnd.push(ConverterUtil.aiThought2DndThought(item, index));
        }
      } else {
        const id = get(item, "results[0].previewId");
        result.push({
          id: id,
          groupDnd: [ConverterUtil.aiThought2DndThought(item, index)],
        });
      }
    });

    /* const groupSource = groupBy(thoughts, "groupId");
    const result = [] as AISaasOutput.DndThoughtWithGroupType[];
    forOwn(groupSource, (value, groupId) => {
      if (groupId) {
        result.push({
          id: groupId,
          groupDnd: ConverterUtil.aiThoughtList2DndThoughtList(value),
        });
      } else {
        value.forEach((thought, index) => {
          const id = get(thought, "results[0].previewId");
          result.push({
            id: id,
            groupDnd: [ConverterUtil.aiThought2DndThought(thought, index)],
          });
        });
      }
    }); */

    return result;
  }

  static dndThought2AiThought(thought: AISaasOutput.DndThoughtType): AISaasOutput.ThoughtType {
    return thought;
  }

  /**
   * 转换 `aiThoughtList2DndGroupThoughts`
   *
   * @param thought
   * @returns
   */
  static dndGroupThoughts2aiThoughtList(thoughts: AISaasOutput.DndThoughtWithGroupType[]): AISaasOutput.ThoughtType[] {
    let result = [] as AISaasOutput.ThoughtType[];
    thoughts.forEach(value => {
      result = result.concat(value.groupDnd);
    });
    return result;
  }

  /**
   * 为 container 提供快捷的数据转换
   * @param thoughts
   * @returns
   */
  static toDndData(thoughts: AISaasOutput.ThoughtType[]) {
    // return ConverterUtil.aiThoughtList2DndThoughtList(thoughts);
    return ConverterUtil.aiThoughtList2DndGroupThoughts(thoughts);
  }
  /**
   * 为 container 提供快捷的数据转换
   * @param thoughts
   * @returns
   */
  static toAiSaasThoughts(thoughts: ReturnType<typeof ConverterUtil.toDndData>) {
    return ConverterUtil.dndGroupThoughts2aiThoughtList(thoughts);
  }
}
