import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import styles from "./HeaderV2.module.scss";
import { getIt } from "@uikit/getIt";
import { PreviewPaneStore, SettingPageContentType } from "@uiview/views/PreviewPane/PreviewPaneStore";
import LoadingOutline from "@views/components/LoadingOutline";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { ProjectNetDataStore } from "@base/ProjectNetDataStore";

export function HeaderV2() {
  const previewPaneStore = getIt(PreviewPaneStore);
  const previewPaneState = useStore(previewPaneStore).value;
  const projectUpdateState = useStore(getIt(ProjectNetDataStore)).value;
  const returnPage = () => {
    previewPaneStore.settingPageStatus(false);
    previewPaneStore.setSettingPageContentType(SettingPageContentType.none);
  };
  const title =
    previewPaneState.settingPageContentType === SettingPageContentType.design ? "App Design" : "Advanced Features";

  return (
    <div className={styles.Header}>
      <div className={styles.SettingTitle}>
        <div>{title}</div>
        {projectUpdateState.isUpdating ? <LoadingOutline className={styles.saveLoading} size={20} /> : null}
      </div>
      <img
        onClick={() => returnPage()}
        src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`}
        width="20"
        alt="icon"
      />
    </div>
  );
}
