import { Api } from "@uikit/service/api.js";
import func from "@uikit/func";

export default {
  toThoughts(api_name, outputObj) {
    // if (!variablesObj.clothing_results || !variablesObj.outfit) {
    //   return [];
    // }
    let results = [];
    let implication = "";
    let grid = false;

    switch (api_name) {
      case "string":
        results = [
          {
            angles: [],
            content: outputObj,
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: outputObj,
            type: "textbox",
          },
        ];
        break;
      case "textlist":
        results = outputObj.map(x => {
          return {
            angles: [],
            content: x,
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: x,
            type: "textbox",
          };
        });
        break;
      case "fashion":
        results = outputObj.map(x => {
          return {
            angles: [],
            content: {
              image: x.image,
              name: x.text,
              price: "",
            },
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: x.text,
            type: "outfit",
          };
        });
        grid = true;
        // implication = variablesObj?.outfit?.value;
        break;
      case "stock_info":
        // const reason = {
        //   angles: [],
        //   content: variablesObj?.reason?.value,
        //   dislikes: [],
        //   filters: [],
        //   likes: [],
        //   reactions: [],
        //   thought: variablesObj?.reason?.value,
        //   type: 'textbox'
        // };
        const stocks = outputObj.map(x => {
          return {
            angles: [],
            content: {
              logo: x.logo,
              name: x.stock_name || "N/A",
              symbol: x.stock_symbol,
              price: x.price.replace("USD", "$"),
              summary: x.business_summary,
              volume: x.volume || "N/A",
            },
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: "",
            type: "stock",
          };
        });
        results = stocks;
        break;
      case "videos":
        const videos = outputObj.map(x => {
          return {
            angles: [],
            content: x.url,
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: x.title,
            thumbnail: x.image,
            type: "video",
          };
        });
        results = videos.slice(0, 10);
        break;
      case "food":
        const dishes = outputObj.map(x => {
          return {
            angles: [],
            content: {
              image: x.image_url,
              dish: x.name,
              restaurant: x.restaurant,
            },
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: x.name,
            type: "food",
          };
        });
        results = dishes;
        break;
      case "shop":
        const products = outputObj.map(x => {
          return {
            angles: [],
            content: {
              image: x.image,
              title: x.title,
            },
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: x.title,
            type: "shopping",
          };
        });
        results = products;
        break;
      case "flights":
        const flights = outputObj.map(x => {
          return {
            angles: [],
            content: {
              from: x.from || "N/A",
              to: x.to || "N/A",
              fromCity: x.fromCity || "N/A",
              toCity: x.toCity || "N/A",
              departureTimeStamp: x.departureTime.dateTime,
              arrivalTimeStamp: x.arrivalTime.dateTime,
              departure: x?.departureTime?.dateTimeString || "N/A",
              arrival: x?.arrivalTime?.dateTimeString || "N/A",
              price: x.price,
              duration: x.duration,
              airlines: x.airlines,
              returnItems: x.returnItems,
            },
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: x.title,
            type: "flight",
          };
        });
        results = flights;
        break;
      case "images":
        const images = outputObj.map(x => {
          return {
            angles: [],
            content: x.image_url,
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: x.prompt,
            type: "image",
          };
        });
        results = images;
        break;
      case "code":
        const code = [
          {
            angles: [],
            content: { code: outputObj },
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: "",
            type: "code",
          },
        ];
        results = code;
        break;
      case "job_description":
        const jobDescription = [
          {
            angles: [],
            content: { job_description: outputObj },
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: "",
            type: "job_description",
          },
        ];
        results = jobDescription;
        break;
      case "pitch_deck":
        const pitchDeck = [
          {
            angles: [],
            content: { pitch_deck: outputObj },
            dislikes: [],
            filters: [],
            likes: [],
            reactions: [],
            thought: "",
            type: "pitch_deck",
          },
        ];
        results = pitchDeck;
    }
    return {
      angle: "",
      implication,
      modifier: "",
      reason: "",
      results,
      grid,
    };
  },
  async handleStockData(api_name, outputObj) {
    if (api_name !== "stock_info") return outputObj;
    const api = new Api(false);
    for (let i = 0; i < outputObj.length; i++) {
      if (
        outputObj[i].price.includes("None") ||
        func.isEmpty(outputObj[i].price) ||
        func.isEmpty(outputObj[i].stock_name)
      ) {
        const response = await api.get(`/be/svc-adapter/stock/symbol?text=${outputObj[i].stock_symbol}`);
        const stockData = await response.json();

        outputObj[i].stock_name = stockData?.data?.name;
        outputObj[i].price = `USD ${stockData?.data?.price}`;
        outputObj[i].volume = stockData?.data?.volume;
      }
    }
    return outputObj;
  },
};
