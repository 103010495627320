export function CopyLInkIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2301 4.10999L11.1701 3.17001C11.5418 2.79868 11.9831 2.50422 12.4687 2.30347C12.9542 2.10272 13.4746 1.99959 14.0001 2C14.7855 2.0052 15.5521 2.24154 16.2041 2.67954C16.8561 3.11754 17.3647 3.7378 17.6665 4.46298C17.9683 5.18816 18.0499 5.98616 17.9012 6.75742C17.7524 7.52868 17.3799 8.23907 16.83 8.79999L13.83 11.8C13.3982 12.2326 12.8729 12.5606 12.2945 12.7585C11.7161 12.9565 11.1 13.0193 10.4936 12.942C9.8872 12.8648 9.30654 12.6496 8.79626 12.3129C8.28599 11.9763 7.85967 11.5271 7.55005 11"
        stroke="#12192B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.77002 15.89L8.83002 16.83C8.07945 17.5806 7.06149 18.0022 6.00003 18.0022C4.93857 18.0022 3.92061 17.5806 3.17004 16.83C2.41948 16.0794 1.9978 15.0615 1.9978 14C1.9978 12.9385 2.41948 11.9206 3.17004 11.17L6.17004 8.17001C6.54179 7.79868 6.98305 7.50422 7.46863 7.30347C7.95421 7.10272 8.47459 6.99959 9.00003 7C9.69686 6.99941 10.3818 7.18086 10.9869 7.5264C11.592 7.87194 12.0964 8.36957 12.45 8.97"
        stroke="#12192B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Collapse() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="white" />
      <path d="M18 28V22H12" stroke="#676E7F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 12V18H28" stroke="#676E7F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function FullScreen() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="white" />
      <path
        d="M29 22V24.2C29 25.8802 29 26.7202 28.673 27.362C28.3854 27.9265 27.9265 28.3854 27.362 28.673C26.7202 29 25.8802 29 24.2 29H22M18 11H15.8C14.1198 11 13.2798 11 12.638 11.327C12.0735 11.6146 11.6146 12.0735 11.327 12.638C11 13.2798 11 14.1198 11 15.8V18M23 17L29 11M29 11H23M29 11V17M17 23L11 29M11 29H17M11 29L11 23"
        stroke="#676E7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
