import { map } from "lodash";
import styles from "./ListPanel.module.scss";

export type ListData = { label: string; value?: object };

type ListPanelProps = {
  onClick: (item: ListData) => void;
  options: ListData[];
};

export function ListPanel(props: ListPanelProps) {
  return (
    <div className={styles.listPanel}>
      {map(props.options, item => {
        return (
          <div className={styles.listPanelItem} key={item.label} onClick={() => props.onClick(item)}>
            {item.label}
          </div>
        );
      })}
    </div>
  );
}
