"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoResponse = exports.VideoData = exports.VideoItem = void 0;
const class_transformer_1 = require("class-transformer");
class VideoItem {
    constructor() {
        this.title = "";
        this.thumbnailUrl = "";
        this.url = "";
        this.length = "";
    }
}
exports.VideoItem = VideoItem;
__decorate([
    (0, class_transformer_1.Expose)()
], VideoItem.prototype, "title", void 0);
__decorate([
    (0, class_transformer_1.Expose)()
], VideoItem.prototype, "thumbnailUrl", void 0);
__decorate([
    (0, class_transformer_1.Expose)()
], VideoItem.prototype, "url", void 0);
__decorate([
    (0, class_transformer_1.Expose)()
], VideoItem.prototype, "length", void 0);
class VideoData {
    constructor() {
        this.items = [];
    }
}
exports.VideoData = VideoData;
class VideoResponse {
    constructor() {
        this.done = false;
    }
}
exports.VideoResponse = VideoResponse;
