"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioProject = void 0;
const class_transformer_1 = require("class-transformer");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const StoryNodeDisplayType_1 = require("../../storyV2/domain/StoryNodeDisplayType");
const StudioProjectAttributes_1 = require("../../project/domain/StudioProjectAttributes");
const StudioProjectAttributesV2UIInput_1 = require("../../project/domain/StudioProjectAttributesV2UIInput");
class StudioProject {
    get inputs() {
        const ui = this.attributes?.v2?.UI ?? [];
        if (ui.length > 0) {
            const input = ui[0].input;
            if (input instanceof StudioProjectAttributesV2UIInput_1.StudioProjectAttributesV2UIInput) {
                const oneInput = input;
                return [oneInput];
            }
            else if (input instanceof Array && input.first() instanceof StudioProjectAttributesV2UIInput_1.StudioProjectAttributesV2UIInput) {
                console.log("input is Array<StudioProjectAttributesV2UIInput>");
                const multiInput = input;
                return multiInput;
            }
        }
        return [];
    }
    get hasUploadFileInput() {
        return this.inputs.some(input => input.data?.displayType === StoryNodeDisplayType_1.StoryNodeDisplayType.UPLOADFILE);
    }
}
exports.StudioProject = StudioProject;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributes_1.StudioProjectAttributes),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toModelFromType(StudioProjectAttributes_1.StudioProjectAttributes, value.value);
    }, { toClassOnly: true })
], StudioProject.prototype, "attributes", void 0);
