import React, { Component, Fragment } from "react";
import { auth, verification } from "@uikit/login";
import style from "./Login.module.css";
import intlTelInput from "intl-tel-input";
import utils from "intl-tel-input/build/js/utils";
import "intl-tel-input/build/css/intlTelInput.css";
import func from "@uikit/func";
import StudiosLogin from "@views/StudiosLogin";
import StudiosSignUp from "@views/StudiosSignUp";
import { withRouter } from "@views/components/withRouter";
import { get } from "lodash";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      userTxt: "",
      showStr: "",
      errorTip: "",
      countryCode: "",
      preCountryCode: "",
      time: "",
      iti: "",
      email: "",
      emailPassword: "",
      firstName: "",
      lastName: "",
      otp: "",

      timer: 0,
      currentIndex: 0,
      verificationCodeLength: ["", "", "", "", "", ""],
      otpStrArr: [],
      isLoading: false,
      showErrTips: false,
      isPhoneNumber: true,
      isShowTime: false,
      isSignUp: props.isSignUp,
      isOtp: false,

      linkParams: {},
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(get(this.props, "router.location.search"));
    const mode = searchParams.get("mode");
    const origin = searchParams.get("origin");

    if (window.location.href.includes("/editor/sign_up") || window.location.href.includes("/signup")) {
      // 进入 sign up
      this.toSignUp();
    }
    if (mode && origin) {
      this.setState({ isSignUp: true });
    }
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }

  otpStrLength = e => {
    const str = e.target.value.split("");
    if (str.length > 6) return;
    this.setStateAsync({
      otpStrArr: str,
      currentIndex: e.target.value.length,
    });
    if (str.length === 6) this.toSign();
  };

  changeData = e => {
    this.setStateAsync({
      userTxt: e.target.value,
      isPhoneNumber: true,
      showStr: "",
      errorTip: "",
      preCountryCode: "",
      countryCode: "",
      otpStrArr: [],
      showErrTips: false,
      isShowTime: false,
      changeState: false,
      focus: false,
    });

    let mailbox = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;

    let reg = /^\s*\+?\s*(\(\s*\d+\s*\)|\d+)(\s*-?\s*(\(\s*\d+\s*\)|\s*\d+\s*))*\s*$/;
    let patt = /[a-z]|@/i;

    // 邮箱类型
    if (mailbox.test(e.target.value)) {
      this.setStateAsync({ isPhoneNumber: true });
    } else if (reg.test(e.target.value) && !patt.test(e.target.value)) {
      if (this.state.isPhoneNumber) {
        const input = document.querySelector("#phone");
        input.value = "";
        this.setStateAsync({ iti: intlTelInput(input, { utilsScript: utils }) });
        input.focus();
      }
      this.setStateAsync({ isPhoneNumber: false });
    } else {
      this.setStateAsync({ showErrTips: true });
    }
  };
  toProceed = async () => {
    if (!this.state.isPhoneNumber) {
      const countryData = this.state.iti.getSelectedCountryData();
      await this.setStateAsync({ countryCode: countryData.dialCode });

      if (this.state.preCountryCode !== countryData) {
        this.setStateAsync({ errorTip: "" });
      }
    }

    if (func.isEmpty(this.state.userTxt) || !func.isEmpty(this.state.errorTip)) return;

    if (this.state.showErrTips) {
      this.setStateAsync({ errorTip: "Incorrect email or phone number, please try again…" });
      return;
    }
    this.state.isPhoneNumber ? this.setStateAsync({ showStr: "psdLgStr" }) : this.getTimeBack();
  };

  getTimeBack = async () => {
    if (func.isEmpty(this.state.userTxt)) {
      this.setStateAsync({ errorTip: "Enter Mobile Number" });
      return;
    }
    const countryData = this.state.iti.getSelectedCountryData();
    await this.setStateAsync({
      countryCode: countryData.dialCode,
      preCountryCode: countryData.dialCode,
    });

    // 验证当前号码
    if (!func.isEmpty(this.state.userTxt)) {
      const isValid = this.state.iti.isValidNumber();
      if (!isValid) {
        this.setStateAsync({ errorTip: "Incorrect phone or otp, please try again…" });
        return;
      }
    }

    if (this.state.changeState || this.state.isShowTime) return;
    this.setStateAsync({
      changeState: true,
    });
    clearInterval(this.state.time);
    const userObj = {
      phone: this.state.iti.getNumber(),
    };
    const res = await verification(userObj);
    if (res.detail) {
      this.setStateAsync({
        changeState: false,
        errorTip: res.detail,
      });
      return;
    }
    const timer = res.OTP_EXP || 60;
    this.codeCountDown(timer);

    this.setStateAsync({
      changeState: false,
      isShowTime: true,
      showStr: "otpLgStr",
      timer: timer,
    });
  };
  codeCountDown(timer) {
    this.state.time = setInterval(() => {
      this.setStateAsync({ timer: timer-- });
      if (this.state.timer <= 0) {
        this.setStateAsync({ isShowTime: false });
        clearInterval(this.state.time);
      }
    }, 1000);
  }

  async toSign() {
    let userObj = {};
    // 帐号登录
    if (this.state.showStr === "psdLgStr") {
      if (func.isEmpty(this.state.password)) {
        this.setStateAsync({ errorTip: "Enter Password" });
        return;
      }

      this.setStateAsync({ isLoading: true });
      const email = this.state.userTxt.toLowerCase();
      userObj = {
        email,
        password: this.state.password,
      };
      const response = await auth(userObj, this.props.setIsLogin);
      if (!func.isEmpty(response.non_field_errors)) {
        this.setStateAsync({
          errorTip: response.non_field_errors,
        });
      }
      this.setStateAsync({ isLoading: false });
      return;
    }

    // 手机验证码登录
    if (func.isEmpty(this.state.otpStrArr)) {
      this.setStateAsync({ errorTip: "Enter OTP" });
      return;
    }
    const countryData = await this.state.iti.getSelectedCountryData();
    if (this.state.preCountryCode !== countryData.dialCode) {
      await this.setStateAsync({
        preCountryCode: countryData.dialCode,
      });
    }
    if (this.state.otpStrArr.length !== 6) return;
    if (this.time !== null) {
      clearInterval(this.time);
    }
    await this.setStateAsync({ isLoading: true });

    userObj = {
      phone: this.state.iti.getNumber(),
      otp: this.state.otpStrArr.join(""),
    };

    const response = await verification(userObj, this.props.setIsLogin);
    if (!func.isEmpty(response.non_field_errors)) {
      this.setStateAsync({
        errorTip: response.non_field_errors,
      });
    }
    await this.setStateAsync({ isLoading: false });
  }
  handleEnterKey = e => {
    if (e.nativeEvent.keyCode === 13) {
      this.state.showStr === "psdLgStr" || this.state.showStr === "otpLgStr" ? this.toSign() : this.toProceed();
    }
  };
  toSignUp = () => {
    this.setStateAsync({ isSignUp: true });
  };
  toLogin = () => {
    this.setStateAsync({ isSignUp: false });
  };
  setEmail = preFillEmail => {
    this.setStateAsync({ email: preFillEmail });
  };
  setLinkParams = params => {
    this.setStateAsync({ linkParams: params });
  };
  toSignin = () => {
    this.setStateAsync({
      isSignUp: false,
      errorTip: "",
    });
  };

  changeEmailRegister = (e, key) => {
    this.setState({
      [key]: e.target.value,
      errorTip: "",
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.isLoading && <div className={style.loading}>Loading..</div>}
        {
          this.state.isSignUp ? (
            <StudiosSignUp
              setIsLogin={this.props.setIsLogin}
              toLogin={this.toLogin}
              setEmail={this.setEmail}
            ></StudiosSignUp>
          ) : (
            // <div className={style.login_c}>
            //   <p className={style.errorTip}>{this.state.errorTip}</p>
            //   <div>
            //     <div>
            //       <input
            //         type="text"
            //         placeholder="email"
            //         value={this.state.email}
            //         onChange={(e) => this.changeEmailRegister(e, 'email')}
            //         className={style['sign-up-input']} />
            //     </div>
            //     <div>
            //       <input
            //         type="password"
            //         placeholder="password"
            //         value={this.state.emailPassword}
            //         onChange={(e) => this.changeEmailRegister(e, 'emailPassword')}
            //         className={style['sign-up-input']} />
            //     </div>
            //     <div>
            //       <input
            //         type="text"
            //         value={this.state.firstName}
            //         placeholder="your name"
            //         onChange={(e) => this.changeEmailRegister(e, 'firstName')}
            //         className={style['sign-up-input']} />
            //     </div>
            //     {
            //       this.state.isOtp ?
            //         <div className={style['sign-box']}>
            //           <input
            //             type="text"
            //             value={this.state.otp}
            //             placeholder="otp"
            //             onChange={(e) => this.changeEmailRegister(e, 'otp')}
            //             className={style['sign-up-input']} />
            //           <Button className={style['retrieve-text']} type="text" onClick={() => this.clickSignUp(true)}>Refresh</Button>
            //         </div>
            //         : null
            //     }
            //   </div>

            //   <div className={`${style['get-otp']} ${style['lg-proceed']}`} onClick={() => this.clickSignUp()}>Confirm</div>

            //   <div>
            //     <a className={style['login-style']} onClick={() => this.toSignin()}>sign in</a>
            //   </div>
            // </div>
            <StudiosLogin
              setIsLogin={this.props.setIsLogin}
              toSignUp={this.toSignUp}
              setEmail={this.setEmail}
              setLinkParams={this.setLinkParams}
            ></StudiosLogin>
          )
          // : <div className={style.login_c}>
          //     <div className={style.login} />
          //     <div className={style['login-text']}>Sign in</div>
          //     <p className={style.errorTip}>{this.state.errorTip}</p>
          //     <input
          //       type="text"
          //       className={this.state.isPhoneNumber ? 'weui-input user-email-input' : 'weui-input cur-height'}
          //       placeholder="Enter email or phone"
          //       value={this.state.userTxt}
          //       onKeyPress={this.handleEnterKey}
          //       onChange={(e) => this.changeData(e)}
          //     />

          //     <div className={this.state.isPhoneNumber ? 'cur-height' : null} >
          //       <input
          //         id="phone"
          //         type="tel"
          //         className={`${style['number-verification-input']} weui-input`}
          //         placeholder="Enter email or phone"
          //         value={this.state.userTxt}
          //         onKeyPress={this.handleEnterKey}
          //         onChange={(e) => this.changeData(e)}
          //       />

          //       <div className={this.state.showStr === 'otpLgStr' ? 'weui-input otp-box' : 'cur-height'}>
          //         <div className={style['otp-bottom']}>
          //           {
          //             this.state.verificationCodeLength.map((item, index) => {
          //               return <div className={ this.state.focus && index === this.state.currentIndex ? `${style['otp-bottom-item']} ${style['active']}` : style['otp-bottom-item']} key={index}>{this.state.otpStrArr[index]}</div>
          //             })
          //           }
          //           <input
          //             className={style['code-input-input']}
          //             onFocus={() => this.setStateAsync({ focus: true }) }
          //             onBlur={() => this.setStateAsync({ focus: false }) }
          //             onChange={(e) => this.otpStrLength(e)}
          //             maxLength="6"
          //             type="tel"/>
          //         </div>
          //         <div
          //           className={this.state.isShowTime ? style['disable-icon'] : style['otp-timer']}
          //           onClick={() => this.getTimeBack()}>
          //             {this.state.isShowTime ? this.state.timer + ' s' : 'Resend Code' }
          //           </div>
          //       </div>
          //     </div>
          //     {
          //       this.state.showStr === 'psdLgStr'
          //       &&
          //       <input
          //         type="password"
          //         name="password"
          //         value={this.state.password}
          //         onKeyPress={this.handleEnterKey}
          //         onChange={(e) => this.setStateAsync({ password: e.target.value } )}
          //         autoComplete="new-password"
          //         className={`weui-input ${style['pass-input']}`}
          //         placeholder="Password"
          //       />
          //     }
          //     <div>
          //       {
          //         this.state.showStr === 'psdLgStr' || this.state.showStr === 'otpLgStr'
          //         ?
          //         <div
          //           className={!util.isEmpty(this.state.otpStrArr) || !util.isEmpty(this.state.password) ? `${style['get-otp']} ${style['lg-proceed']}` : style['get-otp']}
          //           onClick={() => this.toSign()}
          //         >Login</div>
          //         :
          //         <div
          //           className={!util.isEmpty(this.state.userTxt) ? `${style['get-otp']} ${style['lg-proceed']}` : style['get-otp']}
          //           onClick={() => this.toProceed()}
          //         >Proceed</div>
          //       }

          //       <a className={style['login-style']} onClick={() => this.toSignUp()}>sign up</a>
          //     </div>
          //   </div>
        }
      </Fragment>
    );
  }
}

const WithRouterLogin = withRouter(Login);

export { WithRouterLogin };
