import classNames from "classnames";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { getIt } from "@uikit/getIt";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fotActions } from "../../../store/fot";
import func from "@uikit/func";
import style from "./ToSignUpView.module.scss";
import { ToSignUpViewBloc } from "./ToSignUpViewBloc";
import { useReconnect } from "./useReconnect";

export default function ToSignUpView(props: { bloc: ToSignUpViewBloc; className?: string }) {
  const token = getIt(FotAuthStore).state.brainToken;
  useReconnect(token);
  const { bloc } = props;
  const dispatch = useDispatch();
  const setIsLogin = (val: Boolean) => {
    dispatch(fotActions.setIsLogin(val));
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const clickToSignUpButton = () => {
    searchParams.set("mode", "subscriber");
    setSearchParams(searchParams);
    bloc.clickToSignUpButton();
    setIsLogin(false);
  };

  // 已登录不显示 sign up
  if (!func.isEmpty(token)) return null;
  return (
    <div className={classNames(style["sign-in-box"], props.className)} onClick={clickToSignUpButton}>
      Sign up
    </div>
  );
}
