import { CanvasNodeType, CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";

export class CreateProjectService {
  constructor(
    public fotReduxStore: FotReduxStore,
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public previewAppStore: PreviewAppStore,
    public creatorRefStore: CreatorRefStore,
    public canvasDataRef: CanvasDataRef
  ) {}

  initNewProjectV2(): CanvasNodeType[] {
    const width = this.creatorRefStore.homeRef.current?.clientWidth || 0;
    const height = this.creatorRefStore.homeRef.current?.clientHeight || 0;
    this.creatorRefStore.nodeIndexRef.current = 1;
    this.creatorRefStore.edgeIndexRef.current = 0;
    // STUD-1093 tutorial v2 - disableCotOnboarding
    const initNodeId = `editor-${this.creatorRefStore.nodeIndexRef.current++}`;
    //  去掉feature tag，从使用上看之前的feature tag控制没有意义
    const initNodes = [
      {
        id: initNodeId,
        type: "customNode",
        width: 250,
        height: 250,
        data: {
          ...this.canvasDataRef.nodeDataRef.current,
        },
        position: {
          x: width / 2,
          y: height / 2,
        },
      },
    ];
    this.previewAppStore.resetPreviewData();
    this.creatorNodesStore.setNodes(initNodes);
    this.fotReduxStore.setPreSelectNodeId(initNodeId);
    this.creatorEdgesStore.setEdges([]);
    this.fotReduxStore.setVariableList([]);
    return initNodes;
  }
}
