import React, { useCallback, useEffect, useRef, useState } from "react";

import style from "./style.module.scss";
import { getAssetsAnimation, getAssetsIcon } from "@views/thinking-layout-editor/utilities";
import { Player, PlayerEvent } from "@lottiefiles/react-lottie-player";
import { animated, useSpring } from "@react-spring/web";

interface Props {
  isShown: boolean;
  isFirst?: boolean;
  onVideoPlay: () => void;
  onGetTemplate: () => void;
}

export type Display = "block" | "none";

const PreviewQuickStart: React.FC<Props> = ({ isShown = false, isFirst = false, ...props }) => {
  const [spring, springApi] = useSpring(() => ({}));
  const [display, setDisplay] = useState<Display>("block");
  const [opacity, setOpacity] = useState<number>(0);

  const playerRef = useRef<Player | null>(null);

  const show = useCallback(async () => {
    springApi.start({
      config: { duration: 1000 },
      from: {
        opacity: 0,
        // zIndex: 100,
      },
      to: {
        opacity: 1,
        // zIndex: 200,
      },
    });

    setDisplay("block");
    if (!playerRef.current) {
      return;
    }
    playerRef.current.setSeeker(0);
    playerRef.current.play();
  }, [springApi]);

  const hide = useCallback(async () => {
    springApi.start({
      config: { duration: 1000 },
      from: { opacity: 1 },
      to: { opacity: 0 },
    });

    setDisplay("none");
  }, [springApi]);

  useEffect(() => {
    if (isFirst && !isShown) {
      setOpacity(0);
      return;
    }
    if (!isFirst) {
      setOpacity(1);
    }
    if (isShown) {
      show();
    } else {
      hide();
    }
  }, [isFirst, isShown, show, hide]);

  const playerEventChange = useCallback((event: PlayerEvent) => {
    if (event === PlayerEvent.InstanceSaved && isShown) {
      playerRef.current?.play();
    }
  }, []);

  return (
    <animated.div
      className={style.quickStart}
      style={{
        opacity,
        ...spring,
      }}
      onClick={props.onVideoPlay}
    >
      <div className={`${style["quickStart-blur"]}`}></div>
      <div className={`${style["quickStart-logo-box"]}`}>
        <Player
          loop
          style={{ display }}
          src={getAssetsAnimation("quickStartRocket_lottie_homepage.json")}
          ref={playerRef}
          onEvent={playerEventChange}
        />
      </div>
      <div className={`${style["quickStart-title"]}`}>Quick Start Video</div>
      <div className={`${style["quickStart-actions-bar"]}`}>
        <div className={style.watchVideo}>
          <div className={`${style["watchVideo-icon"]}`}>
            <img src={getAssetsIcon("play.svg")} alt="play" />
          </div>
          <div className={`${style["watchVideo-title"]}`}>Watch now</div>
        </div>
        <div className={style.verticalLine} />
        <div
          className={style.getTemplate}
          onClick={e => {
            e.stopPropagation();
            props.onGetTemplate();
          }}
        >
          <img className={`${style["getTemplate-icon"]}`} src={getAssetsIcon("plus-blue.svg")} alt="add" />
          <div className={`${style["getTemplate-title"]}`}>Get this template</div>
        </div>
      </div>
    </animated.div>
  );
};

export default PreviewQuickStart;
