import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { StudioProject } from "imagica-corekit/dist/base/project/domain/StudioProject";
import { StoryTransformer } from "imagica-corekit/dist/base/storyV2/Transformer/StoryTransformer";
import { Story } from "imagica-corekit/dist/base/storyV2/domain/Story";
import { EdgeState, StoryEdge } from "imagica-corekit/dist/base/storyV2/domain/StoryEdge";
import { StoryEventObject } from "imagica-corekit/dist/base/storyV2/domain/StoryEventObject";
import { StoryNodeState } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeState";
import { StoryUtil } from "imagica-corekit/dist/base/storyV2/domain/StoryUtil";
import { get } from "lodash";
import func from "@uikit/func";
import { GROUPID_REG, NEW_EDGE_REG } from "../../views/thinking-layout-editor/constants";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CorebeUtil } from "@uikit/corebe/CorebeUtil";
import { StudioProjectV3BluePrintValue } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3NodeData";
import { UpdateImgContextUtil } from "@uikit/util/UpdateImgContextUtil";
import { EdgeRunHandler } from "./EdgeRunHandler";
import { EdgeRunUpdater } from "./EdgeRunUpdater";
import { EdgeRunCore, EdgeRunTransformDataType } from "./EdgeRunCore";

/**
 * 原来 useNewEdgeRun hook
 */
export class EdgeRunService {
  constructor(public core: EdgeRunCore, private handler: EdgeRunHandler, private updater: EdgeRunUpdater) {}

  getStory = <T extends Record<string, any>>(edgeId?: string, updateParam?: T): any => {
    const templateObj = this.core.fotReduxStore.getState().studio.selectedTemplate;
    const edges = this.core.creatorEdgesStore.getEdges();
    const variableList = this.core.fotReduxStore.getState().fot.variableList;
    const data = this.core.fotSaveService.getSaveDataV3(templateObj, edges, variableList, {}, updateParam);
    const projectObj = {
      attributes: data,
      attrIndexer: (data as any).index || (templateObj as any).index,
      id: (templateObj as any).id,
    };
    const projectJsonStr = JsonUtil.stringify(projectObj);
    const project = JsonUtil.toModelFromType(StudioProject, projectJsonStr);
    if (!project)
      return {
        story: undefined,
        storyEdge: undefined,
      };
    const story = StoryUtil.from(project, true, creatorRefStore.nodeIndexRef.current);
    story.graphData = JsonUtil.parseFromInstance(data);
    this.core.personalFunctionStore.extractFuncs(project);
    if (func.isEmpty(edgeId))
      return {
        story,
        storyEdge: undefined,
      };

    const storyEdge = story
      .findAllEdges()
      .filter(x => x.options.id === edgeId)
      .first();
    return {
      story,
      storyEdge,
    };
  };

  /** 找到对于带/的边，无效输入的line id */
  getUselessLineIds(story: Story, currentEdge: StoryEdge): string[] {
    const edgeText = currentEdge.options.actionData.enterText;
    let useSouceLines = currentEdge.options.source;
    /// 带/ 的边 并且有多个输入node
    if (edgeText.startsWith("/") && useSouceLines.length > 1) {
      // 获取sorcenode 和corekit中一样
      const usedSourceNode = StoryUtil.findEdgeSoure(story, currentEdge);
      useSouceLines = useSouceLines.filter(lineId => {
        return !usedSourceNode?.lines.find(nodeLine => {
          return nodeLine.options.id === lineId;
        });
      });
      return useSouceLines;
    }
    return [];
  }

  listenContent = (params: {
    isRunAll: boolean;
    story: Story;
    resolve: (res?: any) => void;
    reject: (error?: any) => void;
    edgeId?: string;
    onUpdateNode?: (args: any) => void;
    /**
     * 原来 errorClosure
     * @param args
     * @returns
     */
    onErrorClosure?: (args: any) => void;
  }): void => {
    const { isRunAll, story, resolve, edgeId, onUpdateNode, onErrorClosure: errorClosure } = params;
    story.on((eventobject: any) => {
      // console.log("corekit story update");
      eventobject.elements.forEach((element: any) => {
        const updateNode = StoryEventObject.asNode(element);
        if (updateNode) {
          //group node，uiData传{}
          let uiData = {};
          // 更新image node的caption内容
          UpdateImgContextUtil.updateImgNodeContext(updateNode, story);
          if (!GROUPID_REG.test(updateNode.options.id) && !NEW_EDGE_REG.test(updateNode.options.id)) {
            const data = this.handler.handleUIdata(updateNode.options.id);
            if (!func.isEmpty(data)) uiData = data;
          }
          // console.log("corekit node update", updateNode, uiData);
          const transformData = StoryTransformer.transform(uiData, updateNode, story);

          const textAreaValue = get(transformData, "data.textAreaValue") as unknown;
          /// 逐渐废弃 data.blueprintMessage 使用方式，统一在textAreaValue中获取
          const blueprintMessage =
            (textAreaValue as StudioProjectV3BluePrintValue)?.message ?? get(transformData, "data.blueprintMessage");
          if (blueprintMessage) {
            this.updater.updateEdgeBlueprintMessage(edgeId, blueprintMessage);
          }

          this.handler.handleUpdateNodeData(
            updateNode,
            transformData as EdgeRunTransformDataType | Array<EdgeRunTransformDataType>
          );
          // console.log("corekit transformData:", transformData);

          if (errorClosure !== undefined && updateNode.state === StoryNodeState.ERROR) {
            errorClosure(updateNode);
          }

          // onUpdateNode?.({ updateNode, transformData });
          if (
            (transformData as EdgeRunTransformDataType)?.data?.showBuilderUI === false &&
            (transformData as EdgeRunTransformDataType)?.data?.viewBuilderData
          ) {
            (transformData as EdgeRunTransformDataType).data.viewBuilderData.domList = [];
          }
          this.handler.handleViewBuilder({
            updateNode,
            transformData,
            onUpdateNode,
          });
          this.handler.handleResolveRun({
            isRunAll,
            updateNode,
            transformData,
            resolve,
            onUpdateNode,
            onUpdateGroupOutput: this.updater.updateGroupOutput,
          });
        }

        const updateEdge = StoryEventObject.asEdge(element);
        if (updateEdge) {
          const useLessLine = this.getUselessLineIds(story, updateEdge);
          const identifier = {
            type: updateEdge.options.actionData.identifierType,
            value: updateEdge.options.actionData.identifierValue,
          };
          this.updater.updataEdgeRunDisable(updateEdge.options.id, updateEdge.state);
          this.updater.updateEdgeState(updateEdge.options.id, updateEdge.state, identifier, updateEdge);
          this.updater.updateLineState(updateEdge.options.id, updateEdge.state, useLessLine, {
            sourceIds: updateEdge.options.source,
            target: updateEdge.options.target,
          });
          if (errorClosure !== undefined && updateEdge.state === EdgeState.ERROR) {
            errorClosure(updateEdge);
          }
          // console.log("corekit edge update:", updateEdge.options.id, updateEdge.state);
        }
      });
    });
  };

  corekitRun = async <T>(param: {
    edgeId?: string;
    updateParam?: T;
    isFromPreview?: boolean;
    isRunAll?: boolean;
    onUpdateNode?: (args: any) => void;
    onErrorClosure?(updateNode: any): void;
  }): Promise<any> => {
    // 测试代码
    if (!Object.keys(this.core.dependencies.initialReportSizeMapping).length) {
      console.warn("EdgeRunService Warnning: initialReportSizeMapping not set");
    }

    const featureTags = this.core.homeStore.state.featureTags;

    // 有featureTags且不是function界面的运行
    if (featureTags.enableGlobalContext && !this.core.myModificationDataStroe.state.showMyModification) {
      this.updater.updateEdgeState(param.edgeId ?? "", EdgeState.IDLE);
      const saveResult = this.core.fotSaveService.handleSave({ background: true });
      this.updater.updateEdgeState(param.edgeId ?? "", EdgeState.LOADING);
      try {
        await saveResult;
      } catch (error) {
        console.warn(error);
      }
    }

    const hasCorebe = await this.core.meStore.hasCorebe();

    return new Promise((resolve: (res?: any) => void, reject: (error?: any) => void) => {
      const { edgeId, updateParam, isFromPreview = false, isRunAll = false, onUpdateNode, onErrorClosure } = param;
      const { story, storyEdge } = this.getStory(edgeId, updateParam as any);

      if (!story) return resolve();

      this.listenContent({
        isRunAll,
        story,
        resolve,
        reject,
        edgeId,
        onUpdateNode,
        onErrorClosure,
      });

      if (hasCorebe) {
        // CorebeUtil.runByStream(story);
        CorebeUtil.runBySocket(story);
        if (story) return resolve(story);
      }

      if (storyEdge) {
        this.core.fotReduxStore.setShowTooltip("close");
        this.core.storyManager.runEdge(story, storyEdge).finally(() => {
          this.core.fotReduxStore.setIsEdgeRunning(false);
          resolve(story);
        });
      } else {
        const needRunAll = !isFromPreview;
        const cleanContent = !needRunAll;
        this.core.storyManager.run(story, needRunAll, cleanContent).finally(() => {
          resolve(story);
          this.core.fotReduxStore.setIsEdgeRunning(false);
        });
      }
    });
  };

  corekitRunStop = (projectId: string, edgeId: string): void => {
    if (func.isEmpty(edgeId)) return;
    this.core.storyManager.stop(projectId, edgeId);
    this.core.fotReduxStore.setIsEdgeRunning(false);
  };
}
