import { useState } from "react";
import { Typography, Tooltip, Spin, Popconfirm } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fotActions } from "../../store/fot";
import style from "./css/VariablePopoverContent.module.css";
import func from "@uikit/func";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { getIt } from "@uikit/getIt";

const { Paragraph } = Typography;

export default function VariablePopoverContent(props) {
  const fotSaveService = getIt(FotSaveService);
  const dispatch = useDispatch();
  const variableList = useSelector(state => state.fot.variableList);
  const [loading, setLoading] = useState(false);

  const setHighlightData = val => {
    dispatch(fotActions.setHighlightData(val));
  };

  const onMouseEnter = node => {
    setHighlightData({
      nodeId: node.id,
    });
  };
  const onMouseLeave = () => {
    setHighlightData({});
  };

  const handleClickDelete = async ({ name }) => {
    const newVariableList = variableList.filter(variable => variable.name !== name);
    setLoading(true);

    try {
      await fotSaveService.handleSave({
        variableList: newVariableList,
      });
      onMouseLeave();
      dispatch(fotActions.setVariableList(newVariableList));
    } catch (error) {
      console.log(error);
      func.messageError(error);
    }

    setLoading(false);
  };

  return (
    <div className={style["variable-list"]}>
      <Spin spinning={loading}>
        {!props.showDelet ? (
          <>
            <div key="currentSourceNodeValue" className={style["variable-item"]} onMouseLeave={onMouseLeave}>
              <Paragraph copyable>currentSourceNodeValue</Paragraph>
            </div>
            <div key="brainAuthToken" className={style["variable-item"]} onMouseLeave={onMouseLeave}>
              <Paragraph copyable>brainAuthToken</Paragraph>
            </div>
          </>
        ) : null}
        {variableList.map((variable, vIdx) => {
          return (
            <div
              key={vIdx}
              className={style["variable-item"]}
              onMouseEnter={() => onMouseEnter(variable.node)}
              onMouseLeave={onMouseLeave}
            >
              <Paragraph copyable>{!props.showDelet ? variable.name : `<${variable.name}>`}</Paragraph>
              {props.showDelet ? (
                <Popconfirm
                  overlayStyle={{ fontFamily: "Ak-Regular" }}
                  title={`Delete the variable ${variable.name}?`}
                  onConfirm={() => handleClickDelete(variable)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className={style["delete-icon"]}>
                    <Tooltip title="Delete">
                      <CloseOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </div>
                </Popconfirm>
              ) : null}
            </div>
          );
        })}
      </Spin>
    </div>
  );
}
