import { getIt } from "@uikit/getIt";
import { ChatAIPage } from "@uiview/views/ChatAI/ChatAISharedPage";
import PreviewPane from "@uiview/views/PreviewPane";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { useEffect } from "react";
import { PreviewAppUtil } from "@uiview/views/PreviewApp/PreviewAppUtil";
import { previewStore } from "@uiview/store/PreviewStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";

export function CreatorPreviewPanel(): JSX.Element {
  const homePluginStore = getIt(HomePluginStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorPreviewService = getIt(CreatorPreviewService);
  const previewState = useImagicaStore(previewStore).value;
  const homePluginState = useImagincStore(homePluginStore).value;

  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  useEffect(() => {
    PreviewAppUtil.handleChatOutputEdgeId({
      saasUIData,
      previewAppList: previewState.previewAppList,
      nodes: creatorNodesStore.getNodes(),
    });
  }, [saasUIData.output, previewState.previewAppList]);

  if (!homePluginState.isShare && previewState.showPreviewPanel) {
    return (
      <PreviewPane
        closePreviewApp={() => {
          creatorPreviewService.closePreviewApp();
          creatorPreviewService.cancelCreatePreview();
        }}
      />
    );
  }

  if (saasUIData?.v2App?.type === "chat_ai" && homePluginState.isShare && previewState.showPreviewPanel) {
    return <ChatAIPage />;
  }

  return <></>;
}
