import { useRenderData } from "@uiview/views/AISaas/hooks/useRenderData";

import style from "./SaasHeadPc.module.scss";

export function SaasHeadPc(props: AISaas.AISaasProps) {
  const titleRenderData = useRenderData({ type: "title" });
  const subtitleRenderData = useRenderData({ type: "subtitle" });
  return (
    <div className={style["saasHeadPc"]}>
      <div className={style["saas-top"]}>
        {props.saasUIData?.showTitle && (
          <div className={style["title-box"]}>
            <div style={titleRenderData.style} className={style["saas-title"]}>
              {props.saasUIData?.title}
            </div>
          </div>
        )}
        {props.saasUIData?.showSubTitle && (
          <div className={style["title-box"]}>
            <div style={subtitleRenderData.style} className={style["saas-subTitle"]}>
              {props.saasUIData?.subTitle}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
