import { useEffect, useRef, useCallback } from "react";
import { FocusNode } from "@uikit/service/FocusNode";
import { getIt } from "@uikit/getIt";
import { debounce } from "lodash";
import { Signal } from "@preact/signals-react";
import { CommandBarController } from "@uiview/views/CustomNewEdge/CommandBarController";

type FocusCustomEdgeProps = {
  edgeId: string;
  /**
   * 是否为v2版本
   */
  v2: boolean;

  edgeStateSignal: Signal<CustomEdge.EdgeType | undefined>;

  // onFocusFinished?: (param: MoveToHandlerResult) => void;

  controller: CommandBarController;
};

/**
 * 边的聚焦逻辑
 *
 * 抽离逻辑，为了分离聚焦边时产生的下列问题:
 * - useEffect 在监听 edgeState 时改变聚焦位置时，初始化和 input focus 同时执行了导致聚焦多次，位置偏移
 * - 新建边时存在 setCenter 操作，该操作有一个动画
 * - 解决方法： 保留 input focus 时聚焦边， 在不修改 customEdge 状态和逻辑的基础上在该 hook 中完成逻辑
 *
 * @param props
 * @returns
 */
// eslint-disable-next-line
export function useFocusCustomEdge(props: FocusCustomEdgeProps) {
  const { v2, edgeId, edgeStateSignal, controller } = props;

  // 冗余容器上到状态
  // FIXME: 避免在 customEdge 监听中，挂载时改变 edgeType 状态导致多条边聚焦
  // 1. 计数第一次不聚集，之后在执行聚焦视口操作
  // 2. 修改 edgeType 时在应该被拦截
  const edgeTypeTimesRef = useRef(0);

  useEffect(() => {
    return (): void => {
      edgeTypeTimesRef.current = 0;
    };
  }, []);

  const focusNode = getIt(FocusNode);
  // eslint-disable-next-line
  const _focusEdgeOrDropdown = (edgeType: CustomEdge.EdgeType) => {
    // input 出现 commnad bar 时，同时到视口3/1出
    // FIXME: stud-1102 编辑边的时候出现聚焦中心, 只去掉 command bar 移动的行为
    // 其他情况居中
    const focusMethod = v2 ? focusNode.focusEdgeByIdV2 : focusNode.focusEdgeById;

    focusMethod(edgeId)
      .then(param => {
        const { type } = param;
        // 动画移动完成，或者在原来位置
        if (type === "finished" || type === "skip") {
          // 通知动画已结束
          if (edgeStateSignal.value === "input") {
            controller.notify(param);
          }
        }
      })
      .finally(() => {
        controller.close();
      });
  };

  // bsf-2090 300ms 之后
  // 内部依赖了 showPerviewPanel 状态
  const focusEdgeOrDropdown = debounce(_focusEdgeOrDropdown, 400);

  /**
   * 重写改变 EdgeType
   *
   * TODO: 当关闭输入框时，会被重置为 run， 应该去掉被重置为run时到聚焦
   */
  const setEdgeState = useCallback(
    (value: CustomEdge.EdgeType, callback?: () => void) => {
      if (value !== "input") {
        callback && callback();
      }
      if (edgeStateSignal.value !== value) {
        edgeStateSignal.value = value;
      }

      edgeTypeTimesRef.current++;
      // 当组件被挂载之后才执行edgeType 改变移动视口
      if (edgeTypeTimesRef.current > 1) {
        // TODO: 状态没变，不进行聚焦
        // 因为外部改变 edgeType 是从 useEffect 监听中进行的状态改变
        // 在这基础上很难控制聚焦时应该跳过第一次挂载时的聚焦，并且执行完成（也就是run，generating）两种状态时监听不影响其他边的聚焦
        // 所以在这影响下，目前通过拦截改变 edgeType 状态控制聚焦
        if (!value || value !== "input") {
          return;
        }

        // 开始控制 focus 动画改变 commandbar
        controller.start();
        focusEdgeOrDropdown(value);
      }
    },
    [focusEdgeOrDropdown]
  );

  /**
   * 为 focus 事件提供的方法
   */
  const onFocusToFocusEdge = (): void => {
    setEdgeState("input");
    // edgeTypeTimesRef.current++
    // focusEdgeOrDropdown("input");
  };

  return { onFocusToFocusEdge, setEdgeState };
}
