interface Props {
  isActive: boolean;
}
export function SettingFeaturesIcon(props: Props) {
  const isActive = props.isActive;

  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.897 18.3711L8.48144 19.6856C8.65518 20.0768 8.93872 20.4093 9.29767 20.6426C9.65661 20.8759 10.0755 21.0001 10.5037 21C10.9318 21.0001 11.3507 20.8759 11.7097 20.6426C12.0686 20.4093 12.3521 20.0768 12.5259 19.6856L13.1103 18.3711C13.3184 17.9047 13.6683 17.5159 14.1103 17.26C14.5551 17.0034 15.0697 16.8941 15.5803 16.9478L17.0103 17.1C17.436 17.145 17.8656 17.0656 18.247 16.8713C18.6284 16.6771 18.9453 16.3763 19.1592 16.0056C19.3734 15.635 19.4754 15.2103 19.4529 14.7829C19.4305 14.3555 19.2844 13.9438 19.0326 13.5978L18.1859 12.4344C17.8844 12.0171 17.7233 11.5148 17.7259 11C17.7258 10.4866 17.8884 9.98635 18.1903 9.57111L19.037 8.40778C19.2889 8.06175 19.4349 7.65007 19.4574 7.22267C19.4799 6.79528 19.3778 6.37054 19.1637 6C18.9498 5.62923 18.6329 5.32849 18.2515 5.13423C17.87 4.93997 17.4404 4.86053 17.0148 4.90556L15.5848 5.05778C15.0741 5.11141 14.5596 5.00212 14.1148 4.74556C13.6719 4.48825 13.3218 4.09736 13.1148 3.62889L12.5259 2.31444C12.3521 1.92317 12.0686 1.59072 11.7097 1.3574C11.3507 1.12408 10.9318 0.99993 10.5037 1C10.0755 0.99993 9.65661 1.12408 9.29767 1.3574C8.93872 1.59072 8.65518 1.92317 8.48144 2.31444L7.897 3.62889C7.68992 4.09736 7.33987 4.48825 6.897 4.74556C6.45221 5.00212 5.93766 5.11141 5.427 5.05778L3.99255 4.90556C3.56688 4.86053 3.13729 4.93997 2.75587 5.13423C2.37444 5.32849 2.05757 5.62923 1.84366 6C1.62948 6.37054 1.52744 6.79528 1.54993 7.22267C1.57241 7.65007 1.71845 8.06175 1.97033 8.40778L2.81699 9.57111C3.11894 9.98635 3.28153 10.4866 3.28144 11C3.28153 11.5134 3.11894 12.0137 2.81699 12.4289L1.97033 13.5922C1.71845 13.9382 1.57241 14.3499 1.54993 14.7773C1.52744 15.2047 1.62948 15.6295 1.84366 16C2.05778 16.3706 2.37469 16.6712 2.75606 16.8654C3.13743 17.0596 3.56691 17.1392 3.99255 17.0944L5.42255 16.9422C5.93322 16.8886 6.44777 16.9979 6.89255 17.2544C7.33708 17.511 7.68876 17.902 7.897 18.3711Z"
        stroke={isActive ? "white" : "#020C26"}
        strokeOpacity={isActive ? "1" : "0.35"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5019 14C12.1587 14 13.5019 12.6569 13.5019 11C13.5019 9.34315 12.1587 8 10.5019 8C8.84502 8 7.50187 9.34315 7.50187 11C7.50187 12.6569 8.84502 14 10.5019 14Z"
        stroke={isActive ? "white" : "#020C26"}
        strokeOpacity={isActive ? "1" : "0.35"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
