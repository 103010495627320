import mapboxgl from "mapbox-gl";
import { useSignal } from "@preact/signals-react";
import { useRef } from "react";
import { FotClient } from "imagica-corekit/dist/base/api/FotClient";
import { getIt } from "../../../uikit/getIt";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

export class MapViewBloc {
  mapboxgl: typeof mapboxgl;
  constructor(reMapboxgl: typeof mapboxgl) {
    this.mapboxgl = reMapboxgl;
  }
  brainClient = getIt(FotClient);
  // data: mapboxgl.LngLatLike = [-79.4512, 43.6568]
  map = useSignal(<any>null);
  id = useSignal("");
  marker = useSignal(<any>{});
  mapMarkerRef = useRef(null);
  markerIcon = "https://dopniceu5am9m.cloudfront.net/static/blocks/icon/map-icon.svg";

  // async getAddress(address= 'San Francisco, CA, USA') {
  //   const addressData = await tryPromise(this.brainClient.map.getMapAddressGps(address));
  //   const resultData = addressData.data!
  //   const data = resultData.data!
  //   const locationObj = data?.data?.results?.[0]?.geometry?.location || {lng: 0, lat: 0}
  //   if(locationObj.lat === 0) throw 'error null';
  //   this.locationLatLng.value = [locationObj.lng || 0, locationObj.lat || 0]
  // }

  async init(id: string, textAreaValue: number[]) {
    try {
      this.id.value = id;
      const locationLatLng: mapboxgl.LngLatLike = (textAreaValue as mapboxgl.LngLatLike) || [0, 0];
      mapboxgl.accessToken = settings.mapKey;
      this.map.value = new mapboxgl.Map({
        container: id,
        style: "mapbox://styles/mapbox/light-v9",
        center: locationLatLng,
        zoom: 13,
      });
      this.setMarkNew(locationLatLng);
    } catch (error) {
      console.log("error:::::", error);
    }
  }

  setMarkNew(locationLatLng: any) {
    if (this.map.value) {
      // 添加坐标点
      const markerDiv = document.getElementById(`mapbox${this.id.value}`);
      if (markerDiv) {
        new mapboxgl.Marker(markerDiv, {
          offset: [0, 0],
        })
          .setLngLat(locationLatLng)
          .addTo(this.map.value);
        this.map.value.setCenter(locationLatLng);
      }
    }
  }
}
