import { SetURLSearchParams } from "react-router-dom";

export class ToSignUpViewBloc {
  clickToSignUpButton() {
    let url = window.location.href;

    // 判定路由里是否出现两个 ? 号。
    const hasTwoQuestionMarks = (url.match(/\?/g) || []).length >= 2;
    if (hasTwoQuestionMarks) {
      // 去掉第一个问号到 # 的数据。
      url = url.replace(/\?.*?(?=#)/, "");
    }

    if (url.includes("/editor/shared_intelligent_app")) {
      url = url.replace("/editor/shared_intelligent_app", "/editor/sign_up");
    } else {
      url = url.replace("/editor", "/signup");
    }
    window.history.pushState({}, "", url);
  }
}
