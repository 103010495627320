import { Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import style from "./css/IdeaList.module.css";
import { createIdeaListStore } from "@uikit/store/creatorIdeaListStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";

function IdeaList(props) {
  const [listIndex, setListIndex] = useState(0);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const selectedIdeaRef = useRef({});
  const selectedIndexIdeaRef = useRef(new Map());
  // 后退按钮
  const clickBackBtn = () => {
    const obj = selectedIndexIdeaRef.current.get(creatorRefStore.extractDescriptionRef.current) || {};
    const newListIndex = listIndex - 1;
    selectedIdeaRef.current = obj[newListIndex];
    setListIndex(newListIndex);
  };
  // 前进按钮
  const clickForwardBtn = () => {
    const obj = selectedIndexIdeaRef.current.get(creatorRefStore.extractDescriptionRef.current) || {};
    const newListIndex = listIndex + 1;
    selectedIdeaRef.current = obj[newListIndex];
    setListIndex(newListIndex);
  };
  // 点击列表项
  const clickIdea = async idea => {
    if (listIndex >= 1) return;
    createIdeaListStore.setIdeaLoading(true);
    selectedIdeaRef.current = idea;
    try {
      const query = `The AI generates missing APIs based on a product description and Existing APIs.


Product Description: ${idea.title}\n${idea.content}

Existing API:  ${creatorRefStore.extractDescriptionRef.current.replace(/\bAI\b/g, "API")}

Missing APIs:`;
      const results = await props.postAIDescription(query);
      const newIdeaList = [...props.ideaList, results];
      // 更新数据
      createIdeaListStore.setIdeaList(newIdeaList);
      // 显示最后一页列表
      setListIndex(newIdeaList.length - 1);
      // 缓存选过的idea列表项及对应的index
      const obj = selectedIndexIdeaRef.current.get(creatorRefStore.extractDescriptionRef.current) || {};
      obj[newIdeaList.length - 1] = idea;
      selectedIndexIdeaRef.current.set(creatorRefStore.extractDescriptionRef.current, obj);
      // 缓存列表数据
      creatorRefStore.ideaDescriptionRef.current.set(creatorRefStore.extractDescriptionRef.current, newIdeaList);
    } catch (error) {
      console.error("clickIdea", error);
    } finally {
      createIdeaListStore.setIdeaLoading(false);
    }
  };
  // 刷新
  const clickRefreshBtn = async () => {
    try {
      setRefreshLoading(true);
      // 获取idea列表
      let query = `The system generates a list of possible applications with explanations of why the market needs something like that based on a description of an AI.
Query: ${creatorRefStore.extractDescriptionRef.current}
Output:`;
      if (listIndex > 0) {
        query = `The AI generates missing APIs based on a product description and Existing APIs.


Product Description: ${selectedIdeaRef.current.title}\n${selectedIdeaRef.current.content}

Existing API:  ${creatorRefStore.extractDescriptionRef.current.replace(/\bAI\b/g, "API")}

Missing APIs:`;
      }

      const resArr = await props.postAIDescription(query);
      const newIdeaList = props.ideaList.map((x, index) => {
        if (index === listIndex) {
          return resArr;
        }
        return x;
      });
      // 重新设置值
      createIdeaListStore.setIdeaList(newIdeaList);
      // 缓存
      creatorRefStore.ideaDescriptionRef.current.set(creatorRefStore.extractDescriptionRef.current, newIdeaList);
    } catch (error) {
      console.error("clickRefreshBtn", error);
    } finally {
      setRefreshLoading(false);
    }
  };

  useEffect(() => {
    selectedIdeaRef.current = {};
    setListIndex(0);
  }, [creatorRefStore.selectedNodeIdRef.current]);

  return (
    <div className={style.IdeaPage}>
      <Spin spinning={props.ideaLoading}>
        {/* 顶部返回前进按钮 */}
        <header>
          {listIndex > 0 ? (
            <div className={style["right-arrow"]}>
              <span className="iconfont icon-fanhui" onClick={clickBackBtn}></span>
            </div>
          ) : null}
          <div className={style["left-arrow"]}>
            {listIndex < props.ideaList.length - 1 ? (
              <span className={`iconfont icon-fanhui ${style["icon-forward"]}`} onClick={clickForwardBtn}></span>
            ) : null}
            <span
              className={`iconfont icon-shuaxin ${refreshLoading ? style["refresh-loading"] : ""}`}
              onClick={clickRefreshBtn}
            ></span>
            <span
              className={style["shrink-dashed"]}
              onClick={() => createIdeaListStore.setShrinkIdeaList(false)}
            ></span>
          </div>
        </header>
        {/* 列表 */}
        <div className={style.IdeaListBox}>
          {props.ideaList[listIndex]?.map((x, index) => {
            return (
              <div className={style.ideaBox} onClick={() => clickIdea(x)} key={index}>
                <div className={style.title}>{x.title}</div>
                <div className={style.content}>{x.content}</div>
              </div>
            );
          })}
        </div>
      </Spin>
    </div>
  );
}

export default IdeaList;
