import { useSelector, useDispatch } from "react-redux";
import style from "./style.module.scss";
import { CSSProperties, FC, useCallback, useEffect, useRef, useState } from "react";
import { studioActions } from "../../../store/studio";
import { fotActions } from "../../../store/fot";
import { editorActions } from "../../../store/editor";
import func from "@uikit/func";
import { findIndex, isNil } from "lodash";
import useWaitingTodo from "../../../custom-hooks/useWaitingTodo";
import { useNavigate } from "react-router-dom";
import { logEvent } from "@uikit/service/amplitude";
import { Help } from "../../components/Help";
import Sidebar from "./Sidebar";
import { SidebarItems } from "./Sidebar/types";
import { Contents } from "./types";
import { AppDispatch } from "@store/index";
import { getIt } from "@uikit/getIt";
import { useProjectTemplate } from "./useProjectTemplate";
import { useSpring } from "@react-spring/web";
import { HomeUsePluginUtil } from "@uikit/util/HomeUsePluginUtil";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { ProjectListView } from "./Project/ProjectListView";
import { FunctionListView } from "./Function/FunctionListView";
import { TemplateListView } from "./Template/TemplateListView";
import { CarouselHeadView } from "./Carousel/CarouselHeaderView";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { ReadyStatus } from "imagica-corekit/dist/base/cutil/ReadyStatus";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { ProjectLoader } from "@uikit/fotProcess/ProjectLoader";
import cls from "classnames";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { useAModalProvider } from "@uikit/context/AModalContext/useAModalProvider";
import { CotStore } from "@uikit/store/CotStore";
import { CreatorCanvasFocus } from "@uikit/cases/canvasFocus/CreatorCanvasFocus";
import { CreateNewProjectService } from "@uiview/views/ProjectCanvas/CreateNewProject";
import { FotNavigateController } from "@uikit/service/FotNavigateController";

export interface HomeProps {
  pushFunc: (data: any) => void;
  emptyNodes: () => void;
}

const HomeCollectionView: FC<HomeProps> = ({ pushFunc, emptyNodes }) => {
  const gql = getIt(ObjectRelationGqlService);
  const homeStore = getIt(HomeStore);
  const meStore = getIt(MeStore);
  const cotStore = getIt(CotStore);
  const showNavPageStore = getIt(ShowNavPageStore);
  const projectLoader = getIt(ProjectLoader);
  const homePluginStore = getIt(HomePluginStore);
  const creatorCanvasFocus = getIt(CreatorCanvasFocus);
  const homePluginState = useImagicaStore(homePluginStore).value;
  const projectFunctionStore = getIt(ProjectFunctionStore);
  const fotNavigateController = getIt(FotNavigateController);
  const createNewProjectService = getIt(CreateNewProjectService);
  const isShowSideBarState = useImagicaStore(showNavPageStore).value;
  // const smallScreenWidth = 979;
  ///////////// NEW
  const feature_tags = homeStore.state.featureTags;
  const dispatch = useDispatch<AppDispatch>();
  const previewAppStore = getIt(PreviewAppStore);
  const navigate = useNavigate();
  const userMe = useSelector((state: any) => state.editor.me);
  const [sidebarItem, setSidebarItem] = useState<SidebarItems>(SidebarItems.Templates);
  const [content, setContent] = useState<Contents>(Contents.Templates);

  const [viewOfTemplates, viewOfTemplatesApi] = useSpring(() => ({}));
  const [viewOfMyProjects, viewOfMyProjectsApi] = useSpring(() => ({}));
  const [viewOfMyFunctions, viewOfMyFunctionsApi] = useSpring(() => ({}));

  ///////////////// OLD

  const { showModal } = useAModalProvider();

  const { handleWaitingTodo } = useWaitingTodo("editor.me.objectId", () => createNewProjectService.addNewProject());

  const setLoadingState = useCallback(
    (val: any) => {
      dispatch(studioActions.setLoadingState(val));
    },
    [dispatch]
  );
  const imageGenSelectOptions = useSelector((state: any) => state.fot.imageGenSelectOptions);
  const setSelectedTemplate = useCallback(
    (val: any) => {
      dispatch(studioActions.setSelectedTemplate(val));
    },
    [dispatch]
  );
  const setShowTooltip = useCallback(
    (val: any) => {
      dispatch(editorActions.setShowTooltip(val));
    },
    [dispatch]
  );
  const setEnableTooltipWhatTodo = useCallback(
    (val: any) => {
      dispatch(editorActions.setEnableTooltipWhatTodo(val));
    },
    [dispatch]
  );

  const setImageGenSelectOptions = useCallback(
    (val: any) => {
      dispatch(fotActions.setImageGenSelectOptions(val));
    },
    [dispatch]
  );
  const setFetching = useCallback(
    (val: any) => {
      dispatch(fotActions.setFetching(val));
    },
    [dispatch]
  );
  // const setReviseCustomCode = (val) => { dispatch(editorActions.setReviseCustomCode(val)) };
  const setCreateJSFuncData = useCallback(
    (val: any) => {
      dispatch(editorActions.setCreateJSFuncData(val));
    },
    [dispatch]
  );
  const setCreateAPIFuncData = useCallback(
    (val: any) => {
      dispatch(editorActions.setCreateAPIFuncData(val));
    },
    [dispatch]
  );

  const selectedExistingProject = useRef({});

  const [formatTemplates, setFormatTemplates] = useState<any>([]);
  const [existingProjectSearchTerm, setExistingProjectSearchTerm] = useState({
    value: "",
    label: "",
  });

  const setEditorIdArr = useCallback(
    (val: any) => {
      dispatch(fotActions.setEditorIdArr(val));
    },
    [dispatch]
  );
  const setCheckEdgeArr = useCallback(
    (val: any) => {
      dispatch(fotActions.setCheckEdgeArr(val));
    },
    [dispatch]
  );
  const setCheckNodeArr = useCallback(
    (val: any) => {
      dispatch(fotActions.setCheckNodeArr(val));
    },
    [dispatch]
  );

  const setStudioProjectName = useCallback(
    (val: any) => {
      dispatch(fotActions.setStudioProjectName(val));
    },
    [dispatch]
  );

  const adpotSelectedExistingProject = useCallback(
    (defaultTemplateName: any) => {
      const project = {
        ...selectedExistingProject.current,
        defaultTemplateName,
      } as PreviewApp.ProjectType;
      try {
        setSelectedTemplate(project);
        projectLoader.loadFromExistingTemplateV2(project);
      } catch (error: any) {
        func.customMsg({
          type: "error",
          content: error.message,
        });
        return;
      }
      setSelectedTemplate(project);
    },
    [setSelectedTemplate]
  );

  const getTemplateByName = useCallback(
    async (param: any, relationId: string): Promise<void | never> => {
      try {
        setFetching(true);
        const res = await gql.objectSet({ ...param });
        if (func.isEmpty(res?.[0]?.attributes)) {
          // eslint-disable-next-line no-throw-literal
          throw "Get template failed";
        }

        // 点击已有project时转换v2数据到v3
        let attributesV2 = res[0].attributesV2;
        if (func.isEmpty(attributesV2.v3)) {
          attributesV2 = HomeUsePluginUtil.handleV2TransformV3Data({
            attributesV2,
            nodeIndexRef: creatorRefStore.nodeIndexRef,
            edgeIndexRef: creatorRefStore.edgeIndexRef,
            newEdgeDataRef: getIt(CanvasDataRef).newEdgeDataRef,
            newLineDataRef: getIt(CanvasDataRef).newLineDataRef,
          });
        }

        selectedExistingProject.current = {
          ...attributesV2,
          id: res[0].id,
          name: res[0].attributesV2.template_name,
          uuid: res[0].uuid,
          updatedAt: res[0].updatedAt,
          relationId,
        };
      } catch (error: any) {
        func.customMsg({
          type: "error",
          content: error?.message || "Get template failed",
        });
        selectedExistingProject.current = {};
        // 抛出异常，阻断后续代码执行
        throw error;
      } finally {
        setFetching(false);
      }
    },
    [feature_tags, setFetching]
  );

  const getAppropriateParam = useCallback((value: any) => {
    const paramType: Record<string, any> = {
      attrIndexer: {
        key: "attrIndexer",
        value: value.attrIndexer,
      },
      uuid: {
        key: "uuid",
        value: value.uuid,
      },
      id: {
        key: "id",
        value: value.id,
      },
    };
    const param: Record<string, any> = {};
    Object.keys(paramType).some((x: any) => {
      if (!func.isEmpty(value[x])) {
        param[paramType[x].key] = paramType[x].value;
        return true;
      }
      return false;
    });
    return param;
  }, []);

  /**
   * 打开项目
   * @param {import('./types').FormatTemplateType} value
   */
  const openProject = useCallback(
    async (value: any): Promise<void> => {
      try {
        /// loading 中禁用全页面点击
        document.addEventListener("click", disableClick, true);
        const param = getAppropriateParam(value);
        await getTemplateByName(param, value.relationId);
        // 清空上一次的 previewApp 数据
        previewAppStore.clearPreviewApp();
        // 清空上个项目显示数据
        emptyNodes();
        setEnableTooltipWhatTodo(false);
        // 进入已有项目显示 cot 图标
        cotStore.setShowCot(false);
        setExistingProjectSearchTerm({
          value: value.value,
          label: value.label,
        });
        adpotSelectedExistingProject(value.defaultTemplateName);
        navigate(`/editor/${value.attrIndexer || value.uuid || value.id}`);
        setStudioProjectName(existingProjectSearchTerm.label);

        // 重置股票购买数据
        setEditorIdArr([]);
        setCheckEdgeArr([]);
        setCheckNodeArr([]);

        logEvent("open_project");
      } finally {
        /// 移除禁用
        setTimeout(() => {
          document.removeEventListener("click", disableClick, true);
        }, 1000);
      }
    },
    [
      getAppropriateParam,
      adpotSelectedExistingProject,
      existingProjectSearchTerm.label,
      getTemplateByName,
      navigate,
      setCheckEdgeArr,
      setCheckNodeArr,
      setEditorIdArr,
      setEnableTooltipWhatTodo,
      setStudioProjectName,
      emptyNodes,
      disableClick,
    ]
  );

  function disableClick(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  const handleClickDelete = useCallback(
    (type: any, id: any, name: any, object: any = {}): Promise<boolean> => {
      return new Promise<boolean>(resolve => {
        showModal({
          width: 520,
          title: "Confirm your action",
          subTitle: `Do you want to delete the ${type} named "${name}"? This action cannot be undone.`,
          buttons: ["Cancel", "Delete"],
          danger: true,
          shouldExecuteOkOnEnter: true,
          onOk: async ({ setLoading, close }) => {
            let relationId = id;

            // For the copied functions or objects, they don't have relation id currently.
            if (isNil(id)) {
              const relationResponse = await gql.relationSet(undefined, undefined, {
                destId: object.id,
              });
              relationId = relationResponse?.[0]?.relationId;
            }

            if (isNil(relationId)) {
              close();
              return func.customMsg({
                type: "error",
                content: "No relation id found, delete failed",
              });
            }

            if (type === "project") {
              setFormatTemplates((prevList: any) => {
                return prevList.map((x: any) => {
                  if (x.relationId === relationId) {
                    return {
                      ...x,
                      loading: true,
                    };
                  }
                  return x;
                });
              });
            }
            setLoading(true);
            await gql.deleteRelations(relationId, "", true);
            if (type === "project") {
              setFormatTemplates((prevList: any) => {
                return prevList
                  .map((x: any) => {
                    if (x.relationId === id) {
                      return {
                        ...x,
                        loading: false,
                      };
                    }
                    return x;
                  })
                  .filter((x: any) => x.relationId !== id);
              });
              setLoading(false);
              close();
            }
            if (type === "function") {
              projectFunctionStore.deleteFunctionById(object.id);
              setLoading(false);
              close();
              logEvent("delete_function_confirmed", object);
            }
            resolve(true);
          },
          onCancel: () => {
            resolve(false);
          },
        });
      });
    },
    [showModal]
  );

  const setCardLoading = (id: any, bool: any): void => {
    setFormatTemplates((prevList: any) => {
      return prevList.map((x: any) => {
        if (x.id === id) {
          return {
            ...x,
            loading: bool,
          };
        }
        return x;
      });
    });
  };

  const handleClickDuplicate = useCallback(
    async (type: "project" | "function" = "project", object: any): Promise<any> => {
      const isLoading = formatTemplates.some((x: any) => x.loading);
      if (isLoading) return;
      try {
        setCardLoading(object.id, true);
        const functionObj = {
          project: "shareStudioProject",
          function: "shareStudioFunction",
        };
        let method;
        if (functionObj[type] === "shareStudioProject") {
          method = gql.shareStudioProject;
        } else if (functionObj[type] === "shareStudioFunction") {
          method = gql.shareStudioFunction;
        } else {
          throw Error();
        }
        const res = await method(object.id, userMe.id, "", true);
        const formatObject = {
          id: res.id,
          label: res.attributesV2.name,
          value: res.attributesV2.name,
          loading: false,
        };
        if (type === "project") {
          setFormatTemplates((prevList: any) => {
            const index = findIndex(prevList, (item: any) => item.id === object.id);
            prevList.splice(index + 1, 0, { ...formatObject });
            return prevList;
          });
        } else {
          const newFunction = projectFunctionStore.copyFunction(res);
          newFunction && pushFunc(newFunction);
        }
        return res;
      } catch (error: any) {
        console.error(error);
        throw Error();
      } finally {
        setCardLoading(object.id, false);
      }
    },
    [formatTemplates, imageGenSelectOptions, pushFunc, setImageGenSelectOptions, userMe.id]
  );

  const clickModifyFunction = useCallback(
    async (obj: any): Promise<void> => {
      // use obj.id to determine hardcoded function , and use obj.editable to determine global function
      // const valueArr = ['protein', 'imageGen', 'stockInfo', 'midjourney', 'video', 'jdShopping', 'amazonShopping']
      if (!obj.id || !obj.editable) return;
      setEnableTooltipWhatTodo(false);
      switch (obj.type) {
        // function-js-page
        case "CustomCode":
          // props.setMyModificationData(obj)
          // setReviseCustomCode(true)
          setCreateJSFuncData({
            open: true,
            edgeId: "",
            sourceNodeId: "",
            targetNodeId: "",
            state: "modify",
            select: obj,
            parameter: {
              name: obj.value,
              description: obj.description,
            },
          });
          break;
        // function-api-page
        case "customApi": {
          const hasPermission = await meStore.hasCustomApiV2();
          if (hasPermission) {
            navigate(`function/${obj.id}`);
          }
          setCreateAPIFuncData({
            open: true,
            edgeId: "",
            sourceNodeId: "",
            targetNodeId: "",
            state: "modify",
            select: obj,
            parameter: {
              name: obj.value,
              description: obj.description,
            },
          });
          break;
        }
        // function-normal-page
        default:
          fotNavigateController.openFunctionNormalPage(obj);
          // 原来的数据会在加载 function-normal page 后确认数据
          break;
      }
    },
    [setCreateAPIFuncData, setCreateJSFuncData, setEnableTooltipWhatTodo]
  );

  const handleClickAddNewProject = useCallback(() => {
    if (homePluginState.showImagicaTutoriralLoading === ReadyStatus.LOADING) return;
    homeStore.setIsClickAddNewProject(true);
    setLoadingState({
      promptSaveBtn: true,
      promptNewBtn: true,
      promptCard: true,
    });
    setSelectedTemplate({});
    setShowTooltip("");
    previewAppStore.clearPreviewApp();

    handleWaitingTodo();
  }, [
    handleWaitingTodo,
    setLoadingState,
    setSelectedTemplate,
    setShowTooltip,
    homePluginState.showImagicaTutoriralLoading,
  ]);

  /**
   * TODO: 将 openProjectByShareProject 和 entryCanvas 整合
   * @param {import('./types').FormatTemplateType} value
   */
  const openProjectByShareProject = async (value: any): Promise<void> => {
    await openProject(value);
    // target:handleSelectedExistingTemplateChange -> finally
    showNavPageStore.setShowNavPage(false);
  };

  const { onShareProject } = useProjectTemplate({
    onShareProject: openProjectByShareProject,
  });

  const handleProjectTemplateClick = useCallback(
    async (item: any): Promise<void> => {
      document.addEventListener("click", disableClick, true);
      try {
        await onShareProject(item, true);
        logEvent("create_project", { template_name: item.title });
      } catch (error) {
        console.error("handleProjectTemplateClick", error);
      }
      setTimeout(() => {
        document.removeEventListener("click", disableClick, true);
      }, 1000);
    },
    [onShareProject]
  );
  const handleCloseSidebarMask = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    showNavPageStore.setShowSmallScreenSideBar(!showNavPageStore.state.isShowSmallScreenSideBar);
  };
  const handleSidebarItemChange = useCallback(
    (newSidebarItem: SidebarItems): void => {
      switch (newSidebarItem) {
        case SidebarItems.CreateNewProject:
          handleClickAddNewProject();
          return;
        case SidebarItems.Templates:
          // clickLogEvent('home sidebar templates');
          setSidebarItem(SidebarItems.Templates);
          setContent(Contents.Templates);
          break;
        case SidebarItems.MyProjects:
          // clickLogEvent('home sidebar projects');
          setSidebarItem(SidebarItems.MyProjects);
          setContent(Contents.Projects);
          break;
        case SidebarItems.MyFunctions:
          // clickLogEvent('home sidebar functions');
          setSidebarItem(SidebarItems.MyFunctions);
          setContent(Contents.Functions);
          break;
      }
      setSidebarItem(newSidebarItem);
    },
    [handleClickAddNewProject]
  );

  const [templatesStyle, setTemplatesStyle] = useState<CSSProperties>({
    position: "absolute",
    top: "200px",
  });
  const [projectsStyle, setProjectsStyle] = useState<CSSProperties>({
    position: "absolute",
    top: "200px",
  });
  const [functionsStyle, setFunctionsStyle] = useState<CSSProperties>({
    position: "absolute",
    top: "200px",
  });

  const setAnimationCompletionStyles = (content: Contents): void => {
    const showStyle: CSSProperties = { display: "flex", position: "relative", zIndex: 100 };
    const hideStyle: CSSProperties = { display: "none", top: "200px" };
    setTemplatesStyle(content === Contents.Templates ? showStyle : hideStyle);
    setProjectsStyle(content === Contents.Projects ? showStyle : hideStyle);
    setFunctionsStyle(content === Contents.Functions ? showStyle : hideStyle);
  };

  useEffect(() => {
    const main = async (): Promise<void> => {
      if (content === Contents.Templates) {
        setTemplatesStyle({ display: "flex", position: "relative", zIndex: 100 });
        await Promise.all(
          viewOfTemplatesApi.start({
            config: { duration: 500 },
            from: {
              opacity: 0,
              top: "200px",
            },
            to: {
              opacity: 1,
              top: "0px",
            },
          })
        );
        setAnimationCompletionStyles(Contents.Templates);
      } else {
        setTemplatesStyle({ position: "absolute", zIndex: 50 });
        await Promise.all(
          viewOfTemplatesApi.start({
            config: { duration: 250 },
            to: {
              opacity: 0,
            },
          })
        );
        setTemplatesStyle({ display: "none", top: "200px" });
      }
    };
    main();
  }, [content, viewOfMyFunctionsApi, viewOfMyProjectsApi, viewOfTemplatesApi]);

  useEffect(() => {
    const main = async (): Promise<void> => {
      if (content === Contents.Projects) {
        setProjectsStyle({ display: "flex", position: "relative", zIndex: 100 });
        await Promise.all(
          viewOfMyProjectsApi.start({
            config: { duration: 500 },
            from: {
              opacity: 0,
              top: "200px",
            },
            to: {
              opacity: 1,
              top: "0px",
            },
          })
        );
        setAnimationCompletionStyles(Contents.Projects);
      } else {
        setProjectsStyle({ position: "absolute", zIndex: 50 });
        await Promise.all(
          viewOfMyProjectsApi.start({
            config: { duration: 250 },
            to: {
              opacity: 0,
            },
          })
        );
        setProjectsStyle({ display: "none", top: "200px" });
      }
    };
    main();
  }, [content, viewOfMyFunctionsApi, viewOfMyProjectsApi, viewOfTemplatesApi]);

  useEffect(() => {
    const main = async (): Promise<void> => {
      if (content === Contents.Functions) {
        setFunctionsStyle({ display: "flex", position: "relative", zIndex: 100 });
        await Promise.all(
          viewOfMyFunctionsApi.start({
            config: { duration: 500 },
            from: {
              opacity: 0,
              top: "200px",
            },
            to: {
              opacity: 1,
              top: "0px",
            },
          })
        );
        setAnimationCompletionStyles(Contents.Functions);
      } else {
        setFunctionsStyle({ position: "absolute", zIndex: 50 });
        await Promise.all(
          viewOfMyFunctionsApi.start({
            config: { duration: 250 },
            to: {
              opacity: 0,
            },
          })
        );
        setFunctionsStyle({ display: "none", top: "200px" });
      }
    };
    main();
  }, [content, viewOfMyFunctionsApi, viewOfMyProjectsApi, viewOfTemplatesApi]);

  useEffect(() => {
    // 每次回到首页都重置prevOptions
    if (showNavPageStore.state.showNavPage) {
      creatorCanvasFocus.resetPrevOptions();
    }
  }, [showNavPageStore.state.showNavPage]);

  return (
    <main className={style.main}>
      {/* {isShowSideBarState.isShowSmallScreenSideBar || window.innerWidth > smallScreenWidth ? ( */}
      <div
        className={cls(
          style.sidebarMask,
          !isShowSideBarState.isShowSmallScreenSideBar ? style.isVisibleSmallScreenSideBar : ""
        )}
        onClick={handleCloseSidebarMask}
      >
        <div
          className={cls(
            style.main__sidebar,
            !isShowSideBarState.isShowSmallScreenSideBar ? style.isVisibleSmallScreenSideBar : ""
          )}
        >
          <Sidebar sidebarItem={sidebarItem} onSidebarItemChange={handleSidebarItemChange} />
        </div>
      </div>
      {/* ) : null} */}

      <div className={style.main__content}>
        <div className={style.gridHomeRgtBox}>
          <CarouselHeadView handleProjectTemplateClick={handleProjectTemplateClick} />
          <div className={style.gridMainContentBox}>
            <div className={style.content__view}>
              <TemplateListView
                templatesStyle={templatesStyle}
                viewOfTemplates={viewOfTemplates}
                handleProjectTemplateClick={handleProjectTemplateClick}
              />
              <ProjectListView
                projectsStyle={projectsStyle}
                viewOfMyProjects={viewOfMyProjects}
                setShowNavPage={showNavPageStore.setShowNavPage}
                didLoadData={() => {
                  setSidebarItem(SidebarItems.MyProjects);
                  setContent(Contents.Projects);
                }}
                openProject={openProject}
                handleClickDelete={handleClickDelete}
                handleClickDuplicate={handleClickDuplicate}
              />
              <FunctionListView
                functionsStyle={functionsStyle}
                viewOfMyFunctions={viewOfMyFunctions}
                clickModifyFunction={clickModifyFunction}
                handleClickDelete={handleClickDelete}
                handleClickDuplicate={handleClickDuplicate}
              />
            </div>
          </div>
        </div>
      </div>
      <Help />
    </main>
  );
};

export default HomeCollectionView;
