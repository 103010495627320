"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticLinksType = void 0;
class StaticLinksType {
    constructor() {
        this.privacy = "";
        this.terms_of_service_creator = "";
        this.terms_of_service_subscriber = "";
        this.copyright_policy = "";
        this.discord_community = "";
        this.terms_of_service_environment = "";
        this.terms_of_service_platform = "";
    }
}
exports.StaticLinksType = StaticLinksType;
