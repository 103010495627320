"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddDataSourceFunctionUtil = void 0;
const StoryNodeDisplayType_1 = require("../../storyV2/domain/StoryNodeDisplayType");
const LangUtil_1 = require("../../cutil/LangUtil");
const Extensions_1 = require("../../cutil/Extensions");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const ChainRequest_1 = require("../../storyV2/response/customApiHandler/chain/ChainRequest");
const imageType = [
    StoryNodeDisplayType_1.StoryNodeDisplayType.IMAGEGEN,
    StoryNodeDisplayType_1.StoryNodeDisplayType.IMAGESEARCH,
    StoryNodeDisplayType_1.StoryNodeDisplayType.MIDJOURNEY,
    StoryNodeDisplayType_1.StoryNodeDisplayType.MIDJOURNEYV2,
];
const fileType = [
    StoryNodeDisplayType_1.StoryNodeDisplayType.UPLOADIMAGE,
    StoryNodeDisplayType_1.StoryNodeDisplayType.BASEVIDEO,
    StoryNodeDisplayType_1.StoryNodeDisplayType.AUDIO,
    StoryNodeDisplayType_1.StoryNodeDisplayType.PDFFILE,
    StoryNodeDisplayType_1.StoryNodeDisplayType.TABLE,
    StoryNodeDisplayType_1.StoryNodeDisplayType.UPLOADFILE,
];
class AddDataSourceFunctionUtil {
    /**
     * 是否为文件格式，图片也应该算作文件类型
     * @param type
     */
    static isFile(type) {
        return [...imageType, ...fileType].includes(type);
    }
    /**
     * 如果是文件类型，获取api 需要的参数
     * @param input
     * @param type
     */
    static getFileData(input, type) {
        let result = { filename: "", uuid: "", file: "" };
        const json = (0, LangUtil_1.tryBlock)(() => JSON.parse(input)).data;
        // usecase: 来自于blobHandler,这里比较特殊。此时run all会出现问题
        if (!(0, Extensions_1.isBlank)(json?.textAreaValue)) {
            const textAreaValue = JsonUtil_1.JsonUtil.toModelFromType(ChainRequest_1.ChainResultTextAreaValue, json.textAreaValue);
            const file = textAreaValue?.s3Path || textAreaValue?.value || textAreaValue?.s3Url;
            if (textAreaValue && !(0, Extensions_1.isBlank)(file)) {
                result.filename = textAreaValue.name;
                result.uuid = textAreaValue.uuid;
                result.file = file ?? "";
                return result;
            }
        }
        if (fileType.includes(type)) {
            result.filename = json.name;
            result.uuid = json.uuid;
            result.file = json.s3Path || json.value || json.s3Url;
            return result;
        }
        else if (imageType.includes(type)) {
            result.uuid = json.uuid;
            // 图片的话，content 是来自于fot，那么file 是 value， story里面的字段是imageUrl。兼容处理
            result.file = json.imageUrl || json.value;
            result.filename = this.fetchFilenameFromUrl(result.file) || "the picture";
            return result;
        }
        return result;
    }
    /**
     * 获取文件名
     * @param url
     * @private
     */
    static fetchFilenameFromUrl(url) {
        try {
            const urlObject = new URL(url);
            const pathArray = urlObject.pathname.split("/");
            return pathArray[pathArray.length - 1];
        }
        catch (error) {
            console.error("Invalid URL:", error);
            return null;
        }
    }
}
exports.AddDataSourceFunctionUtil = AddDataSourceFunctionUtil;
