"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorebeApi = void 0;
const StreamClient_1 = require("../cutil/StreamClient");
class CorebeApi {
    constructor(rest, streamRequest) {
        this.rest = rest;
        this.streamRequest = streamRequest;
    }
    storyRun(projectId) {
        return this.streamRequest.request({
            path: "/story/run",
            queryParameters: {
                projectId: projectId,
            },
            method: StreamClient_1.RestMethod.GET,
        });
    }
    storyRunBySocket(projectId, topic) {
        return this.rest.get("/socket/story/run", {
            projectId: projectId,
            topic: topic,
        });
    }
}
exports.CorebeApi = CorebeApi;
