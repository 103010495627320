"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayApi = void 0;
const JsonUtil_1 = require("../cutil/JsonUtil");
class PayApi {
    constructor(restClient) {
        this.restClient = restClient;
    }
    async execute(executionId, query, variables) {
        const url = "/be/gateway-api/graphql";
        const params = {
            operationName: "execution",
            query: query,
            variables: variables,
        };
        console.log(`execution params: ${JsonUtil_1.JsonUtil.stringify(params)}`);
        return this.restClient.post(url, params, undefined, { "X-Brain-Exec-Id": executionId }).then(res => {
            return res;
        });
    }
    /**
     * 支付
     * @param path
     * @param params
     */
    pay(path, params, queryId) {
        return this.restClient.post(path, params, undefined, { "X-Brain-Exec-Id": queryId });
    }
    async createOrUpdateQueryData(queryId, event, data) {
        const url = "/be/gateway-api/graphql?operationName=createOrUpdateQueryData";
        const query = {
            operationName: "createOrUpdateQueryData",
            query: `
        mutation createOrUpdateQueryData($queryId: String!, $data: JSON!, $event: String!) {
          createOrUpdateQueryData(queryId: $queryId, event: $event, data: $data) {
            __typename
              _id
              queryId
              event
              data
           }
        }
      `,
            variables: {
                event: event,
                data: data,
                queryId: queryId,
            },
        };
        console.log(`createOrUpdateQueryData params5: ${JsonUtil_1.JsonUtil.stringify(query)}`);
        const response = await this.restClient.post(url, query, undefined, { "X-Brain-Exec-Id": queryId });
        console.log(`createOrUpdateQueryData res: ${JsonUtil_1.JsonUtil.stringify(response)}`);
        return response;
    }
    async createNLPQuery(query, actionId, actionIds = undefined) {
        const url = "/be/gateway-api/graphql?operationName=createNLPQuery";
        const graphqlQuery = {
            operationName: "createNLPQuery",
            query: `
        mutation createNLPQuery($query: String!, $actionId: String!, $actionIds: [String!]) {
            createNLPQuery(query: $query, actionId: $actionId, actionIds: $actionIds) {
              __typename
              _id
              query
              status
              actionId
              actionIds
              data {
                __typename
                _id
                queryId
                event
                data
              }
              state {
                __typename
                _id
                queryId
                event
                data
              }
            }
          }
      `,
            variables: {
                query: query,
                actionId: actionId,
                actionIds: actionIds,
            },
        };
        console.log(`createNLPQuery graphqlQuery: ${JsonUtil_1.JsonUtil.stringify(graphqlQuery)}`);
        const response = await this.restClient.post(url, graphqlQuery, undefined);
        console.log(`createNLPQuery response: ${JsonUtil_1.JsonUtil.stringify(response)}`);
        return response;
    }
    async saveUserDataAndExecutionData(userData_queryId, userData_event, userData_data, executionv2_queryId, executionv2_event, executionv2_data) {
        const url = "/be/gateway-api/graphql?operationName=createOrUpdateQueryData";
        const query = {
            operationName: "createOrUpdateQueryData",
            query: `
        mutation createOrUpdateQueryData($userData_queryId: String!, $userData_event: String!, $userData_data: JSON!, $executionv2_queryId: String!, $executionv2_event: String!, $executionv2_data: JSON!) {
          userData:createOrUpdateQueryData(queryId: $userData_queryId, event: $userData_event, data: $userData_data) {
            __typename
            _id
            queryId
            event
            data
          }
        executionV2:createOrUpdateQueryData(queryId: $executionv2_queryId, event: $executionv2_event, data: $executionv2_data) {
            __typename
            _id
            queryId
            event
            data
          }
        }
      `,
            variables: {
                userData_queryId: userData_queryId,
                userData_event: userData_event,
                userData_data: userData_data,
                executionv2_queryId: executionv2_queryId,
                executionv2_event: executionv2_event,
                executionv2_data: executionv2_data,
            },
        };
        console.log(`saveUserDataAndExecutionData params: ${JsonUtil_1.JsonUtil.stringify(query)}`);
        const response = await this.restClient.post(url, query, undefined, { "X-Brain-Exec-Id": userData_queryId });
        console.log(`saveUserDataAndExecutionData res: ${JsonUtil_1.JsonUtil.stringify(response)}`);
        return response;
    }
}
exports.PayApi = PayApi;
