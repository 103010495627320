import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { HomeUsePluginUtil } from "@uikit/util/HomeUsePluginUtil";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import store from "@store/index";
import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { Signal, signal } from "@preact/signals-react";
import { findIndex, isNil } from "lodash";
import { UseModalProps } from "@uiview/views/AModal/types";
import { logEvent } from "@uikit/service/amplitude";
import { RelationSetDest } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";

export class MobileProjectBloc {
  dropDownItem: Signal<RelationSetDest | undefined> = signal(undefined);
  dropDownTarget?: EventTarget;

  gql = getIt(ObjectRelationGqlService);
  projectFunctionStore = getIt(ProjectFunctionStore);

  selectedExistingProject = {};

  showAutoImagicAlert = signal(false);

  formatTemplates = signal<any>([]);

  async getTemplateByName(param: any, relationId: string): Promise<void | never> {
    try {
      const res = await this.gql.objectSet({ ...param });
      if (func.isEmpty(res?.[0]?.attributes)) {
        // eslint-disable-next-line no-throw-literal
        throw "Get template failed";
      }

      // 点击已有project时转换v2数据到v3
      let attributesV2 = res[0].attributesV2;
      if (func.isEmpty(attributesV2.v3)) {
        attributesV2 = HomeUsePluginUtil.handleV2TransformV3Data({
          attributesV2,
          nodeIndexRef: creatorRefStore.nodeIndexRef,
          edgeIndexRef: creatorRefStore.edgeIndexRef,
          newEdgeDataRef: getIt(CanvasDataRef).newEdgeDataRef,
          newLineDataRef: getIt(CanvasDataRef).newLineDataRef,
        });
      }

      this.selectedExistingProject = {
        ...attributesV2,
        id: res[0].id,
        name: res[0].attributesV2.template_name,
        uuid: res[0].uuid,
        updatedAt: res[0].updatedAt,
        relationId,
      };
    } catch (error: any) {
      func.customMsg({
        type: "error",
        content: error?.message || "Get template failed",
      });
      this.selectedExistingProject = {};
    }
  }

  getAppropriateParam(value: any): any {
    const paramType: Record<string, any> = {
      attrIndexer: {
        key: "attrIndexer",
        value: value.attrIndexer,
      },
      uuid: {
        key: "uuid",
        value: value.uuid,
      },
      id: {
        key: "id",
        value: value.id,
      },
    };
    const param: Record<string, any> = {};
    Object.keys(paramType).some((x: any) => {
      if (!func.isEmpty(value[x])) {
        param[paramType[x].key] = paramType[x].value;
        return true;
      }
      return false;
    });
    return param;
  }

  async handleClickDuplicate(type: "project" | "function" = "project", object: any): Promise<any> {
    const isLoading = this.formatTemplates.value.some((x: any) => x.loading);
    if (isLoading) return;
    try {
      this.setCardLoading(object.id, true);
      const functionObj = {
        project: "shareStudioProject",
        function: "shareStudioFunction",
      };
      let method;
      if (functionObj[type] === "shareStudioProject") {
        method = this.gql.shareStudioProject;
      } else if (functionObj[type] === "shareStudioFunction") {
        method = this.gql.shareStudioFunction;
      } else {
        throw Error();
      }
      const userMe = store.getState().editor.me as any;
      const res = await method(object.id, userMe.id, "", true);
      const formatObject = {
        id: res.id,
        label: res.attributesV2.name,
        value: res.attributesV2.name,
        loading: false,
      };
      if (type === "project") {
        const index = findIndex(this.formatTemplates.value, (item: any) => item.id === object.id);
        this.formatTemplates.value.splice(index + 1, 0, { ...formatObject });
      } else {
        const newFunction = this.projectFunctionStore.copyFunction(res);
        creatorRefStore.singleFlowEdgeArrRef.current.push(newFunction);
      }
      return res;
    } catch (error: any) {
      console.error(error);
      throw Error();
    } finally {
      this.setCardLoading(object.id, false);
    }
  }

  setCardLoading(id: any, bool: any): void {
    const newList = this.formatTemplates.value.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          loading: bool,
        };
      }
      return x;
    });
    this.formatTemplates.value = newList;
  }

  removeCard(id: any): void {
    this.formatTemplates.value = this.formatTemplates.value.filter((x: any) => x.relationId !== id);
  }

  async handleClickDelete(
    type: any,
    id: any,
    name: any,
    object: any = {},
    showModal: (props: UseModalProps) => void
  ): Promise<any> {
    return new Promise<boolean>(resolve => {
      showModal({
        width: 520,
        title: "Confirm your action",
        subTitle: `Do you want to delete the ${type} named "${name}"? This action cannot be undone.`,
        buttons: ["Cancel", "Delete"],
        danger: true,
        shouldExecuteOkOnEnter: true,
        onOk: async ({ setLoading, close }) => {
          let relationId = id;
          // For the copied functions or objects, they don't have relation id currently.
          if (isNil(id)) {
            const relationResponse = await this.gql.relationSet(undefined, undefined, {
              destId: object.id,
            });
            relationId = relationResponse?.[0]?.relationId;
          }

          if (isNil(relationId)) {
            close();
            return func.customMsg({
              type: "error",
              content: "No relation id found, delete failed",
            });
          }

          if (type === "project") {
            this.setCardLoading(relationId, true);
          }
          setLoading(true);
          await this.gql.deleteRelations(relationId, "", true);
          if (type === "project") {
            this.removeCard(id);
            setLoading(false);
            close();
          }
          if (type === "function") {
            this.projectFunctionStore.deleteFunctionById(object.id);
            setLoading(false);
            close();
            logEvent("delete_function_confirmed", object);
          }
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  }
}
