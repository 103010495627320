import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import style from "./RejectTip.module.scss";
import classNames from "classnames";

export default function RejectTip({
  id,
  errorText,
  setShowRejectTip,
  retry,
}: {
  id: string;
  isOpenDeleteBox: boolean;
  errorText: string;
  setShowRejectTip: (param: boolean) => void;
  retry: () => void;
}): JSX.Element {
  const creatorNodesStore = getIt(CreatorNodesStore);

  const clickGotItBtn = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    setShowRejectTip(false);
    e.stopPropagation();
    retry();
    creatorNodesStore.setNodes((prevNodes: any) => {
      return prevNodes.map((l: any) => {
        if (l.id === id) {
          return {
            ...l,
            data: {
              ...l.data,
              errorText: "",
            },
          };
        }
        return l;
      });
    });
  };

  return (
    <div className={classNames(style["reject-content"], "nowheel")}>
      <div className={style["reject-box"]}>
        <div className={style.textContent}>
          <p>{errorText}</p>
        </div>

        <div className={style["btn-box"]} onClick={clickGotItBtn}>
          Retry
        </div>
      </div>
    </div>
  );
}

export function RejectTipText({ errorText }: { errorText: string }): JSX.Element {
  return (
    <div className={classNames(style["reject-text-content"], "nowheel")}>
      <div className={style["reject-box"]}>
        <div className={style.textContent}>
          <p>{errorText}</p>
        </div>
      </div>
    </div>
  );
}
