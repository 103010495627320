export class ProjectModifiedTransfromUtil {
  static stringToObject<T, R = any>(value: T): R | T {
    if (typeof value === "string") {
      try {
        return JSON.parse(value);
      } catch {}
    }
    return value;
  }

  static transformOutput<T extends AISaasOutput.ThoughtType>(value: T[]): T[] {
    return value.map(output => {
      return {
        ...output,
        // FIXME: 每当打开一个 preview 时，previewID 可能会发生变化，所以不进行比较
        // FIXME: errorText, 不确定什么样的情况下会自动添加一个该字段，暂时的做法是不比较该字段
        results: output.results.map(result => ({ ...result, previewId: "", errorText: "" })),
      };
    });
  }
}
