"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterFunction = void 0;
const FunctionRegistry_1 = require("../base/storyV2/function/FunctionRegistry");
const ImageGenFunction_1 = require("../base/storyV2/function/ImageGenFunction");
const MidjourneyFunction_1 = require("../base/storyV2/function/MidjourneyFunction");
const SplitOutputFunction_1 = require("../base/storyV2/function/SplitOutputFunction");
const StockInfoFunction_1 = require("../base/storyV2/function/StockInfoFunction");
const JDShoppingFunction_1 = require("../base/storyV2/function/JDShoppingFunction");
const ImageSearchFunction_1 = require("../base/storyV2/function/ImageSearchFunction");
const MapFunction_1 = require("../base/storyV2/function/MapFunction");
const FoodFunction_1 = require("../base/storyV2/function/FoodFunction");
const UnknownFunction_1 = require("../base/storyV2/function/UnknownFunction");
const CustomCodeFunction_1 = require("../base/storyV2/function/CustomCodeFunction");
const CustomApiFunction_1 = require("../base/storyV2/function/CustomApiFunction");
const AddDataSourceFunction_1 = require("../base/storyV2/function/AddDataSourceFunction");
const ProteinFunction_1 = require("../base/storyV2/function/ProteinFunction");
const AskBrainFunction_1 = require("../base/storyV2/function/AskBrainFunction");
const ChatInterfaceFunction_1 = require("../base/storyV2/function/ChatInterfaceFunction");
const BlueprintFunction_1 = require("../base/storyV2/function/BlueprintFunction");
const GlobalContextFunction_1 = require("../base/storyV2/function/GlobalContextFunction");
const WeeeShoppingFunction_1 = require("../base/storyV2/function/WeeeShoppingFunction");
const YoutubeVideosFunction_1 = require("../base/storyV2/function/YoutubeVideosFunction");
const AmazonShoppingFunction_1 = require("../base/storyV2/function/AmazonShoppingFunction");
const InterviewBotFunction_1 = require("../base/storyV2/function/InterviewBotFunction");
const TextGenFunction_1 = require("../base/storyV2/function/TextGenFunction");
const BrainClient_1 = require("../base/api/BrainClient");
const AuthStore_1 = require("../base/store/AuthStore");
const WeeeGroceriesFunction_1 = require("../base/storyV2/function/WeeeGroceriesFunction");
const LocationManager_1 = require("../base/port/LocationManager");
const MeStore_1 = require("../base/store/MeStore");
const MessageCenter_1 = require("../base/kernel/MessageCenter");
const FunctionParams_1 = require("../base/storyV2/function/FunctionParams");
const SocketRestClient_1 = require("../base/cutil/SocketRestClient");
class RegisterFunction {
    static configure(container) {
        const it = (serviceIdentifier) => {
            return container.get(serviceIdentifier);
        };
        container
            .bind(TextGenFunction_1.TextGenFunction)
            .toConstantValue(new TextGenFunction_1.TextGenFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(ImageGenFunction_1.ImageGenFunction)
            .toConstantValue(new ImageGenFunction_1.ImageGenFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(SplitOutputFunction_1.SplitOutputFunction)
            .toConstantValue(new SplitOutputFunction_1.SplitOutputFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(StockInfoFunction_1.StockInfoFunction)
            .toConstantValue(new StockInfoFunction_1.StockInfoFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(AmazonShoppingFunction_1.AmazonShoppingFunction)
            .toConstantValue(new AmazonShoppingFunction_1.AmazonShoppingFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(JDShoppingFunction_1.JDShoppingFunction)
            .toConstantValue(new JDShoppingFunction_1.JDShoppingFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(YoutubeVideosFunction_1.YoutubeVideosFunction)
            .toConstantValue(new YoutubeVideosFunction_1.YoutubeVideosFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(WeeeGroceriesFunction_1.WeeeGroceriesFunction)
            .toConstantValue(new WeeeGroceriesFunction_1.WeeeGroceriesFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(WeeeShoppingFunction_1.WeeeShoppingFunction)
            .toConstantValue(new WeeeShoppingFunction_1.WeeeShoppingFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(ImageSearchFunction_1.ImageSearchFunction)
            .toConstantValue(new ImageSearchFunction_1.ImageSearchFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(MapFunction_1.MapFunction)
            .toConstantValue(new MapFunction_1.MapFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient), it(LocationManager_1.LocationManager)));
        container
            .bind(FoodFunction_1.FoodFunction)
            .toConstantValue(new FoodFunction_1.FoodFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient), it(LocationManager_1.LocationManager)));
        container
            .bind(ChatInterfaceFunction_1.ChatInterfaceFunction)
            .toConstantValue(new ChatInterfaceFunction_1.ChatInterfaceFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(MeStore_1.MeStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(MidjourneyFunction_1.MidjourneyFunction)
            .toConstantValue(new MidjourneyFunction_1.MidjourneyFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(MeStore_1.MeStore), it(MessageCenter_1.MessageCenter), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(UnknownFunction_1.UnknownFunction)
            .toConstantValue(new UnknownFunction_1.UnknownFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(CustomCodeFunction_1.CustomCodeFunction)
            .toConstantValue(new CustomCodeFunction_1.CustomCodeFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(AddDataSourceFunction_1.AddDataSourceFunction)
            .toConstantValue(new AddDataSourceFunction_1.AddDataSourceFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(ProteinFunction_1.ProteinFunction)
            .toConstantValue(new ProteinFunction_1.ProteinFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(AskBrainFunction_1.AskBrainFunction)
            .toConstantValue(new AskBrainFunction_1.AskBrainFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(BlueprintFunction_1.BlueprintFunction)
            .toConstantValue(new BlueprintFunction_1.BlueprintFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(GlobalContextFunction_1.GlobalContextFunction)
            .toConstantValue(new GlobalContextFunction_1.GlobalContextFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient), it(MeStore_1.MeStore)));
        container
            .bind(InterviewBotFunction_1.InterviewBotFunction)
            .toConstantValue(new InterviewBotFunction_1.InterviewBotFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient)));
        const functionRegistry = it(FunctionRegistry_1.FunctionRegistry);
        functionRegistry.register(it(ImageGenFunction_1.ImageGenFunction), FunctionRegistry_1.FunctionName.dallE2);
        functionRegistry.register(it(MidjourneyFunction_1.MidjourneyFunction), FunctionRegistry_1.FunctionName.midjourney);
        functionRegistry.register(it(SplitOutputFunction_1.SplitOutputFunction), FunctionRegistry_1.FunctionName.splitOutput);
        functionRegistry.register(it(StockInfoFunction_1.StockInfoFunction), FunctionRegistry_1.FunctionName.stockPrices);
        functionRegistry.register(it(JDShoppingFunction_1.JDShoppingFunction), FunctionRegistry_1.FunctionName.jdShopping);
        functionRegistry.register(it(ImageSearchFunction_1.ImageSearchFunction), FunctionRegistry_1.FunctionName.imageSearch);
        functionRegistry.register(it(MapFunction_1.MapFunction), FunctionRegistry_1.FunctionName.maps);
        functionRegistry.register(it(FoodFunction_1.FoodFunction), FunctionRegistry_1.FunctionName.food);
        functionRegistry.register(it(UnknownFunction_1.UnknownFunction), FunctionRegistry_1.FunctionName.unknown);
        functionRegistry.register(it(CustomCodeFunction_1.CustomCodeFunction), FunctionRegistry_1.FunctionName.customCode);
        functionRegistry.register(it(AddDataSourceFunction_1.AddDataSourceFunction), FunctionRegistry_1.FunctionName.addDataSource);
        functionRegistry.register(it(ProteinFunction_1.ProteinFunction), FunctionRegistry_1.FunctionName.protein);
        functionRegistry.register(it(AskBrainFunction_1.AskBrainFunction), FunctionRegistry_1.FunctionName.askImagica);
        functionRegistry.register(it(ChatInterfaceFunction_1.ChatInterfaceFunction), FunctionRegistry_1.FunctionName.chatInterface);
        functionRegistry.register(it(GlobalContextFunction_1.GlobalContextFunction), FunctionRegistry_1.FunctionName.globalContext);
        functionRegistry.register(it(WeeeShoppingFunction_1.WeeeShoppingFunction), FunctionRegistry_1.FunctionName.weeeShopping);
        functionRegistry.register(it(YoutubeVideosFunction_1.YoutubeVideosFunction), FunctionRegistry_1.FunctionName.youtubeVideos);
        functionRegistry.register(it(AmazonShoppingFunction_1.AmazonShoppingFunction), FunctionRegistry_1.FunctionName.amazonShopping);
        functionRegistry.register(it(InterviewBotFunction_1.InterviewBotFunction), FunctionRegistry_1.FunctionName.interviewBot);
        // v2
        functionRegistry.register(it(StockInfoFunction_1.StockInfoFunction), FunctionRegistry_1.FunctionName.stockValue);
        functionRegistry.register(it(AddDataSourceFunction_1.AddDataSourceFunction), FunctionRegistry_1.FunctionName.addDataSourceValue);
        functionRegistry.register(it(ImageGenFunction_1.ImageGenFunction), FunctionRegistry_1.FunctionName.dallE2Value);
        functionRegistry.register(it(ImageSearchFunction_1.ImageSearchFunction), FunctionRegistry_1.FunctionName.imageSearchValue);
        functionRegistry.register(it(ProteinFunction_1.ProteinFunction), FunctionRegistry_1.FunctionName.proteinValue);
        functionRegistry.register(it(YoutubeVideosFunction_1.YoutubeVideosFunction), FunctionRegistry_1.FunctionName.videoValue);
        functionRegistry.register(it(MapFunction_1.MapFunction), FunctionRegistry_1.FunctionName.mapValue);
        functionRegistry.register(it(JDShoppingFunction_1.JDShoppingFunction), FunctionRegistry_1.FunctionName.jdShoppingValue);
        functionRegistry.register(it(AmazonShoppingFunction_1.AmazonShoppingFunction), FunctionRegistry_1.FunctionName.amazonShoppingOldValue);
        functionRegistry.register(it(WeeeShoppingFunction_1.WeeeShoppingFunction), FunctionRegistry_1.FunctionName.weeeShoppingOldValue);
        functionRegistry.register(it(SplitOutputFunction_1.SplitOutputFunction), FunctionRegistry_1.FunctionName.splitOutputValue);
        functionRegistry.register(it(AskBrainFunction_1.AskBrainFunction), FunctionRegistry_1.FunctionName.askImagicaValue);
        functionRegistry.register(it(BlueprintFunction_1.BlueprintFunction), FunctionRegistry_1.FunctionName.blueprint);
        functionRegistry.register(it(FoodFunction_1.FoodFunction), FunctionRegistry_1.FunctionName.showFoodInterfaceValue);
        functionRegistry.register(it(MidjourneyFunction_1.MidjourneyFunction), FunctionRegistry_1.FunctionName.midjourneyValue);
        functionRegistry.register(it(ChatInterfaceFunction_1.ChatInterfaceFunction), FunctionRegistry_1.FunctionName.chatInterfaceValue);
        functionRegistry.register(it(InterviewBotFunction_1.InterviewBotFunction), FunctionRegistry_1.FunctionName.interviewBotValue);
        functionRegistry.register(it(TextGenFunction_1.TextGenFunction), FunctionRegistry_1.FunctionName.textGeneration);
        functionRegistry.registConstructor(() => {
            return new CustomApiFunction_1.CustomApiFunction(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient));
        }, FunctionRegistry_1.FunctionName.customApi);
        functionRegistry.registConstructor(() => {
            return new FunctionParams_1.FunctionParamsManager(it(BrainClient_1.BrainClient), it(AuthStore_1.AuthStore), it(SocketRestClient_1.SocketRestClient));
        }, FunctionRegistry_1.FunctionName.param);
    }
}
exports.RegisterFunction = RegisterFunction;
