import { useEffect, useRef } from "react";
export function useThrottle(fn, delay) {
  var dep = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var _useRef = useRef({
      fn: fn,
      timer: null
    }),
    current = _useRef.current;
  useEffect(function () {
    current.fn = fn;
  }, [fn]);
  useEffect(function () {
    if (!current.timer) {
      current.timer = setTimeout(function () {
        delete current.timer;
        fn();
      }, delay);
    }
  }, [dep]);
}