import { find, isEmpty } from "lodash";
import func from "@uikit/func";
import { StudioProjectAttributesV3Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Node";
import { ProjectUtil } from "imagica-corekit/dist/cases/util/ProjectUtil";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { distance } from "@uiview/views/ProjectCanvas/homeConst";
import { NodeConfigUtil } from "@uiview/views/Nodes/NodeConfigUtil";
import { DisplayType } from "imagica-uikit/dist/nodesV2/typing";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { ProjectPageStore } from "@uikit/store/ProjectPageStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { EdgeRunCore } from "./EdgeRunCore";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";

/**
 * 原来 useHomeUsePluginUtil hook 中的 handleCustomToGroup,handleGroupToCustom group 相关方法
 */
export class EdgeRunGroupHandler {
  constructor(
    public core: EdgeRunCore,
    public projectNodeStore: ProjectNodeStore,
    public projectPageStore: ProjectPageStore,
    public canvasDataRef: CanvasDataRef
  ) {}

  handleCustomToGroup = (preNodeId: string, newNodeArr: Array<any>): void => {
    const nodes = this.core.creatorNodesStore.getNodes();
    const currentEdge: any = nodes.find(x => x.data.targetNodeId === preNodeId);
    const currentLine: any = this.core.creatorEdgesStore.getEdges().find(line => line.target === preNodeId);
    const preTargetNode = nodes.find(x => x.id === preNodeId);
    const childNodes = newNodeArr.filter(x => x.type !== "customGroup");
    const groupNode = newNodeArr.find(x => x.type === "customGroup");
    if (
      func.isEmpty(currentEdge) ||
      func.isEmpty(currentLine) ||
      func.isEmpty(preTargetNode) ||
      func.isEmpty(childNodes) ||
      func.isEmpty(groupNode)
    )
      return;

    let newArr: Array<any> = [];
    //子node计算position
    let bottom = 0;
    childNodes.forEach(node => {
      const oldChildNode = find(nodes, item => item.id === node.id);
      const tempNode = ReactFlowNodeUtil.getNodeById(node.id, nodes);
      const oldChildProjectNode = this.projectNodeStore.getNode(node.id);

      const childNode = {
        ...node,
        data: {
          ...this.canvasDataRef.nodeDataRef.current,
          // stud-2493 set generated label(nodes)
          // stud-2493 get generated label(nodes)
          // ...(oldChildNode ? { generatedLabel: oldChildNode.data?.generatedLabel } : {}),
          ...(oldChildNode ? { generatedLabel: oldChildProjectNode?.generatedLabel } : {}),
          ...node.data,
        },
        width: tempNode?.width,
        height: tempNode?.height,
        position: {
          x: distance,
          y: distance + bottom + CreatorNodesConsts.titleDistance,
        },
      };
      let tempHeight = tempNode?.height;
      if (tempHeight === undefined || tempHeight === null) {
        const nodeLayout = NodeConfigUtil.getNodeLayoutByType(node.data.displayType as DisplayType);
        tempHeight = nodeLayout.height;
        if (node.data.displayType === "text") {
          tempHeight = nodeLayout.minHeight ?? nodeLayout.height;
        }
      }
      bottom += tempHeight + distance / 2;
      newArr.push(childNode);
    });
    //group计算高宽
    const groupHeight =
      newArr
        .map(x => {
          let tempHeight = x?.height;
          if (tempHeight === undefined) {
            const nodeLayout = NodeConfigUtil.getNodeLayoutByType(x.data.displayType as DisplayType);
            tempHeight = nodeLayout.height;
            if (x.data.displayType === "text") {
              return nodeLayout.minHeight ?? tempHeight;
            }
          }
          return tempHeight;
        })
        .reduce((total, x) => total + x + distance / 2, distance) + this.projectPageStore.state.previewHeight;
    const maxX = Math.max(
      ...childNodes.map(x => {
        const displayType = x.data?.displayType;
        if (func.isEmpty(displayType)) {
          console.error("DisplayType is empty:", displayType);
          return 0;
        }
        return this.core.dependencies.initialReportSizeMapping[displayType as DisplayType]?.width || 0;
      })
    );
    const groupWidth = maxX + 2 * distance;
    const preTargetProjectNode = this.projectNodeStore.getNode(preNodeId);

    newArr.unshift({
      ...groupNode,
      data: {
        ...this.canvasDataRef.groupDataRef.current,
        ...groupNode.data,
        // stud-2493 set generated label(nodes)
        // stud-2493 get generated label(nodes)
        ...(preTargetNode?.id === groupNode.id ? { generatedLabel: preTargetProjectNode?.generatedLabel } : {}),
      },
      style: {
        width: groupWidth,
        height: groupHeight + CreatorNodesConsts.collapseDistance,
      },
      position: {
        x: preTargetNode?.position?.x,
        y: preTargetNode?.position?.y,
      },
    });
    //替换node、边上的targetNodeId
    this.core.creatorNodesStore.setNodes(prevList => {
      const filterList = prevList.filter(x => x.id !== preNodeId && x.parentNode !== preNodeId);
      const updateList = filterList.map(x => {
        if (x.id === currentEdge.id) {
          return {
            ...x,
            data: {
              ...x.data,
              targetNodeId: groupNode.id,
            },
          };
        }
        // 判断new array 在updateList已经存在就不重复添加
        newArr = newArr.filter((y: any) => y.id !== x.id);
        return x;
      });
      return [...updateList, ...newArr];
    });
    //替换线的target
    this.core.creatorEdgesStore.setEdges(prevList => {
      return prevList.map(line => {
        if (line.id === currentLine.id) {
          return {
            ...line,
            target: groupNode.id,
          };
        }
        return line;
      });
    });
  };

  handleGroupToCustom = (preNodeId: string, newNode: any): void => {
    const currentEdge = this.core.creatorNodesStore.getNodes().find(x => x.data.targetNodeId === preNodeId);
    const preTargetNode = this.core.creatorNodesStore.getNodes().find(x => x.id === preNodeId);
    // 由于preNodeId一直是group的id，所以这里判定只会通过一次，后续不存在group了，currentEdge/preTargetNode都是undefined
    if (isEmpty(currentEdge) || func.isEmpty(preTargetNode) || func.isEmpty(newNode)) return;

    const node = {
      id: newNode.id,
      type: "customNode",
      data: Object.assign({}, { ...this.canvasDataRef.nodeDataRef.current }, newNode.data),
      position: {
        x: preTargetNode?.position?.x,
        y: preTargetNode?.position?.y,
      },
    };

    /// 替换node、边上的targetNodeId
    this.core.creatorNodesStore.setNodes((prevList: any) => {
      const filterList = prevList.filter((x: any) => x.id !== preNodeId && x.parentNode !== preNodeId);
      const updateList = filterList.map((x: any) => {
        if (x.id === currentEdge.id) {
          return {
            ...x,
            data: {
              ...x.data,
              targetNodeId: newNode.id,
            },
          };
        }
        return x;
      });
      return [...updateList, node];
    });

    /// 替换线的target
    this.core.creatorEdgesStore.setEdges((prevList: any) => {
      return prevList.map((line: any) => {
        if (line.target === preNodeId) {
          return {
            ...line,
            target: node.id,
          };
        }
        return line;
      });
    });

    /// 删除node后面的线和边
    const allNodes = this.core.creatorNodesStore.getNodes() as StudioProjectAttributesV3Node[];
    const allEdges = this.core.creatorEdgesStore.getEdges() as any as any[];
    const deleteElements = ProjectUtil.nodeRightRelations(preNodeId, allNodes, allEdges);
    if (deleteElements.length !== 0) {
      this.core.creatorEdgesStore.setEdges((prevList: any) => {
        const newList = prevList.filter((line: any) => {
          return !deleteElements.includes(line.id);
        });
        return newList;
      });
      this.core.creatorNodesStore.setNodes((prevList: any) => {
        const newList = prevList.filter((edge: any) => {
          return !deleteElements.includes(edge.id);
        });
        return newList;
      });
    }
  };
}
