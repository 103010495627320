export function DownloadIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0013 13.3334C18.4287 13.3334 18.7809 13.6551 18.829 14.0696L18.8346 14.1667V15.8334C18.8346 17.1648 17.7939 18.2531 16.4815 18.3292L16.3346 18.3334H4.66797C3.33657 18.3334 2.24825 17.2926 2.17221 15.9803L2.16797 15.8334V14.1667C2.16797 13.7065 2.54106 13.3334 3.0013 13.3334C3.42867 13.3334 3.78089 13.6551 3.82903 14.0696L3.83464 14.1667V15.8334C3.83464 16.2608 4.15634 16.613 4.57078 16.6611L4.66797 16.6667H16.3346C16.762 16.6667 17.1142 16.345 17.1624 15.9306L17.168 15.8334V14.1667C17.168 13.7065 17.5411 13.3334 18.0013 13.3334ZM10.5013 1.66675C10.9615 1.66675 11.3346 2.03984 11.3346 2.50008V10.4876L12.412 9.41083C12.7125 9.11042 13.1851 9.08731 13.5121 9.3415L13.5906 9.41083C13.891 9.71123 13.9141 10.1839 13.6599 10.5108L13.5906 10.5893L11.0906 13.0893L11.0539 13.1238L10.9974 13.1698L10.9047 13.2295L10.8107 13.2741L10.723 13.3035L10.5992 13.3277L10.5013 13.3334L10.4386 13.3311L10.334 13.3165L10.2412 13.292L10.1486 13.2554L10.0674 13.2118L9.98734 13.1561C9.96091 13.1354 9.93577 13.1131 9.91205 13.0893L7.41205 10.5893C7.08661 10.2639 7.08661 9.73626 7.41205 9.41083C7.71245 9.11042 8.18514 9.08731 8.51205 9.3415L8.59056 9.41083L9.66797 10.4876V2.50008C9.66797 2.03984 10.0411 1.66675 10.5013 1.66675Z"
        fill="#676E7F"
      />
    </svg>
  );
}
