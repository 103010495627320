"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Blueprint = exports.Blueprints = void 0;
const Blueprint_1 = require("../blueprintTyped/Blueprint");
class Blueprints {
}
exports.Blueprints = Blueprints;
class Blueprint extends Blueprint_1.ListItem {
}
exports.Blueprint = Blueprint;
