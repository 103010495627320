"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestTimeoutValidUtil = void 0;
const LangUtil_1 = require("../cutil/LangUtil");
/**
 * if fail to fetch status code error happens for https://api-dev.braininc.net/be/vectordb/indexers/ or https://api-dev.braininc.net/be/gpt-research/report/research_report, set timer for 30s, use web socket response
   BE will increase the time threshold for these two endpoints
 */
class RequestTimeoutValidUtil {
    constructor(url) {
        this.TIMEOUT = 30 * 1000;
        this.isValid = false;
        this.timer = undefined;
        this.urls = ["/be/vectordb/indexers/", "/be/gpt-research/report/research_report", "/be/vectordb/model/music/gen"];
        this.setup(url);
    }
    setup(requestUrl) {
        if (this.urls.some(url => requestUrl.includes(url))) {
            this.timer = setTimeout(() => {
                console.log("Pass request timeout valid");
                this.isValid = true;
            }, this.TIMEOUT);
        }
    }
    get valid() {
        return this.isValid;
    }
    clear() {
        clearTimeout(this.timer);
    }
    response() {
        return new LangUtil_1.TryResult(new Response(undefined, {
            status: 502,
        }));
    }
}
exports.RequestTimeoutValidUtil = RequestTimeoutValidUtil;
