import { editorActions } from "@store/editor";
import { fotActions } from "@store/fot";
import store from "@store/index";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { NavBarStore } from "../navBar/NavBarStore";

/**
 * reactflow 组件公用方法
 */
export function useReactFlowMethods() {
  // redux
  const dispatch = useDispatch();
  const setCheckEdgeArr = useCallback(
    (val: string[]) => {
      dispatch(fotActions.setCheckEdgeArr(val));
    },
    [dispatch]
  );
  const setHoverEdgeData = useCallback(
    (val: any) => {
      dispatch(editorActions.setHoverEdgeData(val));
    },
    [dispatch]
  );
  const setPreSelectNodeId = useCallback(
    (val: string) => {
      dispatch(fotActions.setPreSelectNodeId(val));
    },
    [dispatch]
  );
  const setHasSelectedArea = useCallback(
    (val: boolean) => {
      dispatch(fotActions.setHasSelectedArea(val));
    },
    [dispatch]
  );
  const setCheckNodeArr = useCallback(
    (val: any[]) => {
      dispatch(fotActions.setCheckNodeArr(val));
    },
    [dispatch]
  );

  // 点击空白面白清空选中nodes及edges
  const onPaneClick = useCallback(() => {
    setPreSelectNodeId("");
    setCheckNodeArr([]);
    setCheckEdgeArr([]);
    setHasSelectedArea(false);
    const hoverEdgeData = store.getState().editor.hoverEdgeData as any;
    setHoverEdgeData({
      state: false,
      lineIds: hoverEdgeData.lineIds,
      edgeId: hoverEdgeData.id,
    });
  }, [setCheckEdgeArr, setCheckNodeArr, setHasSelectedArea, setHoverEdgeData, setPreSelectNodeId]);

  return useMemo(() => ({ onPaneClick }), [onPaneClick]);
}
