import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

type Param = Partial<Omit<PreviewPaneStoreState, "copyWith">>;
export enum SettingPageContentType {
  none, // 关闭中
  design,
  features,
}

export class PreviewPaneStoreState {
  settingPageStatus = false;
  openTitleIconTooltip = false;
  openAppDetails = false;
  settingPageContentType = SettingPageContentType.none;
  upgradeZindex = false;
  mobileChatStatus = false;

  copyWith(params: Param): PreviewPaneStoreState {
    const state = new PreviewPaneStoreState();
    state.settingPageStatus = params.settingPageStatus ?? this.settingPageStatus;
    state.openTitleIconTooltip = params.openTitleIconTooltip ?? this.openTitleIconTooltip;
    state.openAppDetails = params.openAppDetails ?? this.openAppDetails;
    state.settingPageContentType = params.settingPageContentType ?? this.settingPageContentType;
    state.upgradeZindex = params.upgradeZindex ?? this.upgradeZindex;
    state.mobileChatStatus = params.mobileChatStatus ?? this.mobileChatStatus;
    return state;
  }
}

export class PreviewPaneStore extends StoreBase<PreviewPaneStoreState> {
  constructor() {
    super(() => {
      return new PreviewPaneStoreState();
    });
  }

  settingPageStatus(settingPageStatus: boolean): void {
    this.emit(this.state.copyWith({ settingPageStatus }));
  }

  setSettingPageContentType(settingPageContentType: SettingPageContentType): void {
    this.emit(this.state.copyWith({ settingPageContentType }));
  }

  openTitleIconTooltip(openTitleIconTooltip: boolean): void {
    this.emit(this.state.copyWith({ openTitleIconTooltip }));
  }

  openAppDetails(openAppDetails: boolean): void {
    this.emit(this.state.copyWith({ openAppDetails }));
  }

  setUpgradeZindex(upgradeZindex: boolean): void {
    this.emit(this.state.copyWith({ upgradeZindex }));
  }

  setMobileChatStatus(mobileChatStatus: boolean): void {
    this.emit(this.state.copyWith({ mobileChatStatus }));
  }
}
