"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewBuilderImgService = exports.ComponentTypeImg = void 0;
const ImgUtil_1 = require("../util/ImgUtil");
const Extensions_1 = require("../../base/cutil/Extensions");
const ComponentId_1 = require("../../base/api/viewBuilderTyped/VBGraphData/ComponentId");
const ViewBuilderImgUtil_1 = require("../util/ViewBuilderImgUtil");
class ImgParams {
    constructor() {
        this.imgUrl = "";
    }
}
class ComponentTypeImg {
    constructor(imgKey, autoFillLen) {
        this.imgKey = imgKey;
        this.autoFillLen = autoFillLen;
    }
}
exports.ComponentTypeImg = ComponentTypeImg;
class ViewBuilderImgService {
    constructor(viewBuilderService, viewbuilderImgStore) {
        this.viewBuilderService = viewBuilderService;
        this.viewbuilderImgStore = viewbuilderImgStore;
    }
    downloadImage(url) {
        let img = new Image();
        // 跨域图片需要添加这个属性，否则画布被污染了无法导出图片
        img.setAttribute("crossOrigin", "anonymous");
        const promise = new Promise((resolve, reject) => {
            let blobData = "";
            img.onload = () => {
                let canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                // 图片绘制到canvas里
                ctx?.drawImage(img, 0, 0, img.width, img.height);
                // 2.blob:协议
                canvas.toBlob((blob) => {
                    blobData = URL.createObjectURL(blob);
                    resolve(blobData);
                });
                img = null;
                canvas = null;
            };
            img.onerror = () => {
                reject(new Error("Image download failed"));
            };
        });
        img.src = url;
        return promise;
    }
    async handleImgSearch(params) {
        const searchKey = params.imgUrl.replace(/\.(jpg|png|jpeg)/, "");
        const res = await this.viewBuilderService.imgSearch(searchKey);
        if (!res.data)
            return [];
        const result = res.data;
        // 如果autoFillImgLen存在，则获取搜索结果中autoFillImgLen长度的可用图片，否则获取第一张可用图片
        const imgUrls = (result.data.images_results || []).map((x) => x.original);
        const finalUrls = await ImgUtil_1.ImgUtil.getAvailableNumberOfImgs(imgUrls, params?.autoFillLen);
        return finalUrls;
    }
    async getCorrespondingImage(params) {
        if ((0, Extensions_1.isBlank)(params.imgUrl))
            return;
        // 先从缓存中查找
        const imgCacheValue = this.viewbuilderImgStore.state.viewBuilderImgCache[params.imgUrl];
        if (imgCacheValue?.searchImgPromise) {
            await imgCacheValue.searchImgPromise;
            return;
        }
        if (!(0, Extensions_1.isBlank)(imgCacheValue?.urls))
            return;
        // 判断是否为一个有效的图片路径，是则返回
        if (ImgUtil_1.ImgUtil.isValidUrl(params.imgUrl)) {
            this.viewbuilderImgStore.setViewBuilderImgCache({
                [params.imgUrl]: {
                    urls: [params.imgUrl],
                },
            });
            return;
        }
        // 搜索图片
        const promise = this.handleImgSearch(params);
        this.viewbuilderImgStore.setViewBuilderImgCache({
            [params.imgUrl]: {
                searchImgPromise: promise,
            },
        });
        const urls = await promise;
        this.viewbuilderImgStore.setViewBuilderImgCache({
            [params.imgUrl]: {
                urls,
                searchImgPromise: undefined,
            },
        });
        return;
    }
    // todo: 每添加一种带图片的组件都需要在这里加上提取逻辑
    getAllComponentTypeImgs(componentId, componentData) {
        // 这里提取各种组件类型数据结构中的img url/keyword
        const imgFuncConfig = {
            [ComponentId_1.ComponentId.C1]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getComponentCImgs(componentData),
            [ComponentId_1.ComponentId.C2]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getC2Imgs(componentData),
            [ComponentId_1.ComponentId.C3]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getComponentCImgs(componentData),
            [ComponentId_1.ComponentId.C4]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getComponentCImgs(componentData),
            [ComponentId_1.ComponentId.ItineraryPlanning]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getItineraryPlanningImgs(componentData),
            [ComponentId_1.ComponentId.TopicWithRelatedPeople]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getTopicWithRelatedPeopleImgs(componentData),
            [ComponentId_1.ComponentId.TopicWithRelatedDetail]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getTopicWithRelatedDetailImgs(componentData),
            [ComponentId_1.ComponentId.DishRecommendation]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getDishRecommendationImgs(componentData),
            [ComponentId_1.ComponentId.RestaurantRecommendation]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getRestaurantRecommendationImgs(componentData),
            [ComponentId_1.ComponentId.ImageNewsGrid]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getImageNewsGridImgs(componentData),
            [ComponentId_1.ComponentId.NewsDigest]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getNewsDigestImgs(componentData),
            [ComponentId_1.ComponentId.NewsDigestDetails]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getNewsDigestDetailsImgs(componentData),
            [ComponentId_1.ComponentId.NewsHeadline]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getNewsHeadlineImgs(componentData),
            [ComponentId_1.ComponentId.NewsVideoList]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getNewsVideoListImgs(componentData),
            [ComponentId_1.ComponentId.SeasonalTravelDestinationPlan]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getSeasonalTravelDestinationPlanImgs(componentData),
            [ComponentId_1.ComponentId.TouristAttraction]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getTouristAttractionImgs(componentData),
            [ComponentId_1.ComponentId.ShoppingProduct]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getShoppingImgs(componentData),
            [ComponentId_1.ComponentId.ShoppingSearchResults]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getShoppingImgs(componentData),
            [ComponentId_1.ComponentId.YoutubeSearchResults]: () => ViewBuilderImgUtil_1.ViewBuilderImgUtil.getYoutubeSearchResultsImgs(componentData),
        };
        const finalImgs = imgFuncConfig[componentId]?.() || [];
        return finalImgs;
    }
}
exports.ViewBuilderImgService = ViewBuilderImgService;
