"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageSearchFunction = void 0;
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
const ErrorUtil_1 = require("../../api/ErrorUtil");
const Meta_1 = require("../../cutil/Meta");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const ImageModel_1 = require("../../storyV2/model/ImageModel");
class ImageSearchFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo) {
        // const res = await this.imageSearch(input, identityInfo);
        const stremResponse = await this.socketRestClient.requestRest(topic => {
            return this.brainClient.openAi.imageSearch(input, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo), topic);
        }, result => {
            const images_results = result.data.images_results;
            if (images_results === undefined) {
                throw new Meta_1.Meta("ImageSearchFunction", ErrorUtil_1.ErrorUtil.defaultErrorMsg, "");
            }
            const imagesUrl = images_results.first().original || "";
            return new FunctionBase_1.FuncDataSource(FunctionBase_1.FuncDataSourceType.IMAGE_TEXT, JsonUtil_1.JsonUtil.stringify(new ImageModel_1.ImageModel(input, imagesUrl, imagesUrl)));
        }, "imageSearch", identityInfo);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.IMAGE_TEXT, stremResponse);
    }
    async imageSearch(query, identityInfo) {
        const result = await this.brainClient.openAi.imageSearch(query, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo));
        const images_results = result.data.images_results;
        if (images_results === undefined) {
            throw new Meta_1.Meta("ImageSearchFunction", ErrorUtil_1.ErrorUtil.defaultErrorMsg, "");
        }
        const imagesUrl = images_results.first().original || "";
        return imagesUrl;
    }
}
exports.ImageSearchFunction = ImageSearchFunction;
