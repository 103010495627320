export function UpgradeIcon() {
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4183_51808)">
        <path
          d="M12.8994 4L4.8835 13.6191C4.56957 13.9958 4.41261 14.1841 4.41021 14.3432C4.40812 14.4815 4.46975 14.6131 4.57732 14.7C4.70106 14.8 4.94625 14.8 5.43662 14.8H11.9994L11.0994 22L19.1153 12.3809C19.4292 12.0042 19.5862 11.8159 19.5886 11.6568C19.5907 11.5185 19.529 11.3869 19.4215 11.3C19.2977 11.2 19.0525 11.2 18.5622 11.2H11.9994L12.8994 4Z"
          fill="#8E79FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4183_51808"
          x="0.910156"
          y="0.5"
          width="22.1797"
          height="25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.75" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.65 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4183_51808" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4183_51808" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
