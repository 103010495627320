import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

interface toolTipValue {
  title?: string;
  content: string[];
}

interface TemplateItem {
  typeTitle: string;
  type: string;
  list: ListItem[];
}
interface ListItem {
  title: string;
  value: string;
  eventName: string;
  description: string[];
  image: string;
  toolTips?: Record<string, toolTipValue>;
  selected: boolean;
  disabled: boolean;
}
interface OtherItemParam {
  showTooltip?: boolean;
}

export class NewOnboardingFlowStoreState {
  openOptTempLateModal = false;
  selectedFlowItem: ListItem & OtherItemParam = {
    title: "",
    value: "",
    eventName: "",
    description: [],
    image: "",
    selected: false,
    disabled: false,
  };

  templateList: TemplateItem[] = [
    {
      typeTitle: "Start by choosing a publishing format",
      type: "publishingFormat",
      list: [
        {
          title: "AI App",
          value: "aiApp",
          eventName: "ai_app",
          description: ["Generate content based on user input"],
          image: "template-ai.png",
          toolTips: {
            "editor-1": {
              title: "",
              content: [
                "This ",
                "Node ",
                "becomes the ",
                "Input Field\n",
                "for your AI app. Type something then\n",
                "click ",
                "+",
                " to continue",
              ],
            },
            "edge-0": {
              content: [
                "Describe what the AI should do,\n",
                "or ",
                "type ",
                "/",
                " to launch the ",
                "command bar\n",
                "for a list of functions",
              ],
            },
            "editor-2": {
              title: "",
              content: [
                "This is your output node.\n",
                "You can click ",
                "+",
                " to add more edges and\n",
                "activate more AI functions",
              ],
            },
            "group-2": {
              title: "",
              content: [
                "This is your ",
                "group node",
                ".\n",
                "You can ",
                "click ",
                "+",
                "and ",
                "/",
                " to split your\n",
                "content and apply more AI functions",
              ],
            },
          },
          selected: false,
          disabled: false,
        },
        {
          title: "Static WebSite",
          value: "staticWeb",
          eventName: "static_Web",
          description: ["Share fixed content generated from your project"],
          image: "template-static.png",
          toolTips: {
            "editor-1": {
              title: "",
              content: ["This is the Input Node.\n", "Type a sentence or word then\n", "click ", "+", " to continue"],
            },
            "edge-0": {
              content: [
                "Describe what the AI should do,\n",
                "or type ",
                "/",
                " to launch the ",
                "command bar\n",
                "for a list of functions",
              ],
            },
            "editor-2": {
              title: "",
              content: [
                "This is your output node.\n",
                "We will add it to your website for you\n",
                "since it is the first output node.",
              ],
            },
            "group-2": {
              title: "",
              content: [
                "This is your ",
                "group node",
                ".\n",
                "You can ",
                "click ",
                "+",
                "and ",
                "/",
                " to split your\n",
                "content and apply more AI functions",
              ],
            },
          },
          selected: false,
          disabled: false,
        },
        {
          title: "AI Chat Agent",
          value: "aiChat",
          eventName: "ai_chat",
          description: ["Create an AI chat agent using your source of knowledge"],
          image: "template-chat.png",
          toolTips: {
            "editor-1": {
              title: "",
              content: ["Upload your\n", "Source Knowledge", " to train\n", "your AI chat agent."],
            },
            "edge-upload": {
              content: ["Click the Run button to generate\n", "an AI Chat Agent trained on the\n", "input document"],
            },
            "editor-upload": {
              title: "",
              content: [
                "Here is a Chat Interface to try out your\n",
                "new Chat Agent. We've also added it to\n",
                "your new Chat Agent App",
              ],
            },
            "group-2": {
              title: "",
              content: [],
            },
          },
          selected: false,
          disabled: false,
        },
        {
          title: "Mobile App",
          value: "mobileApp",
          eventName: "mobile_app",
          description: ["Publish a mobile app", "Coming soon..."],
          image: "template-mobile.png",
          selected: false,
          disabled: true,
        },
      ],
    },
    {
      typeTitle: "Make your own flow",
      type: "ownFlow",
      list: [
        {
          title: "Thinking Canvas",
          value: "thinkingCanvas",
          eventName: "thinking_canvas",
          description: ["Connect any idea to an AI function to build a project"],
          image: "template-think.png",
          toolTips: {
            "editor-1": {
              title: "",
              content: [
                "This ",
                "Input Node",
                " is your starting\n",
                "thought. Add a word or a sentence\n",
                "then click ",
                "+",
                " to continue",
              ],
            },
            "edge-0": {
              content: [
                "Describe what the AI should do,\n",
                "or type ",
                "/",
                " to launch the ",
                "command bar\n",
                "for a list of functions",
              ],
            },
            "editor-2": {
              title: "",
              content: [
                "This is your output node.\n",
                "You can click ",
                "+",
                " to add more edges and\n",
                "activate more AI functions",
              ],
            },
            "group-2": {
              title: "",
              content: [
                "This is your ",
                "group node",
                ".\n",
                "You can ",
                "click ",
                "+",
                "and ",
                "/",
                " to split your\n",
                "content and apply more AI functions",
              ],
            },
          },
          selected: false,
          disabled: false,
        },
      ],
    },
    {
      typeTitle: "Or start with a prompt",
      type: "startWithPrompt",
      list: [
        {
          title: "Auto Imagica",
          value: "autoImagica",
          eventName: "auto_imagica",
          description: ["Enter a single prompt to generate an app template"],
          image: "template-imagica.png",
          selected: false,
          disabled: false,
        },
      ],
    },
  ];

  templateListV2: ListItem[] = [
    {
      title: "Thinking Canvas",
      value: "thinkingCanvas",
      eventName: "thinking_canvas",
      description: ["Build a project with our powerful nodes-based Thinking canvas"],
      image: "template-think.png",
      toolTips: {
        "editor-1": {
          title: "",
          content: [
            "This ",
            "Input Node",
            " is your starting\n",
            "thought. Add a word or a sentence\n",
            "then click ",
            "+",
            " to continue",
          ],
        },
        "edge-0": {
          content: [
            "Describe what the AI should do,\n",
            "or type ",
            "/",
            " to launch the ",
            "command bar\n",
            "for a list of functions",
          ],
        },
        "editor-2": {
          title: "",
          content: [
            "This is your output node.\n",
            "You can click ",
            "+",
            " to add more edges and\n",
            "activate more AI functions",
          ],
        },
        "group-2": {
          title: "",
          content: [
            "This is your ",
            "group node",
            ".\n",
            "You can ",
            "click ",
            "+",
            "and ",
            "/",
            " to split your\n",
            "content and apply more AI functions",
          ],
        },
      },
      selected: false,
      disabled: false,
    },
    {
      title: "Auto Imagica",
      value: "autoImagica",
      eventName: "auto_imagica",
      description: ["Chat with AI builder to turn your idea into reality"],
      image: "template-imagica.png",
      selected: false,
      disabled: false,
    },
  ];

  copyWith(param: Partial<Omit<NewOnboardingFlowStoreState, "copyWith">>): NewOnboardingFlowStoreState {
    const newState = new NewOnboardingFlowStoreState();
    newState.openOptTempLateModal = param.openOptTempLateModal ?? this.openOptTempLateModal;
    newState.selectedFlowItem = param.selectedFlowItem ?? this.selectedFlowItem;
    newState.templateList = param.templateList ?? this.templateList;
    newState.templateListV2 = param.templateListV2 ?? this.templateListV2;

    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);

export class NewOnboardingFlowStore extends StoreBase<NewOnboardingFlowStoreState> {
  constructor() {
    super(() => new NewOnboardingFlowStoreState());
  }

  setOpenOptTempLateModal = (openOptTempLateModal: boolean): void => {
    this.emit(this.state.copyWith({ openOptTempLateModal }));
  };

  setSelectedFlowItem = (selectedFlowItem: NewOnboardingFlowStoreState["selectedFlowItem"]): void => {
    this.emit(this.state.copyWith({ selectedFlowItem }));
  };

  setTemplateList = (templateList: NewOnboardingFlowStoreState["templateList"]): void => {
    this.emit(this.state.copyWith({ templateList }));
  };

  setTemplateListV2 = (templateListV2: NewOnboardingFlowStoreState["templateListV2"]): void => {
    this.emit(this.state.copyWith({ templateListV2 }));
  };

  getItemByKeyword = (type: string, value: string): ListItem | undefined => {
    return this.state.templateList.find(x => x.type === type)?.list.find(x => x.value === value);
  };

  showNewOnboardingFlowTips = (getHomeNodes: () => any): void => {
    const nodes = getHomeNodes();
    const edgeLen = nodes.filter((x: any) => x.type === "customNewEdge").length;
    if (edgeLen === 2) {
      this.setSelectedFlowItem({
        ...this.state.selectedFlowItem,
        showTooltip: true,
      });
    }
  };
}
export const newOnboardingFlowStore = new NewOnboardingFlowStore();
