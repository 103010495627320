import { Typography, Spin } from "antd";
import { useSelector } from "react-redux";
import style from "./VariablePopoverContent.module.css";

const { Paragraph } = Typography;

const VariablePopoverContent = () => {
  const variableList = useSelector((state: any) => state.fot.variableList);

  return (
    <div className={style["variable-list"]}>
      <Spin spinning={false}>
        <div key="currentSourceNodeValue" className={style["variable-item"]}>
          <Paragraph copyable={{ text: "${currentSourceNodeValue}" }}>currentSourceNodeValue</Paragraph>
        </div>
        <div key="brainAuthToken" className={style["variable-item"]}>
          <Paragraph copyable={{ text: "${brainAuthToken}" }}>brainAuthToken</Paragraph>
        </div>

        {variableList.map((variable: any, vIdx: any) => (
          <div key={vIdx} className={style["variable-item"]}>
            <Paragraph copyable={{ text: "${" + `${variable.name}` + "}" }}>{variable.name}</Paragraph>
          </div>
        ))}
      </Spin>
    </div>
  );
};

export default VariablePopoverContent;
