import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { find, get } from "lodash";

type RenderType = "title" | "subtitle" | "inputLabel" | "inputValue" | "output";

type UseRenderData = {
  type: RenderType;
  id?: string;
  previewId?: string;
};
const creatorSaasAppStore = getIt(CreatorSaasAppStore);

function useRenderData({ type, id, previewId }: UseRenderData): any {
  const getRenderData = (): any => {
    // ESLint 的 no-case-declarations 规则
    let inputLabel, inputValue, output;
    switch (type) {
      case "title":
        return get(creatorSaasAppStore.state.saasUIData, "titleV3");
      case "subtitle":
        return get(creatorSaasAppStore.state.saasUIData, "subTitleV3");
      case "inputLabel":
        inputLabel = find(get(creatorSaasAppStore.state.saasUIData, "input"), (input: any) => input.id === id);
        return { style: get(inputLabel, "data.labelFontStyle") };
      case "inputValue":
        inputValue = find(get(creatorSaasAppStore.state.saasUIData, "input"), (input: any) => input.id === id);
        return { style: get(inputValue, "data.nodeFontStyle") };
      case "output":
        output = find(
          get(creatorSaasAppStore.state.saasUIData, "output"),
          output => get(output, "results.0.previewId") === previewId
        );
        return {
          style: get(output, "results.0.nodeFontStyle"),
          isCaptionToggle: get(output, "results.0.isCaptionToggle"),
        };
      default:
        return { style: {} };
    }
  };

  // FIXME: 返回类型不应该有 string
  return (getRenderData() as any) || { style: {} };
}

export { useRenderData };
