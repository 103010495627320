import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { getDefaultPreviewAppTypeList } from "@uiview/views/PreviewApp/const";
import { PreviewInput } from "@uiview/views/PreviewApp/model/previewInput";
import { ViewBuilderGraph } from "imagica-corekit/dist/base/api/viewBuilderTyped/viewBuilderGraph";
import { eventbus } from "imagica-corekit/dist/base/cutil/Eventbus";
import { CotMsg } from "imagica-corekit/dist/base/msg/CotMsg";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { GenUIProcess, TalkBuilder } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboardingType";
import { ViewBuilder } from "imagica-corekit/dist/cases/viewBuilder/ViewBuilder";
import { forEach, uniqBy } from "lodash";
import { HomeHandleFieldMethods } from "./HomeHandleFieldMethods";
import { PreviewPaneStore } from "@uiview/views/PreviewPane/PreviewPaneStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { CotFlow } from "imagica-corekit/dist/cases/cot/CotFlow";
import { CreatorCotService } from "./CreatorCotService";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorPreviewService } from "./CreatorPreviewService";

export class MultiRoundChatOnboardingService {
  constructor(
    private fotReduxStore: FotReduxStore,
    private creatorNodesStore: CreatorNodesStore,
    private creatorEdgesStore: CreatorEdgesStore,
    private creatorSaasAppStore: CreatorSaasAppStore,
    private creatorAISaasStore: CreatorAISaasStore,
    private previewAppStore: PreviewAppStore,
    private previewPaneStore: PreviewPaneStore,
    private chatOnboardingStore: ChatOnboardingStore,
    private projectNodeStore: ProjectNodeStore,
    private projectRunStore: ProjectRunStore,
    private canvasDataRef: CanvasDataRef,
    private cotFlow: CotFlow,
    private creatorCotService: CreatorCotService,
    private creatorPreviewService: CreatorPreviewService,
    private homeHandleFieldMethods: HomeHandleFieldMethods
  ) {}

  openPreviewPage(): void {
    //  previewApp未打开时，打开previewPane，并创建一个prevewApp
    this.previewAppStore.openPreviewPage();
    this.previewPaneStore.setMobileChatStatus(false);
    //  build过则不再重新创建previewApp
    if (this.chatOnboardingStore.state.currentStep !== GenUIProcess.Build) {
      this.previewAppStore.clearPreviewApp();
      this.previewAppStore.addPreview({ format: getDefaultPreviewAppTypeList()[0] });
    }
    // eslint-disable-next-line
  }

  //  如果新生成的input和output不包含talkToBuilder中数据，则清空talkToBuilder
  clearTalkToBuilder = (nodes: any): void => {
    // stud-2493 get input title(不确定)
    const hasNode = nodes.some((item: any) => {
      const projectNode = this.projectNodeStore.getNode(item.id);
      return projectNode?.inputTitle === this.chatOnboardingStore.state.talkBuilder.content;
    });

    if (!hasNode) {
      this.chatOnboardingStore.setChatOnboardingState({ talkBuilder: new TalkBuilder() });
    }
  };

  generateViewBuilder = (viewBuilderGraph: ViewBuilderGraph): void => {
    const inputs: PreviewInput[] = [];
    forEach(viewBuilderGraph.user_inputs, (input, index) => {
      const previewInput = new PreviewInput();
      previewInput.id = `viewBuilderInput-${index}`;
      previewInput.data.placeholder = input.description || "";
      // stud-2493 set input title(preview)
      previewInput.data.inputTitle = input.name || "";
      // stud-2493 set generated label(preview)
      previewInput.data.generatedLabel = input.name || "";
      previewInput.data.textAreaValue = input.value || "";
      previewInput.data.viewbuilderGraphUserInputId = input.id || "";
      inputs.push(previewInput);
    });
    if (viewBuilderGraph.graph && viewBuilderGraph.graph?.nodes?.length) {
      const flow = ViewBuilder.transformGraph(
        viewBuilderGraph.graph,
        this.canvasDataRef.newLineDataRef.current,
        this.canvasDataRef.newEdgeDataRef.current,
        this.canvasDataRef.newLineDataRef.current
      );

      this.creatorNodesStore.setNodes(flow.nodes || []);
      this.creatorEdgesStore.setEdges(flow.edges || []);
    }

    const originalTemplate = this.fotReduxStore.getState().studio.selectedTemplate as any;

    const title = originalTemplate.isMannulModifyProjectName
      ? originalTemplate.name
      : this.creatorSaasAppStore.state.saasUIData.appDetail?.name;
    const subTitle = this.creatorSaasAppStore.state.saasUIData.appDetail?.description;

    this.fotReduxStore.setSelectedTemplate({
      ...this.fotReduxStore.getState().studio.selectedTemplate,
      name: title,
      template_name: title,
    } as any);
    // stud-2493 set input(preview)
    this.creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        output: [],
        input: inputs,
        viewBuilderGraph: viewBuilderGraph,
        title,
        subTitle,
      };
    });
    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);
  };

  async genUIStartFlow({ flow, platform = "saas" }: { flow: any; platform?: string }): Promise<void> {
    this.projectRunStore.setRunAllLoading(false);
    const flowData = await this.cotFlow.transformTemplate(
      "",
      flow,
      this.canvasDataRef.nodeDataRef.current,
      this.canvasDataRef.newEdgeDataRef.current,
      this.canvasDataRef.newLineDataRef.current
    );
    const { nodes = [], edges = [], inputs = [], previewData } = flowData || {};
    this.creatorNodesStore.setNodes(nodes);
    this.creatorEdgesStore.setEdges(edges);
    this.creatorCotService.defaultOpenWebApp();

    const originalTemplate = this.fotReduxStore.getState().studio.selectedTemplate as any;

    const title = originalTemplate.isMannulModifyProjectName
      ? originalTemplate.name
      : this.creatorSaasAppStore.state.saasUIData.appDetail?.name;
    const subTitle = this.creatorSaasAppStore.state.saasUIData.appDetail?.description;
    // FIXME: do we need to use generated app title as project name on thinking canvas too?
    this.fotReduxStore.setSelectedTemplate({
      ...this.fotReduxStore.getState().studio.selectedTemplate,
      name: title,
      template_name: title,
    } as any);
    // stud-2493 set input(preview)(clear)
    // fitCanvas();
    // Empty input and output first
    this.creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        output: [],
        input: [],
      };
    });
    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);

    if (previewData) {
      const { input = [], output = [] } = previewData;
      const uniqNodes = uniqBy(nodes, node => node.id);
      const inputNodes = uniqNodes.filter(node => input.map(item => `editor-${item}`).includes(node.id));
      const outPutNodes = uniqNodes.filter(node => output.map(item => `editor-${item}`).includes(node.id));
      const outPutGroupNodes = uniqNodes.filter(node => output.map(item => `group-${item}`).includes(node.id));
      const inputIds = this.fotReduxStore.getState().fot.inputId;
      const addInputs = inputs.map(x => x.id);
      const newInputId = [...inputIds, ...addInputs];
      this.creatorAISaasStore.setInputId(newInputId);
      this.clearTalkToBuilder([...inputNodes, ...outPutNodes]);
      const needAddInput = inputNodes.filter(node =>
        (this.creatorSaasAppStore.state.saasUIData.input || []).every((input: any) => input.id !== node.id)
      );
      needAddInput.forEach(input => {
        this.homeHandleFieldMethods.handleInputField(
          input,
          platform,
          creatorRefStore.cotDescription.current,
          title,
          subTitle
        );
      });
      outPutNodes.forEach(node => this.creatorPreviewService.addIndividual(node, platform, true));
      outPutGroupNodes.forEach(node => this.creatorPreviewService.clickGroupToPreview(node, platform, true));
    }
    this.creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        title,
        subTitle,
      };
    });
    eventbus.emit(new CotMsg());
  }
}
