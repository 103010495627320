"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFunctionDistirbution = void 0;
const StoryEdge_1 = require("../../../storyV2/domain/StoryEdge");
const StoryNode_1 = require("../../../storyV2/domain/StoryNode");
const StoryNodeType_1 = require("../../../storyV2/domain/StoryNodeType");
const StoryManagerUtil_1 = require("../../../storyV2/domain/StoryManagerUtil");
const StoryNodeOptions_1 = require("../../../storyV2/domain/StoryNodeOptions");
const BaseDistribution_1 = require("../../../storyV2/response/responseDistribution/BaseDistribution");
class CustomFunctionDistirbution extends BaseDistribution_1.BaseDistribution {
    handleRunResponse(story, edge, res, relatedEdges) {
        const streamResponse = res.data;
        /**
         * 记录监听来了多少次，一般都是来两次
         */
        let listenCount = 0;
        return new Promise((resolve, reject) => {
            streamResponse.onReceived(result => {
                /**
                 * 一条边运行过程中只会来两次，
                 * 1 已经明确知道outNode(也就是走到了handlexxxxResult 这个函数)
                 * 2 当这条边已经运行结束了
                 */
                const createdNode = result.value;
                const createdNodeIsGroup = createdNode.options.type === StoryNodeType_1.StoryNodeType.CUSTOMGROUP;
                const state = result.completion ? StoryEdge_1.EdgeState.COMPLETED : StoryEdge_1.EdgeState.LOADING;
                listenCount += 1;
                if (createdNodeIsGroup) {
                    const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMGROUP, undefined);
                    if (!result.completion || (listenCount === 1 && result.completion)) {
                        // 只有第一次来的时候才需要走以下逻辑，生成新的node，并且做绑定
                        // 不能两次都来，两次都走到这里面会导致最后一次来的时候页面闪烁
                        const newChildIdNumbers = StoryManagerUtil_1.StoryManagerUtil.idNumberForGroup(story, createdNode.children.length, targetNode);
                        const childNodes = createdNode.children.map((value, index) => {
                            const nodeOptions = new StoryNodeOptions_1.StoryNodeOptions(`editor-${newChildIdNumbers.get(index)}`, undefined, StoryNodeType_1.StoryNodeType.CUSTOMNODE, value.options.displayType, targetNode.id);
                            const node = new StoryNode_1.StoryNode(nodeOptions);
                            node.state = value.state;
                            node.content = value.content;
                            return node;
                        });
                        targetNode.options.children = childNodes.map(node => node.options.id);
                        StoryManagerUtil_1.StoryManagerUtil.cleanAndSetNewChildren(targetNode, childNodes);
                        childNodes.forEach(item => {
                            item.received(new StoryNode_1.StoryNodeEventObject(item.content));
                        });
                        targetNode.state = createdNode.state;
                        // 更新group的状态
                        targetNode.received(new StoryNode_1.StoryNodeEventObject(""));
                        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], state, story);
                        this.bindSubStoryLastNodeToParentStory(createdNode, targetNode);
                    }
                    // 这里需要更新node/edge的状态
                    targetNode.options.displayType = createdNode.options.displayType;
                    targetNode.state = createdNode.state;
                    // 更新group的状态
                    targetNode.received(new StoryNode_1.StoryNodeEventObject(""));
                    StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], state, story);
                }
                else {
                    const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMNODE, createdNode.options.displayType);
                    if (!result.completion || (listenCount === 1 && result.completion)) {
                        targetNode.content = createdNode.content;
                        targetNode.options.displayType = createdNode.options.displayType;
                        targetNode.state = createdNode.state;
                        targetNode.cleanChildren();
                        targetNode.received(new StoryNode_1.StoryNodeEventObject(createdNode.content));
                        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], state, story);
                        this.bindSubStoryLastNodeToParentStory(createdNode, targetNode);
                    }
                    targetNode.options.displayType = createdNode.options.displayType;
                    targetNode.state = createdNode.state;
                    StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], state, story);
                }
                /*this.bindSubStoryLastNodeToParentStory(createdNode, targetNode); 这个方法内部的绑定事件是异步的，有点延迟*/
                // 所以结束的时候需要延迟一点处理
                if (result.error) {
                    setTimeout(() => {
                        reject(result.error);
                    }, 100);
                    return;
                }
                if (result.completion) {
                    setTimeout(() => {
                        resolve();
                    }, 100);
                    return;
                }
            });
        });
    }
    /**
     * 绑定subStroy的最后一个节点到parentStory，实现联动
     * @param parentNode
     * @param subStoryCreatedNode
     * @private
     */
    bindSubStoryLastNodeToParentStory(parentNode, subStoryCreatedNode) {
        if (parentNode.children.length > 0 && parentNode.children.length >= subStoryCreatedNode.children.length) {
            parentNode.children.forEach((itemNode, index) => {
                itemNode.on(eventObject => {
                    subStoryCreatedNode.children[index].state = itemNode.state;
                    subStoryCreatedNode.children[index].options.displayType = itemNode.options.displayType;
                    subStoryCreatedNode.children[index].received(eventObject);
                });
            });
            return;
        }
        parentNode.on(eventObject => {
            subStoryCreatedNode.state = parentNode.state;
            subStoryCreatedNode.options.displayType = parentNode.options.displayType;
            subStoryCreatedNode.received(eventObject);
        });
    }
}
exports.CustomFunctionDistirbution = CustomFunctionDistirbution;
