import style from "./ChatContainer.module.scss";
import { ChatContent } from "./../Components/ChatContent/ChatContent";
import { ChatAIStore } from "../ChatAIStore";
import { getIt } from "@uikit/getIt";
import { useCreation } from "ahooks";
import { ChatContainerBloc, ChatContainerState } from "./ChatContainerBloc";
import isBlank from "@sedan-utils/is-blank";
import { useEffect, useMemo } from "react";
import func from "@uikit/func";
import { get, isEqual } from "lodash";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import store from "@store/index";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { EdgeRunAll } from "@uikit/service/EdgeRunAll";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";
import { previewStore } from "@uiview/store/PreviewStore";

export function ChatContainer({ container }: { container: React.RefObject<HTMLElement> }): JSX.Element {
  const chatAIStore = getIt(ChatAIStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const edgeRunAll = getIt(EdgeRunAll);

  const state = new ChatContainerState();
  const featureTags: string[] = get(store.getState().editor.me, "feature_tags", []);
  const homePluginStore = getIt(HomePluginStore);
  const isShare = homePluginStore.state.isShare;
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const previewComposeState = getIt(PreviewComposeState);
  const previewState = useImagicaStore(previewStore).value;
  const appStates = useMemo(() => {
    return previewComposeState.appStates();
  }, [previewState.previewAppAcivieId, saasUIData]);
  const type = appStates?.chatMode || ""; // text chat upload
  const inputId = get(saasUIData, "input.[0].id", undefined);
  const outputId = get(saasUIData, "output.[0].results.[0].nodeId", undefined);
  const chatId = type === "chat" ? inputId || outputId : outputId;
  const inputNode = creatorNodesStore.getNodes().find((x: any) => x.id === inputId);
  const chatNode = creatorNodesStore.getNodes().find((x: any) => x.id === chatId);
  const bloc = useCreation(() => new ChatContainerBloc(state, isShare, featureTags, edgeRunAll, inputNode), []);

  useEffect(() => {
    if (bloc.state.chatList.value.length > 0) {
      chatAIStore.setHasChatList(true);
    } else {
      chatAIStore.setHasChatList(false);
    }
  }, [bloc.state.chatList.value, chatAIStore]);

  useEffect(() => {
    if (
      chatNode &&
      !func.isEmpty(chatNode.data?.textAreaValue) &&
      (func.isEmpty(chatNode.data.textAreaValue.chatList) ||
        !isEqual(bloc.state.chatList.value, chatNode.data.textAreaValue.chatList))
    ) {
      bloc.updateChatInfo(chatNode.data);
    }
    // console.log("----------------------------- chatNode?.data?.textAreaValue", chatNode?.data?.textAreaValue);
    // eslint-disable-next-line
  }, [JsonUtil.stringify(chatNode?.data?.textAreaValue)]);

  useEffect(() => {
    if (!isShare) {
      creatorNodesStore.setNodes((prevList: any[]) => {
        return prevList.map(x => {
          if (x.id === chatId) {
            return {
              ...x,
              data: {
                ...x.data,
                textAreaValue: {
                  file: x.data.textAreaValue.file,
                  filename: x.data.textAreaValue.filename,
                  date: x.data.textAreaValue.date,
                  file_uuid: x.data.textAreaValue.file_uuid,
                  chatList: bloc.state.chatList.value,
                  preQuery: bloc.state.prevInput.value,
                  chatHistory: bloc.state.chatList.value,
                  isStreamStopped: bloc.state.isStreamStopped.value,
                },
              },
            };
          }
          return x;
        });
      });
    }
    // eslint-disable-next-line
  }, [bloc.state.chatList.value, bloc.state.isStreamStopped.value]);

  useEffect(() => {
    if (type === "upload" && !isShare && !func.isEmpty(bloc.state.rcFile.value) && !func.isEmpty(inputId)) {
      creatorNodesStore.setNodes((prevList: any[]) => {
        return prevList.map(x => {
          if (x.id === inputId) {
            const textAreaValue = {
              s3Url: bloc.state.s3Url.value,
              file: { name: bloc.state.filename.value, size: 0 },
              loading: false,
              errorMsg: "",
              percent: "",
              name: bloc.state.filename.value,
              size: 0,
              s3Path: bloc.state.file.value,
              needToCreateBlobUrl: `/api/me/upload/studios?url=${encodeURIComponent(bloc.state.s3Url.value)}`,
              unStructuredDownloadFailedText: "",
              format: bloc.state.fileFormat.value,
              uuid: bloc.state.file_uuid.value,
            };
            const file = bloc.state.rcFile.value;
            return {
              ...x,
              data: {
                ...x.data,
                ...(bloc.state.displayType.value === "unStructured"
                  ? {}
                  : {
                      initCreateBlobUrl: {
                        objectURL: URL.createObjectURL(file as unknown as Blob),
                        fileBlob: file,
                        loading: false,
                      },
                    }),
                textAreaValue,
                displayType: bloc.state.displayType.value,
              },
            };
          }
          return x;
        });
      });
      edgeRunAll.clickRunAllBtn([inputNode], true);
    }
    // eslint-disable-next-line
  }, [bloc.state.rcFile.value]);

  // share app
  if (isShare) {
    return (
      <div className={style.ChatContainer}>
        <ChatContent
          type={type}
          bloc={bloc}
          container={container}
          chatUpload={type === "upload"}
          fileName={bloc.state.filename.value}
          state={state}
          inputNode={inputNode}
        />
        {/* {chatAIState.isEnterInputLoadingDone ? (
          <ChatContent
            type={type}
            bloc={bloc}
            container={container}
            chatUpload={type === "upload"}
            fileName={bloc.state.filename.value}
            state={state}
          />
        ) : type === "upload" ? (
          <ChatUpload page={"home"} uploadFile={(file: RcFile, res: any) => bloc.uploadFile(file, res)} />
        ) : (
          <>
            <ChatInput
              isLoading={state.isLoading.value}
              page={"home"}
              onPressEnter={(txt: string) => bloc.onPressEnter(txt, type)}
            />
          </>
        )} */}
      </div>
    );
  }

  // canvas app
  return (
    <div className={style.ChatContainer}>
      {/* upload input */}
      {/* {saasUIData?.input?.[0]?.data?.displayType === "text" && (
        <>
          <ChatInput
            isLoading={state.isLoading.value}
            page={"preview"}
            id={saasUIData?.input?.[0]?.id}
            label={saasUIData?.input?.[0]?.data?.inputTitle}
            inputVal={
              chatAIState.editingType === "textAreaValue"
                ? saasUIData?.input?.[0]?.data?.textAreaValue
                : saasUIData?.input?.[0]?.data?.placeholder
            }
            placeholder={saasUIData?.input?.[0]?.data?.placeholder}
            labelStyle={saasUIData?.input?.[0]?.data?.labelFontStyle}
            inputStyle={saasUIData?.input?.[0]?.data?.nodeFontStyle}
            onPressEnter={(txt: string) => bloc.onPressEnter(txt, type)}
            onLabelChange={(label: string) => onLabelChange(label)}
            onLabelRemove={() => onLabelChange("")}
            onInputChange={(inputVal: string) => onInputChange(inputVal)}
            onInputRemove={() => onInputChange("")}
          />
        </>
      )} */}
      {/* {uploadTypes.includes(saasUIData?.input?.[0]?.data?.displayType) && (
        <div
          ref={bloc.state.chatUploadRef}
          style={{ position: "relative" }}
          onMouseEnter={onPreviewItemMouseEnter.bind(null, {
            page: "chat",
            type: "fileUpload",
            container: bloc.state.chatUploadRef.current,
            remove: () => {
              setInputId([]);
              setSaasUIData((prevData: any) => {
                return {
                  ...prevData,
                  input: [],
                };
              });
            },
          })}
          onMouseLeave={onPreviewItemMouseLeave}
        >
          <ChatUpload
            file={inputNode?.data?.textAreaValue?.file}
            page={"preview"}
            uploadFile={(file: RcFile, res: any) => bloc.uploadFile(file, res)}
          />
        </div>
      )} */}
      {/* chat output */}
      {(!isBlank(saasUIData?.output) || saasUIData?.input?.[0]?.data?.displayType === "chatInterface") && (
        <div
          ref={bloc.state.chatContentRef}
          style={{ position: "relative", flex: 1, display: "flex" }}
          // onMouseEnter={onPreviewItemMouseEnter.bind(null, {
          //   page: "chat",
          //   type: "chat",
          //   container: bloc.state.chatContentRef.current,
          //   remove: () => {
          //     handleDeleteSingleNodeByPreviewBtn(saasUIData?.output[0].results[0].nodeId);
          //     setSaasUIData((prevData: any) => {
          //       return {
          //         ...prevData,
          //         output: [],
          //       };
          //     });
          //   },
          // })}
          // onMouseLeave={onPreviewItemMouseLeave}
        >
          <ChatContent
            bloc={bloc}
            type={type}
            container={container}
            chatUpload={type === "upload"}
            fileName={bloc.state.filename.value}
            state={state}
            inputNode={inputNode}
          />
        </div>
      )}
    </div>
  );
}
