import store from "@store/index";
import { getIt } from "@uikit/getIt";
import { TermsPopup } from "@views/thinking-layout-editor/TermsPopup/TermsPopup";
import { TermsPopupBloc } from "@views/thinking-layout-editor/TermsPopup/TermsPopupBloc";
import { useCreation } from "ahooks";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";

export function CreatorTermsPopup() {
  const webConfigState = useImagincStore(getIt(WebConfigStore)).value;
  const termsPopuBloc = useCreation(() => new TermsPopupBloc(store), []);

  if (!termsPopuBloc.state.agreementPromptModel.value) {
    return null;
  }
  return (
    <TermsPopup
      imagicaTermsPopup={webConfigState?.imagica_terms_popup}
      clickTermsButton={type => termsPopuBloc.clickTermsButton(type)}
    />
  );
}
