import store from "@store/index";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { useEffect, useState } from "react";
import styles from "./ShareToAppModal.module.scss";

export function ShareToAppModal() {
  const [visible, setVisible] = useState(false);

  const subscriberParamsData: any = store.getState().editor.subscriberParamsData;

  const isMobile = window.navigator.userAgent.includes("Mobile");

  const handleClick = () => {
    const projectId = subscriberParamsData.attributes?.project_id;
    navigator?.share?.({
      title: "Imagica",
      url: `${settings.ImagicaUrl}${projectId}`,
    });
    setVisible(false);
  };

  const handleClose: React.MouseEventHandler<HTMLImageElement> = e => {
    e.stopPropagation();
    setVisible(false);
  };

  useEffect(() => {
    setVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMobile && visible) {
    return (
      <div className={styles.shareToAppModal} onClick={handleClick}>
        <img className={styles.shareIcon} src={`${settings.S3CDN}${settings.viewAssetsPath}shareToApp.svg`} alt="" />{" "}
        Edit or publish the app on desktop
        {/* <div className={styles.shareToAppCloseIcon}>
          <img onClick={handleClose} src={`${settings.S3CDN}${settings.viewAssetsPath}close_black.svg`} alt="" />
        </div> */}
      </div>
    );
  }
  return null;
}
