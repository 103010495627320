import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class FunctionPopupStoreState {
  createNewFuncModal = false;
  funcName = "";
  createFunctionTitle = "Create a new function";
  funcDescription = "";
  saveFunctionLoading = false;

  copyWith(param: Partial<Omit<FunctionPopupStoreState, "copyWith">>): FunctionPopupStoreState {
    const newState = new FunctionPopupStoreState();
    newState.createNewFuncModal = param.createNewFuncModal ?? this.createNewFuncModal;
    newState.funcName = param.funcName ?? this.funcName;
    newState.createFunctionTitle = param.createFunctionTitle ?? this.createFunctionTitle;
    newState.funcDescription = param.funcDescription ?? this.funcDescription;
    newState.saveFunctionLoading = param.saveFunctionLoading ?? this.saveFunctionLoading;

    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);

/**
 * canvas 创建函数弹窗状态
 */
export class FunctionPopupStore extends StoreBase<FunctionPopupStoreState> {
  constructor() {
    super(() => new FunctionPopupStoreState());
  }

  setCreateNewFuncModal = (createNewFuncModal: boolean): void => {
    this.emit(this.state.copyWith({ createNewFuncModal }));
  };

  setFuncName = (funcName: string): void => {
    this.emit(this.state.copyWith({ funcName }));
  };

  setCreateFunctionTitle = (createFunctionTitle: string): void => {
    this.emit(this.state.copyWith({ createFunctionTitle }));
  };

  setFuncDescription = (funcDescription: string): void => {
    this.emit(this.state.copyWith({ funcDescription }));
  };

  setSaveFunctionLoading = (saveFunctionLoading: boolean): void => {
    this.emit(this.state.copyWith({ saveFunctionLoading }));
  };
}
export const functionPopupStore = new FunctionPopupStore();
