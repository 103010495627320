import { AiSaasOutputProvider, useAiSaasOutputContainer } from "..";
import css from "./index.module.scss";
import classNames from "classnames";
import { useEffect } from "react";
import { AYLWAYS_SHOW_OUTPUT } from "../../AddToContent/conts";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { matchMobile } from "@uikit/func";
import { SortableList } from "@uiview/views/SortableList/SortableList";
import { OutputItem } from "./OutputItem";

function AiSaasOutputListInner(): JSX.Element {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);

  const homeStoreState = useImagincStore(homeStore).value;
  const previewState = useImagincStore(previewStore).value;

  const { dndThoughts, className, disabledDnd, onChange } = useAiSaasOutputContainer();

  const featureTags = homeStoreState.featureTags;

  useEffect(() => {
    if (!previewState.isAddOutputNode || previewState.addContentId === AYLWAYS_SHOW_OUTPUT) return;
    if (dndThoughts?.every(item => item.id !== previewState.addContentId)) {
      //  当移除的是addContentId
      previewStore.setAddContentId(previewState.nextAddContentId);
      dndThoughts.forEach((item, index, array) => {
        if (item.id === previewState.nextAddContentId) {
          previewStore.setNextAddContentId((array[index + 1]?.id as string) || AYLWAYS_SHOW_OUTPUT);
        }
      });
    } else if (dndThoughts?.every(item => item.id !== previewState.nextAddContentId)) {
      //  当移除的是nextAddContentId
      dndThoughts.forEach((item, index, array) => {
        if (item.id === previewState.addContentId) {
          previewStore.setNextAddContentId((array[index + 1]?.id as string) || AYLWAYS_SHOW_OUTPUT);
        }
      });
    }
  }, [dndThoughts, previewState.addContentId, previewState.nextAddContentId, previewState.isAddOutputNode]);

  return (
    <div
      className={classNames(
        css["AiSaasOutputList"],
        { [css.AiSaasOutputListMobile]: isMobile && featureTags.disableMobilePage },
        className,
        "globalAiSaasOutputList"
      )}
    >
      <SortableList
        onChange={onChange}
        disabled={disabledDnd}
        options={dndThoughts}
        renderItem={({ item, index }) => (
          <OutputItem key={item.id} item={item} index={index} sortableList={dndThoughts} />
        )}
      />
    </div>
  );
}

export default function AiSaasOutputList(props: AISaasOutput.AiSaasOutputListProps): JSX.Element {
  return (
    <AiSaasOutputProvider initialState={props}>
      <AiSaasOutputListInner />
    </AiSaasOutputProvider>
  );
}
