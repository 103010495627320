"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketClient = void 0;
class EventMap {
    constructor() {
        this.deps = new Map();
    }
    add(eventType, callback) {
        this.deps.set(eventType, callback);
    }
    remove(eventType) {
        if (this.deps.has(eventType)) {
            this.deps.delete(eventType);
        }
    }
    clear() {
        this.deps.clear();
    }
}
class SocketClient extends EventMap {
    // eslint-disable-next-line
    constructor(authStore) {
        super();
        this.topicMap = new Map();
    }
    on(topic, listener, topicKey) {
        this.socketOn(topic, listener);
        super.add(topic, listener);
        if (topicKey) {
            this.topicMap.set(topicKey, topic);
        }
    }
    off(topic, listener) {
        this.socketOff(topic, listener);
        super.remove(topic);
        this.topicMap.forEach((value, key, map) => {
            if (value === topic) {
                map.delete(key);
            }
        });
    }
    offTopic(topicKey) {
        const msgType = this.topicMap.get(topicKey);
        if (msgType) {
            this.off(msgType);
        }
    }
    disconnect(cleanup = true) {
        this.socketDisconnect();
        if (cleanup) {
            this.topicMap.clear();
            super.clear();
        }
    }
    async reconnect() {
        console.log(`${this.socketName} reconnect :>> `);
        this.disconnect(false);
        await this.socketReconnect();
        this.deps.forEach((listener, eventName) => {
            this.on(eventName, listener);
        });
    }
}
exports.SocketClient = SocketClient;
