import { drag } from "d3-drag";
import { select } from "d3-selection";
import { useEffect, useRef } from "react";
import { getIt } from "@uikit/getIt";
import { useGetPointerPosition } from "reactflow";
import { calcNextPosition, getDragItems, getEventHandlerParams, getEventPosition } from "./utils";
import { FotReactFlow } from "@uikit/model/FotReactFlow";

function wrapSelectionDragFunc(selectionFunc) {
  return (event, _, nodes) => selectionFunc?.(event, nodes);
}

function useGroupDraggable({ nodeRef, nodeId, disable = false }) {
  const fotReactFlow = getIt(FotReactFlow);
  const store = fotReactFlow.getReactFlowStore();
  const dragItems = useRef([]);
  const lastPos = useRef({
    x: null,
    y: null,
  });
  const containerBounds = useRef(null);
  const mousePosition = useRef({ x: 0, y: 0 });
  const dragEvent = useRef(null);

  const getPointerPosition = useGetPointerPosition();

  useEffect(() => {
    if (nodeRef?.current) {
      const selection = select(nodeRef.current);

      const updateNodes = ({ x, y }) => {
        const {
          nodeInternals,
          updateNodePositions,
          nodeExtent,
          snapGrid,
          snapToGrid,
          nodeOrigin,
          onError,
          onNodeDrag,
          onSelectionDrag,
        } = store.getState();

        lastPos.current = { x, y };

        let hasChange = false;

        dragItems.current = dragItems.current.map(n => {
          const nextPosition = { x: x - n.distance.x, y: y - n.distance.y };

          if (snapToGrid) {
            nextPosition.x = snapGrid[0] * Math.round(nextPosition.x / snapGrid[0]);
            nextPosition.y = snapGrid[1] * Math.round(nextPosition.y / snapGrid[1]);
          }

          const updatedPos = calcNextPosition(n, nextPosition, nodeInternals, nodeExtent, nodeOrigin, onError);

          // we want to make sure that we only fire a change event when there is a changes
          hasChange = hasChange || n.position.x !== updatedPos.position.x || n.position.y !== updatedPos.position.y;

          n.position = updatedPos.position;
          n.positionAbsolute = updatedPos.positionAbsolute;

          return n;
        });

        if (!hasChange) {
          return;
        }

        updateNodePositions(dragItems.current, true, true);

        const onDrag = nodeId ? onNodeDrag : wrapSelectionDragFunc(onSelectionDrag);

        if (onDrag && dragEvent.current) {
          const [currentNode, nodes] = getEventHandlerParams({
            nodeId,
            dragItems: dragItems.current,
            nodeInternals,
          });
          onDrag(dragEvent.current, currentNode, nodes);
        }
      };

      if (!nodeId === true || disable === true) {
        selection.on(".drag", null);
      } else {
        const dragHandler = drag()
          .on("start", event => {
            const { nodeInternals, domNode, nodesDraggable, onNodeDragStart, onSelectionDragStart } = store.getState();

            const onStart = nodeId ? onNodeDragStart : wrapSelectionDragFunc(onSelectionDragStart);

            const pointerPos = getPointerPosition(event);
            lastPos.current = pointerPos;
            dragItems.current = getDragItems(nodeInternals, nodesDraggable, pointerPos, nodeId);

            if (onStart && dragItems.current) {
              const [currentNode, nodes] = getEventHandlerParams({
                nodeId,
                dragItems: dragItems.current,
                nodeInternals,
              });
              onStart(event.sourceEvent, currentNode, nodes);
            }

            containerBounds.current = domNode?.getBoundingClientRect() || null;
            mousePosition.current = getEventPosition(event.sourceEvent, containerBounds.current);
          })
          .on("drag", event => {
            const pointerPos = getPointerPosition(event);
            // skip events without movement
            if (
              (lastPos.current.x !== pointerPos.xSnapped || lastPos.current.y !== pointerPos.ySnapped) &&
              dragItems.current
            ) {
              dragEvent.current = event.sourceEvent;
              mousePosition.current = getEventPosition(event.sourceEvent, containerBounds.current);

              updateNodes(pointerPos);
            }
          })
          .on("end", event => {
            if (dragItems.current) {
              const { updateNodePositions, nodeInternals, onNodeDragStop, onSelectionDragStop } = store.getState();
              const onStop = nodeId ? onNodeDragStop : wrapSelectionDragFunc(onSelectionDragStop);

              updateNodePositions(dragItems.current, false, false);

              if (onStop) {
                const [currentNode, nodes] = getEventHandlerParams({
                  nodeId,
                  dragItems: dragItems.current,
                  nodeInternals,
                });
                onStop(event.sourceEvent, currentNode, nodes);
              }
            }
          });

        selection.call(dragHandler);

        return () => {
          selection.on(".drag", null);
        };
      }
    }
  }, [nodeRef, store, nodeId, getPointerPosition, disable]);
}

export default useGroupDraggable;
