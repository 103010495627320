"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadResponse = void 0;
class UploadResponse {
    constructor() {
        this.cdn_url = undefined;
        this.object_key = "";
        this.s3_https_url = "";
        this.s3_s3_url = "";
        this.s3_url = "";
        this.uuid = "";
    }
}
exports.UploadResponse = UploadResponse;
