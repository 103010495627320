import { useCreation } from "ahooks";
import { getIt } from "@uikit/getIt";
import func from "@uikit/func";
import { useDispatch } from "react-redux";
import styles from "./Unpublish.module.scss";
import { studioActions } from "@store/studio";
import { UnpublishBloc, UnpublishBlocState } from "./UnpublishBloc";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { PowerMaskView } from "@uiview/views/PowerMask/PowerMaskView";
import { logEvent } from "@uikit/service/amplitude";
import { previewStore } from "@uiview/store/PreviewStore";
import { UnpublishModal } from "./UnpublishModal";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { PreviewAppStore, PublishState } from "@uikit/store/PreviewAppStore";

const gql = getIt(ObjectRelationGqlService);

export function Unpublish(): JSX.Element {
  const dispatch = useDispatch();

  const state = new UnpublishBlocState();
  const bloc = useCreation(() => new UnpublishBloc(state), []);

  const brainClient = getIt(BrainClient);

  const setSelectedTemplate = (val: any): void => {
    dispatch(studioActions.setSelectedTemplate(val));
  };
  const previewAppStore = getIt(PreviewAppStore);

  const published_metadata = previewAppStore.getPublishMetadata();
  const disabledUnPublish = func.isEmpty(published_metadata?.link);

  function handleOk(): void {
    handleUnpublishV2();
  }

  async function handleUnpublishV2(): Promise<void> {
    try {
      bloc.state.unpublishLoading.value = true;
      const selectedTemplate = previewAppStore.getSelectedTemplate();
      if (selectedTemplate === undefined) {
        throw Error("can not find app");
      }
      if (!func.isEmpty(published_metadata?.published_at)) {
        const linkMateData = (selectedTemplate.published_metadata_v2 as any[])?.find(item => {
          return item.id === previewStore.state.previewAppAcivieId;
        });
        if (linkMateData?.link) {
          const linkUrl = new URL(linkMateData?.link.replace("#/", ""));
          const query = linkUrl.searchParams.get("q") ?? "";
          await brainClient.user.unpublish(query);
          const setingUIData = selectedTemplate.v3?.UI?.find((item: any) => {
            return item.id === linkMateData.id;
          });
          logEvent("unpublish", {
            URL: linkMateData?.link,
            publish_view_name: setingUIData?.title,
            name: setingUIData?.v2App?.appName,
            uuid: linkMateData.uuid,
          });
        }
      }
      let newTemplate = previewAppStore.clearPublishMetadata();
      /**
       * 重置部分已保存domain name的数据
       */
      newTemplate = previewAppStore.resetDomainName(newTemplate);
      await gql.updateObject(parseInt(selectedTemplate.id), {
        name: "studio_project",
        attributes: newTemplate,
      });
      setSelectedTemplate(newTemplate);
      bloc.state.showModal.value = false;
    } catch (error) {
      func.messageError(error);
    } finally {
      bloc.state.unpublishLoading.value = false;
      // 标记状态为unpublished
      previewAppStore.setPublishState(PublishState.unpublish);
    }
  }

  if (disabledUnPublish) return <div></div>;

  return (
    <PowerMaskView feature={[]} featureItem="">
      <div className={styles.unpublishBox}>
        <div className={styles.title}>Unpublish</div>
        <div className={styles.subTitle}>Hide this publish view from the public.</div>
        <div
          className={styles.buttonBox}
          onClick={() => {
            const selectedTemplate = previewAppStore.getSelectedTemplate();
            bloc.showUnpublishModal(
              disabledUnPublish,
              previewStore.state.previewAppAcivieId as string,
              selectedTemplate
            );
          }}
        >
          Unpublish
        </div>

        {bloc.state.showModal.value && <UnpublishModal bloc={bloc} handleOk={handleOk} />}
      </div>
    </PowerMaskView>
  );
}
