import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { Root } from "react-dom/client";

type Param = Partial<Omit<AmodalStoreState, "copyWith">>;

export class AmodalStoreState {
  root: null | Root = null;

  copyWith(params: Param): AmodalStoreState {
    const state = new AmodalStoreState();
    state.root = params.root ?? this.root;
    return state;
  }
}

export class AmodalStore extends StoreBase<AmodalStoreState> {
  constructor() {
    super(() => new AmodalStoreState());
  }

  updateRoot(root: Root): void {
    this.emit(this.state.copyWith({ root }));
  }

  clear(): void {
    this.emit(this.state.copyWith({ root: null }));
  }
}
