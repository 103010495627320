"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageModel = void 0;
class ImageModel {
    constructor(content, imageUrl, value) {
        this.content = content;
        this.imageUrl = imageUrl;
        this.value = value;
    }
}
exports.ImageModel = ImageModel;
