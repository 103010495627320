import { useRef, useEffect, useState } from "react";
import className from "classnames";
import style from "./SaasHeadManage.module.scss";
import { Input } from "antd";
import func from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import LoadingOutline from "@views/components/LoadingOutline";
import { useRenderData } from "@uiview/views/AISaas/hooks/useRenderData";
import { useAISaasPreviewItem } from "../../hooks/useAISaasPreviewItem";
import { useSignal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { TalkBuilder } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboardingType";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { isEqual } from "lodash";
import { matchMobile } from "@uikit/func";

const SaasHeadManage = props => {
  const titleBoxRef = useRef(null);
  const titleSubRef = useRef(null);
  const titleRef = useRef(null);
  const [showEditTitle, setShowEditTitle] = useState(false);
  const [showTitle, setShowTitle] = useState(props.saasUIData?.showTitle || true);
  const [saasTitle, setSaasTitle] = useState(props.saasUIData?.title || "");
  const [saasTitleStyle, setSaasTitleStyle] = useState({
    fontFamily: "Ak-Regular",
    fontSize: "36px",
    textAlign: "center",
    fontWeight: 500,
  });

  const subTitleRef = useRef(null);
  const [showEditSubTitle, setShowEditSubTitle] = useState(false);
  const [showSubTitle, setShowSubTitle] = useState(props.saasUIData?.showSubTitle || true);
  const [saasSubTitle, setSaasSubTitle] = useState(props.saasUIData?.subTitle || "");
  const [saasSubTitleStyle] = useState({
    fontFamily: "Ak-Regular",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "25px",
  });

  const hoverTitle = useSignal(false);
  const hoverSubTitle = useSignal(false);

  const isMobile = matchMobile();

  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const chatOnboardingState = useStore(chatOnboardingStore).value;
  const { id } = chatOnboardingState.talkBuilder;

  // 开始编辑时的title，用于编辑结束时对比title是否变化(暂用于Amplitude判断是否修改了title),下同
  const startEditingTitle = useSignal("");
  const startEditingSubTitle = useSignal("");

  const longPressTimer = useRef(null);

  const { previewHoverData, onPreviewItemMouseEnter, onPreviewItemMouseLeave } = useAISaasPreviewItem();

  const hideAndDisplay = key => {
    return key ? style["display-box"] : style["hide-box"];
  };
  // title
  const onTitleChange = e => {
    setSaasTitle(e.target.value);
  };

  const onTitlePressEnter = e => {
    e.preventDefault();
    setShowEditTitle(false);
    clickTitleStyleSave(saasTitleStyle);
    titleJudgeThenLogEvent();
  };

  /* const titleClickTalkToAIBuilder = () => {
    if (featureTags.disableMultiRoundChatOnboarding) {
      return;
    }
    chatOnboardingStore.setTalkBuilder({
      ...new TalkBuilder(),
      id: "title",
      content: "title",
      container: titleBoxRef.current,
    });
  };

  const subTitleClickTalkToAIBuilder = () => {
    if (featureTags.disableMultiRoundChatOnboarding) {
      return;
    }
    chatOnboardingStore.setTalkBuilder({
      ...new TalkBuilder(),
      id: "subtitle",
      content: "subtitle",
      container: titleSubRef.current,
    });
  }; */

  const titleEditTouchStart = e => {
    longPressTimer.current = setTimeout(() => {
      onPreviewItemMouseEnter({
        type: "title",
        container: titleBoxRef.current,
        edit: clickTitleEdit,
        remove: clickCloseTitle,
      });
    }, 500);
  };

  const subtitleEditTouchStart = e => {
    longPressTimer.current = setTimeout(() => {
      onPreviewItemMouseEnter({
        type: "description",
        container: titleSubRef.current,
        edit: clickSubTitleEdit,
        remove: clickSubCloseTitle,
      });
    }, 500);
  };

  const touchEnd = e => {
    longPressTimer.current && clearTimeout(longPressTimer.current);
  };

  const titleEdit = options => {
    if (showEditTitle) {
      return;
    }
    setShowTitle(true);
    setShowEditTitle(true);
    if (!func.isEmpty(titleRef.current)) {
      titleRef.current.focus(options);
    }

    // if (!(isMobile && featureTags.disableMobilePage)) {
    onPreviewItemMouseEnter({
      type: "title",
      container: titleBoxRef.current,
      edit: clickTitleEdit,
      remove: clickCloseTitle,
    });
    // }

    startEditingTitle.value = saasTitle;
    // titleClickTalkToAIBuilder();
  };
  const clickTitleEdit = () => {
    titleEdit({
      cursor: "end",
    });
  };

  const clickCloseTitle = () => {
    setSaasTitle(props.saasUIData?.title || "");
    setShowEditTitle(false);
    props.setSaasUIData(prevData => {
      return Object.assign({}, prevData, { showTitle: false, title: "" });
    });
    titleJudgeThenLogEvent({ delete: true });
  };

  const titleJudgeThenLogEvent = param => {
    const oldText = startEditingTitle.value;
    if (isEqual(oldText, saasTitle)) {
      return;
    }

    editTextLogEventWithParam({ target: "title", new_text: saasTitle, old_text: oldText, ...param });
  };

  const subTitleJudgeThenLogEvent = param => {
    const oldText = startEditingSubTitle.value;
    if (isEqual(oldText, saasSubTitle)) {
      return;
    }

    editTextLogEventWithParam({ target: "sub", new_text: saasSubTitle, old_text: oldText, ...param });
  };

  const editTextLogEventWithParam = param => {
    logEvent("click_edit_preview_text", { ...param });
  };

  const clickTitleStyleSave = styleData => {
    setShowEditTitle(false);
    props.setSaasUIData(prevData => {
      return Object.assign({}, prevData, {
        titleStyle: styleData,
        title: saasTitle,
        showTitle: showTitle,
        appDetail: {
          ...prevData.appDetail,
          name: saasTitle,
        },
      });
    });
  };
  // sub title
  const onSubTitleChange = e => {
    setSaasSubTitle(e.target.value);
  };

  const onSubTitlePressEnter = e => {
    e.preventDefault();
    setShowEditSubTitle(false);
    clickSubTitleStyleSave(saasSubTitleStyle);

    subTitleJudgeThenLogEvent();
  };

  const subtitleEdit = options => {
    if (showEditSubTitle) {
      return;
    }
    setShowSubTitle(true);
    setShowEditSubTitle(true);
    if (!func.isEmpty(subTitleRef.current)) {
      subTitleRef.current.focus(options);
    }

    // if (!(isMobile && featureTags.disableMobilePage)) {
    onPreviewItemMouseEnter({
      type: "description",
      container: titleSubRef.current,
      edit: clickSubTitleEdit,
      remove: clickSubCloseTitle,
    });
    // }

    startEditingSubTitle.value = saasSubTitle;
    // subTitleClickTalkToAIBuilder();
  };
  const clickSubTitleEdit = () => {
    subtitleEdit({
      cursor: "end",
    });
  };
  const clickSubCloseTitle = () => {
    setSaasSubTitle(props.saasUIData?.subTitle || "");
    setShowEditSubTitle(false);
    props.setSaasUIData(prevData => {
      return Object.assign({}, prevData, { showSubTitle: false, subTitle: "" });
    });

    subTitleJudgeThenLogEvent({ delete: true });
  };
  const clickSubTitleStyleSave = styleData => {
    setShowEditSubTitle(false);
    props.setSaasUIData(prevData => {
      return Object.assign({}, prevData, {
        subTitleStyle: styleData,
        subTitle: saasSubTitle,
        showSubTitle: showSubTitle,
        appDetail: {
          ...prevData.appDetail,
          description: saasSubTitle,
        },
      });
    });
  };

  useEffect(() => {
    setSaasTitle(props.saasUIData?.title || "");
  }, [props.saasUIData?.title]);
  useEffect(() => {
    setSaasSubTitle(props.saasUIData?.subTitle || "");
  }, [props.saasUIData?.subTitle]);
  useEffect(() => {
    setShowTitle(func.isEmpty(props.saasUIData?.showTitle) ? true : props.saasUIData?.showTitle);
    setShowSubTitle(func.isEmpty(props.saasUIData?.showSubTitle) ? true : props.saasUIData?.showSubTitle);
  }, [props.saasUIData]);

  const titleRenderData = useRenderData({ type: "title" });
  const subtitleRenderData = useRenderData({ type: "subtitle" });

  return (
    <div className={style["saasHeadManage"]}>
      {/* {!props.showOutPut && props.saasLoading ? ( */}
      {!props.showSaasOutputList && props.saasLoading ? (
        <div className={style["saas-top"]}>
          <LoadingOutline />
        </div>
      ) : (
        <div className={style["saas-top"]}>
          <div
            ref={titleBoxRef}
            onClick={titleEdit}
            // onTouchStart={titleEditTouchStart}
            // onTouchEnd={touchEnd}
            onMouseEnter={() => {
              hoverTitle.value = true;
            }}
            onMouseLeave={e => {
              hoverTitle.value = false;
            }}
            className={className(style["title-box"])}
          >
            <div
              className={className(
                style["edit-box"],
                { [style["edit-box-mobile"]]: isMobile && featureTags.disableMobilePage },
                {
                  [style["border-highlight"]]: showEditTitle,
                },
                showEditTitle
                  ? isMobile && featureTags.disableMobilePage
                    ? [style["border-highlight-mobile"]]
                    : [style["border-highlight"]]
                  : {}
              )}
            ></div>
            <div className={hideAndDisplay(showTitle)}>
              <div style={titleRenderData.style} className={`${style["saas-title"]} ${hideAndDisplay(!showEditTitle)}`}>
                {!saasTitle && hoverTitle.value && !props.saasLoading ? (
                  <span className={style.hoverPlaceholder}>Click to edit title</span>
                ) : (
                  saasTitle
                )}
              </div>
              <Input.TextArea
                style={titleRenderData.style}
                ref={titleRef}
                className={`title-textarea ${style["saas-title"]} ${hideAndDisplay(showEditTitle)} ${
                  showEditTitle ? className(style["display-zIndex"], style["title-highlight"]) : null
                }`}
                value={saasTitle}
                onChange={onTitleChange}
                onPressEnter={onTitlePressEnter}
                onBlur={onTitlePressEnter}
                bordered={false}
                autoSize
              />
            </div>
          </div>

          <div
            ref={titleSubRef}
            onClick={subtitleEdit}
            onTouchStart={subtitleEditTouchStart}
            onTouchEnd={touchEnd}
            onMouseEnter={() => {
              hoverSubTitle.value = true;
            }}
            onMouseLeave={e => {
              hoverSubTitle.value = false;
            }}
            className={style["title-box"]}
          >
            <div
              className={className(
                style["edit-box"],
                { [style["edit-box-mobile"]]: isMobile && featureTags.disableMobilePage },
                showEditSubTitle
                  ? isMobile && featureTags.disableMobilePage
                    ? [style["border-highlight-mobile"]]
                    : [style["border-highlight"]]
                  : {}
              )}
            ></div>
            <div>
              <div
                style={subtitleRenderData.style}
                className={`${style["saas-subTitle"]} ${hideAndDisplay(!showEditSubTitle)}`}
              >
                {!saasSubTitle && hoverSubTitle.value ? (
                  <span className={style.hoverPlaceholder}>Click to edit Subtitle</span>
                ) : (
                  saasSubTitle
                )}
              </div>
              <Input.TextArea
                style={subtitleRenderData.style}
                ref={subTitleRef}
                className={`subtitle-textarea ${hideAndDisplay(showEditSubTitle)} ${
                  showEditSubTitle ? className(style["display-zIndex"], style["title-highlight"]) : null
                }`}
                value={saasSubTitle}
                onChange={onSubTitleChange}
                onPressEnter={onSubTitlePressEnter}
                onBlur={onSubTitlePressEnter}
                bordered={false}
                autoSize
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SaasHeadManage;
