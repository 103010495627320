import { last } from "lodash";
import { useEffect } from "react";
import { useCreation } from "ahooks";
import { GenUIBloc, GenUIProcess } from "./GenUIBloc";
import { CallerBotBloc } from "./CallerBotBloc";
import { Chatbox, ImagicaUrl } from "./components/Chatbox/Chatbox";
import { useComputed, useSignal } from "@preact/signals-react";
import { OnBoardingInputBloc } from "./components/OnboardingInput/OnBoardingInputBloc";
import { OnboardingInput } from "./components/OnboardingInput/OnboardingInput";
import { CotFlow } from "imagica-corekit/dist/cases/cot/CotFlow";
import { useEventbus } from "imagica-uikit/dist/hooks/useEventbus";
import { CotMsg } from "imagica-corekit/dist/base/msg/CotMsg";
import { Preview } from "./components/Preview/Preview";
import AutoResizablePanel from "../AutoResizablePanel/AutoResizablePanel";
import classNames from "classnames";
import styles from "./CallerBot.module.scss";

export type BlocType = CallerBotBloc | GenUIBloc;

type CallerBotProps = {
  showFlow: (flowData: ReturnType<CotFlow["getTemplate"]>) => Promise<void>;
};

export function CallerBot(props: CallerBotProps): JSX.Element {
  const showOnBoardingInput = useSignal(true);
  const isCallerBotAI = useSignal(false);

  const onBoardingInputBloc = new OnBoardingInputBloc([]);

  const callerBotBloc = useCreation(() => new CallerBotBloc(), []);
  const genUIBloc = useCreation(() => new GenUIBloc(props.showFlow), []);

  const bloc = useComputed(() => {
    if (isCallerBotAI.value) {
      return callerBotBloc;
    }
    return genUIBloc;
  }).value;

  const handleSetIsCallerBotAI = (e: boolean): void => {
    isCallerBotAI.value = e;
    showOnBoardingInput.value = false;
    const current = e ? callerBotBloc : genUIBloc;
    current.state.submitValue.value = onBoardingInputBloc.thinkValue.value;
    current.onPressEnter();
  };

  useEffect(() => {
    if (genUIBloc.prototypingBloc.state.message.value.length === 0) {
      genUIBloc.clear();
      return;
    }
    const lastMessge = last(genUIBloc.prototypingBloc.state.message.value);
    if (lastMessge && lastMessge.role == "assistant") {
      genUIBloc.updateOutput(lastMessge);
    }
  }, [genUIBloc.prototypingBloc.state.message.value]);

  useEventbus(CotMsg, () => {
    bloc.state.showPreview.value = false;
  });

  const chatPanelClassName = classNames(
    styles.callerBotPane,
    bloc.state.showPreview.value && styles.padding20,
    bloc.state.collapsed.value && styles.width500,
    bloc.state.showPreview.value === false && bloc.state.collapsed.value && styles.left0,
    bloc.state.buildEnd.value && styles.left0,
    bloc.state.buildEnd.value &&
      bloc.state.showPreview.value &&
      bloc.state.collapsed.value === false &&
      styles.padding20
  );

  return (
    <>
      {showOnBoardingInput.value === true && (
        <OnboardingInput
          setIsCallerBotAI={handleSetIsCallerBotAI}
          showOnBoardingInput={showOnBoardingInput.value}
          bloc={onBoardingInputBloc}
        />
      )}
      {showOnBoardingInput.value === false && (
        <>
          <AutoResizablePanel className={chatPanelClassName} position="top-right" data-testid="CallerBotPane">
            <MakeItRealLoadingPanel bloc={bloc} />
            <Chatbox bloc={bloc} />
          </AutoResizablePanel>
          {bloc.state.showPreview.value && (
            <AutoResizablePanel
              className={styles.callerBotPreviewPane}
              position="top-right"
              data-testid="CallerBotPreviewPane"
            >
              <Preview
                bloc={bloc}
                highlightComponent={bloc.state.answerIndex.value === 2 ? "callInstruction" : undefined}
              />
            </AutoResizablePanel>
          )}
        </>
      )}
    </>
  );
}

function MakeItRealLoadingPanel(props: { bloc: BlocType }): JSX.Element | null {
  if (
    props.bloc instanceof GenUIBloc === true &&
    props.bloc.state.submitLoading.value === true &&
    props.bloc.state.currentStep.value === GenUIProcess.Build
  ) {
    return (
      <div className={styles.makeItRealLoadingPanel}>
        <div className={styles.loadingInner}>
          <div className={styles.imagicaWrapper}>
            <video
              className={styles.imagica}
              loop
              autoPlay
              playsInline
              x5-playsinline="true"
              webkit-playsinline="true"
              style={{ zIndex: 10 }}
              src={ImagicaUrl}
            />
          </div>
          <div className={styles.buildingYourApp}>Building your app ...</div>
        </div>
      </div>
    );
  }

  return null;
}
