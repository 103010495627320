import classNames from "classnames";
import css from "./index.module.scss";
import { Link, Outlet } from "react-router-dom";
import { useCallback } from "react";
import { getIt } from "@uikit/getIt";
import { useCreation } from "ahooks";
import { signal } from "@preact/signals-react";
import { CurrentPlan } from "imagica-corekit/dist/cases/util/PayWallUtil";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import isBlank from "@sedan-utils/is-blank";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import LoadingOutline from "@views/components/LoadingOutline";
import { Skeleton } from "antd";
import { PageConstants } from "@uiview/pages/PageConstants";
import { logEvent } from "@uikit/service/amplitude";
import { PlansTableUtil } from "@uikit/planList/PlanTableUtil";

const subscriptionStore = getIt(SubscriptionStore);
class SubscriptionPageState {
  currentPlanSignal = signal<CurrentPlan>({
    itemId: "",
    name: "",
    isCancel: false,
    expiryDate: "",
    price: {
      amount: "",
      interval: "",
      interval_count: 0,
    },
    value: "",
  });
  getManagementStripeUrlLoading = signal<boolean>(false);
  currentPlansLoading = signal<boolean>(false);
}
class SubscriptionPageBloc {
  state = new SubscriptionPageState();
  brainClient = getIt(BrainClient);
  constructor() {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.state.currentPlansLoading.value = true;
    const currentPlan = await subscriptionStore.currentPlan();
    this.state.currentPlanSignal.value = PlansTableUtil.transformCurrentPlan(currentPlan);
    this.state.currentPlansLoading.value = false;
  }

  get currentplan(): CurrentPlan {
    return this.state.currentPlanSignal.value;
  }

  async openStripeManagementLink(): Promise<void> {
    if (this.state.getManagementStripeUrlLoading.value) return;
    const parmasData = {
      customer: this.currentplan.customer || "",
      return_url: window.location.href,
    };
    this.state.getManagementStripeUrlLoading.value = true;
    const res = await tryPromise(this.brainClient.payWall.updateSubscripber(parmasData));
    this.state.getManagementStripeUrlLoading.value = false;
    if (res.data?.data?.url) {
      logEvent("click", { target: "profile_manage_subscription" });
      window.open(res.data?.data?.url, "_blank");
    }
  }
}

export function SubscriptionPage() {
  const bloc = useCreation(() => new SubscriptionPageBloc(), []);
  const priceAmount = bloc.state.currentPlanSignal.value.price.amount;
  const interval = bloc.state.currentPlanSignal.value.price.interval;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onOpenLink = useCallback(() => bloc.openStripeManagementLink(), []);

  const planName = bloc.state.currentPlanSignal.value.name;
  const priceValue = priceAmount ? `$${priceAmount}` : "Free";
  const planLoading = bloc.state.currentPlansLoading.value;
  return (
    <div className={css.wrap}>
      <div className={css.subTitle}>
        <span>Current plan</span>
      </div>

      <div className={classNames(css.row, css.rowHead)}>
        <div className={css.rowInfo}>
          <div className={css.rowTitle}>
            {!planLoading ? <span>{planName}</span> : <Skeleton className={css.skeleton} active paragraph={false} />}
          </div>

          <div className={css.rowContent}>
            <div className={css.priceRow}>
              {!planLoading ? (
                <>
                  <span className={css.value}>{priceValue}</span>

                  {priceAmount && interval ? (
                    <span className={css.unit}>
                      <span className={css.split}>/</span>
                      {interval}
                    </span>
                  ) : null}
                </>
              ) : (
                <Skeleton className={css.skeleton} active paragraph={false} />
              )}
            </div>
          </div>
        </div>
        <div className={css.rowAction}>
          <Link to="upgrade_plans" className={css.seePlanButton} data-amplitude="profile_see_plans_button">
            <span data-amplitude="profile_see_plans_button">See plans</span>
          </Link>
        </div>
      </div>
      {bloc.state.currentPlanSignal.value.isCancel && !isBlank(bloc.state.currentPlanSignal.value.expiryDate) ? (
        <span className={classNames(css.cancelTip)}>
          {PageConstants.planCancelTip} {bloc.state.currentPlanSignal.value.expiryDate}
        </span>
      ) : null}
      {!isBlank(bloc.currentplan.customer) ? (
        <div className={classNames(css.row, css.rowBody, css.poninter)} onClick={onOpenLink}>
          {bloc.state.getManagementStripeUrlLoading.value ? (
            <div className={css.loadingBox}>
              <LoadingOutline />
            </div>
          ) : null}
          <div className={css.rowInfo}>
            <div className={css.rowTitle}>
              <span>Manage your current subscription</span>
            </div>

            <div className={css.rowContent}>
              <span>Review terms or manage your subscription from your Stripe account.</span>
            </div>
          </div>
          <div className={css.rowAction}>
            <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 10L18 2M18 2H13M18 2V7"
                  stroke="#12192B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 3H6.8C5.11984 3 4.27976 3 3.63803 3.32698C3.07354 3.6146 2.6146 4.07354 2.32698 4.63803C2 5.27976 2 6.11984 2 7.8V13.2C2 14.8802 2 15.7202 2.32698 16.362C2.6146 16.9265 3.07354 17.3854 3.63803 17.673C4.27976 18 5.11984 18 6.8 18H12.2C13.8802 18 14.7202 18 15.362 17.673C15.9265 17.3854 16.3854 16.9265 16.673 16.362C17 15.7202 17 14.8802 17 13.2V12"
                  stroke="#12192B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      ) : null}

      <Outlet></Outlet>
    </div>
  );
}
