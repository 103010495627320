"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FoodApi = void 0;
const Restaurant_1 = require("../api/foodTyped/Restaurant");
const Food_1 = require("../api/foodTyped/Food");
class FoodApi {
    constructor(httpRequest, locationManager) {
        this.httpRequest = httpRequest;
        this.locationManager = locationManager;
    }
    async getRestaurantList(param) {
        const { latitude: lat, longitude: lng } = await this.locationManager.getUserLocation();
        const restParam = {
            lat,
            lng,
            dish: 0,
            limit: 5,
            logo: 1,
            open_now: true,
            mode: "delivery",
            sorts: "default",
        };
        const result = await this.httpRequest
            .get("/be/svc-adapter/food/v2/search", Object.assign(restParam, param))
            .then(x => x.toModel(Restaurant_1.RestaurantResponseData, x => x.data.data));
        return result.data;
    }
    async getFoodList(param) {
        const { latitude: lat, longitude: lng } = await this.locationManager.getUserLocation();
        const restParam = {
            lat,
            lng,
            mode: "delivery",
            isCheckout: 0,
        };
        const result = await this.httpRequest
            .get(`/be/svc-adapter/food/detail`, Object.assign(restParam, param))
            .then(x => x.toModel(Food_1.FoodResponseData, x => x.data.data));
        return result.data;
    }
}
exports.FoodApi = FoodApi;
