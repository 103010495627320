"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureAccess = void 0;
const SyncStorage_1 = require("./SyncStorage");
const Extensions_1 = require("../cutil/Extensions");
class FeatureAccess {
    constructor(storage = new SyncStorage_1.EmptyStorage(), location = "") {
        this.storage = storage;
        this.location = location;
    }
    can(name) {
        let value = this.getParam(this.getKey(name));
        if (value === undefined) {
            value = this.storage.getItem(this.getKey(name)) || undefined;
        }
        return (0, Extensions_1.isBlank)(value) ? undefined : value.toString();
    }
    getKey(key) {
        return key;
    }
    getParam(key) {
        const url = new URL(this.location.replace("#", "_"));
        return url.searchParams.get(key) || undefined;
    }
}
exports.FeatureAccess = FeatureAccess;
