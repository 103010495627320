import isBlank from "@sedan-utils/is-blank";
import { getIt } from "@uikit/getIt";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";

export class ProjectNetDataState {
  // 存储上一次更新数据
  public lastUpdateData: PreviewApp.UISaasValueType[] = [];
  // 是否正在更新
  public isUpdating = false;

  // 复制一个新的状态
  copyWith(param: { updateData?: PreviewApp.UISaasValueType[]; hasUnmountUpdated?: boolean; isUpdating?: boolean }) {
    const newState = new ProjectNetDataState();
    newState.lastUpdateData = param.updateData || this.lastUpdateData;
    if (param.isUpdating !== undefined) {
      newState.isUpdating = param.isUpdating;
    }
    return newState;
  }

  /// 判断setting数据是否相同
  isEqualTolastUpdated(newDara: PreviewApp.UISaasValueType[]): boolean {
    if (newDara.length !== this.lastUpdateData.length) {
      return false;
    }
    for (let i = 0; i < newDara.length; i++) {
      const newValue = newDara[i];
      const lastItem = this.lastUpdateData.find(item => item.id === newDara[i].id);
      if (!lastItem) return false;
      if (JsonUtil.stringify(newValue) !== JsonUtil.stringify(lastItem)) {
        return false;
      }
    }
    return true;
  }
}

export class ProjectUpdateParam {
  constructor(public newApplistData: PreviewApp.UISaasValueType[], public selectedTemplate: any, public gql: any) {}
}

export class ProjectNetDataStore extends StoreBase<ProjectNetDataState> {
  /// 定时器
  public timer: NodeJS.Timeout | null = null;

  constructor(public meStore: MeStore) {
    super(() => {
      return new ProjectNetDataState();
    });
  }

  hasUnmount(): boolean {
    return this.timer == null;
  }

  /// 开启每过一分钟自动更新的计时器
  async beginAutoUpdate(useData: () => ProjectUpdateParam) {
    const usePermission = await this.meStore.hasUpdatePreviewDataPermission();
    if (!usePermission) return;
    this.stopAutoUpdate();
    this.timer = setInterval(() => {
      this.updateProjectData(useData());
    }, 60 * 1000);
  }
  /// 关闭计时器
  stopAutoUpdate(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  // 更新项目setting数据
  async updateProjectData(param: ProjectUpdateParam) {
    const chatOnboardingStore = getIt(ChatOnboardingStore);
    if (chatOnboardingStore.state.selectTemplateInModal === ChoosingFormat.AUTOIMAGICA) {
      return;
    }
    const usePermission = await this.meStore.hasUpdatePreviewDataPermission();
    if (!usePermission) return;
    if (isBlank(param.selectedTemplate.id)) return;
    // 如果数据相同不更新
    if (this.state.isEqualTolastUpdated(param.newApplistData)) return;
    const newState = this.state.copyWith({ updateData: param.newApplistData, isUpdating: true });
    this.emit(newState);
    const atts = Object.assign({}, param.selectedTemplate, {
      ...param.selectedTemplate,
      v3: {
        ...param.selectedTemplate.v3,
        UI: param.newApplistData,
      },
    });
    const newData = {
      name: "studio_project",
      attributes: atts,
    };
    const res = await tryPromise(param.gql.updateObject(parseInt(param.selectedTemplate.id), newData, ""));

    const data = res?.data;
    this.emit(this.state.copyWith({ isUpdating: false }));
    if (!data) {
      console.log("update project failed", res);
    } else {
      console.log("update project success");
    }
  }
}
