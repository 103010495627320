import { Signal, useSignal } from "@preact/signals-react";
import Thumbnail from "../../Thumbnail";
import { ProjectProps } from "../../type";
import style from "./TableItemCellStyle.module.scss";
import { useEffect, useRef } from "react";
import { DotsIcon } from "../assets/svg";
import { MobileProjectsViewUtils } from "../MobileProjectsViewUtils";
import { MoreActionView } from "../MoreActionView/MoreActionView";
import { LongPressDiv } from "../LongPress/LongPressDiv";

export function TableItemCell(props: ProjectProps): JSX.Element {
  const ref = useRef(null);

  const thumbnailUrl = useSignal("");

  const isDropDown = useSignal(false);
  const dropDownPostions: Signal<React.CSSProperties> = useSignal({});

  const handleClick = (): void => {
    props.onClick?.(props.project);
  };

  useEffect(() => {
    MobileProjectsViewUtils.setThumbnailUrl(props.project).then(res => {
      thumbnailUrl.value = res;
      props.project.thumbnailUrl = res;
    });
  }, [props.project]);

  function showMoreDrop(): void {
    const element = ref.current;
    if (element) {
      const rect1 = (element as HTMLDivElement).getBoundingClientRect();
      if (rect1.top < 130) {
        dropDownPostions.value = { marginTop: "100px" };
      } else {
        dropDownPostions.value = {};
      }
    }
    isDropDown.value = true;
  }

  const attr = props.project.attributesV2 || JSON.parse(props.project.attributes ?? "");

  return (
    <LongPressDiv
      ref={ref}
      disable={isDropDown.value}
      className={style.tableItemCell}
      onClick={handleClick}
      onLongPress={() => showMoreDrop()}
    >
      <div className={style.img}>
        {thumbnailUrl.value === "" ? <Thumbnail height={80} /> : <img src={thumbnailUrl.value} alt="thumbnail" />}
        <div className={style.imgLayer}></div>
      </div>
      <div className={style.name}>{attr?.name || "Untitled"}</div>
      {isDropDown.value && (
        <MoreActionView
          style={dropDownPostions.value}
          onHidenView={() => {
            isDropDown.value = false;
          }}
          onClickItem={type => {
            isDropDown.value = false;
            props.onDropdownClick?.({ type, project: props.project });
          }}
        />
      )}
      <div
        className={style.more}
        onClick={e => {
          showMoreDrop();
          e.stopPropagation();
        }}
      >
        <DotsIcon />
      </div>
    </LongPressDiv>
  );
}
