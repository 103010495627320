import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import { EdgeState } from "imagica-corekit/dist/base/storyV2/domain/StoryEdge";
import { MarkerType } from "reactflow";

export const AI_INPUT_PLACEHOLDER = "Create an AI that...";
export const AI_INPUT_PLACEHOLDER_MULTI_NODES = "Create an AI that…\nor drag to connect";
export const DEFALUT_PROJECT_NAME = "Untitled";
export const DEFAULT_ERROR_TEXT = "";
export const PROJECT_LOAD_SIZE = 20;
export const INPUT_DEFAULT_WIDTH = 140;
export const INPUT_MAX_WIDTH = 200;
export const OFFSET_DISTANCE = 300;
// export const FOT_INPUT = "Enter your app idea or try a starter template below"
export const FOT_INPUT =
  "Try to be specific when describing your AI app, e.g. when users ask for stock advice, the app generates 3 stock options with explanations and a stock price interface";
export const WEB_APP_DEFAULT_TITLE = "Untitled";
export const WEB_APP_DEFAULT_SUBTITLE = "Welcome! This is a generative AI web app built in Imagica. Give it a try!";

export const IMAGICA_NEW_PROJECT_ONBOARDING = "imagica_new_project_onboarding";

export const CUSTOM_FUNCTION_VARS_REG = /(\$\{currentSourceNodeValue\})|(\$\{brainAuthToken\})/g;
export const CUSTOM_FUNCTION_VARS_JS_REG = /\w*currentSourceNodeValue\w*|\w*brainAuthToken\w*/g;
export const GUIDE_NODE_PATTERN = /^guide/;

export const DIRECTIONHANDLE = {
  top: {
    source: "0top",
    target: "2bottom",
  },
  right: {
    source: "b",
    target: "a",
  },
  bottom: {
    source: "2bottom",
    target: "0top",
  },
  left: {
    source: "a",
    target: "b",
  },
};

export const IMAGE_TYPES = ["imageGen", "midjourney", "midjourneyV2", "imageSearch"];
export const ENABLE_SPLIT_TYPES = ["text", "inputTextarea"];
export const DISABLE_AUTO_RUN_LIST = ["SourceOfTruth"];

export const DEFAULT_LINE_STYLE = {
  strokeWidth: 2,
  stroke: "#B0B0B7",
};
export const COMPLETE_LINE_STYLE = {
  strokeWidth: 2,
  stroke: "#469BF8",
};
export const ERROR_LINE_STYLE = {
  strokeWidth: 2,
  stroke: "#DE1C22",
};
export const EDGE_STATE_CONFIG = {
  [EdgeState.IDLE]: {
    color: "#B0B0B7",
  },
  [EdgeState.COMPLETED]: {
    color: "#469BF8",
  },
  [EdgeState.ERROR]: {
    color: "#DE1C22",
  },
};
export const DEFAULT_MARKER_END = {
  type: MarkerType.ArrowClosed,
  width: 12,
  height: 8,
  color: "#B0B0B7",
};
export const TRANSPARENT_MARKER_END = {
  type: MarkerType.ArrowClosed,
  width: 12,
  height: 8,
  color: "transparent",
};
export const COMPLETE_MARKER_END = {
  type: MarkerType.ArrowClosed,
  width: 12,
  height: 8,
  color: "#469BF8",
};
export const ERROR_MARKER_END = {
  type: MarkerType.ArrowClosed,
  width: 12,
  height: 8,
  color: "#DE1C22",
};
export const IMAGE_GEN_SELECT_LIST = [
  {
    label: "Stock Prices",
    value: "showStockInterface",
  },
  {
    label: "DALL·E 2 ",
    value: "imageGen",
  },
  {
    label: "image_gen",
    value: "imageGen",
  },
];
export const HANDLES = [
  {
    direction: "top",
    position: "Top",
    types: [{ type: "source" }, { type: "target" }],
  },
  {
    direction: "right",
    position: "Right",
    types: [
      { type: "source", handleId: "b" },
      { type: "target", handleId: "b" },
    ],
  },
  {
    direction: "bottom",
    position: "Bottom",
    types: [{ type: "source" }, { type: "target" }],
  },
  {
    direction: "left",
    position: "Left",
    types: [
      { type: "source", handleId: "a" },
      { type: "target", handleId: "a" },
    ],
  },
];
export const HANDLES_V3 = [
  {
    direction: "right",
    position: "Right",
    types: [{ type: "source", handleId: "b" }],
  },
  {
    direction: "left",
    position: "Left",
    types: [{ type: "target", handleId: "a" }],
  },
];
export const STOCK_TYPES = ["showStockInterface", "stockInfo"];
/**
 * 组ID匹配正则
 * @deprecated `CreatorNodesConsts.GROUPID_REG`
 */
export const GROUPID_REG = CreatorNodesConsts.GROUPID_REG;
/**
 * 边ID匹配正则
 * @deprecated `CreatorNodesConsts.NEW_EDGE_REG`
 */
export const NEW_EDGE_REG = CreatorNodesConsts.NEW_EDGE_REG;
export const FUNCTION_REG = /^\//;

export const PROJECT_NAME_LENGTH = {
  max: 9999,
};

export const EDITORABLE_INPUT_TYPE = ["text", "inputTextarea"];

export const ENGINE_LIST = [
  {
    value: "gpt-3.5-turbo-16k",
    label: "GDTC-Finn-1",
    chat: true,
  },
  {
    value: "gpt-4",
    label: "GDTC-CogniWan-1",
    chat: true,
  },
  {
    value: "text-davinci-003",
    label: "GDTT-Erin2",
  },
  {
    value: "text-davinci-002",
    label: "GDTT-Erin1",
  },
  {
    value: "text-curie-001",
    label: "GDTT-Cosmos",
  },
  {
    value: "text-babbage-001",
    label: "GDTT-Bold",
  },
  {
    value: "text-ada-001",
    label: "GDTT-Angela",
  },
  // {
  //   value: 'davinci-instruct-beta',
  //   label: 'davinci-instruct-beta',
  // },
  // {
  //   value: 'davinci',
  //   label: 'davinci',
  // },
  // {
  //   value: 'curie-instruct-beta',
  //   label: 'curie-instruct-beta',
  // },
  // {
  //   value: 'curie',
  //   label: 'curie',
  // },
];
