import { useCreation } from "ahooks";
import { InputNumber } from "antd";
import { useEffect } from "react";
import func from "@uikit/func";
import styles from "./Embed.module.scss";
import { EmbedBloc, EmbedBlocState } from "./EmbedBloc";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { PowerMaskView } from "@uiview/views/PowerMask/PowerMaskView";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { getIt } from "@uikit/getIt";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

const COPY_ICON = "https://dopniceu5am9m.cloudfront.net/static/assets/230705/copy.svg";
const promptText = "Publish to generate embed code.";
const promptCopy = "Copy this embed code and place it on your website.";

export function Embed(): JSX.Element {
  const state = new EmbedBlocState();
  const bloc = useCreation(() => new EmbedBloc(state), []);

  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const previewAppStore = getIt(PreviewAppStore);
  const { saasUIData } = creatorSaasAppState;

  const published_metadata = previewAppStore.getPublishMetadata();
  const disabledUnPublish = func.isEmpty(published_metadata?.link);
  const link = disabledUnPublish ? "" : bloc.getLink(published_metadata?.link || "");

  const htmlCode = `
      <iframe src="${link}" width="${bloc.state.widthInput.value}" height="${bloc.state.heightInput.value}"></iframe>
    `;

  useEffect(() => {
    if (!func.isEmpty(saasUIData?.settings?.width)) {
      bloc.state.widthInput.value = saasUIData?.settings?.width || 0;
    } else if (!bloc.state.widthFocus) {
      bloc.state.widthInput.value = EmbedBlocState.defaultWidth;
    }
  }, [saasUIData?.settings?.width]);

  useEffect(() => {
    if (!func.isEmpty(saasUIData?.settings?.height)) {
      bloc.state.heightInput.value = saasUIData?.settings?.height || 0;
    } else if (!bloc.state.heightFocus) {
      bloc.state.heightInput.value = EmbedBlocState.defaultHeight;
    }
  }, [saasUIData?.settings?.height]);

  useEffect(() => {
    creatorSaasAppStore.setSaasUIData((prev: any) => ({
      ...prev,
      settings: { ...prev.settings, width: bloc.state.widthInput.value },
    }));
  }, [bloc.state.widthInput.value]);

  useEffect(() => {
    creatorSaasAppStore.setSaasUIData((prev: any) => ({
      ...prev,
      settings: { ...prev.settings, height: bloc.state.heightInput.value },
    }));
  }, [bloc.state.heightInput.value]);

  return (
    <PowerMaskView feature={["embedded_view"]} featureItem="embedded_view">
      <div className={styles.embedBox}>
        <div className={styles.title}>Embed</div>

        <div className={styles.embedSizeBox}>
          <div className={styles.blackTitle}>Embed Size</div>

          <div className={styles.centerBox}>
            <div className={styles.inputBox}>
              <InputNumber
                className={styles.customNumberInput}
                min={1}
                max={9999}
                bordered={false}
                controls={false}
                value={bloc.state.widthInput.value}
                onChange={e => bloc.handleWidthInputChange(e)}
                onFocus={() => (bloc.state.widthFocus = true)}
                onBlur={() => (bloc.state.widthFocus = false)}
              />
              <div className={styles.addon}>width</div>
            </div>
            <div className={styles.connectorBox}>×</div>
            <div className={styles.inputBox}>
              <InputNumber
                className={styles.customNumberInput}
                min={1}
                max={9999}
                bordered={false}
                controls={false}
                value={bloc.state.heightInput.value}
                onChange={e => bloc.handleHeightInputChange(e)}
                onFocus={() => (bloc.state.heightFocus = true)}
                onBlur={() => (bloc.state.heightFocus = false)}
              />
              <div className={styles.addon}>height</div>
            </div>
          </div>
        </div>

        <div>
          {disabledUnPublish ? (
            <div className={styles.promptText}>{promptText}</div>
          ) : (
            <div>
              <div className={styles.tooltipText}>{promptCopy}</div>
              <div className={styles.generateBox}>
                <div className={styles.generateLink}>{htmlCode}</div>
                <img src={COPY_ICON} onClick={() => bloc.clickCopyText(htmlCode)} width="20" height="20" alt="copy" />
              </div>
            </div>
          )}
        </div>
      </div>
    </PowerMaskView>
  );
}
