"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseHandler = exports.TransformResponse = void 0;
const ErrorUtil_1 = require("../../../api/ErrorUtil");
const Meta_1 = require("../../../cutil/Meta");
const ResponseTransformError_1 = require("../error/ResponseTransformError");
const FunctionBase_1 = require("../../../storyV2/function/FunctionBase");
const GlobalClient_1 = require("../../../cutil/GlobalClient");
const WebsocketResponse_1 = require("../../../storyV2/model/WebsocketResponse");
const StoryNodeDisplayType_1 = require("../../../storyV2/domain/StoryNodeDisplayType");
class TransformResponse {
    constructor(result, isEnded) {
        this.result = result;
        this.isEnded = isEnded;
    }
}
exports.TransformResponse = TransformResponse;
class ResponseHandler {
    constructor(customApiTransformService) {
        this.customApiTransformService = customApiTransformService;
    }
    async handle(query, res) {
        if (res instanceof FunctionBase_1.FuncDataSource) {
            return this.handleFuncDataSource(query, res);
        }
        if (res instanceof GlobalClient_1.GlobalResponse) {
            return this.handleGlobalResponse(query, res);
        }
        if (res instanceof WebsocketResponse_1.WebsocketResponse) {
            return this.handleWebsocketResponse(query, res);
        }
        throw ResponseTransformError_1.ResponseTransformError.default;
    }
    async handleFuncDataSource(query, res) {
        return new TransformResponse(res.value, res.isEnded);
        // throw ResponseTransformError.default;
    }
    async handleGlobalResponse(query, res) {
        const result = await this.customApiTransformService.contentTransform(query, res);
        return new TransformResponse(result.result, result.isEnded);
    }
    async handleWebsocketResponse(query, res) {
        const result = await this.customApiTransformService.contentTransform(query, res);
        return new TransformResponse(result.result, result.isEnded);
    }
    getDisplayType(res) {
        if (res instanceof FunctionBase_1.FuncDataSource) {
            return this.getDisplayTypeByFuncDataSourceType(res.type);
        }
        return this.customApiTransformService.typeTransform(res);
    }
    getDisplayTypeByFuncDataSourceType(runResponseType) {
        switch (runResponseType) {
            case FunctionBase_1.FuncDataSourceType.ASK_BRAIN:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.ASKBRAIN;
            case FunctionBase_1.FuncDataSourceType.IMAGE_TEXT:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.IMAGEGEN;
            case FunctionBase_1.FuncDataSourceType.INTERVIEW_BOT:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.INTERVIEW_BOT;
            case FunctionBase_1.FuncDataSourceType.AMAZON_SHOPPING:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.AMAZONSHOPPING;
            case FunctionBase_1.FuncDataSourceType.WEEE_GROCERIES:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.WEEEGROCERIES;
            case FunctionBase_1.FuncDataSourceType.WEEE_SHOPPING:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.WEEESHOPPING;
            case FunctionBase_1.FuncDataSourceType.JD_SHOPPING:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.JDSHOPPING;
            case FunctionBase_1.FuncDataSourceType.MAP:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.MAP;
            case FunctionBase_1.FuncDataSourceType.FOOD:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.SHOWFOODINTERFACE;
            case FunctionBase_1.FuncDataSourceType.PROTEIN:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.CUSTOMJSFUNCTION;
            default:
                return StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT;
        }
    }
}
exports.ResponseHandler = ResponseHandler;
ResponseHandler.contentError = new Meta_1.Meta("ResponseHandler Error", ErrorUtil_1.ErrorUtil.defaultErrorMsg);
