"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AblyStore = exports.AblyStoreState = void 0;
const StoreBase_1 = require("../cutil/StoreBase");
class AblyStoreState {
    constructor() {
        this.ablyConnected = false;
    }
    copyWith(params) {
        const state = new AblyStoreState();
        state.ablyConnected = params.ablyConnected ?? this.ablyConnected;
        return state;
    }
}
exports.AblyStoreState = AblyStoreState;
class AblyStore extends StoreBase_1.StoreBase {
    constructor() {
        super(() => new AblyStoreState());
    }
    setAblyConnected(ablyConnected) {
        const newState = this.state.copyWith({ ablyConnected });
        this.emit(newState);
    }
    get ablyConnected() {
        return this.state.ablyConnected;
    }
}
exports.AblyStore = AblyStore;
