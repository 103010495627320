"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdapterErrorUtil = void 0;
const LangUtil_1 = require("../../cutil/LangUtil");
const ErrorUtil_1 = require("../../api/ErrorUtil");
const RestClient_1 = require("../../cutil/RestClient");
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const StreamClient_1 = require("../../cutil/StreamClient");
class AdapterErrorUtil {
    static getErrorMsg(data) {
        let error = data;
        if (data instanceof LangUtil_1.TryResult) {
            error = data.error;
        }
        else if (data instanceof FunctionBase_1.RunStreamResponseStreamResult) {
            error = data.error;
        }
        if (!error) {
            return AdapterErrorUtil.defaultErrorMessage;
        }
        let errorDescription = error.message;
        if (error instanceof RestClient_1.RestResponse) {
            errorDescription = error.errorDescription;
        }
        if (error instanceof StreamClient_1.StreamInternalError) {
            errorDescription = error.errorDescription;
        }
        if (error instanceof RestClient_1.RestInternalError) {
            errorDescription = error.errorDescription;
        }
        if (errorDescription === "Failed to fetch") {
            errorDescription = ErrorUtil_1.ErrorUtil.defaultErrorMsg;
        }
        if (errorDescription === "The user aborted a request.") {
            errorDescription = ErrorUtil_1.ErrorUtil.defaultErrorMsg;
        }
        return errorDescription || AdapterErrorUtil.defaultErrorMessage;
    }
}
exports.AdapterErrorUtil = AdapterErrorUtil;
AdapterErrorUtil.defaultErrorMessage = "Get content failed";
