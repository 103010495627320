import { UserIdentity } from "@uikit/util/userIdentity";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { ObjectRelationGqlService } from "./ObjectRelationGqlService";
import { isEmpty } from "lodash";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { logEvent } from "./amplitude";

/**
 * 原 `useUserAuth` 逻辑
 */
export class FotUserAuthService {
  constructor(
    public fotReduxStore: FotReduxStore,
    public meStore: MeStore,
    public gql: ObjectRelationGqlService,
    public homePluginStore: HomePluginStore,
    public homeStore: HomeStore
  ) {}

  private getSearchParams(originHref: string): URLSearchParams {
    return new URL(originHref.replace("#/", "")).searchParams;
  }

  init(): void {
    if (UserIdentity.hasGuest()) {
      this.fotReduxStore.setMeLoading(false);
    }
  }

  isSubscriber(): boolean {
    const subscriberParamsData = this.fotReduxStore.getState().editor.subscriberParamsData as Record<string, any>;
    const searchParams = this.getSearchParams(window.location.href);

    return subscriberParamsData?.attributes?.mode === "subscriber" || searchParams.get("mode") === "subscriber";
  }

  async fetchUserMe(): Promise<void> {
    try {
      this.fotReduxStore.setMeLoading(true);

      const res = await this.meStore.get();
      // throw "Failed to get user info";

      const meObj = res?.is_guest ? {} : await this.gql.me();
      const me = Object.assign({}, res, { objectId: meObj.objectId });
      if (isEmpty(me)) {
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get user info";
      }
      // 这里将class转为object后存入redux中, 避免redux报错
      this.fotReduxStore.setMe(JsonUtil.parse(JsonUtil.stringify(me)));
      const isShare = this.homePluginStore.state.isShare;
      const studioData = this.homePluginStore.state.studioData;

      this.homeStore.setFeatureTags(this.meStore.getAllFeatureTags());

      if (this.fotReduxStore.getState().editor.isClickSignup) {
        if (isShare) {
          // const result = await setSubscriberParamsByQ(shareQ);
          logEvent("webapp_signup", {
            guest_uuid: studioData?.guest_user_key,
            project_static_id: studioData?.attributes?.project_id,
          });
        } else {
          logEvent("signup");
        }
      }
      if (this.fotReduxStore.getState().editor.isClickLogin) {
        if (isShare) {
          // const result = await setSubscriberParamsByQ(shareQ);
          logEvent("webapp_login", {
            guest_uuid: studioData?.guest_user_key,
            project_static_id: studioData?.attributes?.project_id,
          }); //TODO: add event properties
        } else {
          logEvent("login"); //TODO: add event properties
        }
      }

      this.fotReduxStore.setMeLoading(false);
    } catch (error: any) {
      console.error("error:::", error);
      this.meStore.dirty();
      throw error;
    }
  }

  async getUserMe(): Promise<undefined> {
    // TODO: 当登录成功后，进入 homeUsePlugin 是否需要二次刷新 Me
    // if (!UserIdentity.hasGuest()) {
    // TAG: 912
    try {
      await this.fetchUserMe();
    } catch (error: any) {
      /// 在api.getWithError 中已经处理401 错误(进入登录页面) 这儿不再需要抛出错误
      if (error?.response?.status === 401) return;
      return Promise.reject(error);
    }
    // }
  }
}
