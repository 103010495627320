"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewService = void 0;
class PreviewService {
    constructor(titleService, linkService) {
        this.titleService = titleService;
        this.linkService = linkService;
    }
    async getTitle(input) {
        return await this.titleService.generateTitle(input);
    }
    async getLink(params) {
        return await this.linkService.generateLink(params);
    }
}
exports.PreviewService = PreviewService;
