"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterAdapter = void 0;
const AdapterRegistry_1 = require("../base/storyV2/adapter/AdapterRegistry");
const AdapterUtil_1 = require("../base/storyV2/adapter/AdapterUtil");
const ImageGenAdapter_1 = require("../base/storyV2/adapter/ImageGenAdapter");
const MidjourneyAdapter_1 = require("../base/storyV2/adapter/MidjourneyAdapter");
const SplitOutputAdapter_1 = require("../base/storyV2/adapter/SplitOutputAdapter");
const StockAdapter_1 = require("../base/storyV2/adapter/StockAdapter");
const AmazonShoppingAdapter_1 = require("../base/storyV2/adapter/AmazonShoppingAdapter");
const WeeeShoppingAdapter_1 = require("../base/storyV2/adapter/WeeeShoppingAdapter");
const JDShoppingAdapter_1 = require("../base/storyV2/adapter/JDShoppingAdapter");
const ChatInterfaceAdapter_1 = require("../base/storyV2/adapter/ChatInterfaceAdapter");
const MapAdapter_1 = require("../base/storyV2/adapter/MapAdapter");
const FoodAdapter_1 = require("../base/storyV2/adapter/FoodAdapter");
const YoutubeVideosAdapter_1 = require("../base/storyV2/adapter/YoutubeVideosAdapter");
const AddDataSourceAdapter_1 = require("../base/storyV2/adapter/AddDataSourceAdapter");
const ProteinAdapter_1 = require("../base/storyV2/adapter/ProteinAdapter");
const AskBrainAdapter_1 = require("../base/storyV2/adapter/AskBrainAdapter");
const CustomCodeAdapter_1 = require("../base/storyV2/adapter/CustomCodeAdapter");
const CustomApiAdapter_1 = require("../base/storyV2/adapter/CustomApiAdapter");
const StreamTextGenAdapter_1 = require("../base/storyV2/adapter/StreamTextGenAdapter");
const BluePrintAdapter_1 = require("../base/storyV2/adapter/BluePrintAdapter");
const InterviewBotAdapter_1 = require("../base/storyV2/adapter/InterviewBotAdapter");
const CustomFunctionAdapter_1 = require("../base/storyV2/adapter/CustomFunctionAdapter");
const FunctionRegistry_1 = require("../base/storyV2/function/FunctionRegistry");
const WeeeGroceriesAdapter_1 = require("../base/storyV2/adapter/WeeeGroceriesAdapter");
const FiberClothingAdapter_1 = require("../base/storyV2/adapter/FiberClothingAdapter");
const BrainClient_1 = require("../base/api/BrainClient");
class RegisterAdapter {
    static configure(container) {
        const it = (serviceIdentifier) => {
            return container.get(serviceIdentifier);
        };
        const funcRegistry = it(FunctionRegistry_1.FunctionRegistry);
        container.bind(SplitOutputAdapter_1.SplitOutputAdapter).toConstantValue(new SplitOutputAdapter_1.SplitOutputAdapter(funcRegistry));
        container.bind(StreamTextGenAdapter_1.StreamTextGenAdapter).toConstantValue(new StreamTextGenAdapter_1.StreamTextGenAdapter(funcRegistry));
        container.bind(ImageGenAdapter_1.ImageGenAdapter).toConstantValue(new ImageGenAdapter_1.ImageGenAdapter(funcRegistry));
        container.bind(StockAdapter_1.StockAdapter).toConstantValue(new StockAdapter_1.StockAdapter(funcRegistry));
        container.bind(YoutubeVideosAdapter_1.YoutubeVideosAdapter).toConstantValue(new YoutubeVideosAdapter_1.YoutubeVideosAdapter(funcRegistry));
        container.bind(AmazonShoppingAdapter_1.AmazonShoppingAdapter).toConstantValue(new AmazonShoppingAdapter_1.AmazonShoppingAdapter(funcRegistry));
        container.bind(JDShoppingAdapter_1.JDShoppingAdapter).toConstantValue(new JDShoppingAdapter_1.JDShoppingAdapter(funcRegistry));
        container.bind(ChatInterfaceAdapter_1.ChatInterfaceAdapter).toConstantValue(new ChatInterfaceAdapter_1.ChatInterfaceAdapter(funcRegistry));
        container.bind(WeeeShoppingAdapter_1.WeeeShoppingAdapter).toConstantValue(new WeeeShoppingAdapter_1.WeeeShoppingAdapter(funcRegistry));
        container.bind(MapAdapter_1.MapAdapter).toConstantValue(new MapAdapter_1.MapAdapter(funcRegistry));
        container.bind(FoodAdapter_1.FoodAdapter).toConstantValue(new FoodAdapter_1.FoodAdapter(funcRegistry));
        container.bind(WeeeGroceriesAdapter_1.WeeeGroceriesAdapter).toConstantValue(new WeeeGroceriesAdapter_1.WeeeGroceriesAdapter(funcRegistry));
        container.bind(FiberClothingAdapter_1.FiberClothingAdapter).toConstantValue(new FiberClothingAdapter_1.FiberClothingAdapter(funcRegistry));
        container.bind(CustomCodeAdapter_1.CustomCodeAdapter).toConstantValue(new CustomCodeAdapter_1.CustomCodeAdapter(funcRegistry));
        container.bind(CustomFunctionAdapter_1.CustomFunctionAdapter).toConstantValue(new CustomFunctionAdapter_1.CustomFunctionAdapter(funcRegistry));
        container.bind(AddDataSourceAdapter_1.AddDataSourceAdapter).toConstantValue(new AddDataSourceAdapter_1.AddDataSourceAdapter(funcRegistry));
        container.bind(ProteinAdapter_1.ProteinAdapter).toConstantValue(new ProteinAdapter_1.ProteinAdapter(funcRegistry));
        container.bind(AskBrainAdapter_1.AskBrainAdapter).toConstantValue(new AskBrainAdapter_1.AskBrainAdapter(funcRegistry));
        container.bind(MidjourneyAdapter_1.MidjourneyAdapter).toConstantValue(new MidjourneyAdapter_1.MidjourneyAdapter(funcRegistry));
        container.bind(BluePrintAdapter_1.BluePrintAdapter).toConstantValue(new BluePrintAdapter_1.BluePrintAdapter(funcRegistry, it(BrainClient_1.BrainClient)));
        container.bind(InterviewBotAdapter_1.InterviewBotAdapter).toConstantValue(new InterviewBotAdapter_1.InterviewBotAdapter(funcRegistry));
        const adapterRegistry = it(AdapterRegistry_1.AdapterRegistry);
        AdapterUtil_1.AdapterUtil.allImgAdapter().forEach(adapterName => {
            adapterRegistry.register(it(ImageGenAdapter_1.ImageGenAdapter), adapterName);
        });
        adapterRegistry.register(it(MidjourneyAdapter_1.MidjourneyAdapter), AdapterRegistry_1.AdapterName.midjourney);
        adapterRegistry.register(it(SplitOutputAdapter_1.SplitOutputAdapter), AdapterRegistry_1.AdapterName.splitOutput);
        adapterRegistry.register(it(StockAdapter_1.StockAdapter), AdapterRegistry_1.AdapterName.stockPrices);
        adapterRegistry.register(it(AmazonShoppingAdapter_1.AmazonShoppingAdapter), AdapterRegistry_1.AdapterName.amazonShoppingOld);
        adapterRegistry.register(it(WeeeShoppingAdapter_1.WeeeShoppingAdapter), AdapterRegistry_1.AdapterName.weeeShoppingOld);
        adapterRegistry.register(it(JDShoppingAdapter_1.JDShoppingAdapter), AdapterRegistry_1.AdapterName.jdShopping);
        adapterRegistry.register(it(ChatInterfaceAdapter_1.ChatInterfaceAdapter), AdapterRegistry_1.AdapterName.chatInterface);
        adapterRegistry.register(it(MapAdapter_1.MapAdapter), AdapterRegistry_1.AdapterName.maps);
        adapterRegistry.register(it(FoodAdapter_1.FoodAdapter), AdapterRegistry_1.AdapterName.food);
        adapterRegistry.register(it(YoutubeVideosAdapter_1.YoutubeVideosAdapter), AdapterRegistry_1.AdapterName.video);
        adapterRegistry.register(it(AddDataSourceAdapter_1.AddDataSourceAdapter), AdapterRegistry_1.AdapterName.addDataSource);
        adapterRegistry.register(it(ProteinAdapter_1.ProteinAdapter), AdapterRegistry_1.AdapterName.protein);
        adapterRegistry.register(it(AskBrainAdapter_1.AskBrainAdapter), AdapterRegistry_1.AdapterName.askImagica);
        adapterRegistry.register(it(CustomCodeAdapter_1.CustomCodeAdapter), AdapterRegistry_1.AdapterName.customCode);
        adapterRegistry.register(it(StreamTextGenAdapter_1.StreamTextGenAdapter), AdapterRegistry_1.AdapterName.identifier);
        adapterRegistry.register(it(BluePrintAdapter_1.BluePrintAdapter), AdapterRegistry_1.AdapterName.blue_print);
        adapterRegistry.register(it(InterviewBotAdapter_1.InterviewBotAdapter), AdapterRegistry_1.AdapterName.interviewBot);
        // v2
        adapterRegistry.register(it(StockAdapter_1.StockAdapter), AdapterRegistry_1.AdapterName.stockValue);
        adapterRegistry.register(it(AddDataSourceAdapter_1.AddDataSourceAdapter), AdapterRegistry_1.AdapterName.addDataSourceValue);
        adapterRegistry.register(it(ImageGenAdapter_1.ImageGenAdapter), AdapterRegistry_1.AdapterName.dallE2Value);
        adapterRegistry.register(it(ImageGenAdapter_1.ImageGenAdapter), AdapterRegistry_1.AdapterName.imageSearchValue);
        adapterRegistry.register(it(ProteinAdapter_1.ProteinAdapter), AdapterRegistry_1.AdapterName.proteinValue);
        adapterRegistry.register(it(YoutubeVideosAdapter_1.YoutubeVideosAdapter), AdapterRegistry_1.AdapterName.videoValue);
        adapterRegistry.register(it(MapAdapter_1.MapAdapter), AdapterRegistry_1.AdapterName.mapValue);
        adapterRegistry.register(it(JDShoppingAdapter_1.JDShoppingAdapter), AdapterRegistry_1.AdapterName.jdShoppingValue);
        adapterRegistry.register(it(AmazonShoppingAdapter_1.AmazonShoppingAdapter), AdapterRegistry_1.AdapterName.amazonShoppingOldValue);
        adapterRegistry.register(it(WeeeShoppingAdapter_1.WeeeShoppingAdapter), AdapterRegistry_1.AdapterName.weeeShoppingOldValue);
        adapterRegistry.register(it(SplitOutputAdapter_1.SplitOutputAdapter), AdapterRegistry_1.AdapterName.splitOutputValue);
        adapterRegistry.register(it(AskBrainAdapter_1.AskBrainAdapter), AdapterRegistry_1.AdapterName.askImagicaValue);
        adapterRegistry.register(it(FoodAdapter_1.FoodAdapter), AdapterRegistry_1.AdapterName.showFoodInterfaceValue);
        adapterRegistry.register(it(MidjourneyAdapter_1.MidjourneyAdapter), AdapterRegistry_1.AdapterName.midjourneyValue);
        adapterRegistry.register(it(ChatInterfaceAdapter_1.ChatInterfaceAdapter), AdapterRegistry_1.AdapterName.chatInterfaceValue);
        adapterRegistry.register(it(InterviewBotAdapter_1.InterviewBotAdapter), AdapterRegistry_1.AdapterName.interviewBotValue);
        adapterRegistry.registConstructor(() => new CustomFunctionAdapter_1.CustomFunctionAdapter(it(FunctionRegistry_1.FunctionRegistry)), AdapterRegistry_1.AdapterName.customFunction);
        adapterRegistry.registConstructor(() => new CustomApiAdapter_1.CustomApiAdapter(it(FunctionRegistry_1.FunctionRegistry)), AdapterRegistry_1.AdapterName.customApi);
    }
}
exports.RegisterAdapter = RegisterAdapter;
