import { ConnectionLineComponentProps, getSmoothStepPath, Position } from "reactflow";
import style from "./CustomNewConnectionLine.module.scss";

export function CustomNewConnectionLine({ fromX, fromY, toX, toY, fromPosition }: ConnectionLineComponentProps) {
  const targetPositionObj = {
    left: {
      position: Position.Right,
      targetX: toX + 15,
      targetY: toY,
    },
    right: {
      position: Position.Left,
      targetX: toX - 15,
      targetY: toY,
    },
    top: {
      position: Position.Bottom,
      targetX: toX,
      targetY: toY + 15,
    },
    bottom: {
      position: Position.Top,
      targetX: toX,
      targetY: toY - 15,
    },
  };

  const [edgePath] = getSmoothStepPath({
    sourceX: fromX,
    sourceY: fromY,
    sourcePosition: fromPosition,
    targetX: targetPositionObj[fromPosition]?.targetX,
    targetY: targetPositionObj[fromPosition]?.targetY,
    targetPosition: targetPositionObj[fromPosition]?.position,
  });

  return (
    <g>
      {/* 虚线 */}
      <path
        className={style["animated-path"]}
        fill="none"
        stroke="#0A9DFF"
        strokeWidth={2}
        d={edgePath}
        strokeDasharray="5"
        // d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle cx={fromX} cy={fromY} fill="#469BF8" r={3} />
      {/* 加号 */}
      <circle cx={toX} cy={toY} fill="rgba(10, 157, 255, 0.25)" r={15} />
      <path
        d={`M${toX} ${toY - 7.5}v15M${toX - 7.5} ${toY}h15`}
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
}
