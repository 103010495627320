import { getIt } from "@uikit/getIt";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { OFFSET_DISTANCE } from "@views/thinking-layout-editor/constants";
import { Node } from "reactflow";

export class CanvasEdgeUtil {
  static generateNewEdge(sourceNode: any): Node {
    const canvasDataRef = getIt(CanvasDataRef);
    const newEdgeId = `new-edge-${creatorRefStore.nodeIndexRef.current++}`;
    return {
      id: newEdgeId,
      type: "customNewEdge",
      zIndex: 20000,
      data: {
        ...canvasDataRef.newEdgeDataRef.current,
        ...sourceNode,
        flows: [
          {
            sourceNodeId: sourceNode.id,
            sourceLineId: "",
            creationMethod: true,
          },
        ],
      },
      position: {
        x: sourceNode.xPos + OFFSET_DISTANCE,
        y: sourceNode.yPos,
      },
    };
  }

  static createNewEdge = (sourceNode: any): Node => {
    const creatorNodesStore = getIt(CreatorNodesStore);
    const newEdge = CanvasEdgeUtil.generateNewEdge(sourceNode);

    creatorNodesStore.setNodes((prevList: any[]) => [...prevList, newEdge]);
    return newEdge;
  };
}
