import { FotUIDependenciesInterface } from "@uikit/infra/FotUIDependenciesInterface";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { HomeToolBarStore } from "@uikit/store/homeToolBarStore";
import {
  Node,
  DeleteElementsOptions,
  ReactFlowInstance,
  SetCenterOptions,
  useStoreApi,
  Viewport,
  ViewportHelperFunctionOptions,
  ReactFlowState,
} from "reactflow";

type FotReactFlowDep = {
  /**
   * 依赖ui层的 reactFlow 实例
   */
  reactFlowInstance?: ReactFlowInstance<any, any>;
  reactFlowStore?: ReturnType<typeof useStoreApi>;
};

/**
 * 用来替换 useReactFlow 调用，因为有大量逻辑仍依赖的 reactFlow, 为了方便拆分代码
 */
export class FotReactFlow implements FotUIDependenciesInterface<FotReactFlowDep> {
  dependencies: FotReactFlowDep = {};

  constructor(public creatorNodesStore: CreatorNodesStore, public homeToolBarStore: HomeToolBarStore) {}

  get state(): ReactFlowState | undefined {
    return this.dependencies.reactFlowStore?.getState();
  }

  setDependencies(value: FotReactFlowDep): void {
    Object.assign(this.dependencies, value);
  }

  deleteNodeByIds(ids: string[]): void {
    // 将可能是组节点中的 childNode 移除
    this.creatorNodesStore.removeById(ids);

    // 删除 react-flow 的节点
    const nodeIds = ids.map(id => ({ id }));
    this.dependencies.reactFlowInstance?.deleteElements({ nodes: nodeIds });
  }

  deleteNodeById(id: string): void {
    // 将可能是组节点中的 childNode 移除
    this.creatorNodesStore.removeById(id);

    // 删除 react-flow 的节点
    this.dependencies.reactFlowInstance?.deleteElements({ nodes: [{ id }] });
  }

  zoomTo(zoomLevel: number, options?: ViewportHelperFunctionOptions): void {
    if (!this.homeToolBarStore.state.allowZoomOrPan) {
      return;
    }
    this.dependencies.reactFlowInstance?.zoomTo(zoomLevel, options);
  }

  /**
   * *****此处重写的方法都应该使用尖头函数，保证调用时this指向不改变****
   */

  /**
   * 该方法应替换为 creatorCanvasFocus 中的 focus
   *
   * 但是由于 fotReactFlow 被创建，creatorCanvasFocus 可能会重构优化
   *
   * @param x
   * @param y
   * @param options
   */
  setCenter = (x: number, y: number, options?: SetCenterOptions): void => {
    this.dependencies.reactFlowInstance?.setCenter(x, y, options);
  };

  getZoom = (): number => {
    return this.dependencies.reactFlowInstance?.getZoom() as number;
  };

  getNode = (id: string): Node | undefined => {
    return this.dependencies.reactFlowInstance?.getNode(id);
  };

  deleteElements = (data: DeleteElementsOptions): void => {
    return this.dependencies.reactFlowInstance?.deleteElements(data);
  };

  getReactFlowStore = (): ReturnType<typeof useStoreApi> | undefined => {
    return this.dependencies.reactFlowStore;
  };

  getViewport = (): Viewport => {
    return this.dependencies.reactFlowInstance?.getViewport() as Viewport;
  };
}
