export const HandleUpIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 11L10 7L6 11"
      stroke="#020C26"
      strokeOpacity="0.35"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const HandleDragIcon = (
  <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2.5" cy="2" r="2" fill="#020C26" fillOpacity="0.35" />
    <circle cx="2.5" cy="9" r="2" fill="#020C26" fillOpacity="0.35" />
    <circle cx="2.5" cy="16" r="2" fill="#020C26" fillOpacity="0.35" />
    <circle cx="9.5" cy="2" r="2" fill="#020C26" fillOpacity="0.35" />
    <circle cx="9.5" cy="9" r="2" fill="#020C26" fillOpacity="0.35" />
    <circle cx="9.5" cy="16" r="2" fill="#020C26" fillOpacity="0.35" />
  </svg>
);

export const HandleDownIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 9L10 13L6 9"
      stroke="#020C26"
      strokeOpacity="0.35"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
