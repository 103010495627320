import { Signal } from "@preact/signals-react";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { CotFlow } from "imagica-corekit/dist/cases/cot/CotFlow";
import { findLastIndex } from "lodash";
import { IdeationChatBloc, NewIdeation, PrototypingSortableComponent } from "./ChatBloc";
import { Constants, Role } from "./Constants";
import { JsonItem, OnboardingBloc, OnboardingState } from "./OnboardingBloc";
import { ProtypingComponent, ProtypingMessage } from "./types";
import { previewStore } from "@uiview/store/PreviewStore";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { GenUIProcess, Message } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboardingType";
import { logEvent } from "@uikit/service/amplitude";
import { DisableChatUtil } from "./DisableChatUtil";
import { OnboardingBuildRequestBody } from "imagica-corekit/dist/base/api/chatCompletionTyped/ChatCompletionsTyped";
import { DomUtil } from "imagica-uikit/dist/base/util/DomUtil";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";
import { CardDataManager } from "imagica-corekit/dist/cases/cardDataSearch/CardDataManager";
import { PreviewPaneStore } from "../PreviewPane/PreviewPaneStore";
import { CotStore } from "@uikit/store/CotStore";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { MultiRoundChatOnboardingService } from "@uikit/service/MultiRoundChatOnboardingService";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

class GenUIState extends OnboardingState {
  public submitValue = new Signal("");
  public answerIndex = new Signal(0);
  public buildEnd = new Signal(false);
  public collapsed = new Signal(false);
  public showPreview = new Signal(false);
  public selectedComponent = new Signal<PrototypingSortableComponent | null>(null);
  public isCallerBotBloc = new Signal(false);
  public process = new Signal(["Ideate", "Build"]);
  public outputs = new Signal<ProtypingComponent[]>([]);
  public graph = new Signal<string>();
  public graphstring = new Signal<string>();
  public buildWaiting = new Signal<boolean>(false);
  public actionLoading = new Signal<boolean>(false);
}

export type ComponentKey = keyof typeof Constants.componentConfig;

export class GenUIBloc extends OnboardingBloc {
  brainClient = getIt(BrainClient);
  cotFlow = getIt(CotFlow);
  viewbuilderStore = getIt(ViewbuilderStore);
  chatOnboardingStore: ChatOnboardingStore = getIt(ChatOnboardingStore);
  cardDataManager = getIt(CardDataManager);
  previewPaneStore = getIt(PreviewPaneStore);
  cotStore = getIt(CotStore);
  fotSaveService = getIt(FotSaveService);
  multiRoundChatOnboardingService = getIt(MultiRoundChatOnboardingService);
  creatorSaasAppStore = getIt(CreatorSaasAppStore);
  homeStore = getIt(HomeStore);

  state = new GenUIState();

  public ideationBloc = new IdeationChatBloc();

  private APP_BUILT_MSG: string =
    "I’ve built your app! Test it by following these steps:\n - Find the App Preview on the right\n - Type in your input(s)\n - Click the Submit button\n\nIf you’re happy with the app, try publishing it using the Publish button on the top right! You can always unpublish the app or publish a new version at any time.\n\nIf you’d like to make any additional changes, let me know!";

  private APP_UPDATED_MSG: string = "I've updated your app.";
  private isShiftEnter = new Signal(false);

  setSubmitValue(value: string): void {
    if (value === this.state.submitValue.value + "\n" && !this.isShiftEnter.value) {
      return;
    }
    this.state.submitValue.value = value;
    this.isShiftEnter.value = false;
  }

  updateMessage(latestMsg: string, role: Role): void {
    const onboardingState = this.chatOnboardingStore.state;
    this.chatOnboardingStore.setChatOnboardingState({
      messages: [
        ...onboardingState.messages,
        {
          content: latestMsg,
          role: role as unknown as string,
          talkBuilder: onboardingState.talkBuilder,
        },
      ],
    });
  }

  private addOrUpdateAssistantMsg(msg: string): void {
    const index = findLastIndex(this.chatOnboardingStore.state.messages);
    if ((this.chatOnboardingStore.state.messages[index]?.role as unknown as Role) === Role.user) {
      return this.updateMessage(msg, Role.assistant);
    }
    if (this.chatOnboardingStore.state.messages[index].content === msg) {
      return;
    }
    const newMsg = [...this.chatOnboardingStore.state.messages];
    newMsg[index].content = msg;
    this.chatOnboardingStore.setChatOnboardingState({
      messages: newMsg,
    });
  }

  async onPressEnter(e: any = null): Promise<void> {
    if (DisableChatUtil.isDisabled()) return;
    if (e?.shiftKey) {
      this.isShiftEnter.value = true;
      return;
    }
    if (func.isEmpty(this.state.submitValue.value)) {
      func.customMsg({
        content: "Message cannot be empty",
        type: "warning",
      });
      return;
    }
    if (this.state.submitLoading.value) {
      func.customMsg({
        content: "Please wait a moment",
        type: "warning",
      });
      return;
    }

    //  移动端回车后回到chat页
    this.previewPaneStore.setMobileChatStatus(true);
    previewStore.setAiBuildLoading(true);
    const submitValue = this.state.submitValue.value;
    this.state.submitValue.value = "";
    try {
      if (this.chatOnboardingStore.state.messages.length === 1) {
        const message = this.chatOnboardingStore.state.messages[0].content;
        logEvent("auto_imagica_chat_system_message", {
          message,
          words_count: DomUtil.getStringWordLength(message),
          talk_to_ai_builder_component: this.chatOnboardingStore.state?.talkBuilder?.content || "",
        });
      }
      this.updateMessage(submitValue, Role.user);
      logEvent("auto_imagica_chat_user_message", {
        message: submitValue,
        words_count: DomUtil.getStringWordLength(submitValue),
        talk_to_ai_builder_component: this.chatOnboardingStore.state?.talkBuilder?.content || "",
      });
      this.updateMessage("", Role.assistant);
      await this.ideation();
    } finally {
      this.ideationCleanUp();
    }
  }

  async handleRegenerate(): Promise<void> {
    if (this.state.submitLoading.value) return;
    try {
      this.modifyLastMessage("suggestion", []);
      this.removeEmptyContentMessage();
      await this.autoBuilding(true);
    } finally {
      this.ideationCleanUp();
    }
  }

  get buildAppButtonText(): string {
    return this.chatOnboardingStore.state.currentStep === GenUIProcess.Ideation ? "Make it real" : "Update app";
  }

  get functionalityCheckButtonText(): string {
    return "Continue";
  }

  handleClickBuildButton(): void {
    // prevent multiple clicks
    if (this.state.buildWaiting.value === true) {
      return;
    }
    // if ideation is completed and we have implementation value
    if (this.state.submitLoading.value === false) {
      logEvent("auto_imagica_click_action_button", { button_text: this.buildAppButtonText });
      this.autoBuilding();
    } else {
      this.state.buildWaiting.value = true;
    }
  }

  handleClickFunctionalityCheck(): void {
    if (this.state.buildWaiting.value === true) {
      return;
    }
    if (this.state.submitLoading.value === false) {
      logEvent("auto_imagica_click_action_button", { button_text: this.functionalityCheckButtonText });
      this.state.submitValue.value = "check if the functions are supported";
      this.onPressEnter();
    } else {
      this.state.buildWaiting.value = true;
    }
  }

  async ideationStream(): Promise<NewIdeation> {
    return await this.ideationBloc.transformAssistantMessage(
      this.getLastMessage(Role.user),
      (msg: string) => {
        const value = this.jsonItemRegex(JsonItem.response, msg);
        const showInterface = this.jsonBooleanRegex(JsonItem.show_interface, msg);
        const functionalityCheck = this.jsonBooleanRegex(JsonItem.check_function, msg);
        this.chatOnboardingStore.setChatOnboardingState({
          lastChatData: Object.assign({}, this.chatOnboardingStore.state.lastChatData, {
            show_interface: showInterface,
          }),
        });

        const message = this.chatOnboardingStore.state.messages;
        if (message?.length) {
          const lastMessage = message[message.length - 1];
          if (value && lastMessage.content === value) {
            this.state.actionLoading.value = true;
          }
        }
        if (showInterface) {
          this.chatOnboardingStore.setChatOnboardingState({ showMakeItReal: true });
        }
        if (functionalityCheck) {
          this.chatOnboardingStore.setChatOnboardingState({ showFunctionalityCheck: true });
        }
        if (value) {
          previewStore.setChatMessageLoading(false);
          this.addOrUpdateAssistantMsg(value);
        }
      },
      true
    );
  }

  async ideation(): Promise<void> {
    this.state.submitLoading.value = true;
    previewStore.setChatMessageLoading(true);

    this.chatOnboardingStore.setChatOnboardingState({ showMakeItReal: false });
    this.chatOnboardingStore.setChatOnboardingState({ showFunctionalityCheck: false });
    this.state.buildWaiting.value = false;
    this.state.actionLoading.value = false;

    const meStore = getIt(MeStore);
    const hasStreaming = await meStore.hasMultiRoundOnboardingChatStream();
    try {
      if (hasStreaming) {
        const newIdeation = await this.ideationStream();
        this.state.actionLoading.value = false;
        logEvent("auto_imagica_chat_system_message", {
          message: newIdeation.response,
          words_count: DomUtil.getStringWordLength(newIdeation.response),
          talk_to_ai_builder_component: this.chatOnboardingStore.state?.talkBuilder?.content || "",
        });
      } else {
        await this.ideationBloc.transformAssistantMessage(
          this.getLastMessage(Role.user),
          (msg: string) => {
            if (msg) {
              this.addOrUpdateAssistantMsg(msg);
              logEvent("auto_imagica_chat_system_message", {
                message: msg,
                words_count: DomUtil.getStringWordLength(msg),
                talk_to_ai_builder_component: this.chatOnboardingStore.state?.talkBuilder?.content || "",
              });
            }
          },
          false
        );
      }
    } catch (err) {
      console.error("failed to ideate", err);
      this.addOrUpdateAssistantMsg("");
      return;
    }
    this.modifyLastMessage("suggestion", this.chatOnboardingStore.state.lastChatData.choices);
    if (this.chatOnboardingStore.state.lastChatData.show_interface && !this.state.buildWaiting.value) {
      this.chatOnboardingStore.setChatOnboardingState({ showMakeItReal: true });
    }
  }

  async getAppInfo(): Promise<void> {
    const result = await tryPromise(
      this.brainClient.openAi.onboardingAppInfo({
        functions: this.chatOnboardingStore.state.lastChatData.functions || [],
        implementation: this.chatOnboardingStore.state.lastChatData.implementation || "",
      })
    );
    if (result?.data?.data) {
      const resultData = result.data.data;
      this.creatorSaasAppStore.setSaasUIData((prevData: any) => ({
        ...prevData,
        appDetail: resultData,
      }));
    }
  }

  // building结束后需要自动save一次
  save(): void {
    const time = +new Date();
    this.chatOnboardingStore.state.saveTime = time;
    this.chatOnboardingStore.state.updateTime = time;
    this.fotSaveService.handleSave({ background: true });
  }

  buildCleanUp(): void {
    previewStore.setPreviewStore({ loading: false });
    this.state.submitLoading.value = false;
    this.chatOnboardingStore.setChatOnboardingState({ showMakeItReal: false });
  }

  ideationCleanUp(): void {
    this.state.submitLoading.value = false;
    previewStore.setChatMessageLoading(false);
    previewStore.setAiBuildLoading(false);
    if (this.state.buildWaiting.value) {
      this.state.buildWaiting.value = false;
      if (this.chatOnboardingStore.state.showFunctionalityCheck) {
        this.handleClickFunctionalityCheck();
      } else {
        this.handleClickBuildButton();
      }
    }
  }

  getLastUserMessage(): string {
    const messages = this.chatOnboardingStore.state.messages.filter(msg => {
      return msg.role === Role.user && msg.content !== "Make it real";
    });
    return messages[messages.length - 1]?.content || "";
  }

  //  如果首次build失败了，则把title设置为Error，subtitle设置为空
  setErrorTitleAndSubtitle(): void {
    if (this.chatOnboardingStore.state.currentStep !== GenUIProcess.Build) {
      this.creatorSaasAppStore.setSaasUIData((prevData: any) => {
        return {
          ...prevData,
          title: "Error",
          subTitle: "",
        };
      });
    }
  }

  private getBuildParams(): OnboardingBuildRequestBody {
    const data = this.chatOnboardingStore.state.talkBuilder.id
      ? {
          selected_node_name: this.chatOnboardingStore.state.talkBuilder.content,
          selected_node_id: this.chatOnboardingStore.state.talkBuilder.id,
        }
      : {};
    return {
      ...data,
      user_message: this.getLastUserMessage(),
      implementation: this.chatOnboardingStore.state.lastChatData.implementation!,
      functions: this.chatOnboardingStore.state.lastChatData.functions!,
      graphstring: this.chatOnboardingStore.state.lastBuildData.graphstring,
    };
  }

  async onBoardingBuild(callback: () => void): Promise<void> {
    const data = this.getBuildParams();
    if (this.homeStore.state.featureTags.disableViewBuilderToolBoxIntegration === false) {
      const result = await tryPromise(this.cardDataManager.requestVBData(data));
      const handleError = (error: Error): void => {
        console.error("error building app", error);
        this.setErrorTitleAndSubtitle();
        this.addOrUpdateAssistantMsg("");
        this.buildCleanUp();
        return;
      };
      if (result.error) {
        handleError(result.error);
        return;
      }
      if (result.data?.data) {
        const viewBuilderGraph = result.data?.data;
        this.viewbuilderStore.init(viewBuilderGraph);
        this.multiRoundChatOnboardingService.generateViewBuilder(viewBuilderGraph);
        callback();
      } else {
        handleError(new Error(result.data?.message ?? ""));
      }
    } else {
      const result = await tryPromise(this.brainClient.openAi.onboardingBuild(data));

      if (result.error) {
        console.error("error building app", result.error);
        this.setErrorTitleAndSubtitle();
        this.addOrUpdateAssistantMsg("");
        this.buildCleanUp();
        return;
      }

      if (result.data?.data) {
        this.chatOnboardingStore.setChatOnboardingState({ lastBuildData: result.data.data });
        await this.multiRoundChatOnboardingService.genUIStartFlow({
          flow: result.data.data as any,
        });
        callback();
      }
    }
  }

  async autoBuilding(isRegenerate = false): Promise<void> {
    this.state.buildWaiting.value = false;
    if (this.chatOnboardingStore.state.currentStep === GenUIProcess.Ideation && !isRegenerate) {
      this.updateMessage("Make it real", Role.user);
    }
    this.updateMessage("Building...", Role.assistant);
    previewStore.setPreviewStore({ loading: true });
    this.state.submitLoading.value = true;
    this.chatOnboardingStore.setChatOnboardingState({ showMakeItReal: false });

    this.multiRoundChatOnboardingService.openPreviewPage();

    await this.getAppInfo();
    await this.onBoardingBuild(() => {
      const successMessage =
        this.chatOnboardingStore.state.currentStep === GenUIProcess.Ideation
          ? this.APP_BUILT_MSG
          : this.APP_UPDATED_MSG;
      this.addOrUpdateAssistantMsg(successMessage);
      logEvent("auto_imagica_chat_system_message", {
        message: successMessage,
        words_count: DomUtil.getStringWordLength(successMessage),
        talk_to_ai_builder_component: this.chatOnboardingStore.state?.talkBuilder?.content || "",
      });
      this.chatOnboardingStore.setChatOnboardingState({ currentStep: GenUIProcess.Build });
    });

    this.save();
    this.buildCleanUp();
  }

  handleSelected(component: PrototypingSortableComponent): void {
    const name = component.name.replace(/<|>/g, "");
    const old = this.state.selectedComponent.value;
    if (old && old.id === component.id) {
      this.state.selectedComponent.value = null;
      this.state.submitValue.value = "";
      return;
    }
    this.state.selectedComponent.value = component;
    this.state.submitValue.value = `[${name}]: `;
  }

  closeCallerBot(): void {
    this.cotStore.setMultiRoundChatOnboarding(false);
  }

  closePreview(): void {
    this.state.showPreview.value = false;
  }

  handleClickCollapse(): void {
    this.state.collapsed.value = !this.state.collapsed.value;
  }

  updateOutput(message: Message): void {
    const result = JsonUtil.toModelFromType(ProtypingMessage, message.content);
    if (result) {
      this.state.outputs.value = result.components.map(item => ({
        ...item,
        key: item.name.replace(/<|>/g, "") as ComponentKey,
      }));
    }
  }

  clear(): void {
    if (this.state.outputs.value.length === 0) return;
    this.state.outputs.value = [];
  }
}
