import { animated } from "@react-spring/web";
import { CSSProperties, JSX, useCallback, useEffect } from "react";
import { ApiStatuses, TemplateModel } from "@views/thinking-layout-editor/types";
import ApiProgress from "../ApiProgress";
import style from "../Project/ProjectListViewStyle.module.scss";
import { useCreation } from "ahooks";
import { TemplateListBloc } from "./TemplateListBloc";
import func from "@uikit/func";
import Loader from "../Loader";
import TemplateItem from "./TemplateItem";
import { getIt } from "@uikit/getIt";
import { FotClient } from "imagica-corekit/dist/base/api/FotClient";

export class TemplateListViewProps {
  templatesStyle: CSSProperties = {};
  viewOfTemplates: Record<string, any> = {};
  handleProjectTemplateClick: (value: any) => Promise<void> = async () => {};
}

export function TemplateListView(props: TemplateListViewProps): JSX.Element {
  const bloc = useCreation(() => new TemplateListBloc(), []);

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = useCallback(async () => {
    try {
      bloc.apiStatusOfTemplates.value = ApiStatuses.Pending;
      const brainClient: any = getIt(FotClient);
      const response = await brainClient.webConfigApi.fetchWebConfig();
      const newTemplates: TemplateModel[] = response.studios.templates;
      bloc.myTemplates.value = newTemplates;
      bloc.apiStatusOfTemplates.value = ApiStatuses.Fulfilled;
    } catch (e) {
      bloc.apiStatusOfTemplates.value = ApiStatuses.Rejected;
      func.customMsg({
        type: "error",
        content: "Something went wrong",
      });
    }
  }, []);

  const handleTemplateAddClick = useCallback(
    async (template: TemplateModel) => {
      bloc.loadingOfTemplate.value = template;
      await props.handleProjectTemplateClick(template);
      bloc.loadingOfTemplate.value = undefined;
    },
    [props.handleProjectTemplateClick]
  );

  const isTemplateLoading = useCallback(
    (template: TemplateModel) =>
      !!bloc.loadingOfTemplate.value && bloc.loadingOfTemplate.value.project_id === template.project_id,
    [bloc.loadingOfTemplate.value]
  );

  return (
    <>
      {bloc.screenLoader.value ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader full={true} screenLoader={bloc.screenLoader.value} />
        </div>
      ) : (
        <animated.div
          style={{
            ...props.templatesStyle,
            ...props.viewOfTemplates,
          }}
          className={style.view}
        >
          <div className={style.view__title}>Templates</div>
          <div className={style.view__content}>
            <ApiProgress status={bloc.apiStatusOfTemplates.value} full>
              {bloc.myTemplates.value.map((item, index) => (
                <TemplateItem
                  key={index}
                  template={item}
                  loading={isTemplateLoading(item)}
                  onClick={handleTemplateAddClick}
                  onAddClick={handleTemplateAddClick}
                />
              ))}
            </ApiProgress>
          </div>
        </animated.div>
      )}
    </>
  );
}
