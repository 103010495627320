import { Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import style from "./css/HeaderList.module.css";
import func from "@uikit/func";

const HeaderList = props => {
  const [rowSelection, setRowSelection] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "KEY",
      dataIndex: "name",
      width: "30%",
      ellipsis: true,
      render: (value, record) => (
        <input
          type="text"
          value={value}
          readOnly={props.isResponse}
          onChange={e => handleInputChange(e, record, "name")}
        />
      ),
    },
    {
      title: "VALUE",
      dataIndex: "value",
      width: "30%",
      ellipsis: true,
      render: (value, record) => (
        <input
          type="text"
          value={value}
          readOnly={props.isResponse}
          onChange={e => handleInputChange(e, record, "value")}
        />
      ),
    },
  ]);

  const handleDelete = key => {
    const newData = [...props.headerList];
    props.setHeaderList(newData.filter(item => item.key !== key));
  };
  function handleInputChange(e, record, keyStr) {
    const { key } = record;
    props.setHeaderList(prevList => {
      return prevList.map(x => {
        if (x.key === key) {
          return {
            ...x,
            [keyStr]: e.target.value,
          };
        }
        return x;
      });
    });
  }
  function handleSelectChange(keys) {
    props.setHeaderList(prevList => {
      return prevList.map(x => {
        if (keys.includes(x.key)) {
          return {
            ...x,
            selected: true,
          };
        }
        return {
          ...x,
          selected: false,
        };
      });
    });
  }
  function clickHeaderAddBtn() {
    props.setHeaderList(prevList => [
      ...prevList,
      {
        key: `${prevList.length - 1}_manualAdd`,
        name: "",
        value: "",
        description: "",
        selected: true,
      },
    ]);
  }
  function Footer() {
    return !props.isResponse ? (
      <div className={style["footer-box"]}>
        <PlusCircleOutlined onClick={clickHeaderAddBtn} />
      </div>
    ) : null;
  }

  useEffect(() => {
    setSelectedRows(props.headerList.filter(x => x.selected).map(x => x.key));

    if (!props.isResponse) {
      const otherColumns = [
        {
          title: "DESCRIPTION",
          dataIndex: "description",
          width: "30%",
          ellipsis: true,
          render: (value, record) => (
            <input type="text" value={value} onChange={e => handleInputChange(e, record, "description")} />
          ),
        },
        {
          title: "",
          dataIndex: "operations",
          width: "50px",
          render: (_, record) => <DeleteOutlined onClick={() => handleDelete(record.key)} />,
        },
      ];

      setColumns(prevList => {
        // 需要先过滤掉以前的旧数据（函数中存在以前的数据状态），再重新添加
        const filteredPrevList = prevList.filter(x => !otherColumns.some(y => y.dataIndex === x.dataIndex));
        return [...filteredPrevList, ...otherColumns];
      });
    }
  }, [props.headerList]);

  useEffect(() => {
    if (props.isResponse) {
      setRowSelection(false);
      return;
    }
    setRowSelection({
      selectedRowKeys: selectedRows,
      onChange: handleSelectChange,
    });
  }, []);

  return (
    <Table
      className={style["header-table"]}
      columns={columns}
      dataSource={props.headerList}
      rowSelection={
        !props.isResponse
          ? {
              selectedRowKeys: selectedRows,
              onChange: handleSelectChange,
            }
          : false
      }
      pagination={false}
      footer={Footer}
    />
  );
};

export default HeaderList;
