import { first, get, has, isArray, isEmpty, isEqual, isString } from "lodash";
import {
  PREVIEW_APP_ID_PATH,
  PREVIEW_APP_METADATA_V2_PATH,
  PREVIEW_CHATAI_PUBLISH_INPUT_TYPES,
  PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES,
  PreviewAppTypeMap,
} from "./const";
import { isOnlyChatInterface } from "@views/thinking-layout-editor/util";
import { StoryNodeDisplayType } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeDisplayType";
import { Node } from "reactflow";
import { PreviewAppValue } from "./model/PreviewAppValue";

export class PreviewAppValueLangUtil {
  static isV2UIValue(uiItem: PreviewApp.UISaasValueType): boolean {
    return has(uiItem, "id") && has(uiItem, "v2App");
  }

  static isChatAiApp(saasUiData: PreviewAppValue): boolean {
    return get(saasUiData, ["v2App", "type"]) === PreviewAppTypeMap.CHAT_AI;
  }

  /**
   * 判断saasuidata是否是 static app
   * @param saasUiData
   * @returns
   */
  static isStaticApp(saasUiData: PreviewAppValue): boolean {
    if (has(saasUiData, "v2App")) {
      return get(saasUiData, ["v2App", "type"]) === PreviewAppTypeMap.STATIC_WEB;
    }

    return saasUiData.staticMode;
  }

  /**
   * 判断是否是 previewV2 数据
   *
   * 目前只用用了 publish_metadata_v2 确认是否是 v2数据
   * @param project
   * @returns
   */
  static isV2PreviewProject(project: PreviewApp.ProjectType): boolean {
    const publishv2 = get(project, PREVIEW_APP_METADATA_V2_PATH);
    return has(project, PREVIEW_APP_ID_PATH) && isArray(publishv2);
  }

  /**
   * 判断是否是默认的 app value
   *
   * @param app
   * @returns
   */
  static isDefaultPreviewApp(app?: PreviewAppValue): boolean {
    // FIXME:
    return app ? isEqual(new PreviewAppValue(), app) : true;
  }

  static checkInputLengthAndType(input: Node[], types: string[], length = 1): boolean {
    if (isArray(input) && input.length !== length) {
      return false;
    }
    const displayType = first(input)?.data.displayType;
    return isString(displayType) ? types.includes(displayType) : false;
  }

  static getChatAiMode(args: PreviewApp.GetChatAiModeArgs): PreviewApp.ChatAppModeType | undefined {
    const { input, output } = args.saasUIData;

    const hasOutput = !isEmpty(output);
    const hasInput = !isEmpty(input);

    // 有输出/有输入
    // 2.1 只有输出, 只有一个 chatinterface 可发布
    if (hasOutput && !hasInput && isOnlyChatInterface(output)) {
      return "chat";
    }

    // 2.2 只有输入
    if (
      hasInput &&
      !hasOutput &&
      PreviewAppValueLangUtil.checkInputLengthAndType(
        input,
        PREVIEW_CHATAI_PUBLISH_INPUT_TYPES.concat(PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES)
      )
    ) {
      if (PreviewAppValueLangUtil.checkInputLengthAndType(input, PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES)) {
        return "chat";
      }

      if (PreviewAppValueLangUtil.checkInputLengthAndType(input, [StoryNodeDisplayType.TEXT])) {
        return "text";
      }
      return "upload";
    }

    // 2.3 有输出 && 有输入, 只有一个 text/upload 作为输入, chatinterface 作为输出 可发布
    if (hasInput && hasOutput) {
      if (PreviewAppValueLangUtil.checkInputLengthAndType(input, [StoryNodeDisplayType.TEXT])) {
        return "text";
      }
      if (PreviewAppValueLangUtil.checkInputLengthAndType(input, PREVIEW_CHATAI_PUBLISH_OUTPUT_TYPES)) {
        return "chat";
      }
      return "upload";
    }

    return;
  }

  static hasNodeIds(
    appList: PreviewApp.UISaasValueType[],
    saasUIData: PreviewApp.UISaasValueType,
    ids: string[]
  ): boolean {
    for (const app of appList) {
      if (PreviewAppValueLangUtil.isInApp(app, ids)) return true;
    }
    return PreviewAppValueLangUtil.isInApp(saasUIData, ids);
  }

  static isInApp(app: PreviewApp.UISaasValueType, ids: string[]): boolean {
    for (const input of app.input) {
      if (ids.includes(input.id)) {
        return true;
      }
    }

    for (const output of app.output) {
      for (const result of output.results) {
        if (ids.includes(result.nodeId)) {
          return true;
        }
      }
    }
    return false;
  }
}
