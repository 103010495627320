"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectStore = exports.ProjectStoreState = void 0;
const StoreBase_1 = require("../cutil/StoreBase");
class ProjectStoreState {
    constructor() {
        this.attrIndexer = "";
    }
    copyWith(params) {
        const state = new ProjectStoreState();
        state.attrIndexer = params.attrIndexer ?? this.attrIndexer;
        return state;
    }
}
exports.ProjectStoreState = ProjectStoreState;
class ProjectStore extends StoreBase_1.StoreBase {
    constructor() {
        super(() => new ProjectStoreState());
    }
    setAttrIndexer(attrIndexer) {
        const newState = this.state.copyWith({ attrIndexer });
        this.emit(newState);
    }
    get attrIndexer() {
        return this.state.attrIndexer;
    }
}
exports.ProjectStore = ProjectStore;
