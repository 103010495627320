import { autoSort } from "./autoSort";
import { getBottomOrRightPosition } from "./getBottomOrRightPosition";

function moveCursorToEnd(el) {
  const end = el?.value?.length || 0;
  el.setSelectionRange(end, end);
}

// 根据displayType判定textAreaValue不为string的情况下应该返回的字段
function getTextFromTextAreaValueByType(data) {
  // if(typeof data.textAreaValue === 'string') return data.textAreaValue

  if (data.displayType === "askBrain") {
    return data.textAreaValue.answer;
  }

  // todo: 还需要处理其他textAreaValue不为string的情况
  return data.textAreaValue;
}

function createIdByNodeType(type, id) {
  let nodeId = "";
  switch (type) {
    case "CustomNode":
      nodeId = `editor-${id}`;
      break;

    case "customNewEdge":
      nodeId = `new-edge-${id}`;
      break;

    case "customGroup":
      nodeId = `group-${id}`;
      break;

    default:
      nodeId = `editor-${id}`;
      break;
  }
  return nodeId;
}

function createIdByEdgeType(type, id) {
  let edgeId = "";
  switch (type) {
    case "customNewLine":
      edgeId = `new-line-${id}`;
      break;

    case "customEdge":
      edgeId = `edge-${id}`;
      break;

    default:
      edgeId = `edge-${id}`;
      break;
  }
  return edgeId;
}

export default {
  autoSort,
  getBottomOrRightPosition,
  getTextFromTextAreaValueByType,
  moveCursorToEnd,
  createIdByNodeType,
  createIdByEdgeType,
};
