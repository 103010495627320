"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CDNPathUtil = void 0;
const Settings_1 = require("../kernel/Settings");
class CDNPathUtil {
    static getFilePath(filename) {
        return `${Settings_1.settings.S3CDN}${Settings_1.settings.viewAssetsPath}${filename}`;
    }
    static getAssetsPath(path) {
        return `${Settings_1.settings.S3CDN}${Settings_1.settings.assets}${path}`;
    }
    static getIconAssetUrl(filename) {
        return CDNPathUtil.getAssetsPath(`/icons/${filename}`);
    }
    static getImageAssetUrl(filename) {
        return CDNPathUtil.getAssetsPath(`/images/${filename}`);
    }
}
exports.CDNPathUtil = CDNPathUtil;
