import { useHtmlIframe } from "imagica-uikit/dist/hooks/useHtmlIframeV1";
import style from "./css/ResponseContent.module.css";

export const initialCustomApiFuncSize = {
  width: 300,
  height: 350,
};

export default function ResponseContent(props) {
  const [iframeRef] = useHtmlIframe({
    htmlStr: props.responseData?.data?.value || "",
    deps: [props.responseData?.data?.type, props.responseData?.data?.value],
  });

  return (
    <div className={`${style["white-frame"]}`}>
      <div className={style["image-box"]}>
        <div className={style["image-content"]}>
          <div
            className={`${style["custom-api"]} ${props.isResized ? "" : style["default-resize"]} ${
              props.displayLabel ? "" : style["adaptive-width"]
            } ${props.isSelected ? "nowheel nodrag" : ""}`}
          >
            {props.responseData?.data?.type === "json" || props.responseData?.data?.type === "text" ? (
              <div className={`${style["json-response"]}`}>
                {JSON.stringify(props.responseData?.data.value || "", null, 4)}
              </div>
            ) : props.responseData?.data?.type === "image" ? (
              <div className={`${style["img-response"]} ${style[props.className]}`}>
                <img src={props.responseData?.data.value} />
              </div>
            ) : props.responseData?.data?.type === "html" ? (
              <div className={style["iframe-response"]}>
                <iframe ref={iframeRef} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
