import { Spin } from "antd";
import React from "react";
import styles from "./BlueprintDetail.module.scss";

type LoadingProps = {
  children: React.ReactNode;
};

export function Loading(props: LoadingProps) {
  return (
    <div className={styles.loadingBlueprint}>
      <div className={styles.loadingText}>{props.children}</div>
      <Spin />
    </div>
  );
}
