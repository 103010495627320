"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileApi = void 0;
class FileApi {
    constructor(restClient, globalClient) {
        this.restClient = restClient;
        this.globalClient = globalClient;
    }
    /**
     * 将url 通过blob url形式返回
     * @param url
     */
    async getResponseText(url) {
        return this.restClient.get(url).then(response => {
            if (!response.data) {
                return Promise.reject();
            }
            const httpResponse = response.data;
            return httpResponse.text();
        });
    }
    async getGlobalResponseText(url, uuid = undefined) {
        return this.globalClient
            .request({
            path: url,
            method: "GET",
            data: undefined,
            queryParameters: undefined,
            contentType: undefined,
            uuid: undefined,
        })
            .then(response => {
            if (!response.response) {
                return Promise.reject();
            }
            return response.response.text();
        });
    }
}
exports.FileApi = FileApi;
