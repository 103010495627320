import { FC, useEffect, useRef } from "react";
import cls from "classnames";
import styleCss from "./style.module.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import lottie, { AnimationItem } from "lottie-web";
import loader from "./assets/GeneratingLoader.json";

export interface LoaderProps {
  full?: boolean;
  className?: string;
  screenLoader?: boolean;
  style?: React.CSSProperties | undefined;
}

const Loader: FC<LoaderProps> = ({ full, className, screenLoader, style }) => {
  const lottieRef = useRef(null);
  useEffect(() => {
    if (lottieRef.current) {
      let animation: AnimationItem = lottie.loadAnimation({
        animationData: loader,
        container: lottieRef.current,
      });

      return () => {
        animation.destroy();
      };
    }
  }, [lottieRef]);
  return (
    <div className={cls(styleCss.loader, full && styleCss.loader_full, className)} style={style}>
      {/* <Spin indicator={<LoadingOutlined spin />} /> */}
      {screenLoader ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <div ref={lottieRef} className={styleCss.lottieLoader} />
      )}
    </div>
  );
};

export default Loader;
