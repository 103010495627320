import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { isArray, isEmpty } from "lodash";
import { ProjectNode } from "./model";
import { ProjectNodeUtil } from "./ProjectNodeUtil";
import { CanvasNodeType } from "@uikit/store/CreatorNodesStore";
import { CreatorNodesUtil } from "@uikit/util/CreatorNodesUtil";
import { PreviewAppValue } from "@uiview/views/PreviewApp/model/PreviewAppValue";

export class ProjectNodeStoreState {
  /**
   * 该值初始值应该是 creatorNodes 和 sassUiData.input 合并数据
   */
  nodes: ProjectNode[] = [];

  copyWith(params: { nodes?: ProjectNode[] }): ProjectNodeStoreState {
    const state = new ProjectNodeStoreState();
    state.nodes = params.nodes ?? this.nodes;

    return state;
  }
}

/**
 * 该类用于存储现有逻辑中 canvas，preview，share 使用到的 nodes 公用数据
 *
 * 比如： nodes 中的 textAreaValue
 *
 * 在该类实现之前，textAreaValue 会以不同的类型保存在nodes中不同的节点里，preview渲染的 textAreaValue 是复制的 nodes 中的数据
 *
 * **但是 preview 使用的 textAreaValue 可能在 nodes 中不存在对应的节点，未来可能会数据 redux.selecteTemplate 抽象化**
 *
 * 该 store 会在每一次切换project的时候重置
 *
 * FIXME: 有个问题就是：
 * - 原来功能中， SaasUiData 只是详情数据，可能存在多个 SaasUiData.inpu, 在调用 syncSaasUIDataInput 的 *
会存在不能更新到 appList 的情况，需要测试
 *
 * 
 * TODO: 当公用数据不在被 creatorNodeStore 或 creatorSaasAppStore setter 时，这两个store中的字段需要被移除
 * @see https://brain-ai.atlassian.net/browse/STUD-2493
 * @see https://github.com/brain/micro-apps/pull/4881
 */
export class ProjectNodeStore extends StoreBase<ProjectNodeStoreState> {
  constructor() {
    super(() => new ProjectNodeStoreState());
  }

  /**
   * @param id
   * @param previewAppId
   * @returns
   */
  getNode(id: ProjectNode["id"], previewAppId?: string): ProjectNode | undefined {
    // FIXME: 是否考虑 preview app id？？？
    // previewAppId = previewAppId || this.previewStore.previewAppAcivieId
    if (previewAppId) {
      return this.state.nodes.find(node => node.appId === previewAppId && node.id === id);
    }

    return this.state.nodes.find(node => node.id === id);
  }

  insertNode(projectNode: ProjectNode): void {
    const nodes = [...this.state.nodes];
    nodes.push(projectNode);
    this.setNodes(nodes);
  }

  setNodes(nodes: ProjectNode[]): void {
    this.emit(this.state.copyWith({ nodes }));
  }

  /**
   * update
   * @param id
   * @param source
   */
  updateById(id: ProjectNode["id"], source: Partial<ProjectNode>): void {
    if (!isEmpty(source)) {
      const nodes = this.state.nodes.map(node => {
        if (node.id === id) {
          // 是否需要 merge???
          return Object.assign(new ProjectNode(), node, source);
        }
        return node;
      });

      this.setNodes(nodes);
    }
  }

  removeNodeByIds(id: ProjectNode["id"] | ProjectNode["id"][]): void {
    if (isArray(id)) {
      this.setNodes(this.state.nodes.filter(node => !id.includes(node.id)));
      return;
    }

    this.setNodes(this.state.nodes.filter(node => node.id !== id));
  }

  /**
   * 该方法用于组合 saasUIData.input 和 canvasnodes 数据
   *
   * @param nodes
   */
  composeNodes(canvasNodes?: CanvasNodeType[], saasUIData?: PreviewAppValue): void {
    canvasNodes = canvasNodes ? CreatorNodesUtil.getOnlyNodes(canvasNodes) : [];
    const compatibleNodes = saasUIData ? canvasNodes.concat(saasUIData.input) : canvasNodes;

    // FIXME: 参数二为 applist 准备这里是否需要考虑？？？
    const projectNodes = ProjectNodeUtil.compatibleProjectNodes(compatibleNodes, []);

    this.setNodes(projectNodes);
  }

  getNodes(): ProjectNode[] {
    return this.state.nodes;
  }

  resetNodes(nodes: ProjectNode[]): void {
    this.emit(this.state.copyWith({ nodes }));
  }
}
