import style from "./AutoImagicAlertStyle.module.scss";

type AutoImagicAlertProps = {
  onClose: () => void;
  onConfirm: () => void;
};

export function AutoImagicAlert(props: AutoImagicAlertProps): JSX.Element {
  return (
    <>
      <div className={style.back_layer}>
        <div className={style.content}>
          <div className={style.title}>Canvas is only available on Desktop</div>
          <div className={style.msg}>This project is only accessible via desktop.</div>
          <div className={style.button}>
            {/*<div className={style.cancel} onClick={() => props.onClose()}>*/}
            {/*  Cancel*/}
            {/*</div>*/}
            <div className={style.confirm} onClick={() => props.onConfirm()}>
              Confirm
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
