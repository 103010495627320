"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MidjourneyDistribution = void 0;
const StoryEdge_1 = require("../../../storyV2/domain/StoryEdge");
const StoryUtil_1 = require("../../../storyV2/domain/StoryUtil");
const StoryNodeType_1 = require("../../../storyV2/domain/StoryNodeType");
const StoryManagerUtil_1 = require("../../../storyV2/domain/StoryManagerUtil");
const StoryNodeOptions_1 = require("../../../storyV2/domain/StoryNodeOptions");
const StoryNodeDisplayType_1 = require("../../../storyV2/domain/StoryNodeDisplayType");
const StoryNode_1 = require("../../../storyV2/domain/StoryNode");
const StoryNodeState_1 = require("../../../storyV2/domain/StoryNodeState");
const FunctionBase_1 = require("../../../storyV2/function/FunctionBase");
const AdapterErrorUtil_1 = require("../../../storyV2/adapter/AdapterErrorUtil");
const StudioProjectAttributesV3NodeData_1 = require("../../../project/domain/StudioProjectAttributesV3NodeData");
const JsonUtil_1 = require("../../../cutil/JsonUtil");
const BaseDistribution_1 = require("../../../storyV2/response/responseDistribution/BaseDistribution");
class MidjourneyDistribution extends BaseDistribution_1.BaseDistribution {
    handleRunResponse(story, edge, res, relatedEdges, displayType) {
        const sourceNode = StoryUtil_1.StoryUtil.findEdgeSoure(story, edge);
        const isFromGroup = sourceNode?.options.type === StoryNodeType_1.StoryNodeType.CUSTOMGROUP;
        // 如果sourceNode 是一个组，那么需要特殊处理
        if (isFromGroup) {
            const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMGROUP, undefined);
            const newChildIdNumbers = StoryManagerUtil_1.StoryManagerUtil.idNumberForGroup(story, sourceNode.children.length, targetNode);
            const textNodeGroup = sourceNode.children.map((value, index) => {
                const nodeOptions = new StoryNodeOptions_1.StoryNodeOptions(`editor-${newChildIdNumbers.get(index)}`, undefined, StoryNodeType_1.StoryNodeType.CUSTOMNODE, StoryNodeDisplayType_1.StoryNodeDisplayType.MIDJOURNEYV2, targetNode.id);
                const node = new StoryNode_1.StoryNode(nodeOptions);
                node.state = StoryNodeState_1.StoryNodeState.RECEIVING;
                return node;
            });
            targetNode.options.children = textNodeGroup.map(node => node.options.id);
            /// 触发下一个节点的event，表示有子节点了，可以订阅子节点的event了
            StoryManagerUtil_1.StoryManagerUtil.cleanAndSetNewChildren(targetNode, textNodeGroup);
            targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
            StoryManagerUtil_1.StoryManagerUtil.updateLastEdgeOutnode(story, edge, targetNode);
            return new Promise(executor => {
                const streamResponse = res.data;
                let completionCount = 0;
                streamResponse.onReceived(result => {
                    result.value.forEach((value, key, map) => {
                        const childNode = targetNode.children[key];
                        if (childNode.state === StoryNodeState_1.StoryNodeState.COMPLETED) {
                            return;
                        }
                        childNode.state = StoryNodeState_1.StoryNodeState.RECEIVING;
                        if (value.error) {
                            const contentString = AdapterErrorUtil_1.AdapterErrorUtil.getErrorMsg(value);
                            childNode.state = StoryNodeState_1.StoryNodeState.ERROR;
                            childNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
                            completionCount += 1;
                            if (completionCount === textNodeGroup.length) {
                                const edgeState = StoryManagerUtil_1.StoryManagerUtil.checkGroupEdgeState(targetNode);
                                StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], edgeState, story);
                                executor();
                            }
                        }
                        else {
                            const stream = value.data;
                            stream?.onReceived(subStreamResult => {
                                if (subStreamResult.error) {
                                    const contentString = AdapterErrorUtil_1.AdapterErrorUtil.getErrorMsg(subStreamResult);
                                    childNode.state = StoryNodeState_1.StoryNodeState.ERROR;
                                    childNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
                                    completionCount += 1;
                                    if (completionCount === textNodeGroup.length) {
                                        const edgeState = StoryManagerUtil_1.StoryManagerUtil.checkGroupEdgeState(targetNode);
                                        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], edgeState, story);
                                        executor();
                                    }
                                }
                                else {
                                    let midjourneyValue = new StudioProjectAttributesV3NodeData_1.StudioProjectV3MidjourneyValue();
                                    if (subStreamResult.value instanceof StudioProjectAttributesV3NodeData_1.StudioProjectV3MidjourneyValue) {
                                        midjourneyValue = subStreamResult.value;
                                    }
                                    childNode.received(new StoryNode_1.StoryNodeEventObject(JsonUtil_1.JsonUtil.stringify(midjourneyValue) ?? ""));
                                    if (subStreamResult.completion) {
                                        childNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                                        completionCount += 1;
                                    }
                                    if (completionCount === textNodeGroup.length) {
                                        const edgeState = StoryManagerUtil_1.StoryManagerUtil.checkGroupEdgeState(targetNode);
                                        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], edgeState, story);
                                        executor();
                                    }
                                }
                            });
                        }
                    });
                });
            });
            return Promise.resolve();
        }
        // 不是一个组的情况
        const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMNODE, StoryNodeDisplayType_1.StoryNodeDisplayType.MIDJOURNEYV2);
        StoryManagerUtil_1.StoryManagerUtil.updateLastEdgeOutnode(story, edge, targetNode);
        let content = res.data;
        if (typeof content === "string") {
            const midjourneyData = JsonUtil_1.JsonUtil.stringify({ loading: true, value: content }) ?? "";
            targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
            targetNode.received(new StoryNode_1.StoryNodeEventObject(midjourneyData));
            StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
            return Promise.resolve();
        }
        else if (content instanceof FunctionBase_1.RunStreamResponse) {
            return new Promise(executor => {
                const streamResponse = content;
                streamResponse.onReceived(result => {
                    targetNode.state = StoryNodeState_1.StoryNodeState.RECEIVING;
                    if (result.error) {
                        let contentString = AdapterErrorUtil_1.AdapterErrorUtil.getErrorMsg(result.error);
                        if (contentString == undefined || contentString == "") {
                            contentString = AdapterErrorUtil_1.AdapterErrorUtil.defaultErrorMessage;
                        }
                        targetNode.state = StoryNodeState_1.StoryNodeState.ERROR;
                        targetNode.received(new StoryNode_1.StoryNodeEventObject(contentString));
                        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.ERROR, story);
                        executor();
                        return;
                    }
                    targetNode.received(new StoryNode_1.StoryNodeEventObject(JsonUtil_1.JsonUtil.stringify(result.value) ?? ""));
                    if (result.completion) {
                        targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
                        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
                        executor();
                    }
                });
            });
        }
        else {
            console.error("not handle content!!!!");
        }
        return Promise.resolve();
    }
}
exports.MidjourneyDistribution = MidjourneyDistribution;
