import { useSignal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { fotActions } from "@store/fot";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { logEvent } from "@uikit/service/amplitude";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";
import { useStore as useImagicaStore, useStore } from "imagica-uikit/dist/hooks/useStore";
import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContainer } from "unstated-next";
import { useAISaasContainer } from "../../container";
import { AISaasInputConverter } from "./util";
import { BlueprintExecution } from "@uikit/util/BlueprintExecution";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

function useAiSaasInput(): any {
  const isMobile = window.navigator.userAgent.includes("Mobile");

  const outputListRef = useRef<HTMLDivElement>(null);
  const aiSaasContentRef = useRef<HTMLDivElement>(null);
  const editPlaceholderRef = useRef(null);
  const inputListRef = useRef<Array<any>>([]);
  const signalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const inputBoxRef = useRef<HTMLDivElement>(null);

  const isFocus = useSignal<Record<any, any>>({});
  const inputTitleId = useSignal("");
  const editInputId = useSignal("");
  const inputTitle = useSignal("");
  const btnText = useSignal("Submit");
  const showOutPut = useSignal(false);
  const noScroll = useSignal(false);
  const scrollTopNum = useSignal(0);
  const onMouseInputId = useSignal("");
  const showInputTitleId = useSignal("");
  const changeInputText = useSignal(false);
  const showTopSuction = useSignal(false);
  const showBottomPrompt = useSignal(false);
  const editPlaceholderData = useSignal<{
    id?: string;
    placeholder?: string;
  }>({});
  const isStaticMode = useSelector((state: any) => state.editor.isStaticMode);
  const applicationTheme = useSelector((state: any) => state.editor.applicationTheme);

  const projectRunStore = getIt(ProjectRunStore);
  const store = getIt(PublishSubscriptionStore);
  const viewbuilderStore = getIt(ViewbuilderStore);
  const projectNodeStore = getIt(ProjectNodeStore);
  const state = useStore(store).value;
  const authStore = getIt(FotAuthStore);
  const homePluginStore: HomePluginStore = getIt(HomePluginStore);
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags: FeatureTags = homeStoreState.featureTags;

  function checkSubscribe(): boolean {
    //  如果是付费APP，并且没有付费
    if (state.isPaidApp && !state.isPay) {
      if (!authStore?.state?.brainToken) {
        store.setOpenPayWallAndLogin();
        return true;
      }
      store.setIsOpenPayWall(true);
      return true;
    }
    return false;
  }

  const dispatch = useDispatch();
  const { props: aiSaasProps } = useAISaasContainer();
  const { isShare, closeInputId, clickCreateContent, saasUIInputData } = aiSaasProps;
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;

  const { saasUIData } = creatorSaasAppState;
  const blueprintExecution = new BlueprintExecution(featureTags, saasUIInputData, viewbuilderStore, saasUIData);

  const setHighlightData = (val: any): void => {
    dispatch(fotActions.setHighlightData(val));
  };

  // 将 inputData 变成 sortableList
  const sortableList = useMemo(() => {
    return AISaasInputConverter.toSoratbleList(saasUIInputData);
  }, [saasUIInputData]);

  const onChangeSaasUIInputData = useCallback((sortableList: SortableList.SortableData<AISaas.SaasUIInputType>[]) => {
    const saasUiInputDataList = AISaasInputConverter.toSaasUIInputData(sortableList);
    // stud-2493 set input(preview)
    creatorSaasAppStore.setSaasUIData((prev: any) => ({ ...prev, input: saasUiInputDataList }));
    projectNodeStore.composeNodes(creatorNodesStore.getNodes(), creatorSaasAppStore.state.saasUIData);
  }, []);

  const onClickCloseContent = (e: any, nodeId: string): void => {
    if (isStaticMode) return;
    e.preventDefault();
    //在收起状态：点击x按钮，清空所有input的内容，并且展开所有输入框
    if (showTopSuction.value) {
      resetInput();
      // clickTopRightNumber()
    } else {
      //在展开状态，点击x按钮，只清空当前的输入。
      e.target.value = "";
      onSaasInputChange(e, nodeId);
    }
  };

  const resetInput = (): any => {
    const newInput = saasUIInputData.map((x: any) => {
      return {
        ...x,
        data: {
          ...x.data,
          textAreaValue: "",
        },
      };
    });
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });
  };

  const onInputTitleBlur = (): void => {
    inputTitleId.value = "";
  };

  const onInputTitleEnter = (e: any, nodeId: string): void => {
    e?.stopPropagation();
    e?.preventDefault();

    const newInput = saasUIInputData.map((x: any) => {
      if (x.id === nodeId) {
        return {
          ...x,
          data: {
            ...x.data,
            // stud-2493 set input title(preview)
            inputTitle: inputTitle.value?.trim(),
          },
        };
      }
      return x;
    });
    // stud-2493 deprecated
    // 更新 input title
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });
    projectNodeStore.updateById(nodeId, { inputTitle: inputTitle.value?.trim() });

    inputTitleId.value = "";
    inputTitle.value = "";
    showInputTitleId.value = "";
  };

  // eslint-disable-next-line
  const onInputEnter = async (e: any, nodeId: string, value: any): Promise<void> => {
    e.preventDefault();
    if (checkSubscribe()) return;

    const emptyNode = saasUIInputData.find((x: any) => isBlank(x?.data?.textAreaValue));

    //input都有值，点击submit
    if (isBlank(emptyNode)) {
      const blueprintExecuted = await blueprintExecution.execute();
      if (blueprintExecuted) {
        return;
      }
      clickSubmit();
      return;
    }
    //聚焦到空的input
    setTimeout(() => {
      const currentInput = inputListRef.current.find((x: any) => x.refKey === emptyNode.id);
      currentInput && currentInput?.refValue?.focus();
    }, 300);
  };

  const disableButton = (): any => {
    return hasEmptyInput() || !changeInputText || saasUIData?.staticMode;
  };

  const hasEmptyInput = (): any => {
    return saasUIInputData.some((x: any) => isBlank(x?.data?.textAreaValue));
  };

  const inputValues = (): any => {
    return saasUIInputData.map((x: any) => x?.data?.textAreaValue);
  };

  const clickSubmit = async (): Promise<void> => {
    if (checkSubscribe()) return;
    if (disableButton()) return;
    if (hasEmptyInput()) {
      func.customMsg({
        content: "Need to input something",
        type: "warning",
      });
      return;
    }
    if (homePluginStore.state.isShare) {
      logEvent("run_shared_webapp", {
        guest_uuid: homePluginStore.state.studioData?.guest_user_key,
        project_static_id: homePluginStore.state.studioData?.attributes?.project_id,
        user_webapp_input: inputValues(),
      });
    }
    // e.preventDefault();
    //使输入框失去焦点
    blurInput();
    // loading不可点击
    if (projectRunStore.state.runAllLoading) return;

    setContentBoxMinHeight();
    // 滚动动画及修改状态
    calcTitleHideScroll();

    noScroll.value = true;
    await clickCreateContent();
    noScroll.value = false;
    showBottomPrompt.value = false;
  };

  const calcTitleHideScroll = (): void => {
    // const needScrollLength = (inputBoxRef.current?.offsetTop || 230) - 30
    setTimeout(() => {
      if (!signalRef.current || !contentRef.current) {
        return;
      }
      showOutPut.value = false;
      showTopSuction.value = true;
      const distance = saasUIInputData.length === 1 ? 0 : 70;
      const needScrollLength = signalRef.current?.offsetTop - distance;
      contentRef.current.scrollTop = needScrollLength;

      contentRef.current.scrollTo({
        top: needScrollLength,
        behavior: "smooth",
      });
      scrollTopNum.value = needScrollLength;
    }, 800);
  };

  const setContentBoxMinHeight = (): void => {
    if (!aiSaasContentRef.current) {
      return;
    }
    const screenHeight = aiSaasContentRef.current?.clientHeight;
    if (outputListRef.current) {
      outputListRef.current.style.minHeight = `${screenHeight - 20}px`;
      outputListRef.current.style.height = `${screenHeight - 20}px`;
    }
  };

  const blurInput = (): void => {
    const active = document.activeElement;
    if (active?.nodeName === "TEXTAREA") {
      (active as HTMLTextAreaElement).blur();
    }
    const focusObj: { [key: string]: boolean } = {};
    saasUIInputData.forEach((x: any) => {
      focusObj[x.id] = false;
    });
    isFocus.value = focusObj;
  };

  const onSaasInputTitleChange = (e: any, id: string): void => {
    inputTitleId.value = id;
    inputTitle.value = e.target.value;
  };

  const onClickInputTitleClose = (e: any, nodeId: string): void => {
    if (isStaticMode) return;
    e?.stopPropagation();
    const newInput = saasUIInputData.map((x: any) => {
      if (x.id === nodeId) {
        return {
          ...x,
          data: {
            ...x.data,
            // stud-2493 set input title(preview)
            inputTitle: "",
          },
        };
      }
      return x;
    });
    // stud-2493 deprecated
    // 更新 input title
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });
    projectNodeStore.updateById(nodeId, { inputTitle: "" });

    inputTitleId.value = "";
    inputTitle.value = "";
    showInputTitleId.value = "";
  };

  const onClickInputTitle = (e: any, x: any): void => {
    if (isStaticMode) return;
    // 默认重置
    // const dataTitle = x?.data?.inputTitle || "";
    // stud-2493 get input title(nodes)
    const dataTitle = projectNodeStore.getNode(x.id)?.inputTitle || "";
    e?.stopPropagation();
    e?.preventDefault();
    // 输入后 点击不重置
    if (isShare || showOutPut.value) {
      return;
    }

    inputTitleId.value = x.id;
    inputTitle.value = dataTitle;
  };

  const onMouseLeaveBox = (): void => {
    onMouseInputId.value = "";
  };

  const onMouseOverBox = (id: string): void => {
    if (editInputId.value === id || getIsEditPlaceholder(id)) return;
    onMouseInputId.value = id;
  };

  const onPlaceholderBlurEnter = (): void => {
    const newInput = saasUIInputData.map((x: any) => {
      if (getIsEditPlaceholder(x.id)) {
        return {
          ...x,
          data: {
            ...x.data,
            webPlaceholder: editPlaceholderData.value.placeholder,
          },
        };
      }
      return x;
    });

    // 更新 input placeholder
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });

    onPlaceholderBlurClean();
  };

  const onPlaceholderBlurClean = (): void => {
    setTimeout(() => {
      editPlaceholderData.value = {};
    }, 300);
  };

  const getIsEditPlaceholder = (id: string): boolean => {
    return !isBlank(editPlaceholderData) && editPlaceholderData.value.id === id;
  };

  const onClickEditInputId = (e: any, id: string): void => {
    if (isStaticMode) return;
    e?.stopPropagation();
    e?.preventDefault();

    if (getIsEditPlaceholder(id)) {
      onPlaceholderBlurEnter();
      return;
    }
    setTimeout(() => {
      editInputId.value = id;
      const currentInput = inputListRef.current.find((x: any) => x.refKey === id);
      currentInput?.refValue &&
        currentInput.refValue.focus({
          cursor: "end",
        });
    }, 300);
  };

  const onDeleteInput = (e: any, id: string): void => {
    if (isStaticMode) return;
    e?.stopPropagation();
    e?.preventDefault();
    const newInput = saasUIInputData.filter((x: any) => x.id !== id);
    // stud-2493 set input(preview)(delete)
    // 删除 input
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });

    projectNodeStore.composeNodes(creatorNodesStore.getNodes(), creatorSaasAppStore.state.saasUIData);
    closeInputId(id);
  };

  const getByPlaceholderText = (x: any, index: number): any => {
    // 默认 Enter something
    let placeholderText = saasUIData?.placeholder || "";
    // placeholder 不是默认值。
    if (x?.data?.data?.placeholder && x?.data?.data?.placeholder !== "Enter input") {
      placeholderText = x?.data?.data?.placeholder || "";
    }

    // 选中/编辑状态不往下执行
    if (!isBlank(isFocus.value) && isFocus.value[x.id]) return placeholderText;

    const inputRef = inputListRef.current[index]?.refValue?.resizableTextArea?.textArea;

    // 减去 input padding 22px
    const scrollWidth = inputRef?.scrollWidth - 22 || 210;
    const byteProportion = 11;
    const byteNum = Math.floor(scrollWidth / byteProportion);

    // 内容大于最宽字符换行
    const isOmit = placeholderText.length > byteNum;

    // 大于最宽字符换行
    if (isOmit) {
      return `${placeholderText.substr(0, byteNum)}...`;
    }
    // 小于显示完整字符
    return placeholderText;
  };

  const onSaasPlaceholderChange = (e: any): void => {
    const placeholderData = Object.assign({}, { ...editPlaceholderData }, { placeholder: e?.target?.value });
    editPlaceholderData.value = placeholderData;
  };

  const onInputFocus = (nodeId: string, index: number): void => {
    if (isMobile && isShare && !showOutPut.value) {
      calcScrollDistanceTop();
    }

    const focusObj: { [key: string]: boolean } = {};
    saasUIInputData.forEach((x: any) => {
      focusObj[x.id] = x.id === nodeId;
    });
    isFocus.value = focusObj;

    // if(showOutPut && showTopSuction && saasUIInputData?.length === 1) {
    //   clickTopRightNumber()
    // }

    if (isShare) {
      const inputRef = inputListRef.current[index]?.refValue?.resizableTextArea?.textArea;
      let indexPlaceholder = saasUIInputData.find((x: any) => x.id === nodeId)?.data?.placeholder;
      if (indexPlaceholder === "Enter input") {
        indexPlaceholder = saasUIData?.placeholder;
      }
      // 改变 input 高度 && placeholder 没有截断 不改变高度。
      if (!isBlank(inputRef) && isBlank(inputRef.value) && indexPlaceholder !== inputRef.placeholder) {
        inputRef.style.height = "auto";
      }
    }
    // setShowTopSuction(false)
  };

  const calcScrollDistanceTop = (): void => {
    const screenHeight = document.body.scrollHeight;
    const element = inputBoxRef.current;
    if (!element || !outputListRef.current) {
      return;
    }
    // 目前存在多个 input 高度计算 确保高度正确
    const scrollHeight = element?.scrollHeight || 200;
    outputListRef.current.style.minHeight = `${screenHeight - scrollHeight}px`;
    outputListRef.current.style.height = `${screenHeight - scrollHeight}px`;

    setTimeout(() => {
      if (isBlank(element)) return;
      element.scrollIntoView(false);
      // 聚焦
    }, 300);
  };

  const onInputBlur = (nodeId: string, index: number): void => {
    editInputId.value = "";
    setTimeout(() => {
      isFocus.value = {
        ...isFocus.value,
        [nodeId]: false,
      };
      const inputRef = inputListRef.current[index]?.refValue?.resizableTextArea?.textArea;
      // 失去焦点重置 input 高度
      if (isShare && !isBlank(inputRef) && isBlank(inputRef.value)) {
        inputRef.style.height = inputRef.style.minHeight;
      }
    }, 200);
  };

  const onMouseOver = (nodeId: string): void => {
    const obj = { nodeId };
    setHighlightData(obj);
  };

  const onMouseLeave = (): void => {
    setHighlightData({});
  };

  const onSaasInputChange = (e: any, nodeId: string): void => {
    changeInputText.value = true;
    const newInput = saasUIInputData.map((x: any) => {
      if (x.id === nodeId) {
        return {
          ...x,
          data: {
            ...x.data,
            textAreaValue: e.target.value,
          },
        };
      }
      return x;
    });
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });
  };

  return {
    isDarkMode: applicationTheme === "dark",
    isFocus,
    btnText,
    inputListRef,
    editInputId,
    saasUIData,
    inputTitleId,
    sortableList,
    showTopSuction,
    onMouseInputId,
    showInputTitleId,
    editPlaceholderData,
    editPlaceholderRef,
    onPlaceholderBlurClean,
    onPlaceholderBlurEnter,
    onInputTitleBlur,
    onInputTitleEnter,
    onInputEnter,
    onInputFocus,
    onInputBlur,
    onMouseOver,
    onMouseLeave,
    onDeleteInput,
    onMouseOverBox,
    onMouseLeaveBox,
    onClickInputTitle,
    onClickCloseContent,
    onClickEditInputId,
    onSaasInputTitleChange,
    onClickInputTitleClose,
    onChangeSaasUIInputData,
    onSaasPlaceholderChange,
    getByPlaceholderText,
    onSaasInputChange,
    aiSaasProps,
    disableButton,
    clickSubmit,
  };
}

/**
 * AiSaasInput 容器
 *
 * - 只要是 AiSaasInput 组件子节点都可以通过 `useAiSaasInput` 得到内部数据和逻辑，可避免深度传递
 *
 * @returns
 */
export const AiSaasInputContainer = createContainer(useAiSaasInput);

export const { Provider: AiSaasInputProvider, useContainer: useAiSaasInputContainer } = AiSaasInputContainer;
