import isBlank from "@sedan-utils/is-blank";
import { SwitcherModeType } from "@uiview/views/navBar/NavBarStore";
import { getQueryParams } from "@views/thinking-layout-editor/util";
import { RelationSetDest } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { ChatOnboardingState } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { HomeUtil } from "imagica-corekit/dist/cases/home/HomeUtil";
import func from "@uikit/func";
import { invalidShareAppTipStore } from "@uikit/store/InvalidShareAppTipStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { fontUtil } from "@uiview/views/ProjectCanvas/useDownloadFont";
import { ViewBuilderGraph } from "imagica-corekit/dist/base/api/viewBuilderTyped/viewBuilderGraph";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { FontType } from "imagica-corekit/dist/cases/theme/fontTheme/FontData";
import { Api } from "@uikit/service/api";
import { ImagicaDep, ImagicaUserDataDep, ImagicaShareDataDep, InitImagicaDataParams } from "./modles";
import { UserIdentity } from "@uikit/util/userIdentity";

/**
 * 该clas 是原来 useHomeInit 中的 handleImagicaData 逻辑
 *
 * constructor 依赖过多，这里用三个 class 单独处理
 *
 * handleInit 移动到 ProcessService 中
 *
 */
export class ProcessImagicaData {
  api = new Api(false);

  constructor(
    private imagicaDep: ImagicaDep,
    private userDep: ImagicaUserDataDep,
    private shareDep: ImagicaShareDataDep
  ) {}

  /**
   * @throws
   */
  async handleImagicaData(): Promise<void> {
    /// 获取function v2列表
    if (this.imagicaDep.homePluginStore.state.isShare) {
      return;
    }

    this.imagicaDep.preferencesStore.renew();
    this.imagicaDep.projectFunctionStore.init();
    const storeDataPromise = [this.imagicaDep.functionV2Store.get()];

    if (this.imagicaDep.homeStore.state.featureTags.showEdgeBluePrint) {
      storeDataPromise.push(this.imagicaDep.toolPieceStore.get(), this.imagicaDep.blueprintsStore.get());
    } else {
      // 这里在没打开BluePrint权限时重置Toolbox的值，因为Toolbox只能在有BluePrint权限时才能手动打开
      this.imagicaDep.fotReduxStore.setEnableToolboxFeature(false);
    }
    await Promise.all(storeDataPromise);
  }

  async getProject(params: InitImagicaDataParams): Promise<void> {
    if (params.attrIndexer && !func.isEmpty(params.attrIndexer)) {
      try {
        const objectData = await HomeUtil.getObjectData(this.userDep.brainClient, params.attrIndexer);
        let existingRelation: RelationSetDest[] = [];
        if (!isBlank(objectData?.[0]?.id)) {
          existingRelation = await this.userDep.gql.relationSet({
            name: "studio_project",
            destId: parseInt(objectData?.[0]?.id as string),
          });
        }

        const selectedTemplateobj = HomeUtil.transformProjectDataToTemplate(objectData[0]) as PreviewApp.ProjectType;
        // 进入project时，赋值一次chatOnboardingStore
        if (this.userDep.chatOnboardingStore.state.isNewUser) {
          this.userDep.chatOnboardingStore.setChatOnboardingState({ isNewUser: false });
        } else {
          this.userDep.chatOnboardingStore.setState(
            selectedTemplateobj?.multiRoundChatData || new ChatOnboardingState()
          );
          if (selectedTemplateobj?.multiRoundChatData?.isOpenChat) {
            this.userDep.navBarStore.setSwtichMode(SwitcherModeType.imagica);
          }
        }
        selectedTemplateobj.relationId = existingRelation?.[0]?.relationId;
        this.imagicaDep.fotReduxStore.setSelectedTemplate(selectedTemplateobj);
        this.userDep.projectLoader.loadFromExistingTemplateV2(selectedTemplateobj);
        this.imagicaDep.fotReduxStore.setStudioProjectName(selectedTemplateobj.name);

        const urlParams = getQueryParams() as any;
        if (urlParams.appId) {
          this.userDep.previewAppStore.openPreviewApp(urlParams.appId, urlParams.open);
        }
      } catch (error) {
        params.onProjectError?.(error);
        // navigate("/editor");
        // window.location.reload();
      } finally {
        this.imagicaDep.fotReduxStore.setFetching(false);
      }
    }
  }

  private async getSubscription(price_id: any): Promise<void> {
    try {
      const res = await this.api.getWithError(`/be/payment/subscription/price/${price_id}`);
      this.shareDep.projectPageStore.setSubscriptionPrice(res.data);
    } catch (error) {
      console.error("get price detail failed.", error);
    }
  }

  async handleShare(params: InitImagicaDataParams): Promise<void> {
    // 根据url请求数据渲染share页面
    const data: Record<string, any> = this.imagicaDep.fotReduxStore.getState().editor.subscriberParamsData;
    // data为空表示unpublish
    if (isBlank(data)) {
      invalidShareAppTipStore.setShowInvalidShareAppTip(true);
      return;
    }
    this.imagicaDep.fotReduxStore.setShare(true);
    this.shareDep.projectPageStore.setSubscriptionPrice({});
    this.shareDep.projectPageStore.setShareSelectedLoading(true);
    const subscriberObj = !func.isEmpty(data) ? data?.attributes : undefined;
    const selectedUIQuery = subscriberObj?.selectedUI || params.urlSelectedUI || "saas";

    this.shareDep.creatorAISaasStore.setShowSaasOutputList(false);
    this.userDep.previewAppStore.setShowPreviewPanel(true);
    this.shareDep.projectPageStore.setStripePaymentActivated(false);
    this.imagicaDep.fotReduxStore.setShowShareSignin(false);
    this.shareDep.sharedLinkToGetProject
      .getSharedProjectData(subscriberObj, params.urlQparams, params.routerProjectId)
      .then(async (data: PreviewApp.ProjectType) => {
        this.imagicaDep.fotReduxStore.setSelectedTemplate(data);
        this.shareDep.projectPageStore.setShareSelectedLoading(false);
        // 判断分享中是否需要订阅收费
        if (!func.isEmpty(data?.published_metadata?.stripe_price_id)) {
          this.shareDep.projectPageStore.setStripePaymentActivated(JSON.parse(params.urlStripePaymentActivated as any));
          // 判断用户是否登录
          if (func.isEmpty(this.shareDep.authStore.state.brainToken)) {
            this.shareDep.authStore.removeBrainToken();
            localStorage.removeItem("BRAIN_USER_EMAIL");
            this.imagicaDep.fotReduxStore.setIsLogin(false);
            if (!this.shareDep.projectPageStore.state.stripePaymentActivated)
              this.imagicaDep.fotReduxStore.setShowShareSignin(true);
          }
          await this.getSubscription(data.published_metadata?.stripe_price_id);
        }
        const uiData = !func.isEmpty(data?.v3) ? data?.v3?.UI : data?.v2?.UI;
        const currPage = uiData?.find(x => x.value === selectedUIQuery);

        const viewBuilderGraph = JsonUtil.toModelFromType(ViewBuilderGraph, currPage?.viewBuilderGraph || {});

        if (viewBuilderGraph) {
          this.shareDep.viewbuilderStore.init(viewBuilderGraph);
        }

        if (func.isEmpty(currPage)) {
          invalidShareAppTipStore.setShowInvalidShareAppTip(true);
          return;
        }
        const configList = await this.shareDep.webConfigStore.get();
        const fontFamily = currPage?.settings?.fontFamily || FontType.AKTIVGROTESK;
        fontUtil.downLoadFont(configList.imagicaAppfonts, fontFamily);
        previewStore.setSelectedUI(selectedUIQuery);
        this.userDep.projectLoader.loadFromExistingTemplateV2(data, selectedUIQuery, true);
      })
      .catch((error: any) => {
        console.error("error:::", error);
        invalidShareAppTipStore.setShowInvalidShareAppTip(true);
      });
  }

  /**
   * 原来 useHomeInit handleInit 方法
   * @param params
   * @throws 会将原来 useHomeInit handleInit 的错误抛出去，会处理 401
   * @returns
   */
  async init(params: InitImagicaDataParams): Promise<void> {
    // 初始化定义mode
    this.imagicaDep.fotReduxStore.setMode("editorMode");
    if (this.imagicaDep.homePluginStore.state.isShare) {
      this.handleShare(params);
      return;
    }
    this.handleImagicaData();
    // 获取项目信息
    this.getProject(params);

    this.imagicaDep.creatorRefStore.singleFlowEdgeArrRef.current = [];

    this.imagicaDep.fotReduxStore.setStudioThought("user Query");
    this.imagicaDep.fotReduxStore.setView("feed");
    this.imagicaDep.fotReduxStore.setStudioProjectName("New thinking app");

    // Get user's saved functions
    // if (!props.share) {
    // TAG: 912
    if (!UserIdentity.hasGuest()) {
      // initLoadUserFunctions();
    } else {
      // 07/28/2023 load all built-in functions if user are not signed in @david
      this.imagicaDep.fotReduxStore.setImageGenSelectOptions(params.defaultApis);
    }
  }
}
