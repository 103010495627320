import styles from "./Instructionview.module.scss";
import { InstructionItem } from "@uiview/views/PreviewPane/Settings/DomainName/InstructionModel";
import func from "@uikit/func";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import {
  DomainCheckStatus,
  DomainNameBloc,
  DomainNameState,
  DoneStatus,
} from "@uiview/views/PreviewPane/Settings/DomainName/DomainNameBloc";
import { SettingCustomDomain } from "imagica-corekit/dist/cases/webconfig/domain/ImagicatPublishSetting";
import { JSX } from "react";
import LoadingOutline from "@views/components/LoadingOutline";
import { Input } from "antd";
import isBlank from "@sedan-utils/is-blank";
import { Signal, useSignal } from "@preact/signals-react";
const COPY_ICON = `${settings.S3CDN}${settings.viewAssetsPath}copy.svg`;
const COPY_SUCCESS = settings.getViewAssetsPath("customdomain_done_right.svg");
export function InstructionView(props: { bloc: DomainNameBloc; webConfigDomain?: SettingCustomDomain }) {
  const state = props.bloc.state.value;
  const checkResponse = state.domainCheckInstructions;
  const configSuccess = state.domainCheckStatus === DomainCheckStatus.connected;
  const apiHadResponse = checkResponse != null;
  const instructions = checkResponse?.instructions?.instructions ?? [];
  const titleDescription = state.getStepData(props.webConfigDomain);
  return (
    <div className={styles.container}>
      {!configSuccess && (
        <InstructionDescription
          description={titleDescription[1]}
          linkText={props.webConfigDomain?.link?.text ?? ""}
          linkValue={props.webConfigDomain?.link?.value ?? ""}
          title={titleDescription[0]}
        ></InstructionDescription>
      )}
      {apiHadResponse && <div className={styles.configuration}>CNAME records required</div>}
      {apiHadResponse &&
        isBlank(state.domainCheckError) &&
        instructions.map((item, index) => {
          return (
            <div key={`${index}item`}>
              <InstructionItemView
                instructionItem={item}
                index={index}
                doneStatus={state.doneBtnStatus[index]}
                onClickDone={() => props.bloc.onClickDone(item)}
              ></InstructionItemView>
            </div>
          );
        })}
    </div>
  );
}
export function InstructionDescription(props: {
  description: string;
  linkText: string;
  linkValue: string;
  title: string;
}) {
  return (
    <div>
      <div className={styles.descriptionTitle}>{props.title}</div>
      <div>
        <div className={styles.description}>{props.description}</div>
        <div>
          <a
            onClick={() => {
              window.open(props.linkValue, "_blank");
            }}
            className={styles.link}
          >
            {props.linkText}
          </a>
        </div>
      </div>
    </div>
  );
}

export function InstructionItemView(props: {
  instructionItem: InstructionItem;
  index: number;
  doneStatus: DoneStatus;
  onClickDone: () => void;
}): JSX.Element {
  const tapValueCopy = useSignal(false);
  const tapNameCopy = useSignal(false);
  const clickCopyText = (txt: string, signal: Signal<boolean>) => {
    if (signal.value) {
      return;
    }
    if (func.isEmpty(txt)) {
      func.customMsg({
        content: "Content is empty",
        type: "warning",
      });
      return;
    }

    navigator.clipboard.writeText(txt);
    tapNameCopy.value = false;
    tapValueCopy.value = false;
    signal.value = true;
    setTimeout(() => {
      signal.value = false;
    }, 2000);
  };

  const nameCopyIcon = tapNameCopy.value ? COPY_SUCCESS : COPY_ICON;
  const valueCopyIcon = tapValueCopy.value ? COPY_SUCCESS : COPY_ICON;
  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemDetail}>
        <div className={styles.detailBox}>
          <div className={styles.detailLabel}>Name:</div>
          {/* <div className={styles.detailText}>{props.instructionItem.Name}</div> */}
          <Input className={styles.detailText} value={props.instructionItem.Name} />
          <img
            src={nameCopyIcon}
            onClick={() => clickCopyText(props.instructionItem.Name, tapNameCopy)}
            width="20"
            height="20"
            alt="copy"
          />
        </div>
        <div className={styles.detailBox}>
          <div className={styles.detailLabel}>Value:</div>
          {/* <div className={styles.detailText}>{props.instructionItem.Value}</div> */}
          <Input className={styles.detailText} value={props.instructionItem.Value} />
          <img
            src={valueCopyIcon}
            onClick={() => clickCopyText(props.instructionItem.Value, tapValueCopy)}
            width="20"
            height="20"
            alt="copy"
          />
        </div>
      </div>

      <button className={styles.domainDone} onClick={() => props.doneStatus === DoneStatus.done && props.onClickDone()}>
        {props.doneStatus === DoneStatus.done ? "Done" : ""}
        {props.doneStatus === DoneStatus.refresh && <LoadingOutline></LoadingOutline>}
        {props.doneStatus === DoneStatus.success && (
          <img src={settings.getViewAssetsPath("customdomain_done_right.svg")} />
        )}
      </button>
    </div>
  );
}
