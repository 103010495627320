"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctionParamsManager = exports.ChatInterfaceParams = exports.AddDataSourceParams = exports.TextGenerationParams = exports.FunctionParams = void 0;
const FunctionRegistry_1 = require("./FunctionRegistry");
const StoryNodeDisplayType_1 = require("../../storyV2/domain/StoryNodeDisplayType");
const UrlUtil_1 = require("../../util/UrlUtil");
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const AddDataSourceFunctionUtil_1 = require("../../storyV2/function/AddDataSourceFunctionUtil");
const LangUtil_1 = require("../../cutil/LangUtil");
class FunctionParams {
    async getParams(actionData, sourceNode, context = {}) {
        return {};
    }
}
exports.FunctionParams = FunctionParams;
class TextGenerationParams extends FunctionParams {
    async getParams(actionData, sourceNode, context = {}) {
        return Promise.resolve({
            url: undefined,
            identifierType: actionData.identifierType,
            identifierValue: actionData.identifierValue,
            streaming: !context.isNested,
            domain: undefined,
        });
    }
}
exports.TextGenerationParams = TextGenerationParams;
class AddDataSourceParams extends FunctionParams {
    constructor(brainClient) {
        super();
        this.brainClient = brainClient;
    }
    async getParams(actionData, sourceNode, context = {}) {
        const fileOnEdge = actionData.enterText === FunctionRegistry_1.FunctionName.addDataSource ||
            actionData.enterText === `/${FunctionRegistry_1.FunctionName.addDataSourceValue}`;
        // 上传的文件不在边上
        if (!fileOnEdge) {
            let params = await this.getIndexParams(sourceNode, actionData);
            return Promise.resolve(params);
        }
        else {
            // 上传的文件在边上，不在node 上
            return Promise.resolve(actionData.requestParam);
        }
    }
    /**
     * 获取请求的params
     * 分为三大类
     * 1 text,2 file, 3 json
     * @param sourceNode
     * @param actionData
     * @private
     */
    async getIndexParams(sourceNode, actionData) {
        const displayType = sourceNode.options.displayType ?? StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT;
        const query = actionData.enterText.replace("/", "");
        const loader = "PDFReader";
        let params = { query, loader };
        if (displayType === StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT || displayType === StoryNodeDisplayType_1.StoryNodeDisplayType.HTML) {
            if (displayType === StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT) {
                params.text = sourceNode.content;
            }
            else {
                const content = JSON.parse(sourceNode.content);
                const htmlText = await this.getHtmlText(sourceNode, content.s3Url || content.value);
                params.text = htmlText;
            }
        }
        else if (AddDataSourceFunctionUtil_1.AddDataSourceFunctionUtil.isFile(displayType)) {
            const result = AddDataSourceFunctionUtil_1.AddDataSourceFunctionUtil.getFileData(sourceNode.content, displayType);
            params.file = result.file;
            params.uuid = result.uuid;
            params.filename = result.filename;
        }
        else {
            const json = (0, LangUtil_1.tryBlock)(() => JSON.parse(sourceNode.content)).data;
            params.json = json;
        }
        return params;
    }
    /**
     * 获取html文本
     * @param sourceNode
     * @param url
     * @private
     */
    async getHtmlText(sourceNode, url) {
        if (sourceNode.options.displayType === StoryNodeDisplayType_1.StoryNodeDisplayType.HTML && url) {
            return this.downloadHtml(url);
        }
        else if (sourceNode.options.displayType === StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT) {
            return sourceNode.content;
        }
        return undefined;
    }
    /**
     * 通过url 获取html数据，并且转为text
     * @param url
     * @private
     */
    async downloadHtml(url) {
        if (UrlUtil_1.UrlUtil.isS3Url(url)) {
            const urlNew = `/api/me/upload/studios?url=${encodeURIComponent(url)}`;
            const file = this.brainClient.file;
            try {
                const htmlText = await file.getResponseText(urlNew);
                return htmlText;
            }
            catch (error) {
                throw error;
            }
            return "";
        }
        const htmlText = await this.brainClient.file.getGlobalResponseText(url);
        return htmlText;
    }
}
exports.AddDataSourceParams = AddDataSourceParams;
class ChatInterfaceParams extends AddDataSourceParams {
    async getParams(actionData, sourceNode, context = {}) {
        return super.getParams(actionData, sourceNode, context);
    }
}
exports.ChatInterfaceParams = ChatInterfaceParams;
class FunctionParamsManager extends FunctionBase_1.FunctionBase {
    constructor(brainClient, authStore, socketRestClient) {
        super(brainClient, authStore, socketRestClient);
        this.brainClient = brainClient;
        this.authStore = authStore;
        this.socketRestClient = socketRestClient;
    }
    getByType(functionName) {
        // bind params types with function name
        const map = {};
        map[FunctionRegistry_1.FunctionName.textGeneration] = new TextGenerationParams();
        map[FunctionRegistry_1.FunctionName.addDataSource] = new AddDataSourceParams(this.brainClient);
        map[FunctionRegistry_1.FunctionName.chatInterface] = new ChatInterfaceParams(this.brainClient);
        map[FunctionRegistry_1.FunctionName.empty] = new FunctionParams();
        this.map = map;
        let instance = this.map[functionName || FunctionRegistry_1.FunctionName.empty];
        if (!instance) {
            instance = this.map[FunctionRegistry_1.FunctionName.empty];
        }
        return instance;
    }
    async run(input, identityInfo, variableList = [], page = 1, next = undefined, pagination = undefined, requestParams = {}, checkAbort, sourceNodeType = StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT, queries = [], blueprintJSON = "", graphData) {
        throw "Not implemented run method";
    }
}
exports.FunctionParamsManager = FunctionParamsManager;
