import { EDGE_STATE_CONFIG } from "@views/thinking-layout-editor/constants";
import { EdgeState } from "imagica-corekit/dist/base/storyV2/domain/StoryEdge";
import { BaseDataRef } from "./BaseDataRef";

class NewLineDataRefState {
  stateConfig = EDGE_STATE_CONFIG[EdgeState.IDLE];
  edgePath = "";
}

/**
 * 原来 HomeUsePlugin 中 newLineDataRef
 */
export class NewLineDataRef extends BaseDataRef<NewLineDataRefState> {
  constructor() {
    super(new NewLineDataRefState());
  }
}
