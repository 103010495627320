import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import func from "@uikit/func";
import cls from "classnames";
import { Drawer, Popover } from "antd";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { editorActions } from "../../store/editor";
import { fotActions } from "../../store/fot";
import VariablePopoverContent from "./VariablePopoverContent";
import GuidanceContent from "./GuidanceContent";
import LoadingOutline from "../components/LoadingOutline";
import { CUSTOM_FUNCTION_VARS_REG, CUSTOM_FUNCTION_VARS_JS_REG } from "./constants";
import style from "./css/CustomJSFunction.module.css";
import { ChatBoxV2 } from "../../uiview/views/Nodes/ChatBox";
import useFeatureTags from "../../custom-hooks/useFeatureTags";
import { getIt } from "@uikit/getIt";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreateService } from "@uikit/service/CreateService";
import { HandleSaveUtil } from "@uikit/util/HandleSaveUtil";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { allPreDefinedFunctions } from "./nodeTypeComponents/nodeTypeDispose";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";

function CustomJSFunction(props) {
  const gql = getIt(ObjectRelationGqlService);
  const createService = getIt(CreateService);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const canvasDataRef = getIt(CanvasDataRef);
  const showNavPageStore = getIt(ShowNavPageStore);
  const dispatch = useDispatch();
  const store = useStore();
  const featureTags = useFeatureTags();

  const creatorStoreMethods = getIt(CreatorStoreMethods);

  const [demoHtmlStr, setDemoHtmlStr] = useState("");
  const [demoCssStr, setDemoCssStr] = useState("");
  const [demoJsStr, setDemoJsStr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const contentBoxRef = useRef(null);

  const createJSFuncData = useSelector(state => state.editor.createJSFuncData);
  const setCreateJSFuncData = val => {
    dispatch(editorActions.setCreateJSFuncData(val));
  };
  const setCreateFunctionClick = val => {
    dispatch(fotActions.setCreateFunctionClick(val));
  };

  const variableList = useSelector(state => state.fot.variableList);
  // const reviseCustomCode = useSelector((state) => state.editor.reviseCustomCode);
  // const setReviseCustomCode = (val) => { dispatch(editorActions.setReviseCustomCode(val)) };

  const handleOk = async () => {
    const newFunctionData = {
      description: createJSFuncData?.parameter?.description || "",
      edgeArr: [],
      name: createJSFuncData?.parameter?.name || "",
      nodeIdArr: [],
      type: "CustomCode",
      codeData: {
        htmlStr: demoHtmlStr,
        cssStr: demoCssStr,
        jsStr: demoJsStr,
      },
    };
    // const functionId = props.modifyCodeObj.id;
    const functionId = createJSFuncData?.select?.id;
    try {
      setIsLoading(true);
      if (functionId) {
        const renewData = await gql.updateObject(functionId, {
          name: "studio_function",
          attributes: newFunctionData,
        });

        HandleSaveUtil.handleUserSavedFunctions({
          each: { user: createJSFuncData?.select?.originElement?.user, ...renewData },
          isUpdate: true,
          nodeDataRef: canvasDataRef.nodeDataRef,
          personalFunctionStore: getIt(PersonalFunctionStore),
          creatorRefStore: creatorRefStore,
          allPreDefinedFunctions: allPreDefinedFunctions,
          isSort: true,
        });
      } else {
        const currentSingleFlow = creatorRefStore.singleFlowEdgeArrRef.current.find(
          x => x.name === newFunctionData.name
        );
        if (!func.isEmpty(currentSingleFlow)) {
          func.messageUtil("Duplicate Name", "error");
          setIsLoading(false);
          return;
        }

        const callback = object => {
          HandleSaveUtil.handleUserSavedFunctions({
            each: object,
            isUpdate: false,
            // 原来调用方法并没有传入 nodeDataRef???, 所以这里变为空
            // nodeDataRef: nodeDataRef,
            nodeDataRef: { current: {} },
            personalFunctionStore: getIt(PersonalFunctionStore),
            creatorRefStore: creatorRefStore,
            allPreDefinedFunctions: allPreDefinedFunctions,
            isSort: true,
          });
        };
        await createService.createFunction(newFunctionData, false, callback);
      }
      setIsLoading(false);
      func.messageUtil("Success", "success");

      const clickFuncData = {
        edgeId: createJSFuncData.edgeId,
        functionName: newFunctionData.name,
      };
      setCreateFunctionClick(clickFuncData);
      onCancel();
      // 如果在首页则不继续执行
      if (showNavPageStore.state.showNavPage) return;
      // 给按钮下的文本赋值
      creatorStoreMethods.setEdgeLineParam({
        id: createJSFuncData.edgeId,
        enterText: `/${newFunctionData.name}`,
      });
      // 显示run all按钮
      creatorEdgesStore.setQueryLoading(createJSFuncData.edgeId, "isGetQuery", true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      func.messageUtil("Failed to save function", "error");
    } finally {
      setCreateJSFuncData({ loading: false });
    }
  };

  const customErrorTip = `window.onerror = function(e){
      const customdiv = document.getElementById('error-tips-auto')
      customdiv.setAttribute("style","display: block;");
      customdiv.innerHTML = e
      setTimeout(() => {
        customdiv.setAttribute("style","display: none");
      }, 5000)
      console.error('\\nerror ' + e + ':');
      return true;
    }`;

  const clickRun = (htmlStr, cssStr, jsStr) => {
    let htmlValue = demoHtmlStr || htmlStr || "";
    let cssValue = demoCssStr || cssStr || "";
    let jsValue = demoJsStr || jsStr || "";
    const errorStyl = `
      #error-tips-auto {
        background: red;
        color: #fff;
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 999999;
        padding: 5px;
        border-radius: 5px;
        display: none;
      }
    `;
    const nodes = creatorNodesStore.getNodes();
    const currentSourceNode = nodes.find(x => x.id === createJSFuncData.sourceNodeId);
    let currentSourceNodeValue = currentSourceNode?.data?.textAreaValue || "";
    if (
      currentSourceNodeValue instanceof Object &&
      currentSourceNodeValue.hasOwnProperty("data") &&
      !func.isEmpty(currentSourceNodeValue.data.value)
    ) {
      currentSourceNodeValue = JSON.stringify(currentSourceNodeValue.data.value);
    }

    const brainAuthToken = getIt(FotAuthStore).state.brainToken || "";

    // if(currentSourceNodeValue) {
    // htmlValue = htmlValue.replaceAll("${currentSourceNodeValue}", currentSourceNodeValue)
    htmlValue = htmlValue.replaceAll(CUSTOM_FUNCTION_VARS_REG, match => {
      if (match === "${currentSourceNodeValue}") return currentSourceNodeValue;
      if (match === "${brainAuthToken}") return brainAuthToken;
    });
    jsValue = jsValue.replaceAll(CUSTOM_FUNCTION_VARS_JS_REG, match => {
      // if (match === 'currentSourceNodeValue') return `'${currentSourceNodeValue}'`
      if (match === "currentSourceNodeValue") return JSON.stringify(currentSourceNodeValue);
      if (match === "brainAuthToken") return `'${brainAuthToken}'`;
      return match;
    });
    // jsValue = jsValue.replaceAll('currentSourceNodeValue', JSON.stringify(currentSourceNodeValue))

    // }
    variableList.map(x => {
      htmlValue = htmlValue.replaceAll("${" + `${x.name}` + "}", x?.node?.data?.textAreaValue);
      cssValue = cssValue.replaceAll("${" + `${x.name}` + "}", x?.node?.data?.textAreaValue);

      jsValue = jsValue.replaceAll(new RegExp(`\\w*${x.name}\\w*`, "g"), match => {
        if (match === x.name) return JSON.stringify(x?.node?.data?.textAreaValue);
        return match;
      });
      return x;
    });
    const lineFeed = htmlValue.match(/^<html>/) ? "" : "white-space: pre-wrap;";
    const htmlObjStr = `
      <html>
        <style>${cssValue}${errorStyl}</style>
        <body style="${lineFeed}">${htmlValue}<div id="error-tips-auto"></div></body>
        <script>{${customErrorTip};${jsValue}}</script>
      </html>
    `;
    injectHtml(htmlObjStr);
  };

  const onCancel = () => {
    setCreateJSFuncData({
      open: false,
      loading: false,
      edgeId: "",
      sourceNodeId: "",
      targetNodeId: "",
      function: "",
      select: {},
      parameter: {},
    });
    setDemoHtmlStr("");
    setDemoCssStr("");
    setDemoJsStr("");
    injectHtml("");
  };

  const injectHtml = htmlStr => {
    // if (util.isEmpty(iframeRef.current)) return;
    const existedIframe = document.getElementById("custom-iframe");
    // 移除旧的
    if (!func.isEmpty(existedIframe)) {
      if (func.isEmpty(contentBoxRef.current)) return;
      contentBoxRef.current.removeChild(existedIframe);
      if (func.isEmpty(htmlStr)) return;
    }
    // 创建新的iframe标签
    const iframe = document.createElement("iframe");
    // 设置属性
    iframe.setAttribute("id", "custom-iframe");
    iframe.setAttribute("class", `${style["preview-code"]}`);
    // 挂载到指定元素后
    contentBoxRef.current.appendChild(iframe);

    let iframedoc = iframe.document;
    if (iframe.contentDocument) iframedoc = iframe.contentDocument;
    else if (iframe.contentWindow) iframedoc = iframe.contentWindow.document;

    if (iframedoc) {
      iframedoc.open();
      iframedoc.writeln(htmlStr);
      iframedoc.close();
    } else {
      func.customMsg({
        content: "Unable to render html code",
        type: "warning",
      });
    }
    setTimeout(() => {
      setCreateJSFuncData({ loading: false });
    }, 500);
  };

  useEffect(() => {
    if (func.isEmpty(createJSFuncData.function)) return;
    const data = {
      clickRun: clickRun,
      onCancel: onCancel,
      handleOk: handleOk,
    };
    if (typeof data[createJSFuncData.function] != "function") return;
    data[createJSFuncData.function]();
    setCreateJSFuncData({ function: "" });
  }, [createJSFuncData.function]);

  useEffect(() => {
    if (func.isEmpty(createJSFuncData.select)) return;
    // const currFuncData = creatorRefStore.singleFlowEdgeArrRef.current.find(x => x.name === createJSFuncData.select.label)
    // copy function在imageGenSelectOptions中
    const imageGenSelectOptions = store.getState().fot.imageGenSelectOptions;
    const currFuncData = imageGenSelectOptions.find(x => x.label === createJSFuncData.select.label);
    const htmlStr = currFuncData?.codeData?.htmlStr || "";
    const cssStr = currFuncData?.codeData?.cssStr || "";
    const jsStr = currFuncData?.codeData?.jsStr || "";

    setDemoHtmlStr(htmlStr);
    setDemoCssStr(cssStr);
    setDemoJsStr(jsStr);

    clickRun(htmlStr, cssStr, jsStr);
  }, [createJSFuncData.select]);

  return (
    <Drawer
      className={cls(style["customJSFunction-box"], createJSFuncData.open && style["occlusion"])}
      placement="bottom"
      onClose={() => props.onCloseCustomModel()}
      open={createJSFuncData.open}
      closable={false}
    >
      <div
        className={style["content-box"]}
        style={featureTags.enableStudiosCustomJsFunctionChatBox ? undefined : { width: "100%" }}
        ref={contentBoxRef}
      >
        {isLoading ? (
          <div className={style["example"]}>
            <LoadingOutline />
          </div>
        ) : null}
        <div className={style["code-box"]}>
          <div className={style["code-editor"]}>
            <div className={style["code-title"]}>HTML</div>
            <div className={style["code-text-box"]}>
              <CodeEditor
                className={style["CodeEditor"]}
                value={demoHtmlStr}
                language="html"
                onChange={evn => setDemoHtmlStr(evn.target.value)}
              />
            </div>
          </div>
          <div className={style["code-editor"]}>
            <div className={style["code-title"]}>CSS</div>
            <div className={style["code-text-box"]}>
              <CodeEditor
                className={style["CodeEditor"]}
                value={demoCssStr}
                language="css"
                onChange={evn => setDemoCssStr(evn.target.value)}
              />
            </div>
          </div>
          <div className={style["code-editor"]}>
            <div className={style["code-title"]}>JS</div>
            <div className={style["code-text-box"]}>
              <CodeEditor
                className={style["CodeEditor"]}
                value={demoJsStr}
                language="js"
                onChange={evn => setDemoJsStr(evn.target.value)}
              />
            </div>
          </div>
        </div>
        {/* <iframe className={style['preview-code']} ref={iframeRef}></iframe> */}

        <div className={style["tool-bar-box"]}>
          <div className={style["bottom-tool-bar"]}>
            <Popover content={<VariablePopoverContent showDelet={false} />}>
              <span className="iconfont icon-bianliang"></span>
            </Popover>

            {/* Custom JS function guidance */}
            {
              <Popover content={<GuidanceContent type="customJsFunc" />}>
                <span className="iconfont icon-i-info"></span>
              </Popover>
            }
          </div>
        </div>
      </div>
      {featureTags.enableStudiosCustomJsFunctionChatBox && (
        <div className={style.chatBoxWrap}>
          <ChatBoxV2 isOpen={createJSFuncData.open} />
        </div>
      )}
    </Drawer>
  );
}

export default CustomJSFunction;
