import {
  StudioProjectAttributesV2EdgeData,
  StudioProjectAttributesV2EdgeDataLineParam,
} from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { StudioProjectAttributesV3Line } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Line";
import { isEmpty } from "lodash";
import { MutableRefObject } from "react";

/**
 * 该util 针对 原来homeusePlugin 中，newLineDataRef,nodeDataRef,edgeDataRef，newEdgeDataRef 四个 ref 相关的数据处理方法
 */
export class DataRefUtil {
  static getSimpleEdgeV3(
    edge: any,
    newLineDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV3Line>, any>
  ): any {
    const edgeData = DataRefUtil.getSimpleEdgeDataV3(edge, newLineDataRef);
    return {
      ...edge,
      data: edgeData,
    };
  }

  static getSimpleEdgeDataV3(
    edge: any,
    newLineDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV3Line>, any>
  ): Record<string, any> {
    const simpleEdgeData: {
      [key: string]: any;
    } = {};
    const noNeedAttrs: string[] = [];

    if (isEmpty(newLineDataRef.current) || isEmpty(edge.data)) return simpleEdgeData;
    Object.keys(newLineDataRef.current).forEach(x => {
      if (typeof newLineDataRef.current[x] !== "function" && !noNeedAttrs.includes(x)) {
        simpleEdgeData[x] = edge.data[x] || newLineDataRef.current[x];
      }
    });
    return simpleEdgeData;
  }

  static getSimpleNodeV3(
    node: any,
    newEdgeDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV2EdgeData>, any>
  ): any {
    const nodeData = DataRefUtil.getSimpleNodeDataV3(node, newEdgeDataRef);
    return {
      ...node,
      data: nodeData,
    };
  }

  /**
   * 原 useHomeUsePluginUtil 和 HomeUsePluginUtil 的 getSimpleNodeDataV3 的结合
   * @param node
   * @param newEdgeDataRef
   * @returns
   */
  static getSimpleNodeDataV3(
    node: any,
    newEdgeDataRef: Record<keyof MutableRefObject<StudioProjectAttributesV2EdgeData>, any>
  ): Record<string, any> {
    const simpleNodeData: {
      [key: string]: any;
    } = {};
    const attrFunc: Record<
      string,
      (lineParam: StudioProjectAttributesV2EdgeDataLineParam) => StudioProjectAttributesV2EdgeDataLineParam
    > = {
      lineParam: DataRefUtil.getSimpleNodeLineParamV3,
    };

    Object.keys(newEdgeDataRef.current).forEach((x: any) => {
      if (typeof newEdgeDataRef.current[x] !== "function") {
        simpleNodeData[x] = attrFunc[x] ? attrFunc[x](node.data[x]) : node.data[x] || newEdgeDataRef.current[x];
      }
    });
    return simpleNodeData;
  }

  static getSimpleNodeLineParamV3(lineParam: Record<string, any>): StudioProjectAttributesV2EdgeDataLineParam {
    const noNeedAttrs: string[] = ["loading"];
    const newLineParam: Record<string, any> = {};

    if (isEmpty(lineParam)) {
      return newLineParam;
    }

    Object.keys(lineParam).forEach((x: any) => {
      if (!noNeedAttrs.includes(x)) {
        newLineParam[x] = lineParam[x];
      }
    });
    return newLineParam;
  }
}
