"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestaurantAddress = exports.Restaurant = exports.RestaurantResponseData = exports.RestaurantResponse = exports.SearchParam = void 0;
const class_transformer_1 = require("class-transformer");
const JsonUtil_1 = require("../../cutil/JsonUtil");
class SearchParam {
}
exports.SearchParam = SearchParam;
class RestaurantResponse {
}
exports.RestaurantResponse = RestaurantResponse;
__decorate([
    (0, class_transformer_1.Type)(() => RestaurantResponseData)
], RestaurantResponse.prototype, "data", void 0);
class RestaurantResponseData {
    constructor() {
        this.stores = [];
    }
}
exports.RestaurantResponseData = RestaurantResponseData;
__decorate([
    (0, class_transformer_1.Type)(() => Restaurant),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toArrayFromType(Restaurant, value.value);
    }, { toClassOnly: true })
], RestaurantResponseData.prototype, "stores", void 0);
class Restaurant {
}
exports.Restaurant = Restaurant;
__decorate([
    (0, class_transformer_1.Type)(() => RestaurantAddress)
], Restaurant.prototype, "address", void 0);
class RestaurantAddress {
}
exports.RestaurantAddress = RestaurantAddress;
