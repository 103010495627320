"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RunCustomFunctionMsg = void 0;
class RunCustomFunctionMsg {
    /**
     * 当需要调用customFunction
     * @param story Story
     * @param sourceNode StoryNode
     * @param customFuncDest FunctionDest
     * @param runEnded 运行结束后，返回StoryNode
     * @param substoryLastEdgeTargetNodeCreated 运行到最后一条边的第一个节点需要回调回来
     */
    constructor(story, edge, customFuncDest, runEnded, substoryLastEdgeTargetNodeCreated) {
        this.story = story;
        this.edge = edge;
        this.customFuncDest = customFuncDest;
        this.runEnded = runEnded;
        this.substoryLastEdgeTargetNodeCreated = substoryLastEdgeTargetNodeCreated;
        this.naming = "RunCustomFunctionMsg";
    }
}
exports.RunCustomFunctionMsg = RunCustomFunctionMsg;
