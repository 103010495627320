import { CreatorNodesErrorTips } from "@uikit/service/CreatorNodesErrorTips";
import func from "@uikit/func";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { OpenAiParam } from "@uikit/service/OpenAiParam";
import { Api } from "@uikit/service/api";
import { EdgeRunResultParseUtil } from "@uikit/util/EdgeRunResultParseUtil";

export class EdgeRunTypeStock {
  api: Api;
  constructor(
    public creatorStoreMethods: CreatorStoreMethods,
    public openAiParam: OpenAiParam,
    public creatorNodesErrorTips: CreatorNodesErrorTips
  ) {
    this.api = new Api(false);
  }

  postAIDescription = async (
    query: any,
    identifier: string,
    variables = {},
    abort: boolean,
    isNeedHandelRes = true,
    edgeParam = {}
  ): Promise<any> => {
    try {
      let url = "/be/bas-demo-v4/nlp/brain_openai";
      let postParam = {
        engine: "text-davinci-003",
        frequency_penalty: 0,
        max_tokens: 500,
        presence_penalty: 0,
        stop: [],
        prompt: query,
      } as any;
      if (identifier) {
        postParam = await this.openAiParam.getCompletionsGenerationParam("file", identifier, variables, false);
        url = "/be/bas-demo-v4/nlp/completions_generation";
      }
      const { source = "", target = "", id = "" } = edgeParam as any;
      const res = await this.openAiParam.postUrlCanAbortService.postData(url, postParam, abort, (postInstance: any) => {
        this.creatorStoreMethods.setNodeParam({
          id: target,
          data: {
            abortNode: {
              source,
              target,
              edgeId: id,
              apiInstance: postInstance,
            },
          },
        });
      });
      if (!isNeedHandelRes) {
        return res;
      }
      const text = res?.data?.choices?.[0]?.text || res?.data?.choices?.[0]?.message?.content || "";
      const keyValues = text.split("\n").filter((x: any) => !func.isEmpty(x));
      const results = keyValues.map((x: string) => {
        const arr = x.split(": ");
        return {
          title: arr?.[0] || "",
          content: arr?.[1] || "",
        };
      });
      return results;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  handleStockInterfaceFunc = async (
    query: any,
    edgeParam: any
  ): Promise<{
    formatRes: any[];
  }> => {
    let formatRes = [] as any[];
    try {
      this.creatorStoreMethods.setNodeParam({
        id: edgeParam.target,
        data: {
          showStopGenerateBtn: true,
          stockLoading: true,
        },
      });
      const res = await this.postAIDescription(
        null,
        "brain_studios_xxx_brain_studios_extract_stock.model",
        { input: query },
        false,
        true,
        edgeParam
      );
      const stockSymbolStr = res
        .map((x: { title: any }) => x.title)
        .filter((x: any) => !func.isEmpty(x))
        .join("\n");
      // 切分
      const splitedArr = EdgeRunResultParseUtil.splitText(stockSymbolStr);
      const getStockSymbolArr = splitedArr.map((x: any) => {
        return this.api.getWithErrorCanAbort(`/be/svc-adapter/stock/symbol?text=${x}`);
      });
      // 添加可取消
      this.creatorStoreMethods.setNodeParam({
        id: edgeParam.target,
        data: {
          abortNode: {
            source: edgeParam.source,
            target: edgeParam.target,
            edgeId: edgeParam.id,
            apiInstance: getStockSymbolArr,
          },
        },
      });
      // 调用stock api
      const stockRes = await Promise.allSettled(getStockSymbolArr);
      const fulfilledStockRes = stockRes
        .filter((x: { status: string }) => x.status === "fulfilled")
        .map((x: any) => x.value.data.data);
      const isAbort = stockRes
        .filter((x: { status: string }) => x.status === "rejected")
        .some((x: any) => x.reason.name === "AbortError");
      if (isAbort) {
        throw {
          fromApiError: "The user aborted a request.",
        };
      }
      // 重构返回的stock数据
      formatRes = fulfilledStockRes
        .filter((x: { name: any }) => !func.isEmpty(x.name))
        .map((x: { logo: any; name: any; symbol: any; price: any; volume: any; business_summary: any }) => ({
          logo: x.logo,
          name: x.name,
          symbol: x.symbol,
          price: `$ ${x.price}`,
          volume: x.volume,
          businessSummary: x.business_summary,
        }));
    } catch (errorObject: any) {
      let error = errorObject;

      if (func.isEmpty(error.fromApiError)) {
        const errText = typeof error === "string" ? error : error.message;
        error = {
          specifyErrorText: errText || "Get stock failed",
        };
      }
      this.creatorNodesErrorTips.setNodeErrorTip(edgeParam.target, error);
    } finally {
      this.creatorStoreMethods.setNodeParam({
        id: edgeParam.target,
        data: {
          showStopGenerateBtn: false,
          stockLoading: false,
          abortNode: {},
        },
      });
    }
    return {
      formatRes,
    };
  };
}
