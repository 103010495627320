import func from "@uikit/func";
import { Input, Button } from "antd";
import { fotActions } from "../../../../store/fot";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore as useReduxStore } from "react-redux";
import { SendOutlined, SyncOutlined, LoadingOutlined } from "@ant-design/icons";
import style from "./ChatBox.module.css";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { OpenAiParamUtil } from "@uikit/util/OpenAiParamUtil";
import { OpenAiParam } from "@uikit/service/OpenAiParam";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";

const typeLabel = {
  user: {
    label: "User",
    flag: "<#USER>",
  },
  assistant: {
    label: "Assistant",
    flag: "<#ASSISTANT>",
  },
};

export type TypeLabelKey = "user" | "assistant";
const ChatBox: React.FC<any> = props => {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const openAiParam = getIt(OpenAiParam);
  const postUrlCanAbortService = getIt(PostUrlCanAbortService);

  const store = useReduxStore();
  const dispatch = useDispatch();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(false);

  const [submitValue, setSubmitValue] = useState("");

  const [chatList, setChatList] = useState<any[]>([]);

  const chatBoxListRef = useRef<null | HTMLDivElement>(null);
  const prevAttribute = useRef<any>({});
  const currEdge = useRef<any>({});
  const sourceNode = useRef<any>("");

  const isShare = useSelector((state: any) => state.fot.isShare);

  const setVariableList = (val: any): void => {
    dispatch(fotActions.setVariableList(val));
  };

  const clickRegenerateBtn = async (): Promise<void> => {
    if (submitLoading || regenerateLoading) return;
    const filterChatList = chatList.filter((x, idx) => !(x.type === "assistant" && idx === chatList.length - 1));
    setRegenerateLoading(true);
    await postStreamingApi(filterChatList);
    setRegenerateLoading(false);
  };
  const clickRestartBtn = (): void => {
    init();
  };
  const getCurrentAttribute = (arr: any[]): any => {
    const attributeStr = arr?.[0]?.attributes || "";
    let attribute = {};
    try {
      attribute = JSON.parse(attributeStr);
    } catch (error) {
      return attribute;
    }
    return attribute;
  };
  const moveListScrollBarToBottom = (): void => {
    if (chatBoxListRef.current) {
      if (chatBoxListRef.current.scrollHeight <= chatBoxListRef.current.clientHeight) return;
      chatBoxListRef.current.scrollTo({
        top: chatBoxListRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  const postStreamingApi = async (newChatList: any[]): Promise<any> => {
    try {
      // 这里需要组装最新的prompt
      prevAttribute.current.prompt = listToFlagStr(newChatList);
      // 请求completions
      const url = "/stream/bas-demo-v4/nlp/completions_generation";
      const postParam = await openAiParam.getCompletionsGenerationParam(
        "object_id",
        currEdge.current.data.lineParam.identifier.value,
        {
          input: sourceNode.current.data.textAreaValue,
          chatHistory: prevAttribute.current.prompt,
        }
      );
      let temp = "";
      await postUrlCanAbortService.postWithStreaming(
        url,
        postParam,
        (msg: any) => {
          temp += msg.choices?.[0]?.text || msg.choices?.[0]?.delta?.content || "";
          setChatList(() => {
            return [
              ...newChatList,
              {
                type: "assistant",
                value: temp,
              },
            ];
          });
          // 需要置滚动条到最底部
          moveListScrollBarToBottom();
        },
        false
      );
    } catch (error: any) {
      func.messageError(error);
    }
  };
  const onPressEnter = async (): Promise<void> => {
    if (func.isEmpty(submitValue)) {
      func.customMsg({
        content: "Message cannot be empty",
        type: "warning",
      });
      return;
    }
    if (func.isEmpty(prevAttribute.current) || submitLoading || regenerateLoading) return;

    setSubmitValue("");
    const newChatList = [
      ...chatList,
      {
        type: "user",
        value: submitValue,
      },
    ];
    setChatList(newChatList);
    setSubmitLoading(true);
    await postStreamingApi(newChatList);
    setSubmitLoading(false);
  };
  const extractAllFlagTextToList = (prompt: any): any[] => {
    const results: any[] = [];
    const reg = /<#(ASSISTANT)>([^<]*)|<#(USER)>([^<]*)/g;

    prompt.replace(reg, (matchStr: string, ...param: any[]) => {
      const filterParam = param.filter(
        (x, idx) => !func.isEmpty(x) && idx !== param.length - 1 && idx !== param.length - 2
      );
      for (let index = 0; index < filterParam.length; index++) {
        if (index % 2 === 0) {
          const type = filterParam[index].toLowerCase();
          const value = filterParam[index + 1];
          results.push({
            type,
            value,
          });
        }
      }
    });
    return results;
  };
  const listToFlagStr = (newChatList: any[]): string => {
    let str = "";
    newChatList
      .filter((x, idx) => !(x.type === "assistant" && idx === 0))
      .forEach(x => {
        str += `${typeLabel[x.type as TypeLabelKey].flag}${x.value}`;
      });
    return str;
  };

  const init = (): void => {
    if (func.isEmpty(props.chatBoxParam)) return;
    const edges = creatorEdgesStore.getEdges();
    currEdge.current = edges.find(x => x.target === props.nodeId) || {};
    if (func.isEmpty(currEdge.current)) return;
    // 替换变量
    const variableList = (store.getState() as any).fot.variableList;
    const nodes = creatorNodesStore.getNodes();
    sourceNode.current = nodes.find(x => x.id === currEdge.current.source);
    prevAttribute.current = getCurrentAttribute(props.chatBoxParam);
    prevAttribute.current.prompt = OpenAiParamUtil.replaceInputAndCustomVars({
      variableList,
      nodes,
      prompt: prevAttribute.current.prompt,
      nodeText: sourceNode.current.data.textAreaValue,
      setVariableList,
    });
    // 初始化assistant对话
    const initialArr = extractAllFlagTextToList(prevAttribute.current.prompt);
    setChatList(initialArr.filter(x => x.type === "assistant"));
  };

  useEffect(() => {
    init();
  }, [props.chatBoxParam]);

  useEffect(() => {
    creatorNodesStore.setNodes((prevList: any[]) => {
      return prevList.map(x => {
        if (x.id === props.nodeId) {
          return {
            ...x,
            data: {
              ...x.data,
              textAreaValue: chatList,
            },
          };
        }
        return x;
      });
    });
  }, [chatList]);

  useEffect(() => {
    if (!Array.isArray(props.textAreaValue)) return;
    setChatList(props.textAreaValue);
  }, []);

  return (
    <div className={`${style["white-frame"]}`}>
      <div className={style["chat-box"]}>
        <div className={`${style["chatbox-content"]} ${!props.isResized && !isShare ? style["max-chatbox"] : ""}`}>
          {/* list区域选中后不能拖拽，以便选中文本和滚动文本 */}
          <div className={`${style["chatbox-list"]} ${props.isSelected ? "nowheel nodrag" : ""}`} ref={chatBoxListRef}>
            {chatList.map((x, idx) => {
              return (
                <div
                  key={idx}
                  className={`${style["chatbox-item"]} ${
                    x.type === "assistant" ? style["chatbox-assistant-item"] : ""
                  }`}
                >
                  <span className={style["chatbox-type"]}>
                    {typeLabel[x.type as TypeLabelKey]?.label || "Assistant"}
                  </span>
                  <span className={style["chatbox-text"]}>{x.value}</span>
                </div>
              );
            })}
          </div>

          <div className={style["chatbox-footer"]}>
            {!func.isEmpty(chatList) ? (
              <>
                <Button
                  icon={<SyncOutlined />}
                  loading={regenerateLoading}
                  onClick={clickRegenerateBtn}
                  disabled={!chatList.some(x => x.type === "user")}
                >
                  Regenerate
                </Button>
                <Button
                  onClick={clickRestartBtn}
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Restart Conversation
                </Button>
              </>
            ) : null}

            <Input
              className={style["chatbox-input"]}
              value={submitValue}
              onChange={e => setSubmitValue(e.target.value)}
              allowClear
              size="large"
              placeholder="Send a message..."
              onPressEnter={onPressEnter}
              suffix={
                !submitLoading ? (
                  <SendOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      transform: "rotateZ(-45deg)",
                    }}
                    onClick={onPressEnter}
                  />
                ) : (
                  <LoadingOutlined />
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatBox;
