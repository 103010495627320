import classNames from "classnames";
import css from "./index.module.scss";
import { Api } from "@uikit/service/api";
import { useEffect, useMemo } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { AISaasLayout } from "../AISaasLayout";
import { AISaasProvider, useAISaasContainer } from "../container";
import { AiSaasOutputList } from "../AISaasOutput";
import { AISaasHeader } from "../AiSaasHeader";
// import PreviewHeaderView from "@uiview/views/PreviewHeader/PreviewHeaderView";
import { AISaasLogoIcon } from "./icons";
import { PreviewV2ToolBar } from "@uiview/views/PreviewV2ToolBar/PreviewV2ToolBar";
import SimpleBar from "simplebar-react";
import AddToContent from "../AddToContent";
import { AYLWAYS_SHOW_OUTPUT } from "../AddToContent/conts";
import { ChatAIPage } from "@uiview/views/ChatAI/ChatAISharedPage";
import { useSignal } from "@preact/signals-react";
import func, { matchMobile } from "@uikit/func";
import { FooterUrl } from "@uiview/views/ShareAccount/FooterUrl";
import { previewStore } from "@uiview/store/PreviewStore";
import PreviewHeaderViewV2 from "@uiview/views/PreviewHeaderV2/PreviewHeaderViewV2";
import { getIt } from "@uikit/getIt";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { GetItContext } from "imagica-uikit/dist/base/provider/GetItContext";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { AIViewBuilder } from "../AISaasApp/AIViewBuilder/AIViewBuilder";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

function AISaasInner(props: { inOrOutSetting: (bol: boolean) => void; backPreview: () => void }): JSX.Element {
  const { thoughtOptions, onChangeThoughts, onMouseEnterAISaas, onMouseLeaveAISaas } = useAISaasContainer();
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useImagicaStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;
  const previewComposeState = getIt(PreviewComposeState);
  const previewAppStore = getIt(PreviewAppStore);
  const previewAppState = useImagicaStore(previewAppStore).value;
  const isInHistory = useMemo(() => {
    return previewComposeState.isInHistory();
  }, [
    previewAppState.historyShowStatus,
    previewAppState.publishShowStatus,
    previewAppState.previewShowStatus,
    previewAppState.settingShowStatus,
  ]);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const chatOnboardingStore = getIt(ChatOnboardingStore);

  const chatOnboardingState = useImagicaStore(chatOnboardingStore).value;

  const api = new Api(false);
  const uploadLoge = useSignal("");
  const logoLoading = useSignal(false);

  const isChatAI = creatorSaasAppStore.state.saasUIData?.v2App?.type === "chat_ai";

  const HeaderV2 = (
    <div className="aisass-head">
      <PreviewHeaderViewV2 backPreview={props.backPreview} inOrOutSetting={props.inOrOutSetting} />
    </div>
  );

  const showInfo = !isMobile && !!thoughtOptions.length;

  const AISaasCopyright = (
    <div>
      {chatOnboardingState.selectTemplateInModal !== ChoosingFormat.AUTOIMAGICA && (
        <AddToContent id={AYLWAYS_SHOW_OUTPUT} className={css.bottomAddToContent} type="output" isAlwaysShow />
      )}
      <FooterUrl showInfo={showInfo} className={css.aiSaasAppFooterUrl} />
    </div>
  );

  const initializationLogo = async (): Promise<any> => {
    uploadLoge.value = "";
    const url = saasUIData?.settings?.logoImg || "";
    if (saasUIData.id === previewStore.state.previewAppAcivieId && !func.isEmpty(url)) {
      try {
        logoLoading.value = true;
        const { fileUrl } = await api.getByCustomType(`/api/me/upload/im?url=${url}`, "blob");
        uploadLoge.value = fileUrl || "";
      } finally {
        logoLoading.value = false;
      }
    }
  };

  useEffect(() => {
    initializationLogo();
    // eslint-disable-next-line
  }, [saasUIData?.settings?.logoImg]);

  useEffect(() => {
    if (isInHistory) {
      // 组件离开
      uploadLoge.value = "";
    }
  }, [isInHistory]);

  const DefaultLogo = (): JSX.Element => {
    return logoLoading.value ? (
      <LoadingOutlined className={css.logoLoading} />
    ) : (
      <AISaasLogoIcon disableCursorPointer={true} />
    );
  };

  return (
    <AISaasLayout
      header={HeaderV2}
      className={css["AISaas-wrap"]}
      onMouseEnter={onMouseEnterAISaas}
      onMouseLeave={onMouseLeaveAISaas}
    >
      <div
        className={classNames(css["AISaas-background"], {
          [css["AISaas-background-mobile"]]: isMobile && featureTags.disableMobilePage,
        })}
      ></div>
      {/* TIP: simple bar 为了控制 windows和 mac上滚动条一致, 让左右两侧能够显示出滑块, 如果不需要滑块可直接将 simple 替换普通 div */}
      <SimpleBar
        className={classNames(css["AISaas-main"], {
          [css["AISaas-main-mobile"]]: isMobile && featureTags.disableMobilePage,
        })}
      >
        <div data-testid="AiSaasContent" className={css.aiSaasContent}>
          {isChatAI ? (
            <ChatAIPage />
          ) : (
            <>
              <div className={css["AISaas-logo"]}>
                {func.isEmpty(uploadLoge.value) ? (
                  <DefaultLogo />
                ) : (
                  <img className={css.logoBoxImg} src={uploadLoge.value} alt="" />
                )}
              </div>
              <div className={css.AISaasContent}>
                <AISaasHeader />
                <AIViewBuilder />
                <AiSaasOutputList thoughtOptions={thoughtOptions} onChangeThoughts={onChangeThoughts} />
                {AISaasCopyright}
                <PreviewV2ToolBar />
              </div>
            </>
          )}
        </div>
      </SimpleBar>
    </AISaasLayout>
  );
}

export function AISaasPreview(
  props: AISaas.AISaasStoreProps & { inOrOutSetting: (bol: boolean) => void; backPreview: () => void }
): JSX.Element {
  return (
    <GetItContext.Provider value={getIt}>
      <AISaasProvider initialState={props}>
        <AISaasInner inOrOutSetting={props.inOrOutSetting} backPreview={props.backPreview} />
      </AISaasProvider>
    </GetItContext.Provider>
  );
}
