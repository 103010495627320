"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeeeGroceriesFunction = void 0;
const SearchInfoWrapper_1 = require("../../storyV2/model/SearchInfoWrapper");
const FunctionBase_1 = require("./FunctionBase");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
class WeeeGroceriesFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo, variableList = [], page = 1) {
        const streamResponse = await this.socketRestClient.requestRest(topic => {
            return this.brainClient.openAi.getWeeeGroceriesBase(input, page, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo), topic);
        }, result => {
            const res = result.data?.data;
            const pagination = new SearchInfoWrapper_1.SearchInfoPagination(res?.pagination?.page || page, res?.pagination?.totalPage);
            const searchInfoWrapper = new SearchInfoWrapper_1.SearchInfoWrapper(input, pagination, res?.items || [], res?.source);
            return new FunctionBase_1.FuncDataSource(FunctionBase_1.FuncDataSourceType.WEEE_GROCERIES, searchInfoWrapper);
        }, "weeGroceries", identityInfo);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.WEEE_GROCERIES, streamResponse);
    }
}
exports.WeeeGroceriesFunction = WeeeGroceriesFunction;
