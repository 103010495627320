"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryEdge = exports.EdgeState = void 0;
const Eventbus_1 = require("../../cutil/Eventbus");
var EdgeState;
(function (EdgeState) {
    EdgeState["IDLE"] = "IDLE";
    EdgeState["LOADING"] = "LOADING";
    EdgeState["COMPLETED"] = "COMPLETED";
    EdgeState["ERROR"] = "ERROR";
})(EdgeState || (exports.EdgeState = EdgeState = {}));
/**
 * source 节点只保留基本元素，不包含 children 和 edges 两个完整的数据（避免序列化失败）
 * 如果需要完整的 `StoryEdge` 数据可以通过 Story.findEdgeSourceByNode 或者 Story.findEdgeSourceByEdge 两个方法获取完整的属性
 */
class StoryEdge {
    constructor(options) {
        this.options = options;
        this.state = EdgeState.IDLE;
        this._eventbus = new Eventbus_1.Eventbus();
    }
    targetNode() {
        return this.target?.target;
    }
    reset() {
        this.state = EdgeState.IDLE;
        this.targetNode()?.reset();
        this.targetNode()?.lines.forEach(x => x.target.reset());
    }
    /**
     * 查找和当前node相关的node，返回结果中包含了group的子节点
     */
    findRelatedNodes() {
        let nodes = [];
        const node = this.targetNode();
        // 添加当前node
        if (node) {
            nodes.push(node);
        }
        // 获取childChildren
        const childrenNodes = this.targetNode()?.findChildren();
        if (childrenNodes) {
            nodes.push(...childrenNodes);
        }
        // 获取边链接出去的nodes
        const related = this.targetNode()?.lines.flatMap(x => {
            return x.target.findRelatedNodes();
        });
        if (related) {
            nodes.push(...related);
        }
        return nodes;
    }
    resetState() {
        this.state = EdgeState.IDLE;
        this.targetNode()?.resetState();
        this.targetNode()?.lines.forEach(x => x.target.resetState());
    }
    /// 只会改变当前edge和它的targetNode的loading状态
    setLoading() {
        this.state = EdgeState.LOADING;
        this.targetNode()?.setLoading();
    }
    /// 边 以及 边后面相关的边，都会变成Completed
    setCompleted() {
        this.state = EdgeState.COMPLETED;
        this.targetNode()?.setCompleted();
        this.targetNode()?.lines.forEach(x => x.target.setCompleted());
    }
    /**
     * 找到这条边出去的最后一条边
     */
    findLastEdge() {
        const last = this.targetNode()?.edges().first()?.findLastEdge();
        return last ?? this;
    }
}
exports.StoryEdge = StoryEdge;
