"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralApi = void 0;
class GeneralApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    post(url, data, options, headers = {
        "Content-Type": "application/json",
    }) {
        return this.httpRequest.post(url, data, options, headers);
    }
}
exports.GeneralApi = GeneralApi;
