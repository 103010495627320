"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodePosition = exports.Identifier = exports.NodeType = exports.NodeContent = exports.NodeData = exports.FlowNode = exports.FlowEdge = exports.CotTemplateGraph = exports.CotTemplateResponseData = void 0;
const class_transformer_1 = require("class-transformer");
class CotTemplateResponseData {
    constructor() {
        this.action_sequence = [];
        this.detail = null;
        this.graph = new CotTemplateGraph();
        this.interface = [];
        this.unsupported_interface = [];
        this.unsupported_action_sequence = [];
    }
}
exports.CotTemplateResponseData = CotTemplateResponseData;
__decorate([
    (0, class_transformer_1.Type)(() => CotTemplateGraph)
], CotTemplateResponseData.prototype, "graph", void 0);
class CotTemplateGraph {
    constructor() {
        this.edges = [];
        this.nodes = [];
    }
}
exports.CotTemplateGraph = CotTemplateGraph;
__decorate([
    (0, class_transformer_1.Type)(() => FlowEdge)
], CotTemplateGraph.prototype, "edges", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => FlowNode)
], CotTemplateGraph.prototype, "nodes", void 0);
class FlowEdge {
    constructor() {
        this.id = "";
        this.source = "";
        this.sourceHandle = "";
        this.target = "";
        this.targetHandle = "";
        this.type = "";
        this.blueprints = [];
    }
}
exports.FlowEdge = FlowEdge;
class FlowNode {
    constructor() {
        this.id = "";
        this.design = "";
        this.inputs = [];
        this.data = new NodeData();
    }
}
exports.FlowNode = FlowNode;
__decorate([
    (0, class_transformer_1.Type)(() => NodeContent)
], FlowNode.prototype, "content", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => NodeData)
], FlowNode.prototype, "data", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => NodePosition)
], FlowNode.prototype, "position", void 0);
class NodeData {
    constructor() {
        this.description = "";
        this.executed = false;
        this.function_description = "";
        this.function_type = "";
        this.id = "";
        this.modality = "";
        this.mode = "default";
        this.name = "";
        this.node_type = "";
        this.realtime = false;
        this.group = false;
        this.content = "";
    }
}
exports.NodeData = NodeData;
__decorate([
    (0, class_transformer_1.Type)(() => Identifier)
], NodeData.prototype, "identifier", void 0);
class NodeContent {
    constructor() {
        this.text = "";
    }
}
exports.NodeContent = NodeContent;
var NodeType;
(function (NodeType) {
    NodeType["CONNECTION"] = "connection";
    NodeType["INTERACTION"] = "interaction";
    NodeType["USERINPUT"] = "userinput";
    NodeType["CONTENT"] = "content";
    NodeType["INPUT"] = "input";
})(NodeType || (exports.NodeType = NodeType = {}));
class Identifier {
    constructor() {
        this.identifier_type = "";
        this.identifier_value = "";
    }
}
exports.Identifier = Identifier;
class NodePosition {
    constructor() {
        this.x = 0;
        this.y = 0;
    }
}
exports.NodePosition = NodePosition;
