import { createSlice } from "@reduxjs/toolkit";

const homeScreenSlice = createSlice({
  name: "homeScreen",
  initialState: {
    homeCards: [],
  },
  reducers: {
    setHomeCards(state, action) {
      const homeCards = action.payload;
      state.homeCards = homeCards;
    },
  },
});

export const homeScreenActions = homeScreenSlice.actions;
export default homeScreenSlice.reducer;
