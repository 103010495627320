import { PreviewPaneBloc } from "@uiview/views/PreviewPane/PreviewPaneBloc";
// import { AccountStatus } from "@uiview/views/ShareAccount/AccountStatus";
import { useCreation } from "ahooks";
import { ThemeType } from "imagica-corekit/dist/cases/theme/appTheme/AiAppThemeData";
import { FontType } from "imagica-corekit/dist/cases/theme/fontTheme/FontData";
import { useEffect, useRef } from "react";
import { AISaasProvider, useAISaasContainer } from "../container";
import { AiSaasOutputList } from "./AISaasOutput";
import { AISaasHeader } from "./AiSaasHeader";
import css from "./index.module.scss";
import { FooterUrl } from "@uiview/views/ShareAccount/FooterUrl";
import { GetItContext } from "imagica-uikit/dist/base/provider/GetItContext";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";

function AISaasInner(): JSX.Element {
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const contentRef = useRef(null);
  const { thoughtOptions, onChangeThoughts } = useAISaasContainer();
  const { theme, settings, title } = saasUIData;
  const bloc = useCreation(
    () =>
      new PreviewPaneBloc({
        saasUIData,
        setSaasUIData: creatorSaasAppStore.setSaasUIData,
        themeContainer: contentRef,
      }),
    []
  );

  useEffect(() => {
    bloc.changeTheme(theme as ThemeType);
  }, [bloc, contentRef, theme]);

  useEffect(() => {
    const fontFamily = settings?.fontFamily || FontType.AKREGULAR;
    bloc.changeFont(fontFamily);
  }, [bloc, settings?.fontFamily]);

  useEffect(() => {
    setTimeout(() => {
      document.title = title || "Untitled";
    });
  }, [title]);

  const isMobile = window.navigator.userAgent.includes("Mobile");
  const showInfo = !isMobile && !!thoughtOptions.length;
  return (
    <div className={`${css["AISaasShare"]} ${css["saas-content"]}`} ref={contentRef}>
      <AISaasHeader />
      <AiSaasOutputList thoughtOptions={thoughtOptions} onChangeThoughts={onChangeThoughts} />
      <FooterUrl showInfo={showInfo} />
      {/* <AccountStatus className={css.account} /> */}
    </div>
  );
}

export function AISaasApp(props: AISaas.AISaasStoreProps): JSX.Element {
  return (
    <GetItContext.Provider value={getIt}>
      <AISaasProvider initialState={props}>
        <AISaasInner />
      </AISaasProvider>
    </GetItContext.Provider>
  );
}
