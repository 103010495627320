"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryLineOptionsUtil = void 0;
class StoryLineOptionsUtil {
    static maxIdNumber(story) {
        const nodeIds = story
            .findAllLines()
            .map(node => node.options.id)
            .map(id => id.split("-").last() ?? "0")
            .map(id => parseInt(id));
        return Math.max(...nodeIds);
    }
    static idNumber(node) {
        return parseInt(node.options.id.split("-").last() ?? "0");
    }
}
exports.StoryLineOptionsUtil = StoryLineOptionsUtil;
