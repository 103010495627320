"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoppingResponse = exports.ShoppingData = exports.ShoppingPage = exports.ShoppingItem = void 0;
class ShoppingItem {
    constructor() {
        this.id = "";
        this.by = "";
        this.title = "";
        this.image = "";
        this.oldPrice = 0;
        this.price = 0;
        this.rating = 0;
        this.reviews = 0;
        this.detailKey = "";
        this.storeName = "";
        this.link = "";
        this.prime = false;
    }
}
exports.ShoppingItem = ShoppingItem;
class ShoppingPage {
    constructor() {
        this.currIndex = 1;
        this.totalPage = 1;
    }
}
exports.ShoppingPage = ShoppingPage;
class ShoppingData {
    constructor() {
        this.source = "";
        this.cache = false;
        this.empty = false;
        this.items = [];
        this.page = {
            currIndex: 1,
            totalPage: 1,
        };
    }
}
exports.ShoppingData = ShoppingData;
class ShoppingResponse {
    constructor() {
        this.done = false;
    }
}
exports.ShoppingResponse = ShoppingResponse;
