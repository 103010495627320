import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import style from "../components-css/PitchDesk.module.css";
import publicMethod from "@uikit/publicMethod";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

function PitchDesk(props) {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const focusedThought = useSelector(state => state.feed.focusedThought);

  const [currIdx, setCurrIdx] = useState(0);
  const [deck, setDeck] = useState([{}]);
  const [loading, setLoading] = useState(false);

  const projectName = "jerry_deck_demo";

  const handleClickRefresh = async () => {
    const res = await publicMethod.getUIFromIntelligentAppProject(projectName, focusedThought.thought, setLoading);
    const deckResult = res?.variables?.slide_deck?.value;
    setDeck(handleRawDeckResult(deckResult));
  };

  const clickItem = index => {
    setCurrIdx(() => index);
  };

  const handleClickCard = e => {
    e.stopPropagation();
  };

  const handleRawDeckResult = raw => {
    if (!(raw instanceof Array)) return [];
    const arr = [];
    const reg = /^slide\s*[0-9]+\:*\s*/i;
    raw.forEach(x => {
      if (reg.test(x)) {
        const str = x.replace(reg, "");
        const reg1 = /^\b([^-]*)-/;
        const execArr = reg1.exec(str);
        const title = execArr?.[1]?.trim() || "";
        const content = str.replace(reg1, "");
        arr.push({ title, content });
      }
    });
    return arr;
  };

  useEffect(() => {
    setDeck(handleRawDeckResult(props.content));
  }, []);

  return (
    <div className={style["pitch-desk"]} onClick={handleClickCard}>
      <div className={style["h3-box"]}>
        <h3>Pitch deck presenation</h3>
        <span className="iconfont icon-right"></span>
      </div>

      <p className={style.content}>Here is the generated pitch deck for you.</p>
      <Spin indicator={antIcon} spinning={loading} tip="refreshing...">
        <div className={style["slide-box"]}>
          <div className={style["big-content"]}>
            <img src={`${settings.S3CDN}${settings.viewAssetsPath}deck_bg.png`} className={style["deck_bg"]} />
            <div className={style["inslide-box"]}>
              <label>{(deck[currIdx] || {}).title}</label>
              <p className={(deck[currIdx] || {}).title == "" ? "" : style["mar-top"]}>
                {(deck[currIdx] || {}).content}
              </p>
            </div>
          </div>
          <div className={style["scroll-list"]}>
            <div className={style["scroll-in_list"]}>
              {deck.map((x, idx) => {
                return (
                  <div
                    key={idx}
                    className={idx == currIdx ? style["selected-item"] : ""}
                    onClick={() => clickItem(idx)}
                  >
                    <label>{x.title}</label>
                    <p>{x.content}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="refresh-box" onClick={handleClickRefresh}>
            <img src={`${settings.S3CDN}${settings.viewAssetsPath}refresh.svg`} />
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default PitchDesk;
