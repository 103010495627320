import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { HomeMethodsService } from "./HomeMethodsService";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { isEmpty, last } from "lodash";
import { HomeToolBarStore } from "@uikit/store/homeToolBarStore";
import func from "@uikit/func";
import { logEvent } from "@amplitude/analytics-browser";
import { NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import { markerEnd, selectApiReg, transparentMarkerEnd } from "@uiview/views/ProjectCanvas/homeConst";
import { CopyUtil } from "@uikit/util/CopyUtil";
import { easeInOutExpo, sleep } from "@views/thinking-layout-editor/util";
import { CustomFunctionStore } from "@uikit/store/CustomFunctionStore";
import utilIndex from "@views/thinking-layout-editor/utils";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";

/**
 * 原来 useCreatorStoreMethods hook 方法
 *
 * 内部有大量方法使用箭头函数，为了保证调用时 不会丢失this
 */
export class CreatorStoreMethods {
  constructor(
    public fotReduxStore: FotReduxStore,
    public homePluginStore: HomePluginStore,
    public homeMethods: HomeMethodsService,
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public creatorRefStore: CreatorRefStore,
    public homeToolBarStore: HomeToolBarStore,
    public customFunctionStore: CustomFunctionStore,
    public canvasDataRef: CanvasDataRef
  ) {}

  setEdgeLineParam = (newLineParam: any): void => {
    this.creatorNodesStore.setNodes(prevList => {
      return prevList.map(l => {
        if (l.id === newLineParam.id) {
          return {
            ...l,
            data: {
              ...l.data,
              lineParam: {
                ...l.data.lineParam,
                ...newLineParam,
              },
            },
          };
        }
        return l;
      });
    });
  };

  /**
   * TODO: move creatorNodesStore
   * @param nodeId
   * @param position
   */
  private updateNodePostion(nodeId: string, position: any): void {
    this.creatorNodesStore.setNodes(prevList => {
      return prevList.map(x => {
        if (x.id === nodeId) {
          return {
            ...x,
            position,
          };
        }
        return x;
      });
    });
  }
  /**
   * TODO: move creatorNodesStore
   * @param nodeId
   * @param position
   */
  setNodeParam = (newNodeParam: { id: string; data: any }): void => {
    this.creatorNodesStore.setNodes(prevList => {
      return prevList.map(l => {
        if (l.id === newNodeParam.id) {
          return {
            ...l,
            data: {
              ...l.data,
              ...newNodeParam.data,
            },
          };
        }
        return l;
      });
    });
  };

  replaceGroupEdge(oldArr: any[], newArr: any[]): void {
    oldArr.forEach((x, index) => {
      const currSourceEdges = this.creatorEdgesStore.getEdges().filter(y => y.source === x);
      if (isEmpty(currSourceEdges)) return;
      currSourceEdges.forEach(y => {
        this.creatorEdgesStore.setEdges(prevList => {
          return prevList.map(l => {
            if (l.id === y.id) {
              return Object.assign({}, { ...l }, { source: newArr[index].id });
            }
            return l;
          });
        });
      });
    });
  }

  resetHistoryIndex(): void {
    this.homePluginStore.undoData.current = this.homePluginStore.undoData.current.slice(
      this.homeToolBarStore.state.historyIndex + 1
    );
    this.homeToolBarStore.setHistoryIndex(-1);
  }

  onNodeContentChange = (nodesData: any): void => {
    if (func.isEmpty(nodesData)) return;
    this.resetHistoryIndex();
    this.homePluginStore.undoData.current = [
      {
        nodes: nodesData,
        edges: [],
        type: "nodeContentChange",
      },
      ...this.homePluginStore.undoData.current,
    ];
  };
  onEdgeContentChange = (edgesData: any): void => {
    this.resetHistoryIndex();
    this.homePluginStore.undoData.current = [
      {
        nodes: [],
        edges: edgesData,
        type: "edgeContentChange",
      },
      ...this.homePluginStore.undoData.current,
    ];
  };

  onNodesEdgesAdd = (addNodes: any[], addEdges: any[], relevantNodes = []): void => {
    this.resetHistoryIndex();
    this.homePluginStore.undoData.current = [
      {
        nodes: addNodes,
        relevantNodes,
        edges: addEdges,
        type: "add",
      },
      ...this.homePluginStore.undoData.current,
    ];
  };

  getHomeNodesIndex = (): number => {
    return this.creatorRefStore.nodeIndexRef.current;
  };
  getHomeEdgesIndex = (): number => {
    return this.creatorRefStore.edgeIndexRef.current;
  };
  getHomeNextNodeIndex = (): number => {
    return ++this.creatorRefStore.nodeIndexRef.current;
  };

  // 通过 id 查找目标 node id
  getTargetNodeId(id: any): any {
    return this.creatorEdgesStore.getEdges().find(x => !func.isEmpty(x.data?.lineParam?.enterText) && x.source === id)
      ?.target;
  }

  /**
   * @deprecated use `homePluginStore.resetUndoData`
   */
  resetUndoData = (): void => {
    this.homePluginStore.resetUndoData();
  };

  onNodesResize = (resizeNode: any, callback: (params: Record<string, number>) => void): void => {
    this.resetHistoryIndex();
    this.homePluginStore.undoData.current = [
      {
        node: resizeNode,
        callback,
        type: "resize",
      },
      ...this.homePluginStore.undoData.current,
    ];
  };

  copyNode(nodeArr: string | string[], isToolBarCopy = false): void {
    this.creatorRefStore.copyNodes.current = [];
    this.creatorNodesStore.getNodes().forEach(x => {
      if (nodeArr.includes(x.id)) {
        const newNode = {
          ...x,
          id: "",
          type: x.type,
          data: {
            ...x.data,
            originId: x.id,
          },
          position: {
            ...x.position,
          },
          style: x.style,
        };

        this.creatorRefStore.copyNodes.current.push(newNode);
      }
    });

    if (isToolBarCopy) {
      logEvent("click", {
        target: "rightmenu_copy",
        content: {
          copyNodes: this.creatorRefStore.copyNodes.current,
        },
      });
    }
  }

  copyLine(lineArr: string | any[]): void {
    this.creatorRefStore.copyEdges.current = [];
    // 将选中的边以及边链接的node储存起来
    this.creatorEdgesStore.getEdges().forEach(x => {
      if (lineArr.includes(x.id)) {
        const newEdge = {
          id: "",
          type: x.type,
          animated: false,
          source: "",
          target: "",
          markerEnd: NEW_EDGE_REG.test(x.target) ? transparentMarkerEnd : markerEnd,
          data: {
            ...x.data,
            originId: x.id,
            originSource: x.source,
            originTarget: x.target,
          },
        };
        this.creatorRefStore.copyEdges.current.push(newEdge);
      }
    });
  }

  copyNodeAndEdge = (): void => {
    // opt:
    const nodes = this.creatorNodesStore.getNodes();
    const edges = this.creatorEdgesStore.getEdges();

    // const nodes = this.creatorNodesStore.getNodes();
    // const edges = this.creatorEdgesStore.getEdges();
    // opt:
    const checkEdgeArr = this.fotReduxStore.getState().fot.checkEdgeArr as any[];
    const checkNodeArr = this.fotReduxStore.getState().fot.checkNodeArr as any[];

    const { nodeIds, lineIds } = CopyUtil.copyFlow(checkEdgeArr, checkNodeArr, nodes as any[], edges);

    this.copyNode(nodeIds);
    this.copyLine(lineIds);
    this.fotReduxStore.setCheckEdgeArr(lineIds);

    if (!func.isEmpty(nodeIds) || !func.isEmpty(lineIds)) {
      func.customMsg({
        content: "Copied",
        type: "info",
      });
    } else {
      func.customMsg({
        content: "No node or edge selected",
        type: "info",
      });
    }

    logEvent("click", {
      target: "rightmenu_copy",
      content: {
        copyNodes: this.creatorRefStore.copyNodes.current,
        copyEdges: this.creatorRefStore.copyEdges.current,
      },
    });
  };

  translateNode(newNode: { position: any; id: any }, direction: string, offsetDistance: number, count = 10): void {
    let times = 1;
    const newNodePosition = newNode.position;
    const timer = setInterval(() => {
      if (times > count) {
        clearInterval(timer);
        return;
      }
      const currentDistance = (easeInOutExpo(times / count) - easeInOutExpo((times - 1) / count)) * offsetDistance;
      if (direction === "top") {
        newNodePosition.y -= currentDistance;
      } else if (direction === "right") {
        newNodePosition.x += currentDistance;
      } else if (direction === "bottom") {
        newNodePosition.y += currentDistance;
      } else if (direction === "left") {
        newNodePosition.x -= currentDistance;
      }
      this.updateNodePostion(newNode.id, newNodePosition);
      times += 1;
    }, 10);
  }

  getItemRelavantFunctions = (): any[] => {
    // 本地所有自定义 function
    const funcArrs = this.creatorRefStore.singleFlowEdgeArrRef.current || [];

    // 当前接口所保存自定义 function
    if (!func.isEmpty(this.customFunctionStore.customFunctionRef.current)) {
      // 去重以本地创建 function 为主
      const noDuplicateArr = this.customFunctionStore.customFunctionRef.current.filter((x: any) => {
        return !funcArrs.some(y => y.name === x.name);
      });
      funcArrs.push(...noDuplicateArr);
    }

    // 所有用到的自定义 func
    const edgesNameArr = this.creatorEdgesStore
      .getEdges()
      .map(x => {
        return selectApiReg.test(x.data.lineParam?.enterText) && x.data.lineParam.enterText.replace("/", "");
      })
      .filter(x => x);
    const usedFunctions = funcArrs.filter(x => edgesNameArr.includes(x.name));
    return usedFunctions;
  };

  onNodesEdgesDrag = (dragNodes: any): void => {
    this.resetHistoryIndex();
    this.homePluginStore.undoData.current = [
      {
        nodes: dragNodes,
        edges: [],
        type: "drag",
      },
      ...this.homePluginStore.undoData.current,
    ];
  };
  onNodesEdgesDel = (delNodes: any, delEdges: any, relevantNodes = []): void => {
    this.resetHistoryIndex();
    this.homePluginStore.undoData.current = [
      {
        nodes: delNodes,
        relevantNodes,
        edges: delEdges,
        type: "delete",
      },
      ...this.homePluginStore.undoData.current,
    ];
  };

  handleCopyId(): void {
    const edgeIdMap = {} as any;
    const nodeIdMap = {} as any;
    this.creatorRefStore.copyNodes.current = this.creatorRefStore.copyNodes.current.map(x => {
      this.creatorRefStore.nodeIndexRef.current++;
      const nodeId = utilIndex.createIdByNodeType(x.type, this.creatorRefStore.nodeIndexRef.current);
      nodeIdMap[x.data.originId] = nodeId;
      const nodeObj = {
        ...x,
        id: nodeId,
        type: x.type,
        selected: false,
        data: {
          ...x.data,
        },
        position: {
          ...x.position,
        },
      };
      return nodeObj;
    });
    if (!func.isEmpty(this.creatorRefStore.copyEdges.current)) {
      this.creatorRefStore.copyEdges.current = this.creatorRefStore.copyEdges.current.map(x => {
        this.creatorRefStore.edgeIndexRef.current++;
        const source = this.creatorRefStore.copyNodes.current.find(n => n.data.originId === x.data.originSource);
        const target = this.creatorRefStore.copyNodes.current.find(n => n.data.originId === x.data.originTarget);
        if (func.isEmpty(source) || func.isEmpty(target)) {
          return null;
        }
        const newEdgeId = utilIndex.createIdByEdgeType(x.type, this.creatorRefStore.edgeIndexRef.current);
        edgeIdMap[x.data.originId] = newEdgeId;
        const newEdge = {
          id: newEdgeId,
          type: x.type,
          animated: false,
          source: source.id,
          sourceHandle: x.sourceHandle,
          target: target.id,
          targetHandle: x.targetHandle,
          markerEnd: NEW_EDGE_REG.test(target.id) ? transparentMarkerEnd : markerEnd,
          style: x.style,
          selected: false,
          data: x.data,
        };
        return newEdge;
      });
      this.creatorRefStore.copyEdges.current = this.creatorRefStore.copyEdges.current.filter(x => !func.isEmpty(x));
    }
    for (const x of this.creatorRefStore.copyNodes.current) {
      if (x.type === "customNewEdge") {
        x.data.targetLineId = edgeIdMap[x.data.targetLineId];
        x.data.targetNodeId = nodeIdMap[x.data.targetNodeId];
        x.data.flows = x.data.flows.map(({ sourceLineId, sourceNodeId, creationMethod }: any) => {
          return {
            sourceNodeId: nodeIdMap[sourceNodeId],
            sourceLineId: edgeIdMap[sourceLineId],
            creationMethod: creationMethod || false,
          };
        });
      }
      if (x.type === "customNode" && !func.isEmpty(x.parentNode)) {
        const newParentNodeId = nodeIdMap[x.data.parentNodeId];
        x.data.parentNodeId = newParentNodeId;
        x.parentNode = newParentNodeId;
        x.zIndex = 1;
      }
      if (x.type === "customGroup" && !func.isEmpty(x.data.childNode)) {
        x.data.childNode = x.data.childNode.map((i: string | number) => {
          return nodeIdMap[i];
        });
      }
    }
  }

  pasteNodeAndEdge(target: { x: number; y: number }): void {
    // 3123
    if (func.isEmpty(this.creatorRefStore.copyNodes.current)) return;

    const oldCopyNodes = this.creatorRefStore.copyNodes.current;
    const oldCopyEdges = this.creatorRefStore.copyEdges.current;

    this.handleCopyId();

    // const { x, y, zoom } = getViewPort();
    //找到最后一个node和edge的id序号
    this.creatorRefStore.copyNodes.current.sort(
      (a, b) => last(a.id.split("-") as any[]) - last(b.id.split("-") as any[])
    );
    const {
      id: leftNodeId,
      x: prevX,
      y: prevY,
    } = this.creatorRefStore.copyNodes.current.reduce(
      (result, current) => {
        if (func.isEmpty(current.parentNode) && current.position.x < result.x) {
          return {
            id: current.id,
            x: current.position.x,
            y: current.position.y,
          };
        }
        return result;
      },
      { id: "", x: Infinity, y: Infinity }
    );
    // 新的粘贴原点位置
    const newOriginX = target.x; // (args.clientX - x) / zoom;
    const newOriginY = target.y; // (args.clientY - y) / zoom;

    this.creatorRefStore.copyNodes.current.forEach(n => {
      // 将最左侧节点位置设置为粘贴时鼠标所在位置
      if (n.id === leftNodeId) {
        n.position.x = newOriginX;
        n.position.y = newOriginY;
        return;
      }
      if (func.isEmpty(n.data.parentNodeId)) {
        // 其他节点依据最左侧节点重新定位
        n.position.x = n.position.x - prevX + newOriginX;
        n.position.y = n.position.y - prevY + newOriginY;
      }
    });

    this.creatorNodesStore.setNodes(prevList => [...prevList, ...this.creatorRefStore.copyNodes.current]);

    if (!func.isEmpty(this.creatorRefStore.copyEdges.current)) {
      this.creatorEdgesStore.setEdges(prevList => [...prevList, ...this.creatorRefStore.copyEdges.current]);
    }

    const newSimpleNodes = this.creatorRefStore.copyNodes.current.map(node => this.canvasDataRef.getSimpleNode(node));
    const newSimpleEdges = this.creatorRefStore.copyEdges.current.map(node => this.canvasDataRef.getSimpleEdge(node));
    this.onNodesEdgesAdd(newSimpleNodes, newSimpleEdges);

    logEvent("click", {
      target: "rightmenu_paste",
      content: {
        copyNodes: this.creatorRefStore.copyNodes.current,
        copyEdges: this.creatorRefStore.copyEdges.current,
      },
    });
    // 由于修改了copyNodes 需要重制粘贴板数据
    // 否则再次粘贴会id错乱
    this.creatorRefStore.copyNodes.current = oldCopyNodes;
    this.creatorRefStore.copyEdges.current = oldCopyEdges;
  }

  /**
   * TODO: 移动到 creatorEdgesStore
   * @param edge
   * @param variables
   */
  setVarilableNames(edge: { id: any }, variables: any): void {
    this.creatorEdgesStore.setEdges(prevList => {
      return prevList.map(l => {
        const arr = l?.data?.lineParam?.varilableNames || [];
        if (l.id === edge.id && !arr.includes(variables)) {
          return {
            ...l,
            data: {
              ...l.data,
              lineParam: {
                ...l.data.lineParam,
                varilableNames: [...arr, variables],
              },
            },
          };
        }
        return l;
      });
    });
  }

  /**
   * TODO: 移动到 creatorEdgesStore
   * @param id
   * @param identifier
   */
  setIdentifier(id: any, identifier: any): void {
    this.creatorEdgesStore.setEdges(prevList => {
      return prevList.map(l => {
        if (l.id === id) {
          return {
            ...l,
            data: {
              ...l.data,
              lineParam: {
                ...l.data.lineParam,
                identifier,
              },
            },
          };
        }
        return l;
      });
    });
  }

  /**
   * TODO: 移动到 creatorNodesStore
   * @param targetNodeId
   */
  setTargetNodeShowFeedback(targetNodeId: string): void {
    this.creatorNodesStore.setNodes(prevList => {
      return prevList.map(l => {
        if (l.id === targetNodeId) {
          return {
            ...l,
            data: {
              ...l.data,
              showFeedback: true,
            },
          };
        }
        return l;
      });
    });
  }

  async setEdgeValueByWriter(edge: any): Promise<void> {
    const id = edge.id;
    const fullText = edge.data.lineParam.enterText;
    const write = async (i: number): Promise<void> => {
      await sleep(20);
      const txt = fullText.slice(0, i);
      this.creatorEdgesStore.setEdges(prevList => {
        return prevList.map(l => {
          if (l.id === id) {
            return {
              ...l,
              data: {
                ...l.data,
                lineParam: {
                  ...l.data.lineParam,
                  enterText: txt,
                },
              },
            };
          }
          return l;
        });
      });
      if (i < fullText.length) {
        await write(i + 2);
      }
    };
    await write(1);
  }

  /**
   * 点击空白面白清空选中nodes及edges
   *
   * 该方法可能不在需要
   */
  onPaneClick = (): void => {
    this.fotReduxStore.setPreSelectNodeId("");
    this.fotReduxStore.setCheckNodeArr([]);
    this.fotReduxStore.setCheckEdgeArr([]);
    this.fotReduxStore.setHasSelectedArea(false);
    const hoverEdgeData = this.fotReduxStore.getState().editor.hoverEdgeData as any;
    this.fotReduxStore.setHoverEdgeData({
      state: false,
      lineIds: hoverEdgeData.lineIds,
      edgeId: hoverEdgeData.id,
    });
  };

  hideMultiEdgeRenderBox(): void {
    this.fotReduxStore.setSameTargetEdgeIds([]);
    this.fotReduxStore.setMultiSameTargetEdgeIds([]);
    this.creatorNodesStore.getNodes().forEach(x => {
      let sameTargetEdges = this.creatorEdgesStore.getEdges().filter(y => x.id === y.target) as any[];
      if (sameTargetEdges.length >= 1) {
        sameTargetEdges = sameTargetEdges.sort((a, b) => {
          return a.id.split("-")[1] - b.id.split("-")[1];
        });
        const sameTargetEdgeIds = this.fotReduxStore.getState().editor.sameTargetEdgeIds as any[];
        if (!sameTargetEdgeIds.includes(sameTargetEdges[0].id)) {
          this.fotReduxStore.setSameTargetEdgeIds([...sameTargetEdgeIds, sameTargetEdges[0].id]);
        }
        const multiSameTargetEdgeIds = this.fotReduxStore.getState().editor.multiSameTargetEdgeIds as any[];
        if (sameTargetEdges.length > 1 && !multiSameTargetEdgeIds.includes(sameTargetEdges[0].id)) {
          this.fotReduxStore.setMultiSameTargetEdgeIds([...multiSameTargetEdgeIds, sameTargetEdges[0].id]);
        }
      }
    });
  }
}
