"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatThemeType = void 0;
var ChatThemeType;
(function (ChatThemeType) {
    ChatThemeType["LIGHT"] = "light";
    ChatThemeType["DARK"] = "dark";
    ChatThemeType["CHATTHEMEA"] = "chatThemeA";
    ChatThemeType["CHATTHEMEB"] = "chatThemeB";
    ChatThemeType["CHATTHEMEC"] = "chatThemeC";
    ChatThemeType["CHATTHEMED"] = "chatThemeD";
    ChatThemeType["CHATTHEMEE"] = "chatThemeE";
    ChatThemeType["CHATTHEMEF"] = "chatThemeF";
    ChatThemeType["CHATTHEMEG"] = "chatThemeG";
})(ChatThemeType || (exports.ChatThemeType = ChatThemeType = {}));
