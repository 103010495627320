import { isEmpty, camelCase } from "lodash";
import { Position, MarkerType } from "reactflow";
import func from "@uikit/func";
import { uploadTypes } from "@uiview/views/Nodes/constants";
import { EDITORABLE_INPUT_TYPE } from "@views/thinking-layout-editor/constants";
import { getPermissions } from "@custom-hooks/useFeatureTags";
import store from "@store/index";
import { StoryNodeDisplayType } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeDisplayType";
const groupReg = /^group/;

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.positionAbsolute;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;
  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

export function createNodesAndEdges() {
  const nodes = [];
  const edges = [];
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

  nodes.push({ id: "target", data: { label: "Target" }, position: center });

  for (let i = 0; i < 8; i++) {
    const degrees = i * (360 / 8);
    const radians = degrees * (Math.PI / 180);
    const x = 250 * Math.cos(radians) + center.x;
    const y = 250 * Math.sin(radians) + center.y;

    nodes.push({ id: `${i}`, data: { label: "Source" }, position: { x, y } });

    edges.push({
      id: `edge-${i}`,
      target: "target",
      source: `${i}`,
      type: "floating",
      markerEnd: {
        type: MarkerType.Arrow,
      },
    });
  }

  return { nodes, edges };
}

/**
 * 获取元素中心坐标点
 * @param
 */
export function getCenterPositionByElement(element) {
  if (!element) {
    return {
      x: halfValue(window.innerWidth),
      y: halfValue(window.innerHeight),
    };
  }

  const rect = element.getBoundingClientRect();
  return {
    x: rect.x + halfValue(rect.width),
    y: rect.y + halfValue(rect.height),
  };
}

export function halfValue(value) {
  return value / 2;
}

export function easeInOutExpo(x) {
  return x === 0 ? 0 : x === 1 ? 1 : 1 - Math.pow(1 - x, 5);
}
export function centToDollar(cent) {
  if (isNaN(Number(cent))) return NaN;
  return +(cent / 100).toFixed(2);
}

export function getQueryParams() {
  const result = {}; // 存参数得对象
  const urlString = window.location.search || `?${window.location.hash.split("?")[1]}`;

  let reg = /[?&][^?&]+=[^?&]+/g;
  const found = urlString.match(reg); //拿到有符合正则得字符串，输出为数组
  if (found) {
    found.forEach(item => {
      let temp = item.substring(1).split("="); // = 分割
      let key = temp[0];
      let value = temp[1];
      result[key] = value;
    });
  }
  return result;
}

export function formatToHump(text = "") {
  if (isEmpty(text) && typeof text !== "string") {
    return "";
  }
  text = camelCase(text.trim());
  return text.slice(0, 1).toUpperCase() + text.slice(1);
}

export function getStartNodes(nodes, edges, isChat) {
  const sourceNodes = edges.map(x => x.source);
  const targetNodes = edges.map(x => x.target);
  const permissions = getPermissions(store.getState().editor.me);
  const disableUploadTypes = permissions.disableUploadTypesAsInputNode;

  //不是子node, 不是group, 只作为边的起点
  const startNodeId = nodes
    .filter(x => {
      //已有的逻辑
      let notChatCondition =
        (disableUploadTypes ? !uploadTypes.includes(x.data.displayType) : true) &&
        func.isEmpty(x.parentNode) &&
        !groupReg.test(x.id) &&
        sourceNodes.includes(x.id) &&
        !targetNodes.includes(x.id);
      if (isChat)
        notChatCondition =
          notChatCondition &&
          (uploadTypes.includes(x.data.displayType) || EDITORABLE_INPUT_TYPE.includes(x.data.displayType));
      //当前preview是chat，并且chat node没有连线
      const isSingleChat =
        isChat &&
        edges.every(edge => edge.source !== x.id && edge.target !== x.id) &&
        x.data.displayType === StoryNodeDisplayType.CHATINTERFACE;
      return notChatCondition || isSingleChat;
    })
    .map(x => x.id);
  return startNodeId;
}

// STUD-1425, get a list of nodes that have no edges connected to it
export function getInputNodesForNodeLabel(nodes, edges) {
  // const sourceNodes = edges.map(x => x.source);
  const targetNodes = edges.map(x => x.target);
  return nodes
    .filter(x => isEmpty(x.parentNode) && x.type === "customNode")
    .map(x => x.id)
    .filter(x => !targetNodes.includes(x));
}

export const sleep = seconds => new Promise(resolve => setTimeout(resolve, seconds));

// 有且仅有 chatInterface 时也可以Publish
export const isOnlyChatInterface = output => {
  let isOnlyChat = false;
  if (output?.length === 1 && output[0].results?.length === 1) {
    isOnlyChat = output[0].results[0].type === "chatInterface";
  }
  return isOnlyChat;
};

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
