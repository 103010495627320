"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardDataBaseService = exports.VBComponent = exports.CardDataBaseParams = void 0;
const viewBuilderGraph_1 = require("../../../base/api/viewBuilderTyped/viewBuilderGraph");
class CardDataBaseParams {
    constructor() {
        this.keyWords = "";
    }
}
exports.CardDataBaseParams = CardDataBaseParams;
// export class CardDataBaseResponse extends VBGraphDataBase{}
class VBComponent extends viewBuilderGraph_1.ViewBuilderGraphDesignSectionComponent {
}
exports.VBComponent = VBComponent;
class CardDataBaseService {
    /**
     * 搜索
     */
    async search(params) {
        throw `not implemented ${params.keyWords}`;
    }
    getParams(component) {
        throw `not implemented getParams ${component.component_id}`;
    }
}
exports.CardDataBaseService = CardDataBaseService;
