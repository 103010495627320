import { NavBarUserInfo } from "../navBar/navBarUserInfo/NavBarUserInfo";
import { ReportBug } from "../navBar/reportBug/ReportBug";
import styles from "./Header.module.scss";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { getIt } from "@uikit/getIt";
import { FotSaveService } from "@uikit/service/FotSaveService";
import React, { useEffect, useMemo } from "react";
import { useSignal } from "@preact/signals-react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ProjectNetDataStore, ProjectUpdateParam } from "@base/ProjectNetDataStore";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { popupFeedbackStore } from "@uikit/store/PopupFeedback";
import { fotActions } from "@store/fot";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "@store/editor";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import store, { RootState } from "@store/index";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { studioActions } from "@store/studio";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { GenUIProcess } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboardingType";
import { useCreation } from "ahooks";
import { PreviewHeaderViewBlocV2 } from "@uiview/views/PreviewHeaderV2/PreviewHeaderViewBlocV2";
import func from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import { Meta } from "imagica-corekit/dist/base/cutil/Meta";
import { PreviewUtil } from "imagica-corekit/dist/cases/preview/PreviewUtil";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { LinksAndQRCodesViewBloc, QRCodeViewState } from "@uiview/views/LinksAndQRCodes/LinksAndQRCodesViewBloc";
import { PublishCompletionModal } from "@uiview/views/HomeNavBarViews/PublishCompletion/PublishCompletion";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { CotStore } from "@uikit/store/CotStore";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";
import { useIsStaticApp } from "@custom-hooks/useNodePreview";
import { FotNavigateController } from "@uikit/service/FotNavigateController";

export function Header(): React.JSX.Element {
  const fotNavigateController = getIt(FotNavigateController);
  const isSaving = useSignal(false);
  const shouldShowPublish = useSignal(false);
  const isPublishing = useSignal(false);
  const showNavPageStore = getIt(ShowNavPageStore);
  const fotSaveService = getIt(FotSaveService);
  const projectUpdateStore = getIt(ProjectNetDataStore);
  const previewAppStore = getIt(PreviewAppStore);
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const gql = getIt(ObjectRelationGqlService);
  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const homeMethods = getIt(HomeMethodsService);
  const navBarStore = getIt(NavBarStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const cotStore = getIt(CotStore);
  const previewComposeState = getIt(PreviewComposeState);
  const { isStaticApp } = useIsStaticApp();
  const creatorPreviewState = useImagicaStore(previewStore).value;
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const disabledPublishV2 = useMemo(() => {
    return previewComposeState.disabledPublishV2();
  }, [saasUIData, isStaticApp, creatorPreviewState.selectedUI]);
  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);
  const dispatch = useDispatch();
  const dataVersion = "v3";
  const publishBloc = useCreation(() => new PreviewHeaderViewBlocV2(), []);
  const shouldDisabledPublish = disabledPublishV2 || publishBloc.subscriptionAndNoPrice();
  const navBarState = useImagicaStore(navBarStore).value;
  const LinksAndQRCodesUrl = useSignal(
    PreviewUtil.getCustomDomain(saasUIData) || previewAppStore.getPublishMetadata()?.link || ""
  );
  const qrCodeState = new QRCodeViewState();
  const QRCodeBloc = useCreation(() => new LinksAndQRCodesViewBloc(qrCodeState), []);
  const title = saasUIData.v2App?.appName ?? "";
  const isShowCompletionModal = useSignal(false);

  useEffect(() => {
    shouldShowPublish.value = chatOnboardingStore.state.currentStep === GenUIProcess.Build && !shouldDisabledPublish;
  }, [chatOnboardingStore.state.currentStep, shouldDisabledPublish]);

  useEffect(() => {
    const nothing = "Add input/content to publish";
    const nosubscriptionPrice = "Add subscription pricing before publishing";
    if (!shouldDisabledPublish) {
      navBarStore.setShowPublishDisableTip("");
      return;
    }
    if (publishBloc.subscriptionAndNoPrice()) {
      navBarStore.setShowPublishDisableTip(nosubscriptionPrice);
    } else {
      navBarStore.setShowPublishDisableTip(nothing);
    }
  }, [shouldDisabledPublish]);

  function setEnableCOTStartAgain(val: any): void {
    dispatch(editorActions.setEnableCOTStartAgain(val));
  }

  function setSelectedTemplate(val: any): void {
    dispatch(studioActions.setSelectedTemplate(val));
  }

  function setPromptStatus(status: any): void {
    const selectedTemplate: any = (store.getState() as RootState).studio.selectedTemplate;
    const v = Object.assign({}, selectedTemplate[dataVersion], { showToolTipStatus: status });
    const data = Object.assign({}, selectedTemplate, { [dataVersion]: v });
    setSelectedTemplate(data);
  }
  function closePromptHide(): void {
    setPromptStatus("close");
    newOnboardingFlowStore.setSelectedFlowItem({
      ...newOnboardingFlowStore.state.selectedFlowItem,
      showTooltip: false,
    });
  }
  function handleReset(): void {
    // 重置unde数据
    closePromptHide();
    creatorStoreMethods.resetUndoData();
    homeMethods.emptyNodes();

    // 重置右侧preview数据
    previewAppStore.resetPreviewData();
    // 重置按钮默认颜色(fixme: mobile页面用不到？)
    navBarStore.setClickItemButton(false);
    // 重置project inputvalue 内容
    creatorRefStore.currentProjectRef.current?.resetInnerState();

    // 重置Onboarding input
    cotStore.setMultiRoundChatOnboarding(false);

    // 显示首页
    fotNavigateController.openCreatorHomePage();
  }

  function backToHome(): void {
    if (!projectUpdateStore.hasUnmount()) {
      previewAppStore.saasUiDataToPreview();
      projectUpdateStore.stopAutoUpdate();
      if (chatOnboardingStore.state.selectTemplateInModal !== ChoosingFormat.AUTOIMAGICA) {
        projectUpdateStore.updateProjectData(
          new ProjectUpdateParam(previewStore.state.previewAppList, selectedTemplate, gql)
        );
      }
    }
    projectPageStore.setIsPublish(false);
    handleReset();
    setEnableCOTStartAgain(null);
    popupFeedbackStore.setEnableCOTFeedbackPopup(false);
    cotStore.setOldCotAnimationState({
      creating: false,
      inputIds: [],
      currentInputId: "",
    });
    dispatch(fotActions.setVariableList([]));
    previewAppStore.clearPreviewApp();
  }

  async function save(): Promise<void> {
    if (isSaving.value) {
      return;
    }
    isSaving.value = true;
    const result = await tryPromise(fotSaveService.handleSave());
    if (result.data) {
      func.messageUtil("Successfully saved", "success");
    }
    isSaving.value = false;
  }

  async function publish(): Promise<void> {
    if (!shouldShowPublish.value || navBarState.publishLoading || isPublishing.value) return;
    let published_metadata = previewAppStore.getPublishMetadata() || {};
    navBarStore.setPublishLoading(true);
    isPublishing.value = true;
    if (
      previewStore.state.selectedUI === "saas" ||
      // || (util.isEmpty(selectedTemplate?.published_metadata)
      (func.isEmpty(published_metadata) &&
        func.isEmpty(published_metadata) &&
        publishBloc.featureTags.showSubscriptionFeaturesToggle &&
        previewStore.state.selectedUI === "saasV2")
    ) {
      published_metadata = {
        uuid: "",
        original: true,
        stripe_product_id: "",
        stripe_price_id: "",
        stripe_account_id: "",
      };
    }
    try {
      const shareLink = "";
      const template = store.getState().studio.selectedTemplate;
      const data: any = {
        templateObj: template,
        shareObj: { share: true, shareLink },
        params: { published_metadata },
        showPublishTips: shouldDisabledPublish,
      };

      const { updated_project } = await fotSaveService.handleSave(data);
      if (!updated_project) {
        throw new Meta("Error", "Unable to create shared project");
      }
      setSelectedTemplate(updated_project);
      await publishBloc.handleSubscription(updated_project);
      LinksAndQRCodesUrl.value =
        PreviewUtil.getCustomDomain(saasUIData) || previewAppStore.getPublishMetadata()?.link || "";
      await QRCodeBloc.getStudioDomainList(LinksAndQRCodesUrl.value, title);
      isShowCompletionModal.value = true;

      const selectTemlate = previewAppStore.getSelectedTemplate();
      const eventProps = publishBloc.getPublishEventProps(selectTemlate, LinksAndQRCodesUrl.value as string, template);
      switch (previewStore.state.selectedUI) {
        case "saas":
          logEvent("publish_as_webapp", { URL: updated_project.published_metadata.link, ...eventProps });
          break;
        case "saasV2":
          logEvent("publish_as_saas", { URL: updated_project.published_metadata.link });
          break;
        case "thinkingApp":
          logEvent("publish_as_thinkingapp", { URL: updated_project.published_metadata.link });
          break;
        default:
          break;
      }
      func.messageUtil("Successfully created", "success");
    } catch (error: any) {
      console.error("publish", error);
      func.messageUtil(error);
    } finally {
      navBarStore.setPublishLoading(false);
      isPublishing.value = false;
    }
  }

  return (
    <header className={styles.headerWrap}>
      <div className={styles.left}>
        <img
          src="https://dopniceu5am9m.cloudfront.net/natural.ai/assets/svg/logo.svg"
          className={styles.logo}
          alt=""
          onClick={() => backToHome()}
        />
        <ReportBug />
      </div>
      <div className={styles.right}>
        {!showNavPageStore.state.showNavPage && (
          <>
            <div className={styles.projectBtn} onClick={() => save()}>
              {isSaving.value ? <Spinner spinning={isSaving.value} /> : <>Save</>}
            </div>
            {shouldShowPublish.value && (
              <div className={styles.projectBtn} onClick={() => publish()}>
                {isPublishing.value ? <Spinner spinning={isPublishing.value} /> : <>Publish</>}
              </div>
            )}
          </>
        )}
        <NavBarUserInfo className={styles.headerAvatar} />
      </div>
      <PublishCompletionModal
        isOpen={isShowCompletionModal.value}
        onClose={() => {
          isShowCompletionModal.value = false;
        }}
        qrCodeImgUrl={QRCodeBloc.state.qrCodeUrl.value}
        appLink={LinksAndQRCodesUrl.value}
      />
    </header>
  );
}

function Spinner(props: { spinning: boolean }): JSX.Element {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{ fontSize: 18 }}
          spin
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      }
      spinning={props.spinning}
    />
  );
}
