"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueprintBuildMsg = void 0;
class BlueprintBuildMsg {
    constructor(blueprints, targetNodeId) {
        this.blueprints = blueprints;
        this.targetNodeId = targetNodeId;
        this.naming = "BlueprintBuildMsg";
    }
}
exports.BlueprintBuildMsg = BlueprintBuildMsg;
