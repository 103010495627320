import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, Card, Input, List, Button, Tag, Tooltip, Spin, Form, Select } from "antd";
import { EditFilled, PlusOutlined, ExclamationCircleOutlined, ClearOutlined, DeleteOutlined } from "@ant-design/icons";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { fotActions } from "../store/fot";
import { studioActions } from "../store/studio";
import { Api } from "../uikit/service/api";

const { TextArea } = Input;
const { Option } = Select;

const StudioVariableCard = () => {
  const dispatch = useDispatch();
  const api = new Api(false);

  const variables = useSelector(state => state.studio.selectedTemplate.variables);
  const setVariables = val => {
    dispatch(studioActions.setVariables(val));
  };

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);
  const setSelectedTemplate = val => {
    dispatch(studioActions.setSelectedTemplate(val));
  };

  const loadingState = useSelector(state => state.studio.loadingState);
  const setLoadingState = val => {
    dispatch(studioActions.setLoadingState(val));
  };

  const selectedDomain = useSelector(state => state.studio.selectedDomain);

  const layoutComponents = useSelector(state => state.studio.layoutComponents);
  const setLayoutComponents = val => {
    dispatch(studioActions.setLayoutComponents(val));
  };

  const setUpdateLayout = val => {
    dispatch(studioActions.setUpdateLayout(val));
  };

  const setUpdateSelectedLogic = val => {
    dispatch(studioActions.setUpdateSelectedLogic(val));
  };

  // lcoal states
  const [loading, setLoading] = useState(false);
  const [variableToEdit, setVariableToEdit] = useState({ vname: "", vtype: "", vvalue: "" });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [newVariableObj, setNewVariableObj] = useState({ vname: "", vvalue: "", vtype: "" });
  const [variableTypes, setVariableTypes] = useState([]);
  const [convertedVariables, setConvertedVariables] = useState([]);

  const [variableEditForm] = Form.useForm();

  const [isHiddenArr, setIsHidden] = useState([]);

  // methods
  const handleEditModalOk = () => {
    updateVariable();
  };

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleNewModalOk = () => {
    if (newVariableObj.vtype !== "string") {
      const newObj = JSON.parse(JSON.stringify(newVariableObj));
      try {
        newObj.vvalue = JSON.parse(newObj.vvalue);
      } catch (_) {}
      setNewVariableObj(newObj);
      updateVariable(newObj);
    } else {
      updateVariable(newVariableObj);
    }
  };

  const disableNewModalOk = () => {
    return !newVariableObj.vname || !newVariableObj.vtype;
  };

  const handleNewModalCancel = () => {
    setIsNewModalOpen(false);
  };

  const handleVariableToEditValueChange = e => {
    setVariableToEdit({ vname: variableToEdit.vname, vvalue: e.target.value, vtype: variableToEdit.vtype });
  };

  const handleVariableToEditTypeChange = value => {
    setVariableToEdit({ vname: variableToEdit.vname, vvalue: variableToEdit.vvalue, vtype: value });
  };

  const handleNewVariableNameChange = e => {
    const newObj = JSON.parse(JSON.stringify(newVariableObj));
    newObj.vname = e.target.value;
    setNewVariableObj(newObj);
  };

  const handleNewVariableValueChange = e => {
    const newObj = JSON.parse(JSON.stringify(newVariableObj));
    newObj.vvalue = e.target.value;
    setNewVariableObj(newObj);
  };

  const handleNewVariableTypeChange = value => {
    const newObj = JSON.parse(JSON.stringify(newVariableObj));
    newObj.vtype = value;
    setNewVariableObj(newObj);
  };

  const handleClickEditVariable = item => {
    setVariableToEdit({ vname: item.key, vvalue: item.value, vtype: item.type });
    variableEditForm.setFieldsValue({
      vtype: item.type,
      vvalue: item.value,
    });
    setIsEditModalOpen(true);
  };

  const handleClickClearVariable = item => {
    let val;
    if (item.type === "string") {
      val = "";
    } else {
      val = [];
    }
    item.value = val;
    updateVariable({ vname: item.key, vvalue: item.value, vtype: item.type });
  };

  const handleClickDeleteVariable = item => {
    updateVariable({ vname: item.key, vvalue: item.value, vtype: item.type }, true);
  };

  const handleClickNewVariable = () => {
    setIsNewModalOpen(true);
  };

  const convertVariables = () => {
    // deep copy
    const data = JSON.parse(JSON.stringify(variables));
    Object.keys(data).forEach(key => {
      data[key].key = key;
      const strValue = JSON.stringify(data[key].value);
      data[key].valueDisplay = strValue;
      // data[key].valueDisplay = strValue.substring(0,200);
      // if (strValue.length > 100) {
      //   data[key].valueDisplay = `${data[key].valueDisplay}...`;
      // }
    });
    return Object.values(data).map(each => each);
  };

  const updateVariable = (item = variableToEdit, deleteVariable = false) => {
    dispatch(fotActions.setFetching(true));
    setLoading(true);
    const data = {
      template_name: selectedTemplate.name,
      vname: item.vname,
      vtype: item.vtype,
      vvalue: item.vvalue,
      delete: deleteVariable,
    };
    api
      .post(`/be/bas-demo-v4/brain_studios/update_variable`, data)
      .then(response => response.json())
      .then(data => {
        const variablesData = data[0];
        setVariables(variablesData);
        setUpdateSelectedLogic(true);
        if (deleteVariable) {
          const idx = layoutComponents.findIndex(x => x.name === item.vname);
          const layoutComponentsList = [...layoutComponents];
          layoutComponentsList.splice(idx, 1);
          setLayoutComponents(layoutComponentsList);
          setUpdateLayout(true);
        }
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setUpdateSelectedLogic(false);
        setLoading(false);
        setIsEditModalOpen(false);
        setIsNewModalOpen(false);
      });
  };

  const getVariableTypes = () => {
    dispatch(fotActions.setFetching(true));
    api
      .get(`/be/bas-demo-v4/brain_studios/list_variable_types`)
      .then(response => response.json())
      .then(data => {
        const variableTypesData = data[0];
        setVariableTypes(variableTypesData);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
      });
  };

  const cardExtra = item => {
    return (
      <div>
        <Tag color="magenta">{item.type}</Tag>
        {item.key !== "thought" ? (
          <DeleteOutlined className="Studio-Variable-Delete-Icon" onClick={() => handleClickDeleteVariable(item)} />
        ) : (
          ""
        )}
      </div>
    );
  };

  const clickHiddenArr = id => {
    const arr = isHiddenArr || [];
    if (!arr.includes(id)) {
      arr.push(id);
    } else {
      const index = arr.findIndex(x => x === id);
      arr.splice(index, 1);
    }
    setIsHidden([...arr]);
  };

  useEffect(() => {
    getVariableTypes();
  }, []);

  useEffect(() => {
    const convertedList = convertVariables();
    setConvertedVariables(convertedList);
  }, [variables]);

  return (
    <Card
      className="Studio-Variable-Card shadow"
      title="Variables"
      bordered={false}
      actions={[
        <Button
          size="large"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleClickNewVariable}
          className="Studio-Variable-Plus-Button"
        ></Button>,
      ]}
    >
      <Spin tip="Loading..." spinning={loadingState.variableCard}>
        <Droppable droppableId="variables" className="Studio-Variable-List">
          {provided => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {convertedVariables.map((item, idx) => (
                <Draggable key={item.key} draggableId={item.key} index={idx}>
                  {provided => (
                    <Card
                      key={idx}
                      title={item.key}
                      extra={cardExtra(item)}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      className="Studio-Variable-Item-Card shadow-shallow"
                      actions={[
                        // <Button icon={<ExpandOutlined/>}>Expand</Button>,
                        <Button icon={<ClearOutlined />} onClick={() => handleClickClearVariable(item)}>
                          Clear
                        </Button>,
                        <div>
                          <Button
                            type="primary"
                            icon={<EditFilled />}
                            onClick={() => handleClickEditVariable(item)}
                            key={item.key}
                          >
                            Edit
                          </Button>
                        </div>,
                      ]}
                    >
                      <p
                        className={`hidden-content ${isHiddenArr.includes(idx) ? "expand-content" : ""}`}
                        onClick={() => clickHiddenArr(idx)}
                      >
                        {" "}
                        {item.valueDisplay}
                      </p>
                    </Card>
                  )}
                </Draggable>
              ))}
            </ul>
          )}
        </Droppable>
      </Spin>
      <Modal
        key="edit-variable"
        title={`Edit Variable - ${variableToEdit.vname}`}
        open={isEditModalOpen}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
        okButtonProps={{ loading: loading }}
      >
        <Form layout="vertical" form={variableEditForm}>
          <Form.Item label="Type" rules={[{ required: true, message: "Please input type" }]} name="vtype">
            <Select
              placeholder="Select variable type"
              value={variableToEdit.vtype}
              onChange={handleVariableToEditTypeChange}
            >
              {variableTypes.map((type, idx) => (
                <Option value={type} key={idx}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Value" name="vvalue">
            <TextArea
              value={variableToEdit.vvalue}
              onChange={handleVariableToEditValueChange}
              disabled={variableToEdit.vtype !== "string"}
            ></TextArea>
            {variableToEdit.vtype !== "string" ? (
              <Tooltip title="Only string variable is editable">
                <ExclamationCircleOutlined style={{ marginLeft: "10px", fontSize: "16px", color: "red" }} />
              </Tooltip>
            ) : (
              ""
            )}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        key="new-variable"
        title={`New Variable`}
        open={isNewModalOpen}
        onOk={handleNewModalOk}
        onCancel={handleNewModalCancel}
        okButtonProps={{ loading: loading, disabled: disableNewModalOk() }}
      >
        <Form layout="vertical">
          <Form.Item label="Name" rules={[{ required: true, message: "Please input name" }]} name="vname">
            <Input
              placeholder="Input variable name"
              value={newVariableObj.vname}
              onChange={handleNewVariableNameChange}
            />
          </Form.Item>
          <Form.Item label="Type" rules={[{ required: true, message: "Please input type" }]} name="vtype">
            <Select
              placeholder="Select variable type"
              value={newVariableObj.vtype}
              onChange={handleNewVariableTypeChange}
            >
              {variableTypes.map((type, idx) => (
                <Option value={type} key={idx}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Value (Optional)" name="vvalue">
            <TextArea value={newVariableObj.vvalue} onChange={handleNewVariableValueChange}></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default StudioVariableCard;
