"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveAsExistingParams = exports.SaveProcess = exports.SaveVars = exports.SaveResult = exports.HandleSaveArgs = void 0;
/**
 * 来自 useHandleSave handleSave 参数
 */
class HandleSaveArgs {
    constructor() {
        this.params = {};
        this.showPublishTips = true;
        this.source = undefined; //records where the save events comes from: save/home/title/share
        this.uiObj = {};
        this.notShowSuccessTip = false;
        this.background = false;
        this.local = false;
        this.backgroundCreateRelation = true;
        // 下面三个参数，默认值从 fot 来，目前从 createSaveProcess 创建时覆盖
        this.templateObj = {};
        this.variableList = [];
        this.edges = [];
    }
}
exports.HandleSaveArgs = HandleSaveArgs;
/**
 * 用于表示 handleSave 返回类型
 */
class SaveResult {
    constructor() {
        this.updated_project = {};
        this.shared_project = {};
    }
}
exports.SaveResult = SaveResult;
/**
 * 用于在save 流程中传递的其他变量方法的对象, 包含以下属性
 *
 * 1. preview 或 redux 状态
 */
class SaveVars {
    constructor() {
        /**
         * fot myModificationDataStroe store 状态
         */
        this.showMyModification = false;
        /**
         * 原来 handleSave 中用到的 urlParams() Hook 的 attrIndexer 路由参数
         */
        this.attrIndexer = "";
        /**
         * 来自 redux userMe
         *
         * 未来可能需要被 meStore 替换
         */
        this.userMe = {};
        /**
         * 来自 redux selectedTemplate
         */
        this.selectedTemplate = {};
        /**
         * publish genlink 所需参数
         *
         * - 其中 uuid 会在内部被重置
         *
         */
        this.structureLinkParams = {};
    }
}
exports.SaveVars = SaveVars;
/**
 * 该对象用于标识 save 流程的对象，上面有 handleSave 所有数据
 */
class SaveProcess {
    constructor(
    /**
     * save 入参
     */
    args, 
    /**
     * save 保存数据
     */
    saveData, 
    /**
     * save 流程结果
     */
    result, 
    /**
     * save 流程使用到的额外变量，一般是原 handleSave 使用到的一些 store 或 方法
     */
    vars, 
    /**
     * save 过程中用到的额外的回调，方法或事件函数，因为使用了大量fot的方法和逻辑，暂时还不能全部用 class 代替
     *
     * FIXME: **如果后续有替换方案，events 则需要被删除**
     */
    events) {
        this.args = args;
        this.saveData = saveData;
        this.result = result;
        this.vars = vars;
        this.events = events;
    }
}
exports.SaveProcess = SaveProcess;
class SaveAsExistingParams {
    constructor() {
        // shareObj = undefined,
        // variableList = store.getState().fot.variableList,
        // params,
        this.data = {};
        this.attrIndexer = "";
        // 下面两个参数，默认值从 fot 来，目前从 saveCore.saveAsExistingTemplateV2 创建时覆盖
        this.templateObj = {};
        this.edges = [];
        // 上面是原来方法传参数，下面是依赖参数
        this.showMyModification = false;
        /** userme objectId */
        this.objectId = "";
    }
}
exports.SaveAsExistingParams = SaveAsExistingParams;
/**
 * 该接口用于定义 fot redux store 接口，具体应该由 fot 实现
 *
 * - 如果不在需要 fot redux store则可以被删除
 */
