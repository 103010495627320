import { Tooltip, Input, TooltipProps } from "antd";
import { ChangeEvent, PropsWithChildren, useEffect, useRef } from "react";
import styles from "./TooltipMessage.module.scss";
import { useSignal, useSignalEffect } from "@preact/signals-react";
import { useClickAway } from "ahooks";

type TooltipMessageProps = {
  title: string;
  placeholder: string;
  onConfirm: (message: string) => void;
  onChangeShowBluePrintFeedbackModal?: (bool: boolean) => void;
} & PropsWithChildren &
  Pick<TooltipProps, "trigger">;

export function TooltipMessage(props: TooltipMessageProps) {
  const toolTipState = useSignal(false);
  const reason = useSignal("");

  const tooltipRef = useRef(null);
  const childRef = useRef(null);

  useClickAway(() => {
    toolTipState.value = false;
  }, [tooltipRef, childRef]);

  const handlePressEnter = async () => {
    const res: any = await props.onConfirm(reason.value);
    if (res?.error) return;
    toolTipState.value = false;
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    reason.value = e.target.value;
  };

  const handleClick = (): void => {
    toolTipState.value = !toolTipState.value;
  };

  useSignalEffect(() => {
    props.onChangeShowBluePrintFeedbackModal?.(toolTipState.value);
    if (!toolTipState.value) {
      reason.value = "";
    }
  });

  return (
    <Tooltip
      open={toolTipState.value}
      color="#fff"
      trigger="click"
      placement="topLeft"
      overlayInnerStyle={{ borderRadius: 20, width: 300 }}
      title={
        <div className={styles["feedback-tips"]} ref={tooltipRef}>
          <p className={styles["feedback-tips-title"]}>{props.title}</p>
          <Input
            value={reason.value}
            bordered={false}
            placeholder={props.placeholder}
            onChange={handleOnChange}
            onPressEnter={handlePressEnter}
          />
        </div>
      }
    >
      <div onClick={handleClick} ref={childRef}>
        {props.children}
      </div>
    </Tooltip>
  );
}
