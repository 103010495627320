"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dataload = exports.DataloadState = void 0;
const Eventsource_1 = require("./Eventsource");
const LangUtil_1 = require("./LangUtil");
const ReadyStatus_1 = require("./ReadyStatus");
const GenUtil_1 = require("./GenUtil");
class DataloadState {
    constructor() {
        this.status = ReadyStatus_1.ReadyStatus.PENDING;
    }
    copyWith(params) {
        return GenUtil_1.GenUtil.copyWith(new DataloadState(), this, params);
    }
}
exports.DataloadState = DataloadState;
class Dataload extends Eventsource_1.Eventsource {
    static value(value) {
        return new Dataload(() => Promise.resolve(value));
    }
    constructor(factory) {
        super(() => new DataloadState());
        this.factory = factory;
    }
    async get() {
        this.emit(this.state.copyWith({ status: ReadyStatus_1.ReadyStatus.LOADING }));
        const result = await (0, LangUtil_1.tryPromise)(this.factory());
        const newState = this.state.copyWith({});
        if (result.error) {
            newState.error = result.error;
        }
        else {
            newState.data = result.data;
        }
        newState.status = ReadyStatus_1.ReadyStatus.DONE;
        this.emit(newState);
        return result.data;
    }
}
exports.Dataload = Dataload;
