import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { cloneDeep, isFunction } from "lodash";

export class CreatorEdgesStoreState {
  edges: any[] = [];
}

export type DispatchType<T> = T | ((prevState: T) => T);

export class CreatorEdgesStore extends StoreBase<CreatorEdgesStoreState> {
  lastState: CreatorEdgesStoreState;

  constructor() {
    super(() => new CreatorEdgesStoreState());
    this.lastState = new CreatorEdgesStoreState();
  }

  setEdges = (value: DispatchType<CreatorEdgesStoreState["edges"]>): void => {
    const newState = new CreatorEdgesStoreState();

    if (isFunction(value)) {
      newState.edges = value(this.lastState.edges);
      // if (!newState.edges.length) {
      //   console.trace("empty");
      // }

      this.lastState.edges = newState.edges;
      this.emit(newState);
      return;
    }

    // if (!value.length) {
    //   console.trace("empty");
    // }

    newState.edges = value;
    this.lastState.edges = cloneDeep(value);
    this.emit(newState);
  };

  // !!! 为了保证调用getEdges的地方，this指向不会变
  getEdges = (): any[] => {
    return this.state.edges;
  };

  setQueryLoading(id: any, keyStr: any, value: any): void {
    this.setEdges(prevList => {
      return prevList.map(l => {
        if (l.id === id) {
          return {
            ...l,
            data: {
              ...l.data,
              [keyStr]: value,
            },
          };
        }
        return l;
      });
    });
  }
}
