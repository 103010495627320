import { ReactNode, memo, useMemo, useRef } from "react";
import { isBlank } from "imagica-corekit/dist/base/cutil/Extensions";
import { Blank } from "imagica-uikit/dist/nodesV2/Blank/Blank";
import { Node } from "reactflow";
import css from "./PreviewOutputItem.module.scss";
import { PreviewOutputChildGetter } from "./config";
import { PreviewDataType } from "../PreviewDataType";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";
import { NodesProps } from "@uiview/views/Nodes/typing";
import { RejectTipText } from "@uiview/views/RejectTip/RejectTip";

export type OldOutputItemProps = {
  className?: any;
  id?: any;
  type?: any;
  isShare?: any;
  card?: any;
  len?: any;
  page?: any;
  zone?: any;
  editorId?: any;
  currentPageLoadingState?: any;
  handleImageLoaded?: any;
  locus?: any;
};

export type PreviewOutputItemProps<T extends Record<string, any> = any> = {
  /**
   * node 节点
   */
  node: Node<T>;
  /**
   * preview 相关数据
   */
  previewData: PreviewDataType;

  featureTags: FeatureTags;

  isShare?: boolean;

  isStaticApp?: boolean;

  className?: string;

  /**
   * 是否显示 blank
   */
  showBlank?: boolean;

  children?: () => ReactNode;
};

/**
 * - 因为 Nodes 组件即在 canvas 又在 preview 作为组件使用，但是有些类型渲染出来不需要 完全使用 canvas 中的 Nodes 组件
 * - 所以在原来的逻辑中，重写了渲染部分类型的组件，这些通过当前分支测试，重新定义这些类型在 PreviewOutputItemChildComponentMaps 中
 * - 如果，需要将 preview output item 完全独立, 则需要重新 Nodes 包含 preview 渲染类型的组件
 *
 * contentType 是原来逻辑通过转换后使用与 preview 渲染节点的类型，不明白为什么不适用 `node.data.displayType`?
 *
 * **该组件统一使用 `node.data.displayType`, 且应该不受任何 容器，状态，依赖限制，应该是一个非受控且独立的组件**
 *
 * TODO: 但是目前内部很多子节点并没有独立
 *
 * @returns
 */
export const PreviewOutputItem = memo(function PreviewOutputItemInner<T extends Record<string, any>>(
  props: PreviewOutputItemProps<T>
) {
  const outputRef = useRef(null);

  const displayType = props.node.data.displayType;

  const ChildComponent = useMemo(
    () => PreviewOutputChildGetter.getPreviewOutputChildComponent(displayType),
    [displayType]
  );
  const childComponentProps = PreviewOutputChildGetter.getPreviewOutputChildComponentProps(props);
  const nodeData = props?.node?.data;

  // 除agent以外的node显示报错信息
  if (childComponentProps?.displayType !== "Agent" && isBlank(nodeData?.errorText) === false) {
    return <RejectTipText errorText={nodeData?.errorText} />;
  }

  if (isBlank(props.previewData.data.content)) {
    return (
      <div
        ref={outputRef}
        className={css.previewOutput}
        data-preview-content-type={props.previewData.data.contentType}
        data-preview-display-type={props.node.data.displayType}
      >
        <Blank content={props.previewData.data.blank} />
      </div>
    );
  }

  // console.log("jj previewoutputitem child props", childComponentProps);

  return (
    <div
      ref={outputRef}
      className={css.previewOutput}
      data-preview-content-type={props.previewData.data.contentType}
      data-preview-display-type={props.node.data.displayType}
      data-testid="PreviewOutputItemV2"
      // style={{ margin: marginStyle, width: `calc(100% - ${marginLeft * 2}px)` }}
    >
      {props.children ? props.children() : <ChildComponent {...(childComponentProps as unknown as NodesProps)} />}
    </div>
  );
});
