"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewBuilder = void 0;
const CotDataGraphToProjectUtil_1 = require("../cot/CotDataGraphToProjectUtil");
const ViewBuilderUtil_1 = require("./ViewBuilderUtil");
class ViewBuilder {
    /**
     * 解析viewbuilderV2返回的graph
     * @param graph CotTemplateGraph
     * @returns
     */
    static transformGraph(graph, nodeInitData, edgeNodeInitData, lineInitData) {
        const extraSaasUIData = { title: "", subTitle: "" };
        const newGraph = ViewBuilderUtil_1.ViewBuilderUtil.handleGraph(graph);
        const flow = {
            graph: newGraph,
            detail: null,
            interface: [],
            action_sequence: [],
            unsupported_interface: [],
            unsupported_action_sequence: [],
        };
        return CotDataGraphToProjectUtil_1.CotDataGraphToProjectUtil.transformTemplate("", flow, extraSaasUIData, nodeInitData, edgeNodeInitData, lineInitData);
    }
}
exports.ViewBuilder = ViewBuilder;
