import func from "@uikit/func";
import { get } from "lodash";
import { getIt } from "@uikit/getIt";
import { useEffect, useRef, useState } from "react";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { Api } from "../../../../uikit/service/api";

const api = new Api(false);
const START_TIME = 25;
const INTERVAL_TIME = 5;

export const useTimeoutQuery = (messageId: string | undefined, nodeId: string, meId: string | undefined): any => {
  const fotReactFlow = getIt(FotReactFlow);
  const intervalTimer = useRef<NodeJS.Timeout>();

  const [isTimeout, setIsTimeout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState("0");

  const queryUrl = async (): Promise<any> => {
    setLoading(true);
    const res = await api.getWithErrorCanAbort(`/be/svc-adapter/midjourney/v2/message?id=${messageId}`);
    setLoading(false);

    const _progress = get(res, "data.data.progress");
    setProgress(_progress);

    if (_progress === "100") {
      const imageUrl = get(res, "data.data.response.imageUrls.0");
      if (imageUrl) {
        const node = fotReactFlow.getNode(nodeId);
        const resolve = get(node, "data.abortNode.apiInstance.resolve");

        clearInterval(intervalTimer.current);
        resolve?.(imageUrl);
      }
    }
  };

  const intervalQuery = (): void => {
    // Use interval because cannot connect websocket when mode is shared page
    if (func.isEmpty(meId)) {
      intervalTimer.current = setInterval(() => {
        queryUrl();
      }, INTERVAL_TIME * 1000);
    }
  };

  const timeoutQuery = (): void => {
    setTimeout(async () => {
      intervalQuery();
      await queryUrl();
      setIsTimeout(true);
    }, START_TIME * 1000);
  };

  useEffect(() => {
    setProgress("0");
    setIsTimeout(false);
    if (messageId) {
      timeoutQuery();
    }
    // eslint-disable-next-line
  }, [messageId]);

  return {
    isTimeout,
    queryUrl,
    loading,
    progress,
  };
};
