import classNames from "classnames";
import { Image } from "antd-mobile";
import { Outputs } from "../Outputs/Outputs";
import { GenUIBloc } from "../../GenUIBloc";
import { useSignal } from "@preact/signals-react";
import { CallerBotBloc } from "../../CallerBotBloc";
import { UploadFile } from "../uploadfile/UploadFile";
import { PhoneInfo, PhoneList } from "../phoneList/PhoneList";
import { NewContactPanel } from "../newContactPanel/NewContactPanel";
import { AISaasLogoIcon } from "@uiview/views/AISaas/AISaasPreview/icons";
import phoneInfosMockData from "./phoneListMockData.json";
import styles from "./Preview.module.scss";

type PreviewProps = {
  bloc: CallerBotBloc | GenUIBloc;
  highlightComponent?: string | undefined;
};

export function Preview(props: PreviewProps) {
  const contentBoxCls = classNames(styles.contentBox, props.highlightComponent !== undefined && styles.highlightMode);
  return (
    <div className={styles.previewPane}>
      <div className={styles.previewLayout}>
        <div className={styles.closeIcon} onClick={() => props.bloc.closePreview()}></div>
        <div className={styles.header}>
          <h3 className={styles.title}>Show Prototype</h3>
        </div>
        <div className={styles.saasContent}>
          <div className={contentBoxCls}>
            {props.bloc.state.isCallerBotBloc.value && (
              <CallerBotPreview bloc={props.bloc as CallerBotBloc} highlightComponent={props.highlightComponent} />
            )}
            {props.bloc.state.isCallerBotBloc.value === false && <GenUIPreview bloc={props.bloc as GenUIBloc} />}
            <div className={styles.footer}>Built with Imagica by Brain.ai</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CallerBotPreview(props: { bloc: CallerBotBloc; highlightComponent?: string | undefined }) {
  const phoneInfos = useSignal<PhoneInfo[]>([]);
  const expand = useSignal<boolean>(true);
  const expandInstruction = useSignal<boolean>(true);

  const handleSuccess = () => {
    phoneInfos.value = phoneInfosMockData;
  };

  const handleClear = () => {
    phoneInfos.value = [];
  };

  const handleExpand = () => {
    expand.value = !expand.value;
  };

  const handleExpandInstruction = () => {
    expandInstruction.value = !expandInstruction.value;
  };

  const handleClickAdd = (phoneInfo: PhoneInfo) => {
    const newPhoneInfos = [phoneInfo, ...phoneInfos.value];
    phoneInfos.value = newPhoneInfos;
  };

  const callInstructionCls = classNames(
    styles.bgContentBox,
    props.highlightComponent === "callInstruction" && styles.highlightComponent
  );

  return (
    <>
      <div className={styles.logo}>
        <AISaasLogoIcon opacity={0.35} />
      </div>
      <div className={styles.contentTitle}>Your callerbot for consumers</div>
      <div className={styles.contentSubTitle}>
        Making contact calls to enhance communication, streamline processes, and increase customer satisfaction
      </div>
      <div className={styles.bgContentBox}>
        <div className={styles.contactHeader}>
          <p>Form your contact list</p>
          <div className={`${styles.imageBox} ${expand.value ? styles.rotateArrow : ""}`} onClick={handleExpand}>
            <Image
              src="https://dopniceu5am9m.cloudfront.net/static/imagica.ai/icons/dropdown-right.svg"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <p className={styles.contactDescription}>
          Upload your contact or add new contact manually to form your contact list
        </p>
        {expand.value && (
          <div>
            {props.bloc.state.answerIndex.value > 4 && (
              <UploadFile onSuccess={handleSuccess} onReUpload={handleClear}>
                <div className={styles.contactUpload}>
                  <Image
                    className={styles.uploadImage}
                    src={require("../../assets/callerbot-file-icon.png")}
                    width="48px"
                    height="auto"
                  />
                  <div className={styles.uploadTextBox}>
                    <p className={styles.uploadTitle}>Upload Contact</p>
                    <p className={styles.uploadDescription}>50 Mb max file size.</p>
                  </div>
                </div>
              </UploadFile>
            )}
            {props.bloc.state.answerIndex.value > 4 && <NewContactPanel onClickAdd={handleClickAdd} />}
            <PhoneList phoneInfos={phoneInfos.value} />
          </div>
        )}
      </div>
      <div className={callInstructionCls}>
        <div className={styles.contactHeader}>
          <p>Call Instruction</p>
          <div
            className={`${styles.imageBox} ${expandInstruction.value ? styles.rotateArrow : ""}`}
            onClick={handleExpandInstruction}
          >
            <Image
              src="https://dopniceu5am9m.cloudfront.net/static/imagica.ai/icons/dropdown-right.svg"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        {expandInstruction.value && (
          <p className={styles.contactDescription}>{props.bloc.state.callInstructionText.value}</p>
        )}
      </div>
      <div
        className={classNames(
          styles.bgContentBox,
          styles.callBtn,
          props.highlightComponent !== undefined ? styles.callBtnDisable : ""
        )}
      >
        Call
      </div>
      <div className={styles.bgContentBox}>
        <div className={styles.contactHeader}>
          <p>Transcription</p>
          <div className={styles.imageBox}>
            <Image
              src="https://dopniceu5am9m.cloudfront.net/static/imagica.ai/icons/dropdown-right.svg"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <p className={styles.contactDescription}>Call history will be transcribed once call initiated.</p>
      </div>
    </>
  );
}

export function GenUIPreview(props: { bloc: GenUIBloc }) {
  return <Outputs bloc={props.bloc} />;
}
