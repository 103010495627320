export interface ConfigItemPage {
  title: string;
  key: "account" | "password" | "subscription" | "signOut";
  description: string;
}
export const pagesConfig: ConfigItemPage[] = [
  {
    title: "Account",
    key: "account",
    description: "Update your name and manage your account.",
  },
  {
    title: "Password",
    key: "password",
    description: "Reset your password?",
  },
  {
    title: "Subscription",
    key: "subscription",
    description: "Manage your plan.",
  },
  {
    title: "Log out",
    key: "signOut",
    description: "",
  },
];
