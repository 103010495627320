import style from "./CopyLink.module.scss";
import { useEffect } from "react";
import { useCreation } from "ahooks";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { Button } from "antd";
import { DownloadIcon } from "@uiview/views/LinksAndQRCodes/DownloadIcon";
import { CopyLInkIcon } from "@uiview/views/PreviewHeaderV2/icons/CopyLInkIcon";
import { LinksAndQRCodesViewBloc, QRCodeViewState } from "@uiview/views/LinksAndQRCodes/LinksAndQRCodesViewBloc";
import { getIt } from "@uikit/getIt";
import { PreviewUtil } from "imagica-corekit/dist/cases/preview/PreviewUtil";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";

export function CopyLinkItem(): JSX.Element {
  const homeStore = getIt(HomeStore);
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const homeStoreState = useImagicaStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;

  const previewAppStore = getIt(PreviewAppStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const published_metadata = previewAppStore.getPublishMetadata();
  const url = (PreviewUtil.getCustomDomain(saasUIData) || published_metadata?.link) ?? "";

  const uuid = published_metadata?.uuid ?? "";
  const title = saasUIData.v2App?.appName ?? "";

  const qrCodeState = new QRCodeViewState();
  const bloc = useCreation(() => new LinksAndQRCodesViewBloc(qrCodeState), []);

  const handleClick = (): void => {
    bloc.clickClose();
  };

  const clickStop = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  const downloadImage = (): void => {
    const link = document.createElement("a");
    link.href = bloc.state.qrCodeUrl.value;
    link.download = "QRCode.png";
    link.click();
  };

  useEffect(() => {
    if (bloc.state.isQRCodeBox.value) {
      document.addEventListener("click", handleClick);
    }

    return () => {
      // 在组件卸载时移除事件监听器
      document.removeEventListener("click", handleClick);
    };
  }, [bloc.state.isQRCodeBox.value]);
  const QRIcon = `${settings.S3CDN}${settings.viewAssetsPath}group.svg`;

  return !featureTags.disableMultiRoundChatOnboarding &&
    chatOnboardingStore.state.selectTemplateInModal === ChoosingFormat.AUTOIMAGICA &&
    url !== "" ? (
    <div className={style.divider_box}>
      <div className={style.linksAndCodesBox} data-testid="linksAndCodesBox">
        <>
          <div onClick={() => bloc.copyShareLink(url, uuid)} className={style.copyLink}>
            <CopyLInkIcon />
            <span className={style.copyLinkTxt}>Copy App link</span>
          </div>
          <div className={style.orLine}></div>
          <img
            src={QRIcon}
            width="20"
            alt="icon"
            onClick={() => {
              bloc.getStudioDomainList(url, title);
            }}
          />
          {bloc.state.isQRCodeBox.value && (
            <>
              <div onClick={() => bloc.clickClose()} className={style.maskBox}></div>
              <div className={style.QRCodeModeBox} onClick={e => clickStop(e)} data-testid="QRCodeMode">
                <div className={style.QRCodeBox}>
                  <div className={style.codeTitle}>{bloc.state.titleStr.value}</div>
                  <img
                    className={style.crossBox}
                    onClick={() => bloc.clickClose()}
                    src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`}
                    width="20"
                    alt="icon"
                  />
                  <img className={style.codeBox} src={bloc.state.qrCodeUrl.value} width="240" alt="icon" />
                  <div className={style.codeText}>Preview on mobile device</div>
                  <Button
                    icon={<DownloadIcon />}
                    type="primary"
                    shape="round"
                    style={{
                      width: "auto",
                      margin: "30px auto 40px",
                      padding: "10px 20px 10px 15px",
                      display: "flex",
                      height: "40px",
                      gap: "10px",
                      fontSize: "15px",
                      fontWeight: 500,
                      lineHeight: "20px",
                    }}
                    onClick={() => downloadImage()}
                  >
                    Download
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  ) : (
    <></>
  );
}
