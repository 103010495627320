import { getIt } from "@uikit/getIt";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { isEmpty } from "lodash";
import { Node } from "reactflow";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { popupFeedbackStore } from "@uikit/store/PopupFeedback";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { useHomePublicMethods } from "@uiview/views/ProjectCanvas/useHomeUtilMethods";
import { SwitcherModeType, NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { DisableChatUtil } from "../../DisableChatUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CotStore } from "@uikit/store/CotStore";
import { AddNode } from "@uikit/service/AddNode";
import { FotSaveService } from "@uikit/service/FotSaveService";

let instance: ChatBoxManager;
/**
 * 内部实现，为UI组件使用的 hook
 * @returns
 */
function useChatBoxManager(): ChatBoxManager {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const homePublicMethods = useHomePublicMethods();

  if (!instance) {
    instance = new ChatBoxManager(
      creatorNodesStore.getNodes,
      homePublicMethods.clickClosePreview,
      getIt(FotSaveService)
    );
  }

  // 需要监听 reactflow 的变化，重新更新 reactflow
  return instance;
}

const chatOnboardingStore = getIt(ChatOnboardingStore);
const homeStore = getIt(HomeStore);
const navBarStore = getIt(NavBarStore);
const cotStore = getIt(CotStore);
const addNode = getIt(AddNode);
/**
 * FIXME: 当 home 容器都移除完成后, 不再需要传入方法到 ChatBoxManager
 */
export class ChatBoxManager {
  static use = useChatBoxManager;

  constructor(
    public getNodes: () => Node<any>[],
    public clickClosePreview: () => void,
    public fotSaveService: FotSaveService
  ) {}

  closeCallerBot(): void {
    cotStore.setMultiRoundChatOnboarding(false);
  }

  async onCloseChat(e: React.MouseEvent): Promise<void> {
    cotStore.setShowCot(false);
    navBarStore.setSwtichMode(SwitcherModeType.canvas);
    if (isEmpty(this.getNodes())) {
      await addNode.addNodeV2(e, { isEnableTooltipNode: true });
    }
    chatOnboardingStore.setIsOpenChat(false);
    // 关闭时重置changeCanvas状态
    if (DisableChatUtil.isDisabled() === false) {
      chatOnboardingStore.setChatOnboardingState({ canvasChangesOutOfSync: false });
    }
    this.closeCallerBot();
  }

  handleCOTAI(): void {
    if (
      homeStore.state.featureTags.disableMultiRoundChatOnboarding === false &&
      getIt(ChatOnboardingStore).state.selectTemplateInModal === ChoosingFormat.AUTOIMAGICA
    ) {
      cotStore.setMultiRoundChatOnboarding(true);
    } else {
      cotStore.setShowCot(true);
    }
    this.clickClosePreview();
  }

  onOpenChat(): void {
    chatOnboardingStore.setIsOpenChat(true, DisableChatUtil.isDisabled() ? null : this.fotSaveService.handleSave);
    navBarStore.setSwtichMode(SwitcherModeType.imagica);
    if (homeStore.state.featureTags.disableTriggerCotOnboardingInCanvas) {
      this.handleCOTAI();
    } else {
      cotStore.triggerGuideCot(() => {
        this.handleCOTAI();
      });
    }

    popupFeedbackStore.setEnableCOTFeedbackPopup(false);
  }

  onSwitchChatBox(e: React.MouseEvent, type: SwitcherModeType): void {
    if (type === SwitcherModeType.canvas) {
      this.onCloseChat(e);
      return;
    }

    this.onOpenChat();
  }
}
