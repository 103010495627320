import { ChangeEvent, FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import cls from "classnames";
import style from "./style.module.scss";
import Dropdown, { Triggers } from "../Dropdown";
import Text from "../Text";
import Icon from "../Icon";
import { FunctionDropdownItemArgs, FunctionDropdownItems } from "./type";
import { callback, getAssetsIcon, getAssetsImage } from "../../utilities";
import { FunctionModel } from "../../types";
import dayjs from "dayjs";
import { FocusEvent } from "react";
import { animated, useSpring } from "@react-spring/web";
import Loader from "../Loader";

export interface FunctionItemProps {
  func: FunctionModel;
  loading?: boolean;
  onClick?: (func: FunctionModel) => void;
  onNameChange?: (value: string, func: FunctionModel) => void;
  onDropdownClick?: (value: FunctionDropdownItemArgs) => void;
}

const FunctionItem: FC<FunctionItemProps> = ({
  func,
  loading = false,
  onClick = callback,
  onNameChange = callback,
  onDropdownClick = callback,
}) => {
  const [templateStyle, templateApi] = useSpring(() => ({}));
  const [name, setName] = useState("");
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    const newName = func.label || "";
    setName(newName);
  }, [func]);

  const handleDropdownOpenChange = useCallback((visible: boolean) => {
    setIsDropdownOpen(visible);
  }, []);

  const handleDropdownClick = useCallback(
    (type: FunctionDropdownItems) => (_event: MouseEvent<HTMLDivElement>) => {
      setIsDropdownOpen(false);
      onDropdownClick({
        type,
        func,
      });
    },
    [func, onDropdownClick]
  );

  const handleTextClick = useCallback(
    () => {
      // if (isNameEditing) {
      //   return;
      // }
      // setIsNameEditing(true);
    },
    [
      // isNameEditing
    ]
  );

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  }, []);

  const handleInputKeyup = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onNameChange(name, func);
        setIsNameEditing(false);
      }
      if (event.key === "Escape") {
        setName(func.attributesV2.function_name || func.attributesV2.name);
        setIsNameEditing(false);
      }
    },
    [func, name, onNameChange]
  );

  const handleInputBlur = useCallback(() => {
    setName(func.attributesV2.function_name || func.attributesV2.name);
    setIsNameEditing(false);
  }, [func]);

  const handleContextMenu = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDropdownOpen(true);
    return false;
  }, []);

  const handleClick = useCallback(
    async (_event: MouseEvent<HTMLDivElement>) => {
      await Promise.all(
        templateApi.start({
          config: { duration: 1000 },
          to: [{ transform: "scale(0.9)" }, { transform: "scale(1)" }],
        })
      );
      onClick(func);
    },
    [func, templateApi, onClick]
  );

  const handleFocus = useCallback((event: FocusEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDropdownOpen(false);
  }, []);

  const handleInputFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.stopPropagation();
  }, []);

  const trigger = useMemo<Triggers>(() => (isDropdownOpen ? Triggers.Click : Triggers.Focus), [isDropdownOpen]);
  const imageUrl = useMemo(() => getAssetsImage("empty-thumbnail.png"), []);
  const datetime = useMemo(() => dayjs(func.updatedAt).fromNow(), [func]);

  return (
    <div style={{ width: "100%" }}>
      {/* {loading && (
        <div className={style.loading}>
          <Loader full />
        </div>
      )} */}
      <Dropdown
        isOpen={isDropdownOpen}
        trigger={trigger}
        className={style.functionToolTipsBox}
        content={
          <div className={style.dropdown}>
            <div className={style.dropdown__item}>
              <div className={style.dropdownItem} onClick={handleDropdownClick(FunctionDropdownItems.Duplicate)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("copy.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Duplicate</Text>
                </div>
              </div>
              <div className={style.dropdownItem} onClick={handleDropdownClick(FunctionDropdownItems.Delete)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("bin.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Delete</Text>
                </div>
              </div>
            </div>
          </div>
        }
        onOpenChange={handleDropdownOpenChange}
      >
        <animated.div
          className={style.template}
          style={templateStyle}
          onContextMenu={handleContextMenu}
          onClick={handleClick}
          onFocus={handleFocus}
        >
          {loading && (
            <div className={style.loading}>
              <Loader full />
            </div>
          )}
          <div className={style.template__image}>
            <img className={cls(style.image, style.image_template)} src={imageUrl} alt="template" />
          </div>
          <div className={style.template__description}>
            <div className={style.templateDescription}>
              <div className={style.templateDescription__text} onClick={handleTextClick}>
                {!isNameEditing && <>{name || "Untitled"}</>}
                {isNameEditing && (
                  <input
                    className={style.input}
                    type="text"
                    title="text"
                    name="name"
                    value={name}
                    onFocus={handleInputFocus}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyUp={handleInputKeyup}
                  />
                )}
              </div>
              <div className={style.templateDescription__datetime}>{datetime}</div>
            </div>
          </div>
        </animated.div>
      </Dropdown>
    </div>
  );
};

export default FunctionItem;
