import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import { getIt } from "@uikit/getIt";
import { getNodeWidthHeight } from "@uikit/util/EdgeCalculate";
import { NodeConfigUtil } from "@uiview/views/Nodes/NodeConfigUtil";
import { initialReportSizeMapping } from "@uiview/views/Nodes/nodeConfig";
import { DisplayType } from "@uiview/views/Nodes/typing";
import { DispatchType } from "@uikit/store/CreatorNodesStore";
import { GroupCOEStatus, HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { chain } from "lodash";
import { Node } from "reactflow";

type ResetGroupPositionParams = {
  nodesCurrent: Node<any>[];
  disableAddPreview: boolean;
  nodeData: {
    displayType: DisplayType;
    parentNodeId?: string;
  } & {
    [key: string]: any;
  };
  init: boolean;
  hasParentNode?: boolean;
  id?: string;
};

export class CustomNodeUtil {
  static getDistance = (displayType: DisplayType): number => (displayType === "blueprint" ? 50 : 10);
  static titleDistance = CreatorNodesConsts.titleDistance;
  static collapseDistance = CreatorNodesConsts.collapseDistance;

  static getDefaultHight(displayType: string, type?: string): number | undefined {
    if (type === "customGroup" || type === "customNewEdge") {
      return 0;
    }
    if (displayType === "text") {
      return NodeConfigUtil.getNodeLayoutByType("text")?.minHeight;
    }
    return NodeConfigUtil.getNodeLayoutByType(displayType as DisplayType).height;
  }

  static resetGroupPosition({
    nodesCurrent,
    disableAddPreview,
    nodeData,
    init,
    hasParentNode = false,
  }: ResetGroupPositionParams): Node<any>[] {
    const homeStore: any = getIt(HomeStore);
    const currGroupCOEData = homeStore.state.groupCOEData.find(
      (x: { groupId: string | undefined }) => x.groupId === nodeData?.parentNodeId
    );
    const isGroupCollapse = currGroupCOEData?.groupCOEStatus === GroupCOEStatus.Collapse;

    const distance = this.getDistance(nodeData.displayType);
    const enableInit = nodeData.displayType === "blueprint" ? false : init;
    const initWidth = initialReportSizeMapping[nodeData.displayType].width;
    const currentGroupNodes = chain(nodesCurrent)
      .filter(x => x.parentNode === nodeData?.parentNodeId && !x.hidden)
      .sort((a, b) => a.position.y - b.position.y)
      .value();
    const minX = Math.min(...currentGroupNodes.map(x => x.position.x));
    const maxNodeWidth = Math.max(...currentGroupNodes.map(x => getNodeWidthHeight(x, "width", initWidth)));
    const groupWidth = maxNodeWidth + 2 * distance + (hasParentNode ? distance : 0);
    const totalHeight =
      currentGroupNodes
        .map(x => getNodeWidthHeight(x, "height", undefined, this.getDefaultHight(x.data.displayType, x.type)))
        .reduce((total, x) => total + x + distance / 2, distance) +
      (disableAddPreview ? 0 : 50) +
      (hasParentNode ? distance : 0) +
      (isGroupCollapse ? 30 : 0);
    const [, nodePosYObj] = currentGroupNodes.reduce(
      ([prevPosY, map]: any, current) => {
        // blueprint的组需要加大间距
        if (current.data.displayType === "blueprint" && current.type === "customGroup") {
          prevPosY += 40;
        }
        map[current.id] = prevPosY;

        const currentChildHeight = getNodeWidthHeight(
          current,
          "height",
          undefined,
          this.getDefaultHight(current.data.displayType, current.type)
        );
        prevPosY = prevPosY + currentChildHeight + distance / 2 + (current.type === "customGroup" ? distance : 0);
        return [prevPosY, map];
      },
      [distance, {}]
    );

    // 重新定义该group和该group下所有的node的位置
    const nodes = chain(nodesCurrent)
      .map(node => {
        // group
        if (node.id === nodeData?.parentNodeId) {
          return {
            ...node,
            style: {
              height: totalHeight + this.titleDistance + this.collapseDistance,
              width: groupWidth,
            },
            position: {
              y: node.position.y,
              x: node.position.x,
            },
          };
        }
        // 子node
        if (node.parentNode === nodeData?.parentNodeId) {
          return {
            ...node,
            position: {
              y: nodePosYObj[node.id] + this.titleDistance,
              x: enableInit ? distance : node.position.x - minX + distance, // resize时候重新对齐
            },
            style: {
              ...node.style,
              width: enableInit ? maxNodeWidth : node.style?.width,
            },
          };
        }
        return node;
      })
      .value();

    const parentNode = nodes.find(node => {
      if (node?.id === nodeData?.parentNodeId && node.parentNode) {
        return true;
      }
      return false;
    });

    if (parentNode) {
      return this.resetGroupPosition({
        nodesCurrent: nodes,
        disableAddPreview,
        nodeData: parentNode.data,
        init: enableInit,
        hasParentNode: true,
        id: parentNode.id,
      });
    }

    return nodes;
  }

  static setMaxNodeResize(data: any, params: any): { width: number; height: number } {
    if (data.displayType === undefined || data.parentNodeId) {
      return params;
    }
    const obj = { ...params };
    const layout = NodeConfigUtil.getNodeLayoutByType(data.displayType);
    if (layout.maxWidth && params.width > layout.maxWidth) {
      obj.width = layout.maxWidth;
    }
    if (layout.maxHeight && params.height > layout.maxHeight) {
      obj.height = layout.maxHeight;
    }
    return obj;
  }
  /// 初始化时设置node的最大边界
  static setNodeMaxLayout(data: any, id: string, setNodes: (value: DispatchType<Node<any>[]>) => void): void {
    if (data.parentNodeId) return;
    const layout = NodeConfigUtil.getNodeLayoutByType(data.displayType);
    setNodes((prevList: any[]) => {
      return prevList.map(x => {
        if (x.id === id) {
          return {
            ...x,
            style: {
              ...x.style,
              maxHeight: layout.maxHeight,
              maxWidth: layout.maxWidth,
            },
          };
        }
        return x;
      });
    });
  }

  static getMinSize(data: any): { width: number | undefined; height: number | undefined } {
    const layout = NodeConfigUtil.getNodeLayoutByType(data.displayType);
    if (data.displayType === "text" && data.parentNodeId) {
      return {
        width: layout.minWidth,
        height: layout.minHeight,
      };
    }
    return {
      width: layout.minWidth ?? layout.width,
      height: layout.minHeight ?? layout.height,
    };
  }

  static enableResize(displayType: DisplayType, nodeId: string, checkNodeArr: any[], parentNodeId?: string): boolean {
    const layout = NodeConfigUtil.getNodeLayoutByType(displayType);
    if (!layout.enableResize) {
      return false;
    }
    if (parentNodeId) {
      return false;
    }
    return checkNodeArr.includes(nodeId);
  }

  static keepAspectRatio(displayType: DisplayType): boolean {
    const layout = NodeConfigUtil.getNodeLayoutByType(displayType);
    return layout.keepAspectRatio ?? false;
  }

  /// 当displayType 发生变化时，如果node被拖动导致大小改变，还原node默认大小。
  static reductionNodeEventSize(setNodes: (node: any) => any, id: string): void {
    setNodes((prevList: any) => {
      return prevList.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            style: {},
          };
        }
        return item;
      });
    });
  }

  static getNode(nodes: Node[], id: string): Node | undefined {
    return nodes.find((node: Node) => node.id === id);
  }
}
