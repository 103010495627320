import React from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import CardHome from "./CardHome";
import "../views-css/CardFrame.css";

function CardFrame(props) {
  const on_select_card = content => {
    // if (typeof content === 'string') {
    //   props.select_card(props.id, content);
    //   return;
    // }
    props.select_card(props.id, props.card.thought);
  };

  const mode = useSelector(state => state.fot.mode);
  const item = {
    init: {
      opacity: 0,
      y: "100%",
      scale: 0.8,
    },
    show: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
  };

  return (
    <motion.div
      className={`CardFrame`}
      variants={item}
      onClick={props.otherModes?.includes(mode) ? () => {} : () => on_select_card(props.card.content)}
    >
      {props.reason ? (
        <section>
          <article className="CardFrameContent">
            <CardHome
              type={"thought"}
              card={props.card}
              zone={props.zone}
              handleImageLoaded={props.handleImageLoaded}
            />
          </article>
          <article className="CardFrameReason">
            <p>We recommend the following content because you have seen similar stuff in the past</p>
          </article>
        </section>
      ) : (
        <section className="CardFrameContent">
          <CardHome
            className={props.className}
            id={props.id}
            type={"thought"}
            isShare={props.isShare}
            card={props.card}
            len={props.len}
            page={props.page}
            zone={props.zone}
            editorId={props.editorId}
            currentPageLoadingState={props.currentPageLoadingState}
            handleImageLoaded={props.handleImageLoaded}
            locus={props.locus}
          />
        </section>
      )}
    </motion.div>
  );
}

export default CardFrame;
