import { HTMLAttributes, PropsWithChildren } from "react";
import css from "./index.module.scss";
import classNames from "classnames";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { PreviewPaneStore } from "@uiview/views/PreviewPane/PreviewPaneStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { matchMobile } from "@uikit/func";

type AISaasLayoutProps = HTMLAttributes<HTMLDivElement> & {
  header?: React.ReactNode;
};

/**
 * body 滚动权交给 children, layout 只提供溢出间隙
 * @param props
 * @returns
 */
export function AISaasLayout(props: PropsWithChildren<AISaasLayoutProps>): JSX.Element {
  const isMobile = matchMobile();
  const { header, className, ...restProps } = props;
  const previewPaneStore = getIt(PreviewPaneStore);
  const previewPaneState = useStore(previewPaneStore).value;
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;

  return (
    <div
      data-testid="AISaasLayout"
      {...restProps}
      className={classNames(css["layout"], { [css.layoutMobile]: isMobile && featureTags.disableMobilePage })}
    >
      <div className={css["layout-background"]}></div>
      <div className={`${css["layout-content"]} ${previewPaneState.settingPageStatus ? css.coverSettingContent : ""}`}>
        {header ? (
          <div
            className={classNames(css.header, {
              [css.headerMobile]: isMobile && featureTags.disableMobilePage,
              [css.publishV3Header]: featureTags.enablePublishV3 && featureTags.disableMultiRoundChatOnboarding,
            })}
          >
            <div className={css["header-inner"]}>{header}</div>
          </div>
        ) : null}
        <div className={classNames(css["body"], className)}>{props.children}</div>
      </div>
    </div>
  );
}
