import { computed, signal, Signal } from "@preact/signals-react";
import { BrainClient as CorekitBrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { getIt } from "@uikit/getIt";
import { CustomApiService } from "imagica-corekit/dist/cases/customApi/CustomApiService";
import { AuthStore } from "imagica-corekit/dist/base/store/AuthStore";
import { CustomApiProps } from "imagica-corekit/dist/base/storyV2/adapter/CustomApiAdapter";
import { FuncCustomVariable } from "imagica-corekit/dist/base/storyV2/function/FunctionBase";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { GenUtil } from "imagica-corekit/dist/base/cutil/GenUtil";
import { Header } from "@uiview/pages/customApi/HeaderList";
import func from "@uikit/func";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { CustomApiUtil } from "imagica-corekit/dist/cases/util/CustomApiUtil";
import { Type } from "class-transformer";
import { ResponseDataUtil } from "@uikit/util/ResponseDataUtil";

class ApiResponseData {
  type = "";
  value = {};
}
export class ApiResponse {
  size = "";
  status = 200;
  statusText = "";
  time = "";
  responseHeaders = {};
  @Type(() => ApiResponseData)
  data = new ApiResponseData();
}

export class RequestData {
  url = "";
  method = "get";
  headerList: Header[] = [];
  bodyParam = "";
  copyWith(params: { url?: string; method?: string; headerList?: Header[]; bodyParam?: string }): RequestData {
    return GenUtil.copyWith(new RequestData(), this, params);
  }
}
export class CustomApiPageState {
  createAPIFuncOpen: Signal<boolean> = signal(false);
  apiLoading = signal(false);
  showPublishFunctionConfirm = signal(false);
  publishFunctionLoading = signal(false);
  isFuncLoading = signal(false);
  isNewCustomFunctionOpen = signal(false);
  selectBodyType = signal(false);
  selectedOption = signal("Json");
  childState = signal("");
  customFunctionTitle = signal("");
  functionId: Signal<string | null> = signal("");
  newFunctionData = signal({ name: "", description: "" });

  response: Signal<ApiResponse | null> = signal(new ApiResponse());
  requestData = signal(new RequestData());
  responseHeaderList = computed<{ key: string; name: string; value: string }[]>(() => {
    const responseHeaders = this.response.value?.responseHeaders as any;
    if (responseHeaders && !func.isEmpty(responseHeaders)) {
      const headerLists = Object.keys(responseHeaders).map((x, index) => {
        return {
          key: `${index}_${x.toLowerCase()}`,
          name: x,
          value: responseHeaders[x],
        };
      });
      return headerLists;
    }
    return [];
  });
}

export class CustomApiPageBloc {
  brainClient = getIt(CorekitBrainClient);
  meStore = getIt(MeStore);
  apiService = new CustomApiService(getIt(AuthStore), getIt(CorekitBrainClient));
  getSourceNodeValue: () => string = () => {
    return "";
  };
  getVarliableList: () => FuncCustomVariable[] = () => {
    return [];
  };
  getEvaluateFinalBodyStr(newBodyParam: string, sourceNodeValue: string, variableList: FuncCustomVariable[]): string {
    if (func.isEmpty(newBodyParam)) {
      return "";
    }
    // 注入currentSourceNodeValue
    return CustomApiUtil.evaluateFinalBodyStr(sourceNodeValue, newBodyParam, variableList);
  }

  evaluateFinalBodyStr = computed(() => {
    const requestData = this.state.requestData.value;
    return this.getEvaluateFinalBodyStr(requestData.bodyParam, this.getSourceNodeValue(), this.getVarliableList());
  });
  state = new CustomApiPageState();
  constructor(public enterPath: string) {
    this.initData();
  }
  async initData(): Promise<void> {
    const regex = /\/function\/(?:\d+|Untitled)/;
    const result = regex.test(this.enterPath);
    if (result) {
      const hasPermission = await this.meStore.hasCustomApiV2();
      if (hasPermission) {
        this.switchPage(result);
      }
    }
  }

  get popProfilePath(): string {
    const parent = this.enterPath.split("/function").first();
    return parent ?? "";
  }

  switchPage(value: boolean): void {
    this.state.createAPIFuncOpen.value = value;
  }

  async sendRequest(apiData: CustomApiProps, input: string, variableList: FuncCustomVariable[]): Promise<void> {
    this.state.apiLoading.value = true;
    const start = Date.now();
    const tryResult = await tryPromise(this.apiService.sendRequest(apiData, input, variableList));
    this.state.apiLoading.value = false;
    if (tryResult.error) {
      console.log("error: ", tryResult, tryResult.error);
      return;
    }
    const response: any = tryResult.data;
    if (!response) {
      return;
    }
    const data = await ResponseDataUtil.handleGlobalResponseStatusData(response, start);
    this.state.response.value = JsonUtil.toModelFromType(ApiResponse, data) ?? new ApiResponse();
  }

  extractNumber(inputString: string): string | null {
    const startIndex = inputString.indexOf("function/") + "function/".length;
    const endIndex = inputString.length;

    if (startIndex >= "function/".length && endIndex > startIndex) {
      const extractedString = inputString.substring(startIndex, endIndex);

      // 增加检查，确保后面不是 "/Untitled"
      if (extractedString !== "Untitled") {
        return extractedString;
      }
    }

    return null;
  }

  setIsNewCustomFunctionOpen = (value: boolean): void => {
    this.state.isNewCustomFunctionOpen.value = value;
  };
  publishFunction = (value: boolean): void => {
    this.state.showPublishFunctionConfirm.value = value;
  };
  setCustomFunctionTitle = (value: string): void => {
    this.state.customFunctionTitle.value = value;
  };
  handleFunctionData = (): void => {
    this.setCustomFunctionTitle("");
    this.setIsNewCustomFunctionOpen(false);
  };
  setNewFunctionData = (data: Signal<{ name: string; description: string }> | any): void => {
    this.state.newFunctionData.value = data;
  };
  newFunctionDataChange = (e: React.ChangeEvent<HTMLInputElement>, key: string): void => {
    const data = Object.assign({}, { ...this.state.newFunctionData.value }, { [key]: e.target.value });
    this.setNewFunctionData(data);
  };
  setChildState = (value: string): void => {
    this.state.childState.value = value;
  };
  setSelectBodyType = (value: boolean): void => {
    this.state.selectBodyType.value = value;
  };
  setSelectedOption = (value: string): void => {
    this.state.selectedOption.value = value;
  };
  handleMenuClick = (option: string): void => {
    this.setSelectedOption(option);
    this.setSelectBodyType(false);
  };
}
