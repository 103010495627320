"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryNodeValidator = void 0;
const StoryNodeDisplayType_1 = require("../storyV2/domain/StoryNodeDisplayType");
class StoryNodeValidator {
    static isValidStoryNodeDisplayType(input) {
        return Object.values(StoryNodeDisplayType_1.StoryNodeDisplayType).includes(input);
    }
}
exports.StoryNodeValidator = StoryNodeValidator;
