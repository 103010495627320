"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETheme = void 0;
const DarkTheme_1 = require("./DarkTheme");
const DefaultTheme_1 = require("./DefaultTheme");
class ETheme {
}
exports.ETheme = ETheme;
ETheme.theme = {
    saasAiAppTheme: {
        ...DarkTheme_1.DarkTheme.theme.saasAiAppTheme,
        background: "linear-gradient(180deg, #203481 0%, #2654E8 100%)",
    },
    nodeTheme: {
        ...DarkTheme_1.DarkTheme.theme.nodeTheme,
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBorder: "0",
        inputBorderBottom: "1px solid rgba(255, 255, 255, 0.10)",
        inputPadding: "15px 25px",
        inputBorderRadius: "0px",
        inputBoxShadow: "none",
        topSuctionBgc: "transparent",
        submitButtonBorder: "1px solid #fff",
        submitButtonBgClr: "transparent",
        submitButtonColor: "#fff",
        askImagicaDotBackground: DefaultTheme_1.DefaultTheme.theme.nodeTheme.askImagicaDotBackground,
        nodeBackdropFilter: DefaultTheme_1.DefaultTheme.theme.nodeTheme.nodeBackdropFilter,
    },
};
