import React from "react";
import { useGuideContainer } from "./useGuideContainer";
import { GuideModal } from "./GuideModal";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { getIt } from "@uikit/getIt";

export type GuideContainerProps = {};

export const GuideContainer: React.FC<GuideContainerProps> = React.memo(props => {
  const { guideConfig } = useGuideContainer();
  const homeStore = getIt(HomeStore);

  const featureTags = homeStore.state.featureTags;

  // TODO: revisit featureTags.disableMultiRoundChatOnboarding
  return <>{guideConfig && <GuideModal {...guideConfig} />}</>;
});
