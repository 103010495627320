"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferencesStore = void 0;
const Me_1 = require("../api/userTyped/Me");
const StoreBase_1 = require("../cutil/StoreBase");
const Lazyload_1 = require("../cutil/Lazyload");
const lodash_1 = require("lodash");
const JsonUtil_1 = require("../cutil/JsonUtil");
class PreferencesStore extends StoreBase_1.StoreBase {
    constructor(brainClient) {
        super(() => []);
        this.brainClient = brainClient;
        this.lazyload = new Lazyload_1.Lazyload(() => {
            return this.brainClient.user.preferences().then(x => {
                const state = x.data ?? new Me_1.Me();
                this.emit(state);
                return state;
            });
        });
    }
    dirty() {
        this.lazyload.dirty();
    }
    async renew() {
        this.lazyload.renew();
    }
    async get() {
        return this.lazyload.get();
    }
    async getByKey(key) {
        const data = await this.get();
        return data.filter(x => x.key == key).first()?.value;
    }
    async set(key, value) {
        const param = {
            key: key,
            value: value,
        };
        return this.brainClient.user.setPreference(param).then(x => {
            this.lazyload.dirty();
            return x.data;
        });
    }
    async recieve(key, value) {
        let data = await this.get();
        const newData = data.map(x => {
            if (x.key == key) {
                x.value = value;
            }
            return x;
        });
        this.lazyload.dirty();
        this.emit(newData);
    }
    /// 修改本地TermsRead值
    async changeLocalTermsRead(version, read) {
        const termsValue = await this.parseNewTerms(version, read);
        this.recieve(PreferencesStore.termsReadKey, termsValue);
    }
    // 根据当前数据组装新的terms数据
    async parseNewTerms(version, read) {
        const key = PreferencesStore.termsReadKey;
        const value = { version: version, read: read };
        const newData = await this.get();
        const newValue = (0, lodash_1.cloneDeep)(newData);
        let termsValue = newValue.find(x => x.key === key)?.value;
        if (!termsValue) {
            termsValue = [value];
        }
        else {
            const versionIndex = termsValue.findIndex((x) => x.version === version);
            if (versionIndex == -1) {
                termsValue.push(value);
            }
            else {
                termsValue.splice(versionIndex, 1, value);
            }
        }
        return [...termsValue];
    }
    /// api 设置imagica_terms_popup_read
    async setTermsRead(version, read) {
        const lastData = await this.get();
        const lastTerms = lastData.filter(x => x.key === PreferencesStore.termsReadKey)?.first();
        const termsValue = await this.parseNewTerms(version, read);
        // 需要更新的数据相同则不去更新
        if (JsonUtil_1.JsonUtil.stringify(lastTerms?.value) === JsonUtil_1.JsonUtil.stringify(termsValue)) {
            return Promise.resolve(lastData);
        }
        // 先更改本地数据
        this.changeLocalTermsRead(version, read);
        return this.set(PreferencesStore.termsReadKey, termsValue);
    }
    // 获取termsRead
    async termsRead(version) {
        return await this.get().then(x => {
            const value = x.filter(y => y.key === PreferencesStore.termsReadKey).first()?.value;
            if ((0, lodash_1.isEmpty)(value))
                return false;
            return value?.filter((item) => item.version === version)?.first()?.read ?? false;
        });
    }
}
exports.PreferencesStore = PreferencesStore;
PreferencesStore.termsReadKey = "imagica_terms_popup_read";
