"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeStore = exports.HomeStoreState = exports.GroupCOEStatus = void 0;
const GenUtil_1 = require("../../base/cutil/GenUtil");
const StoreBase_1 = require("../../base/cutil/StoreBase");
const FeatureTags_1 = require("../../base/domain/project/FeatureTags");
var GroupCOEStatus;
(function (GroupCOEStatus) {
    GroupCOEStatus[GroupCOEStatus["Collapse"] = 0] = "Collapse";
    GroupCOEStatus[GroupCOEStatus["Expand"] = 1] = "Expand";
})(GroupCOEStatus || (exports.GroupCOEStatus = GroupCOEStatus = {}));
class GroupCOEData {
    constructor() {
        this.groupId = "";
    }
}
class HomeStoreState {
    constructor() {
        this.isClickAddNewProject = false;
        this.homeNavSaveLoading = false;
        this.groupCOEData = [];
        this.featureTags = new FeatureTags_1.FeatureTags();
    }
    copyWith(params) {
        return GenUtil_1.GenUtil.copyWith(new HomeStoreState(), this, params);
    }
}
exports.HomeStoreState = HomeStoreState;
class HomeStore extends StoreBase_1.StoreBase {
    constructor() {
        super(() => new HomeStoreState());
    }
    setState(param) {
        let newState = this.state.copyWith(param);
        this.emit(newState);
    }
    setIsClickAddNewProject(isClickAddNewProject) {
        this.setState({ isClickAddNewProject });
    }
    setHomeNavSaveLoading(homeNavSaveLoading) {
        this.setState({ homeNavSaveLoading });
    }
    setGroupCOEData(groupCOEData) {
        let newGroupCOEData = this.state.groupCOEData;
        const existIdx = this.state.groupCOEData.findIndex((x) => x.groupId === groupCOEData.groupId);
        const mergedGroupCOEData = Object.assign({}, this.state.groupCOEData[existIdx] || {
            groupId: "",
            groupCOEStatus: 1,
            collapseChildNodes: [],
        }, groupCOEData);
        if (existIdx > -1) {
            newGroupCOEData.splice(existIdx, 1, mergedGroupCOEData);
        }
        else {
            newGroupCOEData.push(mergedGroupCOEData);
        }
        this.setState({ groupCOEData: newGroupCOEData });
    }
    cleanGroupCOEData() {
        this.setState({ groupCOEData: [] });
    }
    setFeatureTags(featureTags) {
        this.setState({ featureTags });
    }
}
exports.HomeStore = HomeStore;
