"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsCategoryDetail = exports.NewsDetailsFeed = exports.VBDataNewsDigestDetails = void 0;
const VBGraphDataBase_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataNewsDigestDetails extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.news_details_feed = [];
    }
}
exports.VBDataNewsDigestDetails = VBDataNewsDigestDetails;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => NewsDetailsFeed, [])
], VBDataNewsDigestDetails.prototype, "news_details_feed", void 0);
class NewsDetailsFeed {
    constructor() {
        this.news_category_detail = [];
        this.news_category_title = "";
    }
}
exports.NewsDetailsFeed = NewsDetailsFeed;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => NewsCategoryDetail, [])
], NewsDetailsFeed.prototype, "news_category_detail", void 0);
class NewsCategoryDetail {
    constructor() {
        this.category_tag = "";
        this.news_source_icon = "";
        this.news_title = "";
        this.news_url = "";
        this.time_posted = "";
    }
}
exports.NewsCategoryDetail = NewsCategoryDetail;
