import { chain, every, isNil } from "lodash";
import { Node } from "reactflow";
import { Input as BluePrintValue } from "imagica-corekit/dist/base/api/blueprintTyped/BlueprintV2";
import { DisplayType } from "../typing";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { IMAGE_TYPES } from "@views/thinking-layout-editor/constants";
import { getIt } from "@uikit/getIt";
import { RestResponse } from "imagica-corekit/dist/base/cutil/RestClient";

export type GetExpandedBluePrintNodes = {
  id: string;
  bluePrintValue: BluePrintValue;
  nodeIndexRef: React.MutableRefObject<number>;
  nodeDataRef: React.MutableRefObject<any>;
  nodesRef: React.MutableRefObject<Node<any>[]>;
};

type BlueprintConstructorParams = {
  textAreaValue: BluePrintValue;
  nodeId: string;
  nodeDataRef: React.MutableRefObject<any>;
  parentNode: string;
  fromList: boolean;
};
class BlueprintConstructor {
  constructor(public params: BlueprintConstructorParams) {}
  generateBlueprint(): Node<any> {
    return {
      id: this.params.nodeId,
      type: "customNode",
      parentNode: this.params.parentNode,
      extent: "parent",
      data: {
        ...this.params.nodeDataRef.current,
        textAreaValue: this.params.textAreaValue,
        displayType: "blueprint",
        parentNodeId: this.params.parentNode,
        isInBlueprint: true,
        fromList: this.params.fromList,
      },
      position: {
        x: 0,
        y: 0,
      },
    };
  }
}

export function getExpandedBluePrintNodes({
  id,
  bluePrintValue,
  nodeIndexRef,
  nodeDataRef,
  nodesRef,
}: GetExpandedBluePrintNodes): Node<any>[] {
  const isListNode = bluePrintValue.list === true;
  let nodes: Node<any>[] = [];
  const childNode: string[] = [];
  if (isListNode) {
    nodes = bluePrintValue.value.map((item: string) => {
      const nodeId = `editor-${nodeIndexRef.current++}`;
      childNode.push(nodeId);
      return new BlueprintConstructor({
        nodeDataRef,
        nodeId,
        parentNode: id,
        textAreaValue: {
          data: {
            type: bluePrintValue.type,
            name: "",
            value: item,
            list: false,
            level: 0,
          },
          name: "",
        } as unknown as BluePrintValue,
        fromList: true,
      }).generateBlueprint();
    }) as unknown as Node<any>[];
  } else {
    nodes = chain(bluePrintValue)
      .get("components")
      .map(blueprint => {
        const nodeId = `editor-${nodeIndexRef.current++}`;
        childNode.push(nodeId);
        return new BlueprintConstructor({
          nodeDataRef,
          nodeId,
          parentNode: id,
          textAreaValue: {
            data: blueprint,
            name: blueprint.name,
          } as unknown as BluePrintValue,
          fromList: false,
        }).generateBlueprint();
      })
      .value();
  }

  console.log("nodes", nodes);

  const prevNodes = chain(nodesRef)
    .get("current")
    .map(node => {
      if (node.id === id) {
        // 子node执行是根据customGroup data.childNode去寻找 所以必须更新group的childNode
        return changeBlueprintNodeType(node, "customGroup", childNode);
      }
      return node;
    })
    .value();

  return [...prevNodes, ...nodes];
}

export type GetCollapseBluePrintNodeParams = {
  id: string;
  nodesRef: React.MutableRefObject<Node<any>[]>;
};

export function getCollapseBluePrintNode({ id, nodesRef }: GetCollapseBluePrintNodeParams): {
  nodes: Node<any>[];
  changedTypeNodeId: string | undefined;
} {
  const needDeleteIds: string[] = [];
  let changedTypeNodeId: string | undefined;
  const nodes = chain(nodesRef)
    .get("current")
    .forEach(node => {
      if (node.parentNode === id || needDeleteIds.includes(node.parentNode || "")) {
        needDeleteIds.push(node.id);
      }
    })
    .filter(node => needDeleteIds.includes(node.id) === false)
    .map((node, _, array) => {
      if (
        node.data.displayType === "blueprint" &&
        node.type === "customGroup" &&
        every(array, item => item.parentNode !== node.id)
      ) {
        changedTypeNodeId = node.id;
        return changeBlueprintNodeType(node, "customNode");
      }
      return node;
    })
    .value();
  return { nodes, changedTypeNodeId };
}

export function changeBlueprintNodeType(
  node: Node<any>,
  type: "customGroup" | "customNode",
  childNode?: string[]
): Node<any> {
  return {
    ...node,
    type,
    data: {
      ...node.data,
      childNode: childNode ?? node.data.childNode,
    },
  };
}

export type AutoCreateInputNodeProps = {
  nodes: Node[];
  edgeNode: Node;
  inputs: Array<{ description: string; name: string; type: string }>;
};

export function flattenBluePrintArray(print: BluePrintValue): BluePrintValue[] {
  const result: BluePrintValue[] = [];

  function flattenHelper(arr: BluePrintValue[], level: number): void {
    for (const item of arr) {
      if (item) {
        result.push({ ...item, level });
        if (item.components && item.components.length > 0) {
          flattenHelper(item.components, level + 1);
        }
      }
    }
  }

  // 如果有compoents只显示第二层
  if (isNil(print) === false) {
    const current = print?.components?.length ? print?.components : [print];
    flattenHelper(current, 0);
  }

  return result;
}

export function transformNodeType(type: string, list: boolean): DisplayType {
  if (list) {
    return "list";
  }

  // viewbuilder后台返回的type和前端不统一
  /// 后续统一后删除
  if (type === "image") {
    return "imageGen";
  }

  return type as DisplayType;
}

/**
 *
 * @param type 节点类型
 * @param list 是否是list
 * @param value 节点value
 * @returns 对应节点传递textareaValue的格式
 */
export function transformNodeValue(type: string, list: boolean, value: any): any {
  const isImageNode = IMAGE_TYPES.includes(type);
  if (type === "text" && list === false) {
    return [{ results: [{ content: value }] }];
  }
  if (isImageNode) {
    return { value };
  }
  return value;
}

export async function feedBluePrintMessage(data: { id: string; feedback: string }): Promise<RestResponse<any>> {
  const brainClient = getIt(BrainClient);
  return await brainClient.openAi.blueprintFeedback(data);
}

export async function dislikePrint(data: {
  id: string;
  feedback: string;
  inputs: any[];
  output: object;
}): Promise<void> {
  const brainClient = getIt(BrainClient);
  await brainClient.openAi.blueprintDislike(data);
}
