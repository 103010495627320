"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformServiceHandlerChain = exports.Handler = void 0;
class Handler {
    async process(request) {
        return false;
    }
}
exports.Handler = Handler;
class TransformServiceHandlerChain {
    constructor() {
        this.handles = [];
    }
    addHandler(handle) {
        this.handles.push(handle);
    }
    async process(request) {
        for (let index = 0; index < this.handles.length; index++) {
            const handler = this.handles[index];
            const isCompletion = await handler.process(request);
            if (isCompletion) {
                break;
            }
        }
    }
}
exports.TransformServiceHandlerChain = TransformServiceHandlerChain;
