import { EdgeRunCore } from "@uikit/edgeRun/EdgeRunCore";
import { EdgeRunGroupHandler } from "@uikit/edgeRun/EdgeRunGroupHandler";
import { EdgeRunHandler } from "@uikit/edgeRun/EdgeRunHandler";
import { EdgeRunHandlerRequest } from "@uikit/edgeRun/EdgeRunHandlerRequest";
import { EdgeRunQuery } from "@uikit/edgeRun/EdgeRunQuery";
import { EdgeRunService } from "@uikit/edgeRun/EdgeRunService";
import { EdgeRunTypeAskBrain } from "@uikit/edgeRun/EdgeRunTypeAskBrain";
import { EdgeRunTypeChatBox } from "@uikit/edgeRun/EdgeRunTypeChatBox";
import { EdgeRunTypeStock } from "@uikit/edgeRun/EdgeRunTypeStock";
import { EdgeRunTypeUpload } from "@uikit/edgeRun/EdgeRunTypeUpload";
import { EdgeRunUpdater } from "@uikit/edgeRun/EdgeRunUpdater";
import { EdgeRunVariable } from "@uikit/edgeRun/EdgeRunVariable";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorNodesErrorTips } from "@uikit/service/CreatorNodesErrorTips";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { EdgeRunAll } from "@uikit/service/EdgeRunAll";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { OpenAiParam } from "@uikit/service/OpenAiParam";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";
import { RunEdgeAddNodeToPreview } from "@uikit/service/RunEdgeAddNodeToPreview";
import { ShareAppService } from "@uikit/service/ShareAppService";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorRefStore, creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { customFunctionStore } from "@uikit/store/CustomFunctionStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { MessageCenter } from "imagica-corekit/dist/base/kernel/MessageCenter";
import { UploadService } from "imagica-corekit/dist/base/service/UploadService";
import { ViewBuilderService } from "imagica-corekit/dist/base/service/ViewBuilderService";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { StoryManager } from "imagica-corekit/dist/base/storyV2/domain/StoryManager";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { Container } from "inversify";

/**
 * 该 class 用于注册运行时的逻辑
 */
export class RegisterEdgeRun {
  /**
   * 该方法在 configure 之前调用
   * @param container
   */
  static preConfigure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };

    container
      .bind(CreatorStoreMethods)
      .toConstantValue(
        new CreatorStoreMethods(
          it(FotReduxStore),
          it(HomePluginStore),
          it(HomeMethodsService),
          it(CreatorNodesStore),
          it(CreatorEdgesStore),
          creatorRefStore,
          homeToolBarStore,
          customFunctionStore,
          it(CanvasDataRef)
        )
      );

    container
      .bind(OpenAiParam)
      .toConstantValue(
        new OpenAiParam(it(FotReduxStore), it(CreatorNodesStore), it(ProjectNodeStore), it(PostUrlCanAbortService))
      );
    container
      .bind(EdgeRunTypeStock)
      .toConstantValue(new EdgeRunTypeStock(it(CreatorStoreMethods), it(OpenAiParam), it(CreatorNodesErrorTips)));
  }

  static configure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };

    const runEdgeAddNodeToPreview = new RunEdgeAddNodeToPreview(
      it(CreatorNodesStore),
      it(CreatorPreviewService),
      it(PreviewAppStore),
      it(CreatorRefStore),
      it(HomeHandleFieldMethods),
      it(CreatorAISaasStore)
    );

    const edgeRunQuery = new EdgeRunQuery(it(CreatorEdgesStore), it(CreatorNodesStore), it(OpenAiParam));

    const edgeRunTypeChatBox = new EdgeRunTypeChatBox(
      it(ObjectRelationGqlService),
      it(CreatorSaasAppStore),
      it(CreatorNodesStore),
      it(CreatorStoreMethods)
    );

    const edgeRunTypeUpload = new EdgeRunTypeUpload(
      it(CreatorNodesStore),
      it(CreatorEdgesStore),
      it(CreatorStoreMethods)
    );

    const edgeRunTypeAskBrain = new EdgeRunTypeAskBrain(
      it(FotReduxStore),
      it(PostUrlCanAbortService),
      it(CreatorStoreMethods),
      it(MessageCenter),
      it(CreatorNodesStore)
    );
    const edgeRunVariable = new EdgeRunVariable(it(FotAuthStore), it(FotReduxStore));

    const edgeRunHandlerRequest = new EdgeRunHandlerRequest(
      it(FotReduxStore),
      edgeRunVariable,
      it(CreatorNodesStore),
      it(CreatorStoreMethods),
      it(OpenAiParam),
      it(PostUrlCanAbortService)
    );

    const edgeRunCore = new EdgeRunCore(
      it(FotReduxStore),
      it(CreatorNodesStore),
      it(CreatorEdgesStore),
      it(StoryManager),
      it(FotSaveService),
      myModificationDataStroe,
      it(HomeStore),
      it(MeStore),
      it(PersonalFunctionStore)
    );
    const edgeRunGroupHandler = new EdgeRunGroupHandler(
      edgeRunCore,
      it(ProjectNodeStore),
      projectPageStore,
      it(CanvasDataRef)
    );
    const edgeRunHandler = new EdgeRunHandler(
      edgeRunCore,
      edgeRunGroupHandler,
      it(ViewBuilderService),
      it(UploadService),
      it(CreatorStoreMethods),
      it(CanvasDataRef)
    );
    const edgeRunService = new EdgeRunService(
      edgeRunCore,
      edgeRunHandler,
      new EdgeRunUpdater(edgeRunCore, it(CreatorSaasAppStore), it(CreatorPreviewService))
    );

    const edgeRunAll = new EdgeRunAll(
      it(CreatorNodesStore),
      it(CreatorRefStore),
      it(CreatorEdgesStore),
      it(FotSaveService),
      it(CreatorSaasAppStore),
      it(FotReduxStore),
      edgeRunService,
      it(ShareAppService),
      it(HomeStore),
      it(CreatorStoreMethods),
      newOnboardingFlowStore,
      runEdgeAddNodeToPreview,
      it(ProjectRunStore)
    );

    container.bind(RunEdgeAddNodeToPreview).toConstantValue(runEdgeAddNodeToPreview);
    container.bind(EdgeRunQuery).toConstantValue(edgeRunQuery);
    container.bind(EdgeRunTypeChatBox).toConstantValue(edgeRunTypeChatBox);
    container.bind(EdgeRunTypeUpload).toConstantValue(edgeRunTypeUpload);
    container.bind(EdgeRunTypeAskBrain).toConstantValue(edgeRunTypeAskBrain);
    container.bind(EdgeRunVariable).toConstantValue(edgeRunVariable);
    container.bind(EdgeRunHandlerRequest).toConstantValue(edgeRunHandlerRequest);
    container.bind(EdgeRunCore).toConstantValue(edgeRunCore);
    container.bind(EdgeRunGroupHandler).toConstantValue(edgeRunGroupHandler);
    container.bind(EdgeRunHandler).toConstantValue(edgeRunHandler);
    container.bind(EdgeRunService).toConstantValue(edgeRunService);
    container.bind(EdgeRunAll).toConstantValue(edgeRunAll);
  }
}
