"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainApi = void 0;
const DomainStudio_1 = require("./DomainTyped/DomainStudio");
class DomainApi {
    constructor(restClient) {
        this.restClient = restClient;
    }
    async domainCheck(data) {
        const res = await this.restClient.post("/api/users/me/domains", data);
        return res.validate();
    }
    async deleteDomain(data) {
        const res = await this.restClient.delete("/api/users/me/domains", data);
        return res.validate();
    }
    async guestUserKey(id) {
        const res = await this.restClient.get(`/api/studios/${id}`);
        return res.toModelFromType(DomainStudio_1.DomainStudio);
    }
    /**
     *
     * 原来是项目初始化的接口是单独调用的`/api/studios`, 现将初始也调用了该方法
     *
     * 且原来该方法是返回了一个 DomainStudio 实例，是经过强类型转换后的
     * ```
     * return res.toModelFromType(DomainStudio);
     * ```
     *
     * 但是，在调用该方法之前的逻辑时，接口数据是使用的原始数据是没有进行任何转换
     *
     * 所以为了兼容每一类写法，此处之定义返回类型，但不转换实例
     *
     * @param id
     * @param toModal
     * @returns
     */
    async getDomainStudio(id) {
        return await this.restClient.get(`/api/studios/${id}`);
    }
}
exports.DomainApi = DomainApi;
