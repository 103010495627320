"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveWarningError = exports.SaveInfoError = exports.SaveApiFailedError = exports.SaveError = void 0;
class SaveError extends Error {
    constructor(error) {
        super(typeof error === "string" ? error : "");
        this.error = error;
    }
}
exports.SaveError = SaveError;
class SaveApiFailedError extends SaveError {
    constructor(error) {
        super(error);
        this.error = error;
    }
}
exports.SaveApiFailedError = SaveApiFailedError;
class SaveInfoError extends SaveError {
    constructor(error) {
        super(error);
        this.error = error;
    }
}
exports.SaveInfoError = SaveInfoError;
class SaveWarningError extends SaveError {
    constructor(error) {
        super(error);
        this.error = error;
    }
}
exports.SaveWarningError = SaveWarningError;
