import isBlank from "@sedan-utils/is-blank";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { tryBlock } from "imagica-corekit/dist/base/cutil/LangUtil";
import { Story } from "imagica-corekit/dist/base/storyV2/domain/Story";
import { StoryNode } from "imagica-corekit/dist/base/storyV2/domain/StoryNode";
import { StoryNodeState } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeState";
import { StoryUtil } from "imagica-corekit/dist/base/storyV2/domain/StoryUtil";
class NodeContentParams {
  isGroup?: boolean;
  updateNode?: StoryNode;
  oldContentStr: string = "";
  key: string = "";
  story?: Story;
}

export class UpdateImgContextUtil {
  static updateImgNodeContext(updateNode: StoryNode, story: Story): void {
    const imgTypes = ["imageGen", "uploadImage"];
    const displayType = updateNode.options?.displayType || "";
    if (!imgTypes.includes(displayType) || updateNode.state !== StoryNodeState.COMPLETED) return;

    const funcObj: Record<string, any> = {
      imageGen: (param: any) => UpdateImgContextUtil.getNodeContent(param),
      uploadImage: (param: any) => UpdateImgContextUtil.getUploadImgNodeContent(param),
    };
    // 外层content
    updateNode.content = funcObj[displayType]({
      isGroup: !isBlank(updateNode.options.parentId),
      updateNode,
      oldContentStr: updateNode.content,
      key: "content",
      story,
    });
    //  options内层content
    updateNode.options.content = funcObj[displayType]({
      isGroup: !isBlank(updateNode.options.parentId),
      updateNode,
      oldContentStr: updateNode.options.content || "",
      key: "context",
      story,
    });
  }

  static getNodeContent(params: NodeContentParams): string {
    const content = tryBlock(() => JsonUtil.parse(params.oldContentStr));
    let newContentStr = params.oldContentStr;
    const story = params.story;
    const targetNode = params.updateNode;

    if (story && targetNode && content.data && !isBlank(content.data?.[params.key])) {
      let sourceNode = {} as any;
      if (params.isGroup) {
        const targetGroup = StoryUtil.findParent(story, targetNode).first();
        if (targetGroup) {
          const sourceGroup = StoryUtil.findNodeSourceNode(story, targetGroup).first();
          const idx = targetGroup.children.findIndex(x => x.id === targetNode.id);
          sourceNode = sourceGroup?.children[idx];
        }
      } else {
        sourceNode = StoryUtil.findNodeSourceNode(story, targetNode).first();
      }
      // 获取最新content
      const newContent = Object.assign({}, content.data, {
        [params.key]: sourceNode?.originContent || sourceNode?.content || "",
      });
      newContentStr = JsonUtil.stringify(newContent) || "";
    }
    return newContentStr;
  }

  static getUploadImgNodeContent(params: NodeContentParams): string {
    const parseContent = tryBlock(() => JsonUtil.parse(params.oldContentStr));
    let newContentStr = params.oldContentStr;
    if (isBlank(parseContent.data)) return newContentStr;
    const content = parseContent.data;
    const story = params.story;
    const targetNode = params.updateNode;

    if (story && targetNode) {
      let sourceNode = {} as any;
      if (params.isGroup) {
        const targetGroup = StoryUtil.findParent(story, targetNode).first();
        if (targetGroup) {
          const sourceGroup = StoryUtil.findNodeSourceNode(story, targetGroup).first();
          const idx = targetGroup.children.findIndex(x => x.id === targetNode.id);
          sourceNode = sourceGroup?.children[idx];
        }
      } else {
        sourceNode = StoryUtil.findNodeSourceNode(story, targetNode).first();
      }
      // 获取最新content
      let newContent = {};
      if (params.key === "content") {
        newContent = {
          ...content,
          textAreaValue: {
            ...content.textAreaValue,
            context: sourceNode?.originContent || sourceNode?.content || "",
          },
        };
      } else if (params.key === "context") {
        newContent = {
          ...content,
          context: sourceNode?.originContent || sourceNode?.content || "",
        };
      }
      newContentStr = JsonUtil.stringify(newContent) || "";
    }
    return newContentStr;
  }
}
