import { Popover } from "antd";
import { useEffect, useState } from "react";
import styles from "./TooltipWhatTodo.module.css";
import { editorActions } from "../../../store/editor";
import { useDispatch } from "react-redux";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { logEvent } from "@uikit/service/amplitude";
import classNames from "classnames";

const TooltipWhatTodo = ({
  id,
  children,
  title,
  content,
  show,
  classNameSuffix,
  trigger,
  placement,
  type,
  overlayStyle = {},
  tooltipClassName = "",
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(show);
  const boldContents = [
    "type",
    "click",
    "node",
    "input field",
    "command bar",
    "input node",
    "source knowledge",
    "group node",
  ];

  const setCloseToolTipArr = val => {
    dispatch(editorActions.setCloseToolTipArr(val));
  };
  const handleClick = () => {
    logEvent("close_tooltip", { title: title, content: content });
    setCloseToolTipArr([id]);
    setOpen(false);
  };
  useEffect(() => {
    if (Array.isArray(content)) {
      setOpen(content.length > 0 ? show : false);
    } else {
      setOpen(show);
    }
  }, [show]);

  const tooltipWhatTodoPopover = styles.TooltipWhatTodoPopoverV3;
  //  title也支持传数组 并可使用\n来换行
  const titleContent = title => {
    if (Array.isArray(title)) {
      return title.map(item => {
        return item;
      });
    }
    return title;
  };

  return (
    <Popover
      className={`${styles[`TooltipWhatTodoPopover${classNameSuffix}`]} ${tooltipWhatTodoPopover} ${
        type !== "edge" ? styles.TooltipWhatTodoPopoverNode : null
      } ${tooltipClassName} ${styles.initialPopover}`}
      open={open}
      trigger={trigger || ""}
      getPopupContainer={e => e}
      placement={placement || "top"}
      autoAdjustOverflow={false}
      overlayStyle={overlayStyle}
      content={
        <div className={classNames(styles.content, styles.newOnboardingFlowContent)}>
          <div className={styles.title}>{titleContent(title)}</div>
          <div className={styles.desc}>
            {Array.isArray(content)
              ? content.map((x, idx) => {
                  if (boldContents.includes(x.toLowerCase().trim())) {
                    return (
                      <span key={idx} className={styles.contentBold}>
                        {x}
                      </span>
                    );
                  }
                  if (["/", "+", "X"].includes(x)) {
                    return (
                      <span key={idx} className={styles.codeHighlight}>
                        {x}
                      </span>
                    );
                  }
                  return x;
                })
              : content}
          </div>
          <span onClick={handleClick}>
            <img src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`} alt="" />
          </span>
        </div>
      }
    >
      <div>{children}</div>
    </Popover>
  );
};

export { TooltipWhatTodo };
