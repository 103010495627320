"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadService = void 0;
const JsonUtil_1 = require("../cutil/JsonUtil");
const LangUtil_1 = require("../cutil/LangUtil");
const ChainRequest_1 = require("../storyV2/response/customApiHandler/chain/ChainRequest");
class UploadService {
    constructor(brainClient) {
        this.brainClient = brainClient;
    }
    async tts(words) {
        return await (0, LangUtil_1.tryPromise)(this.brainClient.openAi.tts(words));
    }
    async upLoad(params) {
        const data = new FormData();
        data.append("file", params.blob);
        const res = await this.brainClient.openAi.upload(data);
        const s3Url = res?.data?.object_s3_url || res?.data?.s3_https_url || "";
        const uuid = res?.data?.uuid;
        const initCreateBlobUrl = JsonUtil_1.JsonUtil.toModelFromInstance(new ChainRequest_1.ChainResultInitCreateBlobUrl(), {
            objectURL: URL.createObjectURL(params.blob),
            loading: false,
            streaming: false,
        }) || new ChainRequest_1.ChainResultInitCreateBlobUrl();
        const textAreaValue = JsonUtil_1.JsonUtil.toModelFromInstance(new ChainRequest_1.ChainResultTextAreaValue(), {
            loading: false,
            s3Url,
            s3Path: res?.data?.object_key,
            needToCreateBlobUrl: `/api/me/upload/studios?url=${encodeURIComponent(s3Url)}`,
            uuid,
            name: params.name || "",
            format: params.format || "",
            value: s3Url,
        }) || new ChainRequest_1.ChainResultTextAreaValue();
        return {
            initCreateBlobUrl,
            textAreaValue,
        };
    }
}
exports.UploadService = UploadService;
