import { Input } from "antd";
import style from "../components-css/AppView.module.css";
import ThreeBotsLoading from "./ThreeBotsLoading";
import { Droppable } from "react-beautiful-dnd";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

export default function AppView(props) {
  const onTextAreaChange = e => {
    props.setAppViewTextArea(e.target.value);
  };
  const onTextAreaPressEnter = () => {
    props.handleDragged();
  };

  return (
    <div className={style["AppView"]}>
      <div className={style["title"]}>App View</div>
      <p className={style["text"]}>The App view generates layers based on your description of the thinking App</p>

      <div className={style["app-bottom-box"]}>
        <div className={style["app-box"]}>
          <div className={style["Top-Menu"]}>
            <div className={style["input-box"]}>
              <Input.TextArea
                value={props.appViewTextArea}
                onChange={onTextAreaChange}
                onPressEnter={onTextAreaPressEnter}
                placeholder="I want to invest in the future of EV"
                rows={2}
              />
              <div className={style["app-refresh-box"]} onClick={onTextAreaPressEnter}>
                <span className="iconfont icon-shuaxin"></span>
              </div>
            </div>

            <div className={style["button-box"]}>
              <div className={style["app-button"]}>
                <img src={`${settings.S3CDN}${settings.viewAssetsPath}thought.svg`} />
              </div>
              {/* <div className={style['template-button']}>
                videos_base_template
              </div> */}
            </div>
          </div>

          {!props.htmlResLoading ? (
            <Droppable droppableId="app-view">
              {provided => (
                <div className={style["content-box"]} {...provided.droppableProps} ref={provided.innerRef}>
                  <div className="content-html" dangerouslySetInnerHTML={{ __html: props.htmlStr }}></div>
                  <div className="api-content-description">{props.explanationStr}</div>
                </div>
              )}
            </Droppable>
          ) : (
            <ThreeBotsLoading class="layer-logic-loading" />
          )}
        </div>

        <div className={style["example-output"]}>
          <div className={style["title"]}>Example output:</div>
          <div className={style["text-code"]}>
            {!props.appViewLoading ? props.exampleOutput : <ThreeBotsLoading class="layer-logic-loading" />}
          </div>
        </div>
      </div>
    </div>
  );
}
