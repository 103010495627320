import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class RightMenuStoreState {
  edgeRight = false;
  nodeRight = false;
  showRightClickPop = false;
  customFuncRightContextMenu = {} as Record<string, any>;
  showAddInputField = false;

  copyWith(param: Partial<Omit<RightMenuStoreState, "copyWith">>): RightMenuStoreState {
    const newState = new RightMenuStoreState();
    newState.edgeRight = param.edgeRight ?? this.edgeRight;
    newState.nodeRight = param.nodeRight ?? this.nodeRight;
    newState.showRightClickPop = param.showRightClickPop ?? this.showRightClickPop;
    newState.showAddInputField = param.showAddInputField ?? this.showAddInputField;
    newState.customFuncRightContextMenu = param.customFuncRightContextMenu ?? this.customFuncRightContextMenu;

    return newState;
  }
}

export type DispatchType<T> = T | ((prevState: T) => T);

class RightMenuStore extends StoreBase<RightMenuStoreState> {
  constructor() {
    super(() => new RightMenuStoreState());
  }

  setEdgeRight(edgeRight: boolean): void {
    this.emit(this.state.copyWith({ edgeRight }));
  }

  setNodeRight(nodeRight: boolean): void {
    this.emit(this.state.copyWith({ nodeRight }));
  }

  setShowRightClickPop(showRightClickPop: boolean): void {
    this.emit(this.state.copyWith({ showRightClickPop }));
  }

  setCustomFuncRightContextMenu(customFuncRightContextMenu: Record<string, any>): void {
    this.emit(this.state.copyWith({ customFuncRightContextMenu }));
  }

  setShowAddInputField(showAddInputField: boolean): void {
    this.emit(this.state.copyWith({ showAddInputField }));
  }
}

export const rightMenuStore = new RightMenuStore();
