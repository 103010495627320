import { notification } from "antd";
import { useCallback, useRef } from "react";
import { UAParseUtil } from "@uikit/util/UAParseUtil";
import { ChromeIcon } from "@uiview/views/Icons/ChromeIcon";
import { CloseIcon } from "@uiview/views/Icons/CloseIcon";
import css from "./index.module.scss";

const chromeDownloadLink = "https://www.google.com/chrome/";

export function useBrowerSuggestionTip() {
  const Id = useRef("BrowerSuggestionTip").current;
  const mobileId = useRef("mBrowerSuggestionTip").current;

  const show = useCallback(() => {
    notification.open({
      top: 120,
      key: Id,
      className: css["BrowerSuggestionTip-wrap"],
      placement: "topLeft",
      // message: "Use Chrome or Edge for the best experience",
      message: "",
      description: (
        <div>
          <p className={css["title"]}> Unsupported browser</p>
          <p>
            Your browser or device is not currently supported by Imagica. Please try using Imagica on a desktop with the
            latest version of
            <a href={chromeDownloadLink}> Google Chrome</a>.
          </p>
        </div>
      ),
      duration: null,
      icon: <ChromeIcon />,
      closeIcon: <CloseIcon />,
      props: {
        "data-testid": Id,
      },
      onClose: close,
    });
  }, []);

  const close = useCallback(() => {
    notification.close(Id);
  }, []);

  const closeMobile = useCallback(() => {
    notification.close(mobileId);
  }, []);

  const showMobileAdvising = useCallback(() => {
    notification.open({
      top: 120,
      key: mobileId,
      className: css["BrowerSuggestionTip-wrap"],
      placement: "topLeft",
      // message: "Use Chrome or Edge for the best experience",
      message: "",
      description: (
        <div>
          <p className={css["title"]}> Unsupported device</p>
          <p>
            Your device is not currently supported by Imagica. Please try using Imagica on a desktop/laptop with the
            latest version of <a href={chromeDownloadLink}> Google Chrome</a>.
          </p>
        </div>
      ),
      duration: null,
      icon: <ChromeIcon />,
      closeIcon: <CloseIcon />,
      props: {
        "data-testid": Id,
      },
      onClose: closeMobile,
    });
  }, []);

  /**
   * 是否是创作方(非订阅者就是创作方)
   * @returns
   */

  const setBrowerSuggestionTip = (isShare: boolean) => {
    if (!isShare) {
      // pc or ipad
      if (UAParseUtil.isIpad() || UAParseUtil.isPc()) {
        const browser = UAParseUtil.getBrowserType();
        if (!(browser === "Chrome" || browser === "Edge")) {
          show();
        }
      } else {
        // showMobileAdvising();
      }
    }
  };

  return { show, close, setBrowerSuggestionTip };
}
