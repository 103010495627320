import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { logEvent } from "@uikit/service/amplitude";

export function initSDK() {
  // Create a queue, but don't obliterate an existing one!
  const birdeatsbug = (window.birdeatsbug = window.birdeatsbug || []);
  // If the real SDK is already on the page, return.
  if (birdeatsbug.initialize) return;
  // If the snippet was invoked already, show an error.
  if (birdeatsbug.invoked) {
    if (window.console && console.error) {
      console.error("birdeatsbug snippet included twice.");
    }
    return;
  }
  // Invoked flag, to make sure the snippet is never invoked twice.
  birdeatsbug.invoked = true;
  // A list of the methods in the SDK to stub.
  birdeatsbug.methods = [
    "setOptions",
    "trigger",
    "resumeSession",
    "takeScreenshot",
    "startRecording",
    "stopRecording",
    "stopSession",
    "uploadSession",
    "deleteSession",
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in the SDK so that you never have to wait
  // for it to load to record method calls. The `method` is
  // stored as the first argument, so we can replay the data.
  birdeatsbug.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      birdeatsbug.push(args);
      return birdeatsbug;
    };
  };
  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < birdeatsbug.methods.length; i++) {
    const key = birdeatsbug.methods[i];
    birdeatsbug[key] = birdeatsbug.factory(key);
  }
  // Define a method to load the SDK
  // and that will be sure to only ever load it once.
  birdeatsbug.load = function () {
    // Create an async script element.
    const script = document.createElement("script");
    script.type = "module";
    script.async = true;
    script.src = "https://sdk.birdeatsbug.com/latest/core.js";
    // Insert the script next to the first script element.
    const mountJsBefore = document.getElementsByTagName("script")[0] || document.body.firstChild;
    mountJsBefore.parentNode.insertBefore(script, mountJsBefore);

    // Create a style element
    const style = document.createElement("link");
    style.rel = "stylesheet";
    style.type = "text/css";
    style.href = "https://sdk.birdeatsbug.com/latest/style.css";
    // Insert the styles before the 1st style
    const mountCssBefore = document.querySelector('link[rel="stylesheet"]') || mountJsBefore;
    mountCssBefore.parentNode.insertBefore(style, mountCssBefore);
  };
  // Load SDK
  birdeatsbug.load();
  // Pass options
  /**
   * 去掉提交成功后的文本，api 见下面地址
   *
   * @see https://sdk.birdeatsbug.com/1.67.2/docs/options.html#the-options-object
   */
  window.birdeatsbug.setOptions({
    publicAppId: settings.birdEatsBugAppId,
    ui: {
      text: {
        submitConfirmationScreen: {
          // 传一个空格字符串，空字符会被默认文本替换
          message: "Thanks for reporting this issue to us. A member of the team will investigate this.",
        },
      },
      position: {
        position: "top-right",
      },
    },
    hooks: {
      afterRecording: () => {
        logEvent("report_bug_recording");
      },
      afterScreenshot: () => {
        logEvent("report_bug_screenshot");
      },
    },
  });
}
