import { useCallback, useState, useEffect } from "react";
import { editorActions } from "../store/editor";
import { useSelector, useDispatch } from "react-redux";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import useFeatureTags from "./useFeatureTags";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";

/**
 * 后续会通过web.config.json接口数据
 * 以实现从后台控制新增主题
 */
const THEME_LIST = [
  {
    label: "Light",
    value: "light",
    logo: `${settings.S3CDN}${settings.viewAssetsPath}light-logo.png`,
  },
  {
    label: "Dark",
    value: "dark",
    logo: `${settings.S3CDN}${settings.viewAssetsPath}dark-logo.png`,
  },
  // todo 多种主题
];

const CHAT_LIGHT = {
  chatBackground: "linear-gradient(180deg, #F3F5F6 22.4%, #DEE1E3 81.25%)",
  chatBackgroundPad: "linear-gradient(180deg, #F3F5F6 22.4%, #DEE1E3 81.25%)",
  chatBackgroundMobile: "linear-gradient(180deg, #F3F5F6 22.4%, #DEE1E3 81.25%)",
  logoBrightness: 0,
  shareTitleColor: "#12192b",
  uploadTipBackground: "rgba(255, 255, 255, 0)",
  toolTipBackground: "rgba(255, 255, 255, 0.65)",
  uploadTipPadding: "0",
  uploadDesColor: "rgba(18, 25, 43, 0.65)",
  uploadLimitSizeColor: "#12192b59",
  uploadLoadingWrapBackground: "#fff",
  uploadLoadingBorderColor: "rgba(2, 12, 38, 0.05)",
  inputBarBorderColor: "#12192B1A",
  inputPlaceholderColor: "rgba(18, 25, 43, 0.35)",
  buttonBackground: "rgba(18, 25, 43, 0.05)",
  buttonColor: "rgba(18, 25, 43, 0.65)",
  hintTextColor: "#12192b",
  valueColor: "#12192b",
};
const CHAT_DARK = {
  chatBackground: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat-pc-background.png')",
  chatBackgroundPad: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat-pad-background.png')",
  chatBackgroundMobile: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat-mobile-background.png')",
  logoBrightness: 15,
  shareTitleColor: "#fff",
  uploadTipBackground: "rgba(255, 255, 255, 0.1)",
  toolTipBackground: "rgba(255, 255, 255, 0.1)",
  uploadTipPadding: "15px 25px",
  uploadDesColor: "rgba(255, 255, 255, 0.35)",
  uploadLimitSizeColor: "rgba(255, 255, 255, 0.35)",
  uploadLoadingWrapBackground: "rgba(255, 255, 255, 0.10)",
  uploadLoadingBorderColor: "rgba(255, 255, 255, 0.10)",
  inputBarBorderColor: "rgba(255, 255, 255, 0.1)",
  inputPlaceholderColor: "rgba(255, 255, 255, 0.35)",
  buttonBackground: "rgba(255, 255, 255, 0.1)",
  buttonColor: "rgba(255, 255, 255, 0.65)",
  hintTextColor: "#fff",
  valueColor: "#fff",
  renderingTransitionColors: "#1a142d",
};
const THEME_COLOR = {
  light: {
    //  原有aisaas的css变量
    background: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/aiSaasBackground2x.png')",
    saasTitleColor: "#12192B",
    inputBoxBgc: "#fff",
    inputBoxBottom: "#fff",
    inputBoxEnterBgc: "rgba(2, 12, 38, 0.05)",
    footerBrainStudiosColor: "rgba(18, 25, 43, 0.65)",
    topSuctionBgc: "rgba(255, 255, 255, 0.65)",
    topSuctionBorder: "rgba(255, 255, 255, 0.65)",
    submitButtonColor: "#12192B",
    submitButtonBgClr: "#ffffff",
    homeIconBgcolor: "#fff",
    homeInconFilter: "none",
    rightTopNumberBg: "#12192B",
    botIndexBg: "rgba(10, 157, 255, 0.25)",
    askLinkBorder: "rgba(2, 12, 38, 0.05)",
    inputTitleColor: "#020C26",
    saasTitleDisableColor: "#000",
    // node wrapper
    nodeWrapperBgColor: "#fff",
    addContentColor: "#12192B",

    textColor: "rgba(2, 12, 38, 0.35)",
    contentBg: "rgba(2, 12, 38, 0.05)",
    messageColor: "#12192B",
    errorColor: "#B92C2C",
    useNodeContentBg: "#0A9DFF40",
    handleBtnBorder: "rgba(2, 12, 38, 0.10)",
    handleBtnTextColor: "rgba(18, 25, 43, 0.65)",
    chatInputBorder: "#020C260D",
    chatInputTextColor: "#12192B",
    chatInputTextPlaceholder: "rgba(2, 12, 38, 0.35)",
    svgColor: "#020C26",
    svgOpacity: 0.05,
    audioFileNameColor: "#12192b",
    audioCurrentTimeColor: "#12192b",
    audioTotalTimeColor: "rgba(2, 12, 38, 0.35)",
    pdfPreviewBg: "#fff",
    pdfPreviewItem: "transprent",
    pdfPreviewScrollBarColor: "#E7E8EA",
    xlsxThTextColor: "#12192b",
    xlsxScrollBorder: "#eeeeee",
    xlsxScrollBg: "rgba(255, 255, 255, 0.2)",
    xlsxThBg: "#eeeeee",
    xlsxTdBorder: "#dadde1",
    xlsxScrollbarTrack: "#eeeeee",
    dragDropTitle: "#12192b",
    dragDropDes: "rgba(18, 25, 43, 0.65)",
    uploadDes: "rgba(2, 12, 38, .45)",
    uploadLimitColor: "rgba(2, 12, 38, .45)",
    uploadingWrapBg: "#fff",
    uploadingFilename: "#12192b",
    uploadingFilesize: "rgba(2, 12, 38, 0.35)",
    uploadingError: "#ff335c",
    uploadingSuccess: "#0a9dff",
    textboxBg: "#fff",
    textboxColor: "#12192b",
    imageBg: "#fff",
    imageColor: "#12192b",
    codeBg: "#fff",
    codeColor: "#12192b",
    codeFooterBg: "#fff",
    codeFooterColor: "#12192b",
    stockBg: "#fff",
    stockColor: "#12192b",
    codeEditorBg: "#f4f4f4",
    rightBtnBg: "#5182ff",
    stockTradeBtnBg: "#0a9dff",
    stockCancelBtnBg: "#0495f5",
    stockCancelBtnColor: "#fff",
    stockCancelBtnBorder: "1px solid #0495f5",
    stockNamePrice: "#22232f",
    askBrainTextColor: "#12192b",
    nodeWrapperBorder: "0.5px solid rgba(255, 255, 255, 0.35)",
    imgTextColor: "#12192b",
    // chat
    ...CHAT_LIGHT,
  },
  dark: {
    background: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/darkModeBackground2x.png')",
    saasTitleColor: "#FFFFFF",
    inputBoxBgc: "rgba(255, 255, 255, 0.1)",
    inputBoxBottom: "rgba(255, 255, 255, 0.1)",
    inputBoxEnterBgc: "rgba(255, 255, 255, 0.1)",
    footerBrainStudiosColor: "rgba(255, 255, 255, 0.65)",
    topSuctionBgc: "rgba(255, 255, 255, 0.1)",
    topSuctionBorder: "#12192B",
    submitButtonColor: "#12192B",
    submitButtonBgClr: "#fff",
    homeIconBgcolor: "#fff",
    homeInconFilter: "blur(80px)",
    rightTopNumberBg: "#12192B",
    botIndexBg: "rgb(10, 157, 255)",
    askLinkBorder: "rgba(255, 255, 255, 0.3)",
    inputTitleColor: "#fff",
    saasTitleDisableColor: "rgba(255, 255, 255, 0.3)",
    // node wrapper
    nodeWrapperBgColor: "rgba(255, 255, 255, 0.1)",
    addContentColor: "#fff",

    textColor: "#fff",
    contentBg: "rgba(255, 255, 255, 0.3)",
    messageColor: "#fff",
    errorColor: "#FF7B7B",
    useNodeContentBg: "#0A9DFF",
    handleBtnBorder: "rgba(255, 255, 255, 0.5)",
    handleBtnTextColor: "rgba(255, 255, 255, 0.5)",
    chatInputBorder: "rgba(255, 255, 255, 0.13)",
    chatInputTextColor: "#fff",
    chatInputTextPlaceholder: "#fff",
    svgColor: "#fff",
    svgOpacity: 0.3,
    audioFileNameColor: "#fff",
    audioCurrentTimeColor: "#fff",
    audioTotalTimeColor: "rgba(255, 255, 255, 0.65)",
    pdfPreviewBg: "rgba(255, 255, 255, 0.2)",
    pdfPreviewItem: "#fff",
    pdfPreviewScrollBarColor: "rgba(255, 255, 255, 0.15)",
    xlsxThTextColor: "#fff",
    xlsxScrollBorder: "rgba(255, 255, 255, 0.2)",
    xlsxScrollBg: "rgba(23, 23, 23, 0.80)",
    xlsxThBg: "rgba(76, 76, 77)",
    xlsxTdBorder: "rgba(255, 255, 255, 0.2)",
    xlsxScrollbarTrack: "rgba(255, 255, 255, 0.10)",
    dragDropTitle: "#fff",
    dragDropDes: "rgba(255, 255, 255, 0.65)",
    uploadDes: "#fff",
    uploadLimitColor: "rgba(255, 255, 255, 0.35)",
    uploadingWrapBg: "rgba(255, 255, 255, 0.10)",
    uploadingFilename: "#fff",
    uploadingFilesize: "rgba(255, 255, 255, 0.35)",
    uploadingError: "#CC295F",
    uploadingSuccess: "transparent",
    textboxBg: "rgba(255, 255, 255, 0.1)",
    textboxColor: "#fff",
    imageBg: "rgba(255, 255, 255, 0.1)",
    imageColor: "#fff",
    codeBg: "rgba(255, 255, 255, 0.1)",
    codeColor: "#fff",
    codeFooterBg: "rgba(255, 255, 255, 0.1)",
    codeFooterColor: "#fff",
    stockBg: "rgba(255, 255, 255, 0.1)",
    stockColor: "#fff",
    codeEditorBg: "rgba(255, 255, 255, 0.1)",
    rightBtnBg: "rgba(255, 255, 255, 0.1)",
    stockTradeBtnBg: "rgba(255, 255, 255, 0.1)",
    stockCancelBtnBg: "#fff",
    stockCancelBtnColor: "rgba(255, 255, 255, 0.1)",
    stockCancelBtnBorder: "0.5px solid rgba(255, 255, 255, 0.1)",
    stockNamePrice: "#fff",
    askBrainTextColor: "#fff",
    nodeWrapperBorder: "0",
    imgTextColor: "#fff",
    // chat
    ...CHAT_DARK,
  },
};

const FONT_SIZE_LIST = [
  {
    label: "12",
    value: "12px",
  },
  {
    label: "16",
    value: "16px",
  },
];

const FONT_SIZE_CONTENT = {
  12: {
    titleSize: 12,
  },
  16: {
    titleSize: 16,
  },
};
const creatorSaasAppStore = getIt(CreatorSaasAppStore);

export const setThemeProperty = (current, theme) => {
  const THEME_COLOR_SELECT = THEME_COLOR[theme];
  Object.keys(THEME_COLOR_SELECT).forEach(key => {
    current.style.setProperty(`--${key}`, THEME_COLOR_SELECT[key]);
  });
};

const setFontSizeProperty = (current, fontSize) => {
  const FONT_SIZE_CONTENT_SELECT = FONT_SIZE_CONTENT[fontSize];
  Object.keys(FONT_SIZE_CONTENT_SELECT).forEach(key => {
    current.style.setProperty(`--${key}`, FONT_SIZE_CONTENT_SELECT[key]);
  });
};

export const useSaasTheme = contentRef => {
  const dispatch = useDispatch();
  const { enablePublishV3 } = useFeatureTags();
  const [showThemeModal, setShowThemeModal] = useState(false);
  const theme = useSelector(state => state.editor.applicationTheme);
  const setApplicationTheme = useCallback(
    val => {
      dispatch(editorActions.setApplicationTheme(val));
    },
    [dispatch]
  );
  const fontSize = useSelector(state => state.editor.fontSize);

  const setFontSize = useCallback(
    val => {
      dispatch(editorActions.setFontSize(val));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!enablePublishV3 && contentRef?.current) {
      setThemeProperty(contentRef.current, theme);
    }
  }, [theme, contentRef, enablePublishV3]);

  //  主题切换后更新saasUIData
  useEffect(() => {
    if (!enablePublishV3) {
      creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, theme }));
    }
  }, [theme, enablePublishV3]);

  useEffect(() => {
    if (contentRef?.current) {
      setFontSizeProperty(contentRef.current, fontSize);
    }
  }, [fontSize, contentRef]);

  return {
    showThemeModal,
    themes: THEME_LIST,
    saasTheme: theme || "light",
    setSaasTheme: setApplicationTheme,
    setShowThemeModal,
    fontSizeList: FONT_SIZE_LIST,
    fontSize,
    setFontSize,
  };
};
