import { useEffect } from "react";
import style from "./UpgradeSuccess.module.scss";
import { UpgradeSuccessBloc } from "./UpgradeSuccessBloc";
import { useCreation } from "ahooks";
import { useLocation, useNavigate } from "react-router-dom";
import isBlank from "@sedan-utils/is-blank";
import { Skeleton } from "antd";
import classNames from "classnames";

export function UpgradeSuccess() {
  const location = useLocation();
  const navigate = useNavigate();
  const bloc = useCreation(() => new UpgradeSuccessBloc(location.pathname), []);

  useEffect(() => {
    //如果本地有路径，显示该路径
    const success_path = localStorage.getItem("UPGRADE_SUCCESS_URL");
    if (success_path && !isBlank(success_path)) {
      navigate(success_path);
      return;
    }
    bloc.loadData();
  }, []);
  const loading = bloc.state.isLoading.value;
  return (
    <div>
      <div className={style.maskBox}></div>
      <div className={style.doneBox}>
        <div className={style.doneContent}>
          <div className={style.header}>
            <div className={style.header_conetnt}>
              {loading ? (
                <Skeleton className={classNames(style.loading_line, style.tips_loading)} active paragraph={false} />
              ) : (
                <div className={style.tips}>{bloc.state.title.value}</div>
              )}
              {loading ? (
                <div className={style.column_space}>
                  <Skeleton className={classNames(style.loading_line, style.title_loading)} active paragraph={false} />
                  <Skeleton className={classNames(style.loading_line, style.title_loading)} active paragraph={false} />
                </div>
              ) : (
                <div className={style.title}>{bloc.state.sub_itle.value}</div>
              )}
            </div>
          </div>
          <div className={style.bottom}>
            <div className={style.tips}>
              {loading ? (
                <Skeleton className={classNames(style.loading_line, style.tips_loading)} active paragraph={false} />
              ) : (
                <span>Here's what's available to you now:</span>
              )}
            </div>

            <div className={style.item_view}>
              {loading ? (
                <div className={style.column_space}>
                  <Skeleton className={classNames(style.loading_line, style.item_loading)} active paragraph={false} />
                  <Skeleton className={classNames(style.loading_line, style.item_loading)} active paragraph={false} />
                </div>
              ) : (
                bloc.state.feature.map((item, index) => {
                  return (
                    <div className={style.item} key={index}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#8F79FF" />
                        <path
                          d="M5.5 10L8.5 13L14.5 7"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>{item}</span>
                    </div>
                  );
                })
              )}
            </div>
            <div onClick={() => navigate(bloc.nextPagePath)} className={style.button}>
              Done
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
