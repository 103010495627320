import { WEB_APP_DEFAULT_SUBTITLE, WEB_APP_DEFAULT_TITLE } from "@views/thinking-layout-editor/constants";
import { PreviewDataType } from "../PreviewDataType";
import { get, isEmpty } from "lodash";
import { Node } from "reactflow";
import { PreviewContentUtil } from "../PreviewContentUtil";
import {
  ViewBuilderGraph,
  ViewBuilderGraphDesign,
} from "imagica-corekit/dist/base/api/viewBuilderTyped/viewBuilderGraph";
import { Type } from "class-transformer";

export class PreviewAppValue {
  id: string | number = "";
  title = WEB_APP_DEFAULT_TITLE;
  showTitle = true;
  titleStyle = {};
  titleV3?: any = {};
  subTitle?: string = WEB_APP_DEFAULT_SUBTITLE;
  showSubTitle: boolean = true;
  subTitleStyle: any = {};
  subTitleV3?: any = {};
  placeholder = "Enter something";
  theme: string = "light";
  fontSize?: number = 12;
  staticMode: boolean = false;
  input: any[] = [];
  @Type(() => ViewBuilderGraphDesign)
  viewBuilderGraph?: ViewBuilderGraph;
  output: AISaasOutput.ThoughtType[] = [];
  fromCotTitle?: string = "";
  fromCotSubtitle?: string = "";
  settings?: PreviewApp.settings = {
    branding: true,
    width: 100,
    height: 100,
    logoImg: "",
    fontFamily: "Ak-Regular",
    copyright: "",
    domain: {},
  };
  outputV2?: PreviewDataType[] = [];
  v2App?: PreviewApp.PreviewAppV2FieldType = {
    id: "",
    type: undefined,
    appDescription: "",
    appName: "",
  };
  appDetail?: PreviewApp.AppDetail = {
    name: "",
    features: [],
    audience: "",
    description: "",
    goal: "",
  };
  label?: any = "";
  // FIXME: value 应取值为saas 等字符串，为了类型不报错
  value?: any = "saas";

  /**
   * 获取每个 output node 的唯一ID
   * @param node
   * @returns
   */
  static genNodeUUID(nodeId: string, parentId?: string): string {
    return `${parentId || ""}**${nodeId}`;
  }

  static toPreviewOutputv2(output: AISaasOutput.ThoughtType[], getNodes: () => any[]): PreviewDataType[] {
    if (isEmpty(output)) return [];
    return output
      .map(x => {
        const nodeId = get(x, ["results", "0", "nodeId"]) as string | undefined;
        if (!nodeId) return undefined;
        const nodes = getNodes();
        const targetNode = nodes.find(item => item.id === nodeId);
        if (!targetNode) return undefined;
        return PreviewAppValue.thoughtToPreviewData(x, targetNode);
      })
      .filter(x => x) as PreviewDataType[];
  }

  /**
   * 将原来 previewOutput 数据(saasuidata.output)转成新的 previewOutput 数据
   * @param thought
   * @param targetNode
   * @returns
   */
  static thoughtToPreviewData(thought: any, targetNode: Node): PreviewDataType {
    const renderData = {
      style: get(thought, "results.0.nodeFontStyle"),
      isCaptionToggle: get(thought, "results.0.isCaptionToggle"),
    };

    const outputContent = PreviewContentUtil.getPreviewContentByNode(targetNode);

    /**
     * FIXME: 该数据用于之前 sassuidata.output 中数据可用的
     */
    const previewData: PreviewDataType = {
      /**
       * 唯一的id
       */
      id: PreviewAppValue.genNodeUUID(targetNode.id, targetNode.parentNode),

      nodeId: targetNode.id,

      parentId: targetNode.parentNode || "",

      data: {
        // blank: {
        //   title: node.data.inputTitle,
        // }
        blank: get(thought, ["results", "0", "blank"], {}),
        renderData,
        fontSize: get(thought, ["results", "0", "fontSize"]),
        content: outputContent.content,
        errorText: get(thought, ["results", "0", "errotText"]),
        contentType: outputContent.contentType as PreviewDataType["data"]["contentType"],
        // 没有使用了
        // layout: get(thought, ["results", "0", "layout"]),
        nodeFontStyle: get(thought, ["results", "0", "nodeFontStyle"]),
        viewBuilderData: {
          loadingHtml: get(thought, ["results", "0", "viewBuilderData", "loadingHtml"]),
          domList: get(thought, ["results", "0", "viewBuilderData", "domList"]),
          originalText: get(thought, ["results", "0", "viewBuilderData", "originalText"]),
        },
        isCaptionToggle: get(thought, ["results", "0", "isCaptionToggle"]),
      },
    };

    return previewData;
  }
}
