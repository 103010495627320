"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProteinFunction = void 0;
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const ProteinModel_1 = require("../../storyV2/model/ProteinModel");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
class ProteinFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo, variableList = [], page = 1) {
        const streamResponse = await this.socketRestClient.requestRest(topic => {
            return this.brainClient.openAi.alphafold(input, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo), topic);
        }, result => {
            const entryId = result.data?.data?.items[0].entryId ?? "";
            return new FunctionBase_1.FuncDataSource(FunctionBase_1.FuncDataSourceType.PROTEIN, new ProteinModel_1.ProteinModel("protein", entryId));
        }, "protein", identityInfo);
        // const apiRes = await this.brainClient.openAi.alphafold(input, FunctionUtil.createAbortUuid(identityInfo));
        // const entryId = apiRes.data.data.items[0].entryId;
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.PROTEIN, streamResponse);
    }
}
exports.ProteinFunction = ProteinFunction;
