import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { defaults } from "lodash";
import func from "@uikit/func";
import { fotActions } from "../../store/fot";
import store from "../../store";
import { getIt } from "@uikit/getIt";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { ProjectStore } from "imagica-corekit/dist/base/store/ProjectStore";

let userTz = "";

const getErrorText = response => {
  let errorData = response.errorData;
  // parse text error response to json
  if (typeof errorData === "string") {
    try {
      const tmp = JSON.parse(errorData.replace(/None/g, `""`).replace(/'/g, '"'));
      errorData = tmp;
    } catch (_) {}
  }
  if (typeof errorData === "string") {
    try {
      const tmp = JSON.parse(errorData);
      errorData = tmp;
    } catch (_) {}
  }
  if (typeof errorData === "string") {
    return errorData;
  } else if (errorData instanceof Object) {
    // json error response
    return (
      errorData?.detail ||
      errorData?.message?.identifier_type || // 这里cot第一条边run的时候可能失败导致返回错误消息格式是这样的
      errorData?.error?.message ||
      errorData?.message ||
      errorData?.non_field_errors?.[0]
    );
  } else {
    // other error response
    return response.statusText;
  }
};

export class Api {
  constructor(fot = true, external = false) {
    const base = settings.API;

    this.base = base;
    this.external = external;

    if (fot) {
      this.base = settings.ENV === "development-server" ? base : `${base}/be/fot`;
    }
    this.headers = { "Content-Type": "application/json" };
    this.handleHeadersToken();
  }

  handleHeadersToken() {
    const token = getIt(FotAuthStore).state.brainToken;
    const guestToken = sessionStorage.getItem("GUEST_TOKEN");

    if (token) {
      this.headers.Authorization = `token ${token}`;
    } else {
      // 没token的时候就清空header里的token 不传递
      delete this.headers.Authorization;
    }

    if (guestToken) {
      this.headers["Brain-Guest-User-Key"] = guestToken;
    } else {
      // 没guestToken的时候就清空header里的guestToken 不传递
      delete this.headers["Brain-Guest-User-Key"];
    }

    if (this.external) {
      this.base = "";
      delete this.headers.Authorization;
    }
  }

  async setCustomHeaders() {
    if (func.isEmpty(userTz)) {
      userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    let customHeaders = {
      "X-Brain-User-Tz": userTz,
    };

    const attrIndexer = getIt(ProjectStore).attrIndexer;
    if (!func.isEmpty(attrIndexer)) {
      customHeaders["X-Brain-Imagica-Id"] = attrIndexer;
    }

    this.handleHeadersToken();

    this.headers = Object.assign({}, this.headers, customHeaders);
  }

  handleOtherResult = response => {
    const setIsLogin = val => {
      store.dispatch(fotActions.setIsLogin(val));
    };
    if (response.status === 401) {
      getIt(FotAuthStore).removeBrainToken();
      localStorage.removeItem("BRAIN_USER_EMAIL");
      sessionStorage.removeItem("GUEST_TOKEN");
      setIsLogin(false);
    }
  };

  handleResult(response) {
    this.handleOtherResult(response);
    let obj = {};
    obj.status = response.status;
    obj.statusText = response.statusText;
    obj.url = response.url;

    return response
      .json()
      .then(res => {
        obj.data = res;
        return Promise.resolve(obj);
      })
      .catch(error => {
        const codeList = [400, 403, 404, 500];
        if (!codeList.includes(obj.status)) obj.statusText = error.toString();
        return Promise.reject(obj);
      });
  }
  async checkStatus(response, notJudge401 = false) {
    if (response instanceof Error === true) {
      throw response;
    }
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    //自定义api不判定
    if (!notJudge401) {
      this.handleOtherResult(response);
    }

    let errorData = {};
    const textClone = response.clone();
    try {
      errorData = await response.json();
    } catch (error) {
      errorData = await textClone.text();
    }
    response.errorData = errorData;
    const errorText = getErrorText(response);
    response.errorText = errorText;
    throw {
      fromApiError: errorText,
      statusText: response.statusText,
      name: response.status,
      response: response,
    };
  }
  getWithError(url, headers) {
    return this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "GET",
          headers: defaults(headers, this.headers),
        });
      })
      .then(res => this.checkStatus.call(this, res))
      .then(response => {
        return this.handleResult(response);
      })
      .catch(error => Promise.reject(error));
  }
  getCanAbort(url, headers) {
    // 获取AbortController实例
    const controller = new AbortController();
    // 获取 signal属性
    const signal = controller.signal;
    const promise = this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "GET",
          headers: defaults(headers, this.headers),
          signal,
        });
      })
      .then(res => this.checkStatus.call(this, res))
      .then(response => {
        this.handleOtherResult(response);
        return response;
      })
      .catch(error => Promise.reject(error));

    // 设置一个 取消函数
    promise.cancel = () => controller.abort();
    return promise;
  }
  getWithErrorCanAbort(url, headers) {
    // 获取AbortController实例
    const controller = new AbortController();
    // 获取 signal属性
    const signal = controller.signal;
    const promise = this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "GET",
          headers: defaults(headers, this.headers),
          signal,
        });
      })
      .then(res => this.checkStatus.call(this, res))
      .then(response => {
        return this.handleResult(response);
      })
      .catch(error => Promise.reject(error));

    // 设置一个 取消函数
    promise.cancel = () => controller.abort();
    return promise;
  }

  getByCustomType(url, type) {
    return this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "GET",
          responseType: type,
          headers: this.headers,
        });
      })
      .then(response => {
        this.handleOtherResult(response);
        return response.blob();
      })
      .then(blob => {
        const fileUrl = URL.createObjectURL(blob);
        return { fileUrl, blob };
      });
  }

  get(url, headers) {
    return this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "GET",
          headers: defaults(headers, this.headers),
        });
      })
      .then(response => {
        this.handleOtherResult(response);
        return response;
      });
  }

  post(url, body, headers) {
    return this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "POST",
          headers: defaults(headers, this.headers),
          body: JSON.stringify(body),
        });
      })
      .then(response => {
        this.handleOtherResult(response);
        return response;
      });
  }

  postIntegration(url, body, headers) {
    return this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "POST",
          headers: defaults(headers, this.headers),
          body: JSON.stringify(body),
        });
      })
      .then(response => {
        // this.handleOtherResult(response)
        // return response;
        return this.handleResult(response);
      });
  }

  postWithError(url, body, headers) {
    return this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "POST",
          headers: defaults(headers, this.headers),
          body: JSON.stringify(body),
        });
      })
      .then(res => this.checkStatus.call(this, res))
      .then(response => {
        return this.handleResult(response);
      });
  }

  postOtherTypeDataWithError(url, body, headers) {
    delete this.headers["Content-Type"];
    return this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "POST",
          headers: defaults(headers, this.headers),
          body: body,
        });
      })
      .then(response => {
        return this.handleResult(response);
      });
  }

  uploadUseXhr(url, body, { onProgress }) {
    delete this.headers["Content-Type"];
    return this.setCustomHeaders()
      .then(() => {
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.open("POST", `${this.base}${url}`, true);
          Object.entries(this.headers).forEach(([name, value]) => xhr.setRequestHeader(name, value));
          xhr.onload = () => {
            xhr.json = () => Promise.resolve(JSON.parse(xhr.responseText));
            resolve(xhr);
          };
          xhr.upload.onprogress = ({ loaded, total }) => {
            onProgress({ loaded, total });
          };
          xhr.onerror = () => {
            reject("Network error occurred");
          };
          xhr.send(body);
        });
      })
      .then(response => {
        return this.handleResult(response);
      });
  }

  postWithErrorCanAbort(url, body, headers, callback = () => {}) {
    // 获取AbortController实例
    const controller = new AbortController();
    // 获取 signal属性
    const signal = controller.signal;
    const promise = this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "POST",
          headers: defaults(headers, this.headers),
          body: JSON.stringify(body),
          signal,
        })
          .then(res => res)
          .catch(err => err);
      })
      .then(res => this.checkStatus.call(this, res))
      .then(response => {
        return this.handleResult(response);
      });
    // 设置一个 取消函数
    callback(controller);
    promise.cancel = () => controller.abort();
    return promise;
  }

  // 可以取消的post请求，返回原始response数据，用于非json格式返回的请求，主要逻辑与postWithErrorCanAbort一致
  postCanAbort(url, body, headers) {
    const controller = new AbortController();
    const signal = controller.signal;
    const promise = this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "POST",
          headers: defaults(headers, this.headers),
          body: JSON.stringify(body),
          signal,
        });
      })
      .then(res => this.checkStatus.call(this, res))
      .then(response => {
        this.handleOtherResult(response);
        return response;
      });
    promise.cancel = () => controller.abort();
    return promise;
  }

  put(url, body, headers) {
    return this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "PUT",
          headers: defaults(headers, this.headers),
          body: JSON.stringify(body),
        });
      })
      .then(response => {
        this.handleOtherResult(response);
        return response;
      });
  }

  delete(url, body, headers) {
    return this.setCustomHeaders()
      .then(() => {
        return fetch(`${this.base}${url}`, {
          method: "DELETE",
          headers: defaults(headers, this.headers),
          body: JSON.stringify(body),
        });
      })
      .then(response => {
        this.handleOtherResult(response);
        return response;
      });
  }

  /**
   * @returns {Promise<Response>}
   */
  commonRequest(url, method, options) {
    const currOptions = {
      method,
      headers: options.headers,
    };
    if (!func.isEmpty(options.body)) {
      currOptions.body = JSON.stringify(options.body);
    }

    return fetch(url, currOptions)
      .then(response => {
        return response;
      })
      .then(res => this.checkStatus.call(this, res, true))
      .catch(error => {
        // util.messageUtil(error)
        return Promise.reject(error);
      });
  }
}
