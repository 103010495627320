import React, { useEffect, useContext } from "react";
import func from "@uikit/func";
import HeaderList from "./HeaderList";
import BodyParam from "./BodyParam";
import { editorActions } from "../../../store/editor";
import { Button, Input, Form, Select, Tabs, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { CustomApiPageBlocContext } from "@uiview/pages/customApi/CustomApiPageBlocContext";
import { InfoCircleOutlined } from "@ant-design/icons";
import parseCurlString, { ParsedCurlOptions } from "./parseCurlString";
import style from "./Request.module.css";

const methodOptions = [
  { value: "get", label: "GET" },
  { value: "post", label: "POST" },
];

interface RequestProps {
  requestData: {
    method: string;
    url: string;
    headerList: any[];
    bodyParam: string;
  };
  sendLoading: boolean;
}

function ErrorTip(props: { errorTips: string }): JSX.Element | null {
  return !func.isEmpty(props.errorTips) ? (
    <Tooltip title={props.errorTips} color="#ff4d4f" placement="bottomRight" className={style["error-tips"]}>
      <InfoCircleOutlined />
    </Tooltip>
  ) : null;
}

export default function Request(props: RequestProps): JSX.Element {
  const dispatch = useDispatch();
  const bloc = useContext(CustomApiPageBlocContext);
  const createAPIFuncData = useSelector((state: any) => state.editor.createAPIFuncData);
  const setCreateAPIFuncData = (val: any): void => {
    dispatch(editorActions.setCreateAPIFuncData(val));
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = e.target.value;
    bloc.state.requestData.value = bloc.state.requestData.value.copyWith({ url: text });

    const curlReg = /^curl\s+/i;
    if (!curlReg.test(text)) return;
    const parseTextData: ParsedCurlOptions | null = parseCurlString(text);
    const header: never[] | Record<string, string> = parseTextData ? parseTextData.header : [];
    const lowercaseMethod: string = (parseTextData?.method || "").toLowerCase();
    const url: string = parseTextData?.url || "";
    const body: string = parseTextData?.body || "";

    const headerList: any = Object.keys(header).map((x, index) => {
      return {
        key: `${index}_${x.toLowerCase()}`,
        name: x,
        value: (header as Record<string, string>)[x],
        description: "",
        selected: true,
      };
    });
    bloc.state.requestData.value = bloc.state.requestData.value.copyWith({
      method: lowercaseMethod,
      url: url,
      headerList,
      bodyParam: body,
    });
  };

  async function clickSendBtn(): Promise<void> {
    if (!func.isEmpty(bloc.state.childState.value)) return;
    const currentSourceNodeValue = bloc.getSourceNodeValue();
    const variableList = bloc.getVarliableList();
    bloc.sendRequest(props.requestData, currentSourceNodeValue, variableList);
    setCreateAPIFuncData({ loading: false });
  }

  const items = [
    {
      key: "headersParam",
      label: "Headers",
      children: <HeaderList headerList={props.requestData.headerList} />,
    },
    {
      key: "bodyParam",
      label: "Body",
      children: <BodyParam requestData={props.requestData} setChildState={bloc.setChildState} />,
    },
  ];

  useEffect(() => {
    if (func.isEmpty(createAPIFuncData.function)) return;
    const data: any = {
      clickRun: clickSendBtn,
    };

    if (typeof data[createAPIFuncData.function] !== "function") return;
    data[createAPIFuncData.function]();
    setCreateAPIFuncData({ function: "" });
  }, [createAPIFuncData.function]);

  return (
    <div className={style["request-box"]}>
      <Form layout="inline" size="large">
        <Form.Item
          style={{
            flex: 0.1,
          }}
        >
          <Select
            data-testid="apifunc-select"
            value={props.requestData.method}
            options={methodOptions}
            onSelect={value =>
              (bloc.state.requestData.value = bloc.state.requestData.value.copyWith({ method: value }))
            }
          />
        </Form.Item>
        <Form.Item
          style={{
            flex: 1,
          }}
        >
          <Input data-testid="apifunc-input" value={props.requestData.url} onChange={onInputChange} />
        </Form.Item>
        <Form.Item
          style={{
            flex: 0.1,
            marginRight: 0,
          }}
        >
          <div className={!func.isEmpty(bloc.state.childState.value) ? style["disabled-btn"] : ""}>
            <Button
              data-testid="apifunc-send-button"
              loading={props.sendLoading}
              type="primary"
              block
              onClick={clickSendBtn}
              className={style["send-box"]}
            >
              Send
              <ErrorTip errorTips={bloc.state.childState.value} />
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}
