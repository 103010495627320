"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryEventObject = void 0;
const StoryNode_1 = require("./StoryNode");
const StoryEdge_1 = require("./StoryEdge");
class StoryEventObject {
    static create(elements) {
        return new StoryEventObject(elements);
    }
    constructor(elements) {
        this.elements = elements;
        this.naming = "StoryEventObject";
    }
    static asNode(element) {
        if (element instanceof StoryNode_1.StoryNode) {
            return element;
        }
        return undefined;
    }
    static asEdge(element) {
        if (element instanceof StoryEdge_1.StoryEdge) {
            return element;
        }
        return undefined;
    }
}
exports.StoryEventObject = StoryEventObject;
