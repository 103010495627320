import React, { useCallback } from "react";
import styles from "./Header.module.scss";
import { PlanReturnIcon } from "@uiview/views/Mobile/assets/PlanReturnIcon";
import { PlanCloseIcon } from "@uiview/views/Mobile/assets/PlanCloseIcon";
import { useLocation, useNavigate } from "react-router-dom";

export function Header(props: { title: string; showReturn: boolean; onClose: () => void }): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const onClose = useCallback(() => {
    const path = location.pathname.split("/").slice(0, -1).join("/");
    navigate(path);
  }, []);
  return (
    <div className={styles.header}>
      {props.showReturn ? (
        <div onClick={props.onClose}>
          <PlanReturnIcon />
        </div>
      ) : (
        <div />
      )}

      <span>{props.title}</span>
      <div onClick={onClose}>
        <PlanCloseIcon />
      </div>
    </div>
  );
}
