import cls from "classnames";
import { Variants, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styles from "./ChatPanel.module.scss";
import { CotChatStatus, CotBloc } from "./CotBloc";
import { VirtualAssistant } from "./VirtualAssistant";
import { LoadingMotion } from "./motion/LoadingMotion";
import { LoadingTexts } from "./motion/LoadingTextsMotion";
import { background1, background2, transition } from "./motion/motionConfig";
import { useCurrentStatus } from "./useCurrentStatus";

const wrapperVariants: Variants = {
  normal: { height: "80px", width: "100%", borderRadius: "15px", transition },
  creating: { height: "70px", borderRadius: "15px", width: "100%", transition },
  completeCreating: {
    minHeight: "0px",
    width: "50px",
    height: "50px",
    padding: "0px",
    borderRadius: "25px",
    transition: { duration: 0.3 },
  },
};

const maskVariants = {
  normal: { background: "rgba(255, 255, 255, 0.1)" },
  creating: { background: "rgba(255, 255, 255, 0.2)" },
  completeCreating: { background: "rgba(255, 255, 255, 1)" },
};

export const ChatPanel = ({
  bloc,
  onClose,
  startMotionComplete,
}: {
  bloc: CotBloc;
  onClose: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  startMotionComplete: boolean;
}) => {
  const cotChatStatus = bloc.state.cotChatStatus.value;
  const { currentStatus } = useCurrentStatus(bloc);
  const prevCotChatStatus = useRef<CotChatStatus | null>(null);
  const [loadingBackgroundMotion, setLoadingBackgroundMotion] = useState<boolean>(false);
  const [backgroundMotion, setBackgroundMotion] = useState<React.ReactElement | null>(null);

  const handleClickClose: React.MouseEventHandler<HTMLImageElement> = e => {
    e.stopPropagation();
    onClose(e);
  };

  const executeBackgroundMotion = () => {
    prevCotChatStatus.current = cotChatStatus;
    setLoadingBackgroundMotion(true);
    setBackgroundMotion(
      <motion.div
        key={Date.now()}
        animate={{
          background: cotChatStatus === "waiting" ? background1 : background2,
        }}
        transition={{
          duration: 1,
          times: [0, 1],
        }}
        onAnimationComplete={() => setLoadingBackgroundMotion(false)}
        className={styles.mask}
      />
    );
  };

  useEffect(() => {
    if (
      startMotionComplete === true &&
      loadingBackgroundMotion === false &&
      cotChatStatus !== prevCotChatStatus.current
    ) {
      executeBackgroundMotion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMotionComplete, cotChatStatus, loadingBackgroundMotion]);

  const chatPanelClassName = cls(styles.chatPanel);
  const textContentClassName = cls(styles.textContent);
  const maskClassName = cls(styles.mask, startMotionComplete === false && styles.startMotionComplete);

  const chatContentElement =
    currentStatus === "normal" ? bloc.state.question.value : <LoadingTexts currentStatus={currentStatus} />;

  const progressElement =
    currentStatus === "creating" ? (
      <motion.div
        className={styles.progress}
        animate={{
          width: ["0%", "20%", "40%", "60%", "80%", "90%"],
        }}
        transition={{
          duration: 4,
          ease: "linear",
          times: [0, 0.2, 0.4, 0.6, 0.8, 1],
          repeat: Infinity,
        }}
      />
    ) : null;

  return (
    <motion.div
      initial={"normal"}
      variants={wrapperVariants}
      animate={currentStatus}
      transition={transition}
      className={chatPanelClassName}
    >
      <VirtualAssistant bloc={bloc} />
      <motion.div
        initial={"normal"}
        variants={maskVariants}
        animate={currentStatus}
        transition={transition}
        className={maskClassName}
      />

      {backgroundMotion}

      <div className={textContentClassName}>
        {bloc.state.waitingThinking.value === true ? <LoadingMotion /> : chatContentElement}
      </div>
      {progressElement}
    </motion.div>
  );
};
