import { BackgroundVariant } from "reactflow";
import style from "./index.module.css";
import FotReactFlowBackground from "./FotReactFlow/FotReactFlowBackground";

export function CreatorBackground({ children }: { children?: React.ReactNode }): JSX.Element {
  return (
    <div className={style["background-box"]}>
      <FotReactFlowBackground
        id="1"
        gap={70}
        color="rgba(2, 12, 38, 0.05)"
        lineWidth={0.5}
        variant={BackgroundVariant.Lines}
      />
      {/* create new project后显示可供选择的不同模板 */}
      {/* <TemplateModal /> */}
      {children}
    </div>
  );
}
