"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseUtil = void 0;
const LangUtil_1 = require("../cutil/LangUtil");
const JsonUtil_1 = require("../cutil/JsonUtil");
class ResponseUtil {
    /**
     * 获取已经读取过的json
     * @param response
     */
    static unwrapJson(response) {
        const text = this.unwrapText(response);
        if (text != undefined) {
            return JsonUtil_1.JsonUtil.parse(text) ?? {};
        }
        return undefined;
    }
    /**
     * 获取已经读取过的text
     * @param response
     */
    static unwrapText(response) {
        return response.responseText;
    }
    /**
     * 读取response.text 数据，只当header.content-type includes 'application/json'，并且注入到Response 对象中
     * @param response
     */
    static async wrap(response) {
        const content = response.headers.get("Content-Type");
        if (!content?.toLowerCase().includes("application/json")) {
            return response;
        }
        const responseText = await (0, LangUtil_1.tryPromise)(response.text());
        response.responseText = responseText.data ?? "";
        return response;
    }
}
exports.ResponseUtil = ResponseUtil;
