"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrototypingParameters = exports.PrototypingData = exports.PrototypingComponent = exports.Prototyping = void 0;
const class_transformer_1 = require("class-transformer");
class Prototyping {
    constructor() {
        this.components = [];
    }
}
exports.Prototyping = Prototyping;
__decorate([
    (0, class_transformer_1.Type)(() => PrototypingComponent)
], Prototyping.prototype, "components", void 0);
class PrototypingComponent {
    constructor() {
        this.data = [];
        this.description = "";
        this.name = "";
        this.type = "";
    }
}
exports.PrototypingComponent = PrototypingComponent;
__decorate([
    (0, class_transformer_1.Type)(() => PrototypingData)
], PrototypingComponent.prototype, "data", void 0);
class PrototypingData {
    constructor() {
        this.description = "";
        this.name = "";
        this.parameters = new PrototypingParameters();
    }
}
exports.PrototypingData = PrototypingData;
__decorate([
    (0, class_transformer_1.Type)(() => PrototypingParameters)
], PrototypingData.prototype, "parameters", void 0);
class PrototypingParameters {
}
exports.PrototypingParameters = PrototypingParameters;
