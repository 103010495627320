import { WEB_APP_DEFAULT_SUBTITLE, WEB_APP_DEFAULT_TITLE } from "@views/thinking-layout-editor/constants";
import { CHAT_APP_DEFAULTS, PreviewAppTypeMap, APP_NAME_TEMPLATE } from "./const";
import { PreviewAppValue } from "./model/PreviewAppValue";

export class PreviewAppValueGenUtil {
  /**
   * 原来 PreviewAppUtil.genDefaultUISaasValue
   */
  static genPreviewAppValue(): PreviewAppValue {
    return new PreviewAppValue();
  }

  /**
   * 根据groupId创建output
   *
   * **只会在mobile中cot生成的flow调用，根据边上文字预判模拟输出组**
   *
   * @param groupId 组的Id
   * @returns AISaasOutput.ThoughtTyp
   */
  static createGroupOutput(groupId: string): AISaasOutput.ThoughtType {
    return {
      groupId,
      angle: "",
      implication: "",
      modifier: "",
      reason: "",
      results: [
        {
          previewId: "",
          nodeId: "",
          angles: [],
          content: [],
          dislikes: [],
          filters: [],
          fontSize: "Normal",
          layout: "textBeautify",
          likes: [],
          parentNode: groupId,
          reactions: [],
          thought: "",
          type: "text",
        },
      ],
      grid: false,
    };
  }

  static genAppId(index: number = 1): string {
    return `appId-${index}`;
  }

  static getAppTypeCountByFormat(value: PreviewApp.UISaasValueType[], format: PreviewApp.FormatChooseAppType): number {
    return value.filter(item => item.v2App?.type === format.appType).length;
  }

  static genPreviewAppTitleByFormat(option: PreviewApp.FormatChooseAppType): {
    title: string;
    subTitle: string;
  } {
    if (option.appType === PreviewAppTypeMap.CHAT_AI) {
      return {
        title: CHAT_APP_DEFAULTS.title,
        subTitle: CHAT_APP_DEFAULTS.subTitle,
      };
    }

    return {
      title: WEB_APP_DEFAULT_TITLE,
      subTitle: WEB_APP_DEFAULT_SUBTITLE,
    };
  }

  /**
   * 见 getUIListParam
   *
   * 并扩展 v2 版本预览初始数据
   *
   * @param saasUIData
   * @param extra
   * @returns
   */
  static genPreviewApp(
    saasUIData: PreviewAppValue,
    extra?: Partial<PreviewAppValue> & {
      v2App: PreviewApp.PreviewAppV2FieldType;
    }
  ): PreviewAppValue {
    const { v2App, ...uidata } = extra || {};

    return {
      label: "",
      value: saasUIData.value || "saas",
      title: saasUIData.title,
      titleV3: saasUIData.titleV3 || {},
      subTitle: saasUIData.subTitle,
      subTitleV3: saasUIData.subTitleV3 || {},
      placeholder: saasUIData.placeholder,
      // TODO: 应该去掉 function
      input: saasUIData.input,
      // TODO: 应该去掉 function
      output: [...saasUIData.output],
      showTitle: saasUIData.showTitle,
      showSubTitle: saasUIData.showSubTitle,
      titleStyle: JSON.stringify(saasUIData.titleStyle),
      subTitleStyle: JSON.stringify(saasUIData.subTitleStyle),
      staticMode: saasUIData.staticMode,
      settings: saasUIData.settings,
      theme: saasUIData.theme,
      fontSize: saasUIData.fontSize,
      ...uidata,

      // v2 version
      id: v2App?.id || "",
      v2App: v2App,
    };
  }

  static getAppName(format: PreviewApp.FormatChooseAppType, count: number): string {
    const { title } = format;
    if (!count || count < 1) {
      return APP_NAME_TEMPLATE.replace("{type}", title).replace(" {count}", "");
    }
    return APP_NAME_TEMPLATE.replace("{type}", title).replace("{count}", count.toString());
  }

  /**
   * FIXME: 生成的 appid 是从 1开始,并非0,所以如果外部传入的值小于等于1,则需要 +1
   * @param args
   * @param defaultV2App
   * @returns
   */
  static genV2App(
    args: Partial<PreviewApp.GenV2AppArgs>,
    defaultV2App?: Partial<PreviewApp.PreviewAppV2FieldType>
  ): PreviewApp.PreviewAppV2FieldType {
    const { lastIndex = 1, lastTypeIndex = 0, format } = args;
    const type = format?.appType;
    return {
      id: PreviewAppValueGenUtil.genAppId(lastIndex),
      type: type,
      appName: type ? PreviewAppValueGenUtil.getAppName(format, lastTypeIndex) : "",
      appDescription: "",
      ...defaultV2App,
    };
  }
}
