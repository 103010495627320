"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryNodeUtil = void 0;
const SearchInfoWrapper_1 = require("../../storyV2/model/SearchInfoWrapper");
class StoryNodeUtil {
    static hasNextPage(node) {
        const searchInfo = node.parseContent((SearchInfoWrapper_1.SearchInfoWrapper));
        if (searchInfo == undefined) {
            return true;
        }
        return searchInfo.pagination.hasNextPage();
    }
    static searchKey(node) {
        const searchInfo = node.parseContent((SearchInfoWrapper_1.SearchInfoWrapper));
        return searchInfo?.searchKey || "";
    }
    static maxIdNumber(story) {
        const nodeIds = story
            .findAllNodes()
            .map(node => node.options.id)
            .map(id => id.split("-").last() ?? "1")
            .map(id => parseInt(id))
            .filter(x => x != undefined && !isNaN(x));
        const maxFromNodeIds = Math.max(...nodeIds);
        if (story.nodeIndexRef) {
            return Math.max(maxFromNodeIds, story.nodeIndexRef);
        }
        return maxFromNodeIds;
    }
    static idNumber(node) {
        return parseInt(node.options.id.split("-").last() ?? "1");
    }
}
exports.StoryNodeUtil = StoryNodeUtil;
