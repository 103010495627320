"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YoutubeVideosAdapter = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
class YoutubeVideosAdapter extends AdapterBase_1.AdapterBase {
    runResponseType() {
        return AdapterBase_1.RunResponseType.YOUTUBE_VIDEOS;
    }
}
exports.YoutubeVideosAdapter = YoutubeVideosAdapter;
