"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DarkTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class DarkTheme {
}
exports.DarkTheme = DarkTheme;
DarkTheme.theme = {
    chatAiTheme: {
        chatBackground: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat-pc-background.png')",
        chatBackgroundPad: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat-pad-background.png')",
        chatBackgroundMobile: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/chat-mobile-background.png')",
        logoBrightness: 15,
        shareTitleColor: "#fff",
        hintTextColor: "#fff",
        valueColor: "#fff",
        uploadTipBackground: "rgba(255, 255, 255, 0.1)",
        toolTipBackground: "rgba(255, 255, 255, 0.1)",
        uploadTipPadding: "15px 25px",
        uploadDesColor: "rgba(255, 255, 255, 0.35)",
        uploadLimitSizeColor: "rgba(255, 255, 255, 0.35)",
        uploadLoadingWrapBackground: "rgba(255, 255, 255, 0.10)",
        uploadLoadingBorderColor: "rgba(255, 255, 255, 0.10)",
        inputBarBorderColor: "rgba(255, 255, 255, 0.1)",
        inputPlaceholderColor: "rgba(255, 255, 255, 0.35)",
        buttonBackground: "rgba(255, 255, 255, 0.1)",
        buttonColor: "rgba(255, 255, 255, 0.65)",
        seeFormatDescriptionColor: "#0a9dff",
        toolTipBoxBg: "rgba(255, 255, 255, 0.1)",
        toolTipBoxShadow: "none",
        addContentColor: "#fff",
        priceTextColor: "#fff",
        shareFooterUrlInfo: "#fff",
        shareFooterUrlColor: "#fff",
        priceBtnBg: "#12192B",
        priceDesColor: "#fff",
        inputColor: "#fff",
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        renderingTransitionColors: "#1a142d",
        inputPaddingLeft: "11px",
    },
};
