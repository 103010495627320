import { Button } from "antd";
import styles from "./NewContactPanel.module.scss";
import { useSignal } from "@preact/signals-react";
import { Image } from "antd-mobile";
import { PhoneInfo } from "../phoneList/PhoneList";

export function NewContactPanel(props: { onClickAdd: (phoneInfo: PhoneInfo) => void }) {
  const isInAddModel = useSignal(false);
  const name = useSignal("");
  const phoneNumber = useSignal("");

  const initializeInfo = () => {
    phoneNumber.value = "";
    name.value = "";
  };

  const handleChangePhoneNumber: React.ChangeEventHandler<HTMLInputElement> = e => {
    phoneNumber.value = e.target.value;
  };

  const handleChangeName: React.ChangeEventHandler<HTMLInputElement> = e => {
    name.value = e.target.value;
  };

  const handleClickAdd = () => {
    props.onClickAdd({
      name: name.value,
      phoneNumber: phoneNumber.value,
    });
    isInAddModel.value = false;
    initializeInfo();
  };

  const handleClickCancel = () => {
    isInAddModel.value = false;
    initializeInfo();
  };

  const handleClickOpen = () => {
    isInAddModel.value = true;
  };

  return (
    <>
      {isInAddModel.value === false && (
        <div className={styles.contactAdd} onClick={handleClickOpen}>
          <Image
            className={styles.addImage}
            src={require("../../assets/callerbot-add-icon.png")}
            width="10px"
            height="auto"
          />
          <div className={styles.uploadTextBox}>Add a new contact manually</div>
        </div>
      )}

      {isInAddModel.value === true && (
        <div className={styles.form}>
          <div className={styles.title}>New Contact</div>
          <label className={styles.label}>Name</label>
          <input type="text" onChange={handleChangeName} value={name.value} className={styles.input} />
          <label className={styles.label}>Phone number</label>
          <input type="text" onChange={handleChangePhoneNumber} className={styles.input} value={phoneNumber.value} />
          <div className={styles.buttonGroup}>
            <Button onClick={handleClickCancel} className={styles.cancel}>
              Cancel
            </Button>
            <Button onClick={handleClickAdd} type="primary" className={styles.add}>
              Add
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
