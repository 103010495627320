"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BluePrintApi = void 0;
class BluePrintApi {
    constructor(restClient) {
        this.restClient = restClient;
    }
    run(inputs, blueprintData) {
        const reqData = { inputs, blueprint: blueprintData };
        return this.restClient.post(`/be/langchain/execute_blueprint`, reqData).then(res => res.validate());
    }
}
exports.BluePrintApi = BluePrintApi;
