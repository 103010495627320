"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultBehaviorService = void 0;
const StudioProjectAttributesV3NodeData_1 = require("../../project/domain/StudioProjectAttributesV3NodeData");
const AdapterRegistry_1 = require("../adapter/AdapterRegistry");
class DefaultBehaviorService {
    constructor(adapterRegistry, sourceNode, sourceNodes) {
        this.nodeTypeMap = {};
        this.adapterRegistry = adapterRegistry;
        this.sourceNodes = sourceNodes;
        this.sourceNode = sourceNode;
        // initMap
        this.initNodeTypeMap();
    }
    initNodeTypeMap() {
        StudioProjectAttributesV3NodeData_1.nonTextTypes.forEach((each) => {
            this.nodeTypeMap[each] = AdapterRegistry_1.AdapterName.addDataSource;
        });
        StudioProjectAttributesV3NodeData_1.textTypes.forEach((each) => {
            // TODO: handle non-stream when response is not displayed in a node
            this.nodeTypeMap[each] = AdapterRegistry_1.AdapterName.identifier;
        });
    }
    getSourceNodeType() {
        if (this.sourceNode?.options?.type === "customGroup") {
            const firstChild = this.sourceNodes[0]?.children[0];
            return firstChild.options.displayType || "";
        }
        return this?.sourceNode?.options?.displayType || "";
    }
    async getAdapter() {
        let adapter = AdapterRegistry_1.AdapterName.addDataSource;
        if (this.sourceNode) {
            adapter = this.nodeTypeMap[this.getSourceNodeType()] || AdapterRegistry_1.AdapterName.identifier;
        }
        else if (this.sourceNodes.length > 0) {
            // not covering the case for multiple inputs
            throw Error("Not Implemented!");
        }
        // return text generation by default
        return await this.adapterRegistry.get(adapter);
    }
}
exports.DefaultBehaviorService = DefaultBehaviorService;
