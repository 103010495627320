import { Alert, message } from "antd";
import { CloseCircleOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import store from "@store/index";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { message as Message } from "imagica-uikit/dist/components/Message/Message";
import { AdapterErrorUtil } from "imagica-corekit/dist/base/storyV2/adapter/AdapterErrorUtil";

const CloseIcon = () => (
  <img src={`${settings.S3CDN}${settings.viewAssetsPath}close-white.png`} className="close-icon" />
);
const CheckMarkIcon = () => (
  <img src={`${settings.S3CDN}${settings.viewAssetsPath}checkMark.png`} className="sucess-icon" />
);
const msgIconEl = {
  success: <CheckMarkIcon />,
  error: <CloseCircleOutlined />,
  info: <InfoCircleOutlined />,
  warning: <ExclamationCircleOutlined />,
};

function hasProperty(obj) {
  var hasProp = false;
  for (var prop in obj) {
    hasProp = true;
    break;
  }
  return hasProp;
}

function customMsg(param) {
  const content = typeof param === "string" ? param : param.content;
  const type = typeof param === "string" ? "error" : param.type;
  const duration = typeof param === "string" ? 5 : param.duration;

  message.open({
    type,
    content: (
      <Alert
        message={content}
        type={type}
        icon={msgIconEl[type]}
        closeIcon={<CloseIcon />}
        showIcon
        closable
        onClose={() => message.destroy()}
      />
    ),
    className: "custom-editor-msg",
    icon: <></>,
    duration,
  });
}

const func = {
  setLocalStorage(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  },

  getLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  },

  isEmpty(value) {
    return (
      value == null ||
      value == "" ||
      (typeof value === "string" && value.trim() == "") ||
      (typeof value === "object" && !hasProperty(value)) ||
      (value instanceof Array && value.length == 0)
    );
  },
  customMsg: Message,
  messageError(error) {
    if (error?.statusText) {
      error.message = error.statusText;
    }
    Message({
      content: AdapterErrorUtil.getErrorMsg(error),
      type: "error",
    });
  },
  messageUtil(content, type = "error") {
    const mode = store.getState().fot.mode;

    if (mode !== "studioMode") {
      customMsg({
        content,
        type,
      });
      return;
    }
    const styleObj = {
      position: "fixed",
      top: "10px",
      left: "20px",
      right: "20px",
      height: "auto",
    };
    message.open({
      type,
      content,
      className: "custom-class",
      // duration: 1000,
      style: styleObj,
      onClick: () => {
        message.destroy();
      },
    });
  },
  queryURLParams(url) {
    let pattern = /(\w+)=(\w+)/gi; //定义正则表达式
    let parames = {}; // 定义参数对象
    url.replace(pattern, ($, $1, $2) => {
      parames[$1] = $2;
    });
    return parames;
  },
  disableScale() {
    const keyCodeMap = {
      // 91: true, // command
      61: true,
      107: true, // 数字键盘 +
      109: true, // 数字键盘 -
      173: true, // 火狐 - 号
      187: true, // +
      189: true, // -
    };
    // 覆盖ctrl||command + ‘+’/‘-’
    document.onkeydown = function (event) {
      const e = event || window.event;
      const ctrlKey = e.ctrlKey || e.metaKey;
      if (ctrlKey && keyCodeMap[e.keyCode]) {
        e.preventDefault();
      } else if (e.detail) {
        // Firefox
        event.returnValue = false;
      }
    };
    // 覆盖鼠标滑动
    document.body.addEventListener("wheel", func.preventWheel, { passive: false });
  },

  preventWheel(e) {
    if (e.ctrlKey) {
      if (e.deltaY < 0) {
        e.preventDefault();
        return false;
      }
      if (e.deltaY > 0) {
        e.preventDefault();
        return false;
      }
    }
  },

  moveDisableScale() {
    document.body.removeEventListener("wheel", func.preventWheel);
  },

  getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12 || 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return formattedTime;
  },

  // 将每个键转换为驼峰命名，并复制到转换后的样式对象中
  transformStyle(originalStyle) {
    const transformedStyle = {};
    for (const key in originalStyle) {
      if (Object.prototype.hasOwnProperty.call(originalStyle, key)) {
        const transformedKey = key.replace(/_./g, match => match.charAt(1).toUpperCase());
        transformedStyle[transformedKey] = originalStyle[key];
      }
    }
    return transformedStyle;
  },
};

export const matchMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      userAgent.substr(0, 4)
    )
  ) {
    return true;
  }
  return false;
};

export default func;
