"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryEdgeOptionsUtil = void 0;
const StudioProjectAttributesV2Edge_1 = require("../../project/domain/StudioProjectAttributesV2Edge");
const StoryEdgeOptions_1 = require("../../storyV2/domain/StoryEdgeOptions");
const StudioProjectAttributesV3Node_1 = require("../../project/domain/StudioProjectAttributesV3Node");
const StoryEdgeOptions_2 = require("../../storyV2/domain/StoryEdgeOptions");
class StoryEdgeOptionsUtil {
    static from(params) {
        if (!(params.data instanceof StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2EdgeData)) {
            throw new Error("params.data type is wrong");
        }
        const data = params.data;
        const requestParams = new StoryEdgeOptions_2.StoryEdgeOptionsActionDataRequestParams(params.data?.lineParam?.requestParam?.loader || "", params.data?.lineParam?.requestParam?.file, params.data?.lineParam?.requestParam?.name, params.data?.lineParam?.requestParam?.s3Url, params.data?.lineParam?.requestParam?.urls);
        const funcOriginalData = new StoryEdgeOptions_1.StoryEdgeOptionsFuncOriginalData(params.data?.lineParam?.originFunctionData?.id, params.data?.lineParam?.originFunctionData?.value);
        const actionData = new StoryEdgeOptions_1.StoryEdgeOptionsActionData(data.lineParam?.lineType || "", data.lineParam?.enterText || "", data.lineParam?.identifier?.type || "", data.lineParam?.identifier?.value || "", data.lineParam?.queryValue || "", requestParams, funcOriginalData, data.lineParam?.fileUuid || "", data.lineParam?.blueJson || "", data.lineParam?.description || "");
        let sources = [];
        if (params instanceof StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2Edge) {
            sources = [params.source];
        }
        else {
            sources = (params.data.flows ?? []).map(f => {
                return f.sourceLineId || "";
            });
        }
        let target = "";
        if (params instanceof StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2Edge) {
            target = params.target;
        }
        else {
            target = params.data.targetLineId || "";
        }
        return new StoryEdgeOptions_1.StoryEdgeOptions(params.id, sources, target, actionData);
    }
    static optionsToProjectEdge(options) {
        const edgeData = new StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2EdgeData();
        edgeData.targetLineId = options.target;
        edgeData.flows = options.source.map(s => {
            let flow = new StudioProjectAttributesV2Edge_1.StudioProjectV3EdgeFlow();
            flow.sourceLineId = s;
            return flow;
        });
        edgeData.lineParam = {
            lineType: options.actionData.lineType,
            enterText: options.actionData.enterText,
            identifier: {
                type: options.actionData.identifierType,
                value: options.actionData.identifierValue,
            },
            queryValue: options.actionData.queryValue,
            requestParam: {
                loader: options.actionData.requestParam.loader,
                file: options.actionData.requestParam.file,
                name: options.actionData.requestParam.name,
                s3Url: options.actionData.requestParam.s3Url,
                urls: options.actionData.requestParam.urls,
            },
            originFunctionData: {
                id: options.actionData.funcOriginalData.id,
                value: options.actionData.funcOriginalData.value,
            },
            fileUuid: options.actionData.fileUuid,
            blueJson: options.actionData.blueJson,
        };
        const edge = new StudioProjectAttributesV3Node_1.StudioProjectAttributesV3Node();
        edge.id = options.id;
        edge.type = "customNewEdge";
        edge.data = edgeData;
        return edge;
    }
}
exports.StoryEdgeOptionsUtil = StoryEdgeOptionsUtil;
