import { Modal as AntdModal } from "antd";
import { DefaultProps } from "../../types";
import { callback } from "../../utilities";
import { FC, useCallback } from "react";
import cls from "classnames";
import style from "./style.module.scss";

export interface ModalProps extends DefaultProps {
  isOpen?: boolean;
  className?: string;
  wrapClassName?: string;
  onOpenChange?: (visible: boolean) => void;
  mask?: boolean;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  className,
  wrapClassName,
  children,
  onOpenChange = callback,
  mask = true,
}) => {
  const handleCancel = useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  return (
    <AntdModal
      mask={mask}
      className={cls(style.modal, className)}
      wrapClassName={cls(style.wrap, wrapClassName)}
      open={isOpen}
      title={null}
      footer={null}
      closeIcon={<></>}
      onCancel={handleCancel}
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
