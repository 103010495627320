import { useSelector, useDispatch } from "react-redux";
import { Position } from "reactflow";
import { useSignal, computed } from "@preact/signals-react";
import { NEW_EDGE_REG } from "../../../views/thinking-layout-editor/constants";
import { getEdgeParams, getMultiEdgeParams, getHandlePosition } from "../../../uikit/util/EdgeCalculate";
import { getSmoothStepPath, getPath as uikitGetPath } from "../../../uikit/util/getSmoothStepPath";
import { fotActions } from "../../../store/fot";
import { getMidllePoint } from "../../../uikit/hooks/useCalculateEdgePosition";

type CustomNewLineBlocParam = {
  id: string;
  getHomeNodes: () => any;
  setEdges: (edges: any) => void;
};

export class CustomNewLineBloc {
  private id: string;
  private getHomeNodes: () => any;
  private setEdges: (edges: any) => void;

  constructor({ id, getHomeNodes, setEdges }: CustomNewLineBlocParam) {
    this.id = id;
    this.getHomeNodes = getHomeNodes;
    this.setEdges = setEdges;
  }

  dispatch = useDispatch();

  hoverEdgeData = computed(() => useSelector((state: any) => state.editor.hoverEdgeData));
  isDraggingCreatingNewLine = useSelector((state: any) => state.editor.isDraggingCreatingNewLine);
  needHighlight = useSignal(false);

  checkEdgeArr = useSelector((state: any) => state.fot.checkEdgeArr);
  hasSelectedArea = useSelector((state: any) => state.fot.hasSelectedArea);
  setCheckEdgeArr = (val: string[]) => {
    this.dispatch(fotActions.setCheckEdgeArr(val));
  };

  getOriginPoints = (allNode: Array<any>, borderRadius: number, param: any) => {
    const sourceHandlePos = getHandlePosition(param.firstSourceNode, allNode, Position.Right);
    const targetHandlePos = getHandlePosition(param.realTargetNode, allNode, Position.Left);
    const [, edgePoints = []] = getSmoothStepPath({
      sourceX: sourceHandlePos.x,
      sourceY: sourceHandlePos.y,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      targetX: targetHandlePos.x,
      targetY: targetHandlePos.y,
      borderRadius,
    });
    return edgePoints;
  };

  public getPath = (param: any) => {
    const { sourceNode, targetNode } = param;
    const borderRadius = 20;
    const fromEdge = NEW_EDGE_REG.test(sourceNode?.id);
    const allNode = this.getHomeNodes();
    let edgePath;
    //多个input输入，除去第一条其他的连线path计算
    if (NEW_EDGE_REG.test(targetNode.id) && targetNode.data?.flows?.[0]?.sourceLineId !== this.id) {
      const firstLinePoints = this.getOriginPoints(allNode, borderRadius, param);
      const middlePointX = (firstLinePoints[1].x + firstLinePoints[2].x) / 2;
      const middlePointY = (firstLinePoints[1].y + firstLinePoints[2].y) / 2;
      const sourceHandlePos = getHandlePosition(sourceNode, allNode, Position.Right);
      const [, edgePoints = []] = getSmoothStepPath({
        sourceX: sourceHandlePos.x,
        sourceY: sourceHandlePos.y,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        targetX: middlePointX,
        targetY: middlePointY,
        borderRadius,
      });
      const threePoints = edgePoints.slice(0, 3);
      //相交的连线是横向时
      let connectPoint = {
        x: middlePointX,
        y: threePoints.at(-1)?.y || 0,
      };
      //相交的连线是竖向时
      if (firstLinePoints[1].x === firstLinePoints[2].x) {
        connectPoint.x = threePoints.at(-1)?.x || 0;
        connectPoint.y = middlePointY;
      }
      const lastPoint = { x: middlePointX, y: middlePointY };
      const points = [...threePoints, connectPoint, lastPoint];
      edgePath = uikitGetPath(points, borderRadius);
    } else {
      const edgePoints = this.getOriginPoints(allNode, borderRadius, param);
      const middlePoint = getMidllePoint(edgePoints);
      const len = Math.floor(edgePoints.length / 2);
      const points = !fromEdge ? edgePoints.slice(0, len) : edgePoints.slice(len);

      if (!fromEdge) {
        points.push(middlePoint);
      } else {
        points.unshift(middlePoint);
      }
      edgePath = uikitGetPath(points, borderRadius);
    }
    return edgePath;
  };

  handleHoverEdgeDataEffect() {
    if (this.hasSelectedArea) {
      // 有选中的区域
      return;
    }
    const hoverData = this.hoverEdgeData.value;
    const isHovered = hoverData.state && hoverData.lineIds.includes(this.id);
    this.needHighlight.value = this.isDraggingCreatingNewLine.state && isHovered;
    if (isHovered === false && !hoverData.isSelected) {
      const filterArr = this.checkEdgeArr.filter((x: string) => !hoverData.lineIds.includes(x));
      this.setCheckEdgeArr(filterArr);
      return;
    }
    this.setCheckEdgeArr(Array.from(new Set([...this.checkEdgeArr, ...this.hoverEdgeData.value.lineIds])));
  }

  handleCheckEdgeArrEffect() {
    if (!this.checkEdgeArr.includes(this.id)) {
      this.setEdges((prevList: any) => {
        return prevList.map((x: any) => {
          if (x.id === this.id) {
            return {
              ...x,
              style: {
                ...x.style,
                strokeWidth: 2,
              },
            };
          }
          return x;
        });
      });
      return;
    }
    this.setEdges((prevList: any) => {
      return prevList.map((x: any) => {
        if (x.id === this.id) {
          return {
            ...x,
            style: {
              ...x.style,
              strokeWidth: 1,
            },
          };
        }
        return x;
      });
    });
  }
}
