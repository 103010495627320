import { useSignal } from "@preact/signals-react";
import { CallerBotBloc } from "../../CallerBotBloc";
import styles from "./CallPanel.module.scss";

type CallPanelProps = {
  bloc: CallerBotBloc;
};

export function CallPanel(props: CallPanelProps) {
  const name = useSignal("");
  const phoneNumber = useSignal("");

  const handleChangePhoneNumber: React.ChangeEventHandler<HTMLInputElement> = e => {
    phoneNumber.value = e.target.value;
    props.bloc.setPhoneNumber(e.target.value);
  };

  const handleChangeName: React.ChangeEventHandler<HTMLInputElement> = e => {
    name.value = e.target.value;
  };

  return (
    <div className={styles.form}>
      <div className={styles.title}>New Contact</div>
      <label className={styles.label}>Name</label>
      <input type="text" placeholder="Name" onChange={handleChangeName} value={name.value} className={styles.input} />
      <label className={styles.label}>Phone number</label>
      <input
        type="text"
        placeholder="000-000-000"
        onChange={handleChangePhoneNumber}
        className={styles.input}
        value={phoneNumber.value}
      />
      <span className={styles.callButton} onClick={() => props.bloc.callPhone()}>
        Call
      </span>
    </div>
  );
}
