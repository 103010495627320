import { Help } from "@views/components/Help";
import AutoResizablePanel from "@uiview/views/AutoResizablePanel/AutoResizablePanel";

export function CreatorHelp() {
  return (
    <AutoResizablePanel data-testid="help" data-creator="Help">
      <Help />
    </AutoResizablePanel>
  );
}
