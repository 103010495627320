export function autoSort(arr, needMoveNode, direction, newNode) {
  let len = 0;
  let nodes = [];
  const distance = 30;
  nodes = arr.map(x => {
    const idx = needMoveNode.findIndex(y => y.id === x.id);
    const position = {
      ...x.position,
    };
    if (idx !== -1) {
      if (direction === "left" || direction === "right") {
        const currentHeight = needMoveNode?.[idx]?.height || 0;
        len += currentHeight + distance;
        position.x = newNode.position.x;
        position.y = newNode.position.y - len;
      }
      if (direction === "top" || direction === "bottom") {
        const currentWidth = needMoveNode?.[idx]?.width || 0;
        len += currentWidth + distance;
        position.x = newNode.position.x - len;
        position.y = newNode.position.y;
      }

      return {
        ...x,
        position,
      };
    }
    return x;
  });
  return nodes.reverse();
}
