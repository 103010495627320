import { BaseDataRef } from "./BaseDataRef";

class GroupDataRefState {
  showStopGenerateBtn: boolean = false;
  abortNode: Record<string, any> = {};
}

/**
 * 原来 HomeUsePlugin 中 newLineDataRef
 */
export class GroupDataRef extends BaseDataRef<GroupDataRefState> {
  constructor() {
    super(new GroupDataRefState());
  }
}
