"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CotService = void 0;
class CotService {
    constructor(brainClient, meStore) {
        this.brainClient = brainClient;
        this.meStore = meStore;
    }
    async getTemplate(intent) {
        if (typeof intent !== "string") {
            return await this.getGoldenUtterance(intent);
        }
        const hasCotTemplateV2Permission = await this.meStore.hasCotTemplateV2Permission();
        if (hasCotTemplateV2Permission) {
            return await this.getTemplateGraphV2(intent);
        }
        return await this.getTemplateGraphV1(intent);
    }
    async getTemplateGraphV1(intent) {
        const result = await this.brainClient.openAi.getCotTemplateGraph("/be/cot/template", { intent });
        return result.data;
    }
    async getTemplateGraphV2(intent) {
        const result = await this.brainClient.openAi.getCotTemplateGraph("/be/cot/v2/template", { intent });
        return result.data;
    }
    async getGoldenUtterance(content) {
        const result = await this.brainClient.openAi.getCotTemplateGraph("/be/cot/golden_utterance", content);
        return result.data;
    }
    async getAiTitle(content) {
        const result = await this.brainClient.openAi.getCotTemplateGraph("/be/cot/golden_utterance", content);
        return result.data;
    }
}
exports.CotService = CotService;
