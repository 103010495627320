import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { ProjectNodeUtil } from "@uikit/projectNode/ProjectNodeUtil";
import { Node } from "reactflow";

export interface Input {
  name?: string;
  type?: string;
  description?: string;
  value?: string;
}

export class BluePrintUtil {
  static moveElementToEnd<T>(arr: T[], index: number) {
    if (index < 0 || index >= arr.length) {
      // 检查索引是否有效
      console.log("Invalid index");
      return arr;
    }

    const element = arr.splice(index, 1)[0];
    arr.push(element);
    return arr;
  }

  static findTargetNodes(edgeId: string, nodes: Node[], projectNodeStore: ProjectNodeStore): Input[] {
    const targetNode = nodes.find(x => x.id === edgeId);
    const flows = targetNode?.data.flows.map((x: any) => x.sourceNodeId);
    const inputs: Input[] = nodes
      .filter(node => flows.includes(node.id))
      .map(node => {
        const projectNode = projectNodeStore.getNode(node.id);

        return {
          // stud-2493 get input title(nodes)
          // name: node.data.textAreaValue?.name || node.data?.inputTitle || node.data?.generatedLabel || "input",
          name: node.data.textAreaValue?.name || ProjectNodeUtil.getTitle(projectNode, "input"),
          type: node.data.textAreaValue?.type ?? node.data.displayType,
          description: node.data.placeholder,
          value: node.data.textAreaValue?.value ?? node.data.textAreaValue,
        };
      });
    return inputs;
  }
}
