import { useState, useEffect } from "react";
import { useSelector, useDispatch, useStore as useReduxStore } from "react-redux";
import func from "@uikit/func";
import cls from "classnames";
import { Drawer, Popover } from "antd";
import { editorActions } from "../../store/editor";
import { fotActions } from "../../store/fot";
import { Request, Response } from "./customAPIFunctionComponents";
import VariablePopoverContent from "./VariablePopoverContent";
import GuidanceContent from "./GuidanceContent";
import style from "./css/CustomAPIFunction.module.css";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { getIt } from "@uikit/getIt";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { useSignal } from "@preact/signals-react";
import { useAsyncEffect } from "ahooks";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreateService } from "@uikit/service/CreateService";
import { HandleSaveUtil } from "@uikit/util/HandleSaveUtil";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { allPreDefinedFunctions } from "./nodeTypeComponents/nodeTypeDispose";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";

export function CreatorCustomAPIFunction() {
  const gql = getIt(ObjectRelationGqlService);
  const createService = getIt(CreateService);
  const dispatch = useDispatch();
  const store = useReduxStore();
  const homeStore = getIt(HomeStore);
  const meStore = getIt(MeStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const showNavPageStore = getIt(ShowNavPageStore);
  const [name, setName] = useState("");
  const [sendLoading, setSendLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [requestData, setRequestData] = useState({
    url: "",
    method: "get",
    headerList: [],
    bodyParam: "",
  });

  const hasCustomApiV2 = useSignal(false);

  // const inputNameRef = useRef(null)
  // const inputDescriptionRef = useRef(null)

  const createAPIFuncData = useSelector(state => state.editor.createAPIFuncData);
  const setCreateAPIFuncData = val => {
    dispatch(editorActions.setCreateAPIFuncData(val));
  };
  const setCreateFunctionClick = val => {
    dispatch(fotActions.setCreateFunctionClick(val));
  };
  const setCommandEnterNodeId = val => {
    dispatch(fotActions.setCommandEnterNodeId(val));
  };

  const onCancel = () => {
    setCreateAPIFuncData({
      open: false,
      loading: false,
      edgeId: "",
      sourceNodeId: "",
      targetNodeId: "",
      function: "",
      select: {},
      parameter: {},
    });

    setName("");
    setRequestData({
      url: "",
      method: "get",
      headerList: [],
      bodyParam: "",
    });
    setResponseData({});
  };
  const handleOk = async () => {
    const newFunctionData = {
      description: createAPIFuncData?.parameter?.description || "",
      edgeArr: [],
      name: createAPIFuncData?.parameter?.name || "",
      nodeArr: [],
      type: "customApi",
      apiData: {
        requestData,
        // responseData
      },
    };
    const functionId = createAPIFuncData?.select?.id;
    try {
      homeStore.setHomeNavSaveLoading(true);

      if (!func.isEmpty(functionId)) {
        await gql.updateObject(functionId, {
          name: "studio_function",
          attributes: newFunctionData,
        });
      } else {
        // TODO：老版本判定是否重名
        // https://brain-ai.atlassian.net/browse/BSF-7552不工作故还原修改 =>https://github.com/brain/micro-apps/pull/4088
        const currentSingleFlow = creatorRefStore.singleFlowEdgeArrRef.current.find(
          x => x.name === newFunctionData.name
        );
        if (!func.isEmpty(currentSingleFlow)) {
          func.customMsg("Duplicate Name");
          setCreateAPIFuncData({
            loading: false,
          });
          return;
        }
        const callback = object => {
          HandleSaveUtil.handleUserSavedFunctions({
            each: { user: createAPIFuncData?.select?.originElement?.user, ...object },
            isUpdate: false,
            // 原来调用方法并没有传入 nodeDataRef???, 所以这里变为空
            // nodeDataRef: nodeDataRef,
            nodeDataRef: { current: {} },
            personalFunctionStore: getIt(PersonalFunctionStore),
            creatorRefStore: creatorRefStore,
            allPreDefinedFunctions: allPreDefinedFunctions,
          });
        };
        await createService.createFunction(newFunctionData, false, callback);
      }

      const clickFuncData = {
        edgeId: createAPIFuncData.edgeId,
        functionName: newFunctionData.name,
      };
      setCreateFunctionClick(clickFuncData);
      func.customMsg({
        content: "Success",
        type: "success",
      });

      // 如果在首页则不继续执行
      if (showNavPageStore.state.showNavPage) {
        onCancel();
        return;
      }
      // 给按钮下的文本赋值
      creatorStoreMethods.setEdgeLineParam({
        id: createAPIFuncData.edgeId,
        enterText: `/${newFunctionData.name}`,
      });
      // 显示run all按钮
      creatorEdgesStore.setQueryLoading(createAPIFuncData.edgeId, "isGetQuery", true);
      creatorEdgesStore.setQueryLoading(createAPIFuncData.edgeId, "isApiFunction", true);
      // auto run
      setCommandEnterNodeId({
        nodeId: createAPIFuncData.sourceNodeId,
        targetNodeId: createAPIFuncData.targetNodeId,
        param: {
          selectValueParam: name,
          newAiDescription: `/${name}`,
        },
      });

      onCancel();
      /// 设想通过发送EdgeRunMsg并在v3的edge中监听，以触发保存api func时自动run
      /// 但上面setCreateFunctionClick已经触发了edge run，所以这个代码不打开
      /// eventbus.emit(new EdgeRunMsg(createAPIFuncData.edgeId, false))
    } catch (error) {
      console.error("handleOk", error);
      func.messageError(error);
    } finally {
      homeStore.setHomeNavSaveLoading(false);
    }
  };

  useAsyncEffect(async () => {
    hasCustomApiV2.value = await meStore.hasCustomApiV2();
  }, []);

  useEffect(() => {
    if (func.isEmpty(createAPIFuncData.function)) return;
    const data = {
      onCancel: onCancel,
      handleOk: handleOk,
    };
    if (typeof data[createAPIFuncData.function] != "function") return;
    data[createAPIFuncData.function]();
    setCreateAPIFuncData({ function: "" });
  }, [createAPIFuncData.function]);
  useEffect(() => {
    if (func.isEmpty(createAPIFuncData.select) || hasCustomApiV2.value) return;
    // const currFuncData = creatorRefStore.singleFlowEdgeArrRef.current.find(x => x.name === createAPIFuncData.select.label)
    // copy function在imageGenSelectOptions中
    const imageGenSelectOptions = store.getState().fot.imageGenSelectOptions;
    const currFuncData = imageGenSelectOptions.find(x => x.label === createAPIFuncData.select.label);
    if (func.isEmpty(currFuncData)) return;
    setName(currFuncData?.label);
    setRequestData(currFuncData?.apiData?.requestData);
    // setResponseData(currFuncData.apiData.responseData)
  }, [createAPIFuncData.select]);

  return (
    <Drawer
      className={cls(style["customAPIFunction-box"], createAPIFuncData.open && style["occlusion"])}
      placement="bottom"
      open={createAPIFuncData.open && !hasCustomApiV2.value}
      closable={false}
      data-testid="CreatorCustomAPIFunction"
    >
      <div className={style["content-box"]}>
        {/* 请求区域 */}
        <Request
          requestData={requestData}
          setRequestData={setRequestData}
          sendLoading={sendLoading}
          setSendLoading={setSendLoading}
          setResponseData={setResponseData}
        />
        {/* 响应区域 */}
        <Response responseData={responseData} />
        {/* 底部工具栏 */}
        <div className={style["tool-bar-box"]}>
          <div className={style["bottom-tool-bar"]}>
            <Popover content={<VariablePopoverContent showDelet={false} />}>
              <span className="iconfont icon-bianliang"></span>
            </Popover>

            {/* Custom JS function guidance */}
            {
              <Popover content={<GuidanceContent type="customApiFunction" />}>
                <span className="iconfont icon-i-info"></span>
              </Popover>
            }
          </div>
        </div>
      </div>
    </Drawer>
  );
}
