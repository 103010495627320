import { Popover } from "antd";
import classNames from "classnames";
import { useCallback } from "react";
import css from "./index.module.scss";
import { AddedIcon, PublishIcon } from "../icon";

const PopoverTipText = "You selected Chat AI template which does not support this type of content.";

export function Action(props: NodeToolbar.ActionProps) {
  const { className, disabled, added, onClick, popverContent, tipText, children, ...resetProps } = props;

  const _onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      !disabled && onClick?.(e);
    },
    [onClick, disabled]
  );

  const Element = (
    <div
      {...resetProps}
      className={classNames(className, css.wrap, {
        [css.disabled]: disabled,
        [css.added]: disabled ? false : added,
      })}
      aria-disabled={disabled}
      onClick={_onClick}
    >
      {added ? <AddedIcon /> : <PublishIcon />}

      {tipText ? tipText : null}
      {children}
    </div>
  );

  if (disabled) {
    return (
      <Popover
        autoAdjustOverflow={false}
        overlayClassName={css.popover}
        trigger={["hover"]}
        content={PopoverTipText || popverContent}
      >
        {Element}
      </Popover>
    );
  }

  return Element;
}
