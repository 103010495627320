"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Story = void 0;
const Eventbus_1 = require("../../cutil/Eventbus");
const StoryEdge_1 = require("../../storyV2/domain/StoryEdge");
const ArrayUtil_1 = require("../../util/ArrayUtil");
const StoryEventObject_1 = require("./StoryEventObject");
const StoryNodeState_1 = require("../../storyV2/domain/StoryNodeState");
class Story {
    constructor(projectId, startNodes = [], singleEdges = [], variableList = [], templateId = "", nodeIndexRef) {
        this.projectId = projectId;
        this.startNodes = startNodes;
        this.singleEdges = singleEdges;
        this.variableList = variableList;
        this.templateId = templateId;
        this.nodeIndexRef = nodeIndexRef;
        this._eventbus = new Eventbus_1.Eventbus();
        this.isNested = false;
        this.addListen();
    }
    addListen() {
        let allNodes = this.findAllNodes();
        allNodes.forEach(node => {
            node.on(rs => {
                this._eventbus.emit(StoryEventObject_1.StoryEventObject.create([node]));
                /// 如果mainNode接受的是childNode，那这些新增的child也增加监听
                if (typeof rs.content !== "string") {
                    const childs = rs.content;
                    childs.forEach(n => {
                        n.on(obj => {
                            this._eventbus.emit(StoryEventObject_1.StoryEventObject.create([n]));
                        });
                    });
                }
            });
        });
    }
    on(listener) {
        this._eventbus.on(StoryEventObject_1.StoryEventObject, result => {
            listener(result);
        }, true);
        const that = this;
        return {
            off() {
                that.off(listener);
            },
        };
    }
    off(listener) {
        this._eventbus.off(StoryEventObject_1.StoryEventObject, listener);
    }
    emit(elements) {
        this._eventbus.emit(StoryEventObject_1.StoryEventObject.create(elements));
    }
    updateInput(content, inputId) {
        const allNodes = this.findMainNodes();
        const targetNode = allNodes
            .filter(element => {
            return element.options.id === inputId;
        })
            .first();
        if (targetNode === undefined) {
            console.log("can not find input node");
            return;
        }
        targetNode.content = content;
    }
    /// 根据 nodeId 查找树下面的节点
    findNodesById(nodeId) {
        const nodes = this.findNodesByIds([nodeId]);
        const [node] = Array.from(nodes); // 获取第一个值
        return node;
    }
    findMainNodes() {
        const nodes = [...this.startNodes];
        let edges = this.startNodes.flatMap(x => x.lines).map(x => x.target);
        edges = [...edges, ...this.singleEdges];
        while (edges.length > 0) {
            const current = edges.pop();
            const targetNode = current.targetNode();
            if (targetNode) {
                /// 添加节点
                nodes.push(targetNode);
                /// 添加下一个节点的边
                edges.push(...targetNode.allEdges());
            }
        }
        return nodes;
    }
    flattenArray(nodes) {
        const result = [];
        function flattenHelper(nodes) {
            for (const item of nodes) {
                result.push(item);
                if (item.children) {
                    flattenHelper(item.children);
                }
            }
        }
        flattenHelper(nodes);
        return result;
    }
    findAllNodes() {
        const nodes = [...this.startNodes];
        this.startNodes.forEach(n => {
            if (n.children.length !== 0) {
                nodes.push(...n.children);
            }
        });
        let edges = this.startNodes.flatMap(x => x.lines).map(e => e.target);
        edges = [...edges, ...this.singleEdges];
        while (edges.length > 0) {
            const current = edges.pop();
            const targetNode = current.targetNode();
            if (targetNode) {
                /// 添加节点
                nodes.push(targetNode);
                /// 兼容blueprint多个group嵌套
                nodes.push(...this.flattenArray(targetNode.children));
                /// 添加下一个节点的边
                edges.push(...targetNode.allEdges());
            }
        }
        return ArrayUtil_1.ArrayUtil.deDuplication(nodes, node => {
            return node.options.id;
        });
    }
    findAllEdges() {
        const nodes = [...this.startNodes];
        let edges = nodes.flatMap(x => x.lines).map(e => e.target);
        edges = [...edges, ...this.singleEdges];
        let rs = [...edges];
        while (edges.length > 0) {
            const current = edges.pop();
            const targetNode = current.targetNode();
            if (targetNode) {
                /// 添加下一个节点的边
                edges.push(...targetNode.allEdges());
                rs.push(...targetNode.allEdges());
            }
        }
        return ArrayUtil_1.ArrayUtil.deDuplication(rs, item => {
            return item.options.id;
        });
    }
    findAllLines() {
        let lines = [];
        const nodes = this.findAllNodes();
        nodes.forEach(e => {
            lines = [...lines, ...e.lines];
        });
        const edges = this.findAllEdges();
        edges.forEach(e => {
            if (e.target) {
                lines = [...lines, e.target];
            }
        });
        return ArrayUtil_1.ArrayUtil.deDuplication(lines, item => {
            return item.options.id;
        });
    }
    isLoading() {
        for (const edge of this.findAllEdges()) {
            if (edge.state === StoryEdge_1.EdgeState.LOADING) {
                return true;
            }
        }
        return false;
    }
    /// 根据 nodeId 查找树下面的节点
    findNodesByIds(nodeIds) {
        const allNodes = this.findAllNodes();
        const results = new Set();
        for (const node of allNodes) {
            if (nodeIds.includes(node.options.id)) {
                results.add(node);
            }
        }
        return results;
    }
    reset() {
        this.startNodes.forEach(node => {
            node.allEdges().forEach(edge => edge.reset());
        });
    }
    resetState() {
        this.startNodes.forEach(node => {
            node.allEdges().forEach(edge => edge.resetState());
        });
    }
    resetInputGroup() {
        this.startNodes.forEach(node => {
            if (node.isInput) {
                node.allEdges().forEach(edge => edge.reset());
            }
        });
    }
    resetStateInputGroup() {
        this.startNodes.forEach(node => {
            if (node.isInput) {
                node.allEdges().forEach(edge => edge.resetState());
            }
        });
    }
    completeNotInputGroup() {
        this.startNodes.forEach(node => {
            if (!node.isInput) {
                node.setCompleted();
                node.allEdges().forEach(edge => edge.setCompleted());
            }
        });
    }
    hasShowingNodes() {
        return (this.findAllNodes().filter(node => node.state === StoryNodeState_1.StoryNodeState.RECEIVING || node.state === StoryNodeState_1.StoryNodeState.COMPLETED).length > 0);
    }
    /**
     * 找到和inputNode相关的Nodes
     */
    findInputRelateNodes() {
        const relatedNodes = this.startNodes
            .filter(x => x.isInput)
            .flatMap(node => {
            return node.allEdges().flatMap(x => {
                return x.findRelatedNodes();
            });
        });
        return relatedNodes;
    }
    /**
     * 找到第一条主线的最后一条边
     */
    findFirstStartNodesLastEdge() {
        return this.startNodes.first()?.findLastEdge();
    }
}
exports.Story = Story;
