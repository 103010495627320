import styles from "./nodeTypeComponents.module.css";
import { useStore as useReduxStore } from "react-redux";
import { get } from "lodash";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { getIt } from "@uikit/getIt";
import { FotReactFlow } from "@uikit/model/FotReactFlow";

const TitleBar = ({ children, id = "", isOpenDeleteBox }) => {
  const store = useReduxStore();
  const fotReactFlow = getIt(FotReactFlow);
  const handleClickDelete = () => {
    id && fotReactFlow.deleteNodeByIds([id]);
  };

  const isShare = get(store.getState(), "fot.isShare", false);

  return (
    <div>
      {isShare === false && isOpenDeleteBox ? (
        <div className={styles["delete-content"]} onClick={handleClickDelete}>
          <img src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`}></img>
        </div>
      ) : null}
    </div>
  );
};

export default TitleBar;
