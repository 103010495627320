import { ProjectNode } from "@uikit/projectNode/model";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class InputTitle {
  id = "";
  title = "";
}

type InputTitles = Record<string, InputTitle>;
export class NodesTitleStoreState {
  inputTitles: InputTitles = {};

  copyWith(param: { inputTitles?: InputTitles }): NodesTitleStoreState {
    const newState = new NodesTitleStoreState();
    newState.inputTitles = param.inputTitles ?? this.inputTitles;

    return newState;
  }
}

export type NodesTitleStorePropsType = {
  id: string;
  isInBlueprint?: boolean;
  projectNode?: ProjectNode;
};

// 原 useAisassState 状态
export class NodesTitleStore extends StoreBase<NodesTitleStoreState> {
  constructor() {
    super(() => new NodesTitleStoreState());
  }

  updateInputTitle(id: string, inputTitle: InputTitle): void {
    let newInputTitle;
    // delete
    if (!(inputTitle.id && inputTitle.title)) {
      newInputTitle = { ...this.state.inputTitles };
      delete newInputTitle[id];
    } else {
      newInputTitle = { ...this.state.inputTitles, [id]: inputTitle };
    }
    this.emit(this.state.copyWith({ inputTitles: newInputTitle }));
  }

  getInputTitleById(id: string): InputTitle {
    return this.state.inputTitles[id] || new InputTitle();
  }
}
