import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, useDragControls } from "framer-motion";
import { Skeleton } from "antd";
import { Virtuoso } from "react-virtuoso";

// import '../index.css';
import "../views-css/Feed.css";
import { fotActions } from "../store/fot";

import useScrollBottomToFetch from "../custom-hooks/useScrollBottomToFetch";
import useOpenAiToGenerate from "../custom-hooks/useOpenAiToGenerate";
import DomainButton from "./DomainButton";
import Filter from "./Filter";
import ContentFrame from "./ContentFrame";
import CardFocus from "./CardFocus";
import ChainThought from "./ChainThought";
import func from "@uikit/func";
import Recipe from "./components/Recipe";
import ThoughtInput from "./ThoughtInput";
import ThoughtOfUGC from "./ThoughtOfUGC";
import Footer from "./components/VirtuosoFooter";
import FeedContentSkeleton from "./components/FeedContentSkeleton";
import ChainThoughtIcon from "./components/ChainThoughtIcon";
import SharesBuy from "./components/SharesBuy";
import Setting from "./thinking-layout-editor/Setting";

function Feed(props) {
  const dispatch = useDispatch();

  const dragControls = useDragControls();

  const mode = useSelector(state => state.fot.mode);
  const thoughtChain = useSelector(state => state.fot.thoughtChain);
  const domainLoading = useSelector(state => state.fot.domainLoading);
  const contentLoading = useSelector(state => state.fot.contentLoading);
  const studioProjectName = useSelector(state => state.fot.studioProjectName);
  const showSetting = useSelector(state => state.fot.showSetting);

  // const stockData = useSelector((state) => state.fot.stockData);

  const setChainThoughtsState = val => {
    dispatch(fotActions.setChainThoughtsState(val));
  };

  const [query, setQuery] = useState("");
  const [showEditQuery, setEditQuery] = useState(false);
  const [jobDescriptions, setJobDescriptions] = useState([]);

  // const [isDomainsInit, setDomainsInitState] = useState(true)
  const tempFocusMode = false; //props.focusMode暂时使用这个值
  // const toggle_top_menu = () => {
  //   props.setFocusMode(!props.focusMode);
  // };
  const domainListRef = useRef(null);
  const studioDomainListRef = useRef(null);
  const constraintsRef = useRef(null);
  const observer = useRef({});
  const contentRef = useRef(null);
  const editInputRef = useRef(null);
  const needHandleAngel = ["Time", "Servings", "Difficulty"];

  const { contentFrameLoading, atBottomStateChange } = useScrollBottomToFetch(contentRef);
  const { openaiLoading } = useOpenAiToGenerate();

  const filteredThoughts = useMemo(() => {
    if (!props.otherModes.includes(mode)) {
      const isJobDescriptions = x => x?.results?.[0]?.type === "job_description";
      const jobDescriptionArr = props.thoughts.filter(x => isJobDescriptions(x));
      setJobDescriptions(jobDescriptionArr);
      return props.thoughts.filter(x => x !== ". . ." && !needHandleAngel.includes(x.angle) && !isJobDescriptions(x));
    }
    return props.thoughts.filter(x => x !== ". . ." && !needHandleAngel.includes(x.angle));
  }, [props.thoughts]);

  const isMobile = window.navigator.userAgent.includes("Mobile");
  const container = {
    init: {
      opacity: 0,
      y: "100%",
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        ease: "easeInOut",
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
  };
  let yStart = 0;
  let observerType = "domainList";
  let threshold = 0.1;

  const onChangeQuery = e => {
    setQuery(e.target.value);
  };

  const changeEditQuery = (boo, val) => {
    setEditQuery(boo);
    setQuery(val);
  };

  // 键盘事件
  const keyUpEvent = e => {
    const value = e.target.value;
    // enter
    if (e.keyCode === 13) {
      if (func.isEmpty(value)) {
        func.messageUtil("Thought cannot be empty", "warning");
        return;
      }
      props.select_thought({
        type: "thoughtEdit",
        text: value,
      });
      changeEditQuery(false, "");
    }
  };

  useEffect(() => {
    if (showEditQuery) editInputRef.current.focus();
  }, [showEditQuery]);

  function observeCb(entries) {
    entries.forEach(currEntry => {
      if (currEntry.intersectionRatio > threshold) {
        const innerText = currEntry.target.innerText;
        const index = props.domains.findIndex(x => x === innerText);
        // 这里需要添加获取的api调用逻辑
        props.lazy_domain(index);
        // 如果已经显示过了就取消监听
        observer.current[observerType].unobserve(currEntry.target);
      }
    });
  }
  function createObserver() {
    if (domainLoading || props.otherModes.includes(mode)) return;
    const rootEl = tempFocusMode ? domainListRef.current : studioDomainListRef.current;
    const children = Array.from(rootEl.children);
    const options = {
      root: rootEl,
      rootMargin: "0px",
      threshold: [threshold],
    };

    observer.current[observerType] = new IntersectionObserver(observeCb, options);
    children.forEach(x => {
      observer.current[observerType].observe(x);
    });
  }

  useEffect(() => {
    // 刚进入内容页面不执行
    if (props.domains.includes(".") || func.isEmpty(studioDomainListRef.current)) return;

    const childrens = [...studioDomainListRef.current.children];
    // loading完成且不是选中的第一个执行
    if (!contentLoading && props.selectedDomain > -1) {
      const currentSelectedChild = childrens[props.selectedDomain];
      studioDomainListRef.current.scrollTo({
        left: currentSelectedChild.offsetLeft,
        // behavior: 'smooth'
      });
    }
  }, [contentLoading]);

  useEffect(() => {
    createObserver();
  }, [domainLoading]);

  // useEffect(() => {
  //   observerType = props.focusMode ? 'domainList' : 'studioDomainList'
  //   createObserver()
  // }, [props.focusMode])

  const hasSubThoughtsAttr = () => {
    return props.thoughts.some(x => x.hasOwnProperty("subthoughts"));
  };
  const getIndexText = index => {
    if (index === -1) {
      return "thought";
    }
    return props.domains[index] || "";
  };
  const getThoughtFilter = () => {
    const noDummyFilters =
      !func.isEmpty(props.focusedThought) && !func.isEmpty(props.focusedThought.filters)
        ? props.focusedThought.filters.filter(x => x !== ".")
        : [];
    return noDummyFilters.length === 0;
  };
  const headerDragStart = y => {
    yStart = y;
  };
  const headerDragEnd = y => {
    if (y - yStart > 100) setChainThoughtsState(true);
  };
  const inputOnBlur = () => {
    setEditQuery(false);
  };

  return (
    <article className={tempFocusMode ? "Feed Feed-Focus" : "Feed"}>
      <ChainThought enterQuery={props.enterQuery} get_home_cards={props.get_home_cards} />

      <motion.div
        className="Feed-Drag-Box"
        drag="y"
        dragControls={dragControls}
        dragConstraints={{ top: 0, bottom: 0 }}
        dragListener={false}
        onDragStart={(event, info) => {
          headerDragStart(info.point.y);
        }}
        onDragEnd={(event, info) => {
          headerDragEnd(info.point.y);
        }}
        ref={constraintsRef}
      >
        {/* 头部  */}
        {!tempFocusMode ? (
          <div className="Top-Menu">
            <div className="Condensed-Content">
              {/* 文本 */}
              {mode === "productionMode" ? (
                <motion.div
                  className="Feed-Thought-Filter"
                  //手机通过ref控制拖动时，移动的元素
                  dragConstraints={constraintsRef}
                  onTouchStart={e => {
                    dragControls.start(e);
                  }}
                  onPointerDown={e => {
                    //电脑父元素dragListener=false, 不能直接拖动元素，需通过代码控制
                    if (!isMobile) {
                      dragControls.start(e);
                    }
                  }}
                >
                  {/* <p>{props.focusedThought.thought}</p> */}
                  {showEditQuery ? (
                    <input
                      className="feed-edit-query"
                      value={query}
                      ref={editInputRef}
                      onKeyUp={keyUpEvent}
                      onChange={onChangeQuery}
                      onBlur={inputOnBlur}
                      placeholder="Enter a query..."
                      type="text"
                    />
                  ) : (
                    <div className="tap-to-edit" onClick={() => changeEditQuery(true, props.focusedThought.thought)}>
                      <p>{props.focusedThought.thought}</p>
                      <span>Tap to edit</span>
                    </div>
                  )}

                  <ChainThoughtIcon />
                </motion.div>
              ) : (
                <ThoughtInput UIMode={props.UIMode} />
              )}
              {/* Filters */}
              <section className={getThoughtFilter() ? "Feed-Hidden" : "Feed-Filters"}>
                {getThoughtFilter()
                  ? null
                  : props.focusedThought.filters.map((filter, id) => (
                      <Filter key={id} id={id} label={filter} delete_angle={props.delete_angle} />
                    ))}
              </section>
            </div>
            {
              // studio显示固定buttons
              props.otherModes.includes(mode) ? (
                !func.isEmpty(studioProjectName) ? (
                  <article className="Domains Feed-Domains">
                    <section className="DomainsThought">
                      <DomainButton id={-1} selectedDomain={-1} on_select_domain={() => {}} label="thinking" />
                    </section>

                    <section className="Feed-DomainsList">
                      <DomainButton
                        key={0}
                        id={0}
                        label={studioProjectName}
                        selectedDomain={0}
                        on_select_domain={() => {}}
                      />
                    </section>
                  </article>
                ) : null
              ) : (
                <article className="Domains Feed-Domains">
                  <section className="DomainsThought">
                    <DomainButton
                      id={-1}
                      selectedDomain={props.selectedDomain}
                      on_select_domain={props.change_domain}
                      label="thinking"
                    />
                  </section>

                  <section className="Feed-DomainsList" ref={studioDomainListRef}>
                    {props.domains.map((domain, id) =>
                      domainLoading ? (
                        <Skeleton.Button
                          className="Feed-Domain-Skeleton"
                          key={id}
                          loading={true}
                          paragraph={false}
                          shape="round"
                          active
                        />
                      ) : domain !== "." ? (
                        <DomainButton
                          key={id}
                          id={id}
                          label={domain}
                          selectedDomain={props.selectedDomain}
                          on_select_domain={props.change_domain}
                        />
                      ) : null
                    )}
                  </section>
                </article>
              )
            }
            {/* <button type="button" className="Top-Down-Button" onClick={toggle_top_menu}>&#65086;</button> */}
          </div>
        ) : (
          <section className="Top-Menu-Focus">
            {/* <button type="button" className="Back-Button" onClick={props.go_back} disabled={mode === 'studioMode'}>&#65086;</button> */}
            <article className="Focus-Content">
              <CardFocus card={props.focusedThought} thoughtChain={thoughtChain} />

              {mode === "studioMode" ? (
                ""
              ) : (
                <article className="Domains">
                  <section className="DomainsThought">
                    <DomainButton
                      id={-1}
                      label="thinking"
                      selectedDomain={props.selectedDomain}
                      on_select_domain={props.change_domain}
                    />
                  </section>

                  <section className="Feed-DomainsList" ref={domainListRef}>
                    {props.domains.map((domain, id) =>
                      domainLoading ? (
                        <Skeleton.Button
                          className="Feed-Domain-Skeleton"
                          key={id}
                          loading={true}
                          paragraph={false}
                          shape="round"
                          active
                        />
                      ) : domain !== "." ? (
                        <DomainButton
                          key={id}
                          id={id}
                          label={domain}
                          selectedDomain={props.selectedDomain}
                          on_select_domain={props.change_domain}
                        />
                      ) : null
                    )}
                  </section>
                </article>
              )}
            </article>
            {/* <button type="button" className="Next-Button" onClick={toggle_top_menu} disabled={mode === 'studioMode'}>&#65085;</button> */}
          </section>
        )}
        <SharesBuy editorId="fotId" filteredThoughts={filteredThoughts} />
        {showSetting ? <Setting></Setting> : null}
        {/* {
          !util.isEmpty(stockData) ?
            <SharesBuy
              filteredThoughts={filteredThoughts} />
          : null
        } */}
        {/* 内容 */}
        {contentLoading ? (
          <FeedContentSkeleton />
        ) : (
          <motion.div
            ref={contentRef}
            className={`Content ${mode !== "studioMode" ? getIndexText(props.selectedDomain) : ""}`}
            variants={container}
            initial="init"
            animate="show"
          >
            {props.thoughts.some(x => needHandleAngel.includes(x.angle)) ? (
              <Recipe content={props.thoughts}></Recipe>
            ) : null}
            {/* {
                mode !== 'studioMode' ?
                  hasSubThoughtsAttr() ?
                    <ThoughtOfUGC
                      thoughts={props.thoughts}
                      select_thought={props.select_thought}
                    />
                  : props.thoughts
                      .filter(x => x !== '. . .')
                      .map((thought, id) => (
                        !needHandleAngel.includes(thought.angle) ?
                          <ContentFrame
                            key={id}
                            id={id}
                            type={getIndexText(props.selectedDomain)}
                            content={thought}
                            select_thought={props.select_thought}
                            add_angle={props.add_angle}
                            add_like={props.add_like}
                            add_dislike={props.add_dislike}
                          />
                        : null
                      ))
                  : null
              } */}
            {!props.otherModes.includes(mode) ? (
              hasSubThoughtsAttr() ? (
                <ThoughtOfUGC
                  thoughts={props.thoughts}
                  openaiLoading={openaiLoading}
                  select_thought={props.select_thought}
                  ContentRetrieval={props.ContentRetrieval}
                  enterQuery={props.enterQuery}
                />
              ) : !func.isEmpty(jobDescriptions) ? (
                jobDescriptions.map((thought, id) => (
                  <ContentFrame
                    key={id}
                    id={id}
                    type={getIndexText(props.selectedDomain)}
                    content={thought}
                    select_thought={props.select_thought}
                    add_angle={props.add_angle}
                    add_like={props.add_like}
                    add_dislike={props.add_dislike}
                    ContentRetrieval={props.ContentRetrieval}
                    enterQuery={props.enterQuery}
                  />
                ))
              ) : (
                <Virtuoso
                  context={{ contentFrameLoading }}
                  style={{ height: "100%" }}
                  data={filteredThoughts}
                  atBottomStateChange={atBottomStateChange}
                  itemContent={(id, thought) => {
                    return (
                      <ContentFrame
                        key={id}
                        id={id}
                        type={getIndexText(props.selectedDomain)}
                        content={thought}
                        select_thought={props.select_thought}
                        add_angle={props.add_angle}
                        add_like={props.add_like}
                        add_dislike={props.add_dislike}
                        ContentRetrieval={props.ContentRetrieval}
                        enterQuery={props.enterQuery}
                      />
                    );
                  }}
                  components={{ Footer }}
                />
              )
            ) : (
              filteredThoughts.map((thought, id) => (
                <ContentFrame
                  className={props.className}
                  key={id}
                  id={id}
                  type={getIndexText(props.selectedDomain)}
                  content={thought}
                  select_thought={props.select_thought}
                  add_angle={props.add_angle}
                  add_like={props.add_like}
                  add_dislike={props.add_dislike}
                  ContentRetrieval={props.ContentRetrieval}
                  enterQuery={props.enterQuery}
                  otherModes={props.otherModes}
                />
              ))
            )}
          </motion.div>
        )}
      </motion.div>
    </article>
  );
}

export default Feed;
