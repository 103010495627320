import { Signal, useSignal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { QuickStartModel, TemplateModel, TutorialModel } from "@views/thinking-layout-editor/types";
import Carousel from "./Carousel";
import { FotClient } from "imagica-corekit/dist/base/api/FotClient";
import { useCallback, useEffect } from "react";
import PreviewQuickStart from "../Preview/PreviewQuickStart";
import style from "./style.module.scss";
import { VideoModal } from "../VideoModal";
import { logEvent } from "@uikit/service/amplitude";
import { VideoModalState } from "../types";
import { getAssetsVideo } from "@views/thinking-layout-editor/utilities";

class CarouselHeadViewProps {
  handleProjectTemplateClick: Function = () => {};
}

export function CarouselHeadView(props: CarouselHeadViewProps): JSX.Element {
  const myTemplates: Signal<TemplateModel[]> = useSignal([]);
  const myTutorials: Signal<TutorialModel[]> = useSignal([]);

  const videoModalState: Signal<VideoModalState> = useSignal({
    isOpen: false,
    videoUrl: "",
    videoName: "",
  });

  const quickStart = useSignal<QuickStartModel | null>(null);

  const loadTemplates = useCallback(async () => {
    try {
      const brainClient: any = getIt(FotClient);
      const response = await brainClient.webConfigApi.fetchWebConfig();
      const newTemplates: TemplateModel[] = response.studios.templates;
      myTemplates.value = newTemplates;
      quickStart.value = response.studios.quickstart;
      // Anton: set tutorials from the server but without project_id
      const newTutorials: TutorialModel[] = response.studios.example_videos;
      myTutorials.value = newTutorials;
    } catch (e) {
      console.error("error", e);
    }
  }, []);

  useEffect(() => {
    loadTemplates();
  }, []);

  const handleTemplateClick = useCallback(
    async (template: TemplateModel) => {
      await props.handleProjectTemplateClick(template);
    },
    [props.handleProjectTemplateClick]
  );

  const handleTutorialClick = useCallback(
    (tutorial: TutorialModel) => {
      logEvent("start_template_video", { name: tutorial.name });
      videoModalState.value = {
        ...videoModalState,
        isOpen: true,
        videoUrl: tutorial.video_url,
        videoName: tutorial.name,
      };
    },
    [videoModalState.value]
  );

  const handleVideoModalOpenChange = useCallback(
    (isOpen: boolean) => {
      videoModalState.value = {
        ...videoModalState.value,
        isOpen,
      };
      logEvent("close_template_video", { name: videoModalState.value.videoName });
    },
    [videoModalState.value]
  );

  const playQuickStartVideo = useCallback(() => {
    const videoName = "quick start video";
    videoModalState.value = {
      ...videoModalState,
      isOpen: true,
      videoName,
      videoUrl: quickStart.value?.video ?? getAssetsVideo("quickStart.webm"),
    };
    logEvent("start_template_video", { name: videoName });
  }, [videoModalState, quickStart.value?.video]);

  const getQuickStartTemplate = useCallback(async () => {
    const env = process.env.REACT_APP_ENVIRONMENT ?? "development";
    const defaultProjectId = env === "production" ? 1392261 : 169494;
    await props.handleProjectTemplateClick({
      project_id: quickStart.value?.project_id ?? defaultProjectId,
      title: quickStart.value?.project_name ?? "Cooking Plan",
      description: "",
    });
  }, [quickStart.value]);

  return (
    <>
      <div className={style.gridHeaderLft}>
        <Carousel
          templates={myTemplates.value}
          tutorials={myTutorials.value}
          onTemplateClick={handleTemplateClick}
          onTutorialClick={handleTutorialClick}
        />
      </div>
      <div className={style.gridHeaderRgt}>
        <PreviewQuickStart
          isShown={true}
          isFirst={false}
          onVideoPlay={playQuickStartVideo}
          onGetTemplate={getQuickStartTemplate}
        />
      </div>
      <VideoModal
        isOpen={videoModalState.value.isOpen}
        videoUrl={videoModalState.value.videoUrl}
        onOpenChange={handleVideoModalOpenChange}
      />
    </>
  );
}
