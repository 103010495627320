"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiographyType = exports.VBDataTopicWithRelatedPeople = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataTopicWithRelatedPeople extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.topic_info = new Topic();
        this.people_list = [];
    }
}
exports.VBDataTopicWithRelatedPeople = VBDataTopicWithRelatedPeople;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Topic)
], VBDataTopicWithRelatedPeople.prototype, "topic_info", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => RelatedPerson, [])
], VBDataTopicWithRelatedPeople.prototype, "people_list", void 0);
class Topic {
    constructor() {
        this.topic = new TopicDetail();
    }
}
class TopicDetail {
    constructor() {
        this.topic_title = "";
        this.topic_summary = "";
    }
}
class RelatedPerson {
    constructor() {
        this.related_person = new PersonInfo();
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => PersonInfo)
], RelatedPerson.prototype, "related_person", void 0);
class PersonInfo {
    constructor() {
        this.image = "";
        this.lifespan = "";
        this.name = "";
        this.biography = [];
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Biography, [])
], PersonInfo.prototype, "biography", void 0);
class Biography {
    constructor() {
        this.biography_detail = new BiographyDetail();
    }
}
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => BiographyDetail)
], Biography.prototype, "biography_detail", void 0);
var BiographyType;
(function (BiographyType) {
    BiographyType["IMAGE"] = "image";
    BiographyType["TEXT"] = "text";
})(BiographyType || (exports.BiographyType = BiographyType = {}));
class BiographyDetail {
    constructor() {
        this.type = BiographyType.TEXT;
        this.biography_value = "";
    }
}
