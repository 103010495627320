"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YoutubeSearchResultsDataService = void 0;
const CardDataBaseService_1 = require("../../cardDataSearch/dataSerivce/CardDataBaseService");
const FunctionRegistry_1 = require("../../../base/storyV2/function/FunctionRegistry");
const VBDataYoutubeSearchResult_1 = require("../../../base/api/viewBuilderTyped/VBGraphData/VBDataYoutubeSearchResult");
/**
 * youtube 列表
 */
class YoutubeSearchResultsDataService extends CardDataBaseService_1.CardDataBaseService {
    constructor(functionRegistry) {
        super();
        this.functionRegistry = functionRegistry;
    }
    async search(params) {
        const findFunction = this.functionRegistry.getByRegistName(FunctionRegistry_1.FunctionName.youtubeVideos);
        if (!findFunction) {
            return Promise.reject("can not find function");
        }
        const youtubeFunction = findFunction;
        const funcResponse = await youtubeFunction.run(params.keyWords);
        const resultData = funcResponse.data;
        const data = resultData.result.map(item => {
            const newItem = new VBDataYoutubeSearchResult_1.VBDataYoutubeSearchResultItem();
            newItem.video_title = item.title;
            newItem.video_length = item.length;
            newItem.video_image = item.thumbnailUrl;
            return newItem;
        });
        const response = new VBDataYoutubeSearchResult_1.VBDataYoutubeSearchResult();
        response.video_list = data;
        return response;
    }
}
exports.YoutubeSearchResultsDataService = YoutubeSearchResultsDataService;
