import { AModal } from "@uiview/views/AModal/AModal";
import styles from "./EditModal.module.scss";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

type EditModalProps = {
  onClose: () => void;
  onOk: () => void;
};

export function EditModal({ onClose, onOk }: EditModalProps) {
  return (
    <AModal
      title={
        <div className={styles.mainTitle}>
          <div className={styles.title}> Developer Mode</div>
          <div className={styles.desc}>Create and modify functionality for your ideas</div>
        </div>
      }
      open={true}
      onCancel={onClose}
      onOk={onOk}
      buttons={[]}
      width={1000}
      className={styles.EditModal}
    >
      <img width={"100%"} height={"100%"} alt="" src={`${settings.getViewAssetsPath("edit_popup.png")}`} />
    </AModal>
  );
}
