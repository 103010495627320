import React from "react";
import { Root, createRoot } from "react-dom/client";

interface RenderMessageProps {
  onOk?: () => void;
  onClose?: () => void;
  [key: string]: any;
}

export class Popup {
  private POPUP_MODAL_ID = "popup-modal-container";
  private root: Root | null = null;

  constructor(private component: React.FC<any>) {
    let container = document.getElementById(this.POPUP_MODAL_ID);
    if (container === null) {
      container = document.createElement("div");
      container.setAttribute("id", this.POPUP_MODAL_ID);
      document.body.appendChild(container);
    }
    this.root = createRoot(container);
  }

  destroy() {
    this.root?.unmount();
  }

  open<T extends RenderMessageProps>(props?: T) {
    const element = React.createElement(this.component, {
      onClose: () => this.destroy(),
      onOk: () => this.destroy(),
      ...props,
    });
    this.root?.render(element);
  }
}
