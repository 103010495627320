import { get, lowerCase, upperFirst } from "lodash";
import { Node } from "reactflow";
import { defaultNodeLabelMapping } from "./nodeConfig/defaultNodeLabelMapping";

export class NodeTitleUtil {
  /**
   * nodeTitle 优先级依次 inputTitle, generateLabel, defaultLabel
   * @param node
   * @returns node title
   */
  static getNodeTitle(node: Node<any>): string {
    const inputTitle = get(node, "data.inputTitle");
    const generatedLabel = get(node, "data.generatedLabel");

    const displayType = get(node, "data.displayType");
    const displayTypeTitle = upperFirst(lowerCase(displayType));
    const defaultLabel = get(defaultNodeLabelMapping, displayType, displayTypeTitle);

    return inputTitle || generatedLabel || defaultLabel;
  }

  /**
   * 对 输出 title 进行处理
   * @param title
   * @returns
   */
  static outputTitle(title: string = ""): string {
    return title?.replace(/-.*$/, "");
  }
}
