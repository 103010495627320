"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmazonShoppingFunction = void 0;
const Shopping_1 = require("../../api/shoppingTyped/Shopping");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const SearchInfoWrapper_1 = require("../../storyV2/model/SearchInfoWrapper");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
class AmazonShoppingFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo, variableList = [], page = 1) {
        const streamResponse = await this.socketRestClient.requestRest(topic => {
            return this.brainClient.openAi.getAmazonShoppingInfoBase(input, page, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo), topic);
        }, result => {
            const res = result.data?.data;
            console.log("data: ", result, res);
            const querys = res["querys"];
            let items = [];
            for (const query of querys) {
                const data = JsonUtil_1.JsonUtil.toModelFromType(Shopping_1.ShoppingData, res[query]);
                items.push(...(data?.items ?? []));
            }
            const pagination = new SearchInfoWrapper_1.SearchInfoPagination(page);
            const searchInfoWrapper = new SearchInfoWrapper_1.SearchInfoWrapper(input, pagination, items);
            return new FunctionBase_1.FuncDataSource(FunctionBase_1.FuncDataSourceType.AMAZON_SHOPPING, searchInfoWrapper);
        }, "amazonShopping", identityInfo);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.FUNCTION_V2, streamResponse);
    }
}
exports.AmazonShoppingFunction = AmazonShoppingFunction;
