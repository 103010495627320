"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class CTheme {
}
exports.CTheme = CTheme;
CTheme.theme = {
    chatAiTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.chatAiTheme,
        chatBackground: "linear-gradient(180deg, #E0E5E8 0%, #E0E5E8 100%)",
        chatBackgroundPad: "linear-gradient(180deg, #E0E5E8 0%, #E0E5E8 100%)",
        chatBackgroundMobile: "linear-gradient(180deg, #E0E5E8 0%, #E0E5E8 100%)",
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBarBorderColor: "transparent",
        hightLightBorderLeft: "0",
        inputPaddingLeft: "25px",
        inputPadding: "15px 25px",
        inputBorderRadius: "30px",
        inputBorder: "1px solid rgba(255, 255, 255, 0.35)",
        inputBorderBottom: "1px solid rgba(255, 255, 255, 0.35)",
        inputBoxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.10)",
        inputBackground: "rgba(255, 255, 255, 0.35)",
        buttonColor: "#fff",
        buttonBackground: "#000101",
        toolTipBackground: "#87DCFB",
        hintTextColor: "#12192B",
        tooltipBoxColor: "#12192B",
        valueColor: "#12192B",
        seeFormatDescriptionColor: "#12192B",
        toolTipBoxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.04)",
    },
};
