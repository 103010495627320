"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VBDataC1 = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
class VBDataC1 extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.header_text = "";
        this.description_text = "";
        this.image = "";
    }
}
exports.VBDataC1 = VBDataC1;
