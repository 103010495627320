import { CanvasNodeType } from "@uikit/store/CreatorNodesStore";
import { isEmpty } from "lodash";

export class OpenAiParamUtil {
  static replaceInputAndCustomVars(params: {
    variableList: any[];
    nodes: CanvasNodeType[];
    prompt: string;
    nodeText: string;
    setVariableList: any;
    isNode?: boolean;
  }): string {
    const inputTestReg = /<input>/gi;
    const { variableList, nodes, nodeText, isNode = false, setVariableList } = params;
    let { prompt } = params;

    const variableRegs = variableList.map(x => {
      const currentNode = nodes.find(y => y.id === x.node.id);
      const currentNodeTextAreaValue = currentNode?.data?.textAreaValue;
      let recentValue = x.node.data.textAreaValue;

      if (!isEmpty(currentNodeTextAreaValue)) {
        recentValue = currentNodeTextAreaValue;
        // 更新variableList保存的textAreaValue
        const tempVariableList = [...variableList];
        const currVarIdx = tempVariableList.findIndex(y => y.name === x.name);
        tempVariableList.splice(currVarIdx, 1, {
          name: x.name,
          node: {
            ...x.node,
            data: {
              ...x.node.data,
              textAreaValue: currentNodeTextAreaValue,
            },
          },
        });
        setVariableList([...tempVariableList]);
      }

      return {
        reg: new RegExp(`<${x.name}>`, "g"),
        value: recentValue || "",
      };
    });

    // 匹配自定义变量并替换值
    if (!isEmpty(variableRegs)) {
      variableRegs.forEach(x => {
        prompt = prompt.replace(x.reg, x.value);
      });
    }

    // 替换<input>变量
    if (inputTestReg.test(prompt)) {
      prompt = prompt.replace(inputTestReg, nodeText);
    } else {
      if (isNode) {
        prompt = `${prompt}${nodeText}`;
      }
    }
    return prompt;
  }

  static arrayKeyToObject(keys: string[]): Record<string, any> {
    if (!isEmpty(keys) && Array.isArray(keys)) {
      return Object.fromEntries(keys.map(key => [key, key]));
    }
    return {};
  }
}
