"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultTheme = void 0;
class DefaultTheme {
}
exports.DefaultTheme = DefaultTheme;
DefaultTheme.theme = {
    saasAiAppTheme: {
        background: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/aiSaasBackground2x.png')",
        saasTitleColor: "#12192B",
        inputBoxBgc: "#fff",
        inputBoxBottom: "#fff",
        inputBoxEnterBgc: "rgba(2, 12, 38, 0.05)",
        footerBrainStudiosColor: "rgba(18, 25, 43, 0.65)",
        topSuctionBgc: "rgba(255, 255, 255, 0.65)",
        topSuctionBorder: "rgba(255, 255, 255, 0.65)",
        submitButtonColor: "#12192B",
        submitButtonBgClr: "#fff",
        homeIconBgcolor: "#fff",
        homeInconFilter: "none",
        rightTopNumberBg: "#12192B",
        botIndexBg: "rgba(10, 157, 255, 0.25)",
        askLinkBorder: "rgba(2, 12, 38, 0.05)",
        inputTitleColor: "#020C26",
        saasTitleDisableColor: "#000",
        aiLogoBrightColor: "#000",
        addContentColor: "#12192B",
        priceTextColor: "#12192B",
        shareFooterUrlInfo: "rgba(18, 25, 43, 0.35)",
        shareFooterUrlColor: "rgba(18, 25, 43, 0.65)",
        priceBtnBg: "#fff",
        priceDesColor: "rgba(18, 25, 43, 0.65)",
        tooltipBgColor: "#12192B",
        tooltipColor: "#fff",
    },
    nodeTheme: {
        nodeWrapperBgColor: "#fff",
        addContentColor: "#12192B",
        textColor: "rgba(2, 12, 38, 0.35)",
        contentBg: "rgba(2, 12, 38, 0.05)",
        messageColor: "#12192B",
        errorColor: "#B92C2C",
        useNodeContentBg: "#0A9DFF40",
        handleBtnBorder: "rgba(2, 12, 38, 0.10)",
        handleBtnTextColor: "rgba(18, 25, 43, 0.65)",
        chatInputBorder: "#020C260D",
        chatInputTextColor: "#12192B",
        chatInputTextPlaceholder: "rgba(2, 12, 38, 0.35)",
        svgColor: "#020C26",
        svgOpacity: 0.05,
        audioFileNameColor: "#12192b",
        audioCurrentTimeColor: "#12192b",
        audioTotalTimeColor: "rgba(2, 12, 38, 0.35)",
        audioVoiceBrightness: "brightness(0)",
        pdfPreviewBg: "#fff",
        pdfPreviewItem: "transprent",
        pdfPreviewScrollBarColor: "#E7E8EA",
        xlsxThTextColor: "#12192b",
        xlsxScrollBorder: "#eeeeee",
        xlsxScrollBg: "rgba(255, 255, 255, 0.2)",
        xlsxThBg: "#eeeeee",
        xlsxTdBorder: "#dadde1",
        xlsxScrollbarTrack: "#eeeeee",
        dragDropTitle: "#12192b",
        dragDropDes: "rgba(18, 25, 43, 0.65)",
        uploadDes: "rgba(2, 12, 38, .45)",
        uploadLimitColor: "rgba(2, 12, 38, .45)",
        uploadingWrapBg: "#fff",
        uploadingFilename: "#12192b",
        uploadingFilesize: "rgba(2, 12, 38, 0.35)",
        uploadingError: "#ff335c",
        uploadingSuccess: "#0a9dff",
        textboxBg: "#fff",
        textboxColor: "#12192b",
        imageBg: "#fff",
        imageColor: "#12192b",
        codeBg: "#fff",
        codeColor: "#12192b",
        codeFooterBg: "#fff",
        codeFooterColor: "#12192b",
        stockBg: "#fff",
        stockColor: "#12192b",
        codeEditorBg: "#f4f4f4",
        rightBtnBg: "#5182ff",
        stockTradeBtnBg: "#0a9dff",
        stockTradeBtnBoxShadow: "0px 8px 16px 2px rgba(10, 157, 255, 0.15)",
        stockCancelBtnBg: "#fff",
        stockCancelBtnColor: "#0495f5",
        stockCancelBtnBorder: "1px solid #0495f5",
        stockNamePrice: "#22232f",
        askBrainTextColor: "#12192b",
        nodeWrapperBorder: "0.5px solid rgba(255, 255, 255, 0.35)",
        imgTextColor: "#12192b",
        askImagicaDotBackground: "rgba(10, 157, 255, 0.25)",
        askImagicaDotTextColor: "#12192b",
        nodeBackdropFilter: "none",
        videoKeywordContentBg: "#fff",
        videoKeywordContentColor: "#12192b",
        videoItemContentColor: "#12192b",
        videoItemSpanColor: "rgba(18, 25, 43, 0.65)",
        videoPaginationBg: "#F3F3F4",
        videoShowMoreColor: "#ACAEB5",
        videoItemDurationBg: "#ACAEB5",
        videoSelectedItemBg: "#E7E8EA",
        videoPaginationIconDisabledColor: "#ACAEB5",
        shoppingSearchIconBrightness: "brightness(0)",
        shoppingSearchBarInputColor: "rgba(2, 12, 38, 0.35)",
        shoppingSearchBarBorderColor: "rgba(2, 12, 38, 0.05)",
        shoppingItemBg: "#fff",
        shoppingItemInfoColor: "rgba(2, 12, 38, 0.35)",
        shoppingItemInfoSpanColor: "#12192b",
        shoppingNoResultIcon: "url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/noResultLightIcon.jpg')",
        shoppingNoResultTitleColor: "#12192b",
        shoppingNoResultDesColor: "rgba(18, 25, 43, 0.65)",
        viewBuilderButtonBackground: "rgba(244, 245, 246, 1)",
        viewBuilderButtonTextColor: "rgba(18, 25, 43, 0.65)",
        ViewBuilderL1DividerColor: "rgba(18, 25, 43, 0.1)",
        viewBuilderButtonHoverBackground: "rgba(2, 12, 38, 0.05)",
        viewBuilderButtonHoverTextColor: "rgba(18, 25, 43, 1)",
        viewBuilderButtonHoverBorderColor: "rgba(18, 25, 43, 0.1)",
        viewBuilderButtonClickBackground: "rgba(232, 232, 232, 1)",
        viewBuilderButtonClickBorderColor: "rgba(2, 12, 38, 0.35)",
        toolbarBg: "rgb(80, 84, 96)",
        toolbarIcon: "#fff",
        talkBuilderBg: "#12192b",
        talkBuilderText: "#fff",
    },
    appSignUpButtonTheme: {
        appSignUpHeight: "50px",
        appSignUpPadding: "10px 25px",
        appSignUpBorderRadius: "50px",
        appSignUpBackgroundColor: "#12192b",
        appSignUpTextColor: "#fff",
        appSignUpFontFamily: "'Ak-Regular'",
        appSignUpFontWeight: 500,
        appSignUpFontSize: "20px",
        appSignUpLineHeight: "30px",
        appSignUpPositionTop: "0",
        appSignUpPositionRight: "0",
    },
};
