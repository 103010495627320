import lottie from "lottie-web";
import { useEffect, useRef } from "react";

const LottieAnimation = ({ loader, ...rest }) => {
  const lottieRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    if (lottieRef.current) {
      animationRef.current = lottie.loadAnimation({
        animationData: loader,
        container: lottieRef.current,
      });

      return () => {
        animationRef.current.destroy();
      };
    }
  }, [loader, lottieRef]);

  return <div ref={lottieRef} {...rest} />;
};

export { LottieAnimation };
