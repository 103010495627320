import "../components-css/Image.css";
import func from "@uikit/func";
import { useSelector } from "react-redux";
import { ImageLoading } from "imagica-uikit/dist/components/ImageLoading/ImageLoading";
import { get } from "lodash";

function unserialize(value) {
  try {
    const obj = JSON.parse(value);

    if (typeof obj === "string") {
      return obj;
    }
    // 兼容 askBrain
    if (typeof obj === "object") {
      return obj?.answer || "";
    }
    // 其他类型不处理
    return "unsupported value";
  } catch {
    return value;
  }
}

function Image(props) {
  const compatibleValue = props?.content?.value || props?.content;
  const loading = get(props, "content.loading");

  const isShare = useSelector(state => state.fot.isShare);
  const shareClass = isShare ? "share" : "";
  return (
    <section className={`CardHomeImage ${props.className} ${shareClass}`}>
      {typeof compatibleValue === "string" ? (
        <img src={compatibleValue} onLoad={props.handleImageLoaded} alt="" />
      ) : null}
      <ImageLoading loading={loading} style={{ position: "unset" }} />
      {props.isCaptionToggle === true && !func.isEmpty(props?.content?.context) ? (
        <div className="image-describe">{unserialize(props.content.context)}</div>
      ) : null}
    </section>
  );
}

export default Image;
