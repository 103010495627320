import useIntroTooltip from "@custom-hooks/useIntroTooltip";
import { CreatorCanvasFocus } from "@uikit/cases/canvasFocus/CreatorCanvasFocus";
import { getIt } from "@uikit/getIt";
import { previewStore } from "@uiview/store/PreviewStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

// eslint-disable-next-line
export function useFitCanvas() {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorCanvasFocus = getIt(CreatorCanvasFocus);

  const firstNode = creatorNodesStore.getNodes()[0];
  const isAiChatOfNode = firstNode?.data?.chatAi || firstNode?.data?.showChatInterfaceTooltip;
  const { introTooltipObj } = useIntroTooltip(
    firstNode?.id,
    firstNode?.data?.displayType,
    undefined,
    undefined,
    isAiChatOfNode
  );

  /**
   * 1. 当节点过多时使用原来逻辑
   * 2. 当节点多少时(只有一条边,或一个节点) 执行100% focus 操作,但是需要在可执行的区域内
   */
  const fitCanvas = (duration = 200): void => {
    creatorCanvasFocus.autoFit({
      duration,
      showPreviewPanel: previewStore.state.showPreviewPanel,
      showIntroTooltipObj: introTooltipObj.show,
    });
  };
  return { fitCanvas };
}
