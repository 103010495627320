import { Button, Popover, Spin } from "antd";
import css from "../PopoverButton.module.scss";
import cssSave from "./SaveButton.module.scss";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { NavBarStore } from "../NavBarStore";
import { useSelector } from "react-redux";
import store, { RootState } from "@store/index";
import { ShareProjectIcon } from "../icons/ShareProjectIcon";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import func from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import { useCallback, useEffect } from "react";
import isBlank from "@sedan-utils/is-blank";
import { useSignal } from "@preact/signals-react";

const homeStore = getIt(HomeStore);

export function SaveButton(props: {
  handleSave: (param?: any) => any;
  saveProject: (source: any, projectName?: string) => Promise<void>;
  disable?: boolean;
}): JSX.Element {
  const navBarStore = getIt(NavBarStore);
  const homeStoreState = useImagicaStore(homeStore).value;
  const navBarState = useImagicaStore(navBarStore).value;

  const selectedTemplate = useSelector((state: RootState) => state.studio.selectedTemplate) as any;
  const loading = homeStoreState.homeNavSaveLoading;

  // FIXME: selectedTemplate.updateAt 可能在接口还没增加
  // HomeUtil.getObjectData

  const generateShareLink = useCallback(async () => {
    if (homeStoreState.homeNavSaveLoading) return;

    const userMe = store.getState().editor.me as any;

    try {
      navBarStore.setShareLoading(true);
      const data = await props.handleSave({ background: true });

      const url = `${window.location.origin}${window.location.pathname}#/editor`;
      const encodeProjectName = encodeURIComponent(selectedTemplate.name);
      const link = `${url}/shared_canvas/?projectId=${
        data?.updated_project?.id || selectedTemplate.id
      }&projectName=${encodeProjectName}&author=${userMe.id}`;
      navigator.clipboard.writeText(link);

      // showShareProjectTip();
      func.customMsg({
        content: "Link copied to clipboard",
        type: "info",
      });

      navBarStore.setShareLoading(false);
      logEvent("share_project", { URL: link });
    } catch (error) {
      console.error(error);
      navBarStore.setShareLoading(false);
    }
  }, [homeStoreState.homeNavSaveLoading, props.handleSave, selectedTemplate.id, selectedTemplate.name]);

  const onSave = useCallback(() => {
    if (homeStoreState.homeNavSaveLoading) return;
    props.saveProject("save button");
  }, [props.saveProject]);

  function PopoverContent(): JSX.Element {
    const savedTime = useSignal("");
    useEffect(() => {
      savedTime.value =
        !isBlank(selectedTemplate.updatedAt) &&
        (!isBlank(selectedTemplate.attrIndexer) || !isBlank(selectedTemplate.relationId))
          ? new Date(selectedTemplate.updatedAt).toLocaleString()
          : "";
    }, [selectedTemplate.updatedAt, selectedTemplate.attrIndexer, selectedTemplate.relationId]);
    return (
      <div style={{ display: !props.disable ? "block" : "none" }} className={css.popoverContent}>
        <div className={css.popoverHead}>
          <div className={css.popverHeadTitle}>Save</div>

          <div className={css.popverHeadDetailsBox}>
            <div>{isBlank(savedTime.value) ? "Not saved yet." : "Autosaved at"}</div>
            <div>{isBlank(savedTime.value) ? "" : savedTime.value}</div>
          </div>
        </div>

        <div className={css.popoverActionList}>
          <Button
            data-testid="NavBarShare"
            icon={<ShareProjectIcon />}
            className={css.actionItem}
            type="text"
            loading={navBarState.shareLoading}
            disabled={navBarState.shareLoading}
            onMouseDown={generateShareLink}
          >
            <span className={css.actionLabel}>Share Project</span>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Popover
      content={PopoverContent}
      placement="bottom"
      showArrow={false}
      getPopupContainer={e => e}
      overlayClassName={classNames(
        css.popoverWrap,
        cssSave.savePopoverWrap,
        !props.disable ? css.showSaveBtn : css.hideSaveBtn
      )}
      // open
    >
      <div className={css.popoverTrigger} data-testid="NavbarSaveButton">
        <Button
          type="text"
          className={classNames(css.triggerButton, cssSave.triggerButton)}
          disabled={loading || props.disable}
          // loading={loading}
          onMouseDown={onSave}
        >
          Save
          {loading && (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 18, marginLeft: 10 }} spin />} spinning={loading} />
          )}
        </Button>
      </div>
    </Popover>
  );
}
