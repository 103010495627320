import { get } from "lodash";
import { useSelector, useStore } from "react-redux";
import func from "@uikit/func";
import { useCallback } from "react";

/**
 * @deprecated 使用 `homeStore featureTags` 代替 `useFeatureTags`
 * @returns
 */
const useFeatureTags = () => {
  const store = useStore();
  const userMe = useSelector(state => state.editor.me);

  const getFeatureTagResult = useCallback(
    tag => {
      return () => get(store.getState().editor.me, "feature_tags", []).includes(tag) === false;
    },
    [store.getState().editor.me.feature_tags?.length]
  );

  // 返回函数使得外部调用最新值
  const enableV2TransformV3Data = getFeatureTagResult("disable_v2_transform_v3_project");
  const staticPermissons = getPermissions(userMe);

  return {
    ...staticPermissons,
    enableV2TransformV3Data,
  };
};

export function getPermissions(userMe) {
  function handleFeatureTags(key) {
    // userMe 为空，默认禁用所有
    if (func.isEmpty(userMe)) return false;
    // 判定包含就禁用，不包含就显示
    return get(userMe, "feature_tags", []).includes(key) === false;
  }

  function handleFeatureTagsV2(key) {
    // userMe 为空，默认禁用所有
    if (func.isEmpty(userMe) || userMe.is_guest) return false;
    // 判定包含就禁用，不包含就显示
    return get(userMe, "feature_tags", []).includes(key) === false;
  }

  function handleShowTag(key) {
    return get(userMe, "feature_tags", []).every(x => x !== key);
  }

  const defaultIsFalse = featureTag => {
    return get(userMe, "feature_tags", []).includes(featureTag);
  };

  const disableCallerBot = defaultIsFalse("disable_caller_bot");
  const disableMultiRoundChatOnboarding = defaultIsFalse("disable_multi_round_chat_onboarding");
  const showEdgeBluePrint = handleShowTag("disable_edge_blue_print");
  const showDesignSpaceMenu = handleShowTag("disable_design_space_menu");
  const showSuggestionFunctions = handleFeatureTagsV2("disable_suggestion_functions");
  const showSubscriptionFeaturesToggle = handleShowTag("disable_studios_subscription");
  const showStudiosAgent = handleShowTag("disable_studios_agent");
  const showProtein = handleShowTag("disable_studios_protein");
  const showMap = handleShowTag("disable_studios_map");
  const showUserFeedback = handleShowTag("disable_user_feedback");
  const showCotUserFeedback = handleShowTag("disable_cot_user_feedback");
  const showBuyButton = handleFeatureTagsV2("disable_buy_button");
  const showInterviewBot = handleShowTag("disable_interview_bot");

  const enableSkipEmptyVerification = handleFeatureTags("disable_skip_empty_verification");
  const enable_saas_web = get(userMe, "feature_tags", []).includes("disable_saas_web") === false;
  const enableFood = handleFeatureTags("disable_food");
  const enableMidjourneyV2 = handleFeatureTags("disable_studios_midjourney_v2");
  const enableStaticMode = handleFeatureTags("disable_studios_static_mode");
  const enableStudiosCustomJsFunctionChatBox = handleFeatureTags("disable_studios_customJSFunction_chatBox");
  const enableGlobalContext = handleFeatureTags("disable_global_context");
  const disableCotOnboarding = get(userMe, "feature_tags", []).includes("disable_cot_onboarding");
  const disableManualOnboarding = defaultIsFalse("disable_manual_onboarding");
  const disableToolBarPublish = defaultIsFalse("disable_toolBar_publish");

  const disableUploadTypesAsInputNode = defaultIsFalse("disable_upload_types_as_input_node");

  const disableTriggerManualOnboardingInCanvas = defaultIsFalse("disable_trigger_manual_onboarding_in_canvas");

  const disableTriggerCotOnboardingInCanvas = defaultIsFalse("disable_trigger_cot_onboarding_in_canvas");

  const disableCotFlowInCanvas = defaultIsFalse("disable_cot_flow_in_canvas");
  const disableMobilePage = defaultIsFalse("disable_mobile_page");

  const showExpFeatures = handleShowTag("disable_studios_exp_features");

  const enableShrinkAndExpand = handleFeatureTags("disable_shrinkAndExpand");
  const enableGlobalFunctionPublish = handleFeatureTags("disable_global_function_publish");
  /** 是否启用 chat ai 流程 */
  const enableChatAiFlow = handleFeatureTags("disable_chat_ai_flow_in_canvas");
  // /** 是否启用 publish v3 流程 */
  const enablePublishV3 = handleFeatureTags("disable_publish_v3");

  const enableCotTemplateV2 = handleFeatureTags("disable_studios_cot_template_v2");
  const enableGroupCollapse = handleFeatureTags("disable_group_collapse");
  const enableStudiosPaywall = handleFeatureTags("disable_studios_paywall");

  return {
    showEdgeBluePrint,
    showDesignSpaceMenu,
    showSubscriptionFeaturesToggle,
    showStudiosAgent,
    showProtein,
    showMap,
    showUserFeedback,
    showCotUserFeedback,
    showBuyButton,
    showSuggestionFunctions,

    enableSkipEmptyVerification,
    enable_saas_web,
    enableFood,
    enableMidjourneyV2,
    enableStaticMode,
    enableStudiosCustomJsFunctionChatBox,
    disableCotOnboarding,
    disableManualOnboarding,
    disableToolBarPublish,
    disableUploadTypesAsInputNode,
    showExpFeatures,
    enableShrinkAndExpand,
    enableGlobalContext,
    enableGlobalFunctionPublish,
    disableTriggerManualOnboardingInCanvas,
    disableTriggerCotOnboardingInCanvas,
    disableCotFlowInCanvas,
    enableChatAiFlow,
    enablePublishV3,
    enableCotTemplateV2,
    enableGroupCollapse,
    enableStudiosPaywall,
    disableMobilePage,
    showInterviewBot,
    disableCallerBot,
    disableMultiRoundChatOnboarding,
  };
}

export default useFeatureTags;
