import { Type } from "class-transformer";
import { ComponentKey } from "./GenUIBloc";

export class ProtypingMessage {
  @Type(() => ProtypingComponent)
  public components: ProtypingComponent[] = [];
}

export class ProtypingComponent {
  public id: string = "";
  public type: string = "input";
  public name: string = "";
  public description: string = "";
  public key: ComponentKey = "input";
  @Type(() => ProtypingComponentData)
  public data: ProtypingComponentData[] = [];
}

export class ProtypingComponentData {
  public name: string = "";
  public description: string = "";
  @Type(() => ProtypingComponentParameters)
  public parameters: ProtypingComponentParameters = new ProtypingComponentParameters();
}

export class ProtypingComponentParameters {
  public search_topic?: string;
  public current_date?: string;
}
