import { PreviewTitle } from "./PreviewTitle";
import { useAiSaasInputContainer } from "../container";
import isBlank from "@sedan-utils/is-blank";
import css from "./index.module.scss";
import { ProjectNodeUtil } from "@uikit/projectNode/ProjectNodeUtil";
import { useProjectNode } from "@uikit/projectNode/useProjectNode";

export function InputTitle(props: AISaas.AISaasInputItemTitleProps): React.ReactNode {
  const { x } = props;
  const { id: itemId } = x.data;
  const projectNode = useProjectNode(x.data.id);

  const {
    aiSaasProps,
    inputTitleId,
    showInputTitleId,
    onInputTitleBlur,
    onInputTitleEnter,
    onClickInputTitle,
    onSaasInputTitleChange,
  } = useAiSaasInputContainer();

  // const showPreviewTittle = !isBlank(x?.data?.inputTitle) || !isBlank(x?.data?.generatedLabel) || !aiSaasProps.isShare;
  // stud-2493 get input title(preview)
  const showPreviewTittle = !isBlank(ProjectNodeUtil.getTitle(projectNode)) || !aiSaasProps.isShare;

  if (!showPreviewTittle) {
    return null;
  }

  return (
    <div className={`${css["input-title-box"]}`}>
      <PreviewTitle
        id={itemId}
        // stud-2493 get input title(preview)
        title={projectNode?.inputTitle || ""}
        isShare={aiSaasProps.isShare}
        inputTitleId={inputTitleId.value}
        onInputTitleBlur={id => {
          onInputTitleBlur(id);
        }}
        onInputTitleEnter={onInputTitleEnter}
        onSaasInputTitleChange={onSaasInputTitleChange}
        onClickEdit={onClickInputTitle}
        showInputTitleId={showInputTitleId.value}
        saasUIInputData={x}
      />
    </div>
  );
}
