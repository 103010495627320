import { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import { Nodes } from "../../../Nodes/Nodes";
import { useAiSaasInputContainer } from "../container";
import css from "./index.module.scss";
import { useRenderData } from "../../hooks/useRenderData";
import { useSignal } from "@preact/signals-react";
import { isEqual } from "lodash";
import { logEvent } from "@uikit/service/amplitude";

export function InputMain(props: AISaas.AISaasInputItemMainProps): React.JSX.Element {
  const { x, index, isUploadFileInputNode, isEditPlaceholder } = props;
  const { id: itemId } = x.data;
  const placeholderRef = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState(x?.data?.data?.textAreaValue || "");
  useEffect(() => {
    setInputValue(x?.data?.data?.textAreaValue || "");
  }, [x?.data?.data?.textAreaValue]);

  const {
    saasUIData,
    inputListRef,
    onInputEnter,
    onMouseLeave,
    onInputBlur,
    onInputFocus,
    onMouseOver,
    editPlaceholderData,
    onSaasInputChange,
    getByPlaceholderText,
    onSaasPlaceholderChange,
    onPlaceholderBlurEnter,
    onPlaceholderBlurClean,
  } = useAiSaasInputContainer();

  const renderData = useRenderData({ type: "inputValue", id: itemId });
  // 开始编辑时的placeholder，用于编辑结束时对比placeholder是否变化(暂用于Amplitude判断是否修改了placeholder)
  const startEditingPlaceholder = useSignal("");

  useEffect(() => {
    if (placeholderRef.current) {
      placeholderRef.current.focus();
      startEditingPlaceholder.value = getPlaceholder();
    }
  }, [placeholderRef, isEditPlaceholder]);

  if (isUploadFileInputNode) {
    return (
      <Nodes
        style={renderData?.style}
        {...x.data}
        {...x.data.data}
        isInCanvas={false}
        canvasType={"saas"}
        asInputNodeChange={(value: any) => {
          onSaasInputChange({ target: { value } }, itemId);
        }}
      />
    );
  }

  const getPlaceholder = (): string => {
    return (
      (editPlaceholderData.value.id === itemId
        ? editPlaceholderData.value.placeholder
        : getByPlaceholderText(x, index, false)) ?? ""
    );
  };

  const handleEditPlaceholderEnter = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    onPlaceholderBlurEnter(e);

    const old_text = startEditingPlaceholder.value;
    const new_text = getPlaceholder();
    if (isEqual(old_text, new_text)) {
      return;
    }

    logEvent("click_edit_preview_text", { target: "input_placeholder", new_text, old_text });
  };

  return (
    <>
      {isEditPlaceholder ? (
        <Input.TextArea
          style={renderData?.style}
          autoFocus
          ref={placeholderRef}
          placeholder={
            editPlaceholderData.value.id === itemId
              ? editPlaceholderData.value.placeholder
              : getByPlaceholderText(x, index)
          }
          className={`${css["saas-placeholder"]} ${css["saas-input"]}`}
          autoSize={{ minRows: 1, maxRows: 10 }}
          bordered={false}
          value={
            editPlaceholderData.value.id === itemId
              ? editPlaceholderData.value.placeholder
              : getByPlaceholderText(x, index, false)
          }
          onChange={(e: any) => {
            onSaasPlaceholderChange(e, itemId);
          }}
          onPressEnter={e => handleEditPlaceholderEnter(e)}
          onBlur={() => onPlaceholderBlurClean(itemId)}
        />
      ) : (
        <Input.TextArea
          style={renderData?.style}
          ref={(r: any) =>
            (inputListRef.current[index] = {
              refKey: itemId,
              refValue: r,
            })
          }
          // ref={index === 0 ? inputTextArea : undefined}
          placeholder={getByPlaceholderText(x, index)}
          className={`${css["saas-input"]} ${isEditPlaceholder && css["disappears"]}`}
          autoSize={{ minRows: 1, maxRows: 10 }}
          bordered={false}
          value={inputValue}
          disabled={saasUIData.staticMode}
          onChange={(e: any) => {
            setInputValue(e.target.value);
            onSaasInputChange(e, itemId);
          }}
          // allowClear
          onMouseLeave={onMouseLeave}
          onMouseOver={() => onMouseOver(itemId)}
          // onPressEnter={(e) => e.preventDefault()}
          onPressEnter={(e: React.FormEvent<HTMLTextAreaElement>) => onInputEnter(e)}
          onBlur={() => onInputBlur(itemId, index)}
          onFocus={() => onInputFocus(itemId, index)}
          onClick={() => {
            props.onInputFocus?.();
          }}
        />
      )}
    </>
  );
}
