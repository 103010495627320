import { PreviewSerialize } from "./PreviewSerialize";
import { ProjectModifiedTransfromUtil } from "./ProjectModifiedTransfromUtil";

export type CompareArgs<T, V extends PreviewApp.UISaasDataType = PreviewApp.UISaasDataType> = {
  compareSource: T;
  compareTarget: T;
  source: V;
  target: V;
};

export type ComparatorFields = Exclude<keyof typeof ProjectComparator, "getCompare" | "prototype">;

export type ComparatorHandler = (typeof ProjectComparator)[ComparatorFields];

function _compare(args: CompareArgs<any>): boolean {
  return PreviewSerialize.serialize(args.compareSource) === PreviewSerialize.serialize(args.compareTarget);
}

export class ProjectComparator {
  static getCompare<T extends ComparatorFields>(method: T): (typeof ProjectComparator)[T] | typeof _compare {
    if ((method as any) !== "getCompare") {
      return ProjectComparator[method] || _compare;
    }

    return _compare;
  }

  //====================  对应每个字段

  /**
   * FIXME: output 数据需要单独处理,因为发现其中的有些字段即使不使用也会被修改???
   * @param args
   * @returns
   */
  static output(args: CompareArgs<AISaasOutput.ThoughtType[]>): boolean {
    args.compareSource = ProjectModifiedTransfromUtil.transformOutput(args.compareSource);
    args.compareTarget = ProjectModifiedTransfromUtil.transformOutput(args.compareTarget);
    return _compare(args);
  }

  static subTitleStyle(args: CompareArgs<any>): boolean {
    args.compareSource = ProjectModifiedTransfromUtil.stringToObject(args.compareSource);
    args.compareTarget = ProjectModifiedTransfromUtil.stringToObject(args.compareTarget);
    return _compare(args);
  }

  static titleStyle(args: CompareArgs<any>): boolean {
    return ProjectComparator.subTitleStyle(args);
  }

  /**
   * FIXME: width,height会在没有任何操作的时候新增该字段, 如果是800或600，则当作没有被修改
   * @param args
   * @returns
   */
  static settings(args: CompareArgs<any>): boolean {
    args.compareSource = {
      ...args.compareSource,
      width: args.compareSource.width || 800,
      height: args.compareSource.height || 600,
    };
    args.compareTarget = {
      ...args.compareTarget,
      width: args.compareTarget.width || 800,
      height: args.compareTarget.height || 600,
    };
    return _compare(args);
  }

  /**
   * 比较 theme 字段
   * @param args
   * @returns
   */
  static theme(args: CompareArgs<string>): boolean {
    return _compare(args);
  }
}
