import React from "react";

import styles from "./ChargeOptions.module.scss";
import { ChargeOptionButton } from "./ChargeOptionButton";
import { Signal } from "@preact/signals-react";
import { ChargeOption } from "./ChargeYourCustomersBloc";
import { PoweredByIcon } from "./SvgIcon";
import { PowerMaskUitl } from "@uiview/views/PowerMask/PowerMaskUtil";
import { PowerMaskView } from "@uiview/views/PowerMask/PowerMaskView";
import { Switch } from "antd";

export interface Props {
  selected: Signal<ChargeOption>;
  loading: boolean;
  onClicked: (option: ChargeOption) => void;
}

export function ChargeOptions(props: Props) {
  return (
    <>
      <div className={styles.optionsPane}>
        <div className={styles.title}>Charge your customers</div>
        <div className={styles.desc}>Easily charge your users for the AI app you create with Imagica.</div>
      </div>
      <div className={styles.options}>
        <div className={styles.checkSwitch}>
          <Switch
            checked={props.selected.value === ChargeOption.SUBSCRIPTION}
            loading={props.loading}
            onChange={async checked => {
              if (await PowerMaskUitl.disAbleByFeature(["aaas_monetization"])) {
                return;
              }
              props.onClicked(checked ? ChargeOption.SUBSCRIPTION : ChargeOption.FREE);
            }}
          />
          <span className={styles.checkTip}>
            <label>Subscription: </label>Charge a monthly fee for your service
          </span>
        </div>
      </div>
      {/* <div className={styles.bottomToolbar}>
        <div className={styles.poweredBy}>
          <PoweredByIcon />
        </div>
        <div className={styles.termsLink}>
          <a href="https://brain.ai/tos/" target="_blank" rel="noreferrer">
            Terms
          </a>
        </div>
        <div className={styles.privacyLink}>
          <a href="https://brain.ai/privacy/" target="_blank" rel="noreferrer">
            Privacy
          </a>
        </div>
      </div> */}
    </>
  );
}
