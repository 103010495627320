import { useSignal } from "@preact/signals-react";
import func from "@uikit/func";
import { PowerMaskUitl } from "@uiview/views/PowerMask/PowerMaskUtil";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

export class EmbedBlocState {
  static defaultWidth = 800;
  static defaultHeight = 600;
  widthInput = useSignal(EmbedBlocState.defaultWidth);
  heightInput = useSignal(EmbedBlocState.defaultHeight);
  widthFocus = false;
  heightFocus = false;
}

export class EmbedBloc {
  constructor(public state: EmbedBlocState) {}

  async handleWidthInputChange(event: any) {
    if (await PowerMaskUitl.disAbleByFeature(["embedded_view"])) {
      return;
    }
    this.state.widthInput.value = event;
  }
  async handleHeightInputChange(event: any) {
    if (await PowerMaskUitl.disAbleByFeature(["embedded_view"])) {
      return;
    }
    this.state.heightInput.value = event;
  }

  async clickCopyText(txt: string) {
    if (await PowerMaskUitl.disAbleByFeature(["embedded_view"])) {
      return;
    }
    if (func.isEmpty(txt)) {
      func.customMsg({
        content: "Content is empty",
        type: "warning",
      });
      return;
    }
    navigator.clipboard.writeText(txt);
    func.customMsg({
      content: "Copied",
      type: "info",
    });
  }

  getLink(origLink: string): string {
    const parsedOrigLink = new URL(origLink);
    const domain = parsedOrigLink.host;
    const isShareDomain = settings.shareDomain.includes(domain);
    if (!isShareDomain) return origLink;
    // TODO: revisit during refactor
    const expandedLink = `${parsedOrigLink.origin}/fot/#/editor${parsedOrigLink.search}`;
    return expandedLink;
  }
}
