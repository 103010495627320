import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
// import { Header } from "./Header";
import { CustomizeBranding } from "./CustomizeBranding/CustomizeBranding";
import { Embed } from "./Embed/Embed";
import { Unpublish } from "./Unpublish/Unpublish";
// import { Delete } from "./Delete/Delete";
import { Appearance } from "./appearance/Appearance";
import { PreviewPaneStore, SettingPageContentType } from "../PreviewPaneStore";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { PreviewPaneBloc } from "../PreviewPaneBloc";
import { ChargeYourCustomers } from "./ChargeYourCustomers/ChargeYourCustomers";
import { DomainName } from "@uiview/views/PreviewPane/Settings/DomainName/DomainName";
import { HeaderV2 } from "./HeaderV2";
// import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { matchMobile } from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

/**
 * publish v3 setting页面容器
 * 这里面最后组合setting页面下每一个模块
 */

export function SettingPage(props: { bloc: PreviewPaneBloc }): JSX.Element {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const homeStoreState = useImagicaStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;

  const previewPaneStore = getIt(PreviewPaneStore);
  const previewPaneState = useStore(previewPaneStore).value;

  // const chatOnboardingStore = getIt(ChatOnboardingStore);

  // const chatObState = useImagicaStore(chatOnboardingStore).value;
  const isShowFeatures = previewPaneState.settingPageContentType !== SettingPageContentType.design;

  const settingPageClassName = classNames(styles.settingPage, {
    [styles.showSettingPage]: previewPaneState.settingPageStatus,
    [styles.settingPageMobile]: isMobile && featureTags.disableMobilePage,
    [styles.showSettingPageMobile]: previewPaneState.settingPageStatus && isMobile && featureTags.disableMobilePage,
  });

  return (
    <div className={settingPageClassName}>
      {/*{chatObState.isOpenChat ? <HeaderV2 /> : <Header />}*/}
      {!(isMobile && featureTags.disableMobilePage) && <HeaderV2 />}
      <section className={classNames({ [styles.featuresMobile]: isMobile && featureTags.disableMobilePage })}>
        {isShowFeatures && <ChargeYourCustomers />}
        {previewPaneState.settingPageContentType !== SettingPageContentType.features && (
          <Appearance bloc={props.bloc} />
        )}
        {isShowFeatures && (
          <>
            <CustomizeBranding />
            <DomainName bloc={props.bloc} showSettingPage={previewPaneState.settingPageStatus} />
            <Embed />
            <Unpublish />
            {/*<Delete />*/}
          </>
        )}
      </section>
    </div>
  );
}
