"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageGenFunction = void 0;
const Settings_1 = require("../../kernel/Settings");
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const ImageModel_1 = require("../../storyV2/model/ImageModel");
class ImageGenFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo) {
        const streamResponse = await this.socketRestClient.requestRest(topic => {
            return this.brainClient.openAi.stableai(input, undefined, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo), topic);
        }, restResponse => {
            const s3url = restResponse.data.s3_path || "";
            const url = `${Settings_1.settings.S3CDN}${encodeURI(s3url)}`;
            return new FunctionBase_1.FuncDataSource(FunctionBase_1.FuncDataSourceType.IMAGE_TEXT, JsonUtil_1.JsonUtil.stringify(new ImageModel_1.ImageModel(input, url, url)), true);
        }, "imageGen", identityInfo);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.IMAGE_TEXT, streamResponse);
    }
}
exports.ImageGenFunction = ImageGenFunction;
