interface Props {
  isActive: boolean;
}

export function SettingDesignIcon(props: Props) {
  const isActive = props.isActive;

  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 11C1.5 16.5228 5.97715 21 11.5 21C13.1569 21 14.5 19.6569 14.5 18V17.5C14.5 17.0356 14.5 16.8034 14.5257 16.6084C14.7029 15.2622 15.7622 14.2029 17.1084 14.0257C17.3034 14 17.5356 14 18 14H18.5C20.1569 14 21.5 12.6569 21.5 11C21.5 5.47715 17.0228 1 11.5 1C5.97715 1 1.5 5.47715 1.5 11Z"
        stroke={isActive ? "white" : "#020C26"}
        strokeOpacity={isActive ? "1" : "0.35"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 12C7.05228 12 7.5 11.5523 7.5 11C7.5 10.4477 7.05228 10 6.5 10C5.94772 10 5.5 10.4477 5.5 11C5.5 11.5523 5.94772 12 6.5 12Z"
        fill={isActive ? "white" : "#020C26"}
        fillOpacity={isActive ? "1" : "0.35"}
      />
      <path
        d="M15.5 8C16.0523 8 16.5 7.55228 16.5 7C16.5 6.44772 16.0523 6 15.5 6C14.9477 6 14.5 6.44772 14.5 7C14.5 7.55228 14.9477 8 15.5 8Z"
        fill={isActive ? "white" : "#020C26"}
        fillOpacity={isActive ? "1" : "0.35"}
      />
      <path
        d="M9.5 7C10.0523 7 10.5 6.55228 10.5 6C10.5 5.44772 10.0523 5 9.5 5C8.94772 5 8.5 5.44772 8.5 6C8.5 6.55228 8.94772 7 9.5 7Z"
        fill={isActive ? "white" : "#020C26"}
        fillOpacity={isActive ? "1" : "0.35"}
      />
      <path
        d="M6.5 12C7.05228 12 7.5 11.5523 7.5 11C7.5 10.4477 7.05228 10 6.5 10C5.94772 10 5.5 10.4477 5.5 11C5.5 11.5523 5.94772 12 6.5 12Z"
        stroke={isActive ? "white" : "#020C26"}
        strokeOpacity={isActive ? "1" : "0.35"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 8C16.0523 8 16.5 7.55228 16.5 7C16.5 6.44772 16.0523 6 15.5 6C14.9477 6 14.5 6.44772 14.5 7C14.5 7.55228 14.9477 8 15.5 8Z"
        stroke={isActive ? "white" : "#020C26"}
        strokeOpacity={isActive ? "1" : "0.35"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 7C10.0523 7 10.5 6.55228 10.5 6C10.5 5.44772 10.0523 5 9.5 5C8.94772 5 8.5 5.44772 8.5 6C8.5 6.55228 8.94772 7 9.5 7Z"
        stroke={isActive ? "white" : "#020C26"}
        strokeOpacity={isActive ? "1" : "0.35"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
