"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JsonHandler = void 0;
const LangUtil_1 = require("../../../../cutil/LangUtil");
const TransformServiceHandlerChain_1 = require("../chain/TransformServiceHandlerChain");
const JsonUtil_1 = require("../../../../cutil/JsonUtil");
const CustomApiTransformError_1 = require("../error/CustomApiTransformError");
const Extensions_1 = require("../../../../cutil/Extensions");
class JsonHandler extends TransformServiceHandlerChain_1.Handler {
    async process(request) {
        const rs = await (0, LangUtil_1.tryPromise)(request.text()); /// 需要尝试返回一个 string
        if (!rs.data) {
            throw CustomApiTransformError_1.CustomApiTransformError.default;
        }
        const json = JsonUtil_1.JsonUtil.parse(rs.data);
        if (json) {
            // json.data.response 有值，那么就取 这个字段
            const dataResponse = json.data?.response;
            request.setResult(!(0, Extensions_1.isBlank)(dataResponse) ? dataResponse : json);
        }
        else {
            request.setResult(rs.data || "");
        }
        return true;
    }
}
exports.JsonHandler = JsonHandler;
