import { NewEdgeData, OldEdgeData } from "@uikit/project/HomeUsePluginData";
import { CreatorNodesUtil } from "@uikit/util/CreatorNodesUtil";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { cloneDeep, isFunction } from "lodash";
import { Node } from "reactflow";

export type CanvasNodeData = {
  inputTitle?: string;

  [key: string]: any;
};

export type CanvasNodeType<T extends CanvasNodeData = CanvasNodeData> = Node<T>;

export class CreatorNodesStoreState {
  nodes: CanvasNodeType[] = [];
}

export type DispatchType<T> = T | ((prevState: T) => T);

export class CreatorNodesStore extends StoreBase<CreatorNodesStoreState> {
  lastState: CreatorNodesStoreState;

  constructor() {
    super(() => new CreatorNodesStoreState());
    this.lastState = new CreatorNodesStoreState();
  }

  setNodes = (value: DispatchType<CreatorNodesStoreState["nodes"]>): void => {
    const newState = new CreatorNodesStoreState();

    if (isFunction(value)) {
      newState.nodes = value(this.lastState.nodes);
      // if (!newState.nodes.length) {
      //   console.trace("empty");
      // }

      this.lastState.nodes = newState.nodes;
      this.emit(newState);
      return;
    }

    // if (!value.length) {
    //   console.trace("empty");
    // }

    newState.nodes = value;
    this.lastState.nodes = cloneDeep(value);
    this.emit(newState);
  };

  // !!! 为了保证调用getNodes的地方，this指向不会变
  getNodes = (): CanvasNodeType[] => {
    return this.state.nodes;
  };

  getOnlyNodes(): CanvasNodeType[] {
    return CreatorNodesUtil.getOnlyNodes(this.state.nodes);
  }

  getOnlyEdges(): CanvasNodeType[] {
    return this.state.nodes.filter(x => x.type === "customNewEdge");
  }

  getNodeById(id: string): CanvasNodeType | undefined {
    return this.state.nodes.find(item => item.id === id);
  }

  setQueryLoadingV3 = ({ id, data }: { id: string; data: Partial<NewEdgeData & OldEdgeData> }): void => {
    this.setNodes((prevList: any) => {
      return prevList.map((l: any) => {
        if (l.id === id) {
          return {
            ...l,
            data: {
              ...l.data,
              ...data,
            },
          };
        }
        return l;
      });
    });
  };
  // 保存targetNodeId到边的data中
  handleUpdateTarget({ newNode, edgeNode }: { newNode: any; edgeNode: any }): void {
    this.setNodes((prevList: any[]) => {
      const newList = prevList.map(x => {
        if (x.id === edgeNode.id) {
          return {
            ...x,
            data: {
              ...x.data,
              targetNodeId: newNode.id,
            },
          };
        }
        return x;
      });
      return [...newList, newNode];
    });
  }

  /**
   * 该方法用于更新 node 中 data 的数据，但是不会触发ui变化，是直接修改 state nodes 值
   *
   * @param id
   * @param source
   */
  updateDataWithNoEmit(id: string, source: Partial<CanvasNodeType["data"]>): void {
    this.state.nodes.forEach(node => {
      if (id === node.id) {
        // 不修改原来 数据引用，直接对data进行扩展
        node.data = Object.assign(node.data, source);
      }
    });

    // 为了 functions 调用
    this.lastState.nodes = this.state.nodes;
  }

  /**
   * 删除节点
   * @param id
   */
  removeById(id: string | string[]): void {
    // 将可能是组节点中的 childNode 移除
    this.setNodes(CreatorNodesUtil.removeNodesById(id, this.state.nodes));
  }
}
