import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { CreatorNodeNewPosition } from "@uikit/service/CreatorNodeNewPosition";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { logEvent } from "@uikit/service/amplitude";
import { CreatorCanvasFocus } from "@uikit/cases/canvasFocus/CreatorCanvasFocus";
import { RectType } from "@uikit/util/FocusNodeUtil";
import { MoveToHandlerResult } from "@uikit/cases/canvasFocus/FocusController";
import { PreviewStore } from "@uiview/store/PreviewStore";
import { AddNewNodeParam, CreateNewNodeParam } from "@uikit/project/HomeUsePluginData";
import { CanvasEdgeUtil } from "@uikit/util/CanvasEdgeUtil";
import { CanvasLineUtil } from "@uikit/util/CanvasLineUtil";
import { OFFSET_DISTANCE } from "@views/thinking-layout-editor/constants";
import { Node } from "reactflow";
interface NodeData {
  nodeId: string;
}
interface Data {
  chatAi?: boolean;
  displayType?: string;
  isEnableTooltipNode?: boolean;
}
export class AddNode {
  constructor(
    private creatorRefStore: CreatorRefStore,
    private homeMethods: HomeMethodsService,
    private creatorNodeNewPosition: CreatorNodeNewPosition,
    private canvasDataRef: CanvasDataRef,
    private creatorNodesStore: CreatorNodesStore,
    private creatorStoreMethods: CreatorStoreMethods,
    private creatorCanvasFocus: CreatorCanvasFocus,
    private previewStore: PreviewStore
  ) {}
  // 新增节点
  addNodeV2(e: React.MouseEvent<Element | HTMLImageElement | HTMLDivElement, MouseEvent>, data?: Data): NodeData {
    const newNodeId = `editor-${this.creatorRefStore.nodeIndexRef.current++}`;
    this.homeMethods.saveSecondInputNodeId(newNodeId);
    const position = this.creatorNodeNewPosition.getNewNodePosition(e);
    const newNode = {
      id: newNodeId,
      type: "customNode",
      data: {
        ...this.canvasDataRef.nodeDataRef.current,
        ...data,
      },
      position: {
        x: position.x,
        y: position.y,
      },
    };

    this.creatorNodesStore.setNodes(prevList => {
      return [...prevList, newNode];
    });
    this.creatorStoreMethods.onNodesEdgesAdd([this.canvasDataRef.getSimpleNode(newNode)], []);
    // to avoid reversed event order received on amplitude because of 2 events are triggered in parallel
    setTimeout(() => {
      logEvent("create_node", data);
    }, 50);

    // 有 todotip 的节点移动视口到合适的位置
    if (data?.isEnableTooltipNode) {
      setTimeout(() => {
        // 居中节点，但是需要减掉 todoTip 的高度
        this.focusLastNode({ height: -160 });
      }, 200);
    } else {
      // 聚焦节点
      setTimeout(() => {
        this.focusLastNode();
      }, 200);
    }

    return {
      nodeId: newNode.id,
    };
  }

  focusLastNode(extraRect?: RectType): Promise<MoveToHandlerResult> {
    return this.creatorCanvasFocus.focusLastNode({
      extraRect,
      showPreviewPanel: this.previewStore.state.showPreviewPanel,
    });
  }

  // 切分调用
  addSliceNode({ direction, prevNode, isStickie }: AddNewNodeParam): {
    newEdge: Node;
    sourceLine: any;
    targetLine: any;
  } {
    const newEdge = CanvasEdgeUtil.createNewEdge(prevNode);
    const newNode = this.createNode({
      direction,
      edgeNode: newEdge,
      isStickie,
    });
    const sourceLine = CanvasLineUtil.createNewLine({
      source: prevNode.id,
      target: newEdge.id,
      type: "source",
    });
    const targetLine = CanvasLineUtil.createNewLine({
      sourceNode: prevNode,
      source: newEdge.id,
      target: newNode.id,
      type: "target",
    });
    return {
      newEdge,
      sourceLine,
      targetLine,
    };
  }
  // 切分创建Node
  createNode({ edgeNode, isStickie }: CreateNewNodeParam): Node {
    const newNodeId = `editor-${this.creatorRefStore.nodeIndexRef.current++}`;
    const newNode = {
      id: newNodeId,
      type: "customNode",
      data: Object.assign(
        {},
        { ...this.canvasDataRef.nodeDataRef.current },
        {
          displayType: isStickie ? "inputTextarea" : "text",
          showFeedback: false,
        }
      ),
      position: {
        x: edgeNode.position.x + OFFSET_DISTANCE,
        y: edgeNode.position.y,
      },
    };
    this.creatorNodesStore.handleUpdateTarget({
      newNode,
      edgeNode,
    });
    return newNode;
  }
}
