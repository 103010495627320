import { FC, useCallback, useRef } from "react";
import style from "./style.module.scss";
import { callback } from "../../utilities";
import { Modal } from "antd";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

export interface VideoModalProps {
  isOpen: boolean;
  videoUrl: string;
  onOpenChange: (value: boolean) => void;
}

export const VideoModal: FC<VideoModalProps> = ({ isOpen, videoUrl, onOpenChange = callback }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const dispose = useCallback(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    video.pause();
    video.currentTime = 0;
  }, []);

  const handleCloseIconClick = useCallback(() => {
    dispose();
    onOpenChange(false);
  }, [dispose, onOpenChange]);

  return (
    <Modal
      title=""
      maskClosable={true}
      keyboard={false}
      open={isOpen}
      onCancel={handleCloseIconClick}
      wrapClassName={style["dialog-modal-box"]}
      closeIcon={<img src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`} width="30" height="30" />}
      footer={[]}
    >
      <video ref={videoRef} className={style.video} src={videoUrl} title="" controls autoPlay />
    </Modal>
  );
};
