import { RightClickProps } from "./RightClickPopBloc";
import style from "./RightClickPopView.module.scss";
import useFeatureTags from "@custom-hooks/useFeatureTags";
import { useCallback, useMemo } from "react";
import func from "@uikit/func";
import { useSelector } from "react-redux";
import { useIsStaticApp } from "@custom-hooks/useNodePreview";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { getIt } from "@uikit/getIt";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { rightMenuStore } from "@uikit/store/rightMenuStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { AddNode } from "@uikit/service/AddNode";

/* 右键菜单 */
export function RightClickPopView(props: RightClickProps): JSX.Element {
  const homePluginStore = getIt(HomePluginStore);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const previewAppStore = getIt(PreviewAppStore);
  const creatorPreviewService = getIt(CreatorPreviewService);
  const fotReactFlow = getIt(FotReactFlow);
  const homeHandleFieldMethods = getIt(HomeHandleFieldMethods);
  const addNode = getIt(AddNode);

  const selectApiReg = /^\//;

  const { duplicateEdgeEnterText, enableAddNodetoPreview } = props;

  const myModificationDataState = useImagicaStore(myModificationDataStroe).value;
  const rightMenuState = useImagicaStore(rightMenuStore).value;
  const homePluginState = useImagicaStore(homePluginStore).value;
  const creatorPreviewState = useImagicaStore(previewStore).value;
  const feature_tags = useFeatureTags();

  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const inputId: any[] = useSelector((state: any) => state.fot.inputId);
  const checkEdgeArr = useSelector((state: any) => state.fot.checkEdgeArr);
  const checkNodeArr = useSelector((state: any) => state.fot.checkNodeArr);
  const developerMode = useSelector((state: any) => state.fot.developerMode);
  const thoughts = saasUIData.output.map(x => x?.groupId);
  const { isStaticApp } = useIsStaticApp();

  const getCurrentUILabel = useCallback(() => {
    if (PreviewAppValueLangUtil.isStaticApp(saasUIData)) {
      return "static content";
    }
    return "dynamic content";
  }, [saasUIData]);

  const menuDeleteOption = useMemo(() => {
    if (func.isEmpty(checkNodeArr)) return null;
    return (
      <p className={style.right_text} onClick={props.handleMenuDelete}>
        Delete
      </p>
    );
  }, [checkNodeArr]);

  const onPasteNodeAndEdge = useCallback(
    (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
      const { x, y, zoom } = fotReactFlow.getViewport();
      const targetX = (e.clientX - x) / zoom;
      const targetY = (e.clientY - y) / zoom;
      creatorStoreMethods.pasteNodeAndEdge({ x: targetX, y: targetY });
    },
    [fotReactFlow.getViewport]
  );

  const clickAddNodeBtn = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>): void => {
    addNode.addNodeV2(e);
  };

  return (
    <div
      ref={creatorRefStore.rightMenuRef}
      className={style.right_menu}
      onClick={() => homeToolBarStore.setAllowZoomOrPan(true)}
      id="RightClickPopView"
      data-testid="RightClickPopView"
      data-creator="RightMenu"
    >
      <p
        className={style.right_text}
        onClick={clickAddNodeBtn}
        data-testid="rightmenu-new"
        data-amplitude="rightmenu_new"
      >
        New
      </p>
      <p
        className={style.right_text}
        onClick={props.handleClickUpload}
        data-testid="rightmenu-new-upload"
        data-amplitude="rightmenu_new_upload"
      >
        Upload a File
      </p>
      {feature_tags.showStudiosAgent && (
        <p className={style.right_text} onClick={props.clickAddNodeAgent} data-amplitude="rightmenu_new_agent">
          New Agent
        </p>
      )}
      {feature_tags.disableCallerBot === false && (
        <p
          className={style.right_text}
          onClick={props.handleClickCreateCallerBot}
          data-amplitude="rightmenu_callerbot_UI"
        >
          Test callerbot flow
        </p>
      )}
      {menuDeleteOption}
      <p className={style.right_text} onClick={props.copyNodeAndEdge} data-amplitude="rightmenu_copy">
        Copy
      </p>
      <p className={style.right_text} onClick={onPasteNodeAndEdge} data-amplitude="rightmenu_paste">
        Paste
      </p>

      {!func.isEmpty(rightMenuState.customFuncRightContextMenu) ? (
        <p
          className={style.right_text}
          onClick={props.editCustomJsApiFunction}
          data-amplitude="rightmenu_edit_function"
        >
          Edit Function
        </p>
      ) : null}
      {rightMenuState.edgeRight && developerMode && !selectApiReg.test(duplicateEdgeEnterText ?? "") ? (
        <p className={style.right_text} onClick={props.editPrompt} data-amplitude="rightmenu_edit_prompt">
          Edit Prompt
        </p>
      ) : null}
      {rightMenuState.edgeRight ? (
        <p className={style.right_text} onClick={props.duplicateEdge} data-amplitude="rightmenu_duplicate">
          Duplicate
        </p>
      ) : null}
      {rightMenuState.nodeRight ? (
        <>
          {developerMode && !myModificationDataState.showMyModification ? (
            <p
              className={style.right_text}
              onClick={() => props.clickToCreateVariable(false)}
              data-amplitude="rightmenu_create_variable"
            >
              Create variable
            </p>
          ) : null}

          {!func.isEmpty(creatorPreviewState.selectedUI) ? (
            <>
              {enableAddNodetoPreview ? (
                <p
                  className={style.right_text}
                  aria-disabled={homePluginState.disablePublishAsContent}
                  onClick={e => {
                    props.publishAsOutPut(e);
                  }}
                  data-amplitude="rightmenu_add_to_preview"
                >
                  {`Publish as ${getCurrentUILabel()}`}
                </p>
              ) : null}
              {!func.isEmpty(creatorRefStore.nodeContextMenuRef.current?.data?.parentNodeId) ? (
                <p
                  className={style.right_text}
                  aria-disabled={thoughts?.includes(creatorRefStore.nodeContextMenuRef.current?.data?.parentNodeId)}
                  onClick={e =>
                    e.currentTarget.getAttribute("aria-disabled") === "true" ||
                    // e.target.getAttribute("aria-disabled") === "true" ||
                    creatorPreviewService.clickToPreviewNode(creatorRefStore.nodeContextMenuRef.current)
                  }
                  data-amplitude="rightmenu_add_group_to_preview"
                >
                  {`Add group to ${getCurrentUILabel()}`}
                </p>
              ) : null}
              {rightMenuState.showAddInputField &&
              feature_tags.disableUploadTypesAsInputNode === false &&
              !isStaticApp ? (
                <p
                  className={style.right_text}
                  data-amplitude={
                    "rightmenu_add/remove_as_input_field:" +
                    (!inputId.includes(creatorRefStore.nodeContextMenuRef.current?.id) ? "remove" : "add")
                  }
                  onClick={() =>
                    homeHandleFieldMethods.addOrRemoveInputFeild(creatorRefStore.nodeContextMenuRef.current)
                  }
                >
                  {inputId.includes(creatorRefStore.nodeContextMenuRef.current?.id) &&
                  previewAppStore.enableRemovePreview()
                    ? "Remove from preview"
                    : "Publish as input field"}
                </p>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
      {!func.isEmpty(checkEdgeArr) && !myModificationDataState.showMyModification ? (
        <p
          className={style.right_text}
          onClick={props.clickToCreateFunction}
          data-amplitude="rightmenu_create_function"
        >
          Create function
        </p>
      ) : null}
    </div>
  );
}
