import { CanvasNodeType, CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { ProjectPageStore } from "@uikit/store/ProjectPageStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { DataRefUtil } from "@uikit/util/DataRefUtil";
import utilIndex from "@views/thinking-layout-editor/utils";
import { CanvasEdgeUtil } from "@uikit/util/CanvasEdgeUtil";
import { CanvasLineUtil } from "@uikit/util/CanvasLineUtil";
import { distance, markerEnd } from "@uiview/views/ProjectCanvas/homeConst";
import { GetZoom, SetCenter } from "reactflow";
import { DIRECTIONHANDLE } from "@views/thinking-layout-editor/constants";

interface OtherProps {
  dragging?: boolean;
  height?: number;
  id: string;
  isConnectable?: boolean;
  offsetHeight: number;
  selected?: boolean;
  sourcePosition?: "bottom" | "top" | "left" | "right";
  targetPosition?: "bottom" | "top" | "left" | "right";
  type?: string;
  width: number;
  xPos: number;
  yPos: number;
  zIndex?: number;
}

export class AddGroup {
  constructor(
    private creatorNodesStore: CreatorNodesStore,
    private creatorRefStore: CreatorRefStore,
    private canvasDataRef: CanvasDataRef,
    private projectPageStore: ProjectPageStore,
    private creatorStoreMethods: CreatorStoreMethods
  ) {}

  clickGroupAddBtn(
    direction: keyof typeof DIRECTIONHANDLE,
    otherProps: OtherProps,
    setCenter: SetCenter,
    getZoom: GetZoom,
    displayType: string
  ): void {
    let Interval = 200;
    let widthDistance = 200;
    if (displayType === "text") {
      Interval = 250;
      widthDistance = 250;
    }
    const offsetDistance = 370 + otherProps.width;

    const newAddEdge = CanvasEdgeUtil.createNewEdge(otherProps);
    const recentNodes = this.creatorNodesStore.getNodes();
    const currGroupNodes = recentNodes.filter(x => x.parentNode === otherProps.id);
    const newNodes: CanvasNodeType[] = [];
    const childNode: string[] = [];
    const groupId = `group-${this.creatorRefStore.nodeIndexRef.current++}`;
    const needMoveNode = this.creatorNodesStore.getNodes().filter(x => !CreatorNodesConsts.NEW_EDGE_REG.test(x.id));

    this.creatorRefStore.sliceLevelRef.current++;
    currGroupNodes.forEach((x, index) => {
      const newNodeId = `editor-${this.creatorRefStore.nodeIndexRef.current++}`;
      const newNode: CanvasNodeType = {
        id: newNodeId,
        type: "customNode",
        parentNode: groupId,
        extent: "parent",
        data: {
          ...this.canvasDataRef.nodeDataRef.current,
          sliceLevel: this.creatorRefStore.sliceLevelRef.current,
          isSliceNode: true,
          parentNodeId: groupId,
          displayType: displayType,
        },
        position: {
          x: distance,
          y: distance * (index + 1) + index * Interval,
        },
      };
      newNodes.push(newNode);
      childNode.push(newNodeId);
    });
    // 新增group
    const groupHeight =
      currGroupNodes.length * Interval +
      ((currGroupNodes.length + 1) * distance) / 2 +
      this.projectPageStore.state.previewHeight;
    const getCenterY = otherProps.yPos + otherProps.offsetHeight / 2;

    //获取最下方或者最右方的node位置
    const adjacentNodepPosition = utilIndex.getBottomOrRightPosition(
      needMoveNode,
      direction,
      offsetDistance,
      { x: otherProps.xPos, y: otherProps.yPos },
      widthDistance + 2 * distance,
      groupHeight
    );

    const newGroupNode = {
      id: groupId,
      type: "customGroup",
      markerEnd,
      data: {
        ...this.canvasDataRef.groupDataRef.current,
        childNode,
      },
      style: {
        width: widthDistance + 2 * distance,
        height: groupHeight,
      },
      position: {
        x: otherProps.xPos + 100,
        y: getCenterY - groupHeight / 2,
      },
    };
    //新node按照已有的输出node位置调整
    if (direction === "left" || direction === "right") {
      newGroupNode.position.y = adjacentNodepPosition;
    } else {
      newGroupNode.position.x = adjacentNodepPosition;
    }

    if (direction === "right") {
      newGroupNode.position.x += 350;
    } else if (direction === "left") {
      newGroupNode.position.x -= 350;
    }

    //使输入框居中，zoom不变
    const positionX = (otherProps.xPos + newGroupNode.position.x) / 2 + 100;
    const positionY = (otherProps.yPos + newGroupNode.position.y) / 2 + groupHeight / 2;
    setCenter(positionX, positionY, { duration: 300, zoom: getZoom() });

    this.creatorNodesStore.handleUpdateTarget({
      newNode: newGroupNode,
      edgeNode: newAddEdge,
    });
    this.creatorNodesStore.setNodes(prevList => {
      return [...prevList, ...newNodes];
    });
    const nodesArr = this.creatorNodesStore.getNodes();
    const updatedNewEdge = nodesArr.find(x => x.id === newAddEdge.id);
    const sourceLine = CanvasLineUtil.createNewLine({
      source: otherProps.id,
      target: newAddEdge.id,
      type: "source",
    });

    const targetLine = CanvasLineUtil.createNewLine({
      sourceNode: otherProps,
      source: newAddEdge.id,
      target: newGroupNode.id,
      type: "target",
    });

    /// 移动节点
    this.creatorStoreMethods.translateNode(newGroupNode, direction, offsetDistance - 350, 30);
    const newSimpleNodes = newNodes.map(node => this.canvasDataRef.getSimpleNode(node));
    this.creatorStoreMethods.onNodesEdgesAdd(
      [newGroupNode, this.canvasDataRef.getSimpleNodeV3(updatedNewEdge), ...newSimpleNodes],
      [
        DataRefUtil.getSimpleEdgeV3(sourceLine, this.canvasDataRef.newLineDataRef),
        DataRefUtil.getSimpleEdgeV3(targetLine, this.canvasDataRef.newLineDataRef),
      ]
    );
  }
}
