"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratePromptService = void 0;
class GeneratePromptService {
    constructor(brainClient) {
        this.brainClient = brainClient;
    }
    async getEdgeGeneration(edge, sourceNodes) {
        const enterText = edge.options.actionData.enterText;
        const postParam = {
            domain: "brain_studios",
            identifier_type: "file",
            identifier_value: "brain_studios_edge_prompt_generation.model",
            stream: false,
            variables: { input: enterText },
            extra_inputs: {},
        };
        /// 多个input需要传递extra_inputs
        if (sourceNodes.length > 1) {
            postParam.extra_inputs = this.getSourceNodeLabels(sourceNodes);
        }
        const res = await this.brainClient.openAi.getGenerationPrompt(postParam);
        return res.data.identifier;
    }
    getSourceNodeLabels(sourceNodes) {
        return sourceNodes.reduce((keyObj, node) => {
            const key = node.options.inputTitle || node.options.generatedLabel || "Input";
            keyObj[key] = key;
            return keyObj;
        }, {});
    }
}
exports.GeneratePromptService = GeneratePromptService;
