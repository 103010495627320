"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VBDataWeatherCurrent = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
class VBDataWeatherCurrent extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.component_id = "";
        this.Description = "";
        this.weather_icon = "";
        this.temperature = "";
        this.summary_text = "";
        this.uv_value = "";
        this.humidity_value = "";
        this.precipitation_value = "";
        this.suggestion_text = "";
    }
}
exports.VBDataWeatherCurrent = VBDataWeatherCurrent;
