"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioProjectAttributesV2Edge = exports.StudioProjectV3EdgeFlow = exports.StudioProjectAttributesV2EdgeData = exports.StudioProjectAttributesV2EdgeDataLineParamFuncData = exports.StudioProjectAttributesV2EdgeDataLineParamRequestParam = exports.StudioProjectAttributesV2EdgeDataLineParamIdentifier = exports.StudioProjectAttributesV2EdgeDataLineParam = exports.StudioProjectAttributesV2EdgeDataNodeIndexRef = exports.StudioProjectAttributesV2EdgeMarkerEnd = void 0;
const class_transformer_1 = require("class-transformer");
require("reflect-metadata");
class StudioProjectAttributesV2EdgeMarkerEnd {
}
exports.StudioProjectAttributesV2EdgeMarkerEnd = StudioProjectAttributesV2EdgeMarkerEnd;
class StudioProjectAttributesV2EdgeDataNodeIndexRef {
}
exports.StudioProjectAttributesV2EdgeDataNodeIndexRef = StudioProjectAttributesV2EdgeDataNodeIndexRef;
class StudioProjectAttributesV2EdgeDataLineParam {
}
exports.StudioProjectAttributesV2EdgeDataLineParam = StudioProjectAttributesV2EdgeDataLineParam;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2EdgeDataLineParamIdentifier)
], StudioProjectAttributesV2EdgeDataLineParam.prototype, "identifier", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2EdgeDataLineParamRequestParam)
], StudioProjectAttributesV2EdgeDataLineParam.prototype, "requestParam", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2EdgeDataLineParamFuncData)
], StudioProjectAttributesV2EdgeDataLineParam.prototype, "originFunctionData", void 0);
class StudioProjectAttributesV2EdgeDataLineParamIdentifier {
}
exports.StudioProjectAttributesV2EdgeDataLineParamIdentifier = StudioProjectAttributesV2EdgeDataLineParamIdentifier;
class StudioProjectAttributesV2EdgeDataLineParamRequestParam {
}
exports.StudioProjectAttributesV2EdgeDataLineParamRequestParam = StudioProjectAttributesV2EdgeDataLineParamRequestParam;
class StudioProjectAttributesV2EdgeDataLineParamFuncData {
}
exports.StudioProjectAttributesV2EdgeDataLineParamFuncData = StudioProjectAttributesV2EdgeDataLineParamFuncData;
class StudioProjectAttributesV2EdgeData {
}
exports.StudioProjectAttributesV2EdgeData = StudioProjectAttributesV2EdgeData;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2EdgeDataNodeIndexRef)
], StudioProjectAttributesV2EdgeData.prototype, "nodeIndexRef", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2EdgeDataLineParam)
], StudioProjectAttributesV2EdgeData.prototype, "lineParam", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectV3EdgeFlow)
], StudioProjectAttributesV2EdgeData.prototype, "flows", void 0);
class StudioProjectV3EdgeFlow {
}
exports.StudioProjectV3EdgeFlow = StudioProjectV3EdgeFlow;
class StudioProjectAttributesV2Edge {
    constructor() {
        this.id = "";
        this.source = "";
        this.target = "";
    }
}
exports.StudioProjectAttributesV2Edge = StudioProjectAttributesV2Edge;
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2EdgeMarkerEnd)
], StudioProjectAttributesV2Edge.prototype, "markerEnd", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV2EdgeData)
], StudioProjectAttributesV2Edge.prototype, "data", void 0);
