import { useSelector } from "react-redux";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import "../views-css/DomainButton.css";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

function DomainButton(props) {
  const mode = useSelector(state => state.fot.mode);
  const domainDisabled = useSelector(state => state.fot.domainDisabled);

  const on_select_domain = e => {
    if (domainDisabled) {
      e.stopPropagation();
      return;
    }
    props.on_select_domain(props.id);
  };

  const popoverContent = domain => {
    return (
      <div
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => {
          props.handleDeleteDomain(domain);
        }}
      >
        <span>Delete</span>
        <DeleteOutlined />
      </div>
    );
  };

  return (
    <>
      {props.id !== -1 && mode === "studioMode" ? (
        <article className={props.id == props.selectedDomain ? "SelectedDomain" : "Domain"}>
          <span style={{ marginRight: "3px" }}>{props.label}</span>
        </article>
      ) : (
        // <Popover content={popoverContent(props.label)} trigger="hover" placement="bottom">
        //   <article onClick={on_select_domain} className= {props.id == props.selectedDomain ? "SelectedDomain" : "Domain"} >
        //     {
        //       props.label == "thinking" ?
        //       <img src={thought} />
        //       :
        //       props.label == "plus" ?
        //       <>
        //         <span>New</span>
        //         <PlusOutlined style={{marginLeft: "0px"}}/>
        //       </>
        //       :
        //       <span style={{marginRight: '3px'}}>{props.label}</span>
        //     }
        //   </article>
        // </Popover>
        <article onClick={on_select_domain} className={props.id == props.selectedDomain ? "SelectedDomain" : "Domain"}>
          {props.label == "thinking" ? (
            <img src={`${settings.S3CDN}${settings.viewAssetsPath}thought.svg`} />
          ) : props.label == "plus" ? (
            <>
              <span>New</span>
              <PlusOutlined style={{ marginLeft: "0px" }} />
            </>
          ) : (
            <span style={{ marginRight: "3px" }}>{props.label}</span>
          )}
        </article>
      )}
    </>
  );
}

export default DomainButton;
