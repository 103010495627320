"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayWallApi = void 0;
const PayPlan_1 = require("./payWallTyped/PayPlan");
class PayWallApi {
    constructor(restClient) {
        this.restClient = restClient;
    }
    async plans() {
        const query = "active:'true' AND metadata['purpose']:'imagica.ai'";
        return this.restClient.get("/be/payment/subscription/product/search", { query }).then(res => {
            return res.toArray(PayPlan_1.PayPlan, res => res.data.data);
        });
    }
    async planPrice(planId) {
        return this.restClient.get("/be/payment/subscription/price", { product: planId, active: true }).then(res => {
            return res.toArray(PayPlan_1.PayPlanPrice, res => res.data.data);
        });
    }
    async subscription(id) {
        const url = `/be/payment/subscription/schedule/${id}`;
        return this.restClient.get(url).then(res => {
            return res;
        });
    }
    async paymentLink(parmasData) {
        const url = `/be/payment/stripe/paymentLink`;
        return this.restClient.post(url, parmasData).then(res => {
            return res;
        });
    }
    async updateSubscripber(parmasData) {
        const url = "/be/payment/stripe/billingPortal";
        return this.restClient.post(url, parmasData).then(res => {
            return res;
        });
    }
}
exports.PayWallApi = PayWallApi;
