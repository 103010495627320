"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RetrievalApi = void 0;
const Retrieval_1 = require("../api/retrievalTyped/Retrieval");
const Settings_1 = require("../kernel/Settings");
class RetrievalApi {
    constructor(restClient) {
        this.restClient = restClient;
    }
    async query(query, userId, top, sourceId) {
        const authorType = Settings_1.settings.ENV === "production" ? "production" : "staging";
        const source = sourceId ? { source_id: `${authorType}/${sourceId}` } : { source: "chat" };
        const res = await this.restClient.post("/be/retrieval/query", {
            queries: [
                {
                    filter: {
                        author: `${authorType}/${userId}`,
                        ...source,
                    },
                    top_k: top,
                    query: query,
                },
            ],
        });
        res.validate();
        return res.toModelFromType(Retrieval_1.RetrievalResponse);
    }
}
exports.RetrievalApi = RetrievalApi;
