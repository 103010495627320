"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureTags = void 0;
class FeatureTags {
    constructor() {
        this.disableUploadNodeUnStructured = false;
        this.disableUploadNodeHtml = false;
        this.disableUploadNodeImage = false;
        this.disableViewBuilderToolBoxIntegration = true;
        this.disableGenUI = false;
        this.disableManualOnboarding = false;
        // public disableUploadFileV2 = false;
        this.disableToolBarPublish = false;
        // public disableSelectTemplate = false;
        // public disableUpdateEdgeTextByApi = false;
        this.disableUploadTypesAsInputNode = false;
        this.disableTriggerManualOnboardingInCanvas = false;
        this.disableTriggerCotOnboardingInCanvas = false;
        this.disableCotFlowInCanvas = false;
        this.disableMobilePage = false;
        this.disableCotOnboarding = false;
        // public enableV2TransformV3Data = true;
        // public showFeatureCot = true;
        this.showEdgeBluePrint = true;
        this.showDesignSpaceMenu = true;
        this.showSubscriptionFeaturesToggle = true;
        this.showStudiosAgent = true;
        this.showProtein = true;
        this.showMap = true;
        this.showUserFeedback = true;
        this.showCotUserFeedback = true;
        // public showNewProjectTemplates = true;
        // public showChatInterface = true;
        this.showInterviewBot = true;
        // public enableAutoCreateAIApp = true;
        // public enableDragLine = true;
        this.showExpFeatures = true;
        this.enableSkipEmptyVerification = true;
        this.enableFood = true;
        this.enableMidjourneyV2 = true;
        this.enableStaticMode = true;
        this.enableStudiosCustomJsFunctionChatBox = true;
        this.enableGlobalContext = true;
        this.enableShrinkAndExpand = true;
        this.enableGlobalFunctionPublish = true;
        // public enableNewOnboardingFlow = true;
        // public enableMyAccount = true;
        /** 是否启用 chat ai 流程 */
        this.enableChatAiFlow = true;
        /** 是否启用 publish v3 流程 */
        this.enablePublishV3 = true;
        this.enableCotTemplateV2 = true;
        this.enableGroupCollapse = true;
        this.enableStudiosPaywall = true;
        this.enable_saas_web = true;
        this.showBuyButton = true;
        this.showSuggestionFunctions = true;
        /** 是否启用新的 preview output 渲染组件(不使用cardHome, cardFrame, contentFrame..., 直接渲染 Nodes 或 preview node) */
        this.enablePreviewOutputV2 = false;
        this.disableCallerBot = false;
        this.disableMultiRoundChatOnboarding = false;
    }
}
exports.FeatureTags = FeatureTags;
