"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamGroupDistribution = void 0;
const StoryEdge_1 = require("../../../storyV2/domain/StoryEdge");
const StoryUtil_1 = require("../../../storyV2/domain/StoryUtil");
const StoryManagerUtil_1 = require("../../../storyV2/domain/StoryManagerUtil");
const StoryNodeType_1 = require("../../../storyV2/domain/StoryNodeType");
const StoryNodeOptions_1 = require("../../../storyV2/domain/StoryNodeOptions");
const StoryNodeDisplayType_1 = require("../../../storyV2/domain/StoryNodeDisplayType");
const StoryNode_1 = require("../../../storyV2/domain/StoryNode");
const StoryNodeState_1 = require("../../../storyV2/domain/StoryNodeState");
const BaseDistribution_1 = require("../../../storyV2/response/responseDistribution/BaseDistribution");
class StreamGroupDistribution extends BaseDistribution_1.BaseDistribution {
    handleRunResponse(story, edge, res, relatedEdges) {
        const sourceNode = StoryUtil_1.StoryUtil.findEdgeSoure(story, edge);
        if (!sourceNode) {
            return Promise.reject();
        }
        const targetNode = StoryManagerUtil_1.StoryManagerUtil.getOrCreateOutputNode(story, edge, StoryNodeType_1.StoryNodeType.CUSTOMGROUP, undefined);
        /// 返回类型为字符串数组，则构造子节点数组，作为下一个节点的子阶段
        const stringGroup = res.data;
        const newChildIdNumbers = StoryManagerUtil_1.StoryManagerUtil.idNumberForGroup(story, stringGroup.length, targetNode);
        const textNodeGroup = stringGroup.map((value, index) => {
            const nodeOptions = new StoryNodeOptions_1.StoryNodeOptions(`editor-${newChildIdNumbers.get(index)}`, undefined, StoryNodeType_1.StoryNodeType.CUSTOMNODE, StoryNodeDisplayType_1.StoryNodeDisplayType.TEXT, targetNode.id);
            const node = new StoryNode_1.StoryNode(nodeOptions);
            node.content = value;
            node.state = StoryNodeState_1.StoryNodeState.COMPLETED;
            return node;
        });
        targetNode.options.children = textNodeGroup.map(node => node.options.id);
        /// 清空下一个节点的数据，重新赋值结果
        StoryManagerUtil_1.StoryManagerUtil.cleanAndSetNewChildren(targetNode, textNodeGroup);
        /// 结束edge的run
        targetNode.state = StoryNodeState_1.StoryNodeState.COMPLETED;
        targetNode.received(new StoryNode_1.StoryNodeEventObject(""));
        StoryManagerUtil_1.StoryManagerUtil.setEdgesStateAndNotify([edge, ...relatedEdges], StoryEdge_1.EdgeState.COMPLETED, story);
        StoryManagerUtil_1.StoryManagerUtil.updateLastEdgeOutnode(story, edge, targetNode);
        return Promise.resolve();
    }
}
exports.StreamGroupDistribution = StreamGroupDistribution;
