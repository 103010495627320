"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class DTheme {
}
exports.DTheme = DTheme;
DTheme.theme = {
    saasAiAppTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.saasAiAppTheme,
        background: "linear-gradient(180deg, #FDF6EC 0%, #F3DCCD 100%)",
    },
    nodeTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.nodeTheme,
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBorder: "1px solid rgba(18, 25, 43, 0.10)",
        inputBorderBottom: "1px solid rgba(18, 25, 43, 0.10)",
        inputBorderRadius: "30px",
        inputPadding: "15px 25px",
        inputBoxShadow: "none",
        topSuctionBgc: "transparent",
        submitButtonBgClr: "#000101",
        submitButtonColor: "#fff",
    },
};
