"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dish = exports.VBDataDishRecommendation = void 0;
const VBGraphDataBase_1 = require("./VBGraphDataBase");
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
class VBDataDishRecommendation extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.title = "";
        this.description = "";
        this.source = new Source();
        this.dish_list = [];
    }
}
exports.VBDataDishRecommendation = VBDataDishRecommendation;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Source)
], VBDataDishRecommendation.prototype, "source", void 0);
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => Dish, [])
], VBDataDishRecommendation.prototype, "dish_list", void 0);
class Source {
    constructor() {
        this.source_icon = "";
        this.source_text = "";
    }
}
class Dish {
    constructor() {
        this.dish_image = "";
        this.dish_name = "";
        this.dish_price = "";
        this.dish_delivery_time = "";
    }
}
exports.Dish = Dish;
