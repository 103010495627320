import { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import Dropdown from "../Home/Dropdown";
import { callback, getAssetsIcon } from "../utilities";
import style from "./style.module.scss";
import cls from "classnames";
import { homeToolBarStore } from "@uikit/store/homeToolBarStore";

export enum MenuItemTypes {
  Normal,
}

export enum MenuItemValues {
  ReportBug,
  RequestFeature,
}

export interface MenuItem {
  key: number | string;
  type: MenuItemTypes;
  value: MenuItemValues;
  icon: string;
  text: string;
}

export interface FeedbackProps {
  onMenuItemClick: (value: MenuItem) => void;
}

const Feedback: FC<FeedbackProps> = ({ onMenuItemClick = callback }) => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState<boolean>(false);

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        key: 1,
        type: MenuItemTypes.Normal,
        value: MenuItemValues.ReportBug,
        icon: getAssetsIcon("invalid.svg"),
        text: "Report a bug",
      },
      {
        key: 2,
        type: MenuItemTypes.Normal,
        value: MenuItemValues.RequestFeature,
        icon: getAssetsIcon("message.svg"),
        text: "Request a feature",
      },
    ],
    []
  );

  const handleFeedbackOpenChange = useCallback((isOpen: boolean) => {
    homeToolBarStore.setAllowZoomOrPan(!isOpen);
    setIsFeedbackOpen(isOpen);
  }, []);

  const handleMenuItemClick = useCallback(
    (menuItem: MenuItem) => () => {
      //点击menu选项的时候,自动关闭了弹窗，但是没有触发onOpenChange方法
      handleFeedbackOpenChange(false);
      setIsFeedbackOpen(false);
      onMenuItemClick(menuItem);
    },
    [onMenuItemClick]
  );

  const handleButtonClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  return (
    <div className={style.dropdown}>
      <Dropdown
        isOpen={isFeedbackOpen}
        content={
          <div className={style.dropdown_content}>
            {menuItems.map(menuItem => (
              <div key={menuItem.key} className={style.dropdown__item} onClick={handleMenuItemClick(menuItem)}>
                <div className={style.item}>
                  <div className={style.item__container}>
                    <img className={style.icon} src={menuItem.icon} alt={menuItem.text} />
                    <span className={cls(style.text, style.text_dropdown)}>{menuItem.text}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
        onOpenChange={handleFeedbackOpenChange}
      >
        <button className={style.button} onClick={handleButtonClick}>
          <span className={style.filterIconBox}>
            <img className={style.icon} src={getAssetsIcon("nav-feedback.svg")} alt="feedback" />
          </span>
          <span className={cls(style.text, style.text_button)}>Beta feedback</span>
        </button>
      </Dropdown>
    </div>
  );
};

export default Feedback;
