import React from "react";
import styles from "./nodeTypeComponents.module.css";

const NodeWrapper = ({ mouseEnterLeave, style, children }) => {
  return (
    <div
      className={styles.nodeCovering}
      onMouseEnter={() => mouseEnterLeave?.(true)}
      onMouseLeave={() => mouseEnterLeave?.(false)}
      style={style}
    >
      {children}
    </div>
  );
};

export default NodeWrapper;
