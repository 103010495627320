import React from "react";

import { Switch } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { fotActions } from "../../store/fot";

const StudioSwitch = () => {
  const dispatch = useDispatch();

  const mode = useSelector(state => state.fot.mode);
  const fetching = useSelector(state => state.fot.fetching);

  const selectStudioMode = checked => {
    const mode = checked ? "studioMode" : "testMode";
    dispatch(fotActions.setMode(mode));
    dispatch(fotActions.setIsFirstLoad(false));
    // navigate(checked ? '/studio' : '/');
  };

  return (
    <Switch
      checkedChildren="Studio Mode"
      unCheckedChildren="Test Mode"
      defaultChecked={false}
      checked={mode === "studioMode"}
      onChange={selectStudioMode}
      disabled={fetching}
    />
  );
};

export default StudioSwitch;
