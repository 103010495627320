"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomCodeFunction = void 0;
const CustomCodeAdapter_1 = require("../../storyV2/adapter/CustomCodeAdapter");
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
class CustomCodeFunction extends FunctionBase_1.FunctionBase {
    constructor() {
        super(...arguments);
        this.props = new CustomCodeAdapter_1.CustomCodeProps();
    }
    async run(input, identityInfo, variableList = [], page = 1) {
        let cssValue = this.props.cssStr;
        let htmlValue = this.props.htmlStr;
        let jsValue = this.props.jsStr;
        const currentSourceNodeValue = "${currentSourceNodeValue}";
        const brainAuthToken = "${brainAuthToken}";
        const inputWithQuotation = '"' + input + '"';
        htmlValue = htmlValue.replace(currentSourceNodeValue, input);
        htmlValue = htmlValue.replace(brainAuthToken, this.authStore.state.brainToken);
        jsValue = jsValue.replace(currentSourceNodeValue, inputWithQuotation);
        jsValue = jsValue.replace(brainAuthToken, this.authStore.state.brainToken);
        for (const variable of variableList) {
            const variableName = "${" + variable.name + "}";
            const valueWithQuotation = '"' + variable.value + '"';
            htmlValue = htmlValue.replace(variableName, variable.value);
            jsValue = jsValue.replace(variableName, valueWithQuotation);
        }
        const lineFeed = htmlValue.match(/^<html>/) ? "" : "white-space: pre-wrap;";
        const completionHtmlStr = `<html>
        <style>${cssValue}</style>
        <body style="${lineFeed}">${htmlValue}</body>
        <script>{${jsValue}}</script>
      </html>`;
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.TEXT, completionHtmlStr);
    }
}
exports.CustomCodeFunction = CustomCodeFunction;
