"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VBDataYoutubeVideoModel = exports.VBDataYoutubeVideoSource = void 0;
const ClassTransformerDecorators_1 = require("../../../cutil/ClassTransformerDecorators");
const VBGraphDataBase_1 = require("../../../api/viewBuilderTyped/VBGraphData/VBGraphDataBase");
class VBDataYoutubeVideoSource {
    constructor() {
        this.source_icon = "";
        this.source_text = "";
    }
}
exports.VBDataYoutubeVideoSource = VBDataYoutubeVideoSource;
class VBDataYoutubeVideoModel extends VBGraphDataBase_1.VBGraphDataBase {
    constructor() {
        super(...arguments);
        this.isFromList = false;
        this.source = new VBDataYoutubeVideoSource();
        this.video_image = "";
        this.video_title = "";
        this.url = "";
    }
}
exports.VBDataYoutubeVideoModel = VBDataYoutubeVideoModel;
__decorate([
    (0, ClassTransformerDecorators_1.Type)(() => VBDataYoutubeVideoSource)
], VBDataYoutubeVideoModel.prototype, "source", void 0);
