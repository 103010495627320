import func from "@uikit/func";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { Api } from "@uikit/service/api";
import { uploadTypes } from "@uiview/views/Nodes/constants";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

export class EdgeRunTypeUpload {
  api: Api;
  constructor(
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public creatorStoreMethods: CreatorStoreMethods
  ) {
    this.api = new Api(false);
  }

  getSourceOfTruthResults = (query: any, edgeParam: any): Promise<any> => {
    const requestParam = edgeParam?.lineParam?.requestParam || edgeParam;
    const data = Object.assign({}, requestParam, { query });
    return this.api.postWithError("/be/vectordb/indexers/", { data });
    // .catch(error => Promise.reject(error));
  };

  // 处理上传file类型
  handleUploadFileRun = async (
    text: any,
    sourceNodeId: string,
    targetNodeId: string,
    edgeId: any,
    disabled?: any
  ): Promise<boolean> => {
    const nodes = this.creatorNodesStore.getNodes();
    const sourceNode = nodes.find(x => x.id === sourceNodeId);

    if (uploadTypes.includes(sourceNode?.data.displayType) === false) return false;

    this.creatorStoreMethods.setEdgeLineParam({
      id: edgeId,
      loading: disabled ? false : true,
      enterText: text,
    });
    try {
      this.creatorEdgesStore.setQueryLoading(edgeId, "isGetQuery", true);
      if (disabled) {
        return true;
      }
      const res = await this.getSourceOfTruthResults(text, {
        file: sourceNode?.data.textAreaValue.s3Path,
        loader: "PDFReader",
      });
      this.creatorNodesStore.setNodes(prevList => {
        return prevList.map(x => {
          if (x.id === targetNodeId) {
            return {
              ...x,
              data: {
                ...x.data,
                displayType: "text",
                textAreaValue: res?.data?.data?.response,
              },
            };
          }
          return x;
        });
      });
    } catch (error) {
      func.messageError(error);
    } finally {
      this.creatorStoreMethods.setEdgeLineParam({
        id: edgeId,
        loading: false,
      });
    }
    return true;
  };
}
