import { useRef } from "react";
import { useRenderData } from "../../../hooks/useRenderData";
import style from "../index.module.scss";

type PreviewTitleProps = {
  id: string;
  title: string;
  isShare: boolean;
  showInputTitleId: string;
  saasUIInputData: any;
  inputTitleId: string;
  onSaasInputTitleChange: (e: any, id: string) => void;
  onInputTitleEnter: (e: any, id: string) => void;
  onInputTitleBlur: () => void;
  onClickInputTitleClose: (e: any, id: string) => void;
  onClickEdit: (e: any, data: any) => void;
};

export function PreviewTitle({ title, saasUIInputData }: PreviewTitleProps): React.JSX.Element {
  const inputTitleRef = useRef(null);
  const renderData = useRenderData({ type: "inputLabel", id: saasUIInputData.id });

  return (
    <div style={renderData?.style} ref={inputTitleRef}>
      <div style={renderData?.style} className={style["input-title-piece"]}>
        {title}
      </div>
    </div>
  );
}
