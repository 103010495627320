import UserAvatarV2 from "@views/components/UserAvatarV2";
import { getAssetsIcon } from "@views/thinking-layout-editor/utilities";
import { useCallback } from "react";
import { logEvent } from "@uikit/service/amplitude";
import { useNavigate } from "react-router-dom";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";
import { getIt } from "@uikit/getIt";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";

type navBarUserInfoPropsType = {
  className?: string;
  disable?: boolean;
};
export function NavBarUserInfo(navBarUserInfoProps: navBarUserInfoPropsType): JSX.Element {
  const meStore = getIt(MeStore);
  const navigate = useNavigate();
  const fotProcessManager = FotProcessManager.use();
  const isStaffRole = (meStore.state.roles || []).indexOf("staff") > -1;

  const items = [
    {
      key: "myAccount",
      label: "My Account",
      icon: getAssetsIcon("myaccount_enter.svg"),
      closed: true,
      className: "",
      showWebsocketStatus: isStaffRole,
    },
    {
      key: "logout",
      label: "Log out",
      icon: getAssetsIcon("logout.svg"),
      closed: true,
      className: "",
      showWebsocketStatus: false,
    },
  ];
  const clickMyAccount = useCallback(() => {
    navigate("profile/account");
  }, [navigate]);

  const handleDropdownItemClick = useCallback(
    (/** @param {import('../components/UserAvatar').DropdownItem} dropdownItem */ dropdownItem: { key: string }) => {
      if (dropdownItem.key === "myAccount") {
        logEvent("my_account_access");
        clickMyAccount();
      }
      if (dropdownItem.key === "logout") {
        fotProcessManager.logout();
      }
    },
    [navBarUserInfoProps]
  );
  return (
    <UserAvatarV2
      className={navBarUserInfoProps.className}
      dropdownItems={items}
      userDisable={navBarUserInfoProps.disable}
      onDropdownItemClick={handleDropdownItemClick}
    />
  );
}
