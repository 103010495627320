"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockInfoFunction = void 0;
const FunctionBase_1 = require("../../storyV2/function/FunctionBase");
const StockInfoModel_1 = require("../../storyV2/model/StockInfoModel");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
const TextGenFunction_1 = require("../../storyV2/function/TextGenFunction");
const SplitOutputFunction_1 = require("../../storyV2/function/SplitOutputFunction");
const ArrayUtil_1 = require("../../util/ArrayUtil");
const Meta_1 = require("../../cutil/Meta");
const ErrorUtil_1 = require("../../api/ErrorUtil");
const StringUtil_1 = require("../../util/StringUtil");
class StockInfoFunction extends FunctionBase_1.FunctionBase {
    async run(input, identityInfo) {
        if (identityInfo?.fromCustomFunction === true) {
            return this.runFromCustomFunc(input, identityInfo);
        }
        const res = await this.getStockInfo(input, identityInfo);
        const info = StockInfoModel_1.StockInfoModel.create(res);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.STOCK, info);
    }
    async runFromCustomFunc(input, identityInfo) {
        /// 调用api生成股票号
        const textGenFunc = new TextGenFunction_1.TextGenFunction(this.brainClient, this.authStore, this.socketRestClient);
        textGenFunc.url = undefined;
        textGenFunc.domain = undefined;
        textGenFunc.identifierType = "file";
        textGenFunc.identifierValue = "brain_studios_xxx_brain_studios_extract_stock.model";
        textGenFunc.streaming = false;
        const funcResponse = await textGenFunc.run(input, identityInfo);
        const data = funcResponse.data;
        const textFuncDataSource = await data.promise();
        if (!(textFuncDataSource instanceof FunctionBase_1.FuncDataSource)) {
            throw new Meta_1.Meta("Stock function run error", ErrorUtil_1.ErrorUtil.defaultErrorMsg);
        }
        /// 拆分为股票号数组
        const text = textFuncDataSource.value;
        const splitFunc = new SplitOutputFunction_1.SplitOutputFunction(this.brainClient, this.authStore, this.socketRestClient);
        const splitFuncRes = await splitFunc.run(text, identityInfo);
        let textArr = splitFuncRes.data;
        //去掉股票号码数字前缀
        textArr = textArr.map(x => {
            return StringUtil_1.StringUtil.removeNumberPrefix(x);
        });
        /// 数组去重
        textArr = ArrayUtil_1.ArrayUtil.deDuplication(textArr, item => {
            return item;
        });
        /// 分别调用api生成股票数据
        const requests = textArr.map(value => {
            return this.getStockInfo(value, identityInfo).then(res => {
                const info = StockInfoModel_1.StockInfoModel.create(res);
                return info;
            });
        });
        const res = await Promise.all(requests);
        return new FunctionBase_1.FuncResponse(FunctionBase_1.FuncResponseType.STOCK_GROUP, res);
    }
    async getStockInfo(input, identityInfo) {
        const result = await this.brainClient.openAi.getStockInfo(input, FunctionUtil_1.FunctionUtil.createAbortUuid(identityInfo));
        return result?.data;
    }
}
exports.StockInfoFunction = StockInfoFunction;
