import { MobileProjectsView } from "@views/thinking-layout-editor/Home/Project/Mobile/MobileProjectsView";
import styles from "./ExamplePage.module.scss";
import { useDidMount } from "imagica-uikit/dist/hooks/useDidMount";

export function ExamplePage(): JSX.Element {
  useDidMount(() => {
    console.log("ExamplePage mounted");
  });
  return (
    <div className={styles.example}>
      {/* <h1 className={styles.title}>ExamplePage</h1> */}
      <MobileProjectsView />
    </div>
  );
}
