import { Spin } from "antd";
import style from "../components-css/UserAvatar.module.css";
import useGetUserAvatarUrl from "../../custom-hooks/useGetUserAvatarUrl";
import func from "@uikit/func";
import { LoadingOutlined } from "@ant-design/icons";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function UserAvatar() {
  const { userAvatarUrl, userLoading } = useGetUserAvatarUrl();

  return (
    <div className={style["avatar-box"]}>
      {!userLoading ? (
        !func.isEmpty(userAvatarUrl) ? (
          <img className={style["user-avatar"]} src={userAvatarUrl} data-testid="user-avatar-img" />
        ) : (
          <img
            className={style["user-avatar"]}
            src={`${settings.S3CDN}${settings.viewAssetsPath}default.jpg`}
            data-testid="user-avatar-img"
          />
        )
      ) : (
        <Spin indicator={antIcon} />
      )}
    </div>
  );
}
