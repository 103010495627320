/**
 * 序列化
 */
export class PreviewSerialize {
  static serialize<T = any>(value: T | T[]): string {
    return JSON.stringify(value);
  }

  static unserialize(value: any, defaultValue?: any): any {
    try {
      return JSON.parse(value);
    } catch {
      return defaultValue;
    }
  }

  /**
   * 主要用于过滤掉function类型或一些其他不需要的数据
   * @param value
   * @returns
   */
  static reset<T = any>(value: T): T {
    return PreviewSerialize.unserialize(PreviewSerialize.serialize(value));
  }
}
