import { LoadingOutlined, SendOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import func from "@uikit/func";
import style from "./ChatBox.module.css";
import { useChat } from "./useChat";
import { useChatWithReactFlow } from "./useChatWithReactFlow";
import Markdown from "./CodeSnippet";

const typeLabel = {
  user: {
    label: "User",
    flag: "<#USER>",
  },
  assistant: {
    label: "Assistant",
    flag: "<#ASSISTANT>",
  },
};

type ChatBoxProps = {
  chatBoxParam: any[];
  nodeId: string;
  textAreaValue: Array<any>;
  fontSize?: string;
  isResized: boolean;
  isSelected: boolean;
  isOpen?: boolean;
};

export type TypeLabelKey = "user" | "assistant";
const ChatBox: React.FC<ChatBoxProps> = props => {
  const isShare = useSelector((state: any) => state.fot.isShare);

  const prevAttribute = useRef<any>({});
  const currEdge = useRef<any>({});
  const sourceNode = useRef<any>("");

  const {
    submitValue,
    setSubmitValue,
    submitLoading,
    regenerateLoading,
    onPressEnter,
    clickRegenerateBtn,
    chatList,
    setChatList,
    chatBoxListRef,
  } = useChat({
    prevAttribute,
    currEdge,
    sourceNode,
    nodeId: props.nodeId,
  });

  const { init } = useChatWithReactFlow({
    prevAttribute,
    currEdge,
    sourceNode,
    chatList,
    setChatList,
    ...props,
  });

  const clickRestartBtn = (): void => {
    init();
  };

  useEffect(() => {
    if (props?.isOpen) {
      init();
    }
  }, [props?.isOpen]);

  return (
    <div className={`${style["white-frame"]}`}>
      <div className={style["chat-box"]}>
        <div className={`${style["chatbox-content"]} ${!props.isResized && !isShare ? style["max-chatbox"] : ""}`}>
          {/* list区域选中后不能拖拽，以便选中文本和滚动文本 */}
          <div className={`${style["chatbox-list"]} ${props.isSelected ? "nowheel nodrag" : ""}`} ref={chatBoxListRef}>
            {chatList.map((x, idx) => {
              return (
                <div
                  key={idx}
                  className={`${style["chatbox-item"]} ${
                    x.type === "assistant" ? style["chatbox-assistant-item"] : ""
                  }`}
                >
                  <span className={style["chatbox-type"]}>
                    {typeLabel[x.type as TypeLabelKey]?.label || "Assistant"}
                  </span>
                  <span className={style["chatbox-text"]}>
                    <Markdown markdown={x.value} />
                  </span>
                </div>
              );
            })}
          </div>

          <div className={style["chatbox-footer"]}>
            {!func.isEmpty(chatList) ? (
              <>
                <Button
                  icon={<SyncOutlined />}
                  loading={regenerateLoading}
                  onClick={clickRegenerateBtn}
                  disabled={!chatList.some(x => x.type === "user")}
                >
                  Regenerate
                </Button>
                <Button
                  onClick={clickRestartBtn}
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Restart Conversation
                </Button>
              </>
            ) : null}

            <Input
              className={style["chatbox-input"]}
              value={submitValue}
              onChange={e => setSubmitValue(e.target.value)}
              allowClear
              size="large"
              placeholder="Send a message..."
              onPressEnter={onPressEnter}
              suffix={
                !submitLoading ? (
                  <SendOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      transform: "rotateZ(-45deg)",
                    }}
                    onClick={onPressEnter}
                  />
                ) : (
                  <LoadingOutlined />
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatBox;
