import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class ShowNavPageStoreState {
  showNavPage = false;
  isShowSmallScreenSideBar = false;
  copyWith(param: Partial<Omit<ShowNavPageStoreState, "copyWith">>) {
    const newState = new ShowNavPageStoreState();
    newState.showNavPage = param.showNavPage ?? this.showNavPage;
    newState.isShowSmallScreenSideBar = param.isShowSmallScreenSideBar ?? this.isShowSmallScreenSideBar;
    return newState;
  }
}

export class ShowNavPageStore extends StoreBase<ShowNavPageStoreState> {
  constructor() {
    super(() => new ShowNavPageStoreState());
  }

  setDefaultState(showNavPage: boolean) {
    this.state.showNavPage = showNavPage;
  }

  setShowNavPage = (showNavPage: boolean) => {
    this.emit(this.state.copyWith({ showNavPage }));
  };
  setShowSmallScreenSideBar = (isShowSmallScreenSideBar: boolean) => {
    this.emit(this.state.copyWith({ isShowSmallScreenSideBar }));
  };
}
