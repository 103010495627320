import styles from "./DomainName.module.scss";
import React, { useEffect, useRef } from "react";
import { PreviewPaneBloc } from "@uiview/views/PreviewPane/PreviewPaneBloc";
import { useCreation } from "ahooks";
import {
  DomainNameState,
  DomainCheckStatus,
  DomainNameBloc,
} from "@uiview/views/PreviewPane/Settings/DomainName/DomainNameBloc";
import isBlank from "@sedan-utils/is-blank";
import { PowerMaskView } from "@uiview/views/PowerMask/PowerMaskView";
import { InstructionView } from "./InstructionView";
import { useSelector } from "react-redux";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { PowerMaskUitl } from "@uiview/views/PowerMask/PowerMaskUtil";
import { AlertView } from "@uiview/views/PreviewPane/Settings/DomainName/AlertView";
import { AModal } from "@uiview/views/AModal/AModal";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { PreviewAppStore, PublishState } from "@uikit/store/PreviewAppStore";
import { PreviewAppValue } from "@uiview/views/PreviewApp/model/PreviewAppValue";

const gql = getIt(ObjectRelationGqlService);

export function DomainName(props: { bloc: PreviewPaneBloc; showSettingPage: boolean }): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const previewAppStore = getIt(PreviewAppStore);
  const { saasUIData } = creatorSaasAppState;
  const saasUIDataDomain = saasUIData.settings?.domain;
  const isPublieshed = !isBlank(previewAppStore.getPublishMetadata()?.link);
  const webConfigState = useStore(getIt(WebConfigStore)).value;
  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);

  const bloc = useCreation(() => {
    return new DomainNameBloc(saasUIDataDomain ?? {}, {
      gql,
      selectedTemplate,
    });
  }, []);
  const state = bloc.state.value;

  const saveDomainData = (domainDataNew?: PreviewApp.CustomDomain): void => {
    const data = updateSaasUIData(domainDataNew || saasUIDataDomain || {}, bloc.state.value);
    bloc.updateDomainData(data);
  };
  const updateSaasUIData = (domainDataNew?: PreviewApp.CustomDomain, domainSubDataNew?: any): PreviewAppValue => {
    const domainData = { ...domainDataNew };
    domainData.data = JsonUtil.parse(JsonUtil.stringify(domainSubDataNew));
    return props.bloc.changeCustom(domainData, saasUIData);
  };
  bloc.updateSaasUIData = updateSaasUIData;
  bloc.saveSaasUIData = saveDomainData;

  const openNewUrl = (url: string): void => {
    const value = url.trim();
    if (value.toLowerCase().startsWith("http://") || value.toLowerCase().startsWith("https://")) {
      window.open(value, "_blank");
    } else {
      window.open(`https://${value}`, "_blank");
    }
  };
  const connected = state.domainCheckStatus === DomainCheckStatus.connected;

  useEffect(() => {
    if (props.showSettingPage) {
      if (saasUIDataDomain) {
        bloc.recoverState(saasUIDataDomain);
      }
      return;
    }
    bloc.state.value = new DomainNameState();
    bloc.stopPolling();
  }, [props.showSettingPage]);

  useEffect(() => {
    return (): void => {
      bloc.stopPolling();
    };
  }, []);

  /**
   * 添加publish状态的监听
   */
  useEffect(() => {
    if (previewAppStore.state.publishState !== PublishState.unpublish) return;
    // 如果存在已保存的domain name数据,需要调用delete删除
    if (!isBlank(state.editFullUrl)) {
      bloc.deleteDomainName();
    }
    // 重置本地的数据
    bloc.resetDomainData();
  }, [previewAppStore.state.publishState]);

  return (
    <PowerMaskView feature={["use_your_own_domain_name"]} featureItem="use_your_own_domain_name">
      <div className={styles.card}>
        <div className={styles.title}>DOMAIN NAME</div>
        {isPublieshed && (
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p>Custom Sub-Domain</p>
              {state.statuButtonText !== "Save" && <SaveAndStateBtn state={state} bloc={bloc} />}
            </div>
            {/*输入框*/}
            <div className={styles.inputContainerContainer}>
              {!connected && (
                <div className={styles.inputContainer} style={{ width: "70px" }}>
                  <input
                    className={styles.input}
                    placeholder={"WWW"}
                    value={state.hostInputValue}
                    readOnly={connected}
                    unselectable={connected ? "on" : "off"}
                    onChange={e => bloc.onHostChanged(e.target.value)}
                    onBlur={() => {
                      bloc.onBlur();
                    }}
                    onFocus={() => {
                      bloc.recordCantShowAlert();
                    }}
                  />
                </div>
              )}
              {!connected && <span style={{ padding: "8px 3px 0" }}>.</span>}
              <div className={styles.inputContainer} style={{ flex: "1" }}>
                {connected && (
                  <div className={styles.inputLinkContainer}>
                    <a className={styles.inputLink} onClick={() => openNewUrl(state.validationUrl)}>
                      {state.validationUrl}
                    </a>
                  </div>
                )}
                {!connected && (
                  <input
                    className={styles.input}
                    style={{ padding: "15px 5px 15px 10px" }}
                    ref={inputRef}
                    placeholder={"YOUR-DOMAIN-NAME.COM"}
                    value={state.inputValue}
                    readOnly={connected}
                    unselectable={connected ? "on" : "off"}
                    onChange={e => bloc.onTextChanged(e.target.value)}
                    onBlur={() => {
                      bloc.onBlur();
                    }}
                    onFocus={() => {
                      bloc.recordCantShowAlert();
                    }}
                    onKeyUp={async e => {
                      if (e.code === "Enter" && inputRef.current) {
                        if (await PowerMaskUitl.disAbleByFeature(["use_your_own_domain_name"])) {
                          return;
                        }
                        bloc.userManualSave();
                        saveDomainData();
                        inputRef.current.blur();
                      }
                    }}
                  />
                )}
                {state.statuButtonText === "Save" && (
                  <SaveAndStateBtn
                    state={state}
                    bloc={bloc}
                    onClickSave={async () => {
                      if (await PowerMaskUitl.disAbleByFeature(["use_your_own_domain_name"])) {
                        return;
                      }
                      bloc.userManualSave();
                      saveDomainData();
                    }}
                  />
                )}
              </div>
            </div>
            {/*step*/}
            <InstructionView
              bloc={bloc}
              webConfigDomain={webConfigState.imagica_publish_setting?.customDomain}
            ></InstructionView>
          </div>
        )}
        {!isPublieshed && <div className={styles.notPublishText}>Publish to customize your domain.</div>}
        {!isBlank(state.domainCheckError) && <div className={styles.errorText}>{state.domainCheckError}</div>}
      </div>
      {bloc.showAlert.value && (
        <AModal open={true} className={styles.alert} onCancel={() => {}} buttons={[]} onOk={() => {}} closable={false}>
          <AlertView
            onCancel={() => {
              bloc.onClickAlertCancel();
            }}
            onConfirm={() => {
              bloc.onClickAlertConfirm();
            }}
          />
        </AModal>
      )}
    </PowerMaskView>
  );
}

function SaveAndStateBtn(props: {
  state: DomainNameState;
  bloc: DomainNameBloc;
  onClickSave?: () => void;
}): React.JSX.Element {
  const getButtonClass = (): any => {
    const status = props.state.domainCheckStatus;
    if (DomainCheckStatus.validating === status) {
      return styles["validatingButton"];
    } else if (DomainCheckStatus.connected === status) {
      return styles["connectedButton"];
    }
    if (!props.bloc.saveButtonEnable.value) {
      return styles["saveButtonDisable"];
    }
    return styles["saveButton"];
  };
  return (
    <button
      className={getButtonClass()}
      onClick={() => {
        props.onClickSave && props.onClickSave();
      }}
    >
      {props.state.statuButtonText}
    </button>
  );
}
