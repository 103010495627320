import { useRef, useState } from "react";
import { Input, message } from "antd";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import func from "@uikit/func";
import style from "./index.module.scss";
import { getIt } from "../../../uikit/getIt";
import { FotClient } from "imagica-corekit/dist/base/api/FotClient";
import { useSelector } from "react-redux";
import { Me } from "imagica-corekit/dist/base/api/userTyped/Me";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";

const TextArea = ({
  textAreaVal,
  setTextAreaVal,
}: {
  textAreaVal: string;
  setTextAreaVal: (param: string) => void;
}) => (
  <Input.TextArea
    className={style["submit-textarea"]}
    value={textAreaVal}
    onChange={e => setTextAreaVal(e.target.value)}
    style={{
      resize: "none",
    }}
    autoSize={{ minRows: 4, maxRows: 8 }}
    placeholder="Examples include shopping, travel, food, maps, messaging, stock purchasing etc."
  />
);
const SuccessIcon = () => <img src={`${settings.S3CDN}${settings.viewAssetsPath}jira-success.png`} />;

class ModalData {
  title: React.ReactNode = null;
  description: string = "";
  saveBtnText: string = "";
  children: any;
  type: "send-jira-success" | "send-jira" | "Unsupported" = "Unsupported";
}
export default function Index({
  showWarningModal,
  setShowWarningModal,
}: {
  showWarningModal: boolean;
  setShowWarningModal: (param: boolean) => void;
}) {
  const brainClient = getIt(FotClient);
  const userInfo = useSelector((state: { editor: { me: Me } }) => state.editor.me);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [textAreaVal, setTextAreaVal] = useState("");

  const [modalData, setModalData] = useState<ModalData>({
    title: "Unsupported Node or Function",
    description:
      "Imagica is a community of creators and we welcome your ideas for additional Functions to improve your experience. Please share your suggestions by submitting a request.",
    saveBtnText: "Submit a request",
    children: null,
    type: "Unsupported",
  });

  const resetState = () => {
    setTextAreaVal("");
    setModalData({
      title: "Unsupported Node or Function",
      description:
        "Imagica is a community of creators and we welcome your ideas for additional Functions to improve your experience. Please share your suggestions by submitting a request.",
      saveBtnText: "Submit a request",
      children: null,
      type: "Unsupported",
    });
    setShowWarningModal(false);
  };

  const createIssueType = async () => {
    const username = userInfo.name;
    if (!username || confirmLoading) {
      return;
    }

    const jiraPermisstion = userInfo.profile?.permissions?.find(item => item.key === "jira_auth_token");
    if (!jiraPermisstion || !jiraPermisstion.value?.length) {
      return;
    }

    const userToken = getIt(FotAuthStore).state.brainToken;

    if (!userToken) {
      throw new Error();
    }

    const request = brainClient.jira.genCreateIssueRequestByUsername({
      username: username,
      description: textAreaVal,
      token: jiraPermisstion.value[0],
      userToken: userToken,
    });

    setConfirmLoading(true);

    try {
      const result = await brainClient.jira.fetchCreateIssue(request);

      // console.log("result.data", result.data);

      setModalData({
        type: "send-jira-success",
        title: (
          <div className={style["success-title"]}>
            <SuccessIcon />
            <div>Congratulations!</div>
          </div>
        ),
        description:
          "Your request has been successfully sent and is now being processed. Our team will review your request as soon as possible and get back to you with any necessary updates or information.",
        saveBtnText: "Done",
        children: null,
      });
    } catch (error) {
      func.messageError(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const submitRequest = () => {
    if (modalData.type === "send-jira-success") {
      resetState();
      return;
    }
    if (modalData.type === "send-jira") {
      setConfirmLoading(true);
      createIssueType();
      return;
    }
    if (func.isEmpty(modalData.children)) {
      setModalData({
        type: "send-jira",
        title: "Submit a request",
        description: "Which additional Functions would you like to see on Imagica?",
        saveBtnText: "Send",
        children: null,
      });
    }
  };

  const ModalChildren = () => {
    if (modalData.type === "send-jira") {
      return <TextArea textAreaVal={textAreaVal} setTextAreaVal={setTextAreaVal} />;
    }
  };

  return (
    <ConfirmModal
      confirmModalState={showWarningModal}
      title={modalData.title}
      description={modalData.description}
      discardBtnText="Cancel"
      saveBtnText={modalData.saveBtnText}
      confirmLoading={confirmLoading}
      // setConfirmModalState={setShowWarningModal}
      handleDiscard={() => setShowWarningModal(false)}
      handleOk={submitRequest}
      className={modalData.type === "send-jira-success" ? style["successModal"] : ""}
      diabledConfirm={modalData.type === "send-jira" && !textAreaVal}
    >
      {/* {modalData.children} */}
      {ModalChildren()}
    </ConfirmModal>
  );
}
