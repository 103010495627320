import classNames from "classnames";
import css from "./index.module.scss";
import { ImagicaCreatorPlan } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { ImagicaFeature } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaFeature";

function CheckedIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#8F79FF" />
      <path d="M5.5 10L8.5 13L14.5 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function DisabledIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#12192B" fillOpacity="0.05" />
      <path
        d="M6.46484 13.5352L13.5359 6.46409"
        stroke="#12192B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5352 13.5352L6.46409 6.46409"
        stroke="#12192B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
type RenderFeatureCell = {
  type?: "title" | "content";
  plan: ImagicaCreatorPlan;
  feature: ImagicaFeature;
};

export function FeatureCell(props: RenderFeatureCell): JSX.Element {
  if (props.type === "title") {
    return (
      <div className={classNames(css.featureCell, css.title)}>
        <div className={css.featureName} title={props.feature.name}>
          {props.feature.name}
        </div>
      </div>
    );
  }

  const disabled = !props.feature.plans.includes(props.plan.value as any);

  return <div className={css.featureCell}>{!disabled ? <CheckedIcon /> : <DisabledIcon />}</div>;
}
