"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CotDataProjectToGraphUtil = exports.CanvasNodeUtil = void 0;
const cotflow_1 = require("../../base/api/cotTyped/cotflow");
const CotTemplateResponseData_1 = require("../cot/typed/CotTemplateResponseData");
class CanvasNodeUtil {
    static isNewEdgeId(id) {
        return /^new-edge-/.test(id);
    }
    static isNodeId(id) {
        return /^editor-/.test(id);
    }
    static isGroupId(id) {
        return /^group-/.test(id);
    }
    static getNodeType(canvasNode, canvasEdges) {
        const id = canvasNode.id;
        for (const edge of canvasEdges) {
            const targetId = edge.target || "";
            const sourceId = edge.source;
            // 仅输入节点
            if (sourceId === id && CanvasNodeUtil.isNewEdgeId(targetId)) {
                return "input";
            }
            // 输出节点
            if (targetId === id) {
                return "output";
            }
        }
        // 其余节点当作输出节点
        return "output";
    }
    /**
     * 获取连接 nodeid 的**边**节点(customNewEdge)
     * @param nodeId
     * @param allNodes
     */
    static getConnectEdge(nodeId, allNodes) {
        return allNodes.find(item => item.data.targetNodeId === nodeId && item.type === "customNewEdge");
    }
    static getNodeById(nodeId, nodes) {
        return nodes.find(item => item.id === nodeId);
    }
    static getCustomNewEdgeById(nodeId, nodes) {
        return nodes.find(item => item.id === nodeId && item.type === "customNewEdge");
    }
    static getCustomNewNodeById(nodeId, nodes) {
        return nodes.find(item => item.id === nodeId && item.type === "customNode");
    }
}
exports.CanvasNodeUtil = CanvasNodeUtil;
/**
 * Graph 数据转换层
 *
 * 1. project 可能存在单个 node，没有关联,直接当作输出
 * 2. group node 暂时不支持（需要后续支持）
 */
class CotDataProjectToGraphUtil {
    static createGrpah(project) {
        const projectNodes = project.v3?.nodes || [];
        const projectEdges = project.v3?.edges || [];
        const graph = new cotflow_1.Graph();
        graph.nodes = CotDataProjectToGraphUtil.getGraphNodes(projectNodes, projectEdges);
        graph.edges = CotDataProjectToGraphUtil.getGraphEdges(projectNodes, projectEdges);
        return graph;
    }
    // 数据生成
    /**
     * 生成 Data 数据
     * @param canvasNode
     * @returns
     */
    static genGraphNodeData(canvasNode, projectNodes) {
        const data = new cotflow_1.Data();
        const connnectEdge = CanvasNodeUtil.getConnectEdge(canvasNode.id, projectNodes);
        data.id = canvasNode.id;
        // 需要测试
        data.content = canvasNode.data.textAreaValue;
        // {
        //   text: "",
        // };
        data.name = canvasNode.data.inputTitle;
        data.description = canvasNode.data.placeholder;
        data.function_type = connnectEdge?.data?.lineParam.enterText;
        data.identifier = connnectEdge?.data?.lineParam?.identifier;
        data.position = this.genGraphNodePosition(canvasNode);
        data.node_type = canvasNode.data.displayType; // 还有 Unsupported 等类型
        // 下列是一些暂时性的默认值
        data.function_description = ""; // 可暂时为默认值
        data.executed = false; // 暂时为 false
        data.modality = "Text"; // 暂时为默认值
        data.mode = "default"; // 暂时为默认值
        data.realtime = false; // 暂时为默认值
        data.value = ""; // FIXME: 可能是 enum FunctionName 的值
        return data;
    }
    /**
     * 生成 position
     * @param canvasNode
     * @returns
     */
    static genGraphNodePosition(canvasNode) {
        const position = new cotflow_1.Position();
        position.x = canvasNode.position.x;
        position.y = canvasNode.position.y;
        // project
        position.type = "pixels";
        return position;
    }
    /**
     * 上一级连接过来的所有节点
     * @param canvasNode
     * @returns
     */
    static genGraphNodeInputs(canvasNode, projectNodes) {
        const customNewEdge = CanvasNodeUtil.getConnectEdge(canvasNode.id, projectNodes);
        if (!customNewEdge) {
            return [];
        }
        const flows = customNewEdge?.data?.flows || [];
        return flows.map(flow => flow.sourceNodeId);
    }
    /**
     * 无类型节点直接当作 output
     * @returns  {'userinput' |'content'}
     */
    static genGraphNodeType(canvasNode, projectEdges) {
        const canvasNodeType = CanvasNodeUtil.getNodeType(canvasNode, projectEdges);
        const graphNodeType = canvasNodeType ? CotDataProjectToGraphUtil.graphNodeTypeMaps[canvasNodeType] : "";
        return graphNodeType || CotDataProjectToGraphUtil.graphNodeTypeMaps["output"];
    }
    //  Graph 数据转换
    static getGraphEdges(projectNodes, projectEdges) {
        const result = [];
        projectEdges.forEach(edge => {
            // 得到边id对应的 custom，如果没有则说明只是普通 line，则过滤掉
            const customNewEdge = CanvasNodeUtil.getCustomNewEdgeById(edge.target, projectNodes);
            if (customNewEdge) {
                const graphEdge = new cotflow_1.Edge();
                graphEdge.id = edge.id;
                graphEdge.source = edge.source;
                graphEdge.sourceHandle = edge.sourceHandle;
                graphEdge.target = customNewEdge?.data.targetNodeId;
                graphEdge.targetHandle = edge.targetHandle;
                graphEdge.type = "smoothstep";
                result.push(graphEdge);
            }
        });
        return result;
    }
    /**
     * FIXME: 需要考虑三种类型，边，节点和组
     * @returns
     */
    static getGraphNodes(projectNodes, projectEdges) {
        const canvasCustomNodes = projectNodes.filter(item => item.type === "customNode");
        return canvasCustomNodes.map(canvasNode => {
            const graphNode = new cotflow_1.Node();
            graphNode.id = canvasNode.id;
            graphNode.data = CotDataProjectToGraphUtil.genGraphNodeData(canvasNode, projectNodes);
            graphNode.inputs = CotDataProjectToGraphUtil.genGraphNodeInputs(canvasNode, projectNodes);
            // FIXME: 来源暂不清楚(暂时没用)
            graphNode.interface_position = 0;
            graphNode.position = CotDataProjectToGraphUtil.genGraphNodePosition(canvasNode);
            graphNode.type = CotDataProjectToGraphUtil.genGraphNodeType(canvasNode, projectEdges);
            return graphNode;
        });
    }
}
exports.CotDataProjectToGraphUtil = CotDataProjectToGraphUtil;
CotDataProjectToGraphUtil.graphNodeTypeMaps = {
    input: CotTemplateResponseData_1.NodeType.USERINPUT,
    output: CotTemplateResponseData_1.NodeType.CONTENT,
};
