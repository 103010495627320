import isBlank from "@sedan-utils/is-blank";
import { getIt } from "@uikit/getIt";
import { logEvent } from "@uikit/service/amplitude";
import { AISaasLogoIcon } from "@uiview/views/AISaas/AISaasPreview/icons";
import { AModal } from "@uiview/views/AModal/AModal";
import { Popup } from "@uiview/views/BluePrint/Popup";
import { useCreation } from "ahooks";
import { Spin } from "antd";
import { motion } from "framer-motion";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { ReadyStatus } from "imagica-corekit/dist/base/cutil/ReadyStatus";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { CotFlow } from "imagica-corekit/dist/cases/cot/CotFlow";
import { PreviewService } from "imagica-corekit/dist/cases/preview/PreviewService";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChatPanel } from "./ChatPanel";
import styles from "./Cot.module.scss";
import { CotBloc } from "./CotBloc";
import { CotUtil } from "./CotUtil";
import { ListData, ListPanel } from "./ListPanel";
import { ShareToAppModal } from "./ShareToAppModal";
import * as constants from "./constants";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { SubscriberService } from "@uikit/imagicaApp/SubscriberService";
import { CreateService } from "@uikit/service/CreateService";
import { SaveCreateService } from "imagica-corekit/dist/cases/saveService/SaveCreateService";
import store from "@store/index";
import { FotSaveEvent } from "@uikit/service/FotSaveEvent";

const variants = {
  normal: { opacity: 1 },
  creating: { opacity: 0 },
};

export function Cot(): React.JSX.Element {
  const createService = getIt(CreateService);
  const cotFlow = getIt(CotFlow);
  const previewService = getIt(PreviewService);
  const subscriberService = getIt(SubscriberService);
  const urlParam = useParams();

  const bloc = useCreation(() => new CotBloc(), []);

  const webConfigStore = getIt(WebConfigStore);
  const result = useStore(webConfigStore).value;
  if (result.readyStatus === ReadyStatus.PENDING) {
    getIt(WebConfigStore).get();
  }

  const selectedUI = "saas";

  const [animate, setAnimate] = useState<"normal" | "creating">("normal");

  const handleClick = async (item: ListData): Promise<void> => {
    bloc.state.creating.value = true;
    setAnimate("creating");

    bloc.state.sharableLink.value = undefined;

    const result = await cotFlow.getTemplate(item.label);

    const template = CotUtil.generateData(result, item.label);

    const updated_project = await createService.createProject(template);
    logEvent("create_project_for_mobile", {
      cot_prompt: item.label,
    });
    const userMe = store.getState().editor.me as any;
    const [sharedProject] = await getIt(SaveCreateService).publishProject({
      attributes: updated_project,
      scopedProject: updated_project,
      author: {
        id: userMe.id,
        name: userMe.name,
        objectId: userMe.objectId,
      },
      saveEvent: getIt(FotSaveEvent),
    });

    const link = await previewService.getLink({
      index: sharedProject?.index,
      structureLinkParams: {
        uuid: sharedProject.uuid,
        appId: "appId-1",
        enablePreviewApp: true,
        selectedUI,
        type: "ai_app",
      },
    });

    // eslint-disable-next-line
    const match = link.match(/[\?&]q=([^&]*)/);

    const qValue = match && match[1];
    if (qValue) {
      bloc.state.guest_uuid = qValue;
    }
    await tryPromise(subscriberService.getSubscriberParamsByQ(qValue));
    const newUrl = `${window.location.origin}${window.location.pathname}#/editor`;

    bloc.state.sharableLink.value = `${newUrl}?q=${qValue}`;
    bloc.state.creating.value = false;
    bloc.state.question.value = constants.APP_GENERATION_IS_COMPLETE;
    setAnimate("normal");
  };

  const handleClose = (): void => {};

  useEffect(() => {
    if (isBlank(urlParam.attrIndexer) === false) {
      const popup = new Popup(UpSupportedModal as React.FC<any>);
      popup.open();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handlePageBack = (): void => {
      // 此处写你想要触发的事件
      logEvent("back_create_project_for_mobile", { guest_uuid: bloc.state.guest_uuid });
    };
    // 监听浏览器的 popstate 事件，即页面返回事件
    window.addEventListener("popstate", handlePageBack);
    // 在组件卸载时移除事件监听
    return (): void => {
      window.removeEventListener("popstate", handlePageBack);
    };
  }, []);

  const sharableLink = bloc.state.sharableLink.value;
  const placeholder = isBlank(sharableLink)
    ? constants.TRY_THESE_EXAMPLE_STARTER_PROMPTS
    : constants.TRY_ANOTHER_PROMPT;

  return (
    <div className={styles.cotWrapper}>
      {/* TODO: Need product confirmation to add or not Log out  */}
      {/* <div className={styles.logout} onClick={handleClickLogout}>
        Log out
      </div> */}
      <div className={styles.logo}>
        <AISaasLogoIcon />
      </div>
      <ChatPanel bloc={bloc} onClose={handleClose} startMotionComplete={true} />
      <motion.div initial={variants.normal} variants={variants} animate={animate} transition={{ duration: 0.5 }}>
        <LinkToButton link={sharableLink} />
        <div className={styles.placeholder}>{placeholder}</div>
        {(result.readyStatus === ReadyStatus.LOADING || result.readyStatus === ReadyStatus.PENDING) && (
          <div className={styles.spinBox}>
            <Spin />
          </div>
        )}
        {result.readyStatus === ReadyStatus.DONE && (
          <ListPanel onClick={handleClick} options={get(result, "cot.examples", [])} />
        )}
        {sharableLink && <ShareToAppModal />}
      </motion.div>
    </div>
  );
}

export function LinkToButton(props: { link?: string }): React.JSX.Element | null {
  const handleClick = (): void => {
    window.open(props.link);
  };

  if (isBlank(props.link)) {
    return null;
  }

  return (
    <div className={styles.linkToButtonWrap}>
      <div className={styles.linkToButton} onClick={handleClick}>
        View the generated app <img alt="" src={`${settings.S3CDN}${settings.viewAssetsPath}linkTo.svg`} />
      </div>
    </div>
  );
}

function UpSupportedModal(props: { onClose: () => void; onOk: () => void }): React.ReactNode {
  return (
    <AModal title={""} open={true} onCancel={props.onClose} onOk={props.onOk} buttons={[]}>
      <div>
        Projects can only be opened from a laptop or desktop version for optimal functionality and user experience.
        Mobile devices are not currently supported.
      </div>
    </AModal>
  );
}
