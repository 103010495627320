import { ApolloClient, InMemoryCache } from "@apollo/client";
import { HttpLink } from "apollo-link-http";
import ApolloLinkTimeout from "apollo-link-timeout";

import func from "@uikit/func";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { getIt } from "@uikit/getIt";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { ProjectStore } from "imagica-corekit/dist/base/store/ProjectStore";

export function apollo_gql(url, param = {}) {
  const { noNeedTimeOut, userToken } = param;
  const localToken = getIt(FotAuthStore).state.brainToken;
  const projectStore = getIt(ProjectStore);

  const token = !func.isEmpty(userToken) ? `Bearer ${userToken}` : `token ${localToken}`;
  const guestToken = sessionStorage.getItem("GUEST_TOKEN");
  // const queryId = store.state.scenesave.queryTxtId
  // const brainInternal = store.state.admin.brainInternal
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const headers = {
    "X-Brain-User-Tz": tz,
  };
  if (userToken || localToken) {
    headers.Authorization = token;
  } else if (guestToken) {
    headers["Brain-Guest-User-Key"] = guestToken;
  }

  if (!func.isEmpty(projectStore.attrIndexer)) {
    headers["X-Brain-Imagica-Id"] = projectStore.attrIndexer;
  }

  let httpLink = new HttpLink({
    uri: `${settings.API}${url}`,
    headers,
  });

  // if(!util.isEmpty(queryId)) {
  //   headers['X-Brain-Exec-Id'] = queryId
  // }
  // if(brainInternal) {
  //   headers['X-Brain-Internal'] = brainInternal
  // }
  const memoryCache = new InMemoryCache({
    possibleTypes: {
      xyz: ["abc", "def"],
    },
  }).restore();

  if (!noNeedTimeOut) {
    const timeoutLink = new ApolloLinkTimeout(15000); // 15 second timeout
    httpLink = timeoutLink.concat(httpLink);
  }

  return new ApolloClient({
    link: httpLink,
    cache: memoryCache,
    connectToDevTools: true,
  });
}
