import { PreviewContentUtil } from "./PreviewContentUtil";
import { cloneDeep, eachRight, find, get, isArray, merge, set, unionBy } from "lodash";
import { PREVIEW_MERGE_UPLOAD_EXCLUDE_PATHS } from "./const";
import { Node } from "reactflow";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { PreviewAppValue } from "./model/PreviewAppValue";

export class PreviewAppValueMergeUtil {
  static merge<T>(value: T, target: Partial<T>): T {
    // 保证返回一个新的 value
    return merge(cloneDeep(value), target);
  }

  static mergeValue<T extends PreviewAppValue>(value: T, target: Partial<T>): T {
    // 先合并一次
    const newValue = merge(value, target);

    // FIXME: 这是 saasuidat 到 appList 中最后一次保证 input 不重复的地方
    if (target.input) {
      // const targetIds = map(target.input, "id");
      // const newInput = newValue.input.filter(item => targetIds.includes(item.id));
      // newValue.input = newInput;
      // ??? 兜底方案，防止 input 重复，!!! 直接深拷贝一份input，并且过滤掉重复的 input
      newValue.input = unionBy(cloneDeep(target.input), "id");
    }

    if (target.output) {
      // const targetIds = target.output.map(item => {
      //   return item.results[0].previewId;
      // });
      // const newOutput = newValue.output.filter(item => {
      //   const nodeId = item.results[0].previewId;
      //   return targetIds.includes(nodeId);
      // });
      // newValue.output = newOutput;
      // ??? 兜底方案，防止o utput 重复，!!! 直接深拷贝一份output，并且过滤掉重复的 output
      newValue.output = PreviewAppValueMergeUtil.unionOutput(cloneDeep(target.output));
    }

    return newValue;
  }

  /**
   * 合并 node 节点，可排除不合并的字段
   * @param target
   * @param source
   * @param excludePathList
   * @returns
   */
  static mergeNode<T extends Node>(target: T, source: T, excludePathList?: string[]): T {
    const newNode = PreviewAppValueMergeUtil.merge(target, source);

    // 排除指定值
    if (isArray(excludePathList)) {
      excludePathList.forEach(path => {
        const targetValue = get(target, path);
        set(newNode, path, targetValue);
      });
    }

    return newNode;
  }

  /**
   * 合并 canvas 上最新 nodes 节点数据到 指定 inputList
   * @param inputList
   * @param current
   * @returns
   */
  static mergeNodesInput(inputList: any[], current: Node<any>[]): any[] {
    const resultList: any[] = [];
    inputList.forEach(input => {
      const target = find(current, ["id", input.id]);
      if (target) {
        // FIXME: functions 需要 在 input 中过滤掉
        resultList.push(
          PreviewAppValueMergeUtil.mergeNode(
            input,
            target,
            // 当输入时 upload 时，上传内容不应该被合并，应保持不变
            ReactFlowNodeUtil.isUploadNode(input) ? PREVIEW_MERGE_UPLOAD_EXCLUDE_PATHS : undefined
          )
        );
      }
      // 不存在的节点不增加直接过滤掉
    });
    return resultList;
  }

  /**
   * 合并 canvas 上最新 nodes 节点数据到 指定 previewList
   *
   * - previewList 是 output 转换之后的数据
   *
   * @param outputList
   * @param nodes
   * @returns
   */
  static mergeNodesOutput<T extends AISaasOutput.ThoughtType>(
    outputs: T[],
    nodes: Node<any>[],
    saasUIData: PreviewAppValue
  ): T[] {
    // 统计 outpuList 中所有 node id,group id 比较特殊有多个子节点
    // 但不能按顺序依次将原来的节点用新的节点替换，因为会存在一个问题就是，current 中已经没有指定节点了，group id需要当作一个节点看
    const processedGroups = {} as Record<string, boolean>;
    let resultOutputList = [] as T[];
    outputs.forEach(output => {
      // 处理 组节点
      const groupId = output.groupId;
      if (ReactFlowNodeUtil.isGroupNodeId(groupId)) {
        const isProcessed = processedGroups[groupId];
        if (isProcessed) {
          return;
        }

        const outputList = PreviewContentUtil.getPreviewOutputByGroupNode({
          groupChildeNodes: ReactFlowNodeUtil.getSubNodesByGroupId(groupId, nodes),
          getResult: true,
          output: [output],
          addContentId: "",
          isShare: true, // 这里的数据需要保存或发布，相当于是web页上看到的内容，所以固定传true
        });
        resultOutputList = resultOutputList.concat(outputList as T[]);
        processedGroups[groupId] = true;
        return;
      }

      // 处理 单个节点
      const nodeId = get(output, ["results", "0", "nodeId"]) as string | undefined;
      if (!nodeId) {
        // 正常情况不应该到这里
        return;
      }

      const targetNode = ReactFlowNodeUtil.getNodeById(nodeId, nodes);
      // 节点已经不存在，直接跳过
      if (!targetNode) {
        return;
      }

      const outputList = PreviewContentUtil.getPreviewOutputBySingleNode({
        saasUIData: saasUIData,
        output: [output],
        node: targetNode,
        addContentId: "",
        isShare: true, // 这里的数据需要保存或发布，相当于是web页上看到的内容，所以固定传true
      });

      resultOutputList = resultOutputList.concat(outputList as T[]);
    });
    return resultOutputList;
  }

  /**
   * 合并 canvas 上最新nodes 节点数据到 指定 app
   * - 包含 input 和 ouput
   * @param target
   * @param current
   */
  static mergeNodes(target: PreviewAppValue, current: Node<any>[]): PreviewAppValue {
    return {
      ...target,
      output: PreviewAppValueMergeUtil.mergeNodesOutput(target.output, current, target),
    };
  }

  /**
   * 合并 canvas 上最新nodes 节点数据到 一组 app list 中
   * @param appList
   * @param nodes
   */
  static mergeNodesWithAppList(appList: PreviewAppValue[], nodes: Node<any>[]): PreviewAppValue[] {
    return appList.map(app => PreviewAppValueMergeUtil.mergeNodes(app, nodes));
  }

  /**
   * 对 output 去重
   *
   * 去重时可优先使用后面的元素
   *
   * 1. 每个 node 只能出现一次
   * 2. gourpNode 的子node 最多可以出现两次，因为可以 group 子node和 group 同时添加
   *
   * 简单判断就是用 外层的 groupId + results[0].nodeId 作为唯一key 判断是否重复
   * - 当有多个相同 nodeId 时，如果有 groupID 则表示通过group增加，如果没有则表示单个增加
   */
  static unionOutput<T extends AISaasOutput.ThoughtType>(ouputList: T[], level: "right" | "left" = "right"): T[] {
    function getUnionKey(a: T): string {
      const aGroupId = get(a, "groupId", "");
      const aNodeId = get(a, ["results", "0", "nodeId"]);
      const unionKey = aGroupId + aNodeId;
      return unionKey;
    }

    if (level === "left") {
      return unionBy(ouputList, getUnionKey);
    }

    const result: T[] = [];
    const unioMap: Record<string, boolean> = {};
    eachRight(ouputList, output => {
      const key = getUnionKey(output);
      if (!unioMap[key]) {
        result.unshift(output);
        unioMap[key] = true;
      }
    });
    return result;
  }
}
