import { motion } from "framer-motion";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { CotInputBloc } from "./CotInputBloc";
import styles from "./OperationList.module.scss";
import { OPTION_PLACEHOLDER_WHEN_ENTERING, OPTION_PLACEHOLDER_WHEN_SUGGESTING } from "./constants";

const variants = {
  open: { opacity: 1, top: "10px" },
  closed: { opacity: 0, top: "-50px" },
};

export const OperationList = ({
  bloc,
  onClick,
}: {
  bloc: CotInputBloc;
  onClick: (item: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const inputSwitching = bloc.inputSwitching.value;
  const operationList = bloc.operationList.value;

  const [styleState, setStyleState] = useState({ display: "block" });
  const [activeItem, setActiveItem] = useState<number>();

  const handleClick = (query: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setActiveItem(index);
    onClick(query, event);
  };

  useEffect(() => {
    if (inputSwitching === true) {
      setActiveItem(undefined);
    }
  }, [inputSwitching]);

  if (bloc.creating.value === true) {
    return null;
  }

  const placeholder =
    isEqual(operationList, bloc.defaultOperationList.value) === true
      ? OPTION_PLACEHOLDER_WHEN_ENTERING
      : OPTION_PLACEHOLDER_WHEN_SUGGESTING;

  return (
    <motion.div
      className={styles.motion}
      initial={variants.closed}
      animate={inputSwitching === true ? "open" : "closed"}
      variants={variants}
      transition={{
        duration: 0.3,
      }}
      onAnimationComplete={() => setStyleState({ display: inputSwitching === true ? "block" : "none" })}
      onAnimationStart={() => setStyleState({ display: "block" })}
    >
      <div className={styles["content-box"]} style={styleState}>
        <div className={styles["bottom-background"]}></div>
        <div className={styles["assistant-title"]}>{placeholder}</div>
        <div className={styles["starter-prompts-box"]}>
          {operationList.map(({ label, value }, index) => {
            return (
              <div
                style={activeItem === index ? { opacity: 0 } : undefined}
                data-amplitude={"cot_prompt:" + label}
                key={index}
                className={styles["optional-text"]}
                onClick={e => handleClick(label, e, index)}
              >
                {label}
              </div>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};
