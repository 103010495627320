import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
require("dayjs/locale/en");

export class ShareAccountUtils {
  static formatTime(time: number): string {
    const date = dayjs.unix(time);
    return date.locale("en").format("MMMM D, YYYY");
  }
}
