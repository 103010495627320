import { ProjectUtil } from "imagica-corekit/dist/cases/util/ProjectUtil";
import store from "@store/index";
import func from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { logEvent } from "@uikit/service/amplitude";
import { AppSnapshotService } from "@uikit/service/AppSnapshotService";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { EdgeRunService } from "@uikit/edgeRun/EdgeRunService";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { ShareAppService } from "@uikit/service/ShareAppService";
import { NewOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { RunEdgeAddNodeToPreview } from "@uikit/service/RunEdgeAddNodeToPreview";

export class EdgeRunAll {
  constructor(
    private creatorNodesStore: CreatorNodesStore,
    private creatorRefStore: CreatorRefStore,
    private creatorEdgesStore: CreatorEdgesStore,
    private fotSaveService: FotSaveService,
    private creatorSaasAppStore: CreatorSaasAppStore,
    private fotReduxStore: FotReduxStore,
    private edgeRunService: EdgeRunService,
    private shareAppService: ShareAppService,
    private fotHomeStore: HomeStore,
    private creatorStoreMethods: CreatorStoreMethods,
    private newOnboardingFlowStore: NewOnboardingFlowStore,
    private runEdgeAddNodeToPreview: RunEdgeAddNodeToPreview,
    private projectRunStore: ProjectRunStore
  ) {}

  public async clickRunAllBtn(sourceNodeArr?: any, isFromPreview = false): Promise<void> {
    this.creatorRefStore.prevRunAllNodesContent.current = this.creatorNodesStore.getNodes().map(x => ({
      id: x.id,
      textAreaValue: x.data.textAreaValue,
      displayType: x.data.displayType,
    }));

    const emptyInput = ProjectUtil.emptyInput(
      store.getState().studio.selectedTemplate,
      this.creatorEdgesStore.getEdges(),
      store.getState().fot.variableList,
      this.fotSaveService.getSaveDataV3
    );
    const emptyAddDataSource = ProjectUtil.emptyAddDataSourceParams(
      this.creatorNodesStore.getNodes(),
      this.creatorSaasAppStore.state.saasUIData?.input,
      isFromPreview
    );

    if (emptyAddDataSource) {
      func.customMsg({
        content: "Need Add Data Source",
        type: "warning",
      });
      return;
    }
    if (emptyInput && !isFromPreview) {
      func.customMsg({
        content: "Need input value",
        type: "warning",
      });
      return;
    }

    const customNewEdgeArr = this.creatorNodesStore
      .getNodes()
      .filter(x => x.type === "customNewEdge")
      .map(x => x.id);
    this.fotReduxStore.setNeedDisableEdgeIds(customNewEdgeArr);

    try {
      this.projectRunStore.setRunAllLoading(true);
      await this.edgeRunService.corekitRun({
        isFromPreview,
        isRunAll: true,
        onUpdateNode: this.shareAppService.setRunAllUpdateData,
        onErrorClosure: () => {
          this.projectRunStore.setRunAllLoading(false);
        },
      });

      this.fotHomeStore.cleanGroupCOEData();

      this.creatorNodesStore.setNodes(l => {
        return l.map((x: any) => {
          if (x.type === "customNode") {
            return {
              ...x,
              data: {
                ...x.data,
                showGroupCollapseData: false,
              },
            };
          }
          return x;
        });
      });
      // 需要先移动
      this.runEdgeAddNodeToPreview.firstRunAllAddNodeToPreview(customNewEdgeArr[0]);

      this.creatorStoreMethods.onNodeContentChange(
        HomeMethodsUtil.getStartEndNodesContentArr(
          this.creatorRefStore.prevRunAllNodesContent.current,
          undefined,
          this.creatorNodesStore.state.nodes
        )
      );

      const data = this.fotSaveService.getSaveDataV3(
        store.getState().studio.selectedTemplate,
        this.creatorEdgesStore.getEdges(),
        store.getState().fot.variableList,
        {}
      );
      const appMetadata = store.getState().editor.subscriberParamsData as any;
      data.index = appMetadata.guest_user_key || appMetadata.attributes?.guest_user_key;
      data.app_session_id = this.getQueryParam("app_session_id");
      const snapsotService = new AppSnapshotService();
      snapsotService.buildProjectData(data).createAppSnapshot();
    } catch (error) {
      console.error(error);
    } finally {
      this.projectRunStore.setRunAllLoading(false);
      this.newOnboardingFlowStore.showNewOnboardingFlowTips(this.creatorNodesStore.getNodes);
    }

    const eventProperties = {
      source_nodes: sourceNodeArr,
    };
    logEvent("run_all", eventProperties);
  }

  getQueryParam(name: string): string | null {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get(name);
  }
}
