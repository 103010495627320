import { useCallback, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Button, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DesignPanel } from "./DesignPanel";
import { CarouselRef } from "antd/lib/carousel";
import styles from "./DesignBox.module.scss";
import classNames from "classnames";

type DesignBoxProps = {
  name: string;
  onCenter: () => void;
  onClose: (open: boolean) => void;
};

type CarouselBoxProps = {
  showPanel: boolean;
};

type ButtonGroupProps = {
  handleClickOwnDesign: () => void;
  handleClickApplyDesign: () => void;
} & CarouselBoxProps;

export function DesignBox(props: DesignBoxProps) {
  const [showPanel, setShowPanel] = useState(false);
  const handleCloseDesignBox = () => {
    setShowPanel(false);
    props.onClose(false);
  };
  const handleClosePanel = useCallback(() => {
    setShowPanel(false);
  }, []);
  const handleClickApplyDesign = useCallback(() => {
    if (showPanel) {
      setShowPanel(false);
    } else {
      props.onClose(false);
    }
  }, [showPanel]);
  const handleClickOwnDesign = useCallback(() => {
    setShowPanel(true);
    props.onCenter();
  }, []);
  return (
    <div className={styles.designBox}>
      <div className={styles.header}>
        <div className={styles.name}>{props.name}</div>
        <div className={styles.closeIcon} onClick={handleCloseDesignBox}></div>
      </div>
      <CarouselBox showPanel={showPanel} />
      <ButtonGroup
        showPanel={showPanel}
        handleClickOwnDesign={handleClickOwnDesign}
        handleClickApplyDesign={handleClickApplyDesign}
      />
      {showPanel &&
        createPortal(<DesignPanel onClose={handleClosePanel} />, document.getElementsByClassName("react-flow")[0])}
    </div>
  );
}

function ButtonGroup(props: ButtonGroupProps) {
  return (
    <div className={styles.buttonGroup}>
      <Button
        shape="round"
        type="primary"
        className={classNames(
          styles.button,
          styles.buttonApply,
          props.showPanel ? styles.buttonApplyAble : styles.buttonApplyDisable
        )}
        onClick={props.handleClickApplyDesign}
      >
        Apply Design
      </Button>
      {props.showPanel === false && <p className={styles.wrapWord}>Or</p>}
      {props.showPanel === false && (
        <Button
          shape="round"
          type="primary"
          className={classNames(styles.button, styles.buttonCreate)}
          onClick={props.handleClickOwnDesign}
        >
          Create your own Design
        </Button>
      )}
    </div>
  );
}

function CarouselBox(props: CarouselBoxProps) {
  const carouselRef = useRef<CarouselRef | null>(null);
  const handleCarouselClick = (next: boolean) => {
    if (next) {
      carouselRef.current?.next();
    } else {
      carouselRef.current?.prev();
    }
  };

  if (props.showPanel) {
    return (
      <div className={styles.carousel}>
        <div className={classNames(styles.selectedSlide)}></div>
      </div>
    );
  }

  return (
    <div className={styles.carousel}>
      <div>
        <Carousel centerMode infinite autoplay dots={{ className: styles.dots }} ref={carouselRef}>
          {[1, 2, 3, 4, 5].map(slide => (
            <div key={slide} className="slide"></div>
          ))}
        </Carousel>
        <div className={styles.carouselToolbox}>
          <LeftOutlined onClick={() => handleCarouselClick(false)} />
          <span>Video Card</span>
          <RightOutlined onClick={() => handleCarouselClick(true)} />
        </div>
      </div>
    </div>
  );
}
