"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalAdapterUtil = exports.GlobalAdapter = void 0;
const RestClient_1 = require("../cutil/RestClient");
const ErrorUtil_1 = require("./ErrorUtil");
const StreamClient_1 = require("../cutil/StreamClient");
class GlobalAdapter {
    constructor() { }
    errorHandler(error) {
        if (error instanceof RestClient_1.RestResponse) {
            // 提前注入错误处理函数，会被 API 的 parseError 重写
            error.parseError(res => {
                const errorFromApi = ErrorUtil_1.ErrorUtil.parseRestResponse(res);
                return errorFromApi || ErrorUtil_1.ErrorUtil.defaultErrorMsg;
            });
        }
        else if (error instanceof StreamClient_1.StreamInternalError) {
            error.parseError(res => {
                if (error.jsonData.name === ErrorUtil_1.ErrorUtil.abortErrorName) {
                    return ErrorUtil_1.ErrorUtil.defaultErrorMsg;
                }
                const errorFromApi = ErrorUtil_1.ErrorUtil.parseErrorData(error.jsonData).result;
                return errorFromApi || ErrorUtil_1.ErrorUtil.defaultErrorMsg;
            });
        }
        else if (error instanceof RestClient_1.RestInternalError) {
            error.parseError(res => {
                /// 基本库直接抛出的错误，不用解析，直接显示默认文本
                return ErrorUtil_1.ErrorUtil.defaultErrorMsg;
            });
        }
    }
    get headers() {
        return {};
    }
    get baseUrl() {
        return "";
    }
    get contentType() {
        return RestClient_1.RestContentType.any;
    }
}
exports.GlobalAdapter = GlobalAdapter;
class GlobalAdapterUtil {
    static isCustomApiSupported(headers) {
        const supportNodeTypes = ["uploadImage", "baseVideo", "audio", "pdfFile", "table", "text", "html", "askBrain"];
        const supportMimeTypes = [
            "image/",
            "video/",
            "audio/",
            "application/pdf",
            "text/csv",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/plain",
            "text/html",
            "application/json",
        ];
        const nodeType = headers.get(this.customApiNodeTypeHeaderName);
        const contentType = headers.get("Content-Type");
        if (nodeType && supportNodeTypes.includes(nodeType)) {
            return true;
        }
        if (contentType) {
            for (let mimeType of supportMimeTypes) {
                const regex = new RegExp(`^${mimeType}`);
                if (regex.test(contentType)) {
                    return true;
                }
            }
        }
        return false;
    }
}
exports.GlobalAdapterUtil = GlobalAdapterUtil;
GlobalAdapterUtil.customApiNodeTypeHeaderName = "X-Brain-Imagica-Node";
