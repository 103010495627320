import "../components-css/Recipe.css";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

const getValue = props => {
  let obj = {};
  const needHandleAngel = ["Time", "Servings", "Difficulty"];
  props.content.forEach(x => {
    if (needHandleAngel.includes(x.angle)) {
      obj[x.angle] = x.results?.[0]?.content || "N/A";
    }
  });
  return obj;
};

function Recipe(props) {
  return (
    <section className="recipeCardRecipe">
      <img src={`${settings.S3CDN}${settings.viewAssetsPath}receipt-img.png`} />
      <div className="recipeCook-oma">How to cook your dish</div>
      <div>
        {getValue(props).Time} to cook • {getValue(props).Servings} servings
      </div>
      <div>{getValue(props).Difficulty} difficulty</div>
    </section>
  );
}

export default Recipe;
