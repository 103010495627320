import { RootState } from "@store/index";
import { useSelector } from "react-redux";
import IdeaList from "@views/thinking-layout-editor/IdeaList";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import style from "./index.module.css";
import { createIdeaListStore } from "@uikit/store/creatorIdeaListStore";
import { EdgeRunTypeStock } from "@uikit/edgeRun/EdgeRunTypeStock";
import { getIt } from "@uikit/getIt";

export function CreatorIdeaList(): React.ReactNode {
  const checkNodeArr = useSelector((state: RootState) => state.fot.checkNodeArr);

  const { ideaList, ideaLoading, showIdeaList, shrinkIdeaList } = useImagincStore(createIdeaListStore).value;

  const edgeRunTypeStock = getIt(EdgeRunTypeStock);

  if (showIdeaList && checkNodeArr.length === 1) {
    if (shrinkIdeaList) {
      return (
        <IdeaList
          ideaLoading={ideaLoading}
          ideaList={ideaList}
          postAIDescription={edgeRunTypeStock.postAIDescription}
        />
      );
    }
    return (
      <span
        className={`${style["shrink-addicon"]} iconfont icon-tianjia`}
        onClick={() => createIdeaListStore.setShrinkIdeaList(true)}
      ></span>
    );
  }

  return null;
}
