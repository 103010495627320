"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStorageManagerImpl = void 0;
const StorageManager_1 = require("../base/port/StorageManager");
class LocalStorageManagerImpl extends StorageManager_1.StorageManager {
    clear() {
        return localStorage.clear();
    }
    getItem(key) {
        return localStorage.getItem(key) ?? undefined;
    }
    removeItem(key) {
        return localStorage.removeItem(key);
    }
    setItem(key, value) {
        return localStorage.setItem(key, value);
    }
}
exports.LocalStorageManagerImpl = LocalStorageManagerImpl;
