import { useEffect, useRef } from "react";
import { useUpdate } from "ahooks";
export function useSubscribable(subscribable) {
  var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  var foreUpdate = useUpdate();
  var callbackRef = useRef();
  if (callback) {
    callbackRef.current = callback;
  } else {
    callbackRef.current = foreUpdate;
  }
  useEffect(function () {
    var subscription = subscribable === null || subscribable === void 0 ? void 0 : subscribable.on(function (msg) {
      if (callbackRef.current) {
        callbackRef.current(msg);
      }
    }, true);
    return function () {
      subscription === null || subscription === void 0 || subscription.off();
    };
  }, [subscribable]);
}