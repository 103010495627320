import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import classNames from "classnames";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import useFeatureTags from "@custom-hooks/useFeatureTags";
import { useSaasTheme } from "@custom-hooks/useSaasTheme";

import Preview from "./preview";
import Setting from "./setting";
import styles from "./index.module.scss";
import { useSignal } from "@preact/signals-react";
import { useDispatch, useSelector } from "react-redux";
import func, { matchMobile } from "@uikit/func";
import { fotActions } from "@store/fot";
import AutoResizablePanel from "../AutoResizablePanel/AutoResizablePanel";
import { CHOOSE_FORMAT_CONFIRM } from "../PreviewApp/const";
import { DomUtil } from "imagica-uikit/dist/base/util/DomUtil";
import { ADD_TO_CONTENT_FLAG } from "../AISaas/AddToContent/addTooltip";
import { editorActions } from "@store/editor";
import { getIt } from "@uikit/getIt";
import { PreviewPaneStore } from "./PreviewPaneStore";
import { ProjectNetDataStore, ProjectUpdateParam } from "@base/ProjectNetDataStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppValueLangUtil } from "../PreviewApp/PreviewAppValueLangUtil";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { PreviewComposeState } from "@uikit/service/PreviewComposeState";

const gql = getIt(ObjectRelationGqlService);
const creatorSaasAppStore = getIt(CreatorSaasAppStore);
interface Props {
  closePreviewApp: () => void;
}

const PreviewPane: React.FC<Props> = props => {
  const isMobile = matchMobile();
  const dispatch = useDispatch();
  const { closePreviewApp } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const [previewKey, setPreviewKey] = useState(0);
  const silderState = useSignal(true);
  const previewRef = useRef(null);
  const previewComposeState = getIt(PreviewComposeState);
  const previewAppStore = getIt(PreviewAppStore);
  const previewAppState = useImagicaStore(previewAppStore).value;
  const isInHistory = useMemo(() => {
    return previewComposeState.isInHistory();
  }, [
    previewAppState.historyShowStatus,
    previewAppState.publishShowStatus,
    previewAppState.previewShowStatus,
    previewAppState.settingShowStatus,
  ]);

  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);
  /**
   * setting页面使用
   * themes: 主题分类
   * saasTheme: 主题类型
   * setSaasTheme: 修改主题
   * fontSize: 字体分类
   * setFontSize: 修改字体
   */
  const homeStore = getIt(HomeStore);
  const homeStoreState = useImagicaStore(homeStore).value;
  const previewState = useImagicaStore(previewStore).value;

  const { themes, saasTheme, setSaasTheme } = useSaasTheme(contentRef);

  const projectUpdateStore = getIt(ProjectNetDataStore);

  const featureTags = useFeatureTags();

  const setIsStaticMode = (val: any): void => {
    dispatch(editorActions.setIsStaticMode(val));
  };

  const previewPaneStore = getIt(PreviewPaneStore);
  const previewPaneState = useImagicaStore(previewPaneStore).value;

  const setDisableAddPreview = (val: boolean): void => {
    dispatch(fotActions.setDisableAddPreview(val));
  };

  // sutd-1722: published_metadata
  const disabledUnPublish = func.isEmpty(previewAppStore.getPublishMetadata()?.link);
  // const disabledUnPublish = util.isEmpty(selectedTemplate?.published_metadata?.link);

  useEffect(() => {
    // 这里添加一定延迟是因为homeuseplugin页面的selectedUI监听存在可能后触发的情况导致数据被清除
    setTimeout(() => {
      if (previewAppStore.state.previewShowStatus && creatorRefStore.sendToAppStagingData?.cb) {
        // 原因是直接使用isStaticApp判断可能会存在已经渲染了preview App，isStaticApp的数据状态还是最初的状态，改为了直接用数据判断是否是静态app
        if (PreviewAppValueLangUtil.isStaticApp(creatorSaasAppStore.state.saasUIData)) {
          creatorRefStore.sendToAppStagingData?.outputCb?.();
        } else {
          creatorRefStore.sendToAppStagingData.cb();
        }
        creatorRefStore.sendToAppStagingData = {
          cb: (): void => {},
        };
      }
    }, 100);
  }, [previewAppState.previewShowStatus]);

  useEffect(() => {
    if (previewAppStore.state.previewShowStatus && previewState.showPreviewPanel) return;
    setDisableAddPreview(true); // add content状态重置
  }, [previewAppState.previewShowStatus, previewState.showPreviewPanel]);

  // 显示隐藏preview页面

  const setSettingStatus = (status: boolean): void => {
    previewPaneStore.settingPageStatus(status);
  };

  // 显示隐藏setting页面
  const inOrOutSetting = (bol: boolean): void => {
    if (featureTags.enablePublishV3) {
      setSettingStatus(bol);
      return;
    }
    previewAppStore.setSettingShowStatus(bol);
  };

  const backPublishPage = useCallback(() => {
    /**
     * 通过修改preview组件的key，生成新的fiber
     * 来实现重置preview组将状态
     */
    previewAppStore.setPreviewShowStatus(false);
    previewAppStore.setSettingShowStatus(false);
    previewPaneStore.settingPageStatus(false);
    previewAppStore.changePreviewShowStatus(false);
    previewAppStore.setHistoryShowStatus(true);
    setPreviewKey(n => n + 1);
  }, []);

  // preview页面返回有权限的页面
  const backPreview = (value?: string): void => {
    // BSF-7245 创建新的app时，如果preview list 之前没有创建过，应该直接打开preview，否则保存原逻辑
    if (value === CHOOSE_FORMAT_CONFIRM && previewStore.state.previewAppList.length === 1) {
      previewAppStore.previewToSaasUiData(previewStore.state.previewAppAcivieId);
      previewAppStore.setPreviewShowStatus(true);
      previewAppStore.changePreviewShowStatus(false);
      previewAppStore.setHistoryShowStatus(false);
      return;
    }
    // 有app list页面的tag时，返回app list页面，没有则保持原逻辑
    previewAppStore.setHistoryShowStatus(true);
    previewAppStore.changePreviewShowStatus(false);
    previewAppStore.setPreviewShowStatus(false);
    // stud-1722: async saasUiDataToPreview
    previewAppStore.saasUiDataToPreview();
    setIsStaticMode(false); // 在返回列表页时重置staticmode 状态
    // FIXME: 清空当前激活的 app, 判断是否时 chat app 时会根据当前的 saasuidata 判断, saasuidata 没有清空则数据没有发生变化 canvas addto 按钮状态会出现错误
    previewAppStore.clearCurrentPreviewApp();
  };

  const computedClass = (showStatus: null | boolean): string => {
    return classNames({
      [styles.paneIn]: showStatus,
      [styles.paneOut]: !showStatus && showStatus !== null,
    });
  };

  //  组件卸载时，重置各组件的显示状态
  useEffect(() => {
    return () => {
      previewAppStore.setHistoryShowStatus(true);
      previewAppStore.changePreviewShowStatus(null);
      previewAppStore.setPreviewShowStatus(null);
      previewAppStore.setSettingShowStatus(null);
      previewPaneStore.settingPageStatus(false);
    };
  }, [previewPaneStore]);

  useEffect(() => {
    /**
     * 打开此组件时，判断具体显示哪一个页面
     * 没有app list数据可时, 显示choose format页面
     * 以上都不满足时显示preview页面
     * 还有其他逻辑可在这里加判断（tips: 这里修改判定会影响send to app添加到preview的展示，需要同时考虑）
     * */
    // 如果是通过其他方式打开了某一个页面，则不走以下逻辑
    if (
      previewAppStore.state.publishShowStatus ||
      isInHistory ||
      previewAppStore.state.previewShowStatus ||
      previewAppStore.state.settingShowStatus
    )
      return;
    if (previewStore.state.previewAppList.length) {
      previewAppStore.setHistoryShowStatus(true);
    } else {
      const isSelectUI = !!previewStore.state.selectedUI;
      if (isSelectUI) {
        previewAppStore.setPreviewShowStatus(true);
      } else {
        previewAppStore.changePreviewShowStatus(true);
      }
    }
  }, []);

  const clearClickHereStatus = useCallback(() => {
    previewStore.setAddContentId("");
    previewStore.setNextAddContentId("");
    previewStore.setIsClickAddToContent(false);
  }, []);

  useEffect(() => {
    return () => {
      clearClickHereStatus();
    };
  }, [clearClickHereStatus]);

  //  点击ESC退出add content模式
  const escClickHereEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {
        event.preventDefault();
        clearClickHereStatus();
      }
    },
    [clearClickHereStatus]
  );

  useEffect(() => {
    document.addEventListener("keydown", escClickHereEvent);
    return () => {
      document.removeEventListener("keydown", escClickHereEvent);
    };
  }, [clearClickHereStatus, escClickHereEvent]);

  //  点击除了add content添加和按钮本身其他区域，都退出add content模式
  const removeClickHereStatus = useCallback(
    (event: MouseEvent) => {
      // event.preventDefault();
      if (previewState.isClickAddToContent && !DomUtil.hasAttr(event.target, "data-click", ADD_TO_CONTENT_FLAG)) {
        clearClickHereStatus();
      }
    },
    [clearClickHereStatus, previewState.isClickAddToContent]
  );

  useEffect(() => {
    document.addEventListener("click", removeClickHereStatus);
    return () => {
      document.removeEventListener("click", removeClickHereStatus);
    };
  }, [removeClickHereStatus]);

  useEffect(() => {
    projectUpdateStore.beginAutoUpdate(() => {
      previewAppStore.saasUiDataToPreview();
      return new ProjectUpdateParam(previewStore.state.previewAppList, selectedTemplate, gql);
    });
    return () => {
      if (projectUpdateStore.hasUnmount()) return;
      // 保存saasuidata 到 previewAppList 然后清空
      previewAppStore.saasUiDataToPreview();
      previewAppStore.clearCurrentPreviewApp();
      projectUpdateStore.stopAutoUpdate();
      projectUpdateStore.updateProjectData(
        new ProjectUpdateParam(previewStore.state.previewAppList, selectedTemplate, gql)
      );
    };
  }, []);

  if (isMobile && featureTags.disableMobilePage) {
    return (
      <div ref={contentRef} className={classNames(styles.previewPane, styles.previewPaneMobile)}>
        <div ref={previewRef} className={styles.previewBox} data-testid="preview-preview">
          <Preview
            {...props}
            key={previewKey}
            isShowPublish={disabledUnPublish}
            backPreview={backPreview}
            inOrOutSetting={inOrOutSetting}
            silderState={silderState}
            previewContainer={contentRef}
            setSaasTheme={setSaasTheme}
            showSetting={previewPaneState.settingPageStatus}
          />
        </div>
      </div>
    );
  }

  return (
    <AutoResizablePanel
      position="top-right"
      className={classNames(
        styles.nodePreviewPane,
        { [styles.nodePreviewPaneCollapse]: previewState.previewCollapseState },
        silderState.value ? styles.silder : "",
        previewPaneState.openTitleIconTooltip ? styles.paneZIndex : "",
        previewPaneState.settingPageStatus ? styles.coverSettingPane : "",
        { [styles.coverHomeNavBar]: previewPaneState.upgradeZindex }
      )}
      data-testid="nodePreviewPane"
      data-creator="PreviewApp"
    >
      <div ref={contentRef} className={classNames(styles.previewPane)}>
        {homeStoreState.featureTags.disableMultiRoundChatOnboarding ? (
          <img
            className={styles.closePreviewApp}
            onClick={() => closePreviewApp()}
            src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`}
            width="20"
            alt="icon"
          />
        ) : null}
        {/*<div className={computedClass(historyShowStatus)} data-testid="preview-history">*/}
        {/*  <PreviewHistory addNewPreview={addNewPreview} selectPreview={onSelectPreviewApp} />*/}
        {/*</div>*/}
        {/*<div className={computedClass(publishShowStatus)} data-testid="preview-publish">*/}
        {/*  <Publish backPreview={backPreview} inOrOutPreview={inOrOutPreview} {...props} />*/}
        {/*</div>*/}
        <div
          ref={previewRef}
          className={classNames(computedClass(true), styles.previewBox)}
          data-testid="preview-preview"
        >
          <Preview
            {...props}
            key={previewKey}
            isShowPublish={disabledUnPublish}
            backPreview={backPreview}
            inOrOutSetting={inOrOutSetting}
            silderState={silderState}
            previewContainer={contentRef}
            setSaasTheme={setSaasTheme}
            showSetting={previewPaneState.settingPageStatus}
          />
        </div>

        {/* v2 setting */}
        <div className={computedClass(previewAppState.settingShowStatus)} data-testid="preview-setting">
          <Setting
            {...props}
            inOrOutSetting={inOrOutSetting}
            backPublishPage={backPublishPage}
            themes={themes}
            saasTheme={saasTheme}
            setSaasTheme={setSaasTheme}
          />
        </div>
      </div>
    </AutoResizablePanel>
  );
};

export default PreviewPane;
