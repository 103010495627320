"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Polling = void 0;
const LangUtil_1 = require("./LangUtil");
class Polling {
    constructor(spaceTime, task, callback) {
        this.spaceTime = spaceTime;
        this.task = task;
        this.callback = callback;
        this.stopStatus = false;
    }
    /**
     * 开始执行
     * @param task
     * @param callback
     */
    start() {
        this.stopStatus = false;
        const execute = async () => {
            this.task.dirty();
            const result = await (0, LangUtil_1.tryPromise)(this.task.get());
            // 这个时候已经停止了，那么结果也不需要更新出去
            if (this.stopStatus) {
                console.log("data response, user stop");
                return;
            }
            this.timer = setTimeout(() => {
                execute();
            }, this.spaceTime);
            this.callback(result);
        };
        execute().then(r => { });
    }
    /**
     * 停止执行
     */
    stop() {
        this.stopStatus = true;
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
}
exports.Polling = Polling;
