import React, { useRef } from "react";
import { DisplayType } from "../typing";
import { useNodesTitle } from "@custom-hooks/HomeUsePluginRoot/useNodesTitle";
import { Input, InputRef } from "antd";
import styles from "./NodeTitle.module.scss";

type NodeTitleProps = {
  id: string;
  inputTitle?: string;
  displayType: DisplayType;
  isInBlueprint: boolean;
  typeInputRef?: React.RefObject<InputRef>;
};

export function NodeTitle({ id, typeInputRef, isInBlueprint }: NodeTitleProps) {
  const reservedInputRef = useRef<InputRef>(null);
  const inputRef = typeInputRef ?? reservedInputRef;

  const {
    handleBlur,
    handleChange,
    handleFocus,
    inputTitle: value,
    showAllNodesLabel,
    disableInput,
  } = useNodesTitle(id, isInBlueprint);
  if (!showAllNodesLabel()) {
    return null;
  }

  return (
    <Input
      ref={inputRef}
      placeholder="Enter name of the node"
      value={value}
      disabled={disableInput}
      className={styles.NodeTypeInput}
      onChange={handleChange}
      onBlur={handleBlur}
      onPressEnter={() => {
        inputRef.current?.blur();
      }}
      onFocus={handleFocus}
    />
  );
}
