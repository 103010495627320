import { getIt } from "@uikit/getIt";
import { tryPromise, TryResult } from "imagica-corekit/dist/base/cutil/LangUtil";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { computed, ReadonlySignal, signal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { DomainCheckResponse, InstructionItem } from "./InstructionModel";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { GenUtil } from "imagica-corekit/dist/base/cutil/GenUtil";
import { PreviewUtil } from "imagica-corekit/dist/cases/preview/PreviewUtil";
import { Type } from "class-transformer";
import { SettingCustomDomain } from "imagica-corekit/dist/cases/webconfig/domain/ImagicatPublishSetting";
import { Lazyload } from "imagica-corekit/dist/base/cutil/Lazyload";
import { Polling } from "imagica-corekit/dist/base/cutil/Polling";
import { RestResponse } from "imagica-corekit/dist/base/cutil/RestClient";
import { PreviewAppStore, PublishState } from "@uikit/store/PreviewAppStore";
import { PreviewAppValue } from "@uiview/views/PreviewApp/model/PreviewAppValue";

export enum DomainCheckStatus {
  idle = "idle",
  validating = "validating",
  connected = "connected",
}
export enum DoneStatus {
  done = "done",
  refresh = "refresh",
  success = "success",
}
class Params {
  public gql: any;
  public selectedTemplate: any;
}
export class DomainNameState {
  domainCheckStatus: DomainCheckStatus = DomainCheckStatus.idle;
  @Type(() => DomainCheckResponse)
  domainCheckInstructions: DomainCheckResponse | null = null;
  domainCheckError: string = "";
  inputValue: string = "";
  hostInputValue: string = "";
  requestHost: string = "";
  requestDomain: string = "";
  // 手动触发了done 按钮，需要记录下来
  @Type(() => InstructionItem)
  tapDoneItems: InstructionItem[] = [];
  copyWith(params: {
    domainCheckStatus?: DomainCheckStatus;
    domainCheckInstructions?: DomainCheckResponse | null;
    domainCheckError?: string;
    inputValue?: string;
    hostInputValue?: string;
    tapDoneItems?: InstructionItem[];
    open?: boolean;
    requestHost?: string;
    requestDomain?: string;
  }): DomainNameState {
    return GenUtil.copyWith(new DomainNameState(), this, params);
  }

  /**
   * done 按钮的显示状态
   * */
  get doneBtnStatus(): DoneStatus[] {
    const value = this.tapDoneItems;
    return (
      this.domainCheckInstructions?.instructions?.instructions.map(item => {
        const haveTapDone = value.some(ins => ins.equral(item));
        if (this.domainCheckStatus === DomainCheckStatus.connected) {
          return DoneStatus.success;
        }
        if (haveTapDone) {
          if (item.isSuccess) {
            return DoneStatus.success;
          }
          return DoneStatus.refresh;
        }
        return DoneStatus.done;
      }) ?? []
    );
  }
  get editFullUrl(): string {
    let host = this.hostInputValue.trim();
    host = isBlank(host) ? "www" : host;
    return PreviewUtil.combineHostAndDomain(host, this.inputValue);
  }
  get validationUrl(): string {
    let host = this.requestHost.trim();
    host = isBlank(host) ? "www" : host;
    return PreviewUtil.combineHostAndDomain(host, this.requestDomain);
  }
  /**
   * 状态文本
   */
  get statuButtonText(): string {
    const currentStatus = this.domainCheckStatus;
    switch (currentStatus) {
      case DomainCheckStatus.idle:
        return "Save";
      case DomainCheckStatus.validating:
        return "Validating";
      case DomainCheckStatus.connected:
        return "Connected";
    }
    return "";
  }
  getStepData(webconfigCustomDomain?: SettingCustomDomain): [title: string, description: string] {
    const checkResponse = this.domainCheckInstructions;
    const apiHadResponse = checkResponse != null;
    // 获取title
    const getStepTile = (step: number): string => {
      return `Step ${step} of 3`;
    };
    // 获取description
    const getDescription = (step: number): string => {
      switch (step) {
        case 1:
          return webconfigCustomDomain?.stepDefaultText ?? "";
        case 2:
          return checkResponse?.instructions?.description ?? "";
      }
      return webconfigCustomDomain?.stepOfThreeText ?? "";
    };
    // 获取当前已经进行到第几步了
    let step = 1;
    if (apiHadResponse) {
      step = 2;
    }
    const doneStatus = this.doneBtnStatus.filter(item => DoneStatus.done === item);
    if (apiHadResponse && doneStatus.length === 0) {
      step = 3;
    }
    // 根据当前步骤显示不同的文本
    return [getStepTile(step), getDescription(step)];
  }
}

export class DomainNameBloc {
  brainClient: BrainClient = getIt(BrainClient);
  previewAppStore = getIt(PreviewAppStore);
  public state = signal(new DomainNameState());
  public saveSaasUIData?: (domainDataNew?: PreviewApp.CustomDomain) => void;
  public updateSaasUIData?: (domainDataNew?: PreviewApp.CustomDomain, domainSubDataNew?: any) => PreviewAppValue;
  public polling?: Polling<RestResponse<any>>;
  showAlert = signal(false);
  /** 失去焦点，但是某些特殊情况是能弹窗的
   * 当输入框切换的时候，某个输入框失去了焦点
   * 当点击的是save按钮或者回车的
   **/
  public cantShowAlert = false;
  /**
   * 获取guestUserKey
   */
  public guestUserKey = new Lazyload<string>(async () => {
    const link = this.previewAppStore.getPublishMetadata()?.link;
    if (!link) return Promise.reject();
    const id = link.split("q=")[1] || "";
    const res: any = await tryPromise(this.brainClient.domain.guestUserKey(id));
    if (!res.data?.data) {
      return;
    }
    const guestUserKey = res.data.data.guest_user_key;
    return guestUserKey;
  });
  constructor(domainData: PreviewApp.CustomDomain, public params: Params) {}

  /**
   * 还原之前保存的数据
   * @param domainData
   */
  recoverState(domainData: PreviewApp.CustomDomain): void {
    const stateOld: DomainNameState | undefined = JsonUtil.toModelFromType(DomainNameState, domainData.data);
    if (stateOld) {
      // 旧数据
      if (domainData.data?.requestDomain === undefined && domainData.data?.domainCheckStatus !== "idle") {
        console.log("old domain data from bloc: ", domainData);
        stateOld.requestHost = domainData.data?.hostInputValue ?? "";
        stateOld.requestDomain = domainData.data?.inputValue ?? "";
      }
      this.state.value = stateOld;
      if (stateOld.domainCheckStatus !== DomainCheckStatus.connected && !isBlank(stateOld.requestDomain)) {
        this.state.value = this.state.value.copyWith({
          domainCheckStatus: DomainCheckStatus.idle,
          inputValue: stateOld.inputValue,
          hostInputValue: stateOld.hostInputValue,
        });
        this.save();
      }
    }
  }

  /**
   * save button 是否可用
   * */
  saveButtonEnable: ReadonlySignal<boolean> = computed(() => {
    if (!this.validDomain() && this.state.value.domainCheckStatus === DomainCheckStatus.idle) {
      return false;
    }
    return true;
  });

  /**
   * host输入框变化
   * @param text
   */
  onHostChanged(text: string): void {
    this.state.value = this.state.value.copyWith({ hostInputValue: text.toLowerCase() });
    this.stopPolling();
  }

  /**
   * 域名发生变化
   * @param text
   */
  onTextChanged(text: string): void {
    this.state.value = this.state.value.copyWith({ inputValue: text.toLowerCase() });
    this.stopPolling();
  }
  /**
   * 验证域名是否有效
   * */
  validDomain(): boolean {
    // const regExp = /.*/
    // const valid = regExp.test(this.state.inputValue.value)
    // console.log('valid: ',valid,this.state.inputValue.value)
    if (this.state.value.inputValue.trim().length > 0) {
      return true;
    }
    return false;
  }

  /**
   * 点击save按钮
   */
  async save(): Promise<void> {
    if (!this.saveButtonEnable.value || this.state.value.domainCheckStatus !== DomainCheckStatus.idle) {
      console.log("不是一个有效的域名 或者当前不是save 状态");
      return;
    }
    if (isBlank(this.state.value.hostInputValue)) {
      this.state.value = this.state.value.copyWith({ hostInputValue: "www" });
    }
    const newDomainState: DomainNameState = this.state.value.copyWith({
      domainCheckError: "",
      domainCheckStatus: DomainCheckStatus.validating,
    });
    // 如果需要请求的url和已经请求的不一致，那么需要清空之前的数据
    if (newDomainState.editFullUrl !== newDomainState.validationUrl) {
      newDomainState.domainCheckInstructions = null;
    }
    newDomainState.requestDomain = newDomainState.inputValue;
    newDomainState.requestHost = newDomainState.hostInputValue;
    this.state.value = newDomainState;
    this.startPolling();
  }

  /**
   * 点击done按钮
   * @param item
   */
  onClickDone(item: InstructionItem): void {
    const data = this.state.value.tapDoneItems;
    this.state.value = this.state.value.copyWith({
      tapDoneItems: [...data, item],
    });
  }
  userManualSave(): void {
    this.save();
    this.recordCantShowAlert();
  }
  recordCantShowAlert(): void {
    this.cantShowAlert = true;
    setTimeout(() => {
      this.cantShowAlert = false;
    }, 300);
  }
  onBlur(): void {
    // 用延迟做判断，需要结合cantShowAlert字段来处理一些特殊情况。
    setTimeout(() => {
      if (this.cantShowAlert) {
        return;
      }
      const state = this.state.value;
      // 还没有发起过请求，那么也不需要确认修改
      if (isBlank(state.requestDomain)) {
        return;
      }
      // 前后两次没有发生改变，那么只需要重新请求即可
      if (state.editFullUrl.toLowerCase() === state.validationUrl.toLowerCase()) {
        // 文本没有修改，那么应该还原polling 状态
        this.save();
        return;
      }
      this.showAlert.value = true;
    }, 150);
  }
  onClickAlertCancel(): void {
    this.showAlert.value = false;
    const state = this.state.value;
    this.state.value = state.copyWith({
      hostInputValue: state.requestHost,
      inputValue: state.requestDomain,
    });
    this.save();
  }
  onClickAlertConfirm(): void {
    this.showAlert.value = false;
    const state = this.state.value;
    // 传入null不会真正的复制
    const newState = state.copyWith({
      domainCheckInstructions: null,
    });
    newState.domainCheckInstructions = null;
    newState.domainCheckError = "";
    this.state.value = newState;
  }

  /**
   * 保存数据
   * @param updatedSaasUIData
   */
  updateDomainData(updatedSaasUIData: PreviewApp.UISaasValueType): void {
    if (!(this.params.selectedTemplate.v3.UI instanceof Array)) {
      console.log("UI is not array: ", this.params.selectedTemplate.v3);
      return;
    }
    const UI = [...this.params.selectedTemplate.v3.UI];
    const currUIIdx = UI.findIndex((x: any) => x.id === updatedSaasUIData.id);

    UI.splice(currUIIdx, 1, updatedSaasUIData);

    tryPromise(
      this.params.gql.updateObject(
        parseInt(this.params.selectedTemplate.id),
        {
          name: "studio_project",
          attributes: Object.assign({}, this.params.selectedTemplate, {
            ...this.params.selectedTemplate,
            v3: {
              ...this.params.selectedTemplate.v3,
              UI,
            },
          }),
        },
        ""
      )
    );
  }

  deleteDomainName(): void {
    const domainName = this.state.value.editFullUrl;
    this.brainClient.domain.deleteDomain({
      name: domainName,
    });
  }
  resetDomainData(): void {
    const resetObj = {
      domainCheckStatus: DomainCheckStatus.idle,
      domainCheckInstructions: null,
      requestDomain: "",
      requestHost: "",
      domainCheckError: "",
    };
    this.state.value = this.state.value.copyWith(resetObj);
    this.updateSaasUIData?.(undefined, resetObj);
    this.stopPolling();
  }
  /************************** polling 相关 **************************/
  private startPolling(): void {
    this.polling?.stop();
    this.guestUserKey.dirty();
    const lazyLoad = new Lazyload<RestResponse<any>>(() => {
      return this.guestUserKey.get().then(guestUserKey => {
        const domain = this.state.value.editFullUrl;
        return this.brainClient.domain.domainCheck({
          name: domain,
          guest_user_key: guestUserKey,
        });
      });
    });
    this.polling = new Polling<RestResponse<any>>(5000, lazyLoad, tryResult => {
      this.handlePollingResult(tryResult);
    });
    this.polling?.start();
  }
  private handlePollingResult(domainCheckRes: TryResult<RestResponse<any>>): void {
    this.state.value = this.state.value.copyWith({ domainCheckError: "" });
    // 状态码是201，并且保存数据，停掉轮训
    if (domainCheckRes.data && domainCheckRes.data.status === 201) {
      this.state.value = this.state.value.copyWith({
        domainCheckStatus: DomainCheckStatus.connected,
      });
      this.saveSaasUIData && this.saveSaasUIData();
      this.stopPolling();
      return;
    }

    if (domainCheckRes.error) {
      const checkModel = JsonUtil.toModelFromType(DomainCheckResponse, domainCheckRes.error.data);
      // 如果模型转换失败，直接返回
      if (!checkModel) {
        return;
      }
      // 错误码不是400，停掉轮训
      if (domainCheckRes.error?.status !== 400) {
        const errorText = checkModel.getError;
        this.state.value = this.state.value.copyWith({
          domainCheckError: errorText,
          domainCheckStatus: DomainCheckStatus.idle,
          domainCheckInstructions: null,
        });
        this.stopPolling();
        return;
      }
      // 错误码是400，需要继续轮训的
      if (!checkModel.instructions) {
        const errorText = checkModel.getError;
        this.state.value = this.state.value.copyWith({
          domainCheckError: errorText,
          domainCheckStatus: DomainCheckStatus.validating,
          domainCheckInstructions: null,
        });
        this.stopPolling();
        return;
      }
      if (this.previewAppStore.state.publishState !== PublishState.unpublish) {
        this.state.value = this.state.value.copyWith({
          domainCheckInstructions: checkModel,
        });
      }
    }
  }
  stopPolling(): void {
    this.polling?.stop();
    if (this.state.value.domainCheckStatus === DomainCheckStatus.validating) {
      this.state.value = this.state.value.copyWith({
        domainCheckStatus: DomainCheckStatus.idle,
      });
    }
  }
}
