import dayjs from "dayjs";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import "../components-css/Flight.css";

function Flight(props) {
  const getStops = () => {
    const len = props?.flight?.airlines?.length;
    return len ? (len - 1 > 0 ? `${len} stops` : "Nonstop") : "N/A";
  };
  const getTripType = () => {
    const returnItemsLen = props?.flight?.returnItems?.length || 0;
    return returnItemsLen > 0 ? "Roundtrip for 1 traveler" : "Oneway for 1 traveler";
  };

  return (
    <section className="CardHomeFlight">
      {/* 上半部分 */}
      <div className={`FLight-Top ${(props.id + 1) % 2 === 0 ? "Orange-Background" : "Blue-Background"}`}>
        {/* 机场代码 */}
        <div className="FLight-AirCode">
          <span className="AirCode-From">{props.flight.from}</span>
          <img src={`${settings.S3CDN}${settings.viewAssetsPath}Flight-Icon.png`} alt="Flight" />
          <span className="AirCode-To">{props.flight.to}</span>
        </div>
        {/* 城市 */}
        <div className="FLight-Cities">
          <span className="AirCode-FromCity">{props.flight.fromCity}</span>
          <span className="AirCode-ToCity">{props.flight.toCity}</span>
        </div>
        {/* 出发到达时间 */}
        <div className="FLight-Times">
          <p>
            {props.flight?.departureTime?.dateTime
              ? dayjs(props.flight.departureTime.dateTime).format("D MMM, hh:mma")
              : "N/A"}
          </p>
          <p>
            {props.flight?.arrivalTime?.dateTime ? dayjs(props.flight.departureTime.dateTime).format("hh:mma") : "N/A"}
          </p>
        </div>
        {/* 耗时、仓等 */}
        <div className="FLight-Duration-Cabin">
          <p>
            <span className="Duration-Time">{dayjs.duration(props.flight.duration, "seconds").humanize()}</span>
            <span>&nbsp;•&nbsp;</span>
            <span className="Duration-Stop">{getStops()}</span>
          </p>
          <p>Economy</p>
        </div>
      </div>

      {/* 下半部分 */}
      <div className="FLight-Bottom">
        <div className="FLight-Icon-Price">
          <div className="Price-Left">
            <img src={props.flight.airlines[0].icon} alt="airlines" />
            <div className="Price-Info">
              <p>${props.flight.price}</p>
              <p>{getTripType()}</p>
            </div>
          </div>

          <span className="iconfont icon-right"></span>
        </div>

        <div className="Flight-Description">
          <p>{props.flight.description}</p>
        </div>
      </div>
    </section>
  );
}

export default Flight;
