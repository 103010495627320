import React from "react";
import "../views-css/Filter.css";

function Filter(props) {
  const on_select_filter = () => {
    props.delete_angle(props.label);
  };

  return (
    <article className="Filter-Item">
      <p>{props.label}</p>
      <span className="iconfont icon-guanbi" onClick={on_select_filter}></span>
    </article>
  );
}

export default Filter;
