import { animated } from "@react-spring/web";
import { CSSProperties, JSX, useCallback, useEffect } from "react";
import { ApiStatuses, FunctionModel } from "@views/thinking-layout-editor/types";
import ApiProgress from "../ApiProgress";
import style from "../Project/ProjectListViewStyle.module.scss";
import { useCreation } from "ahooks";
import { logEvent } from "@uikit/service/amplitude";
import func from "@uikit/func";
import FunctionItem from "./FunctionItem";
// import Loader from "../Loader";
import { FunctionListBloc } from "./FunctionListBloc";
import { FunctionDropdownItemArgs, FunctionDropdownItems } from "./type";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { SortByView, UIModel } from "../Project/SortBy/SortByView";
import { ListButton } from "../Project/Buttons/ListButton";
import { GridButton } from "../Project/Buttons/GridButton";
import { ListViewHeader } from "../Project/ListViewHeader/ListViewHeader";
import { FunctionCellItem } from "./FunctionCellItem";
import { getIt } from "@uikit/getIt";

export class FunctionListViewProps {
  functionsStyle: CSSProperties = {};
  viewOfMyFunctions: Record<string, any> = {};
  clickModifyFunction: (value: any) => Promise<void> = async () => {};
  handleClickDuplicate: (type: "function" | "project" | undefined, object: any) => Promise<any> = async () => {};
  handleClickDelete: (type: any, id: any, name: any, object?: any) => Promise<any> = async () => {};
}

export function FunctionListView(props: FunctionListViewProps): JSX.Element {
  const projectFunctionStore = getIt(ProjectFunctionStore);
  const projectFunctionState = useImagicaStore(projectFunctionStore).value;

  const bloc = useCreation(
    () => new FunctionListBloc(projectFunctionState.functions),
    [projectFunctionState.functions]
  );
  // FIXME: function loading
  useEffect(() => {
    bloc.apiStatusOfFunctions.value = ApiStatuses.Fulfilled;
  }, []);

  // useEffect(() => {
  //   bloc.apiStatusOfFunctions.value = ApiStatuses.Pending;
  //   const newMyFunctions = imageGenSelectOptions?.filter((item: any) => item.editable);
  //   bloc.myFunctions.value = newMyFunctions;
  //   bloc.apiStatusOfFunctions.value = ApiStatuses.Fulfilled;
  // }, [imageGenSelectOptions]);

  // const isFunctionLoading = useCallback(
  //   (func: FunctionModel) => !!bloc.loadingOfFunction && bloc.loadingOfFunction.id === func.id,
  //   [bloc.loadingOfFunction]
  // );
  const isFunctionLoading = useCallback(
    (func: FunctionModel) => bloc.isFuncLoading.value && bloc.isFuncId.value === func.id,
    [bloc.isFuncLoading.value, bloc.isFuncId.value]
  );

  const handleFunctionClick = useCallback(
    async (func: FunctionModel) => {
      bloc.isFuncId.value = func.id;
      setTimeout(() => {
        logEvent("open_function", {
          function_static_id: func.attrIndexer ?? func.uuid ?? func.id,
          function_name: func.label,
        });
        bloc.loadingOfFunction = func;
        props.clickModifyFunction(func);
        bloc.isFuncLoading.value = false;
      }, 1000);
      bloc.loadingOfFunction = undefined;
      bloc.isFuncLoading.value = true;
    },
    [props.clickModifyFunction]
  );

  const handleFunctionDropdownClick = useCallback(
    async (args: FunctionDropdownItemArgs) => {
      if (args.type === FunctionDropdownItems.Duplicate) {
        const copyFunc = await props.handleClickDuplicate("function", args.func);
        logEvent("duplicate_Function", {
          source_id: args.func.attrIndexer ?? args.func.uuid,
          destination_id: copyFunc.uuid,
        });
        func.messageUtil("Successfully duplicated function", "success");
      }
      if (args.type === FunctionDropdownItems.Delete) {
        logEvent("delete_function_confirmed");
        const isOk = await props.handleClickDelete("function", args.func.relationId, args.func.label, args.func);
        if (isOk) {
          func.messageUtil("The function has been deleted successfully", "success");
          // projectFunctionStore.deleteFunctionById(args.func.id);
        }
      }
    },
    [props.handleClickDuplicate, props.handleClickDelete]
  );

  return (
    <>
      <animated.div
        className={style.view}
        style={{
          ...props.functionsStyle,
          ...props.viewOfMyFunctions,
        }}
      >
        <div className={style.view__title}>
          My Functions
          <div className={style.searchbar}>
            <SortByView
              model={UIModel.web}
              onSelectSortBy={sortType => {
                bloc.sortByRefresh(sortType.value);
              }}
            />
            <ListButton
              isHeightLight={bloc.showList.value}
              onClickBtn={() => {
                bloc.changeListView(true);
              }}
            />
            <GridButton
              isHeightLight={!bloc.showList.value}
              onClickBtn={() => {
                bloc.changeListView(false);
              }}
            />
          </div>
        </div>
        <div className={bloc.showList.value ? style.view__list : style.view__content}>
          {bloc.showList.value ? (
            <>
              <ListViewHeader />
              <ApiProgress status={projectFunctionState.functionLoading} full>
                {bloc.showItems.value.map(item => (
                  <FunctionCellItem
                    key={item.id}
                    func={item}
                    loading={isFunctionLoading(item)}
                    onClick={handleFunctionClick}
                    onNameChange={() => {}}
                    onDropdownClick={handleFunctionDropdownClick}
                  />
                ))}
              </ApiProgress>
            </>
          ) : (
            <ApiProgress status={projectFunctionState.functionLoading} full>
              {bloc.showItems.value.map(item => (
                <FunctionItem
                  key={item.id}
                  func={item}
                  loading={isFunctionLoading(item)}
                  onClick={handleFunctionClick}
                  onNameChange={() => {}}
                  onDropdownClick={handleFunctionDropdownClick}
                />
              ))}
            </ApiProgress>
          )}
        </div>
      </animated.div>
    </>
  );
}
