"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalContextData = exports.GlobalContextQuery = void 0;
class GlobalContextQuery {
    constructor() {
        this.content = "";
        this.updated_q = "";
    }
}
exports.GlobalContextQuery = GlobalContextQuery;
class GlobalContextData {
    constructor() {
        this.query = [];
    }
}
exports.GlobalContextData = GlobalContextData;
