"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomCodeAdapter = exports.CustomCodeProps = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const AdapterUtil_1 = require("../../storyV2/adapter/AdapterUtil");
const FunctionRegistry_1 = require("../../storyV2/function/FunctionRegistry");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
class CustomCodeProps {
    constructor() {
        this.cssStr = "";
        this.htmlStr = "";
        this.jsStr = "";
    }
}
exports.CustomCodeProps = CustomCodeProps;
class CustomCodeAdapter extends AdapterBase_1.CustomAdapterBase {
    runResponseType() {
        return AdapterBase_1.RunResponseType.CUSTOMCODE_FUNCTION;
    }
    async runNode(node, actionData, story, edge) {
        if (node === undefined) {
            return Promise.reject();
        }
        const adapter = this.funcRegistry.getByRegistName(FunctionRegistry_1.FunctionName.customCode);
        if (this.customFuncDest != undefined) {
            adapter.props = AdapterUtil_1.AdapterUtil.parseCustomCodeProps(this.customFuncDest);
        }
        const response = await adapter.run(node.content, { projectId: story.projectId, edgeId: edge.options.id }, FunctionUtil_1.FunctionUtil.createFuncVariables(story, edge), 1);
        return response.data;
    }
}
exports.CustomCodeAdapter = CustomCodeAdapter;
