import { ResizeParams as ResizeEventParams } from "@reactflow/node-resizer";
import { Spin } from "antd";
import { isArray, map } from "lodash";
import { useEffect, useState } from "react";
import { Food } from "imagica-corekit/dist/base/api/foodTyped/Food";
import { Restaurant, RestaurantResponseData } from "imagica-corekit/dist/base/api/foodTyped/Restaurant";
import { useEnableScroll } from "imagica-uikit/dist/hooks/useEnableScroll";
import { LoadMore } from "imagica-uikit/dist/nodesV2/components/LoadMore";
import NodeWrapper from "../../../views/thinking-layout-editor/nodeTypeComponents/NodeWrapper";
import TitleBar from "@uiview/views/Nodes/components/TitleBar";
import style from "./FoodNode.module.scss";
import { FoodNodeBloc } from "./FoodNodeBloc";

export type FoodNodeType = {
  id?: string;
  isOpenDeleteBox?: boolean;
  resizeEventParams?: ResizeEventParams;
  bloc: FoodNodeBloc;
  content: RestaurantResponseData;
};

export const initialFoodNodeSize = {
  width: 295,
  height: 510,
};

export default function FoodNode({ id, isOpenDeleteBox, resizeEventParams, bloc, content }: FoodNodeType) {
  const { enableScroll, mouseEnterLeave } = useEnableScroll();
  const { width = initialFoodNodeSize.width, height = initialFoodNodeSize.height } = resizeEventParams || {};

  const restaurantList = bloc.restaurantList.value;

  const handleClickLoadMore = () => {
    bloc.getNextPageData();
  };

  useEffect(() => {
    if (isArray(content?.stores)) {
      bloc.resetData(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const noMore = restaurantList.length === 0 || bloc.offset.value > restaurantList.length;

  return (
    <NodeWrapper
      mouseEnterLeave={mouseEnterLeave}
      style={{
        maxWidth: width,
        maxHeight: height,
        height: "100%",
      }}
    >
      <div
        className={`${style["food-content"]} ${enableScroll ? "nowheel" : ""} `}
        style={{
          maxHeight: height,
        }}
      >
        <TitleBar id={id} isOpenDeleteBox={isOpenDeleteBox}>
          User Output
        </TitleBar>

        <div
          className={style["food-box"]}
          style={{
            maxHeight: height - 10,
          }}
        >
          <div>
            <Spin spinning={bloc.loading.value}>
              <p className={style["food-title"]}>{bloc.query.value}</p>
              {map(restaurantList, (store, index: number) => {
                return (
                  <div className={style["store-box"]} key={index}>
                    <div className={style["store-detail"]}>
                      <img src={store.logo} className={style["store-img"]} alt="" />
                      <div className={style["store-info"]}>
                        <p>{store.name}</p>
                        <p>{store.description}</p>
                      </div>
                    </div>
                    <FoodList bloc={bloc} by={store.by} id={store.id} />
                  </div>
                );
              })}
              <LoadMore onClick={handleClickLoadMore} loading={bloc.loading.value} noMore={noMore} />
            </Spin>
          </div>
        </div>
      </div>
    </NodeWrapper>
  );
}

type FoodListPropsType = {
  bloc: FoodNodeBloc;
  by: Restaurant["by"];
  id: Restaurant["id"];
};

const FoodList = ({ bloc, by, id }: FoodListPropsType) => {
  const [loading, setLoading] = useState(false);
  const [foods, setFoods] = useState<Food[]>([]);

  const getFoodList = async () => {
    setLoading(true);
    const data = await bloc.getFoodList({ by, id });
    setLoading(false);
    setFoods(data);
  };

  useEffect(() => {
    getFoodList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={loading}>
      <div className={style["food-list"]}>
        {map(foods, (food: any, index: number) => {
          return (
            <div className={style["food-item"]} key={food.id + index}>
              <img src={food.image_url} className={style["food-img"]} alt="" />
              <div className={style["food-info"]}>
                <p className={style["food-price"]}>${(food.price ?? 0 / 100).toFixed(2)}</p>
                <p className={style["food-name"]}>{food.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Spin>
  );
};

export const FoodNodeWithBloc: React.FC<FoodNodeType> = props => {
  return <FoodNode {...props} bloc={new FoodNodeBloc()} />;
};
