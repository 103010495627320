import { CanvasNodeType } from "@uikit/store/CreatorNodesStore";
import { get, isArray, isString } from "lodash";

export class CanvasNodeList {
  nodes: CanvasNodeType[];
  constructor(nodes: CanvasNodeType[]) {
    this.nodes = nodes;
  }

  getNodes(): CanvasNodeType[] {
    return this.nodes;
  }

  getNodeById(id: string): CanvasNodeType | undefined {
    return this.nodes.find(item => item.id === id);
  }

  removeChildNode(parentId: string, targetChildId: string): void {
    const parentNode = this.getNodeById(parentId);
    if (!parentNode || parentNode.type !== "customGroup") {
      return;
    }

    const parentChildNodes = get(parentNode, "data.childNode", []);
    if (parentNode && isArray(parentChildNodes) && parentChildNodes.length) {
      parentNode.data.childNode = parentChildNodes.filter(item => item !== targetChildId);
    }
  }

  /**
   * 移除节点用ID
   * @param id
   * @param withGroup
   * @returns
   */
  removeById(id: string): CanvasNodeList {
    const target = this.getNodeById(id);

    if (!target) {
      return this;
    }

    // remove `childNode`
    if (target.parentNode) {
      this.removeChildNode(target.parentNode, id);
    }

    this.nodes = this.nodes.filter(item => item.id !== id);

    return this;
  }

  removeByIds(ids: string[]): CanvasNodeList {
    ids.forEach(id => {
      if (isString(id) && id) {
        this.removeById(id);
      }
    });
    return this;
  }
}
