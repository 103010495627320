"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class LightTheme {
}
exports.LightTheme = LightTheme;
LightTheme.theme = {
    chatAiTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.chatAiTheme,
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputLeftLineWidth: "5px",
        inputLeftLineHeight: "40px",
        inputLeftLineBorderRadius: "2px",
        inputLeftLineBackground: "rgba(18, 25, 43, 0.10)",
        inputPaddingLeft: "11px",
    },
};
