import style from "./ChatItem.module.scss";
import func from "@uikit/func";
import classNames from "classnames";
import { useStreamText } from "imagica-uikit/dist/components/StreamText/useStreamText";
import { LottieAnimation } from "@views/components/LottieAnimation";
import generateJson from "../../generate.json";
import { useEffect } from "react";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { getIt } from "@uikit/getIt";

type ChatItemProps = {
  type: string;
  value: string;
  isLastOne?: boolean;
  disableStream: boolean;
  regenerate: () => void;
  isStreamStopped: boolean;
  stop: (content?: string) => void;
  reloadFile: (() => void) | null;
  container: React.RefObject<HTMLElement>;
};

export function ChatItem(props: ChatItemProps) {
  const homePluginStore = getIt(HomePluginStore);
  const isShare = homePluginStore.state.isShare;

  const { streamContent, streaming, streamList, stop } = useStreamText({
    content: props.value,
    isStreamStopped: props.isStreamStopped,
    onStreamingContentStopped: content => {
      props.stop(content);
    },
  });

  useEffect(() => {
    //  把容器滚动条拉到最底部
    const sharedBodyBox = props.container.current;
    if (sharedBodyBox) {
      sharedBodyBox.scrollTop = sharedBodyBox.scrollHeight;
    }
    // eslint-disable-next-line
  }, [streamList.length]);

  useEffect(() => {
    if (props.isStreamStopped) {
      stop(false);
    }
  }, [props.isStreamStopped]);

  const ChatLocation: any = {
    Assistant: "leftItem",
    User: "rightItem",
  };

  const isLastOne = props.isLastOne && (props.type === "Assistant" || props.type === "Error");

  const Item = () => {
    return props.type === "Error" ? (
      <>
        <div className={style.chatValue}>
          <div className={style.errorHint}>An error occurred.</div>
          <span>{streamContent}</span>
        </div>
      </>
    ) : (
      <p className={style.chatValue}>
        {props.isStreamStopped || !streaming || props.disableStream
          ? // _props.disabled || !streaming || isStreamStopped
            props.value
          : streamList.map((value, index) => <span key={index}>{value}</span>)}
      </p>
    );
  };

  const onStop = () => {
    stop(true);
  };

  return (
    <div className={isShare ? style.shareChatItem : style.canvasChatItem}>
      <div className={classNames(style.chatItem, style[ChatLocation[props.type]])}>
        {isLastOne && <LottieAnimation loader={generateJson} className={style.generateAnimation} />}
        <div className={classNames(style.chatInfo, { [style.indent]: isLastOne })}>
          <Item />
          {/* todo: 正在请求时隐藏时间 */}
          {streaming ? null : <p className={style.chatTime}>{func.getCurrentTime()}</p>}
        </div>
        {isLastOne && (
          <div className={style.btns}>
            {!streaming ? <button onClick={() => props.regenerate()}>Regenerate</button> : null}
            {streaming ? <button onClick={() => onStop()}>Stop Generating</button> : null}
            {/* {props.reloadFile && <button onClick={props.reloadFile}>Reload a file</button>} */}
          </div>
        )}
      </div>
    </div>
  );
}
