"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomApiAdapter = exports.CustomApiProps = void 0;
const AdapterBase_1 = require("../../storyV2/adapter/AdapterBase");
const AdapterUtil_1 = require("../../storyV2/adapter/AdapterUtil");
const FunctionRegistry_1 = require("../../storyV2/function/FunctionRegistry");
const FunctionUtil_1 = require("../../storyV2/function/FunctionUtil");
class CustomApiProps {
}
exports.CustomApiProps = CustomApiProps;
class CustomApiAdapter extends AdapterBase_1.CustomAdapterBase {
    dynamicRunResponseTypeFunctionV2() {
        return AdapterBase_1.RunResponseType.FUNCTION_V2;
    }
    dynamicRunResponseType() {
        return AdapterBase_1.RunResponseType.CUSTOM_API;
    }
    async runNode(node, actionData, story, edge) {
        if (node === undefined) {
            return Promise.reject();
        }
        const func = this.funcRegistry.getByRegistName(FunctionRegistry_1.FunctionName.customApi);
        if (this.customFuncDest != undefined) {
            func.props = AdapterUtil_1.AdapterUtil.parseCustomApiProps(this.customFuncDest);
        }
        const response = await func.run(node.content, { projectId: story.projectId, edgeId: edge.options.id, sourceNodeId: node.options.id }, FunctionUtil_1.FunctionUtil.createFuncVariables(story, edge));
        return response.data;
    }
}
exports.CustomApiAdapter = CustomApiAdapter;
