"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClickTracking = exports.Request = exports.User = exports.ConfigInfo = exports.Client = exports.Context = exports.Item = exports.Youtube = exports.YoutubeResponse = void 0;
const class_transformer_1 = require("class-transformer");
class YoutubeResponse {
    constructor() {
        this.apiTime = "";
        this.data = new Youtube();
        this.done = false;
        this.message = "";
        this.name = "";
    }
}
exports.YoutubeResponse = YoutubeResponse;
__decorate([
    (0, class_transformer_1.Type)(() => Youtube)
], YoutubeResponse.prototype, "data", void 0);
class Youtube {
    constructor() {
        this.cache = false;
        this.categories = undefined;
        this.context = new Context();
        this.continuation = "";
        this.empty = false;
        this.items = [];
        this.key = "";
        this.source = "";
        this.sourceLink = undefined;
        this.total_time = undefined;
        this.query = "";
    }
}
exports.Youtube = Youtube;
__decorate([
    (0, class_transformer_1.Type)(() => Context)
], Youtube.prototype, "context", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Item)
], Youtube.prototype, "items", void 0);
class Item {
    constructor() {
        this.description = undefined;
        this.id = "";
        this.length = "";
        this.owner = "";
        this.publishedTime = "";
        this.thumbnailUrl = "";
        this.title = "";
        this.url = "";
        this.viewCount = "";
        this.viewCountValue = 0;
    }
}
exports.Item = Item;
class Context {
    constructor() {
        this.clickTracking = new ClickTracking();
        this.client = new Client();
        this.request = new Request();
        this.user = new User();
    }
}
exports.Context = Context;
__decorate([
    (0, class_transformer_1.Type)(() => ClickTracking)
], Context.prototype, "clickTracking", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Client)
], Context.prototype, "client", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Request)
], Context.prototype, "request", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => User)
], Context.prototype, "user", void 0);
class Client {
    constructor() {
        this.browserName = "";
        this.browserVersion = "";
        this.clientFormFactor = "";
        this.clientName = "";
        this.clientVersion = "";
        this.configInfo = new ConfigInfo();
        this.deviceExperimentId = "";
        this.deviceMake = "";
        this.deviceModel = "";
        this.gl = "";
        this.hl = "";
        this.originalUrl = "";
        this.osName = "";
        this.osVersion = "";
        this.platform = "";
        this.remoteHost = "";
        this.userAgent = "";
        this.userInterfaceTheme = "";
        this.visitorData = "";
    }
}
exports.Client = Client;
__decorate([
    (0, class_transformer_1.Type)(() => ConfigInfo)
], Client.prototype, "configInfo", void 0);
class ConfigInfo {
    constructor() {
        this.appInstallData = "";
    }
}
exports.ConfigInfo = ConfigInfo;
class User {
    constructor() {
        this.lockedSafetyMode = false;
    }
}
exports.User = User;
class Request {
    constructor() {
        this.useSsl = false;
    }
}
exports.Request = Request;
class ClickTracking {
    constructor() {
        this.clickTrackingParams = "";
    }
}
exports.ClickTracking = ClickTracking;
