"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DishRecommendationDataService = void 0;
const CardDataBaseService_1 = require("../../cardDataSearch/dataSerivce/CardDataBaseService");
const FunctionRegistry_1 = require("../../../base/storyV2/function/FunctionRegistry");
const DataTransformerUtil_1 = require("../../cardDataSearch/dataSerivce/DataTransformerUtil");
const LangUtil_1 = require("../../../base/cutil/LangUtil");
const VBDataDishRecommendation_1 = require("../../../base/api/viewBuilderTyped/VBGraphData/VBDataDishRecommendation");
const Extensions_1 = require("../../../base/cutil/Extensions");
class DishRecommendationDataService extends CardDataBaseService_1.CardDataBaseService {
    constructor(functionRegistry, fotClient) {
        super();
        this.functionRegistry = functionRegistry;
        this.fotClient = fotClient;
    }
    async search(params) {
        const findFunction = this.functionRegistry.getByRegistName(FunctionRegistry_1.FunctionName.food);
        if (!findFunction) {
            return Promise.reject("can not find function");
        }
        const foodFunction = findFunction;
        const funcResponse = (await foodFunction.run(params.keyWords));
        const streamResponse = funcResponse.data;
        const data = await streamResponse.promise();
        const wrapper = data.value;
        if ((0, Extensions_1.isBlank)(wrapper.result?.[0]?.value)) {
            return new VBDataDishRecommendation_1.VBDataDishRecommendation();
        }
        const res = await (0, LangUtil_1.tryPromise)(this.fotClient.foodApi.getFoodList({
            id: wrapper.result[0].value.id,
            by: wrapper.result[0].value.by || "",
        }));
        if (!res.data) {
            return new VBDataDishRecommendation_1.VBDataDishRecommendation();
        }
        const result = DataTransformerUtil_1.DataTransformerUtil.dishesToVBDataDishRecommendation(res.data);
        return result;
    }
}
exports.DishRecommendationDataService = DishRecommendationDataService;
