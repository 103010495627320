import HomeCollectionView from "@views/thinking-layout-editor/Home/HomeCollectionView";
import AutoResizablePanel from "../AutoResizablePanel/AutoResizablePanel";
import cls from "classnames";
import style from "./HomeUsePlugin.module.css";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { getIt } from "@uikit/getIt";

export function ProjectPage(props: any): JSX.Element {
  const homeMethods = getIt(HomeMethodsService);

  return (
    <AutoResizablePanel id="homeContainer" className={cls(style["nav-page-pane"], style.background)}>
      <HomeCollectionView
        pushFunc={data => creatorRefStore.singleFlowEdgeArrRef.current.push(data)}
        emptyNodes={homeMethods.emptyNodes}
      />
    </AutoResizablePanel>
  );
}
