import { Row, Col } from "antd";
import { useMemo } from "react";
import { useSignal } from "@preact/signals-react";
import { ButtonProps, FooterProps } from "./types";
import { ModalButton } from "./ModalButton";
import style from "./AModal.module.css";

export function ModalFooter(props: FooterProps): JSX.Element {
  const { buttons, disableConfirm, loading, danger, handleOk, handleDiscard } = props;
  const selectedButton = useSignal(0);
  const buttonProps = useMemo<ButtonProps[]>(
    () =>
      buttons.map((button, index) => ({
        text: typeof button === "string" ? button : button.text,
        loading: typeof button === "string" ? (index === buttons.length - 1 ? true : false) : !!button.loading,
      })),
    [buttons]
  );

  const handleClick = (e: any, idx: number): void => {
    selectedButton.value = idx;
    if (idx === 0) {
      handleDiscard(e);
    } else {
      handleOk(e);
    }
  };

  // only confirm button
  if (buttons.length === 1) {
    const [button] = buttonProps;
    return (
      <Row gutter={16} justify={"center"}>
        <Col span={8}>
          <ModalButton type="primary" danger={danger} loading={loading} disabled={disableConfirm} onClick={handleOk}>
            {button.text}
          </ModalButton>
        </Col>
      </Row>
    );
  }

  return (
    <div className={style["modal-custom-footer"]}>
      {buttonProps.map((button, idx) => (
        <span className={style[`modal-custom-footer-btn-${idx}`]} key={button.text}>
          <ModalButton
            loading={button.loading && loading}
            danger={idx > 0 ? danger : false}
            type={idx > 0 ? "primary" : "default"}
            // disabled={disableConfirm && idx > 0}
            disabled={loading && selectedButton.value !== idx}
            onClick={e => handleClick(e, idx)}
          >
            {button.text}
          </ModalButton>
        </span>
      ))}
    </div>
  );
}
