"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalApi = void 0;
class GlobalApi {
    constructor(globalClient) {
        this.globalClient = globalClient;
    }
    request(path, method, headers, data, uuid) {
        return this.globalClient.request({
            path,
            method,
            headers,
            data,
            queryParameters: undefined,
            contentType: undefined,
            uuid,
        });
    }
}
exports.GlobalApi = GlobalApi;
