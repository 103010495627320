import { useEffect } from "react";
import { useDispatch, useStore as useReduxStore } from "react-redux";
import { fotActions } from "../../../../store/fot";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { OpenAiParamUtil } from "@uikit/util/OpenAiParamUtil";

export const useChatWithReactFlow = (props: any) => {
  const { prevAttribute, currEdge, sourceNode, setChatList, chatList } = props;

  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);

  const store = useReduxStore();
  const dispatch = useDispatch();

  const setVariableList = (val: any) => {
    dispatch(fotActions.setVariableList(val));
  };

  const getCurrentAttribute = (arr: any[]) => {
    const attributeStr = arr?.[0]?.attributes || "";
    let attribute = {};
    try {
      attribute = JSON.parse(attributeStr);
    } catch (error) {}
    return attribute;
  };

  const extractAllFlagTextToList = (prompt: any) => {
    const results: any[] = [];
    const reg = /<#(ASSISTANT)>([^<]*)|<#(USER)>([^<]*)/g;

    prompt.replace(reg, (matchStr: string, ...param: any[]) => {
      const filterParam = param.filter(
        (x, idx) => !func.isEmpty(x) && idx !== param.length - 1 && idx !== param.length - 2
      );
      for (let index = 0; index < filterParam.length; index++) {
        if (index % 2 === 0) {
          const type = filterParam[index].toLowerCase();
          const value = filterParam[index + 1];
          results.push({
            type,
            value,
          });
        }
      }
    });
    return results;
  };

  const init = () => {
    if (func.isEmpty(props.nodeId)) {
      return setChatList([
        {
          type: "assistant",
          value: "Hello, how can I help you today? ",
        },
      ]);
    }

    if (func.isEmpty(props.chatBoxParam)) return;

    const edges = creatorEdgesStore.getEdges();
    currEdge.current = edges.find(x => x.target === props.nodeId) || {};
    if (func.isEmpty(currEdge.current)) return;
    // 替换变量
    const variableList = (store.getState() as any).fot.variableList;
    const nodes = creatorNodesStore.getNodes();
    sourceNode.current = nodes.find(x => x.id === currEdge.current.source);
    prevAttribute.current = getCurrentAttribute(props.chatBoxParam);

    prevAttribute.current.prompt = OpenAiParamUtil.replaceInputAndCustomVars({
      variableList,
      nodes,
      prompt: prevAttribute.current.prompt,
      nodeText: sourceNode.current.data.textAreaValue,
      setVariableList,
    });

    // 初始化assistant对话
    const initialArr = extractAllFlagTextToList(prevAttribute.current.prompt);

    setChatList(initialArr.filter(x => x.type === "assistant"));
  };

  useEffect(() => {
    init();
  }, [props.chatBoxParam]);

  useEffect(() => {
    creatorNodesStore.setNodes((prevList: any[]) => {
      return prevList.map(x => {
        if (x.id === props.nodeId) {
          return {
            ...x,
            data: {
              ...x.data,
              textAreaValue: chatList,
            },
          };
        }
        return x;
      });
    });
  }, [chatList]);

  useEffect(() => {
    if (!Array.isArray(props.textAreaValue)) return;
    setChatList(props.textAreaValue);
  }, []);

  return { init };
};
