import { Type } from "class-transformer";
import { StudioProjectAttributesV2Edge } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { StudioProjectAttributesV3Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Node";

export class HomeUsePluginParam {
  public distance: number = 10;
  getEdgeGeneration: (aiValue: string, id: string) => string = () => "";
  setStartingPointInput: (param: any, variableName: string) => void = () => {};
  getSaasAction: (type: string) => any = () => {};
}
export class AddNewNodeParam {
  direction: string = "";
  public prevNode: any;
  isStickie: boolean = false;
}
export class CreateNewNodeParam {
  direction: string = "";
  public edgeNode: any;
  isStickie: boolean = false;
}
export class createNewLineParam {
  sourceNode?: any;
  public source: string = "";
  public target: string = "";
  type: "source" | "target" = "source";
}
export class NewEdgeData {
  textAreaValue: string = "";
  targetNodeId: string = "";
  targetLineId: string = "";
  @Type(() => FlowData)
  flows: FlowData[] = [];
  blueprintMessages?: string[];
}

export class LineParam {
  id: string = "";
  loading: boolean = false;
  queryValue: string = "";
  enterText: string = "";
  description?: string = "";
  lineType: string = "prompt";
  identifier: any = {};
  createFromCot: boolean = false;
  disabledRun: boolean = false;
  varilableNames?: string[] = [];
  blueJson?: string | undefined;
  selectedBlueprints?: string[] | undefined;

  /**
   * For generate node title
   */
  get functionDescription(): string {
    return this.description || this.enterText.replace(/^\//, "");
  }
}
export class OldEdgeData {
  isGetQuery: boolean = false;
  getQueryLoading: boolean = false;
  isCreatedBySlice: boolean = false;
  isSliceAutoRun: boolean = false;
  isApiFunction: boolean = false;
  isUndoRedoAdd: boolean = false;
  isFromSaveData: boolean = false;
  originSource: string = "";
  originTarget: string = "";
  sourceHandle: string = "";
  targetHandle: string = "";
  selectedApi: object = {};
  @Type(() => LineParam)
  lineParam: LineParam = {
    id: "",
    description: "",
    functionDescription: "",
    loading: false,
    queryValue: "",
    enterText: "",
    lineType: "",
    identifier: {},
    createFromCot: false,
    disabledRun: false,
  };
}

export class FlowData {
  sourceNodeId: string = "";
  sourceLineId: string = "";
  creationMethod: boolean = false;
}
export class EdgeAttr {
  @Type(() => EdgeData)
  data: EdgeData = { textAreaValue: "" };
}
export class EdgeData {
  textAreaValue: string = "";
}

export class UpdateParam {
  edgeId: string = "";
  type: string = "";
  lineParam: Pick<LineParam, "enterText" | "blueJson" | "identifier" | "description"> = {
    enterText: "",
    description: "",
    identifier: {},
    blueJson: undefined,
  };
}

export class EdgeParam {
  source: string = "";
  target: string = "";
  targetHandle: string = "";
  sourceHandle: string = "";
}

export class FilterFarget {
  aliases?: Array<string>;
  description?: string;
  groupTitle?: string;
  groupType?: string;
  label?: string;
  order?: number;
  value?: string;
}

export class CotData {
  public title = "";
  public description = "";
  public action_sequence: Array<Array<string>> = [];
  public graph: {
    edges: Array<any>;
    nodes: Array<any>;
  } = {
    edges: [],
    nodes: [],
  };
  public interface: Array<{
    description: string;
    id: string;
    name: string;
    type: string;
  }> = [];
}

export class CotSelectTemplateItem {
  public option = "";
  public value = "";
}

export class ManualLineParam {
  onNodesEdgesAdd: (
    addNodes: StudioProjectAttributesV3Node[],
    addEdges: StudioProjectAttributesV2Edge[],
    relevantNodes?: any[]
  ) => void = () => {};
}
