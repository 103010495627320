"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TalkBuilder = exports.ChatResponse = exports.GenUIProcess = exports.Role = void 0;
var Role;
(function (Role) {
    Role["user"] = "user";
    Role["assistant"] = "assistant";
})(Role || (exports.Role = Role = {}));
var GenUIProcess;
(function (GenUIProcess) {
    GenUIProcess[GenUIProcess["Ideation"] = 0] = "Ideation";
    GenUIProcess[GenUIProcess["Build"] = 1] = "Build";
})(GenUIProcess || (exports.GenUIProcess = GenUIProcess = {}));
class ChatResponse {
    constructor() {
        this.response = "";
        this.choices = [];
        this.functionality_messages = [];
        this.response_messages = [];
        this.functions = [];
        this.implementation = "";
        this.show_interface = false;
    }
}
exports.ChatResponse = ChatResponse;
class TalkBuilder {
    constructor() {
        this.id = "";
        this.content = "";
    }
}
exports.TalkBuilder = TalkBuilder;
