import { PlanCell } from "./PlanCell";
import { useMemo } from "react";
import css from "./index.module.scss";
import { FeatureCell } from "./FeatureCell";
import classNames from "classnames";
import { ImagicaCreatorPlan } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaCreatorPlan";
import { ImagicaFeature } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaFeature";
import { Buttonloading } from "@uikit/planList/PlansListBloc";

type PlanTableProps = {
  buttonLoading: Buttonloading;
  options?: ImagicaCreatorPlan[];
  featureOptions?: ImagicaFeature[];
  onUpgradeClick: (plan: ImagicaCreatorPlan) => void;
  onDowngradeClick: (plan: ImagicaCreatorPlan) => void;
};

export function PlanTable(props: PlanTableProps) {
  const { options, featureOptions } = props;

  const renderOptions = useMemo(() => {
    return [{ value: "__empty__" }, ...(options || [])];
  }, [options]);

  return (
    <div className={css.planTable}>
      <div className={css.columns}>
        {renderOptions.map(item => {
          const plan = item as ImagicaCreatorPlan;
          return (
            <div key={item.value} className={css.column} data-highlight-border={plan.highlight_border}>
              <div className={classNames(css.cell)}>
                {item.value === "__empty__" ? null : (
                  <PlanCell
                    record={plan}
                    buttonLoading={props.buttonLoading}
                    onUpgradeClick={props.onUpgradeClick}
                    onDowngradeClick={props.onDowngradeClick}
                  />
                )}
              </div>
              {featureOptions?.map(feature => {
                return (
                  <div
                    className={classNames(css.cell, {
                      [css.multiLine]: !!feature.short_description,
                    })}
                    key={feature.name}
                  >
                    <FeatureCell
                      type={item.value === "__empty__" ? "title" : "content"}
                      plan={plan}
                      feature={feature}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
