import { signal } from "@preact/signals-react";

export class UnpublishBlocState {
  showModal = signal(false);
  showSubTitle = signal(false);
  unpublishLoading = signal(false);
}

export class UnpublishBloc {
  constructor(public state: UnpublishBlocState) {}

  showUnpublishModal(
    disabledUnPublish: boolean,
    previewAppActiveId?: string,
    selectedTemplate?: PreviewApp.ProjectType
  ) {
    const metaData = selectedTemplate?.published_metadata_v2?.find(item => {
      return item.id === previewAppActiveId;
    });
    if (metaData && metaData.charge_option === "Subscription") {
      this.state.showSubTitle.value = true;
    } else {
      this.state.showSubTitle.value = false;
    }
    if (disabledUnPublish) return;
    this.state.showModal.value = true;
  }
  handleCancel() {
    if (this.state.unpublishLoading.value) return;
    this.state.showModal.value = false;
  }
}
