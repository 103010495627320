"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BTheme = void 0;
const DefaultTheme_1 = require("./DefaultTheme");
class BTheme {
}
exports.BTheme = BTheme;
BTheme.theme = {
    chatAiTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.chatAiTheme,
        chatBackground: "linear-gradient(180deg, #FAFAFA 0%, #FFF 100%)",
        chatBackgroundPad: "linear-gradient(180deg, #FAFAFA 0%, #FFF 100%)",
        chatBackgroundMobile: "linear-gradient(180deg, #FAFAFA 0%, #FFF 100%)",
    },
    appSignUpButtonTheme: {
        ...DefaultTheme_1.DefaultTheme.theme.appSignUpButtonTheme,
    },
    patchTheme: {
        inputBarBorderColor: "transparent",
        hightLightBorderLeft: "0",
        inputPaddingLeft: "25px",
        inputPadding: "15px 25px",
        inputBorder: "0",
        inputBorderBottom: "1px solid rgba(18, 25, 43, 0.10)",
        buttonBackground: "transparent",
        buttonColor: "#96F",
        buttonRadius: "10px",
        buttonBorder: " 2px solid #96F",
        toolTipBackground: "#96F",
        hintTextColor: "#96F",
        valueColor: "#fff",
        tooltipBoxColor: "#96F",
        seeFormatDescriptionColor: "#96F",
        toolTipBoxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
    },
};
