import { PreviewSerialize } from "./PreviewSerialize";
import { get, isEmpty } from "lodash";
import { getIt } from "@uikit/getIt";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { ComparatorFields, CompareArgs, ProjectComparator } from "./ProjectComparator";
import { ProjectModifiedOptions } from "./ProjectModifiedOptions";
import { PreviewAppValueGenUtil } from "@uiview/views/PreviewApp/PreviewAppValueGenUtil";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";

const PreviewAppFields = Object.keys(
  PreviewAppValueGenUtil.genPreviewAppValue()
) as (keyof PreviewApp.UISaasValueType)[];
const homePluginStore = getIt(HomePluginStore);

export class ProjectModifiedUtil {
  /**
   * 依次比较每个字段的值
   *
   * - 如果是对象会去掉 function
   * - output, subTitleStyle, titleStyle, settings 四个字段会单独比较 `ProjectComparator`
   *    1. output 中会存在 previewId 和 errotText 字段不确定性，所以不比较
   *    2. subTitleStyle 和 titleStyle 在没有值的时候，保存的是 json 字符串
   *    3. settings 在默认值时, width 和 height 分别是 800 和 600,但是保存过的项目可能没有width 和 height字段
   *
   * !!! 由于数据复杂性，这里用的是直接序列化比较字符串
   *
   * @param source
   * @param target
   */
  private static hasModifiedWithPreviewApp(
    source: PreviewApp.UISaasValueType,
    target: PreviewApp.UISaasValueType
  ): boolean {
    const resetSource = PreviewSerialize.reset(source);
    const resetTarget = PreviewSerialize.reset(target);
    //console.log("5914 compare", resetSource, resetTarget);

    for (const field of PreviewAppFields) {
      let sourceValue = PreviewSerialize.reset(resetSource[field]);
      let targetValue = PreviewSerialize.reset(resetTarget[field]);

      const compareHandler = ProjectComparator.getCompare(field as ComparatorFields);
      const compareArgs: CompareArgs<typeof sourceValue> = {
        compareSource: sourceValue,
        compareTarget: targetValue,
        source: resetSource,
        target: resetTarget,
      };
      if (compareHandler && !compareHandler(compareArgs)) {
        console.log("5914 field not equal:", field, sourceValue, targetValue);
        return true;
      }
    }

    return false;
  }

  /**
   * 比较两个 applist 是否相等
   *
   * 如果有激活的 app,则用激活app ID和 sourcelist 中的对应app比较
   *
   * @param sourceList
   * @param targetList
   * @param activeApp
   * @returns
   */
  private static hasModifiedWithPreviewAppList(
    sourceList: PreviewApp.UISaasValueType[],
    targetList: PreviewApp.UISaasValueType[],
    activeApp?: PreviewApp.UISaasValueType
  ): boolean {
    //console.log("5914", sourceList, targetList, activeApp);

    if (activeApp && !PreviewAppValueLangUtil.isDefaultPreviewApp(activeApp)) {
      const target = targetList.find(item => item.id === activeApp.id);
      if (target) {
        const modified = ProjectModifiedUtil.hasModifiedWithPreviewApp(activeApp, target);
        if (modified) {
          return true;
        }
      }
    }

    // return !isEqual(PreviewSerialize.reset(sourceList), PreviewSerialize.reset(targetList));
    let sLength = sourceList.length;
    const tLength = targetList.length;

    if (sLength !== tLength) {
      return true;
    }

    while (sLength > 0) {
      if (ProjectModifiedUtil.hasModifiedWithPreviewApp(sourceList[sLength - 1], targetList[sLength - 1])) {
        return true;
      }

      sLength--;
    }

    return false;
  }

  /**
   * 判断 canvas 中是否有修改
   *
   * 排除了 cot
   *
   * @returns
   */
  private static hasProjectModifiedWithoutCOT(): boolean {
    const initWithCot = homePluginStore.state.initWithCot;

    const hasModified = ProjectModifiedUtil.hasProjectModified();
    // ! util.isEmpty(props.undoRef.current) && !initWithCot

    // hasModifed => !isEmpty(undoRef.current)
    return !(!hasModified && !initWithCot);
  }

  /**
   * 判断 canvas 中是否有修改
   *
   * @returns
   */
  private static hasProjectModified(): boolean {
    const undoRef = homePluginStore.undoData;
    //console.log("5914 undoRef", undoRef);

    return !isEmpty(undoRef.current);
  }

  /**
   * 判断指定项目是否被修改
   * @param args
   * @returns
   */
  static hasModified(options: Partial<ProjectModifiedOptions>): boolean {
    const _options = Object.assign(new ProjectModifiedOptions(), options);

    // canvas 是否被修改, 提前返回

    // 排除 cot
    if (_options.withoutCot) {
      if (ProjectModifiedUtil.hasProjectModifiedWithoutCOT()) {
        //console.log("5914 not cot canvas!!");
        return true;
      }
    }
    //
    else {
      if (ProjectModifiedUtil.hasProjectModified()) {
        //console.log("5914 canvas!!");

        return true;
      }
    }

    // 比较 applist 和 selectedtemplte 的ui list
    const uiList = get(_options.selectedTemplate, ["v3", "UI"], []) as PreviewApp.UISaasValueType[];
    // 将 saasuidata 当作applist 中激活的 app
    if (ProjectModifiedUtil.hasModifiedWithPreviewAppList(uiList, _options.appList, _options.saasUIData)) {
      //console.log("5914 appList and v3.ui!!");
      return true;
    }

    return false;
  }
}
