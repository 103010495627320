import { Signal } from "@preact/signals-react";
import { ApiStatuses, TemplateModel } from "@views/thinking-layout-editor/types";

export class TemplateListBloc {
  screenLoader = new Signal(false);

  loadingOfTemplate: Signal<TemplateModel | undefined> = new Signal();

  apiStatusOfTemplates = new Signal(ApiStatuses.Idle);

  myTemplates: Signal<any[]> = new Signal([]);
}
