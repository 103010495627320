import { fotActions } from "@store/fot";
import store from "@store/index";
import { CreatorRefStore } from "@uikit/store/CreatorRefStore";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { isEmpty } from "lodash";

interface FunData {
  attributesV2?: any;
  attributes?: string;
  edgeArr?: any[];
  nodeIdArr?: string[];
  nodeArr?: any[] | undefined;
  type?: string;
  isV3Function?: boolean;
  name?: string;
  function_name?: string;
  author_user_id?: string;
  author?: string | undefined;
  codeData?: any;
  apiData?: any;
  description?: string;
  projectName?: string;
  updated_at?: string;
}

type handleUserSavedArgs = {
  each: any;
  isUpdate: boolean;
  nodeDataRef: React.MutableRefObject<{ [key: string]: any }>;
  personalFunctionStore: PersonalFunctionStore;
  creatorRefStore: CreatorRefStore;
  allPreDefinedFunctions: any[];
  isSort?: boolean;
};
export class HandleSaveUtil {
  /**
   * 原来 HomeUsePluginUtil 的 方法，因为方法不纯，所以移动到这里
   * @param args
   * @returns
   */
  static handleUserSavedFunctions(args: handleUserSavedArgs): void {
    if (isEmpty(args.each)) {
      return;
    }

    let fun: FunData;
    try {
      fun = args.each.attributesV2 || JSON.parse(args.each.attributes);
    } catch {
      fun = {};
    }

    if (!fun.edgeArr || !(fun.edgeArr instanceof Array)) return;

    const edgeArr = fun?.edgeArr || [];
    let nodeArr = fun?.nodeArr || [];

    // WEB-2601 兼容只保存 id 的数据
    if (!isEmpty(fun?.nodeIdArr) && isEmpty(nodeArr) && fun.nodeIdArr instanceof Array) {
      const width = 200;
      const height = 200;
      nodeArr = fun.nodeIdArr.map((x: string, index: number) => {
        const onj = {
          data: { ...args.nodeDataRef.current },
          dragging: false,
          height: 200,
          width: 200,
          id: x,
          selected: false,
          type: "customNode",
          position: {
            x: width / 2 + index * 400,
            y: height / 2,
          },
        };
        return onj;
      });
    }

    let groupType: string = fun?.type || "other";

    const targetEdgeData = edgeArr.filter((el: any, index: number, self: any[]) => {
      return !self.some(e => e.source === el.target);
    });

    if (fun.nodeArr && fun.nodeArr instanceof Array) {
      groupType = fun.nodeArr.find((x: any) => x.id === targetEdgeData[0]?.target)?.data?.displayType || "";
    }

    let groupTitle = "";

    const preDefinedFunctionConfig: any = args.allPreDefinedFunctions.find(x => x.label === fun.name);

    if (preDefinedFunctionConfig?.groupTitle) {
      groupTitle = preDefinedFunctionConfig.groupTitle;
    } else if (fun.author_user_id) {
      groupTitle = "marketPlace";
    } else {
      groupTitle = "myFunctions";
    }

    const order = [null, undefined].includes(preDefinedFunctionConfig?.order)
      ? Infinity
      : preDefinedFunctionConfig.order;

    const obgData: any = {
      CustomCode: "customJsFunction",
      customApi: "customApiFunction",
      customApiFunc: "customApiFunction",
    };

    if (obgData[groupType]) {
      groupType = obgData[groupType];
    }
    if (args.creatorRefStore.singleFlowEdgeArrRef.current) {
      args.creatorRefStore.singleFlowEdgeArrRef.current.push(fun);
    }
    const functionData = {
      id: args.each.id,
      author: fun.author,
      editable: true,
      codeData: fun?.codeData || {},
      apiData: fun?.apiData || {},
      type: fun?.type || "",
      relationId: args.each.relationId,
      isCreatedManual: true,
      isV3Function: fun.isV3Function || false,
      value: fun.name || fun.function_name,
      label: fun.name || fun.function_name,
      description: fun.description,
      projectName: fun.projectName,
      edgeArr: JSON.stringify(edgeArr),
      nodeArr: JSON.stringify(nodeArr),
      groupType,
      groupTitle,
      order,
      hide: preDefinedFunctionConfig?.hide,
      updatedAt: args.each.updatedAt,
    };

    const imageGenSelectOptions = (store.getState() as any).fot.imageGenSelectOptions;
    args.personalFunctionStore.receive([args.each], args.isSort);
    if (args.isUpdate) {
      const updatedItems = imageGenSelectOptions.map((item: any) =>
        item.id === args.each.id ? { ...item, ...functionData } : item
      );
      store.dispatch(fotActions.setImageGenSelectOptions(updatedItems));
    } else {
      store.dispatch(fotActions.setImageGenSelectOptions([functionData, ...imageGenSelectOptions]));
    }
  }

  static getDeleteFunctionData(data: any): any {
    const simpleNodeData: {
      [key: string]: any;
    } = {};
    Object.keys(data).forEach(x => {
      if (typeof data[x] !== "function") {
        simpleNodeData[x] = data[x];
      }
    });
    return simpleNodeData;
  }
}
