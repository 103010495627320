import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import func from "@uikit/func";
import style from "../views-css/ThoughtOfUGC.module.css";
import { Api } from "../uikit/service/api";

import ThoughtOfUGCItem from "./components/ThoughtOfUGCItem";
import Footer from "./components/VirtuosoFooter";
import useScrollBottomToFetch from "../custom-hooks/useScrollBottomToFetch";

function ThoughtOfUGC(props) {
  const api = new Api();

  const [questionText, setQuestionText] = useState("");
  const [thoughtList, setThoughtList] = useState([]);
  const [ugcInputLoading, setUgcInputLoading] = useState(false);

  const contentRef = useRef(null);

  const focusedThought = useSelector(state => state.feed.focusedThought);
  const openAiTextData = useSelector(state => state.fot.openAiTextData);

  const { contentFrameLoading, atBottomStateChange } = useScrollBottomToFetch(contentRef);

  // input change事件
  const changeInput = e => {
    setQuestionText(e.target.value);
  };
  // 键盘事件
  const keyUpEvent = async e => {
    const value = e.target.value;
    // enter
    if (e.keyCode === 13) {
      if (func.isEmpty(value)) {
        func.messageUtil("Question cannot be empty", "warning");
        return;
      }
      //  /thought/process api
      try {
        setUgcInputLoading(true);
        const res = await api.get(`/thought/ugc/${value}`);
        const data = await res.json();
        // 添加新数据到数组
        if (data.thoughts && data.thoughts.length > 0) {
          concatThoughtList(data.thoughts);
        }
      } catch (error) {
        func.messageError(error);
      } finally {
        setQuestionText("");
        setUgcInputLoading(false);
      }
    }
  };

  // 点击process文本
  const clickProcessTxt = (idx, rid, text) => {
    try {
      props.select_thought({
        type: "thought",
        id: idx,
        rid: rid,
        text: text,
      });
    } catch (error) {
      console.error("clickProcessTxt", error);
    }
  };
  const clickOpenaiSubThought = (process, subthought) => {
    try {
      props.select_thought({
        type: "openai",
        id: process,
        text: subthought,
      });
    } catch (error) {
      console.error("clickProcessTxt", error);
    }
  };
  // 点击liked图标
  const clickThumbLiked = (idx, val) => {
    // 更新数组状态
    setThoughtList(prevList =>
      prevList.map((x, index) => {
        if (idx === index) {
          return { ...x, isLiked: val };
        }
        return x;
      })
    );
  };

  const concatThoughtList = (thoughts = props.thoughts) => {
    const input = {
      type: "inputField",
    };
    let openAiTextArr = openAiTextData?.[focusedThought.thought] || [];
    if (props.openaiLoading) {
      openAiTextArr = [{ loading: true, type: "openai" }];
    }
    const arr = thoughts.map((x, idx) => {
      return Object.assign({}, x, { isLiked: false }, { tIdx: idx });
    });
    // TODO: Hardcoded sequence need to revisit and remove
    // requirement is to put the 1st element from BE response at 1st,
    // put jerry's hardcoded thought on 2nd and the rest of BE response after that
    setThoughtList([input, ...arr.slice(0, 1), ...openAiTextArr, ...arr.slice(1)]);
    // setThoughtList([input, ...openAiTextArr, ...arr])
  };

  useEffect(() => {
    concatThoughtList(props.thoughts);
  }, [props.thoughts]);

  useEffect(() => {
    if (props.openaiLoading) return;
    concatThoughtList(props.thoughts);
  }, [props.openaiLoading]);

  return (
    <>
      {
        <div className={style["ugc-thought"]} ref={contentRef}>
          {/* api返回的列表 */}
          <Virtuoso
            className="virtuoso-box"
            context={{ contentFrameLoading }}
            data={thoughtList}
            atBottomStateChange={atBottomStateChange}
            itemContent={(tIdx, thought) => {
              return (
                <ThoughtOfUGCItem
                  thought={thought}
                  tIdx={thought.tIdx}
                  key={tIdx}
                  questionText={questionText}
                  ugcInputLoading={ugcInputLoading}
                  keyUpEvent={keyUpEvent}
                  changeInput={changeInput}
                  clickOpenaiSubThought={clickOpenaiSubThought}
                  clickSubThought={clickProcessTxt}
                  clickThumbLiked={clickThumbLiked}
                  ContentRetrieval={props.ContentRetrieval}
                  enterQuery={props.enterQuery}
                />
              );
            }}
            components={{ Footer }}
          />
        </div>
      }
    </>
  );
}

export default ThoughtOfUGC;
