import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { PreviewAppUtil } from "@uiview/views/PreviewApp/PreviewAppUtil";

export class PreviewStoreState {
  previewAppList: PreviewApp.UISaasValueType[] = [];
  loading: boolean = false; //  Auto-Imagica build loading
  previewAppAcivieId: string = "";
  /**
   * 该状态可能会被弃用
   */
  selectedUI: string = "";
  chargeOptionUpdated: string = "";
  vDataLastIndex: number = 0;

  showPreviewPanel: boolean = false;

  //  是否添加input node
  isAddInputNode = false;
  //  是否添加output node
  isAddOutputNode = false;

  isClickAddToContent = false;

  addContentId = "";

  nextAddContentId = "";

  previewCollapseState = false;

  chatMessageLoading = false;

  aiBuildLoading = false;

  copyWith(param: Partial<Omit<PreviewStoreState, "copyWith">>): PreviewStoreState {
    const newState = new PreviewStoreState();
    newState.previewAppList = param.previewAppList ?? this.previewAppList;
    newState.loading = param.loading ?? this.loading;
    newState.previewAppAcivieId = param.previewAppAcivieId ?? this.previewAppAcivieId;
    newState.selectedUI = param.selectedUI ?? this.selectedUI;
    newState.chargeOptionUpdated = param.chargeOptionUpdated ?? this.chargeOptionUpdated;
    newState.vDataLastIndex = param.vDataLastIndex ?? this.vDataLastIndex;
    newState.showPreviewPanel = param.showPreviewPanel ?? this.showPreviewPanel;
    newState.isAddInputNode = param.isAddInputNode ?? this.isAddInputNode;
    newState.isAddOutputNode = param.isAddOutputNode ?? this.isAddOutputNode;
    newState.isClickAddToContent = param.isClickAddToContent ?? this.isClickAddToContent;
    newState.addContentId = param.addContentId ?? this.addContentId;
    newState.nextAddContentId = param.nextAddContentId ?? this.nextAddContentId;
    newState.previewCollapseState = param.previewCollapseState ?? this.previewCollapseState;
    newState.chatMessageLoading = param.chatMessageLoading ?? this.chatMessageLoading;
    newState.aiBuildLoading = param.aiBuildLoading ?? this.aiBuildLoading;

    return newState;
  }
}

export class PreviewStore extends StoreBase<PreviewStoreState> {
  constructor() {
    super(() => new PreviewStoreState());
  }

  setPreviewAppList = (previewAppList: PreviewApp.UISaasValueType[]): void => {
    this.emit(this.state.copyWith({ previewAppList }));
  };

  setPreviewStore = (param: Partial<Omit<PreviewStoreState, "copyWith">>): void => {
    this.emit(this.state.copyWith(param));
  };

  setPreviewAppAcivieId = (previewAppAcivieId: any): void => {
    this.emit(this.state.copyWith({ previewAppAcivieId }));
  };

  setSelectedUI = (selectedUI: string): void => {
    this.emit(this.state.copyWith({ selectedUI }));
  };

  setChargeOptionUpdated = (chargeOptionUpdated: string): void => {
    this.emit(this.state.copyWith({ chargeOptionUpdated }));
  };

  setVDataLastIndex = (vDataLastIndex: number): void => {
    this.emit(this.state.copyWith({ vDataLastIndex }));
  };

  setShowPreviewPanel = (showPreviewPanel: boolean): void => {
    this.emit(this.state.copyWith({ showPreviewPanel }));
  };

  setIsAddInputNode = (isAddInputNode: boolean): void => {
    this.emit(this.state.copyWith({ isAddInputNode }));
  };

  setIsAddOutputNode = (isAddOutputNode: boolean): void => {
    this.emit(this.state.copyWith({ isAddOutputNode }));
  };

  setIsClickAddToContent = (isClickAddToContent: boolean): void => {
    this.emit(this.state.copyWith({ isClickAddToContent }));
  };

  setAddContentId = (addContentId: string): void => {
    this.emit(this.state.copyWith({ addContentId }));
  };

  setNextAddContentId = (nextAddContentId: string): void => {
    this.emit(this.state.copyWith({ nextAddContentId }));
  };

  setPreviewCollapseState = (previewCollapseState: boolean): void => {
    this.emit(this.state.copyWith({ previewCollapseState }));
  };

  getPreviewAppActiveLable(): PreviewApp.PreviewAppType | undefined {
    const activeItem = this.state.previewAppList.find(
      (x: PreviewApp.UISaasValueType) => x.id === this.state.previewAppAcivieId
    );
    return activeItem?.v2App?.type;
  }

  setChatMessageLoading = (chatMessageLoading: boolean): void => {
    this.emit(this.state.copyWith({ chatMessageLoading }));
  };

  /**
   * 更新置顶preview 中的v2app 字段
   */
  updatePreviewV2App(id: any, value: Partial<Omit<PreviewApp.PreviewAppV2FieldType, "id">>): void {
    previewStore.setPreviewAppList(
      previewStore.state.previewAppList.map(item => {
        if (item.id === id) {
          return {
            ...item,
            v2App: {
              // 为了类型安全
              id: item.id as any,
              ...item.v2App,
              ...value,
            },
          };
        }
        return item;
      })
    );
  }

  setAiBuildLoading = (aiBuildLoading: boolean): void => {
    this.emit(this.state.copyWith({ aiBuildLoading }));
  };

  removeByNodeIds(ids: string[]): void {
    this.setPreviewAppList(PreviewAppUtil.removeByNodeIds(previewStore.state.previewAppList, ids));
  }
}
export const previewStore = new PreviewStore();
