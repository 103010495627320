import style from "./CustomApiPage.module.scss";
import { CustomApiPageBloc, RequestData } from "./CustomApiPageBloc";
import { useCreation } from "ahooks";
import cls from "classnames";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Button, Input, Drawer, Form, Popover } from "antd";
import Request from "./Request";
import { useSelector, useDispatch, useStore as useReduxStore } from "react-redux";
import func from "@uikit/func";
import { editorActions } from "../../../store/editor";
import { useSignal } from "@preact/signals-react";
import { getIt } from "../../../uikit/getIt";
import { CustomApiUtil } from "imagica-corekit/dist/cases/util/CustomApiUtil";
import { FuncCustomVariable } from "imagica-corekit/dist/base/storyV2/function/FunctionBase";
import { CustomApiPageUtil } from "@uiview/pages/customApi/CustomApiPageUtil";
import { CustomApiPageBlocContext } from "@uiview/pages/customApi/CustomApiPageBlocContext";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import Response from "./Response";
import VariablePopoverContent from "./VariablePopoverContent";
import GuidanceContent from "./GuidanceContent";
import { fotActions } from "../../../store/fot";
import { AModal } from "@uiview/views/AModal/AModal";
import LoadingOutline from "@views/components/LoadingOutline";
import ResizableDiv from "./ResizableDiv";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreateService } from "@uikit/service/CreateService";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { allPreDefinedFunctions } from "@views/thinking-layout-editor/nodeTypeComponents/nodeTypeDispose";
import { HandleSaveUtil } from "@uikit/util/HandleSaveUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { SaveTemplateService } from "@uikit/service/SaveTemplateService";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { EdgeRunVariable } from "@uikit/edgeRun/EdgeRunVariable";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";

interface createAPIFuncDataType {
  edgeId: string;
  function: string;
  loading: boolean;
  open: boolean;
  parameter: Record<string, any>;
  select: Record<string, any>;
  sourceNodeId: string;
  state: string;
  targetNodeId: string;
}

interface FunctionAttributes {
  edgeArr?: string | any[];
  nodeArr?: string | any[];
}

export function CustomApiPage(): JSX.Element {
  const gql = getIt(ObjectRelationGqlService);
  const createService = getIt(CreateService);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const edgeRunVariable = getIt(EdgeRunVariable);
  const canvasDataRef = getIt(CanvasDataRef);
  const showNavPageStore = getIt(ShowNavPageStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const creatorStoreMethods = getIt(CreatorStoreMethods);

  const store = useReduxStore();
  const location = useLocation();
  const bloc = useCreation(() => new CustomApiPageBloc(location.pathname), []);

  const projectPageState = useImagicaStore(projectPageStore).value;
  const functionData = useSignal({});

  const createAPIFuncData: createAPIFuncDataType = useSelector((state: any) => state.editor.createAPIFuncData);
  const userMe = useSelector((state: any) => state.editor.me);
  const setCreateAPIFuncData = (val: any): void => {
    dispatch(editorActions.setCreateAPIFuncData(val));
  };
  const setCreateFunctionClick = (val: any): void => {
    dispatch(fotActions.setCreateFunctionClick(val));
  };
  const setCommandEnterNodeId = (val: any): void => {
    dispatch(fotActions.setCommandEnterNodeId(val));
  };

  const onClickBack = (): void => {
    navigate(bloc.popProfilePath);
    bloc.switchPage(false);
    setCreateAPIFuncData({
      open: false,
      loading: false,
      edgeId: "",
      sourceNodeId: "",
      targetNodeId: "",
      function: "",
      select: {},
      parameter: {},
    });
    bloc.handleFunctionData();
  };

  const getVariableList = (currentSourceNodeValue: string): FuncCustomVariable[] => {
    const list = edgeRunVariable.getVariableList(currentSourceNodeValue);
    return CustomApiUtil.translateFotVariableListToCoreKit(list);
  };

  /**
   * 获取sourceNodeValue
   */
  bloc.getSourceNodeValue = (): any => {
    const nodes = creatorNodesStore.getNodes();
    return CustomApiPageUtil.getSourceNodeTextAreaValue(store, nodes);
  };
  /**
   * 获取variableList
   */
  bloc.getVarliableList = (): FuncCustomVariable[] => {
    const sourceNodeValue = bloc.getSourceNodeValue();
    return getVariableList(sourceNodeValue);
  };

  const createAPIFunction = (): void => {
    const apiTitleData: Record<string, string> = {
      modify: "Modify Custom API Function",
      new: "New Custom API Function",
    };

    let apiTitleDataState = createAPIFuncData?.state || "";
    if (!func.isEmpty(bloc.state.functionId.value)) {
      apiTitleDataState = "modify";
    }

    bloc.setCustomFunctionTitle(apiTitleData[apiTitleDataState]);

    if (!func.isEmpty(createAPIFuncData.parameter)) {
      bloc.setNewFunctionData(createAPIFuncData.parameter);
    }

    if (createAPIFuncData.state === "new") {
      bloc.setNewFunctionData({ name: "", description: "" });
    }

    bloc.setIsNewCustomFunctionOpen(true);
  };

  const handleFunctionDataOk = async (): Promise<void> => {
    const newFunctionDatas: {
      description: string;
      edgeArr: any[];
      name: string;
      nodeArr: any[];
      type: string;
      apiData: {
        requestData: any;
      };
    } = {
      description: bloc.state.newFunctionData?.value?.description || "",
      edgeArr: [],
      name: bloc.state.newFunctionData?.value?.name || "",
      nodeArr: [],
      type: "customApi",
      apiData: {
        requestData: bloc.state.requestData.value,
      },
    };

    const functionId = createAPIFuncData?.select?.id || bloc.state.functionId.value;

    try {
      bloc.state.isFuncLoading.value = true;

      const imageGenSelectOptions = (store.getState() as any).fot.imageGenSelectOptions;
      const currentSingleFlow = imageGenSelectOptions.find(
        (x: any) => x.label === newFunctionDatas.name && functionId !== x.id
      );
      if (!func.isEmpty(currentSingleFlow)) {
        func.customMsg("Duplicate Name");
        bloc.state.isFuncLoading.value = false;
        return;
      }

      if (!func.isEmpty(functionId)) {
        const renewData = await gql.updateObject(functionId, {
          name: "studio_function",
          attributes: newFunctionDatas,
        });
        HandleSaveUtil.handleUserSavedFunctions({
          each: { user: createAPIFuncData?.select?.originElement?.user, ...renewData },
          isUpdate: true,
          nodeDataRef: canvasDataRef.nodeDataRef,
          personalFunctionStore: getIt(PersonalFunctionStore),
          creatorRefStore: creatorRefStore,
          allPreDefinedFunctions: allPreDefinedFunctions,
          isSort: true,
        });
      } else {
        const callback = (object: any): void => {
          HandleSaveUtil.handleUserSavedFunctions({
            each: { user: createAPIFuncData?.select?.originElement?.user, ...object },
            isUpdate: false,
            // 原来调用方法并没有传入 nodeDataRef???, 所以这里变为空
            // nodeDataRef: nodeDataRef,
            nodeDataRef: { current: {} },
            personalFunctionStore: getIt(PersonalFunctionStore),
            creatorRefStore: creatorRefStore,
            allPreDefinedFunctions: allPreDefinedFunctions,
            isSort: true,
          });
        };
        await createService.createFunction(newFunctionDatas, false, callback);
      }

      const clickFuncData = {
        edgeId: createAPIFuncData.edgeId,
        functionName: newFunctionDatas.name,
      };

      // WEB-2891 保存 function 自动运行
      // STUD-1032 添加函数选项显示列表
      setCreateFunctionClick(clickFuncData);

      func.customMsg({
        content: "Success",
        type: "success",
      });

      // 如果在首页则不继续执行
      if (showNavPageStore.state.showNavPage) {
        onClickBack();
        return;
      }

      // 设置按钮下面的文本值
      creatorStoreMethods.setEdgeLineParam({
        id: createAPIFuncData.edgeId,
        enterText: `/${newFunctionDatas.name}`,
      });

      // 显示 run all 按钮
      creatorEdgesStore.setQueryLoading(createAPIFuncData.edgeId, "isGetQuery", true);
      creatorEdgesStore.setQueryLoading(createAPIFuncData.edgeId, "isApiFunction", true);

      // Auto run
      setCommandEnterNodeId({
        nodeId: createAPIFuncData.sourceNodeId,
        targetNodeId: createAPIFuncData.targetNodeId,
        param: {
          selectValueParam: newFunctionDatas.name,
          newAiDescription: `/${newFunctionDatas.name}`,
        },
      });
      onClickBack();
    } catch (error) {
      func.messageError(error);
    } finally {
      bloc.state.isFuncLoading.value = false;
    }
  };

  const handleImageGenSelect = (currFuncData: any): void => {
    if (!func.isEmpty(currFuncData)) {
      functionData.value = currFuncData;
      bloc.setNewFunctionData({
        name: currFuncData?.label || currFuncData?.name,
        description: currFuncData.description,
      });
      bloc.state.requestData.value =
        JsonUtil.toModelFromType(RequestData, currFuncData.apiData.requestData) ?? new RequestData();
    }
  };

  const callPublishFunction = async (
    name: string,
    description: string,
    functionData?: FunctionAttributes
  ): Promise<void> => {
    let attributes: FunctionAttributes =
      functionData || getIt(SaveTemplateService).getCurrentStateOfFunctionForSaving();

    try {
      if (typeof attributes.edgeArr === "string") {
        attributes = { ...attributes, edgeArr: JSON.parse(attributes.edgeArr) };
      }

      if (typeof attributes.nodeArr === "string") {
        attributes = { ...attributes, nodeArr: JSON.parse(attributes.nodeArr) };
      }
    } catch (error) {
      func.messageUtil(error);
    }

    await gql.createObject(
      {
        name: "studio_function",
        isGlobal: true,
        attributes: {
          ...attributes,
          name,
          projectName: name,
          description,
          author_user_id: userMe.id,
        },
      },
      "Successfully published"
    );
  };

  const handlePublishFunctionConfirm = async (): Promise<void> => {
    // 处理确认发布的逻辑
    try {
      bloc.state.publishFunctionLoading.value = true;
      await callPublishFunction(
        bloc.state.newFunctionData.value.name,
        bloc.state.newFunctionData.value.description,
        functionData.value
      );
      bloc.publishFunction(false);
    } catch (error) {
      func.messageUtil(error);
    } finally {
      bloc.state.publishFunctionLoading.value = false;
    }
  };

  useEffect(() => {
    const imageGenSelectOptions = (store.getState() as any).fot.imageGenSelectOptions;
    if (func.isEmpty(bloc.state.functionId.value) || projectPageState.userFunctionsLoading) return;
    const funcData = imageGenSelectOptions.find((x: any) => x?.id === bloc.state.functionId.value);

    if (!func.isEmpty(funcData)) {
      handleImageGenSelect(funcData);
    }
  }, [bloc.state.functionId.value, projectPageState.userFunctionsLoading]);

  useEffect(() => {
    if (func.isEmpty(createAPIFuncData.select)) {
      // 只在需要在刷新的时候触发
      bloc.state.functionId.value = bloc.extractNumber(location.pathname);
      return;
    }
    const imageGenSelectOptions = (store.getState() as any).fot.imageGenSelectOptions;
    const currFuncData = imageGenSelectOptions.find(
      (x: any) => x.label === (createAPIFuncData.select?.label || createAPIFuncData.select?.name)
    );
    handleImageGenSelect(currFuncData);
  }, [createAPIFuncData.select]);

  return (
    <div>
      <CustomApiPageBlocContext.Provider value={bloc}>
        <Drawer
          className={cls(style["customApiBox"], bloc.state.createAPIFuncOpen.value && style["occlusion"])}
          placement="bottom"
          open={bloc.state.createAPIFuncOpen.value}
          closable={false}
        >
          <div className={style["content-box"]}>
            <div className={style["top-box"]}>
              <Button type="text" onClick={() => onClickBack()}>
                Back
              </Button>
              <Button type="primary" onClick={() => createAPIFunction()}>
                Save
              </Button>
              <Button type="primary" onClick={() => bloc.publishFunction(true)}>
                Publish Function
              </Button>
            </div>

            {/* Request 请求区域 */}
            {/* Response 响应区域 */}
            <ResizableDiv
              topContent={
                <Request requestData={bloc.state.requestData.value} sendLoading={bloc.state.apiLoading.value} />
              }
              bottomContent={<Response responseData={bloc.state.response.value} />}
            />

            {/* 底部工具栏 */}
            <div className={style["tool-bar-box"]}>
              <div className={style["bottom-tool-bar"]}>
                <Popover content={<VariablePopoverContent />}>
                  <span className="iconfont icon-bianliang"></span>
                </Popover>

                {/* Custom JS function guidance */}
                {
                  <Popover content={<GuidanceContent type="customApiFunction" />}>
                    <span className="iconfont icon-i-info"></span>
                  </Popover>
                }
              </div>
            </div>

            {projectPageState.userFunctionsLoading && (
              <div className={style["customApiBox-loading"]}>
                <LoadingOutline />
              </div>
            )}
          </div>
        </Drawer>

        {bloc.state.isNewCustomFunctionOpen.value && (
          <AModal
            open={true}
            onCancel={() => bloc.handleFunctionData()}
            loading={bloc.state.isFuncLoading.value}
            disableConfirm={func.isEmpty(bloc.state.newFunctionData.value.name)}
            buttons={["Cancel", "Save"]}
            onOk={() => handleFunctionDataOk()}
            className={style["publish-global-function"]}
          >
            <div className={style["modal-title"]}>{bloc.state.customFunctionTitle.value}</div>

            <div className={style["modal-content"]}>
              <Form layout="vertical">
                <Form.Item label="Name" rules={[{ required: true, message: "Please enter function name" }]}>
                  <Input
                    autoComplete="off"
                    placeholder="Enter function name"
                    value={bloc.state.newFunctionData.value.name}
                    onChange={e => bloc.newFunctionDataChange(e, "name")}
                  />
                </Form.Item>
                <Form.Item label="Description">
                  <Input
                    autoComplete="off"
                    placeholder="Enter function description"
                    value={bloc.state.newFunctionData.value.description}
                    onChange={e => bloc.newFunctionDataChange(e, "description")}
                  />
                </Form.Item>
              </Form>
            </div>
          </AModal>
        )}

        {bloc.state.showPublishFunctionConfirm.value && (
          <AModal
            open={true}
            onCancel={() => bloc.publishFunction(false)}
            loading={bloc.state.publishFunctionLoading.value}
            buttons={["Cancel", "Ok"]}
            onOk={() => handlePublishFunctionConfirm()}
            className={style["publish-global-function"]}
          >
            <div className={style["modal-title"]}>Publish Global Function</div>
            <div className={style["modal-content"]}>
              <p>
                Please confirm the name of your global function. Once you publish this function everyone will be able to
                see and use it.
              </p>
              <Form layout="vertical" validateTrigger="onBlur" autoComplete="off">
                <Form.Item label="Function Name" rules={[{ required: true, message: "Please enter fucntion name" }]}>
                  <Input
                    placeholder="Please enter function name"
                    value={bloc.state.newFunctionData.value.name}
                    onChange={e => bloc.newFunctionDataChange(e, "name")}
                  />
                </Form.Item>
                <Form.Item label="Description">
                  <Input
                    autoComplete="off"
                    placeholder="Enter function description"
                    value={bloc.state.newFunctionData.value.description}
                    onChange={e => bloc.newFunctionDataChange(e, "description")}
                  />
                </Form.Item>
              </Form>
            </div>
          </AModal>
        )}
      </CustomApiPageBlocContext.Provider>
    </div>
  );
}
