import style from "./AccountMobile.module.scss";
import { AccountMobileCloseIcon } from "@uiview/views/Mobile/assets/AccountMobileCloseIcon";
import { deleteIcon, inputInValidIcon } from "@uiview/pages/profile/account/AccountPage";
import accountStyle from "@uiview/pages/profile/account/AccountPage.module.scss";
import isBlank from "@sedan-utils/is-blank";
import { Modal, Spin, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadState } from "@uiview/pages/profile/account/AccountData";
import { logEvent } from "@uikit/service/amplitude";
import func from "@uikit/func";
import { useCreation } from "ahooks";
import { AccountMobileBloc } from "@uiview/views/Mobile/Account/AccountMobileBloc";
import cls from "classnames";
import { AccountMobileAvatarPlaceholderIcon } from "@uiview/views/Mobile/assets/AccountMobileAvatarPlaceholderIcon";
import { CSSProperties, useRef } from "react";
import classNames from "classnames";
import { SubscriptionPage } from "@uiview/pages/profile/subscription";
import { useNavigate } from "react-router-dom";
import { DeleteAccountAlert } from "@uiview/pages/profile/account/view/DeleteAccountAlert";
import { AuthCodeView } from "@uiview/pages/profile/account/view/AuthCodeView";
import { AccountAlert } from "@uiview/pages/profile/account/view/AccountAlert";
import accountChangeAlertStyle from "@uiview/pages/profile/account/view/AuthCodeView.module.scss";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";

export function AccountMobile(): JSX.Element {
  const navigate = useNavigate();
  const bloc = useCreation(() => new AccountMobileBloc(), []);
  const accountBloc = bloc.accountPageBloc;
  const avatarData = accountBloc.state.headerData;
  const nameData = accountBloc.state.nameEdit;
  const emailData = accountBloc.state.emailEdit;
  const saveChangesBtnClasses = cls(
    style.saveChangesBtn,
    {
      [style.saveChangesBtn_emailInvalid]: !isBlank(emailData.errorText.value),
    },
    { [style.saveChangesBtn_dsiabled]: !accountBloc.state.bottomData.value.isEnable }
  );
  const fotProcessManager = FotProcessManager.use();

  function closeClicked(): void {
    navigate("/editor");
  }

  function resetPasswordClicked(): void {
    const resetUrl = document.location.origin + "/password_reset";
    window.open(resetUrl);
    logEvent("my_account_reset_password");
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        Account
        <div onClick={() => closeClicked()}>
          <AccountMobileCloseIcon />
        </div>
      </div>
      <div className={style.content}>
        <section>
          <SectionHeader title="My Account" subTitle="Update your name and manage your account." />
          <Avatar
            deleteLoading={avatarData.deleteLoading.value}
            progress={avatarData.state.value[1]}
            loading={
              avatarData.state.value[0] === UploadState.loading || avatarData.state.value[0] === UploadState.progress
            }
            icon={avatarData.icon.value}
            typeDescription={avatarData.typeDescription.value}
            clickDeleteBtn={() => {
              console.log("handle tap delete btn");
              logEvent("my_account_click_delete_image_button");
              bloc?.accountPageBloc.tapDeleteIcon();
            }}
            clickUpdateBtn={file => {
              // file.size使用MB，800k等于0.78125MB
              const isLt800K = file.size / 1024 / 1024 <= 0.78125;
              if (!isLt800K) {
                func.customMsg({
                  content: "Image should be no more than 800K!",
                  type: "warning",
                });
              } else {
                accountBloc.uploadIcon(file);
              }
              return isLt800K;
              // bloc?.uploadIcon(file);
            }}
          />
          <TextInput
            showLoading={nameData.loading.value}
            name={nameData.title.value}
            placeHolder={nameData.placeHolder.value}
            inputValue={nameData.value.value}
            errorText={nameData.errorText.value}
            onBlur={() => {
              accountBloc.onBlur(nameData.title.value);
            }}
            tapEnter={() => {
              accountBloc.tapNameEnter(nameData.title.value);
            }}
            onChange={text => {
              accountBloc.textOnChange(nameData.title.value, text);
            }}
          />
          <TextInput
            showLoading={emailData.loading.value}
            style={{ flex: "1" }}
            name={emailData.title.value}
            placeHolder={emailData.placeHolder.value}
            inputValue={emailData.value.value}
            errorText={emailData.errorText.value}
            onBlur={() => {
              accountBloc.onBlur(emailData.title.value);
            }}
            tapEnter={() => {
              accountBloc.tapNameEnter(emailData.title.value);
            }}
            onChange={text => {
              accountBloc.textOnChange(emailData.title.value, text);
            }}
          />
          <button className={saveChangesBtnClasses} onClick={() => accountBloc.tapSavesChanges()}>
            Save changes
          </button>
          <button className={style.deleteAccountBtn} onClick={() => accountBloc.tapDeleteAccount()}>
            Delete account
          </button>
        </section>
        <section>
          <SectionHeader title="Password" subTitle="Manage your password." />
          <button className={style.normalRowBtn} onClick={() => resetPasswordClicked()}>
            Reset Password
          </button>
        </section>
        <section>
          <SectionHeader title="Subscription" subTitle="Manage your plan." />
          <SubscriptionPage />
        </section>
        <section>
          <button className={style.logOutBtn} onClick={() => fotProcessManager.logout()}>
            Log Out
          </button>
        </section>
      </div>
      {accountBloc.state.showOtpAlert.value && (
        <AuthCodeView
          sendAgainLoading={false}
          otpLoading={false}
          alertData={accountBloc.state.showOtpAlert.value}
          otpInputComplete={otp => {
            accountBloc.otpInputComplete(otp);
          }}
          close={() => {
            accountBloc.state.showOtpAlert.value = null;
          }}
          sendAgain={() => {
            accountBloc.otpSendAgain();
          }}
          count={accountBloc.state.showOtpAlert.value.otp.value}
        />
      )}
      {accountBloc.state.showAlert.value && (
        <Modal
          title=""
          maskClosable={false}
          keyboard={false}
          open={true}
          wrapClassName={accountChangeAlertStyle.myAccountVerifyModal}
          footer={[]}
          centered={true}
        >
          <AccountAlert
            title={accountBloc.state.showAlert.value.title}
            detail={accountBloc.state.showAlert.value.detail}
            tapDone={() => {
              accountBloc.state.showAlert.value = null;
            }}
          ></AccountAlert>
        </Modal>
      )}
      {accountBloc.state.showDeleteAccountAlert.value && (
        <DeleteAccountAlert
          deleteLoading={accountBloc.state.deleteLoading.value}
          alertData={accountBloc.state.showDeleteAccountAlert.value}
          tapSureDelete={() => {
            accountBloc.tapSureDeleteAccount();
          }}
          confirmDelete={code => {
            accountBloc.deleteAccount(code).then(() => {
              logEvent("delete_account");
              fotProcessManager.logout();
            });
          }}
          confirmClose={() => {
            accountBloc.state.showDeleteAccountAlert.value = null;
          }}
          sendAgain={() => {
            accountBloc.getDeleteAccpuntOTP();
          }}
        />
      )}
      {accountBloc.isLoading.value && (
        <Modal
          title=""
          maskClosable={false}
          keyboard={false}
          open={true}
          wrapClassName={accountStyle.loaderModal}
          footer={[]}
          centered={true}
          closeIcon={<></>}
        >
          <div className={accountStyle.loader}>
            <Spin spinning={true}></Spin>
          </div>
        </Modal>
      )}
    </div>
  );
}

function SectionHeader(props: { title: string; subTitle: string }): JSX.Element {
  return (
    <>
      <div className={style.sectionTitle}>{props.title}</div>
      <div className={style.sectionSubTitle}>{props.subTitle}</div>
    </>
  );
}

function Avatar(props: {
  icon: string;
  typeDescription: string;
  clickUpdateBtn: (file: RcFile) => void;
  clickDeleteBtn: () => void;
  loading: boolean;
  progress: string;
  deleteLoading: boolean;
}): JSX.Element {
  let updateBtn = isBlank(props.icon) ? "Upload" : "Change";
  if (props.loading) {
    const progress = Number(props.progress);
    updateBtn = `... ${Math.floor(progress * 100 * 100) / 100}%`;
  }

  return (
    <div className={accountStyle.header}>
      {isBlank(props.icon) ? (
        <AccountMobileAvatarPlaceholderIcon />
      ) : (
        <img className={accountStyle.img} src={props.icon} alt="avatar" />
      )}
      <div className={accountStyle.right}>
        <div className={accountStyle.buttonContaer}>
          {
            <Upload
              accept={".png, .jpg, .jpeg, image/jpeg"}
              maxCount={1}
              showUploadList={false}
              disabled={props.deleteLoading || props.loading}
              beforeUpload={file => {
                props.clickUpdateBtn(file);
              }}
            >
              <button type="button" className={accountStyle.updateBtn}>
                {updateBtn}
              </button>
            </Upload>
          }

          {!isBlank(props.icon) && (
            <button type="button" className={accountStyle.deleteBtn} onClick={() => props.clickDeleteBtn()}>
              <img src={deleteIcon} />
            </button>
          )}
        </div>
        <div className={accountStyle.description}>{props.typeDescription}</div>
      </div>
    </div>
  );
}

function TextInput(props: {
  showLoading: boolean;
  key?: string;
  name: string;
  inputValue: string;
  onBlur: () => void;
  placeHolder: string;
  errorText?: string;
  tapEnter?: () => void;
  className?: string;
  onChange?: (text: string) => void;
  setInputRef?: (input: HTMLInputElement) => void;
  style?: CSSProperties;
}): JSX.Element {
  const inputRef = useRef<HTMLElement | null>(null);
  const inputClass = classNames(accountStyle.edit, isBlank(props.errorText) ? "" : accountStyle.error);
  return (
    <div key={props.key} className={accountStyle.editContainer} style={props.style}>
      <div className={accountStyle.title}>{props.name}</div>
      <div style={{ position: "relative" }}>
        <input
          value={props.inputValue}
          className={inputClass}
          placeholder={props.placeHolder}
          onBlur={() => props.onBlur()}
          onKeyUp={e => {
            if (e.code === "Enter" && props.tapEnter) {
              props.tapEnter();
              inputRef.current?.blur();
            }
          }}
          onChange={e => props.onChange && props.onChange(e.target.value)}
          ref={ref => {
            if (props.setInputRef && ref) {
              props?.setInputRef(ref);
            }
            inputRef.current = ref;
          }}
        />
        <img
          src={inputInValidIcon}
          className={accountStyle.editIcon}
          style={{ visibility: !isBlank(props.errorText) ? "visible" : "hidden" }}
        />
      </div>
      {!isBlank(props.errorText) && <div className={accountStyle.errorText}> {props.errorText} </div>}
    </div>
  );
}
