import isBlank from "@sedan-utils/is-blank";
import { getIt } from "@uikit/getIt";
import { Input } from "antd";
import classNames from "classnames";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { ToolPieceData } from "imagica-corekit/dist/base/api/blueprintTyped/Blueprint";
import { Dataload } from "imagica-corekit/dist/base/cutil/Dataload";
import { ReadyStatus } from "imagica-corekit/dist/base/cutil/ReadyStatus";
import { useDataload } from "imagica-uikit/dist/hooks/useDataload";
import styles from "./BlueprintDetail.module.scss";
import { Loading } from "./Loading";
import { DeleteButton } from "../Button/DeleteButton";

export type ToolPiecesDetailPanelProps = {
  value?: ToolPieceData | undefined;
  id?: string;
  updateFuncGroup: () => void;
};

export function ToolPiecesDetail(props: ToolPiecesDetailPanelProps) {
  const brainClient = getIt(BrainClient);

  const result = useDataload(() => {
    if (isBlank(props.value) === false) {
      return new Dataload(() => Promise.resolve({ data: props.value }));
    }
    return new Dataload(() => brainClient.openAi.toolPiecesDetail({ id: props.id || "" }));
  }, []).value;

  const handleClick: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
  };

  const readyStatus = result.status;
  const toolPiecesData = result.data?.data;
  const id = result.data?.data?.id;

  return (
    <>
      {readyStatus === ReadyStatus.LOADING && <Loading>Loading Toolpiece Data...</Loading>}
      {readyStatus === ReadyStatus.DONE && (
        <div className={classNames(styles.BlueprintDetail, styles.toolPiece)} onClick={handleClick}>
          <div className={styles.content}>
            <div className={styles.canvas}>{toolPiecesData?.io}</div>
            {toolPiecesData?.type === "api" && <TypeApi url={toolPiecesData.url} method={toolPiecesData.method} />}
            {toolPiecesData?.type === "prompt" && (
              <TypePrompt model={toolPiecesData.model} prompt={toolPiecesData.prompt} />
            )}
            <DeleteButton deleteType="toolPiece" id={id} updateFuncGroup={props.updateFuncGroup} />
          </div>
        </div>
      )}
    </>
  );
}

function TypeApi(props: { url?: string; method?: string }) {
  return (
    <div>
      <div className={styles.item}>
        <div className={styles.label}>url:</div>
        <div className={styles.itemContent}>{props.url}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>method:</div>
        <div className={styles.itemContent}>{props.method}</div>
      </div>
    </div>
  );
}

function TypePrompt(props: { model?: string; prompt?: string }) {
  return (
    <div>
      <div className={styles.item}>
        <div className={styles.label}>model:</div>
        <div className={styles.itemContent}>{props?.model}</div>
      </div>
      <div className={styles.inputTextarea}>
        <div className={styles.label}>prompt:</div>
        <Input.TextArea
          className={styles.textArea}
          value={props?.prompt}
          rows={4}
          placeholder="You are a recipe dish suggestor..."
        ></Input.TextArea>
      </div>
    </div>
  );
}
