import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Card, Select, Spin, Tag } from "antd";
import { DeleteOutlined, LoadingOutlined, PlayCircleFilled } from "@ant-design/icons";
import { Draggable, Droppable } from "react-beautiful-dnd";
import func from "@uikit/func";

import { studioActions } from "@store/studio";
import { feedActions } from "@store/feed";
import { fotActions } from "@store/fot";
import { Api } from "@uikit/service/api";
import studioPublicMethod from "@uikit/StudioPublicMethod";

const { Option } = Select;

const StudioLayoutCard = () => {
  const dispatch = useDispatch();
  const api = new Api(false);

  // store states
  const loadingState = useSelector(state => state.studio.loadingState);
  const setLoadingState = val => {
    dispatch(studioActions.setLoadingState(val));
  };

  const layoutComponents = useSelector(state => state.studio.layoutComponents);
  const setLayoutComponents = val => {
    dispatch(studioActions.setLayoutComponents(val));
  };

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);

  const selectedDomain = useSelector(state => state.studio.selectedDomain);

  const updateLayout = useSelector(state => state.studio.updateLayout);
  const setUpdateLayout = val => {
    dispatch(studioActions.setUpdateLayout(val));
  };

  const setThoughts = val => {
    dispatch(feedActions.setThoughts(val));
  };

  // local states
  const [updateLayoutSpin, setUpdateLayoutSpin] = useState(false);

  const handleClickRun = () => {
    dispatch(fotActions.setFetching(true));
    setLoadingState({ layoutRunBtn: true });
    setUpdateLayoutSpin(true);
    const data = {
      template_name: selectedTemplate.name,
      domain: selectedDomain,
    };
    setThoughts([]);
    api
      .post(`/be/bas-demo-v4/brain_studios/run_layout`, data)
      .then(response => response.json())
      .then(data => {
        handleRunLayoutResult((data[0] || {}).layout_ui);
      })
      .catch(error => {
        func.messageError(error);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({ layoutRunBtn: false });
        setUpdateLayout(false);
        setUpdateLayoutSpin(false);
      });
  };

  const postUpdateLayout = layoutData => {
    dispatch(fotActions.setFetching(true));
    setLoadingState({ layoutRunBtn: true });
    setUpdateLayoutSpin(true);
    const data = {
      template_name: selectedTemplate.name,
      layout_components: layoutData.layout_components,
      layout_maps: layoutData.layout_maps,
    };
    api
      .post(`/be/bas-demo-v4/brain_studios/update_layout`, data)
      .then(response => response.json())
      .then(data => {
        setLayoutComponents(data[0].layout_components);
      })
      .catch(error => {
        func.messageError(error);
      })
      .finally(_ => {
        dispatch(fotActions.setFetching(false));
        setLoadingState({ layoutRunBtn: false });
        setUpdateLayout(false);
        setUpdateLayoutSpin(false);
      });
  };

  const handleRunLayoutResult = async layoutData => {
    try {
      const resultsArr = [];
      for (let i = 0; i < layoutData.length; i++) {
        const type = layoutData[i].template || "textbox";
        const value = layoutData[i].value[type];
        const outputObj = await studioPublicMethod.handleStockData(type, value);
        const results = studioPublicMethod.toThoughts(type, outputObj);
        resultsArr.push(results);
      }
      setThoughts(resultsArr);
    } catch (error) {
      func.messageError(error);
    }
  };

  const convertLayout = () => {
    const maps = [];
    const components = [];
    layoutComponents.forEach(each => {
      const map = {};
      map[each.component_template] = each.name;
      maps.push({
        map,
        map_options: {},
        name: each.name,
      });
      components.push(each);
    });
    return { layout_maps: maps, layout_components: components };
  };

  const handleClickDeleteComponent = idx => {
    const updatedList = [...layoutComponents];
    updatedList.splice(idx, 1);
    setLayoutComponents(updatedList);
    setUpdateLayout(true);
  };

  const runLayoutDisabled = () => {
    return !selectedTemplate.template_name;
  };

  useEffect(() => {
    if (updateLayout) {
      const convertedLayoutObj = convertLayout(layoutComponents);
      postUpdateLayout(convertedLayoutObj);
    }
  }, [updateLayout]);

  useEffect(() => {
    if (selectedTemplate.layout_components) {
      setLayoutComponents(selectedTemplate.layout_components);
    }
  }, [selectedTemplate.layout_components]);

  return (
    <Card
      className="Studio-Layout-Card shadow"
      title="Layout"
      actions={[
        <Button
          type="primary"
          icon={<PlayCircleFilled />}
          onClick={handleClickRun}
          loading={loadingState.layoutRunBtn}
          disabled={runLayoutDisabled()}
        >
          Run Layout
        </Button>,
      ]}
      extra={
        updateLayoutSpin ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 14, marginRight: "15px" }} spin />} />
        ) : (
          ""
        )
      }
    >
      <Spin tip="Loading..." spinning={loadingState.layoutCard}>
        <Droppable droppableId="layout-components">
          {provided => (
            <ul className="Studio-Layout-Card-Container" {...provided.droppableProps} ref={provided.innerRef}>
              {layoutComponents.map((item, idx) => (
                <Draggable key={`component-${item.name}`} draggableId={`component-${item.name}`} index={idx}>
                  {provided => (
                    <Card
                      key={idx}
                      title={item.name}
                      bodyStyle={{ display: "none" }}
                      extra={
                        <div>
                          <Tag color="magenta">{item.component_template}</Tag>
                          <DeleteOutlined
                            className="Studio-Variable-Delete-Icon"
                            onClick={() => handleClickDeleteComponent(idx)}
                          />
                        </div>
                      }
                      className="Studio-Layout-Component-Card shadow-shallow"
                      actions={[]}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    ></Card>
                  )}
                </Draggable>
              ))}
            </ul>
          )}
        </Droppable>
      </Spin>
      {/* <Button
        size="large"
        shape="circle"
        type="primary"
        icon={<PlusOutlined />}
        className="Studio-Layout-Plus-Button"
        // onClick={handleClickNewVariable}
      ></Button> */}
    </Card>
  );
};

export default StudioLayoutCard;
