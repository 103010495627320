import { AISaasInputItem } from "./item";
import { useAiSaasInputContainer } from "./container";

export function AISaasInput(): JSX.Element {
  const { sortableList } = useAiSaasInputContainer();

  return (
    <>
      {sortableList.map((value: any, index: number) => {
        return <AISaasInputItem key={value.id} x={value} index={index} isShare={false} />;
      })}
    </>
  );
}
