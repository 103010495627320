"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlUtil = void 0;
class UrlUtil {
    /**
     * 判断链接是否为一个s3url
     * @param url
     */
    static isS3Url(url) {
        return (url.startsWith("https://s3.us-east-2.amazonaws.com/brain.ai") ||
            url.startsWith("http://s3.us-east-2.amazonaws.com/brain.ai"));
    }
}
exports.UrlUtil = UrlUtil;
