var words = require("shellwords");

export interface ParsedCurlOptions {
  method: string;
  url?: string;
  header: Record<string, string>;
  body?: string;
}

export default function parseCurlString(curlString: string): ParsedCurlOptions | null {
  if (!curlString.startsWith("curl ")) return null;

  const args = rewrite(words.split(curlString));

  const parsedOptions: ParsedCurlOptions = { method: "GET", header: {} };
  let state = "";

  args.forEach(function (arg) {
    switch (true) {
      case isURL(arg):
        parsedOptions.url = arg;
        break;

      case arg === "-A" || arg === "--user-agent":
        state = "user-agent";
        break;

      case arg === "-H" || arg === "--header":
        state = "header";
        break;

      case arg === "-d" || arg === "--data" || arg === "--data-ascii":
        state = "data";
        break;

      case arg === "-u" || arg === "--user":
        state = "user";
        break;

      case arg === "-I" || arg === "--head":
        parsedOptions.method = "HEAD";
        break;

      case arg === "-X" || arg === "--request":
        state = "method";
        break;

      case arg === "-b" || arg === "--cookie":
        state = "cookie";
        break;

      case arg === "--compressed":
        parsedOptions.header["Accept-Encoding"] = parsedOptions.header["Accept-Encoding"] || "deflate, gzip";
        break;

      case arg === "--data-raw":
        state = "data";
        break;

      case !!arg:
        switch (state) {
          case "header":
            const field = parseField(arg);
            parsedOptions.header[field[0]] = field[1];
            state = "";
            break;
          case "user-agent":
            parsedOptions.header["User-Agent"] = arg;
            state = "";
            break;
          case "data":
            if (parsedOptions.method === "GET" || parsedOptions.method === "HEAD") {
              parsedOptions.method = "POST";
            }
            parsedOptions.header["Content-Type"] =
              parsedOptions.header["Content-Type"] || "application/x-www-form-urlencoded";
            parsedOptions.body = parsedOptions.body ? parsedOptions.body + "&" + arg : arg;
            state = "";
            break;
          case "user":
            parsedOptions.header["Authorization"] = "Basic " + btoa(arg);
            state = "";
            break;
          case "method":
            parsedOptions.method = arg;
            state = "";
            break;
          case "cookie":
            parsedOptions.header["Set-Cookie"] = arg;
            state = "";
            break;
        }
        break;
    }
  });

  return parsedOptions;
}

/**
 * Rewrite args for special cases such as -XPUT.
 */
function rewrite(args: string[]): string[] {
  return args.reduce<string[]>((acc, a) => {
    if (a.startsWith("-X")) {
      acc.push("-X", a.slice(2));
    } else {
      acc.push(a);
    }
    return acc;
  }, []);
}

/**
 * Parse header field.
 */
function parseField(s: string): string[] {
  return s.split(/: (.+)/);
}

/**
 * Check if `s` looks like a URL.
 */
function isURL(s: string): boolean {
  // TODO: Others at some point
  return /^https?:\/\//.test(s);
}
