import style from "./ChatInput.module.scss";
import { useSignal } from "@preact/signals-react";
import classNames from "classnames";
import func from "@uikit/func";
import { ChatAIStore } from "@uiview/views/ChatAI/ChatAIStore";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { useAISaasPreviewItem } from "@uiview/views/AISaas/hooks/useAISaasPreviewItem";
import { useRef } from "react";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { PriceText } from "@uiview/views/ShareAccount/PriceText";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import TextArea from "antd/lib/input/TextArea";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";

export function ChatInput(props: {
  isLoading: boolean | undefined;
  id?: string;
  type?: string;
  label?: string;
  inputVal?: string;
  placeholder?: string;
  page?: string;
  labelStyle?: any;
  inputStyle?: any;
  onPressEnter?: (txt: string) => void;
  onLabelChange?: (label: string) => void;
  onLabelRemove?: (label: string) => void;
  onInputChange?: (inputVal: string) => void;
  onInputRemove?: (label: string) => void;
}) {
  let {
    id = "",
    type = "input",
    label = "Title for the field",
    placeholder = "Type here...",
    inputVal = "",
    onPressEnter,
    onLabelChange = () => {},
    onLabelRemove = () => {},
    onInputChange = () => {},
    onInputRemove = () => {},
    labelStyle = {},
    inputStyle = {},
  } = props;

  const chatAIStore = getIt(ChatAIStore);
  const chatAIState = useStore(chatAIStore).value;
  const homePluginStore = getIt(HomePluginStore);
  const isShare = homePluginStore.state.isShare;
  const { onPreviewItemMouseEnter, onPreviewItemMouseLeave } = useAISaasPreviewItem();

  const showEditLabel = useSignal(false);
  const inputValue = useSignal("");
  const inputState = useSignal("");

  const chatInputLabelBoxRef = useRef(null);
  const chatInputLabelRef = useRef(null);
  const chatInputBoxRef = useRef(null);
  const chatInputRef = useRef(null);

  const store = getIt(PublishSubscriptionStore);
  const state = useStore(store).value;
  const authStore = getIt(FotAuthStore);

  const chatInputClass = {
    [style.chatStickyBottom]: !chatAIState.isInSharedBodyBoxRefBottom,
    [style.canvasChatInput]: !isShare,
  };
  const inputClass = {
    [style.hideBorderLeft]: !func.isEmpty(inputValue.value),
    [style.hightLightBorderLeft]: inputState.value === "focus",
  };

  const handlePressEnter = () => {
    //  如果是付费APP，并且没有付费
    if (state.isPaidApp && !state.isPay) {
      if (!authStore?.state?.brainToken) {
        return store.setOpenPayWallAndLogin();
      }
      return store.setIsOpenPayWall(true);
    }
    onPressEnter && onPressEnter(inputValue.value || inputVal);
    inputValue.value = "";
  };

  const onLabelMouseEnter = () => {
    if (type === "chat") return;
    onPreviewItemMouseEnter({
      page: "chat",
      type: "inputNodeLabel",
      id,
      container: chatInputLabelBoxRef.current,
      edit: () => {
        showEditLabel.value = true;
        setTimeout(() => {
          (chatInputLabelRef?.current as any)?.focus?.({
            cursor: "end",
          });
        });
      },
      remove: onLabelRemove,
    });
  };
  const onInputMouseEnter = () => {
    if (type === "chat") return;
    onPreviewItemMouseEnter({
      page: "chat",
      type: "inputField",
      id,
      container: chatInputBoxRef.current,
      edit: () => {
        (chatInputRef?.current as any)?.focus?.({
          cursor: "end",
        });
        chatAIState.editingType = "textAreaValue";
      },
      editPlaceholder: () => {
        (chatInputRef?.current as any)?.focus?.({
          cursor: "end",
        });
        chatAIState.editingType = "placeholder";
        inputVal = placeholder;
      },
      remove: onInputRemove,
    });
  };

  //  数据加载完成，且是付费APP，且没有付费
  const isShowPrice = isShare && !state.loading && state.isPaidApp && !state.isPay;

  return (
    <div className={classNames(style.chatInput, chatInputClass)}>
      <div
        ref={chatInputLabelBoxRef}
        onMouseEnter={onLabelMouseEnter}
        onMouseLeave={e => {
          if (type === "chat") return;
          onPreviewItemMouseLeave(e);
        }}
      >
        {!showEditLabel.value ? (
          <label style={labelStyle}>{label}</label>
        ) : (
          <input
            ref={chatInputLabelRef}
            value={label}
            onChange={e => onLabelChange(e.target.value)}
            className={style.labelInput}
            type="text"
            style={labelStyle}
            onBlur={() => {
              showEditLabel.value = false;
            }}
          />
        )}
      </div>

      <div
        style={{ position: "relative", display: "flex" }}
        ref={chatInputBoxRef}
        onMouseEnter={onInputMouseEnter}
        onMouseLeave={e => {
          if (type === "chat") return;
          onPreviewItemMouseLeave(e);
        }}
      >
        <div className={style.leftLine}></div>
        <TextArea
          ref={chatInputRef}
          disabled={props.isLoading}
          placeholder={placeholder}
          className={classNames(style.inputBar, inputClass)}
          style={inputStyle}
          value={inputVal || inputValue.value}
          onChange={e => {
            onInputChange(e.target.value);
            inputValue.value = e.target.value;
          }}
          onFocus={() => (inputState.value = "focus")}
          onBlur={() => {
            inputState.value = "blur";
            chatAIState.editingType = "textAreaValue";
          }}
          onPressEnter={handlePressEnter}
          autoSize={{ minRows: 1, maxRows: 10 }}
        />
      </div>
      {/* todo: 这里文本显隐有动画 */}
      {type === "input" && !func.isEmpty(inputValue.value) && (
        <div className={style.pressEnter}>
          Press <span>Enter</span> to continue
        </div>
      )}
      {isShowPrice && <PriceText className={style.priceText} />}
    </div>
  );
}
