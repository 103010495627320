import { PROJECT_NAME_LENGTH } from "@views/thinking-layout-editor/constants";
import { Button, Form, Input, Modal } from "antd";
import func from "@uikit/func";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { functionPopupStore } from "@uikit/store/FunctionPopupStore";
import { getIt } from "@uikit/getIt";
import { SaveTemplateService } from "@uikit/service/SaveTemplateService";
import { useCallback } from "react";

export function CreatorFunctionPopup() {
  const saveTemplateService = getIt(SaveTemplateService);
  const functionPopupState = useImagicaStore(functionPopupStore).value;

  const onClick = useCallback(() => {
    saveTemplateService.clickModalSaveBtn();
  }, []);

  return (
    <Modal
      title={functionPopupState.createFunctionTitle}
      centered
      open={functionPopupState.createNewFuncModal}
      onCancel={() => functionPopupStore.setCreateNewFuncModal(false)}
      maskStyle={{ background: "transparent" }}
      footer={[
        <Button
          key="submit"
          type="primary"
          disabled={func.isEmpty(functionPopupState.funcName)}
          onClick={onClick}
          loading={functionPopupState.saveFunctionLoading}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
        autoComplete="off"
        validateTrigger="onBlur"
      >
        <Form.Item label="Name">
          <Input
            maxLength={PROJECT_NAME_LENGTH.max}
            placeholder="Enter function name"
            value={functionPopupState.funcName}
            onChange={e => {
              functionPopupStore.setFuncName(e.target.value.slice(0, PROJECT_NAME_LENGTH.max));
            }}
          />
        </Form.Item>

        <Form.Item label="Description">
          <Input
            placeholder="Enter function description"
            value={functionPopupState.funcDescription}
            onChange={e => functionPopupStore.setFuncDescription(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
