import { Type } from "class-transformer";
import { StudioProjectAttributes } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributes";
import { ErrorUtil } from "imagica-corekit/dist/base/api/ErrorUtil";
import isBlank from "@sedan-utils/is-blank";

export class DomainCheckResponse {
  public detail: string[] | string = [];

  @Type(() => Instruction)
  public instructions?: Instruction;

  get getError(): string {
    let errorText = ErrorUtil.defaultErrorMsg;
    if (typeof this.detail === "string" && !isBlank(this.detail)) {
      errorText = this.detail;
    }
    if (this.detail instanceof Array && !isBlank(this.detail)) {
      errorText = this.detail[0];
    }
    return errorText;
  }
}
export class Instruction {
  public description: string = "";

  @Type(() => InstructionItem)
  public instructions: Array<InstructionItem> = [];

  @Type(() => Link)
  public link?: Link;
}
export class InstructionItem {
  public Type: string = "";
  public Name: string = "";
  public Value: string = "";
  public Status: string = "";
  get isSuccess(): boolean {
    return this.Status.toLowerCase() === "success";
  }
  equral(to: InstructionItem) {
    return this.Name === to.Name && this.Value === to.Value && this.Type === to.Type;
  }
}
class Link {
  public value: string = "";
  public text: string = "";
}
