"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewBuilderService = void 0;
const LangUtil_1 = require("../cutil/LangUtil");
const Lazyload_1 = require("../cutil/Lazyload");
class ViewBuilderService {
    constructor(brainClient, fotClient) {
        this.brainClient = brainClient;
        this.fotClient = fotClient;
        this.lazyload = new Lazyload_1.Lazyload(async () => {
            const promptModel = {
                domain: "onboarding",
                model_params: `onboarding_xxx_view_builder.model`,
            };
            return this.brainClient.openAi.instantIntent(promptModel);
        });
    }
    async viewBuilder(input) {
        const result = await this.lazyload.get();
        const messages = [
            {
                content: result.data?.prompt,
                role: "system",
            },
            {
                content: input,
                role: "user",
            },
        ];
        return this.brainClient.openAi.chatCompletions({
            messages,
            model: result.data?.engine,
            response_format: undefined,
            stream: true,
        });
    }
    async imgSearch(key) {
        const res = await (0, LangUtil_1.tryPromise)(this.brainClient.openAi.imageSearch(key));
        return res;
    }
    async getMapAddressGps(addr) {
        return await (0, LangUtil_1.tryPromise)(this.fotClient.map.getMapAddressGps(addr));
    }
}
exports.ViewBuilderService = ViewBuilderService;
