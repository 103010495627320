import { useState, useRef } from "react";
import { Api } from "../uikit/service/api";
import { useSelector } from "react-redux";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";

const creatorSaasAppStore = getIt(CreatorSaasAppStore);

export default function useScrollBottomToFetch(contentRef) {
  const api = new Api();

  const domains = useSelector(state => state.feed.domains);
  const selectedDomain = useSelector(state => state.feed.selectedDomain);
  const scrollToBottomblackList = useSelector(state => state.fot.scrollToBottomblackList);

  const [contentFrameLoading, setContentFrameLoading] = useState(false);

  const moreLoading = useRef(false);

  async function atBottomStateChange(atBottom) {
    /**
     * scrollToBottomblackList.includes(currentDomain) 不需要滚动加载的domain
     * ！atBottom 不是滚动到底部时不执行
     * moreLoading.current 正在loading时不执行
     * if content still loading do not execute
     */
    // bsf-4912 replace thoughts
    const contentLoading = creatorSaasAppStore.state.saasUIData.output.every(x => x.loading);
    const currentDomain = domains[selectedDomain];
    if (contentLoading || scrollToBottomblackList.includes(currentDomain) || !atBottom || moreLoading.current) return;

    const virtuosoEl = [...contentRef.current.children].find(x => x.dataset.testId === "virtuoso-scroller") || null;
    if (func.isEmpty(virtuosoEl)) return;
    const canScroll = virtuosoEl.scrollHeight > virtuosoEl.clientHeight;
    try {
      setContentFrameLoading(true);
      moreLoading.current = true;
      const res = await api.getWithErrorCanAbort("/scroll");
      const newThoughts = res.data?.thoughts || [];

      if (func.isEmpty(newThoughts)) {
        // virtuosoEl 如果有滚动条才执行
        // if (canScroll) {
        //   util.messageUtil('No more data', 'warning')
        // }
        return;
      }
      // 取到最新的thoughts
      // bsf-4912 replace thoughts
      const recentThoughts = creatorSaasAppStore.state.saasUIData.output;
      // bsf-4912 use setSassUIData
      creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: recentThoughts }));
    } catch (error) {
      console.error("loadMore", error);
      if (canScroll) {
        func.messageError(error);
      }
    } finally {
      moreLoading.current = false;
      setContentFrameLoading(false);
    }
  }

  return {
    contentFrameLoading,
    atBottomStateChange,
  };
}
