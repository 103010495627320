import { TransformResponseData } from "imagica-corekit/dist/cases/cot/typed/TransformResponseData";

export class CotUtil {
  static generateData(data: TransformResponseData, query: string): any {
    return {
      template_name: "Untitled",
      name: "Untitled",
      description: "",
      lastIndex: 1,
      v3: {
        edges: data.edges,
        nodes: data.nodes,
        showToolTipStatus: "init",
        nodeIndex: 15,
        edgeIndex: 4,
        autoImagica: {
          query,
        },
        version: 1,
        functions: [],
        UI: [
          {
            label: "",
            value: "saas",
            title: data.extraSaasUIData?.title, // cal api
            titleV3: {},
            // cal api
            subTitle: data.extraSaasUIData?.subTitle,
            subTitleV3: {},
            placeholder: "Enter something",
            input: data.inputs,
            output: data.outputs,
            showTitle: true,
            showSubTitle: true,
            titleStyle: "{}",
            subTitleStyle: "{}",
            staticMode: false,
            settings: {
              branding: true,
              width: 800,
              height: 600,
            },
            theme: "light",
            fontSize: 12,
            id: "appId-1",
            v2App: {
              id: "appId-1",
              type: "ai_app",
              appName: "My AI App",
              appDescription: "",
            },
          },
        ],
        previewAppId: "appId-1",
      },
      published_metadata: {
        uuid: "",
        original: true,
        stripe_product_id: "",
        stripe_price_id: "",
        stripe_account_id: "",
      },
    };
  }
}
