import { Type } from "class-transformer";

export class PreviewInput {
  id: string = "";
  type: string = "customNode";
  @Type(() => InputData)
  data: InputData = new InputData();
}

export class InputData {
  isResized: boolean = false;
  loadingNodeHtml: boolean = false;
  showWarning: boolean = false;
  showStopGenerateBtn: boolean = false;
  showFeedback: boolean = false;
  fromList: boolean = false;
  showGroupCollapseData: boolean = false;
  textAreaValue: string = "";
  inputTitle: string = "";
  generatedLabel: string = "";
  displayType: string = "";
  originId: string = "";
  blueprintId?: string = "";
  viewbuilderGraphUserInputId?: string = "";
  parentNode: string = "";
  parentNodeId: string = "";
  errorText: string = "";
  childNode: any[] = [];
  chatBoxParam: any[] = [];
  @Type(() => InputDropUploadFileData)
  dropUploadFileData: InputDropUploadFileData = new InputDropUploadFileData();
  @Type(() => InputAbortNode)
  abortNode: InputAbortNode = new InputAbortNode();
  @Type(() => InputViewBuilderData)
  viewBuilderData: InputViewBuilderData = new InputViewBuilderData();
  showBuilderUI: boolean = false;
  @Type(() => InputNodeIndexRef)
  nodeIndexRef: InputNodeIndexRef = new InputNodeIndexRef();
  @Type(() => InputEdgeIndexRef)
  edgeIndexRef: InputEdgeIndexRef = new InputEdgeIndexRef();
  share: boolean = false;
  showUploadFile: boolean = false;
  ownPlaceholder: boolean = false;
  functionType: string = "";
  placeholder: string = "";
}

export class InputDropUploadFileData {}

export class InputAbortNode {}

export class InputViewBuilderData {
  loadingHtml: boolean = false;
  domList: any[] = [];
}

export class InputNodeIndexRef {
  current: number = 0;
}

export class InputEdgeIndexRef {
  current: number = 0;
}
