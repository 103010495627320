"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctionNameUtil = void 0;
const FunctionRegistry_1 = require("../../storyV2/function/FunctionRegistry");
const StoryNodeDisplayType_1 = require("../../storyV2/domain/StoryNodeDisplayType");
class FunctionNameUtil {
    static parse(node) {
        const displayType = node.options.displayType;
        switch (displayType) {
            case StoryNodeDisplayType_1.StoryNodeDisplayType.AMAZONSHOPPING:
                return FunctionRegistry_1.FunctionName.amazonShopping;
            case StoryNodeDisplayType_1.StoryNodeDisplayType.WEEESHOPPING:
                return FunctionRegistry_1.FunctionName.weeeShopping;
            case StoryNodeDisplayType_1.StoryNodeDisplayType.JDSHOPPING:
                return FunctionRegistry_1.FunctionName.jdShopping;
            case StoryNodeDisplayType_1.StoryNodeDisplayType.SHOWFOODINTERFACE:
                return FunctionRegistry_1.FunctionName.food;
            case StoryNodeDisplayType_1.StoryNodeDisplayType.VIDEO:
            case StoryNodeDisplayType_1.StoryNodeDisplayType.BASEVIDEO:
                return FunctionRegistry_1.FunctionName.youtubeVideos;
        }
        return undefined;
    }
}
exports.FunctionNameUtil = FunctionNameUtil;
