import { get } from "lodash";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import func from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import { mapNewTemplatesById } from "./utilities";
import { UseProjectTemplateProps } from "./types";
import { ProjectTemplateType } from "imagica-corekit/dist/cases/webconfig/domain/ProjectTemplateType";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";

const gql = getIt(ObjectRelationGqlService);

/**
 * 为`stud-1183`提供新建项目模板的功能状态和逻辑
 *
 * - 目前不清楚是否需要挂载到 HomeContainer 上，如果需要后期可将hooks 挂载到容器上
 */
export function useProjectTemplate(props: UseProjectTemplateProps) {
  const webConfigState = useStore(getIt(WebConfigStore)).value;

  const userMe = useSelector((state: any) => state.editor.me);

  const setLoadingProject = useCallback((project_id: ProjectTemplateType["project_id"], loading = false) => {
    webConfigState.templates = mapNewTemplatesById(webConfigState.templates, project_id, { ui: { loading } });
  }, []);

  const _onShareProject = async (template: ProjectTemplateType, isDefaultTemplate: boolean = false) => {
    const authorID = userMe.id;
    const { project_id, title: projectName } = template;

    setLoadingProject(project_id, true);

    try {
      // 1. 复制模板项目
      const fields = ["id", "attrIndexer", "uuid"].join("\n");
      const shareStudioProject = await gql.shareStudioProject(project_id, authorID, "", true, projectName, fields);

      const attrIndexer = get(shareStudioProject, "attrIndexer");
      const id = get(shareStudioProject, "id");
      const uuid = get(shareStudioProject, "uuid");
      const relationId = get(shareStudioProject, "relationId");

      await props.onShareProject({
        attrIndexer,
        id,
        label: projectName,
        loading: false,
        relationId,
        uuid,
        value: projectName,
        defaultTemplateName: isDefaultTemplate ? projectName : "", // 记录默认的模版
      });

      setLoadingProject(project_id, false);
      // stud-1180: add event
      logEvent("click_manual_mode_template", {
        description: template.description,
        template_name: projectName,
        project_static_id: attrIndexer || uuid,
      });
    } catch (e) {
      func.messageUtil(e);
      setLoadingProject(project_id, false);
    }
  };

  return {
    onShareProject: _onShareProject,
  };
}
