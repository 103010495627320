"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamSimulator = exports.sperator = void 0;
const lodash_1 = require("lodash");
const DEFAULT_DELAY = 20;
exports.sperator = " ";
function getDelay(delay) {
    if ((0, lodash_1.isFunction)(delay)) {
        return delay();
    }
    if ((0, lodash_1.isNumber)(delay) && delay > 0) {
        return delay;
    }
    return DEFAULT_DELAY;
}
/**
 * stream 模拟器
 */
class StreamSimulator {
    constructor(config) {
        this.status = "fulfilled";
        this.delay = DEFAULT_DELAY;
        this.content = "";
        this.reset = (config) => {
            const { delay, content } = config || {};
            delay && (this.delay = delay);
            content && (this.content = content);
            this.stop();
            return this;
        };
        this.stop = () => {
            this.status = "fulfilled";
            clearTimeout(this.timer);
            this.timer = undefined;
        };
        this.sleep = async () => {
            return new Promise(resolve => {
                this.timer = setTimeout(resolve, getDelay(this.delay));
            });
        };
        this.startByWord = async (handler) => {
            const texts = this.content.split(exports.sperator);
            const streamList = [];
            const run = async () => {
                if (!texts.length) {
                    this.stop();
                    return;
                }
                await this.sleep();
                const content = texts.shift();
                streamList.push(content + exports.sperator);
                if (content) {
                    handler?.({ status: this.status, streamList });
                    if (this.timer) {
                        await run();
                    }
                }
            };
            this.status = "pending";
            await run();
            this.status = "fulfilled";
            handler?.({ status: this.status, streamList });
        };
        this.startByLength = async (handler) => {
            const run = async (endIndex = 0) => {
                if (endIndex >= this.content.length) {
                    this.stop();
                    return;
                }
                await this.sleep();
                const end = endIndex + 2;
                const content = this.content.slice(0, end);
                handler?.({ streamList: [content], status: this.status });
                if (this.timer) {
                    await run(end);
                }
            };
            this.status = "pending";
            await run(0);
            this.status = "fulfilled";
            handler?.({ streamList: [this.content], status: this.status });
        };
        const { delay, content } = config || {};
        delay && (this.delay = delay);
        content && (this.content = content);
    }
}
exports.StreamSimulator = StreamSimulator;
