/* eslint-disable import/no-anonymous-default-export */
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesUtil } from "@uikit/util/CreatorNodesUtil";

export default function () {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);

  const getNodeEdgeMaxIndex = (nodesParam, edgesParam) => {
    const nodes = nodesParam || creatorNodesStore.state.nodes;
    const edges = edgesParam || creatorEdgesStore.getEdges();

    return CreatorNodesUtil.getNodeEdgeMaxIndex(nodes, edges);
  };

  return {
    getNodeEdgeMaxIndex,
  };
}
