import HomeNavBar from "@views/thinking-layout-editor/HomeNavBar";
import AutoResizablePanel from "../AutoResizablePanel/AutoResizablePanel";
import style from "./HomeUsePlugin.module.css";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { FotHomeNavBar } from "../navBar/FotHomeNavBar";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { getIt } from "@uikit/getIt";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { SaveTemplateService } from "@uikit/service/SaveTemplateService";
import { ShowNavPageStore } from "./store/ShowNavPageStore";

export function PageNavBar(pageNavBarProps: any): JSX.Element {
  const gql = getIt(ObjectRelationGqlService);
  const showNavPageStore = getIt(ShowNavPageStore);
  const homeMethods = getIt(HomeMethodsService);
  const showNavPageState = useImagicaStore(showNavPageStore).value;
  const page = showNavPageState.showNavPage ? "home" : "canvas";
  const creatorStoreMethods = getIt(CreatorStoreMethods);

  const myModificationDataState = useImagicaStore(myModificationDataStroe).value;

  const navigate = useNavigate();
  const userMe = useSelector((state: RootState) => state.editor.me) as any;
  const createJSFuncData = useSelector((state: RootState) => state.editor.createJSFuncData);

  const createAPIFuncData = useSelector((state: RootState) => state.editor.createAPIFuncData);

  const isFunc = createAPIFuncData?.open || createJSFuncData?.open;

  const clickMyAccount = useCallback(() => {
    navigate("profile/account");
  }, [navigate]);

  const publishFunction = useCallback(
    async (name: any, description: any, functionData: any) => {
      let attributes = functionData || getIt(SaveTemplateService).getCurrentStateOfFunctionForSaving();
      try {
        if (typeof attributes.edgeArr === "string") {
          attributes = Object.assign({}, attributes, { edgeArr: JSON.parse(attributes.edgeArr) });
        }
        if (typeof attributes.nodeArr === "string") {
          attributes = Object.assign({}, attributes, { nodeArr: JSON.parse(attributes.nodeArr) });
        }
      } catch (e) {
        console.error("JSON parse function data error", e);
      }
      await gql.createObject(
        {
          name: "studio_function",
          isGlobal: true,
          attributes: Object.assign({}, attributes, {
            name,
            projectName: name,
            description: description,
            author_user_id: userMe.id,
          }),
        },
        "Successfully published"
      );
    },
    [userMe.id]
  );

  return (
    <AutoResizablePanel className={style["top-pane"]} data-creator="NavBar">
      {myModificationDataState.showMyModification || isFunc ? (
        <HomeNavBar
          page={page}
          stickyNotes={true}
          clickMyAccount={clickMyAccount}
          // fixme:opt: clickRunAllBtn
          resetUndoData={creatorStoreMethods.resetUndoData}
          emptyNodes={homeMethods.emptyNodes}
          publishFunction={publishFunction}
        />
      ) : (
        <FotHomeNavBar
          page={page}
          resetUndoData={creatorStoreMethods.resetUndoData}
          emptyNodes={homeMethods.emptyNodes}
        />
      )}
    </AutoResizablePanel>
  );
}
