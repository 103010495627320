"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockInfoModel = void 0;
class StockInfoModel {
    constructor() {
        this.logo = "";
        this.name = "";
        this.symbol = "";
        this.price = "";
        this.volume = 0;
    }
    static create(stock) {
        const stockInfo = new StockInfoModel();
        stockInfo.logo = stock.data?.logo || "";
        stockInfo.name = stock.data?.name || "";
        stockInfo.symbol = stock.data?.symbol || "";
        const priceNumber = stock.data?.price || 0;
        stockInfo.price = "$ " + priceNumber;
        stockInfo.volume = stock.data?.volume || 0;
        return stockInfo;
    }
}
exports.StockInfoModel = StockInfoModel;
