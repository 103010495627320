import { useEffect, useState } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Api } from "../uikit/service/api";
import func from "@uikit/func";
import { fotActions } from "../store/fot";
import { signal } from "@preact/signals-react";
import { editorActions } from "@store/editor";
import { getIt } from "@uikit/getIt";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { useAsyncEffect } from "ahooks";

export default function useAvatarUrl() {
  const store = useStore();
  const dispatch = useDispatch();
  const api = new Api(false);

  const [userLoading, setUserLoading] = useState(false);

  const userAvatarUrl = useSelector(state => state.fot.userAvatarUrl);
  const setUserAvatarUrl = val => {
    dispatch(fotActions.setUserAvatarUrl(val));
  };

  const meLoading = useSelector(state => state.editor.meLoading);
  const setMe = val => {
    dispatch(editorActions.setMe(val));
  };
  const url = store.getState().editor.me?.profile?.profile_img_url;
  const meStore = getIt(MeStore);
  const updateUserAvatarUrl = async () => {
    if (meStore.state.id === undefined) return;
    const icon = (await tryPromise(meStore.profileImgUrl())).data;
    setUserAvatarUrl(icon);
  };
  useAsyncEffect(async () => {
    meStore.on(me => {
      updateUserAvatarUrl();
    });
  }, []);

  useEffect(() => {
    setUserLoading(meLoading);
    if (meLoading) {
      return;
    }
    updateUserAvatarUrl();
  }, [meLoading, url]);
  return {
    userAvatarUrl,
    userLoading,
  };
}
