import cls from "classnames";
import { Variants, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styles from "./ChatPanel.module.scss";
import { CotChatStatus, MultiRoundChatOnboardingBloc } from "./MultiRoundChatOnboardingBloc";
import { VirtualAssistant } from "./VirtualAssistant";
import { LoadingMotion } from "./motion/LoadingMotion";
import { LoadingTexts } from "./motion/LoadingTextsMotion";
import { background1, background2, transition } from "./motion/motionConfig";
import { useCurrentStatus } from "./useCurrentStatus";
import { InputBar } from "@uiview/views/MultiRoundChatOnboarding/components/OnboardingInput/InputBar";
import { useSignal } from "@preact/signals-react";

const maskVariants = {
  normal: { background: "rgba(255, 255, 255, 0.1)" },
  creating: { background: "rgba(255, 255, 255, 0.2)" },
  completeCreating: { background: "rgba(255, 255, 255, 1)" },
};

export const ChatPanel = ({
  bloc,
  startMotionComplete,
  setIsCallerBotAI,
}: {
  bloc: MultiRoundChatOnboardingBloc;
  onClose: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  startMotionComplete: boolean;
  setIsCallerBotAI: (bool: boolean) => void;
}): JSX.Element => {
  const cotChatStatus = bloc.cotChatStatus.value;
  const { currentStatus } = useCurrentStatus(bloc);
  const prevCotChatStatus = useRef<CotChatStatus | null>(null);
  const [loadingBackgroundMotion, setLoadingBackgroundMotion] = useState<boolean>(false);
  const [backgroundMotion, setBackgroundMotion] = useState<React.ReactElement | null>(null);
  const normalWidth = useSignal(0);

  const wrapperVariants: Variants = {
    normal: { minHeight: "90px", width: normalWidth.value + "px", borderRadius: "15px", transition },
    creating: { minHeight: "0px", borderRadius: "35px", width: "480px", height: "70px", transition },
    completeCreating: {
      minHeight: "0px",
      width: "50px",
      height: "50px",
      padding: "0px",
      borderRadius: "25px",
      transition: { duration: 0.3 },
    },
  };

  const handleThinkEnter = async (): Promise<void> => {
    setIsCallerBotAI(false);
  };

  const executeBackgroundMotion = (): void => {
    prevCotChatStatus.current = cotChatStatus;
    setLoadingBackgroundMotion(true);
    setBackgroundMotion(
      <motion.div
        key={Date.now()}
        animate={{
          background: cotChatStatus === "waiting" ? background1 : background2,
        }}
        transition={{
          duration: 1,
          times: [0, 1],
        }}
        onAnimationComplete={() => setLoadingBackgroundMotion(false)}
        className={styles.mask}
      />
    );
  };

  useEffect(() => {
    if (
      startMotionComplete === true &&
      loadingBackgroundMotion === false &&
      cotChatStatus !== prevCotChatStatus.current
    ) {
      executeBackgroundMotion();
    }
    // eslint-disable-next-line
  }, [startMotionComplete, cotChatStatus, loadingBackgroundMotion]);

  useEffect(() => {
    const width = window.innerWidth - 60; // 左右margin:30
    normalWidth.value = Math.min(width, 1000);
  }, [window.innerWidth]);

  const chatPanelClassName = cls(styles.chatPanel);
  const questionClassName = cls(styles.questionRow);
  const textContentClassName = cls(styles.textContent);
  const maskClassName = cls(styles.mask, startMotionComplete === false && styles.startMotionComplete);

  const chatContentElement =
    currentStatus === "normal" ? bloc.question.value : <LoadingTexts currentStatus={currentStatus} />;
  // const closeIconElement =
  //   currentStatus === "normal" ? (
  //     <img src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`} alt="" onClick={handleClickClose} />
  //   ) : null;
  const progressElement =
    currentStatus === "creating" ? (
      <motion.div
        className={styles.progress}
        animate={{
          width: ["0%", "20%", "40%", "60%", "80%", "90%"],
        }}
        transition={{
          duration: 4,
          ease: "linear",
          times: [0, 0.2, 0.4, 0.6, 0.8, 1],
          repeat: Infinity,
        }}
      />
    ) : null;

  return (
    <motion.div
      initial={"normal"}
      variants={wrapperVariants}
      animate={currentStatus}
      transition={transition}
      className={chatPanelClassName}
    >
      <div className={questionClassName}>
        <VirtualAssistant bloc={bloc} />
        <div className={textContentClassName}>
          {bloc.waitingThinking.value === true ? <LoadingMotion /> : chatContentElement}
        </div>
      </div>

      <motion.div
        initial={"normal"}
        variants={maskVariants}
        animate={currentStatus}
        transition={transition}
        className={maskClassName}
      />

      {backgroundMotion}

      {startMotionComplete === true ? (
        <>
          <InputBar onPressEnter={handleThinkEnter} bloc={bloc} />
        </>
      ) : null}
      {/*{closeIconElement}*/}
      {progressElement}
    </motion.div>
  );
};
