"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreBase = void 0;
const Eventsource_1 = require("./Eventsource");
class StoreBase extends Eventsource_1.Eventsource {
    constructor(initState) {
        super(initState);
    }
}
exports.StoreBase = StoreBase;
