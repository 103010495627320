import styles from "./AddEdgeBackView.module.scss";
import * as url from "url";
import { CSSProperties } from "react";
export function AddEdgeBackView(props: { className: string | undefined; fill: string | undefined }) {
  return (
    <svg className={props.className}>
      <defs>
        <clipPath id={"clipPath"}>
          <path
            d="
            M 0 0
            Q 0 21.5 15 28
            T 30 56
            Q 30 75.5 15 84
            T 0 112
            Z"
          />
        </clipPath>
      </defs>
      <rect
        x="0"
        y="0"
        style={{ clipPath: "url(#clipPath)", fill: props.fill }}
        className={styles.add_edge_back_view_content}
      ></rect>
    </svg>
  );
}
