import { AddNode } from "@uikit/service/AddNode";
import { EdgeRunService } from "@uikit/edgeRun/EdgeRunService";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { DataRefUtil } from "@uikit/util/DataRefUtil";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { RunEdgeAddNodeToPreview } from "@uikit/service/RunEdgeAddNodeToPreview";
import { EdgeRunTransformDataType } from "@uikit/edgeRun/EdgeRunCore";
import { CanvasNodeType, CanvasNodeData, CreatorNodesStore } from "@uikit/store/CreatorNodesStore";

export class ClickSlice {
  constructor(
    private creatorNodesStore: CreatorNodesStore,
    private addNode: AddNode,
    private edgeRunService: EdgeRunService,
    private projectRunStore: ProjectRunStore,
    private canvasDataRef: CanvasDataRef,
    private creatorEdgesStore: CreatorEdgesStore,
    private creatorStoreMethods: CreatorStoreMethods,
    private runEdgeAddNodeToPreview: RunEdgeAddNodeToPreview
  ) {}
  async clickSliceBtn(id: string): Promise<void> {
    //使用corekit的run
    const recentNodes = this.creatorNodesStore.getNodes();
    const sourceNode: CanvasNodeType<CanvasNodeData> | undefined = recentNodes.find(x => x.id === id);
    const prevNode = {
      id,
      xPos: sourceNode?.position.x,
      yPos: sourceNode?.position.y,
    };
    const { newEdge, sourceLine, targetLine } = this.addNode.addSliceNode({
      direction: "right",
      prevNode,
      isStickie: false,
    });
    this.creatorNodesStore.setNodes(prevList => {
      return prevList.map(x => {
        if (x.id === newEdge.id) {
          return {
            ...x,
            data: {
              ...x.data,
              isGetQuery: true,
              lineParam: {
                ...x.data.lineParam,
                enterText: "/Split Output",
                description: "Split large content into smaller pieces.",
              },
            },
          };
        }
        return x;
      });
    });
    const updateParam = {
      edgeId: newEdge.id,
      type: "lineParam",
      lineParam: {
        enterText: "/Split Output",
        description: "Split large content into smaller pieces.",
      },
    };

    const { transformData } = await this.edgeRunService.corekitRun({
      edgeId: newEdge.id,
      updateParam,
      onErrorClosure: () => {
        this.projectRunStore.setRunAllLoading(false);
      },
    });

    // undo/redo监听处理
    const updatedNewEdge = recentNodes.find(x => x.id === newEdge.id);
    const groupNodes = recentNodes
      .filter(x => transformData.some((y: EdgeRunTransformDataType) => y.id === x.id))
      .map(node => this.canvasDataRef.getSimpleNode(node));
    const updatedLines = this.creatorEdgesStore
      .getEdges()
      .filter(x => x.id === sourceLine.id || x.id === targetLine.id)
      .map(edge => {
        return DataRefUtil.getSimpleEdgeV3(edge, this.canvasDataRef.newLineDataRef);
      });
    this.creatorStoreMethods.onNodesEdgesAdd(
      [...groupNodes, this.canvasDataRef.getSimpleNodeV3(updatedNewEdge)],
      updatedLines
    );
    this.runEdgeAddNodeToPreview.firstRunAddNodeToPreview({ sourceNode, outputNode: groupNodes[0] });
  }
}
