"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EdgeDataLineParamIdentifier = exports.EdgeDataLineParam = exports.RelationSetDestRequestData = exports.RelationSetDestApiData = exports.RelationSetDestCodeData = exports.userModel = exports.RelationSetDestAttribute = exports.RelationSetDest = void 0;
const class_transformer_1 = require("class-transformer");
require("reflect-metadata");
const JsonUtil_1 = require("../../cutil/JsonUtil");
const StudioProjectAttributesV2Edge_1 = require("../../project/domain/StudioProjectAttributesV2Edge");
const StudioProjectAttributesV3Line_1 = require("../../project/domain/StudioProjectAttributesV3Line");
const StudioProjectAttributesV3Node_1 = require("../../project/domain/StudioProjectAttributesV3Node");
class RelationSetDest {
}
exports.RelationSetDest = RelationSetDest;
__decorate([
    (0, class_transformer_1.Type)(() => RelationSetDestAttribute),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toModelFromType(RelationSetDestAttribute, value.value);
    }, { toClassOnly: true })
], RelationSetDest.prototype, "attributesV2", void 0);
class RelationSetDestAttribute {
}
exports.RelationSetDestAttribute = RelationSetDestAttribute;
__decorate([
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        if (value.obj.isV3Function === true) {
            return JsonUtil_1.JsonUtil.toArrayFromType(StudioProjectAttributesV3Line_1.StudioProjectAttributesV3Line, value.value);
        }
        return JsonUtil_1.JsonUtil.toArrayFromType(StudioProjectAttributesV2Edge_1.StudioProjectAttributesV2Edge, value.value);
    }, { toClassOnly: true })
], RelationSetDestAttribute.prototype, "edgeArr", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => RelationSetDestCodeData),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toModelFromType(RelationSetDestCodeData, value.value);
    }, { toClassOnly: true })
], RelationSetDestAttribute.prototype, "codeData", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => RelationSetDestApiData),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toModelFromType(RelationSetDestApiData, value.value);
    }, { toClassOnly: true })
], RelationSetDestAttribute.prototype, "apiData", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => StudioProjectAttributesV3Node_1.StudioProjectAttributesV3Node)
], RelationSetDestAttribute.prototype, "nodeArr", void 0);
class userModel {
    constructor() {
        this.id = "";
        this.firstName = "";
        this.lastName = "";
    }
}
exports.userModel = userModel;
class RelationSetDestCodeData {
}
exports.RelationSetDestCodeData = RelationSetDestCodeData;
class RelationSetDestApiData {
}
exports.RelationSetDestApiData = RelationSetDestApiData;
__decorate([
    (0, class_transformer_1.Type)(() => RelationSetDestRequestData),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toModelFromType(RelationSetDestRequestData, value.value);
    }, { toClassOnly: true })
], RelationSetDestApiData.prototype, "requestData", void 0);
class RelationSetDestRequestData {
}
exports.RelationSetDestRequestData = RelationSetDestRequestData;
class EdgeDataLineParam {
}
exports.EdgeDataLineParam = EdgeDataLineParam;
__decorate([
    (0, class_transformer_1.Type)(() => EdgeDataLineParamIdentifier),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.stringify(value.value);
    }, { toPlainOnly: true }),
    (0, class_transformer_1.Transform)(value => {
        return JsonUtil_1.JsonUtil.toModelFromType(EdgeDataLineParamIdentifier, value.value);
    }, { toClassOnly: true })
], EdgeDataLineParam.prototype, "identifier", void 0);
class EdgeDataLineParamIdentifier {
}
exports.EdgeDataLineParamIdentifier = EdgeDataLineParamIdentifier;
