export const AddNewProjectIcon = (): JSX.Element => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_1_5959)">
      <rect width="60" height="60" rx="30" fill="#12192B" />
      <path d="M30 40V20" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40 30H20" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <filter
        id="filter0_b_1_5959"
        x="-40"
        y="-40"
        width="140"
        height="140"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_5959" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1_5959" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const DropDownIcon = (): JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 9L10 13L6 9" stroke="#12192B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
