import shoppingProductData from "../../ViewBuilderComponents/assets/shoppingProduct.json";
import shoppingSearchResultData from "../../ViewBuilderComponents/assets/shoppingSearchResultData.json";
import youtubeVideoData from "../../ViewBuilderComponents/assets/youtubeVideo.json";
import youtubeSearchResultsData from "../../ViewBuilderComponents/assets/youtubeSearchResultsData.json";
import itineraryPlanningData from "../../ViewBuilderComponents/assets/itineraryPlanning.json";
import newsVideoData from "../../ViewBuilderComponents/assets/newsVideo.json";
import newsHeadlineData from "../../ViewBuilderComponents/assets/newsJson.json";
import touristAttractionData from "../../ViewBuilderComponents/assets/touristAttraction.json";
import weatherCardData from "../../ViewBuilderComponents/assets/weatherCard.json";
import weatherWeeklyData from "../../ViewBuilderComponents/assets/weatherWeekly.json";
import c1 from "../../ViewBuilderComponents/assets/c1.json";
import c2 from "../../ViewBuilderComponents/assets/c2.json";
import c3 from "../../ViewBuilderComponents/assets/c3.json";
import c4 from "../../ViewBuilderComponents/assets/c4.json";
import textData from "../../ViewBuilderComponents/assets/text.json";
import mapData from "../../ViewBuilderComponents/assets/map.json";
import newsVideoListData from "../../ViewBuilderComponents/assets/newsVideoList.json";
import dishRecommendationData from "../../ViewBuilderComponents/assets/dishRecommendation.json";
import restaurantRecommendationData from "../../ViewBuilderComponents/assets/restaurantRecommendation.json";
import topicWithRelatedPeopleData from "../../ViewBuilderComponents/assets/topicWithRelatedPeople.json";
import topicWithRelatedDetail from "../../ViewBuilderComponents/assets/topicWithRelatedDetail.json";
import SeasonalTravelDestinationPlanData from "../../ViewBuilderComponents/assets/seasonalTravelDestinationPlan.json";
import newsDigest from "../../ViewBuilderComponents/assets/newsDigest.json";
import ImageNewsGridData from "../../ViewBuilderComponents/assets/imageNewsGrid.json";
import NewsDigestDetailsData from "../../ViewBuilderComponents/assets/newsDigestDetails.json";
export var VBComponentsMockDataMapping = {
  NewsVideo: newsVideoData,
  YoutubeVideo: youtubeVideoData,
  YoutubeSearchResults: youtubeSearchResultsData,
  C1: c1,
  C2: c2,
  C3: c3,
  C4: c4,
  ShoppingSearchResults: shoppingSearchResultData,
  mapView: mapData,
  Text: textData,
  WeatherCurrent: weatherCardData,
  WeatherWeekly: weatherWeeklyData,
  ItineraryPlanning: itineraryPlanningData,
  TouristAttraction: touristAttractionData,
  NewsHeadline: newsHeadlineData,
  ShoppingProduct: shoppingProductData,
  NewsVideoList: newsVideoListData,
  DishRecommendation: dishRecommendationData,
  RestaurantRecommendation: restaurantRecommendationData,
  TopicWithRelatedPeople: topicWithRelatedPeopleData,
  TopicWithRelatedDetail: topicWithRelatedDetail,
  SeasonalTravelDestinationPlan: SeasonalTravelDestinationPlanData,
  NewsDigest: newsDigest,
  ImageNewsGrid: ImageNewsGridData,
  NewsDigestDetails: NewsDigestDetailsData,
  showTrip: undefined
};