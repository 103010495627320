import { LoadingOutlined, SendOutlined } from "@ant-design/icons";
import useGetUserAvatarUrl from "@custom-hooks/useGetUserAvatarUrl";
import { useComputed } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { Spin, Steps } from "antd";
import TextArea from "antd/lib/input/TextArea";
import classNames from "classnames";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { map } from "lodash";
import { useEffect, useRef } from "react";
import Markdown from "react-markdown";
import { BlocType } from "../../CallerBot";
import { CallerBotBloc } from "../../CallerBotBloc";
import { Role } from "../../Constants";
import { GenUIBloc, GenUIProcess } from "../../GenUIBloc";
import { Message } from "../../OnboardingBloc";
import { CallPanel } from "../CallPanel/CallPanel";
import styles from "./Chatbox.module.scss";

type ChatboxProps = {
  bloc: BlocType;
};

export const ImagicaUrl = "https://dopniceu5am9m.cloudfront.net/static/230614_ai_listen/ASSET_AI_1_loop.mp4";

export function Chatbox(props: ChatboxProps) {
  const submitLoading = false;
  const listRef = useRef<HTMLDivElement | null>(null);

  const messages = useComputed(() => {
    return props.bloc.state.messages.value;
  }).value;

  const moveListScrollBarToBottom = () => {
    if (listRef.current) {
      if (listRef.current.scrollHeight <= listRef.current.clientHeight) return;
      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (suggestion: string) => {
    props.bloc.setSubmitValue(suggestion);
    props.bloc.onPressEnter();
  };

  useEffect(() => {
    if (messages.length) {
      moveListScrollBarToBottom();
    }
  }, [messages]);

  const closeIconClassName = classNames(styles.Icon, styles.closeIcon);
  const collapseClassName = classNames(
    styles.Icon,
    props.bloc.state.collapsed.value ? styles.collapseIcon : styles.expandIcon,
    props.bloc.state.currentStep.value === GenUIProcess.Build ? styles.show : styles.hide
  );

  return (
    <div className={styles.chatBox}>
      <div className={styles.chatHeader}>
        <span className={styles.chatHeaderTitle}>{props.bloc.state.title.value}</span>
        {props.bloc.state.collapsed.value === false && <Process bloc={props.bloc} />}
        <span className={collapseClassName} onClick={() => props.bloc.handleClickCollapse()}></span>
        <span className={closeIconClassName} onClick={() => props.bloc.closeCallerBot()}></span>
      </div>
      <div className={styles.chatboxContent}>
        <div className={styles.chatboxList} ref={listRef}>
          {messages.map((item, idx, array) => {
            const isLast = idx === array.length - 1;
            return (
              <div key={idx} className={styles.mgt20}>
                <div className={styles.chatboxItem}>
                  <div className={styles.chatboxItemWrapper}>
                    <ChatMessage bloc={props.bloc} {...item} />
                  </div>
                </div>
                <Suggestion bloc={props.bloc} item={item} onClick={handleClick} isLast={isLast} />
              </div>
            );
          })}
        </div>
        <div className={styles.chatboxFooter}>
          <div className={styles["chat-input-box"]}>
            <TextArea
              value={props.bloc.state.submitValue.value}
              onChange={e => props.bloc.setSubmitValue(e.target.value)}
              placeholder="Talk to your AI builder..."
              onPressEnter={() => props.bloc.onPressEnter()}
              className={styles["chat-input"]}
              autoSize={{ minRows: 1, maxRows: 18 }}
            />
            <div className={styles["chat-send-box"]}>
              {!submitLoading ? (
                <SendOutlined
                  style={{
                    color: "rgba(0,0,0,.45)",
                    fontSize: 28,
                  }}
                  onClick={() => props.bloc.onPressEnter()}
                />
              ) : (
                <LoadingOutlined
                  style={{
                    fontSize: 28,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Suggestion(props: { bloc: BlocType; item: Message; onClick: (suggestion: string) => void; isLast: boolean }) {
  const suggestionClassName = classNames(styles.chatboxItem, styles.suggestion);
  const actionClassName = classNames(styles.chatboxItem, styles.suggestion, styles.action);

  const handleClickLookingGood = () => {
    (props.bloc as GenUIBloc).updateMessage("Looking good, build me a prototype", Role.user);
    (props.bloc as GenUIBloc).prototyping();
  };

  const handleClickBuilding = () => {
    (props.bloc as GenUIBloc).updateMessage("Make it Real", Role.user);
    (props.bloc as GenUIBloc).building();
  };

  const submitLoading = props.bloc.state.submitLoading.value;
  const showPrototypingSuggestion =
    props.bloc.state.isCallerBotBloc.value === false &&
    (props.bloc as GenUIBloc).ideationBloc.ideation.value.app_generated &&
    (props.bloc as GenUIBloc).prototypingBloc.components.value.length === 0 &&
    submitLoading === false &&
    props.isLast;
  const showBuildingSuggestion =
    props.bloc.state.isCallerBotBloc.value === false &&
    (props.bloc as GenUIBloc).prototypingBloc.components.value.length !== 0 &&
    (props.bloc as GenUIBloc).buildBloc.graph.value.length === 0 &&
    submitLoading === false &&
    props.isLast;

  const showLoading =
    submitLoading === true &&
    ((props.bloc.state.currentStep.value === 0 && props.bloc.state.isCallerBotBloc.value === false) ||
      (props.bloc.state.currentStep.value === 1 && props.bloc.state.isCallerBotBloc.value)) &&
    isBlank(props.item.suggestion) &&
    props.item.role === Role.assistant &&
    props.item.content.length > 5;

  const showMakeCallButton =
    props.bloc.state.isCallerBotBloc.value === true &&
    (props.bloc as CallerBotBloc).state.showMakeCallButton.value &&
    submitLoading === false &&
    props.isLast;

  const showBehaviorButton =
    props.bloc.state.isCallerBotBloc.value === true &&
    (props.bloc as CallerBotBloc).state.showBehaviorButton.value &&
    submitLoading === false &&
    props.isLast;

  if (showPrototypingSuggestion) {
    /// genUI
    return (
      <div className={styles.suggestionWrapper}>
        <div className={actionClassName} onClick={() => handleClickLookingGood()}>
          Looking good, build me a prototype
        </div>
        <div className={suggestionClassName} onClick={() => props.onClick("Add more features")}>
          Add more features
        </div>
      </div>
    );
  }

  if (showBuildingSuggestion) {
    /// genUI
    return (
      <div className={styles.suggestionWrapper}>
        <div className={actionClassName} onClick={() => handleClickBuilding()}>
          Make it Real
        </div>
        <div className={suggestionClassName} onClick={() => props.onClick("Add more features")}>
          Add more features
        </div>
      </div>
    );
  }

  if (showMakeCallButton) {
    /// callerbot
    return (
      <div className={styles.suggestionWrapper}>
        <div className={actionClassName} onClick={() => (props.bloc as CallerBotBloc).makeItCall()}>
          Make a test call
        </div>
      </div>
    );
  }

  if (showBehaviorButton) {
    /// callerbot
    return (
      <div className={styles.suggestionWrapper}>
        <div className={actionClassName} onClick={() => (props.bloc as CallerBotBloc).happyBehavior()}>
          I’m happy with the bot behavior
        </div>
      </div>
    );
  }

  return (
    <div className={styles.suggestionWrapper}>
      {showLoading && <div className={suggestionClassName}>Suggestions Loading ...</div>}

      {map(props.item.suggestion, message => {
        if (isBlank(message)) {
          return null;
        }
        return (
          <div onClick={() => props.onClick(message)} className={suggestionClassName} key={message}>
            {message}
          </div>
        );
      })}
    </div>
  );
}

function ChatMessage(props: Message & { bloc: BlocType }) {
  if (props.content === "call") {
    return <CallPanel bloc={props.bloc as CallerBotBloc} />;
  }
  if (props.loading) {
    return (
      <div className={styles.content}>
        <Spin />
      </div>
    );
  }
  if (isBlank(props.role)) {
    return (
      <div className={styles.content}>
        {props.title && <div className={styles.chatboxTitle}>{props.title}</div>}
        <Markdown>{props.content.replace(/\{[^}]*\}/g, "")}</Markdown>
      </div>
    );
  }
  return (
    <>
      <UserLogo role={props.role as Role} />
      <div className={styles.content}>
        <p className={styles.chatboxRole}>{props.role === Role.user ? "You" : "Imagica"}</p>
        <div className={styles.chatboxText}>
          <Markdown>{props.content.replace(/\{[^}]*\}/g, "")}</Markdown>
        </div>
      </div>
    </>
  );
}

function UserLogo(props: { role: Role | string }) {
  const { userAvatarUrl } = useGetUserAvatarUrl();
  if (props.role === Role.user || props.role === "user") {
    return <img className={styles.userLogo} src={userAvatarUrl || settings.getViewAssetsPath("default.jpg")} />;
  }
  return (
    <video
      className={styles.userLogo}
      loop
      autoPlay
      playsInline
      x5-playsinline="true"
      webkit-playsinline="true"
      style={{ zIndex: 10 }}
      src={ImagicaUrl}
    />
  );
}

function Process(props: { bloc: BlocType }) {
  return (
    <div className={styles.processBar}>
      <Steps current={props.bloc.state.currentStep.value} size="small">
        {props.bloc.state.process.value.map(process => (
          <Steps.Step key={process} title={process} description={""} />
        ))}
      </Steps>
    </div>
  );
}
