import { useEffect } from "react";
import { studioActions } from "@store/studio";
import { useDispatch } from "react-redux";
import func from "@uikit/func";
import { NEW_EDGE_REG } from "@views/thinking-layout-editor/constants";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { getIt } from "@uikit/getIt";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { HomeUsePluginUtil } from "@uikit/util/HomeUsePluginUtil";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";

export function useEffect12(): void {
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const canvasDataRef = getIt(CanvasDataRef);

  const myModificationDataState = useImagincStore(myModificationDataStroe).value;

  const dispatch = useDispatch();
  const setSelectedTemplate = (val: any): void => {
    dispatch(studioActions.setSelectedTemplate(val));
  };

  useEffect(() => {
    if (!myModificationDataState.showMyModification) return;
    let nodeArr, edgeArr;
    try {
      nodeArr = JSON.parse(myModificationDataState.myModificationData.nodeArr);
      edgeArr = JSON.parse(myModificationDataState.myModificationData.edgeArr);
    } catch (error) {
      console.error(error);
    }
    if (!myModificationDataState.myModificationData?.isV3Function) {
      const { v3Edges, v3Lines } = HomeUsePluginUtil.transformToV3EdgeLines({
        nodes: nodeArr,
        edges: edgeArr,
        nodeIndexRef: creatorRefStore.nodeIndexRef,
        edgeIndexRef: creatorRefStore.edgeIndexRef,
        newEdgeDataRef: getIt(CanvasDataRef).newEdgeDataRef,
        newLineDataRef: getIt(CanvasDataRef).newLineDataRef,
      });
      nodeArr = nodeArr.concat(v3Edges);
      edgeArr = v3Lines;
    }
    setSelectedTemplate({
      id: myModificationDataState.myModificationData.id,
      template_name: myModificationDataState.myModificationData?.value || "",
      name: myModificationDataState.myModificationData?.value || "",
      isV3Function: true,
    });
    if (!func.isEmpty(edgeArr)) {
      const edges = edgeArr.map((x: { id: string; data: any }) => {
        const data = canvasDataRef.newLineDataRef.current;
        x.data = Object.assign({}, { ...data }, x.data);
        return x;
      });
      creatorEdgesStore.setEdges(edges);
    }
    if (!func.isEmpty(nodeArr)) {
      const nodes = nodeArr.map((x: { id: string; data: any }) => {
        const data = NEW_EDGE_REG.test(x.id) ? canvasDataRef.newEdgeDataRef.current : canvasDataRef.nodeDataRef.current;
        x.data = Object.assign({}, { ...data }, x.data);
        return x;
      });
      creatorNodesStore.setNodes(nodes);
    }
  }, [myModificationDataState.showMyModification]);
}
