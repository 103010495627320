import isBlank from "@sedan-utils/is-blank";
import styles from "./CommandItem.module.scss";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";

export type CommandItemProps = {
  name: string | undefined;
  description: string | undefined;
  score?: number;
  onClick?: (() => void) | undefined;
  enableBlueprintDetailPanel?: boolean;
  onClickTriggerBlueprintDetailPanel?: () => void;
};

export default function CommandItem(props: CommandItemProps) {
  const handleClickBtn: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation();
    props.onClickTriggerBlueprintDetailPanel?.();
  };

  return (
    <div className={styles.commandItem} onClick={props.onClick}>
      <div className={styles.icon}>
        <img src={`${settings.S3CDN}${settings.viewAssetsPath}code_white.svg`} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{props.name}</div>
        <div className={styles.desc}>{props.description}</div>
        {isBlank(props.score) === false && <div className={styles.score}>Similarity Score: {props.score}</div>}
      </div>
      {props.enableBlueprintDetailPanel === false && (
        <img onClick={handleClickBtn} alt="" src={`${settings.S3CDN}${settings.viewAssetsPath}info.svg`} />
      )}
    </div>
  );
}
