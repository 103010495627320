import { Api } from "@uikit/service/api";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { clone, find, get, isEmpty, map, set } from "lodash";
import { Node } from "reactflow";
import { BlueprintFunctionType } from "imagica-corekit/dist/base/storyV2/function/BlueprintFunction";
import { InputNode } from "imagica-corekit/dist/base/api/updateNodeType/updateNode";
import { LineParam } from "@uikit/project/HomeUsePluginData";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { ProjectNode } from "@uikit/projectNode/model";
import { ProjectNodeUtil } from "@uikit/projectNode/ProjectNodeUtil";

export class NodeEdgeInfoUpdater {
  private api: Api;
  constructor(
    public brainClient: BrainClient,
    public creatorNodesStore: CreatorNodesStore,
    public creatorEdgesStore: CreatorEdgesStore,
    public projectNodeStore: ProjectNodeStore,
    public creatorSaasAppStore: CreatorSaasAppStore
  ) {
    this.api = new Api(false);
  }

  getNodeInfo(projectNode: ProjectNode | undefined, node: Node<any> | undefined, intermediate?: boolean): InputNode {
    const textareaValue = get(node, "data.textAreaValue");
    return {
      node_id: node?.id || "",
      node_label: ProjectNodeUtil.getTitle(projectNode),
      node_placeholder: get(node, "data.placeholder", ""),
      node_value: typeof textareaValue === "string" ? textareaValue : "",
      intermediate,
    };
  }

  getAllInputNodes(): string[] {
    const nodes = this.creatorNodesStore.getNodes();
    const edges = this.creatorEdgesStore.getEdges();
    const targetNodes = edges.map(x => x.target);
    return nodes
      .filter(x => isEmpty(x.parentNode) && x.type === "customNode")
      .map(x => x.id)
      .filter(x => !targetNodes.includes(x));
  }

  public async updateEdgeText(id: string): Promise<string> {
    const targetNode = this.creatorNodesStore.getNodeById(id);
    const inputNodes = map(get(targetNode, "data.flows"), ({ sourceNodeId }) => {
      const node = this.creatorNodesStore.getNodeById(sourceNodeId);
      const projectNode = this.projectNodeStore.getNode(sourceNodeId);
      return this.getNodeInfo(projectNode, node);
    });

    const { data } = await this.brainClient.openAi.updateEdge({
      input_nodes: inputNodes,
      edge_description: get(targetNode, "data.lineParam.enterText"),
    });

    const edgeText = data.edge_description.replace(/"/g, "");

    this.creatorNodesStore.setNodes(prevNodes => {
      return map(prevNodes, node => {
        if (node.id === id) {
          return set({ ...node }, "data.lineParam.enterText", edgeText);
        }
        const info = find(data.input_nodes, (dataNode: any) => dataNode.node_id === node.id);
        if (info) {
          return set({ ...node }, "data", {
            ...node.data,
            generatedLabel: info.node_label,
            placeholder: info.node_placeholder,
          });
        }
        return node;
      });
    });

    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);

    return edgeText;
  }

  public async updateBlueprintEdge(target: string, edgeDescription: string): Promise<void> {
    const nodes = this.creatorNodesStore.getNodes();
    const targetNode = nodes.find(x => x.id === target);
    const flows = targetNode?.data.flows.map((x: any) => x.sourceNodeId);
    const inputs = nodes
      .filter(x => flows.includes(x.id))
      .map(x => ({
        name: ProjectNodeUtil.getTitle(this.projectNodeStore.getNode(x.id), "input"),
        type: x.data.displayType,
        description: x.data.placeholder,
      }));

    const res = await this.api.postWithError(`/be/langchain/blueprint_builder`, {
      edge_description: edgeDescription,
      inputs,
    });
    this.creatorNodesStore.setNodes((prevList: any[]) =>
      prevList.map(x => {
        if (x.id === target) {
          return {
            ...x,
            data: {
              ...x.data,
              lineParam: {
                ...x.data.lineParam,
                blueJson: JSON.stringify({
                  ...res.data?.blueprint,
                  functionType: BlueprintFunctionType.EXECUTE_BLUEPRINT,
                }),
              },
            },
          };
        }
        return x;
      })
    );
  }

  public async updateNodeText(lineParam: LineParam): Promise<void> {
    if (lineParam.functionDescription === "") {
      return;
    }
    let groupChildTitleOrder = 0;
    const targetNode = this.creatorNodesStore.getNodeById(lineParam.id);

    const inputNodes = this.getAllInputNodes();
    const flows = clone(get(targetNode, "data.flows") || []);
    const inputNodesInfo = map(flows, ({ sourceNodeId }) => {
      const node = this.creatorNodesStore.getNodeById(sourceNodeId);
      const isInputNode = inputNodes.includes(node?.id || "");
      const projectNode = this.projectNodeStore.getNode(sourceNodeId);
      return this.getNodeInfo(projectNode, node, !isInputNode);
    });

    const { data } = await this.brainClient.openAi.updateNode({
      input_nodes: inputNodesInfo,
      edge_description: lineParam.functionDescription,
      number_of_inputs: inputNodesInfo.length,
    });

    // Update input nodes
    this.creatorNodesStore.setNodes(prevNodes => {
      return map(prevNodes, node => {
        const info = find(
          data.input_nodes,
          (dataNode: any) => dataNode.node_id === node.id && inputNodesInfo.some(n => n.node_id === dataNode.node_id)
        );
        if (info) {
          return set({ ...node }, "data", {
            ...node.data,
            generatedLabel: info.node_label,
            placeholder: info.node_placeholder,
          });
        }
        return node;
      });
    });

    // Update output nodes
    this.creatorNodesStore.setNodes(prevNodes => {
      return map(prevNodes, node => {
        if (node.id === targetNode?.data.targetNodeId) {
          return set({ ...node }, "data", {
            ...node.data,
            generatedLabel: data.output_nodes.node_label,
            placeholder: data.output_nodes.node_description,
          });
        }
        if (node.parentNode === targetNode?.data.targetNodeId) {
          groupChildTitleOrder++;
          return set({ ...node }, "data", {
            ...node.data,
            generatedLabel: `${data.output_nodes.node_label}-${groupChildTitleOrder}`,
            placeholder: data.output_nodes.node_description,
          });
        }
        return node;
      });
    });

    this.projectNodeStore.composeNodes(this.creatorNodesStore.getNodes(), this.creatorSaasAppStore.state.saasUIData);
  }
}
