import { useCreation } from "ahooks";
import styles from "./TermsPopup.module.scss";
import { TermsPopupBloc, TermsPopupState } from "./TermsPopupBloc";
import { AModal } from "@uiview/views/AModal/AModal";
import { Button } from "antd";
import func from "@uikit/func";
import { ImagicaTermsPopup } from "imagica-corekit/dist/cases/webconfig/domain/ImagicaTermsPopup";

export function TermsPopup(props: {
  clickTermsButton: (type: string) => void;
  imagicaTermsPopup: ImagicaTermsPopup | undefined;
}) {
  return (
    <AModal
      open={true}
      className={styles["prompt-for-updates"]}
      onCancel={() => {}}
      buttons={[]}
      onOk={() => {}}
      closable={false}
    >
      <div className={styles["modal-title"]}>{props?.imagicaTermsPopup?.title}</div>

      <div className={styles["modal-content"]}>
        {!func.isEmpty(props?.imagicaTermsPopup?.content) &&
          props?.imagicaTermsPopup?.content.map((item: any, index: number) => {
            if (item.type === "link") {
              return (
                <a
                  key={`${index}`}
                  className={styles["text-bold"]}
                  onClick={() => {
                    window.open(item.value.link, "_blank");
                  }}
                >
                  {item.value.text}
                </a>
              );
            }
            if (item.type === "text") {
              return <span key={`${index}`}>{item.value}</span>;
            }
          })}
      </div>

      <footer className={styles["modal-footer"]}>
        {!func.isEmpty(props?.imagicaTermsPopup?.buttons) &&
          props?.imagicaTermsPopup?.buttons.map((item: any, index: number) => {
            return (
              <Button
                key={`${index}`}
                type={item?.type}
                className={styles["button-box"]}
                style={func.transformStyle(item?.style)}
                onClick={() => props?.clickTermsButton(item?.type)}
              >
                {item.text}
              </Button>
            );
          })}
      </footer>
    </AModal>
  );
}
