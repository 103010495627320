"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveCreateService = void 0;
const lodash_1 = require("lodash");
const SaveErrors_1 = require("./SaveErrors");
/**
 * 该类用来替换原来 CreateService, PublishProjectService 核心实现类
 *
 * - 内部方法会将原来写死的 func.messageError 错误消息通过抛出 `SaveApiFailedError` 错误，在外部处理
 * - 其余通过回调处理
 */
class SaveCreateService {
    constructor(saveApi) {
        this.saveApi = saveApi;
    }
    /**
     * 该方法由原来 CreateService.createProject 主要逻辑
     *
     * 其他包装逻辑是 saveAsNewTemplateV2，现在由当前类 saveAsFirstCreate 实现
     *
     * @param data
     * @param objectId
     * @param isGlobal
     * @returns
     */
    async createProject(params) {
        // create new project object
        const object = await this.saveApi.createObject({
            name: params.isGlobal ? "studio_shared_app" : "studio_project",
            isGlobal: params.isGlobal,
            attributes: params.data,
        });
        // if not global project, create relation between user and project object
        if (!params.isGlobal) {
            const relation = await this.saveApi.createRelation({
                destId: parseInt(object.id),
                sourceId: parseInt(params.objectId),
                type: "studio_project",
            });
            params.saveEvents?.updateSelectedTemplate({ relationId: relation.id });
        }
        // return attributes
        const attributes = JSON.parse(object.attributes);
        attributes.id = object.id;
        attributes.uuid = object.uuid;
        attributes.index = object.attrIndexer || object.uuid;
        return attributes;
    }
    /*
      attributes: updated attributes to be saved
      attr_indexer: attr_indexer or uuid of existing project
      projectId: objectId of existing project
    */
    async saveProject(params) {
        try {
            // get existing relation
            const existingRelation = await this.saveApi.relationSet({
                name: "studio_project",
                destId: parseInt(params.projectId),
            });
            const originalProject = existingRelation[0];
            const relationId = originalProject?.relationId || "";
            // 不管有没有 relationId 都需要保存数据
            // if (!relationId) {
            //   setLoading(false);
            //   throw new SaveApiFailedError("existing project may have been deleted");
            // }
            // get user me object id
            if (!params.objectId) {
                throw new SaveErrors_1.SaveApiFailedError("empty user meobject id");
            }
            // create new object based on updated attributes
            if (!(0, lodash_1.isEmpty)(originalProject)) {
                params.attributes.index = originalProject.attrIndexer || originalProject.uuid;
            }
            const updatedProject = await this.saveApi.createObject({
                name: "studio_project",
                attributes: params.attributes,
                isGlobal: false,
            });
            // remove relationship of old project
            if (!(0, lodash_1.isEmpty)(relationId)) {
                await this.saveApi.deleteRelations([relationId]);
            }
            let relation = {};
            // create relation of new project
            try {
                relation = await this.saveApi.createRelation({
                    sourceId: parseInt(params.objectId),
                    destId: parseInt(updatedProject.id),
                    type: "studio_project",
                });
            }
            catch (error) {
                // restore old relationship on error and re raise exception
                relation = await this.saveApi.createRelation({
                    sourceId: parseInt(params.objectId),
                    destId: parseInt(params.projectId),
                    type: "studio_project",
                });
                throw new SaveErrors_1.SaveApiFailedError(`save project failed: create relation with updated project failed - ${error.message}`);
            }
            params.saveEvent?.updateSelectedTemplate({ relationId: relation.id });
            // create studio_archive relation between updated project and old project
            await this.saveApi.createRelation({
                sourceId: updatedProject.id,
                destId: params.projectId,
                type: "studio_archive",
            });
            const updatedProjectAttributes = updatedProject.attributesV2;
            updatedProjectAttributes.attrIndexer = updatedProjectAttributes.attrIndexer || updatedProject.attrIndexer;
            updatedProjectAttributes.id = updatedProject.id;
            updatedProjectAttributes.uuid = updatedProject.uuid;
            updatedProjectAttributes.updatedAt = updatedProject.updatedAt;
            if (!(0, lodash_1.isEmpty)(params.attrIndexer) && updatedProject.attrIndexer !== params.attrIndexer) {
                // from: navigate(`/editor/${updatedProject.attrIndexer}`);
                params.saveEvent?.onSaveSuccessNavigate({ editorAttrIndexer: updatedProject.attrIndexer });
            }
            return updatedProjectAttributes;
        }
        catch (error) {
            throw new SaveErrors_1.SaveApiFailedError(error);
        }
    }
    async createRelationIfNotExists(projectId, objectId) {
        const relation = await this.saveApi.relationSet({
            name: "studio_project",
            destId: parseInt(projectId),
        });
        if (!relation.length) {
            return await this.saveApi.createRelation({
                sourceId: parseInt(objectId),
                destId: parseInt(projectId),
                type: "studio_project",
            });
        }
    }
    /**
     * 原来 saveAsExistingTemplateV2 前半部分校验参数方法
     */
    checkSaveAsExistingParams(params) {
        if (params.showMyModification) {
            if ((0, lodash_1.isEmpty)(params.edges)) {
                throw new SaveErrors_1.SaveWarningError("Function cannot be empty.");
            }
            const sourceEdgeData = params.edges
                .map(x => x.source)
                .filter((el, index, self) => {
                return self.indexOf(el) !== index;
            });
            if (!(0, lodash_1.isEmpty)(sourceEdgeData)) {
                throw new SaveErrors_1.SaveInfoError("Functions can only contain one-way flow edges, and the current structure cannot be saved.");
            }
            return 1;
        }
        if ((0, lodash_1.isEmpty)(params.templateObj.id)) {
            throw new SaveErrors_1.SaveApiFailedError("Unable to save project, empty id");
        }
        return 2;
    }
    async saveAsExistingTemplateV2(params) {
        // 会抛出错误
        if (this.checkSaveAsExistingParams(params) === 1) {
            params.saveEvent?.setFuncName(params.templateObj.name);
            // 异步触发 弹窗确认事件
            return new Promise((reslove, reject) => {
                setTimeout(() => {
                    params.saveEvent?.onOpenCreatorFunctionPopup().then(reslove).catch(reject);
                }, 0);
            });
        }
        // 更新保存
        return await this.saveProject({
            attributes: params.data,
            projectId: params.templateObj.id,
            attrIndexer: params.attrIndexer,
            objectId: params.objectId,
            saveEvent: params.saveEvent,
        });
    }
    /**
     *
     * attributes: updated attributes to be saved
     * scopedProject: latest user scoped(not global) copy of the project object(in web format)
     * @param params
     * @returns
     */
    async publishProject(params) {
        console.log("jj publishproject", params);
        if (!params.author.objectId) {
            throw new SaveErrors_1.SaveApiFailedError("empty user meobject id");
            // throw new SaveApiFailedError("empty user meobject id");
        }
        // try {
        // create global project with the updated project data
        // add `published` field
        const published = {
            global: true,
            author: params.author,
            uuid: params.scopedProject.uuid,
        };
        const globalProject = await this.saveApi.createObject({
            attributes: Object.assign({}, params.attributes, { published }),
            name: "studio_shared_app",
            isGlobal: true,
        });
        // create owner relation src: user, dest: global project
        const relation = await this.saveApi.createRelation({
            type: "owner",
            sourceId: params.author.objectId,
            destId: globalProject.id,
        });
        const sharedProjectAttributes = globalProject.attributesV2;
        sharedProjectAttributes.id = globalProject.id;
        sharedProjectAttributes.uuid = globalProject.uuid;
        sharedProjectAttributes.createdAt = globalProject.createdAt;
        return [sharedProjectAttributes, relation];
        // } catch (error) {
        //   return Promise.reject(error);
        // } finally {
        // }
    }
    async updateObject(params) {
        let publishedMetadata = JSON.parse(JSON.stringify(params.attributes?.published_metadata || {}));
        if (!publishedMetadata) {
            publishedMetadata = {
                original: true,
                stripe_product_id: null,
                stripe_price_id: null,
                uuid: null,
            };
        }
        publishedMetadata.uuid = params.globalProject.uuid;
        if (params.shareLink) {
            publishedMetadata.published_at = params.globalProject.createdAt;
            publishedMetadata.link = params.shareLink;
            if (params.saveEvent && params.saveEvent.assignPreviewAppData) {
                params.attributes = params.saveEvent.assignPreviewAppData(params.attributes, { publishedMetadata });
            }
        }
        const updateObject = await this.saveApi.updateObject(parseInt(params.objectId), {
            name: "studio_project",
            attributes: Object.assign({}, params.attributes, { published_metadata: publishedMetadata }),
        });
        const updateProjectAttributes = updateObject.attributesV2;
        updateProjectAttributes.id = updateObject.id;
        updateProjectAttributes.uuid = updateObject.uuid;
        updateProjectAttributes.globaluuid = params.globalProject.uuid;
        updateProjectAttributes.updatedAt = updateObject.updatedAt;
        return updateProjectAttributes;
    }
}
exports.SaveCreateService = SaveCreateService;
