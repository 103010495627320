import React from "react";
import "../components-css/Thought.css";

function Thought(props) {
  return props.content ? (
    <section className="CardThought">
      <p>{props.content}</p>
      <span className="iconfont icon-right"></span>
    </section>
  ) : null;
}

export default Thought;
