import { getIt } from "@uikit/getIt";
import style from "./ChatTooltip.module.scss";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";

export function ChatTooltip() {
  const onLinkClicked = () => {
    window.open("https://imagica.zendesk.com/hc/en-us/articles/18399149126171-Imagica-Terms-of-Use");
  };

  const homePluginStore = getIt(HomePluginStore);
  const isShare = homePluginStore.state.isShare;
  const previewCls = isShare ? "" : style.previewTooltip;

  return (
    <div className={`${style.chatTooltip} ${previewCls}`}>
      <div className={style.info}>Please speak to a professional for medical, legal, or financial advice.</div>
      <div className={style.link} onClick={() => onLinkClicked()}>
        Read our full disclaimer.
      </div>
    </div>
  );
}
