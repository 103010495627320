import React from "react";

import "../components-css/TaskList.css";

function TaskList(props) {
  return (
    <section className="TaskList">
      <div className="title">{props.content}</div>
      <p className="content">
        {props.id + 1} of {props.len}
      </p>
    </section>
  );
}

export default TaskList;
