"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapBoxRouteTollCollection = exports.MapBoxRouteManeuver = exports.MapBoxRouteMapboxStreetsV8 = exports.MapBoxRouteIntersection = exports.MapBoxRouteStep = exports.MapBoxRouteAdmin = exports.MapBoxRouteLeg = exports.MapBoxRouteRoute = exports.MapBoxRoute = void 0;
const class_transformer_1 = require("class-transformer");
class MapBoxRoute {
    constructor() {
        this.route = [];
    }
}
exports.MapBoxRoute = MapBoxRoute;
__decorate([
    (0, class_transformer_1.Type)(() => MapBoxRouteRoute)
], MapBoxRoute.prototype, "route", void 0);
class MapBoxRouteRoute {
    constructor() {
        this.weight_typical = 0;
        this.duration_typical = 0;
        this.weight_name = "";
        this.weight = 0;
        this.duration = 0;
        this.distance = 0;
        this.legs = [];
        this.geometry = "";
    }
}
exports.MapBoxRouteRoute = MapBoxRouteRoute;
__decorate([
    (0, class_transformer_1.Type)(() => MapBoxRouteLeg)
], MapBoxRouteRoute.prototype, "legs", void 0);
class MapBoxRouteLeg {
    constructor() {
        this.via_waypoints = [];
        this.admins = [];
        this.weight_typical = 0;
        this.duration_typical = 0;
        this.weight = 0;
        this.duration = 0;
        this.steps = [];
        this.distance = 0;
        this.summary = "";
    }
}
exports.MapBoxRouteLeg = MapBoxRouteLeg;
__decorate([
    (0, class_transformer_1.Type)(() => MapBoxRouteAdmin)
], MapBoxRouteLeg.prototype, "admins", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => MapBoxRouteStep)
], MapBoxRouteLeg.prototype, "steps", void 0);
class MapBoxRouteAdmin {
    constructor() {
        this.iso_3166_1_alpha3 = "";
        this.iso_3166_1 = "";
    }
}
exports.MapBoxRouteAdmin = MapBoxRouteAdmin;
class MapBoxRouteStep {
    constructor() {
        this.intersections = [];
        this.maneuver = new MapBoxRouteManeuver();
        this.name = "";
        this.weight_typical = 0;
        this.duration_typical = 0;
        this.duration = 0;
        this.distance = 0;
        this.driving_side = "";
        this.weight = 0;
        this.mode = "";
        this.geometry = "";
        this.ref = "";
        this.exits = "";
        this.destinations = "";
    }
}
exports.MapBoxRouteStep = MapBoxRouteStep;
__decorate([
    (0, class_transformer_1.Type)(() => MapBoxRouteIntersection)
], MapBoxRouteStep.prototype, "intersections", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => MapBoxRouteManeuver)
], MapBoxRouteStep.prototype, "maneuver", void 0);
class MapBoxRouteIntersection {
    constructor() {
        this.entry = [];
        this.bearings = [];
        this.duration = 0;
        this.mapbox_streets_v8 = new MapBoxRouteMapboxStreetsV8();
        this.is_urban = false;
        this.admin_index = 0;
        this.out = 0;
        this.weight = 0;
        this.geometry_index = 0;
        this.location = [];
        this.in = 0;
        this.turn_weight = 0;
        this.turn_duration = 0;
        this.traffic_signal = false;
        this.toll_collection = new MapBoxRouteTollCollection();
        this.classes = [];
        this.tunnel_name = "";
    }
}
exports.MapBoxRouteIntersection = MapBoxRouteIntersection;
__decorate([
    (0, class_transformer_1.Type)(() => MapBoxRouteMapboxStreetsV8)
], MapBoxRouteIntersection.prototype, "mapbox_streets_v8", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => MapBoxRouteTollCollection)
], MapBoxRouteIntersection.prototype, "toll_collection", void 0);
class MapBoxRouteMapboxStreetsV8 {
    constructor() {
        this.class = "";
    }
}
exports.MapBoxRouteMapboxStreetsV8 = MapBoxRouteMapboxStreetsV8;
class MapBoxRouteManeuver {
    constructor() {
        this.type = "";
        this.instruction = "";
        this.bearing_after = 0;
        this.bearing_before = 0;
        this.location = [];
        this.modifier = "";
        this.exit = 0;
    }
}
exports.MapBoxRouteManeuver = MapBoxRouteManeuver;
class MapBoxRouteTollCollection {
    constructor() {
        this.name = "";
        this.type = "";
    }
}
exports.MapBoxRouteTollCollection = MapBoxRouteTollCollection;
