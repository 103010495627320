"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryNodeDisplayType = void 0;
var StoryNodeDisplayType;
(function (StoryNodeDisplayType) {
    StoryNodeDisplayType["TEXT"] = "text";
    StoryNodeDisplayType["VIDEO"] = "video";
    StoryNodeDisplayType["IMAGEGEN"] = "imageGen";
    StoryNodeDisplayType["IMAGESEARCH"] = "imageSearch";
    StoryNodeDisplayType["MIDJOURNEY"] = "midjourney";
    StoryNodeDisplayType["MIDJOURNEYV2"] = "midjourneyV2";
    StoryNodeDisplayType["AGENT"] = "Agent";
    StoryNodeDisplayType["AMAZONSHOPPING"] = "amazonShopping";
    StoryNodeDisplayType["JDSHOPPING"] = "jdShopping";
    StoryNodeDisplayType["CUSTOMJSFUNCTION"] = "customJsFunction";
    StoryNodeDisplayType["CUSTOMAPIFUNCTION"] = "customApiFunction";
    StoryNodeDisplayType["WEEESHOPPING"] = "weeeShopping";
    StoryNodeDisplayType["WEEEGROCERIES"] = "weeeGroceries";
    StoryNodeDisplayType["STOCKINFO"] = "stockInfo";
    StoryNodeDisplayType["HTMLTEMPLATE"] = "htmlTemplate";
    StoryNodeDisplayType["STICKIE"] = "stickie";
    StoryNodeDisplayType["MAP"] = "map";
    StoryNodeDisplayType["SHOWFOODINTERFACE"] = "showFoodInterface";
    StoryNodeDisplayType["UPLOADFILE"] = "uploadFile";
    StoryNodeDisplayType["ASKBRAIN"] = "askBrain";
    StoryNodeDisplayType["UPLOADIMAGE"] = "uploadImage";
    StoryNodeDisplayType["BASEVIDEO"] = "baseVideo";
    StoryNodeDisplayType["UNSTRUCTURED"] = "unStructured";
    StoryNodeDisplayType["TABLE"] = "table";
    StoryNodeDisplayType["PDFFILE"] = "pdfFile";
    StoryNodeDisplayType["AUDIO"] = "audio";
    StoryNodeDisplayType["CHATINTERFACE"] = "chatInterface";
    StoryNodeDisplayType["HTML"] = "html";
    StoryNodeDisplayType["BLUEPRINT"] = "blueprint";
    StoryNodeDisplayType["INTERVIEW_BOT"] = "interviewBot";
    StoryNodeDisplayType["JSON"] = "json";
})(StoryNodeDisplayType || (exports.StoryNodeDisplayType = StoryNodeDisplayType = {}));
