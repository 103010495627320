import { getIt } from "@uikit/getIt";
import { Spin } from "antd";
import cls from "classnames";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { Dataload, DataloadState } from "imagica-corekit/dist/base/cutil/Dataload";
import { ReadyStatus } from "imagica-corekit/dist/base/cutil/ReadyStatus";
import { RestResponse } from "imagica-corekit/dist/base/cutil/RestClient";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { ListItem } from "imagica-corekit/dist/base/api/blueprintTyped/Blueprint";
import { useDataload } from "imagica-uikit/dist/hooks/useDataload";
import { map } from "lodash";
import React from "react";
import styles from "./SearchBlueprintsToolPieces.module.scss";
import CommandItem from "../components/CommandItem";

export type SearchItemProps<T> = {
  name: string;
  data: DataloadState<RestResponse<T>>;
  value: ListItem[] | undefined;
};

export function SearchItem<T>({ name, value, data }: SearchItemProps<T>) {
  const readyStatus = data.status;
  return (
    <div className={styles.searchItem}>
      <div className={styles.searchTitle}>{name}</div>
      <div className={styles.searchContent}>
        {readyStatus === ReadyStatus.LOADING && (
          <div className={styles.loading}>
            <span>Searching...</span>
            <Spin />
          </div>
        )}
        {readyStatus === ReadyStatus.DONE &&
          map(value, item => {
            return <CommandItem key={item.name} {...item} />;
          })}
      </div>
    </div>
  );
}

export type SearchBlueprintsToolPiecesProps = {
  onClose: () => void;
  searchQuery: string;
} & React.HTMLAttributes<HTMLDivElement>;

export function SearchBlueprintsToolPieces({
  searchQuery,
  className,
  onClose,
  ...rest
}: SearchBlueprintsToolPiecesProps) {
  const brainClient = getIt(BrainClient);

  const blueprints = useDataload(() => {
    return new Dataload(() => brainClient.openAi.searchBlueprints({ search_query: searchQuery }));
  }, []).value;

  const toolPieces = useDataload(() => {
    return new Dataload(() => brainClient.openAi.searchToolPieces({ search_query: searchQuery }));
  }, []).value;

  return (
    <div {...rest} onClick={e => e.stopPropagation()} className={cls(className, styles.searchBlueprintsToolPieces)}>
      <img
        className={styles.closeIcon}
        onClick={onClose}
        src={`${settings.S3CDN}${settings.viewAssetsPath}close_black.svg`}
        alt=""
      />
      <SearchItem name="Blueprints" value={blueprints.data?.data.blueprints} data={blueprints} />
      <SearchItem name="Tool Pieces" value={toolPieces.data?.data.toolpieces} data={toolPieces} />
    </div>
  );
}
