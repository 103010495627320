import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";

export class FotProcessStoreState {
  isInitLoading = true;

  copyWith(params: { isInitLoading?: boolean }): FotProcessStoreState {
    const state = new FotProcessStoreState();
    state.isInitLoading = params.isInitLoading ?? this.isInitLoading;

    return state;
  }
}

export class FotProcessStore extends StoreBase<FotProcessStoreState> {
  constructor() {
    super(() => new FotProcessStoreState());
  }

  startLoading(): void {
    this.emit(this.state.copyWith({ isInitLoading: true }));
  }

  overLoading(): void {
    this.emit(this.state.copyWith({ isInitLoading: false }));
  }
}
