"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FotAuthStore = exports.FotAuthStoreState = void 0;
const JsonUtil_1 = require("../cutil/JsonUtil");
const StoreBase_1 = require("../cutil/StoreBase");
class FotAuthStoreState {
    constructor() {
        this.brainToken = "";
        this.guestToken = "";
        this.brainUserEmail = "";
    }
    copyWith(params) {
        const state = new FotAuthStoreState();
        state.brainToken = params.brainToken ?? this.brainToken;
        state.guestToken = params.guestToken ?? this.guestToken;
        state.brainUserEmail = params.brainUserEmail ?? this.brainUserEmail;
        return state;
    }
}
exports.FotAuthStoreState = FotAuthStoreState;
/**
 * TODO: shareStorage 和 StorageManager 类型不一致，shareStorage属于同步行为，后面需要行为统一
 */
class FotAuthStore extends StoreBase_1.StoreBase {
    constructor(storageManager, authStore) {
        super(() => {
            const token = storageManager.getItem("BRAIN_TOKEN") ?? "";
            const email = storageManager.getItem("BRAIN_USER_EMAIL") ?? "";
            const guestToken = storageManager.getItem("GUEST_TOKEN");
            return new FotAuthStoreState().copyWith({ brainToken: token, brainUserEmail: email, guestToken });
        });
        this.storageManager = storageManager;
        this.authStore = authStore;
        this.on(value => {
            storageManager.setItem("BRAIN_TOKEN", value.brainToken);
            storageManager.setItem("BRAIN_USER_EMAIL", value.brainUserEmail);
            storageManager.setItem("GUEST_TOKEN", value.guestToken);
            const { brainToken, guestToken } = value;
            if (brainToken || guestToken) {
                if (brainToken) {
                    this.authStore.login(brainToken);
                }
                else {
                    this.authStore.loginGuest(guestToken);
                }
            }
            else {
                this.authStore.logout();
            }
            console.log("AuthStore", "state changed", JsonUtil_1.JsonUtil.stringify(value));
        }, true);
    }
    login(token) { }
    logout() {
        this.emit(new FotAuthStoreState());
    }
    loginGuest(guestUserKey) {
        const newState = this.state.copyWith({ guestToken: guestUserKey });
        this.emit(newState);
    }
    removeBrainToken() {
        this.emit(this.state.copyWith({ brainToken: "" }));
    }
    removeBrainUserEmail() {
        this.emit(this.state.copyWith({ brainUserEmail: "" }));
    }
    setBrainToken(value) {
        this.emit(this.state.copyWith({ brainToken: value }));
    }
    setBrainUserEmail(value) {
        this.emit(this.state.copyWith({ brainUserEmail: value }));
    }
}
exports.FotAuthStore = FotAuthStore;
