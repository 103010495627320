import { useEffect, useMemo } from "react";
import { get, isEmpty, isPlainObject } from "lodash";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";

const getDisabledByEdgeLineParam = lineParam => {
  const { requestParam, enterText = "" } = lineParam || {};

  // /Add Data Source function
  if (requestParam && enterText === "/Add Data Source") {
    return !requestParam.file && (!requestParam.urls || requestParam?.urls?.length === 0);
  } else {
    return false;
  }
};

const getDisabledBySourceNode = sourceNode => {
  const sourceNodeData = get(sourceNode, "data", {});
  const sourceNodeType = sourceNodeData.displayType;
  const sourceNodeTextAreaValue = sourceNodeData.textAreaValue;

  // 文件上传类型的 node
  if (sourceNodeType === "uploadFile") {
    if (isPlainObject(sourceNodeTextAreaValue) && sourceNodeTextAreaValue.s3Url) {
      return false;
    } else {
      return true;
    }
  }

  return false;
};

/**
 *
 * @param {{ id:string, data:any, sourceNode: any, sourceNodeId: string }} props
 * @returns
 */
export default function useCustomEdgeDisabled(props) {
  const { id, data, sourceNode, sourceNodeId } = props;
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const lineParam = get(data, "lineParam", {});

  let sourceNodeData = get(sourceNode, "data", {});
  if (isEmpty(sourceNode)) {
    const nodes = creatorNodesStore.state.nodes;
    sourceNodeData = nodes.find(x => x.id === sourceNodeId) || {};
  }
  const sourceNodeType = sourceNodeData.displayType;
  const sourceNodeTextAreaValue = sourceNodeData.textAreaValue;

  const disabledRun = useMemo(() => {
    // disabled line
    const disabledLine = getDisabledByEdgeLineParam(lineParam);
    // disabled uploadFile
    const disabledSourceNode = getDisabledBySourceNode(sourceNode);

    // 当 add data Source 和 uploadFile 同时出现时，先处理前者前者
    return disabledLine || disabledSourceNode;
  }, [lineParam.requestParam, lineParam.enterText, sourceNodeType, sourceNodeTextAreaValue]);

  useEffect(() => {
    creatorStoreMethods.setEdgeLineParam({ id, disabledRun });
  }, [disabledRun]);

  return { disabledRun };
}
